import validator from "validator"
import utils     from "../utils"

const companyUserSchema =

{
    firstName:
    {
        normalize: (value) =>
        {
            value = typeof value === "string" ? value : ""
            value = utils.toAlphaString(value)
            value = utils.toCondensedString(value)
            return value
        },

        validate: (value, match, cb) =>
        {
            return ""
        }
    },

    lastName:
    {
    normalize: (value) =>
        {
            value = typeof value === "string" ? value : ""
            value = utils.toAlphaString(value)
            value = utils.toCondensedString(value)
            return value
        },

        validate: (value, match, cb) =>
        {
            return ""
        }
    },

    email:
    {
        normalize: (value) =>
        {
            value = typeof value === "string" ? value : ""
            value = value.toLowerCase()
            value = utils.toCondensedString(value)
            return value
        },

        validate: (value, excludes = []) =>
        {
            if (typeof value !== "string")
            {
                return "Invalid email"
            }

            if (!value)
            {
                return excludes.includes("required") ? "" : "Email is required"
            }

            if (!validator.isEmail(value))
            {
                return "Invalid email"
            }

            return ""
        }
    }
}

export default companyUserSchema
