import { ApolloQueryResult, OperationVariables } from "@apollo/client";
import { Box, styled } from "@mui/material";
import { GridRenderCellParams, GridValueGetterParams, useGridApiRef } from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { AutoAssembleIcon, EditIcon, FilterIcon, RefreshIcon } from "assets/icons";
import { buildCpnRenderCell, instanceStateRenderCell, serialNumberRenderCell } from "build/components/grid";
import { InstanceState, LotInstance, LotRevision, Source } from "build/models";
import {
  COLLAPSE_TOOLBAR_ICON_PROPS,
  DefaultRowData,
  EXPAND_TOOLBAR_ICON_PROPS,
  Grid,
  GroupingColDef,
  ROW_HEIGHT,
  SortMode,
  ToolBarItem,
  getRowId,
  setVisibleNodeExpansionState,
  useGridExpansion,
  useGridHeight,
} from "common/components/grid";
import { ToolbarItemType } from "common/constants";
import { COLUMN_DEFAULTS } from "design/utils/columnDefaults";
import { BuildQueryData } from "graphql/query/lotQueries";
import { groupBy } from "lodash";
import { MutableRefObject, useCallback, useEffect, useMemo, useState } from "react";

const GRID_NAME = "lot_inventory_view";

enum InventoryItemType {
  INSTANCE = "INSTANCE",
  REVISION = "REVISION",
}

interface InventoryItem {
  category: string;
  cpn: string,
  lastUpdated: string;
  id: string;
  name: string;
  notes?: string;
  revision?: LotRevision;
  revisionValue: string;
  serialNumber?: { displayValue: string };
  source?: Source;
  state?: InstanceState;
  topLevel: boolean;
  type: InventoryItemType;
}

interface InventoryNode extends DefaultRowData {
  item: InventoryItem;
}

interface UseGridExpansionArgs {
  apiRef: MutableRefObject<GridApiPro>
}

// As the children are already loaded, we just have a empty function here.
function setChildLoadingCount() { }

function useExpansion({ apiRef }: UseGridExpansionArgs) {
  // As the children are already loaded, we just have a empty function here.
  const getExpansionChildren = useCallback(async (row: InventoryNode) => [] as InventoryNode[], []);

  return useGridExpansion({ apiRef, getExpansionChildren, setChildLoadingCount });
}

interface UseSetGridDataArgs {
  apiRef: MutableRefObject<GridApiPro>;
  instances?: LotInstance[];
  loading: boolean;
  primaryRevision?: LotRevision;
  revisions?: LotRevision[];
  updatePostExpansionChange: () => void;
}

function useSetGridData(args: UseSetGridDataArgs) {
  const { apiRef, loading, instances, primaryRevision, revisions, updatePostExpansionChange } = args;
  useEffect(() => {
    if (
      loading
      || !instances?.length
      || !revisions?.length
      || apiRef.current.getAllRowIds().length
    ) {
      return;
    }

    const instancesByRevId = groupBy(instances, i => i.revision.id);

    // TODO: Figure out any sorting changes that are needed.
    const rows = revisions.map<InventoryNode[]>(rev => {
      const category = !rev.category || rev.category === "Product" ? "-" : rev.category;
      const revInstances = instancesByRevId[rev.id];
      const topLevel = rev.id === primaryRevision?.id;

      return [{
        _childrenLoaded: true,
        _descendantCount: revInstances.length,
        _path: [rev.id],
        item: {
          category,
          cpn: rev.cpn,
          id: rev.id,
          lastUpdated: rev.lastModifiedDate ?? "",
          name: rev.name,
          revisionValue: rev.revisionValue,
          source: rev.source,
          topLevel,
          type: InventoryItemType.REVISION,
        },
      }, ...revInstances.map<InventoryNode>(instance => ({
        _childrenLoaded: true,
        _path: [rev.id, instance.id],
        item: {
          category,
          cpn: rev.cpn,
          id: instance.id,
          lastUpdated: instance.lastModifiedDate,
          name: rev.name,
          notes: instance.notes,
          revision: rev,
          revisionValue: rev.revisionValue,
          serialNumber: instance.serialNumber,
          state: instance.state,
          topLevel,
          type: InventoryItemType.INSTANCE,
        },
      }))];
    }).flat();

    apiRef.current.setRows(rows);
    updatePostExpansionChange();
  }, [apiRef, instances, loading, primaryRevision, revisions, updatePostExpansionChange]);
}
export type ValueGetterArgs = GridValueGetterParams<any, InventoryNode>;

export function notesValueGetter({ row }: ValueGetterArgs): string {
  return row.item.notes ?? "";
}

export interface LotInventoryViewProps {
  autoAssembleAvailable?: boolean;
  instances?: LotInstance[];
  loading: boolean;
  lotRefresh: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<BuildQueryData>>;
  primaryRevision?: LotRevision;
  revisions?: LotRevision[];
}

export function LotInventoryView(props: LotInventoryViewProps) {
  const { autoAssembleAvailable, instances, loading, lotRefresh, primaryRevision, revisions } = props;

  const apiRef = useGridApiRef();

  const { disableCollapse, disableExpand, updatePostExpansionChange, visibleRowCount } = useExpansion({ apiRef });

  const onExpandClicked = useCallback(() => {
    setVisibleNodeExpansionState(apiRef, true);
  }, [apiRef]);

  const onCollapseClicked = useCallback(() => {
    setVisibleNodeExpansionState(apiRef, false);
  }, [apiRef]);

  const gridHeight = useGridHeight(visibleRowCount, ROW_HEIGHT);

  // Define Columns
  const columns = useMemo(() => [
    {
      field: "serialNumber",
      editable: false,
      headerName: "Serial ID",
      hideable: true,
      minWidth: 71,
      position: 6,
      renderCell: serialNumberRenderCell,
      sortable: true,
      width: 150,
    },
    COLUMN_DEFAULTS.name,
    {
      field: "state",
      editable: false,
      headerName: "State",
      hideable: true,
      minWidth: 71,
      position: 6,
      renderCell: instanceStateRenderCell,
      sortable: true,
      width: 150,
    },
    COLUMN_DEFAULTS.category,
    COLUMN_DEFAULTS.revision,
    {
      editable: false,
      field: "notes",
      headerName: "Notes",
      hideable: true,
      minWidth: 71,
      position: 15,
      sortable: true,
      valueGetter: notesValueGetter,
      width: 140,
    },
    COLUMN_DEFAULTS.lastUpdated,
  ], []);

  const groupingRenderCell = useCallback((params: GridRenderCellParams<any, InventoryNode>) => (
    <GroupingColDef {...params} renderInnerCell={buildCpnRenderCell} />
  ), []);

  const groupColumn = useMemo(() => ({
    ...COLUMN_DEFAULTS.cpn,
    renderCell: groupingRenderCell,
  }), [groupingRenderCell]);

  const toolbarItems = useMemo<ToolBarItem[]>(() => {
    const items = [
      {
        // TODO: Implement editing serial ID's
        disabled: true,
        Icon: EditIcon,
        label: "Edit Serial ID's",
        type: ToolbarItemType.ACTION,
      },
      {
        type: ToolbarItemType.DIVIDER,
      },
      {
        // TODO: Implement filtering the table
        disabled: true,
        Icon: FilterIcon,
        label: "Filter",
        type: ToolbarItemType.ACTION,
      },
      {
        Icon: RefreshIcon,
        label: "Refresh",
        onClick: () => lotRefresh(),
        type: ToolbarItemType.ACTION,
      },
      {
        ...EXPAND_TOOLBAR_ICON_PROPS,
        disabled: disableExpand,
        onClick: onExpandClicked,
      },
      {
        ...COLLAPSE_TOOLBAR_ICON_PROPS,
        disabled: disableCollapse,
        onClick: onCollapseClicked,
      },
    ];

    if (autoAssembleAvailable) {
      items.unshift({ type: ToolbarItemType.DIVIDER });
      items.unshift({
        // TODO: Implement auto-assemble the table
        disabled: true,
        Icon: AutoAssembleIcon,
        label: "Auto Assemble",
        type: ToolbarItemType.ACTION,
      });
    }

    return items;
  }, [autoAssembleAvailable, disableCollapse, disableExpand, lotRefresh, onCollapseClicked, onExpandClicked]);

  useSetGridData({ apiRef, loading, instances, primaryRevision, revisions, updatePostExpansionChange });
  const [defaultGridData] = useState([]);

  const treeCountLabel = useMemo(() => `${instances?.length ?? 0} instances`, [instances?.length]);

  return (
    <GridContainer height={gridHeight}>
      <Grid
        apiRef={apiRef}
        columnDefinition={columns}
        data={defaultGridData}
        defaultGroupingExpansionDepth={1}
        enableCellFocus={false}
        getRowId={getRowId}
        loading={loading}
        name={GRID_NAME}
        sortMode={SortMode.CLIENT}
        toolbarItems={toolbarItems}
        totalCount={treeCountLabel}
        treeColumnDefinition={groupColumn}
        treeData={true}
      />
    </GridContainer>
  );
}

const GridContainer = styled(Box)<{ height: number }>(({ height }) => ({
  display: "flex",
  flex: 1,
  height: `${height}px`,
  // The grid should max out the height at being tall enough to mostly fill the
  // screen when the user scrolls down.
  maxHeight: "calc(100vh - 230px)",
}));
