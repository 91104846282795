import                    "./index.css";
import React, {Component} from "react";
import ModalBox           from "../../../../ui/modal-box";

class DeleteWarningModal extends Component
{
    constructor(props)
    {
        super(props);
        this.onClose = this.onClose.bind(this);
    }

    onClose()
    {
         this.props.closeDeleteWarningModal("showDeleteWarningModal", false);
    }

    render()
    {
        let markup = <div>
                        <ModalBox onClose={this.onClose}>
                            <div className="modal-heading">
                                <h3>Delete Template?</h3>
                            </div>
                            <div className="modal-body">
                                <span>Are you sure you want to delete {this.props.templateName}? <br/>
                                This action cannot be undone.</span>
                                <div className="btn-holder">
                                    <div className="btn-container">
                                        <button
                                            className="dark"
                                            onClick={this.onClose}>
                                            CANCEL
                                        </button>
                                        <button
                                            className="delete"
                                            onClick={this.props.remove}
                                        >
                                            DELETE TEMPLATE
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </ModalBox>
                     </div>
        return markup;
    }
}

export default DeleteWarningModal;
