import                    "./index.css";
import React, {Component} from "react";
import ModalBox           from "../../../../ui/modal-box";
import SingleType         from "./single-type";
import PerfectScrollbar   from 'react-perfect-scrollbar';
import Utils              from "../../../../../modules/utils";

class CustomizedDocumentsModal extends Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            checkAll: false,
            docTypes: Utils.clone(this.props.docTypes),
            middleClass: "",
            disableSaveButton: false
        }

        this.onClose              = this.onClose.bind(this);
        this.renderColumnSettings = this.renderColumnSettings.bind(this);
        this.updateDocTypes       = this.updateDocTypes.bind(this);
        this.saveCustomizeTypes   = this.saveCustomizeTypes.bind(this);
        this.checkAll             = this.checkAll.bind(this);
        this.toggleCheckboxes     = this.toggleCheckboxes.bind(this);
        this.toggleSaveButton     = this.toggleSaveButton.bind(this);
        this.handleOutsideClick   = this.handleOutsideClick.bind(this);
    }

    componentDidMount()
    {
        let types = this.state.docTypes;
        let middleClass = "";
        let {isCheckAll, addMiddleClass} = this.toggleCheckboxes(types);
        if(addMiddleClass)
        {
            middleClass = "middle";
        }
        document.addEventListener("click", this.handleOutsideClick, false);
        this.setState({checkAll: isCheckAll, middleClass: middleClass});
    }

    componentWillUnmount()
    {
        document.removeEventListener("click", this.handleOutsideClick, false);
    }

    onClose()
    {
        document.removeEventListener("click", this.handleOutsideClick, false);
        this.setState({docTypes: this.props.docTypes});
        this.props.onClose("displayDocumentsModal", false);
    }

    renderColumnSettings(){
        let markup = []
        let _this = this;

        let DocTypes = this.state.docTypes;
        DocTypes.forEach(function(Item){
          markup.push(<SingleType DocTypes={Item} checkAll={_this.state.checkAll}
            updateDocTypes={_this.updateDocTypes}/>);
        })
        return markup
    }

    updateDocTypes(name, isChecked)
    {
        let types = this.state.docTypes;
        let disableSaveButton = false;
        types.forEach((type) => {
            if(type.name === name)
            {
                type.isChecked = isChecked;
            }
        });
        let headers = this.state.headers;
        let middleClass = "";
        let {isCheckAll, addMiddleClass} = this.toggleCheckboxes(types);
        disableSaveButton = this.toggleSaveButton(headers, isCheckAll, addMiddleClass);
        if(addMiddleClass)
        {
            middleClass = "middle";
        }
        this.setState({docTypes: types, checkAll: isCheckAll, middleClass: middleClass, disableSaveButton: disableSaveButton});
    }

    toggleSaveButton(headers, isCheckAll, addMiddleClass)
    {
        let isInvalid = false;
        this.state.docTypes.forEach((headerItem) => {
            if(!isCheckAll && !addMiddleClass)
            {
                isInvalid = true;
            }
        });

        return isInvalid;
    }

    saveCustomizeTypes()
    {
        this.props.updateCustomizedSettings(this.state.docTypes, false);
        this.props.onClose("displayDocumentsModal", false);
    }

    checkAll(e)
    {
        let docTypes = this.state.docTypes;
        docTypes.forEach(function(Item){
            Item.isChecked = e.target.checked;
        });
        let disableSaveButton = !e.target.checked;
        this.setState({docTypes: docTypes, checkAll: e.target.checked, middleClass: "", disableSaveButton: disableSaveButton});
    }

    toggleCheckboxes(types)
    {
        let isCheckAll = true;
        let addMiddleClass = false;
        types.forEach((item) => {
            if(!item.isChecked)
            {
                isCheckAll = false;
            }
            else
            {
                addMiddleClass = true;
            }
        });
        if(isCheckAll)
        {
            addMiddleClass = false;
            return {isCheckAll, addMiddleClass};
        }
        else
        {
            return {isCheckAll, addMiddleClass};
        }
    }

    handleOutsideClick(e)
    {
        if(!this.node.contains(e.target) || e.target.classList.contains("background")) this.onClose();
    }

    render()
    {
        let markup =<div ref={node => { this.node = node;}}>
                    <ModalBox onClose={this.onClose} hideCrossIcon={true}>
                        <div className="modal-heading">
                            <h2> SELECT DOCUMENT TYPES</h2>
                        </div>
                        <table className="customise-documents-table-header">
                            <thead>
                                <tr>
                                    <th>
                                       <div className="checkbox-holder">
                                            <input
                                                type="checkbox"
                                                name="check-all"
                                                className="large"
                                                checked={this.state.checkAll}
                                                onChange={(e) => this.checkAll(e)}
                                            />
                                            <label htmlFor="check-all"
                                                className={this.state.middleClass}
                                            />
                                       </div>
                                    </th>
                                    <th>ALL TYPES</th>
                                </tr>
                            </thead>
                        </table>
                        <PerfectScrollbar className="">
                            <table className="customise-documents-table-cotent">
                                <tbody>
                                    {this.renderColumnSettings()}
                                </tbody>
                            </table>
                        </PerfectScrollbar>
                        <div className="btn-holder">
                            <div className="btn-container">
                                <button
                                    className="dark"
                                    onClick={this.onClose}>
                                    CANCEL
                                </button>
                                <button
                                    className={this.state.disableSaveButton ? "disable": ""}
                                    onClick={!this.state.disableSaveButton ? this.saveCustomizeTypes: function(){}}>
                                    SAVE
                                </button>
                            </div>
                        </div>
                    </ModalBox>
                    </div>
        return markup;
    }
}

export default CustomizedDocumentsModal;
