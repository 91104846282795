import                    "./index.css";
import React, {Component} from "react";
import Schemas            from "../../../../../../modules/schemas";
import Utils              from "../../../../../../modules/utils";
import EllipsisTooltip    from "../../../../common/ellipsis-tooltip";
import SourcingUtils      from "../../../sourcing/sourcing-utils.js";
import PerfectScrollbar   from 'react-perfect-scrollbar';
import InlineIcon         from "../../../../../ui/icon/inline-icon.js";
import AlertIcon          from "../../../../../../assets/icons/co-alert.js"
import HelpIcon           from "../../../../../../assets/icons/help-icon.js";
import Tooltip            from 'rc-tooltip';
import Link               from "../../../../../ui/link";
import CpnField           from "../../../../common/cpn-field";
import closeSrc           from "../../../../../../assets/icons/close.svg"
import Icon               from "../../../../../ui/icon"
import CompareRevIcon     from "../../../../../../assets/icons/compare-rev.js";
import LinkIcon           from "../../../../../../assets/icons/open-link-icon.js";
import Spinner            from "../../../../../ui/spinner";
import MassField          from "../../../../common/mass-field";

import 'react-perfect-scrollbar/dist/css/styles.css';


var jsdiff = require('diff');

class DiffToolRightSideBar extends Component
{
    constructor(props)
    {
        super(props);
        this.comparePrdouctTeam = this.comparePrdouctTeam.bind(this);
        this.comparePrimarySource = this.comparePrimarySource.bind(this);
        this.compareField = this.compareField.bind(this);
        this.compareDocuments = this.compareDocuments.bind(this);
        this.compareSoucrces = this.compareSoucrces.bind(this);
        this.compareAssembly = this.compareAssembly.bind(this);
        this.compareBuildSchedule = this.compareBuildSchedule.bind(this);
        this.compareCategoryAndSpecs = this.compareCategoryAndSpecs.bind(this);
        this.diffToolLink    = this.diffToolLink.bind(this);
        this.onMouseDown     = this.onMouseDown.bind(this);
        this.onMouseMove     = this.onMouseMove.bind(this);
        this.onMouseLeave    = this.onMouseLeave.bind(this);


        this.state = {
            changesCount: 0,
            compareDescription: null,
            comparePrdouctTeam: null,
            comparePrimarySource: null,
            compareName: null,
            compareEid: null,
            compareStatus: null,
            compareRevision: null,
            compareDocuments: null,
            compareSoucrces: null,
            compareAssembly: null,
            compareCpn: null,
            compareBuildSchedule: null,
            compareCategoryAndSpecs: null,
            assemblyChangesCount: 0,
            sideBar :
            {
                width   : 310,
                minWidth: 310,
            },
            minWidth: 310,
            maxWidth: 420,
        }
    }

    componentWillMount()
    {
        let state = this.state
        let {descriptionChangesCount, descriptionMarkup} = this.compareField("description")
        state.changesCount = state.changesCount + descriptionChangesCount
        state.compareDescription = descriptionMarkup

        if (this.props.alias === "prd")
        {
            let {teamChangesCount, teamMarkup} = this.comparePrdouctTeam()
            state.changesCount = state.changesCount + teamChangesCount
            state.comparePrdouctTeam = teamMarkup


            let {buildScheduleChangesCount, buildScheduleMarkup} = this.compareBuildSchedule()
            state.changesCount = state.changesCount + buildScheduleChangesCount
            state.compareBuildSchedule = buildScheduleMarkup

        }

        if (this.props.alias === "cmp")
        {
            let {cpnChangesCount, cpnMarkup} = this.compareField("cpn")
            state.changesCount = state.changesCount + cpnChangesCount
            state.compareCpn = cpnMarkup

            let {categoryAndSpecsChangesCount, categoryAndSpecsMarkup} = this.compareCategoryAndSpecs("category")
            state.changesCount = state.changesCount + categoryAndSpecsChangesCount
            state.compareCategoryAndSpecs = categoryAndSpecsMarkup
        }



        let {primarySourceChangesCount, primarySourceMarkup} = this.comparePrimarySource()
        state.changesCount = state.changesCount + primarySourceChangesCount
        state.comparePrimarySource = primarySourceMarkup

        let {nameChangesCount, nameMarkup} = this.compareField("name")
        state.changesCount = state.changesCount + nameChangesCount
        state.compareName = nameMarkup

        let {eidChangesCount, eidMarkup} = this.compareField("eid")
        state.changesCount = state.changesCount + eidChangesCount
        state.compareEid = eidMarkup

        let {statusChangesCount, statusMarkup} = this.compareField("status")
        state.changesCount = state.changesCount + statusChangesCount
        state.compareStatus = statusMarkup

        let {revisionChangesCount, revisionMarkup} = this.compareField("revision")
        state.changesCount = state.changesCount + revisionChangesCount
        state.compareRevision = revisionMarkup

        let {documentsChangesCount, documentsMarkup} = this.compareDocuments()
        state.changesCount = state.changesCount + documentsChangesCount
        state.compareDocuments = documentsMarkup

        let {sourcesChangesCount, sourcesMarkup} = this.compareSoucrces()
        state.changesCount = state.changesCount + sourcesChangesCount
        state.compareSoucrces = sourcesMarkup

        let {assemblyChangesCount, assemblyMarkup} = this.compareAssembly(this.props.mode === 'co' ? this.props.assemblyDiffCount : null)
        state.assemblyChangesCount = assemblyChangesCount
        state.compareAssembly = assemblyMarkup

        this.setState(state)
    }

    componentWillReceiveProps(nextProps)
    {
        if (nextProps.syncWithParent)
        {
            let state = this.state
            let {assemblyChangesCount, assemblyMarkup} = this.compareAssembly(nextProps.assemblyDiffCount)
            state.assemblyChangesCount = assemblyChangesCount
            state.compareAssembly = assemblyMarkup
            this.props.afterSyncWithParent()
            this.setState(state)
        }
    }


    getMatchedAssembly(children, childId)
    {
        let child = null;
        for(let i = 0; i < children.length; i++) {

            if (children[i].assemblyRevision && childId === children[i].assemblyRevision.cpn)
            {
                child = children[i];
                break;
            }
        }
        return {child: child, found: !!child}
    }

    getMatchedDocument(documents, docId)
    {
        let doc = null;
        for(let i = 0; i < documents.length; i++) {

            if (docId === documents[i].file._id)
            {
                doc = documents[i];
                break;
            }
        }
        return {doc: doc, found: !!doc}
    }

    getMatchedSource(sources, srcId)
    {
        let src = null;
        for(let i = 0; i < sources.length; i++) {

            if (srcId === sources[i].gId)
            {
                src = sources[i];
                break;
            }
        }
        return {src: src, found: !!src}
    }

    getMatchedBuildSchedule(shedules, scheduleKey)
    {
        let schedule = null;
        for(let i = 0; i < shedules.length; i++) {

            if (scheduleKey === shedules[i].key)
            {
                schedule = shedules[i];
                break;
            }
        }
        return {schedule: schedule, found: !!schedule}
    }

    getMatchedSpec(specs, specKey)
    {
        let spec = null;
        for(let i = 0; i < specs.length; i++) {

            if (specKey === specs[i].key)
            {
                spec = specs[i];
                break;
            }
        }
        return {spec: spec, found: !!spec}
    }

    activeDeActiveDiffSection(e, selector, action)
    {
        let elements = document.querySelectorAll(selector);
        elements.forEach((elm) => {
            if (action === "add") elm.classList[action]("fade-transition");
            elm.classList[action]("diff-tool-active-section");
        })

        switch(selector)
        {
            case ".diff-primary-source-section" :
            {
                let elementsArr = [
                    ".diff-min-qty-section",
                    ".diff-unit-price-section",
                    ".diff-lead-time-section",
                    ".diff-mass-cell-section",
                    ".diff-procurement-cell-section"
                ];

                elementsArr.forEach((sel) => {
                    let elements = document.querySelectorAll(sel);
                    if (elements.length > 1)
                    {
                        let elm = document.querySelector(`.inner-info${sel}`);
                        if (action === "add") elm && elm.classList[action]("fade-transition");
                        elm && elm.classList && elm.classList[action]("diff-tool-active-section");
                    }
                })
                break;
            }

            case ".diff-team-section" :
            {
                let elementsArr = [
                    ".diff-qa-section",
                    ".diff-engineering-section",
                    ".diff-manufacturing-section",
                    ".diff-procurement-section",
                    ".diff-productManager-section"
                ];
                elementsArr.forEach((sel) => {
                    let elements = document.querySelectorAll(sel);
                    if (elements.length > 1)
                    {
                        let elm = document.querySelector(`.player-email${sel}`);
                        if (action === "add") elm && elm.classList[action]("fade-transition");
                        elm && elm.classList && elm.classList[action]("diff-tool-active-section");
                    }
                })
                break;
            }

            case ".diff-build-schedule-section" :
            {
                let elementsArr = [];
                let {sourceRev} = this.props

                sourceRev.forecasts.forEach((spec) => {
                    elementsArr.push(`.diff-${spec.key.replace(/[()]/g, '').replace(/\s\s+/g, ' ').split(' ').join('-').toLowerCase()}-section`)
                })

                elementsArr.forEach((sel) => {
                    let elements = document.querySelectorAll(sel);
                    if (elements.length > 1)
                    {
                        let elm = document.querySelector(`.schedule-date-amount${sel}`);
                        if (action === "add") elm && elm.classList[action]("fade-transition");
                        elm && elm.classList && elm.classList[action]("diff-tool-active-section");
                    }
                })
                break;
            }

            case ".diff-specs-section" :
            {
                let catElements = document.querySelectorAll(".diff-category-section");
                if (catElements.length > 1)
                {
                    let elm = document.querySelector(".product-overview .diff-category-section");
                    if (action === "add") elm && elm.classList[action]("fade-transition");
                    elm && elm.classList && elm.classList[action]("diff-tool-active-section");
                }

                let elementsArr = [];
                let {sourceRev} = this.props
                let specSourceArr = [];
                for (let spec of sourceRev.specs)
                {
                    specSourceArr.push(spec)
                }
                if(sourceRev.mass) specSourceArr.push({key: 'MASS', value: sourceRev.mass.toString()})
                specSourceArr.push({key: 'PROCUREMENT', value: sourceRev.procurement})
                specSourceArr.push({key: 'UNIT OF MEASURE', value: sourceRev.unitOfMeasure})

                specSourceArr.forEach((spec) => {
                    elementsArr.push(`.diff-${spec.key.replace(/[^a-zA-Z ]/g, "").split(' ').join('-').toLowerCase()}-section`)
                })

                elementsArr.forEach((sel) => {
                    let elements = document.querySelectorAll(sel);
                    if (elements.length > 1)
                    {
                        let elm = document.querySelector(`.inner-info${sel}`);
                        if (action === "add") elm && elm.classList[action]("fade-transition");
                        elm && elm.classList && elm.classList[action]("diff-tool-active-section");
                    }
                })
                break;
            }


            default :
            {
                // noop
            }
        }
    }

    getHtml(count, changeEventName, clazz)
    {
        let markup = count ?
            <div className="item-list">
                <div className="item-label">
                    <span className={`tag-item ${clazz}`}>
                        {changeEventName}
                    </span>
                </div>
                <div className="item-content">
                    <span className={`tag-item ${clazz}`}>
                        {count}
                    </span>
                </div>
            </div> : null
            return markup
    }

    compareAssembly(assemblyDiffCount=null)
    {
        let changesCount = 0
        let removedAssemblyCount = 0
        let addedAssemblyCount = 0
        let updatedAssemblyCount = 0

        if (assemblyDiffCount)
        {
            removedAssemblyCount = removedAssemblyCount + assemblyDiffCount.childRemovedCount
            addedAssemblyCount = addedAssemblyCount + assemblyDiffCount.childAddedCount
            updatedAssemblyCount = updatedAssemblyCount + assemblyDiffCount.childUpdatedCount
            changesCount = removedAssemblyCount + addedAssemblyCount + updatedAssemblyCount
        }
        else
        {
            let {sourceRev, targetRev} = this.props
            let sourceAndTargetRevIds = []
            for(let i = 0; i < sourceRev.children.length; i++) {
                if (sourceRev.children[i].assemblyRevision)
                {
                    sourceAndTargetRevIds.push(sourceRev.children[i].assemblyRevision.cpn)
                }
            }

            for(let i = 0; i < targetRev.children.length; i++) {
                if (targetRev.children[i].assemblyRevision && !sourceAndTargetRevIds.includes(targetRev.children[i].assemblyRevision.cpn))
                {
                    sourceAndTargetRevIds.push(targetRev.children[i].assemblyRevision.cpn)
                }
            }

            for(let i = 0; i < sourceAndTargetRevIds.length; i++) {
                let isPresentInTargetRev = false
                let isPresentInSourceRev = false

                isPresentInTargetRev = this.getMatchedAssembly(targetRev.children, sourceAndTargetRevIds[i]).found
                isPresentInSourceRev = this.getMatchedAssembly(sourceRev.children, sourceAndTargetRevIds[i]).found

                if (!isPresentInSourceRev && isPresentInTargetRev)
                {
                    removedAssemblyCount++
                    changesCount++
                }
                else if (isPresentInSourceRev && !isPresentInTargetRev)
                {
                    addedAssemblyCount++
                    changesCount++
                }

                else if (isPresentInSourceRev && isPresentInTargetRev)
                {
                    let sourceRevChild = this.getMatchedAssembly(sourceRev.children, sourceAndTargetRevIds[i]).child
                    let targetRevChild = this.getMatchedAssembly(targetRev.children, sourceAndTargetRevIds[i]).child

                    let sourceRevChildrenString = `${sourceRevChild.itemNumber || ""}${sourceRevChild.notes || ""}${sourceRevChild.quantity}`
                    let targetRevChildrenString = `${targetRevChild.itemNumber || ""}${targetRevChild.notes || ""}${targetRevChild.quantity}`

                    let diff = jsdiff.diffLines(targetRevChildrenString, sourceRevChildrenString);
                    let diffFlagWithOutRefDes = false
                    let diffFlagWithRefDes = false

                    for(let part of diff)
                    {
                        if (part.removed || part.removed)
                        {
                            diffFlagWithOutRefDes = true
                            break;
                        }
                    }

                    //Handle RefDes separately becuase it's slightly diff because of R1,R2:R3 etc
                    let sourceRefDesString = sourceRevChild.refDes.replace(/[,:;]/g, ' ').replace(/\s\s+/g, ' ');
                    let targetRefDesString = targetRevChild.refDes.replace(/[,:;]/g, ' ').replace(/\s\s+/g, ' ');

                    let diffRefDes = jsdiff.diffWords(targetRefDesString, sourceRefDesString);
                    for(let part of diffRefDes)
                    {
                        if (part.removed || part.removed)
                        {
                            diffFlagWithRefDes = true
                            break;
                        }
                    }

                    //Note: Only checking diff i.e addition/removal of docs
                    if (diffFlagWithRefDes || diffFlagWithOutRefDes)
                    {
                        updatedAssemblyCount++
                        changesCount++
                    }
                }
            }
        }

        let loadingTile =
                <div className="item-list">
                    <div className="item-label">
                    </div>
                    <div className="item-content">
                    </div>
                </div>

        let markup =  this.props.showAssemblyLoading || changesCount ?
            <div className="box-item">
                <div className='assembly-updates-heading'>
                <div className='box-heading'>ASSEMBLY</div>
                {
                    this.props.showAssemblyLoading ?
                        <Spinner iconClassName="where-used-modal-spinner"/> : null
                }
                </div>
                <div className='box-holder primary_source diff-assembly-section'
                    onMouseOver={(e) => this.activeDeActiveDiffSection(e, ".diff-assembly-section", "add")}
                    onMouseLeave={(e) => this.activeDeActiveDiffSection(e, ".diff-assembly-section", "remove")}
                >
                    {
                        this.props.showAssemblyLoading ?
                        <div className="changes-loading">
                            {loadingTile}
                            {loadingTile}
                            {loadingTile}
                        </div>
                        : null
                    }

                    {   changesCount ?
                        <div>
                            {this.getHtml(removedAssemblyCount, "REMOVED", "remove")}
                            {this.getHtml(addedAssemblyCount, "ADDED", "add")}
                            {this.getHtml(updatedAssemblyCount, "MODIFIED", "update")}
                        </div> : null
                    }

                </div>
            </div> : null
        return {assemblyChangesCount: changesCount, assemblyMarkup: markup}
    }

    compareDocuments()
    {
        let changesCount = 0
        let removedDocCount = 0
        let addedDocCount = 0
        let updatedDocCount = 0

        let {sourceRev, targetRev} = this.props
        let sourceAndTargetRevIds = []
        for(let i = 0; i < sourceRev.documents.length; i++) {
            sourceAndTargetRevIds.push(sourceRev.documents[i].file._id)
        }

        for(let i = 0; i < targetRev.documents.length; i++) {
            if (!sourceAndTargetRevIds.includes(targetRev.documents[i].file._id))
            {
                sourceAndTargetRevIds.push(targetRev.documents[i].file._id)
            }
        }

        for(let i = 0; i < sourceAndTargetRevIds.length; i++) {
            let isPresentInTargetRev = false
            let isPresentInSourceRev = false

            isPresentInTargetRev = this.getMatchedDocument(targetRev.documents, sourceAndTargetRevIds[i]).found
            isPresentInSourceRev = this.getMatchedDocument(sourceRev.documents, sourceAndTargetRevIds[i]).found

            if (!isPresentInSourceRev && isPresentInTargetRev)
            {
                removedDocCount++
                changesCount++
            }
            else if (isPresentInSourceRev && !isPresentInTargetRev)
            {
                addedDocCount++
                changesCount++
            }

            else if (isPresentInSourceRev && isPresentInTargetRev)
            {
                let sourceRevDoc = this.getMatchedDocument(sourceRev.documents, sourceAndTargetRevIds[i]).doc
                let targetRevDoc = this.getMatchedDocument(targetRev.documents, sourceAndTargetRevIds[i]).doc

                let sourceRevDocumentString = `${sourceRevDoc.specs.status} ${sourceRevDoc.specs.revision} ${sourceRevDoc.specs.type} ${sourceRevDoc.specs.lastModified}`
                let targetRevDocumentString = `${targetRevDoc.specs.status} ${targetRevDoc.specs.revision} ${targetRevDoc.specs.type} ${targetRevDoc.specs.lastModified}`

                let diff = jsdiff.diffWords(targetRevDocumentString, sourceRevDocumentString);
                //Note: Only checking diff i.e addition/removal of docs
                if (diff.length > 1)
                {
                    updatedDocCount++
                    changesCount++
                }
            }
        }

        let markup = changesCount ?
            <div className="box-item">
                <div className='box-heading'>DOCUMENTS</div>
                <div className='box-holder primary_source diff-document-section'
                    onMouseOver={(e) => this.activeDeActiveDiffSection(e, ".diff-document-section", "add")}
                    onMouseLeave={(e) => this.activeDeActiveDiffSection(e, ".diff-document-section", "remove")}
                >
                    {this.getHtml(removedDocCount, "REMOVED", "remove")}
                    {this.getHtml(addedDocCount, "ADDED", "add")}
                    {this.getHtml(updatedDocCount, "MODIFIED", "update")}

                </div>
            </div> : null
        return {documentsChangesCount: changesCount, documentsMarkup: markup}
    }

    compareSoucrces()
    {
        let changesCount = 0
        let removedSrcCount = 0
        let addedSrcCount = 0
        let updatedSrcCount = 0
        let {sourceRev, targetRev} = this.props

        let sourceRevSourcesWithRowData = SourcingUtils.manufacturersToRowData(sourceRev.manufacturers, null, false, true)
        let targetRevSourcesWithRowData = SourcingUtils.manufacturersToRowData(targetRev.manufacturers, null, false, true)

        let sourceAndTargetRevIds = []
        for(let i = 0; i < sourceRevSourcesWithRowData.length; i++) {
            sourceAndTargetRevIds.push(sourceRevSourcesWithRowData[i].gId)
        }

        for(let i = 0; i < targetRevSourcesWithRowData.length; i++) {
            if (!sourceAndTargetRevIds.includes(targetRevSourcesWithRowData[i].gId))
            {
                sourceAndTargetRevIds.push(targetRevSourcesWithRowData[i].gId)
            }
        }

        for(let i = 0; i < sourceAndTargetRevIds.length; i++) {
            let isPresentInTargetRev = false
            let isPresentInSourceRev = false

            isPresentInTargetRev = this.getMatchedSource(targetRevSourcesWithRowData, sourceAndTargetRevIds[i]).found
            isPresentInSourceRev = this.getMatchedSource(sourceRevSourcesWithRowData, sourceAndTargetRevIds[i]).found

            if (!isPresentInSourceRev && isPresentInTargetRev)
            {
                removedSrcCount++
                changesCount++
            }
            else if (isPresentInSourceRev && !isPresentInTargetRev)
            {
                addedSrcCount++
                changesCount++
            }

            else if (isPresentInSourceRev && isPresentInTargetRev)
            {
                let sourceRevSrc = this.getMatchedSource(sourceRevSourcesWithRowData, sourceAndTargetRevIds[i]).src
                let targetRevSrc = this.getMatchedSource(targetRevSourcesWithRowData, sourceAndTargetRevIds[i]).src

                let diff = jsdiff.diffJson(targetRevSrc, sourceRevSrc);
                //Note: Only checking diff i.e addition/removal of docs
                if (diff.length > 1)
                {
                    updatedSrcCount++
                    changesCount++
                }
            }
        }

        let sourceRolledUpCost = SourcingUtils.getRolledUpCostFromChildren(this.props.sourceRev.children, true)
        let targetRolledUpCost = SourcingUtils.getRolledUpCostFromChildren(this.props.targetRev.children, true)
        let diffRolledUpCost = jsdiff.diffJson(targetRolledUpCost, sourceRolledUpCost)
        if (diffRolledUpCost.length > 1)
        {
            changesCount++
            updatedSrcCount++
        }

        let markup = changesCount ?
            <div className="box-item">
                <div className='box-heading'>SOURCING</div>
                <div className='box-holder primary_source diff-sourcing-section'
                    onMouseOver={(e) => this.activeDeActiveDiffSection(e, ".diff-sourcing-section", "add")}
                    onMouseLeave={(e) => this.activeDeActiveDiffSection(e, ".diff-sourcing-section", "remove")}
                >
                    {this.getHtml(removedSrcCount, "REMOVED", "remove")}
                    {this.getHtml(addedSrcCount, "ADDED", "add")}
                    {this.getHtml(updatedSrcCount, "MODIFIED", "update")}

                </div>
            </div> : null
        return {sourcesChangesCount: changesCount, sourcesMarkup: markup}
    }

    comparePrimarySource()
    {
        let {sourceRev, targetRev, massPrecision} = this.props;
        let sourceRevPrimarySource = sourceRev.primarySource
        let targetRevPrimarySource = targetRev.primarySource
        if (sourceRevPrimarySource && targetRevPrimarySource)
        {
            let sourceRevMinQty = sourceRevPrimarySource.minQuantity ? sourceRevPrimarySource.minQuantity.toString() : ""
            let sourceRevUnitPrice = sourceRevPrimarySource.unitPrice ? sourceRevPrimarySource.unitPrice.toString() : ""
            let sourceRevLeadTime = sourceRevPrimarySource.leadTime ? [null, undefined].includes(sourceRevPrimarySource.leadTime.value) ?  "" : `${sourceRevPrimarySource.leadTime.value} ${sourceRevPrimarySource.leadTime.units}`: "";
            let targetRevMinQty = targetRevPrimarySource.minQuantity ? targetRevPrimarySource.minQuantity.toString() : ""
            let targetRevUnitPrice = targetRevPrimarySource.unitPrice ? targetRevPrimarySource.unitPrice.toString() : ""
            const targetRevLeadTime = targetRevPrimarySource.leadTime.units ?
                `${targetRevPrimarySource.leadTime.value.toString() || sourceRevPrimarySource.leadTime.value.toString()} 
            ${targetRevPrimarySource.leadTime.units || sourceRevPrimarySource.leadTime.units}` :
                "";

            let changesCount = 0
            let isMinQtyChange = false

            let diff = jsdiff.diffWords(targetRevMinQty, sourceRevMinQty);
            let minQty = diff.map((part, i) =>
            {
                if (!isMinQtyChange && (part.added || part.removed))
                {
                    isMinQtyChange = true
                }
                if (part.added || part.removed)
                {
                    changesCount++
                }

                let diffClass = part.added ? "add" : part.removed ? "remove" : "no-change"
                let block  =
                        <span className={`tag-item ${diffClass}`} key={`qty-${i}`}>
                            {part.value}
                        </span>
                return block
            })


            let isLeadTimeChange = false
            diff = jsdiff.diffLines(targetRevLeadTime, sourceRevLeadTime);
            let leadTime = diff.map((part, i) =>
            {
                if (!isLeadTimeChange && (part.added || part.removed))
                {
                    isLeadTimeChange = true
                }
                if (part.added || part.removed)
                {
                    changesCount++
                }

                let diffClass = part.added ? "add" : part.removed ? "remove" : "no-change"
                let block  =
                        <span className={`tag-item ${diffClass}`} key={`lead-time-${i}`}>
                            {part.value && part.value}
                        </span>
                return block
            })

            let isUnitPriceChange = false
            diff = jsdiff.diffLines(targetRevUnitPrice, sourceRevUnitPrice);
            let unitPrice = diff.map((part, i) =>
            {
                if (!isUnitPriceChange && (part.added || part.removed))
                {
                    isUnitPriceChange = true
                }
                if (part.added || part.removed)
                {
                    changesCount++
                }

                let diffClass = part.added ? "add" : part.removed ? "remove" : "no-change"
                let block  =
                        <span className={`tag-item ${diffClass}`} key={`unit-price-${i}`}>
                            {`${SourcingUtils.numberWithCommas(SourcingUtils.autoCorrectPriceDecimalPlaces(part.value))}`}
                        </span>
                return block
            })

            let isMassChange = false
            let isProcurementChange = false
            let mass = null
            let procurement = null
            if(sourceRev.alias === "prd")
            {
                diff = jsdiff.diffLines(targetRev.mass ? targetRev.mass.toString():'', sourceRev.mass ? sourceRev.mass.toString():'');

                mass = diff.map((part, i) =>
                {
                    if (!isMassChange && (part.added || part.removed))
                    {
                        isMassChange = true
                    }
                    if (part.added || part.removed)
                    {
                        changesCount++
                    }

                    let diffClass = part.added ? "add" : part.removed ? "remove" : "no-change"
                    let block  =
                            <span className={`tag-item ${diffClass}`} key={`mass-${i}`}>
                                {
                                    part.value &&
                                    <MassField
                                        mass={part.value}
                                        massPrecision={massPrecision}
                                    />
                                }
                            </span>
                    return block
                })

                diff = jsdiff.diffLines(targetRev.procurement ? targetRev.procurement.toString():'', sourceRev.procurement ? sourceRev.procurement.toString():'');

                procurement = diff.map((part, i) =>
                {
                    if (!isProcurementChange && (part.added || part.removed))
                    {
                        isProcurementChange = true
                    }
                    if (part.added || part.removed)
                    {
                        changesCount++
                    }

                    let diffClass = part.added ? "add" : part.removed ? "remove" : "no-change"
                    let block  =
                            <span className={`tag-item ${diffClass}`} key={`procurement-${i}`}>
                                {part.value && part.value}
                            </span>
                    return block
                })
            }

            let markup = null
            markup = isMinQtyChange || isLeadTimeChange || isUnitPriceChange || isMassChange || isProcurementChange?
                <div className="box-item">
                    <div className='box-heading'>PRIMARY SOURCE</div>
                    <div className='box-holder primary_source diff-primary-source-section'
                        name="primary-source"
                        onMouseOver={(e) => this.activeDeActiveDiffSection(e, ".diff-primary-source-section", "add")}
                        onMouseLeave={(e) => this.activeDeActiveDiffSection(e, ".diff-primary-source-section", "remove")}
                    >
                    {
                        isMinQtyChange &&
                        <div className="item-list diff-min-qty-section">
                            <div className="item-label">
                                MIN QTY
                            </div>
                            <div className="item-content">
                                {minQty}
                            </div>
                        </div>
                    }

                    {
                        isLeadTimeChange &&
                        <div className="item-list diff-lead-time-section">
                            <div className="item-label">
                                LEAD TIME
                            </div>
                            <div className="item-content">
                                {leadTime}
                            </div>
                        </div>
                    }

                    {
                        isUnitPriceChange &&
                        <div className="item-list diff-unit-price-section">
                            <div className="item-label">
                                UNIT PRICE
                            </div>
                            <div className="item-content">
                                {unitPrice}
                            </div>
                        </div>
                    }

                    {
                        sourceRev.alias === "prd" && isMassChange &&
                        <div className="item-list diff-mass-cell-section">
                            <div className="item-label">
                                MASS
                            </div>
                            <div className="item-content">
                                {mass}
                            </div>
                        </div>
                    }

                    {
                        sourceRev.alias === "prd" && isProcurementChange &&
                        <div className="item-list diff-procurement-cell-section">
                            <div className="item-label">
                                PROCUREMENT
                            </div>
                            <div className="item-content">
                                {procurement}
                            </div>
                        </div>
                    }

                    </div>
                </div> : null
            return {primarySourceChangesCount: changesCount, primarySourceMarkup: markup}
        }
        else
        {
            return {primarySourceChangesCount: 0, primarySourceMarkup: null}
        }
    }

    compareField(fieldName)
    {
        let {sourceRev, targetRev} = this.props
        let changesCount = 0
        let isChange = false
        let diff = jsdiff.diffLines(targetRev[fieldName], sourceRev[fieldName]);
        if(fieldName === 'revision')
        {
            let targetRevisionValue = `${targetRev.revision}${targetRev.modified && !targetRev.co ? '*' : ''}`
            let sourceRevisionValue = `${sourceRev.revision}${sourceRev.modified && !sourceRev.co ? '*' : ''}`
            diff = jsdiff.diffLines(targetRevisionValue, sourceRevisionValue);
        }

        if(diff.length && fieldName === 'revision')
        {
            diff.map((part, i) => {
                if (part.removed && !targetRev.co && targetRev.modified)
                {
                    part.modified = true
                }
                else if(part.added && !sourceRev.co && sourceRev.modified)
                {
                    part.modified = true
                }
            })
        }

        let fieldHtml = diff.map((part, i) =>
        {
            if (!isChange && (part.added || part.removed))
            {
                isChange = true
            }
            if (part.added || part.removed)
            {
                changesCount++
            }
            part.value = fieldName === 'revision' ? part.value.replace('*', '') : part.value;

            let diffClass = part.added ? "add" : part.removed ? "remove" : "no-change"
            let block  =
                <span key={`${fieldName}-${i}`} className={`diff-check ${diffClass}`}>
                    {
                        part.value.split(" ").map((wrd, i) =>
                        {
                            if(part.modified)
                            {
                                return <span key={`wrd-${i}`}>&nbsp;{wrd}<div className = "alert-icon alert-border-black"><AlertIcon/></div></span>
                            }
                            return <span key={`wrd-${i}`}>&nbsp;{wrd}</span>
                        })
                    }
                </span>
            return block
        })

        let markup = null
        markup = isChange &&
            <div className="box-item">
                <div className='box-heading'>{fieldName.toUpperCase()}</div>
                <div className={`box-holder diff-${fieldName}-section`}
                    onMouseOver={(e) => this.activeDeActiveDiffSection(e, `.diff-${fieldName}-section`, "add")}
                    onMouseLeave={(e) => this.activeDeActiveDiffSection(e, `.diff-${fieldName}-section`, "remove")}
                >
                    { fieldHtml }
                </div>
            </div>

        let response = {}
        response[`${fieldName}ChangesCount`] = changesCount
        response[`${fieldName}Markup`] = markup
        return response
    }

    comparePrdouctTeam()
    {
        let changesCount = 0
        let {sourceRev, targetRev} = this.props

        let markup = []
        let prdTeamList = Schemas.product.team.list()

        prdTeamList.forEach((spec, i) =>
        {
            let isChange = false
            let diff = jsdiff.diffSentences(targetRev.team[spec.value].email, sourceRev.team[spec.value].email);

            diff.forEach((part, i) =>
            {
                let diffClass = part.added ? "add" : part.removed ? "remove" : "no-change"
                if (!isChange && (part.added || part.removed))
                {
                    isChange = true
                }
                if (part.added || part.removed)
                {
                    changesCount++
                }
            })

            if (isChange)
            {
                let teamBlock = diff.map((part, i) =>
                {
                    let diffClass = part.added ? "add" : part.removed ? "remove" : "no-change"
                    let block  =
                            <span className={`tag-item ${diffClass}`} key={`team-${i}`}>
                                {part.value}
                            </span>
                    return block

                })

                let team =
                    <div className={`item-list diff-${spec.value}-section`}>
                        <EllipsisTooltip
                            index={i}
                            classes={"ellipsis-tooltip"}
                            title={`${spec.displayName}`}
                            innerClasses={"item-label"}
                        >
                        <span>
                            {spec.displayName}
                        </span>
                        </EllipsisTooltip>

                        <div className="item-content">
                            {teamBlock}
                        </div>
                    </div>
                markup.push(team)
            }

        })

        markup = markup.length ?
        <div className="box-item">
            <div className='box-heading'>TEAM</div>
            <div className='box-holder diff-team-section'
                onMouseOver={(e) => this.activeDeActiveDiffSection(e, `.diff-team-section`, "add")}
                onMouseLeave={(e) => this.activeDeActiveDiffSection(e, `.diff-team-section`, "remove")}
            >
                {markup}
            </div>
        </div> : null
        return {teamChangesCount: changesCount, teamMarkup: markup}
    }

    compareBuildSchedule()
    {
        let changesCount = 0
        let {sourceRev, targetRev} = this.props

        let markup = []
        let sourceAndTargetbuildSchedules = []
        let sourceAndTargetbuildSchedulesKeys = []

        for(let i = 0; i < sourceRev.forecasts.length; i++) {
            if (!sourceAndTargetbuildSchedulesKeys.includes(sourceRev.forecasts[i].key))
            {
                sourceAndTargetbuildSchedules.push(sourceRev.forecasts[i])
                sourceAndTargetbuildSchedulesKeys.push(sourceRev.forecasts[i].key)
            }
        }

        for(let i = 0; i < targetRev.forecasts.length; i++) {
            if (!sourceAndTargetbuildSchedulesKeys.includes(targetRev.forecasts[i].key))
            {
                sourceAndTargetbuildSchedules.push(targetRev.forecasts[i])
                sourceAndTargetbuildSchedulesKeys.push(targetRev.forecasts[i].key)
            }
        }

        sourceAndTargetbuildSchedules.forEach((spec, i) =>
        {
            let isChange = false
            let isPresentInTargetRev = false
            let isPresentInSourceRev = false
            let buildScheduleBlock = null

            isPresentInTargetRev = this.getMatchedBuildSchedule(targetRev.forecasts, spec.key).found
            isPresentInSourceRev = this.getMatchedBuildSchedule(sourceRev.forecasts, spec.key).found

            if (!isPresentInSourceRev && isPresentInTargetRev)
            {
                isChange = true
                changesCount++

                buildScheduleBlock  =
                        <div key={`schedule-attr-remove-${i}`}>
                            <span className={`tag-item remove`}>
                                {Utils.dateFormatWithSlash(spec.targetDate)}
                            </span>
                            <span className={`tag-item remove`}>
                                {spec.volume}
                            </span>
                        </div>

            }
            else if (isPresentInSourceRev && !isPresentInTargetRev)
            {
                isChange = true
                changesCount++

                buildScheduleBlock  =
                        <div key={`schedule-attr-add-${i}`}>
                            <span className={`tag-item add`}>
                                {Utils.dateFormatWithSlash(spec.targetDate)}
                            </span>
                            <span className={`tag-item add`}>
                                {spec.volume}
                            </span>
                        </div>
            }

            else if (isPresentInSourceRev && isPresentInTargetRev)
            {
                let sourceRevSchedule = this.getMatchedBuildSchedule(sourceRev.forecasts, spec.key).schedule
                let targetRevSchedule = this.getMatchedBuildSchedule(targetRev.forecasts, spec.key).schedule

                let sourceRevTargetDate = Utils.dateFormatWithSlash(sourceRevSchedule.targetDate)
                let targetRevTargetDate = Utils.dateFormatWithSlash(targetRevSchedule.targetDate)


                let sourceRevSchedulerenString = `${sourceRevTargetDate}${sourceRevSchedule.volume}`
                let targetRevSchedulerenString = `${targetRevTargetDate}${targetRevSchedule.volume}`

                let diff = jsdiff.diffLines(targetRevSchedulerenString, sourceRevSchedulerenString);
                if (diff.length > 1)
                {
                    isChange = true
                    changesCount++

                    let diffTargetDate = jsdiff.diffLines(sourceRevTargetDate, targetRevTargetDate);
                    let dateRemovedBlock = null
                    let dateUpdatedBlock = null

                    diffTargetDate.forEach((part, i) => {
                        if (part.removed)
                        {
                            dateRemovedBlock =
                                <span className={`tag-item remove`} key={`date-attr-remove-${i}`}>
                                    {part.value}
                                </span>
                        }
                        else if (part.added)
                        {
                            dateUpdatedBlock =
                                <span className={`tag-item add`} key={`date-attr-update-${i}`}>
                                    {part.value}
                                </span>
                        }
                    })

                    sourceRevSchedule.volume = sourceRevSchedule.volume ? sourceRevSchedule.volume.toString() : ''
                    targetRevSchedule.volume = targetRevSchedule.volume ? targetRevSchedule.volume.toString() : ''
                    let diffVolum = jsdiff.diffLines(targetRevSchedule.volume, sourceRevSchedule.volume);
                    let volumRemovedBlock = null
                    let volumUpdatedBlock = null

                    diffVolum.forEach((part) => {
                        if (part.removed)
                        {
                             volumRemovedBlock =
                                <span className={`tag-item remove`} key={`vol-attr-remove-${i}`}>
                                    {part.value}
                                </span>
                        }
                        else if (part.added)
                        {
                            volumUpdatedBlock =
                                <span className={`tag-item add`} key={`vol-attr-update-${i}`}>
                                    {part.value}
                                </span>
                        }
                    })

                    buildScheduleBlock  =
                        <div>
                            {dateRemovedBlock && dateRemovedBlock}
                            {dateUpdatedBlock && dateUpdatedBlock}
                            {volumRemovedBlock && volumRemovedBlock}
                            {volumUpdatedBlock && volumUpdatedBlock}
                        </div>
                }
            }

            if (isChange)
            {
                let schedule =
                    <div className={`item-list diff-${spec.key.replace(/[()]/g, '').replace(/\s\s+/g, ' ').split(' ').join('-').toLowerCase()}-section`}
                        key={`schedule-${i}`}
                    >
                        <EllipsisTooltip
                            index={i}
                            classes={"ellipsis-tooltip"}
                            title={`${spec.key}`}
                            innerClasses={"item-label"}
                        >
                        <span>
                            {spec.key}
                        </span>
                        </EllipsisTooltip>

                        <div className="item-content">
                            {buildScheduleBlock}
                        </div>
                    </div>
                markup.push(schedule)
            }

        })

        markup = markup.length ?
        <div className="box-item">
            <div className='box-heading'>BUILD SCHEDULE</div>
            <div className='box-holder diff-build-schedule-section'
                onMouseOver={(e) => this.activeDeActiveDiffSection(e, `.diff-build-schedule-section`, "add")}
                onMouseLeave={(e) => this.activeDeActiveDiffSection(e, `.diff-build-schedule-section`, "remove")}
            >
                {markup}
            </div>
        </div> : null
        return {buildScheduleChangesCount: changesCount, buildScheduleMarkup: markup}
    }

    compareCategoryAndSpecs()
    {
        let changesCount = 0
        let {sourceRev, targetRev, massPrecision} = this.props
        let specSourceArr = []
        let specTargetArr = []
        for (let spec of sourceRev.specs)
        {
            specSourceArr.push(spec)
        }
        for (let spec of targetRev.specs)
        {
            specTargetArr.push(spec)
        }
        if(sourceRev.mass) specSourceArr.push({key: 'MASS', value: Utils.getRoundedMass(sourceRev.mass, massPrecision)})
        specSourceArr.push({key: 'PROCUREMENT', value: sourceRev.procurement})
        if(targetRev.mass) specTargetArr.push({key: 'MASS', value: Utils.getRoundedMass(targetRev.mass, massPrecision)})
        specTargetArr.push({key: 'PROCUREMENT', value: targetRev.procurement})
        specSourceArr.push({key: 'UNIT OF MEASURE', value: sourceRev.unitOfMeasure})
        specTargetArr.push({key: 'UNIT OF MEASURE', value: targetRev.unitOfMeasure})
        let markup = []
        let categoryBlock = null

        let catDiff = jsdiff.diffLines(targetRev.category, sourceRev.category);
        if (catDiff.length > 1)
        {
            changesCount++
            let removedCategory = null
            let updatedCategory = null

            catDiff.forEach((part) => {
                if (part.removed)
                {
                    removedCategory = part.value
                }
                else if (part.added)
                {
                    updatedCategory = part.value
                }
            })

            categoryBlock  =
                <div>
                    <span className={`tag-item remove`}>
                        {removedCategory}
                    </span>
                    <span className={`tag-item add`}>
                        {updatedCategory}
                    </span>
                </div>


            let categoryDiffBlock =
                <div className={`item-list diff-category-section`}>
                    <EllipsisTooltip
                        classes={"ellipsis-tooltip"}
                        title={"Category"}
                        innerClasses={"item-label"}
                    >
                    <span>
                        Category
                    </span>
                    </EllipsisTooltip>

                    <div className="item-content">
                        {categoryBlock}
                    </div>
                </div>
            markup.push(categoryDiffBlock)
        }

        let sourceAndTargetCategoryAndSpecs = []
        let sourceAndTargetCategoryAndSpecsKeys = []

        for(let i = 0; i < specSourceArr.length; i++) {
            if (!sourceAndTargetCategoryAndSpecsKeys.includes(specSourceArr[i].key))
            {
                sourceAndTargetCategoryAndSpecs.push(specSourceArr[i])
                sourceAndTargetCategoryAndSpecsKeys.push(specSourceArr[i].key)
            }
        }

        for(let i = 0; i < specTargetArr.length; i++) {
            if (!sourceAndTargetCategoryAndSpecsKeys.includes(specTargetArr[i].key))
            {
                sourceAndTargetCategoryAndSpecs.push(specTargetArr[i])
                sourceAndTargetCategoryAndSpecsKeys.push(specTargetArr[i].key)
            }
        }

        sourceAndTargetCategoryAndSpecs.forEach((spec, i) =>
        {
            let isChange = false
            let isPresentInTargetRev = false
            let isPresentInSourceRev = false
            let specBlock = null

            isPresentInTargetRev = this.getMatchedSpec(specTargetArr, spec.key).found
            isPresentInSourceRev = this.getMatchedSpec(specSourceArr, spec.key).found

            if (!isPresentInSourceRev && isPresentInTargetRev)
            {
                isChange = true
                changesCount++
                specBlock  = spec.value ?
                    <span className={`tag-item remove`}>
                        {spec.value}
                    </span> : null

            }
            else if (isPresentInSourceRev && !isPresentInTargetRev)
            {
                isChange = true
                changesCount++
                specBlock  = spec.value ?
                <span className={`tag-item add`}>
                    {spec.value}
                </span> : null
            }

            else if (isPresentInSourceRev && isPresentInTargetRev)
            {
                let sourceRevSchedule = this.getMatchedSpec(specSourceArr, spec.key).spec
                let targetRevSchedule = this.getMatchedSpec(specTargetArr, spec.key).spec

                if(!sourceRevSchedule.value) sourceRevSchedule.value = '';
                if(!targetRevSchedule.value) targetRevSchedule.value = '';

                let diff = jsdiff.diffLines(targetRevSchedule.value, sourceRevSchedule.value);
                let specRemovedBlock = null
                let specUpdatedBlock = null
                diff.forEach((part) => {
                    if (part.removed)
                    {
                        isChange = true
                        specRemovedBlock =
                            <span className={`tag-item remove`}>
                                {part.value}
                            </span>
                    }
                    else if (part.added)
                    {
                        isChange = true
                        specUpdatedBlock =
                            <span className={`tag-item add`}>
                                {part.value}
                            </span>
                    }
                })
                specBlock  =
                    <div>
                        {specRemovedBlock && specRemovedBlock}
                        {specUpdatedBlock && specUpdatedBlock}
                    </div>

                if (isChange) changesCount++
            }

            if (isChange)
            {
                let specMainBlock =
                    <div className={`item-list diff-${spec.key.replace(/[^a-zA-Z ]/g, "").split(' ').join('-').toLowerCase()}-section`}
                        key={`spec-${i}`}
                    >
                        <EllipsisTooltip
                            index={i}
                            classes={"ellipsis-tooltip"}
                            title={`${spec.key}`}
                            innerClasses={"item-label"}
                        >
                        <span>
                            {spec.key}
                        </span>
                        </EllipsisTooltip>

                        <div className="item-content">
                            {specBlock}
                        </div>
                    </div>
                markup.push(specMainBlock)
            }
        })

        markup = markup.length ?
        <div className="box-item">
            <div className='box-heading'>SPECS</div>
            <div className='box-holder diff-specs-section'
                onMouseOver={(e) => this.activeDeActiveDiffSection(e, `.diff-specs-section`, "add")}
                onMouseLeave={(e) => this.activeDeActiveDiffSection(e, `.diff-specs-section`, "remove")}
            >
                {markup}
            </div>
        </div> : null
        return {categoryAndSpecsChangesCount: changesCount, categoryAndSpecsMarkup: markup}
    }

    diffToolLink()
    {
        let {sourceRev, targetRev, parent} = this.props
        let sourceRevId = `${sourceRev._id}?include=images,documents,assemblyRevision`
        let targetRevId = `${targetRev._id}?include=images,documents,assemblyRevision`
        let pathname    = ''
        if(this.props.alias === 'prd')
        {
            pathname = `/product/diff/${parent}?sourceRevId=${sourceRevId}&targetRevId=${targetRevId}`;
        }
        else if(this.props.alias === 'cmp')
        {
            pathname = `/component/diff/${parent}?sourceRevId=${sourceRevId}&targetRevId=${targetRevId}`;
        }
        return pathname
    }

    onMouseDown(e)
    {
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', this.onMouseLeave);
        e.stopPropagation()
        e.preventDefault()
    }

    onMouseMove(e)
    {
        let position = document.body.offsetWidth - e.pageX
        let state = this.state;
        if (position < 500)
        {
            position = position > 419 ? 420 : position;
            if (state.sideBar.width !== e.pageX){
                state.sideBar.class    = ""
                state.sideBar.width    = position
                state.sideBar.minWidth = position
                this.setState(state);
            }
            e.stopPropagation()
            e.preventDefault()
        }
    }

    onMouseLeave(e)
    {
        let state = this.state;
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseLeave);
        e.preventDefault()
        e.stopPropagation()
        e.preventDefault()
        state.sideBar.minWidth = state.sideBar.width >= 310 ? state.maxWidth : state.minWidth;
        this.setState(state);
    }


    render()
    {
        let changesCount = this.state.changesCount + this.state.assemblyChangesCount
        let to           = `/${this.props.alias === "cmp" ? "component" : "product"}/view/${this.props.parent}`
        let detailsLink  = this.diffToolLink()
        let sideBar  = this.state.sideBar
        let markup =
                <div className="open right-search-menu diff-tool-sidebar"
                ref={ (sideBarNode) => this.sideBarNode = sideBarNode}
                style={{width: `${sideBar.width}px`, minWidth: `${sideBar.minWidth}px`}}>
                {
                    this.props.mode === 'co' &&
                    <div className="diff-right-sidebar-expand" onMouseDown={(e) => this.onMouseDown(e)}
                    >
                        <div className="border"/>
                    </div>
                }

                    <PerfectScrollbar className="diff-side-bar-scroll">
                    {
                        this.props.mode === "co" &&
                        <div className="co-mode-header">
                            <div className="details-btn-holder">
                                <Link to={detailsLink} target="_blank" className="details-btn" >
                                    <span className="rev-icon-holder">
                                        <InlineIcon><CompareRevIcon/></InlineIcon>
                                    </span>
                                    OPEN FULL DETAILS
                                </Link>
                            </div>
                            <div className="cross-icon-holder">
                                <Icon src={closeSrc}
                                onClick={(e) => this.props.closeDiffToolRightSideBar(e)}
                                />
                            </div>
                        </div>
                    }
                    <div id="right-list-content">
                        <div className="header-block">
                            <h2>Summary of Changes
                               <Tooltip overlay="Help" placement="top" overlayClassName={"help-container default-styles"}>
                                   <div className="help-icon" data-tip="Help" data-for="revision-history-tooltip">
                                        <Link
                                            to={{ pathname: "https://duro.zendesk.com/hc/en-us/articles/360050079871" }}
                                            target="_blank">
                                            <InlineIcon className="">
                                                <HelpIcon/>
                                            </InlineIcon>
                                        </Link>
                                    </div>
                                </Tooltip>
                            </h2>
                            {
                                this.props.mode === "co" &&
                                <div className="name-header-inner">
                                    <Link to={to} target="_blank" className="open-link-holder" >
                                        <span className='cpn-holder'>
                                            <CpnField item={this.props.sourceRev}/>
                                            <InlineIcon className="link-icon"><LinkIcon/></InlineIcon>
                                        </span>
                                    </Link>
                                    {this.props.sourceRev.name}
                                </div>
                            }
                            <div className="header-inner">
                                Total Changes:
                                {this.props.showAssemblyLoading ?
                                    <Spinner iconClassName="where-used-modal-spinner"/>:<span
                                    className="total-changes">{changesCount}</span>
                                }
                            </div>
                        </div>
                        {this.state.compareName}
                        {this.state.compareEid}
                        {this.state.compareStatus}
                        {this.state.compareRevision}
                        {this.state.compareDescription}
                        {this.props.alias === "prd" && this.state.comparePrdouctTeam}
                        {this.props.alias === "prd" && this.state.compareBuildSchedule}
                        {this.props.alias === "cmp" && this.state.compareCategoryAndSpecs}
                        {this.props.alias === "cmp" && this.state.compareCpn}
                        {this.state.comparePrimarySource}
                        {this.state.compareDocuments}
                        {this.state.compareSoucrces}
                        {this.state.compareAssembly}
                    </div>
                    </PerfectScrollbar>
                </div>
        return markup
    }
}

export default DiffToolRightSideBar


