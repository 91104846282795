import { SvgIcon as MuiSvgIcon, styled } from "@mui/material";

export const DuroFullIcon = () => (
  <SvgIcon width="74.323" height="32" viewBox="0 0 74.323 32">
    {/* Intentional fill property is added on the duroLogo */}
    {/* eslint-disable-next-line max-len */}
    <path d="M10.706 30.937h7.067V0h-7.067v30.937zM7.109 23.03c0 1.549 1.004 1.916 1.798 1.916h.544v5.502c-.669.449-1.673.856-3.053.856C2.634 31.304 0 29.307 0 23.356v-7.622c0-5.951 2.634-7.949 6.398-7.949 1.422 0 2.384.408 3.053.897v5.462h-.544c-.794 0-1.798.367-1.798 1.916v6.97zM32.517 8.027h7.068V22.27c0 5.575-3.346 9.034-8.866 9.034h-.585V25.16c.167.04.376.04.585.04.753 0 1.798-.367 1.798-1.912V8.027zm-10.705 0h7.067V31.14c-4.558-.65-7.067-3.906-7.067-8.871V8.027zM54.934 8.109v6.475h-4.08V8.928c.653-.491 1.632-.901 2.978-.901.368 0 .735.041 1.102.082zM42.816 31.304h6.855V8.273h-6.855v23.031zM64.872 14.276v-6.25h.543c5.562 0 8.908 3.584 8.908 9.125v5.708c0 5.04-2.635 8.415-7.068 9.082V16.234c0-1.583-1.003-1.958-1.84-1.958h-.543zm.543 17.831c-5.645 0-8.865-3.54-8.865-9.248V17.15c0-4.916 2.55-8.29 7.067-9V23.9c0 1.583 1.004 1.958 1.798 1.958h.586v6.249h-.586z" fill="#3CD1B6" />,
  </SvgIcon>
);

const SvgIcon = styled(MuiSvgIcon)({
  width: "auto",
  height: "auto",
});
