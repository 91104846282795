import INVITE                               from "../../action-types/invite"
import initialState                         from "./initial_state"
import { handleActions }                    from "redux-actions"

function setInitialState(state=initialState, { payload })
{
    state.inputs.token.value = payload.token.value
    return {...state}
}

function updateLoadedState(state=initialState, { payload })
{
    return { ...state, ...payload }
}

function updateInputsState(state=initialState, { payload })
{
    state.inputs = payload
    return { ...state }
}

export default handleActions({
    [INVITE.SET_INITIAL_STATE]: setInitialState,
    [INVITE.UPDATE_LOADED_STATE]: updateLoadedState,
    [INVITE.UPDATE_INPUTS_STATE]: updateInputsState,

}, initialState);
