import React from "react";

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='16'
      viewBox='0 0 20 16'
      className='vendor-label-icon'
    >
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g
          fill='#FFF'
          fillRule='nonzero'
          stroke='#04ADC3'
          strokeWidth='0.75'
          transform='translate(-321 -79)'
        >
          <path d='M322 89.943c0 .624.445 1.114 1.011 1.114h2.346v1.828c0 .625.445 1.115 1.012 1.115h2.184c3.155 0 5.784-2.541 6.229-5.885h4.207c.566 0 1.011-.49 1.011-1.115 0-.624-.445-1.115-1.011-1.115h-4.167c-.485-3.344-3.074-5.885-6.229-5.885h-2.224c-.567 0-1.012.49-1.012 1.115v1.828h-2.346c-.566 0-1.011.49-1.011 1.114 0 .625.445 1.115 1.011 1.115h2.346v3.656h-2.346c-.566 0-1.011.49-1.011 1.115zm5.299-7.758h1.213c2.387 0 4.328 2.14 4.328 4.77 0 2.631-1.941 4.771-4.328 4.771H327.3v-9.541z'></path>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
