import { FC, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";
import { PrimaryVariantType } from "design/components/fields";
import { PageItemType } from "design/constants";
import { useCanUserViewVariants } from "design/hooks";
import VariantModal from "v1/components/page/common/view-action-items/variant-modal";
import Utils from "v1/modules/utils";

interface WrappedVariantModalProps {
  id?: string;
  onClose: () => void;
  open: boolean;
  pageItemType: PageItemType;
  primaryVariant?: PrimaryVariantType;
}

const aliasValue: { [key in PageItemType]?: string } = {
  component: "cmp",
  product: "prd",
};

export const VariantModalWrapper: FC<WrappedVariantModalProps> = (
  { id, onClose, open, pageItemType, primaryVariant },
) => {
  const { item } = primaryVariant ?? {};
  const history = useHistory();
  const canViewVariants = useCanUserViewVariants();

  const objectData = useMemo(() => (
    item && {
      ...item,
      fixedPrimaryByVendor: Utils.isVendorCmp(Utils.getVendor(item)),
      parent: { _id: id ?? item.id, alias: aliasValue[pageItemType] },
      alias: aliasValue[pageItemType],
    }
  ), [item, id, pageItemType]);

  return (
    <>
      {open && objectData && canViewVariants && (
        <Box className="variant-table-holder">
          <VariantModal
            closeVariantModal={onClose}
            fromLibraryTable={true}
            getDataFromApi={true}
            history={history}
            objectData={objectData}
            openVariantModal={open}
          />
        </Box>
      )}
    </>
  );
};
