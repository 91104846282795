import { Box, CircularProgress, Typography, styled } from "@mui/material";
import { TileContentDirection } from "./consts";
import { TileContentLabel, TileContentWrapper } from "./tileContent";

export interface TileContentDialProps {
  label?: string;
  legendOther?: string;
  legendPercent?: string;
  percent?: number;
}

const DialWrapper = styled(Box)(() => ({
  alignItems: "center",
  display: "flex",
  height: "5rem",
  justifyContent: "center",
  position: "relative",
  width: "5rem",
}));

const DialFront = styled(CircularProgress)(() => ({
  left: 2,
  position: "absolute",
  top: 2,
  transform: "rotate(90deg) !important",
}));

const DialBack = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.grey[400],
  left: 0,
  position: "absolute",
  top: 0,
}));

const LegendRow = styled(Box)(() => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
}));

const LegendColor = styled(Box)<{ layer: "front" | "back" }>(({ layer, theme }) => ({
  backgroundColor: layer === "front" ? theme.palette.primary.main : theme.palette.grey[400],
  borderRadius: "50%",
  height: "1rem",
  marginRight: "0.25rem",
  width: "1rem",
}));

export function TileContentDial(props: TileContentDialProps) {
  const { label, legendOther, legendPercent, percent } = props;
  return (
    <TileContentWrapper>
      <DialWrapper>
        <DialBack size="5rem" thickness={10} value={100} variant="determinate" />
        <DialFront color="primary" size="4.75rem" thickness={8} value={percent} variant="determinate" />
        <Typography>{percent}%</Typography>
      </DialWrapper>
      <TileContentWrapper direction={TileContentDirection.COLUMN}>
        <TileContentLabel label={label} />
        <LegendRow><LegendColor layer="back" />{legendOther}</LegendRow>
        <LegendRow><LegendColor layer="front" />{legendPercent}</LegendRow>
      </TileContentWrapper>
    </TileContentWrapper>
  );
}
