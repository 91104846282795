import mapActions from '../helpers/mapActions';

export default mapActions(
  [
    'SET_COMPANY_STATE',
    'GET_CURRENT_COMPANY',
    'UPDATE_FORM_STATE',
    'SUBMIT_UPDATE_FORM',
    'UPDATE_INPUT_ON_CHANGE',
    'VALIDATE_COMPANY_FORM',
    'SET_API_ERRORS'
  ],
  'COMPANY'
);
