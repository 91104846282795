import { FC, useCallback } from "react";
import { Grid, styled, Box, Typography } from "@mui/material";
import { HelpIcon } from "assets/icons/helpIcon";
import { Switch } from "common/components/inputs";
import { colorPalette } from "theme";
import { Effectivity } from "./effectivity";
import { ItemType } from "./itemType";
import { useChangeOrderFormContext } from "../../changeOrderContextProvider";

const switchLabelStyles = {
  alignItems: "center",
  color: colorPalette.silver,
  display: "flex",
  fontSize: "0.8rem",
  width: "8.5rem",
};

export const ERPEdit: FC = () => {
  const {
    erpToggle,
    setErpToggle,
  } = useChangeOrderFormContext();
  const onChangeErpToggle = useCallback(() => setErpToggle(!erpToggle), [erpToggle, setErpToggle]);

  return (
    <Grid item xs={5}>
      <ERPEnable>
        <Switch
          component={<IconWrapper ><HelpIcon /></IconWrapper>}
          defaultValue={erpToggle}
          label={"Add ERP Options:"}
          labelPosition={"start"}
          labelStyle={switchLabelStyles}
          onChange={onChangeErpToggle}
        />
      </ERPEnable>
      {erpToggle && (
        <Box>
          <Effectivity />
          <ItemType />
        </Box>
      )
      }
    </Grid>
  );
};

const IconWrapper = styled(Typography)({
  margin: "0.9rem 0 0 0.5rem",
});

const ERPEnable = styled(Box)({
  margin: "0 0 1rem 0",
  width: "3rem",
});
