import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="#fffff"
        d="M24 12v-.006a8.654 8.654 0 10-12.793 7.602l.045.023H-.001v1.029H15.86c4.552-.276 8.139-4.035 8.139-8.632V12v.001zm-1.056-.514h-7.083V4.409c3.805.251 6.829 3.273 7.082 7.054l.001.023zM9.963 6.61a7.568 7.568 0 014.852-2.207l.023-.001v7.083H7.755A7.58 7.58 0 019.963 6.61zm-2.208 5.904h7.077v7.077c-3.802-.254-6.822-3.275-7.075-7.054l-.001-.023zm8.106 7.077v-7.077h7.083c-.255 3.804-3.279 6.826-7.061 7.075l-.023.001z"
      ></path>
    </svg>
  );
}

export default Icon;
