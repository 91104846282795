import { combineReducers }  from 'redux';
import viewPage             from "./view";
import editPage             from "./edit";
import revisionPage         from "./revision";

const componentReducer = combineReducers({
    viewPage,
    editPage,
    revisionPage
});

export default componentReducer;
