import CloseIcon from "@mui/icons-material/Close";
import { Box, Icon, IconButton, styled } from "@mui/material";
import { ComponentType, MouseEventHandler, ReactNode, useMemo } from "react";

export interface RightSideBarProps {
  children?: ReactNode | undefined
  contentClassName?: string;
  onClose?: MouseEventHandler<HTMLButtonElement>;
  panelClassName?: string;
  title?: string;
  TitleIcon?: ComponentType,
  visible: boolean;
  width?: string;
}

export function RightSidePanel(props: RightSideBarProps) {
  const {
    children,
    contentClassName,
    onClose,
    panelClassName,
    title,
    TitleIcon,
    visible,
    width,
  } = props;

  const pageTitle = useMemo(() => {
    if (!title && !onClose) return null;

    return (
      <TitleWrapper>
        <Box>
          {TitleIcon && <PaddedIcon><TitleIcon /></PaddedIcon>}
          {title}
        </Box>
        {onClose && (
          <IconButton onClick={onClose}><CloseIcon /></IconButton>
        )}
      </TitleWrapper>
    );
  }, [onClose, title, TitleIcon]);

  return (
    <>
      {visible && (
        <PanelWrapper className={panelClassName} width={width} >
          {pageTitle}
          <ContentWrapper className={contentClassName} >
            {children}
          </ContentWrapper>
        </PanelWrapper>
      )}
    </>
  );
}

const DEFAULT_WIDTH = "24rem";

const PanelWrapper = styled(Box, {
  shouldForwardProp: name => name !== "width",
})(({ theme, width }) => ({
  backgroundColor: theme.duro.rightSideBar.backgroundColor,
  borderTopWidth: 0,
  display: "flex",
  flexDirection: "column",
  flexShrink: 0,
  overflow: "hidden",
  width: `${width ?? DEFAULT_WIDTH}`,
}));

const ContentWrapper = styled(Box)(() => ({
  flex: 1,
  overflow: "hidden",
  padding: "0 1rem 1rem",
}));

const TitleWrapper = styled(Box)(() => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  flexShrink: 0,
  fontSize: "1.25rem",
  justifyContent: "space-between",
  padding: "0.5rem 0 0 1rem",
}));

const PaddedIcon = styled(Icon)(() => ({
  marginRight: "0.5rem",
}));
