// TODO: refactor using regex
// TODO: validate keys in context of type
// TODO: create list of valid keys

import Utils from "../utils"

class Query
{
    static parse(value, defaultType)
    {
        let obj = {}

        // unencode in case a url query was passed
        value = unescape(value)

        // remove extra spaces
        value = Utils.toParagraphString(value).trim()

        // replace spaces next to colons
        value = value.replace(/ :/g, ":")
        value = value.replace(/: /g, ":")

        let parts = value.split(":")
        let typeValue = ''
        let searchParts = parts

        if (parts.length === 2 && parts.includes("type"))
        {
            let typePartIndex  = parts.indexOf('type')
            let valuePart = typePartIndex + 1


            let spacedArrayValues = parts[valuePart].split(" ")
            typeValue = spacedArrayValues[0]
            value = spacedArrayValues.splice(1).join(" ")
            parts = value.split(":")
        }

        // a generic search term was passed
        if(parts.length === 1)
        {
            obj = Query.model(typeValue || defaultType, value)
            obj = Query.validateValues(obj)
            obj.empty = "true"
            return obj
        }

        parts.forEach((part, i) =>
        {
            parts[i] = part.replace(/"/g, "")
        })

        // get keys and values
        parts.forEach((part, i) =>
        {
            parts[i] = part.replace(/"/g, "")
        })

        parts.forEach((part, i) =>
        {
            if(i < 1)
            {
                return
            }

            let prev = parts[i - 1]
            let next = parts[i + 1]

            let sub = prev.replace(/"/g, "`")
            sub     = sub.split("`").filter(Boolean)
            let a   = typeof sub !== "undefined" ? sub.pop().split(" ") : prev.split(" ")
            let key = a[a.length - 1]

            if(!key)
            {
                return
            }

            key = key.toLowerCase().trim()

            if(typeof next !== "string")
            {
                Query.refineQuery(obj, key, part)
                Query.validateType(obj)
                return
            }

            if(!part.match(" "))
            {
                return
            }

            sub     = part.replace(/"/g, "`")
            sub     = sub.split("`").filter(Boolean)
            let b   = sub.length > 1 ? sub[0].trim() : part.split(" ")
            let val = b

            if(b instanceof Array)
            {
                b.pop()
                val = b.join(" ")
            }

            Query.refineQuery(obj, key, val)
        })

        // if only type was passed check for a global search term
        let keys = Object.keys(obj)

        if(keys.length === 1 && keys[0] === "type")
        {
            let term = value.split("type:" + obj.type)[1]

            if(typeof term === "string")
            {
                term = term.trim()
            }
            else
            {
                term = ""
            }

            obj = Query.model(obj.type, term)
            obj = Query.validateValues(obj)
            obj.empty = "true"
            return obj
        }

        obj = Query.validateValues(obj)
        Query.validateType(obj)
        return obj
    }

    static refineQuery(obj, key, value)
    {
        if(obj.hasOwnProperty(key))
        {
            if(!(obj[key] instanceof Array))
            {
                obj[key] = [obj[key]]
            }
            obj[key].push(value)
        }
        else
        {
            obj[key] = value
        }

        return obj
    }

    static stringify(obj, esc)
    {
        let str = ""

        if(typeof obj !== "object")
        {
            return str
        }

        Object.keys(obj).forEach((key) =>
        {
            let val = obj[key]
            if(val instanceof Array)
            {
                val.forEach(function(item)
                {
                    str += key + ":" + item + " "
                })
            }
            else
            {
                if(typeof val !== "string")
                {
                    return
                }

                str += key + ":" + val + " "
            }

        })
        str = Utils.toParagraphString(str).trim()

        if(esc)
        {
            str = escape(str)
        }

        return str
    }

    static validateValues(obj)
    {
        for(var key in obj)
        {
            let value = obj[key]

            if(key === "type")
            {
                continue
            }

            if(value === "" || value === undefined || value === null)
            {
                delete obj[key]
            }
        }

        return obj
    }

    static model(type, val)
    {
        // non-keyed search should default to "search everywhere"
        const value = `*${val}*`
        let defType =
        {
            "component"   : "cmp",
            "changeorder" : "co",
            "product"     : "prd",
            "release"     : "rel"
        }

        if(defType.hasOwnProperty(type))
        {
            type = defType[type]
        }

        if(type !== "cmp" && type !== "co" && type !== "prd" && type !== "rel")
        {
            type = "all"
        }

        let obj =
        {
            type        : type,
            name        : value,
            description : value,
            status      : value
        }

        switch(type)
        {
            case "cmp" :
            {
                obj.cpn             = value
                obj.mfr             = value
                obj.mpn             = value
                obj.dist            = value
                obj.dpn             = value
                obj.category        = value
                obj.revision        = value
                obj.eid             = value
                break
            }
            case "prd" :
            {
                obj.cpn             = value
                obj.mfr             = value
                obj.mpn             = value
                obj.dist            = value
                obj.dpn             = value
                obj.category        = value
                obj.revision        = value
                obj.eid             = value
                break
            }

            case "co" :
            {
                obj.con             = value
                obj.resolution      = value
                break
            }

            case "rel" :
            {
                obj.releaseNo       = value
                break
            }

            default :
            {
                obj.cpn             = value
                obj.con             = value
                obj.mfr             = value
                obj.mpn             = value
                obj.dist            = value
                obj.dpn             = value
                obj.resolution      = value
                obj.category        = value
                obj.revision        = value
                obj.eid             = value
                break
            }
        }

        return obj
    }

    static validateType(obj)
    {
        let defType =
        {
            "component"   : "cmp",
            "changeorder" : "co",
            "product"     : "prd",
            "release"     : "rel"
        }

        if(obj.hasOwnProperty("type"))
        {
            if(defType.hasOwnProperty(obj.type))
            {
                obj.type = defType[obj.type]
            }
            if(obj.type !== "cmp" && obj.type !== "co" && obj.type !== "prd" && obj.type !== "rel")
            {
                obj.type = "all"
            }
        }
        else
        {
            obj.type = "all"
        }
    }
}


// TODO: remove
window.Query = Query

export default Query
