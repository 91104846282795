import                                                                  "../update-revision-modal/index.css"
import                                                                  "./index.css"
import Tooltip                                                          from 'rc-tooltip'
import React                                                            from "react"
import ModalBox                                                         from "../../../ui/modal-box"
import UpdateStatusModaSrc                                              from "../../../../assets/icons/update-status-icon.svg"
import revsSrc                                                          from "../../../../assets/icons/revs.svg";
import FlagIcon                                                         from "../../../../assets/icons/flag.svg"
import Icon                                                             from "../../../ui/icon"
import ExtendedTable                                                    from "../extended-table"
import Utils                                                            from "../../../../modules/utils"
import InlineIcon                                                       from "../../../ui/icon/inline-icon.js"
import DeleteSrc                                                        from "../../../../assets/icons/cross-icon.js"
import ArrowIcon                                                        from "../../../../assets/icons/co-small-arrow.js"
import Link                                                             from "../../../ui/link"
import AlertIcon                                                        from "../../../../assets/icons/alert-icon.js"
import API                                                              from "../../../../modules/api"
import validations, {validateField, normalizeValue}                     from "../../../../modules/validations"
import LazyInput                                                        from "../../../ui/lazy-input/input.js"
import SubmitBtn                                                        from "../../../ui/spinner-button"
import HelpIcon                                                         from "../../../../assets/icons/help-icon.js"
import Spinner                                                          from "../../../ui/spinner"
import LinkIcon                                                         from "../../../../assets/icons/open-link-icon.js"
import BulkUpdateInfoIcon                                               from "../../../../assets/icons/bulk-update-info-icon.js"
import WarningTriangle                                                  from "../../../../assets/icons/warning-triangle.js"
import Schemas                                                          from "../../../../modules/schemas"
import Status                                                           from "../../changeorder/modules/list/status.js";
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import RevisionField    from "../revision-field";
import StatusField      from "../status-field";
import CpnField         from "../cpn-field";

export class UpdateStatusModal extends React.Component
{
    constructor(props, context)
    {
        super(props, context)

        let viewStyles;
        let parentViewStyles;
        let isRevisionTypeDefault = Utils.isRevisionTypeDefault()
        let revisionColumnMinWidth = isRevisionTypeDefault ? 120 : 165

        try
        {
            viewStyles = window.__userStyles.styles.revisionTable || {}
            parentViewStyles = window.__userStyles.styles.parentRevisionTable || {}
        }
        catch(error) {
            viewStyles = {}
        }

        this.revisionScheme = isRevisionTypeDefault ? "default-rev-scheme" : "custom-rev-scheme"
        this.state =
        {
            bulkRevisionForChild : { value: '', class:'', message: '', valid: true},
            syncTable: false,
            syncParentTable: false,
            headings :
            [
                {
                    key         : "cpn",
                    displayName : "CPN",
                    tooltip     : "Customer Part Number",
                    sortable    : true,
                    minWidth    : 109,
                    width       : Utils.getStyleValue(viewStyles, "cpn", "width", 109),
                    position    : Utils.getStyleValue(viewStyles, "cpn", "position", 0),
                    visibility  : Utils.getStyleValue(viewStyles, "cpn", "visibility", true),
                    disabled    : true
                },
                {
                    key         : "name",
                    displayName : "Name",
                    tooltip     : "Name",
                    sortable    : true,
                    minWidth    : 302,
                    width       : Utils.getStyleValue(viewStyles, "name", "width", 302),
                    position    : Utils.getStyleValue(viewStyles, "name", "position", 1),
                    visibility  : Utils.getStyleValue(viewStyles, "name", "visibility", true),
                    disabled    : true
                },
                {
                    key         : "status",
                    displayName : "Status",
                    tooltip     : "Status",
                    sortable    : true,
                    minWidth    : 275,
                    width       : Utils.getStyleValue(viewStyles, "status", "width", 275),
                    position    : Utils.getStyleValue(viewStyles, "status", "position", 2),
                    visibility  : Utils.getStyleValue(viewStyles, "status", "visibility", true),
                    disabled    : true,
                    headingClass: 'status-heading'
                },
                {
                    key         : "revision",
                    displayName : "Revision",
                    tooltip     : "Revision",
                    sortable    : true,
                    minWidth    : revisionColumnMinWidth,
                    width       : Utils.getStyleValue(viewStyles, "revision", "width", revisionColumnMinWidth),
                    position    : Utils.getStyleValue(viewStyles, "revision", "position", 3),
                    visibility  : Utils.getStyleValue(viewStyles, "revision", "visibility", true),
                    disabled    : true
                },
                {
                    key         : "description",
                    displayName : "Description",
                    tooltip     : "Description",
                    sortable    : true,
                    minWidth    : 124,
                    width       : Utils.getStyleValue(viewStyles, "description", "width", 320),
                    position    : Utils.getStyleValue(viewStyles, "description", "position", 4),
                    visibility  : Utils.getStyleValue(viewStyles, "description", "visibility", true),
                    disabled    : false
                },

            ],
            parentHeadings :
            [
                {
                    key         : "cpn",
                    displayName : "CPN",
                    tooltip     : "Customer Part Number",
                    sortable    : true,
                    minWidth    : 109,
                    width       : Utils.getStyleValue(parentViewStyles, "cpn", "width", 109),
                    position    : Utils.getStyleValue(parentViewStyles, "cpn", "position", 0),
                    visibility  : Utils.getStyleValue(parentViewStyles, "cpn", "visibility", true),
                    disabled    : true
                },
                {
                    key         : "name",
                    displayName : "Name",
                    tooltip     : "Name",
                    sortable    : true,
                    minWidth    : 302,
                    width       : Utils.getStyleValue(parentViewStyles, "name", "width", 302),
                    position    : Utils.getStyleValue(parentViewStyles, "name", "position", 1),
                    visibility  : Utils.getStyleValue(parentViewStyles, "name", "visibility", true),
                    disabled    : true
                },
                {
                    key         : "status",
                    displayName : "Status",
                    tooltip     : "Status",
                    sortable    : true,
                    minWidth    : 275,
                    width       : Utils.getStyleValue(parentViewStyles, "status", "width", 275),
                    position    : Utils.getStyleValue(parentViewStyles, "status", "position", 2),
                    visibility  : Utils.getStyleValue(parentViewStyles, "status", "visibility", true),
                    disabled    : true,
                    headingClass: 'status-heading'
                },
                {
                    key         : "revision",
                    displayName : "Revision",
                    tooltip     : "Revision",
                    sortable    : true,
                    minWidth    : revisionColumnMinWidth,
                    width       : Utils.getStyleValue(parentViewStyles, "revision", "width", revisionColumnMinWidth),
                    position    : Utils.getStyleValue(parentViewStyles, "revision", "position", 3),
                    visibility  : Utils.getStyleValue(parentViewStyles, "revision", "visibility", true),
                    disabled    : true
                },
                {
                    key         : "description",
                    displayName : "Description",
                    tooltip     : "Description",
                    sortable    : true,
                    minWidth    : 124,
                    width       : Utils.getStyleValue(parentViewStyles, "description", "width", 308),
                    position    : Utils.getStyleValue(parentViewStyles, "description", "position", 4),
                    visibility  : Utils.getStyleValue(parentViewStyles, "description", "visibility", true),
                    disabled    : false
                }
            ],
            parentRevision          : { value: '', class:'disabled', message: '', valid: true},
            current                 : "cpn",
            currentSortItemAscending: true,
            currentForParent        : "cpn",
            currentSortItemAscendingForParent: true,
            parentStatusValue        : this.props.parentNewStatus || '',
            bulkStatusValueForChild : 'Choose Status',
            list                    : [],
            resetBtn                : { class: 'disabled', text: 'Reset'},
            header                  : { class: '', text: 'Change Status', icon: 'change-status'},
            continueBtn             : { class: '', text: 'Continue', stopLoading: true },
            statusList              : Schemas.component.status.list(),
            bulkRevisionApplyBtn    : { class: 'disabled', text: 'Apply', stopLoading: true },
            innerLoading            : false,
            childRevisionFlag       : false,
        }
        let removeColHeading =
        {
            key         : "remove",
            displayName : "",
            sortable    : false,
            minWidth    : 30,
            width       : 30,
            visibility  : true,
            headingClass: "remove-col",
            dragable    : false,
            expandAble  : false,
            disabled    : true,
            hideFromSettings: true
        }
        this.state.headings.push(removeColHeading)
        this.baseState                  = JSON.parse(JSON.stringify(this.state))
        this.getTableRows               = this.getTableRows.bind(this);
        this.onRevisionInputChange      = this.onRevisionInputChange.bind(this);
        this.createInputs               = this.createInputs.bind(this);
        this.afterSyncTable             = this.afterSyncTable.bind(this);
        this.remove                     = this.remove.bind(this);
        this.updateAll                  = this.updateAll.bind(this);
        this.filterList                 = this.filterList.bind(this);
        this.createDataForUpdate        = this.createDataForUpdate.bind(this);
        this.updateProductStatus        = this.updateProductStatus.bind(this);
        this.resetState                 = this.resetState.bind(this);
        this.submit                     = this.submit.bind(this);
        this.applyBulkRevison           = this.applyBulkRevison.bind(this);
        this.onBulkRevisionChange       = this.onBulkRevisionChange.bind(this);
        this.onSelectAllRows            = this.onSelectAllRows.bind(this);
        this.onRowSelect                = this.onRowSelect.bind(this);
        this.setChildRevisionFlag       = this.setChildRevisionFlag.bind(this);
        this.getParentTableRow          = this.getParentTableRow.bind(this);
        this.afterSyncParentTable       = this.afterSyncParentTable.bind(this);
        this.validateChildInputs        = this.validateChildInputs.bind(this);
        this.validateParentInputs       = this.validateParentInputs.bind(this);
        this.setHeader                  = this.setHeader.bind(this);
        this.onStatusChange             = this.onStatusChange.bind(this);
        this.onParentStatusChange       = this.onParentStatusChange.bind(this);
        this.onBulkStatusChange         = this.onBulkStatusChange.bind(this);
        this.onParentRevisionChange     = this.onParentRevisionChange.bind(this);
        this.afterExtendedTableRender   = this.afterExtendedTableRender.bind(this);
        this.revisionFieldValueOnStatusChange = this.revisionFieldValueOnStatusChange.bind(this);
    }

    setApplyBtnClass(state)
    {
        let disabledClass = ""
        disabledClass = this.isPresentCheckedRow(state.list) && (state.bulkStatusValueForChild.toLowerCase() !== "choose status" && state.bulkRevisionForChild.valid || state.bulkRevisionForChild.value && state.bulkRevisionForChild.valid) ? "" : "disabled"
        return disabledClass
    }

    onBulkStatusChange(value)
    {
        let state = this.state
        state.bulkStatusValueForChild = value

        if (state.bulkStatusValueForChild.toLowerCase() !== "choose status")
        {
            let validationPayload = {
                status: value,
                revSchemeType: window.__revSchemeType,
                libraryType: window.__libraryType,
                isClient: true
            }

            if (state.bulkRevisionForChild.value)
            {
                validateField(state.bulkRevisionForChild, validations.product.revision, state.bulkRevisionForChild.value, validationPayload)
                state.bulkRevisionApplyBtn.class = this.setApplyBtnClass(state)
            }
            else
            {
                state.bulkRevisionForChild.valid = true
                state.bulkRevisionForChild.class = ""
                state.bulkRevisionForChild.message = ""
                state.bulkRevisionApplyBtn.class = this.setApplyBtnClass(state)
            }
        }
        else
        {
            state.bulkRevisionForChild.valid = true
            state.bulkRevisionForChild.class = ""
            state.bulkRevisionForChild.message = ""
            state.bulkRevisionApplyBtn.class = this.setApplyBtnClass(state)
        }

        let _this = this
        setTimeout(function(){
            _this.setState(state);
        }, 100);
    }

    onBulkRevisionChange(event)
    {
        let state = this.state;
        let target = event.target;
        let value = target.value.toUpperCase();

        let validationPayload = {
            status: state.bulkStatusValueForChild,
            revSchemeType: window.__revSchemeType,
            libraryType: window.__libraryType,
            isClient: true,
            defaultBlacklistedRevisions: window.__defaultBlacklistedRevisions
        }

        if (value)
        {
            validateField(state.bulkRevisionForChild, validations.product.revision, value, validationPayload)
            state.bulkRevisionForChild.value = value;
            if (state.bulkRevisionForChild.valid)
            {
                state.bulkRevisionApplyBtn.class = this.setApplyBtnClass(state);
            }
            else
            {
                state.bulkRevisionApplyBtn.class = "disabled"
            }
        }
        else
        {
            state.bulkRevisionForChild.valid = true
            state.bulkRevisionForChild.class = ""
            state.bulkRevisionForChild.value = ""
            state.bulkRevisionForChild.message = ""
            state.bulkRevisionApplyBtn.class = this.setApplyBtnClass(state)
        }
        this.setState(state);
    }

    onParentRevisionChange(event)
    {
        let value = event.target.value
        let state = this.state
        let revisions = this.props.editPage ? this.props.parent.revisions : []
        state.parentRevision.value   = value
        validateField(state.parentRevision, validations.component.revision, value.toUpperCase(), {status: state.parentStatusValue, revisions, revSchemeType: window.__revSchemeType, libraryType: window.__libraryType, isClient: true, defaultBlacklistedRevisions: window.__defaultBlacklistedRevisions})
        state.syncParentTable = true
        this.setHeader(state, false, true);
        this.setState(state);
    }

    onParentStatusChange(value, item)
    {
        let state = this.state
        state.syncParentTable = true;
        state.parentStatusValue = value;

        let currentRevision = this.props.editPage ? this.props.parent.revision : ""
        state.parentRevision.value    = state.list.length > 0 || this.props.editPage ? normalizeValue(validations.component.revision, this.props.parentNewRevision, {isClient: true, status: value, revSchemeType: window.__revSchemeType, libraryType: window.__libraryType, revActionType: null, currentRevision}).revision : ''

        let revisions = this.props.editPage ? this.props.parent.revisions : []
        validateField(state.parentRevision, validations.component.revision, state.parentRevision.value.toUpperCase(), {status: state.parentStatusValue, revisions, revSchemeType: window.__revSchemeType, libraryType: window.__libraryType, isClient: true})
        state.parentRevision.class = ''
        if(!state.parentRevision.valid)
        {
            state.continueBtn.class  = 'disabled'
            state.parentRevision.class = 'invalid'
        }
        else
        {
            state.parentRevision.class = ''
        }
        let _this = this
        setTimeout(function(){
            _this.setState(state);
        }, 100);
    }

    onStatusChange(value, item)
    {
        const list = [...this.state.list];
        item.nextStatus = value; 
        list[item.index] = item;

        const revisions = this.props.editPage ? item.revisions : []
        const validationPayload = {
            isClient: true,
            status: value,
            revSchemeType: window.__revSchemeType,
            libraryType: window.__libraryType,
            currentRevision: item.revision,
            revisions,
            previousRevision: Utils.getPreviousRevision(item),
            revisionBump: false,
        }
        this.revisionFieldValueOnStatusChange(item, validationPayload);
        setTimeout(() => {
            this.setState({list, syncTable: true});
        }, 100);
    }

    revisionFieldValueOnStatusChange(item, validationPayload)
    {
        if(this.props.shouldNotUpdateItemsInDB && !item.modified && item.status === item.nextStatus && item.nextStatus === item.previousStatus)
        {
            Utils.getRevisionBump(item,validationPayload);
            Utils.validateRevisionFieldOnStatusChange(item, validationPayload);
        }
        else if(item.modified && item.previousStatus === item.nextStatus && item.nextStatus !== item.status)
        {
            item.nextCoRevision = item.previousRevision ? item.previousRevision : item.nextCoRevision;
            item.nextRevisionInput.value = item.nextCoRevision;
            Utils.validateRevisionFieldOnStatusChange(item, validationPayload);
        }
        else
            Utils.validateRevisionFieldOnStatusChange(item, validationPayload);

    }

    setHeader(state, errorFound, checkingForErrors=false)
    {
        if(state.list.length === 0)
        {
            state.continueBtn.class = 'disabled'
            state.continueBtn.text  = 'Continue'
            state.header.icon       = 'change-status'
            state.header.text       = 'Change Status'
            state.resetBtn.class    = 'disabled'
            state.header.class      = 'grey'
        }

        if(state.list.length === 0 && this.props.editPage)
        {
            state.continueBtn.class = ''
            state.resetBtn.class    = ''
            state.header.class      = ''
        }

        if (checkingForErrors)
        {
            state.header.icon       = '';
            state.header.class      = 'grey';
            state.header.text       = 'Checking for errors...';
            state.continueBtn.class = "disabled";
            state.resetBtn.class    = "disabled"
        }
        else if(errorFound)
        {
            state.header.icon       = '';
            state.header.class      = 'contain-errors';
            state.header.text       = 'Errors Found';
            state.continueBtn.class = "disabled";
            state.resetBtn.class    = ""

        }
        else
        {
            state.continueBtn.text  = 'Continue';
            state.header.class      = '';
            state.header.icon       = 'change-status';
            state.header.text       = 'Change Status';
            state.continueBtn.class = '';
            state.resetBtn.class    = ""
        }
    }

    afterSyncParentTable()
    {
        let state = this.state;
        state.syncParentTable = false;
        let errorFoundInParent = this.validateParentInputs(state);
        let errorFoundInChild = this.validateChildInputs(state);
        this.setHeader(state, errorFoundInChild || errorFoundInParent);
        this.setState(state);
    }

    getParentTableRow()
    {
        let state = this.state
        let {parent} = this.props;
        let link  = parent.alias === 'cmp' ? `/component/view/${parent._id}` : `/product/edit/${parent._id}`;
        let parentRow =
        {
           "cpn":
            {
                value       : parent.cpn,
                tooltip     : parent.cpn,
                displayValue :
                    <div className="cpn-holder">
                        <Link
                            to={ link }
                            target="_blank"
                            className="link">
                            <CpnField item={parent} cpn={parent.cpn}/>
                        </Link>
                    </div>
            },
            "name":
            {
                value       : parent.name,
                displayValue: parent.name,
                tooltip     : parent.name,
            },
            "status":
            {
                notLink     : true,
                value       : parent.status,
                tooltip     : parent.status,
                displayValue :

                    <span className="status-holder">
                        <StatusField item={parent} status={parent.status}/>
                        <InlineIcon className="arrow">
                            <ArrowIcon/>
                        </InlineIcon>
                        <span className={'field-select'}>
                            <Status
                                onStatusChange={this.onParentStatusChange}
                                className={"select-status"}
                                item={parent}
                                name="parent-status"
                                status={state.parentStatusValue}
                                options={Utils.toOptions(Schemas.component.status.list(parent.modified && parent.previousStatus ? parent.previousStatus : parent.status))}/>
                        </span>
                    </span>
            },
            "revision":
            {
                value       : parent.revision,
                displayValue :
                    <div className="revision-holder">
                        <span className={`revision-label ${this.revisionScheme}`}>
                            <RevisionField item={parent} revision={parent.revision}/>

                        </span>
                        <InlineIcon className="arrow">
                            <ArrowIcon/>
                        </InlineIcon>

                        {
                            <LazyInput
                                className={`${state.parentRevision.class} revision-input ${this.revisionScheme}`}
                                data-tip={state.parentRevision.message}
                                data-place="right"
                                data-type="error"
                                type="text"
                                value={state.parentRevision.value}
                                onChange={(e) => this.onParentRevisionChange(e)}
                                name="bulk-revision-value"
                            />
                        }
                    </div>,
                    cellClass   : "revision-cell",
                    haveInput   : true,
                    notLink     : true
            },
            "description":
            {
                value       : parent.description,
                displayValue : parent.description,
                tooltip     : parent.description,
            },
            rowClassName : '',
            rowLink      : link,
            targetBlank  : true,
            indexTableEl : null
        }
        return [parentRow]
    }

    onSelectAllRows(checked)
    {
        let state = this.state
        let { list } = state;
        list.forEach((item, i) =>
        {
            let isValid = !(!this.props.shouldNotUpdateItemsInDB && item.co) && (Utils.getVendor(item) === "duro" || !this.props.editPage && item.alias === "prd")
            if (isValid)
            {
                item.checked = checked;
            }
        });
        state.bulkRevisionApplyBtn.class = this.setApplyBtnClass(state);
        this.setState(state);
    }

    isPresentCheckedRow(list)
    {
        let isPresentCheckedRow = false
        for (let i=0; i < list.length; i++) {
            let revisionManaged = Utils.checkRevisionManaged(list[i]);
            if (list[i].checked && revisionManaged)
            {
                isPresentCheckedRow = true;
                break;
            }
        }
        return isPresentCheckedRow;
    }

    onRowSelect(event, itemFromExtendedTable)
    {
        let state = this.state
        let { list } = state;
        let checked = event.target.checked;
        list.forEach((item) => {
            if (item._id === itemFromExtendedTable._id)
            {
                item.checked = checked
            }
        })
        state.bulkRevisionApplyBtn.class = this.setApplyBtnClass(state)
        this.setState(state);
    }

    componentDidUpdate() {
        if (this.props.editPage)
        {
            let childTable = document.querySelector("#update-revision-holder .revision-table")
            if (childTable)
            {
                let height = childTable.clientHeight
                let childTableContainer = document.querySelector("#update-revision-holder")
                if (height > 340 && childTableContainer)
                {
                    childTableContainer.classList.add("apply-scrol")
                }
            }
        }

        let el = document.querySelector('#revisionTable [name="remove"]')
        if (el && this.state.list.length === 0)
        {
            el.classList.add('remove-with-no-row-footer');
        }
    }

    componentWillMount()
    {
        if(this.props && this.props.editPage && this.props.parent)
        {
            let id                  =  this.props.parent._id
            let type                =  this.props.parent.alias
            this.state.innerLoading = true
            this.state.parentRevision.class   = ''
            let currentRevision = this.props.parent.revision

            if (type === "prd")
            {
                this.state.parentRevision.value = validations.product.revision.normalize({status: this.state.parentStatusValue, revSchemeType: window.__revSchemeType, libraryType: window.__libraryType, revActionType: null, currentRevision, isClient: true}, this.props.parentNewRevision).revision

                let {revisions} = this.props.parent
                validateField(this.state.parentRevision, validations.product.revision, this.state.parentRevision.value.toUpperCase(), {status: this.state.parentStatusValue, revisions, revSchemeType: window.__revSchemeType, libraryType: window.__libraryType, isClient: true})
            }

            if (type === "cmp")
            {
                this.state.parentRevision.value = normalizeValue(validations.component.revision, this.props.parentNewRevision, {status: this.state.parentStatusValue, revSchemeType: window.__revSchemeType, libraryType: window.__libraryType, revActionType: null, currentRevision, isClient: true}).revision

                let {revisions} = this.props.parent
                validateField(this.state.parentRevision, validations.component.revision, this.state.parentRevision.value.toUpperCase(), {status: this.state.parentStatusValue, revisions, revSchemeType: window.__revSchemeType, libraryType: window.__libraryType, isClient: true})
            }
            this.setState(this.state)

            if(type === 'cmp')
            {
                API.components.getComponentChildrens(id, (err, res) =>
                {
                    if(!res.success)
                    {
                        this.state.list = []
                    }
                    else
                    {
                        this.state.list = res.data
                        this.setState(this.state, this.createInputs())
                    }
                });
            }
            if(type === 'prd')
            {
                API.products.getProductChildrens(id, (err, res) =>
                {
                    if(!res.success)
                    {
                        this.state.list = []
                    }
                    else
                    {
                        this.state.list = res.data
                        this.setState(this.state, this.createInputs())
                    }
                });
            }
        }
        else
        {
            this.state.innerLoading = true
            this.setState(this.state)

            let cmpIdz = []
            let prdIdz = []
            this.props.components.forEach((item) => {
                cmpIdz.push(item._id)
            })

            this.props.products.forEach((item) => {
                prdIdz.push(item._id)
            })

            API.components.getItemsInBulk({components: cmpIdz, products: prdIdz}, (err, res) =>
            {
                if(!res.success)
                {
                    this.state.list = []
                }
                else
                {
                    this.state.list = res.data
                    this.setState(this.state, this.createInputs())

                    let masterCheckbox = document.querySelector('#update-revision-holder .index-table thead .index-checkbox label');
                    if (masterCheckbox)
                    {
                        masterCheckbox.click()
                    }

                }
            });

            // this.createInputs()
        }
    }

    createInputs()
    {
        let state = this.state
        let list  = state.list

        list.map((item) => {
            item.isValid            = true
            item.nextRevisionInput  = { value: "", valid: true, message: "", class: ""}
            item.nextStatus         = item.status
        });
        state.list                = list
        this.baseState.list       = JSON.parse(JSON.stringify(state.list))
        state.innerLoading        = false
        this.setState(state)
    }

    getTableRows()
    {
      let state           = this.state
      let list            = state.list


      if ( !Array.isArray(list) )
      {
         list = []
      }

      let rows = list.map((item, i) =>
          {

            let currentStatus = Utils.getPreviousStatus(item);
            let currentRevision = Utils.getPreviousRevision(item);
            let revisionManaged = Utils.checkRevisionManaged(item);
            let vendor          = Utils.getVendor(item)
            let modifiedClazz = (!this.props.shouldNotUpdateItemsInDB && item.co) || !revisionManaged || (item.alias !== "prd" && vendor !== "duro") ? "not-modified" : ""
            let isValid = !(!this.props.shouldNotUpdateItemsInDB && item.co) && (vendor === "duro" || !this.props.editPage && item.alias === "prd")

            let revValue = "";
            if(item.nextStatus === "DESIGN")
                revValue = item.previousRevision ? item.previousRevision : (item.revision ? item.revision : "—");
            else
                revValue = this.props.shouldNotUpdateItemsInDB ? item.nextCoRevision : (item.previousRevision ? item.previousRevision : item.revision);

            let link  = item.alias === 'cmp' ? `/component/edit/${item._id}`  : `/product/edit/${item._id}`
            let cadPlugin = "Solidworks"

            if (vendor !== "duro" && vendor === "ONSHAPE")
            {
                cadPlugin = "Onshape"
            }
            if(!revisionManaged)
            {
                isValid = false;
            }
            let errorMessageCheck = !isValid && vendor !== "duro" && item.alias === "cmp";
            item.index = i;
            let errorOverlay = <div>
                                    {
                                        errorMessageCheck ? `This Component is managed by ${cadPlugin}. It cannot be edited.` : !revisionManaged ? `This Component is not Revision Managed.`
                                        : !isValid && item.co && item.co.con ?
                                        <span>
                                            <div className="link-text">
                                                <span>Component is already in a open Change Order</span>

                                                <Link
                                                    to={`/changeorder/view/${item.co._id}`}
                                                    target="_blank"
                                                    className="co-link-text">
                                                    {item.co && item.co.con}.
                                                </Link>
                                                <p>Component cannot be edited.</p>
                                            </div>
                                            {
                                                !isValid &&
                                                <Link
                                                    to={`/changeorder/view/${item.co._id}`}
                                                    target="_blank"
                                                    className=""
                                                    >
                                                    <span className="link-text co-text">
                                                        VIEW CHANGE ORDER
                                                    </span>
                                                </Link>
                                            }
                                        </span> : null
                                    }
                                </div>

            let cpn   = Utils.getCpn(item);
            let cells =
            {
               "cpn":
                {
                    value       : cpn,
                    tooltip     : isValid ? cpn : null,
                    cellClass   : modifiedClazz,
                    displayValue :
                        <div className="cpn-holder">
                            <Link
                                to={ link }
                                target="_blank"
                                className="link">
                                <CpnField item={item} cpn={cpn}/>
                            </Link>
                        </div>
                },
                "name":
                {
                    value       : item.name,
                    displayValue: item.name,
                    cellClass   : modifiedClazz,
                    tooltip     : isValid ? item.name : null,
                },
                "description":
                {
                    value       : item.description,
                    displayValue : item.description,
                    cellClass   : modifiedClazz,
                    tooltip     : isValid ? item.description : null,
                },

                "revision":
                {
                    value       : item.revision,
                    cellClass   : `revision-cell`,
                    displayValue :
                    <div className="revision-holder">
                        <span className={`revision-label revision-modified ${this.revisionScheme}`}>
                            <RevisionField item={item} revision={item.revision}/>
                        </span>

                        {
                            isValid &&
                            <InlineIcon className="arrow">
                                <ArrowIcon/>
                            </InlineIcon>
                        }


                        {
                            isValid && Utils.getStatusRank(item.nextStatus) !== Utils.getStatusRank(currentStatus) ?
                                <LazyInput
                                    type="text"
                                    name="childRevision"
                                    value={item.nextRevisionInput.value && item.nextRevisionInput.value ? item.nextRevisionInput.value : item.revision }
                                    data-tip={item.nextRevisionInput && item.nextRevisionInput.message ? item.nextRevisionInput.message : '' }
                                    className={`${(item.nextRevisionInput && item.nextRevisionInput.class ? item.nextRevisionInput.class : '' )} revision-input ${this.revisionScheme}`}
                                    data-place="right"
                                    data-type="error"
                                    onChange={(event) => this.onRevisionInputChange(event, item, i)}
                                />
                            : isValid && item ?
                            <div className={`revision-label text doted-border ${this.revisionScheme}`}>
                                {revValue}
                            </div> : null
                        }
                    </div>,
                    haveInput   : true,
                    notLink     : true
                },
                "status":
                {
                    notLink     : true,
                    value       : item.status,
                    tooltip     : isValid ? item.status : null,
                    cellClass   : `status-cell ${modifiedClazz}`,
                    displayValue :
                        <span className="status-holder">
                            <StatusField item={item} status={item.status}/>

                            {
                                isValid &&
                                <InlineIcon>
                                    <ArrowIcon/>
                                </InlineIcon>
                            }

                            {
                                isValid &&
                                <div>
                                    <span className={'field-select'}>
                                        <Status
                                            enableToolTip={true}
                                            overlayClassName={`simple-rc-tip status-error-tooltip ${(Utils.getStatusRank(item.nextStatus) < Utils.getStatusRank(currentStatus)) ? "" : "hidden"}`}
                                            overlay={
                                                <div>
                                                    <span>{`Must be ${currentStatus} or higher`}</span>
                                                </div>
                                            }
                                            onStatusChange={this.onStatusChange}
                                            className={`select-status ${(Utils.getStatusRank(item.nextStatus) < Utils.getStatusRank(currentStatus)) ? "invalid" : ""}`}
                                            item={item}
                                            name="child-status"
                                            status={item.nextStatus}
                                            options={Utils.toOptions(Schemas.component.status.list((Utils.getStatusRank(item.nextStatus) > Utils.getStatusRank(currentStatus)) ? currentStatus : item.nextStatus))}/>
                                    </span>
                                </div>
                            }
                        </span>
                },

                "remove":
                {
                    notLink      : true,
                    value        : "",
                    cellClass    : "remove-col",
                    displayValue : <div onClick={(event) => this.remove(event, item._id)}>
                                        <InlineIcon
                                            tooltip="Remove"
                                            tooltipPlace="top"
                                            >
                                            <DeleteSrc/>
                                        </InlineIcon>
                                    </div>
                },
                rowLink      : ( link ),
                object       : item,
                rowId        : item._id,
                targetBlank  : true,
                rowSelected  : isValid ? item.checked : false,
                getSelectedInBulk: isValid ? true : false,
                rowToolTip   : false && !isValid ? {
                        overlay          : errorOverlay,
                        mouseEnterDelay: 1,
                        tooltipContainer : ".update-status-screen",
                        overlayClassName : "simple-rc-tip error default-styles update-status-screen-tool-tip"
                    } : null,
                "indexTableEl" : !isValid ?
                            <Tooltip
                              mouseEnterDelay={1}
                              placement={"top"}
                              getTooltipContainer={() => document.querySelector(".update-status-screen")}
                              overlayClassName={"simple-rc-tip error default-styles update-status-screen-tool-tip co-warning-tooltip"}
                              overlay={errorOverlay}
                            >
                            <div>
                                <InlineIcon className="warningEl">
                                    <BulkUpdateInfoIcon/>
                                 </InlineIcon>
                            </div>
                            </Tooltip>
                            : null
            }
            return cells
          })
      return rows
    }

    onRevisionInputChange(event, item=null, i=null)
    {
        let state = this.state
        let target = event.target
        let value = target.value
        item.nextRevisionInput.value = value
        let status = item.nextStatus
        let revisionInput = item.nextRevisionInput

        let currentRevision = item.revision
        let revisions = item.revisions
        let previousRevision = Utils.getPreviousRevision(item);
        let validationPayload = {
            isClient: true,
            status: status,
            revSchemeType: window.__revSchemeType,
            libraryType: window.__libraryType,
            revActionType: null,
            currentRevision,
            revisions,
            previousRevision,
            defaultBlacklistedRevisions: window.__defaultBlacklistedRevisions
        }

        validateField(revisionInput, validations.component.revision, value.toUpperCase(), validationPayload)
        state.syncTable = true
        this.setState(state)
    }

    remove(e, itemId)
    {
        let list = this.state.list
        let state = this.state
        let deletedItemIndex;
        let deletedItem;
        list.forEach((item, i) => {
            if(itemId === item._id)
            {
                deletedItem      = item
                deletedItemIndex = i
            }
        })

        list.splice(deletedItemIndex, 1)
        if(this.baseState && this.baseState.list)
        {
            this.baseState.list.splice(deletedItemIndex, 1)
        }
        state.syncTable = true
        if(state.list.length === 0)
        {
            state.resetBtn.class = 'disabled'
        }

        if(state.list.length === 0 && this.props.editPage)
        {
            state.continueBtn.class = ''
            state.resetBtn.class    = ''
            state.header.class      = ''
        }

        let errorFoundInParent = this.validateParentInputs(state);
        let errorFoundInChild = this.validateChildInputs(state);
        this.setHeader(state, errorFoundInChild || errorFoundInParent);
        this.setState(state)
    }

    afterSyncTable()
    {
        let state = this.state;
        state.syncTable = false;
        let errorFoundInParent = this.validateParentInputs(state);
        let errorFoundInChild = this.validateChildInputs(state);
        state.bulkRevisionApplyBtn.class = this.setApplyBtnClass(state);
        this.setHeader(state, errorFoundInChild || errorFoundInParent);
        this.setState(state);
    }

    createDataForUpdate(list)
    {
        let data = [];
        let state = this.state;
        data = list.map((item) =>
        {
            if(item.checked)
            {
                let currentStatus = Utils.getPreviousStatus(item);
                let revisionManaged = Utils.checkRevisionManaged(item);
                let revValue = item.nextRevisionInput.value ? item.nextRevisionInput.value : item.revision;
                let isStatusChanged = Utils.getStatusRank(item.nextStatus) !== Utils.getStatusRank(currentStatus);
                let isRevisionRevert = !isStatusChanged && item.nextCoRevision === item.previousRevision ? true : false;
                let revisionBumpFromBulkStatus =  this.props.shouldNotUpdateItemsInDB && !isRevisionRevert && !isStatusChanged && item.nextCoRevision !== item.revision ?  true : false;
                let isUpdated = isStatusChanged || revisionBumpFromBulkStatus || isRevisionRevert;
                if(item.nextStatus === "DESIGN" && !item.previousRevision && !revisionBumpFromBulkStatus)
                {
                    isUpdated = true
                    revValue = "";
                }
                else if(isRevisionRevert)
                    revValue = item.previousRevision;
                else if(revisionBumpFromBulkStatus)
                    revValue = item.nextCoRevision;
                let cmpUpdate = Utils.getVendor(item) === "duro" && revisionManaged && !item.co && isUpdated;
                let payloadData =
                {
                    _id: item._id,
                    status: item.nextStatus,
                    revision: revValue,
                    modified: true,
                    nextCoRevision: item.nextCoRevision,
                    skipSources: true,
                    alias: item.alias,
                    lastModified: item.lastModified,
                    rolledUpCostAsPrimary: item.rolledUpCostAsPrimary,
                    skipStatusRevisionValidation: true,
                }
                if (this.props.editPage)
                {
                    if (state.childRevisionFlag && cmpUpdate)
                        return payloadData;
                }
                else
                {
                    if(this.props.shouldNotUpdateItemsInDB)
                        return payloadData;
                    else if(item.alias === "prd")
                    {
                        if(!item.co && isUpdated)
                            return payloadData;
                    }
                    else
                    {
                        if(cmpUpdate)
                            return payloadData;
                    }


                }
            }
        });
        data = data.filter(function( element ) {
            return element !== undefined;
        });

        return data;
    }

    updateAll()
    {
      let state           = this.state
      let filterList      = this.filterList()
      let products        = filterList.products
      let components      = filterList.components
      let cmpData         = this.createDataForUpdate(components)
      let prdData         = this.createDataForUpdate(products)

      if(this.props.shouldNotUpdateItemsInDB) return this.props.refreshAfterBulkUpdate([...prdData, ...cmpData])

      if (cmpData.length === 0 && prdData.length === 0)
      {
        if(this.props.editPage)
        {
            let showErrorModal = true
            if(state.parentStatusValue === 'DESIGN')
            {
                showErrorModal = false
            }
            this.props.refreshAfterBulkUpdate(state.parentRevision.value, state.parentStatusValue, showErrorModal)
        }
        else
        {
            this.props.refreshAfterBulkUpdate(state.list)
        }
      }

      if(cmpData && cmpData.length > 0)
      {
        let payload     = { components: cmpData, responseKeys: ["_id", "cpn", "cpnVariant"] }
        API.components.updateWithData(payload, (err, res) =>
        {
          if(err)
          {

          }
          else
          {

            const componentIds = res.map((item) => item._id);
            API.components.updateParent(componentIds, (res)=> {
                if(res) {
                    if(prdData.length === 0) {
                        if(this.props.editPage)
                        {
                            this.props.refreshAfterBulkUpdate(state.parentRevision.value, state.parentStatusValue, componentIds)
                        }
                        else
                        {
                            this.props.refreshAfterBulkUpdate([...cmpData])
                        }
                    }
                    else {
                        this.updateProductStatus(prdData, cmpData)
                    }
                }
            });
          }
        })
      }

      if(cmpData.length === 0)
      {
        this.updateProductStatus(prdData)
      }
    }

    submit()
    {
        this.state.continueBtn.stopLoading = false
        Utils.resetLocalStorageForAssemblyTree();
        this.setState(this.state, this.updateAll())
    }

    updateProductStatus(prdData=[], cmpData=[])
    {
      let counter       = 0
      let state         = this.state

      if(prdData && prdData.length > 0)
      {
        prdData.forEach((product) => {
          API.products.update(product._id, product, (err, res) =>
          {
            if(err)
            {

            }
            else
            {
              counter++
              if(counter === prdData.length)
              {
                if(this.props.editPage)
                {
                    this.props.refreshAfterBulkUpdate(state.parentRevision.value, state.parentStatusValue)
                }
                else
                {
                    this.props.refreshAfterBulkUpdate([...prdData, ...cmpData])
                }
              }
            }
          })
        });
      }
    }

    filterList()
    {
        let state = this.state
        let list = state.list
        let components = []
        let products = []
        list.forEach((item) =>
        {
            if(item.alias === "cmp")
            {
                components.push(item)
            }
            if(item.alias === "prd")
            {
                products.push(item)
            }
        });
        return { components, products }
    }

    resetState()
    {
        this.state.resetBtn.stopLoading = false
        let parentRevisionValue = this.state.parentRevision.value
        let bulkStatusValueForChild = this.state.bulkStatusValueForChild
        let bulkRevisionForChild = this.state.bulkRevisionForChild

        this.setState(this.state)
        let shouldClickOnMasterCheckbox = this.isPresentCheckedRow(this.state.list) ? true : false
        this.baseState = JSON.parse(JSON.stringify(this.baseState))
        let state      = JSON.parse(JSON.stringify(this.baseState))
        let list       = state.list

        list.map((item) => {
            item.isValid            = true
            item.nextRevisionInput  = { value: "", valid: true, message: "", class: "" }
            item.nextStatus         = item.status
        });
        state.syncTable             = true
        state.list                  = list
        state.continueBtn.stopLoading = true
        state.resetBtn.class        = 'disabled'
        state.resetBtn.stopLoading  = true
        state.parentRevision.value    = parentRevisionValue
        state.bulkStatusValueForChild = bulkStatusValueForChild
        state.bulkRevisionForChild = bulkRevisionForChild
        this.setState(state)

        if (this.props.editPage)
        {
            let el = document.getElementById('update-revision-holder');
            if (el) el.classList.add('disabled-child');
        }

        let masterCheckbox = document.querySelector('#update-revision-holder .index-table thead .index-checkbox label');
        if (shouldClickOnMasterCheckbox && masterCheckbox)
        {
            masterCheckbox.click()
        }
    }

    afterExtendedTableRender()
    {
        var el = document.getElementById('update-revision-holder');
        el.classList.add('mx-width');

        if (el && this.state.childRevisionFlag)
        {
            el.classList.remove('disabled-child');
            el.classList.add('enabled-child');
        }
        else if (this.props.editPage)
        {
            el.classList.add('disabled-child');
            el.classList.remove('enabled-child');
        }
    }

    beforeExtendedTableHandleLoad()
    {
        var el = document.getElementById('update-revision-holder');
        el.classList.remove('mx-width');
    }

    applyBulkRevison()
    {
        let state = this.state
        let {bulkRevisionForChild} = state
        let errorFoundInChild = false
        state.bulkRevisionApplyBtn.class = "disabled"
        state.bulkRevisionApplyBtn.stopLoading = false
        this.setHeader(state, false, true);
        this.setState(state)

        let bulkStatusRank = Utils.getStatusRank(state.bulkStatusValueForChild)
        let _this = this

        //Add some delay to display loading
        setTimeout(function(){

            state.list.forEach((item) =>
            {
                let revisionManaged = Utils.checkRevisionManaged(item);
                let itemStatusRank = Utils.getStatusRank(item.status)
                if (revisionManaged && item.checked && state.bulkStatusValueForChild.toLowerCase() !== "choose status")
                {
                    item.nextStatus = state.bulkStatusValueForChild;
                }
                if(revisionManaged && item.checked)
                {
                    let {revisions} = item;
                    let revisionInput = item.nextRevisionInput;
                    let previousRevision = Utils.getPreviousRevision(item);
                    let validationPayload = {
                        status: item.nextStatus,
                        revisions,
                        revSchemeType: window.__revSchemeType,
                        libraryType: window.__libraryType,
                        isClient: true,
                        previousRevision,
                        defaultBlacklistedRevisions: window.__defaultBlacklistedRevisions
                    }

                    if (bulkRevisionForChild.value)
                    {
                        item.nextRevisionInput.value = bulkRevisionForChild.value;
                        if(item.previousStatus !== item.nextStatus)
                            validateField(revisionInput, validations.component.revision, item.nextRevisionInput.value.toUpperCase(), validationPayload);
                        else
                            _this.revisionFieldValueOnStatusChange(item, validationPayload);
                    }
                    else
                        _this.revisionFieldValueOnStatusChange(item, validationPayload);
                }
            })
            state.bulkRevisionApplyBtn.stopLoading = true
            state.syncTable = true
            _this.setState(state)
        }, 100);
    }

    validateParentInputs(state)
    {
        let errorFound = false;
        if (this.props.editPage && !state.parentRevision.valid && !errorFound)
        {
            errorFound = true;
        }
        return errorFound;
    }

    validateChildInputs(state)
    {
        let errorFound = false;
        let item;
        if (state.list && state.list.length > 0)
        {
            for (let i=0; i < state.list.length; i++) {
                item = state.list[i]
                let nextStatusRank = Utils.getStatusRank(item.nextStatus)
                let currentStatus = Utils.getPreviousStatus(item);
                let currentStatusRank = Utils.getStatusRank(currentStatus);
                let revisionInput = item.nextRevisionInput;
                if (!errorFound && (!revisionInput.valid || !item.isValid || nextStatusRank < currentStatusRank))
                {
                    errorFound = true;
                    break;
                }
            }
        }
        return errorFound;
    }

    setChildRevisionFlag()
    {
        let state = this.state;
        let shouldClickOnMasterCheckbox = false
        state.childRevisionFlag = !state.childRevisionFlag;

        if (state.childRevisionFlag)
        {
            shouldClickOnMasterCheckbox = true
        }
        else
        {
            shouldClickOnMasterCheckbox = this.isPresentCheckedRow(state.list) ? true : false
        }
        let errorFoundInChild = false;
        let errorFoundInParent = false;

        if (state.childRevisionFlag)
        {
            errorFoundInChild = this.validateChildInputs(state);
        }
        errorFoundInParent = this.validateParentInputs(state);
        this.setHeader(state, errorFoundInParent || errorFoundInChild);
        this.setState(state);

        let el = document.getElementById('update-revision-holder');
        if (el && state.childRevisionFlag)
        {
            el.classList.remove('disabled-child');
            el.classList.add('enabled-child');
        }
        else
        {
            el.classList.add('disabled-child');
            el.classList.remove('enabled-child');
        }

        let masterCheckbox = document.querySelector('#update-revision-holder .index-table thead .index-checkbox label');
        if (masterCheckbox && shouldClickOnMasterCheckbox)
        {
            masterCheckbox.click()
        }
    }

    render()
    {
        let headings = this.state.headings
        let parentHeadings = this.state.parentHeadings
        let rows     = this.getTableRows()
        let parentRow = null
        if (this.props.editPage)
        {
            parentRow = this.getParentTableRow();
        }
        let state    = this.state
        let bulkStatus= state.parentStatusValue ? state.parentStatusValue.charAt(0) + state.parentStatusValue.slice(1).toLowerCase() : null

      let markup   =
        <div className={"update-status-screen-modal "}>

            <ModalBox onClose={this.props.hideUpdateStatusModal} bodyClass="update-revision-bd">
                <div className="update-status-screen">
                    <div className="form-holder">
                        <header className={"lable-mapped modal-header " + state.header.class}>
                            <div className="header-heading">
                                <Icon src={state.header.icon == "change-status" ? UpdateStatusModaSrc : FlagIcon} className='header-update-status-icon'/>
                                <h3>{state.header.text}</h3>
                            </div>

                            <div className="btn-holder">
                                <div className="btn-holder-inner">
                                    <SubmitBtn
                                        onClick={this.resetState}
                                        className={' btn-header ' + state.resetBtn.class + " reset-btn"}
                                        btnText={state.resetBtn.text}
                                        isDisabled={state.resetBtn.class === 'disabled'}
                                        stopLoading={state.resetBtn.stopLoading}
                                    />
                                    <SubmitBtn
                                        onClick={this.submit}
                                        className={" btn-header" + (state.continueBtn.class === 'disabled' ? " disabled" : " active") + (state.continueBtn.stopLoading ? '' : ' pl-15')}
                                        btnText={state.continueBtn.text}
                                        isDisabled={state.continueBtn.class === 'disabled'}
                                        stopLoading={state.continueBtn.stopLoading}
                                    />
                                </div>
                            </div>
                        </header>

                        <div className="update-status-screen-content-holder">
                            <div className="modal-heading">
                                <h1>Confirm status change</h1>
                                   <Link
                                    to={'https://duro.zendesk.com/hc/en-us/articles/360036511512-Changing-Status-and-Revision'}
                                    target="_blank"
                                    >
                                    <InlineIcon>
                                      <HelpIcon/>
                                    </InlineIcon>
                                   </Link>
                            </div>

                            <div className="heading-details">
                                <p>Status changes cannot be undone. Please review the updates below before continuing.</p>
                            </div>

                            {
                                this.props.editPage &&
                                <div className="child-revision-label">
                                    <div className="child-revision-label-holder">
                                        <input
                                            type="checkbox"
                                            name="check-child-revision"
                                            data-tip="Set status value for child components"
                                            data-place="top"
                                            onClick={this.setChildRevisionFlag}
                                            checked={state.childRevisionFlag ? true : false}
                                            defaultChecked={false}
                                        />
                                        <label
                                            htmlFor="check-child-revision"
                                        />
                                        <div className="label-heading">
                                            Include child components
                                        </div>
                                    </div>
                                </div>
                            }


                            {
                                this.props.editPage &&
                                <div id="update-parent-revision-holder">
                                  <ExtendedTable
                                    wrapperClassName="update-revision-table"
                                    wrapperSelectorClass="parent-revision-table"
                                    stylesName="parentRevisionTable"
                                    themeDataTableId={"parentRevisionTable"}
                                    headings={parentHeadings}
                                    rows={parentRow || []}
                                    currentSortItem={state.current}
                                    currentSortItemAscending={state.currentSortItemAscendingForParent}
                                    afterSyncWithParentState={this.afterSyncParentTable}
                                    syncWithParentState={state.syncParentTable}
                                    includeToolBar={true}
                                    borderedTable={true}
                                    resultText={`${parentRow.length} ${this.props.parent.alias === "cmp" ? "Components" : "Products"} `}
                                  />
                                </div>
                            }
                            {
                                this.props.editPage &&
                                <div className='child-cmp-heading'>
                                    CHILD COMPONENTS
                                </div>
                            }
                        {
                            state.innerLoading ?
                            <Spinner/> :
                             <div id="update-revision-holder">
                                <PerfectScrollbar
                                    className="variant-modal-scroll"
                                >
                                      <ExtendedTable
                                        wrapperClassName="update-revision-table"
                                        wrapperSelectorClass="revision-table"
                                        stylesName="revisionTable"
                                        themeDataTableId={"revisionTable"}
                                        headings={headings}
                                        rows={rows || []}
                                        afterExtendedTableRender={this.afterExtendedTableRender}
                                        afterExtendedTableHandleLoad={this.afterExtendedTableRender}
                                        beforeExtendedTableHandleLoad={this.beforeExtendedTableHandleLoad}
                                        afterSyncWithParentState={this.afterSyncTable}
                                        syncWithParentState={state.syncTable}
                                        currentSortItem={state.current}
                                        currentSortItemAscending={state.currentSortItemAscending}
                                        footerRow={rows.length === 0 && {
                                            dataCellEl: this.props.editPage ?
                                                        <p>No components in assembly</p> :
                                                        <p>No component selected</p>
                                            }
                                        }
                                        includeToolBar={true}
                                        borderedTable={true}
                                        allowRowSelect={true}
                                        onSelectAllRows={this.onSelectAllRows}
                                        onRowSelect={this.onRowSelect}
                                        resultText={`${rows.length} Components`}
                                        concateWrapperSelectorClassMasterCheckbox={true}
                                        customCTAIcon={
                                            {
                                                htmlBody:
                                                  <div className="set-bulk-revision-holder" >
                                                  <span>Bulk Actions</span>
                                                  <div>
                                                  <span className={'field-select'}>
                                                    <Status
                                                        onStatusChange={this.onBulkStatusChange}
                                                        className={`bulk-status select-status ${this.props.editPage ? state.childRevisionFlag ? "" : "disabled" : ""} ${this.isPresentCheckedRow(state.list) ? "" : "disabled"}`}
                                                        name="bulk-status-for-child"
                                                        status={state.bulkStatusValueForChild}
                                                        options={Utils.toOptions(Schemas.component.status.list(), true)}
                                                        />
                                                  </span>
                                                  </div>
                                                    <span className="bulk-revision-label">Set Revision</span>
                                                    <LazyInput
                                                        type="text"
                                                        value={state.bulkRevisionForChild.value.toUpperCase()}
                                                        className={`revision-input ${state.bulkRevisionForChild.class} ${this.props.editPage ? state.childRevisionFlag ? "" : "disabled" : ""} ${this.isPresentCheckedRow(state.list) ? "" : "disabled"} ${this.revisionScheme}`}
                                                        onChange={(e) => this.onBulkRevisionChange(e)}
                                                        name="bulk-revision-input"
                                                        autoComplete="off"
                                                        data-tip={state.bulkRevisionForChild.message}
                                                        data-place="right"
                                                        data-type="error"
                                                    />

                                                    <SubmitBtn
                                                        onClick={this.applyBulkRevison}
                                                        className={state.bulkRevisionApplyBtn.class}
                                                        btnText={state.bulkRevisionApplyBtn.text}
                                                        isDisabled={state.bulkRevisionApplyBtn.class === 'disabled'}
                                                        stopLoading={state.bulkRevisionApplyBtn.stopLoading}
                                                    />
                                                 </div>
                                            }
                                        }
                                      />
                                </PerfectScrollbar>
                            </div>
                        }
                        </div>

                </div>
            </div>
        </ModalBox>
      </div>
      return markup
    }
}

export default UpdateStatusModal
