import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 31 33"
    >
      <g fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <g>
            <path
              className='path'
              fillRule="nonzero"
              d="M15.5 0L0 13.104 4.017 16.5 0 19.896 15.5 33 31 19.896 26.983 16.5 31 13.104 15.5 0zm13.424 19.896L15.5 31.245 2.076 19.896l2.98-2.518 10.444 8.83 10.445-8.83 2.979 2.518zm-2.98-4.274L15.5 24.452l-10.445-8.83-2.979-2.518L15.5 1.755l13.424 11.349-2.98 2.518z"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
