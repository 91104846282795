import { SvgIcon } from "@mui/material";

export const ArrowReturnIcon = () => (
  <SvgIcon viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="evenodd">
      <path d="M1 1h16v16H1z M16.067 17H1.933A.911.911 0 0 1 1 16.067V1.933C1 1.4 1.4 1 1.933
      1h14.2c.467 0 .867.4.867.933v14.2c0 .467-.4.867-.933.867z" stroke="#CBCBCB"/>
      <path d="M14 5.667v2.666H5.2l2.4-2.4L6.667 5l-4 4 4 4 .933-.933-2.4-2.4h10.133v-4z"/>
    </g>
  </SvgIcon>
);
