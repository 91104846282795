import "./index.css";
import React, { useState } from "react";
import API from "../../../../../../modules/api";
import ModalBox from "../../../../../ui/modal-box";
import InlineIcon from "../../../../../ui/icon/inline-icon.js";
import VisibilityIcon from "../../../../../../assets/icons/visibility.js";
import VisibilityModal from "./visibility-modal";
import CustomReactSelect from "../../../../../ui/custom-react-select";
import { useSelector } from "react-redux";

const MandatoryModal = (props) =>
{
	const tempCoRules = [
		{
			type:"ECO",
			status:"PROTOTYPE",
			combination:"ecoPrototype",
			template:"",
			id:""
		},
		{
			type:"ECO",
			combination:"ecoProduction",
			status:"PRODUCTION",
			template:"",
			id:""
		},
		{
			type:"MCO",
			status:"PROTOTYPE",
			combination:"mcoPrototype",
			template:"",
			id:""
		},
		{
			type:"MCO",
			status:"PRODUCTION",
			combination:"mcoProduction",
			template:"",
			id:""
		}
	]

	const dcoRules = [
		{
			type:"DCO",
			status:"PROTOTYPE",
			combination:"dcoPrototype",
			template:"",
			id:""
		},
		{
			type:"DCO",
			status:"PRODUCTION",
			combination:"dcoProduction",
			template:"",
			id:""
		},
	]

	let newRules = [...tempCoRules];
	if(window.__isDcoEnabled) {
    	newRules = newRules.concat(dcoRules);
    }

    if(props.templates.length) {
    	newRules.forEach((rule) => {
      	    if(props.templateMappings[rule.combination]) {
      	    	for (let template of props.templates) {
      	    		if (template._id === props.templateMappings[rule.combination])
      	    		{
      	    			rule.id = template._id;
      	    			rule.template = template.templateName;
      	    		}
      	    	}
      	    }
    	})
    }

    const user = useSelector((store)=> store.user.data);
	const [templates, setTemplates] = useState(props.templates);
	const [templateId, setTemplateId] = useState('');
	const [updatedRules, setUpdatedRules] = useState({});
	const [visibilityIndex, setVisibilityIndex] = useState('');
	const [selectedRules, setSelectedRules] = useState(newRules);
	const [ShowVisibilityModal, setShowVisibilityModal] = useState(false);
	const {finalizeSetupStage} = props;

	const handleRuleSelection = (e, rowIndex) =>
	{
		let value = e.value;
		if (value !== selectedRules[rowIndex].template) {
			let updatedSelectedRules = [...selectedRules];
			let rulesPayload = {...updatedRules};
			const combination = selectedRules[rowIndex].combination;
			updatedSelectedRules[rowIndex].template = value;
			if (value)
			{
				for (let template of templates) {
					if (value === template.templateName)
					{
						rulesPayload[combination] = template._id;
						updatedSelectedRules[rowIndex].id = template._id;
					}
				}
			}
			else
			{
				updatedSelectedRules[rowIndex].id = '';
				rulesPayload[combination] = null;
			}

			setUpdatedRules(rulesPayload);
			setSelectedRules(updatedSelectedRules);
		}

	}

	const showDropdownComp = (row, rowIndex) =>
	{
		let obj = {label: row.template, value: row.template};
		return (
			<div className='Choose-Template'>
				<CustomReactSelect
					value={row.template ? obj : ''}
					onChange={(e) => handleRuleSelection(e, rowIndex)}
					options={makeDropdownList()}
					placeholder={'Select Template'}
					className={'template'}
					noTemplateExists={!templates.length}
					user={user}
				/>
            </div>
		)
	}

	const makeDropdownList = () =>
	{
		let dropDownList = [];
		if (templates.length)
		{
			for( let template of templates) {
				dropDownList.push(
				{
					value: `${template.templateName}`,
	           		label: `${template.templateName}`,
				})
			}
        	dropDownList.splice(0, 0, {value: '', label: 'Default Template'});
    	}
		else
		{
			dropDownList.push(
				{
					value: '',
					label: 'No templates present',
					isDisabled: true,
				})
		}
		return dropDownList;
	}

	const toggleVisibilityModal = (event, value, id, rowIndex) =>
	{
		event.stopPropagation();
		setShowVisibilityModal(value);
		setTemplateId(id);
		setVisibilityIndex(rowIndex);
	}

	const showCoTemplateRules = () =>
	{
		const rows = selectedRules.map((row, rowIndex) => {
			return (
				<tr key={`template-row-${rowIndex}`}>
					<td>{row.type}</td>
					<td>{row.status}</td>
					<td>
					{
                    	<InlineIcon
                    		tooltip={!ShowVisibilityModal ? 'View Approver List' : ''}
                    		className={`approval-modal-visibility ${row.id ? (ShowVisibilityModal && visibilityIndex === rowIndex ? 'visibility-green' : 'visibility-white') : ''}`}
                    		onClick={row.id ? (e) => toggleVisibilityModal(e, true, row.id, rowIndex) : () => { }}
                    	>
							<VisibilityIcon/>
						</InlineIcon>
					}
					{
						ShowVisibilityModal && visibilityIndex === rowIndex &&
						<VisibilityModal
							onClose={closeVisibilityModal}
							templates={templates}
							templateId={templateId}
							rowIndex={rowIndex}
						/>
					}
	                {
	                	showDropdownComp(row, rowIndex)
	                }
	                </td>
				</tr>
			)
		})

		return rows;
	}

	const handleSaveClick=(e)=>
	{
		finalizeSetupStage(updatedRules);
		API.services.updateCoTemplateMappings(updatedRules, (err, response)=>{
		})
		props.hideModal();
	}

	const closeVisibilityModal = () => {
		setShowVisibilityModal(false);
	}

	return(
		<div className='modal-box'>
			<ModalBox onClose={props.hideModal}>
				<h2 className='approval-model-heading'>Mandatory Approver Templates</h2>
				<div className='rules-table'>
					<table>
						<thead>
							<tr>
								<th>Type</th>
								<th>Status</th>
								<th>Template</th>
							</tr>
						</thead>
						<tbody>
						{
							showCoTemplateRules()
						}
						</tbody>
					</table>
				</div>
				<div className='finalize-block'>
					<button
						className='empty close-button'
						onClick={(e) => props.hideModal()}
					>
						Cancel
	                </button>
	                <button
						className={`green new-button ${Object.keys(updatedRules).length === 0 ? 'disabled' : ''}`}
						onClick={handleSaveClick}
					>
						Save
	                </button>
				</div>
			</ModalBox>
		</div>
	)
}

export default MandatoryModal;
