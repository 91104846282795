import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  PopoverOrigin,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import {
  BuildWorkspaceIcon,
  ChangeOrderIcon,
  ComponentIconFilled,
  DashboardIcon,
  DesignWorkspaceIcon,
  DuroFullIcon,
  DuroShortIcon,
  InstanceIcon,
  LotIcon,
  ProductIcon,
  ProductionsIcon,
  ReleaseIcon,
} from "assets/icons";
import { AppContext, AppSection } from "context";
import React, { FC, MouseEventHandler, ReactElement, useCallback, useContext, useMemo, useState } from "react";
import { colorPalette } from "theme";
import { privatePaths } from "v1/app/routes";
import { useDuroFlag } from "../launchDarkly";

type ClickEvent = React.MouseEvent<HTMLDivElement, MouseEvent>;
interface INavBar {
  activeTab: string;
  currentLibraryType: "GENERAL" | "PERSONAL"
  navigateTo: (event: ClickEvent, path: IRoute) => void;
}

interface IRoute {
  pathname: string,
  state: any, // Need to update the type
}
interface INavListItem {
  disabled?: boolean;
  icon: () => JSX.Element,
  label: string,
  route: IRoute
}

interface IWorkspaceNavigatorProps {
  disabled?: boolean,
  isBuildEnabled?: boolean
  isDrawerExpanded?: boolean,
  onClick: (event: ClickEvent) => void,
  section: AppSection,
  styleProps?: {
    paddingLeft: string,
  },
}

interface IResizeBox {
  section: AppSection;
  onMouseDown: MouseEventHandler<HTMLDivElement>
}

const BuildMode: INavListItem[] = [
  {
    disabled: true,
    icon: ProductionsIcon,
    label: "Productions",
    route: privatePaths.buildProductions,
  },
  {
    disabled: true,
    icon: InstanceIcon,
    label: "Instances",
    route: privatePaths.buildInstances,
  },
  {
    icon: LotIcon,
    label: "Lots",
    route: privatePaths.buildLots,
  },
];

const defaultDesignRoute = { pathname: "/dashboard", state: {} };
// TODO: (PLA-299) Update to the dashboard (/build/productions) when it is available.
const defaultBuildRoute = { pathname: "/build/lots", state: {} };
const MAX_WIDTH = 800;
const MIN_WIDTH = 56;
const COLLAPSED_THRESHOLD_WIDTH = 124;

const menuPositionProps: PopoverOrigin = {
  horizontal: "right",
  vertical: "center",
};

const workspaceNavigatorStyleProps = { paddingLeft: "0.5rem" };

const iconMapper: Record<AppSection, ReactElement> = {
  [AppSection.BUILD]: <BuildWorkspaceIcon />,
  [AppSection.DESIGN]: <DesignWorkspaceIcon />,
};

const sectionLabelMapper: Record<AppSection, "Build" | "Design"> = {
  [AppSection.BUILD]: "Build",
  [AppSection.DESIGN]: "Design",
};

const WorkspaceNavigator: FC<IWorkspaceNavigatorProps> = (
  {
    disabled = false,
    isBuildEnabled,
    isDrawerExpanded,
    onClick,
    section,
    styleProps,
  },
) => (<StyledListItem>
  <Tooltip title={!isDrawerExpanded && isBuildEnabled ? "Workspaces" : ""} placement="right">
    <StyledListItemButton
      disabled={disabled}
      onClick={onClick}
      styleProps={styleProps}
    >
      <NavigatorContentWrapper>
        {iconMapper[section]}
        {
          isDrawerExpanded && <>
            <NavigatorLabelsWrapper>
              <SectionWrapper>
                {sectionLabelMapper[section]}
              </SectionWrapper>
              <NavigatorTextWrapper>
                workspace
              </NavigatorTextWrapper>
            </NavigatorLabelsWrapper>
          </>
        }
      </NavigatorContentWrapper>
      {
        isDrawerExpanded && isBuildEnabled && <StyledArrowIcon />
      }
    </StyledListItemButton >
  </Tooltip>
</StyledListItem>
);

export const NavBar: FC<INavBar> = ({ activeTab, currentLibraryType, navigateTo }) => {
  const DesignMode: INavListItem[] = useMemo(() => [
    {
      icon: DashboardIcon,
      label: "Dashboard",
      route: privatePaths.dashboard,
    },
    {
      icon: ProductIcon,
      label: "Products",
      route: privatePaths.products,
    },
    {
      icon: ComponentIconFilled,
      label: "Components",
      route: privatePaths.components,
    },
    {
      icon: ChangeOrderIcon,
      label: "Change Orders",
      route: privatePaths.changeOrderSearch,
    },
    // Only need to show Releases nav item in GENERAL library
    ...(currentLibraryType === "GENERAL"
      ? [
        {
          icon: ReleaseIcon,
          label: "Releases",
          route: privatePaths.releaseSearch,
        },
      ]
      : []),
  ], [currentLibraryType]);

  const { section, setSection } = useContext(AppContext);
  const { build: isBuildEnabled } = useDuroFlag();

  const selectedTab = activeTab ?? (section === AppSection.DESIGN ? "Dashboard" : "Productions");
  const [isExpand, setIsExpand] = useState(false);
  const [drawerWidth, setDrawerWidth] = useState(MIN_WIDTH);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(menuAnchorEl);

  const customWidth = useMemo(() => ({ style: { width: `${(drawerWidth / 16)}rem` } }), [drawerWidth]);
  const anchorPosition = useMemo(() => ({ top: 85, left: drawerWidth + 10 }), [drawerWidth]);

  const routeTo = useCallback((event: ClickEvent, route: IRoute) => {
    navigateTo(event, route);
  }, [navigateTo]);

  const handleMouseMove = useCallback(e => {
    const newWidth = e.clientX - document.body.offsetLeft;
    if (COLLAPSED_THRESHOLD_WIDTH >= newWidth) {
      setIsExpand(false);
      setDrawerWidth(MIN_WIDTH);
    }
    else if (newWidth > MIN_WIDTH && newWidth < MAX_WIDTH) {
      setIsExpand(true);
      setDrawerWidth(newWidth);
    }
  }, []);

  const handleMouseUp = useCallback((e: MouseEvent) => {
    document.removeEventListener("mouseup", handleMouseUp, true);
    document.removeEventListener("mousemove", handleMouseMove, true);
    e.preventDefault();
  }, [handleMouseMove]);

  const handleMouseDown = useCallback(event => {
    document.addEventListener("mouseup", handleMouseUp, true);
    document.addEventListener("mousemove", handleMouseMove, true);
    event.preventDefault();
  }, [handleMouseMove, handleMouseUp]);

  const handleClick = useCallback((event: ClickEvent) => {
    setMenuAnchorEl(event.currentTarget);
  }, []);

  const switchToDesignWorkspace = useCallback((event: ClickEvent) => {
    setMenuAnchorEl(null);
    setSection(AppSection.DESIGN);
    navigateTo(event, defaultDesignRoute);
  }, [navigateTo, setSection]);

  const switchToBuildWorkspace = useCallback((event: ClickEvent) => {
    setMenuAnchorEl(null);
    setSection(AppSection.BUILD);
    navigateTo(event, defaultBuildRoute);
  }, [navigateTo, setSection]);

  const handleClose = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  const navList = useMemo(() => (
    section === AppSection.DESIGN ? DesignMode : BuildMode), [DesignMode, section]);

  const getDuroLogo = useMemo(() => (
    isExpand ? <DuroFullIcon /> : <DuroShortIcon />), [isExpand]);

  const getNavigationItems = useMemo(() => (
    navList.map((item: INavListItem) => (
      <StyledList key={item.label}>
        <Tooltip title={isExpand ? "" : item.label} placement="right">
          <CustomListItemButton
            disabled={item.disabled}
            onClick={(event: ClickEvent) => routeTo(event, item.route)}
            selected={selectedTab === item.label}
          >
            <CustomListItemIcon>
              <item.icon />
              {isExpand && <ListItemText primary={item.label} />}
            </CustomListItemIcon>
          </CustomListItemButton>
        </Tooltip>
      </StyledList>
    ))), [navList, selectedTab, isExpand, routeTo]);

  return (
    <StyledDrawer
      PaperProps={customWidth}
      variant="permanent"
    >
      <ResizeBlock
        onMouseDown={handleMouseDown}
        section={section}
      />
      <WorkspaceNavigator
        isBuildEnabled={isBuildEnabled}
        isDrawerExpanded={isExpand}
        onClick={handleClick}
        section={section}
        styleProps={workspaceNavigatorStyleProps}
      />
      {
        isBuildEnabled && (
          <StyledMenu
            anchorEl={menuAnchorEl}
            anchorOrigin={menuPositionProps}
            anchorPosition={anchorPosition}
            anchorReference="anchorPosition"
            onClose={handleClose}
            open={open}
          >
            <StyledMenuLabel>
              Select Workspace
            </StyledMenuLabel>
            <WorkspaceNavigator
              disabled={section === AppSection.DESIGN}
              isBuildEnabled={isBuildEnabled}
              isDrawerExpanded={true}
              onClick={switchToDesignWorkspace}
              section={AppSection.DESIGN}
            />
            <WorkspaceNavigator
              disabled={section === AppSection.BUILD}
              isBuildEnabled={isBuildEnabled}
              isDrawerExpanded={true}
              onClick={switchToBuildWorkspace}
              section={AppSection.BUILD}
            />
          </StyledMenu>
        )
      }
      <StyledWrapper>
        <Box>
          {getNavigationItems}
        </Box>
        <LogoWrapper>
          {getDuroLogo}
        </LogoWrapper>
      </StyledWrapper>
    </StyledDrawer>
  );
};

const StyledDrawer = styled(MuiDrawer)({
  boxSizing: "border-box",
  flexShrink: 0,
  height: "calc(100vh - 4.375rem)",
  whiteSpace: "nowrap",
  "& .MuiDrawer-paper": {
    backgroundColor: colorPalette.darkGrey,
    overflow: "hidden",
    position: "relative",
  },
});

const CustomListItemIcon = styled(ListItemIcon)({
  alignItems: "center",
  display: "flex",
  gap: "0.5rem",
  minWidth: "auto",
  width: "100%",
  transition: "0.3s ease-in-out",
  "&:hover": {
    transition: "0.3s ease-in-out",
  },
});

const CustomListItemButton = styled(ListItemButton)({
  transition: "0.3s ease-in-out",
  "&:hover": {
    transition: "0.3s ease-in-out",
  },
  "&.Mui-selected": {
    backgroundColor: colorPalette.dark,
  },
});

const StyledList = styled(ListItem)({
  padding: "0.5rem 0",
});

const LogoWrapper = styled(Box)({
  marginBottom: "2rem",
  textAlign: "center",
});

const StyledWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  height: "inherit",
  justifyContent: "space-between",
});

const StyledListItem = styled(ListItem)({
  padding: "0.8rem 0 0",
});

const NavigatorLabelsWrapper = styled(Box)({ marginRight: "0.5rem" });

const SectionWrapper = styled(Typography)({
  fontSize: "0.9rem",
  lineHeight: "1.2",
  marginBottom: "0.4rem",
});

const NavigatorTextWrapper = styled(Typography)({
  fontSize: "0.9rem",
  lineHeight: "1",
});

const StyledArrowIcon = styled(ArrowForwardIosIcon)({
  fontSize: "1.2rem",
});

const StyledMenuLabel = styled(Typography)({
  fontSize: "0.9rem",
  paddingLeft: "0.8rem",
});

const StyledMenu = styled(Menu)({
  "& .MuiPopover-paper": {
    borderRadius: "0.5rem",
    "& .MuiList-root": {
      backgroundColor: colorPalette.darkGrey,
    },
  },
});

export const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: prop => prop !== "styleProps",
})<Pick<IWorkspaceNavigatorProps, "styleProps">>(
  ({ styleProps }) => ({
    justifyContent: "space-between",
    paddingLeft: styleProps?.paddingLeft ?? "1rem",
  }),
);

const ResizeBlock = styled(Box)<IResizeBox>(({ section }) => ({
  backgroundColor: colorPalette.grey,
  bottom: 0,
  cursor: "ew-resize",
  padding: "0.25rem 0 0",
  position: "absolute",
  right: 0,
  top: 0,
  transition: "0.3s ease-in-out",
  width: "0.094rem",
  zIndex: 100,
  "&:hover": {
    backgroundColor: `${section === AppSection.DESIGN ? colorPalette.green : colorPalette.purple}`,
    transition: "0.3s ease-in-out",
  },
}));

const NavigatorContentWrapper = styled(Box)({
  alignItems: "center",
  display: "flex",
});
