import { PageItemType } from "design/constants";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ComponentActions from "v1/action-types/component";
import buildAction from "v1/helpers/buildAction";
import Schemas from "v1/modules/schemas";
import Utils from "v1/modules/utils";
import { useComponentModalContext, useComponentRevisionContext } from "./componentModal";
import {
  useCompany, useCompanyIs11DigitCpnType, useEditChildren, useEditInputs,
  useEditObject, useUser,
} from "./hooks";

function useOriginalComponentId() {
  return useSelector((store: any) => store.components.editPage.originalComponentId);
}

function useChildrenModified() {
  return useSelector((store: any) => store.assembly_edit.modified);
}

export interface UseOnSubmitArgs {
  isCpnVariantEditable: boolean;
  isCpnVariantScheme: boolean;
  isNewObject: boolean;
  pageItemType: PageItemType;
}

export function useOnSubmit(args: UseOnSubmitArgs) {
  const { isCpnVariantEditable, isCpnVariantScheme, isNewObject, pageItemType } = args;
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useUser();
  const company = useCompany();
  const editObject = useEditObject(pageItemType);
  const editInputs = useEditInputs(pageItemType);
  const children = useEditChildren();
  const companyIs11DigitCpnType = useCompanyIs11DigitCpnType();
  const { showSaveAsRevisionModal, setShouldBlockNavigation } = useComponentModalContext();
  const originalComponentId = useOriginalComponentId();
  const childrenModified = useChildrenModified();
  const { revisionComment, revisionInput } = useComponentRevisionContext();

  const validated = useMemo(() => Utils.isValidated(editInputs), [editInputs]);
  const childrenValidated = useMemo(() => Utils.isValidated(children), [children]);

  const { cpnType } = company.data;
  const getData = useCallback(() => {
    const isAssembly = Schemas.component.category.getType(editInputs.category.value).toLowerCase() === "assembly";
    let newModifiedValue = true;

    const newComponent = { ...editObject };

    newComponent.name = editInputs.name.value;
    newComponent.category = editInputs.category.value;
    newComponent.customSpecs = editInputs.customSpecs;
    newComponent.revision = editInputs.revision.value;
    newComponent.status = editInputs.status.value;
    newComponent.eid = editInputs.eid.value.trim();
    newComponent.description = editInputs.description.value;
    newComponent.images = editInputs.images.value;

    if (isAssembly) {
      Utils.updatePullRequestProperty(children);
    }
    newComponent.children = isAssembly ? children : [];
    newComponent.manufacturers = editInputs.manufacturers.value;
    newComponent.documents = editInputs.documents.value;
    newComponent.specs = editInputs.specs;
    newComponent.customProperties = editInputs.customProperties;
    newComponent.procurement = editInputs.procurement;
    newComponent.mass = editInputs.mass.value;
    newComponent.unitOfMeasure = editInputs.unitOfMeasure.value;
    newComponent.revisionManaged = editInputs.revisionManaged;
    if (cpnType === "FREE-FORM") newComponent.cpn = editInputs.cpn.value;

    if (isCpnVariantScheme && cpnType !== "CPN_RULES") {
      const cpnSplit = (newComponent.cpn as string).split("-");
      newComponent.cpn = `${cpnSplit[0]}-${cpnSplit[1]}`;
      if (companyIs11DigitCpnType) {
        newComponent.cpn = cpnSplit.slice(0, 3).join("-");
      }
      else {
        newComponent.cpn = cpnSplit.slice(0, 2).join("-");
      }
      newComponent.categoryCPN = newComponent.cpn;
    }

    if (!editInputs.inputChanged) {
      newModifiedValue = false;
    }

    if (childrenModified) {
      newModifiedValue = true;
    }

    if (isCpnVariantEditable) {
      newComponent.cpnVariant = editInputs.cpnVariant.value;
    }

    if (newComponent.status === "DESIGN" || isNewObject) {
      newModifiedValue = false;
      newComponent.modified = false;
    }

    if (isNewObject) {
      newComponent.archived = false;
    }

    newComponent.specs = newComponent.specs.map((spec: any, i: number) => ({
      ...spec,
      value: editInputs.specs[i].value,
    }));

    if (!newComponent.modified) {
      newComponent.modified = newModifiedValue;
    }

    if (showSaveAsRevisionModal) {
      newComponent.revisionComment = revisionComment;
      newComponent.saveAsRevision = true;
      newComponent.revision = revisionInput.value;
    }
    return newComponent;
  }, [
    children,
    childrenModified,
    companyIs11DigitCpnType,
    cpnType,
    editInputs,
    editObject,
    isCpnVariantEditable,
    isCpnVariantScheme,
    isNewObject,
    revisionComment,
    revisionInput,
    showSaveAsRevisionModal,
  ]);

  return useCallback((event: any, options: any = null) => {
    event?.preventDefault();
    if (!validated || !childrenValidated) return;

    const payload = {
      data: getData(),
      history,
      user,
      showAll: (history.location as any).showAll,
    };

    if (options) {
      payload.data.revisionComment = options.revisionComment;
      payload.data.saveAsRevision = true;
      payload.data.revision = options.revision;
    }

    if ((history.location as any).isVariant) {
      payload.data.originalObjectId = originalComponentId;
      payload.data.isVariant = (history.location as any).isVariant;
    }
    setShouldBlockNavigation({ block: false, saving: true });
    dispatch(buildAction(ComponentActions.SUBMIT_COMPONENT_EDIT_FORM, payload));
  }, [
    childrenValidated,
    dispatch,
    getData,
    history,
    originalComponentId,
    setShouldBlockNavigation,
    user,
    validated,
  ]);
}
