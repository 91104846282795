import { ApolloClient, FetchPolicy, gql, makeVar, NormalizedCacheObject, useQuery } from "@apollo/client";
import { User } from "common/models";
import { GraphQLError } from "graphql";
import { AllEnabledUsersFragment } from "graphql/fragment/userFragments";
import { useEffect, useMemo } from "react";
import { UserFragment } from "../fragment/userFragments";

export const GET_USER = gql`
  query($id: ID) {
    userById(id: $id) {
      ...userFragment
    }
  }
  ${UserFragment}
`;

export const GET_ALL_ENABLED_USERS = gql`
  query($id: ID) {
    userById(id: $id) {
      ...allEnabledUsersFragment
    }
  }
  ${AllEnabledUsersFragment}
`;

export async function getUser(
  client: ApolloClient<NormalizedCacheObject>,
  id = null,
  fetchPolicy: FetchPolicy = "cache-first",
) {
  try {
    const { data, errors, loading } = await client.query({
      query: GET_USER,
      variables: { id },
      fetchPolicy,
    });
    const user = data?.userById ?? {};
    userById(user);
    return { data: user, errors, loading };
  }
  catch (e: any) {
    return { data: null, errors: [{ message: e?.toString() }] as GraphQLError[], loading: false };
  }
}

export async function getAllEnabledUsers(
  client: ApolloClient<NormalizedCacheObject>,
  id = null,
  fetchPolicy: FetchPolicy = "cache-first",
) {
  const { data, error, loading } = await client.query({
    query: GET_ALL_ENABLED_USERS,
    variables: { id },
    fetchPolicy,
  });
  const user = data?.userById ?? {};
  return { data: user, error, loading };
}

export function useUser(
  id = null,
  fetchPolicy: FetchPolicy = "cache-first",
) {
  const { data, error, loading } = useQuery<{ userById: User | null }>(GET_USER, {
    variables: { id },
    fetchPolicy,
  });

  const user = useMemo(() => data?.userById, [data]);

  useEffect(() => {
    if (!loading) {
      userById(user ?? {});
    }
  }, [user, loading]);

  return { data: user, error, loading };
}

export async function deleteUserFromCache(
  client: ApolloClient<NormalizedCacheObject>,
  id: string,
) {
  client.cache.evict({ id: `User:${id}` });
}

export const GET_SUBDOMAIN = gql`
  query {
    subdomain {
      forwardTo
    }
  }
`;

export async function getSubdomain(
  client: ApolloClient<NormalizedCacheObject>,
  fetchPolicy: FetchPolicy = "network-only",
) {
  const { data, errors, loading } = await client.query({
    query: GET_SUBDOMAIN,
    variables: {},
    fetchPolicy,
  });
  const subdomain = data?.subdomain ?? {};
  return { data: subdomain, errors, loading };
}

export const GET_API_TOKEN = gql`
  query {
    apiToken {
      token
    }
  }
`;

export async function getApiToken(
  client: ApolloClient<NormalizedCacheObject>,
  fetchPolicy: FetchPolicy = "network-only",
) {
  const { data, errors, loading } = await client.query({
    query: GET_API_TOKEN,
    variables: {},
    fetchPolicy,
  });
  const apiToken = data?.apiToken ?? {};
  return { data: apiToken, errors, loading };
}

// Reactive vars
//----------------------------------------------------------------------------
// Initial values
export const userById = makeVar({
  id: 0,
  preferences: {} as any,
  company: {} as any,
  activeLibrary: {} as any,
} as any);
