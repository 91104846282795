import                                                  "./index.css";
import React                                            from "react";
import ModalBox                                         from "../../../ui/modal-box";
import revsSrc                                          from "../../../../assets/icons/revs.svg";
import FlagIcon                                         from "../../../../assets/icons/flag.svg";
import Icon                                             from "../../../ui/icon";
import ExtendedTable                                    from "../extended-table";
import Utils                                            from "../../../../modules/utils";
import InlineIcon                                       from "../../../ui/icon/inline-icon.js";
import DeleteSrc                                        from "../../../../assets/icons/cross-icon.js";
import ArrowIcon                                        from "../../../../assets/icons/co-small-arrow.js";
import Link                                             from "../../../ui/link";
import API                                              from "../../../../modules/api";
import validations, {validateField}                     from "../../../../modules/validations";
import LazyInput                                        from "../../../ui/lazy-input/input.js";
import SubmitBtn                                        from "../../../ui/spinner-button";
import HelpIcon                                         from "../../../../assets/icons/help-icon.js";
import Spinner                                          from "../../../ui/spinner";
import PerfectScrollbar                                 from 'react-perfect-scrollbar';
import Tooltip                                          from 'rc-tooltip';
import BulkUpdateInfoIcon                               from "../../../../assets/icons/bulk-update-info-icon.js";
import 'react-perfect-scrollbar/dist/css/styles.css'
import StatusField                    from "../status-field";
import CpnField                       from "../cpn-field";
import RevisionField                  from "../revision-field";

export class UpdateRevisionModal extends React.Component
{
    constructor(props, context)
    {
        super(props, context);

        let viewStyles;
        let parentViewStyles;
        let isRevisionTypeDefault = Utils.isRevisionTypeDefault()
        let revisionColumnMinWidth = isRevisionTypeDefault ? 120 : 165

        try
        {
            viewStyles = window.__userStyles.styles.updateRevisionTable || {};
            parentViewStyles = window.__userStyles.styles.updateParentRevisionTable || {};

        }
        catch(error)
        {
            viewStyles = {};
        }
        this.revisionScheme = isRevisionTypeDefault ? "default-rev-scheme" : "custom-rev-scheme"
        this.state =
        {
            bulkRevisionValue: "",
            syncTable: false,
            syncParentTable: false,
            headings :
            [
                {
                    key         : "cpn",
                    displayName : "CPN",
                    tooltip     : "Customer Part Number",
                    sortable    : true,
                    minWidth    : 109,
                    width       : Utils.getStyleValue(viewStyles, "cpn", "width", 109),
                    position    : Utils.getStyleValue(viewStyles, "cpn", "position", 0),
                    visibility  : Utils.getStyleValue(viewStyles, "cpn", "visibility", true),
                    disabled    : true
                },
                {
                    key         : "name",
                    displayName : "Name",
                    tooltip     : "Name",
                    sortable    : true,
                    minWidth    : 260,
                    width       : Utils.getStyleValue(viewStyles, "name", "width", 260),
                    position    : Utils.getStyleValue(viewStyles, "name", "position", 1),
                    visibility  : Utils.getStyleValue(viewStyles, "name", "visibility", true),
                    disabled    : true
                },
                {
                    key         : "status",
                    displayName : "Status",
                    tooltip     : "Status",
                    sortable    : true,
                    minWidth    : 135,
                    width       : Utils.getStyleValue(viewStyles, "status", "width", 140),
                    position    : Utils.getStyleValue(viewStyles, "status", "position", 2),
                    visibility  : Utils.getStyleValue(viewStyles, "status", "visibility", true),
                    disabled    : false,
                },
                {
                    key         : "revision",
                    displayName : "Revision",
                    tooltip     : "Revision",
                    sortable    : true,
                    minWidth    : revisionColumnMinWidth,
                    width       : Utils.getStyleValue(viewStyles, "revision", "width", revisionColumnMinWidth),
                    position    : Utils.getStyleValue(viewStyles, "revision", "position", 3),
                    visibility  : Utils.getStyleValue(viewStyles, "revision", "visibility", true),
                    disabled    : true
                },
                {
                    key         : "description",
                    displayName : "Description",
                    tooltip     : "Description",
                    sortable    : true,
                    minWidth    : 145,
                    width       : Utils.getStyleValue(viewStyles, "description", "width", 385),
                    position    : Utils.getStyleValue(viewStyles, "description", "position", 4),
                    visibility  : Utils.getStyleValue(viewStyles, "description", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "remove",
                    displayName : "",
                    sortable    : false,
                    minWidth    : 30,
                    width       : 30,
                    visibility  : true,
                    headingClass   : "remove-col",
                    dragable    : false,
                    expandAble  : false,
                    disabled    : true,
                    hideFromSettings: true
                }
            ],
            parentHeadings :
            [
                {
                    key         : "cpn",
                    displayName : "CPN",
                    tooltip     : "Customer Part Number",
                    sortable    : true,
                    minWidth    : 109,
                    width       : Utils.getStyleValue(parentViewStyles, "cpn", "width", 109),
                    position    : Utils.getStyleValue(parentViewStyles, "cpn", "position", 0),
                    visibility  : Utils.getStyleValue(parentViewStyles, "cpn", "visibility", true),
                    disabled    : true
                },
                {
                    key         : "name",
                    displayName : "Name",
                    tooltip     : "Name",
                    sortable    : true,
                    minWidth    : 260,
                    width       : Utils.getStyleValue(parentViewStyles, "name", "width", 260),
                    position    : Utils.getStyleValue(parentViewStyles, "name", "position", 1),
                    visibility  : Utils.getStyleValue(parentViewStyles, "name", "visibility", true),
                    disabled    : true
                },
                {
                    key         : "status",
                    displayName : "Status",
                    tooltip     : "Status",
                    sortable    : true,
                    minWidth    : 135,
                    width       : Utils.getStyleValue(parentViewStyles, "status", "width", 140),
                    position    : Utils.getStyleValue(parentViewStyles, "status", "position", 2),
                    visibility  : Utils.getStyleValue(parentViewStyles, "status", "visibility", true),
                    disabled    : false,
                },
                {
                    key         : "revision",
                    displayName : "Revision",
                    tooltip     : "Revision",
                    sortable    : true,
                    minWidth    : revisionColumnMinWidth,
                    width       : Utils.getStyleValue(parentViewStyles, "revision", "width", revisionColumnMinWidth),
                    position    : Utils.getStyleValue(parentViewStyles, "revision", "position", 3),
                    visibility  : Utils.getStyleValue(parentViewStyles, "revision", "visibility", true),
                    disabled    : true
                },
                {
                    key         : "description",
                    displayName : "Description",
                    tooltip     : "Description",
                    sortable    : true,
                    minWidth    : 145,
                    width       : Utils.getStyleValue(parentViewStyles, "description", "width", 385),
                    position    : Utils.getStyleValue(parentViewStyles, "description", "position", 4),
                    visibility  : Utils.getStyleValue(parentViewStyles, "description", "visibility", true),
                    disabled    : false
                }
            ],
            current                 : "cpn",
            currentSortItemAscending: true,
            currentForParent        : "cpn",
            currentSortItemAscendingForParent: true,
            list                    : [...this.props.products || [], ...this.props.components || []],
            resetBtn                : { class: '', text: 'Reset'},
            header                  : { class: '', text: 'Set New Revision Values', icon: 'change-revision'},
            continueBtn             : { class: '', text: 'Continue', stopLoading: true },
            bulkRevisionApplyBtn    : { class: 'disabled', text: 'Apply', stopLoading: true },
            innerLoading            : false,
            parentRevision          : { value: this.props.parent.nextCoRevision && this.props.parent.nextCoRevision || "1", class:'', message: '', valid: true},

            revisionComment         : { value: "", class:'', message: '', valid: true},
            childRevisionFlag       : false,
            selectedResults         :
            {
                cmp  : [],
            }
        }
        this.isSubmitted                = false;
        this.baseState                  = JSON.parse(JSON.stringify(this.state));
        this.getTableRows               = this.getTableRows.bind(this);
        this.getParentTableRow          = this.getParentTableRow.bind(this);
        this.onInputChange              = this.onInputChange.bind(this);
        this.createInputs               = this.createInputs.bind(this);
        this.afterSyncTable             = this.afterSyncTable.bind(this);
        this.afterSyncParentTable       = this.afterSyncParentTable.bind(this);
        this.remove                     = this.remove.bind(this);
        this.createRevisionForChilds    = this.createRevisionForChilds.bind(this);
        this.getComponentWithDesign     = this.getComponentWithDesign.bind(this);
        this.createDataForUpdate        = this.createDataForUpdate.bind(this);
        this.resetState                 = this.resetState.bind(this);
        this.submit                     = this.submit.bind(this);
        this.setChildRevisionFlag       = this.setChildRevisionFlag.bind(this);
        this.updateComponentRevision    = this.updateComponentRevision.bind(this);
        this.updateRevisionOfParent     = this.updateRevisionOfParent.bind(this);
        this.validateChildInputs        = this.validateChildInputs.bind(this);
        this.validateParentInputs       = this.validateParentInputs.bind(this);
        this.setHeader                  = this.setHeader.bind(this);
        this.afterExtendedTableRender   = this.afterExtendedTableRender.bind(this);
        this.applyBulkRevison           = this.applyBulkRevison.bind(this);
        this.onBulkRevisionChange       = this.onBulkRevisionChange.bind(this);
        this.onSelectAllRows            = this.onSelectAllRows.bind(this);
        this.onRowSelect                = this.onRowSelect.bind(this);
    }

    onSelectAllRows(checked)
    {
        let state = this.state
        let { list } = state;
        list.forEach((item, i) =>
        {
            item.checked = checked;
        });
        state.bulkRevisionApplyBtn.class = this.isPresentCheckedRow(list) ? "" : "disabled";
        this.setState(state);
    }

    isPresentCheckedRow(list)
    {
        let isPresentCheckedRow = false
        for (let i=0; i < list.length; i++) {
            if (list[i].checked)
            {
                isPresentCheckedRow = true;
                break;
            }
        }
        return isPresentCheckedRow;
    }

    onRowSelect(event, itemFromExtendedTable)
    {
        let state = this.state
        let { list } = state;
        let checked = event.target.checked;
        list.forEach((item) => {
            if (item._id === itemFromExtendedTable._id)
            {
                item.checked = checked
            }
        })
        state.bulkRevisionApplyBtn.class = this.isPresentCheckedRow(list) ? "" : "disabled";
        this.setState(state);
    }

    componentWillMount()
    {
        if(this.props && this.props.editPage && this.props.parent)
        {
            let errorFoundInParent = this.validateParentInputs(this.state);
            this.setHeader(this.state, errorFoundInParent);
            this.state.innerLoading = true;
            this.setState(this.state);
            let items = []
            this.props.children.forEach((child) => {
                items.push({id: child.component._id, type: child.component.alias});
            })

            API.cos.getChildrenAssemblies({items, includeRev: true, excludeParents: false}, (err, res) =>
            {
                if(err)
                {
                    this.state.list = [];
                }
                else
                {
                    let list = [];
                    res.children.forEach((item) => list.push(item.obj));
                    this.state.list = list;
                    this.setState(this.state, this.createInputs());
                }
            });
        }
    }

    componentDidUpdate() {
        let childTable = document.querySelector(".revision-table")
        if (childTable)
        {
            let height = childTable.clientHeight
            let childTableContainer = document.querySelector("#update-revision-holder")
            if (height > 340 && childTableContainer)
            {
                childTableContainer.classList.add("apply-scrol")
            }
        }
    }

    createInputs()
    {
        let state = this.state;
        let list  = state.list;
        list.map((item) => {
            item.nextRevisionInput =
            {
                value: item.nextCoRevision ? item.nextCoRevision.toUpperCase() : "1",
                valid: true,
                message: "",
                class: ""
            }
        });
        state.list = list;
        this.baseState.list = JSON.parse(JSON.stringify(state.list));
        state.innerLoading = false;
        this.setState(state);
    }

    getTableRows()
    {
        let state           = this.state;
        let list            = state.list;
        let rows = list.map((item, i) =>
        {
            let vendor = Utils.getVendor(item);
            let isValid = item.status === "DESIGN" && vendor === "duro"
            let link  = item.alias === 'cmp' ? `/component/view/${item._id}` : `/product/edit/${item._id}`;
            let tooltipText = "Components in Prototype and Production must be updated through a change order. These components will not be effected."

            let cadPlugin = "Solidworks"
            if ( vendor !== "duro" && vendor === "ONSHAPE")
            {
                cadPlugin = "Onshape"
            }

            if (vendor !== "duro")
            {
               tooltipText = `This Component is managed by ${cadPlugin}. It cannot be edited.`
            }

            let cells =
            {
               "cpn":
                {
                    value       : item.cpn,
                    tooltip     : item.cpn,
                    displayValue :
                        <div className="cpn-holder">
                            <Link
                                to={ link }
                                target="_blank"
                                className="link">
                                <CpnField item={item} cpn={item.cpn}/>
                            </Link>
                        </div>,
                    cellClass   : !isValid  ?  "not-modified" : "",
                },
                "name":
                {
                    value       : item.name,
                    displayValue: item.name,
                    cellClass   : !isValid ?  "not-modified" : "",
                    tooltip     : item.name,

                },
                "status":
                {
                    value       : item.status,
                    tooltip     : item.status,
                    cellClass   : `status-cell ${!isValid ?  "not-modified" : ""}`,
                    displayValue :
                        <span className="status-holder">
                            <span className={`status-label ${item.status}`}>
                                {item.status}
                            </span>
                        </span>
                },
                "revision":
                {
                    value       : item.revision,
                    cellClass   : `revision-cell ${!isValid ?  "not-modified" : ""}`,
                    displayValue :
                    <div className="revision-holder">
                        {
                            <span className={`revision-label ${this.revisionScheme}`}>
                                <RevisionField item={item} revision={item.revision}/>
                            </span>
                        }

                        {
                            isValid ?
                            <InlineIcon className="arrow">
                                <ArrowIcon/>
                            </InlineIcon>
                            : null
                        }

                        {
                            isValid ?
                            <div>
                                <LazyInput
                                    type="text"
                                    value={item.nextRevisionInput && item.nextRevisionInput.value ? item.nextRevisionInput.value : '' }
                                    data-tip={item.nextRevisionInput && item.nextRevisionInput.message ? item.nextRevisionInput.message : '' }
                                    className={`${(item.nextRevisionInput && item.nextRevisionInput.class ? item.nextRevisionInput.class : '' )} revision-input ${this.revisionScheme}`}
                                    data-place="right"
                                    data-type="error"
                                    onChange={(event) => this.onInputChange(event, item, i)}
                                />
                                <span className={`revision-label enable-disable-label ${this.revisionScheme}`}>
                                    {
                                         (item.status === 'DESIGN') && !state.childRevisionFlag ? <span className={`${this.revisionScheme}`}>—</span>
                                        : null
                                    }
                                </span>
                            </div>
                            : null
                        }
                    </div>,
                    haveInput   : true,
                    notLink     : true
                },
                "description":
                {
                    value       : item.description,
                    displayValue: item.description,
                    tooltip     : item.description,
                    cellClass   : !isValid ?  "not-modified" : ""
                },
                "remove":
                {
                    notLink      : true,
                    value        : "",
                    cellClass    : "remove-col",
                    displayValue : <div onClick={(event) => this.remove(event, item._id)}>
                                        <InlineIcon
                                            tooltip="Remove"
                                            tooltipPlace="top"
                                            >
                                            <DeleteSrc/>
                                        </InlineIcon>
                                    </div>
                },
                rowClassName : '',
                rowLink      : link,
                targetBlank  : true,
                rowSelected  : isValid ? item.checked : false,
                rowId        : item._id,
                allowRowSelect : isValid ? true : false,
                object       : item,
                getSelectedInBulk: isValid ? true : false,
                indexTableEl :  !isValid ?
                        <Tooltip
                          mouseEnterDelay={1}
                          placement={"top"}
                          getTooltipContainer={() => document.querySelector(".update-status-screen")}
                          overlayClassName={"simple-rc-tip error default-styles update-status-screen-tool-tip bulk-update-heading-info-tooltip" }
                          overlay={tooltipText}
                        >
                            <div>
                                <InlineIcon
                                    isValid={false}
                                    className="warningEl"
                                    >
                                    <BulkUpdateInfoIcon/>
                                </InlineIcon>
                            </div>
                        </Tooltip> : null
            }
            return cells
        })
        return rows
    }

    getParentTableRow()
    {
        let state = this.state
        let {parent} = this.props;
        let link  = parent.alias === 'cmp' ? `/component/view/${parent._id}` : `/product/edit/${parent._id}`;
        let parentRow =
        {
           "cpn":
            {
                value       : parent.cpn,
                tooltip     : parent.cpn,
                displayValue :
                    <div className="cpn-holder">
                        <Link
                            to={ link }
                            target="_blank"
                            className="link">
                            <CpnField item={parent} cpn={parent.cpn}/>
                        </Link>
                    </div>
            },
            "name":
            {
                value       : parent.name,
                displayValue: parent.name,
                tooltip     : parent.name,
            },
            "status":
            {
                value       : parent.status,
                tooltip     : parent.status,
                displayValue :
                    <span className="status-holder">
                        <StatusField item={parent} status={parent.status}/>
                    </span>
            },
            "revision":
            {
                value       : parent.revision,
                displayValue :
                    <div className="revision-holder">
                        <span className={`revision-label ${this.revisionScheme}`}>
                            <RevisionField item={parent} revision={parent.revision} showIcon={false}/>
                        </span>
                        <InlineIcon className="arrow">
                            <ArrowIcon/>
                        </InlineIcon>
                        {
                            <LazyInput
                                value={state.parentRevision.value}
                                className={`${state.parentRevision.class} revision-input ${this.revisionScheme}`}
                                data-tip={state.parentRevision.message}
                                data-place="right"
                                data-type="error"
                                type="text"
                                onChange={(e) => this.onInputChange(e)}
                                name="parent-revision-value"
                            />
                        }
                    </div>,
                    cellClass   : "revision-cell",
                    haveInput   : true,
                    notLink     : true
            },
            "description":
            {
                value       : parent.description,
                displayValue : parent.description,
                tooltip     : parent.description,
            },
            rowClassName : '',
            rowLink      : link,
            targetBlank  : true,
            indexTableEl : null
        }
        return [parentRow]
    }

    onInputChange(event, item=null, i=null)
    {
        let state               = this.state;
        let target              = event.target;
        let value               = target.value;

        switch (target.name)
        {
            case "parent-revision-value":
                state.syncParentTable = true;
                state.parentRevision.value = value.toUpperCase();
                this.validateParentInputs(state);
                break;
            case "revision-comment":
                state.revisionComment.value = value;
                break;
            default:
                break;
        }

        if(item)
        {
            item.nextRevisionInput.value  = value;
            let {status, revisions}       = item;
            let revisionInput = item.nextRevisionInput;

            let validationPayload = {
                status,
                revisions,
                revSchemeType: window.__revSchemeType,
                libraryType: window.__libraryType,
                isClient: true,
                isSaveAsRev: true,
                defaultBlacklistedRevisions: window.__defaultBlacklistedRevisions
            }
            validateField(revisionInput, validations.component.revision, value.toUpperCase(), validationPayload);
            state.syncTable = true;
        }
        state.resetBtn.class = '';
        //Don't need to trigger the validation rules on comment change
        if (target.name !== "revision-comment")
        {
            this.setHeader(state, false, true);
        }
        this.setState(state);
    }

    onBulkRevisionChange(event, item=null, i=null)
    {
        let state = this.state;
        let target = event.target;
        let value = target.value.toUpperCase();
        switch (target.name)
        {
            case "bulk-revision-input":
                state.bulkRevisionApplyBtn.class = this.isPresentCheckedRow(state.list) ? "" : "disabled";
                state.bulkRevisionValue = value;
                break;
            default:
                break;
        }
        this.setState(state);
    }

    applyBulkRevison()
    {
        let state = this.state
        let {bulkRevisionValue} = state
        let errorFoundInChild = false

        state.bulkRevisionApplyBtn.class = "disabled"
        state.bulkRevisionApplyBtn.stopLoading = false
        this.setHeader(state, false, true);
        this.setState(state)

        let _this = this
        //Add some delay to display loading
        setTimeout(function(){

            state.list.forEach((item) =>
            {
                if (item.alias === "cmp" && item.status === "DESIGN" && item.checked)
                {
                    item.nextRevisionInput.value  = bulkRevisionValue;
                    let {status, revisions}       = item;
                    let revisionInput = item.nextRevisionInput;
                    let validationPayload = {
                        status,
                        revisions,
                        revSchemeType: window.__revSchemeType,
                        libraryType: window.__libraryType,
                        isClient: true,
                        isSaveAsRev: true,
                        defaultBlacklistedRevisions: window.__defaultBlacklistedRevisions
                    }
                    validateField(revisionInput, validations.component.revision, bulkRevisionValue.toUpperCase(), validationPayload);
                }
            })
            state.syncTable = true;
            state.resetBtn.class = '';
            state.bulkRevisionApplyBtn.stopLoading = true
            state.bulkRevisionApplyBtn.class = ""
            _this.setState(state);
        }, 100);
    }

    setChildRevisionFlag()
    {
        let state = this.state;
        let shouldClickOnMasterCheckbox = false
        state.resetBtn.class = "";
        state.resetBtn.text= "Reset";
        state.resetBtn.stopLoading= true;
        state.childRevisionFlag = !state.childRevisionFlag;

        if (state.childRevisionFlag)
        {
            shouldClickOnMasterCheckbox = true
        }
        else
        {
            shouldClickOnMasterCheckbox = this.isPresentCheckedRow(state.list) ? true : false
        }
        let errorFoundInChild = false;
        let errorFoundInParent = false;

        //Run validations if checkbox is checked
        if (state.childRevisionFlag)
        {
            errorFoundInChild = this.validateChildInputs(state);
        }
        errorFoundInParent = this.validateParentInputs(state);

        this.setHeader(state, errorFoundInParent || errorFoundInChild);
        this.setState(state);

        let el = document.getElementById('update-revision-holder');
        if (el && state.childRevisionFlag)
        {
            el.classList.remove('disabled-child');
            el.classList.add('enabled-child');
        }
        else
        {
            el.classList.add('disabled-child');
            el.classList.remove('enabled-child');
        }

        let masterCheckbox = document.querySelector('.index-table thead .index-checkbox label');
        if (masterCheckbox && shouldClickOnMasterCheckbox)
        {
            masterCheckbox.click()
        }
    }

    validateParentInputs(state)
    {
        let parentRevision = state.parentRevision;
        let errorFound = false;
        let {status, revisions} = this.props.parent;
        let validationPayload = {
            isSaveAsRev: true,
            status,
            revisions,
            revSchemeType: window.__revSchemeType,
            libraryType: window.__libraryType,
            isClient: true,
            defaultBlacklistedRevisions: window.__defaultBlacklistedRevisions
        }
        validateField(parentRevision, validations.component.revision, parentRevision.value, validationPayload);
        if (!parentRevision.valid && !errorFound)
        {
            errorFound = true;
        }
        return errorFound;
    }

    validateChildInputs(state)
    {
        let errorFound = false;
        let item;
        if (state.list && state.list.length > 0)
        {
            for (let i=0; i < state.list.length; i++) {
                item = state.list[i]
                if (item.status === "DESIGN")
                {
                    let revisionInput = item.nextRevisionInput;
                    let {status, revisions} = item;
                    let validationPayload = {
                        status,
                        revisions,
                        revSchemeType: window.__revSchemeType,
                        libraryType: window.__libraryType,
                        isClient: true,
                        isSaveAsRev: true,
                        defaultBlacklistedRevisions: window.__defaultBlacklistedRevisions
                    }
                    validateField(revisionInput, validations.component.revision, revisionInput.value, validationPayload);
                    if (!revisionInput.valid && !errorFound)
                    {
                        errorFound = true;
                        break;
                    }
                }
            }
        }
        return errorFound;
    }

    setHeader(state, errorFound, checkingForErrors=false)
    {
        if (checkingForErrors)
        {
            state.header.icon       = '';
            state.header.class      = 'grey';
            state.header.text       = 'Checking for errors...';
            state.continueBtn.class = 'disabled';
        }
        else if(errorFound)
        {
            state.header.icon       = '';
            state.header.class      = 'contain-errors';
            state.header.text       = 'Errors Found';
            state.continueBtn.class = 'disabled';
        }
        else
        {
            state.continueBtn.text  = 'Continue';
            state.header.class      = '';
            state.header.icon       = 'change-revision';
            state.header.text       = 'Set New Revision Values';
            state.continueBtn.class = '';
        }
    }

    remove(e, itemId)
    {
        let list = this.state.list;
        let state = this.state;
        let deletedItemIndex;
        list.forEach((item, i) => {
            if(itemId === item._id)
            {
                deletedItemIndex = i;
            }
        });

        list.splice(deletedItemIndex, 1);
        if(this.baseState && this.baseState.list)
        {
            this.baseState.list.splice(deletedItemIndex, 1);
        }
        state.syncTable = true;
        this.setHeader(state, false, true);
        this.setState(state);
    }

    afterSyncTable()
    {
        let state = this.state;
        let _this = this;
        state.syncTable = false;
        //Add some delay to display loading
        setTimeout(function(){
            let errorFoundInParent = _this.validateParentInputs(state);
            let errorFoundInChild = _this.validateChildInputs(state);
            _this.setHeader(state, errorFoundInChild || errorFoundInParent);
            _this.setState(state);
        }, 100);
    }

    afterSyncParentTable()
    {
        let state = this.state;
        let _this = this;
        state.syncParentTable = false;
        //Add some delay to display loading
        setTimeout(function(){
            let errorFoundInParent = _this.validateParentInputs(state);
            let errorFoundInChild = _this.validateChildInputs(state);
            _this.setHeader(state, errorFoundInChild || errorFoundInParent);
            _this.setState(state);
        }, 100);
    }

    createDataForUpdate(list)
    {
        let data = [];
        data = list.map((item) =>
        {
            if(item.checked) {
                if(item.status === 'DESIGN')
                {
                    let payload =
                        {
                            _id: item._id,
                            status: item.status,
                            revision: item.nextRevisionInput.value,
                            nextCoRevision: item.nextCoRevision,
                            saveAsRevision: true,
                            revisionComment: this.state.revisionComment.value,
                            lastModified: item.lastModified,
                            rolledUpCostAsPrimary: item.rolledUpCostAsPrimary,
                        }
                    return payload;
                }
            }
        });
        data = data.filter(function( element ) {
            return element !== undefined;
        });
        return data;
    }

    createRevisionForChilds(revisionUpdateCompletedCb)
    {
        let components      = this.getComponentWithDesign();
        components.sort((a,b) => (a.level > b.level) ? -1 : ((b.level > a.level) ? 1 : 0));
        let cmpData         = this.createDataForUpdate(components);
        this.updateComponentRevision(cmpData, revisionUpdateCompletedCb);
    }

    submit()
    {
        if (!this.isSubmitted)
        {
            Utils.setStore("lastAssemblyParent", null);
            this.isSubmitted = true;
            let state = this.state;
            state.continueBtn.stopLoading = false;
            state.continueBtn.class = "disabled";
            if (state.childRevisionFlag)
            {
                this.setState(state, this.createRevisionForChilds(this.updateRevisionOfParent));
            }
            else
            {
                this.updateRevisionOfParent();
            }
        }
    }

    updateRevisionOfParent()
    {
        let payload =
        {
            revisionComment: this.state.revisionComment.value,
            revision: this.state.parentRevision.value,
        }
        this.setState(this.state, this.props.onSubmit(null, payload));
    }

    updateComponentRevision(cmpData=[], revisionUpdateCompletedCb)
    {
        if(cmpData && cmpData.length > 0)
        {
            let counter = 0;
            let onComplete = (err, data) =>
            {
                counter++;
                if(counter === cmpData.length)
                {
                    revisionUpdateCompletedCb();
                }
                else
                {
                    create(counter);
                }
            }

            let create = (index) =>
            {
                API.components.update(cmpData[index]._id, cmpData[index], onComplete);
            }
            create(0);
        }
        else
        {
            revisionUpdateCompletedCb();
        }
    }

    getComponentWithDesign()
    {
        let state           = this.state;
        let list            = state.list;
        let components      = [];
        list.forEach((item) =>
        {
            if(item.alias === "cmp" && item.status === "DESIGN")
            {
                components.push(item);
            }
        });
        return components;
    }

    resetState()
    {
        this.state.resetBtn.stopLoading = false;
        this.setState(this.state);
        let shouldClickOnMasterCheckbox = this.isPresentCheckedRow(this.state.list) ? true : false
        let _this = this
        //Add some delay to display loading
        setTimeout(function(){
            _this.baseState = JSON.parse(JSON.stringify(_this.baseState));
            let state      = JSON.parse(JSON.stringify(_this.baseState));
            let list       = state.list;

            list.map((item) =>
            {
                item.nextRevisionInput  = {
                    value: item.nextCoRevision ? item.nextCoRevision : "1",
                    valid: true,
                    message: "",
                    class: ""
                }
            });
            state.syncTable             = true;
            state.syncParentTable       = true;
            state.list                  = list;
            state.continueBtn.stopLoading = true;
            state.resetBtn.class        = 'disabled';
            state.resetBtn.stopLoading  = true;
            _this.setState(state);

            let el = document.getElementById('update-revision-holder');
            if (el) el.classList.add('disabled-child');

            let masterCheckbox = document.querySelector('.index-table thead .index-checkbox label');
            if (shouldClickOnMasterCheckbox && masterCheckbox)
            {
                masterCheckbox.click()
            }

        }, 100);
    }

    afterExtendedTableRender()
    {
        var el = document.getElementById('update-revision-holder');
        el.classList.add('mx-width');
        if (el && this.state.childRevisionFlag)
        {
            el.classList.remove('disabled-child');
            el.classList.add('enabled-child');
        }
        else
        {
            el.classList.add('disabled-child');
            el.classList.remove('enabled-child');
        }
    }

    beforeExtendedTableHandleLoad()
    {
        var el = document.getElementById('update-revision-holder');
        el.classList.remove('mx-width');
    }

    render()
    {
      let {headings, parentHeadings} = this.state;
      let rows = this.getTableRows();
      let parentRow = this.getParentTableRow();
      let state = this.state;

      let markup   =
        <div className={"update-revision-screen-modal "}>
            <ModalBox onClose={(e) => {this.props.showHideSaveAsRevisionModal(false)}} bodyClass="update-revision-bd">
                <div className="update-status-screen">
                    <div className="form-holder">
                            <header className={`lable-mapped modal-header ${state.header.class}`}>
                                <div className="header-heading">
                                    <Icon
                                        src={state.header.icon == "change-revision" ? revsSrc : FlagIcon}
                                        className={state.header.icon !== "change-revision" ? "error-icon" : ""}
                                    />
                                    <h3>{state.header.text}</h3>
                                </div>

                                <div className="btn-holder">
                                    <div className="btn-holder-inner">
                                       <SubmitBtn
                                        onClick={this.resetState}
                                        className={`btn-header ${state.resetBtn.class} reset-btn`}
                                        btnText={state.resetBtn.text}
                                        isDisabled={state.resetBtn.class === 'disabled'}
                                        stopLoading={state.resetBtn.stopLoading}
                                                />
                                       <SubmitBtn
                                          onClick={this.submit}
                                          className={`btn-header ${(state.continueBtn.class === 'disabled' ? "disabled" : "active")} ${(state.continueBtn.stopLoading ? '' : 'pl-15')}`}
                                          btnText={state.continueBtn.text}
                                          isDisabled={state.continueBtn.class === 'disabled'}
                                          stopLoading={state.continueBtn.stopLoading}
                                        />
                                    </div>
                                </div>

                            </header>
                            <div className="update-status-screen-content-holder">
                                <div className="modal-heading">
                                    <h1>Save New Revision</h1>
                                    <Link
                                        to={'https://duro.zendesk.com/hc/en-us/articles/360029931491-Lifecycle-Status-and-Revision'}
                                        target="_blank"
                                    >
                                    <InlineIcon><HelpIcon/></InlineIcon>
                                    </Link>
                                </div>

                                <div>
                                    <p>Set the new revision and add any notes here. You can also set new revisions of any child components.
                                    </p>
                                </div>

                                <div className={`required ${this.props.editPage ? 'edit-page-required' : ''}`}>
                                    <div className="field-holder">
                                        <div className="fields">
                                        <span  className={'field-input'}>
                                            <LazyInput
                                                className={state.revisionComment.class}
                                                data-tip={state.revisionComment.message}
                                                data-place="right"
                                                data-type="error" type="text"
                                                value={state.revisionComment.value}
                                                onChange={(e) => this.onInputChange(e)} name="revision-comment"
                                                placeholder="Enter Notes"
                                            />
                                          </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="child-revision-label">
                                    <div className="child-revision-label-holder">
                                        <input
                                            type="checkbox"
                                            name="check-child-revision"
                                            data-tip="Set revision value for child components"
                                            data-place="top"
                                            onClick={this.setChildRevisionFlag}
                                            checked={state.childRevisionFlag ? true : false}
                                            defaultChecked={false}
                                        />
                                        <label
                                            htmlFor="check-child-revision"
                                        />
                                        <div className="label-heading">
                                            Include child components
                                        </div>
                                    </div>
                                </div>
                                {
                                    <div id="update-parent-revision-holder">
                                        <ExtendedTable
                                            wrapperClassName="update-revision-table"
                                            wrapperSelectorClass="parent-revision-table"
                                            stylesName="updateParentRevisionTable"
                                            themeDataTableId={"updateParentRevisionTable"}
                                            headings={parentHeadings}
                                            rows={parentRow || []}
                                            currentSortItem={state.currentForParent}
                                            currentSortItemAscending={state.currentSortItemAscendingForParent}
                                            afterSyncWithParentState={this.afterSyncParentTable}
                                            syncWithParentState={state.syncParentTable}
                                            includeToolBar={true}
                                            borderedTable={true}
                                            resultText={`${parentRow.length} ${this.props.parent.alias === "cmp" ? "Components" : "Products"} `}
                                          />
                                    </div>
                                }
                                <div className='child-cmp-heading'>
                                   CHILD COMPONENTS
                                </div>
                                {
                                    state.innerLoading ?
                                    <Spinner/> :
                                     <div id="update-revision-holder">
                                        <PerfectScrollbar
                                            className="variant-modal-scroll"
                                        >

                                              <ExtendedTable
                                                wrapperClassName="update-revision-table"
                                                wrapperSelectorClass="revision-table"
                                                stylesName="updateRevisionTable"
                                                themeDataTableId={"updateRevisionTable"}
                                                headings={headings}
                                                rows={rows || []}
                                                afterExtendedTableRender={this.afterExtendedTableRender}
                                                afterExtendedTableHandleLoad={this.afterExtendedTableRender}
                                                beforeExtendedTableHandleLoad={this.beforeExtendedTableHandleLoad}
                                                afterSyncWithParentState={this.afterSyncTable}
                                                syncWithParentState={state.syncTable}
                                                currentSortItem={state.current}
                                                currentSortItemAscending={state.currentSortItemAscending}
                                                footerRow={rows.length === 0 && {
                                                    dataCellEl: this.props.editPage ?
                                                                <p>No components in assembly</p> :
                                                                <p>No component selected</p>
                                                    }
                                                }
                                                includeToolBar={true}
                                                borderedTable={true}
                                                allowRowSelect={true}
                                                onSelectAllRows={this.onSelectAllRows}
                                                onRowSelect={this.onRowSelect}
                                                resultText={`${rows.length} Components`}
                                                customCTAIcon={
                                                    {
                                                        htmlBody:
                                                          <div className="set-bulk-revision-holder">
                                                            <span>Bulk Set Revision</span>
                                                            <LazyInput
                                                                type="text"
                                                                value={state.bulkRevisionValue}
                                                                className={`${state.bulkRevisionApplyBtn.class === 'disabled' ? "disabled" : ""} revision-input ${this.revisionScheme}`}
                                                                onChange={(e) => this.onBulkRevisionChange(e)}
                                                                name="bulk-revision-input"
                                                                autoComplete="off"
                                                            />

                                                            <SubmitBtn
                                                              onClick={this.applyBulkRevison}
                                                              className={state.bulkRevisionApplyBtn.class}
                                                              btnText={state.bulkRevisionApplyBtn.text}
                                                              isDisabled={state.bulkRevisionApplyBtn.class === 'disabled'}
                                                              stopLoading={state.bulkRevisionApplyBtn.stopLoading}
                                                            />
                                                         </div>
                                                    }
                                                }
                                              />
                                        </PerfectScrollbar>
                                    </div>
                                }
                            </div>
                    </div>
                </div>
            </ModalBox>
        </div>
      return markup
    }
}

export default UpdateRevisionModal
