import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="16"
      viewBox="0 0 19 16"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#888" stroke="#888" transform="translate(-1124 -162)">
          <g transform="translate(1102 80)">
            <g transform="translate(23 81)">
              <g transform="translate(0 2)">
                <g fillRule="nonzero" strokeWidth="0.35">
                  <path d="M11.455 2.545a2.545 2.545 0 10-2.546 2.546 2.565 2.565 0 002.546-2.546zm-4.308 0a1.762 1.762 0 113.524 0 1.762 1.762 0 01-3.524 0zM17.818 11.44a3.99 3.99 0 00-1.804-3.364 3.886 3.886 0 00-3.771-.265 4.552 4.552 0 00-3.324-1.447c-1.258 0-2.46.523-3.324 1.447a3.886 3.886 0 00-3.786.252A3.99 3.99 0 000 11.44V14h17.818v-2.56zM.766 13.223v-1.785a3.213 3.213 0 011.376-2.647 3.128 3.128 0 012.935-.34 4.659 4.659 0 00-.766 2.58v2.192H.766zm9.58 0H5.077v-2.192c0-2.143 1.716-3.88 3.832-3.88 2.116 0 3.832 1.737 3.832 3.88v2.192h-2.395zm6.725 0h-3.564v-2.192a4.659 4.659 0 00-.766-2.58 3.128 3.128 0 012.935.34 3.213 3.213 0 011.376 2.647l.019 1.785zM14 6.788a2.121 2.121 0 100-4.243 2.121 2.121 0 000 4.243h0zm0-3.492a1.37 1.37 0 110 2.741 1.37 1.37 0 010-2.74h0zM5.94 4.667a2.121 2.121 0 10-4.243 0 2.121 2.121 0 004.242 0zm-3.492 0a1.37 1.37 0 112.74 0 1.37 1.37 0 01-2.74 0h0z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
