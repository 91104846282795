import { useCallback, useMemo, useState } from "react";
import { Box, IconButton, Popover, PopoverOrigin, styled, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { TextSnippetIcon } from "assets/icons/textSnippet";
import { Lot } from "build/models";
import { DateTimeFieldFormat, TimestampField } from "common/components/fields";
import { Link } from "common/components/link";
import { colorPalette } from "theme";

const LotNotesTransformOrigin: PopoverOrigin = {
  horizontal: "right",
  vertical: "bottom",
};

export type DesignLotsRenderCellArgs = GridRenderCellParams<any, {
  item: {
    lot: Lot,
  },
}>;

const customLotNumberStyle = { color: colorPalette.purple };

export function lotCreatedDateRenderCell({ row }: DesignLotsRenderCellArgs) {
  if (!row.item?.lot) return "";
  return (
    <TimestampField dateTime={row.item.lot.createdDate} format={DateTimeFieldFormat.DATE_TIME_LONG} />
  );
}

export function lotDueDateRenderCell({ row }: DesignLotsRenderCellArgs) {
  if (!row.item?.lot) return "";
  return (
    <TimestampField dateTime={row.item.lot.dueDate} format={DateTimeFieldFormat.DATE_LONG} />
  );
}

export function LotNotesRenderCell(props: DesignLotsRenderCellArgs) {
  const { row, colDef } = props;
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const maxTextWidth = (colDef.width as number) * 0.09;

  const notes = row?.item?.lot?.notes || "";
  const truncatedText = useMemo(() => (notes.length > Math.floor(maxTextWidth)
    ? `${notes.substring(0, Math.floor(maxTextWidth))}...`
    : notes), [maxTextWidth, notes]);

  const togglePopup = useCallback(event => {
    setAnchorEl(event.currentTarget);
    setIsPopupOpen(!isPopupOpen);
  }, [isPopupOpen, setIsPopupOpen]);

  if (!notes) return "";

  return (
    <>
      <LotNotesCell>
        <LotNotesText maxTextWidth={`${maxTextWidth / 16}rem`}>{truncatedText}</LotNotesText>
        {notes.length > Math.floor(maxTextWidth) && (
          <CustomIconButton
          onClick={togglePopup}>
            <TextSnippetIcon />
          </CustomIconButton>
        )}
      </LotNotesCell>
      {notes.length > Math.floor(maxTextWidth) && (
        <Popover
          anchorEl={anchorEl}
          onClose={togglePopup}
          open={isPopupOpen}
          transformOrigin={LotNotesTransformOrigin}
        >
          <LotNotesPopover>
            <LotNotesPopoverText>
              {row?.item?.lot?.notes}
            </LotNotesPopoverText>
          </LotNotesPopover>
        </Popover>
      )}
    </>
  );
}

export function lotRenderCell({ row }: DesignLotsRenderCellArgs) {
  if (!row.item?.lot) return "";
  const { id, number } = row.item.lot;
  const url = `/build/lot/${id}`;
  return (
    <Link customStyle={customLotNumberStyle} to={url}>{number.displayValue}</Link>
  );
}

const LotNotesCell = styled(Box)(() => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
}));

const LotNotesPopover = styled(Box)(() => ({
  backgroundColor: colorPalette.blackRussian,
  height: "10rem",
  overflowY: "scroll",
  scrollbarWidth: "thin",
  width: "15rem",
  "&::-webkit-scrollbar": {
    width: "0.3rem",
  },
}));

const LotNotesPopoverText = styled(Box)(() => ({
  padding: "0.5rem 1rem",
}));

const CustomIconButton = styled(IconButton)(() => ({
  "&:hover": {
    color: colorPalette.purple,
  },
}));

const LotNotesText = styled(Typography, {
  shouldForwardProp: propName => propName !== "maxTextWidth",
})<{ maxTextWidth: string }>(({ maxTextWidth }) => ({
  width: maxTextWidth,
}));
