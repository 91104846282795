import React            from "react"

class TextArea extends React.PureComponent
{
    constructor(props)
    {
        super(props)
        this.state = {
            typingTimeout: 0,
            inputValue: this.props.value || ''
        }
        this.onInputChange = this.onInputChange.bind(this)
    }

    onInputChange(event){

        if (this.state.typingTimeout) {
           clearTimeout(this.state.typingTimeout);
        }
        let _this = this
        let _event = {...event}

        this.setState({
           inputValue: event.target.value,
           typingTimeout: setTimeout(function () {
               _this.props.onChange(_event)
             }, 800)
        });
    }

    componentWillReceiveProps(nextProps)
    {
        if (nextProps.forceResetChildState)
        {
            this.setState({inputValue: ""})
        }
    }

    render()
    {
        const inputProps = Object.assign({}, this.props);
        delete inputProps.value;
        delete inputProps.onChange;

        let markup =
            <textarea
                type="text"
                onChange={this.onInputChange}
                value={this.state.inputValue || ''}
                data-input-value={this.props.value || ''}
                {...inputProps}
            />
        return markup
    }
}

export default TextArea
