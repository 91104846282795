import                                                  "./index.css"
import Link                           from "../../../../ui/link"
import Icon                           from "../../../../ui/icon"
import InlineIcon                     from "../../../../ui/icon/inline-icon.js"
import Utils                          from "../../../../../modules/utils"
import DeleteSrc                      from "../../../../../assets/icons/cross-icon.js"
import coAlertSrc                     from "../../../../../assets/icons/co-alert.svg"
import ArrowIcon                      from "../../../../../assets/icons/co-small-arrow.js"
import editSrc                        from "../../../../../assets/icons/edit.svg"
import ExtendedTable                  from "../../../common/extended-table"
import validations, { validateField } from "../../../../../modules/validations"
import React, {Component}             from "react"
import WhereUsedIcon                  from "../../../../../assets/icons/where-used"
import WhereUsedModal                 from "../../../common/view-action-items/where-used-modal"
import ValidationRunningModal         from "../../../common/validation-running-modal";
import API                            from "../../../../../modules/api";
import Schemas                        from "../../../../../modules/schemas";
import roundPlusSrc                   from "../../../../../assets/icons/round-plus.svg";
import buildAction                    from "../../../../../helpers/buildAction";
import CO                             from '../../../../../action-types/changeorder';
import Spinner                        from "../../../../../assets/icons/spinner-green.js";
import Status                         from "./status";
import Tooltip                        from "rc-tooltip";
import LinkIcon                       from "../../../../../assets/icons/open-link-icon.js";
import RevActionModal                 from "../rev-action-modal"
import LazyInput                      from "../../../../ui/lazy-input/input.js"
import config                         from "../../../../../modules/config"
import TimeStampColumn                from "../../../common/timestamp-column";
import RevisionField                  from "../../../common/revision-field";
import CpnField                       from "../../../common/cpn-field";
import StatusField                    from "../../../common/status-field";
import ValidationIcon                 from "../../../common/change-order/validation-icon";
import UpdateStatusModal              from "../../../common/update-status-modal"
import UpdateStatusIcon               from "../../../../../assets/icons/update-status-icon"
import UI                             from "../../../../../action-types/ui"
import { publicPaths } from "v1/app/routes";

class List extends Component
{
    constructor(props)
    {
        super(props)
        let viewStyles;
        let isRevisionTypeDefault = Utils.isRevisionTypeDefault()
        let revisionColumnMinWidth = isRevisionTypeDefault ? 120 : 190

        try
        {
            viewStyles = window.__userStyles.styles.changeOrderAssemblyTableForm || {}
        }
        catch(error) {
            viewStyles = {}
        }
        this.revisionScheme = isRevisionTypeDefault ? "default-rev-scheme" : "custom-rev-scheme"
        this.state =
        {
            list : [],
            search :
            {
                selections : []
            },
            validationModal: {
                open : false
            },
            current : "cpn",
            currentSortItemAscending: true,
            headings :
            [
                {
                    key         : "cpn",
                    displayName : "CPN",
                    tooltip     : "CPN",
                    sortable    : true,
                    minWidth    : 120,
                    width       : Utils.getStyleValue(viewStyles, "cpn", "width", 120),
                    position    : Utils.getStyleValue(viewStyles, "cpn", "position", 0),
                    visibility  : Utils.getStyleValue(viewStyles, "cpn", "visibility", true),
                    disabled    : true
                },
                {
                    key         : "name",
                    displayName : "Name",
                    tooltip     : "Name",
                    sortable    : true,
                    minWidth    : 60,
                    width       : Utils.getStyleValue(viewStyles, "name", "width", 100),
                    position    : Utils.getStyleValue(viewStyles, "name", "position", 1),
                    visibility  : Utils.getStyleValue(viewStyles, "name", "visibility", true),
                    disabled    : true

                },
                {
                    key         : "description",
                    displayName : "Description",
                    tooltip     : "Description",
                    sortable    : true,
                    minWidth    : 60,
                    width       : Utils.getStyleValue(viewStyles, "description", "width", 60),
                    position    : Utils.getStyleValue(viewStyles, "description", "position", 2),
                    visibility  : Utils.getStyleValue(viewStyles, "description", "visibility", true),
                    disabled    : false

                },
                {
                    key         : "revision",
                    displayName : "Revision",
                    tooltip     : "Revision",
                    sortable    : true,
                    minWidth    : revisionColumnMinWidth,
                    width       : Utils.getStyleValue(viewStyles, "revision", "width", revisionColumnMinWidth),
                    position    : Utils.getStyleValue(viewStyles, "revision", "position", 3),
                    visibility  : Utils.getStyleValue(viewStyles, "revision", "visibility", true),
                    disabled    : false

                },
                {
                    key         : "status",
                    displayName : "Status",
                    tooltip     : "Status",
                    sortable    : true,
                    minWidth    : 60,
                    width       : Utils.getStyleValue(viewStyles, "status", "width", 275),
                    position    : Utils.getStyleValue(viewStyles, "status", "position", 4),
                    visibility  : Utils.getStyleValue(viewStyles, "status", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "lastUpdated",
                    displayName : "Last Updated",
                    tooltip     : "Last Updated",
                    sortable    : true,
                    minWidth    : 170,
                    width       : Utils.getStyleValue(viewStyles, "lastUpdated", "width", 170),
                    position    : Utils.getStyleValue(viewStyles, "lastUpdated", "position", 5),
                    visibility  : Utils.getStyleValue(viewStyles, "lastUpdated", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "eid",
                    displayName : "EID",
                    tooltip     : "EID",
                    sortable    : true,
                    minWidth    : 71,
                    width       : Utils.getStyleValue(viewStyles, "eid", "width", 145),
                    position    : Utils.getStyleValue(viewStyles, "eid", "position", 6),
                    visibility  : Utils.getStyleValue(viewStyles, "eid", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "lastRelease",
                    displayName : "Last Release",
                    tooltip     : "Last Release",
                    sortable    : true,
                    minWidth    : 60,
                    width       : Utils.getStyleValue(viewStyles, "lastRelease", "width", 100),
                    position    : Utils.getStyleValue(viewStyles, "lastRelease", "position", 7),
                    visibility  : Utils.getStyleValue(viewStyles, "lastRelease", "visibility", true),
                    disabled    : false
                },
                {
                    key              : "remove",
                    displayName      : "",
                    sortable         : false,
                    minWidth         : 30,
                    width            : 30,
                    visibility       : true,
                    headingClass     : "remove-col",
                    disabled         : true,
                    dragable         : false,
                    expandAble       : false,
                    hideFromSettings : true
                }
            ],
            whereUsedModal: {
                open : false,
                object : ''
            },
            revActionModal: {
                open : false,
                objectId : ''
            },
            selectedResults: {
                cmp: [],
                prd: [],
            },
            clearSelectedRows: false,
            displayStatusModal: false,
        }

        this.initialState                    = this.state;
        this.getTableRows                    = this.getTableRows.bind(this);
        this.onInputChange                   = this.onInputChange.bind(this);
        this.onRowClick                      = this.onRowClick.bind(this);
        this.remove                          = this.remove.bind(this);
        this.openWhereUsedModal              = this.openWhereUsedModal.bind(this);
        this.closeWhereUsedModal             = this.closeWhereUsedModal.bind(this);
        this.setModalPosition                = this.setModalPosition.bind(this);
        this.syncExtentendTable              = this.syncExtentendTable.bind(this);
        this.afterSyncWithParentState        = this.afterSyncWithParentState.bind(this);
        this.showHideRevision                = this.showHideRevision.bind(this);
        this.onStatusChange                  = this.onStatusChange.bind(this);
        this.openValidationModal             = this.openValidationModal.bind(this);
        this.reRunValidation                 = this.reRunValidation.bind(this);
        this.createSourceValidationTooltip   = this.createSourceValidationTooltip.bind(this);
        this.createParentWarningTooltip      = this.createParentWarningTooltip.bind(this);
        this.createLowerStatusErrorTooltip   = this.createLowerStatusErrorTooltip.bind(this);
        this.openRevisionActionModal         = this.openRevisionActionModal.bind(this);
        this.setRevActionModalPosition       = this.setRevActionModalPosition.bind(this);
        this.closeRevActionModal             = this.closeRevActionModal.bind(this);
        this.applyRevAction                  = this.applyRevAction.bind(this);
        this.reCheckCO                       = this.reCheckCO.bind(this);
        this.captureCoTypeChange             = this.captureCoTypeChange.bind(this);
        this.showValidationTooltip           = this.showValidationTooltip.bind(this);
        this.addToSelectedList               = this.addToSelectedList.bind(this);
        this.selectAllRows                   = this.selectAllRows.bind(this);
        this.getIconsActionsList             = this.getIconsActionsList.bind(this);
        this.removeBulk                      = this.removeBulk.bind(this);
        this.createRecheckTooltip            = this.createRecheckTooltip.bind(this);
        this.createLastModifiedToolTip       = this.createLastModifiedToolTip.bind(this);
        this.displayUpdateStatusModal        = this.displayUpdateStatusModal.bind(this);
        this.hideUpdateStatusModal           = this.hideUpdateStatusModal.bind(this);
        this.refreshAfterBulkUpdate          = this.refreshAfterBulkUpdate.bind(this);
        this.getData                         = this.getData.bind(this);
        this.revisionFieldInCO               = this.revisionFieldInCO.bind(this);
    }

    applyRevAction(revActionType, item, i, nextRevValue=null)
    {
        let state = this.state
        let list = state.list
        let inputItem = list[i]

        if (revActionType && revActionType !== "custom")
        {
            inputItem.revActionType = revActionType
            let currentRevision = item.revision
            let validationPayload = {status: item.status, revSchemeType: window.__revSchemeType, libraryType: window.__libraryType, revActionType, currentRevision}

            let revValue = validations.component.revision.normalize(validationPayload, currentRevision).revision
            inputItem.nextCoRevision = revValue
            inputItem.nextRevisionInput.value = revValue
            inputItem.forceUpdate = true
        }
        else
        {
            inputItem.revActionType = revActionType
            inputItem.nextCoRevision = nextRevValue
            inputItem.nextRevisionInput.value = nextRevValue
            inputItem.forceUpdate = true
        }
        this.setState(state, this.onChange)
    }

    syncExtentendTable()
    {
        let state = this.state
        state.syncWithParentState = true
        this.setState(state)
    }

    afterSyncWithParentState()
    {
        let state = this.state
        state.syncWithParentState = false
        state.clearSelectedRows   = false
        this.setState(state)
    }

    openWhereUsedModal(event, item)
    {
        let element = event.target
        let parentTableEl = document.getElementsByClassName("co-list extended-table")[0]

        var elementPos = element.getBoundingClientRect();
        var parentPos = parentTableEl.getBoundingClientRect();

        let top  = elementPos.top - parentPos.top
        let left = elementPos.left - parentPos.left
        left = left + 30

        let state = this.state
        let whereUsedModal  = state.whereUsedModal
        whereUsedModal.open = true
        whereUsedModal.object = item
        whereUsedModal.top = top
        whereUsedModal.left = left
        this.setState(state)
    }

    openRevisionActionModal(event, item, index)
    {
        let element = event.target
        let parentTableEl = document.getElementsByClassName("co-list extended-table")[0]

        var elementPos = element.getBoundingClientRect();
        var parentPos = parentTableEl.getBoundingClientRect();

        let top  = elementPos.top - parentPos.top
        let left = elementPos.left - parentPos.left
        left = left + 30

        let state = this.state
        let revActionModal  = state.revActionModal
        revActionModal.open = true
        revActionModal.item = item
        revActionModal.top = top
        revActionModal.left = left
        revActionModal.index = index
        this.setState(state)
    }

    setModalPosition()
    {
        let state = this.state
        let whereUsedModal  = state.whereUsedModal
        let whereUsedModalElement = document.getElementsByClassName("where-used-modal")[0]
        if (whereUsedModalElement)
        {
            let offsetHeight = whereUsedModalElement.offsetHeight
            whereUsedModalElement.style.top = ( whereUsedModal.top - ( offsetHeight/2 ) ) + 'px'
            whereUsedModalElement.style.left = ( whereUsedModal.left ) + 'px'
        }
    }

    openValidationModal(event=null)
    {
        this.props.dispatch(buildAction(UI.VALIDATION_START))
    }

    setRevActionModalPosition()
    {
        let state = this.state
        let revActionModal  = state.revActionModal
        let revActionModalElement = document.getElementsByClassName("rev-action-modal")[0]
        if (revActionModalElement)
        {
            let offsetHeight = revActionModalElement.offsetHeight
            revActionModalElement.style.top = ( revActionModal.top - ( offsetHeight/2 )) + 'px'
            revActionModalElement.style.left = ( revActionModal.left - 240 ) + 'px'
        }
    }

    closeWhereUsedModal()
    {
        let whereUsedModal  = this.state.whereUsedModal
        whereUsedModal.open = false
        this.setState(this.state)
    }

    closeRevActionModal()
    {
        let revActionModal  = this.state.revActionModal
        revActionModal.open = false
        this.setState(this.state)
    }

    shouldReloadExtendedTable(nextPropslistRow, statelistRow, propsShowLoading, nextPropsShowLoading)
    {
        return nextPropslistRow.warning !== statelistRow.warning
                || nextPropslistRow.showRevision !== statelistRow.showRevision
                || nextPropslistRow.error !== statelistRow.error
                || nextPropslistRow.sourcingError !== statelistRow.sourcingError
                || (propsShowLoading && !nextPropsShowLoading)
                || nextPropslistRow.co !== statelistRow.co ;
    }

    componentWillReceiveProps(nextProps)
    {
      let state = this.state;
      if (nextProps.list.length !== state.list.length)
      {
        let list = JSON.parse(JSON.stringify(nextProps.list));
        this.setState({list})
      }
      else if(nextProps.list.length > 0 && nextProps.list.length === state.list.length)
      {
        for(let i=0; i<state.list.length; i++)
        {
            let reloadExtendedTable = this.shouldReloadExtendedTable(nextProps.list[i], state.list[i], this.props.showLoading, nextProps.showLoading);
            if(reloadExtendedTable)
            {
                let list = JSON.parse(JSON.stringify(nextProps.list));
                let syncWithParentState = true;
                this.setState({list, syncWithParentState});
                break;
            }
        }
      }
      if(this.props.showLoading !== nextProps.showLoading && nextProps.showLoading && nextProps.list.length == state.list.length) this.openValidationModal();
      else if(this.props.showLoading && !nextProps.showLoading) this.props.dispatch(buildAction(UI.VALIDATION_END))

      this.captureCoTypeChange(nextProps);
    }

    captureCoTypeChange(nextProps)
    {
        let state = this.state;
        let props = this.props;

        if (nextProps.coType !== props.coType)
        {
            let isCoTypeChangedToDco = nextProps.coType && nextProps.coType.toUpperCase() === 'DCO'
            let isCoTypeChangedFromDco = props.coType && props.coType.toUpperCase() === 'DCO'

            if (isCoTypeChangedToDco)
            {
                for(let i=0; i<state.list.length; i++)
                {
                    let item = state.list[i]
                    item.index = i
                    if (item.originalStatus !== item.status)
                    {
                        this.onStatusChange(item.originalStatus, item);
                    }
                }
            }

            if (isCoTypeChangedToDco || isCoTypeChangedFromDco)
            {
                props.dispatch(buildAction(CO.GET_CHANGE_ORDER_LIST_DATA));
                this.syncExtentendTable();
            }

        }
    }

    componentWillMount()
    {
        if(this.props.list.length > 0)
        {
            let list = JSON.parse(JSON.stringify(this.props.list));
            let syncWithParentState = true;
            this.setState({list, syncWithParentState});
        }
    }

    onRowClick(e, to)
    {
        if (e.target.parentElement.name !== "where-used")
        {
            var win = window.open(to, '_blank');
        }
    }

    add()
    {
        if(this.state.search.selections.length < 1) return
        let search      = this.state.search
        let list        = this.state.list
        this.state.list = [...list, ...search.selections]
        this.setState(this.state, this.onChange)
        this.stopSearch()
    }

    onInputChange(e, item, i, field)
    {
        let state = this.state
        let list = state.list
        let inputItem = list[i]

        let value = e.target.value.toUpperCase()
        let input = inputItem.nextRevisionInput

        //To skip the greater than validations when user switch status from DESIGN -> PROTOTYPE
        let isClient = window.__revSchemeType === "DEFAULT" && inputItem.previousStatus === "DESIGN" && inputItem.status === "PROTOTYPE" &&  inputItem.previousRevision === value ? false : true
        validateField(input, validations.component.revision, value, {status: inputItem.status, revSchemeType: window.__revSchemeType, libraryType: window.__libraryType, isClient, previousRevision: inputItem.previousRevision, defaultBlacklistedRevisions: window.__defaultBlacklistedRevisions})
        this.setState(state, this.onChange)

    }

    onStatusChange(value, item, newNextRevision)
    {
        const inputItem = this.state.list[item.index];
        inputItem.status = value;

        const currentRevision  = inputItem.revision;
        const previousRevision = Utils.getPreviousRevision(inputItem)
        let validationPayload = {status: value, revSchemeType: window.__revSchemeType, libraryType: window.__libraryType, currentRevision, isClient: true, previousRevision, revisionBump: false}

        if(newNextRevision) {
            inputItem.nextRevisionInput.value = newNextRevision;
        }
        else if(!inputItem.modified && inputItem.status === inputItem.originalStatus && inputItem.status === inputItem.previousStatus) {
            Utils.getRevisionBump(inputItem,validationPayload);
            Utils.validateRevisionFieldOnStatusChange(inputItem, validationPayload, inputItem.nextRevisionInput.value);
        }
        else if(inputItem.modified && inputItem.previousStatus === inputItem.status && inputItem.status !== inputItem.originalStatus) {
            inputItem.nextCoRevision = inputItem.previousRevision ? inputItem.previousRevision : inputItem.nextCoRevision;
            inputItem.nextRevisionInput.value = item.nextCoRevision;
            Utils.validateRevisionFieldOnStatusChange(inputItem, validationPayload, inputItem.nextRevisionInput.value);
        }
        else
            Utils.validateRevisionFieldOnStatusChange(inputItem, validationPayload);
        return inputItem
    }

    addToSelectedList(event, result)
    {
        let state = this.state
        let index = state.selectedResults[result.alias].findIndex(({_id}) => _id === result._id);

        if (event.target.checked === true)
        {
            if(!(index > -1)) {
                state.selectedResults[result.alias].push(result)
            }
        }
        else if (event.target.checked === false)
        {
            if (index > -1) {
               state.selectedResults[result.alias].splice(index, 1)
            }
        }
        this.setState(state)
    }

    selectAllRows(shouldSelectAll)
    {
        const state = this.state
        let list = state.list;

        state.selectedResults = {
            prd: [],
            cmp: [],
        }
        if (shouldSelectAll)
        {
            list.forEach(item => state.selectedResults[item.alias].push(item))
        }
        this.setState(state);
    }

    getIconsActionsList()
    {
        const actionsList = [];
        let actionEntry;
        const {
            prd: selectedProducts,
            cmp: selectedComponents,
        } = this.state.selectedResults;
        const areItemsSelected      = selectedProducts.length || selectedComponents.length;
        const isRemoveIconActive    = areItemsSelected && !this.props.refRelease;

        actionEntry =
        {
            type          : 'action',
            iconSrc       : <UpdateStatusIcon />,
            toolTip       : "Update Status",
            name          : "Update Status",
            onClick       : (areItemsSelected ? this.displayUpdateStatusModal : null),
            active        : (areItemsSelected),
        };
        actionsList.push(actionEntry);

        actionEntry =
        {
            type          : 'action',
            iconSrc       : <DeleteSrc useCurrentColor={true} />,
            toolTip       : "Remove Products and Components from the Change Order",
            name          : "Remove",
            onClick       : (isRemoveIconActive ? this.removeBulk : null),
            className     : `remove-icon ${(isRemoveIconActive ? "" : "muted-text")}`,
            active        : (isRemoveIconActive),
            iconClassName : (isRemoveIconActive ? "" : "disabled"),
        };
        actionsList.push(actionEntry);
        return actionsList;
    }

    showHideRevision(i)
    {
        let state = this.state
        let list = state.list
        let inputItem = list[i]
        inputItem.showRevision = !inputItem.showRevision;
        this.setState(state, this.onChange);
    }

    reRunValidation(item)
    {
        this.openValidationModal();
        let list = this.state.list;
        let alias = item.alias === "cmp" ? "components" : item.alias ===  "prd" ? "products" : "";
        let id= item._id;
        if(item.lastModifiedError)
        {
            API[alias].findById(item._id, (err, res) => {
                let validationModal = document.getElementsByClassName("validation-running");
                validationModal.length > 0 && this.props.dispatch(buildAction(UI.VALIDATION_END));
                let index = list.findIndex((cmp) => cmp._id === res._id);
                if(index !== -1)
                {
                    list[index] = {...item, ...res};
                    list[index].lastModifiedError = false;
                }
                this.setState({list}, () => this.onChange(true, false));
            })
        }
        else
        {
            API[alias].validateManufacturers([{id, status: item.status, alias: item.alias}], (err, res) =>
            {
                let {data} = res;
                if(data)
                {
                    if(data.hasOwnProperty(item._id) && data[item._id].hasOwnProperty("error"))
                    {
                        this.props.dispatch(buildAction(UI.VALIDATION_END));
                    }
                    else
                    {
                        item = Utils.setManufacturersValidationInfo(item, data)
                        this.props.dispatch(buildAction(CO.SET_LOADING, {validationRunning: true}));
                        item.sourcingError = false;
                        this.setState({list}, () => this.onChange(false, true));
                    }
                }
                else
                {
                    this.props.dispatch(buildAction(UI.VALIDATION_END));
                }
            });
        }
    }

    excludeCurrentCO(itemsHavingCO, currentCoId, compId)
    {
        let coList = itemsHavingCO && itemsHavingCO.hasOwnProperty(compId) ? itemsHavingCO[compId] : [];
        if(coList && coList.length > 0) return coList.filter(function(coObject) { return coObject._id != currentCoId; });
        return coList;
    }

    reCheckCO(item)
    {
        this.openValidationModal();
        let list = this.state.list;
        let alias = item.alias === "cmp" ? "components" : item.alias ===  "prd" ? "products" : "";
        let id= item._id;
        API[alias].coExist({items: [id]}, (err, res) =>
        {
            if(res && res.data)
            {
                let itemsHavingCO = [];
                if(item.alias === "cmp" && res.data.componentsCO)
                {
                    itemsHavingCO = this.excludeCurrentCO(res.data.componentsCO, this.props.coId, id);
                }
                else if(item.alias === "prd" && res.data.productsCO)
                {
                    itemsHavingCO = this.excludeCurrentCO(res.data.productsCO, this.props.coId, id);
                }

                if(itemsHavingCO.length !== item.co.length )
                {
                    item.co = itemsHavingCO;
                    this.props.dispatch(buildAction(CO.SET_LOADING, {validationRunning: true}));
                    this.setState({list}, () => this.onChange(false, true));
                }
                else
                {
                    this.props.dispatch(buildAction(UI.VALIDATION_END))

                }
            }
            else
            {
                this.props.dispatch(buildAction(UI.VALIDATION_END))
            }
        })
    }

    remove(e, itemId)
    {
        e.stopPropagation();
        e.preventDefault();
        let list = this.state.list

        let deletedItemIndex = list.findIndex(item => itemId === item._id);
        if (deletedItemIndex === -1) return;
        let deletedItem = list[deletedItemIndex];

        list.splice(deletedItemIndex, 1);
        this.props.dispatch(buildAction(CO.SET_LOADING, {validationRunning: true}));
        this.setState(this.state, this.onChange(false, true));
        this.props.syncSearchInputsWithList(deletedItem)
    }

    removeBulk(e)
    {
        e.preventDefault()
        e.stopPropagation()
        const state                   = this.state;
        let { list, selectedResults} = state;
        const removedItems            = [...selectedResults.cmp, ...selectedResults.prd];

        if(list.length === removedItems.length)
        {
            state.list = []
        }
        else
        {
            removedItems.forEach(item => {
                let removedItemIndex = list.findIndex(itm => item._id === itm._id);
                if (removedItemIndex !== -1) state.list.splice(removedItemIndex, 1);
            })
        }
        state.selectedResults = {
            cmp: [],
            prd: [],
        }
        state.clearSelectedRows = true

        this.props.dispatch(buildAction(CO.SET_LOADING, {validationRunning: true}));
        this.setState(state, this.onChange(true, true));
        this.props.syncSearchInputsWithList(removedItems)
    }

    displayUpdateStatusModal()
    {
        this.setState({displayStatusModal: true});
    }

    hideUpdateStatusModal()
    {
        this.setState({displayStatusModal: false});
    }

    refreshAfterBulkUpdate(receivedList=null)
    {
        let {list, selectedResults} = this.state;
        Utils.resetLocalStorageForAssemblyTree();
        selectedResults = {
            prd: [],
            cmp: [],
        };
        this.setState({selectedResults, displayStatusModal: false});
        this.openValidationModal();
        let payload = [];
        for(let i = 0; i < receivedList.length; i++)
        {
            const j = list.findIndex(({_id}) => _id === receivedList[i]._id)
            if(j < 0) continue;
            const item = list[j];
            item.index = j
            const {status, revision} = receivedList[i]
            const inputItem = this.onStatusChange(status, item, revision);
            payload.push({id: inputItem._id, status: inputItem.status, alias: inputItem.alias});
        }
        this.validateSourcing(payload);
    }

    validateSourcing = (payload) => {
        const list = [...this.state.list];
        API.components.validateManufacturers(payload, (err, res) => {
           if(!err){ 
                const { data } = res;
                Object.keys(data).forEach((id) => {
                    let item = list.find((element) => element._id === id);
                    Utils.setManufacturersValidationInfo(item, data[id]);
                });
                this.props.dispatch(buildAction(CO.SET_LOADING, {validationRunning: true}));
                this.setState({list}, () => this.onChange(false, true));
            }else {
                setTimeout(() => {
                    this.props.dispatch(buildAction(CO.SET_LOADING, {validationRunning: true}));
                    this.setState({list}, () => this.onChange(false, true));
                }, 0)
            }
        });
    }

    onChange(callSyncExtentendTable=true, runValidation=false)
    {
        let event = Utils.getEvent(this, this.state.list)
        let listValid = true
        let list = this.state.list
        list.forEach((item) => {
            if(item.nextRevisionInput.valid === false || (item.co && item.co.length > 0) || item.warning || item.error || item.status === "DESIGN" || item.sourcingError)
                listValid = false;
        })
        event.target.listValid = listValid;
        this.props.onChange(event, runValidation);
        if(callSyncExtentendTable) this.syncExtentendTable();
    }

    createOpenCOList(item, coExist)
    {
        let data = "";
        let alias = item.alias === "cmp" ? "Component" : "Product";
        if(coExist)
        {
            item.tooltipMessage = `This ${alias} is included in one ore more existing open Change Orders. They must be resolved before submitting this new Change Order for approval.`;
            data = (
                <div><p>This {alias} is included in one or more existing open Change Orders. </p>
                    <p>They must be resolved before submitting this new Change Order for approval.</p>
                    <ul>
                        {item.co.map((co, i) =>
                            {
                                let link = `/changeorder/view/${co._id}`;
                                return <li>
                                        <Link to={link} target="_blank" className="open-link-holder" >
                                            <span className="link-text">{co.con}
                                                <InlineIcon >
                                                    <LinkIcon/>
                                                </InlineIcon>
                                            </span>
                                        </Link>
                                    </li>
                            })
                        }
                    </ul>
                    <div className="mt-15">
                        <button className="tooltip-button" onClick={() => this.reCheckCO(item)}>
                            Recheck
                        </button>
                    </div>
                </div>);
        }
        return data;
    }

    createParentWarningTooltip(item)
    {
      if(item.warning) {
        item.tooltipMessage = "Nested child Components must be at an equal or greater Status value than their parent assembly.";
      } else if (item.childObsoleteWarning) {
        item.tooltipMessage = "One or more child Components has a Status of Obsolete.";
      } else return "";

      return (
        <div>
          <p>{item.tooltipMessage}</p>
          <p>Add child Components and update their Status to resolve this issue.</p>
          <div className="mt-10">
            <button className="tooltip-button" onClick={() => this.props.getSpecificChildrenAssemblies(item)}>
              Add child Components
            </button>
          </div>
        </div>
      );
    }

    createSourceValidationTooltip(item, componentEditLink, productEditLink, lowerStatusErrorMessage)
    {
        let link = item.alias === "cmp" ? componentEditLink : productEditLink;
        let alias = item.alias === "cmp" ? "Component" : "Product";
        let markup = null;
        let tooltipDescription = null;
        let setTooltipContent = false;

        if (item.sourcingError)
        {
            item.tooltipMessage = "One or more issues were found in this Item. All issues must be resolved before submitting a Change Order for approval. Run the validation to confirm";
            setTooltipContent = true;
            tooltipDescription =
                <span>
                    <p>One or more issues were found in this {alias}. All issues must be resolved before submitting a Change Order for approval.</p>
                    <p>Go to the {alias} link below to correct errors.</p>
                </span>
        }
        else if (!window.__isPrimarySourceWarningDisabledInCO && !item.isPrimarySource && !lowerStatusErrorMessage)
        {
            item.tooltipMessage = `This ${alias} is missing a Primary Source selection. ${alias}s with a Status value of Prototype or Production should have a selected source to procure it.`;
            setTooltipContent = true;
            if (item.previousStatus === item.status)
            {
                link = `/${item.alias === "cmp" ? "component" : "product"}/edit/${item._id}?tab=sourcing`;
            }

            tooltipDescription =
                <span>
                    <p>This {alias} is missing a Primary Source selection.</p>
                    <p>{alias}s with a Status value of Prototype or Production should have a selected source to procure it.</p>
                </span>
        }

        if (setTooltipContent)
        {
            markup = this.createRecheckTooltip(item, tooltipDescription, link);
        }

        return markup
    }

    createLastModifiedToolTip(item, componentViewLink, productViewLink)
    {
        let link = item.alias === "cmp" ? componentViewLink : productViewLink;
        let alias = item.alias === "cmp" ? "Component" : "Product";
        let markup = null;
        let tooltipDescription = null;
        let setTooltipContent = false;

        if(item.lastModifiedError)
        {
            item.tooltipMessage = `This ${alias} has been modified since it was added to this Change Order.`;
            setTooltipContent = true;
            tooltipDescription =
                <span>
                    <p>This {alias} has been modified since it was added to this Change Order.</p>
                </span>
        }
        if(setTooltipContent)
        {
            markup = this.createRecheckTooltip(item, tooltipDescription, link, false);
        }

        return markup
    }

    createRecheckTooltip(item, tooltipDescription, link, isRecheck=true)
    {
        return <div>
                    {tooltipDescription}
                    <Link to={link} target="_blank" className="open-link-holder" >
                        <span className="link-text">{item.cpn}
                            <InlineIcon > <LinkIcon/> </InlineIcon>
                        </span>
                    </Link>
                    <div className="mt-10">
                        <button className="tooltip-button" onClick={() => this.reRunValidation(item)}>
                            {isRecheck ? "Recheck" : "Refresh"}
                        </button>
                    </div>
                </div>
    }

    getData(data)
    {
        let path = window.__userRole === 'VENDOR' ? 'revision' : 'view';
        let query = window.__userRole === 'VENDOR' ? 'lastReleasePrdRev' : '_id';
        if(query === 'lastReleasePrdRev' && data === "cmp"){
            query = 'lastReleaseCmpRev';
        }
        return {path ,query};
    }

    createLowerStatusErrorTooltip(item)
    {
        if((item.parentIndex && item.parentIndex < 0) || !item.error) return "";
        let index = item.parentIndex;
        let parent = this.state.list[index];
        let parentStatus = parent.status.toUpperCase();
        let { path, query} = this.getData(parent.alias);
        let link = parent.alias === "cmp" ? `/component/${path}/${parent[query]}` : `/product/${path}/${parent[query]}`;

        item.tooltipMessage = `Nested child Components must be at an equal or greater Status value than their parent assembly. This Component's parent has Status: ${parentStatus}`;
        return (<div>
                    <p>Nested child Components must be at an equal or greater Status value than their parent assembly. </p>
                    <p>This Component's parent has Status: <br /> <span className={"mt-5 status-label " + parentStatus}> {parentStatus}</span> </p>
                    <Link to={link} target="_blank" className="open-link-holder" >
                        <span className="link-text">{parent.cpn}
                            <InlineIcon > <LinkIcon/> </InlineIcon>
                        </span>
                    </Link>
                </div>)
    }

    showValidationTooltip(options)
    {
        let { i, item, coExist, coExistTolltip, parentWarning, isStatusDesignForCO, designStatusErrorMessage, sourceTooltipMessage, statusTooltipMessage, lastModifiedToolTipMessage } = options;
        let validationMarkup = null;
        let showValidation = true;
        let validationOverlay = null;
        let validationType = "warning";
        let validationTooltip = item.tooltipMessage;

        if (coExist || ((item.warning || item.childObsoleteWarning) && !item.error))
        {
            validationOverlay = coExist ? coExistTolltip : parentWarning;
        }
        else if (item.sourcingError || item.error || isStatusDesignForCO || item.lastModifiedError)
        {
            validationType = "error";
            validationOverlay = statusTooltipMessage;
            validationTooltip = isStatusDesignForCO ? designStatusErrorMessage : item.tooltipMessage;
        }
        else if (!window.__isPrimarySourceWarningDisabledInCO && !item.isPrimarySource)
        {
            validationOverlay = sourceTooltipMessage;
        }
        else
        {
            showValidation = false;
        }

        if (showValidation)
        {
            validationMarkup =
                <ValidationIcon
                    type={validationType}
                    overlay={validationOverlay}
                    index={i}
                    tooltipMessage={validationTooltip}
                    onClick={this.onClick}
                />
        }

        return validationMarkup
    }

    revisionFieldInCO(item, i, revisionManagedClass)
    {
        const { 
          modified, error, warning, childObsoleteWarning, nextCoRevision,
          previousRevision, previousStatus, status, nextRevisionInput, showRevision,
        } = item;

        let showRemoveRevisionIcon = !modified && !error && !warning && !childObsoleteWarning && ["PROTOTYPE", "PRODUCTION"].includes(status) && Utils.getVendor(item) !== 'ONSHAPE';
        let checkRevisionScheme = previousStatus !== "DESIGN" && status !== "DESIGN" && window.__libraryType === "GENERAL" && !["DEFAULT", "NUMERIC-XY"].includes(window.__revSchemeType);

        let displayValue = nextCoRevision;
        if (status === "DESIGN") {
            displayValue = previousRevision ?? '—'
        } else if (status === "OBSOLETE") {
            if(previousRevision !== nextCoRevision && previousStatus === "OBSOLETE") {
                displayValue = previousRevision;
            } else {
                displayValue = nextRevisionInput.value;
            }
        }

        return( (previousStatus !== status && status !== "OBSOLETE") ?
            <LazyInput
                type="text"
                value={nextRevisionInput.value}
                data-tip={nextRevisionInput.message}
                className={`revision-input ${nextRevisionInput.class} ${this.revisionScheme} ${revisionManagedClass}`}
                data-place="right"
                data-type="error"
                onClick={
                    (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                } }
                onChange={(event) => this.onInputChange(event, item, i)}
            />
            : showRemoveRevisionIcon && !showRevision ?
                <Icon
                    onClick={() => this.showHideRevision(i)}
                    className="plus-icon"
                    src={roundPlusSrc}
                    tooltip="Add Revision"
                 />
                :
                <div className={`${(showRemoveRevisionIcon && showRevision) ? "remove-exist" : ""}`}>
                    <div className={`revision-label text doted-border ${this.revisionScheme}`}>
                        { displayValue }
                        {
                            showRemoveRevisionIcon && showRevision &&
                            <div className="remove-revision">
                                <InlineIcon
                                    className="removed"
                                    tooltipPlace = "right"
                                    onClick={() => this.showHideRevision(i)}
                                    >
                                    <DeleteSrc/>
                                </InlineIcon>
                            </div>
                        }
                    </div>
                    {
                        checkRevisionScheme && status !== "OBSOLETE" ?
                        <Icon
                            src={editSrc}
                            onClick={(e) => this.openRevisionActionModal(e, item, i)}
                            tooltip="Revision Actions"
                            className='rev-actions-icon'

                        /> : null
                    }
                </div>
        )
    }

    getTableRows()
    {
        let {list, selectedResults} = this.state;
        let {refRelease, coType, loadCoTemplates} = this.props;
        if(window.__isConfigurationsEnabled && window.__isCoMandatoryToggleEnabled)
            loadCoTemplates(list, coType)
        let _this = this;
        let isDcoType = coType && coType.toUpperCase() === "DCO";
        const areAllItemsSelected = (selectedResults.cmp.length + selectedResults.prd.length) === list.length;

        let rows = list.map((item, i) =>
            {
                let { path, query} = this.getData(item.alias);
                let isStatusDesignForCO = item.status === 'DESIGN' || (isDcoType && item.previousStatus === 'DESIGN') ? true : false;

                let coExist = item.co && item.co.length > 0;
                item.disableRemove = !!(refRelease?.children.components.find(id => id === item._id));
                let rowClassName = item.error || isStatusDesignForCO || item.sourcingError ? 'warning' : '';
                rowClassName = coExist ? "disable-row warning" : rowClassName;
                let to  = "/" + (item.alias === "cmp" ? "component" : "product") + `/${path}/` + item[query];
                let cpn = item.cpn;
                let alias = item.alias === "cmp" ? "Component" : "Product";
                cpn = Utils.getCpn(item);
                let componentEditLink = `/component/edit/${item._id}?status=${item.status}&revision=${item.nextRevisionInput.value}&refer=co&showModal=false&tab=sourcing`;
                let productEditLink = `/product/edit/${item._id}?status=${item.status}&revision=${item.nextRevisionInput.value}&refer=co&showModal=false&tab=sourcing`;
                let componentViewLink = `/component/${path}/${item[query]}`;
                let productViewLink = `/product/${path}/${item[query]}`;

                let lowerStatusErrorMessage = this.createLowerStatusErrorTooltip(item, item);
                let designStatusErrorMessage = `${alias} must have a Status of Prototype or Production to submit in a Change Order for approval.`;
                let sourceTooltipMessage = this.createSourceValidationTooltip(item, componentEditLink, productEditLink, lowerStatusErrorMessage);
                let lastModifiedToolTipMessage = this.createLastModifiedToolTip(item, componentViewLink, productViewLink);
                let coExistTolltip = this.createOpenCOList(item, coExist);
                let parentWarning = this.createParentWarningTooltip(item);
                let revisionManaged = Utils.checkRevisionManaged(item);
                let revisionManagedClass = revisionManaged ? "" : "disabled";

                let statusTooltipMessage  = item.error ? lowerStatusErrorMessage :
                                                isStatusDesignForCO ? designStatusErrorMessage :
                                                    item.sourcingError ? sourceTooltipMessage :
                                                        item.lastModifiedError ?
                                                            lastModifiedToolTipMessage : "";
                if(item.sourcingError)
                {
                    to = item.alias === "cmp" ? componentEditLink : productEditLink;
                }
                let lastModified         = item.lastModified;
                let lastModifiedToolTip  = lastModified ? Utils.dateTimeWithLongFormat(lastModified) : null;

                let validationOptions = { i, item, coExist, coExistTolltip, parentWarning, isStatusDesignForCO, designStatusErrorMessage, sourceTooltipMessage, statusTooltipMessage, lastModifiedToolTipMessage };

                let isRowSelected = !!areAllItemsSelected;
                if(!areAllItemsSelected)
                {
                    const idxInSelected = selectedResults[item.alias].findIndex(({_id}) => _id === item._id);
                    isRowSelected = idxInSelected !== -1;
                }

                let currentStatus   = Utils.getPreviousStatus(item, true);
                let currentRevision = Utils.getPreviousRevision(item);
                item.index = i;

                let cells =
                {
                    "cpn":
                    {
                        value       : cpn,
                        tooltip     : cpn,
                        displayValue :
                            <div className="cpn-holder">
                                <Link
                                    to={to}
                                    target="_blank"
                                    className="link">
                                    <CpnField item={item} cpn={cpn} />
                                </Link>
                                {
                                    item.alias === "cmp" &&
                                    <div className="where-used-holder">
                                        <InlineIcon
                                            onClick={(e) => this.openWhereUsedModal(e, item)}
                                            tooltip="Where used"
                                            name='where-used'
                                            >
                                            <WhereUsedIcon/>
                                        </InlineIcon>
                                    </div>
                                }
                            </div>,
                        notLink     : true
                    },
                    "name":
                    {
                        value       : item.name,
                        displayValue : item.name,
                        tooltip     : item.name,
                        notLink     : true
                    },
                    "lastRelease":
                    {
                        value       : item.previousStatus,
                        tooltip     : item.previousStatus,
                        cellClass   : 'last-release-cell',
                        displayValue:
                            <div className="last-release-holder status-holder">
                                <StatusField item={item} status={item.previousStatus}/>
                                <span className="revision-label">
                                REV
                                    <span className="rev">
                                    <RevisionField item={item} revision={item.previousRevision} showIcon={false}/>
                                    </span>
                                </span>
                            </div>,
                        notLink     : true
                    },
                    "description":
                    {
                        value       : item.description,
                        displayValue : item.description,
                        tooltip     : item.description,
                        notLink     : true
                    },
                    "revision":
                    {
                        value       : item.revision,
                        cellClass   : 'revision-cell',
                        displayValue :
                            isDcoType
                            ?
                                <div className="revision-holder">
                                    <span className={`revision-label ${this.revisionScheme}`}>
                                        <RevisionField
                                            item={item}
                                            revision={item.previousRevision}
                                            showIcon={item.previousStatus === 'DESIGN' ? false : true}
                                        />
                                    </span>
                                    <InlineIcon className="arrow">
                                        <ArrowIcon/>
                                    </InlineIcon>
                                    <div>
                                        <div className={`revision-label text doted-border ${this.revisionScheme}`}>
                                            {item.previousRevision ? item.previousRevision : '—'}
                                        </div>
                                    </div>
                                </div>
                            :
                                <div className="revision-holder">
                                    <span className={`revision-label ${this.revisionScheme}`}>
                                        <RevisionField item={item} revision={item.revision}/>
                                    </span>
                                        {
                                            <InlineIcon className="arrow">
                                                <ArrowIcon/>
                                            </InlineIcon>
                                        }
                                        {
                                            this.revisionFieldInCO(item, i, revisionManagedClass)
                                        }
                                </div>,
                        notLink     : true,
                        haveInput   : true

                    },
                    "status":
                    {
                        value       : item.originalStatus,
                        tooltip     : item.originalStatus,
                        cellClass   : 'status-cell',
                        displayValue :
                        isDcoType
                        ?
                            <span className="status-holder">
                                <StatusField item={item} status={item.previousStatus}/>
                                <InlineIcon>
                                    <ArrowIcon/>
                                </InlineIcon>
                                <span className={"status-label"}>
                                    <select
                                        className={"status-slect disabled"}
                                        name={"status"}
                                        value={item.previousStatus}
                                        >
                                        {Utils.toOptions(Schemas.component.status.list(''))}
                                    </select>
                                </span>
                            </span>
                        :
                            <span className="status-holder">
                                <StatusField item={item} status={item.originalStatus}/>
                                <InlineIcon>
                                    <ArrowIcon/>
                                </InlineIcon>
                                <Status
                                    onStatusChange={this.onStatusChange}
                                    openValidationModal={this.openValidationModal}
                                    item={item}
                                    status={item.status}
                                    validateSourcing={this.validateSourcing}
                                    options={Utils.toOptions(Schemas.component.status.list(currentStatus))}/>
                            </span>,
                        notLink     : true
                    },
                    "eid":
                    {
                        value       : item.eid,
                        displayValue : item.eid,
                        tooltip     : item.eid,
                        notLink     : true
                    },
                    "lastUpdated":
                    {
                        value       : lastModified ? lastModified : '',
                        tooltip     : lastModified && lastModifiedToolTip ? `${lastModifiedToolTip.dateValue} ${lastModifiedToolTip.timeValue}`: '',
                        displayValue :lastModified && <TimeStampColumn key={Utils.generateUniqueId()} format='date-time-with-long-format' value={lastModified} />,
                        notLink     : true
                    },
                    "remove":
                    {
                        value        : "",
                        cellClass    : "remove-col",
                        displayValue :
                            <div
                                className={item.disableRemove ? "disable-remove-col" : ""}
                                onClick={(e, i) => _this.remove(e, item._id)}
                            >
                            <InlineIcon
                                tooltip = "Remove from Change Order"
                                tooltipPlace = "right"
                                >
                                <DeleteSrc/>
                            </InlineIcon>
                            </div>,
                        notLink     : true,
                    },
                    rowClassName    : rowClassName,
                    rowId           : item._id,
                    object          : item,
                    rowSelected     : isRowSelected,
                    indexTableEl    : this.showValidationTooltip(validationOptions),
                    displayCheckboxOnHover : true,
                    rowLink         : to,
                    targetBlank     : true
                }
                return cells
            })
        return rows
    }

    render()
    {
        let search = this.state.search
        let list   = this.state.list
        let headings = this.state.headings
        let rows = this.getTableRows()
        let markup  =
            <div>
                {
                    <ValidationRunningModal
                        open={true}
                        hideModal={"hide-modal"}
                        message="Checking for validation issues."
                    />
                }
                {
                    this.state.whereUsedModal.open &&
                    <WhereUsedModal
                        component={this.state.whereUsedModal.object}
                        openWhereUsedModal={this.state.whereUsedModal.open}
                        getDataFromApi={true}
                        objectIds={[this.state.whereUsedModal.object._id]}
                        closeWhereUsedModal={this.closeWhereUsedModal}
                        setModalPosition={this.setModalPosition}
                        history={this.props.history}
                        imagesWithSrc={this.props.imagesWithSrc}
                    />
                }

                {
                    this.state.revActionModal.open &&
                    <RevActionModal
                        item={this.state.revActionModal.item}
                        openRevActionModal={this.state.revActionModal.open}
                        closeRevActionModal={this.closeRevActionModal}
                        setRevActionModalPosition={this.setRevActionModalPosition}
                        index={this.state.revActionModal.index}
                        applyRevAction={this.applyRevAction}
                        validateField={validateField}
                        validations={validations}
                        user={this.props.user}
                    />
                }


                {
                    this.state.displayStatusModal &&
                    <UpdateStatusModal
                        hideUpdateStatusModal={this.hideUpdateStatusModal}
                        components={this.state.selectedResults.cmp}
                        products={this.state.selectedResults.prd}
                        refreshAfterBulkUpdate={this.refreshAfterBulkUpdate}
                        shouldNotUpdateItemsInDB={true}
                    />
                }

                <ExtendedTable
                    wrapperClassName="co-list"
                    wrapperSelectorClass="co-list"
                    stylesName="changeOrderAssemblyTableForm"
                    themeDataTableId={"coListTable"}
                    headings={headings}
                    rows={rows || []}
                    onRowSelect={this.addToSelectedList}
                    onSelectAllRows={this.selectAllRows}
                    tableActionButtons={this.getIconsActionsList()}
                    clearSelectedRows={this.state.clearSelectedRows}
                    allowRowSelect={!!this.state.list.length}
                    syncWithParentState={this.state.syncWithParentState}
                    afterSyncWithParentState={this.afterSyncWithParentState}
                    currentSortItem={this.state.current}
                    scrollPagination={true}
                    paginationSize={25}
                    includeToolBar={true}
                    resultText={`${rows.length} results`}
                    showHelp={true}
                    helpLink={publicPaths.docs.changeOrderHelp}
                    currentSortItemAscending={this.state.currentSortItemAscending}
                    footerRow={rows.length === 0 && {
                            dataCellEl: <p>No Products or Components added</p>
                        }
                    }
                />
            </div>
        return markup
    }
}

export default List
