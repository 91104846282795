import UserAPI              from "./users"
import AuthAPI              from "./auth"
import CompanyAPI           from "./companies"
import ProductAPI           from "./products"
import ComponentAPI         from "./components"
import RevisionAPI          from "./revisions"
import SearchAPI            from "./search"
import cos                  from "./cos"
import ImageAPI             from "./images"
import UidAPI               from "./uids"
import DocumentAPI          from "./documents"
import ServiceAPI           from "./services"
import ComponentRevisionAPI from "./component-revision"
import ProductRevisionAPI   from "./product-revision"
import HeadingAPI           from "./headings"
import CategoryAPI          from "./categories"
import ReleaseAPI           from "./release";
import oauth2Api            from './oauth2';
import IntegrationsApi      from "./integrations";
import WebhooksAPI          from "./webhooks";
import SSOIntegrationAPI    from "./sso-integration";
import ProductionBuildAPI   from "./production-builds";
import ProductionInstanceAPI from "./production-instances";

const API =
{
    auth: AuthAPI,
    users: UserAPI,
    companies: CompanyAPI,
    products: ProductAPI,
    components: ComponentAPI,
    cos: cos,
    revisions: RevisionAPI,
    search: SearchAPI,
    images: ImageAPI,
    uids: UidAPI,
    documents: DocumentAPI,
    services: ServiceAPI,
    componentRevision: ComponentRevisionAPI,
    productRevision: ProductRevisionAPI,
    headings: HeadingAPI,
    category: CategoryAPI,
    release: ReleaseAPI,
    oauth2: oauth2Api,
    integrations: IntegrationsApi,
    webhooks: WebhooksAPI,
    ssoIntegration: SSOIntegrationAPI,
    productionBuilds: ProductionBuildAPI,
    productionInstances: ProductionInstanceAPI,

    yahoo : () =>{

    }
}

export default API
