import PRODUCT from "../../../action-types/product"
import initialState, { getInitialState } from "./initial_state"
import { handleActions } from "redux-actions"

function setProduct(state=initialState, { payload })
{
    state.product = payload.product
    state.isFavorite = payload.isFavorite
    return {...state}
}

function setNestedDocumentsOfProduct(state= initialState, { payload })
{
    state.product.documents = payload;
    return {...state};
}


function setDocumentsLoaded(state = initialState, { payload })
{
    state.documents_loaded = payload;
    return {...state};
}

function setLoading(state = initialState, { payload })
{
    state.loading = payload;
    return {...state};
}

function setFav(state=initialState, { payload })
{
    state.isFavorite = payload.isFavorite
    return {...state}
}

function resetPageStates(state=initialState, { payload })
{
    return {...getInitialState()}
}

export default handleActions({
  [PRODUCT.RESET_STATES_IN_VIEW_PAGE]: resetPageStates,
  [PRODUCT.SET_PRODUCT_IN_VIEW_PAGE]: setProduct,
  [PRODUCT.SET_PRODUCT_FAV_IN_VIEW_PAGE]: setFav,
  [PRODUCT.SET_PRODUCT_NESTED_DOCUMENTS]: setNestedDocumentsOfProduct,
  [PRODUCT.SET_PRODUCT_DOCUMENTS_LOADED]: setDocumentsLoaded,
}, initialState);
