import { styled } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";

export const StyledBaseGrid = styled(DataGridPro)<{ enableCellFocus?: boolean }>(({ enableCellFocus, theme }) => ({
  backgroundColor: "transparent",
  border: "0",
  "& > div:last-child": {
    backgroundColor: theme.duro.grid.background,
  },
  "& .MuiDataGrid-main": {
    border: `0.063rem solid ${theme.duro.grid.border}`,
    borderBottomLeftRadius: 0,
    borderRadius: "4px",
    borderTopLeftRadius: 0,
    "& .MuiButtonBase-root": {
      minWidth: "auto",
    },
  },
  "& .MuiDataGrid-panel": {
    "& .MuiPaper-root": {
      padding: "0 !important",
      margin: "0 !important",
    },
  },
  "& .MuiDataGrid-row": {
    "&.Mui-selected": {
      backgroundColor: "inherit",
    },
    "&.odd, &.odd.Mui-selected": {
      backgroundColor: theme.duro.grid.background,
    },
    "&.Mui-hovered, &.Mui-selected.Mui-hovered": {
      backgroundColor: theme.duro.grid.row.highlight,
    },
    "& .MuiDataGrid-cell:not(.MuiDataGrid-cellCheckbox)": {
      borderBottom: "none",
    },
    "& .MuiDataGrid-cell": {
      "&:focus, &:focus-within": {
        outline: !enableCellFocus ? "none" : undefined,
      },
    },
    "& .MuiDataGrid-cell--editable": {
      "&:hover": {
        border: `0.0625rem solid ${theme.palette.common.white}`,
        cursor: "pointer",
      },
    },
    "&.disabled": {
      opacity: 0.5,
      "& .MuiInputBase-formControl": {
        pointerEvents: "none",
      },
    },
  },
  "& .MuiDataGrid-columnSeparator": {
    cursor: "ew-resize",
    opacity: "0 !important",
  },
  "& .MuiDataGrid-columnHeaders": {
    borderBottom: `solid 0.063rem ${theme.duro.grid.border}`,
  },
  "& .MuiDataGrid-columnHeaderDraggableContainer": {
    cursor: "move",
    "& .MuiDataGrid-columnHeaderTitleContainerContent": {
      cursor: "pointer",
    },
  },
  "& .MuiDataGrid-columnHeaders, .MuiDataGrid-pinnedColumnHeaders, .MuiDataGrid-pinnedColumns": {
    backgroundColor: theme.duro.grid.column.pinned.background,
    backgroundImage: "none",
  },
  "& .MuiDataGrid-pinnedColumnHeaders--right .MuiDataGrid-columnHeader": {
    borderLeft: `solid 0.063rem ${theme.duro.grid.border}`,
    borderRight: "solid 0",
  },
  "& .MuiDataGrid-columnHeader": {
    margin: "0 0.938rem 0 0",
    borderRight: `solid 0.063rem ${theme.duro.grid.border}`,
    marginRight: 0,
    "&:focus, &:focus-within": {
      outline: "none",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      color: theme.duro.grid.header.background,
      fontSize: "0.875rem",
      fontWeight: "bold",
      lineHeight: "0.93",
      textTransform: "uppercase",
    },
    "&.MuiDataGrid-columnHeaderCheckbox": {
      backgroundColor: theme.duro.grid.column.pinned.background,
    },
    "&.MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
      display: "none", // Master checkbox is temporarily hidden util "select all" feature is functional.
    },
    "&.selectHeader": {
      padding: 5,
      borderBottom: `solid 2px ${theme.duro.grid.border}`,
    },
    "&.MuiDataGrid-columnHeader--sorted, &.MuiDataGrid-columnHeader--sortable": {
      "& .MuiDataGrid-iconButtonContainer": {
        visibility: "visible !important",
        marginLeft: "auto",
        width: "auto",
        "& .MuiButtonBase-root": {
          border: 0,
          "& svg": {
            fontSize: "1.8rem",
          },
        },
      },
    },
    "&.MuiDataGrid-columnHeader--sortable": {
      "&:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon": {
        opacity: ".5",
      },
    },
  },
  "& .MuiDataGrid-cellCheckbox": {
    backgroundColor: theme.duro.grid.column.pinned.background,
    borderBottom: `solid 0.063rem ${theme.duro.grid.border}`,
    borderRight: `solid 0.063rem ${theme.duro.grid.border}`,
    "& .MuiTypography-root": {
      color: theme.duro.grid.row.count.color,
    },
  },
  "& .grid-cell-edit": {
    alignItems: "stretch",
    padding: "1px",
  },
})) as typeof DataGridPro; // Use this so that the template values can be set.
