import { useState, useCallback, useMemo, ReactElement, useEffect } from "react";
import { Box, styled } from "@mui/material";
import { useLocation } from "react-router-dom";
import { Tab, TabContext, TabList, TabPanel } from "common/components/tabs";
import { Role } from "common/constants";
import { SettingsTabs } from "design/constants";
import { useUser } from "graphql/query/userQueries";
import { colorPalette } from "theme";
import Permissions from "v1/modules/schemas/permissions";
import Company from "../company";
import Configurations from "../configurations";
import Groups from "../groups";
import Integrations from "../integrations";
import User from "../user";
import UsersPage from "../users";
import Webhook from "../webhooks";

interface LocationState {
  currentTab: string
}

interface TabsMappings {
  [key: string]: {
    component: ReactElement,
    isAuthorised: (user: any) => boolean,
    label: string,
    schema: string,
  }
}

const tabsMapping: TabsMappings = {
  users: {
    component: <UsersPage />,
    isAuthorised: user => Permissions.can("view", "user", user) && user.activeLibrary?.type === "GENERAL",
    label: SettingsTabs.USERS,
    schema: "user",
  },
  groups: {
    component: <Groups />,
    isAuthorised: user => Permissions.can("view", "groups", user) && user.activeLibrary?.type === "GENERAL",
    label: SettingsTabs.GROUPS,
    schema: "groups",
  },
  companyProfile: {
    component: <Company />,
    isAuthorised: ({ activeLibrary: { type } }) => type === "GENERAL",
    label: SettingsTabs.COMPANY,
    schema: "company_profile",
  },
  configuration: {
    component: <Configurations />,
    isAuthorised: ({ primaryCompany: { settings: { configurations } } }) =>
      configurations?.isConfigurationsEnabled,
    label: SettingsTabs.CONFIGURATION,
    schema: "configurations",
  },
  userProfile: {
    component: <User />,
    isAuthorised: () => true,
    label: SettingsTabs.USER_PROFILE,
    schema: "user_profile",
  },
  integrations: {
    component: <Integrations />,
    isAuthorised: (
      { role, activeLibrary: { type } },
    ) => ["ADMINISTRATOR", "USER"].includes(role) && type === "GENERAL",
    label: SettingsTabs.INTEGRATIONS,
    schema: "integrations",
  },
  webhooks: {
    component: <Webhook />,
    isAuthorised: ({ activeLibrary: { type } }) => type === "GENERAL",
    label: SettingsTabs.WEBHOOK,
    schema: "webhook",
  },
};

const badgeStyles = {
  flex: 1,
  justifyContent: "left",
};

const tabStyling = {
  fontSize: "0.875rem",
  marginBottom: "0.9rem",
  marginLeft: 0,
  minWidth: "7.3125rem",
};

const tabPanelStyles = {
  flex: 1,
};

export const SettingsSideNav = () => {
  const location = useLocation<LocationState>();
  const currentTabInHistory = location.state?.currentTab;
  const { data: user } = useUser();
  const [currentTab, setCurrentTab] = useState<string>(
    user?.role === Role.REVIEWER ? SettingsTabs.USER_PROFILE : SettingsTabs.USERS,
  );

  const onTabClicked = useCallback((_event, tab) => {
    setCurrentTab(tab);
  }, []);

  useEffect(() => {
    if (currentTabInHistory) {
      setCurrentTab(currentTabInHistory);
    }
  }, [currentTabInHistory]);

  const tabsList = useMemo(() => (
    Object.keys(tabsMapping).map(tab => {
      const { schema, label, isAuthorised } = tabsMapping[tab];
      return isAuthorised(user)
        && (
          <Tab
            badgeStyles={badgeStyles}
            customStyles={tabStyling}
            key={schema}
            value={label}
          >
            {label}
          </Tab>
        );
    })
  ), [user]);

  const tabsContent = useMemo(() => (
    Object.keys(tabsMapping).map(tab => (
      <TabPanel
        currentValue={currentTab}
        style={tabPanelStyles}
        value={tabsMapping[tab].label}
      >
        {tabsMapping[tab].component}
      </TabPanel>
    ))
  ), [currentTab]);

  return (
    <Box>
      <TabContext display="flex">
        <TabList
          onChange={onTabClicked}
          orientation="vertical"
          value={currentTab}
        >
          <StyledSettingsHeading>
            Settings
          </StyledSettingsHeading>
          {tabsList}
        </TabList>
        {tabsContent}
      </TabContext>
    </Box>
  );
};

const StyledSettingsHeading = styled(Box)({
  color: colorPalette.graySeventy,
  fontSize: "0.875rem",
  marginBottom: "1.8rem",
  textTransform: "uppercase",
});
