import                    "./index.css";
import React, {Component} from "react";
import ModalBox           from "../../../../../ui/modal-box";

class CancelDownloadModal extends Component
{
    constructor(props)
    {
        super(props);
        this.onClose = this.onClose.bind(this);
    }

    onClose(e)
    {
        let cancelButton = false;
        if(e.target.name === "cancel") cancelButton = true;
        this.props.closeCancelDownloadModal("displayCancelDownloadModal", false, cancelButton);
    }

    render()
    {
        let markup = <div>
                        <ModalBox onClose={this.onClose}>
                            <div className="modal-heading">
                                <h3>Cancel Download?</h3>
                            </div>
                            <div className="modal-body">
                                <span>Your download is not complete. Would you like to cancel the download?</span>
                                <div className="btn-holder">
                                    <div className="btn-container">
                                        <button
                                            className="confirm"
                                            onClick={this.onClose}
                                        >
                                            CONTINUE DOWNLOAD
                                        </button>
                                        <button
                                            className="dark"
                                            name="cancel"
                                            onClick={(e)=>this.onClose(e)}>
                                            CANCEL DOWNLOAD
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </ModalBox>
                     </div>
        return markup;
    }
}

export default CancelDownloadModal;
