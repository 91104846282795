import                                       "./index.css"
import React                                 from "react"
import ModalBox                              from "../../../../ui/modal-box"
import Utils                                 from "../../../../../modules/utils"
import API                                   from "../../../../../modules/api";
import EditIcon                              from "../../../../../assets/icons/edit"
import {permissions as Permissions}          from "../../../../../modules/schemas"
import ExtendedTable                         from "../../../common/extended-table"
import TableIcons                            from "../../../common/extended-table/table-icons";
import Loading                               from "../../../../ui/inner-loading"
import InlineIcon                            from "../../../../ui/icon/inline-icon.js"
import TimeStampColumn                       from "../../../common/timestamp-column";


export class WebhooksView extends React.Component
{
    constructor(props, context)
    {
        super(props, context);
        let viewStyles;
        try
        {
            viewStyles = window.__userStyles.styles.webhooks || {}
        }
        catch(error) {
            viewStyles = {}
        }

        this.state =
        {
            loading           : false,
            userId            : "",
            webhooks          : this.props.webhooks || [],

            currentSortItemAscending : ("defaultSortAssending" in viewStyles ? viewStyles.defaultSortAssending : true),
            current : ("defaultSortColumnName" in viewStyles ? viewStyles.defaultSortColumnName : "status"),

            headings :
            [
                {
                    key         : "webhookUrl",
                    displayName : "Webhook Url",
                    tooltip     : "Webhook Url",
                    sortable    : true,
                    minWidth    : 425,
                    width       : Utils.getStyleValue(viewStyles, "webhookUrl", "width", 100),
                    position    : Utils.getStyleValue(viewStyles, "webhookUrl", "position", 0),
                    visibility  : Utils.getStyleValue(viewStyles, "webhookUrl", "visibility", true),
                    disabled    : true
                },
                {
                    key         : "status",
                    displayName : "Status",
                    tooltip     : "Status",
                    sortable    : true,
                    minWidth    : 95,
                    width       : Utils.getStyleValue(viewStyles, "status", "width", 100),
                    position    : Utils.getStyleValue(viewStyles, "status", "position", 1),
                    visibility  : Utils.getStyleValue(viewStyles, "status", "visibility", true),
                    disabled    : true
                },
                {
                    key         : "mode",
                    displayName : "Mode",
                    tooltip     : "mode",
                    sortable    : true,
                    minWidth    : 88,
                    width       : Utils.getStyleValue(viewStyles, "mode", "width", 100),
                    position    : Utils.getStyleValue(viewStyles, "mode", "position", 2),
                    visibility  : Utils.getStyleValue(viewStyles, "mode", "visibility", true),
                    disabled    : true
                },
                {
                    key         : "includeComponentProductData",
                    displayName : "Include Component Product data",
                    tooltip     : "Include all Components and Products data",
                    sortable    : false,
                    minWidth    : 100,
                    width       : Utils.getStyleValue(viewStyles, "mode", "width", 120),
                    position    : Utils.getStyleValue(viewStyles, "mode", "position", 3),
                    visibility  : Utils.getStyleValue(viewStyles, "mode", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "eventTypes",
                    displayName : "Event Types",
                    tooltip     : "Event Types",
                    sortable    : true,
                    minWidth    : 190,
                    width       : Utils.getStyleValue(viewStyles, "eventTypes", "width", 100),
                    position    : Utils.getStyleValue(viewStyles, "eventTypes", "position", 4),
                    visibility  : Utils.getStyleValue(viewStyles, "eventTypes", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "signingSecret",
                    displayName : "Signing Secret",
                    tooltip     : "Signing Secret",
                    sortable    : true,
                    minWidth    : 120,
                    width       : Utils.getStyleValue(viewStyles, "signingSecret", "width", 100),
                    position    : Utils.getStyleValue(viewStyles, "signingSecret", "position", 5),
                    visibility  : Utils.getStyleValue(viewStyles, "signingSecret", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "description",
                    displayName : "Description",
                    tooltip     : "Description",
                    sortable    : true,
                    minWidth    : 150,
                    width       : Utils.getStyleValue(viewStyles, "description", "width", 100),
                    position    : Utils.getStyleValue(viewStyles, "description", "position", 6),
                    visibility  : Utils.getStyleValue(viewStyles, "description", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "created",
                    displayName : "CREATED",
                    tooltip     : "CREATED",
                    sortable    : true,
                    minWidth    : 168,
                    width       : Utils.getStyleValue(viewStyles, "created", "width", 198),
                    position    : Utils.getStyleValue(viewStyles, "created", "position", 7),
                    visibility  : Utils.getStyleValue(viewStyles, "created", "visibility", true),
                    headingClass: "created-col",
                    disabled    : false
                },
                {
                    key         : "lastModified",
                    displayName : "LAST MODIFIED",
                    tooltip     : "LAST MODIFIED",
                    sortable    : true,
                    minWidth    : 168,
                    width       : Utils.getStyleValue(viewStyles, "lastModified", "width", 198),
                    position    : Utils.getStyleValue(viewStyles, "lastModified", "position", 8),
                    visibility  : Utils.getStyleValue(viewStyles, "lastModified", "visibility", true),
                    headingClass: "lastModified-col",
                    disabled    : false
                }
            ]
        }
        this.getWebhooksList  = this.getWebhooksList.bind(this)
    }

    getWebhooksList()
    {
        let state = this.state;
        if(this.props.user)
        {
            API.webhooks.getWebhooks({} , (err, data) =>
            {
                if(data)
                {
                    state.webhooks = data;
                    state.loading  = false;
                    this.props.setWebhooks(data);
                    this.setState(state);
                }
            })
        }
    }

    componentWillMount()
    {
        let state = this.state;
        if(this.props.loadWebhooks)
        {
            state.loading = true;
            this.setState(state);
            this.getWebhooksList();
        }
    }

    render()
    {
        let webhooks = this.props.webhooks;
        let user = this.props.user;
        let tableHeaders = this.state.headings
        let rows = webhooks.map((webhook, i) =>
        {
            let eventTypes = webhook.eventTypes.join(', ')
            let cells =
            {
                "webhookUrl":
                {
                    value        : webhook.webhookUrl,
                    tooltip      : webhook.webhookUrl,
                    displayValue : webhook.webhookUrl
                },
                "status":
                {
                    value        : webhook.status,
                    tooltip      : webhook.status,
                    displayValue : <div className="status-nav">
                                    {
                                        webhook.status === 'Enabled' ?
                                        <span className={"status-label " + (webhook.status === "Enabled" ? "ENABLED": "")}>Enabled</span> :
                                        <span className={"status-label " + (webhook.status === "Disabled" ? "DISABLED" : "")}>Disabled</span>
                                    }
                                    </div>
                },
                "mode":
                {
                    value        : webhook.mode,
                    tooltip      : webhook.mode,
                    displayValue : webhook.mode
                },
                "includeComponentProductData":
                {
                    value        : webhook.includeComponentProductData,
                    tooltip      : webhook.includeComponentProductData,
                    displayValue : webhook.includeComponentProductData ? 'True' : 'False'
                },
                "eventTypes":
                {
                    value        : eventTypes,
                    tooltip      : eventTypes,
                    displayValue : eventTypes
                },
                "signingSecret":
                {
                    value        : webhook.signingSecret,
                    tooltip      : webhook.signingSecret,
                    displayValue : webhook.signingSecret
                },
                "description":
                {
                    value        : webhook.description,
                    tooltip      : webhook.description,
                    displayValue : webhook.description
                },
                "created":
                {
                    value           : webhook.created,
                    displayValue    : <TimeStampColumn key={Utils.generateUniqueId()} format='date-time-with-long-format' value={webhook.created} />,
                    tooltip         : webhook.created
                },
                "lastModified":
                {
                    value           : webhook.lastModified,
                    displayValue    : <TimeStampColumn key={Utils.generateUniqueId()} format='date-time-with-long-format' value={webhook.lastModified} />,
                    tooltip         : webhook.lastModified
                },
                rowSelected     : false,
                rowClassName    : "",
                rowLink         : (`/webhook/view/${webhook._id}`),
            }
            return cells
        })

        let markup =
            <div className="webhook-list-form-block">
                <div className="webhooks-header-block">
                    <div className="nav-block">
                        <div className="header-block">
                            <div className="heading-section">
                                <h1>Webhooks</h1>
                                {
                                     Permissions.can("create", "webhook", this.props.user) &&
                                    <button
                                      className='green new-button'
                                      onClick={this.props.showAddNewWebhook}>
                                      NEW
                                    </button>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            {
                this.state.loading ? <Loading />
                :
                <ExtendedTable
                    wrapperClassName="setting-webhooks-block"
                    wrapperSelectorClass="setting-webhooks-view"
                    headings={tableHeaders}
                    rows={rows}
                    stylesName="webhooks"
                    allowRowSelect={false}
                    includeToolBar={true}
                    currentSortItem={this.state.current}
                    currentSortItemAscending={this.state.currentSortItemAscending}
                    resultText={`${rows.length} webhooks`}
                    footerRow={ rows.length === 0 && {dataCellEl: <p>No Webhooks added</p> }}
                />
            }
        </div>
        return markup
    }
}

export default WebhooksView
