import React from "react";

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='19'
      version='1.1'
      viewBox='0 0 20 19'
      id="width-auto-adjust-svg"
    >
      <g fill='none' fillRule='evenodd'>
        <g
          fillRule='nonzero'
          stroke='#FFF'
          transform='translate(-276 -8) translate(276 9)'
        >
          <path
            fill='#FFF'
            strokeWidth='0.25'
            d='M7.386 7.58l.023-.001h7.312L12.414 4.69a.449.449 0 01.054-.596.41.41 0 01.579.056l2.863 3.58c.111.18.128.335 0 .54l-2.863 3.579a.409.409 0 01-.579.056.45.45 0 01-.054-.596l2.307-2.888H5.28l2.307 2.889a.449.449 0 01-.054.596.41.41 0 01-.579-.056L4.09 8.27c-.111-.18-.128-.335 0-.54l2.863-3.579a.409.409 0 01.579-.056.45.45 0 01.054.596L5.28 7.579h2.107z'
          ></path>
          <path
            strokeLinecap='round'
            strokeWidth='1.25'
            d='M1.5.531V16.47M18.5.531V16.47'
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
