import React, { Component }      from "react"

const SvgIcon = () =>
{
    return <svg width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
        <filter x="-1.2%" y="-53.8%" width="102.5%" height="223.1%" filterUnits="objectBoundingBox" id="filter-1">
            <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
            <feMerge>
                <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                <feMergeNode in="SourceGraphic"></feMergeNode>
            </feMerge>
        </filter>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="2_EnterSandbox" transform="translate(-732.000000, -5.000000)" fill="#FFFFFF">
            <g id="Label" filter="url(#filter-1)">
                <g id="CLOSE" transform="translate(736.000000, 7.000000)">
                    <g id="Group-2">
                        <path d="M8.62588235,8.81355932 C8.33223529,8.81355932 8.07529412,9.07062147 8.07529412,9.36440678 L8.07529412,11.8983051 L1.10117647,11.8983051 L1.10117647,1.10169492 L8.07529412,1.10169492 L8.07529412,3.63559322 C8.07529412,3.92937853 8.33223529,4.18644068 8.62588235,4.18644068 C8.91952941,4.18644068 9.17647059,3.92937853 9.17647059,3.63559322 L9.17647059,0.550847458 C9.17647059,0.257062147 8.91952941,0 8.62588235,0 C8.62588235,0 8.62588235,0 8.62588235,0 L0.550588235,0 C0.256941176,0 0,0.257062147 0,0.550847458 L0,12.4491525 C0,12.7429379 0.256941176,13 0.550588235,13 L8.62588235,13 C8.91952941,13 9.17647059,12.7429379 9.17647059,12.4491525 L9.17647059,9.36440678 C9.17647059,9.03389831 8.91952941,8.81355932 8.62588235,8.81355932 C8.62588235,8.81355932 8.62588235,8.81355932 8.62588235,8.81355932 Z" id="Shape" fillRule="nonzero"></path>
                        <path d="M13,6.92207792 C13,6.89029794 13,6.85851795 13,6.82673797 C13,6.79495798 13,6.79495798 13,6.763178 C13,6.763178 13,6.73139801 13,6.69961803 L13,6.69961803 C13,6.66783804 12.9696326,6.66783804 12.9696326,6.63605806 L12.9392652,6.60427807 L12.9392652,6.57249809 L10.4795073,3.96653934 C10.297303,3.77585943 10.0239966,3.77585943 9.84179231,3.96653934 C9.65958802,4.15721925 9.65958802,4.44323911 9.84179231,4.63391902 C9.84179231,4.63391902 9.84179231,4.63391902 9.84179231,4.63391902 L11.4816309,6.41359817 L5.04374602,6.41359817 C4.80080697,6.41359817 4.58823529,6.63605806 4.58823529,6.89029794 C4.58823529,7.14453782 4.80080697,7.36699771 5.04374602,7.36699771 L11.4512635,7.36699771 L9.81142493,9.14667685 C9.62922064,9.33735676 9.62922064,9.62337662 9.81142493,9.81405653 C9.90252708,9.90939649 10.0239966,9.94117647 10.1150987,9.94117647 C10.2365683,9.94117647 10.3580378,9.8776165 10.4491399,9.78227655 L12.8481631,7.20809778 C12.8481631,7.20809778 12.8481631,7.1763178 12.8785305,7.1763178 L12.8785305,7.14453782 C12.9088979,7.11275783 12.9088979,7.08097785 12.9392652,7.04919786 L12.9392652,7.04919786 C12.9392652,7.04919786 12.9392652,7.04919786 12.9392652,7.01741788 C12.9392652,6.98563789 12.9696326,6.95385791 12.9696326,6.92207792 L13,6.92207792 Z" id="Shape" fillRule="nonzero"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
}

export default SvgIcon








