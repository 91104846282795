import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
    >
      <g fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fillRule="nonzero" transform="translate(-1091 -92)">
          <g transform="translate(875 84)">
            <g transform="translate(208)">
              <g transform="translate(8 8)">
                <path d="M9.107 9.724c-.31 0-.581.284-.581.608v2.795H1.163V1.215h7.363v2.796c0 .324.271.608.581.608.31 0 .582-.284.582-.608V.608C9.689.284 9.417 0 9.107 0H.581C.271 0 0 .284 0 .608v13.127c0 .324.271.608.581.608h8.526c.31 0 .582-.284.582-.608v-3.403a.584.584 0 00-.582-.608z"></path>
                <path d="M14.998 7.496v-.122-.081-.081c0-.04-.039-.04-.039-.081l-.038-.04V7.05l-3.14-3.323a.545.545 0 00-.813 0 .606.606 0 000 .851l2.092 2.27H4.844c-.31 0-.581.283-.581.607s.271.608.581.608h8.178l-2.093 2.269a.606.606 0 000 .85.542.542 0 00.387.163.61.61 0 00.427-.203l3.061-3.282s0-.04.039-.04v-.04c.039-.041.039-.082.078-.122v-.04c0-.041.038-.082.038-.122H15z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
