import { useUser } from "graphql/query/userQueries";
import { LDProvider, useFlags } from "launchdarkly-react-client-sdk";
import { PropsWithChildren, useEffect, useMemo, useState } from "react";

type LDContext = {
  [key: string]: "multi" | { [key: string]: string }
} | undefined;

export interface DuroFlags {
  build: boolean
}

// The defaults for the different flags. Overrides for the defaults can be provided via the client
// configuration data.
const flagDefaults: DuroFlags = {
  build: false,
  ...window.DURO_ENV?.ld?.defaults,
};

// The Client ID for the correct project and env within launchdarkly.
const clientID = window.DURO_ENV?.ld?.clientId ?? process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID;

/**
 * Setups up the launchdarkly provider with the information in the configuration.
 * @param props.children The child components to render.
 * @returns A rendered component
 */
export function LaunchDarklyProvider({ children }: PropsWithChildren<{}>) {
  const { data, loading } = useUser();
  const [ldContext, setLdContext] = useState<LDContext>();

  useEffect(() => {
    if (loading || !data) return;

    setLdContext({
      kind: "multi",
      user: {
        email: data.email,
        key: data.id ?? "",
        name: data.email,
      },
      company: {
        key: data.primaryCompany.id ?? "",
        name: data.primaryCompany.name,
      },
    });
  }, [data, loading]);

  // If the client ID doesn't exist, don't bother talking to launchdarkly.
  if (!clientID) return children;

  return (
    <LDProvider clientSideID={clientID} context={ldContext} deferInitialization flags={flagDefaults}>
      {children}
    </LDProvider>
  );
}

/**
 * Loads up the launchdarkly flags and overrides them with the environments defaults when a value
 * isn't available from launchdarkly.
 * @returns Flag values
 */
export function useDuroFlag() {
  const ldFlags = useFlags<DuroFlags>();
  return useMemo(() => ({ ...flagDefaults, ...ldFlags }), [ldFlags]);
}
