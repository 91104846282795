const searchKeywords = [
  "cat",
  "category",
  "catGroup",
  "cpn",
  "desc",
  "description",
  "dist_desc",
  "dist",
  "distributor",
  "dpn",
  "eid",
  "is",
  "manufacturer",
  "mfr_desc",
  "mfr",
  "mpn",
  "name",
  "not",
  "procurement",
  "rev",
  "revision",
  "specs",
  "status",
  "vendor",
];

const typeIdentifierRegex = /type\s*:\s*cmp|type\s*:\s*prd|type\s*:\s*component|type\s*:\s*product/g;

function parseFieldName(fieldName: string) {
  switch (fieldName) {
    case "desc":
      return "descriptionList";
    case "dist_desc":
      return "dist_desc_list";
    case "dist":
      return "distributorList";
    case "mfr":
      return "manufacturerList";
    case "mfr_desc":
      return "mfr_desc_list";
    case "rev":
    case "revision":
      return "revisionValueList";
    case "cat":
      return "categoryList";
    default:
      return `${fieldName}List`;
  }
}

function parseValue(fieldName: string, value: string) {
  switch (fieldName) {
    case "statusList":
      return value.toLocaleUpperCase();
    case "isList":
      return true;
    case "notList":
      return false;
    default:
      return value;
  }
}

export function parseSearchString(searchString: string = "") {
  const specs = [...new Set((window as any).__categories
    .flatMap((el: any) => el.specs.names))]
    .map((el: any) => el.toLowerCase());

  const regex = /"[^"]+"|\S+:(?=\S+)|\S+/g;
  let nextField: string = "";
  let specVal: string = "";

  return searchString.replace(typeIdentifierRegex, "")
    .match(regex)
    ?.map(word => word.replace(/"/g, "").replace(/:$/, ""))
    ?.filter((el: string) => !!el)
    .reduce((acc: any, token: string) => {
      // Whenever we encounter a keyword, we need to reset the index
      if (nextField) {
        // For boolean fields, token is same as the field key
        const fieldKey = ["isList", "notList"].includes(nextField) ? `${token}List` : nextField;
        const value = parseValue(nextField, token);

        if (fieldKey === "specValueList") {
          // eslint-disable-next-line no-param-reassign
          acc[fieldKey] = acc[fieldKey] || [];
          acc[fieldKey].push({ key: specVal, value });
        }
        else {
          Object.prototype.hasOwnProperty.call(acc, fieldKey)
          // eslint-disable-next-line no-param-reassign
            ? acc[fieldKey].push(value) : acc[fieldKey] = [value];
        }
        nextField = "";
      }
      else if (searchKeywords.includes(token)) {
        nextField = parseFieldName(token);
      }
      else if (specs.includes(token.toLowerCase())) {
        nextField = "specValueList";
        specVal = token.toLowerCase();
      }
      else {
        Object.prototype.hasOwnProperty.call(acc, "globalList")
          // eslint-disable-next-line no-param-reassign
          ? acc.globalList.push(token) : acc.globalList = [token];
      }
      return acc;
    }, {});
}
