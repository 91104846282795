import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="57"
      viewBox="0 0 46 57"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      >
        <g transform="translate(-619 -970)">
          <g transform="translate(313 930)">
            <g transform="translate(227)">
              <g transform="translate(80 41)">
                <path
                  stroke="#FFF"
                  d="M33.7981707 10.0878049L24.0457317 10.0878049 24.0457317 0.335365854 0.335365854 0.335365854 0.335365854 44.1743902 33.7981707 44.1743902z"
                ></path>
                <path stroke="#FFF" d="M24.046.335l9.752 9.753"></path>
                <path
                  stroke="#FFF"
                  d="M10.9664634 54.8054878L44.4292683 54.8054878 44.4292683 20.7189024"
                ></path>
                <path
                  stroke="#3CD1B5"
                  d="M5.51341463 49.352439L38.9762195 49.352439 38.9762195 15.2658537"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
