import Link                                 from "../../../../ui/link";
import Utils                                from "../../../../../modules/utils";
import deleteSrc                            from "../../../../../assets/icons/cross-icon.svg";
import ExtendedTable                        from "../../../common/extended-table";
import React, {Component}                   from "react";
import Permissions                          from "../../../../../modules/schemas/permissions";
import InlineIcon                           from "../../../../ui/icon/inline-icon.js";
import Loading                              from "../../../../ui/inner-loading"
import API                                  from "../../../../../modules/api";
import TimeStampColumn                      from "../../../common/timestamp-column";

class WebhookEventList extends Component
{
    constructor(props)
    {
        super(props)
        let viewStyles;
        try
        {
            viewStyles = window.__userStyles.styles.webhookEventsList || {}
        }
        catch(error)
        {
            viewStyles = {}
        }
        this.state =
        {
            webhookEvents : this.props.webhookEvents || [],
            loading : false,
            current : "lastModified",
            currentSortItemAscending: false,
            headings :
            [
                {
                    key         : "status",
                    displayName : "STATUS",
                    tooltip     : "STATUS",
                    sortable    : true,
                    minWidth    : 95,
                    width       : Utils.getStyleValue(viewStyles, "status", "width", 198),
                    position    : Utils.getStyleValue(viewStyles, "status", "position", 0),
                    visibility  : Utils.getStyleValue(viewStyles, "status", "visibility", true),
                    headingClass: "status-col",
                    disabled    : true
                },
                {
                    key         : "errorMsg",
                    displayName : "Error",
                    tooltip     : "Error",
                    sortable    : true,
                    minWidth    : 168,
                    width       : Utils.getStyleValue(viewStyles, "errorMsg", "width", 198),
                    position    : Utils.getStyleValue(viewStyles, "errorMsg", "position", 1),
                    visibility  : Utils.getStyleValue(viewStyles, "errorMsg", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "eventTypes",
                    displayName : "EVENT TYPES",
                    tooltip     : "EVENT TYPES",
                    sortable    : true,
                    minWidth    : 130,
                    width       : Utils.getStyleValue(viewStyles, "eventTypes", "width", 198),
                    position    : Utils.getStyleValue(viewStyles, "eventTypes", "position", 2),
                    visibility  : Utils.getStyleValue(viewStyles, "eventTypes", "visibility", true),
                    headingClass: "eventTypes-col",
                    disabled    : true
                },
                {
                    key         : "payload",
                    displayName : "PAYLOAD",
                    tooltip     : "PAYLOAD",
                    sortable    : true,
                    minWidth    : 250,
                    width       : Utils.getStyleValue(viewStyles, "payload", "width", 265),
                    position    : Utils.getStyleValue(viewStyles, "payload", "position", 3),
                    visibility  : Utils.getStyleValue(viewStyles, "payload", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "created",
                    displayName : "CREATED",
                    tooltip     : "CREATED",
                    sortable    : true,
                    minWidth    : 168,
                    width       : Utils.getStyleValue(viewStyles, "created", "width", 198),
                    position    : Utils.getStyleValue(viewStyles, "created", "position", 4),
                    visibility  : Utils.getStyleValue(viewStyles, "created", "visibility", true),
                    headingClass: "created-col",
                    disabled    : false
                },
                {
                    key         : "lastModified",
                    displayName : "LAST MODIFIED",
                    tooltip     : "LAST MODIFIED",
                    sortable    : true,
                    minWidth    : 168,
                    width       : Utils.getStyleValue(viewStyles, "lastModified", "width", 198),
                    position    : Utils.getStyleValue(viewStyles, "lastModified", "position", 5),
                    visibility  : Utils.getStyleValue(viewStyles, "lastModified", "visibility", true),
                    headingClass: "lastModified-col",
                    disabled    : false
                }
            ]
        }
        this.getWebhookEventTableRows    = this.getWebhookEventTableRows.bind(this);
        this.getWebhookEventsFromAPI     = this.getWebhookEventsFromAPI.bind(this);
    }

    componentWillMount()
    {
        let state = this.state;
        if(!state.webhookEvents.length)
        {
            state.loading = true;
            this.setState(state);
            this.getWebhookEventsFromAPI();
        }
    }

    getWebhookEventsFromAPI()
    {
        let id = this.props.webhookId;
        let state = this.state;
        API.webhooks.getWebhookEvents(id , (err, data) =>
        {
            if(data)
            {
                state.webhookEvents = data;
                state.loading  = false;
                this.setState(state);
            }
        });
    }

    getWebhookEventTableRows()
    {
        let webhookEvents = this.state.webhookEvents;
        let rows = webhookEvents.map((webhookEvent, i) =>
            {
                let cells =
                {
                    "status":
                    {
                        value           : webhookEvent.status,
                        displayValue    : webhookEvent.status,
                        tooltip         : webhookEvent.status
                    },
                    "errorMsg":
                    {
                        value           : webhookEvent.errorMsg,
                        displayValue    : webhookEvent.errorMsg,
                        tooltip         : webhookEvent.errorMsg
                    },
                    "eventTypes":
                    {
                        value           : webhookEvent.eventTypes,
                        displayValue    : webhookEvent.eventTypes,
                        tooltip         : webhookEvent.eventTypes
                    },
                    "payload":
                    {
                        value           : webhookEvent.payload,
                        displayValue    : webhookEvent.payload,
                        tooltip         : webhookEvent.payload
                    },
                    "created":
                    {
                        value           : webhookEvent.created,
                        displayValue    : <TimeStampColumn key={Utils.generateUniqueId()} format='date-time-with-long-format' value={webhookEvent.created} />,
                        tooltip         : webhookEvent.created
                    },
                    "lastModified":
                    {
                        value           : webhookEvent.lastModified,
                        displayValue    : <TimeStampColumn key={Utils.generateUniqueId()} format='date-time-with-long-format' value={webhookEvent.lastModified} />,
                        tooltip         : webhookEvent.lastModified
                    }
                }
                return cells
            })
        return rows
    }

    render()
    {
        if(this.state.loading)
        {
            return <Loading />
        }

        let headings = this.state.headings
        let eventRows = this.getWebhookEventTableRows()
        let markup  =
            <ExtendedTable
                wrapperClassName={"webhookEvents-list-table"}
                wrapperSelectorClass={"webhookEvent-list"}
                themeDataTableId={"webhookEvents-list-table"}
                headings={headings}
                rows={eventRows}
                stylesName="webhookEventsList"
                currentSortItem={this.state.current}
                currentSortItemAscending={this.state.currentSortItemAscending}
                resultText={`${eventRows.length} Events`}
                includeToolBar={true}
                footerRow={ eventRows.length === 0 && {dataCellEl: <p>No WebhookEvents</p> }}
            />
        return markup
    }
}

export default WebhookEventList
