import { ApolloClient, FetchPolicy, gql, NormalizedCacheObject } from "@apollo/client";
import { Source } from "build/models";
import { BuildRevisionWithChildrenFragment } from "graphql/fragment/buildRevisionFragments";

export const GET_REVISION = gql`
  query buildRevisionsById($id: ID!) {
    build {
      revisionsByIds(ids: [$id]) {
        ...buildRevisionWithChildrenFragment
      }
    }
  }
  ${BuildRevisionWithChildrenFragment}
`;

export interface RevisionById {
  assembly?: Assembly[];
  category: string;
  cpn: string;
  id: string;
  lastModifiedDate: string;
  name: string;
  revisionValue: string;
  source: Source;
  status: string;
}

export interface Assembly {
  child: RevisionById;
  quantity: number;
}

export interface BuildQueryData {
  build?: {
    revisionsByIds?: RevisionById[];
  }
}

export async function getBuildRevision(
  client: ApolloClient<NormalizedCacheObject>,
  id: string,
  fetchPolicy: FetchPolicy = "cache-first",
) {
  const { data, error } = await client.query<BuildQueryData>({
    query: GET_REVISION,
    variables: { id },
    fetchPolicy,
  });

  const component = data?.build?.revisionsByIds?.[0];
  return { component, buildError: error };
}
