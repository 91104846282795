import { useMemo } from "react";
import { Link } from "../link";
import { TileContentLabel, TileContentWrapper } from "./tileContent";

interface TileContentLinkProps {
  label?: string;
  linkLabel: string;
  url?: string;
  onClick?: any;
}

export function TileContentLink(props: TileContentLinkProps) {
  const { label, linkLabel, url, onClick } = props;

  const to = useMemo(() => ({ pathname: url }), [url]);

  return (
    <TileContentWrapper>
      <TileContentLabel label={label} />
      <Link to={to} onClick={onClick}>
        {linkLabel}
      </Link>
    </TileContentWrapper>
  );
}
