import React      from "react";
import Tooltip    from 'rc-tooltip';
import Link       from "../../../ui/link";
import LazyInput  from "../../../ui/lazy-input/input.js";
import InlineIcon from "../../../ui/icon/inline-icon.js";
import LinkIcon   from "../../../../assets/icons/open-link-icon.js";

const CpnEditField = (props) =>
{
    let {cpnDuplicateTooltip, inputs, categoryCPN, cpnVariantScheme, onInputChange, cpnVariant_disabled, user} = props;
    const cpnFreeFormClass = window.__currentCompanyCpnType === "FREE-FORM" ? "free-form-input" : "";

    return (
        <div className="inner-info pov diff-cpn-section">
            <span className="inner-attribute">cpn</span>
            <div className={`inner-value ${cpnFreeFormClass}`}>
                <span className="floatLeft">
                    <div className="edit-cpn-field">
                    {
                        window.__currentCompanyCpnType === "FREE-FORM" ?
                        <Tooltip
                            placement={"right"}
                            visible={cpnDuplicateTooltip}
                            overlayClassName={"simple-rc-tip error"}
                            getTooltipContainer={() => document.querySelector("#routes")}
                            overlay=
                            {   cpnDuplicateTooltip ?
                                <div>
                                    <p>
                                        <span className="link-text">{cpnDuplicateTooltip.errorMessage}</span>
                                        <br/>
                                        <Link
                                            to={cpnDuplicateTooltip.viewLink}
                                            target="_blank"
                                            className="open-link-holder white"
                                            >
                                            <span className="link-text">
                                                {cpnDuplicateTooltip.linkMessage}
                                                <InlineIcon >
                                                    <LinkIcon/>
                                                </InlineIcon>
                                            </span>
                                        </Link>
                                    </p>
                                </div>
                                : ""
                            }
                        >
                            <LazyInput
                                type="text"
                                name="cpn"
                                className={inputs.cpn.class}
                                value={inputs.cpn.value}
                                data-place="right"
                                data-type="error"
                                data-tip={inputs.cpn.message}
                                onChange={onInputChange}
                            />
                        </Tooltip>
                        :   categoryCPN
                    }
                    </div>
                </span>
                {cpnVariantScheme && user.data?.currentEnv === "LIVE" ?
                        cpnVariant_disabled ?
                            "-" + inputs.cpnVariant.value
                        :
                        <div className={`edit-cpn-variant-field ${cpnFreeFormClass}`}>
                            -
                            <LazyInput
                                type="text"
                                name="cpnVariant"
                                className={inputs.cpnVariant.class}
                                value={inputs.cpnVariant.value}
                                data-place="right"
                                data-type="error"
                                data-tip={inputs.cpnVariant.message}
                                onChange={onInputChange}
                                disabled={cpnVariant_disabled}
                            />
                        </div>
                    : null
                }
            </div>
        </div>
    )
}

export default CpnEditField;
