import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="#fffff"
        d="M5.17 12.583a5.17 5.17 0 105.17 5.17 5.17 5.17 0 00-5.17-5.17zm2.88 8.05a4.066 4.066 0 111.193-2.879v.008a4.041 4.041 0 01-1.193 2.872zM18.83 12.583A5.17 5.17 0 1024 17.76v-.007a5.17 5.17 0 00-5.17-5.17zm0 9.243a4.073 4.073 0 114.073-4.073 4.073 4.073 0 01-4.073 4.073zM17.143 6.24a5.17 5.17 0 10-5.17 5.17 5.17 5.17 0 005.17-5.17zM12 10.286a4.073 4.073 0 112.88-1.193A4.06 4.06 0 0112 10.286z"
      ></path>
    </svg>
  );
}

export default Icon;
