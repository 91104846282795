// import                      "./index.css";
import React, { Component } from "react";
import {connect}            from "react-redux";
import ModalBox             from "../../../../ui/modal-box";
import API                  from "../../../../../modules/api";
import LazyInput            from "../../../../ui/lazy-input/input.js";

export class NexusModal extends Component
{
    constructor(props, context)
    {
        super(props, context)
        this.message = "must contain atleast 1 character";
        this.state =
        {
            isConnectButtonEnable: false,
            inputs: {
                id: "",
                workflowName: "",
                clientId: "",
                clientSecret: "",
                organizationId: "",
                vendor: "Nexus",
                authType: "keyBased"
            },
            errorMessage: "",
            isEmpty: {workflowName: "", clientId: "", clientSecret: "", organizationId: ""},
            isMessage: {workflowName: "", clientId: "", clientSecret: "", organizationId: ""}
        }
        this.onClose       = this.onClose.bind(this);
        this.connect       = this.connect.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.handleOutsideClick  = this.handleOutsideClick.bind(this);
        this.toggleConnectButton = this.toggleConnectButton.bind(this);
    }

    componentDidMount()
    {
        document.addEventListener("click", this.handleOutsideClick, false);
    }

    componentWillUnmount()
    {
        document.removeEventListener("click", this.handleOutsideClick, false);
    }

    onClose()
    {
        document.removeEventListener("click", this.handleOutsideClick, false);
        this.props.onClose("displayNexusModal");
    }

    onInputChange(event)
    {
        let state = this.state;
        let {value, name} = event.target;
        let inputs = state.inputs;
        inputs[name] = value;
        let enableButton = this.toggleConnectButton();
        state.isConnectButtonEnable = enableButton;
        if(value === "")
        {
            state.isEmpty[name] = "invalid";
            state.isMessage[name] = this.message;
        }
        else
        {
            state.isEmpty[name] = "";
            state.isMessage[name] = "";
        }
        state.errorMessage = "";
        this.setState(state);
    }

    connect(event)
    {
        event.stopPropagation();
        let state = this.state;
        let data  = state.inputs;
        let apiRequest = API.integrations.nexusAuthentication;

        apiRequest(data, (err, response) =>
        {
            if(err)
            {
                state.errorMessage = "Unable to connect, Invalid credentials";
                this.setState(state);
                return;
            }
            this.props.showAlert("modal", "Connection Succeeded");
            this.onClose();
            this.props.getIntegrations();
        });
        this.setState(state);
    }

    toggleConnectButton()
    {
        let {clientId, workflowName, clientSecret} = this.state.inputs;
        let isEnable = true;
        if(clientId === "" || workflowName === "" || clientSecret === "") isEnable=false;
        return isEnable;
    }

    handleOutsideClick(e)
    {
        if(!this.node.contains(e.target) || e.target.classList.contains("background")) this.onClose();
    }

    render()
    {
        let state = this.state;
        let {workflowName, clientId, clientSecret, organizationId} = state.inputs;
        let { isConnectButtonEnable, errorMessage, isEmpty, isMessage } = state;
        let connectClass = `connect ${isConnectButtonEnable ? "" : "disable"}`;
        let markup =
        <div ref={node => { this.node = node;}}>
            <ModalBox onClose={this.onClose}>
                <div className="valispace-form-block">
                    <h1>Netsuite</h1>
                    <div className="field-block">
                    <label htmlFor="workflowName">Netsuite workflow name *</label>
                    <LazyInput
                        type="text"
                        name="workflowName"
                        data-tip="abc"
                        className={isEmpty["workflowName"]}
                        value={workflowName}
                        data-tip={isMessage["workflowName"]}
                        data-place="right"
                        data-type="error"
                        onChange={(e) => this.onInputChange(e)}
                    />
                    <label htmlFor="organizationId">Organization id *</label>
                    <LazyInput
                        type="text"
                        name="organizationId"
                        className={isEmpty["organizationId"]}
                        value={organizationId}
                        data-tip={isMessage["organizationId"]}
                        data-place="right"
                        data-type="error"
                        onChange={(e) => this.onInputChange(e)}
                    />
                    <label htmlFor="clientId">Client id *</label>
                    <LazyInput
                        type="text"
                        name="clientId"
                        className={isEmpty["clientId"]}
                        value={clientId}
                        data-tip={isMessage["clientId"]}
                        data-place="right"
                        data-type="error"
                        onChange={(e) => this.onInputChange(e)}
                    />
                    <label htmlFor="clientSecret">Client secret *</label>
                    <LazyInput
                        type="password"
                        name="clientSecret"
                        className={isEmpty["clientSecret"]}
                        value={clientSecret}
                        data-tip={isMessage["clientSecret"]}
                        data-place="right"
                        data-type="error"
                        onChange={(e) => this.onInputChange(e)}
                    />
                    </div>
                    {
                        errorMessage &&
                        <p className="error-message">{errorMessage}
                        </p>
                    }
                    <div className="btn-holder">
                        <div className="btn-container">
                            <button
                                className={connectClass}
                                onClick={isConnectButtonEnable ? this.connect : function(){}}
                                >
                                Connect
                            </button>
                            <button
                                className="cancel"
                                onClick={this.onClose}
                                >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </ModalBox>
        </div>
        return markup;
    }
}

export default connect((store) => store)(NexusModal)
