import                              "./index.css"
import React, {Component}           from "react"
import { SettingsTabs }             from "design/constants";
import Utils                        from "../../../../../modules/utils"
import Icon                         from "../../../../ui/icon"
import Link                         from "../../../../ui/link"
import closeSrc                     from "../../../../../assets/icons/close.svg"
import                              'rc-tooltip/assets/bootstrap_white.css'
import {connect}                    from "react-redux"
import CustomBlueToolTip            from "../../../../ui/custom-blue-tooltips-for-on-boarding"
import Permissions                  from "../../../../../modules/schemas/permissions"
import ComponentsHeaderNav          from "../../../../ui/components-header-nav"
import buildAction                  from "../../../../../helpers/buildAction"
import CO                           from '../../../../../action-types/changeorder';
import SEARCH                       from '../../../../../action-types/search';
import UI                           from '../../../../../action-types/ui';
import HelpIcon                     from "../../../../../assets/icons/help-icon.js";
import InlineIcon                   from "../../../../ui/icon/inline-icon.js";
import Integrations                 from "../../../../ui/integrations";
import LinkIcon                     from "../../../../../assets/icons/open-link-icon.js";
import CircularTick                 from "../../../../../assets/icons/circular-tick.js";
import TwoArrow                     from "../../../../../assets/icons/two-arrow.js";
import IntegrationsIcon             from "../../../../../assets/icons/plug";
import { privatePaths }             from "../../../../../app/routes";

export class Actions extends Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            modal :
            {
                open : false,
                height: "100vh"

            },

            type: {
                all: {
                    minHeight: "500px"
                },
                prd: {
                    minHeight: "525px"
                },
                cmp: {
                    minHeight: "630px"
                },
                co: {
                    minHeight: "500px"
                },
                rel: {
                    minHeight: "500px"
                }
            },

            keys :
            {
                all :
                [
                    {
                        name        : "id",
                        displayName : "ID"
                    },
                    {
                        name        : "eid",
                        displayName : "EID"
                    },
                    {
                        name        : "name",
                        displayName : "Name"
                    },
                    {
                        name        : "description",
                        displayName : "Description"
                    },
                    {
                        name        : "status",
                        displayName : "Status"
                    }
                ],

                prd :
                [
                    {
                        name        : "cpn",
                        displayName : "CPN"
                    },
                    {
                        name        : "eid",
                        displayName : "EID"
                    },
                    {
                        name        : "name",
                        displayName : "Name"
                    },
                    {
                        name        : "description",
                        displayName : "Description"
                    },
                    {
                        name        : "revision",
                        displayName : "Revision"
                    },
                    {
                        name        : "status",
                        displayName : "Status"
                    }
                ],

                cmp :
                [
                    {
                        name        : "cpn",
                        displayName : "CPN"
                    },
                    {
                        name        : "eid",
                        displayName : "EID"
                    },
                    {
                        name        : "category",
                        displayName : "Category"
                    },
                    {
                        name        : "name",
                        displayName : "Name"
                    },
                    {
                        name        : "description",
                        displayName : "Description"
                    },
                    {
                        name        : "revision",
                        displayName : "Revision"
                    },
                    {
                        name        : "status",
                        displayName : "Status"
                    }
                ],

                co :
                [
                    {
                        name        : "con",
                        displayName : "CON"
                    },
                    {
                        name        : "name",
                        displayName : "Name"
                    },
                    {
                        name        : "description",
                        displayName : "Description"
                    },
                    {
                        name        : "status",
                        displayName : "Status"
                    },
                    {
                        name        : "resolution",
                        displayName : "Resolution"
                    }
                ],

                rel :
                [
                    {
                        name        : "releaseNo",
                        displayName : "Release No"
                    },
                    {
                        name        : "name",
                        displayName : "Name"
                    },
                    {
                        name        : "description",
                        displayName : "Description"
                    },
                    {
                        name        : "author",
                        displayName : "Author"
                    },
                    {
                        name        : "source",
                        displayName : "Source"
                    }
                ]
            }
        }
        this.openModal                  = this.openModal.bind(this);
        this.closeModal                 = this.closeModal.bind(this);
        this.onModalExternalClick       = this.onModalExternalClick.bind(this);
        this.onInputChange              = this.onInputChange.bind(this);
        this.clearFields                = this.clearFields.bind(this);
        this.createCoWithDefaultData    = this.createCoWithDefaultData.bind(this);
        this.getComponentListFromAPI    = this.getComponentListFromAPI.bind(this);
        this.showUiAlert                = this.showUiAlert.bind(this);
    }

    showUiAlert(payload)
    {
        const {dispatch} = this.props;
        dispatch(buildAction(UI.SHOW_ALERT, payload))
    }

    getComponentListFromAPI()
    {
        const {dispatch} = this.props;
        let state = {query: "type:cmp"}
        const payload = {state}
        dispatch(buildAction(SEARCH.CHANGE_SEARCH_TYPE, payload));
    }

    createCoWithDefaultData()
    {
        const {dispatch} = this.props;
        let payload = {history: this.props.history, authorId: this.props.user.data._id}
        dispatch(buildAction(CO.CREATE_CO_WITH_DEFAULT_DATA, payload))
    }

    openModal(event)
    {
        let state = this.state;
        let modal = state.modal;
        document.body.addEventListener("click", this.onModalExternalClick)
        modal.open = true
        state.modal.height = this.props.height || document.getElementById("sidebar-view").clientHeight + 'px';
        this.setState(state);
    }

    closeModal()
    {
        document.body.removeEventListener("click", this.onModalExternalClick)
        let modal  = this.state.modal
        modal.open = false
        this.setState(this.state)
    }

    onModalExternalClick(event)
    {
        let target     = event.target
        let parent     = this.refs.modalNode
        let isExternal = target !== parent && !Utils.isDescendant(parent, target)
        if(isExternal) this.closeModal()
    }

    onInputChange(event)
    {
        let query   = this.props.query.object
        let name    = event.target.name
        let value   = event.target.value
        value.trim() === "" ? delete query[name] : query[name] = value
        this.props.onFieldChange(query)
    }

    clearFields()
    {
        let query = this.props.query.object

        Object.keys(query).forEach((key) =>
        {
            if(key !== "type")
            {
                delete query[key]
            }
        })

        this.props.onFieldChange(query)
    }

    formatVendorLabel(vendor)
    {
        if(vendor.toLowerCase() === "onshape") return "Onshape";
        else if(vendor.toLowerCase() === "solidworks") return "Solidworks";
        return "";
    }

    fillReleaseMenu(vendors, releaseMenu)
    {
        const { history } = this.props;
        const integrationsPathCallback = () => history.push({ pathname: privatePaths.settings.pathname, state: { currentTab: SettingsTabs.INTEGRATIONS }});
        let totalConnections = {label: `You have (${vendors.length}) connections`, labelWapperClass: 'total-connections border-bottom simple-label', isClickAble: false};
        releaseMenu.push(totalConnections);
        releaseMenu.push({label: "Integrations", icon: <IntegrationsIcon />, labelWapperClass: 'border-bottom simple-label-bold', isClickAble: false, iconClass: "vendor-icon"});
        vendors.map((vendor, i) => {
            let option = {label: this.formatVendorLabel(vendor), icon: <CircularTick/>, isClickAble: false, labelWapperClass: "menu-vendor-label", iconClass: "checked-icon"}
            releaseMenu.push(option)
        });
        vendors.length > 0 ?
        releaseMenu.push({label: "More Integrations", last: true, isClickAble: true, iconClass: "link-icon", onClick: integrationsPathCallback})
        : releaseMenu.push({label: "Want to integrate a 3rd party application?", labelWapperClass: "final-entry", linkText: "Duro Integrations", last: true, iconClass: "link-icon", onClick: integrationsPathCallback});
    }

    render()
    {
        let query = this.props.query
        let type  = this.props.query.object.type
        let keys  = this.state.keys[type]
        let modal = this.state.modal
        let currentCompany = this.props.company;

        let releaseClass = "";
        let releaseMenu = [];
        if(this.props.user && type === "rel" && Permissions.can("view", "release", this.props.user.data))
        {
            releaseClass = " release-block";
            let vendors = (this.props.company && this.props.company.data && this.props.company.data.vendors) ? this.props.company.data.vendors : [];
            this.fillReleaseMenu(vendors, releaseMenu)
        }

        if(!keys)
        {
            return null
        }

        let queryString = <div className="query-string">
                            <div className="app-row">{this.props.query.string}</div>
                        </div>

        queryString = null
        let success_message = this.props.location.state && this.props.location.state.success_message
        let total_records = this.props.location.state && this.props.location.state.total_records
        let imported_ids = this.props.location.state && this.props.location.state.imported_ids
        let is_after_bulk_update = this.props.location.state && this.props.location.state.is_after_bulk_update
        let displayRefDesAndItemNumber = false;
        if (imported_ids && total_records && total_records !== imported_ids.length)
        {
           success_message = (
                   <div>
                       <span className="error-msg">Errors.</span>
                       <span>You’ve imported </span>
                       <span className="error-msg">{imported_ids.length} </span>
                       <span >of {total_records} components</span>
                   </div>
            )
        }
        if(is_after_bulk_update)
        {
            success_message = this.props.location.state && this.props.location.state.success_message
        }

        if (this.props.company && this.props.company.data && this.props.company.data.settings && this.props.company.data.settings.displayRefDesAndItemNumber)
        {
           displayRefDesAndItemNumber = true;
        }

        let markup =
          <div className="actions-block">
              {queryString}
              <div className={`nav-block${releaseClass}`}>
                  <div className="actions-header-block">
                      {
                          type === "prd" && <div>
                              <h1>Products</h1>
                              {
                                  Permissions.can("create", "product", this.props.user.data) &&
                                  <Link to="/product/new" className='green new-button btn'>
                                      New
                                  </Link>
                              }

                          </div>
                      }

                      {
                          type === "cmp" &&
                          <div>
                              <div className="top-cmp-nav-holder">
                                  <h1>Library</h1>
                                  <ComponentsHeaderNav
                                    user={this.props.user.data}
                                    selectedTab={"allSearch"}
                                    history={this.props.history}
                                    getComponentListFromAPI={this.getComponentListFromAPI}
                                    showUiAlert={this.showUiAlert}
                                    displayRefDesAndItemNumber={displayRefDesAndItemNumber}
                                    integrations={this.props.integrations}
                                  />
                              </div>
                          </div>

                      }

                      {
                          type === "co" && Permissions.can("view", "change_order", this.props.user.data) ?

                            <div>
                                <h1>Change Orders</h1>
                                {
                                    Permissions.can("create", "change_order", this.props.user.data) &&
                                    <CustomBlueToolTip>
                                        <button className='green new-button' onClick={this.createCoWithDefaultData}>New</button></CustomBlueToolTip>
                                }
                            </div>
                            :
                            type === "co" &&
                            <div>
                                <h1>Search Results</h1>
                            </div>
                      }

                      {
                          type === "rel" && Permissions.can("view", "release", this.props.user.data) ?
                            <div className="flex-h">
                                <div className="flex release-heading">
                                    <div>
                                        <h1>Releases</h1>
                                    </div>
                                    <div className="vendor-list">
                                            <span data-tip="Help">
                                                <Link
                                                  to={{ pathname: "https://duro.zendesk.com/hc/en-us/articles/360043896172-Releases" }}
                                                  target="_blank"
                                                >
                                                    <InlineIcon
                                                    >
                                                        <HelpIcon/>
                                                    </InlineIcon>
                                                </Link>
                                            </span>
                                    </div>
                                </div>
                                {
                                    (currentCompany && currentCompany.data) ?
                                      <div className="vendors-container">
                                          <Integrations options={releaseMenu}>
                                              {
                                                  <button className="action-btn">
                                                      <InlineIcon>
                                                          <IntegrationsIcon/>
                                                      </InlineIcon>
                                                      Manage Integrations
                                                  </button>
                                              }
                                          </Integrations>
                                      </div>
                                      : ""
                                }
                            </div>
                            :
                            type === "rel" &&
                            <div>
                                <h1>Search Results</h1>
                            </div>
                      }

                      {
                          type === "all" && <div>
                              <h1>Search Results</h1>
                          </div>
                      }


                      <div>
                          {
                              type === "cmp" && <span className="success-message">
                                  {success_message}
                                </span>
                          }
                      </div>

                  </div>

                  <div className="refine-block hidden">
                      <div ref="modalNode" style={{height: modal.height, minHeight: this.state.type[type].minHeight}} className={"modal" + (modal.open ? " open" : "")}>
                          <div className="header-block">
                              <div className="clear-btn" onClick={this.clearFields}>CLEAR ALL FIELDS</div>
                              <div className="close-icon" onClick={this.closeModal}>
                                  <Icon src={closeSrc} />
                              </div>
                          </div>
                          <div className="keys">
                              {
                                  keys.map((key, i) =>
                                  {
                                      let value = query.object[key.name] || ""

                                      let block =
                                        <div key={i}>
                                            <label>{key.displayName}</label>
                                            <input
                                              autoComplete="off"
                                              type="text"
                                              name={key.name}
                                              value={value}
                                              onChange={this.onInputChange}
                                            />
                                        </div>
                                      return block
                                  })
                              }
                          </div>
                      </div>
                  </div>


              </div>

          </div>
        return markup
    }
}

export default connect((store) => store)(Actions)
