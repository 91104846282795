import "./index.css";
import "../index-generic.css";
import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import LazyInput from "../../../../ui/lazy-input/input.js";
import NumberFormat from 'react-number-format';
import InlineIcon from "../../../../ui/icon/inline-icon.js";
import ItemScheduleIcon from "../../../../../assets/icons/item-schedule-icon";
import Utils from "../../../../../modules/utils";
import validations, { validateField } from "../../../../../modules/validations";
import API from "../../../../../modules/api";
import { getHeadings, HEADINGS } from "../../extended-table/helpers";
import StatusField from "../../status-field";
import RevisionField from "../../revision-field";
import ExtendedTable from "../../extended-table"
import buildAction from "../../../../../helpers/buildAction";
import UI from '../../../../../action-types/ui';
import Spinner from "../../../../ui/spinner";
import PerfectScrollbar from 'react-perfect-scrollbar';

export class ProductionBuildModal extends Component
{
    constructor(props)
    {
        super(props)

        this.onDateChange = this.onDateChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.getFlattenedBomOfObject = this.getFlattenedBomOfObject.bind(this);
        this.getRows = this.getRows.bind(this);
        this.showAlert = this.showAlert.bind(this);

        let { user } = this.props;
        let styles = user && user.preferences && user.preferences.styles && user.preferences.styles.productionBuildTableStyles ? user.preferences.styles.productionBuildTableStyles : {};

        this.initialState =
        {
            inputs :
            {
                totalUnits :
                {
                    value   : "",
                    valid   : false,
                    message : "",
                    class   : ""
                },

                productionDate :
                {
                    value   : "",
                    valid   : false,
                    message : "",
                    class   : ""
                },

                submit:
                {
                    class : "disabled"
                }
            },
            currentSortItemAscending: ("defaultSortAssending" in styles ? styles.defaultSortAssending : true),
            current : ("defaultSortColumnName" in styles ? styles.defaultSortColumnName : "cpn"),
            headings: getHeadings(
                [
                  {
                    ...HEADINGS.cpn,
                    width: 120,
                  },
                  HEADINGS.name,
                  HEADINGS.status,
                  HEADINGS.revision,
                  HEADINGS.quantity,
                ],
                styles
            ),
            flattenedBom: null,
        }

        this.state = Utils.clone(this.initialState)
    }

    componentDidMount()
    {
        let { object } = this.props;
        if (object.children && object.children.length > 0)
        {
            this.getFlattenedBomOfObject();
        }
    }

    onDateChange(date, event)
    {
        let name  = "productionDate";
        let value = date && new Date(date.toISOString()).getTime();
        this.onInputChange({target: {name: name, value: Number(value)}});
    }

    onInputChange(event)
    {
        let { inputs } = this.state;
        let value = event.target.value;
        let name = event.target.name;
        let input  = ""

        switch(name)
        {
            case "totalUnits" :
            {
                value = !!value ? Number(value) : value;
                validateField(inputs.totalUnits, validations.productionBuild.totalUnits, value);
                break;
            }

            case "productionDate" :
            {
                validateField(inputs.productionDate, validations.productionBuild.productionDate, value);
                break;
            }
        }

        inputs.submit.class = Utils.isValidated(inputs) ? "" : "disabled";
        this.setState({inputs: inputs});
    }


    onSubmit(event, options=null)
    {
        event && event.preventDefault();
        let { object, isProductionBuildExist } = this.props;
        let { inputs } = this.state;

        if(inputs.submit.class === "disabled")
        {
            return
        }

        let payload =
        {
            totalUnits: inputs.totalUnits.value,
            productionDate: inputs.productionDate.value,
            parent: object._id,
            alias: object.alias,
        }

        API.productionBuilds.create(payload, (err, response) =>
        {
            if(err)
            {
                this.showAlert(err.errors)
            }
            if (!isProductionBuildExist) this.props.setIsProductionBuildExist();
            this.props.refreshSerializationTab();
        });

        this.props.onCancel();
    }

    getFlattenedBomOfObject()
    {
        let { object } = this.props;
        let data = {objectIds: [object._id], alias: object.alias, excludeParent: true};
        API.products.getFlattenedBomInBulk(data , (err, data) =>
        {
            if (data && data.length > 0 && data[0].flattenedBom)
            {
                this.setState({flattenedBom: data[0].flattenedBom});
            }
        })
    }

    showAlert(errors){
        const { dispatch } = this.props;
        dispatch(buildAction(UI.SHOW_ALERT, {type: "errors", errors: errors}));
    }

    getRows()
    {
        let { flattenedBom } = this.state;
        let { object } = this.props;

        let rows = [];
        if (flattenedBom)
        {
            rows = flattenedBom.map((item) =>
            {
                let link  = `/component/view/${item._id}`;
                let revision = item.revision;
                let revisionInfo = {alias: "cmp", modified: false};
                if (revision && revision.includes("*"))
                {
                    revisionInfo.modified = true;
                    revision = revision.replace("*", "");
                }

                let cells = {

                    "cpn" : {
                        value       : item.cpn,
                        tooltip     : item.cpn,
                        displayValue : <span className='link'>{item.cpn}</span>,
                    },
                    "name" : {
                        value       : item.name,
                        displayValue: item.name,
                        tooltip     : item.name
                    },
                    "status" : {
                        value       : item.status,
                        displayValue: <StatusField item={item} status={item.status}/>,
                        tooltip     : item.status
                    },
                    "revision" : {
                        value       : item.revision,
                        displayValue: <RevisionField item={revisionInfo} revision={revision} showIcon={true}/>,
                        tooltip     : "tooltip"
                    },
                    "quantity" : {
                        value       : item.quantity,
                        displayValue: item.quantity,
                        tooltip     : item.quantity
                    },
                    rowLink      : link,
                }

                return cells
            });
        }

        return rows;
    }

    render()
    {
        let { isOpen, object } = this.props;
        let { inputs, headings, current, currentSortItemAscending, flattenedBom } = this.state;
        let markup = null;
        let rows = this.getRows();

        markup =
            <div ref="productionBuildFormNode" id="production-build-modal" className={`custom-duro-modal ${isOpen ? "open" : ""} ${object.children && object.children.length > 0 ? "having-children" : ""}`}>
                <div className="content-block">
                    <div className="content-header">
                        <div className="heading-block">
                            <h2>Create Production Build</h2>
                        </div>
                        <div className="warning-text">
                            <div className="inputs-section">
                                <div className="total-units-input-label">
                                    <label className="muted-text">
                                        Number of Units
                                    </label>
                                </div>
                                <div className="total-units-input-holder">
                                    <NumberFormat
                                        type="text"
                                        name="totalUnits"
                                        className={inputs.totalUnits.class}
                                        value={inputs.totalUnits.value}
                                        onChange={this.onInputChange}
                                        data-place="right"
                                        data-type="error"
                                        data-for='production-build-modal-input'
                                        data-tip={inputs.totalUnits.message}
                                        autoFocus={true}
                                        thousandSeparator={true}
                                        allowNegative={false}
                                        allowLeadingZeros={false}
                                        decimalSeparator = {false}
                                    />
                                </div>
                            </div>
                            <div className="inputs-section">
                                <div className="production-date-input-label">
                                    <label className="muted-text">
                                        Production Date
                                    </label>
                                </div>
                                <div className="production-date-picker-input-holder">
                                    <DatePicker
                                        selected={inputs.productionDate.value ? new Date(inputs.productionDate.value) : null}
                                        onChange={(date) => this.onDateChange(date)}
                                        dateFormat={"MM/dd/yyyy"}
                                        className={`production-build-date-trigger`}
                                        customInput={
                                            <input
                                                type="text"
                                                value={inputs.productionDate.value}
                                                data-tip={inputs.productionDate.message}
                                                data-place="right"
                                                data-type="error"
                                            />
                                        }
                                    />
                                    <InlineIcon
                                        tooltip={`${inputs.productionDate.message ? inputs.productionDate.message : ""}`}
                                        tooltipType="error"
                                        tooltipPlace="right"
                                    >
                                        <ItemScheduleIcon onClick={() => document.querySelector(`.production-build-date-trigger`) && document.querySelector(`.production-build-date-trigger`).click()}/>
                                    </InlineIcon>
                                </div>
                            </div>
                        </div>
                        <div className="button-holder">
                            <button
                              type="button"
                              onClick={(e) => {this.props.onCancel()}}
                              >
                            Cancel
                            </button>

                            <button
                              type="button"
                              onClick={this.onSubmit}
                              disabled={inputs.submit.class === "disabled" ? true : false}
                              className={inputs.submit.class}
                            >
                            Apply
                          </button>
                        </div>
                    </div>

                    {
                        object.children && object.children.length > 0 &&
                        <div className="content-body">
                            <div className="heading-block">
                                <h2>Flattened BOM</h2>
                            </div>
                            {
                                !flattenedBom
                                ?
                                    <Spinner/>
                                :
                                    <PerfectScrollbar className="custom-scroolbar">
                                        <ExtendedTable
                                            wrapperClassName="production-build-flattened-bom-list"
                                            wrapperSelectorClass="production-build-flattened-bom-table"
                                            themeDataTableId={"production-build-flattened-bom-table"}
                                            stylesName="productionBuildTableStyles"
                                            currentSortItem={current}
                                            currentSortItemAscending={currentSortItemAscending}
                                            headings={headings}
                                            rows={rows}
                                            borderedTable={true}
                                            disableDataSorting={true}
                                            allowRowSelect={false}
                                            includeToolBar={true}
                                            resultText={`${rows.length} Components`}
                                        />
                                    </PerfectScrollbar>
                            }
                        </div>
                    }

                </div>
            </div>

        return markup
    }
}

export default ProductionBuildModal
