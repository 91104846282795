import mapActions from '../helpers/mapActions';

export default mapActions(
    [
        "SUBMIT_COMPONENT_NEW_FORM",
        "DISABLE_FORM",
        "GET_COMPONENT",
        "GET_COMPONENT_NESTED_DATA",
        "SET_COMPONENT",
        "SET_COMPONENT_NESTED_CHILDREN",
        "SET_COMPONENT_NESTED_DOCUMENTS",
        "SET_COMPONENT_CHILDREN_LOADED",
        "SET_COMPONENT_DOCUMENTS_LOADED",
        "MAKE_DUPLICATE",
        "REVERT_CHANGES",
        "MARK_FAVOURITE",
        "RESET_STATES_IN_VIEW_PAGE",
        "RESET_STATES_IN_REVISION_PAGE",
        "GET_REVISION_IN_REVISION_PAGE",
        "SET_REVISION_IN_REVISION_PAGE",
        "GET_REVISION_NESTED_DATA",
        "SET_REVISION_NESTED_CHILDREN",
        "SET_REVISION_NESTED_DOCUMENTS",
        "SET_REVISION_CHILDREN_LOADED",
        "SET_REVISION_DOCUMENTS_LOADED",
        'UPDATE_EDIT_COMPONENT_FORM_FIELDS',
        'UPDATE_EDIT_COMPONENT_FORM_FIELDS_WITH_MODIFIED',
        'UPDATE_EDIT_FORM_INPUT_STATE',
        'SET_EDIT_PAGE_MODIFIED',
        'RESET_COMPONENT_EDIT_FORM',
        'RESET_COMPONENT_EDIT_FORM_INPUTS',
        'SUBMIT_COMPONENT_EDIT_FORM',
        'SET_EDIT_PAGE_COMPONENT_SEARCH_RESULTS',
        'SEARCH_COMPONENTS_IN_EDIT_PAGE',
        'SET_COMPONENT_IN_EDIT_FORM',
        'GET_COMPONENT_AND_SET_IN_EDIT_PAGE',
        'DELETE_COMPONENT',
        "SET_IS_NEW_OBJECT_FLAG",
        "SET_ORIGNAL_COMPONENT_ID",
        "UPLOAD_IMAGES_ON_EDIT_PAGE",
        "UPDATE_IMAGES_STATE_ON_EDIT_PAGE",
        "SET_LOADING",
        "UPDATE_ASSEMBLY_ON_EDIT_PAGE",
        "EDIT_FORM_NAME_CHANGE",
        "EDIT_FORM_EID_CHANGE",
        "EDIT_FORM_CPN_CHANGE",
        "SET_PRIMARY_SOURCE",
        "CREATE_VARIANT",
        "SET_COMPONENT_PRIMARY_SOURCE"
    ],
    "COMPONENT"
)
