import React from "react";

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='13'
      version='1.1'
      viewBox='0 0 16 13'
      id="assembly-add-icon"
    >
      <g
        fill='none'
        fillRule='evenodd'
        stroke='none'
        strokeLinecap='round'
        strokeWidth='1'
      >
        <g
          fillRule='nonzero'
          stroke='#FFF'
          strokeWidth='1.5'
          transform='translate(-2312 -637)'
        >
          <g transform='translate(1711 626)'>
            <g transform='translate(521)'>
              <g transform='translate(81 9)'>
                <g transform='translate(0 1)'>
                  <path d='M.551 8.522h13.043M5.5 5.16h8.094M10.5 1.797h3.094M.551 12.428h13.057'></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
