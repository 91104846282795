import React from "react"

function SvgComponent(props) {
  return (
    <svg
      width="17px"
      height="20px"
      viewBox="0 0 17 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g stroke="#FFF" strokeWidth={1} fill="none" fillRule="evenodd">
        <g fillRule="evenodd" transform="translate(.5 .6) translate(0 14.9)">
          <path d="M2.5.5v3M4.5.5v3" />
          <path d="M0.5 0.5L0.5 3.5 6.5 3.5 6.5 0 0 0" />
        </g>
        <g fillRule="evenodd" transform="translate(.5 .6) translate(9 7.9)">
          <path d="M2.5.5v3M4.5.5v3" />
          <path d="M0.5 0.5L0.5 3.5 6.5 3.5 6.5 0 0 0" />
        </g>
        <path
          d="M1 14.4V4.9M1 4.9C1 .87 6.756-.848 10.5.4c.74.247 3.088 1.26 4 3 .938 1.791.43 4.31.5 4.5M6 14.4V5.9"
          transform="translate(.5 .6)"
        />
        <path
          d="M5.986 6.044c.044-.26.11-.782.514-1.144.359-.321 1.03-.5 1.5-.5.003 0 1 0 1.5.5.698.698.5 2.39.5 2.5"
          transform="translate(.5 .6)"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
