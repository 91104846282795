import React, { Component }      from "react"

class SvgImage extends Component
{
    render()
    {
        return <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 32 32">
                <path fill="none" stroke="#fff" strokeWidth="1.7778" strokeMiterlimit="4" strokeLinecap="round" strokeLinejoin="round" d="M2.573 25.193h22.62v-22.62h-22.62z"></path>
                <path fill="none" stroke="#fff" strokeWidth="1.7778" strokeMiterlimit="4" strokeLinecap="round" strokeLinejoin="round" d="M30.602 7.982v22.62h-22.62"></path>
                </svg>

    }
}

export default SvgImage
