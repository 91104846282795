import mapActions from '../helpers/mapActions';

export default mapActions(
  [
   "SET_INITIAL_STATE",
   "GET_SEARCH_DATA",
   "UPDATE_SEARCH_STATE",
   "SET_LOADING",
   "SET_INPUT_FOCUSED",
   "CHANGE_SEARCH_TYPE",
   "RESET_STATE",
   "DELETE_ITEMS",
   "REVERT_ITEMS",
   "DUPLICATE_ITEMS",
   "CLEAR_SELECTED_RESULTS"
  ],
  "SEARCH"
)
