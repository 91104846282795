import COMPONENT         from "../../../action-types/component"
import initialState, { getInitialState } from "./initial_state"
import { handleActions } from "redux-actions"

function setComponent(state= initialState, { payload })
{
    state.component  = payload;
    return {...state};
}

function setNestedDocumentsOfComponent(state= initialState, { payload })
{
    state.component.documents  = payload;
    return {...state};
}

function setNestedChildrenOfComponent(state= initialState, { payload })
{
    state.component.children  = payload;
    return {...state};
}

function setLoading(state= initialState, { payload })
{
    state.loading  = payload
    return {...state}
}

function setDocumentsLoaded(state= initialState, { payload })
{
    state.documents_loaded  = payload;
    return {...state};
}

function setChildrenLoaded(state= initialState, { payload })
{
    state.children_loaded  = payload;
    return {...state};
}


function resetPageStates(state=initialState, { payload })
{
    return {...getInitialState()};
}

function setComponentPrimarySource(state= initialState, {payload})
{
    state.component = payload;
    state.tabsUpdatedAt = new Date().getTime();
    return {...state};
}

export default handleActions({
  [COMPONENT.RESET_STATES_IN_VIEW_PAGE]: resetPageStates,
  [COMPONENT.SET_LOADING]: setLoading,
  [COMPONENT.SET_COMPONENT]: setComponent,
  [COMPONENT.SET_COMPONENT_NESTED_DOCUMENTS]: setNestedDocumentsOfComponent,
  [COMPONENT.SET_COMPONENT_NESTED_CHILDREN]: setNestedChildrenOfComponent,
  [COMPONENT.SET_COMPONENT_DOCUMENTS_LOADED]: setDocumentsLoaded,
  [COMPONENT.SET_COMPONENT_CHILDREN_LOADED]: setChildrenLoaded,
  [COMPONENT.SET_COMPONENT_PRIMARY_SOURCE]: setComponentPrimarySource,
}, initialState);
