import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { BuildPageWrapper } from "build/components/pageWrapper";
import { BreadCrumbIcon, useBreadCrumb } from "common/components/breadcrumb";
import { privatePaths } from "v1/app/routes";
import { IToolbarActions } from "common/components/toolbar";
import { DuroZen, SerializationIcon } from "assets/icons";
import { SerializationModal } from "build/components/serializationModal";
import { Box, styled } from "@mui/material";
import {
  CellContentAlignment,
  DELETE_TOOLBAR_ICON_PROPS,
  EmptyTableTileProps,
  EXPORT_TOOLBAR_ICON_PROPS,
  FILTER_TOOLBAR_ICON_PROPS,
  Grid,
  REFRESH_TOOLBAR_ICON_PROPS,
  SortMode,
} from "common/components/grid";
import { GridColDef, GridSelectionModel, GridSortModel, useGridApiRef } from "@mui/x-data-grid-pro";
import { LotListOrderBy, LotListInput, useLotList } from "graphql/query/lotsQueries";
import { DateTimeFieldFormat, NotesField, StatusField, TimestampField } from "common/components/fields";
import { Link } from "common/components/link";
import { Lot } from "build/models";
import { useToasts } from "common/components/toasts";
import { useManageNoRowsOverlay } from "build/components/grid";
import { useHistory } from "react-router-dom";

const BREADCRUMB_OPTIONS = {
  icon: BreadCrumbIcon.PRODUCTS,
  label: "Lots",
  url: privatePaths.buildLots.pathname,
};

const defaultRows: Lot[] = [];
const gridName = "build-lots";
const pinnedColumns = { left: ["numberDisplayValue"] };
const primaryRevisionSortField = ["cpn", "name", "revisionValue"];
const scrollEndThreshold = 2000;

export function BuildLots() {
  const history = useHistory();
  const breadCrumbOptions = useBreadCrumb(BREADCRUMB_OPTIONS);
  const { enqueueToast } = useToasts();
  const [displaySerializationModal, setDisplaySerializationModal] = useState(false);
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "numberDisplayValue", sort: "asc" }]);

  const apiRef = useGridApiRef();

  const toggleSerializationModal = useCallback(() => (
    setDisplaySerializationModal((displayModal: boolean) => !displayModal)
  ), []);

  const onSubmitSerializationModal = useCallback(() => {
    setDisplaySerializationModal(false);
  }, []);

  const toolbarActionItems: IToolbarActions[] = useMemo(() => ([{
    disabled: true,
    Icon: SerializationIcon,
    label: "Serialize",
    onClick: () => toggleSerializationModal(),
  }]), [toggleSerializationModal]);

  const onSortModelChange = useCallback((model: GridSortModel) => {
    if (model.length) {
      setSortModel(model);
    }
  }, []);

  const queryInput: LotListInput = useMemo(() => ({
    orderBy: sortModel.map(({ field, sort }) => {
      if (primaryRevisionSortField.includes(field)) {
        return { primaryRevision: { [field]: sort } } as LotListOrderBy;
      }
      return ({ [field]: sort }) as LotListOrderBy;
    }),
  }), [sortModel]);

  // TODO: (PLA-544) use selectionModel when implementing delete
  const [/* selectionModel */, setSelectionModel] = useState<GridSelectionModel>([]);
  const onSelectionChange = useCallback((model: GridSelectionModel) => {
    setSelectionModel(model);
  }, []);

  const {
    endCursor,
    error: lotsError,
    fetchLots,
    hasNextPage,
    loading: lotsLoading,
    totalCount,
  } = useLotList({ input: queryInput, pageSize: 100 });

  useEffect(() => {
    if (lotsError?.message) {
      enqueueToast({
        message: "Failed to load the list of lost.",
        persist: true,
        variant: "error",
      });
    }
  }, [enqueueToast, lotsError?.message]);

  const loadFirstPage = useCallback(async () => {
    const { lots } = await fetchLots(undefined, queryInput);
    apiRef.current.setRows(lots);
  }, [apiRef, fetchLots, queryInput]);

  useLayoutEffect(() => {
    loadFirstPage();
  }, [loadFirstPage]);

  const rowCount = useMemo(() => (
    `${totalCount?.toLocaleString()} ${totalCount === 1 ? "LOT" : "LOTS"}`
  ), [totalCount]);

  const loadMoreData = useCallback(() => {
    if (!hasNextPage || lotsLoading) return;

    fetchLots(endCursor, queryInput).then(({ lots }) => apiRef.current.updateRows(lots));
  }, [apiRef, endCursor, fetchLots, hasNextPage, lotsLoading, queryInput]);

  const toolbarItems = useMemo(() => [
    {
      // TODO: (PLA-545) Implement editing serial ID's
      ...EXPORT_TOOLBAR_ICON_PROPS,
      disabled: true,
    },
    {
      // TODO: (PLA-544) Implement delete when one lot is selected.
      ...DELETE_TOOLBAR_ICON_PROPS,
      disabled: true,
    },
    {
      // TODO: (PLA-542) Implement filtering the table
      ...FILTER_TOOLBAR_ICON_PROPS,
      disabled: true,
    },
    {
      ...REFRESH_TOOLBAR_ICON_PROPS,
      onClick: loadFirstPage,
    },
  ], [loadFirstPage]);

  const noLotsTiles: EmptyTableTileProps[] = useMemo(() => [
    {
      buttonLabel: "Return to Design",
      Icon: DuroZen,
      onClick: () => history.push(privatePaths.dashboard.pathname),
      subText: "Switch to the design workspace to serialize your first product or assembly.",
      text: "No lots available",
    },
  ], [history]);

  const { OverlayComponent, OverlayProps } = useManageNoRowsOverlay(
    false, // TODO (PLA-542): Set this to true if there are filters.
    noLotsTiles,
  );

  return (
    <BuildPageWrapper
      breadCrumbOptions={breadCrumbOptions}
      helmetTitle="Build Lot Library"
      toolbarProps={{
        toolbarItems: toolbarActionItems,
      }}
    >
      <TableWrapper>
        <Grid
          apiRef={apiRef}
          columnDefinition={lotColumns}
          data={defaultRows}
          loading={lotsLoading}
          name={gridName}
          noRowsOverlay={OverlayComponent}
          noRowsOverlayProps={OverlayProps}
          onRowScrollEnd={loadMoreData}
          onSelectionChange={onSelectionChange}
          onSortChange={onSortModelChange}
          pinnedColumns={pinnedColumns}
          scrollEndThreshold={scrollEndThreshold}
          sortMode={SortMode.SERVER}
          sortModel={sortModel}
          toolbarItems={toolbarItems}
          totalCount={rowCount}
        />
      </TableWrapper>
      {displaySerializationModal && (
        <SerializationModal
          onCancel={toggleSerializationModal}
          onClose={toggleSerializationModal}
          onSubmitCallback={onSubmitSerializationModal}
          open={displaySerializationModal}
          title="Serialize and Create New Lot"
        />
      )}
    </BuildPageWrapper>
  );
}

const TableWrapper = styled(Box)({
  display: "flex",
  flex: 1,
  padding: "0 1.5rem 1.5rem",
});

const lotColumns: GridColDef[] = [
  {
    editable: false,
    field: "numberDisplayValue",
    headerName: "Lot ID",
    hideable: false,
    minWidth: 150,
    renderCell: r => (<Link to={{ pathname: `/build/lot/${r.row.id}` }}>{r.value}</Link>),
    sortable: true,
    valueGetter: r => r.row.number.displayValue,
    width: 150,
  },
  {
    align: CellContentAlignment.RIGHT,
    editable: false,
    field: "productionRun",
    headerName: "Production Run",
    hideable: true,
    minWidth: 150,
    sortable: true,
    valueGetter: r => r.row.productionRun,
    width: 150,
  },
  {
    editable: false,
    field: "cpn",
    headerName: "CPN",
    hideable: true,
    minWidth: 150,
    sortable: true,
    valueGetter: r => r.row.primaryRevision?.cpn ?? "Mixed",
    width: 150,
  },
  {
    editable: false,
    field: "name",
    headerName: "Name",
    hideable: true,
    minWidth: 150,
    sortable: true,
    valueGetter: r => r.row.primaryRevision?.name ?? "-",
    width: 150,
  },
  {
    editable: false,
    field: "revisionValue",
    headerName: "Rev",
    hideable: true,
    minWidth: 100,
    sortable: true,
    valueGetter: r => r.row.primaryRevision?.revisionValue ?? "-",
    width: 100,
  },
  {
    editable: false,
    field: "status",
    headerName: "Status",
    hideable: true,
    minWidth: 150,
    renderCell: r => (<StatusField label={r.value} />),
    sortable: true,
    valueGetter: r => r.row.status,
    width: 150,
  },
  {
    editable: false,
    field: "labels",
    headerName: "Labels",
    hideable: true,
    minWidth: 150,
    sortable: true,
    valueGetter: r => r.row.labels?.join(", "),
    width: 150,
  },
  {
    editable: false,
    field: "notes",
    headerName: "Lot Notes",
    hideable: true,
    minWidth: 150,
    renderCell: r => (<NotesField notes={r.row.notes ?? ""} />),
    sortable: true,
    valueGetter: r => r.row.notes,
    width: 150,
  },
  {
    editable: false,
    field: "dueDate",
    headerName: "Due By",
    hideable: true,
    minWidth: 150,
    renderCell: r => (<TimestampField dateTime={r.value} format={DateTimeFieldFormat.DATE_LONG} />),
    sortable: true,
    valueGetter: r => r.row.dueDate,
    width: 150,
  },
  {
    editable: false,
    field: "createdDate",
    headerName: "Date Created",
    hideable: true,
    minWidth: 170,
    renderCell: r => (<TimestampField dateTime={r.value} format={DateTimeFieldFormat.DATE_TIME_LONG} />),
    sortable: true,
    valueGetter: r => r.row.createdDate,
    width: 170,
  },
];
