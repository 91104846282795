import React from "react";

function Icon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 35 35'>
      <g data-name='Layer 2'>
        <g>
          <path
            d='M17.5 33.44a1.14 1.14 0 01-.64-.19C16.17 32.78 0 21.73 0 12.17 0 5.62 3.9 1.56 10.18 1.56A10 10 0 0117.5 4.8a10 10 0 017.32-3.24A10.42 10.42 0 0135 12.17c0 9.14-16.16 20.59-16.85 21.07a1.13 1.13 0 01-.65.2zM10.18 3.82c-2.38 0-7.91.81-7.91 8.34s12.25 16.63 15.22 18.76c4.79-3.5 15.24-12.44 15.24-18.75a8.15 8.15 0 00-7.92-8.34 7.82 7.82 0 00-6.38 3.43 1.18 1.18 0 01-1.86 0 7.73 7.73 0 00-6.39-3.44z'
            className='cls-10000'
          ></path>
          <path d='M0 0H35V35H0z' className='cls-2'></path>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
