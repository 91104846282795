import React from "react";

function Icon() {
  return (
    <svg width="16px" height="11px" viewBox="0 0 16 11" version="1.1">
        <g id="MANDATORY-APPROVERS" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="SETTINGS-3" transform="translate(-607.000000, -307.000000)">
                <rect x="0" y="0" width="1350" height="960"></rect>
                <g id="Modal" transform="translate(392.000000, 119.000000)">
                    <g id="TABLE" transform="translate(40.000000, 141.000000)">
                        <g id="ECO-PROD" transform="translate(0.000000, 36.000000)">
                            <g id="Approvers" transform="translate(172.000000, 4.000000)">
                                <g id="visibility" transform="translate(3.000000, 5.000000)">
                                    <polygon id="Path" points="0 0 16 0 16 16 0 16"></polygon>
                                    <path d="M8,4 C10.5266667,4 12.78,5.42 13.88,7.66666667 C12.78,9.91333333 10.5266667,11.3333333 8,11.3333333 C5.47333333,11.3333333 3.22,9.91333333 2.12,7.66666667 C3.22,5.42 5.47333333,4 8,4 M8,2.66666667 C4.66666667,2.66666667 1.82,4.74 0.666666667,7.66666667 C1.82,10.5933333 4.66666667,12.6666667 8,12.6666667 C11.3333333,12.6666667 14.18,10.5933333 15.3333333,7.66666667 C14.18,4.74 11.3333333,2.66666667 8,2.66666667 Z M8,6 C8.92,6 9.66666667,6.74666667 9.66666667,7.66666667 C9.66666667,8.58666667 8.92,9.33333333 8,9.33333333 C7.08,9.33333333 6.33333333,8.58666667 6.33333333,7.66666667 C6.33333333,6.74666667 7.08,6 8,6 M8,4.66666667 C6.34666667,4.66666667 5,6.01333333 5,7.66666667 C5,9.32 6.34666667,10.6666667 8,10.6666667 C9.65333333,10.6666667 11,9.32 11,7.66666667 C11,6.01333333 9.65333333,4.66666667 8,4.66666667 Z" id="Shape" fill="#888888" fill-rule="nonzero"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
  );
}

export default Icon;
