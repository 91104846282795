import                      "./index.css"
import                      'rc-tooltip/assets/bootstrap_white.css'
import React, {Component}   from "react"
import Link                 from "../../../../ui/link"
import Utils                from "../../../../../modules/utils"
import InlineIcon           from "../../../../ui/icon/inline-icon.js"
import CoIcon               from "../../../../../assets/icons/co"
import CTAIconToolbar       from "../../../common/cta-icon-toolbar"
import EllipsisTooltip      from "../../../common/ellipsis-tooltip"

class Actions extends Component
{
    constructor(props, context)
    {
        super(props, context)

        this.state =
        {
            isFavorite: this.props.isFavorite,
            deleteFlag: false,
        }

        this.redirect                   = this.redirect.bind(this)
        this.gotoRevision               = this.gotoRevision.bind(this)
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.isFavorite !== this.state.isFavorite) {
        let state = {...this.state}
        state.isFavorite = nextProps.isFavorite
        this.state = state
        this.setState(this.state)
      }
    }

    redirect(path, state)
    {
        document.body.removeEventListener("click", this.onRevModalExternalClick)
        this.props.history.push({pathname: path, state: state})
    }

    gotoRevision(id, isMostRecent=false)
    {
        if (isMostRecent)
        {
            this.redirect(`/product/view/${id}${ window.__userRole === "SUPPLIER" ? '?viewRecent=true' : ''}`)
        }
        else
        {
            this.redirect(`/product/revision/${id}`)
        }
    }

    render()
    {
        // let { productionBuildModal, revModal, revertModal, showEditWarningWhileInCO } = this.state;
        // let { isProductionBuildExist } = this.props;
        let user        = this.props.user.data
        let product     = this.props.product
        let co          = this.props.product.co
        // let modified    = this.props.product.modified
        let markup    = null

        let disabledRevisionId = null
        let revision  = product.revisions.slice(-1)[0]
        if (revision)
        {
            disabledRevisionId = revision;
        }

        let productEditLink = "/product/edit/" + product._id
        let cpn             = Utils.getCpn(product);

        markup =
            <div className="actions-block">
                {
                    (product.archived || co) &&
                    <div className={`banner-block${product.archived ? ' archived' : ''}`}>
                        <div className="app-row">
                            {
                                product.archived &&
                                <div>
                                    <div>This component has been deleted.</div>
                                </div>
                            }
                            {
                                co &&
                                <div>
                                    <InlineIcon>
                                        <CoIcon />
                                    </InlineIcon>
                                    <div>This product is in an unresolved change order</div>
                                    <Link to={`/changeorder/view/${co._id}`}>{co.con}</Link>
                                    <div>{`with Status: ${co.status}`}</div>
                                </div>
                            }
                        </div>
                    </div>
                }
                <div className="nav-block app-row">
                    <EllipsisTooltip
                        classes={"ellipsis-tooltip tooltip-no-width"}
                        title={product.name}
                        innerClasses={"truncate-name"}
                    >
                        {product.name}
                    </EllipsisTooltip>
                    <CTAIconToolbar
                        { ...this.props }
                        user={user}
                        item={product}
                        itemName="product"
                        itemEditLink={productEditLink}
                        disabledRevisionId={disabledRevisionId}
                        gotoRevision={this.gotoRevision}
                    />
                </div>
            </div>
        return markup
    }
}

export default Actions
