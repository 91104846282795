class Assembly
{
  static compareQuantityAndRefDes(refDesValue, quantityValue, cb)
  {
      if ( quantityValue )
      {
          let refDesArray     = refDesValue.split(/,| |:|;/).filter(function(v){return v!==''})
          
          if (refDesValue && parseInt(quantityValue, 10) !== refDesArray.length)
          {
            return cb(true, "Quantity and number of Ref Des should be the same")
          }
          else
          {
              return cb(false, "")
          }
          
      }
  }
}
export default Assembly
