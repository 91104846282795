import { Box, styled, Typography } from "@mui/material";
import { ReactNode } from "react";
import { EmptyTableTile, EmptyTableTileProps } from "./emptyTableTile";

export interface TileStyles {
  height?: string;
  justifyContent?: string;
  padding?: string;
  width?: string;
}

export interface EmptyTableProps {
  footer?: ReactNode;
  subtitle?: string;
  tiles?: EmptyTableTileProps[];
  tileStyles?: TileStyles;
  title?: string;
}

export function EmptyTable({ footer, subtitle, tiles, tileStyles, title }: EmptyTableProps) {
  return (
    <ContentWrapper>
      {title && (
        <TitleSection>{title}</TitleSection>
      )}
      {subtitle && (
        <BaseTypography>{subtitle}</BaseTypography>
      )}
      {tiles?.length && (
        <TilesWrapper>
          <TilesSection tileStyles={tileStyles}>
            {tiles.map((tile, index) => (
              <EmptyTableTile {...tile} key={`tile-${index}`} />
            ))}
          </TilesSection>
        </TilesWrapper>
      )}
      {footer && (
        <BaseTypography>{footer}</BaseTypography>
      )}
    </ContentWrapper>
  );
}

export const ContentWrapper = styled(Box)({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  height: "100%",
  overflow: "hidden",
  padding: "0.5rem",
  textAlign: "center",
  zIndex: 1,
});

const BaseTypography = styled(Typography)({
  fontSize: "0.875rem",
  padding: "0.5rem",
  "& a": {
    fontSize: "0.875rem",
  },
});

const TitleSection = styled(Typography)({
  fontSize: "1.5rem",
  fontWeight: "bold",
  padding: "0.5rem",
  "& a": {
    fontSize: "1.5rem",
  },
});

const TilesWrapper = styled(Box)<{}>({
  display: "flex",
  justifyContent: "center",
});

const tilesSectionFilteredProps: PropertyKey[] = ["tileStyles"];
const TilesSection = styled(Box, {
  shouldForwardProp: propName => !tilesSectionFilteredProps.includes(propName),
})<{ tileStyles?: TileStyles }>(({ tileStyles }) => ({
  display: "flex",
  overflowX: "auto",
  "& > div": {
    height: tileStyles?.height ?? "13.5rem",
    justifyContent: tileStyles?.justifyContent ?? "space-between",
    padding: tileStyles?.padding ?? "1rem",
    width: tileStyles?.width ?? "13.5rem",
  },
}));
