import { deleteUserFromCache } from "graphql/query/userQueries";
import { client } from "graphql/apolloClient"
import {
    takeLatest,
    takeEvery,
    all,
    call,
    put,
    cps,
    select
}                       from 'redux-saga/effects';
import UI               from '../action-types/ui';
import USER             from '../action-types/user';
import USERS             from '../action-types/users';
import USER_PROFILE     from '../action-types/user_profile';
import ON_BOARDING      from '../action-types/on-boarding';
import buildAction      from '../helpers/buildAction';
import Utils            from '../modules/utils'
import api              from '../modules/api';
import config           from "../modules/config";
import { getSubErrors } from 'v1/modules/utils/errors';

const getDefaultUserPreferencesStyle = state => state.user.preferences.styles

export function* logoutUser() {
    try {
        yield cps(api.auth.signOut)
        yield put(buildAction(USER.SET_CURRENT_USER, null ));
    }
    catch (err) {
        yield put(buildAction(USER.SET_CURRENT_USER, null ));
        yield put(buildAction(UI.SHOW_ALERT, {
            type: "errors",
            errors: getSubErrors(err),
            err
        }))
    }
}

export function* setupUser(action) {
    const { payload: { id } } = action;
    const userKey = `firstLogin-${id}`;
    yield put(buildAction(UI.LOAD_START))
    yield put(buildAction(USER.AUTHENTICATING, true));
    yield put(buildAction(
        ON_BOARDING.SET_DISPLAY_SAMPLE_PRODUCT_MODAL_ON_DASHBOARD_FLAG,
        JSON.parse(localStorage.getItem(userKey))
    ));
    localStorage.setItem(userKey, "false");
    const categories = yield cps(api.category.getCategories)
    window.__categories = categories

    if (true)
    {
        try {
            yield put(buildAction(UI.LOAD_START))
            const response = yield cps(api.users.getCurrent)
            const company  = yield cps(api.companies.getCurrent);

            if (response.currentEnv === "SANDBOX")
            {
                window.__companyIs11DigitCpnType = () => { return false };
                window.__companyIs10DigitCpnType = () => { return false };
                window.__companyIs9DigitCpnType = () => { return false };
                window.__conditional01Variant = () => { return false };
                window.__with6DigitPrefixAndCounter = () => { return false };
                window.__nonIntelligentCPN = () => { return false };
                window.__companyWithHybridCpnType = () => { return false };
                window.__libraryType = "PERSONAL";
            }
            window.__currentCompanyCpnType = company.cpnType;
            window.__isIntelligentCpnScheme = company.isIntelligentCpnScheme;
            window.__nonIntelligent = company.nonIntelligent;
            window.__isEcadGeneralSpecsEnabled = company.settings.isEcadGeneralSpecsEnabled;
            window.__isSerializationEnabled = company.settings.isSerializationEnabled;
            window.__massUnit = company.settings.massUnit;
            window.__massPrecisionValue = company.settings.massPrecisionValue;

            let userData = response;
            if (!userData.styles || !userData.styles.assembly || !userData.styles.assembly['component.eid'] || !userData.styles.document
                || !userData.styles.assembly['notes']
                || !userData.styles.assembly['unitPrice']
                || !userData.styles.assembly['totalPrice']
                || !userData.styles.assembly['leadTime']
                ){

              let styles = yield select(getDefaultUserPreferencesStyle)
              if (!userData.styles || (Object.keys(userData.styles).length === 0 && userData.styles.constructor === Object))
              {

                userData.styles = styles
              }
              else
              {

                if (!userData.styles.assembly)
                {
                  userData.styles.assembly = styles.assembly
                }
                if (!userData.styles.assembly['component.eid'])
                {
                  userData.styles.assembly['component.eid'] = styles.assembly['component.eid']
                }
                if (!userData.styles.assembly['notes'])
                {
                  userData.styles.assembly['notes'] = styles.assembly['notes']
                }
                if (!userData.styles.assembly['unitPrice'])
                {
                  userData.styles.assembly['unitPrice'] = styles.assembly['unitPrice']
                }
                if (!userData.styles.assembly['totalPrice'])
                {
                  userData.styles.assembly['totalPrice'] = styles.assembly['totalPrice']
                }
                if (!userData.styles.assembly['leadTime'])
                {
                  userData.styles.assembly['leadTime'] = styles.assembly['leadTime']
                }
                if (!userData.styles.document)
                {
                  userData.styles.document = styles.document
                }
              }

              userData.preferences.styles = userData.styles

              let data = Utils.clone(userData.preferences)
              let payload = {
                data: data, user_id: userData._id
              }

              yield put(buildAction(USER.SAVE_USER_PREFERENCES, payload))
            }
            window.__userStyles = {styles: userData.styles || {}}
            window.__userRole = userData.role
            yield put(buildAction(USER.SET_CURRENT_USER, userData));
            yield put(buildAction(ON_BOARDING.SET_ON_BOARDING_STATE, userData));
            yield put(buildAction(USER_PROFILE.SET_USER_PROFILE_FORM));
        }
        catch (err) {
            yield put(buildAction(UI.SHOW_ALERT, {
                type: "errors",
                errors: getSubErrors(err),
                err
            }))
        }
    }
    yield put(buildAction(UI.LOAD_END))
    yield put(buildAction(USER.CHECKED))
    yield put(buildAction(USER.AUTHENTICATING, false));
}

export function * saveUserPreferencesInApiAndLocalState (action) {
    try {
        let data = action.payload.data
        let user = action.payload.user_id

        let response = yield cps(api.users.update, user, data)
        window.__userStyles = data
        // yield put(buildAction(USER.SET_USER_PREFERENCES, data))
    }catch (err) {
        yield put(buildAction(UI.SHOW_ALERT, {
            type: "errors",
            errors: getSubErrors(err),
            err
        }))
    }
}

export function * saveUserPreferences (action) {
    try {
        let data = action.payload.data
        let user = action.payload.user_id
        let response = yield cps(api.users.update, user, data)
        // window.__userStyles = data
        yield put(buildAction(USER.SET_USER_PREFERENCES, data))
    }catch (err) {
        yield put(buildAction(UI.SHOW_ALERT, {
            type: "errors",
            errors: getSubErrors(err),
            err
        }))
    }
}

export function * saveOnBoardingSampleProductInfo (action) {
    try {

        let user = action.payload.user
        let data = user.data
        data.onboarding.displaySampleProduct = false
        data.onboarding.dummyProductType = action.payload.dummyProductType
        let response = yield cps(api.users.update, user.data._id, data)
        yield put(buildAction(USER.SET_USER_ONBOARDING, data))
    }catch (err) {
        yield put(buildAction(UI.SHOW_ALERT, {
            type: "errors",
            errors: getSubErrors(err),
            err
        }))
    }
}

export function * switchEnv(action) {
    try
    {
        yield put(buildAction(UI.LOAD_START))
        let {personalLibraryNotification, companyLibraryNotification} = action.payload.user.data.onboarding
        let data = action.payload.user.data
        data.currentEnv = action.payload.currentEnv
        data.activeLibrary = action.payload.activeLibrary
        if (personalLibraryNotification && data.currentEnv === "SANDBOX")
        {
            data.onboarding.personalLibraryNotification = false
        }
        else if (companyLibraryNotification && data.currentEnv === "LIVE")
        {
            data.onboarding.companyLibraryNotification = false
        }

        let history = action.payload.history
        let response = yield cps(api.users.update, data._id, data)
        let payload = {personalLibraryNotification, companyLibraryNotification, currentEnv: data.currentEnv}
        yield call(deleteUserFromCache, client, data._id);
        if (action.payload.showQuickGuide) yield put(buildAction(ON_BOARDING.SHOW_HIDE_ON_BOARDING_NAV, true))
        yield put(buildAction(ON_BOARDING.SWITCH_ENV, payload))
        yield put(buildAction(USER.SET_USER_ONBOARDING, data))

        const categories = yield cps(api.category.getCategories)
        window.__categories = categories

        if (action.payload.currentEnv === "LIVE")
        {
            window.__cpn_rules = () => window.__currentCompanyCpnType === 'CPN_RULES';
            window.__companyIs11DigitCpnType = () => window.__currentCompanyCpnType === 'CUSTOM-CODE-WITH-11-DIGIT';
            window.__companyIs10DigitCpnType = () => window.__currentCompanyCpnType === 'CUSTOM-CODE-WITH-10-DIGIT';
            window.__companyIs9DigitCpnType  = () => window.__currentCompanyCpnType === 'CUSTOM-CODE-WITH-9-DIGIT';
            window.__with6DigitPrefixAndCounter  = () => window.__currentCompanyCpnType === 'WITH-6-DIGIT-PREFIX-AND-COUNTER';
            window.__nonIntelligentCPN  = () => window.__isIntelligentCpnScheme === false;
            window.__companyWithHybridCpnType = () => window.__currentCompanyCpnType === 'HYBRID-WITH-6-DIGIT-CPN';

            window.__libraryType = "GENERAL";
        }
        else
        {
            window.__companyIs11DigitCpnType = () => false;
            window.__companyIs9DigitCpnType  = () => false;
            window.__companyIs10DigitCpnType = () => false;
            window.__with6DigitPrefixAndCounter = () => false;
            window.__nonIntelligentCPN       = () => false;
            window.__companyWithHybridCpnType = () => false;
            window.__libraryType = "PERSONAL";
        }
        let url = action.payload.redirectUrl ? action.payload.redirectUrl : "/refresh/dashboard";
        if (history) history.push(url);
        yield put(buildAction(UI.LOAD_END))
        if(action.payload.cb) action.payload.cb();
    }catch (err) {
        yield put(buildAction(UI.SHOW_ALERT, {
            type: "errors",
            errors: getSubErrors(err),
            err
        }))
    }
}

function isOnBoardingCompleted(steps)
{
    let isStatusCompleted = true
    steps.forEach((step) => {
        if (!step.isCompleted)
        {
            isStatusCompleted = false
            return
        }
    })
    return isStatusCompleted
}

export function* saveOnBoardingInfoOnApi(action)
{
    try {
        let on_boarding = action.payload.on_boarding
        let data = action.payload.user.data
        data.onboarding.status = on_boarding.status
        data.onboarding.currentStep = on_boarding.currentStep
        on_boarding.steps.forEach((step) => {
            if (!(data.onboarding.completedSteps).includes(step.key) && step.isCompleted)
            {
                data.onboarding.completedSteps.push(step.key)
            }
        })
        let isCompletedAllSteps = isOnBoardingCompleted(on_boarding.steps)
        data.onboarding.status = isCompletedAllSteps ? "Completed" : "In-Progress"
        let response = yield cps(api.users.update, data._id, data)
        yield put(buildAction(USER.SET_USER_ONBOARDING, data))
    }
    catch (err) {
        yield put(buildAction(UI.SHOW_ALERT, {
            type: "errors",
            errors: getSubErrors(err),
            err
        }))
    }

}

export function* removeUserFromGroup(action)
{
    try
    {
        let response = yield cps(api.users.delete_group, action.payload.userId, {groupName: action.payload.groupName})
        yield put(buildAction(USERS.GET_USERS_LIST))
    }
    catch (err) {
        yield put(buildAction(UI.SHOW_ALERT, {
            type: "errors",
            errors: getSubErrors(err),
            err
        }))
    }

}

export function* setQuickStartGuideFlag(action)
{
    try
    {
        let data = action.payload.user.data
        data.onboarding.quickStartGuideNotification = false
        let response = yield cps(api.users.update, data._id, data)
        yield put(buildAction(USER.SET_USER_ONBOARDING, data))
    }
    catch (err)
    {
        yield put(buildAction(UI.SHOW_ALERT, {
            type: "errors",
            errors: getSubErrors(err)
        }))
    }
}


export default function * (getState) {
    yield all([
        takeEvery(USER.LOGOUT, logoutUser),
        takeEvery(USER.SETUP_USER, setupUser),
        takeLatest(USER.SAVE_USER_PREFERENCES, saveUserPreferences),
        takeLatest(USER.SAVE_ON_BOARDING_INFO_ON_API, saveOnBoardingInfoOnApi),
        takeLatest(USER.SWITCH_ENV, switchEnv),
        takeLatest(USER.REMOVE_USER_FROM_GROUP, removeUserFromGroup),
        takeLatest(USER.SAVE_USER_PREFERENCES_IN_LOCAL, saveUserPreferencesInApiAndLocalState),
        takeLatest(USER.SET_QUICK_START_GUIDE_FLAG, setQuickStartGuideFlag),
        ]);
}
