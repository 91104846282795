import React, { Component }      from "react"

const SvgIcon = () =>
{
    return <svg width="62px" height="38px" viewBox="0 0 62 38" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="LETS-GET-STARTED" transform="translate(-776.000000, -365.000000)" fillRule="nonzero">
            <g id="PEOPLE" transform="translate(776.000000, 365.000000)">
                <path d="M22.25,8.76923077 C22.25,3.8 25.825,0 30.5,0 C35.175,0 38.75,3.8 38.75,8.76923077 C38.75,13.7384615 35.175,17.5384615 30.5,17.5384615 C25.825,17.5384615 22.25,13.7384615 22.25,8.76923077 Z M30.5,23.3846154 C17.85,23.3846154 14,33.0307692 14,33.0307692 L14,38 L47,38 L47,33.0307692 C47,33.0307692 43.15,23.3846154 30.5,23.3846154 Z" id="Shape" fill="#18171D"></path>
                <g id="Group" transform="translate(41.000000, 0.000000)" fill="#000000">
                    <ellipse id="Oval" cx="8.27884615" cy="7.64550265" rx="7.06730769" ry="7.64550265"></ellipse>
                    <path d="M8.27884615,21.4074074 C4.88653846,21.4074074 2.34230769,22.3248677 0.363461538,23.8539683 C6.86538462,27.2179894 9.40961538,33.0285714 9.40961538,33.6402116 L9.40961538,33.9460317 L21,33.9460317 L21,29.9703704 C21,29.6645503 17.8903846,21.4074074 8.27884615,21.4074074 Z" id="Shape"></path>
                </g>
                <g id="Group" transform="translate(41.000000, 0.000000)" fill="#18171D">
                    <ellipse id="Oval" cx="8.27884615" cy="7.64550265" rx="7.06730769" ry="7.64550265"></ellipse>
                    <path d="M8.27884615,21.4074074 C4.88653846,21.4074074 2.34230769,22.3248677 0.363461538,23.8539683 C6.86538462,27.2179894 9.40961538,33.0285714 9.40961538,33.6402116 L9.40961538,33.9460317 L21,33.9460317 L21,29.9703704 C21,29.6645503 17.8903846,21.4074074 8.27884615,21.4074074 Z" id="Shape"></path>
                </g>
                <g id="Group" fill="#18171D">
                    <ellipse id="Oval" cx="12.7211538" cy="7.64550265" rx="7.06730769" ry="7.64550265"></ellipse>
                    <path d="M12.7211538,21.4074074 C16.1134615,21.4074074 18.6576923,22.3248677 20.6365385,23.8539683 C14.1346154,27.2179894 11.5903846,33.0285714 11.5903846,33.6402116 L11.5903846,33.9460317 L0,33.9460317 L0,29.9703704 C0,29.6645503 3.10961538,21.4074074 12.7211538,21.4074074 Z" id="Shape"></path>
                </g>
            </g>
        </g>
    </g>
</svg>

}

export default SvgIcon

