import React from "react";

const SvgComponent = props => (
  <svg
    id="delete-svg"
    width="17px"
    height="19px"
    viewBox="0 0 17 19"
    {...props}
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="TABLE-ICONS-WITH-TEXT"
        transform="translate(-91 -8)"
        fill="#FFF"
        stroke="#FFF"
        strokeWidth={0.75}
      >
        <g id="TABLE-ICONS">
          <g id="DELETE" transform="translate(92 9)">
            <g id="DELETE-ICON">
              <path
                d="M7.521 0C6.78 0 6.305.253 5.916.545c-.389.292-.702.605-1.228.811a.23.23 0 00-.15.21H0v.447h15v-.447h-4.538a.232.232 0 00-.15-.21c-.529-.207-.855-.52-1.242-.81C8.682.253 8.223 0 7.52 0h0zm0 .447c.596 0 .921.186 1.279.455.264.199.541.449.916.664H5.284c.373-.215.646-.466.91-.664.355-.268.685-.455 1.327-.455zM1.136 2.461v12.302c0 .707.187 1.276.583 1.664.396.388.973.573 1.697.573h8.182c.716 0 1.297-.185 1.69-.573.394-.388.576-.957.576-1.664V2.461H1.136zm.455.447h11.818v11.855c0 .632-.154 1.067-.44 1.35-.287.281-.732.44-1.371.44H3.416c-.65 0-1.09-.159-1.378-.44-.288-.283-.447-.718-.447-1.35V2.908zm2.5 2.908v8.052h.454V5.816h-.454zm3.182 0v8.052h.454V5.816h-.454zm3.182 0v8.052h.454V5.816h-.454z"
                id="Fill-1"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgComponent;
