import { useState, ChangeEvent, useCallback, SetStateAction, Dispatch, useMemo } from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Box, styled } from "@mui/material";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { CustomDuroCheckbox } from "common/components/checkbox";
import { DateTimePicker, Date, Switch } from "common/components/inputs";
import { InputFieldVariants } from "common/constants";
import { colorPalette } from "theme";
import { useChangeOrderFormContext } from "../../changeOrderContextProvider";

type Error = string | null;

interface IDateMapper {
  validationDate: Date;
  endDate: boolean;
  startDate: boolean;
}

interface IStyledProps {
  isEffectivityEnabled: boolean,
}

interface IDateObject {
  defaultValue: Date,
  error: boolean,
  label: string,
  minDate?: Date,
  maxDate?: Date,
  onChange: (date: Date)=> void,
  onError: (err: Error) => void,
}

const checkboxLabelStyles = { fontSize: "0.75rem", marginLeft: "0.80rem" };
const datePickerCalenderStyle = { placement: "bottom-start" };
const datePickerCustomStyle = { display: "block", alignItems: "flex-start" };
const datePickerInputWrapperProps = {
  style: {
    backgroundColor: colorPalette.gray,
  },
};
const switchLabelStyles = {
  color: colorPalette.silver,
  fontSize: "0.8rem",
  width: "6rem",
};

export const Effectivity: React.FC = () => {
  const {
    getEffectivityData,
    setEffectivityData,
  } = useChangeOrderFormContext();
  const {
    endDate,
    startDate,
    overrideEffectivity,
    isEffectivityEnabled,
  } = getEffectivityData();

  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);

  const onChangeDate = useCallback((
    label: string,
    inputDate: Date,
  ) => {
    const dateMapper: IDateMapper = {
      startDate: label === "startDate",
      endDate: label === "endDate",
      validationDate: label === "startDate" ? endDate : startDate,
    };
    if ((!inputDate && !dateMapper.validationDate) || (inputDate && dateMapper.validationDate)) {
      setStartDateError(false);
      setEndDateError(false);
    }
    else if (inputDate && !dateMapper.validationDate) {
      setStartDateError(dateMapper.endDate);
      setEndDateError(dateMapper.startDate);
    }
    else {
      dateMapper.startDate ? setStartDateError(true) : setEndDateError(true);
    }
    setEffectivityData({ [label]: inputDate });
  }, [endDate, setEffectivityData, startDate]);

  const handleStartDateChange = useCallback((date: Date) => {
    onChangeDate("startDate", date);
  }, [onChangeDate]);

  const errorHandler = useCallback((err: Error, handler: Dispatch<SetStateAction<boolean>>) => {
    err && handler(true);
  }, []);

  const handleEndDateChange = useCallback((date: Date) => {
    onChangeDate("endDate", date);
  }, [onChangeDate]);

  const handleOverrideChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEffectivityData({ overrideEffectivity: event.target.checked });
  }, [setEffectivityData]);

  const handleIsEffectivityEnabled = useCallback(() => {
    setEffectivityData({ isEffectivityEnabled: !isEffectivityEnabled });
  }, [isEffectivityEnabled, setEffectivityData]);

  const DateSection = useMemo(() => (
    [
      {
        defaultValue: startDate,
        error: startDateError,
        label: "Start Date",
        maxDate: endDate,
        onChange: handleStartDateChange,
        onError: (err: Error) => errorHandler(err, setStartDateError),

      }, {
        defaultValue: endDate,
        error: endDateError,
        label: "End Date",
        minDate: startDate,
        onChange: handleEndDateChange,
        onError: (err: Error) => errorHandler(err, setEndDateError),
      },
    ].map(({ error, ...rest }: IDateObject) => (
    <DatePickerField isEffectivityEnabled={isEffectivityEnabled}>
      <DateTimePicker
        calendarStyle={datePickerCalenderStyle}
        customStyle={datePickerCustomStyle}
        disabled={!isEffectivityEnabled}
        EnclosingTag={MuiDatePicker}
        inputFormat={"MMM D, YYYY"}
        inputWrapperProps={datePickerInputWrapperProps}
        isRequired={true}
        openPickerIcon={CalendarMonthIcon}
        renderInputProps={{
          ...(error && { errorMessage: "invalid Date" }),
          placeholder: "mm/dd/yyyy",
          setError: error,
          style: { height: "0.5rem", padding: "1rem 0 1rem 1rem" },
          variant: InputFieldVariants.FILLED,
        }}
        {...rest}
      />
    </DatePickerField>
    ))
  ), [
    endDate, endDateError, errorHandler, handleEndDateChange,
    handleStartDateChange, isEffectivityEnabled, startDate, startDateError,
  ]);
  return (
    <CustomEffectivityComponent>
      <CustomSwitch>
        <Switch
          defaultValue={isEffectivityEnabled}
          label={"Set Effectivity: "}
          labelStyle={switchLabelStyles}
          labelPosition={"start"}
          onChange={handleIsEffectivityEnabled}
        />
      </CustomSwitch>
      {
        DateSection
      }
      <CustomCheckbox>
        <CustomDuroCheckbox
          checkboxFontSize="1rem"
          checkBoxLabel={"Override"}
          checked={overrideEffectivity}
          disabled={!isEffectivityEnabled}
          labelStyles={checkboxLabelStyles}
          onChange={handleOverrideChange}
        />
      </CustomCheckbox>
    </CustomEffectivityComponent >
  );
};

const CustomCheckbox = styled(Box)({
  marginTop: "0.6rem",
});

const CustomEffectivityComponent = styled(Box)({
  alignItems: "center",
  display: "flex",
  marginBottom: "2rem",
});

const CustomSwitch = styled(Box)({
  marginTop: "1rem",
  paddingRight: "1rem",
});

const DatePickerField = styled(Box)<IStyledProps>(({ isEffectivityEnabled }) => ({
  color: isEffectivityEnabled ? colorPalette.white : colorPalette.disabled,
  paddingRight: "2rem",
  width: "30%",
}));
