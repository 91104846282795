import React from "react";

function Icon() {
  return (
      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
         viewBox="0 0 57.67 57.67" style={{enableBackground: "new 0 0 57.67 57.67"}}>
      <g>
        <path d="M54.162,3.508c-4.678-4.678-12.287-4.678-16.964,0l-9.07,9.07c-3.477,3.477-4.364,8.573-2.671,12.881
          c-1.381-0.541-2.864-0.831-4.396-0.831c-3.212,0-6.225,1.243-8.481,3.5l-9.071,9.07c-4.677,4.677-4.677,12.287,0,16.964
          c2.338,2.339,5.41,3.508,8.482,3.508s6.144-1.169,8.482-3.508l9.07-9.07c3.477-3.477,4.364-8.573,2.671-12.881
          c1.381,0.541,2.864,0.831,4.396,0.831c3.213,0,6.225-1.243,8.482-3.5l9.071-9.07C58.839,15.795,58.839,8.185,54.162,3.508z
           M28.128,43.678l-9.07,9.07c-3.897,3.896-10.238,3.896-14.136,0c-3.896-3.897-3.896-10.238,0-14.136l9.071-9.07
          c1.879-1.879,4.389-2.914,7.067-2.914c2.34,0,4.551,0.79,6.325,2.243l-8.589,8.589c-0.391,0.391-0.391,1.023,0,1.414
          c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293l8.588-8.588C32.009,34.208,31.786,40.019,28.128,43.678z
           M52.748,19.058l-9.071,9.07c-1.879,1.879-4.389,2.914-7.068,2.914c-2.34,0-4.55-0.79-6.325-2.243l8.589-8.589
          c0.391-0.391,0.391-1.023,0-1.414s-1.023-0.391-1.414,0l-8.588,8.588c-3.21-3.923-2.987-9.734,0.671-13.393l9.07-9.07
          C40.561,2.974,43.121,2,45.68,2c2.56,0,5.12,0.975,7.068,2.923C56.646,8.82,56.646,15.161,52.748,19.058z"/>
      </g>
      </svg>
  );
}

export default Icon;
