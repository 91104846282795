import { CurrencyType } from "common/constants";
import { usePrimaryCompany } from "graphql/query/companyQueries";
import { useMemo } from "react";

export function useDefaultCurrencySymbol() {
  const { defaultCurrency } = usePrimaryCompany().data?.settings ?? {};
  return useMemo(() => {
    switch (defaultCurrency) {
      case CurrencyType.AUD: return "A$";
      case CurrencyType.CAD: return "CA$";
      case CurrencyType.EURO: return "€";
      case CurrencyType.GBP: return "£";
      case CurrencyType.RMB: return "¥";
      case CurrencyType.USD: return "$";
      default:
        // If the default currency does not have a supported symbol, then don"t return one.
        return "";
    }
  }, [defaultCurrency]);
}

export function useMassPrecisionValue() {
  const { massPrecisionValue } = usePrimaryCompany().data?.settings ?? {};
  return massPrecisionValue ?? 0; // Default to zero id the company isn't done loading.
}

export function useUnitPricePrecision() {
  const { unitPricePrecision } = usePrimaryCompany().data?.settings ?? {};
  return unitPricePrecision ?? 0; // Default to zero id the company isn't done loading.
}
