import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g
          fill="#FFF"
          fillRule="nonzero"
          stroke="#41414A"
          strokeWidth="0.5"
          transform="translate(-510 -209)"
        >
          <g transform="translate(429 44)">
            <g transform="translate(50 163)">
              <g>
                <g>
                  <path className='path' d="M50.74 18.54l-7.874-7.847a5.609 5.609 0 00-1.248-6.05c-1.995-1.987-5.099-2.169-7.31-.561l3.33 3.327-1.232 1.219-3.32-3.31c-1.613 2.195-1.431 5.298.563 7.277 1.613 1.607 3.963 2.03 5.974 1.279l7.9 7.873a.866.866 0 001.222 0l1.995-1.988a.844.844 0 000-1.218zm-2.601 1.384l-8.203-8.176a3.798 3.798 0 01-1.735.708 3.886 3.886 0 01-4.465-4.053l2.68 2.67 3.676-3.664-2.68-2.67c1.075-.06 2.16.32 2.983 1.132a3.854 3.854 0 011.075 3.422 3.753 3.753 0 01-.763 1.694l8.195 8.167-.763.77z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
