import Schemas    from "../../../modules/schemas"
export function getInitialState()
{
    return {
        inputs :
        {
            isUploadedDocuments: true,
            isUploadedThumbnails: true,
            name :
            {
                value      : "",
                valid      : false,
                interacted : false,
                class      : "",
                message    : ""
            },

            revision :
            {
                value : "",
                valid : true
            },

            status :
            {
                value : "DESIGN",
                valid : true
            },

            eid :
            {
                value   : "",
                valid   : true,
                class   : "",
                message : ""
            },

            description :
            {
                value : "",
                valid : true
            },

            submit :
            {
                class : "disabled"
            },

            images :
            [

            ],

            documents :
            [

            ],

            forecasts    : getForecastInputs(),
            productTeam  : getTeamInputs(),
            categoryList : getComponentCategoryListInputs()
        }
    }
}
export function newforecastInputs(forecastKey, counter)
{
    return {
        key:
        {
            value   : forecastKey,
            class   : "",
            valid   : true,
            message : ""
        },
        targetDate :
        {
            value   : "",
            class   : "",
            valid   : true,
            message : ""
        },
        volume:
        {
            value   : "",
            class   : "",
            valid   : true,
            message : ""
        },
        counter:
        {
            value: counter
        }
    }
}
export function getForecastInputs(type="DEFAULT")
{
    let forecasts   = Schemas.product.forecasts
    let keys = forecasts.getDefaultForecastLabels(type)
    let inputs = keys.map((item, i) =>
    {
        let input =
        {
            key :
            {
                value   : item,
                class   : "",
                valid   : true,
                message : ""
            },
            targetDate :
            {
                value   : "",
                class   : "",
                valid   : true,
                message : ""
            },
            volume :
            {
                value   : "",
                class   : "",
                valid   : true,
                message : ""
            },
            counter:
            {
                value: 1
            },
            isDisabled: true
        }
        return input
    })

    return inputs
}

function getTeamInputs()
{
    let list   = Schemas.product.team.list()
    let inputs = list.map((schema) =>
    {
        let input =
        {
            value   : "",
            class   : "",
            valid   : true,
            message : "",
            schema: schema
        }

        return input
    })

    return inputs
}

function getComponentCategoryListInputs()
{
    let list   = Schemas.product.categories.list()
    let inputs = list.map((schema) =>
    {
        let input =
        {
            value   : schema.displayName,
            class   : "",
            checked : false,
            message : "",
            schema: schema
        }

        return input
    })

    return inputs
}
let initialState = getInitialState()
export default initialState
