import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="#fffff"
        d="M15.346 3.346h-.01a8.654 8.654 0 00-4.15 16.25l.045.023H-.001v1.029h15.346a8.654 8.654 0 10-.012-17.301h.012zm5.39 14.037a7.618 7.618 0 112.235-5.391v.016a7.571 7.571 0 01-2.235 5.382z"
      ></path>
    </svg>
  );
}

export default Icon;
