import React, { Component }         from "react"
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PerfectScrollbar from "react-perfect-scrollbar";
import Icon from "../../icon"
import InlineIcon                   from "../../icon/inline-icon.js"
import CircleSrc                    from "../../../../assets/icons/on-boarding-circle"
import CircleWithTickSrc from "../../../../assets/icons/circle-with-tick"
import quickStartSrc                from "../../../../assets/icons/quick-start.svg"
import OnBoardingStepToolTip from "../../../ui/on-boarding-step-tool-tip"
import { Popover, ArrowContainer } from 'react-tiny-popover'
import ToolTipContent               from "../../../ui/on-boarding-step-tool-tip/step-tool-tip-content"
import VideoModalContent from "../../../ui/on-boarding-step-tool-tip/video-modal-content"
import RibbonCloseSrc               from "../../../../assets/icons/ribbon-close-arrow"
import RibbonOpenSrc                from "../../../../assets/icons/ribbon-open"

class OnBoardingList extends Component
{
    constructor(props, context)
    {
        super(props, context)
        this.nextVideo              = this.nextVideo.bind(this)
        this.onMouseDown            = this.onMouseDown.bind(this)
        this.onMouseMove            = this.onMouseMove.bind(this)
        this.onMouseUp              = this.onMouseUp.bind(this)
        this.onMouseLeave           = this.onMouseLeave.bind(this)
        this.setRibbonWidth = this.setRibbonWidth.bind(this)
        this.onInputChange = this.onInputChange.bind(this);
        this.onDialogClose = this.onDialogClose.bind(this);

        this.state =
        {

            ribbon :
            {
                width           : 129,
                minWidth        : 129,
                class           : "open",
                visibleCloseBtn : false
            }
        }
    }

    setRibbonWidth(e)
    {
        let element = document.getElementsByClassName("on-boarding-holder")[0];
        if(!!element)
        {
            let width = element.style.width.replace('px','');
            width = parseInt(width)
            if (width <= 129 && width !== 15)
            {
                element.classList.remove("open")
                element.classList.add("close")
                this.setState({ribbon:{width: 15, minWidth: 15, class: "close"}})
            }
            else if(width === 15)
            {
                element.classList.remove("close")
                element.classList.add("open")
                this.setState({ribbon:{width: 129, minWidth: 129, class: "open"}})
            }
        }
    }

    onMouseDown(e)
    {
        let element = document.getElementsByClassName("ribbon-resize")[0];
        if (element) element.classList.add("dragged");
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', this.onMouseLeave);
        e.stopPropagation()
        e.preventDefault()
    }

    onMouseUp(e)
    {
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseLeave);
        e.preventDefault();
        e.stopPropagation()
        e.preventDefault()
    }

    onMouseMove(e)
    {
        let position = document.body.offsetWidth - e.pageX
        let state = this.state

        if (position < 129)
        {
            position = position > 15 ? position : 15
            if (state.ribbon.width !== e.pageX)
            {
                state.ribbon.width = position
                state.ribbon.minWidth = position
                this.setState(state)
            }
        }
        else
        {
            state.ribbon.width = 129
            state.ribbon.minWidth = 129
            this.setState(state)
        }
        e.stopPropagation()
        e.preventDefault()
    }

    onMouseLeave(e)
    {
        let element = document.getElementsByClassName("ribbon-resize")[0];
        let onBoardingHolder = document.getElementsByClassName("on-boarding-holder")[0];
        if (element) element.classList.remove("dragged");

        let {ribbon} = this.state
        if (ribbon.width < 129 && ribbon.class === "open")
        {
            if (!!onBoardingHolder)
            {
                onBoardingHolder.classList.remove("open")
                onBoardingHolder.classList.add("close")
            }
            this.setState({ribbon:{width: 15, minWidth: 15, class: "close"}})
        }
        else if (ribbon.class === "close" && ribbon.width > 15)
        {
            if (!!onBoardingHolder)
            {
                onBoardingHolder.classList.remove("close")
                onBoardingHolder.classList.add("open")
            }
            this.setState({ribbon:{width: 129, minWidth: 129, class: "open"}})
        }


        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseLeave);
        e.preventDefault()
        e.stopPropagation()
        e.preventDefault()
    }

    nextVideo(event)
    {
        event.stopPropagation();
        this.props.next({showVideoModal: true})
    }

    onInputChange(event, args) {
        event.stopPropagation();
        this.props.onInputChange(args);
    }

    onDialogClose(event, i, reason) {
        event.stopPropagation();
        if (reason !== "backdropClick") {
            this.props.onCloseHandler(i);
        }
    }

    render()
    {
        let steps = this.props.steps
        let ribbon  = this.state.ribbon
        let markup =
        <div
            className={this.props.currentEnv.toLowerCase() + " on-boarding-holder"}
            id="on-boarding-holder"
            style={{width: ribbon.width+"px", minWidth: ribbon.minWidth+"px"}}
            >
            {
                <div className="ribbon-resize"
                    onMouseDown={(e) => this.onMouseDown(e)}
                    onDoubleClick={this.setRibbonWidth}
                    >
                    <div className="ribbon-icon-holder" onClick={this.setRibbonWidth}>
                        {
                            ribbon.width > 15 ?
                            <InlineIcon onClick={this.setRibbonWidth} className="close-icon"><RibbonCloseSrc/></InlineIcon>
                            : <InlineIcon onClick={this.setRibbonWidth}><RibbonOpenSrc/></InlineIcon>
                        }
                    </div>
                </div>
            }
            <PerfectScrollbar className="area on-boarding-holder-list">
                <ul className="on-boarding-holder-list">
                    {
                        this.props.currentEnv === "SANDBOX" ?
                        steps.map((step, i) =>
                        {
                            let block =
                                <li className={step.class + (step.class === "active" ? "" : " li-hover") + " onboarding-setp-item nav_step_" + i} key={i} onClick={e => this.onInputChange(e, { setCurrentStep: i })}>
                                    {this.props.completedSteps.includes(step.key) ? <InlineIcon><CircleWithTickSrc/></InlineIcon> : <InlineIcon><CircleSrc/></InlineIcon>}
                                    {
                                        step.showToolTip ?
                                        <Popover
                                            padding={25}
                                            containerClassName={"popover-tool-tip-container popover-" + i}
                                            isOpen={true}
                                            position={'left'}
                                            ref="onBoardingPopover"
                                            content={({ position, childRect, popoverRect }) => (
                                                <ArrowContainer
                                                    position={position}
                                                    childRect={childRect}
                                                    popoverRect={popoverRect}
                                                    arrowColor={'white'}
                                                    arrowSize={10}
                                                    arrowStyle={{ opacity: 1, top: (childRect.top - popoverRect.top - 35) }}
                                                >
                                                <div>
                                                    <div className={step.class === "active" ? "tool-tip-modal" : "tool-tip-modal"} key={i}>
                                                        <OnBoardingStepToolTip
                                                            header={"Step " + (i+1) + " of " + steps.length}
                                                            gotoInviteUser={this.props.gotoInviteUser}
                                                            next={this.props.next}
                                                            nextButtonText={step.key === "export" ? "GOT IT" : "NEXT"}
                                                            className={"step_modal_" + i}
                                                            >
                                                            <ToolTipContent
                                                                stepNumber={i}
                                                                onClick={this.props.onOpenCloseVideoModal}
                                                            />
                                                        </OnBoardingStepToolTip>

                                                            <Dialog
                                                                open={step.showVideoModal}
                                                                onClose={(e, r) => this.onDialogClose(e, i, r)}
                                                                PaperProps={{ sx: { maxWidth: 864 } }}
                                                            >
                                                                <DialogTitle sx={{ p: "4 !important" }}>
                                                                    <IconButton
                                                                        onClick={e => this.onDialogClose(e, i)}
                                                                        sx={{
                                                                            border: "none !important",
                                                                            minWidth: "0 !important",
                                                                            p: "0 !important",
                                                                            position: "absolute",
                                                                            right: 2,
                                                                            top: 2,
                                                                        }}
                                                                    >
                                                                        <CloseIcon />
                                                                    </IconButton>
                                                                </DialogTitle>
                                                                <DialogContent sx={{ padding: 0 }}>
                                                                    <VideoModalContent
                                                                        stepNumber={i}
                                                                        onInviteClick={this.props.gotoInviteUser}
                                                                        onNextClick={this.nextVideo}
                                                                    />
                                                                </DialogContent>
                                                            </Dialog>
                                                    </div>
                                                </div>
                                                </ArrowContainer>
                                            )}
                                        >
                                        <div></div>
                                        </Popover> : null
                                    }
                                    <span className={"list-info " + (this.props.completedSteps.includes(step.key) ? "completed" : "")}>{step.displayName}</span>
                                </li>
                            return block
                        })
                        :
                        ""
                    }
                    <li className="onboarding-setp-item hover-links" onClick={this.props.redirectToQuickStart}>
                        <Icon src={quickStartSrc}/>
                        <span className="list-info">Quick Start Guide</span>
                    </li>
                </ul>
            </PerfectScrollbar>
        </div>
        return markup
    }
}

export default OnBoardingList
