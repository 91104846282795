import React from "react";
import InlineIcon from "../../../ui/icon/inline-icon.js";
import CopyIcon from "../../../../assets/icons/prd-cmp-icon.js";
import { CopyToClipboard as ReactCopy}  from 'react-copy-to-clipboard';

const CopyToClipboard = (props) =>
{
    let { textToBeCopied, tooltipText, tooltipPosition, onCopy, name, className } = props;

    return( <div>
                <ReactCopy text={textToBeCopied} role="button">
                    <div>
                        <InlineIcon
                            tooltip={tooltipText}
                            tooltipPlace={tooltipPosition}
                            name={name}
                            className={className}
                            onClick={(e) => onCopy(e)}>
                            <CopyIcon/>
                        </InlineIcon>
                    </div>
                </ReactCopy>
            </div>
    )
}

export default CopyToClipboard;
