import RELEASE         from "../../action-types/release"
import initialState, { getInitialState } from "./initial_state"
import { handleActions } from "redux-actions"

function setRelease(state=initialState, { payload })
{
    state.release  = payload;
    return {...state};
}

function setLoading(state=initialState, { payload })
{
    state.loading  = payload;
    return {...state};
}

function resetState(state=initialState)
{
    state.release = null;
    state.loading = false;
    return {...state};
}

export default handleActions({
  [RELEASE.SET_LOADING]: setLoading,
  [RELEASE.SET_RELEASE_OBJECT]: setRelease,
  [RELEASE.RESET_RELEASE]: resetState,
}, initialState);
