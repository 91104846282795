import                          "./index.css"
import React, {Component}       from "react";
import ModalBox                 from "../../../../ui/modal-box";


class ItemDeleteModal extends Component
{
    constructor(props){
        super(props)

        this.state = {
          isModalOpen: false
        }
    }

    render()
    {
        let {
            onCloseDeleteModal,
            headingTxt,
            bodyTxt
        } = this.props;
        let markup = <div className="new-warning-modal delete-warning">
            <ModalBox
                onClose={onCloseDeleteModal}
            >
                <h1>{headingTxt}</h1>
                <div className="warning-text">
                    <p>{bodyTxt}</p>
                </div>
                <div className="bottom-section">
                    <div className="button-sections">
                    <button
                        type="button"
                        onClick={onCloseDeleteModal}
                        className="dark"
                        >
                        Cancel
                    </button>

                    <button
                        type="button"
                        onClick={this.props.onDeleteBtnClick}
                        className="danger">
                        Delete
                    </button>

                    </div>
                </div>
            </ModalBox>
        </div>

        return markup;
    }

}

export default ItemDeleteModal;