import Utils   from "../../utils"

class ProductionInstanceAPI
{
    static getProductionInstances(id, data, cb)
    {
        Utils.post(`/production/instances/parent/${id}`, data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static findById(id, cb)
    {
        Utils.get(`/production/instances/${id}`, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static update(id, data, cb)
    {
        Utils.post(`/production/instances/${id}`, data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static serialExists(data, cb)
    {
        Utils.post("/production/instances/serial/exists", data, (err, res) =>
        {
            if(!res.success)
            {
                return cb(res)
            }

            return cb(null, res.data)
        })
    }

    static updateBulk(data, cb)
    {
        Utils.post(`/production/instances/bulk-update`, data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }
            cb(null, res.data)
        })
    }

    static downloadTree(data, cb)
    {
        Utils.post(`/production/instances/download/assembly`, data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }
            cb(null, res.data)
        })
    }
}

export default ProductionInstanceAPI
