import { gql } from "@apollo/client";

export const BaseBuildRevisionFragment = gql`
  fragment baseBuildRevisionFragment on BuildRevision {
    assembly {
      quantity
    }
    category
    cpn
    id
    lastModifiedDate
    name
    revisionValue
    source {
      extId
      type
      url
    }
    status
  }
`;

export const BuildRevisionWithChildrenFragment = gql`
  fragment buildRevisionWithChildrenFragment on BuildRevision {
    ...baseBuildRevisionFragment
    assembly {
      child {
        ...baseBuildRevisionFragment
      }
      quantity
    }
  }
  ${BaseBuildRevisionFragment}
`;
