const helpers =
{
    isSuperAdminUser(userFromList, currentLoggedInUser)
    {
        return userFromList._id === currentLoggedInUser._id
    },
    isAdministrator(user)
    {
        return user.role === "ADMINISTRATOR"
    },
}

export default helpers
