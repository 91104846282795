import React, { Component }      from "react"

const SvgIcon = () =>
{
    return <svg width="13px" height="13px" id="INFO-ICON" viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
        <g id="1-from-parent" transform="translate(-684.000000, -344.000000)" fill="#888888">
            <path d="M689.439657,354.562487 C688.988191,354.562487 688.657258,354.28864 688.973213,353.08346 L689.491233,350.946239 C689.581244,350.603719 689.596363,350.466937 689.491233,350.466937 C689.355723,350.466937 688.769594,350.703338 688.423541,350.936348 L688.197737,350.566556 C689.296093,349.649211 690.559208,349.111268 691.100685,349.111268 C691.552292,349.111268 691.627324,349.645255 691.401803,350.466937 L690.808468,352.713668 C690.703338,353.110873 690.748555,353.247655 690.853403,353.247655 C690.989196,353.247655 691.433031,353.082894 691.869095,352.740374 L692.124855,353.082894 C691.056598,354.15101 689.890841,354.562487 689.439657,354.562487 M691.133608,346.166637 C691.794346,346.166637 691.989345,346.550136 691.989345,346.988319 C691.989345,347.536154 691.550879,348.042587 690.802957,348.042587 C690.176981,348.042587 689.879254,347.727621 689.896634,347.207481 C689.896634,346.769157 690.263883,346.166637 691.133608,346.166637 M690.412959,344.000591 C686.823557,344.048634 683.952827,346.997786 684.000587,350.587469 C684.048631,354.175597 686.997502,357.047173 690.586904,356.999413 C694.17574,356.951087 697.047036,354.001935 696.999416,350.412535 C696.951373,346.824124 694.002219,343.952689 690.412959,344.000591" id="Fill-1"></path>
        </g>
    </g>
</svg>
}

export default SvgIcon
