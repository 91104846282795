import { Component } from "react";
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import DoubleArrowIcon from "../../../../assets/icons/double-arrow-icon.js";
import LinkIcon from "../../../../assets/icons/open-link-icon.js";
import RevsIcon from "../../../../assets/icons/revs.js";
import Schemas from "../../../../modules/schemas";
import Utils from "../../../../modules/utils";
import InlineIcon from "../../../ui/icon/inline-icon.js";
import Link from "../../../ui/link";
import RevisionField from "../../common/revision-field";
import ItemDetails from "../../common/specifications/item-details";
import ItemOverView from "../../common/specifications/item-overview";
import ItemSchedule from "../../common/specifications/item-schedule";
import ItemSpecs from "../../common/specifications/item-specs";
import ItemTeam from "../../common/specifications/item-team";
import Tabs from "../../common/tabs/view";
import TimeStampColumn from "../../common/timestamp-column";
import RevisionHistoryTable from "../../common/view-action-items/revision-history-table";
import "./index.css";
import DiffToolRightSideBar from "./modules/diff-tool-right-side-bar";

export class DiffTool extends Component
{
    constructor(props)
    {
        super(props)
        let tab = this.props.alias === "prd" ? "product-tab" : "component-tab"
        let isAssembly = false
        if (this.props.alias === "cmp")
        {
            isAssembly = Schemas.component.category.getType(this.props.component.category).toLowerCase() === "assembly"
        }

        if(isAssembly || this.props.alias === "prd")
        {
            Utils.setStore(tab, 'assembly')
            Utils.setStore("assemblyView", 'tree')
        }

        this.state = {
            revModal: false,
            assemblyDiffCount:
            {
                childAddedCount: 0,
                childUpdatedCount: 0,
                childRemovedCount: 0,
            },
            syncWithParent: false,
            assemblyTree: null,
            isAssemblyLoaded: false,
            showAssemblyLoading: false,
            revList: [],
        }
    }

    setAssemblyDiffCount = assemblyDiffCount => this.setState({
        assemblyDiffCount: assemblyDiffCount,
        syncWithParent: true
    });

    afterSyncWithParent = () => this.setState({ syncWithParent: false });

    setAssemblyTree = tree => this.setState({
        assemblyTree: tree,
        isAssemblyLoaded: true
    });

    setAssemblyLoading = flag => this.setState({ showAssemblyLoading: flag });

    toggleRevModal = () => this.setState(state => ({ revModal: !state.revModal }));

    closeRevModal = () => this.setState({ revModal: false });

    componentDidUpdate(prevProps) {
        this.syncWidthOfRightSideBar();
    }

    componentDidMount() {
        let scrollbarContainer = document.getElementsByClassName("scrollbar-container")[0];
        window.addEventListener("resize", this.syncWidthOfRightSideBar);
        this.syncWidthOfRightSideBar();
    }

    componentWillUnmount() {
        let scrollbarContainer = document.getElementsByClassName("scrollbar-container")[0];
        window.removeEventListener("resize", this.syncWidthOfRightSideBar);
    }

    setRevList = revs => this.setState({ revList: revs });

    syncWidthOfRightSideBar = () => {
        let diffToolContainerHeight;
        let scrollbarContainerHeight;

        let rightSideBarEl = document.getElementsByClassName("right-search-menu")[0];
        if (rightSideBarEl)
        {
            rightSideBarEl.style.height = '0px';
        }

        let scrollbarContainer = document.getElementsByClassName("scrollbar-container")[0];
        if (scrollbarContainer)
            scrollbarContainerHeight = scrollbarContainer.clientHeight;

        if (document.getElementsByClassName("diff-tool-container")[0])
            diffToolContainerHeight = document.getElementsByClassName("diff-tool-container")[0].clientHeight;

        let scrollElHeight = scrollbarContainerHeight;
        let formHeight = diffToolContainerHeight;

        let selectedHeight;
        if (scrollElHeight > formHeight)
        {
            rightSideBarEl.style.height = (scrollElHeight) + "px";
            selectedHeight = (scrollElHeight) + "px";
        }
        else
        {
            selectedHeight = (diffToolContainerHeight) + "px";
        }

        if (rightSideBarEl){
            rightSideBarEl.style.height = selectedHeight;
        }


        let rightSearchBars = document.querySelectorAll(".right-sidebar-menu");
        if (rightSearchBars)
        {
            rightSearchBars.forEach((rightSearchBar) =>
            {
                rightSearchBar.style.minHeight = selectedHeight;
            })

            let mainDiffWrapper = document.querySelector(".main-diff-wrapper");
            if (mainDiffWrapper)
            {
                mainDiffWrapper.style.minHeight = selectedHeight;
            }
        }
    }

    render()
    {
        let {revision, targetRev, company}  = this.props;
        let massPrecision = Utils.getCompanyMassPrecision(company);
        let revModal    = this.state.revModal;
        let sourceRevLink = `/${this.props.alias === "cmp" ? "component" : "product"}/revision/${revision._id}`;
        let targetRevLink = `/${this.props.alias === "cmp" ? "component" : "product"}/revision/${targetRev._id}`;

        let markup  =
            <div className="diff-tool-container">
                <div className="main-diff-wrapper">
                    <div className="diff-holder">
                        <div className="diff-header">
                        <header>
                            <div className="revision-to-be-compare">
                                <div className="revision-holder">
                                    <div className="compare-revision">
                                        <div className="revision-col">
                                            <div className="revision">
                                                <Link
                                                    to={sourceRevLink}
                                                        target="_blank"
                                                    >
                                                    <InlineIcon className="rev-link-icon"><LinkIcon/></InlineIcon>
                                                </Link>
                                                <span className="revision-label">REVISION</span>
                                                <RevisionField item={revision} revision={revision.revision} showIcon={!revision.co}/>
                                            </div>
                                            <div className="modified-date">
                                                <span className="modified-label">MODIFIED</span>
                                                <TimeStampColumn key={"source-rev-time"} format='date-time-with-long-format' value={revision.created} />
                                            </div>
                                        </div>
                                        <div className="double-headed-arrow" >
                                            <InlineIcon className="arrow">
                                                <DoubleArrowIcon/>
                                            </InlineIcon>
                                        </div>
                                        <div className="revision-col modified-col">
                                            <div className="modified-update">
                                                <div className="revision">
                                                    <Link
                                                        to={targetRevLink}
                                                            target="_blank"
                                                        >
                                                        <InlineIcon className="rev-link-icon"><LinkIcon/></InlineIcon>
                                                    </Link>
                                                    <span className="revision-label">REVISION</span>
                                                    <RevisionField item={targetRev} revision={targetRev.revision} showIcon={!targetRev.co}/>
                                                </div>
                                                <div className="modified-date">
                                                    <span className="modified-label">MODIFIED</span>
                                                    <TimeStampColumn key={"target-rev-time"} format='date-time-with-long-format' value={targetRev.created} />
                                                </div>
                                            </div>
                                                <div className="icon-holder">
                                                <InlineIcon
                                                        className={revModal ? "active" : ""}
                                                        onClick={this.toggleRevModal}
                                                tooltip={"Select another revision"}>
                                                <RevsIcon/>
                                                </InlineIcon>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button className="btn-black-theme " id="btn-cancle" onClick={this.props.closeCompareRevisions}>Close</button>
                            </div>
                                {revModal &&
                                    <RevisionHistoryTable
                                        componentId={this.props.alias === "prd" ? this.props.product._id : this.props.component._id}
                                        userRole={window.__userRole}
                                        alias={this.props.alias}
                                        revertBack={this.props._id}
                                        revList={(this.state.revList) ? this.state.revList : []}
                                        setRevList={this.setRevList}
                                        revModal={this.state.revModal}
                                        closeRevModal={this.closeRevModal}
                                    />
                                }
                        </header>
                        <div className="top-name-heading diff-name-section">{revision.name}</div>
                        </div>
                        <div className="tiles-wrapper">
                            <PerfectScrollbar className="tiles-section-scroll">
                                <ItemOverView item={revision} alias={this.props.alias}/>
                                <ItemDetails item={revision} defaultCurrency={this.props.defaultCurrency} massPrecision={massPrecision}/>
                                {this.props.alias === "prd" && <ItemTeam item={revision}/>}
                                {this.props.alias === "prd" && <ItemSchedule item={revision}/>}
                                {this.props.alias === "cmp" && <ItemSpecs item={revision} massPrecision={massPrecision}/>}
                            </PerfectScrollbar>
                        </div>
                        {
                              this.props.alias === "prd" &&
                              <Tabs
                                tabsType="prd"
                                product={this.props.product}
                                documents={revision.documents}
                                manufacturers={revision.manufacturers}
                                assemblyData={revision.children}
                                history={this.props.history}
                                mode="revision"
                                location={this.props.location}
                                documents_loaded={true}
                                children_loaded={true}
                                user={this.props.user}
                                isDiffTool={true}
                                targetRevDocuments={this.props.targetRev.documents}
                                targetRevManufacturers={this.props.targetRev.manufacturers}
                                targetRevChildren={this.props.targetRev.children}
                                setAssemblyDiffCount={this.setAssemblyDiffCount}
                                defaultCurrency={this.props.defaultCurrency}
                                setAssemblyTree={this.setAssemblyTree}
                                assemblyTree={this.state.assemblyTree}
                                setAssemblyLoading={this.setAssemblyLoading}
                                isAssemblyLoaded={this.state.isAssemblyLoaded}
                                paramId = {this.props.product._id}
                              />
                        }
                        {
                            this.props.alias === "cmp" &&
                            <Tabs
                                tabsType="cmp"
                                component={this.props.component}
                                dispatch={this.props.dispatch}
                                documents={revision.documents}
                                manufacturers={revision.manufacturers}
                                assemblyData={revision.children}
                                componentCategory={revision.category}
                                history={this.props.history}
                                mode="revision"
                                documents_loaded={true}
                                children_loaded={true}
                                isDiffTool={true}
                                targetRevDocuments={this.props.targetRev.documents}
                                targetRevManufacturers={this.props.targetRev.manufacturers}
                                targetRevChildren={this.props.targetRev.children}
                                setAssemblyDiffCount={this.setAssemblyDiffCount}
                                defaultCurrency={this.props.defaultCurrency}
                                setAssemblyTree={this.setAssemblyTree}
                                assemblyTree={this.state.assemblyTree}
                                setAssemblyLoading={this.setAssemblyLoading}
                                isAssemblyLoaded={this.state.isAssemblyLoaded}
                                paramId = {this.props.component._id}
                            />
                        }
                    </div>
                    <DiffToolRightSideBar
                        massPrecision={massPrecision}
                        sourceRev={this.props.revision}
                        targetRev={this.props.targetRev}
                        alias={this.props.alias}
                        assemblyDiffCount={this.state.assemblyDiffCount}
                        syncWithParent={this.state.syncWithParent}
                        afterSyncWithParent={this.afterSyncWithParent}
                        showAssemblyLoading={this.state.showAssemblyLoading}
                    />
                </div>
            </div>
        return markup
    }
}

export default DiffTool
