import { gql } from "@apollo/client";
import { BaseComponentFragment, ComponentLastReleasedRevisionFragment } from "./componentFragment";

// TODO: Endless scrolling is pending
export const ComponentsFragment = gql`
  fragment componentsFragment on Components {
    connection(after: $endCursor, first: $pageSize) {
      edges {
        node {
          ...baseComponentFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
  ${BaseComponentFragment}
`;

export const ComponentsLastReleasedRevisionFragment = gql`
  fragment componentsLastReleasedRevisionFragment on Components {
    connection(after: $endCursor, first: $pageSize) {
      edges {
        node {
          ...componentLastReleasedRevisionFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
  ${ComponentLastReleasedRevisionFragment}
`;
