import UI                from '../action-types/ui';
import Utils             from '../modules/utils';
import { handleActions } from 'redux-actions';

const initialState =
{
    loading : false,
    processing : false,
    alert: alertInitialState(),
    notifications: [],
    nolayout: false,
    title: '',
    message:'',
    isValidating: false,
}

function removeNotification (state, action) {
  const notifications = state.notifications.concat();
  notifications.splice(action.index, 1);
  return {...state, notifications: notifications};
}

function blankMessage (state, action) {
 const {title, message} = action.payload;
 return {...state, title, message};
}

function addNotification (state, action) {
  let notification = action.payload;
  if (typeof notification === 'string') {
    notification = {message: notification, level: 'success'};
  }
  if (notification) {
    return {...state, notifications: state.notifications.concat(notification)};
  }
  return state;
}

function alertInitialState()
{
    return {
        display: false,
        type: null,
        text: null,
        errors: [],
        closeCb: null,
        confirmCb: null,
        rejectCb: null,
        errorType: null
    }
}

function showAlert(state, { payload }) {
    let alert = alertInitialState()
    alert.type = payload.type
    if (alert.type === "errors" && !payload.errors)
    {
        Utils.recordException(payload.err)
        return {...state, alert : alert}
    }

    switch(payload.type)
    {
        case "errors" :
        {
            alert.errors         = payload.errors
            alert.closeCb        = payload.closeCb
            alert.modalClass     = payload.modalClass
            alert.confirmBtnText = payload.confirmBtnText
            alert.errorHeading   = payload.errorHeading
            alert.errorType      = payload.errorType ? payload.errorType : null

            if (payload.donotThrowError != true)
            {
                Utils.recordException(payload.err)
            }

            break
        }
        case "confirm" :
        {
            alert.confirmButtonText = payload.confirmButtonText;
            alert.heading = payload.heading;
            alert.errorType = payload.errorType ? payload.errorType : null;
            alert.text = payload.text
            alert.confirmCb = payload.confirmCb
            alert.rejectCb = payload.rejectCb
            break
        }
        case "modal" :
        {
            alert.text = payload.text;
            alert.closeCb = payload.closeCb;
            break
        }
        case "Unknown error" :
        {
            break
        }
        default :
        {
            // noop
        }
    }
    alert.display = true

    return {...state, alert : alert}
}

function closeAlert(state, { payload })
{
    let alert = state.alert
    if (payload.confirmCb && alert.confirmCb)
    {
        alert.confirmCb()
    }
    else if (payload.rejectCb && alert.rejectCb)
    {
        alert.rejectCb()
    }
    else if (alert.closeCb)
    {
        alert.closeCb()
    }
    else if (alert.errors.length)
    {
        Utils.redirectToParentRoute()
    }
    alert = alertInitialState()
    return {...state, alert: alert}
}

export default handleActions({
  [UI.LOAD_START]: state => ({...state, loading: true}),
  [UI.LOAD_END]: state => ({...state, loading: false}),
  [UI.START_PROCESSING]: state => ({...state, processing: true}),
  [UI.END_PROCESSING]: state => ({...state, processing: false}),
  [UI.ADD_NOTIFICATION]: addNotification,
  [UI.REMOVE_NOTIFICATION]: removeNotification,
  [UI.BLANK_MESSAGE]: blankMessage,
  [UI.CLEAR_NOTIFICATIONS]: state => ({...state, notifications: []}),
  [UI.SET_NOLAYOUT]: (state, action) => ({...state, nolayout: action.payload}),
  [UI.SHOW_ALERT]: showAlert,
  [UI.CLOSE_ALERT]: closeAlert,
  [UI.VALIDATION_START]: state => ({...state, isValidating: true}),
  [UI.VALIDATION_END]: state => ({...state, isValidating: false}),
}, initialState);
