import { gql, useMutation } from "@apollo/client";
import { Production, UpdateProductionInput } from "build/models/production";
import { BaseProductionFragment } from "graphql/fragment/productionFragment";
import { useCallback } from "react";

const UPDATE_PRODUCTIONS_MUTATION = gql`
mutation updateProductions($input: [BuildUpdateProductionInput!]!) {
  build {
    updateProductions(input: $input) {
      ...baseProductionFragment
    }
  }
}
${BaseProductionFragment}
`;

export interface UpdateProductionResp {
  build?: {
    updateProductions?: Production[]
  }
}

export function useUpdateProductions() {
  const [updateProductions] = useMutation<UpdateProductionResp>(UPDATE_PRODUCTIONS_MUTATION);

  return useCallback(async (input: UpdateProductionInput[]) => {
    const res = await updateProductions({ variables: { input } });
    return res.data?.build?.updateProductions;
  }, [updateProductions]);
}
