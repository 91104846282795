/**
 * TODO (PLM-6280): The component/file currently is just wrapping the old functionality used here.
 * It needs to be updated to using MUI components and make it so that the data isn't modified in way
 * that are hard to follow and can have unintended side effects.
 */

import { PageItemType } from "design/constants";
import Tooltip from "rc-tooltip";
import { MouseEvent, ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import NumberFormat from "react-number-format";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { getSpecsValidated } from "design/utils/component";
import ComponentActions from "v1/action-types/component";
import AlertErrorIcon from "v1/assets/icons/alert-error";
import ItemDetailsIcon from "v1/assets/icons/item-details-icon";
import ItemSpecsIcon from "v1/assets/icons/item-specs-icon";
import WebSrcIcon from "v1/assets/icons/newcomp-web";
import LinkIcon from "v1/assets/icons/open-link-icon";
import WarningTriangleIcon from "v1/assets/icons/warning-triangle";
import SpecInputField from "v1/components/page/common/components/spec-input";
import CpnEditField from "v1/components/page/common/cpn-edit-field";
import ExtendedRolledUpCost from "v1/components/page/common/extended-rolled-up-cost";
import LastUpdatedField from "v1/components/page/common/last-modified-field";
import ProcurementEdit from "v1/components/page/common/procurement/edit";
import RevisionField from "v1/components/page/common/revision-field";
import RevisionManaged from "v1/components/page/common/revision-managed";
import { AltiumSpecs } from "v1/components/page/common/specifications/item-altium-specs";
import ItemCustomProperties from "v1/components/page/common/specifications/item-custom-properties";
import ItemDetails from "v1/components/page/common/specifications/item-details";
import ItemERP from "v1/components/page/common/specifications/item-erp-view";
import ItemOverView from "v1/components/page/common/specifications/item-overview";
import ItemSpecs from "v1/components/page/common/specifications/item-specs";
import TileItem from "v1/components/page/common/tile-item";
import TimeStampColumn from "v1/components/page/common/timestamp-column";
import UnitPrice from "v1/components/page/common/unit-price";
import CategorySelect from "v1/components/page/component/new/modules/category-select";
import InlineIcon from "v1/components/ui/icon/inline-icon";
import ImageViewerTile from "v1/components/ui/image-viewer-tile";
import LazyInput from "v1/components/ui/lazy-input/input";
import TextArea from "v1/components/ui/lazy-input/textarea";
import Link from "v1/components/ui/link";
import WarningModal from "v1/components/ui/warning-modal";
import buildAction from "v1/helpers/buildAction";
import config from "v1/modules/config";
import Schemas from "v1/modules/schemas";
import Utils from "v1/modules/utils";
import validations, { validateField } from "v1/modules/validations";
import {
  useComponentCategoryContext, useComponentModalContext, useComponentRevisionContext, useComponentStatusContext,
} from "./componentModal";
import {
  useCategoryCpn, useCompany, useEditInputs, useEditObject, useLibraryType, useUser,
  useVariantConfig,
  useViewCpn, useViewObject,
} from "./hooks";
import { ItemCustomSpecs } from "v1/components/page/common/specifications/item-custom-specs";

function useCpnDuplicateTooltip(cpnDuplicateOf: any) {
  const { cpnType } = useCompany().data;

  return useMemo(() => {
    if (cpnType === "FREE-FORM") {
      return Utils.makeDuplicateInputTooltip(cpnDuplicateOf, "CPN");
    }
    return null;
  }, [cpnDuplicateOf, cpnType]);
}

function useIsNewObject() {
  return useSelector((store: any) => store.components.editPage.isNewObject);
}

const VENDORS_FOR_EDITABLE_FIELDS = ["ONSHAPE", "SOLIDWORKS PDM", "SOLIDWORKS CAD"];

export interface ComponentTilesProps {
  isAssembly: boolean;
  isEditing: boolean;
  pageItemType: PageItemType;
}

export function ComponentTiles({ isAssembly, isEditing, pageItemType }: ComponentTilesProps) {
  const dispatch = useDispatch();

  const user = useUser();
  const company = useCompany();
  const viewObject = useViewObject(pageItemType);
  const editObject = useEditObject(pageItemType);
  const editInputs = useEditInputs(pageItemType);
  const isNewObject = useIsNewObject();
  const { revisionInput } = useComponentRevisionContext();
  const {
    setShouldBlockNavigation,
    setShowCategoryChangeAlert,
  } = useComponentModalContext();
  const { setNewStatusValue, setStatusFieldPreviousValue, setTrackStatusChanged } = useComponentStatusContext();
  const libraryType = useLibraryType();
  const { categoryChanged, setChangedFieldName, setChangedFieldValue } = useComponentCategoryContext();

  const { cpnDuplicateOf, eidDuplicateOf, revision } = editInputs ?? {};
  const { value: categoryRevision } = revision ?? {};
  const { revSchemeType = "DEFAULT" } = company.data;
  const { defaultBlacklistedRevisions, isNotRevisionManagedEnabled } = company.data?.settings ?? {};
  const statusInputValue = editInputs?.status?.value;
  const revisionManagedInput = editInputs?.revisionManaged;
  const revisionInputValue = editInputs?.revision?.value;
  const massInput = editInputs?.mass;

  const { cpnType, isCpnVariantEditable, isCpnVariantScheme } = useVariantConfig({ isCreating: isNewObject });
  const cpnDuplicateTooltip = useCpnDuplicateTooltip(cpnDuplicateOf);

  const [showMassWarningIcon, setShowMassWarningIcon] = useState(false);
  const [showMassErrorIcon, setShowMassErrorIcon] = useState(true);
  const [validateSpec, setValidateSpec] = useState(true);
  // const [componentPrimarySource, setComponentPrimarySource] = useState({});

  useEffect(() => {
    const { isSpecValidationEnabled } = company.data?.settings ?? {};
    const specs = editInputs?.specs;
    if (editInputs.category.value && validateSpec) {
      editInputs.specs = getSpecsValidated(specs, editInputs.category.value, isSpecValidationEnabled);
    }
    setValidateSpec(false);
  }, [company.data?.settings, editInputs, editInputs.category.value, validateSpec]);

  const component = useMemo(() => (
    isEditing ? editObject : viewObject
  ), [editObject, isEditing, viewObject]);
  const massPrecision = useMemo(() => Utils.getCompanyMassPrecision(company), [company]);
  const { isOriginallyVendorCmp, isVendorCmp, vendorLabel } = useMemo(() =>
    Utils.getVendorLabel(component), [component]);
  const showCustomProperties = useMemo(() => (
    component.customProperties
    && !!component.customProperties.length
    && (isVendorCmp || isOriginallyVendorCmp)
    && vendorLabel !== "VALISPACE"
  ), [component, isOriginallyVendorCmp, isVendorCmp, vendorLabel]);
  const vendorTooltipText = useMemo(() => Utils.vendorTooltip(Utils.getVendor(component)), [component]);
  const eidDuplicateTooltip = useMemo(() => (
    Utils.makeDuplicateInputTooltip(eidDuplicateOf, "EID")
  ), [eidDuplicateOf]);
  const lastModifiedBy = useMemo(() => (editObject && Utils.getLastModifiedBy(editObject)), [editObject]);
  const primarySource = useMemo(() => (
    // TODO (PLM-6280): Figure out if I need to fix this...
    // Object.keys(this.state.componentPrimarySource).length !== 0
    //   ? this.state.componentPrimarySource : editObject.primarySource
    editObject?.primarySource
  ), [editObject]);
  const primarySourceLeadTimeValue = useMemo(() => primarySource?.leadTime?.value ?? "", [primarySource]);
  const primarySourceLeadTimeUnits = useMemo(() => primarySource?.leadTime?.units ?? "", [primarySource]);
  const uploadImage = useCallback((images, errors, isUploadedThumbnails) => {
    setShouldBlockNavigation({ block: true });

    dispatch(buildAction(ComponentActions.UPLOAD_IMAGES_ON_EDIT_PAGE, {
      images,
      thumbnailUploaderrors: errors,
      isUploadedThumbnails,
    }));
  }, [dispatch, setShouldBlockNavigation]);

  const onInputChange = useCallback((event: any, i?: number) => {
    setShouldBlockNavigation({ block: true });
    const { name, value } = event.target;

    if (name === "category" && value !== editObject.category && !categoryChanged) {
      setChangedFieldName(name);
      setChangedFieldValue(value);
      setShowCategoryChangeAlert(true);
    }
    if (name === "category" && value === editObject.category && categoryChanged) {
      setValidateSpec(true);
    }

    if (name === "status") {
      let updatedTrackStatusChanged = true;
      if (value === "DESIGN") {
        updatedTrackStatusChanged = false;
        dispatch(buildAction(ComponentActions.UPDATE_EDIT_FORM_INPUT_STATE, {
          name,
          value,
        }));
        dispatch(buildAction(ComponentActions.UPDATE_EDIT_FORM_INPUT_STATE, {
          name: "revision",
          value: (editObject.status === "DESIGN" ? editObject.originalRevision : editObject.revision),
        }));
      }

      const previousRevision = Utils.getPreviousRevision(editObject);
      const validationPayload = {
        defaultBlacklistedRevisions,
        isClient: true,
        libraryType,
        previousRevision,
        revActionType: null,
        revisions: editObject,
        revSchemeType,
        status: value,
      };

      const revValue = validations.component.revision.normalize(validationPayload, revisionInputValue).revision;
      validateField(revisionInput, validations.component.revision, revValue.toUpperCase(), validationPayload);
      setNewStatusValue(value);
      setStatusFieldPreviousValue(statusInputValue);
      setTrackStatusChanged(updatedTrackStatusChanged);
    }
    else {
      dispatch(buildAction(ComponentActions.UPDATE_EDIT_FORM_INPUT_STATE, {
        cpnType,
        index: i,
        name,
        value,
      }));
    }

    if (event.target.name === "mass") {
      massInput.value = validations.component.mass.normalize(massInput.value);
      setShowMassErrorIcon(false);
      setShowMassWarningIcon(true);
    }
  }, [
    categoryChanged,
    cpnType,
    defaultBlacklistedRevisions,
    dispatch,
    editObject,
    libraryType,
    massInput,
    revisionInput,
    revisionInputValue,
    revSchemeType,
    setChangedFieldName,
    setChangedFieldValue,
    setNewStatusValue,
    setShouldBlockNavigation,
    setShowCategoryChangeAlert,
    setStatusFieldPreviousValue,
    setTrackStatusChanged,
    statusInputValue,
  ]);

  const selectDisplay = useMemo(
    (): ReactElement => {
      const revisionManaged = isNotRevisionManagedEnabled ? revisionManagedInput : true;
      const currentStatus = Utils.getPreviousStatus(editObject);
      if (isVendorCmp) {
        return (
          <LazyInput
            disabled
            name="status"
            type="text"
            value={statusInputValue}
          />
        );
      }

      return (
        <select
          disabled={!revisionManaged}
          name="status"
          onChange={onInputChange}
          value={statusInputValue}
        >
          {Utils.toOptions(Schemas.component.status.list(!isNewObject ? currentStatus : ""))}
        </select>
      );
    },
    [
      editObject,
      isNewObject,
      isNotRevisionManagedEnabled,
      isVendorCmp,
      onInputChange,
      revisionManagedInput,
      statusInputValue,
    ],
  );

  const handleProcurement = useCallback((event: any) => {
    // TODO (PLM-6280): Fix this so it doesn't update the inputs here...
    editInputs.procurement = event.target.value;
    onInputChange(event);
  }, [editInputs, onInputChange]);

  // Handle clicking the "REVISION MANAGED" checkbox.
  const [revisionWarningModalVisible, setRevisionWarningModalVisible] = useState(false);

  const toggleRevisionWarningModal = useCallback(() => {
    setRevisionWarningModalVisible(r => !r);
  }, [setRevisionWarningModalVisible]);

  const handleRevisionManaged = useCallback((event: any) => {
    // TODO (PLM-6280): This is required to make the redux saga to work, and can't be fixed until
    // components are moved away from redux or we create a new saga.
    editInputs.revisionManaged = !editInputs.revisionManaged;
    if (!editInputs.revisionManaged && component.status !== "PRODUCTION" && component.status !== "OBSOLETE") {
      editInputs.revision.value = "A";
      editInputs.status.value = "PRODUCTION";
    }
    onInputChange(event);
    toggleRevisionWarningModal();
  }, [component, editInputs, onInputChange, toggleRevisionWarningModal]);

  const checkRevisionManaged = useCallback((event: MouseEvent) => {
    // TODO (PLM-6280): This is required to make the redux saga to work, and can't be fixed until
    // components are moved away from redux or we create a new saga.
    editInputs.revisionManaged = !editInputs.revisionManaged;
    editInputs.revision.value = editInputs.previousRevision ? editInputs.previousRevision : editObject.revision;
    editInputs.status.value = editObject.status;
    onInputChange(event);
  }, [editObject, editInputs, onInputChange]);

  const tooltipElement = useCallback(() => document.querySelector("#routes .tiles-wrapper"), []);

  const userInData = useMemo(() => ({ data: user }), [user]);
  const { categoryCPN } = useCategoryCpn(pageItemType, isCpnVariantScheme);

  const viewCpn = useViewCpn(pageItemType);
  const customTileTitle = useMemo(() =>
    user?.activeLibrary?.customTileTitle ?? "Custom Specs", [user?.activeLibrary?.customTileTitle]);
  const viewWithCpn = useMemo(() => ({ ...viewObject, cpn: viewCpn }), [viewCpn, viewObject]);
  const altiumInfo = useMemo(() => component?.altiumInfo ?? {}, [component]);

  const className: string = useMemo(() => (
    `tiles-wrapper top-block ${isEditing && isVendorCmp ? "managed-by-vendor" : ""}`
  ), [isEditing, isVendorCmp]);

  return (
    <div className={className} style={{ paddingTop: isEditing ? 0 : "15px" }}>
      <PerfectScrollbar className="tiles-section-scroll">
        {!isEditing && (
          <section className="tiles-container">
            <ItemOverView item={viewWithCpn} alias="cmp" isPrdOrCmpViewPage={true} />
            <ItemDetails
              alias="cmp"
              defaultCurrency={viewObject.defaultCurrency}
              isPrdOrCmpViewPage={true}
              item={viewWithCpn}
              massPrecision={massPrecision}
            />
            <ItemSpecs item={viewWithCpn} massPrecision={massPrecision} />
            <ItemCustomSpecs
              item={viewWithCpn}
              isEditing={isEditing}
              onInputChange={() => { }}
              title={customTileTitle}
            />
            {showCustomProperties && (
              <ItemCustomProperties
                isVendorCmp={isVendorCmp}
                item={viewWithCpn}
                mode="view"
                onInputChange={undefined}
                tooltipText={undefined}
              />
            )}
            <ItemERP item={viewWithCpn} />
            <AltiumSpecs altiumInfo={altiumInfo}/>
          </section>
        )}
        {isEditing && (
          <>
            <TileItem title="Component overview">
              <ImageViewerTile
                defaultResolution={config.defaultResolutions.featureImage}
                edit={true}
                images={editObject.images}
                isVendorCmp={isVendorCmp}
                name="images"
                onChange={uploadImage}
                vendorTooltipText={vendorTooltipText}
              />
              <div className="inner-info pov diff-category-section">
                <span className="inner-attribute">category</span>
                <div
                  className="inner-value have-input"
                  data-for="vendor-disable-input"
                  data-tip={vendorTooltipText}
                >
                  {isVendorCmp && (
                    <LazyInput
                      className={editInputs.category.class}
                      data-place="right"
                      name="category"
                      type="text"
                      value={editInputs.category.value}
                    />
                  )}
                  {!isVendorCmp && editObject.status.toLowerCase() === "design" && (
                    <CategorySelect
                      className={`category-drop-down ${editInputs.category.class}`}
                      data-place="right"
                      data-tip={editInputs.category.message}
                      data-type="error"
                      name="category"
                      onChange={onInputChange}
                      value={editInputs.category.value}
                    />)
                  }
                  {!isVendorCmp && editObject.status.toLowerCase() !== "design"
                    && Schemas.component.category.getDisplayName(editObject.category)}
                </div>
              </div>
              <CpnEditField
                categoryCPN={categoryCPN}
                cpnDuplicateTooltip={cpnDuplicateTooltip}
                cpnVariant_disabled={!isCpnVariantEditable}
                cpnVariantScheme={isCpnVariantScheme}
                inputs={editInputs}
                onInputChange={onInputChange}
                user={userInData}
              />
              <div className="inner-info pov diff-eid-section">
                <span className="inner-attribute">eid</span>
                <div
                  data-tip={VENDORS_FOR_EDITABLE_FIELDS.includes(vendorLabel) ? "" : vendorTooltipText}
                  data-for="vendor-disable-input"
                  className={
                    `inner-value have-input ${VENDORS_FOR_EDITABLE_FIELDS.includes(vendorLabel)
                      ? "editable-vendor-fields" : ""}`
                  }
                >
                  <Tooltip
                    getTooltipContainer={tooltipElement}
                    overlay={eidDuplicateTooltip
                      ? <div>
                        <p>
                          <span className="link-text">{eidDuplicateTooltip.errorMessage}</span>
                          <br />
                          <Link
                            to={eidDuplicateTooltip.viewLink}
                            target="_blank"
                            className="open-link-holder white"
                          >
                            <span className="link-text">{eidDuplicateTooltip.linkMessage}
                              <InlineIcon >
                                <LinkIcon />
                              </InlineIcon>
                            </span>
                          </Link>
                        </p>
                      </div>
                      : ""
                    }
                    overlayClassName={"simple-rc-tip error"}
                    placement={"right"}
                    visible={!!eidDuplicateTooltip}
                  >
                    <LazyInput
                      className={editInputs.eid.class}
                      data-place="right"
                      data-tip={editInputs.eid.message}
                      data-type="error"
                      name="eid"
                      onChange={onInputChange}
                      type="text"
                      value={editInputs.eid.value}
                    />
                  </Tooltip>
                </div>
              </div>
              <div className="inner-info pov diff-revision-section">
                <span className="inner-attribute">revision</span>
                <div className="inner-value">
                  {isNewObject && (isNotRevisionManagedEnabled ? editInputs.revisionManaged : true)
                    ? (
                      <LazyInput
                        className={editInputs.revision.class}
                        data-place="right"
                        data-tip={editInputs.revision.message}
                        data-type="error"
                        name="revision"
                        onChange={onInputChange}
                        type="text"
                        value={editInputs.revision.value}
                      />
                    ) : (
                      <RevisionField
                        item={editObject}
                        revision={categoryRevision}
                      />
                    )
                  }
                </div>
              </div>
              <div className="inner-info pov diff-status-section">
                <span className="inner-attribute">status</span>
                <div
                  className="inner-value have-input width100"
                  data-for="vendor-disable-input"
                  data-tip={vendorTooltipText}
                >
                  {selectDisplay}
                </div>
              </div>
              {isVendorCmp && (
                <div className="inner-info pov addIn-only-fields">
                  <span className="inner-attribute">workflow state</span>
                  <div className="inner-value">
                    {editObject.cmpState ? editObject.cmpState : ""}
                  </div>
                </div>
              )}
            </TileItem>

            <TileItem title="Details" icon={<ItemDetailsIcon />}>
              {isVendorCmp && editInputs.description.value !== "" && (
                <p className="description">{editInputs.description.value}</p>
              )}
              {!isVendorCmp && (
                <div className="description">
                  <TextArea
                    className={`description diff-description-section ${editInputs.description.class}`}
                    data-place="right"
                    data-tip={editInputs.description.message}
                    data-type="error"
                    name="description"
                    onChange={onInputChange}
                    placeholder="Enter a component description"
                    value={editInputs.description.value}
                  />
                </div>
              )}
              <InlineIcon className="primary-source">
                <WebSrcIcon />
                <span>primary source</span>
              </InlineIcon>
              <div className="inner-info values-to-right diff-mpn-section">
                <span className="inner-attribute">mpn</span>
                <span className="inner-value" data-tip={vendorTooltipText} data-for="vendor-disable-input">
                  {primarySource.mpn}
                </span>
              </div>
              <div className="inner-info values-to-right diff-mfr-section">
                <span className="inner-attribute">mfr</span>
                <span className="inner-value" data-tip={vendorTooltipText} data-for="vendor-disable-input">
                  {primarySource.manufacturer}
                </span>
              </div>
              <div className="inner-info values-to-right diff-min-qty-section">
                <span className="inner-attribute">min qty</span>
                <span className="inner-value" data-tip={vendorTooltipText} data-for="vendor-disable-input">
                  {primarySource.minQuantity === null ? "" : primarySource.minQuantity}
                </span>
              </div>
              <UnitPrice
                item={editObject}
                mode="EDIT"
                primarySource={primarySource}
                tooltipText={vendorTooltipText}
              />
              <ExtendedRolledUpCost
                alias="cmp"
                extendedCost={primarySource.extendedCost}
                mode="EDIT"
                object={editObject}
              />
              <div className="inner-info last-info values-to-right diff-lead-time-section">
                <span className="inner-attribute">lead time</span>
                <span className="inner-value">
                  {`${primarySourceLeadTimeValue} ${primarySourceLeadTimeUnits}`}
                </span>
              </div>
              <div className="inner-info">
                <span className="inner-attribute">created</span>
                <TimeStampColumn format="date-time-with-long-format" value={editObject.created} />
              </div>
              <div className="inner-info">
                <span className="inner-attribute">modified</span>
                <LastUpdatedField item={editObject} />
              </div>
              <div className="inner-info">
                <span className="inner-attribute">modified by</span>
                {lastModifiedBy}
              </div>
            </TileItem>

            <TileItem title="Specs" icon={<ItemSpecsIcon />}>
              {isNotRevisionManagedEnabled && (
                <RevisionManaged
                  onChange={editInputs.revisionManaged ? toggleRevisionWarningModal : checkRevisionManaged}
                  revisionManaged={editInputs.revisionManaged}
                  tooltipText={vendorTooltipText}
                  vendor={Utils.getVendor(editObject)}
                />
              )}
              {
                editInputs.specs.map((spec: any, i: number) => {
                  const allowedValues: any[] = [];
                  spec.schema && spec.schema.allowedValues.forEach((allowedValue: any) => {
                    allowedValues.push({ value: allowedValue, displayName: allowedValue });
                  });
                  const isDropDown = spec.schema && spec.schema.isDropDown;
                  return Schemas.component.specs.isSpecAllowedForCompany(spec.key) && (
                    <div
                      key={`specs-info-${i}`}
                      className={
                        `inner-info values-to-right ${`diff-${spec.key.replace(/[^a-zA-Z ]/g, "")
                          .split(" ").join("-").toLowerCase()}-section`}`
                      }
                    >
                      <span className="inner-attribute specs">
                        {Schemas.component.specs.getKey(spec.key)}
                      </span>
                      <div
                        className={
                          `inner-value${isVendorCmp
                            ? " have-input" : ""} ${(VENDORS_FOR_EDITABLE_FIELDS.includes(vendorLabel)
                              && spec.key !== "Material") ? "editable-vendor-fields" : ""
                          }`
                        }
                        data-for={isVendorCmp ? "vendor-disable-input" : null}
                        data-tip={isVendorCmp && spec.key === "Material" ? vendorTooltipText : null}
                      >
                        <SpecInputField
                          allowedValues={allowedValues}
                          index={i}
                          input={spec}
                          isDropDown={isDropDown}
                          onInputChange={onInputChange}
                        />
                      </div>
                    </div>
                  );
                })
              }
              <div className="inner-info values-to-right diff-mass-section">
                <span className="inner-attribute specs no-cl">{`MASS (${Utils.getCompanyMassUnit()})`}</span>
                <div className="inner-value">
                  {isAssembly && (
                    <>
                      {
                        (
                          showMassErrorIcon === true
                          && editObject.massStatus
                          && editObject.massStatus.toUpperCase() === "ERROR"
                        ) && (
                          <InlineIcon
                            className="inside-icon"
                            tooltip="Missing mass values for one or more children"
                            tooltipPlace="top"
                          >
                            <AlertErrorIcon />
                          </InlineIcon>
                        )}
                      {
                        (
                          (showMassWarningIcon === true)
                          || (editObject.massStatus && editObject.massStatus.toUpperCase() === "WARNING")
                        ) && (
                          <InlineIcon
                            className="inside-icon"
                            tooltip={
                              `${editInputs.mass.value
                                ? "Manually entered value" : "Auto calculate value from children"} `
                            }
                            tooltipPlace="top"
                          >
                            <WarningTriangleIcon />
                          </InlineIcon>
                        )}
                    </>
                  )}
                  <NumberFormat
                    className={`${editInputs.mass.class}${isAssembly ? " icon-in-input" : ""}`}
                    data-place="right"
                    data-tip={editInputs.mass.message}
                    data-type="error"
                    name="mass"
                    onChange={onInputChange}
                    thousandSeparator={true}
                    type="text"
                    value={editInputs.mass.value}
                  />
                </div>
              </div>
              <ProcurementEdit
                inputs={editInputs}
                isVendorCmp={isVendorCmp}
                onInputChange={handleProcurement}
                vendorLabel={vendorLabel}
              />
              <div
                className={`${VENDORS_FOR_EDITABLE_FIELDS.includes(vendorLabel) ? "editable-vendor-fields" : ""}
              inner-info values-to-right diff-unit-of-measure-section`}
              >
                <span className="inner-attribute specs">unit of measure</span>
                <select
                  className="inner-value uom-input"
                  data-for="unit-of-measure-input"
                  data-tip={vendorTooltipText}
                  name="unitOfMeasure"
                  onChange={onInputChange}
                  value={editInputs.unitOfMeasure.value}
                >
                  {Utils.toOptions(Schemas.component.unitOfMeasure.list())}
                </select>
              </div>
            </TileItem>

            <ItemCustomSpecs
              item={editInputs}
              isEditing={isEditing}
              onInputChange={onInputChange}
              title={customTileTitle}
            />

            {showCustomProperties && (
              <ItemCustomProperties
                isVendorCmp={isVendorCmp}
                item={editObject}
                mode="edit"
                onInputChange={onInputChange}
                tooltipText={vendorTooltipText}
              />
            )}
            <ItemERP item={editObject} />
            <AltiumSpecs altiumInfo={altiumInfo}/>
          </>
        )}
      </PerfectScrollbar>
      {revisionWarningModalVisible && (
        <WarningModal
          description="The Status and Revision values for this Component will no longer be revisioned."
          onCancel={toggleRevisionWarningModal}
          onConfirm={handleRevisionManaged}
          isWarningIcon
          title="Warning"
        />
      )}
    </div >
  );
}
