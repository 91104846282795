import                           "./index.css"
import React, { Component } from 'react';
import Spinner              from "../../../assets/icons/spinner-green.js"
import InlineIcon           from "../icon/inline-icon.js"


class SpinnerButton extends Component
{
    constructor(props)
    {
        super(props)
        this.state = { loading: false };
        this.toggle = this.toggle.bind(this)
    }

    toggle(e) {
        this.setState({
          loading: true
        });
        let _this = this
        setTimeout(function(){
            _this.props.onClick(e)
        }, 800);
    }

    render()
    {
        let buttonText = this.props.btnText    ? this.props.btnText    : 'continue'
        let isDisabled = this.props.isDisabled ? this.props.isDisabled : false
        let stopLoading= this.props.stopLoading ? this.props.stopLoading : false 

        let className = ["spinner-btn", this.props.className ? this.props.className : ''].join(' ')

        let markup = null
        markup = <button
                type="button"
                disabled={isDisabled}
                onClick={this.toggle}
                className={className}
            >
                {
                    this.state.loading && !stopLoading ?
                        <div className="spinner-holder">
                            <div className="spinner">
                                <InlineIcon>
                                    <Spinner/>
                                </InlineIcon>
                            </div>
                            <span>{buttonText}</span>
                        </div>
                   : buttonText}
            </button>

        return markup
    }
}

export default SpinnerButton
