import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width="28px" height="24px" viewBox="0 0 28 24" {...props}>
      <defs>
        <style />
      </defs>
      <g
        id="REVISED-CHANGE-ORDERS-NEW-WORKFLOW"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      >
        <g
          id="1_CHANGE-ORDER-EDIT"
          transform="translate(-379 -220)"
          fillRule="nonzero"
        >
          <g id="TOP-CONTENT" transform="translate(313 142)">
            <g id="MCO" transform="translate(66 78)">
              <path
                d="M.558 0A.638.638 0 000 .617v22.769A.64.64 0 00.615 24h25.846a.64.64 0 00.615-.615V6.77c.003-.417-.51-.74-.884-.558l-9.885 4.798V6.77c.003-.417-.51-.74-.884-.558L5.538 11.01V.616a.64.64 0 00-.615-.615H.558zm.673 1.231h3.077v10.77c-.003.416.51.74.884.557l9.884-4.798V12c-.002.417.51.74.885.558l9.884-4.798v15.01h-4V16a.638.638 0 00-.557-.615h-4.365a.64.64 0 00-.616.615v6.77H10.77V16a.64.64 0 00-.615-.615H5.788a.638.638 0 00-.557.615v6.77h-4V1.23zm5.23 15.385h3.077v6.153H6.461v-6.153zm11.077 0h3.077v6.153h-3.077v-6.153z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
