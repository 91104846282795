import { SvgIcon } from "@mui/material";

export const ArrowUpIcon = () => (
  <SvgIcon viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="evenodd">
      <path d="M1 1h16v16H1z M16.067 17H1.933A.911.911 0 0 1 1 16.067V1.933C1 1.4 1.4 1 1.933
      1h14.2c.467 0 .867.4.867.933v14.2c0 .467-.4.867-.933.867z" stroke="#CBCBCB"/>
      <path d="M9.667 15V5.533l2.4 2.4L13 7 9 3 5 7l.933.933 2.4-2.4V15z"/>
    </g>
  </SvgIcon>
);
