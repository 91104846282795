
 /* eslint-disable */

import React    from "react"
import { datadogRum } from "@datadog/browser-rum"
import { inRange, padStart } from "lodash"
import Config   from "../config"
import traverse from "traverse"
import filesize from "filesize"
import Schemas  from "../schemas"
import axios    from 'axios';
import moment   from "moment"
import InlineIcon from "../../components/ui/icon/inline-icon.js";
import LinkIcon from "../../assets/icons/open-link-icon.js";
import RedTriangleIcon from "../../assets/icons/co-alert"
import API      from "../api";
import Pusher   from 'pusher-js';
import {ALLOWED_VENDORS}  from  "../constants";
import validations, { validateField, normalizeValue } from "../validations"
import { userById } from "graphql/query/userQueries"
import { formatLegacyCpn } from "utils/cpn"


// require('colors');
var jsdiff = require('diff');


const connectionErrorMsg = "Server connection issue. Please check your internet connection and try again."
function getApi(url, origin, headers, cb) {
    url = origin + url
        cb  = typeof cb === "function" ? cb : Utils.noop

        let options =
        {
            method      : "GET",
            credentials : "include",
            headers     :
            {
                "Content-Type": "application/json",
                ...headers,
            }
        }

        fetch(url, options)

        .then((res) =>
        {
            res.json()

            .then((data) =>
            {
                if(!data.success) {
                    if (data.errors && data.errors.length && data.errors[0].message === "Unauthorized") {
                        throw data.errors
                    }
                    else {
                        return cb(data)
                    }
                }

                cb(null, data)

            })

            .catch((err) =>
            {
                err =
                {
                    success  : false,
                    apiError : true,
                    errors   : [res.statusText]
                }

                cb(err)
            })
        })

        .catch((err) =>
        {
            err =
            {
                success  : false,
                apiError : true,
                errors   : [{message: connectionErrorMsg}]
            }

            cb(err)
        })
}

function postApi(url, data, origin, headers, cb) {
    url = origin + url
    cb  = typeof cb === "function" ? cb : Utils.noop

    let options =
    {
        method      : "POST",
        credentials : "include",
        headers     :
        {
            "Content-Type": "application/json",
            ...headers,
        }
    }
    if (data)
    {
        options =
        {
            method      : "POST",
            credentials : "include",
            body        : JSON.stringify(data),
            headers     :
            {
                "Content-Type": "application/json",
                ...headers,
            }
        }
    }

    fetch(url, options)

    .then((res) =>
    {
        res.json()

        .then((data) =>
        {
            if(!data.success) {
                if (data.errors && data.errors.length && data.errors[0].message === "Unauthorized") {
                    throw data.errors
                }
                else {
                    return cb(data)
                }
            }

            cb(null, data)

        })

        .catch((err) =>
        {
            err =
            {
                success  : false,
                apiError : true,
                errors   : [{message: connectionErrorMsg}]
            }

            cb(err)
        })
    })

    .catch((err) =>
    {
        err =
        {
            success  : false,
            apiError : true,
            errors   : [{message: connectionErrorMsg}]
        }

        cb(err)
    })
}

function uploadFileApi(url, origin, data, headers, cb)
{
    let form = new FormData()
    form.append('file', data.file ? data.file : data)
    form.append('specs', data.specs)
    if(data.fromMcMaster)
    {
        form.append('username', data.username);
        form.append('password', data.password);
        form.append('passphrase', data.passphrase);
    }
    url = origin + url
    cb  = typeof cb === "function" ? cb : Utils.noop

    let options =
    {
        method      : "POST",
        credentials : "include",
        body: form,
        headers,
    }

    fetch(url, options)

    .then((res) =>
    {
        res.json()

        .then((data) =>
        {
            if(!data.success) {
                if (data.errors && data.errors.length && data.errors[0].message === "Unauthorized") {
                    throw data.errors
                }
                else {
                    return cb(data)
                }
            }

            cb(null, data)

        })

        .catch((err) =>
        {
            err =
            {
                success  : false,
                apiError : true,
                errors   : [{message: connectionErrorMsg}]
            }

            cb(err)
        })
    })

    .catch((err) =>
    {
        err =
        {
            success  : false,
            apiError : true,
            errors   : [{message: connectionErrorMsg}]
        }

        cb(err)
    })
}

class Utils
{
    static headers;

    static get(url, cb)
    {
        getApi(url, Config.API_ORIGIN, this.headers, cb)
    }

    static post(url, data, cb)
    {
        postApi(url, data, Config.API_ORIGIN, this.headers, cb)
    }

    static uploadFile(url, data, cb)
    {
        uploadFileApi(url, Config.API_ORIGIN, data, this.headers, cb)
    }

    static getEvent(target, value)
    {
        let event = new CustomEvent("AppEvent")
        Object.defineProperty(event, "target", {value: target})
        target.name  = target.getAttribute ? target.getAttribute("name") : target.props.name
        target.value = value
        return event
    }

    static removeEmptyValues(array=[])
    {
        return array.filter(function(n){ return n != undefined })
    }


    static getExtension(name)
    {
        if(typeof name !== "string") return "?"
        if(name.indexOf(".") < 0) return ""
        let parts = name.split(".")
        let extenstion = parts.pop();
        if(extenstion === 'x_t')
        {
            extenstion = 'PARASOLID';
        }
        return extenstion;
    }

    static checkForRestrictedRole(role)
    {
         switch(role)
        {
            case "SUPPLIER":
            case "VENDOR":
            {
                return true;
                break;
            }
            default : return false;
        }
    }

    static fileSize(value)
    {
        try
        {
            return filesize(Number(value), {round: 0, base: 10})
        }
        catch(err)
        {
            return value
        }
    }

    static blur()
    {
        document.activeElement && document.activeElement.blur && document.activeElement.blur()
    }

    static replaceSpaceWithDot(value)
    {
        return value.replace(/\s+/g, '.');
    }

    static isValidated(inputs)
    {
        let valid = true

        traverse(inputs).forEach(function(value)
        {
            if(this.key === "valid" && !value)
            {
                valid = false
            }
        })
        return valid
    }

    static getErrorsCount(inputs)
    {
        let count = 0

        traverse(inputs).forEach(function(value)
        {
            if(this.key === "valid" && !value)
            {
                count++
            }
        })

        return count
    }

    static persistCursor(event, value)
    {
        if
        (
            !event                                          ||
            !event.target                                   ||
            event.target.type === "radio"                   ||
            event.target.type === "checkbox"                ||
            typeof event.target.selectionStart !== "number" ||
            typeof value !== "string"                       ||
            event.target.value === value
        )
        {
            return
        }

        let diff     = event.target.value.length - value.length
        let position = event.target.selectionStart - diff
        try
        {
            event.persist()
        }
        catch(err)
        {

        }

        window.requestAnimationFrame(()=>
        {
            event.target.setSelectionRange(position, position)
        })
    }

    // TODO: delete trim
    static trim(value)
    {
        if(typeof value !== "string") value = ""
        return value.trim().replace(/ +(?= )/g, "");
    }

    static contains(a, b)
    {
        if(typeof a !== "string" || typeof a !== "string") return false
        return a.toLowerCase().indexOf(b.toLowerCase()) > -1
    }

    static date(time)
    {
        time    = time || Date.now()
        let d   = new Date(time)
        let str = ""
            + Utils.pad(d.getMonth() + 1, 2) + "-"
            + Utils.pad(d.getDate(), 2) + "-"
            + d.getFullYear()
        return str
    }

    static dateInUSFormat(time)
    {
        time    = time || Date.now()
        let d   = new Date(time)
        let str = ""
            + d.getFullYear() + "-"
            + Utils.pad(d.getMonth() + 1, 2) + "-"
            + Utils.pad(d.getDate(), 2)
        return str
    }


    static dateTime(time)
    {
        let d       = new Date(time)
        let _date   = Utils.date(time)
        let timeStr = _date +
                        " " +
                        Utils.pad(d.getHours(), 2) + ":" +
                        Utils.pad(d.getMinutes(), 2) + ":" +
                        Utils.pad(d.getSeconds(), 2)
        return timeStr
    }

    static dateTimeWithLongFormat(time)
    {
        time = !!Number(time) ? Number(time) : Date.now()
        let dataTimeStr = moment(Number(time)).format('MMM DD, YYYY-hh:mmA')
        return {dateValue: dataTimeStr.split("-")[0].trim(), timeValue: dataTimeStr.split("-")[1].replace("A", " A").replace("P", " P")}
    }

    static dateFormatWithSlash(time)
    {
        let dataTimeStr = moment(time).format('l')
        return dataTimeStr
    }

    static clone(obj)
    {
        if(!obj)
        {
            return null
        }

        try
        {
            return JSON.parse(JSON.stringify(obj))
        }
        catch(err)
        {
            return null
        }
    }

    static timeDiff(time)
    {
        let lastDateTime   = new Date(time);
        let currentDateTime   = Date.now();

        const diffTime = Math.abs(currentDateTime - lastDateTime);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    }

    static pad(number, length)
    {
        var str = "" + number

        while (str.length < length)
        {

            str = "0" + str;
        }
        return str
    }

    static getObjectKeys(obj)
    {
        let props = []

        search(obj)

        function search(obj)
        {
            if(obj == null) return
            let names = Object.getOwnPropertyNames(obj)
            props = [...props, ...names]
            search(Object.getPrototypeOf(obj))
        }

        return props
    }

    static hasClass(el, className)
    {
        if(el.classList)
        {
            return el.classList.contains(className)
        }

        return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'))
    }

    static addClass(el, className)
    {
        if(el.classList)
        {
            return el.classList.add(className)
        }

        if(!Utils.hasClass(el, className))
        {
            return el.className += " " + className
        }
    }

    static removeClass(el, className)
    {
        if(el.classList)
        {
            return el.classList.remove(className)
        }

        if(Utils.hasClass(el, className))
        {
            var reg = new RegExp('(\\s|^)' + className + '(\\s|$)')
            return el.className = el.className.replace(reg, ' ')
        }
    }

    static readFiles(files, cb)
    {
        let errored = false;
        let results = [];

        if( !Array.isArray(files) )
        {
            files = [files];
        }

        if( files.length === 0 )
        {
            return cb(null, files);
        }

        for(var i=0; i<files.length; i++)
        {
            var file = files[i];

            // eslint-disable-next-line
            Utils.readFile(file, (err, data) =>
            {
                if(errored)
                {
                    return;
                }

                if(err)
                {
                    errored = true;
                    return cb(err);
                }

                results.push(data);

                if(results.length === files.length)
                {
                    cb(null, results);
                }
            });
        }
    }

    static readFile(file, cb)
    {
        if(file instanceof File === false) return cb(null, file)

        if(Array.isArray(file))
        {
            return Utils.readFiles(file, cb);
        }

        var reader = new FileReader();

        reader.addEventListener("loadend", function()
        {
            let data =
            {
                name  : file.name,
                size  : file.size,
                mime  : file.type,
                label : Utils.getFileLabel(file.name, file.type),
                specs : file.specs,
                data  : reader.result.split(",")[1]
            }

            cb(null, data);
        });

        reader.addEventListener("error", function()
        {
            cb(reader.error);
        });

        reader.readAsDataURL(file)
    }

    static getFileLabel(name, mime)
    {
        let extParts = name.split(".")
        let ext = extParts[extParts.length-1]

        mime = mime || null

        for(let i=0; i<Config.files.labels.length; i++)
        {
            let item = Config.files.labels[i]
            if(item.ext === ext || item.mime === mime) return item.label
        }

        mime = mime || ""

        let mimeParts = mime.split("/")
        let label = mimeParts[1]

        if(!label) return "File"

        return label.length < 10 ? label : mimeParts[0]
    }

    static isDescendant(parent, child)
    {
        var node = child.parentNode

        while(node !== null && node !== undefined)
        {
            if(node === parent) return true

            node = node.parentNode
        }

        return false
    }

    static diff(newValues, oldValues, returnDiffs)
    {
        const VALUE_CREATED   = "created"
        const VALUE_UPDATED   = "updated"
        const VALUE_DELETED   = "deleted"
        const VALUE_UNCHANGED = "unchanged"

        let modified = false
        let diffs    = map(newValues, oldValues)

        return returnDiffs ? diffs : modified

        function map(obj1, obj2)
        {
            if(isFunction(obj1) || isFunction(obj2))
            {
                throw "Invalid argument. Function given, object expected."
            }
            if(isValue(obj1) || isValue(obj2))
            {
                return {
                    type: compareValues(obj1, obj2),
                    data: (obj1 === undefined) ? obj2 : obj1
                }
            }

            let diff = {}

            for(let key in obj1)
            {
                if(isFunction(obj1[key]))
                {
                    continue
                }

                let value2 = undefined
                if("undefined" !== typeof(obj2[key]))
                {
                    value2 = obj2[key]
                }

                diff[key] = map(obj1[key], value2)
            }
            for(let key in obj2)
            {
                if(isFunction(obj2[key]) || ("undefined" !== typeof(diff[key])))
                {
                    continue
                }

                diff[key] = map(undefined, obj2[key])
            }

            return diff

        }

        function compareValues(value1, value2)
        {
            if(value1 === value2)
            {
                return VALUE_UNCHANGED
            }
            if(isDate(value1) && isDate(value2) && value1.getTime() === value2.getTime())
            {
                return VALUE_UNCHANGED
            }
            if("undefined" == typeof(value1))
            {
                modified = true
                return VALUE_CREATED
            }
            if("undefined" == typeof(value2))
            {
                modified = true
                return VALUE_DELETED
            }

            modified = true
            return VALUE_UPDATED
        }

        function isFunction(obj)
        {
            return {}.toString.apply(obj) === "[object Function]"
        }

        function isArray(obj)
        {
            return {}.toString.apply(obj) === "[object Array]"
        }

        function isDate(obj)
        {
            return {}.toString.apply(obj) === "[object Date]"
        }

        function isObject(obj)
        {
            return {}.toString.apply(obj) === "[object Object]"
        }

        function isValue(obj)
        {
            return !isObject(obj) && !isArray(obj)
        }
    }

    static checkStore()
    {
        if(Config.VERSION !== Utils.getStore("app-version"))
        {
            window.localStorage.clear()
            Utils.setStore("app-version", Config.VERSION)
        }
    }

    static setStore(key, value, restrictions)
    {
        restrictions = restrictions instanceof Array ? restrictions : []

        value = traverse(value).forEach(function()
        {
            if(restrictions.indexOf(this.key) > -1) this.update("")
        })

        try
        {
            value = JSON.stringify(value)
        }
        catch(err)
        {
            // do nothing
        }

        window.localStorage && window.localStorage.setItem(key, value)
    }

    static getStore(key)
    {
        try
        {
            return JSON.parse(window.localStorage.getItem(key))
        }
        catch(err)
        {
            // do nothing
        }
    }

    static clearStore(key)
    {
        window.localStorage.removeItem(key)
    }

    static type(obj)
    {
        try
        {
            return obj.constructor.toString().split("(")[0].replace("function", "").trim()
        }
        catch(err)
        {
            // do nothing
        }
    }

    static toTruncatedString(value, max, ellipsis)
    {
        if(typeof value !== "string") value = ""
        if(typeof max   !== "number") return value

        if(ellipsis)
        {
            if(max < 3) max = 3
            if(value.length < max) return value
            return value.substring(0, max - 3) + "..."
        }

        if(value.length < max) return value
        return value.substring(0, max)
    }

    static toAlphaString(value, exceptions)
    {
        exceptions = typeof exceptions === "string" ? exceptions : ""

        let str = ""

        value = typeof value === "string" ? value : ""

        for(let key in value)
        {
            let char = value[key]

            if
            (
                exceptions.indexOf(char) > -1 ||
                char.match(/[a-z]/i)
            )
            {
                str += char
            }
        }

        return str
    }

    static havePropertyWithValue(hashArray, property, value, options={}){
        var found = false;
        let selectedObj = {}
        for(var i = 0; i < hashArray.length; i++) {

            let hashValue = hashArray[i][property]

            if (options.removeHyphen)
            {
                hashValue = hashArray[i][property].replace(/-/g, " ")
                value = value.replace(/-/g, " ")
            }

            if (hashValue.toLowerCase() === value.toLowerCase()) {
                selectedObj = hashArray[i]
                found = true;
                break;
            }
        }
        return {found, selectedObj}
    }

    static removeExtraSpaces(string = ""){
        return string.replace(/\s+/g,' ').trim()
    }


    static toNumericString(value, exceptions)
    {
        exceptions = typeof exceptions === "string" ? exceptions : ""

        let str = ""

        value = typeof value === "string" ? value :
        (
            !typeof value === "number" ? "" :
            (
                isNaN(value) ? "" : String(value)
            )
        )

        for(let key in value)
        {
            let char = value[key]

            if
            (
                exceptions.indexOf(char) > -1 ||
                char.match(/[0-9]/i)
            )
            {
                str += char
            }
        }

        return str
    }

    static toAlphaNumericString(value, exceptions)
    {
        exceptions = typeof exceptions === "string" ? exceptions : ""

        let str = ""

        value = typeof value === "string" ? value :
        (
            !typeof value === "number" ? "" :
            (
                isNaN(value) ? "" : String(value)
            )
        )

        for(let key in value)
        {
            let char = value[key]

            if
            (
                exceptions.indexOf(char) > -1 ||
                char.match(/[a-z]/i)          ||
                char.match(/[0-9]/i)
            )
            {
                str += char
            }
        }

        return str
    }

    static toCondensedString(value)
    {
        if(typeof value !== "string") value = ""
        return value.replace(/ /g, "")
    }

    static appendHTTP(value)
    {
        if (!value.startsWith("http") && value.length >= 4)
        {
            return "http://" + value
        }
        return value
    }

    static toParagraphString(value, trimRight)
    {
        if(typeof value !== "string") value = ""
        let str  = value.trim().replace(/ +(?= )/g, "");
        let last = value.slice(-1)
        if(!trimRight && last === " ") str += " "
        return str
    }

    static hasSpecialChar(value)
    {
        let re = /[@\_~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g
        return re.test(value)
    }

    static hasNumber(value)
    {
        return /[0-9]/i.test(value)
    }

    static hasLetter(value)
    {
        return /[a-z]/i.test(value)
    }

    static hash(str, obj)
    {
        str = typeof str === "string" ? str : ""
        obj = obj || {}

        let index = (obj, i) =>
        {
            return obj[i]
        }
        return str.split(".").reduce(index, obj)
    }

    static fromInputsToValues(inputs)
    {
        var values = traverse(inputs).forEach(function(value)
        {
            if(!value)
            {
                return
            }

            if(this.node.hasOwnProperty("value"))
            {
                this.update(this.node.value)
            }
        })

        return values
    }

    static toOptions(list, includeChooseStatusOption=false, mcMasterEnabled=false, chooseOption='Choose Status')
    {

        if(!Array.isArray(list)) return
        if (includeChooseStatusOption)
        {
            let ChooseStatusOption = [{ value: `${chooseOption}`, displayName: `${chooseOption}` }]
            list = [...ChooseStatusOption, ...list]
        }
        let options = []
        let name = ""
        list.forEach((item, i) =>
        {
            let value = item.value || item.name
            if(Utils.isNonIntelligentCPNScheme())
            {
                name = item.displayName || item.name
            }
            else
            {
                name = item.displayNameWithPrefix || item.displayName || item.name
            }
            if (item.disabled) value = ''
            let disabled = item.type && item.type.includes('MECHANICAL') && mcMasterEnabled ? true : false;//adding this to mcMasterEnabled in conditio to disable mechanical categories based on the mcmaster availibility
            options[i] = <option key={i} value={value} disabled={item.disabled || disabled }>{name}</option>
        })

        return options
    }

    static capitalizeString(string="") {
        string = string.toLowerCase()
        return string.replace(/(^|\s)\S/g, l => l.toUpperCase())
    }

    static itemsList(list)
    {
        if(!Array.isArray(list)) return

        let options = []
        options[0] = <option key={0} value="items-list">Select Product</option>
        list.forEach((item, i) =>
        {
            let value = item._id
            let displayName = (item.cpn) + " " + item.name
            options[i+1] = <option key={i+1} value={value}>{displayName}</option>
        })

        return options
    }

    static noop()
    {
        return null
    }

    static delay(ms=800) {
        return new Promise(res => setTimeout(res, ms))
    }

    static getStyleValue(style={}, name, property, defaultValue, options={}) {
        try
        {
            if ( Object.keys(style).length > 0 && style[name] && style[name][property] != undefined && style[name][property] != null )
            {

                return style[name][property]
            }
            else
                return defaultValue
        }
        catch(error) {
            if (options.onErrorDefault === true)
            {
                return defaultValue
            }
            else
                throw error
        }
    }

    static generateUniqueId() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
        });
    }

    static checkToolTipAppearance(e, tootipText, elementClass=''){

        let element = e.currentTarget
        if (elementClass && !element.classList.contains(elementClass))
        {
            element = element.closest('.' + elementClass)
            // return;
        }

        element.dataset.tip = ""

        if (element.offsetWidth < element.scrollWidth){
            element.dataset.tip = tootipText
        }
        else{
            element.dataset.tip = ""
        }
    }

    static stringValueToDays(value, unit) {
        unit = unit.toUpperCase()

        if (unit === 'DAYS' || unit === 'DAY')
        {
            return value
        }
        else if (unit === 'WEEKS' || unit === 'WEEK')
        {
            return ( value * 7 )
        }
        else
        {
            throw "Invalid value and unit"
        }
    }

    static scrollMainContainerToTop(value, unit) {
        let scrollbarContainer = document.querySelector(".scrollbar-container")
        if (scrollbarContainer)
            scrollbarContainer.scrollTo(0,0)
    }

    static makeTitle(page_title){
        return page_title + ' | Duro';
    }

    static recordException(error, options={})
    {
        if (Config.RECORD_ERRORS)
        {
            datadogRum.addError(error);
        }
    }

    static haveVariantScheme(company, env="LIVE")
    {
        // if (window.__companyIsArevo && window.__companyIsArevo()) return true
        if (company && company.cpnScheme && company.cpnScheme.activeScheme && env === "LIVE")
            return company.cpnScheme.activeScheme === "EXTRA-TWO-DIGIT-VARIANT"
        else
            return false
    }

    static haveExcelFileExtension(fileName)
    {
        let acceptedExtenstions = ['.csv', '.xlsx', '.xls']
        let haveExtension = false

        acceptedExtenstions.forEach((extenstion) =>
        {
            if (fileName.toLowerCase().endsWith(extenstion))
                haveExtension = true
        })

        return haveExtension
    }

    static makeDuplicateInputTooltip(duplicateOf, field, sourcingWarning=false){
        let linkMessage, errorMessage, viewLink, editLink, alias
        if(duplicateOf){
            let cpn = window.__libraryType === "GENERAL" && window.__cpnSchemeType === "DEFAULT" ? duplicateOf.cpn : `${duplicateOf.cpn}-${duplicateOf.cpnVariant}`
            let type = duplicateOf.alias === 'cmp' ? "/component" : "/product"


            viewLink = type + "/view/" + duplicateOf._id
            editLink = type + "/edit/" + duplicateOf._id
            errorMessage = sourcingWarning ? "This sourcing combination already exists in this component." : field + " already exists in library."
            linkMessage = cpn + " " + duplicateOf.name

            if (linkMessage.length > 24)
            {
                duplicateOf.name = duplicateOf.name.substring(0, (24 - cpn.length)) + "..."
            }
            name = ["\"", duplicateOf.name, "\""].join("")
            linkMessage = cpn + " " + name
            alias = duplicateOf.alias
        }
        else
            return null
        return {viewLink, editLink, linkMessage, errorMessage, alias, cpn: duplicateOf.cpn }
    }

    static makeSerialDuplicateInputTooltip(duplicateOf, field)
    {
        let linkMessage, errorMessage, viewLink, alias;
        if (duplicateOf)
        {
            let { _id, name, serial } = duplicateOf;

            viewLink = `/production/instance/view/${_id}`
            errorMessage = `${field} already exists in library.`
            linkMessage = `${serial} ${name}`

            if (linkMessage.length > 24)
            {
                let uptoIndex = serial.length < 20 ? (20 - serial.length) : 5;
                name = `${name.substring(0, uptoIndex)}...`
            }

            name = ["\"", name, "\""].join("")
            linkMessage = `${serial} ${name}`
            alias = duplicateOf.alias
        }
        else
            return null
        return {viewLink, linkMessage, errorMessage, alias, serial: duplicateOf.serial }
    }

    static sortComponents(sortBy="cpn", components, ascending=true)
    {
        components.sort((a,b) =>
        {
            a = this.hash(sortBy, a)
            b = this.hash(sortBy, b)
            if(typeof a === "string") a = a.toLowerCase()
            if(typeof b === "string") b = b.toLowerCase()
            if(a < b) return ascending ? -1 :  1
            if(a > b) return ascending ?  1 : -1
            return 0
        });

        return components
    }

    static validateStatus(currStatus=null, nextStatus=null)
    {
        let statusList = Schemas.component.status.list()
        let rankOfNextStatus = null
        let rankOfCurrStatus = null
        for(let i = 0; i < statusList.length; i++ )
        {
            if(statusList[i].displayName === currStatus)
            {
                rankOfCurrStatus = statusList[i].rank
            }
            if(statusList[i].displayName === nextStatus)
            {
                rankOfNextStatus = statusList[i].rank
            }
        }

        if(rankOfCurrStatus < rankOfNextStatus || (rankOfNextStatus === rankOfCurrStatus && currStatus === 'DESIGN'))
        {
            return true
        }
        else
        {
            return false
        }
    }

    static getComputedStyleOfElem(selector, stylePty, pseudoElt=null)
    {
        let elem = document.querySelector(selector);
        if(elem)
        {
            let styles      = window.getComputedStyle(elem, pseudoElt);
            let stylePtyVal = styles.getPropertyValue(stylePty);

            return stylePtyVal;
        }
        return false;
    }

    // Return the formatted CPN (prefix, counter, and variant if applicable)
    static getCpn(item) {
        let splittedCpn = item?.cpn?.split('-') ?? "";
        if (window.__cpn_rules()) {
            const { activeLibrary } = userById();
            const modelType = item.alias === "cmp" ? "COMPONENT" : "PRODUCT";
            return formatLegacyCpn({
                cpn: item.cpn, hasVariant: item.variantGroup, library: activeLibrary, modelType, variant: item.cpnVariant
            });
        }
        else if (window.__companyIs9DigitCpnType())
        {
            if(item.alias === 'cmp')
            {
                if(splittedCpn.length === 2 && item.cpnVariant !== "00" && item.cpnVariant !== undefined)
                {
                    return `${item.cpn}-${item.cpnVariant}`;
                }
                if(splittedCpn.length === 3 && item.cpnVariant === "00")
                {
                    return `${splittedCpn[0]}-${splittedCpn[1]}`;
                }
            }
            if(item.alias === 'prd')
            {
                if(splittedCpn.length === 2 && item.cpnVariant !== undefined)
                {
                    return `${item.cpn}-${item.cpnVariant}`;
                }
            }
        }
        else if(window.__conditional01Variant())
        {
            if(item.alias === 'cmp' || item.alias === 'prd')
            {
                if(splittedCpn.length === 2 && item.variantGroup) {
                    return `${item.cpn}-${item.cpnVariant}`;
                }
                else if(splittedCpn.length === 3 && item.variantGroup) {
                    return `${splittedCpn[0]}-${splittedCpn[1]}-${splittedCpn[2]}`;
                }
                else {
                    return `${splittedCpn[0]}-${splittedCpn[1]}`;
                }
            }
        }
        else if(window.__companyIs11DigitCpnType() && splittedCpn.length < 4)
        {
            return `${item.cpn}-${item.cpnVariant}`;
        }
        else if(window.__companyIs10DigitCpnType() && splittedCpn.length < 3)
        {
            return `${item.cpn}-${item.cpnVariant}`;
        }
        else if(window.__with6DigitPrefixAndCounter() && splittedCpn.length < 2)
        {
            return `${item.cpn}-${item.cpnVariant}`;
        }
        else if (window.__libraryType === "GENERAL" && window.__cpnSchemeType === "EXTRA-TWO-DIGIT-VARIANT")
        {
            if (item && item.cpn && item.cpn.split('-').pop() !== item.cpnVariant)
            {
                return `${item.cpn}-${item.cpnVariant}`;
            }
        }
        return item?.cpn;
    }

    static isVendorCmp(vendor)
    {
        // will remove SWX and PDM from array after running migration
        return ALLOWED_VENDORS.indexOf(vendor) > -1;
    }

    static vendorTooltip(vendor){
        if(ALLOWED_VENDORS.indexOf(vendor) > -1)
        {
            let common = "Managed from";
            switch (vendor)
            {
                case "ONSHAPE":
                   return `${common} ONSHAPE`;
                case "SWX":
                case "SWX-CAD":
                case "PDM":
                case "SWX-PDM":
                    return `${common} SolidWorks`;
                case "Valispace":
                    return `${common} VALISPACE`;
                case "ALTIUM":
                    return `${common} ALTIUM`;
                }
        }
        return "";
    }

    static vendorLabel(vendor)
    {
        switch (vendor)
        {
            case "ONSHAPE":
               return "ONSHAPE";
            case "SWX":
            case "SWX-CAD":
               return "SOLIDWORKS CAD";
            case "PDM":
            case "SWX-PDM":
               return "SOLIDWORKS PDM";
            case "Valispace":
                return "VALISPACE";
            case "ALTIUM":
                return "ALTIUM";
            default:
                return "";
        }
    }

    static resizeSensor(element, callback)
    {
        let zIndex = parseInt(getComputedStyle(element));
        if(isNaN(zIndex)) { zIndex = 0; };
        zIndex--;

        let expand = document.createElement('div');
        expand.style.position = "absolute";
        expand.style.left = "0px";
        expand.style.top = "0px";
        expand.style.right = "0px";
        expand.style.bottom = "0px";
        expand.style.overflow = "hidden";
        expand.style.zIndex = zIndex;
        expand.style.visibility = "hidden";

        let expandChild = document.createElement('div');
        expandChild.style.position = "absolute";
        expandChild.style.left = "0px";
        expandChild.style.top = "0px";
        expandChild.style.width = "10000000px";
        expandChild.style.height = "10000000px";
        expand.appendChild(expandChild);

        let shrink = document.createElement('div');
        shrink.style.position = "absolute";
        shrink.style.left = "0px";
        shrink.style.top = "0px";
        shrink.style.right = "0px";
        shrink.style.bottom = "0px";
        shrink.style.overflow = "hidden";
        shrink.style.zIndex = zIndex;
        shrink.style.visibility = "hidden";

        let shrinkChild = document.createElement('div');
        shrinkChild.style.position = "absolute";
        shrinkChild.style.left = "0px";
        shrinkChild.style.top = "0px";
        shrinkChild.style.width = "200%";
        shrinkChild.style.height = "200%";
        shrink.appendChild(shrinkChild);

        element.appendChild(expand);
        element.appendChild(shrink);

        function setScroll()
        {
            expand.scrollLeft = 10000000;
            expand.scrollTop = 10000000;

            shrink.scrollLeft = 10000000;
            shrink.scrollTop = 10000000;
        };
        setScroll();

        let size = element.getBoundingClientRect();

        let currentWidth = size.width;
        let currentHeight = size.height;

        let onScroll = function()
        {
            let size = element.getBoundingClientRect();

            let newWidth = size.width;
            let newHeight = size.height;

            if(newWidth != currentWidth || newHeight != currentHeight)
            {
                currentWidth = newWidth;
                currentHeight = newHeight;

                callback();
            }

            setScroll();
        };

        expand.addEventListener('scroll', onScroll);
        shrink.addEventListener('scroll', onScroll);
    };

    static uploadDocument(url, data, progressCb, responseCb)
    {
        const form = new FormData();
        const blob = data.slice(0, data.size, data.type);
        form.append('specs', data.specs);
        form.append('file', new File([blob], encodeURI(data.name), { type: data.type }));
        let axiosInstance = axios.create({
                            baseURL: Config.API_ORIGIN,
                            timeout: 1000000000,
                            withCredentials: true,
                            responseType: 'json',
                            headers: { 'Content-Type': 'multipart/form-data', ...this.headers },
                            onUploadProgress: progressEvent => progressCb(null, progressEvent.loaded)
                        });

        axiosInstance.post(url, form)
        .then((res) =>
        {
            responseCb(null, res.data);
        })
        .catch((error) =>
        {
            responseCb(error.response.data, null);
        });
    }

    static getDocument(id, cb)
    {
        API.documents.findById(id, cb);
    }

    static updateDocProgress(currentProgress, startTime, document, cb, isThumbnail=false)
    {
        let size    =  isThumbnail ? document.sizeInBytes : document.size;
        let time    = new Date()/1000 - startTime;

        let speed   = currentProgress / time;
        let timeRemaining = ((size - currentProgress) / speed);
        document.info.isBeingUploaded = true;
        if(timeRemaining >= 90)
        {
            document.info.errors = [`${(timeRemaining/60).toFixed(0)} minutes left...`];
        }
        else if(timeRemaining < 90 && timeRemaining > 60)
        {
            document.info.errors = [`${(timeRemaining/60).toFixed(0)} minute left...`];
        }
        else if(timeRemaining <= 60 && timeRemaining > 30)
        {
            document.info.errors = ["Less than 1 minute left..."];
        }
        else if(timeRemaining <= 30 && timeRemaining > 10)
        {
            document.info.errors = ["Less than 30 seconds left..."];
        }
        else if(timeRemaining <= 10 && timeRemaining > 1)
        {
            document.info.errors = [`${timeRemaining.toFixed(0)} seconds left...`];
        }
        else
        {
            document.info.errors = "Finalizing...";
        }
        let progressSoFar = Math.round( (currentProgress * 100) / size);
        let fillRule = (progressSoFar - 0) * (1037 - 1000) / (100 - 0) + 1000;
        cb(fillRule);
    }

    static setLocalStorageForAssemblyTree(id)
    {
        if (Utils.getStore("lastAssemblyParent") !== id)
        {
            Utils.setStore("lastAssemblyParent", null);
            Utils.setStore("lastAssemblyTree", null);
        }
    };

    static isExpired(timeInMs, expiryHours=24)
    {
        let expiry = timeInMs + (expiryHours * 3600000)
        return expiry < Date.now() ? true : false
    }

    static redirectToParentRoute(parentRoute=null)
    {
        if (parentRoute && typeof parentRoute  === "object" && parentRoute.hasOwnProperty("pathname"))
        {
            window.location.replace(parentRoute.pathname);
        }
        else if (parentRoute && typeof parentRoute  === "string")
        {
            window.location.replace(parentRoute);
        }
        else
        {
            window.location.replace("/dashboard");
        }
    }

    static getToolTipText(status, validations, type)
    {
        let modelName = type === "cmp" ? "component" : "product"
        let payload = {status: status, revSchemeType: window.__revSchemeType, libraryType: window.__libraryType}
        let revValue = validations[modelName].revision.getAllowedRevisionValues(payload)
        return revValue
    }

    static shouldDisplayOnlyItemNumber(category, displayRefDesAndItemNumber=false)
    {
        let getCategoryType = Schemas.component.category.getType(category)
        let isAssembly = getCategoryType && getCategoryType.toLowerCase() === "assembly";
        let restrictedCategories = ["PCBA", "EBOM", "Printed Circuit Board Assembly", "Sub-Assembly", "Top Level Assembly", "Cable Assembly", "Electrical Assembly", "Sub Assembly", "Orbit Fab Assembly", "Orbit Fab Printed Wiring Assembly"];

        if (displayRefDesAndItemNumber!== true && isAssembly && !restrictedCategories.includes(category) || category === "Product")
        {
            return true;
        }
        return false; //Should display both Ref Des and Item Number
    }

    static isItemNumber(category)
    {
        let getCategoryType = Schemas.component.category.getType(category)
        let isAssembly = getCategoryType && getCategoryType.toLowerCase() === "assembly";
        if (isAssembly || category === "Product")
        {
            return true;
        }
        return false;
    }

    static isRefDesORItemNumberColumn(defaultSortColumnName)
    {
        let refDesAndItemNumberColumns = ["refDes", "itemNumber"];
        if (defaultSortColumnName && refDesAndItemNumberColumns.includes(defaultSortColumnName))
        {
            return true;
        }
        return false;
    }

    static isIntegerValue(value)
    {
        let numericRegex = /^\+?(0|[1-9]\d*)$/
        if (numericRegex.test(value))
        {
            return true;
        }
        else if (!!Number(value))
        {
            return parseFloat(value) % 1 === 0 && value.indexOf(".") === -1;
        }
    }

    static getLocalDate(hours)
    {
        hours          = hours || 0;
        let utc        = String(new Date().toUTCString().slice(0, 25));
        let utcDate    = new Date(Date.parse(utc));
        let dateString = utcDate.setHours(utcDate.getHours() - (hours));
        let date       = new Date(dateString);
        return String(`${date.getFullYear()}${Utils.twoDigitFormat(date.getMonth() + 1)}${Utils.twoDigitFormat(date.getDate())}-${Utils.twoDigitFormat(date.getHours())}${Utils.twoDigitFormat(date.getMinutes())}`);
    }

    static twoDigitFormat(value)
    {
        return value < 10 ? '0' + value : value;
    }

    static getStatusesList(currentStatus)
    {
        let designDisabled, prototypeDisabled, productionDisabled, obsoleteDisabled
        switch(currentStatus.toUpperCase())
        {
            case "PROTOTYPE" :
            {
                designDisabled     = true
                prototypeDisabled  = false
                productionDisabled = false
                obsoleteDisabled   = false
                break
            }

            case "PRODUCTION" :
            {
                designDisabled     = true
                prototypeDisabled  = true
                productionDisabled = false
                obsoleteDisabled   = false
                break
            }

            case "OBSOLETE" :
            {
                designDisabled     = true
                prototypeDisabled  = true
                productionDisabled = true
                obsoleteDisabled   = false
                break
            }

            case "DESIGN" :
            default :
            {
                designDisabled     = false
                prototypeDisabled  = false
                productionDisabled = false
                obsoleteDisabled   = false
            }
        }
        let list =
        [
            {
                value: "DESIGN",
                displayName: "DESIGN",
                disabled: designDisabled,
                rank: 0
            },
            {
                value: "PROTOTYPE",
                displayName: "PROTOTYPE",
                disabled: prototypeDisabled,
                rank: 1

            },
            {
                value: "PRODUCTION",
                displayName: "PRODUCTION",
                disabled: productionDisabled,
                rank: 2

            },
            {
                value: "OBSOLETE",
                displayName: "OBSOLETE",
                disabled: obsoleteDisabled,
                rank: 3
            }
        ]
        return list
    }

    static getUnitOfMeasuresList()
    {

        let list =
        [
            {
                value: "EACH",
                displayName: "EACH",
            },
            {
                value: "INCHES",
                displayName: "INCHES",
            },
            {
                value: "FEET",
                displayName: "FEET",
            },
            {
                value: "YARDS",
                displayName: "YARDS",
            },
            {
                value: "MILLIMETERS",
                displayName: "MILLIMETERS",
            },
            {
                value: "CENTIMETERS",
                displayName: "CENTIMETERS",
            },
            {
                value: "METERS",
                displayName: "METERS",
            },
            {
                value: "KILOMETERS",
                displayName: "KILOMETERS",
            },
            {
                value: "OUNCES",
                displayName: "OUNCES",
            },
            {
                value: "MILLIGRAMS",
                displayName: "MILLIGRAMS",
            },
            {
                value: "GRAMS",
                displayName: "GRAMS",
            },
            {
                value: "KILOGRAMS",
                displayName: "KILOGRAMS",
            },
            {
                value: "POUNDS",
                displayName: "POUNDS",
            },
            {
                value: "DAYS",
                displayName: "DAYS",
            },
            {
                value: "GALLONS",
                displayName: "GALLONS",
            },
            {
                value: "LITERS",
                displayName: "LITERS",
            },
            {
                value: "HOURS",
                displayName: "HOURS",
            },
            {
                value: "PACKAGE",
                displayName: "PACKAGE",
            }

        ];

        if(window.__customUomLabels && window.__customUomLabels.length)
        {
            for(let customUomLabel of window.__customUomLabels)
            {
                list.push({ value: customUomLabel, displayName: customUomLabel });
            }
        }

        return list;
    }

    static getStatusRank(status)
    {
        let list = Utils.getStatusesList("DESIGN")
        let rank = 0
        switch(status)
        {

            case "DESIGN" :
            {
                rank = list[0].rank
                break
            }

            case "PROTOTYPE" :
            {
                rank = list[1].rank
                break
            }
            case "PRODUCTION" :
            {
                rank = list[2].rank
                break
            }
            case "OBSOLETE" :
            {
                rank = list[3].rank
                break
            }
            default :
            {
                rank = list[0].rank
            }
        }
        return rank
    }

    static isRevisionTypeAlphaNumbericXYZ(value)
    {
        return Config.revisionTypes.alphaNumericXYZ === window.__revSchemeType
    }

    static isRevisionTypeNumericAlphaXY(value) {
        return Config.revisionTypes.numericAlphaXY === window.__revSchemeType
    }

    static isRevisionTypeAlphaNumbericXY(value)
    {
        return Config.revisionTypes.alphaNumericXY === window.__revSchemeType
    }

    static isRevisionTypeDefault()
    {
        return (window.__revSchemeType === "DEFAULT" || window.__libraryType === "PERSONAL")
    }

    static createBatches(list, batchSize=5)
    {
        let i, j, temp, batchesArray=[], elementsCount = 0
        for (i=0;  elementsCount < list.length; i+=batchSize)
        {
            temp = list.slice(i,i+batchSize)
            batchesArray.push(temp)
            elementsCount = elementsCount + temp.length
        }
        return batchesArray
    }

    static viewRefExist(component)
    {
        let element = component.eid;
        if(component.cadMetadata) {
            let ref =   (component.cadMetadata.onshape) ?
                            component.cadMetadata.onshape.viewHref :
                        (component.cadMetadata.valispace) ?
                            component.cadMetadata.valispace.viewHref :
                        null;
            if (ref) {
                element =
                    <a className={''} href={ref} target='_blank'>
                        {component.eid}
                        <InlineIcon className="co-link-icon">
                            <LinkIcon/>
                        </InlineIcon>
                    </a>;
            }
        }
        return element;
    }

    static filterDocuments(documents, vendor)
    {
        let response = [];
        if(vendor && vendor.toUpperCase() === 'ONSHAPE')
        {
            response = documents.filter((document) =>
            {
                if(document.file && typeof(document.file) !== 'string')
                {
                    return document.file.visibility;
                }
            });
        }
        else
        {
            response = documents;
        }
        return response;
    }

    static validateForecastDates(inputs, input)
    {
        function performValidation(inputs, index)
        {
            let length = inputs.forecasts.length;
            let leftSide = false;
            let rightSide = false;
            for(let i = index - 1; i >= 0; i--)
            {
                if(!!inputs.forecasts[i].targetDate.value && !!inputs.forecasts[index].targetDate.value && inputs.forecasts[i].targetDate.value >= inputs.forecasts[index].targetDate.value)
                {
                    leftSide = true;
                    break
                }
                else if(!!inputs.forecasts[i].targetDate.value)
                {
                    break;
                }
            }
            for(let i = index+1; i < length; i++)
            {
                if(!!inputs.forecasts[i].targetDate.value && !!inputs.forecasts[index].targetDate.value && inputs.forecasts[i].targetDate.value <= inputs.forecasts[index].targetDate.value)
                {
                    rightSide = true;
                    break
                }
                else if(!!inputs.forecasts[i].targetDate.value)
                {
                    break;
                }
            }
            if(leftSide || rightSide)
            {
                inputs.forecasts[index].targetDate.valid = false
                inputs.forecasts[index].targetDate.class = "invalid"
                inputs.forecasts[index].targetDate.message = "All dates must be in chronological orders"
            }
            else if(!leftSide && !rightSide)
            {
                inputs.forecasts[index].targetDate.valid = true
                inputs.forecasts[index].targetDate.class = "valid"
                inputs.forecasts[index].targetDate.message = ""
            }
        }
        let index = inputs.forecasts.indexOf(input);
        let length = inputs.forecasts.length;
        if (!input.targetDate.value)
        {
            input.targetDate.valid = true
            input.targetDate.class = "valid"
            input.targetDate.message = ""
        }
        performValidation(inputs, index);
        let previous = index -1;
        let next = index + 1;
        while(previous >=0 && !inputs.forecasts[previous].targetDate.value)
        {
            previous--;
        }
        if(previous >= 0)
        {
            performValidation(inputs, previous);
        }
        while(next < length && !inputs.forecasts[next].targetDate.value)
        {
            next++;
        }
        if(next < length)
        {
            performValidation(inputs, next);
        }
    }

    static getDefaultExportTemplate()
    {
        let isVendor = window.__userRole === "VENDOR";
        let companyDocTypes = window.__allowedDocTypes;
        function documentTypes()
        {
           let types = companyDocTypes || ["GENERIC", "DATASHEET", "PRODUCT LITERATURE", "GERBER", "SCHEMATIC DRAWING", "BOARD FILE", "FAB DRAWING", "CAD", "STP", "DIMENSIONS DRAWING", "SPECIFICATION", "CERTIFICATION", "PROCEDURE", "ASSEMBLY INSTRUCTIONS", "TEST PLAN", "TEST RESULT",
                        "QUOTE", "ARTWORK", "DRAWING", "FORM", "INSTRUCTIONS", "DWG"];
            let docTypes = [];
            types.forEach((type) => {
                docTypes.push({name: type, isChecked: true});
            });
            docTypes.unshift({name:"THUMBNAIL IMAGES", isChecked:true})
            return docTypes;
        }

        function mappedHeaders()
        {
            let headers = [
                            {name: "Level", isChecked: true},
                            {name:"CPN", isChecked:true},
                            {name:"Category", isChecked:true},
                            {name:"Name", isChecked:true},
                            {name:"Where Used", isChecked:true},
                            {name:"EID", isChecked:true},
                            {name:"Revision", isChecked:true},
                            {name:"Status", isChecked:true},
                            {name:"Description", isChecked:true},
                            {name:"Value", isChecked:true},
                            {name:"Specs", isChecked:true},
                            {name:"Quantity", isChecked:true},
                            {name:"Unit of Measure", isChecked:true},
                            {name:"Primary Source Unit Price", isChecked:true},
                            {name:"Primary Source MPN", isChecked:true},
                            {name:"Primary Source Manufacturer", isChecked:true},
                            {name:"Primary Source DPN", isChecked:true},
                            {name:"Primary Source Distributor", isChecked:true},
                            {name: "Total Price", isChecked: true},
                            {name:"Primary Source Lead Time", isChecked:true},
                            {name:"Ref Des", isChecked:true},
                            {name:"Item Number", isChecked:true},
                            {name:"Notes", isChecked:true},
                            {name:`Mass (${Utils.getCompanyMassUnit()})`, isChecked: true},
                            {name:"Last Updated", isChecked:true},
                            {name:"Workflow State", isChecked:true},
                            {name:"Procurement", isChecked:true},
                            {name:"Manufacturer", isChecked:true},
                            {name:"MPN", isChecked:true},
                            {name:"MPN Link", isChecked: true},
                            {name:"Datasheet", isChecked: true},
                            {name:"Mfr Description", isChecked:true},
                            {name:"Distributor", isChecked:true},
                            {name:"DPN", isChecked:true},
                            {name:"DPN Link", isChecked:true},
                            {name:"Dist Description", isChecked:true},
                            {name: "Package", isChecked: true},
                            {name: "Package Quantity", isChecked: true},
                            {name:"Quantity Min", isChecked:true},
                            {name:"Unit Price", isChecked:true},
                            {name:"Lead Time", isChecked:true},
                            {name:"Item Type", isChecked:true},
                            {name:"Effectivity Start Date", isChecked:true},
                            {name:"Effectivity End Date", isChecked:true}
                        ];

            let sourcingData = ["Primary Source Unit Price","Primary Source MPN", "Primary Source Manufacturer", "Primary Source DPN", "Primary Source Distributor", "Total Price", "Primary Source Lead Time", "Manufacturer", "MPN", "MPN Link", "Datasheet", "Mfr Description", "Distributor", "DPN", "DPN Link", "Dist Description", "Package", "Package Quantity", "Quantity Min", "Unit Price", "Lead Time"];
            if(isVendor)
            {
                for(let i = 0; i < headers.length ; i++)
                {
                    if(sourcingData.includes(headers[i].name))
                    {
                        headers.splice(i, 1);
                        i -= 1;
                    }
                }
            }
            let mappedHeaders = {};

            if(window.__customFields)
            {

                if(window.__customFields.wasteFieldEnabled)
                {
                    let qtyIndex = headers.map(i => i.name).indexOf("Quantity");
                    headers.splice( ++qtyIndex, 0, {name:"Waste %", isChecked:true});
                    headers.splice( ++qtyIndex, 0, {name:"Extended Quantity", isChecked:true});
                    headers.splice( ++qtyIndex, 0, {name:"Extended Cost", isChecked:true});
                    if(!isVendor)
                    {
                        let prmSrcUnitPriceIndex = headers.map(i => i.name).indexOf("Primary Source Unit Price");
                        headers.splice( ++prmSrcUnitPriceIndex, 0, {name:"Primary Source Extended Cost", isChecked:true});
                    }
                }
                if(!isVendor && window.__customFields.warrantyFieldEnabled)
                {
                    let index = headers.map(i => i.name).indexOf("Mfr Description");
                    headers.splice(index+1, 0, {name:"Warranty", isChecked:true});
                }
            }

            headers.forEach((header) => {
                mappedHeaders[header.userLabel ? header.userLabel : header.name ] = {isChecked: header.isChecked, userLabel:header.name};
            });

            return mappedHeaders;
        }

        function duroLabels()
        {
            return Object.keys(mappedHeaders());
        }

        function allowedDocTypes()
        {
            let allowedDocTypes = [];
            let docTypes = documentTypes();
            docTypes.forEach((type) => {
                if(type.isChecked)
                {
                    allowedDocTypes.push(type.name);
                }
            })
            return allowedDocTypes;
        }
        let isSourcingEnabled = isVendor ? false : true;

        return {
                "_id": "",
                "docTypes": documentTypes(),
                "sourcing":
                {
                    "includeQuotes": isSourcingEnabled,
                    "includeDistributors": isSourcingEnabled,
                    "includeManufacturers": isSourcingEnabled,
                    "includePrimarySource": isSourcingEnabled
                },
                "duroLabels": duroLabels(),
                "mappedHeaders": mappedHeaders(),
                "includeDocuments": false,
                "exportType": "email",
                "email": ["abc@snf.com"],
                "assembly": "shallow",
                "assemblyLevel": "indented",
                "allowedDocTypes": allowedDocTypes(),
                "templateName": "Default Settings",
                "revisionType": "latestInProgress"
            };
    }

    static updatePullRequestProperty(children)
    {
        for(let cmp of children)
        {
            if(cmp.newlyAdded)
            {
                cmp.isAddedAfterPullRequest = true;
            }
        }
    }

    static isVendorDoc(vendor)
    {
        let vendors = ['MCAD','Valispace'];
        if(vendors.includes(vendor)) return true;
        return false;
    }

    static convertSecToMinAndHours(time)
    {
        if(time > 60 && time < 3600)
        {
            let min = Math.round(time/60);
            return (`${min}${min < 2 ? " minute" : " minutes"}`);
        }
        else if(time > 3600)
        {
            let hours = Math.round(time/3600);
            return (`${hours}${hours < 2 ? " hour" : " hours"}`);
        }
        let sec = Math.round(time);
        return (`${sec}${sec < 2 ? " second" : " seconds"}`);
    }

    static getProcurementList()
    {

        let list =
        [
            {
                value: "",
                displayName: "",
            },
            {
                value: "N/A",
                displayName: "N/A",
            },
            {
                value: "Make",
                displayName: "Make",
            },
            {
                value: "Buy",
                displayName: "Buy",
            }
        ]
        return list
    }

    static calculateAssemblyErrors(childrens)
    {
        let assemblyErrors = 0;
        let keys = ["itemNumber", "notes", "quantity", "refDes", "waste"];
        childrens.forEach((children) => {
            for(let i=0; i<keys.length;i++)
            {
                if(children.inputs)
                {
                    if(children.inputs[keys[i]].valid === false)
                    {
                        assemblyErrors++;
                    }
                }
            }
        })
        return assemblyErrors;
    }

    static getMimeType(extension)
    {
        let mimeType = "";
        switch(extension)
        {
            case ".csv":
                mimeType = "text/csv";
                break;

            case ".doc":
                mimeType = "application/msword";
                break;

            case ".docx":
                mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
                break;

            case ".zip":
                mimeType = "application/zip";
                break;

            case ".html":
            case ".htm":
                mimeType = "text/html";
                break;

            case ".jpeg":
            case ".jgp":
                mimeType = "image/jpeg";
                break;

            case ".json":
                mimeType = "application/json";
                break;

            case ".png":
                mimeType = "image/png";
                break;

            case ".txt":
                mimeType = "text/plain";
                break;

            case ".xls":
                mimeType = "application/vnd.ms-excel";
                break;

            case ".xlsx":
                mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                break;

            case ".sldprt":
                mimeType = "application/octet-stream";
                break;

            default:
                mimeType = "application/pdf";
                break;
        }

        return mimeType;
    }

    static setNestedChildModificationCounter(root, parent, mode)
    {
        if (root.children && root.children.length > 0)
        {
            root.children.forEach((c) =>
            {
                if (mode === "revision")
                {
                    if (typeof(c.assemblyRevision) == "object")
                    {
                        let res = this.setNestedChildModificationCounter(c.assemblyRevision, c, mode)
                        let component = c.assemblyRevision;
                        component.childRemoved = Number(component.childRemoved || 0)
                        component.childAdded = Number(component.childAdded || 0)
                        component.childUpdated = Number(component.childUpdated || 0)

                        component.aggchildRemoved = Number(component.childRemoved || 0) + Number(res.aggchildRemoved || 0)
                        component.aggchildAdded = Number(component.childAdded || 0) + Number(res.aggchildAdded || 0)
                        component.aggchildUpdated = Number(component.childUpdated || 0) + Number(res.aggchildUpdated || 0)
                    }
                }
            })
        }

        let childRemoved = 0
        let childAdded = 0
        let childUpdated = 0

        root.children && root.children.forEach((c) =>
        {
            if (mode === "revision")
            {
                if (typeof(c.assemblyRevision) == "object")
                {
                    let component = c.assemblyRevision;

                    childRemoved = childRemoved + Number(component.aggchildRemoved || 0)
                    childAdded = childAdded + Number(component.aggchildAdded || 0)
                    childUpdated = childUpdated + Number(component.aggchildUpdated || 0)
                }
            }
        })

        return {aggchildRemoved: childRemoved, aggchildAdded: childAdded, aggchildUpdated: childUpdated}
    }

    static compareChildsRecursively(cmp)
    {
        for(let child of cmp.children)
        {
            if(child.assemblyRevision.children.length)
            {
                let {sourceRevChildren, targetRevChildren} = child.assemblyRevision
                if(sourceRevChildren && targetRevChildren)
                {
                    let result = Utils.compareSouceAndTargetChilds(child.assemblyRevision.sourceRevChildren, child.assemblyRevision.targetRevChildren, child.assemblyRevision)
                    result.children = Utils.sortComponents("assemblyRevision.cpn", result.children)
                    child.assemblyRevision.children = result.children;
                    child.assemblyRevision = result.parentCmp;
                    Utils.compareChildsRecursively(child.assemblyRevision)
                }
                else
                {
                    child.assemblyRevision.children = [];
                }
            }
        }
    }

    static compareSouceAndTargetChilds(sourceChildren, targetChildren, parentCmp)
    {
        let sourceAndTargetChildren = []
        let sourceAndTargetChildrenCPNs = []
        let childAdded = 0
        let childRemoved = 0
        let childUpdated = 0

        for(let child of sourceChildren) {
           if (child.assemblyRevision && !sourceAndTargetChildrenCPNs.includes(child.assemblyRevision.cpn))
           {
                sourceAndTargetChildrenCPNs.push(child.assemblyRevision.cpn)
                sourceAndTargetChildren.push(child)
           }
        }

        for(let child of targetChildren) {
           if (child.assemblyRevision && !sourceAndTargetChildrenCPNs.includes(child.assemblyRevision.cpn))
           {
                sourceAndTargetChildrenCPNs.push(child.assemblyRevision.cpn)
                sourceAndTargetChildren.push(child)
           }
        }
        for(let sourceAndTargetchild of sourceAndTargetChildren) {

            let isPresentInSourceRev = Utils.getMatchedAssembly(sourceChildren, sourceAndTargetchild.assemblyRevision.cpn).found
            let isPresentInTargetRev = Utils.getMatchedAssembly(targetChildren, sourceAndTargetchild.assemblyRevision.cpn).found

            if (isPresentInSourceRev && !isPresentInTargetRev)
            {
                sourceAndTargetchild.rowClassName = "add"
                childAdded++
            }

            else if (!isPresentInSourceRev && isPresentInTargetRev)
            {
                sourceAndTargetchild.rowClassName = "remove"
                childRemoved++
            }

            else if (isPresentInSourceRev && isPresentInTargetRev)
            {
                let sourceRevChild = Utils.getMatchedAssembly(sourceChildren, sourceAndTargetchild.assemblyRevision.cpn).child
                let targetRevChild = Utils.getMatchedAssembly(targetChildren, sourceAndTargetchild.assemblyRevision.cpn).child
                let sourceRevChildrenString = `${sourceRevChild.itemNumber || ""} ${sourceRevChild.notes || ""} ${sourceRevChild.quantity} ${sourceRevChild.assemblyRevision.revision}${sourceRevChild.assemblyRevision.modified && sourceRevChild.assemblyRevision.revisionType !== 'coRevision'? '*' : ''}`
                let targetRevChildrenString = `${targetRevChild.itemNumber || ""} ${targetRevChild.notes || ""} ${targetRevChild.quantity} ${targetRevChild.assemblyRevision.revision}${targetRevChild.assemblyRevision.modified && targetRevChild.assemblyRevision.revisionType !== 'coRevision'? '*' : ''}`
                let diff = jsdiff.diffWords(targetRevChildrenString, sourceRevChildrenString);
                for(let part of diff)
                {
                    if (part.removed || part.added)
                    {
                        sourceAndTargetchild.rowClassName = "update"
                    }
                }

                if (sourceAndTargetchild.rowClassName === "update")
                {
                    Utils.compareAssemblyChildrenAttributes(targetRevChild, sourceRevChild)
                }
                Utils.compareAssemblyChildrenRefDes(targetRevChild, sourceRevChild)
                if (sourceRevChild.rowClassName === "update") childUpdated++
            }
        }
        sourceChildren =  sourceAndTargetChildren
        parentCmp.childAdded = Number(childAdded)
        parentCmp.childRemoved = Number(childRemoved)
        parentCmp.childUpdated = Number(childUpdated)
        return {children: sourceChildren, parentCmp: parentCmp}
    }

    static getMatchedAssembly(children, childCpn)
    {
        let child = null;
        for(let i = 0; i < children.length; i++) {

            if (children[i].assemblyRevision && childCpn === children[i].assemblyRevision.cpn)
            {
                child = children[i];
                break;
            }
        }
        return {child: child, found: !!child}
    }

    static compareAssemblyChildrenAttributes(targetRevChild, sourceRevChild)
    {
        let attributes = ["itemNumber", "quantity", "notes", "revision"]
        if (sourceRevChild && targetRevChild)
        {
            for(let i = 0; i < attributes.length; i++) {
                let targetString = targetRevChild[attributes[i]] && targetRevChild[attributes[i]].toString() || ""
                let sourceString = sourceRevChild[attributes[i]] && sourceRevChild[attributes[i]].toString() || ""

                if(attributes[i] === 'revision' && sourceRevChild.rowClassName === 'update')
                {
                    targetString = `${targetRevChild.assemblyRevision.revision}${targetRevChild.assemblyRevision.modified && targetRevChild.assemblyRevision.revisionType !== 'coRevision'? '*' : ''}`;
                    sourceString = `${sourceRevChild.assemblyRevision.revision}${sourceRevChild.assemblyRevision.modified && sourceRevChild.assemblyRevision.revisionType !== 'coRevision'? '*' : ''}`;
                    sourceRevChild.targetRevData = {
                        revisionType: targetRevChild.assemblyRevision.revisionType,
                        modified    : targetRevChild.assemblyRevision.modified
                    }
                }

                if (attributes[i] === "quantity" && targetRevChild[attributes[i]] === 0)
                {
                    targetString = `0`
                }
                else if (attributes[i] === "quantity" && sourceRevChild[attributes[i]] === 0)
                {
                    sourceString = `0`
                }

                let diffAttribute = jsdiff.diffLines(targetString, sourceString);
                diffAttribute.forEach((part) => {
                    part.value = attributes[i] === 'revision' ? part.value.replace('*', '') : part.value;
                    if (part.removed)
                    {
                        sourceRevChild[`removed${attributes[i].charAt(0).toUpperCase()}${attributes[i].slice(1)}`] = part.value
                    }
                    else if (part.added)
                    {
                        sourceRevChild[`added${attributes[i].charAt(0).toUpperCase()}${attributes[i].slice(1)}`] = part.value
                    }
                })
            }
        }
    }

    static compareAssemblyChildrenRefDes(targetRevChild, sourceRevChild)
    {
        let attributes = ["refDes"]
        let refDesBlock = null
        if (sourceRevChild && targetRevChild)
        {
            for(let i = 0; i < attributes.length; i++) {

                let targetRefDesString = targetRevChild[attributes[i]] && targetRevChild[attributes[i]].toString() || ""
                let sourceRefDesString = sourceRevChild[attributes[i]] && sourceRevChild[attributes[i]].toString() || ""

                sourceRefDesString = sourceRefDesString.replace(/[,:;]/g, ' ').replace(/\s\s+/g, ' ');
                targetRefDesString = targetRefDesString.replace(/[,:;]/g, ' ').replace(/\s\s+/g, ' ');
                let diffRefDes = jsdiff.diffWords(targetRefDesString, sourceRefDesString);

                let counter = 0
                refDesBlock = diffRefDes.map((part, i) => {
                    counter++

                    if (part.added || part.removed)
                    {
                        sourceRevChild.rowClassName = "update"
                    }

                    let diffClass = part.added ? "add" : part.removed ? "remove" : "no-change"
                    let isLastPart = counter === diffRefDes.length

                    let value = part.value
                    value = value.trim().replace(/ /g, ',')

                    let dispalyClass = value ? "" : "hidden"

                    value = diffClass === "add" || diffClass === "remove" ? value : isLastPart ? value : `${value},`
                    let block  =
                        (diffClass === "add" || diffClass === "remove") && !isLastPart ?
                        <span>
                        <span key={`ref-des-${i}`} className={`ref-des-check ${diffClass} ${dispalyClass}`}>
                            {value}
                        </span>
                        <span className="comma-span">,</span></span> :
                        <span key={`ref-des-${i}`} className={`ref-des-check ${diffClass} ${dispalyClass}`}>
                            {value}
                        </span>
                    return block
                })
            }
            sourceRevChild.refDesBlock = refDesBlock
        }
    }

    static getCpnTag(categoryObject, cpnRules, cpnVariant=true) {
        let tag = ''
        if (cpnRules && Object.keys(cpnRules).length) {
            const {
                prefixLength, prefixDelimiter, counterLength, counterDelimiter, variantLength
            } = cpnRules;

            const prefix = categoryObject?.code || 'X'.repeat(prefixLength);
            const counter = 'X'.repeat(counterLength);
            const variant = 'X'.repeat(variantLength);
            tag = `${prefix}${prefixDelimiter}${counter}`
            if (variantLength) {
                tag = `${tag}${counterDelimiter}${variant}`
            }
            return tag;
        }

        // For existing legacy cpnType
        if(window.__nonIntelligentCPN()) {
            tag = `${tag.padStart(window.__nonIntelligent.counterLength,'X')}`
            if(window.__cpnSchemeType === 'EXTRA-TWO-DIGIT-VARIANT' && cpnVariant) {
                tag = `${tag}-XX`
            }
        } else {
            tag = `${categoryObject?.code ?? "XXX"}-XXXXX`
        }
        return tag;
    }

    static isNonIntelligentCPNScheme()
    {
        return !window.__isIntelligentCpnScheme && window.__libraryType === "GENERAL";
    }

    static getLastReleasedRevision(list)
    {
        let revs = [...list].reverse();
        for(let rev of revs)
        {
            let isRejectedOrNoneCoRevision = rev.co && ['REJECTED', 'NONE'].includes(rev.co.resolution);
            if((!rev.modified || rev.revisionType === "coRevision" || rev.revisionType === "releaseRevision") && !isRejectedOrNoneCoRevision)
            {

                return rev
            }
            else
            {
                rev.subRevisions.reverse();
                for(let subRev of rev.subRevisions)
                {
                    if(!subRev.modified || subRev.revisionType === "coRevision" || subRev.revisionType === "releaseRevision")
                    {
                        return subRev;
                    }
                }
            }
        }
    }

    static extractDocumentTypeFromFileName(fileName) {
        const fileTypes = {
            "x_t": "PARASOLID",
            "step": "STEP",
            "pdf": "DRAWING",
            "stl": "STL",
            "dxf": "DXF",
        };

        if (!fileName) return;
        let fileType = fileName.split(".")
        fileType = fileType.length > 0 ? fileType[fileType.length - 1] : "";
        return fileTypes[fileType] ?? "";
    }

    static fileCanBeRegenerated(parentVendor, file)
    {
      if(!file) return;
      return ["ERROR", "PROCESSING"].includes(file.status);
    }

    static extractId(obj)
    {
        if(typeof obj === "string")
        {
            return obj;
        }

        if(typeof obj === "object")
        {
            return obj._id;
        }
    }

    static getMatchedDocument(documents, docId)
    {
        let doc = null;
        for(let i = 0; i < documents.length; i++) {
            let fileId = documents[i].file ? documents[i].file._id : documents[i]._id;
            if (docId === fileId)
            {
                doc = documents[i];
                break;
            }
        }
        return {doc, found: !!doc};
    }

    static initPusher()
    {
        if(Config && Config.PUSHER_KEY_ID)
        {
            return new Pusher(Config.PUSHER_KEY_ID, {
                cluster: Config.PUSHER_CLUSTER,
                encrypted: true
            });
        }
    }

    static getRevisionHeadingDisplayName(isAnyChildModified)
    {
        let markup = "Revision";

        if(isAnyChildModified)
        {
            markup =
                <span className="rolled-up-modified-header">
                    Revision
                    <InlineIcon
                        tooltip="1 or more child components have been modified"
                    >
                      <RedTriangleIcon />
                    </InlineIcon>
                </span>
        }

        return markup
    }

    static setManufacturersValidationInfo(object, data)
    {
        if(data && object && data.hasOwnProperty(object._id))
        {
            let item = data[object._id];
            if (item.hasOwnProperty("error"))
            {
                object.sourcingError = item.error;
            }
            else
            {
                object.isPrimarySource = item.isPrimarySource;
            }
        }

        return object
    }

    static haveXMLFileExtension(fileName)
    {
        let acceptedExtension = 'xml';
        let haveExtension = false;

        if (fileName.toLowerCase().endsWith(acceptedExtension))
                haveExtension = true;

        return haveExtension;
    }

    static hasSpecialCharExceptHyphen(value)
    {
        let regex = /[@\_~`!#$%\^&*+=.\[\]\\';,/{}|\\":<>\?]/g
        return regex.test(value)
    }

    static getIdFromParams(params)
    {
        let id = params.id;
        if(id.includes('?'))
        {
            id = id.split('?')[0];
        }

        return id;
    }

    static getVendorLabel(doc)
    {
        if(!doc) return {vendorLabel: "", isVendorCmp: false};
        const isVendorCmp = Utils.isVendorCmp(Utils.getVendor(doc));
        const vendorLabel = Utils.vendorLabel(Utils.getOriginalVendor(doc))
        const isOriginallyVendorCmp = Utils.isVendorCmp(Utils.getOriginalVendor(doc))
        return {vendorLabel, isVendorCmp, isOriginallyVendorCmp};
    }

    static getCompanyMassPrecision(company)
    {
        return company && company.data && company.data.settings ? company.data.settings.massPrecisionValue : 2;
    }

    static getRoundedMass(mass , massPrecision)
    {
        let value = (!!mass || mass === 0) && Number(mass).toFixed(massPrecision);
        return value;
    }

    static getCompanyMassUnit()
    {
        let massUnit = window.__massUnit ? window.__massUnit.toUpperCase() : "";

        switch(massUnit)
        {
            case "POUNDS":
                return "Lb";
            case "KILOGRAMS":
                return "kg"
            case "GRAMS":
            default:
                return "g"
        }
    }

    static updateHeadings(headings)
    {
        for (let heading of headings)
        {
            if (heading.key === "mass")
            {
                let massText = `MASS (${Utils.getCompanyMassUnit()})`;
                heading.displayName = massText;
                heading.tooltip = massText;
                break;
            }
        }
    }

    static roundFieldValue(input, precision)
    {
        let roundedValue = input;
        let isNotNumber  = true;
        if(roundedValue || roundedValue === "0" || roundedValue >= 0)
        {
            isNotNumber = isNaN(Number(roundedValue));
            if(!isNotNumber)
            {
                roundedValue = Number(input).toFixed(precision ? precision : 2);
            }

        }
        return {roundedValue, isNotNumber};
    }

    static calculateExtendedQuantityAndCost(waste, quantity, unitPrice)
    {
        if(waste === "") waste = "0";
        let isNotNumber = waste && isNaN(waste);
        let extendedQuantity = quantity && !isNotNumber ? (quantity * (1 + (waste/100))) : quantity;
        let extendedCost = unitPrice && !isNotNumber ? (unitPrice * extendedQuantity) : 0;
        return { extendedQuantity, extendedCost };
    }

    static concatCurrencySymbol(value, symbol)
    {
        return symbol ? `${symbol}${Utils.roundFieldValue(value, 2).roundedValue}` : `$${Utils.roundFieldValue(value, 2).roundedValue}`;
    }

    static warrantyFieldUnit(value, unit) {
        if(!unit) return "";
        unit = unit.toUpperCase();
        if(value === 1)
        {
            unit = !unit.includes("YEAR") ? "MONTH" : "YEAR";
        }
        else if(value === 0 || value > 1)
        {
            unit = !unit.includes("S") ? `${unit}S` : unit;
        }
        return unit;
    }

    static getParentsForWhereUsed(items, view, cb)
    {
        let mode = view === 'revisionView' ? 'revision' : 'component';
        let keys = 'alias cpn name images quantity revision status revisions children flattenedBom cpnVariant';
        keys = mode === 'revision' ? `${keys} parent` : keys;
        API.cos.getParentAssemblies({ items, mode, keys, from:'whereUsed'}, cb);
    }

    static getPreviousRevision(item)
    {
        if (!item) return "";
        if(item.previousStatus === "DESIGN" && item.previousRevision === "")
            return  "1";
        else
            return item.previousRevision ? item.previousRevision : item.revision;
    }

    static getPreviousStatus(item, inCO=false)
    {
        if (!item) return "";
        if(inCO)
            return  item.previousStatus || item.status;
        else
            return  item.modified && item.previousStatus ? item.previousStatus : item.status;
    }

    static getRevisionBump(item, validationPayload)
    {
        API.cos.getRevisionBump({item, revSchemeType: window.__revSchemeType, libraryType: window.__libraryType, defaultBlacklistedRevisions: window.__defaultBlacklistedRevisions}, (err, res) =>
            {
                if(res)
                {
                    item.nextRevisionInput.value = res;
                    item.nextCoRevision = res;
                    validationPayload.revisionBump = true;
                }
            });
    }

    static validateRevisionFieldOnStatusChange(inputItem, validationPayload,  updatedValue=null)
    {
        let revisionValue = updatedValue ? updatedValue : inputItem.revision;
        inputItem.nextRevisionInput.value = validations.component.revision.normalize(validationPayload, revisionValue).revision;
        validationPayload.defaultBlacklistedRevisions = window.__defaultBlacklistedRevisions
        validateField(inputItem.nextRevisionInput, validations.component.revision, inputItem.nextRevisionInput.value, validationPayload);
    }

    static checkRevisionManaged(item)
    {
        return item && item.alias === "cmp" && window.__isNotRevisionManagedEnabled ? item.revisionManaged : true;
    }

    static getDataSheetLinkToDisplay(datasheet)
    {
        if(datasheet.src)
        {
            return datasheet.src;
        }
        else
        {
            return datasheet.mcmasterDocuments && datasheet.mcmasterDocuments.length ? datasheet.mcmasterDocuments[0].src : "";
        }
    }

    //Only for customUomLabels = ["IN²", "m²", "mm²", "FT²"]
    static mapUOMValues(value)
    {
        value = value.includes('2') && value.includes('M') ? value.toLowerCase() : value;
        value = value.includes('2') ? value.replace('2','²') : value;
        value = value.replace('^','');
        return value;
    }

    static getGACookies(cookieName)
    {
        let cookies = document.cookie.split(';').reduce(
            (cookies, cookie) => {
                const [name, val] = cookie.split('=').map(c => c.trim());
                cookies[name] = val;
                return cookies;
            }, {});
        return cookies[cookieName];
    }

    static displayUserRoleOptions(allowedUserRoles)
    {
      if(!Array.isArray(allowedUserRoles)) return
      return allowedUserRoles.map((item, i) => <option key={`${i}${item}`} value={item}>{`${item.charAt(0).toUpperCase()}${item.slice(1).toLowerCase()}`}</option>)
    }

    static manipulateHeadersForVendor(mappedHeaders, template)
    {
        let sourcingData = ["Primary Source Unit Price","Primary Source MPN", "Primary Source Manufacturer", "Primary Source DPN", "Primary Source Distributor", "Total Price", "Primary Source Lead Time", "Manufacturer", "MPN", "MPN Link", "Datasheet", "Mfr Description", "Distributor", "DPN", "DPN Link", "Dist Description", "Package", "Package Quantity", "Quantity Min", "Unit Price", "Lead Time"];
        if(template)
        {
            if(template.isPublic)
            {
                template.sourcing.includePrimarySource = false;
                template.sourcing.includeQuotes        = false;
                template.sourcing.includeDistributors  = false;
                template.sourcing.includeManufacturers = false;
            }
            sourcingData.forEach((item) =>
            {
                if(mappedHeaders[item])
                {
                    if(template.duroLabels)
                    {
                        let index = template.duroLabels.indexOf(item);
                        index !== -1 && template.duroLabels.splice(index, 1);
                    }
                    delete mappedHeaders[item];
                }
            });
        }
    }

    static getAllowedUserRoles(company)
    {
        let allowedUserRoles = (company && company.data && company.data.settings && company.data.settings.allowedUserRoles) ? company.data.settings.allowedUserRoles : ['ADMINISTRATOR','USER','APPROVER','REVIEWER','SUPPLIER'];
        return allowedUserRoles;
    }

    static vendorComponentEditClass(vendor)
    {
        if(vendor === "VALISPACE")
            return " valispace-cmp-edit"
        else
            return " vendor-component-edit"
    }

    static getLastModifiedBy(item)
    {
        return item.lastModifiedBy ? item.lastModifiedBy : `${item.creator.firstName} ${item.creator.lastName}`;
    }

    static getCoDefaultTemplate(){
        return {approversList: [], notifiersList: [], externalUsers: [], approvalType: "First-In", templateName: "Default Template", coType: "eco", _id: ""}
    }

    static getVendor(doc) {
        return doc && (doc.vendorInfo?.currentVendor?.at(-1) ?? doc.vendorInfo?.currentVendors?.at(-1) ?? doc.vendor);
    }

    static getOriginalVendor(doc)
    {
        if(!doc) return "";
        return (doc.vendorInfo && doc.vendorInfo.originalVendor) || doc.vendor;
    }

    static isComponentLinked(vendorInfo)
    {
        if(!vendorInfo) return;
        return vendorInfo && this.isVendorCmp(vendorInfo.currentVendor[vendorInfo.currentVendor.length-1]) && vendorInfo.isLinked;
    }

    static getItemTypeList()
    {
        if(!window.__erpItemTypeOptions) return null;
        const erpItemTypeOptions = normalizeValue(validations.company.settings.erpItemTypeOptions, window.__erpItemTypeOptions);
        const itemTypeList = erpItemTypeOptions.map((item) => ( {
                value: `${item}`,
                displayName: `${item}`,
            }));
        itemTypeList.splice(0, 0, {value:"",displayName: "Select"})
        return itemTypeList
    }

    static getInitialRevision(){
        switch (window.__revSchemeType)
        {
            case `${Config.revisionTypes.alphaNumericXYZ}`:
               return "1.0";
            case `${Config.revisionTypes.alphaNumericXY}`:
            case `${Config.revisionTypes.alphaBetaAB}`:
            case `${Config.revisionTypes.numericXY}`:
            case `${Config.revisionTypes.prototypeAlphaNumericXY}`:
                return "1";
            case `${Config.revisionTypes.numericAlphaABXY}`:
                return "0A";
            case `${Config.revisionTypes.numericAlphaXY}`:
                return "A";
            default : return "";
        }
    }
    
    static templateToBeLoaded(coRule, templates=[], coTemplatesMappings) {
        if(!coRule) return;
        if(!!coTemplatesMappings[coRule])
            return templates.find((template) => template._id === coTemplatesMappings[coRule]);
    }

    static getUpdatedLists(users, list) {
        return users.reduce((response, current) => {
            if (list.includes(current._id)) {
                current.irremovable = true
                return response.concat([current]);
            }
            else return response;
        }, []);
    }

    static coMappingExists(templateId, coTemplatesMappings) {
        if(!coTemplatesMappings) return false;
        return Object.values(coTemplatesMappings).includes(templateId)
    }

    static getCoRuleCombination(statusList, coType) {
        if(!statusList.length) return
        let selectedStatus = statusList.includes("PRODUCTION") ? "PRODUCTION" : "PROTOTYPE";
        return `${coType}${Utils.capitalizeString(selectedStatus)}`;
    }

    static splitCoRuleCombination(coRule) {
        const value = coRule.split(/(?=[A-Z])/);
        return {coType: value[0], status: value[1]};
    }

    static mandatoryComment(modal, value, type){
        let state = {}
        if(window.__isCommentMandatory){
            state = {
                commentMendatory: true,
                mode: type
            }
            modal.subHeading = 'Comments are required before submitting your decision';
        }
        validateField(modal, validations.changeOrder.history.comment, value, state);
        modal.class = "valid";
        return modal
    }
    
    static getAllowedDocTypes() {
        return window.__allowedDocTypes || [];
    }

    static isApplicabilityFieldEnabled()
    {
        return window.__isApplicabilityFieldEnabled;
    }

    static shouldDisplayCadTile(customProperties) {
        let customPropertiesCount = customProperties.length;
        if (!customPropertiesCount) return false;
        if(customPropertiesCount === 1 && customProperties[0].key === 'Applicability') return Utils.isApplicabilityFieldEnabled();
        return true;
    }

    static ErpOverrideModal(erpOptions) {
        const {effectivity, itemType} = erpOptions;
        let title, description = '';
        let showOverrideWarningModal = false;
        if (effectivity.isEnabled.checked && (!effectivity.startDate.value && !effectivity.endDate.value) && effectivity.overrideExisting.checked && 
        (itemType.isEnabled.checked && (!itemType.value.value) && itemType.overrideExisting.checked)) {
            title = 'ERP will be removed';
            description = 'Effectivity and Item Type has been set to Default. This will reset the erp options to none. If this was done in error, cancel and set new effectivity and item type.'
            showOverrideWarningModal = true;
        } else if (effectivity.isEnabled.checked && (!effectivity.startDate.value && !effectivity.endDate.value) && effectivity.overrideExisting.checked) {
            title = 'Effectivity will be removed';
            description = 'Effectivity has been set with no start and end dates. This will reset effectivity to none. If this was done in error, cancel and set new start and end dates.'
            showOverrideWarningModal = true;
        } else if (itemType.isEnabled.checked && (!itemType.value.value) && itemType.overrideExisting.checked) {
            title = 'Item type will be removed';
            description = 'Item type has been set to default. This will reset item type to none. If this was done in error, cancel and set item type from the menu options.'
            showOverrideWarningModal = true;
        }
        return { title, description, showOverrideWarningModal };
    }

    static getUserChanges(newData, oldData) {
    const {
        email,
        firstName,
        groups,
        lastName,
        phoneNumber,
        role,
        title,
    } = newData;
    const avatar = newData.avatar && Utils.extractId(newData.avatar);

    const payloadData = {
      ...(avatar && { avatarId: avatar }),
      ...(groups && { groups }),
      ...(phoneNumber && { phoneNumber }),
      ...(oldData.email !== email && { email }),
      ...(oldData.role !== role && { role }),
      title,
    };
    if (oldData.firstName !== firstName || oldData.lastName !== lastName) {
      payloadData.firstName = firstName;
      payloadData.lastName = lastName;
    }
    return payloadData;
    }

    static resetLocalStorageForAssemblyTree() {
        Utils.setStore("lastAssemblyParent", null);
        Utils.setStore("lastAssemblyTree", null);
    };


    static validateCpnVariant(args) {
        const { input, value, state: { cpnRules: { variantLength, variantStart }} } = args;
        const variantRegex = new RegExp(`^\\d{${variantLength}}$`);
        const variantEnd = padStart("", variantLength, 9);
        const variantInRange = inRange(parseInt(value), parseInt(variantStart), parseInt(variantEnd) + 1);

        if (!variantRegex.test(value) || !variantInRange) {
           input.message = `Variant should be in range [${variantStart}, ${variantEnd}]`;
           input.class = 'invalid';
           input.valid = false;
           return;
        }
        input.value = value;
        input.message = "";
        input.valid = true;
        input.class = "valid";
    }

    static selectSearchRoute(value) {
        const routeLookup = {
            "type:cmp": "/components",
            "type:component": "/components",
            "type:prd": "/products",
            "type:product": "/products",
        };
        const key = Object.keys(routeLookup).find((key) =>
            value.replace(/\s/g, "").includes(key)
        )
        return routeLookup[key] || "/search";
    }

    static getCustomSpecs = (newCategory) => {
			if (!newCategory?.customSpecs) return [];
			return newCategory.customSpecs.map(({ _id, name, isDropDown, allowedValues }) =>
				({
					allowedValues,
					isDropDown,
					key: name,
					message: "",
					specId: _id,
					valid: true,
					value: "",
				})
			)
    }
    
    static validateCustomSpecs = (category, currentSpec, newSpecValue) => {
			const {  allowedValues = [], isDropDown, name } = category.customSpecs.find(spec => spec._id === currentSpec.specId);
			let valid = true;
			if (allowedValues.length) {
				valid = allowedValues.includes(newSpecValue);
			}
			const message = valid ? "" : `Allowed values for ${name} are ${allowedValues}`;
			return { ...currentSpec, allowedValues, isDropDown,message, value: newSpecValue, valid };
    }

}

export default Utils
