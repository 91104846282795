import            "./index.css"
import React from "react"
import Utils from '../../../modules/utils'

class ToggleBtn extends React.Component
{
    constructor(props, context)
    {
        super(props, context);
        this.onClick       = this.onClick.bind(this);
    }

    onClick()
    {
        let event = Utils.getEvent(this)
        this.props.onChange && this.props.onChange(event)
    }

    render()
    {
        let clazz = "ui-toggle-btn"
        if (this.props.selected || this.props.disabled)
        {
            clazz = clazz
            + (this.props.selected ? " selected" : "")
            + (this.props.disabled ? " disabled" : "")
        }

        if (this.props.class)
        {
            clazz = clazz + " " + this.props.class
        }

        let markup =
            <div
                name={this.props.name}
                className={clazz}
                onClick={this.onClick}
                >
                <div />
            </div>
        return markup
    }
}

ToggleBtn.defaultProps =
{
    selected : true,
    disabled : false
}

export default ToggleBtn
