import React, { useMemo } from "react";
import { Box, styled } from "@mui/material";
import { ALTIUM_INFO_MAPPER } from "v1/modules/constants";
import { colorPalette } from "theme";
import TileItem from "../../tile-item";
import ItemSpecsIcon from "v1/assets/icons/item-specs-icon";

interface IAltiumInfo {
  altiumInfo: {
    altiumStatus: string,
    altiumRevision: string,
  }
}

export const AltiumSpecs = ({ altiumInfo }: IAltiumInfo) => {
  const ItemType = useMemo(() => (
    Object.keys(altiumInfo).map((altiumInput: string) => (
      <AltiumInfoContainer>
        <AltiumAttribute>{(ALTIUM_INFO_MAPPER as any)[altiumInput]}</AltiumAttribute>
        <AltiumValue>{(altiumInfo as any)[altiumInput]}</AltiumValue>
      </AltiumInfoContainer>
    ))), [altiumInfo]);

  const { altiumStatus, altiumRevision } = altiumInfo;
  if (!altiumStatus && !altiumRevision) return null;

  return (
    <TileItem title="Altium Specs" icon={<ItemSpecsIcon />}>
      {ItemType}
    </TileItem>
  );
};

const AltiumInfoContainer = styled(Box)({
  minHeight: "20px",
  marginBottom: "6px",
  lineHeight: "20px",
  justifyContent: "space-between",
});

const AltiumAttribute = styled(Box)({
  minWidth: "110px",
  color: colorPalette.taupeGray,
  textTransform: "uppercase",
});

const AltiumValue = styled(Box)({
  alignItems: "center",
});
