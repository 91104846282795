import React, { Component }      from "react"

const SvgIcon = () =>
{
    return <svg width="7px" height="7px" viewBox="0 0 7 7" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <defs></defs>
            <g id="REVISED-CHANGE-ORDERS-NEW-WORKFLOW" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="2_EDIT" transform="translate(-775.000000, -783.000000)" fill="#888888" fillRule="nonzero">
                    <g id="TABLE" transform="translate(309.000000, 576.000000)">
                        <g id="REVISION" transform="translate(445.000000, 95.000000)">
                            <path d="M24.4110169,118.867698 C24.2478814,118.691294 24.2478814,118.402635 24.4110169,118.226231 L26.4872881,115.9811 L21.4152542,115.9811 C21.1779661,115.9811 21,115.772623 21,115.532073 L21,115.483963 C21,115.227377 21.1927966,115.034937 21.4152542,115.034937 L26.4872881,115.034937 L24.4110169,112.773769 C24.2478814,112.597365 24.2478814,112.308706 24.4110169,112.132302 C24.5741525,111.955899 24.8411017,111.955899 25.0042373,112.132302 L27.9110169,115.275487 C28.029661,115.40378 28.029661,115.59622 27.9110169,115.724513 L25.0042373,118.867698 C24.8411017,119.044101 24.5741525,119.044101 24.4110169,118.867698 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
}

export default SvgIcon
