import mapActions from '../helpers/mapActions';

export default mapActions(
    [
        "SET_DIFF_STATE",
        "RESET_DIFF_STATE",
        "GET_ITEM_AND_SOURCE_AND_TARGET_REVISIONS",
        "CLEAR_TARGET_REV",
        "SET_TARGET_REV",
        "GET_TARGET_REVISION",
    ],
    "ITEM_DIFF"
)
