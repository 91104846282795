import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="76"
      height="56"
      viewBox="0 0 76 56"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-377 -970)">
          <g transform="translate(313 930)">
            <g>
              <g transform="translate(65 41)">
                <path
                  stroke="#3CD1B5"
                  d="M46.4791045 33.6731343L37.219403 24.4134328 27.9679104 33.6731343"
                ></path>
                <path stroke="#3CD1B5" d="M37.22 24.413v30.554"></path>
                <path
                  stroke="#FFF"
                  d="M58.103 13.315c-.841.002-1.681.07-2.512.205a19.061 19.061 0 00-36.94 2.668 14.9 14.9 0 00-3.875-.525C6.918 15.755.508 21.982.186 29.833c-.321 7.852 5.56 14.582 13.383 15.316h44.534a15.925 15.925 0 100-31.85v.016z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
