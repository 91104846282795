import React      from "react"

const SvgIcon = () =>
{
    return <svg width="22px" height="19px" viewBox="0 0 22 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="RESENT" transform="translate(-1162.000000, -567.000000)">
                <g id="List" transform="translate(310.000000, 517.000000)">
                    <g id="Change-Order-Items"></g>
                </g>
                <g id="LeftSideNumbers" transform="translate(310.000000, 443.000000)"></g>
                <g id="Approved" transform="translate(1162.000000, 490.000000)">
                    <g id="SENT" transform="translate(0.000000, 78.000000)">
                        <g>
                            <g>
                                <path d="M0,5.89054643 L0,16.2673483 C0,16.7707006 0.399192111,17.1578947 0.918141856,17.1578947 L16.7660687,17.1578947 C17.2850184,17.1578947 17.6842105,16.7707006 17.6842105,16.2673483 L17.6842105,5.89054643 C17.6842105,5.3871941 17.2850184,5 16.7660687,5 L0.878222645,5 C0.399192111,5 0,5.3871941 0,5.89054643 Z M1.7963645,15.3768019 L1.7963645,7.90395575 L8.22335749,12.8600402 C8.42295355,12.9761985 8.6225496,13.0149179 8.82214566,13.0149179 C9.02174171,13.0149179 9.22133777,12.937479 9.38101461,12.8213208 L15.8080076,7.86523634 L15.8080076,15.3380825 L1.7963645,15.3380825 L1.7963645,15.3768019 Z M3.31329452,6.78109286 L14.370916,6.78109286 L8.82214566,11.0015085 L3.31329452,6.78109286 Z" id="Shape" fill="#888888" fillRule="nonzero"></path>
                                <g id="Syncing" transform="translate(11.085526, 0.000000)" fillRule="nonzero">
                                    <ellipse id="Oval-2" fill="#18171D" cx="5.13861496" cy="5.08508772" rx="4.34805881" ry="4.30276653"></ellipse>
                                    <path d="M5.13861496,9.84210526 C7.97476446,9.84210526 10.2772299,7.62541489 10.2772299,4.92105263 C10.2772299,2.21669037 7.97476446,0 5.13861496,0 C2.30246546,0 0,2.20191244 0,4.92105263 C0,7.64019282 2.30246546,9.84210526 5.13861496,9.84210526 Z M3.62382449,5.04723347 L4.51518981,5.91472672 L7.45987881,3.02834008 L7.90556147,3.46997301 L4.49927257,6.81376518 L3.16222459,5.504639 L3.62382449,5.04723347 Z" id="Shape" stroke="#2A2A36" strokeWidth="0.65" fill="#888888"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
}

export default SvgIcon
