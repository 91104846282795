
import React, { Component }   from "react"

const SvgIcon = () =>
{
    return <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <desc>Created with Sketch.</desc>
                <defs></defs>
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(-893.000000, -72.000000)" fillRule="nonzero">
                        <g transform="translate(893.000000, 74.000000)">
                            <circle stroke="#3CD1B5" strokeWidth="3" opacity="0.485993304" cx="8" cy="6" r="6"></circle>
                            <path d="M0.582241238,7.09969138 C0.475946107,6.44610404 0.456073185,5.85884473 0.522622473,5.33791347 C0.58917176,4.8169822 0.636084363,4.52253336 0.66336028,4.45456695 L3.69730098,4.6255208 C3.58738762,5.09535269 3.51601069,5.47922215 3.4831702,5.77712916 C3.45032971,6.07503618 3.4995935,6.51589025 3.63096158,7.09969138 L2.09074441,7.09969138 L0.582241238,7.09969138 Z" fill="#3CD1B5" opacity="0.632868304"></path>
                        </g>
                    </g>
                </g>
            </svg>
}

export default SvgIcon
