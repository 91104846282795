import                      "./index.css"
import React, {Component}   from "react"
import InlineIcon           from "../icon/inline-icon.js"
import LoadingIcon              from "../../../assets/icons/loading"

export class CILoading extends Component
{
    render()
    {

        let position = this.props.position || 'center'
        let topInPx  = this.props.position || '0px'
        let loaderStyle = {
                            top: topInPx + 'px'
                          };
        let markup =

            <div className={"ci-loading " + position} style={loaderStyle}>
                <div className="background"></div>
                <div className="loading-block">
                    <InlineIcon>
                      <LoadingIcon />
                    </InlineIcon>
                </div>
                <div className="text-block">
                    {this.props.loadingText && <p>{this.props.loadingText}</p>}
                </div>
            </div>

        return markup
    }
}

export default CILoading
