import { Box, Icon, styled } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import BrokenImageIcon from "v1/assets/icons/broken-image-icon";

export interface ImageWithLoaderProps {
  alt?: string;
  className?: string;
  src?: string;
}

export function ImageWithLoader({ alt, className, src }: ImageWithLoaderProps) {
  const [loading, setLoading] = useState(true);

  const onLoad = useCallback(() => {
    setLoading(false);
  }, []);

  const alternateText = useMemo(() => {
    if (loading) return "loading";
    return alt ?? "";
  }, [alt, loading]);

  return (
    <>
      <StyledImage
        alt={alternateText}
        className={className}
        onLoad={onLoad}
        src={src}
      />
      {loading && (
        <PlaceholderBackground>
          <PlaceholderIcon><BrokenImageIcon /></PlaceholderIcon>
        </PlaceholderBackground>
      )}
    </>
  );
}

const StyledImage = styled("img", {
  shouldForwardProp: propName => propName !== "loading",
})(({ loading }) => ({
  display: loading ? "none" : undefined,
}));

export const PlaceholderBackground = styled(Box)(() => ({
  background: "linear-gradient(-180deg, #353540 0%, #18171D 100%)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "4px 5px",
  minWidth: "38px",
  borderRadius: "2px",
  minHeight: "28px",
}));

export const PlaceholderIcon = styled(Icon)(() => ({
  fontSize: "15px",
  "& > svg": {
    fontSize: "10px",
    height: "15px",
    width: "15px",
  },
}));
