import { styled } from "@mui/material";
import { SnackbarOrigin, SnackbarProvider } from "notistack";
import { PropsWithChildren, useMemo } from "react";
import { DefaultToast } from "./defaultToast";

const anchorOrigin: SnackbarOrigin = { vertical: "bottom", horizontal: "left" };
const autoHideDuration: number = 10000; // 10 Seconds.
const maxSnack: number = 10; // Default to 10 for now.

const ToastComponents = {
  default: DefaultToast,
  error: DefaultToast,
  info: DefaultToast,
  success: DefaultToast,
  warning: DefaultToast,
};

function ToastsProviderBase({ children, className }: PropsWithChildren<{ className?: string }>) {
  const classes = useMemo(() => ({ containerRoot: className }), [className]);
  return (
    <SnackbarProvider
      anchorOrigin={anchorOrigin}
      autoHideDuration={autoHideDuration}
      classes={classes}
      Components={ToastComponents}
      maxSnack={maxSnack}
    >
      {children}
    </SnackbarProvider>
  );
}

export const ToastsProvider = styled(ToastsProviderBase)(() => ({
  position: "fixed !important" as any, // Required to override older styles
}));
