import { FC, useCallback, useMemo, useState } from "react";
import { Box, Button, Typography, styled } from "@mui/material";
import { User } from "common/models/user";
import { colorPalette } from "theme";
import InlineIcon from "v1/components/ui/icon/inline-icon.js";
import Permissions from "v1/modules/schemas/permissions";
import LockCloseIcon from "../../../../../assets/icons/lock-close.js";
import LockOpenIcon from "../../../../../assets/icons/lock-open.js";

export const Integrations: FC<{ user: User }> = ({ user }) => {
  const [showCadSettingsModal, setShowCadSettingsModal] = useState(false);
  const toggleCadSettingsModal = useCallback(() => (
    setShowCadSettingsModal(!showCadSettingsModal)
  ), [showCadSettingsModal]);

  const { isDisabled, Icon, tooltipText } = useMemo(() => {
    const actionAllowed = Permissions.can("edit", "configurations", user);
    return {
      Icon: actionAllowed ? LockOpenIcon : LockCloseIcon,
      isDisabled: !actionAllowed,
      tooltipText: actionAllowed ? "" : "Administrator access only",
    };
  }, [user]);

  return (
    <Wrapper>
      <Header>
        <IconWrapper>
          <InlineIcon // Using this for consistency with older sections on the page
            tooltip={tooltipText}
          >
            <Icon />
          </InlineIcon>
        </IconWrapper>
        <HeadingText>
          CAD Settings
        </HeadingText>
      </Header>
      <BodyText>
        Use these settings to customize how Duro interacts <br /> with your integrated CAD tools.
      </BodyText>
      <Button variant="contained" onClick={toggleCadSettingsModal} disabled={isDisabled}>
        Set up
      </Button>
    </Wrapper>
  );
};

export const Header = styled(Box)({
  display: "flex",
});

export const IconWrapper = styled(Box)({
  left: "-1.563rem",
  marginRight: "0.438rem",
  position: "absolute",
});

export const HeadingText = styled(Typography)({
  fontSize: "0.875rem",
  paddingBottom: "0.625rem",
});

export const Wrapper = styled(Box)({
  padding: "1.5rem 0",
});

const BodyText = styled(Typography)({
  color: colorPalette.taupeGray,
  fontSize: "0.875rem",
  marginBottom: "0.938rem",
});
