import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={14} height={12} viewBox="0 0 14 12" {...props}>
      <g fillRule="nonzero" fill="#888">
        <path d="M4 10h10v2H4zM4 5h10v2H4zM0 0h2v2H0zM0 5h2v2H0zM0 10h2v2H0zM4 0h10v2H4z" />
      </g>
    </svg>
  )
}

export default SvgComponent
