export const enum ChangeOrderResolution {
  ACCEPTED = "ACCEPTED",
  NONE = "NONE",
  REJECTED = "REJECTED",
}

export const enum DURO_QUERY_PARAM_KEYS {
  DURO_SEARCH = "duroSearch"
}

export const enum ModelType {
  CMP = "CMP",
  PRD = "PRD",
}

// What is the code of the current page (viewing, editing, etc...)
export const enum PageMode {
  DIFF = "diff",
  EDIT = "edit",
  REVISION = "revision",
  VIEW = "view",
}

// What type of item is the current page for.
export const enum PageItemType {
  CHANGE_ORDER = "change_order",
  COMPONENT = "component",
  PRODUCT = "product",
  RELEASE = "release",
}

export const enum ProcurementType {
  BUY = "BUY",
  MAKE = "MAKE",
  NA = "NA",
  NONE = "NONE",
}

export const enum RevisionType {
  CO_REVISION = "coRevision",
  MODIFIED = "Modified",
  REVISION = "Revision",
  RELEASE_REVISION = "releaseRevision",
}

export const enum StatusValue {
  APPROVED = "APPROVED",
  CLOSED = "CLOSED",
  DESIGN = "DESIGN",
  DRAFT = "DRAFT",
  LOCKED = "LOCKED",
  OBSOLETE = "OBSOLETE",
  OPEN = "OPEN",
  PRODUCTION = "PRODUCTION",
  PROTOTYPE = "PROTOTYPE",
  REJECTED = "REJECTED",
  REVIEW = "REVIEW",
  SERIALIZED = "SERIALIZED",
  SERIALIZING = "SERIALIZING",
}

export const enum SettingsTabs {
  COMPANY = "Company Profile",
  CONFIGURATION = "Configuration",
  GROUPS = "Groups",
  INTEGRATIONS = "Integrations",
  USER_PROFILE = "Your Profile",
  USERS = "Users",
  WEBHOOK = "Webhooks",
}

export const enum UnitOfMeasure {
  CENTIMETERS = "CENTIMETERS",
  DAYS = "DAYS",
  EACH = "EACH",
  FEET = "FEET",
  GALLONS = "GALLONS",
  GRAMS = "GRAMS",
  HOURS = "HOURS",
  INCHES = "INCHES",
  KILOGRAMS = "KILOGRAMS",
  KILOMETERS = "KILOMETERS",
  LITERS = "LITERS",
  METERS = "METERS",
  MILLIGRAMS = "MILLIGRAMS",
  MILLIMETERS = "MILLIMETERS",
  NONE = "",
  OUNCES = "OUNCES",
  PACKAGE = "PACKAGE",
  POUNDS = "POUNDS",
  SQUARE_FEET = "FT²",
  SQUARE_INCH = "IN²",
  SQUARE_METER = "m²",
  SQUARE_MILLIMETER = "mm²",
  YARDS = "YARDS",
}
