import React, { Component }   from "react"

const SvgIcon = () =>
{
    return <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-57.000000, -70.000000)" fillRule="nonzero">
            <g transform="translate(40.000000, 55.000000)">
                <g transform="translate(17.000000, 17.000000)">
                    <circle stroke="#18171D" strokeWidth="3" opacity="0.628180804" cx="10.2857143" cy="8" r="8"></circle>
                    <path d="M0.793200795,8.48880787 C0.763953043,8.02524187 0.782731951,7.4895823 0.84953752,6.88182916 C0.916343089,6.27407601 1.04498337,5.68519074 1.23545837,5.11517334 L4.31013354,5.42699144 C4.07331715,6.0614762 3.92706726,6.5683783 3.87138386,6.94769773 C3.81570046,7.32701716 3.78785876,7.77210901 3.78785876,8.28297327 L0.793200795,8.48880787 Z" fill="#3CD1B5" opacity="0.632868304"></path>
                </g>
            </g>
        </g>
    </g>
</svg>

}

export default SvgIcon
