import mapActions from '../helpers/mapActions';

export default mapActions([
    'SET_ON_BOARDING_STATE',
    'UPDATE_STEPS_VALUE',
    'SHOW_HIDE_ON_BOARDING_NAV',
    'GET_SAMPLE_PRODUCT',
    'HIDE_ON_BOARDING_MODALS',
    'SET_DISPLAY_SAMPLE_PRODUCT_MODAL_ON_DASHBOARD_FLAG',
    'SET_TUTORIAL_SKIPED_FLAG',
    'SWITCH_ENV',
    'SHOW_ENV_INFO',
    'SWITCH_ENV_AND_CREATE_SAMPLE_PRODUCT',
    'SYNC_ACCOUNT_SETUP_PROGRESS',
    'SET_INNER_LOADING_FLAG',
    'UPDATE_INVITE_MODAL_FLAG',
    'SET_LOADING',
    'SET_ON_BOARDING_NOTIFICATION',
    'RESET_INITIAL_STATE',
    'SET_ACCOUNT_SETUP_FLAG'
  ], 'ON_BOARDING');
