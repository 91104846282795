import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={16} height={14} viewBox="0 0 16 14" {...props}>
      <path
        d="M12.112 1.089V0H10.69v1.089H6.096V0H4.675v1.089H.885V14h15V1.089h-3.773zM4.69 2.469v1.089h1.42v-1.09h4.596v1.09h1.42v-1.09h2.369v2.24H2.322v-2.24h2.369zM2.322 12.635V6.072h12.174v6.563H2.322z"
        fill="#888"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default SvgComponent
