import React                from "react";
import SourcingUtils        from '../sourcing/sourcing-utils'
import InlineIcon           from "../../../ui/icon/inline-icon.js"
import AlertErrorIcon       from "../../../../assets/icons/alert-error"
import Utils                from "../../../../modules/utils"
import CurrencyField        from "../currency-field"

function UnitPrice(props) {
    let { primarySource, item, tooltipText, mode} = props;

    return (
        <div className="inner-info values-to-right diff-unit-price-section">
            <span className="inner-attribute">unit price</span>
            <div
                data-tip={mode === 'EDIT' ? item.alias === 'cmp' ? tooltipText : '' : null}
                data-for={mode === 'EDIT' ? item.alias === 'cmp' ? "vendor-disable-input" : '' : null}
                className={`inner-value${mode === 'EDIT' ? SourcingUtils.haveInValidCosting(item) === true ? '' : ' valid' : ''}`}
            >
                {
                    SourcingUtils.haveInValidCosting(item) === true &&
                    <InlineIcon className="inline-icon" tooltip="Incomplete Sources" tooltipPlace="top">
                        <AlertErrorIcon />
                    </InlineIcon>
                }
                {
                    primarySource.unitPrice !== null &&
                    <span
                        className="unit-price"
                        data-tip=""
                        data-place="top"
                        onMouseOver={(e) => Utils.checkToolTipAppearance(e, primarySource.unitPrice)}
                    >
                        <CurrencyField symbol={item.defaultCurrency} value={primarySource.unitPrice} />
                    </span>
                }
            </div>
        </div>
    )
}
export default UnitPrice;


