import React from "react";

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='100%'
      height='100%'
      viewBox='0 0 32 32'
    >
      <defs>
        <path
          id='path-102'
          d='M15 0C6.728 0 0 6.728 0 15s6.728 15 15 15 15-6.728 15-15S23.272 0 15 0zm10.031 26.162c-.916-1.601-3.75-5.393-10.031-5.393-6.281 0-9.115 3.792-10.031 5.393A14.958 14.958 0 010 15C0 6.702 6.702 0 15 0s15 6.702 15 15c0 4.437-1.916 8.418-4.969 11.162zM10.385 11.538c0 3.27 2.25 5.77 5.192 5.77s5.192-2.5 5.192-5.77c0-3.269-2.25-5.769-5.192-5.769s-5.192 2.5-5.192 5.77z'
        ></path>
      </defs>
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g stroke='#FFF' transform='translate(-1325 -17)'>
          <g transform='translate(1326 18)'>
            <g strokeWidth='1.5'>
              <use xlinkHref='#path-102'></use>
              <use xlinkHref='#path-102'></use>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
