import { Box, IconButton, styled, Tooltip, Typography } from "@mui/material";
import { useCanUserViewVariants } from "design/hooks";
import { AssemblyChild, AssemblyChildVariant } from "design/models";
import { TableRow } from "design/components/grid/searchTable";
import { useCallback, useMemo } from "react";
import VariantIcon from "v1/assets/icons/variant-icon";

export type PrimaryVariantType = AssemblyChild & Partial<TableRow>;
export interface NameFieldProps {
  className?: string;
  hideVariants?: boolean;
  openVariantModal?: (pv: PrimaryVariantType) => void;
  primaryVariant: PrimaryVariantType;
}

export function ComponentNameField({ className, hideVariants, openVariantModal, primaryVariant }: NameFieldProps) {
  const canViewVariants = useCanUserViewVariants();
  const { variants, item, component } = primaryVariant;
  const { name, variantGroup } = item ?? component ?? {};

  const title = useMemo(() => {
    if (!variants?.length) return "Component Variants";

    const totalPermitted = variants.reduce((tp: number, variant: AssemblyChildVariant) => {
      if (variant.isPermitted) return tp + 1;
      return tp;
    }, 0);

    return `${totalPermitted} of ${variants.length} Variants`;
  }, [variants]);

  const onClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    openVariantModal?.(primaryVariant);
  }, [openVariantModal, primaryVariant]);

  const showVariantButton = !hideVariants && variantGroup && canViewVariants;
  return (
    <NameFieldContent className={className}>
      <NameText variant="body2">{name}</NameText>
      {showVariantButton && (
        <Tooltip title={title} placement="top" arrow>
          <NameVariantIcon onClick={onClick}>
            <VariantIcon />
          </NameVariantIcon>
        </Tooltip>
      )}
    </NameFieldContent>
  );
}

const NameText = styled(Typography)({
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const NameVariantIcon = styled(IconButton)(({ theme }) => ({
  alignItems: "center",
  background: theme.duro.fields.name.variantButton.background,
  borderRadius: "4px",
  display: "flex",
  fontSize: "17px",
  height: "28px",
  justifyContent: "center",
  padding: 0,
  width: "28px",
  "&:hover": {
    background: theme.duro.fields.name.variantButton.background,
    "& g": {
      stroke: theme.palette.primary.main,
    },
  },
}));

const NameFieldContent = styled(Box)(() => ({
  alignItems: "center",
  display: "flex",
  flex: 1,
  justifyContent: "space-between",
  overflow: "hidden",
}));
