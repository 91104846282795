import './index.css'
import React from 'react'
import InlineIcon from '../../../ui/icon/inline-icon'
import ProductIcon from '../../../../assets/icons/product-icon'
import PerfectScrollbar from 'react-perfect-scrollbar'

const TileItem = (
  {
    children,
    title = 'An example title',
    icon = <ProductIcon />,
    className = false,
    ...props
  }
) => {
  return (
    <aside
      className={className ? `tile-item ${className}` : "tile-item"}
      { ...props }
    >
      <InlineIcon className="tile-header">
        { icon }
        <h6>{ title }</h6>
      </InlineIcon>
      <PerfectScrollbar className="tile-body-scroll">
        { children }
      </PerfectScrollbar>
    </aside>
  )
}

export default TileItem
