import { gql } from "@apollo/client";
import { BaseBuildRevisionFragment } from "./buildRevisionFragments";

export const BaseLotFragment = gql`
  fragment baseLotFragment on BuildLot {
    id
    archived
    createdDate
    createdUser {
      id
      firstName
      lastName
    }
    dueDate
    labels
    lastModifiedDate
    lastModifiedUser {
      id
      firstName
      lastName
    }
    notes
    number {
      displayValue
    }
    productionRun
  }
`;

export const CreatedLotFragment = gql`
  fragment createdLotFragment on BuildLot {
    createdDate
    dueDate
    id
    instanceCount
    labels
    notes
    number {
      displayValue
    }
    revisions {
      cpn
      name
      revisionValue
      source {
        extId
      }
    }
    status
  }
`;

export const LotWithInstancesFragment = gql`
  fragment lotWithInstancesFragment on BuildLot {
    ...baseLotFragment

    instances {
      id
      createdDate
      lastModifiedDate
      notes
      serialNumber {
        displayValue
      }
      state
      revision {
        id
      }
    }
    primaryRevision {
      ...baseBuildRevisionFragment
      production {
        id
        isBookmarked
      }
    }
    revisions {
      ...baseBuildRevisionFragment
    }
  }
  ${BaseLotFragment}
  ${BaseBuildRevisionFragment}
`;

export const LotSearchFragment = gql`
  fragment lotSearchFragment on BuildLot {
    ...baseLotFragment

    primaryRevision {
      cpn
      id
      name
      revisionValue
    }
    status
  }
  ${BaseLotFragment}
`;
