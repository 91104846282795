import * as React from "react"

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 35 34" width="1em" height="1em" {...props}>
      <g data-name="Layer 2" className="co-icon">
        <path
          d="M33.92 34.41H1.08a1.08 1.08 0 01-1-1.58L16.93 1.16a1.08 1.08 0 011-.57 1.08 1.08 0 01.95.59l16 31.68a1.08 1.08 0 01-1 1.56zM2.87 32.26h29.3L17.86 4z"
          fill="#fffff"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
