import React, { Component } from "react"

const SvgIcon = () =>
{
    return <svg width="16px" height="17px" viewBox="0 0 16 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <g id="REPORTS-DOWNLOAD-A" transform="translate(-359.000000, -553.000000)" stroke="#FFFFFF">
                <g id="Download" transform="translate(359.000000, 553.000000)">
                    <g id="Export" transform="translate(8.000000, 8.027778) rotate(-180.000000) translate(-8.000000, -8.027778) translate(0.941176, -0.000000)">
                        <path d="M6.96851373,4.8982594 L6.96851373,15.1497775" id="Stroke-336" strokeWidth="1.25"></path>
                        <polyline id="Stroke-337" strokeWidth="1.25" points="12.2936941 10.2440435 6.9694 4.89803693 1.6428902 10.2440435"></polyline>
                        <polyline id="Stroke-338" strokeWidth="1.25" transform="translate(6.883653, 2.976792) rotate(-180.000000) translate(-6.883653, -2.976792) " points="13.5960745 -1.77635684e-15 13.5960745 5.95358437 0.171231373 5.95358437 0.171231373 -1.77635684e-15"></polyline>
                    </g>
                </g>
            </g>
        </g>
    </svg>
}

export default SvgIcon;