import React from "react"

function SvgComponent(props) {
  return (
    <svg
      width="20px"
      height="19px"
      viewBox="0 0 20 19"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fillRule="nonzero" stroke="#FFF" strokeWidth={0.25}>
        <path
          d="M3.092 8a.419.419 0 01-.416-.373l-.119-.856a2.924 2.924 0 01-.434-.26l-.788.323a.42.42 0 01-.52-.19L.065 5.35a.443.443 0 01.1-.578l.669-.538v-.26-.259l-.668-.534a.438.438 0 01-.1-.558l.73-1.277a.41.41 0 01.515-.186l.793.328c.137-.1.282-.186.434-.26l.124-.861A.418.418 0 013.092 0h1.432c.209 0 .386.16.415.372l.12.857c.15.074.296.16.433.26l.788-.323a.42.42 0 01.544.18l.73 1.293a.443.443 0 01-.105.553l-.668.539v.519l.668.523a.443.443 0 01.105.554L6.82 6.629a.41.41 0 01-.515.186l-.792-.328a2.99 2.99 0 01-.435.26l-.12.86A.419.419 0 014.525 8H3.092zm.023-.485h1.366l.152-1.082.124-.054c.203-.087.394-.2.568-.338l.11-.083.988.406.683-1.214-.84-.67v-.137a2.41 2.41 0 000-.676V3.53l.84-.67-.673-1.225-.988.407-.11-.083a2.373 2.373 0 00-.563-.338l-.13-.054L4.49.485H3.125l-.153 1.082-.124.054c-.203.087-.393.2-.568.338l-.11.083-.988-.407L.505 2.85l.84.67v.137a2.41 2.41 0 000 .676v.137l-.84.675.677 1.215.988-.407.11.083c.173.138.362.252.563.338l.125.054.147 1.087zm.693-1.973c-.833 0-1.509-.693-1.509-1.547s.676-1.547 1.509-1.547c.833 0 1.508.693 1.508 1.547 0 .41-.16.804-.442 1.094a1.49 1.49 0 01-1.066.453h0zm0-2.6c-.57 0-1.031.474-1.031 1.058 0 .584.461 1.057 1.03 1.057.57 0 1.032-.473 1.032-1.057s-.462-1.057-1.031-1.057z"
          transform="translate(.839 1) translate(11.161 9)"
        />
        <path
          d="M9.521 15.969l1.014.198a.89.89 0 01-.923.832H6.57a.89.89 0 01-.883-.79l-.253-1.821a6.213 6.213 0 01-.923-.552l-1.674.687a.892.892 0 01-1.105-.406L.139 11.371a.942.942 0 01.213-1.227l1.42-1.145v-.551-.551L.352 6.763A.931.931 0 01.14 5.577l1.552-2.715a.872.872 0 011.095-.396l1.684.697c.292-.21.601-.394.923-.551l.264-1.83A.889.889 0 016.57 0h3.042a.9.9 0 01.883.79l.253 1.821c.323.156.632.34.923.551l1.674-.686a.891.891 0 011.156.385l1.552 2.746a.942.942 0 01-.223 1.175l-1.42 1.145-1.065-.447m0 0L15.1 6.075l-1.43-2.6-2.1.863-.233-.177a5.044 5.044 0 00-1.197-.718l-.274-.114-.325-2.3h-2.9l-.325 2.3-.264.114c-.43.185-.836.426-1.207.718l-.233.177-2.1-.864-1.44 2.58L2.857 7.48v.292a5.12 5.12 0 000 1.435V9.5l-1.785 1.435 1.44 2.58 2.1-.863.233.176c.367.293.77.534 1.197.718l.264.115.314 2.309h2.901m-1.43-4.192c-1.77 0-3.205-1.472-3.205-3.288 0-1.815 1.435-3.287 3.205-3.287s3.205 1.472 3.205 3.287a3.33 3.33 0 01-.939 2.325 3.165 3.165 0 01-2.266.963h0zm0-5.524c-1.21 0-2.19 1.006-2.19 2.247 0 1.24.98 2.247 2.19 2.247s2.19-1.006 2.19-2.247c0-1.241-.98-2.247-2.19-2.247z"
          transform="translate(.839 1)"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
