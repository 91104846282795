import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      viewBox="0 0 18 17"
    >
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(-313 -394) translate(314 393) translate(0 1.85)"
      >
        <path d="M0.447368421 12.6296495L13.1709202 12.6296495 13.1709202 0.428983417 0.447368421 0.428983417z"></path>
        <path d="M16.2135086 3.346534L16.2135086 15.5472001 3.48995688 15.5472001"></path>
      </g>
    </svg>
  );
}

export default Icon;
