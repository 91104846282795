import { SvgIcon } from "@mui/material";

export const OverviewIcon = () => (
  <SvgIcon height="16" width="16" viewBox="0 0 16 16" version="1.1">
    <g fillRule="nonzero">
      {/* eslint-disable-next-line max-len */}
      <path d="M1.333 6.689V1.333H6.69V6.69H1.333zm0 6.316V7.649H6.69v5.356H1.333zM7.65 6.689V1.333h5.356V6.69H7.649zm-5.355-.96h3.398V2.293H2.294v3.434zm6.352 0h3.398V2.293H8.646v3.434zm-6.352 6.315h3.398V8.646H2.294v3.398zM13.965 14.63l-1.958-1.958c-.221.185-.48.296-.738.37-.259.073-.554.11-.85.11-.775 0-1.403-.258-1.957-.812a2.704 2.704 0 0 1-.813-1.958c0-.775.259-1.403.813-1.957a2.704 2.704 0 0 1 1.957-.813c.776 0 1.404.259 1.958.813.517.517.812 1.182.812 1.957 0 .296-.037.591-.11.85a1.988 1.988 0 0 1-.37.738l1.958 1.958-.702.702zm-3.546-2.438c.517 0 .924-.185 1.256-.517.332-.332.517-.776.517-1.256 0-.517-.185-.923-.517-1.256a1.759 1.759 0 0 0-1.256-.517c-.517 0-.923.185-1.256.517a1.759 1.759 0 0 0-.517 1.256c0 .517.185.924.517 1.256.333.332.74.517 1.256.517z" />
    </g>
  </SvgIcon>
);
