import React, {Component} from "react"
import MpnStatusIcon      from "./mpn-status-icon"
import Tooltip            from "rc-tooltip"
import Utils              from "../../../../../../../../modules/utils/index.js";
import InlineIcon         from "../../../../../../../ui/icon/inline-icon.js"
import LinkIcon           from "../../../../../../../../assets/icons/open-link-icon.js"
import Link               from "../../../../../../../ui/link"
export default class InputField extends Component
{
    constructor(props)
    {
        super(props);
        this.selectAll = false;
        this.onInputChange   = this.onInputChange.bind(this);
        this.state =
        {
            input: this.props.input,
            inputIndex: this.props.col,
            row: this.props.row
        }
    }
    makeOverlay(duplicate, includeUseExistingBtn=false, inputName=null, currentlyOpenedCpn=null, warningTooltip=false)
    {
        let rowIndex = this.props.rowIndex;
        let isSelfReferencing = duplicate.cpn && currentlyOpenedCpn && duplicate.cpn === currentlyOpenedCpn;
        let isProduct = duplicate.alias === 'prd';
        let nameOfInput = inputName.toLowerCase() === 'name' ? `${inputName.toLowerCase()}s` : `${inputName.toUpperCase()}'s`;
        if(isSelfReferencing)
        {
            duplicate.errorMessage = "Cannot add parent component as child assembly (Self reference not allowed)"
        }
        return <div>
                {
                    isSelfReferencing ?
                    <p>
                        <span className="link-text"> {duplicate.errorMessage}</span>
                    </p>
                    :
                    <p>
                        <span className="link-text error-message">{duplicate.errorMessage}</span>
                        <Link
                            to={duplicate.viewLink}
                            target="_blank"
                            className={`open-link-holder ${warningTooltip ? 'duplicate-sourcing-warning' : '' }`}
                            >
                            <span className="link-text">{duplicate.linkMessage}
                                <InlineIcon >
                                    <LinkIcon/>
                                </InlineIcon>
                            </span>
                        </Link>
                        { !isProduct && includeUseExistingBtn ?
                            <div>
                                <p className="use-existing">
                                    <input type="checkbox" name="select-all-duplicates" onClick={() => this.selectAll=true}/>
                                    <label class="mr-7"></label>
                                     {`Apply to all matching ${nameOfInput}`}
                                </p>
                                <div class="ta-right">
                                    <button onClick={(e) => this.props.selectFromLibrary(e,rowIndex, inputName, this.selectAll)}>Use Existing</button>
                                </div>
                            </div>
                            : null
                        }
                        { isProduct ? <p className="warning">Cannot add product as assembly</p> : ''}
                    </p>
                }
            </div>
    }
    onInputChange(e, rowIndex, inputIndex)
    {
        let state = this.state
        state.input.value = e.target.value
        if (state.input.name === "ref des")
        {
            state.input.value = state.input.value.toUpperCase()
        }
        this.setState(state)
        clearTimeout(state.timeOutID)
        state.timeOutID = setTimeout(function(){
            this.props.onInputChange(this.state.input.value, rowIndex, inputIndex)
            if (this.state.input.name == "mpn") {
              this.setState(state)
            }
        }.bind(this), 800)
    }
    render()
    {
        let markup       = ""
        let row          = this.state.row
        let input        = this.state.input
        let inputIndex   = this.state.inputIndex
        let exClass      = input.name === "mpn" && input.resStatus ? " pr-25" : ""
        let tooltipProps = input.class ? {} : {visible: false}
        let includeUseExistingBtn = false
        let overlay = input.class ? input.message : ""
        let inputClazzForElipses = " elipses-" + inputIndex + "-" + this.props.rowIndex

        if (input.valid)
        {
            overlay = input.value
        }

        let sorucingOverlay = null
        let value       = input.value
        let tableSettings = this.props.tableSettings
        let disabledClazz = ""
        let displayToolTipClazz = "hidden"
        const sorucingCombination = ["cpn","mpn","dpn","min quantity"]
        if (input.message) {
            let messages = ["This sourcing combination already exists in this component.", "Duplicate data exists in file.", "Invalid CPN"]
            displayToolTipClazz = this.props.fileType === "update" && messages.includes(input.message) ? "hidden" : "";
        }

        if (row.disabled)
        {
            disabledClazz = " disabled"
            displayToolTipClazz = "hidden"

        }
        else if (row.selectedFromLibrary)
        {
            disabledClazz = " disabled selected-from-library"
            displayToolTipClazz = "hidden"
            if (!["quantity", "ref des", "notes"].includes(input.name))
                value = row.selectedCmpFromLibrary[input.name] || ""
        }

        if (input.name === "name")
        {
            if(input.nameDuplicateOf && this.props.fileType === "new_assembly")
            {
                includeUseExistingBtn = true
            }
            let nameDuplicateTooltip = Utils.makeDuplicateInputTooltip(input.nameDuplicateOf, "Name")
            overlay = nameDuplicateTooltip ?
                this.makeOverlay(nameDuplicateTooltip, includeUseExistingBtn, "name", this.props.parentComponent.props.currentlyOpenedCpn)
                : input.message

        }
        if (input.name === "eid")
        {
            if(input.eidDuplicateOf && this.props.fileType === "new_assembly")
            {
                includeUseExistingBtn = true
            }
            let eidDuplicateTooltip = Utils.makeDuplicateInputTooltip(input.eidDuplicateOf, "EID")
            overlay = eidDuplicateTooltip ?
                this.makeOverlay(eidDuplicateTooltip, includeUseExistingBtn, "eid", this.props.parentComponent.props.currentlyOpenedCpn)
                : input.message
        }
        if ((["new", "new_assembly"].includes(this.props.fileType) && window.__currentCompanyCpnType === "FREE-FORM" ) && input.name === "cpn")
        {
            if(input.cpnDuplicateOf && this.props.fileType === "new_assembly")
                includeUseExistingBtn = true;
            let cpnDuplicateTooltip = Utils.makeDuplicateInputTooltip(input.cpnDuplicateOf, "CPN")
            overlay = cpnDuplicateTooltip ? this.makeOverlay(cpnDuplicateTooltip, includeUseExistingBtn, "cpn",this.props.parentComponent.props.currentlyOpenedCpn): input.message;
        }
        let mpnDuplicateTooltip = null
        if (input.name === "mpn")
        {
            if(input.mpnDuplicateOf && this.props.fileType === "new_assembly")
            {
                includeUseExistingBtn = true
            }
            mpnDuplicateTooltip = input.resMsg && input.resMsg === "MPN is already in use" ? Utils.makeDuplicateInputTooltip(input.mpnDuplicateOf, "MPN") : null;
            sorucingOverlay = mpnDuplicateTooltip ?
                this.makeOverlay(mpnDuplicateTooltip, includeUseExistingBtn, "mpn")
                : input.message
        }
        if(this.props.fileType === "update" && input.resStatus === "warning" && (sorucingCombination.includes(input.name)) )
        {
            let duplicateTooltip = input.message && input.message === "This sourcing combination already exists in this component." ? Utils.makeDuplicateInputTooltip(input.duplicate_of, Utils.capitalizeString(input.name), true) : null;
            sorucingOverlay = duplicateTooltip ?
                this.makeOverlay(duplicateTooltip, includeUseExistingBtn, input.name, null, true)
                : input.message
        }
        let customTipMsg = ""
        if (mpnDuplicateTooltip)
        {
            customTipMsg = mpnDuplicateTooltip.errorMessage + " " + mpnDuplicateTooltip.linkMessage
        }
        else
        {
            customTipMsg = input.resMsg
        }

        if (input.valid)
        {
            overlay = input.value

        }

        let isNotRevisionManaged = false;
        if(input && input.class && input.class.includes("not-revision-managed")) isNotRevisionManaged = true;
        markup = <td name={input.name} style={ {minWidth: (input.minWidth + 'px'), width: (input.width + 'px') }}>
                    <div className={`icon-holder ${sorucingCombination.includes(input.name) && input.value ? 'mpn-input' : ''}`}>
                        {
                            <Tooltip
                                {...tooltipProps}
                                overlayClassName={`simple-rc-tip error default-styles ${isNotRevisionManaged ? 'not-revision-managed' : ''} ${this.props.fileType === "new_assembly" ? 'new_assembly-rc-tooltip' : 'new-file-rc-tooltip'} ${includeUseExistingBtn ? 'with-existing-btn ' : ''} ${displayToolTipClazz} ${inputClazzForElipses}`}
                                getTooltipContainer={() => document.querySelector("#routes")}
                                overlay={overlay}
                                placement={input.valid ? "top" : "right"}
                                onPopupAlign={function(popupDomNode, align)
                                    {
                                        let elipsesClazz = ""
                                        let clazzList = popupDomNode.classList
                                        for(let i=0; i < clazzList.length; i++)
                                        {
                                            if(clazzList[i].startsWith("elipses-"))
                                            {
                                                elipsesClazz = clazzList[i]
                                                break
                                            }
                                        }

                                        let inputSelector = ".table-content ." + elipsesClazz
                                        let element = document.querySelector(inputSelector)
                                        if (element.offsetWidth < element.scrollWidth && input.valid)
                                        {
                                            popupDomNode.classList.remove("hidden")
                                            popupDomNode.classList.remove("error")
                                            popupDomNode.classList.add("custom-elipses-tip")
                                        }
                                    }
                                }
                              >
                                <input
                                    data-for={this.props.rowIndex + "" + inputIndex}
                                    type="text"
                                    name={input.name}
                                    className={"text-inputs " + input.class + exClass + (disabledClazz) + inputClazzForElipses}
                                    value={value}
                                    onChange={(e) => this.onInputChange(e, this.props.rowIndex, inputIndex)}
                                    data-tip={input.class ? input.message : ""}
                                    data-place="right"
                                    data-type="error"
                                    disabled={row.selectedFromLibrary ? true : false}
                                    autoComplete="off"
                                />
                            </Tooltip>
                        }
                        {
                            (sorucingCombination.includes(input.name)) && input.value &&
                                <MpnStatusIcon
                                    input={input}
                                    rowIndex={this.props.rowIndex}
                                    inputIndex={inputIndex}
                                    isRowDisabled={row.disabled}
                                    mpnOverlay={sorucingOverlay}
                                    customTipMsg={customTipMsg}
                                    mpnImportDialog={this.props.mpnImportDialog}
                                    isSelectedFromLibrary={row.selectedFromLibrary}
                                    includeUseExistingBtn={includeUseExistingBtn}
                                    fileType={this.props.fileType}
                                    />
                        }
                    </div>
                </td>
        return markup
    }
}
