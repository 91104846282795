import React, {Component} from "react"
import Tooltip            from 'rc-tooltip';
import Utils              from "../../../../../../../../modules/utils/index.js";
import Schemas              from "../../../../../../../../modules/schemas";

export default class UnitOfMeasures extends Component
{

    constructor(props)
    {
        super(props)
        this.onInputChange   = this.onInputChange.bind(this)
        this.onSelectClick   = this.onSelectClick.bind(this)
        this.state =
        {
            input: null,
            inputIndex: 0,
            row: this.props.row,
            showSelectOptions: true,
        }
    }

    componentWillMount()
    {
        let state      = this.state
        let row        = state.row
        let inputIndex = row.columns.indexOf("unit of measure")
        let input      = row.data[inputIndex]

        state.input = input
        state.inputIndex = inputIndex
        this.setState(state)
    }

    onInputChange(e, rowIndex, inputIndex)
    {
        let state = this.state
        state.input.value = e.target.value
        this.setState(state)

        this.props.parentComponent.onInputChange(state.input.value, rowIndex, inputIndex)
    }

    onSelectClick(e, rowIndex, inputIndex)
    {
        let state = this.state

        //On first render show the default value as option in select (both valid/invalid)
        //otherwise show only valid options in select
        if(!state.showSelectOptions)
        {
            //On UOM select click send empty value for validation.
            state.input.value = ""
            this.props.parentComponent.onInputChange(state.input.value, rowIndex, inputIndex)

            state.showSelectOptions = true //Show only allowed options in UOM select
            this.setState(state);
        }
    }

    createToolTipClass(fileType, message){
        return `error default-styles ${fileType === "new_assembly" ? 'new_assembly-rc-tooltip' : 'new-file-rc-tooltip'} ${message ? '' : 'hidden'}`
    }

    render()
    {
        let markup     = ""
        let row        = this.state.row
        let input      = this.state.input
        let inputIndex = this.state.inputIndex
        let tooltipProps = input.class ? {} : {visible: false}
        let value       = input.value
        let disabledClazz = ""
        let tableSettings = this.props.tableSettings

        if (row.disabled)
        {
            disabledClazz = " disabled"
        }

        markup = <td  name={"unit of measure"} style={ {width: ( Utils.getStyleValue(tableSettings, "unit of measure", "width", 176, {onErrorDefault: true}) + 'px') }}>
                    <Tooltip
                        {...tooltipProps}
                        placement="right"
                        overlay={input.class ? input.message : ""}
                        destroyTooltipOnHide={true}
                        mouseLeaveDelay={0}
                        overlayClassName={this.createToolTipClass(this.props.fileType, input.message)}
                        arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                      >
                        <select
                            data-for={this.props.rowIndex + "" + inputIndex}
                            name={input.name}
                            className={input.class + disabledClazz}
                            value={value}
                            data-default-value={input.defaultOption}
                            onChange={(e) => this.onInputChange(e, this.props.rowIndex, inputIndex)}
                            onMouseDown={(e) => this.onSelectClick(e, this.props.rowIndex, inputIndex)}
                            data-tip={input.class ? input.message : ""}
                            data-place="right"
                            data-type="error"
                        >
                            <option value=""></option>
                            {
                                this.state.showSelectOptions ?
                                    Utils.toOptions(Schemas.component.unitOfMeasure.list())
                                :
                                    <option value={value}>{value}</option>
                            }
                        </select>
                    </Tooltip>
                </td>

        return markup
    }
}
