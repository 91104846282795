import { SvgIcon } from "@mui/material";

export const RevertIcon = () => (
  <SvgIcon width="24px" height="24px" viewBox="0 0 24 24">
    <path d="M12.191 22c-1.8
      0-3.442-.412-4.927-1.237-1.484-.825-2.73-2.003-3.734-3.532v2.97H2v-5.67h5.624v1.53H4.61c.84 1.41
      1.923 2.497 3.25 3.262a8.524 8.524 0 0 0 4.331 1.147c1.815 0 3.45-.536 4.905-1.608 1.454-1.073
      2.46-2.486 3.014-4.241l1.485.36c-.63 2.1-1.811 3.794-3.543 5.084-1.733 1.29-3.686 1.935-5.86
      1.935zM2.045 11.089c.105-1.035.345-2.014.72-2.936A9.434 9.434 0 0 1 4.317 5.6l1.08 1.102a9.499
      9.499 0 0 0-1.215 2.08 8.252 8.252 0 0 0-.607 2.307h-1.53zm4.657-5.692-1.08-1.125a10.35 10.35 0
      0 1 2.61-1.563A9.94 9.94 0 0 1 11.156 2v1.552a7.854 7.854 0 0 0-2.238.563c-.728.3-1.466.727-2.216
      1.282zm10.664 0a9.046 9.046 0 0 0-2.104-1.26 7.73 7.73 0 0 0-2.306-.585V2c1.035.09 2.01.326
      2.925.709.915.382 1.77.903 2.564 1.563l-1.08 1.125zm3.127 5.692a8.117 8.117 0 0 0-.641-2.306
      9.62 9.62 0 0 0-1.272-2.126l1.058-1.125c.69.81 1.24 1.687 1.653 2.632.413.945.657
      1.92.731 2.925h-1.53z"
    />
  </SvgIcon>
);
