import ON_BOARDING                       from "../../action-types/on-boarding"
import initialState, {getInitialState}   from "./initial_state"
import { handleActions }                 from "redux-actions"

function isOnBoardingCompleted(steps)
{
  let isStatusCompleted = true
  steps.forEach((step) => 
  {
    if (!step.isCompleted)
    {
      isStatusCompleted = false
      return
    }
  })
  return isStatusCompleted
}

function updateStepsValue(state=initialState, { payload })
{

  let isStatusCompleted = isOnBoardingCompleted(payload.steps)
  state.steps           = payload.steps
  if(payload.activeStepIndex >= 0) state.activeStepIndex = payload.activeStepIndex
  if (payload.currentStep) state.currentStep = payload.currentStep
  if (isStatusCompleted) state.status = "Completed"
  return {...state}
}

function showHideOnBoardingNav(state=initialState, { payload })
{
  state.displayOnBoardingNav = payload
  return {...state}
}

function setOnBoardingState(state=initialState, { payload })
{
  let activeStepIndex = 0
  state.steps.forEach((step, i) => 
  {
    if (payload.onboarding.completedSteps.includes(step.key))
    {
      step.isCompleted = true
    }
    if (step.key === payload.onboarding.currentStep)
    {
      activeStepIndex = i
      step.class = "active"
    }
  })

  state.activeStepIndex  = activeStepIndex
  state.currentEnv       = payload.currentEnv
  state.currentStep      = payload.onboarding.currentStep
  state.dummyProductType = payload.onboarding.dummyProductType === "none" ? "drone" : payload.onboarding.dummyProductType 
  state.status           = payload.onboarding.status
  return {...state}
}

function hideOnBoardingModals(state=initialState, { payload })
{
  state.displayOnBoardingNav = false
  state.steps.forEach((step) => {
      step.showToolTip    = false
      step.showVideoModal = false
  })
  return {...state}
}

function setDisplaySampleProductOnDashBoardFlag(state=initialState, { payload })
{
  state.displaySampleProductModalOnDashBoard = payload
  return {...state}
}

function setTutorialSkipedFlag(state=initialState, { payload })
{
  state.isTutorialSkiped = payload
  return {...state}
}

function switchEnv(state=initialState, { payload })
{
  state.currentEnv       = payload.currentEnv
  if(payload.personalLibraryNotification || payload.companyLibraryNotification)
  {
      state.showEnvInfoModal = true
  }
  else
  {
      state.showEnvInfoModal = false
  } 

  return {...state}
}

function updateInfoModalFlag(state=initialState, { payload })
{
  state.showEnvInfoModal = payload
  return {...state}
}

function setInnerLoadingFlag(state=initialState, { payload })
{
  state.innerLoading = payload
  return {...state}
}

function setAccountSetupFlag(state=initialState, { payload })
{
  state.accountSetupCompleted = payload
  return {...state}
}

function setLoadingFlag(state=initialState, { payload })
{
  state.loading = payload
  return {...state}
}

function updateInviteModalFlag(state=initialState, { payload })
{
  state.showInviteModal = payload
  return {...state}
}

function setOnBoardingNotification(state=initialState, { payload })
{
  state.isOnBoardingNotification = payload
  return {...state}
}

function resetInitialState(state, { payload })
{
  return {...getInitialState()}
}

export default handleActions({
  [ON_BOARDING.UPDATE_STEPS_VALUE]                                          : updateStepsValue,
  [ON_BOARDING.SHOW_HIDE_ON_BOARDING_NAV]                                   : showHideOnBoardingNav,
  [ON_BOARDING.SET_ON_BOARDING_STATE]                                       : setOnBoardingState,
  [ON_BOARDING.HIDE_ON_BOARDING_MODALS]                                     : hideOnBoardingModals,
  [ON_BOARDING.SET_DISPLAY_SAMPLE_PRODUCT_MODAL_ON_DASHBOARD_FLAG]          : setDisplaySampleProductOnDashBoardFlag,
  [ON_BOARDING.SET_TUTORIAL_SKIPED_FLAG]                                    : setTutorialSkipedFlag,
  [ON_BOARDING.SWITCH_ENV]                                                  : switchEnv,
  [ON_BOARDING.SHOW_ENV_INFO]                                               : updateInfoModalFlag,
  [ON_BOARDING.SET_INNER_LOADING_FLAG]                                      : setInnerLoadingFlag,
  [ON_BOARDING.SET_ACCOUNT_SETUP_FLAG]                                      : setAccountSetupFlag,
  [ON_BOARDING.UPDATE_INVITE_MODAL_FLAG]                                    : updateInviteModalFlag,
  [ON_BOARDING.SET_LOADING]                                                 : setLoadingFlag,
  [ON_BOARDING.SET_ON_BOARDING_NOTIFICATION]                                : setOnBoardingNotification,
  [ON_BOARDING.RESET_INITIAL_STATE]                                         : resetInitialState

}, initialState);
