import                    "./index.css";
import React, {Component} from "react";
import ModalBox           from "../../../ui/modal-box";
import DeleteWarningModal from "./delete-warning-modal";
import Utils              from "../../../../modules/utils";
import LazyInput          from "../../../ui/lazy-input/input.js";
import InlineIcon         from "../../../ui/icon/inline-icon.js";
import RemoveIcon         from "../../../../assets/icons/cross-icon.js";
import PerfectScrollbar   from 'react-perfect-scrollbar';

class ManageTemplatesModal extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            templates: Utils.clone(this.props.templates),
            removeTemplates : [],
            showDeleteWarningModal: false,
            template : {},
            toggleManageTemplateModalSaveButton: false
        }
        this.onClose                 = this.onClose.bind(this);
        this.getPrivateTemplates     = this.getPrivateTemplates.bind(this);
        this.getCompanyTemplates     = this.getCompanyTemplates.bind(this);
        this.remove                  = this.remove.bind(this);
        this.saveTemplate            = this.saveTemplate.bind(this);
        this.closeDeleteWarningModal = this.closeDeleteWarningModal.bind(this);
        this.showDeleteWarningModal  = this.showDeleteWarningModal.bind(this);
        this.handleOutsideClick      = this.handleOutsideClick.bind(this);
    }

    componentDidMount()
    {
        document.addEventListener("click", this.handleOutsideClick, false);
    }

    componentWillUnmount()
    {
        document.removeEventListener("click", this.handleOutsideClick, false);
    }

    closeDeleteWarningModal(modalName, modalValue)
    {
        this.setState({[modalName]: modalValue});
    }

    onClose()
    {
        document.removeEventListener("click", this.handleOutsideClick, false);
        this.props.onClose("displayManageTemplatesModal", false);
    }

    showDeleteWarningModal(item)
    {
        this.setState({template: item , showDeleteWarningModal: true});
    }

    remove()
    {
        let state = this.state;
        let item = state.template;
        let templates = state.templates;
        let removeTemplates = [];
        state.removeTemplates.push(item._id);
        templates.forEach((template, i) => {
            if(template._id === item._id)
            {
                templates.splice(i, 1);
            }
        });
        this.setState({templates: templates, showDeleteWarningModal: false, toggleManageTemplateModalSaveButton: true});
    }

    getPrivateTemplates()
    {
        let templates = [];
        this.state.templates.forEach((item, i) => {
            if(!item.isPublic && this.props.user.data._id === item.creator)
            {
                templates[i] =  <div key={"item-"+i} className="row-item">
                                {item.templateName}
                                <InlineIcon className="remove-template"
                                onClick={(e) => {this.showDeleteWarningModal(item)}}>
                                    <RemoveIcon />
                                </InlineIcon>
                            </div>
            }
        })
        return templates;
    }

    getCompanyTemplates()
    {
        const { coTemplatesMappings } = this.props;
        let templates = [];
        let isAdmin   = this.props.user.data.role === "ADMINISTRATOR" ? true : false;
        this.state.templates.forEach((item, i) => {
            if(item.isPublic)
            {
                const mappingExists = Utils.coMappingExists(item._id,coTemplatesMappings);
                templates[i] =  <div key={"item-"+i} className="row-item">
                                {item.templateName}
                                <InlineIcon className={`remove-template ${isAdmin && !mappingExists ? "" : "disabled"}`}
                                tooltip={ isAdmin && mappingExists ? "This template cannot be deleted. It is being used as a Mandatory Approver Template." : ""}
                                onClick={isAdmin && !mappingExists ? (e) => {this.showDeleteWarningModal(item)} : function(){}}>
                                    <RemoveIcon />
                                </InlineIcon>
                            </div>
            }
        })
        return templates;
    }

    saveTemplate()
    {
        let data = this.state.removeTemplates;
        if(data.length > 0)
        {
            this.props.manageTemplates(this.state.templates, data);
            this.props.saveTemplate(data, false, true);
        }
        this.onClose();
    }

    handleOutsideClick(e)
    {
        let isDeleteWarningModalEvent = (this.state.showDeleteWarningModal || (e.target.closest('.ui-modal-box')));
        if(!isDeleteWarningModalEvent && (!this.node.contains(e.target)) || e.target.classList.contains("background")) this.onClose();
    }

    render()
    {
        let saveButtonClass = this.state.toggleManageTemplateModalSaveButton ? "" : "disabled";
        let markup = <div ref={node => { this.node = node;}}>
                        {
                            this.state.showDeleteWarningModal ?
                            <div className="delete-warning-modal">
                                <DeleteWarningModal
                                closeDeleteWarningModal={this.closeDeleteWarningModal}
                                remove={this.remove}
                                templateName={this.state.template.templateName}
                                />
                            </div>
                            : null
                        }
                        <ModalBox className={this.props.isCoTemplate ? 'co-manage-template' : ''} onClose={this.onClose} hideCrossIcon={true}>
                            <div className="modal-heading">
                                <h3>MANAGE TEMPLATES</h3>
                            </div>
                            <div className="modal-body">
                                <h3>PRIVATE TEMPLATES</h3>
                                <PerfectScrollbar>
                                    <div className="modal-body-content templates-screen-scroll">
                                        {this.getPrivateTemplates()}
                                    </div>
                                </PerfectScrollbar>

                                <h3>COMPANY TEMPLATES</h3>
                                <PerfectScrollbar>
                                    <div className="modal-company-templates templates-screen-scroll">
                                        {this.getCompanyTemplates()}
                                    </div>
                                </PerfectScrollbar>

                                <div className="btn-holder">
                                    <div className="btn-container">
                                        <button
                                            className="dark"
                                            onClick={this.onClose}>
                                            CANCEL
                                        </button>
                                        <button
                                            className={saveButtonClass}
                                            onClick={this.saveTemplate}
                                        >
                                            SAVE
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </ModalBox>
                     </div>
        return markup;
    }
}

export default ManageTemplatesModal;
