import React, { Fragment, useMemo } from 'react'
import InlineIcon from '../../../../ui/icon/inline-icon'
import PaginationPrevious from '../../../../../assets/icons/pag-previous'
import PaginationNext from '../../../../../assets/icons/pag-next'

const Pagination = (
  {
    resultText,
    totalResults,
    totalPages,
    page,
    paginationSize,
    goPreviousPage,
    goNextPage
  }
) => {
  const render = useMemo(() => {
    const pagination = resultText && totalPages && totalPages > 1 ? (
      <div className="grey records-count d-flex">
        {(paginationSize * (page - 1)) + 1} - {
          totalPages !== page ? paginationSize * page : totalResults
        } of {resultText}
        <InlineIcon
          className={`arrow${page > 1 ? ' active' : ''}`}
          onClick={e => goPreviousPage(e)}
        >
          <PaginationPrevious />
        </InlineIcon>
        <InlineIcon
          className={`arrow${totalPages > page ? ' active' : ''}`}
          onClick={e => goNextPage(e)}
        >
          <PaginationNext />
        </InlineIcon>
      </div>
    )
    : null

    return (
      <Fragment>
        {pagination}
      </Fragment>
    )
  }, [goNextPage, goPreviousPage, paginationSize, totalResults, resultText, totalPages, page])

  return render
}

export default Pagination
