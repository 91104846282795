import { LockOutlined } from "@mui/icons-material";
import { Box, Card, CardContent, CardHeader, CardMedia, styled } from "@mui/material";
import { PropsWithChildren, ReactNode, useMemo } from "react";
import { ImageContextProvider } from "../imageContext/imageContextProvider";
import { useImage } from "graphql/query/imagesQueries";

const StyledCard = styled(Card)({
  flexShrink: 0,
  margin: "0.5rem 0.75rem",
  ":first-of-type": {
    marginLeft: 0,
  },
  ":last-of-type": {
    marginRight: 0,
  },
  "& .MuiCardHeader-root": {
    "& svg": {
      fontSize: "1rem",
    },
  },
});

const Content = styled(CardContent)(() => ({
  overflowX: "hidden",
}));

const PinnedTitle = styled("span")(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}));

function BaseTileMedia({ alt, imageId }: { alt?: string, imageId: string }) {
  const { image } = useImage(imageId);

  if (!image?.variants?.r255_160) return <></>;

  return (
    <CardMedia
      sx={{ height: 140 }}
      image={image?.variants?.r255_160}
      title={alt}
    />
  );
}

interface TileProps {
  icon?: any;
  imageAlt?: string;
  imageId?: string;
  pinned?: boolean;
  title?: string;
}

export function BaseTile(props: PropsWithChildren<TileProps>) {
  const { children, icon, imageAlt, imageId, pinned, title } = props;

  const displayTitle: ReactNode = useMemo(() => {
    if (!pinned) return title;

    return (
      <PinnedTitle>{title}<LockOutlined /></PinnedTitle>
    );
  }, [pinned, title]);

  const displayHeader = !!(displayTitle || icon || pinned);

  return (
    <StyledCard>
      {displayHeader && <CardHeader avatar={icon} title={displayTitle} />}
      {imageId && <BaseTileMedia alt={imageAlt} imageId={imageId} />}
      <Content>{children}</Content>
    </StyledCard>
  );
}

const RowContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  flexShrink: 0,
  overflowX: "auto",
});

export function BaseTilesRow({ children, className }: PropsWithChildren<{ className?: string }>) {
  return (
    <ImageContextProvider><RowContainer className={className}>{children}</RowContainer></ImageContextProvider>
  );
}

const GridContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
});

export function BaseTilesGrid({ children }: PropsWithChildren<{}>) {
  return <ImageContextProvider><GridContainer>{children}</GridContainer></ImageContextProvider>;
}
