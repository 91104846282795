import React from "react";

const SvgComponent = props => (
  <svg
    width="17px"
    height="18px"
    viewBox="0 0 17 18"
    id="export-new-svg"
    {...props}
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="TABLE-ICONS-WITH-TEXT"
        transform="translate(-173 -8)"
        stroke="#FFF"
      >
        <g id="TABLE-ICONS">
          <g id="EXPORT" transform="translate(174 9)">
            <g id="EXPORT-ICON">
              <g id="Export" strokeWidth={1.25}>
                <path d="M7.404.186v10.855" id="Stroke-336" />
                <path
                  id="Stroke-337"
                  d="M13.06205 5.84663425L7.4049875 0.186156749 1.74557083 5.84663425"
                />
                <path
                  id="Stroke-338"
                  d="M14.4458292 10.6268286L14.4458292 16.3037952 0.181933333 16.3037952 0.181933333 10.6268286"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgComponent;
