import {
  BookmarkIcon, DeleteIcon, EditIcon, ExportIcon, InventoryIcon, LotIcon, SerializationIcon, TodoIcon,
} from "assets/icons";
import { BuildPageWrapper } from "build/components/pageWrapper";
import { SerializationModal } from "build/components/serializationModal";
import { SourceType } from "build/models";
import { BreadCrumbIcon, useBreadCrumb } from "common/components/breadcrumb";
import { Tab, TabList, TabPanel, TabsWrapper } from "common/components/tabs";
import {
  TileConfiguration, TileContentType, TileIcon, TileValueFormat, TilesRow,
} from "common/components/tile";
import { useToasts } from "common/components/toasts";
import { IToolbarActions } from "common/components/toolbar";
import { ModelType } from "design/constants";
import { useUpdateProductions } from "graphql/mutation/productionMutations";
import { useLotWithInstances } from "graphql/query/lotQueries";
import { MouseEventHandler, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { LotInventoryView } from "./lot/instanceTable";
import { TransactionalModal } from "common/components/modals";
import { useDeleteLots } from "graphql/mutation/lotMutations";
import { styled } from "@mui/material";
import { colorPalette } from "theme";

enum LotTab {
  INVENTORY = "INVENTORY",
  TODO = "TODO"
}

interface BuildLotRouteParams {
  lotId: string;
}

const tabClassName = "tabs-class";

export function BuildLot() {
  const history = useHistory();
  const { lotId } = useParams<BuildLotRouteParams>();
  const { closeToast, enqueueToast } = useToasts();

  const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
  const [displaySerializationModal, setDisplaySerializationModal] = useState(false);
  const [activeTab, setActiveTab] = useState(LotTab.INVENTORY);

  const deleteLots = useDeleteLots();
  const updateProductions = useUpdateProductions();
  const { lot, lotError, lotLoading, lotRefetch } = useLotWithInstances(lotId);

  useEffect(() => {
    if (lotError?.message) {
      enqueueToast({
        body: `Lot ID ${lotId}`,
        message: "Failed to load the Lot",
        persist: true,
        variant: "error",
      });
    }
  }, [enqueueToast, lotError?.message, lotId]);

  const breadcrumbLabel = useMemo(() => {
    if (lot?.number.displayValue) return `Lot ${lot.number.displayValue}`;

    return "Lot";
  }, [lot?.number.displayValue]);

  const breadCrumbOptions = useBreadCrumb({
    icon: BreadCrumbIcon.LOT,
    label: breadcrumbLabel,
    url: history.location.pathname,
  });

  const { primaryRevision } = lot ?? {};
  const { source } = primaryRevision ?? {};
  const cpnLink = useMemo(() => {
    if (!source) return null;

    switch (source.type) {
      case SourceType.DURO_COMPONENT_REVISION:
        return `/component/revision/${source.extId}`;
      case SourceType.DURO_PRODUCT_REVISION:
        return `/product/revision/${source.extId}`;
      default:
        return source.url;
    }
  }, [source]);

  const tileData = useMemo(() => ({ cpnLink, lot }), [cpnLink, lot]);

  const onEditClicked: MouseEventHandler = useCallback(e => {
    // TODO: Open the edit serialization dialog on clicking edit.
    // This will open the serialization modal loaded with the settings used do create the lot.
  }, []);

  const primaryRevisionId = primaryRevision?.id;
  const primaryProductionId = primaryRevision?.production?.id;
  const isPrimaryBookmarked = primaryRevision?.production?.isBookmarked;
  const onBookmarkClicked: MouseEventHandler = useCallback(() => {
    if (!primaryProductionId) return;
    updateProductions([{ id: primaryProductionId, isBookmarked: !isPrimaryBookmarked, primaryRevisionId }])
      .catch(e =>
        enqueueToast({
          body: e.message,
          message: "Updating bookmarked status failed.",
          persist: true,
          variant: "error",
        }));
  }, [enqueueToast, isPrimaryBookmarked, primaryProductionId, primaryRevisionId, updateProductions]);

  const onDeleteClicked: MouseEventHandler = useCallback(e => {
    setDisplayDeleteModal(true);
  }, []);

  const onExportClicked: MouseEventHandler = useCallback(e => {
    // Opens the Export page with the lot contents loaded to the export table
  }, []);

  const onSerializeClicked = useCallback(() => {
    // Opens the serialization modal with the anchor CPN from the lot pre-loaded
    setDisplaySerializationModal((displayModal: boolean) => !displayModal);
  }, []);

  const onSubmitSerializationModal = useCallback(() => {
    setDisplaySerializationModal(false);
  }, []);

  // ToDo: Use the build information to serialize, instead of design information
  const getPayloadForSerialization = useMemo(() => ({
    id: primaryRevision?.id,
    alias: source?.type === SourceType.DURO_COMPONENT_REVISION
      ? ModelType.CMP : ModelType.PRD,
    build: true,
  }), [primaryRevision?.id, source?.type]);

  const toolbarItems = useMemo((): IToolbarActions[] => [
    {
      disabled: true,
      Icon: EditIcon,
      onClick: onEditClicked,
      tooltip: "Edit",
    },
    {
      disabled: true,
      active: isPrimaryBookmarked,
      Icon: BookmarkIcon,
      onClick: onBookmarkClicked,
      tooltip: "Bookmark",
    },
    {
      Icon: DeleteIcon,
      onClick: onDeleteClicked,
      tooltip: "Delete",
    },
    {
      disabled: true,
      Icon: ExportIcon,
      onClick: onExportClicked,
      tooltip: "Export",
    },
    {
      Icon: SerializationIcon,
      onClick: onSerializeClicked,
      tooltip: "Serialize",
    },
  ], [
    isPrimaryBookmarked,
    onBookmarkClicked,
    onDeleteClicked,
    onEditClicked,
    onExportClicked,
    onSerializeClicked,
  ]);

  const onHideDelete = useCallback(() => setDisplayDeleteModal(false), []);
  const onDeleteLot = useCallback(() => {
    setDisplayDeleteModal(false);
    const toastId = enqueueToast({
      message: `Deleting Lot ${lot?.number.displayValue}`,
      persist: true,
      showSpinner: true,
    });
    deleteLots([lotId])
      .then(() => {
        closeToast(toastId);
        enqueueToast({
          message: `Deleted Lot ${lot?.number.displayValue}`,
          customIcon: <LotIcon />,
        });
        history.push("/build/productions");
      })
      .catch(e => {
        closeToast(toastId);
        enqueueToast({
          body: e.message,
          message: "Deleting the lot failed.",
          persist: true,
          variant: "error",
        });
      });
  }, [closeToast, deleteLots, enqueueToast, history, lot?.number.displayValue, lotId]);

  const onTabClicked = useCallback((_event, tab) => {
    setActiveTab(tab);
  }, []);

  const inventoryActive = activeTab === LotTab.INVENTORY;
  const todoActive = activeTab === LotTab.TODO;

  return (
    <BuildPageWrapper
      breadCrumbOptions={breadCrumbOptions}
      isLoading={lotLoading}
      helmetTitle="Build Lot"
      toolbarProps={{ toolbarItems }}
    >
      <StyledTileRow configs={tileConfigs} data={tileData} />
      <StyledTabsWrapper>
        <TabList className="tabs-block" onChange={onTabClicked} value={activeTab}>
          <Tab
            className={tabClassName}
            Icon={InventoryIcon}
            iconPosition="start"
            isActive={inventoryActive}
            value={LotTab.INVENTORY}
          >Inventory View</Tab>
          <Tab
            className={tabClassName}
            disabled
            Icon={TodoIcon}
            iconPosition="start"
            isActive={todoActive}
            value={LotTab.TODO}
          >To Do</Tab>
        </TabList>
        <StyledTabPanel value={LotTab.INVENTORY} currentValue={activeTab}>
          {inventoryActive && (
            <LotInventoryView
              loading={lotLoading}
              lotRefresh={lotRefetch}
              instances={lot?.instances}
              primaryRevision={primaryRevision}
              revisions={lot?.revisions}
            />
          )}
        </StyledTabPanel>
        <StyledTabPanel value={LotTab.TODO} currentValue={activeTab}>
          {todoActive && (
            <LotInventoryView
              autoAssembleAvailable
              loading={lotLoading}
              lotRefresh={lotRefetch}
              instances={lot?.instances}
              primaryRevision={primaryRevision}
              revisions={lot?.revisions}
            />
          )}
        </StyledTabPanel>
      </StyledTabsWrapper>
      {displaySerializationModal && (
        <SerializationModal
          itemToBeSerialized={getPayloadForSerialization}
          onCancel={onSerializeClicked}
          onClose={onSerializeClicked}
          onSubmitCallback={onSubmitSerializationModal}
          open={displaySerializationModal}
          title="Serialize and Create New Lot"
        />
      )}
      <TransactionalModal
        onAccept={onDeleteLot}
        onCancel={onHideDelete}
        onClose={onHideDelete}
        open={displayDeleteModal}
        title="Delete Lot"
        variant="danger"
      >
        {`Do you want to delete Lot ${lot?.number.displayValue}? This action is permanent.`}
      </TransactionalModal>
    </BuildPageWrapper>
  );
}

const StyledTabsWrapper = styled(TabsWrapper)({
  display: "flex",
  flexDirection: "column",
  minHeight: "unset",
  paddingBottom: 0,
  "& .tabs-block": {
    marginBottom: 0,
    padding: "0 1.5rem",
    "& .tabs-class": {
      fontSize: "1rem",
      marginBottom: 0,
      padding: "0 0.625rem 0 1rem",
      "& .MuiSvgIcon-root": {
        fontSize: "inherit",
        marginRight: "0.5rem",
      },
      "& .MuiBadge-root": {
        width: "unset",
      },
    },
  },
});

const StyledTileRow = styled(TilesRow)({
  padding: "0 1.5rem",
  "& .MuiCard-root": {
    border: `0.125rem solid ${colorPalette.spanishGray}`,
    "& .MuiCardHeader-root": {
      background: "inherit",
      color: colorPalette.lightGrey,
      paddingBottom: 0,
    },
    "& .MuiCardContent-root": {
      paddingTop: 0,
      "& hr": {
        borderBottomStyle: "solid",
      },
    },
  },
});

const StyledTabPanel = styled(TabPanel)({
  backgroundColor: colorPalette.darkGrey,
  flex: 1,
  padding: "1.5rem",
});

// TODO: Get this configuration coming from the server.
const tileConfigs: TileConfiguration[] = [
  {
    content: [
      {
        type: TileContentType.DIVIDER,
      },
      // TODO: (PLA-365) Figure out how to display assembly status
      // {
      //   type: TileContentType.DIAL,
      //   label: "TODO: Assembly Status",
      //   label2: "Not Started",
      //   label3: "Completed",
      //   value: "25",
      // },
      // {
      //   type: TileContentType.DIVIDER,
      // },
      {
        type: TileContentType.LINK_IF_VALUE,
        label: "CPN",
        label2Path: "lot.primaryRevision.cpn",
        label2: "Mixed",
        valuePath: "cpnLink",
      },
      {
        type: TileContentType.TEXT,
        label: "Due Date",
        valuePath: "lot.dueDate",
        valueFormat: TileValueFormat.DATE,
      },
      {
        type: TileContentType.TEXT,
        label: "Production Run",
        valuePath: "lot.productionRun",
      },
      {
        type: TileContentType.TEXT,
        label: "Lot Labels",
        valuePath: "lot.labels",
        valueFormat: TileValueFormat.COMBINE,
        valueFormatSep: ", ",
      },
    ],
    icon: TileIcon.OVERVIEW,
    pinned: true,
    title: "Lot Overview",
  },
  {
    content: [
      {
        type: TileContentType.DIVIDER,
      },
      {
        type: TileContentType.TEXT,
        label: "Date Created",
        valuePath: "lot.createdDate",
        valueFormat: TileValueFormat.DATE,
      },
      {
        type: TileContentType.TEXT,
        label: "Created By",
        valuePath: ["lot.createdUser.firstName", "lot.createdUser.lastName"],
        valueFormat: TileValueFormat.COMBINE,
        valueFormatSep: " ",
      },
      {
        type: TileContentType.TEXT,
        label: "Last Modified",
        valuePath: "lot.lastModifiedDate",
        valueFormat: TileValueFormat.DATE,
      },
      {
        type: TileContentType.TEXT,
        label: "Modified By",
        valuePath: ["lot.lastModifiedUser.firstName", "lot.lastModifiedUser.lastName"],
        valueFormat: TileValueFormat.COMBINE,
        valueFormatSep: " ",
      },
      {
        type: TileContentType.DIVIDER,
      },
      {
        type: TileContentType.PARAGRAPH,
        label: "Notes",
        valuePath: "lot.notes",
      },
    ],
    icon: TileIcon.OVERVIEW,
    pinned: true,
    title: "Lot Details",
  },
];
