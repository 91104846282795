import  "./index.css";
import React, { Component } from "react";
import Tooltip from "rc-tooltip";
import WarningTriangle from "../../../../assets/icons/alert-icon.js"
import API from "../../../../modules/api/index.js";
import Spinner from "../../../ui/spinner";

export class DocumentRegenerateButton extends Component
{
    constructor(props)
    {
        super(props);
        this.regenerateDocument = this.regenerateDocument.bind(this);
    }

    regenerateDocument()
    {
        let {fileData, docId, updateDocState} = this.props;

        API.documents.regenerateDocuments(docId, fileData, (err, response) =>
            {
                if(err)
                {
                    return;
                }
            });

        updateDocState(docId);
    }

    render()
    {
        let isLoading   = this.props.docState === "PROCESSING";
        let icon        = <div onClick={isLoading ? null : this.regenerateDocument} data-place="top" className={!isLoading ? 'regenerate-button': 'regenerate-button-spinner'}>
                            {isLoading ? <Spinner iconClassName="spinner-wrapper-table"/> : <WarningTriangle/> }
                          </div>
        let markup      = isLoading ? icon :
                            <React.Fragment>
                              <Tooltip
                              placement="top"
                              overlay={"Regenerate document"}
                              overlayClassName={`default-styles document-regenerate-tooltip`}
                              arrowContent={<div className="rc-tooltip-arrow-inner"></div>}>
                                {icon}
                              </Tooltip>
                           </React.Fragment>
        return markup;
    }
}
