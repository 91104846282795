import { Box, Icon, styled, Tooltip } from "@mui/material";
import { CurrencyField } from "../../../common/components/fields/currency";
import AlertIcon from "v1/assets/icons/alert-icon";

export function UnitPriceField(props: { currencySymbol?: string, incompleteCost: boolean, unitPrice?: number }) {
  const { currencySymbol, incompleteCost, unitPrice } = props;
  return (
    <UnitPriceContainer>
      {incompleteCost && (
        <Tooltip title="Incomplete Sources" placement="top">
          <StyledIcon><AlertIcon /></StyledIcon>
        </Tooltip>
      )}
      {unitPrice && (
        <CurrencyField currencySymbol={currencySymbol} value={unitPrice} />
      )}
    </UnitPriceContainer>
  );
}

const UnitPriceContainer = styled(Box)(() => ({
  alignItems: "center",
  display: "flex",
  flex: "1",
  justifyContent: "space-between",
}));

const StyledIcon = styled(Icon)(() => ({
  fontSize: "15px",
  "& > svg": {
    fontSize: "10px",
    height: "15px",
    width: "15px",
  },
}));
