import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width="15px" height="15px" viewBox="0 0 15 15" {...props}>
      <g fillRule="nonzero" stroke="none" strokeWidth={1} fill="none">
        <rect fill="#3CD1B5" x={0} y={0} width={15} height={15} rx={7.5} />
        <path
          fill="#18171D"
          transform="matrix(-1 0 0 1 14 0)"
          d="M5.86938101 2.5L5.86938101 6.12362211 2 6.12362211 2 8.78395015 5.86938101 8.78395015 5.86938101 12.5 12 7.4998106z"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
