import { AppContext, AppSection } from "context";
import React, { useContext, useEffect } from "react";

export interface AppSectionProviderProps {
  section: AppSection
}

export const AppSectionProvider: React.FC<AppSectionProviderProps> = ({ section, children }) => {
  const { setSection } = useContext(AppContext);

  useEffect(() => {
    setSection(section);
  }, [section, setSection]);

  return <>{children}</>;
};
