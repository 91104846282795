import { SvgIcon } from "@mui/material";

export const ComponentIcon = () => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path d="M19.778 4.222v15.556H4.222V4.222h15.556m0-2.222H4.222C3 2 2 3 2 4.222v15.556C2 21 3 22 4.222 22h15.556C21 22 22 21 22 19.778V4.222C22 3 21 2 19.778 2zM6.444 15.333v2.223h11.112v-2.223H6.444zm0-2.222h7.408V10.89H6.444v2.222zm0-4.444h7.408V6.444H6.444v2.223z" fillRule="nonzero"/>
    </g>
  </SvgIcon>
);
