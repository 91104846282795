import                              "./index.css"
import React, {Component}           from "react"
import VariantIcon                  from "../../../../../assets/icons/variant-icon";
import InlineIcon                   from "../../../../ui/icon/inline-icon";

export class VariantIconHolder extends Component
{
    constructor(props)
    {
        super(props)
        this.openVariantModal = this.openVariantModal.bind(this);

        this.state = {
            VariantModal: {
                top: 0,
                left: 0
            }
        }
    }

    openVariantModal(e, result)
    {
        let state = this.state
        const primaryVariant = result;

        if (this.props.tableSelector){
            let element       = e.target
            let parentTableEl = document.getElementsByClassName(this.props.tableSelector)[0]

            var elementPos = element.getBoundingClientRect();
            var parentPos = parentTableEl.getBoundingClientRect();

            let top  = elementPos.top - parentPos.top
            let left = elementPos.left - parentPos.left
            left = this.props.leftFomular(left)
            state.VariantModal.top = top
            state.VariantModal.left = left
        }
        this.setState(state, () => this.props.openVariantModal(primaryVariant, state.VariantModal))
    }

    render()
    {
        let markup = <div className="variant-holder"
        >
            {this.props.children}
            <InlineIcon
            stopPropagation={true}
            onMouseOver={(e) => this.addHOverState(e)}
            onClick={
                (e) => {
                    this.openVariantModal(e, this.props.result)
                }
            }
            tooltip={this.props.toolTip}>
                <VariantIcon />
            </InlineIcon>
        </div>
        
        return markup;
    }
}

export default VariantIconHolder