import { useCallback, FC } from "react";
import { CoChildrenListIcon, ApproversIcon, NotififiersIcon, DocumentsIcon } from "assets/icons";
import { Tab, TabsWrapper, TabList, TabPanel } from "common/components/tabs";
import Utils from "v1/modules/utils";
import { useChangeOrderTabsContext } from "../changeOrderContextProvider";
import  { ChangeOrderTabValue }  from "../constants";

export const ChangeOrderEditTabs: FC = () => {
  const {
    currentTab,
    setCurrentTab,
  } = useChangeOrderTabsContext();

  const onTabClicked = useCallback((_event, tab) => {
    Utils.setStore("co-tab", tab);
    setCurrentTab(tab);
  }, [setCurrentTab]);

  const tabClassName = "tabs-class";

  return (
    <TabsWrapper className="tabs-block">
      <TabList value={currentTab} onChange={onTabClicked}>
        <Tab
          className={tabClassName}
          errorCount={0}
          Icon={CoChildrenListIcon}
          iconPosition="start"
          isActive={ChangeOrderTabValue.CMP_PRD === currentTab}
          value={ChangeOrderTabValue.CMP_PRD}
        >
          Components & Products
        </Tab>
        <Tab
          className={tabClassName}
          errorCount={0}
          Icon={ApproversIcon}
          iconPosition="start"
          isActive={ChangeOrderTabValue.APPROVERS === currentTab}
          value={ChangeOrderTabValue.APPROVERS}
        >
          Approvers
        </Tab>
        <Tab
          className={tabClassName}
          errorCount={0}
          Icon={NotififiersIcon}
          iconPosition="start"
          isActive={ChangeOrderTabValue.NOTIFIERS === currentTab}
          value={ChangeOrderTabValue.NOTIFIERS}
        >
          Notifiers
        </Tab>
        <Tab
          className={tabClassName}
          errorCount={0}
          Icon={DocumentsIcon}
          iconPosition="start"
          isActive={ChangeOrderTabValue.DOCUMENTS === currentTab}
          value={ChangeOrderTabValue.DOCUMENTS}
        >
          Documents
        </Tab>
      </TabList>
      <TabPanel value={ChangeOrderTabValue.CMP_PRD} currentValue={currentTab}>
        <></>  {/* Need to Handle */}
      </TabPanel>
      <TabPanel value={ChangeOrderTabValue.APPROVERS} currentValue={currentTab}>
        <></> {/* Need to Handle */}
      </TabPanel>
      <TabPanel value={ChangeOrderTabValue.NOTIFIERS} currentValue={currentTab}>
        <></> {/* Need to Handle */}
      </TabPanel>
      <TabPanel value={ChangeOrderTabValue.DOCUMENTS} currentValue={currentTab}>
        <></> {/* Need to Handle */}
      </TabPanel>
    </TabsWrapper>
  );
};
