import { client } from "graphql/apolloClient"
import { refetchComponents } from "graphql/query/componentQueries"
import Schemas from "../../schemas"
import Utils from "../../utils"

class ComponentAPI
{
    static create(data, cb)
    {
        Utils.post("/components", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static validateMpn(data, cb)
    {
        Utils.post("/components/mpn/validate", data, (err, res) =>
        {
            if(err)
            {
                return cb(err);
            }

            if(!res.success)
            {
                return cb(res);
            }

            return cb(null, res);
        })
    }

    static validateManufacturers(data, cb)
    {
        Utils.post("/components/manufacturers/validate", data, (err, res) =>
        {
            if(err)
            {
                return cb(err);
            }

            if(!res.success)
            {
                return cb(res);
            }

            return cb(null, res);
        })
    }


    static coExist(data, cb)
    {
        Utils.post("/components/co/exist", data, (err, res) =>
        {
            if(err)
            {
                return cb(err);
            }

            if(!res.success)
            {
                return cb(res);
            }

            return cb(null, res);
        })
    }

    static validateOctopartMpn(data, cb)
    {
        Utils.post("/components/mpn/validate/octopart", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            if(!res.success)
            {
                return cb(res)
            }

            return cb(null, res)
        })
    }

    static createWithData(payload, cb)
    {
        Utils.post("/components/bulk/create", payload, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }
            res.data.forEach((cmp, i) => {
                payload.components[i]._id = cmp._id
                payload.components[i].cpn = cmp.cpn
                payload.components[i].cpnVariant = cmp.cpnVariant
            })
            return cb(null, payload.components)
        })
    }

    static findById(id, cb)
    {
        Utils.get("/components/" + id, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static getItemsInBulk(data, cb)
    {
        Utils.post("/components/get/bulk", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res)
        })
    }

    static findByCpn(cpn, cb)
    {
        Utils.get("/components/cpn/" + cpn, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static nameExists(payload, cb, data={})
    {
        data.name = payload.name
        if (payload.id) data.id = payload.id

        Utils.post("/components/name/exists", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            if(!res.success)
            {
                return cb(res)
            }

            return cb(null, res.data)
        })
    }

    static bulkNameExists(names, cb)
    {
        Utils.post("/components/name/exists/bulk", {names}, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            if(!res.success)
            {
                return cb(res)
            }

            return cb(null, res.data)
        })
    }

    static bulkEidExists(data, cb)
    {
        Utils.post("/components/eid/exists/bulk", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            if(!res.success)
            {
                return cb(res)
            }

            return cb(null, res.data)
        })
    }

    static cpnExists(data, cb)
    {
        Utils.post("/components/cpn/exists", data , (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            if(!res.success)
            {
                return cb(res)
            }

            return cb(null, res.data)
        })
    }

    static eidExists(eid, cb, data={})
    {
        data = {...eid, ...data}
        Utils.post("/components/eid/exists", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            if(!res.success)
            {
                return cb(res)
            }

            return cb(null, res.data)
        })
    }

    static isValidDocumentUrl(urls, cb)
    {
        let data = {urls: urls}
        Utils.post("/documents/validate/url", data , (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            if(!res.success)
            {
                return cb(res)
            }

            return cb(null, res.data)
        })
    }

    static isValidImageUrl(urls, cb)
    {
        let data = {urls: urls}
        Utils.post("/images/validate/url", data , (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            if(!res.success)
            {
                return cb(res)
            }

            return cb(null, res.data)
        })
    }

    static update(id, data, cb)
    {
        Utils.post("/components/" + id, data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static setNextRevision(id, data, cb)
    {
        Utils.post("/components/" + id + "/set-next-revision", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static updateWithData(payload, cb)
    {
        Utils.post("/components/bulk/update", payload, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }
            res.data.forEach((cmp, i) => {
                payload.components[i].cpn = cmp.cpn
                payload.components[i].cpnVariant = cmp.cpnVariant
            })
            return cb(null, payload.components)
        })
    }

    static updateParent(data, cb=null)
    {
        Utils.post("/components/bulk/parent-update", data, (err, res) =>
        {
            if(cb)
                return cb(res)
            return;
        })
    }

    static export(type, components, emails, cc, cb)
    {
        components = components.map((cmp) =>
        {
            if(typeof cmp === "object")
            {
                return cmp._id || false
            }

            return cmp || false
        })

        let data =
        {
            type       : type,
            components : components,
            emails     : emails,
            cc         : cc
        }

        Utils.post("/components/export", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static addAliases(data, cb)
    {
        Utils.post("/components/aliases", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static list(cb)
    {
        Utils.get("/components", (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static parseFile(file, cb)
    {
        let path = "/services/files/parse"
        Utils.uploadFile(path, file, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

             return cb(null, res.data)
        })
    }

    static import(category, sku, name, addMore, cb)
    {
        let data =
        {
            category : category,
            sku      : sku,
            name     : name,
            addMore  : addMore
        }

        Utils.post("/components/web/import", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static getSource(category, sku, cb, fetchAllDistributors = true) {
        //Note: Encoding MPN before sending it to server so we can ignore the URI reserved words
        sku = encodeURIComponent(sku.toString().replace(/%/g,'~~pct~~'));

        Utils.get(`/components/source/${category}/${sku}?fetchAllDistributors=${fetchAllDistributors}`, (err, res) => {
            if(err) return cb(err);
            cb(null, res.data);
        })
    }

    static deleteById(id, deleteRequestBody, cb)
    {
        Utils.post("/components/delete/" + id, deleteRequestBody ,(err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static setPrimarySource(id, data, cb)
    {
        Utils.post("/components/" + id + "/set-primary-source", data ,(err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static checkIfMpnUsed(data, cb)
    {
        Utils.post("/components/mpn/checkMpnAlreadyUsed", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            if(!res.success)
            {
                return cb(res)
            }

            return cb(null, res)
        })
    }

    static validate(data, cb)
    {
        Utils.post('/components/bulk/validate', data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            if(!res.success)
            {
                return cb(res)
            }

            return cb(null, res)
        })
    }

    static getComponentChildrens(id, cb)
    {
        Utils.get("/components/" + id + "/getChilds" ,(err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            if(!res.success)
            {
                return cb(res)
            }

            return cb(null, res)
        });
    }

    static getChildrenInfo(id, data, cb)
    {
        Utils.post("/components/" + id + "/get/children/info", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            if(!res.success)
            {
                return cb(res)
            }

            return cb(null, res)
        });
    }

    static createDuplicate(data, cb)
    {
        Utils.post(`/components/duplicates/create-duplicate`, data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }
            return cb(null, res.data)
        })
    }

    static createVariant(id, cb)
    {
        Utils.post(`/components/${id}/create-variant`, {}, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }
            return cb(null, res.data)
        })
    }

    static getComponentVariants(id, data, cb)
    {
        Utils.post(`/components/${id}/variants`, data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }


    static addComponentToVariantGroup(rootId, addedVariants, removedVariants, cb)
    {
        let data =
        {
            rootId          : rootId,
            addedVariants   : addedVariants,
            removedVariants : removedVariants,
        }

        Utils.post(`/components/variants/add-to-variant-group`, data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static setVariantPermitted(rootId, childId, permittedVariants, cb)
    {
        let data =
        {
            rootId            : rootId,
            childId           : childId,
            permittedVariants : permittedVariants,
        }

        Utils.post(`/components/variants/set-permitted`, data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            refetchComponents(client, [rootId]);
            cb(null, res.data)
        })
    }

    static unlinkComponentFromVendor(componentId,cb)
    {
        Utils.get(`/components/unlink/${componentId}`, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }
            cb(null, res.data)
        })
    }
}

export default ComponentAPI
