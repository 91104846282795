import React from "react";

function Icon() {
  return (
    <svg
        width="22"
        height="22"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
    >
                <path
                      className='path'
                      fill="#CFCFCF"
                      fillRule="evenodd"
                      stroke="none"
                      strokeWidth="1"
                 d="M16,2C8.269,2,2,8.269,2,16c0,7.73,6.269,14,14,14c7.73,0,14-6.27,14-14C30,8.269,23.73,2,16,2z M16,28  C9.383,28,4,22.617,4,16S9.383,4,16,4s12,5.383,12,12S22.617,28,16,28z"></path>
                <path
                      className='path'
                      fill="#CFCFCF"
                      fillRule="evenodd"
                      stroke="none"
                      strokeWidth="1"
                d="M16,10.571c1.355,0,2.458,0.931,2.458,2.074c0,0.552,0.448,1,1,1s1-0.448,1-1c0-1.931-1.481-3.545-3.458-3.962V7.5  c0-0.552-0.448-1-1-1s-1,0.448-1,1v1.184c-1.977,0.417-3.458,2.031-3.458,3.962c0,1.801,1.068,3.278,2.79,3.854l2.704,0.896  c0.917,0.308,1.422,1.002,1.422,1.957c0,1.145-1.103,2.074-2.458,2.074c-1.355,0-2.458-0.931-2.458-2.074c0-0.552-0.448-1-1-1  s-1,0.448-1,1c0,1.932,1.481,3.545,3.458,3.963V24.5c0,0.552,0.448,1,1,1s1-0.448,1-1v-1.184c1.977-0.418,3.458-2.031,3.458-3.963  c0-1.801-1.067-3.277-2.79-3.854l-2.704-0.897c-0.917-0.307-1.422-1.002-1.422-1.957C13.542,11.502,14.645,10.571,16,10.571z"></path>

    </svg>
 );
}

export default Icon;
