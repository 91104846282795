import {
  createContext, FC, ReactNode, useCallback, useEffect, useState,
} from "react";
import { useImageList } from "graphql/query/imagesQueries";

export interface IContextData {
  setImageIds: (value: string[]) => void,
}

export interface IImagesContextProviderProps {
  children: ReactNode,
}

export const imageContext = createContext<IContextData>({
  setImageIds: _value => { },
});

export const ImageContextProvider: FC<IImagesContextProviderProps> = ({ children }) => {
  const [item, setItem] = useState<string[]>([]);
  const { fetchImageByIds } = useImageList();

  useEffect(() => {
    let timeoutId : NodeJS.Timeout;
    if (item.length) {
      timeoutId = setTimeout(() => {
        fetchImageByIds(item);
        setItem([]);
      }, 500);
    }
    return () => clearTimeout(timeoutId);
  }, [fetchImageByIds, item]);

  const setImageIds = useCallback(cmp => {
    setItem(prev => [...new Set([...prev, ...cmp])]);
  }, []);

  return <imageContext.Provider value={{ setImageIds }}>
    { children }
  </imageContext.Provider>;
};
