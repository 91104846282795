import React from "react";

function MaterialMetalIcon() {
  return (
    <svg
      width="19px"
      height="17px"
      viewBox="0 0 19 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="#FFF" strokeWidth={1} fill="none" fillRule="evenodd">
        <circle cx={10.5} cy={8.5} r={8} />
        <path d="M11 16.5L0 16.5" />
        <circle cx={10.5} cy={8.5} r={6} />
        <circle cx={10.5} cy={8.5} r={4} />
      </g>
    </svg>
  );
}

export default MaterialMetalIcon;