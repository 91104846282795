import { Button, Menu, MenuItem } from "@mui/material";
import { InputFieldVariants } from "common/constants";
import { MouseEvent, useCallback, useMemo, useState } from "react";
import { ToolBarItem } from "./gridToolbar";

export interface ToolbarOptionsItem {
  label: string;
  value: any;
}

export function ToolbarOptionsButton(props: Omit<ToolBarItem, "type" | "onClick">) {
  const { disabled, Icon, items, label, onOptionClick } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const onButtonClicked = useCallback((e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleMenuItemClick = useCallback((event: MouseEvent<HTMLElement>, value: any) => {
    onOptionClick?.(event, value);
    setAnchorEl(null);
  }, [onOptionClick]);

  const options = useMemo(() => (
    items?.map((item, index) => (
      <MenuItem key={index} onClick={e => handleMenuItemClick(e, item.value)}>{item.label}</MenuItem>
    ))
  ), [handleMenuItemClick, items]);

  return (
    <>
      <Button
        disabled={disabled}
        onClick={onButtonClicked}
        startIcon={Icon ? <Icon /> : null}
        variant={InputFieldVariants.OUTLINED}
      >
        {label}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {options}
      </Menu>
    </>
  );
}
