import { SvgIcon } from "@mui/material";

export const AutoAssembleIcon = () => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path d="m19.268 7.458-.864-1.866-1.868-.863 1.868-.84L19.268 2l.84 1.89L22 4.728l-1.891.863-.841 1.866zm-11.582 0-.84-1.866-1.892-.863 1.892-.84L7.686 2l.864 1.89 1.868.839-1.868.863-.864 1.866zm11.582 11.568-.864-1.866-1.868-.863 1.868-.84.864-1.889.84 1.89 1.892.84-1.891.862-.841 1.866zM4.674 21.685l-2.358-2.356c-.203-.202-.308-.427-.316-.676-.007-.25.098-.482.316-.7L12.823 7.458c.203-.203.452-.304.748-.304.295 0 .544.101.747.304l2.241 2.239a1 1 0 0 1 .304.734c0 .288-.101.54-.304.758L6.052 21.685c-.203.217-.432.322-.69.315a.995.995 0 0 1-.688-.315zm.607-1.54 7.239-7.23-1.448-1.446-7.239 7.23 1.448 1.446z" fillRule="nonzero" />
    </g>
  </SvgIcon >
);
