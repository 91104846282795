import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g
          fill="#888"
          fillRule="nonzero"
          stroke="#888"
          strokeWidth="0.5"
          transform="translate(-1126 -344)"
        >
          <g transform="translate(1102 80)">
            <g transform="translate(25 262)">
              <g transform="translate(0 3)">
                <path d="M4.436 0c-.19.016-.35.174-.353.345-.002.13 0 .258 0 .355-.022.523-.305.785-.917 1.012-.617.23-1.547.343-2.6.389-.167.007-.32.122-.36.268A7.486 7.486 0 000 4.2v1.926c0 1.393.768 2.785 1.987 4.109 1.22 1.323 2.903 2.585 4.8 3.709.126.073.3.073.426 0 1.897-1.124 3.58-2.386 4.8-3.71C13.233 8.913 14 7.52 14 6.128V4.201c0-.615-.056-1.242-.207-1.832-.038-.146-.192-.26-.358-.268-1.054-.046-1.984-.16-2.6-.389-.613-.227-.896-.489-.918-1.012V.69c0-.122.003-.161 0-.345C9.913.163 9.729 0 9.527 0H4.437h0zm.425.7H9.14v.011c.028.798.617 1.356 1.397 1.647.701.26 1.588.362 2.546.416.093.455.14.937.14 1.427v1.926c0 1.158-.661 2.412-1.81 3.66C10.306 10.986 8.76 12.166 7 13.228c-1.76-1.062-3.307-2.242-4.411-3.441C1.439 8.539.778 7.285.778 6.127V4.201c0-.49.047-.972.14-1.427.958-.054 1.845-.155 2.546-.416.78-.29 1.37-.849 1.397-1.647v-.01h0zm2.096 3.496c-.19.019-.35.183-.346.356v1.575H4.825c-.204.009-.38.183-.371.367.01.183.203.342.407.333h1.75v1.576c-.003.185.184.355.389.355.205 0 .392-.17.389-.355V6.827h1.75c.205.003.394-.165.394-.35 0-.185-.189-.353-.394-.35h-1.75V4.552c.005-.2-.212-.378-.432-.356z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
