
import Utils   from "../../utils"
import Schemas from "../../schemas"

class ProductAPI
{
    static create(data, cb)
    {
        Utils.post("/products", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static findById(id, cb)
    {
        Utils.get("/products/" + id, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static validateManufacturers(data, cb)
    {
        Utils.post("/products/manufacturers/validate", data, (err, res) =>
        {
            if(err)
            {
                return cb(err);
            }

            if(!res.success)
            {
                return cb(res);
            }

            return cb(null, res);
        })
    }

    static coExist(data, cb)
    {
        Utils.post("/products/co/exist", data, (err, res) =>
        {
            if(err)
            {
                return cb(err);
            }

            if(!res.success)
            {
                return cb(res);
            }

            return cb(null, res);
        })
    }

    static get_bulk(data, cb)
    {
        Utils.post("/products/get/bulk", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static findByCpn(cpn, cb)
    {
        Utils.get("/products/cpn/" + cpn, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static nameExists(data, cb)
    {
        Utils.post("/products/name/exists", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            if(!res.success)
            {
                return cb(res)
            }

            return cb(null, res.data)
        })
    }

    static validate(data, cb)
    {
        Utils.post('/products/bulk/validate', data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            if(!res.success)
            {
                return cb(res)
            }

            return cb(null, res)
        })
    }

    static cpnExists(data, cb)
    {
        Utils.post("/products/cpn/exists", data , (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            if(!res.success)
            {
                return cb(res)
            }

            return cb(null, res.data)
        })
    }

    static eidExists(data, cb)
    {
        Utils.post("/products/eid/exists", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            if(!res.success)
            {
                return cb(res)
            }

            return cb(null, res.data)
        })
    }

    static update(id, data, cb)
    {
        //continue with update
        Utils.post("/products/" + id, data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static setNextRevision(id, data, cb)
    {
        Utils.post("/products/" + id + "/set-next-revision", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }
    static getProductVariants(id, data, cb)
    {
        Utils.post("/products/" + id + "/variants", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static getFlattenedQuantities(data, cb)
    {
        Utils.post("/flattened_quantities", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static getFlattenedBomInBulk(data, cb)
    {
        Utils.post("/products/bulk/flattenedBom", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static getProductReports(id, data, cb)
    {
        Utils.post(`/products/${id}/reports`, data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static export(type, products, emails, cc, cb)
    {
        products = products.map((cmp) =>
        {
            if(typeof cmp === "object")
            {
                return cmp._id || false
            }

            return cmp || false
        })

        let data =
        {
            type     : type,
            products : products,
            emails   : emails,
            cc       : cc
        }

        Utils.post("/products/export", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static list(cb)
    {
        Utils.get("/products", (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static deleteById(id, deleteRequestBody, cb)
    {
        Utils.post("/products/delete/" + id, deleteRequestBody, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static setPrimarySource(id, data, cb)
    {
        Utils.post("/products/" + id + "/set-primary-source", data ,(err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static getProductChildrens(id, cb)
    {
        Utils.get("/products/" + id + "/getChilds" ,(err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            if(!res.success)
            {
                return cb(res)
            }

            return cb(null, res)
        });
    }

    static getChildrenInfo(id, data, cb)
    {
        Utils.post("/products/" + id + "/get/children/info", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            if(!res.success)
            {
                return cb(res)
            }

            return cb(null, res)
        });
    }

    static createDuplicate(data, cb)
    {
        Utils.post(`/products/duplicates/create-duplicate`, data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }
            return cb(null, res.data)
        })
    }


    static createVariant(id, cb)
    {
        Utils.post(`/products/${id}/create-variant`, {}, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }
            return cb(null, res.data)
        })
    }


    static addProductToVariantGroup(rootId, addedVariants, removedVariants, cb)
    {
        let data =
        {
            rootId          : rootId,
            addedVariants   : addedVariants,
            removedVariants : removedVariants,
        }

        Utils.post(`/products/variants/add-to-variant-group`, data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static setVariantPermitted(rootId, childId, permittedVariants, cb)
    {
        let data =
        {
            rootId            : rootId,
            childId           : childId,
            permittedVariants : permittedVariants,
        }

        Utils.post(`/products/variants/set-permitted`, data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

}

export default ProductAPI
