import React, { Component }      from "react"

const SvgIcon = () =>
{
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35.01">
            <title>search</title>
            <g>
                <g>
                    <path className="cls-10000" d="M35,33.71,23.45,22.16a13.38,13.38,0,1,0-1.3,1.3L33.7,35ZM5.21,21.54a11.54,11.54,0,1,1,8.16,3.38A11.56,11.56,0,0,1,5.21,21.54Z"/>
                    <rect className="cls-2" style={{fill: 'none'}} width="35" height="35"/>
                </g>
            </g>
        </svg>
}

export default SvgIcon
