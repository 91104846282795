import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { GridDataType } from "design/components/grid";
import { AssemblyChild, Component } from "design/models";
import { client } from "graphql/apolloClient";
import { getComponentWithChildren } from "graphql/query/componentQueries";
import { Dispatch, MutableRefObject, SetStateAction, useCallback, useEffect } from "react";
import { AssemblyView } from "../constants";
import { isCollapseDisabled, isExpandDisabled, useGridExpansion } from "common/components/grid";

/**
 * Adds additional fields to an assembly child for making it easy to display it
 * in tree mode.
 *
 * @param child The child component to convert.
 * @param parentPath The ancestry tree for the component.
 * @returns Data ready to be displayed in the grid table.
 */
export function childToGridData(child: AssemblyChild, parentPath: string[] = []): GridDataType {
  const { component, ...rest } = child;
  return {
    ...rest,
    item: component,
    _childrenLoaded: false,
    _descendantCount: child.component?.children?.length ?? 0,
    _path: parentPath.concat(child.component?.id ?? ""),
  };
}

/**
 * Loops over the children and adds additional fields to them so they are ready
 * for use in the grid tree view.
 *
 * @param children The children components to covert.
 * @param parentPath THe ancestry tree for the component.
 * @returns Data ready to be displayed in the grid table.
 */
export function childrenToGridData(children?: AssemblyChild[], parentPath: string[] = []): GridDataType[] {
  return children?.map(c => childToGridData(c, parentPath)) ?? [];
}

export interface UseOthersArgs {
  apiRef: MutableRefObject<GridApiPro>
  assemblyView: AssemblyView;
  component?: Component;
  componentLoading: boolean;
  gridData: GridDataType[];
  isEditing: boolean;
  setChildLoadingCount: Dispatch<SetStateAction<number>>;
}

export function useGridHelpers(args: UseOthersArgs) {
  const {
    apiRef,
    assemblyView,
    component,
    componentLoading,
    gridData,
    isEditing,
    setChildLoadingCount,
  } = args;

  const getExpansionChildren = useCallback(async (row: GridDataType) => {
    if (!row.item) return [];
    const res = await getComponentWithChildren(client, row.item.id);
    return childrenToGridData(res.component?.children, row._path);
  }, []);

  const {
    disableCollapse,
    disableExpand,
    setDisableCollapse,
    setDisableExpand,
    setVisibleRowCount,
    visibleRowCount,
  } = useGridExpansion({ apiRef, getExpansionChildren, setChildLoadingCount });

  // When the number of children or assembly view change then update the number
  // visible rows.
  useEffect(() => {
    setVisibleRowCount(component?.children?.length ?? 0);
  }, [assemblyView, component?.children?.length, setVisibleRowCount]);

  // When in edit mode, the size of the gridData collection is the number of
  // visible rows in the table.
  useEffect(() => {
    if (isEditing) setVisibleRowCount(gridData.length);
  }, [gridData, isEditing, setVisibleRowCount]);

  // When returning the tree view from grid or edit view, make sure that the
  // expand and collapse buttons are in the correct state.
  useEffect(() => {
    if (isEditing || assemblyView !== AssemblyView.TREE) return;

    setDisableCollapse(isCollapseDisabled(apiRef));
    setDisableExpand(isExpandDisabled(apiRef));
  }, [apiRef, assemblyView, isEditing, setDisableCollapse, setDisableExpand]);

  // When the component is done loading, set the initial expand state based off
  // of there being any children with their own children.
  useEffect(() => {
    if (componentLoading || !component?.children?.length) return;

    setDisableCollapse(true);
    setDisableExpand(!component.children.find(c => c.component?.children?.length));
  }, [component?.children, componentLoading, setDisableCollapse, setDisableExpand]);

  return { disableCollapse, disableExpand, visibleRowCount };
}
