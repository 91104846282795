import { BuildPageWrapper } from "build/components/pageWrapper";
import { BreadCrumbIcon, useBreadCrumb } from "common/components/breadcrumb";
import { privatePaths } from "v1/app/routes";

const BREADCRUMB_OPTIONS = {
  icon: BreadCrumbIcon.PRODUCTS,
  label: "Export",
  url: privatePaths.buildExport.pathname,
};

export function BuildExport() {
  const breadCrumbOptions = useBreadCrumb(BREADCRUMB_OPTIONS);

  return (
    <BuildPageWrapper
      breadCrumbOptions={breadCrumbOptions}
      helmetTitle="Build Export"
      toolbarProps={{
        toolbarItems: [],
      }}
    >
      This is the Dashboard!
    </BuildPageWrapper>
  );
}
