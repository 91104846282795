import Utils              from "../../../../../modules/utils"
import Icon               from "../../../../ui/icon"
import ExtendedTable      from "../../../common/extended-table"
import React, {Component} from "react"
import InlineIcon         from "../../../../ui/icon/inline-icon.js"
import AvatarIcon         from "../../../../../assets/icons/user-icon.js"
import Avatar             from "../../../../ui/avatar"
import TimeStampColumn    from "../../../common/timestamp-column";

class HistoryTable extends Component
{
    constructor(props)
    {
        super(props)

        let viewStyles;
        try
        {
            viewStyles = window.__userStyles.styles.changeOrderHistoryTable || {}
        }
        catch(error) {
            viewStyles = {}
        }

        this.state =
        {
            current : "date",
            currentSortItemAscending: false,
            headings :
            [
                {
                    key         : "user",
                    displayName : "USER",
                    tooltip     : "USER",
                    sortable    : true,
                    minWidth    : 170,
                    width       : Utils.getStyleValue(viewStyles, "user", "width", 200),
                    position    : Utils.getStyleValue(viewStyles, "user", "position", 0),
                    visibility  : Utils.getStyleValue(viewStyles, "user", "visibility", true),
                    headingClass: "user-col",
                    disabled    : true,
                    // expandAble  : false

                },
                {
                    key         : "date",
                    displayName : "DATE",
                    tooltip     : "DATE",
                    sortable    : true,
                    minWidth    : 60,
                    width       : Utils.getStyleValue(viewStyles, "date", "width", 105),
                    position    : Utils.getStyleValue(viewStyles, "date", "position", 1),
                    visibility  : Utils.getStyleValue(viewStyles, "date", "visibility", true),
                    disabled    : true,
                    // expandAble  : false

                },
                {
                    key         : "activity",
                    displayName : "ACTIVITY",
                    tooltip     : "ACTIVITY",
                    sortable    : true,
                    minWidth    : 60,
                    width       : Utils.getStyleValue(viewStyles, "activity", "width", 115),
                    position    : Utils.getStyleValue(viewStyles, "activity", "position", 2),
                    visibility  : Utils.getStyleValue(viewStyles, "activity", "visibility", true),
                    disabled    : false

                },
                {
                    key         : "comment",
                    displayName : "COMMENT",
                    tooltip     : "COMMENT",
                    sortable    : true,
                    minWidth    : 60,
                    width       : Utils.getStyleValue(viewStyles, "comment", "width", 240),
                    position    : Utils.getStyleValue(viewStyles, "comment", "position", 3),
                    visibility  : Utils.getStyleValue(viewStyles, "comment", "visibility", true),
                    disabled    : false

                }
            ]

        }

        this.getTableRows = this.getTableRows.bind(this);
    }

    getTableRows()
    {
        let list = this.props.co.history
        if ( !Array.isArray(list) )
            list = []
        let rows = list.map((item, i) =>
            {
                let user = item.user
                let created         = item.created;
                let createdToolTip  = created ? Utils.dateTimeWithLongFormat(created) : null;
                let cells =
                {
                    "user":
                    {
                        value        : user.firstName + " " + user.lastName,
                        tooltip      : user.firstName + " " + user.lastName,
                        cellClass    : "user-info-col",
                        displayValue : [
                        <Avatar
                            imageId={user.avatar}
                            index={i}
                            imagesWithSrc={this.props.imagesWithSrc}
                            key={Utils.generateUniqueId()}
                            />,
                            <span
                                className="user-name"
                                data-tip=""
                                key={i + '-name'}
                                onMouseOver={(e) => Utils.checkToolTipAppearance(e, user.name)}
                            >{user.firstName + " " + user.lastName}</span>
                        ]
                    },
                    "date":
                    {
                        value        : created,
                        tooltip      : created ? `${createdToolTip.dateValue} ${createdToolTip.timeValue}` : '',
                        displayValue : created && <TimeStampColumn key={Utils.generateUniqueId()} format='date-time-with-long-format' value={created} />
                    },
                    "activity":
                    {
                        value        : item.action,
                        tooltip      : item.action,
                        displayValue :
                            <span className={"resolution-label " + item.action}>
                                {item.action}
                            </span>
                    },
                    "comment":
                    {
                        value        : item.comment,
                        displayValue : item.comment,
                        tooltip      : item.comment
                    }
                }
                return cells
            })
        return rows

    }
    render()
    {
        let headings = this.state.headings
        let rows = this.getTableRows()

        let markup  =
            <ExtendedTable
                wrapperClassName="co-history-table"
                wrapperSelectorClass="co-history-table"
                stylesName="changeOrderHistoryTable"
                themeDataTableId={"co-history-table"}
                headings={headings}
                rows={rows || []}
                currentSortItem={this.state.current}
                currentSortItemAscending={this.state.currentSortItemAscending}
                includeToolBar={true}
                resultText={`${rows.length} results`}
            />
        return markup
    }
}

export default HistoryTable
