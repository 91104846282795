import                              "../index.css"
import React, { Component }         from    "react"
import stepsData                    from    "../step-tool-tip-content/stepsData"

class VideoModalContent extends Component
{
    render()
    {
        let step = stepsData.steps[this.props.stepNumber]
        let markup =
            <div className="flexbox-parent">
                <div className="video-holder flexbox-item header">
                    <iframe
                        src={`${step.videoLink}?rel=0&modestbranding=1`}
                        width="864"
                        height="486"
                        frameBorder="0"
                        allowFullScreen="true"
                        title="video"
                    >
                    </iframe>
                </div>

            </div>
        return markup
    }
}

export default VideoModalContent
