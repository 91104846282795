import Utils        from "../../utils"
import Query        from "../../query"
import Config       from "../../config"

function  search(query, page, sort, cb)
{
    cb = arguments[arguments.length - 1]

    let keyEmpty = false
    let isFromVariantModal = false;

    if(typeof query === "string")
    {
        keyEmpty = query.split(":").includes("empty") ? true : false
        query = Query.parse(query)
    }

    if(typeof page !== "number" && typeof page !== "string")
    {
        page = 1
    }

    if(typeof sort !== "object")
    {
        sort = {cpn : 1}
    }

    if(query.limit === -99)
    {
        query.limit = 0
    }

    else if(!query.limit)
    {
        query.limit = Config.MAX_RESULTS
    }

    if (sort === null)
    {
       sort = sort = {cpn : 1}
    }

    let queryClone = Utils.clone(query)
    let reverse = Object.values(sort)[0]
    let queryObject = {}

    queryObject.page = page
    queryObject.sort = Object.keys(sort)[0]

    queryObject.reverse = reverse > 0 ? true : false
    queryObject.limit = parseInt((queryClone.limit))
    queryObject.lean = queryClone.lean || false

    delete queryClone.type
    delete queryClone.limit
    delete queryClone.without_co
    delete queryClone.is_export
    delete queryClone.response_type

    if(queryClone.hasOwnProperty("lean"))
    {
        delete queryClone.lean
    }

    if (queryClone.exclude)
    {
        queryObject.exclude = queryClone.exclude
        delete queryClone.exclude
    }

    if(query.hasOwnProperty("is") || query.hasOwnProperty("not"))
    {
        queryObject.and = {}
        if(query.hasOwnProperty("is"))
        {
            queryObject.and.is = query.is
            delete queryClone.is
        }

        if(query.hasOwnProperty("not"))
        {
            queryObject.and.not = query.not
            delete queryClone.not
        }

    }

    if(query.hasOwnProperty("populate"))
    {
        queryObject.populate = query.populate
        delete queryClone.populate
    }

    if (Object.keys(queryClone).length > 0)
    {
        if((keyEmpty || Boolean(queryClone.empty)) && !query.hasOwnProperty("fromVariantModal"))
        {
            delete queryClone.empty
            queryObject.or = queryClone
        }
        else
        {
            let queryOr  = {}
            let queryAnd = {}
            queryAnd["or"] = {}

            if(query.hasOwnProperty("fromVariantModal") && (keyEmpty || Boolean(queryClone.empty)))
            {
                isFromVariantModal = true;
                queryObject.isFromVariantModal = true;
                delete queryClone.empty;
                queryClone.fromVariantModal = query.fromVariantModal;
                delete queryClone.fromVariantModal;
            }

            Object.keys(queryClone).map((key) =>
            {
                if (query.categoryFilter) {
                    queryAnd["or"][key] = key === "category" ? queryClone[key] : null;
                }
                else if(key === "desc" || key === "description" || queryClone[key] instanceof Array)
                {
                    queryAnd["or"][key] = queryClone[key]
                }
                else if(isFromVariantModal && key !== "category")
                {
                    queryAnd["or"][key] = queryClone[key]
                }
                else
                {
                    queryAnd[key] = queryClone[key]
                }
            })
            queryObject.or  = queryObject.hasOwnProperty("or")  ? {...queryObject.or, ...queryOr }   : queryOr
            queryObject.and = queryObject.hasOwnProperty("and") ? {...queryObject.and, ...queryAnd } : queryAnd
        }
    }

    if (query.type === "prd")
    {
        Utils.post("/search/products", queryObject, (err, res) =>
        {
            if(err)
            {
                if (err.errors[0].message === "Invalid Query")
                {
                    return cb(null, {count: 0, results: []})
                }
                else
                {
                    return cb(err)
                }
            }
            cb(null, res.data )
        })
    }

    if (query.type === "cmp")
    {
        if(query.response_type)
        {
            queryObject.response_type = "assemblyEdit";
        }

        Utils.post("/search/components", queryObject, (err, res) =>
        {
            if(err)
            {
                if (err.errors[0].message === "Invalid Query")
                {
                    return cb(null, {count: 0, results: []})
                }
                else
                {
                    return cb(err)
                }
            }
            cb(null, res.data )
        })
    }

    if (query.type === "co")
    {
        Utils.post("/search/changeorders", queryObject, (err, res) =>
        {
            if(err)
            {
                if (err.errors[0].message === "Invalid Query")
                {
                    return cb(null, {count: 0, results: []})
                }
                else
                {
                    return cb(err)
                }
            }
            cb(null, res.data )
        })
    }

    if (query.type === "rel")
    {
        Utils.post("/search/releases", queryObject, (err, res) =>
        {
            if(err)
            {
                if (err.errors[0].message === "Invalid Query")
                {
                    return cb(null, {count: 0, results: []});
                }
                else
                {
                    return cb(err)
                }
            }
            cb(null, res.data )
        })
    }

    if (query.type === "all" || !query.type )
    {
        if (query.without_co)
        {
            queryObject.without_co = true
        }
        if(query.is_export)
        {
            queryObject.is_export = true;
        }
        if(query.response_type)
        {
            queryObject.response_type = "changeOrderEdit";
        }
        Utils.post("/search", queryObject, (err, res) =>
        {
            if(err)
            {
                if (err.errors[0].message === "Invalid Query")
                {
                    return cb(null, {count: 0, results: []})
                }
                else
                {
                    return cb(err)
                }
            }
            cb(null, res.data )
        })
    }

}

export default search
