import React         from "react"
import Link          from "../link"

const McMasterDisconnectedToolTip = () => {
    return <span>
                Contact <a href="mailto:Info@durolabs.co"> info@durolabs.co </a> to learn more about Mechanical Vendor integrations
           </span>
}

export default McMasterDisconnectedToolTip
