import { ComponentIcon, EditIcon, FileImportIcon, ManualCreateIcon, VendorImportIcon } from "assets/icons";
import { EmptyTableProps } from "common/components/grid";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import DisplaySidebarActions from "v1/action-types/display-sidebar";
import { privatePaths } from "v1/app/routes";

export interface UseNoRowsOverlayArgs {
  id: string;
  isEditing: boolean;
  setDisplayFileImport: (display: boolean) => void;
  setDisplayVendorModal: (display: boolean) => void;
  setDisplayManualModal: (display: boolean) => void;
}

export const NO_ROWS_HEIGHT = 285;

export function useNoRowsOverlay(args: UseNoRowsOverlayArgs): EmptyTableProps {
  const { id, isEditing, setDisplayFileImport, setDisplayVendorModal, setDisplayManualModal } = args;

  const dispatch = useDispatch();
  const history = useHistory();

  const onEnterEdit = useCallback(() => {
    history.push(`/component/edit/${id}`);
  }, [history, id]);

  const showHideAssemblySearch = useCallback(
    () => dispatch({ type: DisplaySidebarActions.SET_LIBRARY_FLAG }),
    [dispatch],
  );

  return useMemo(() => {
    const footer = (
      <>
        Read our <Link to={privatePaths.docs.quickstart} target="_blank"> Quick Start Guide </Link>
        and < Link to={privatePaths.docs.documentation} target="_blank" > Documentation </Link >
        to get up and running.
      </>
    );

    if (isEditing) {
      return {
        footer,
        tiles: [
          {
            buttonLabel: "Add from Library",
            Icon: ComponentIcon,
            text: "Add existing components from your library.",
            onClick: showHideAssemblySearch,
          },
          {
            buttonLabel: "Import Spreadsheet",
            Icon: FileImportIcon,
            text: "Import components from a spreadsheet.",
            onClick: () => setDisplayFileImport(true),
          },
          {
            buttonLabel: "Import from Vendor",
            Icon: VendorImportIcon,
            text: "Import components from Vendor.",
            onClick: () => setDisplayVendorModal(true),
          },
          {
            buttonLabel: "Create Component",
            Icon: ManualCreateIcon,
            text: "Create and add components manually.",
            onClick: () => setDisplayManualModal(true),
          },
        ],
      };
    }

    return {
      footer,
      tiles: [
        {
          buttonLabel: "Edit",
          Icon: EditIcon,
          text: "Edit to add components to your assembly.",
          onClick: onEnterEdit,
        },
      ],
    };
  }, [
    isEditing,
    onEnterEdit,
    setDisplayFileImport,
    setDisplayVendorModal,
    setDisplayManualModal,
    showHideAssemblySearch,
  ]);
}
