import { Typography, styled } from "@mui/material";
import { InstanceState } from "build/models";
import { useMemo } from "react";

const StateText = styled(Typography)<{ darken: boolean }>(({ darken, theme }) => ({
  color: darken ? theme.palette.text.disabled : theme.palette.text.primary,
}));

const darkenStates = [InstanceState.NOT_STARTED];

export function InstanceStateField({ state }: { state?: InstanceState }) {
  const displayValue = useMemo(() => {
    switch (state) {
      case InstanceState.ASSEMBLED: return "Assembled";
      case InstanceState.AVAILABLE: return "Available";
      case InstanceState.CONSUMED: return "Consumed";
      case InstanceState.IN_PROGRESS: return "In Progress";
      case InstanceState.NOT_STARTED: return "Not Started";
      case InstanceState.SCRAPPED: return "Scrapped";
      default: return "";
    }
  }, [state]);

  const darken = useMemo(() => !!state && darkenStates.includes(state), [state]);

  return <StateText darken={darken} variant="body2">{displayValue}</StateText>;
}
