import mapActions from '../helpers/mapActions';

export default mapActions(
  [
    'SUBMIT_USER_FORM',
    'UPDATE_INPUT_ON_CHANGE',
    'UPDATE_FORM_STATE',
    'FORM_SUBMISSION_STATE',
    'RESET_FORM',
    'SET_API_ERRORS',
    'VALIDATE_USER_FORM',
    'UPDATE_INNER_LOADING_FLAG',
    'RESET_THESE_FIELDS',
    'SET_USER_FROM_INPUTS',
    'SUBMIT_INVITE_USER'
  ],
  'USER_FORM'
  );
