import mapActions from '../helpers/mapActions';

export default mapActions(
  [
    'UPDATE_CHILDREN',
    'ON_INPUT_CHANGE',
    'SET_CHILDREN',
  ],
  'ASSEMBLY_EDIT'
);
