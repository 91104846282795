import { handleActions } from 'redux-actions'
import initialState, { getInitialState }    from "./initial_state"
import DISPLAYSIDEBAR    from "../../action-types/display-sidebar"

function toggleLibrarySideBar(state=initialState, action)
{
    let display = action.payload && action.payload.display
    state.side_bar_flag = display !== undefined ? display : !state.side_bar_flag
    state.file_import_bar_flag = false
    return {...state}
}

function hideSidebars(state=initialState)
{
    state.side_bar_flag = false
    state.file_import_bar_flag = false
    return {...state}
}


export default handleActions({
    [DISPLAYSIDEBAR.SET_LIBRARY_FLAG]: toggleLibrarySideBar,
    [DISPLAYSIDEBAR.HIDE_SIDE_BARS]: hideSidebars
}, initialState);
