import                       "./index.css"
import React            from "react"
import Utils            from "../../../../../../modules/utils"
import Tooltip          from 'rc-tooltip'
import Permissions      from "../../../../../../modules/schemas/permissions"
import                  'rc-tooltip/assets/bootstrap_white.css'
import LazyInput        from "../../../../../ui/lazy-input/input.js"
import API              from "../../../../../../modules/api";
import Spinner          from "../../../../../../assets/icons/spinner-green.js";
import InlineIcon       from "../../../../../ui/icon/inline-icon.js";
import validations,{validateField} from '../../../../../../modules/validations';
import Link             from "../../../../../ui/link"
import HelpIcon         from "../../../../../../assets/icons/help-icon.js"


export class WebhookFormView extends React.Component
{
    constructor(props, context)
    {
      super(props, context);
      this.onSubmit          = this.onSubmit.bind(this);
      this.onEdit            = this.onEdit.bind(this);
      this.onCancel          = this.onCancel.bind(this);
      this.onInputChange     = this.onInputChange.bind(this);
      this.toggleAddMore     = this.toggleAddMore.bind(this);
      this.onModeChange      = this.onModeChange.bind(this);
      this.haveEventType     = this.haveEventType.bind(this);
      this.onEventChanged    = this.onEventChanged.bind(this);
      this.resetFields       = this.resetFields.bind(this);
      this.disableSaveButton = this.disableSaveButton.bind(this);
      this.setEditForm       = this.setEditForm.bind(this);
      this.includeComponentProductData = this.includeComponentProductData.bind(this);

      this.state =
      {
        //Note: currently add more feature is not enabled. we can add or remove later.
        addMore: false,
        disableSaveButton: true,
        showInnerLoading: false,
        modified: false,
        inputs:
        {
          eventTypes: [],
          mode: "LIVE",
          status: 'Enabled',
          includeComponentProductData: false,
          description :
          {
              value : "",
              valid : true,
              class: "",
              message: ""
          },
          webhookUrl :
          {
              value : "",
              valid : true,
              class: "",
              message: ""
          },
        }
      }
    }

    componentWillMount()
    {
      let state = this.state;
      if(this.props.mode === 'edit' && this.props.data)
      {
        this.setEditForm(this.props.data);
      }
    }

    setEditForm(data)
    {
      let state = this.state;
      let inputs = state.inputs;
      inputs.eventTypes        = data.eventTypes;
      inputs.status            = data.status;
      inputs.mode              = data.mode;
      inputs.webhookUrl.value  = data.webhookUrl;
      inputs.description.value = data.description;
      inputs.includeComponentProductData= data.includeComponentProductData;
      this.setState(state);
    }

    onInputChange(event)
    {
      let name  = event.target.name
      let value = event.target.value
      let { inputs } = this.state;
      switch(name)
      {
        case "webhookUrl":
          validateField(inputs.webhookUrl, validations.webhook.webhookUrl, value)
        break;

        case "description":
          validateField(inputs.description, validations.webhook.description, value)
        break;
      }
      this.disableSaveButton();
      this.setState({inputs: inputs, modified: true})
    }

    disableSaveButton()
    {
      let state  = this.state;
      let inputs = state.inputs;
      if(inputs.webhookUrl.value  && inputs.webhookUrl.valid  &&
         inputs.description.valid && inputs.eventTypes.length)
      {
        state.disableSaveButton = false;
      }
      else
      {
        state.disableSaveButton = true;
      }
      this.setState(state);
    }

    haveEventType(event)
    {
      return this.state.inputs.eventTypes.includes(event)
    }

    onSubmit()
    {
      let state  = this.state;
      let inputs = state.inputs;
      state.showInnerLoading = true;
      this.setState(state);
      API.webhooks.registerWebhook(inputs , (err, data) =>
      {
        if(data)
        {
          if(this.state.addMore)
          {
            this.resetFields();
          }
          else
          {
            state.showInnerLoading = false
            this.setState(state)
            this.props.onCancel();
          }
          this.props.addNewToWebhooks(data);
        }
      });
    }

    onEdit()
    {
      let state  = this.state;
      const id  = this.props.data._id;
      let inputs = state.inputs;
      state.showInnerLoading = true;
      this.setState(state);
      API.webhooks.updateWebhook(id, inputs , (err, data) =>
      {
        if(data)
        {
          state.showInnerLoading = false
          this.setState(state)
          this.onCancel();
          this.props.updateWebhook(data);
        }
      });
    }

    resetFields()
    {
      let state  = this.state;
      state.addMore = false;
      let inputs = state.inputs;
      inputs.eventTypes = [];
      inputs.description.value   = ""
      inputs.description.valid   = false
      inputs.description.class   = ""
      inputs.description.message = ""
      inputs.webhookUrl.value    = ""
      inputs.webhookUrl.valid    = false
      inputs.webhookUrl.class    = ""
      inputs.webhookUrl.message  = ""
      inputs.mode                = "LIVE"
      inputs.includeComponentProductData  = false
      this.setState(state);
    }

    onEventChanged(event)
    {
      let inputs = this.state.inputs;
      let events = inputs.eventTypes;
      let index;
      let value = event.target.value
      if (event.target.checked) {
        events.push(value)
      } else {
        index = events.indexOf(value)
        events.splice(index, 1)
      }
      this.disableSaveButton();
      this.setState({ inputs: inputs , modified: true})
    }

    onCancel(event)
    {
      this.props.onCancel()
    }

    toggleAddMore(){
      this.state.addMore = !this.state.addMore
      this.setState(this.state)
    }

    onModeChange(event)
    {
      let state = this.state
      state.inputs.mode = event.target.name
      state.modified = true
      this.disableSaveButton();
      this.setState(state)
    }

    onStatusChange(event)
    {
      let state = this.state
      state.inputs.status = event.target.name
      state.modified = true
      this.disableSaveButton();
      this.setState(state)
    }

    includeComponentProductData(){
      let state = this.state;
      state.inputs.includeComponentProductData = !state.inputs.includeComponentProductData
      state.modified = true
      this.disableSaveButton();
      this.setState(state)
    }

    _renderEventCheckbox(eventType, label) {
        return <div>
            <input
                type="checkbox"
                name="event"
                value={eventType}
                checked={this.haveEventType(eventType)}
                onChange={this.onEventChanged}
                className={""}
            />
            <label
                htmlFor="event"
            />
            <span className="label_value">
                {label}
            </span>
        </div>;
    }

    render()
    {
        let inputs = this.state.inputs;
        let markup =

            <div className="new-webhook-form-block">
                <div className="heading-holder">
                  <h1>{this.props.mode === 'edit' ? "Edit Webhook" : "New Webhook"}</h1>
                  <Link
                      to={'https://public-api.dev-gcp.durolabs.xyz/v1/docs/'}
                      target="_blank"
                      >
                    <InlineIcon>
                        <HelpIcon/>
                    </InlineIcon>
                  </Link>
                </div>
                <label htmlFor="webhookUrl">WebhookUrl *</label>
                <Tooltip
                  overlay={inputs.webhookUrl.message}
                  overlayClassName={`error ${inputs.webhookUrl.message.length > 0 ? "" : "hidden"}`}
                  getTooltipContainer={() => document.querySelector("#routes")}>
                  <LazyInput
                    name="webhookUrl"
                    placeholder="Webhook Url"
                    value={inputs.webhookUrl.value}
                    onChange={this.onInputChange}
                    className={inputs.webhookUrl.class}
                  />
                </Tooltip>

                <label htmlFor="description">Description</label>
                <Tooltip
                  overlay={inputs.description.message}
                  overlayClassName={`error ${inputs.description.message.length > 0 ? "" : "hidden"}`}
                  getTooltipContainer={() => document.querySelector("#routes")}>
                  <LazyInput
                    name="description"
                    placeholder="Description"
                    className={inputs.description.class}
                    value={inputs.description.value}
                    onChange={this.onInputChange}
                  />
                </Tooltip>

                <div className="eventTypes">
                  <label
                    htmlFor="eventTypes"
                    className='muted-text'>
                    Event Types
                  </label>

                  <div className="flex-inputs">
                    {this._renderEventCheckbox("co.Approved", "Change Order Approved")}
                    {this._renderEventCheckbox("cmp.Created", "Component Created")}
                  </div>
                  <div className="flex-inputs">
                    {this._renderEventCheckbox("co.Submitted", "Change Order Submitted")}
                    {this._renderEventCheckbox("cmp.Updated", "Component Updated")}
                  </div>
                  <div className="flex-inputs">
                    {this._renderEventCheckbox("co.Rejected", "Change Order Rejected")}
                    {this._renderEventCheckbox("cmp.Deleted", "Component Deleted")}
                  </div>
                </div>
                <div className="bottom-section">
                <div className="mode-block">
                    <div className="item-label">
                        <div className="label-heading">
                            Mode:
                        </div>

                        <div className="mode-holder">
                            <input
                                type="checkbox"
                                name="LIVE"
                                checked={this.state.inputs.mode === "LIVE"}
                                data-tip=""
                                data-place = "top"
                                onChange={(e) => this.onModeChange(e)}
                            />
                            <label htmlFor="Live" />
                            <span>LIVE</span>
                        </div>

                        <div className="mode-holder">
                            <input
                                type="checkbox"
                                name="SANDBOX"
                                checked={this.state.inputs.mode === "SANDBOX"}
                                data-tip=""
                                data-place = "top"
                                onChange={(e) => this.onModeChange(e)}
                            />
                            <label htmlFor="SandBox" />
                            <span>SANDBOX</span>
                        </div>
                    </div>
                </div>
                {this.props.mode === 'edit' ?
                  <div className="status-block">
                    <div className="item-label">
                        <div className="label-heading">
                            Status:
                        </div>

                        <div className="status-holder">
                            <input
                                type="checkbox"
                                name="Enabled"
                                checked={this.state.inputs.status === "Enabled"}
                                data-tip=""
                                data-place = "top"
                                onChange={(e) => this.onStatusChange(e)}
                            />
                            <label htmlFor="Enabled" />
                            <span>Enabled</span>
                        </div>

                        <div className="status-holder">
                            <input
                                type="checkbox"
                                name="Disabled"
                                checked={this.state.inputs.status === "Disabled"}
                                data-tip=""
                                data-place = "top"
                                onChange={(e) => this.onStatusChange(e)}
                            />
                            <label htmlFor="Disabled" />
                            <span>Disabled</span>
                        </div>
                    </div>
                  </div> : null
                }
                </div>
                <div>
                 <input
                    type="checkbox"
                    name="event"
                    checked={this.state.inputs.includeComponentProductData}
                    onChange={this.includeComponentProductData}
                    />
                    <label
                        htmlFor="event"
                    />
                    <span className="label_value">
                      Include all Components and Products data
                    </span>
                </div>
                <hr/>
                <div className="button-sections">
                  <button
                      type="button"
                      value="cancel"
                      onClick={this.onCancel}
                      className="empty">
                    Cancel
                  </button>

                  {/*<div>
                    <input
                        type="checkbox"
                        name="add_more"
                        checked={this.state.addMore}
                        onClick={this.toggleAddMore}
                    />
                    <label
                        htmlFor="event"
                    />
                    <span className="label_value">
                      Add Another
                    </span>
                  </div>*/}

                  <button
                      type="submit"
                      value="Save"
                      className={this.state.disableSaveButton || !this.state.modified ? "disabled" : ""}
                      onClick={this.props.mode === 'edit'? this.onEdit : this.onSubmit}
                  >
                  <span className={this.state.showInnerLoading ? "label": ""}>Save</span>
                  {
                      this.state.showInnerLoading ?
                      <div className="spin-block">
                          <div className="spinner">
                              <InlineIcon>
                                  <Spinner/>
                              </InlineIcon>
                          </div>
                      </div>
                      : null
                  }
                  </button>
                </div>
            </div>
        return markup
    }
}

export default WebhookFormView
