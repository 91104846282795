import { styled, SvgIcon as MuiSvgIcon } from "@mui/material";

export const CheckboxUnfilledIcon = () => (
  <SvgIcon viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.5 21c-.4 0-.75-.15-1.05-.45-.3-.3-.45-.65-.45-1.05v-15c0-.4.15-.75.45-1.05.3-.3.65-.45
    1.05-.45h15c.4 0 .75.15 1.05.45.3.3.45.65.45
    1.05v15c0 .4-.15.75-.45 1.05-.3.3-.65.45-1.05.45h-15zm0-1.5h15v-15h-15v15z"
    />
  </SvgIcon>
);

const SvgIcon = styled(MuiSvgIcon)({
  fontSize: "1rem",
});
