import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="19"
      height="9"
      viewBox="0 0 19 9"
    >
      <defs>
        <path
          id="path-1"
          d="M13.411 6.868a.482.482 0 010-.642l2.076-2.245H5.415c-.237 0-.415-.208-.415-.449v-.048c0-.257.193-.45.415-.45h10.072L13.411.775a.482.482 0 010-.642.399.399 0 01.593 0l2.907 3.143a.328.328 0 010 .45l-2.907 3.143a.399.399 0 01-.593 0z"
        ></path>
        <path
          id="path-2"
          d="M8.411 6.868a.482.482 0 010-.642l2.076-2.245H.415C.178 3.981 0 3.773 0 3.532v-.048c0-.257.193-.45.415-.45h10.072L8.411.775a.482.482 0 010-.642.399.399 0 01.593 0l2.907 3.143a.328.328 0 010 .45L9.004 6.868a.399.399 0 01-.593 0z"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fillRule="nonzero" transform="translate(-482 -89)">
          <g transform="translate(270 70)">
            <g>
              <g transform="translate(213 20)">
                <g>
                  <use
                    fill="#D9EAD9"
                    style={{ mixBlendMode: "multiply" }}
                    xlinkHref="#path-1"
                  ></use>
                  <use stroke="#9DAC9D" xlinkHref="#path-1"></use>
                </g>
                <g transform="rotate(-180 6 3.5)">
                  <use
                    fill="#D9EAD9"
                    style={{ mixBlendMode: "multiply" }}
                    xlinkHref="#path-2"
                  ></use>
                  <use stroke="#9DAC9D" xlinkHref="#path-2"></use>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
