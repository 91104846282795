
import Utils from "../../utils"
import Schemas from "../../schemas"

const ImageAPI =
{

    findById : (id, cb) =>
    {
        Utils.get("/images/" + id, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    },

    create : (img, cb) =>
    {
        //create image
        Utils.uploadFile("/images", img, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })

    },

    update : (id, data, cb) =>
    {
        Utils.post("/images/" + id, data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    },

    getImagesBulk : (data, cb) =>
    {
        Utils.post("/images/getImagesBulk", {data}, (err, res) =>
        {
            if(err)
            {
                return cb(err);
            }
            cb(null, res.data);
        });
    }
}

export default ImageAPI
