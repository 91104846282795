import Utils from 'v1/modules/utils'
import COMPONENT                            from '../../../action-types/component'
import initialState, { getInitialState }    from "./initial_state"
import { handleActions }                    from 'redux-actions'

function setComponentAndPopulateInForm(state= initialState, action)
{
    state = resetFormInputs(state, action)

    let { inputs } = state
    let { component } = state

    component = action.payload

    component.originalRevision = component.revision
    component.categoryCPN      = component.cpn
    inputs.name.value          = component.name
    inputs.category.value      = component.category
    inputs.revision.value      = component.revision
    inputs.status.value        = component.status
    inputs.eid.value           = component.eid
    inputs.description.value   = component.description
    inputs.images.value        = component.images
    inputs.documents.value     = component.documents
    inputs.cpn.value           = component.cpn
    // inputs.children.value      = component.children
    inputs.manufacturers.value = component.manufacturers
    inputs.cpnVariant.value    = component.cpnVariant
    inputs.mass.value          = component.mass
    inputs.unitOfMeasure.value = component.unitOfMeasure
    inputs.procurement         = component.procurement
    inputs.revisionManaged     = component.revisionManaged;

    inputs.specs = component.specs.map((spec) =>
    {
        let input =
        {
            value : spec.value,
            key: spec.key
        }
        return input
    })

    inputs.customProperties = component.customProperties.map((property) =>
    {
        return {
            value: property.value,
            key: property.key
        }
    })

    const catObject = window.__categories.find(cat => cat.name === component.category);

    inputs.customSpecs = component.customSpecs.map(({ value, ...rest}) => {
			return Utils.validateCustomSpecs(catObject, rest, value);
    })

    state.inputs = inputs
    state.component = component
    return {...state}
}

function setFormFields(state= initialState, { payload })
{
    state.inputs  = payload
    return {...state}
}


function setFormFieldsWithModified(state= initialState, { payload })
{
    state.inputs  = {...payload.inputs}
    state.modified  = payload.modified
    return {...state}
}


function setComponentSearchResults(state=initialState, { payload })
{
    state.inputs.searchComponent.results = payload
    return {...state}
}

function resetForm(state, { payload })
{
    return {...getInitialState()}
}

function updateModifiedFlag(state, action)
{
    state = {...state, modified: action.payload}
    return {...state}
}

function setIsNewObjectFlag(state, action)
{
    state = {...state, isNewObject: action.payload}
    return {...state}
}

function setOrignalComponentId(state, action)
{
    state = {...state, originalComponentId: action.payload}
    return {...state}
}

function resetFormInputs(state= initialState, action)
{
    let {inputs} = {...getInitialState()}
    state = {...state, inputs: inputs, component: null}
    return {...state}
}

function updateImageStateOnEditPage(state, { payload })
{
    state.inputs.isUploadedThumbnails = payload.isUploadedThumbnails
    state.inputs.images.value = payload.images
    return {...state}
}
// function updateAssemblyOnEditPage(state, { payload })
// {
//     state.inputs.children.value = payload.children
//     return {...state}
// }

export default handleActions({
    [COMPONENT.SET_COMPONENT_IN_EDIT_FORM]: setComponentAndPopulateInForm,
    [COMPONENT.UPDATE_EDIT_COMPONENT_FORM_FIELDS]: setFormFields,
    [COMPONENT.UPDATE_EDIT_COMPONENT_FORM_FIELDS_WITH_MODIFIED]: setFormFieldsWithModified,
    [COMPONENT.SET_EDIT_PAGE_MODIFIED]: updateModifiedFlag,
    [COMPONENT.SET_IS_NEW_OBJECT_FLAG]: setIsNewObjectFlag,
    [COMPONENT.SET_ORIGNAL_COMPONENT_ID]: setOrignalComponentId,
    [COMPONENT.SET_EDIT_PAGE_COMPONENT_SEARCH_RESULTS]: setComponentSearchResults,
    [COMPONENT.RESET_COMPONENT_EDIT_FORM]: resetForm,
    [COMPONENT.RESET_COMPONENT_EDIT_FORM_INPUTS]: resetFormInputs,
    [COMPONENT.UPDATE_IMAGES_STATE_ON_EDIT_PAGE]: updateImageStateOnEditPage,
    // [COMPONENT.UPDATE_ASSEMBLY_ON_EDIT_PAGE]: updateAssemblyOnEditPage,

}, initialState);
