import React, { Component }      from "react";

const SvgIcon = () =>
{
    return  <svg width="22px" height="19px" viewBox="0 0 22 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="7-CHANGE-ORDER" transform="translate(-319.000000, -846.000000)">
                    <g id="TABLE" transform="translate(-3386.000000, 610.000000)">
                        <g id="LeftSideNumbers" transform="translate(3699.000000, 45.000000)">
                            <g id="WARNING" transform="translate(6.000000, 191.000000)">
                                <g transform="translate(-1.000000, -2.500000)" id="baseline-warning-24px">
                                    <g>
                                        <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
                                        <path d="M1.41176471,21.1764706 L22.5882353,21.1764706 L12,2.82352941 L1.41176471,21.1764706 Z M12.9625668,18.2786378 L11.0374332,18.2786378 L11.0374332,16.3467492 L12.9625668,16.3467492 L12.9625668,18.2786378 Z M12.9625668,14.4148607 L11.0374332,14.4148607 L11.0374332,10.5510836 L12.9625668,10.5510836 L12.9625668,14.4148607 Z" id="Shape" fill="#F5A64F" fill-rule="nonzero"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
}

export default SvgIcon
