import React from "react";

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='23'
      viewBox='0 0 20 23'
    >
      <g fill='none' fillRule='evenodd' strokeWidth='1'>
        <g transform='translate(-954 -108)'>
          <g transform='translate(955 109)'>
            <g>
              <g>
                <path d='M3.916 21s-2.497-2.207-2.668-4.709A10.61 10.61 0 00.29 12.32s-.687-1.498.053-1.958c2.486-1.526 4.29 3.972 4.29 3.972V1.71C4.623.858 5.269.13 6.143.008c.474-.046.946.103 1.3.411.354.308.556.746.557 1.206v7.801M15 12.223v-1.6C15 9.727 15.672 9 16.5 9s1.5.727 1.5 1.623V21M11 10.896V9.545c0-.851.67-1.542 1.497-1.545.83.003 1.5.693 1.503 1.545V13M8 11V7.531C8 6.685 8.672 6 9.5 6s1.5.685 1.5 1.531V11'></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
