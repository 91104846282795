import React, {Component}       from "react"
import Spinner                  from "../../../../ui/spinner"
import Utils                    from "../../../../../modules/utils"
import ComponentProductUtils    from "../../../../../modules/component-product-utils"
import ExtendedTable            from "../../../common/extended-table"
import { connect }              from "react-redux"
import Link                     from "../../../../ui/link"
import InlineIcon               from "../../../../ui/icon/inline-icon.js"
import EditIcon                 from "../../../../../assets/icons/edit"
import Permissions              from "../../../../../modules/schemas/permissions"
import TimeStampColumn          from "../../../common/timestamp-column";
import { DocumentRegenerateButton } from "../../document-regenerate-button";
var jsdiff = require('diff');

export class Documents extends Component
{
    constructor(props)
    {
        super(props)
        this.pusher = null;
        let styles;
        try
        {
            styles = window.__userStyles.styles.documentsTable || {}
        }
        catch(error) {
            styles = {}
        }

        this.state =
        {
            data: [],
            currentSortItemAscending: ("defaultSortAssending" in styles ? styles.defaultSortAssending : true),
            current : ("defaultSortColumnName" in styles ? styles.defaultSortColumnName : "name"),
            syncWithParentState: false,
            headings :
            [
                {
                    key         : "name",
                    displayName : "Name",
                    tooltip     : "Name",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 71,
                    width       : Utils.getStyleValue(styles, "name", "width", 254),
                    position    : Utils.getStyleValue(styles, "name", "position", 0),
                    visibility  : Utils.getStyleValue(styles, "name", "visibility", true),
                    disabled    : true
                },

                {
                    key         : "type",
                    displayName : "Doc type",
                    tooltip     : "Doc type",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 91,
                    width       : Utils.getStyleValue(styles, "type", "width", 91),
                    position    : Utils.getStyleValue(styles, "type", "position", 1),
                    visibility  : Utils.getStyleValue(styles, "type", "visibility", true),
                    disabled    : false
                },


                {
                    key         : "ext",
                    displayName : "File type",
                    tooltip     : "File type",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 71,
                    width       : Utils.getStyleValue(styles, "ext", "width", 151),
                    position    : Utils.getStyleValue(styles, "ext", "position", 2),
                    visibility  : Utils.getStyleValue(styles, "ext", "visibility", true),
                    disabled    : false
                },

                {
                    key         : "size",
                    displayName : "File size",
                    tooltip     : "File size",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 71,
                    width       : Utils.getStyleValue(styles, "size", "width", 111),
                    position    : Utils.getStyleValue(styles, "size", "position", 3),
                    visibility  : Utils.getStyleValue(styles, "size", "visibility", true),
                    disabled    : false
                },

                {
                    key         : "revision",
                    displayName : "Revision",
                    tooltip     : "Revision",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 71,
                    width       : Utils.getStyleValue(styles, "revision", "width", 120),
                    position    : Utils.getStyleValue(styles, "revision", "position", 4),
                    visibility  : props.cmptype === "co" ? false : Utils.getStyleValue(styles, "revision", "visibility", true),
                    disabled    : props.cmptype === "co" ? true : false,
                    hideFromSettings: props.cmptype === "co" ? true : false
                },

                {
                    key         : "status",
                    displayName : "Status",
                    tooltip     : "Status",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 71,
                    width       : Utils.getStyleValue(styles, "status", "width", 145),
                    position    : Utils.getStyleValue(styles, "status", "position", 5),
                    visibility  : props.cmptype === "co" ? false : Utils.getStyleValue(styles, "status", "visibility", true),
                    disabled    : props.cmptype === "co" ? true : false,
                    hideFromSettings: props.cmptype === "co" ? true : false
                },
                {
                    key         : "lastUpdated",
                    displayName : "Last Updated",
                    tooltip     : "Last Updated",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 170,
                    width       : Utils.getStyleValue(styles, "lastUpdated", "width", 170),
                    position    : Utils.getStyleValue(styles, "lastUpdated", "position", 6),
                    visibility  : Utils.getStyleValue(styles, "lastUpdated", "visibility", true),
                    disabled    : false
                },
            ]

        }
        this.getRows = this.getRows.bind(this)
        this.getRowsForDiffTool = this.getRowsForDiffTool.bind(this)
        this.afterSyncWithParentState = this.afterSyncWithParentState.bind(this);
        this.updateDocState = this.updateDocState.bind(this);
        this.getCompanyId = this.getCompanyId.bind(this);
    }

    afterSyncWithParentState()
    {
        this.setState({syncWithParentState: false});
    }

    getCompanyId()
    {
        let {company} = this.props;
        if(company && company.data)
        {
            return Utils.extractId(company.data);
        }
        return null;
    }

    componentDidMount()
    {
        if(this.props.initializePusher && !this.pusher) this.pusher = Utils.initPusher();
        let companyId                     = this.getCompanyId();
        let { data, syncWithParentState } = this.state;
        if(companyId && this.pusher) 
        {
            let channel = this.pusher.subscribe(`company-${companyId}`);
            channel.bind('document-regeneration', res => 
            {
                if(!res) return;
                let { doc, found } = Utils.getMatchedDocument(data, res.doc._id);
                if (!found) return;
                doc.specs.lastModified = res.lastModified;
                doc.file.status        = res.doc.status;
                syncWithParentState    = true;
                this.setState({ syncWithParentState, data });
            });
        }
    }

    componentWillUnmount()
    {
        let companyId = this.getCompanyId();
        if(companyId && this.pusher)
        {
            this.pusher.unsubscribe(`company-${companyId}`);
        }
    }

    componentWillMount()
    {
        let documents = this.props.documents
        let state  = this.state
        state.data = Utils.filterDocuments(documents, this.props.objectData && Utils.getVendor(this.props.objectData));
        this.setState(state)
    }

    updateDocState(docId)
    {
        let {syncWithParentState, data} = this.state;
        let {doc, found} = Utils.getMatchedDocument(data, docId);
        if(!found) return;
        doc.file.status = "PROCESSING";
        syncWithParentState = true;
        this.setState({data, syncWithParentState});
    }

    compareDocumentAttributes(targetRevDoc, sourceRevDoc)
    {
        let attributes = ["status", "revision", "type", "lastModified"]
        for(let i = 0; i < attributes.length; i++) {
            let diffAttribute = jsdiff.diffLines(targetRevDoc.specs[attributes[i]].toString() || "", sourceRevDoc.specs[attributes[i]].toString() || "");
            diffAttribute.forEach((part) => {
                if (part.removed)
                {
                    sourceRevDoc.specs[`removed${attributes[i].charAt(0).toUpperCase()}${attributes[i].slice(1)}`] = part.value
                }
                else if (part.added)
                {
                    sourceRevDoc.specs[`added${attributes[i].charAt(0).toUpperCase()}${attributes[i].slice(1)}`] = part.value
                }
            })
        }
    }

    getRowsForDiffTool()
    {
        let sourceAndTargetDocuments = []
        let sourceAndTargetRevDocumentIds = []

        let sourceRevDocuments = this.props.documents
        let targetRevDocuments = this.props.targetRevDocuments

        for(let i = 0; i < sourceRevDocuments.length; i++) {
            if (!sourceAndTargetRevDocumentIds.includes(sourceRevDocuments[i].file._id))
            {
                sourceAndTargetDocuments.push(sourceRevDocuments[i])
                sourceAndTargetRevDocumentIds.push(sourceRevDocuments[i].file._id)
            }
        }

        for(let i = 0; i < targetRevDocuments.length; i++) {
            if (!sourceAndTargetRevDocumentIds.includes(targetRevDocuments[i].file._id))
            {
                sourceAndTargetDocuments.push(targetRevDocuments[i])
                sourceAndTargetRevDocumentIds.push(targetRevDocuments[i].file._id)
            }
        }

        for(let i = 0; i < sourceAndTargetDocuments.length; i++) {
            let isPresentInTargetRev = false
            let isPresentInSourceRev = false

            isPresentInTargetRev = Utils.getMatchedDocument(targetRevDocuments, sourceAndTargetDocuments[i].file._id).found;
            isPresentInSourceRev = Utils.getMatchedDocument(sourceRevDocuments, sourceAndTargetDocuments[i].file._id).found;

            if (!isPresentInSourceRev && isPresentInTargetRev)
            {
                sourceAndTargetDocuments[i].file.rowClassName = "remove"
            }
            else if (isPresentInSourceRev && !isPresentInTargetRev)
            {
                sourceAndTargetDocuments[i].file.rowClassName = "add"
            }

            else if (isPresentInSourceRev && isPresentInTargetRev)
            {
                let sourceRevDoc = Utils.getMatchedDocument(sourceRevDocuments, sourceAndTargetDocuments[i].file._id).doc;
                let targetRevDoc = Utils.getMatchedDocument(targetRevDocuments, sourceAndTargetDocuments[i].file._id).doc;

                let sourceRevDocumentString = `${sourceRevDoc.specs.status} ${sourceRevDoc.specs.revision} ${sourceRevDoc.specs.type} ${sourceRevDoc.specs.lastModified}`
                let targetRevDocumentString = `${targetRevDoc.specs.status} ${targetRevDoc.specs.revision} ${targetRevDoc.specs.type} ${targetRevDoc.specs.lastModified}`

                let diff = jsdiff.diffWords(targetRevDocumentString, sourceRevDocumentString);
                if (diff.length > 1)
                {
                    sourceAndTargetDocuments[i].file.rowClassName = "update"
                    //Note: Compare exact attribute changes here
                    this.compareDocumentAttributes(targetRevDoc, sourceRevDoc)
                }
            }
        }

        let rows = sourceAndTargetDocuments.map((result, i) =>
        {
            let lastModified = result.specs && result.specs.lastModified ? result.specs.lastModified : undefined;
            let lastModifiedToolTip  = lastModified ? Utils.dateTimeWithLongFormat(lastModified) : null;
            let allowRowSelect = !!(result.quote_id && result.quoteMinQuantity !== null && result.quoteUnitPrice !== null )
            let cells =

            {
                "name" :
                {
                    value        : result.file.name,
                    displayValue : <span className="link">{result.file.name}</span>,
                    tooltip      : result.file.name
                },

                "type" :
                {
                    value        : result.specs.type,
                    displayValue : result.file.rowClassName === "update" && (result.specs.removedType || result.specs.addedType) ?
                    <div className="diff-update-section">
                        {result.specs.removedType && <div className="update-tag remove">{result.specs.removedType}</div>}
                        {result.specs.addedType && <div className="update-tag add">{result.specs.addedType}</div>}
                    </div> : result.specs.type,
                    tooltip      : result.specs.type,
                    notLink      : false
                },

                "ext" :
                {
                    value        : Utils.getExtension(result.file.name),
                    displayValue : Utils.getExtension(result.file.name).toUpperCase(),
                    tooltip      : Utils.getExtension(result.file.name).toUpperCase(),
                    notLink      : false
                },

                "size" :
                {
                    value        : Utils.fileSize(result.file.size),
                    displayValue : Utils.fileSize(result.file.size),
                    tooltip      : Utils.fileSize(result.file.size),
                    notLink      : false
                },

                "revision" :
                {
                    value        : result.specs.revision,
                    displayValue : result.file.rowClassName === "update" && (result.specs.removedRevision || result.specs.addedRevision) ?
                    <div className="diff-update-section">
                        {result.specs.removedRevision && <div className="update-tag remove">{result.specs.removedRevision}</div>}
                        {result.specs.addedRevision && <div className="update-tag add">{result.specs.addedRevision}</div>}
                    </div> : result.specs.revision,
                    tooltip      : result.specs.revision,
                    notLink      : false,
                    cellCustomClass : result.file.rowClassName === "update" ? "diff-tool" : "",
                },

                "status" :
                {
                    value        : result.specs.status,
                    displayValue : result.file.rowClassName === "update" && (result.specs.removedStatus || result.specs.addedStatus) ?
                    <div className="diff-update-section">
                        {result.specs.removedStatus && <div className="update-tag remove">{result.specs.removedStatus}</div>}
                        {result.specs.addedStatus && <div className="update-tag add">{result.specs.addedStatus}</div>}
                    </div> : result.specs.status,
                    tooltip      : result.specs.status,
                    notLink      : false,
                    cellCustomClass : result.file.rowClassName === "update" ? "diff-tool" : "",
                },
                "lastUpdated" :
                {
                    value        : lastModified ? lastModified : '',
                    displayValue : result.file.rowClassName === "update" && (result.specs.removedLastModified || result.specs.addedLastModified) ?
                    <div className="diff-update-section">
                        {result.specs.removedLastModified && <div className="update-tag remove"><TimeStampColumn key={Utils.generateUniqueId()} format='date-time-with-long-format' value={result.specs.removedLastModified} /></div>}
                        {result.specs.addedLastModified && <div className="update-tag add"><TimeStampColumn key={Utils.generateUniqueId()} format='date-time-with-long-format' value={result.specs.addedLastModified} /></div>}
                    </div> : lastModified && <TimeStampColumn key={Utils.generateUniqueId()} format='date-time-with-long-format' value={lastModified} />,
                    notLink      : false
                },

                onClick         : () => {},
                rowLink         : result.file.src || "",
                simpleLink      : true,
                rowClassName    : result.file.rowClassName

            }
            return cells
        })
        return rows
    }

    getRows()
    {
        if (this.props.isDiffTool)
        {
            return this.getRowsForDiffTool()
        }

        let rows = this.state.data.map((result, i) =>
        {
            let lastModified = result.specs && result.specs.lastModified ? result.specs.lastModified : undefined;
            let lastModifiedToolTip  = lastModified ? Utils.dateTimeWithLongFormat(lastModified) : null;
            let allowRowSelect = !!(result.quote_id && result.quoteMinQuantity !== null && result.quoteUnitPrice !== null )

            let parentVendor         = this.props.component ? Utils.getVendor(this.props.component).toUpperCase() : "";
            let needsToBeRegenerated = Utils.fileCanBeRegenerated(parentVendor, result.file);
            let fileData =
            {
                vendor: parentVendor,
                fileType: Utils.extractDocumentTypeFromFileName(result.file.name)
            };

            let cells =

            {
                "name" :
                {
                    value        : result.file.name,
                    displayValue : <span className={needsToBeRegenerated ? "" : "link"}>{result.file.name}</span>,
                    tooltip      : result.file.name,
                    notLink      : needsToBeRegenerated ? true : false
                },

                "type" :
                {
                    value        : result.specs.type,
                    displayValue : result.specs.type,
                    tooltip      : result.specs.type,
                    notLink      : false
                },

                "ext" :
                {
                    value        : Utils.getExtension(result.file.name),
                    displayValue : Utils.getExtension(result.file.name).toUpperCase(),
                    tooltip      : Utils.getExtension(result.file.name).toUpperCase(),
                    notLink      : false
                },

                "size" :
                {
                    value        : Utils.fileSize(result.file.size),
                    displayValue : Utils.fileSize(result.file.size),
                    tooltip      : Utils.fileSize(result.file.size),
                    notLink      : false
                },

                "revision" :
                {
                    value        : result.specs.revision,
                    displayValue : result.specs.revision,
                    tooltip      : result.specs.revision,
                    notLink      : false
                },

                "status" :
                {
                    value        : result.specs.status,
                    displayValue : result.specs.status,
                    tooltip      : result.specs.status,
                    notLink      : false
                },
                "lastUpdated" :
                {
                    value        : lastModified ? lastModified : '',
                    displayValue : lastModified && <TimeStampColumn key={Utils.generateUniqueId()} format='date-time-with-long-format' value={lastModified} />,
                    tooltip      : lastModified && lastModifiedToolTip ? `${lastModifiedToolTip.dateValue} ${lastModifiedToolTip.timeValue}`: '',
                    notLink      : false
                },
                "fileStatus" :
                {
                    value        : result.file.status,
                    displayValue : result.file.status,
                },
                "indexTableEl"   : needsToBeRegenerated ?
                <DocumentRegenerateButton docId={result.file._id} fileData={fileData} docState={result.file.status} updateDocState={this.updateDocState}/>
                    : null,
                onClick         : () => {},
                rowLink         : needsToBeRegenerated ? "" : result.file.src || "",
                simpleLink      : true
            }
            return cells
        })

        return rows
    }

    render()
    {
        if(this.props.loading)
        {
            return <Spinner />
        }

        let tableHeaders = this.state.headings
        let rows         = this.getRows()
        let isRevisionPage = false
        let isInOpenCo = ComponentProductUtils.isInOpenCo(this.props.objectData)
        if (this.props.location) isRevisionPage = ComponentProductUtils.isRevisionPage(this.props.location)
        let isCoCanEdit = this.props.objectData.alias === "co" ? (this.props.objectData.status === 'DRAFT' && Permissions.can("edit", "change_order", this.props.user.data)): false;
        let isEditable = this.props.objectData.alias !== "co" ? (!this.props.isDiffTool && !isInOpenCo && Permissions.can("edit", "component", this.props.user.data) && !isRevisionPage): false;

        let markup  =
                <div className="documents-view-block">
                    <ExtendedTable
                        syncWithParentState={this.state.syncWithParentState}
                        afterSyncWithParentState={this.afterSyncWithParentState}
                        wrapperClassName="documents-list"
                        wrapperSelectorClass="documents-list"
                        headings={tableHeaders}
                        rows={rows}
                        resultText={`${rows.length} Documents`}
                        stylesName="documentsTable"
                        borderedTable={true}
                        allowRowSelect={false}
                        includeToolBar={true}
                        currentSortItem={this.state.current}
                        currentSortItemAscending={this.state.currentSortItemAscending}
                        footerRow={isEditable || isCoCanEdit ? {
                                bordered: true,
                                dataCellEl: <p>Edit to add</p>,
                                indexCellEl: <div className="add-more-actions">
                                                <Link to={this.props.isProductionInstance ? `/production/instance/edit/${this.props.objectData._id}` : ComponentProductUtils.makeEditLink(this.props.objectData)}>
                                                    <button
                                                        className='add-trigger'
                                                        data-tip='Edit'
                                                        >
                                                        <InlineIcon><EditIcon/></InlineIcon>
                                                    </button>
                                                </Link>
                                             </div>
                            }
                            :
                            rows.length === 0 &&
                            {
                                dataCellEl: <p>No Documents added</p>
                            }
                        }
                    />
                </div>

        return markup
    }
}

export default connect((store) => store)(Documents)
