import Utils from "../../utils"

class CategoryAPI
{
    static getCategories(cb)
    {
        Utils.get("/categories/list", (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }
}

export default CategoryAPI
