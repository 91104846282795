import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g stroke="#FFF" strokeWidth="0.5" transform="translate(-1114 -875)">
          <g transform="translate(1103 863)">
            <g transform="translate(12 13)">
              <circle cx="6" cy="6" r="6"></circle>
              <path strokeLinecap="square" d="M8.7 3.3L3.3 8.7"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
