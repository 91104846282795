import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import SEARCH from "v1/action-types/search.js";
import buildAction from "v1/helpers/buildAction";
import Query from "v1/modules/query";

export const useSearchStringHandler = (currentValue: string, type: string) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const searchValue = (history?.location?.state as any)?.query.trim();
    if (searchValue && !currentValue) {
      const query = Query.parse(searchValue);
      const payload = {
        page: 1,
        query: {
          object: query,
          string: Query.stringify(query),
        },
        type,
        value: searchValue,
      };
      dispatch(buildAction(SEARCH.UPDATE_SEARCH_STATE, payload));
    }
  }, [currentValue, dispatch, history, type]);
};
