import UI                   from "../../action-types/ui";
import RELEASE              from "../../action-types/release";
import buildAction          from "../../helpers/buildAction";
import API                  from "../../modules/api";
import {privatePaths}       from "../../app/routes.js";
import Utils                from "../../modules/utils";
import
{
    takeEvery,
    all,
    cps,
    put,
    call
}                           from "redux-saga/effects";


export function* findReleaseById(action)
{
    yield put(buildAction(UI.LOAD_START));
    try
    {
        const release = yield cps(API.release.findById, `${action.payload.id}?checkRevisions=true&include=revisions,documents,creator,refCo`);
        if (release)
        {
            yield put(buildAction(RELEASE.SET_RELEASE_OBJECT, release));
        }
        yield put(buildAction(UI.LOAD_END));
    }
    catch(err)
    {
        let payload =
        {
            type: "errors",
            errors: err.errors,
            err: err,
            closeCb: action.payload.notFoundCb || function(){
                                Utils.redirectToParentRoute(privatePaths.releaseSearch)
                            }
        };
        yield put(buildAction(UI.SHOW_ALERT, payload));
    }
    yield put(buildAction(UI.LOAD_END));
}

export default function* (getState)
{
    yield all([
        takeEvery(RELEASE.FIND_RELEASE, findReleaseById)
    ])
}
