import                "./index.css"
import React          from "react"
import Tooltip        from 'rc-tooltip'

class EllipsisTooltip extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state = {
          visible: false
        }
        this.handleVisibleChange = this.handleVisibleChange.bind(this)
    }

    handleVisibleChange(visible)
    {
      if ((this.container.clientWidth < this.container.scrollWidth) ||
        this.container.tagName === "P" && this.container.scrollHeight > 32
      ) {
        this.setState({
          visible: visible
        })
      }
    }

    render()
    {
        let markup =
            this.props.twoLines ?
            <div className="two-lines">
                <Tooltip overlayClassName={`${this.props.classes} cell${this.props.index} default-styles`}
                    visible={this.state.visible}
                    placement="top"
                    mouseEnterDelay={1}
                    onVisibleChange={this.handleVisibleChange}
                    overlay={this.props.title}>
                    <p ref={node => this.container = node}>
                        {this.props.children}
                    </p>
                </Tooltip>
            </div> :
            <Tooltip overlayClassName={`${this.props.classes} cell${this.props.index} default-styles`}
                visible={this.state.visible}
                placement="top"
                mouseEnterDelay={1}
                onVisibleChange={this.handleVisibleChange}
                overlay={this.props.title}>
                <div ref={node => this.container = node} className={this.props.innerClasses}>
                    {this.props.children}
                </div>
            </Tooltip>
        return markup
    }
}

export default EllipsisTooltip
