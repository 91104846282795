import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="51"
      height="57"
      viewBox="0 0 51 57"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      >
        <g transform="translate(-844 -626)">
          <g transform="translate(313 586)">
            <g transform="translate(454)">
              <g transform="translate(78 41)">
                <g>
                  <path stroke="#3CD1B5" d="M25.088 0v37.632"></path>
                  <path
                    stroke="#3CD1B5"
                    d="M45.3508772 19.2982456L25.5701754 0 5.78947368 19.2982456"
                  ></path>
                  <path
                    stroke="#FFF"
                    d="M49.2105263 35.7017544L49.2105263 55 0 55 0 35.7017544"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
