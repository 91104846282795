import React from "react";

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='17'
      version='1.1'
      viewBox='0 0 18 17'
      id="enlarge-svg"
    >
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g
          fill='#FFF'
          fillRule='nonzero'
          stroke='#FFF'
          strokeWidth='0.75'
          transform='translate(-361 -9)'
        >
          <g transform='translate(362 9)'>
            <g>
              <path
                d='M1.155 1a.125.125 0 00-.088.213l6.181 6.182a.25.25 0 01-.353.353L.713 1.567a.125.125 0 00-.213.088V6.25a.25.25 0 01-.5 0V.75A.25.25 0 01.25.5h5.499a.25.25 0 010 .5H1.155zm13.69 15a.125.125 0 00.088-.213L8.752 9.605a.25.25 0 01.353-.353l6.182 6.181a.125.125 0 00.213-.088V10.75a.25.25 0 01.5 0v5.499a.25.25 0 01-.25.25h-5.499a.25.25 0 010-.5h4.594z'
                transform='rotate(90 8 8.5)'
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
