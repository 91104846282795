import UI                   from "../../action-types/ui";
import ITEM_DIFF            from "../../action-types/item-diff";
import buildAction          from "../../helpers/buildAction";
import API                  from "../../modules/api";
import {privatePaths}       from "../../app/routes.js";
import Utils                from "../../modules/utils";
import validations          from "../../modules/validations";

import
{
    takeEvery,
    all,
    cps,
    put,
    call
}                           from "redux-saga/effects";


export function* findProductAndSourceAndTargetRevs(action)
{
    try
    {
        let itemModal = action.payload.alias === "cmp" ? "components" : "products"
        let revModal = action.payload.alias === "cmp" ? "componentRevision" : "productRevision"
        const item = yield cps(API[itemModal].findById, action.payload.id)
        const sourceRev = yield cps(API[revModal].findById, action.payload.sourceRevId);
        const targetRev = yield cps(API[revModal].findById, action.payload.targetRevId);
        if (item && sourceRev && targetRev)
        {

            const response = yield cps(API.companies.getCurrent);
            window.__buildScheduleScheme = response.settings.buildScheduleScheme;
            let forecastScheme = window.__libraryType === "GENERAL" && window.__buildScheduleScheme === "EXPERT" ? "EXPERT" : "DEFAULT"
            if (action.payload.alias === "prd")
            {
                //Note: Mapped the forecast key to generate the display-keys so diff-tool will work as expected for forecast section. PLM-2791
                let forecastKeys = []
                sourceRev.forecasts.forEach((spec) => forecastKeys.push(spec.key))

                for(let forecast of sourceRev.forecasts)
                {
                    forecast.key = validations.product.forecasts.getForecastDisplayName(forecast.key, forecast.counter, forecastScheme, forecastKeys)


                }
                forecastKeys = []
                targetRev.forecasts.forEach((spec) => forecastKeys.push(spec.key))

                for(let forecast of targetRev.forecasts)
                {
                    forecast.key = validations.product.forecasts.getForecastDisplayName(forecast.key, forecast.counter, forecastScheme, forecastKeys)
                }
            }

            let payload = {item, sourceRev, targetRev}
            yield put(buildAction(ITEM_DIFF.SET_DIFF_STATE, payload));
        }
    }
    catch(err)
    {
        let payload =
        {
            type: "errors",
            errors: err.errors,
            err: err,
            closeCb: action.payload.notFoundCb || function(){
                                Utils.redirectToParentRoute(privatePaths.search)
                            }
        };
        yield put(buildAction(UI.SHOW_ALERT, payload));
    }
}

export function* findTargetRev(action)
{
    try
    {
        let itemModal = action.payload.alias === "cmp" ? "components" : "products"
        let revModal = action.payload.alias === "cmp" ? "componentRevision" : "productRevision"
        const targetRev = yield cps(API[revModal].findById, action.payload.targetRevId);
        if (targetRev)
        {
            let payload = {targetRev}
            yield put(buildAction(ITEM_DIFF.SET_TARGET_REV, payload));
        }
    }
    catch(err)
    {
        let payload =
        {
            type: "errors",
            errors: err.errors,
            err: err,
            closeCb: action.payload.notFoundCb || function(){
                                Utils.redirectToParentRoute(privatePaths.search)
                            }
        };
        yield put(buildAction(UI.SHOW_ALERT, payload));
    }
}

export default function* (getState)
{
    yield all([
        takeEvery(ITEM_DIFF.GET_ITEM_AND_SOURCE_AND_TARGET_REVISIONS, findProductAndSourceAndTargetRevs),
        takeEvery(ITEM_DIFF.GET_TARGET_REVISION, findTargetRev)
    ])
}
