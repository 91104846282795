import { SvgIcon } from "@mui/material";

export const TreeCollapseIcon = () => (
  <SvgIcon viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" sx={{
    width: "16px",
    height: "16px",
    marginTop: "-4px",
  }}>
    <path d="m2 5 6 6.545 6-6.544z" fillRule="evenodd" />
  </SvgIcon>
);
