import React, { FunctionComponent } from "react";

interface ITileContentImageProps {
  imgUrl: string;
  alt?: string;
  style? : React.CSSProperties;
}

export const TileContentImage: FunctionComponent<ITileContentImageProps> = (props: ITileContentImageProps) => {
  const { imgUrl, alt, style } = props;
  return (
    <div style={{ textAlign: "center" }}>
      <img src={imgUrl} style={{ maxHeight: "8.75rem", ...style }} alt={alt}/>
    </div>
  );
};
