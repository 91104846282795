import { SvgIcon } from "@mui/material";

export const McoIcon = () => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <title>1C861492-524A-4E72-8EC8-F6A6F766BF25</title>
    <g id="Symbols" stroke="none" strokeWidth="1" fillRule="evenodd">
      <g id="A-/-icon-/-MCO">
        {/* eslint-disable-next-line max-len */}
        <path d="M2,21 L2,3 L11.75,3 L11.75,7.125 L22,7.125 L22,21 L2,21 Z M3.5,19.5 L6.125,19.5 L6.125,16.875 L3.5,16.875 L3.5,19.5 Z M3.5,15.375 L6.125,15.375 L6.125,12.75 L3.5,12.75 L3.5,15.375 Z M3.5,11.25 L6.125,11.25 L6.125,8.625 L3.5,8.625 L3.5,11.25 Z M3.5,7.125 L6.125,7.125 L6.125,4.5 L3.5,4.5 L3.5,7.125 Z M7.625,19.5 L10.25,19.5 L10.25,16.875 L7.625,16.875 L7.625,19.5 Z M7.625,15.375 L10.25,15.375 L10.25,12.75 L7.625,12.75 L7.625,15.375 Z M7.625,11.25 L10.25,11.25 L10.25,8.625 L7.625,8.625 L7.625,11.25 Z M7.625,7.125 L10.25,7.125 L10.25,4.5 L7.625,4.5 L7.625,7.125 Z M11.75,19.5 L20.5,19.5 L20.5,8.625 L11.75,8.625 L11.75,11.25 L13.75,11.25 L13.75,12.75 L11.75,12.75 L11.75,15.375 L13.75,15.375 L13.75,16.875 L11.75,16.875 L11.75,19.5 Z M16.375,12.75 L16.375,11.25 L17.875,11.25 L17.875,12.75 L16.375,12.75 Z M16.375,16.875 L16.375,15.375 L17.875,15.375 L17.875,16.875 L16.375,16.875 Z" id="Shape"></path>
      </g>
    </g>
  </SvgIcon>
);
