
import { takeLatest, all, call, put, cps, select }  from 'redux-saga/effects';
import UI                                           from '../action-types/ui';
import USER_FORM                                    from '../action-types/user_form';
import USERS                                        from '../action-types/users';
import USER                                         from '../action-types/user';
import buildAction                                  from '../helpers/buildAction';
import API                                          from "../modules/api";
import utils                                        from '../modules/utils'
import validations, {validateField}                 from '../modules/validations';
import { getSubErrors } from '../modules/utils/errors';

export const userFormState = state => state.userForm

export function* submitUserForm(action) {
    let payload = action.payload

    if(payload.data.inviteModal)
    {
        delete payload.data.inviteModal
        yield put(buildAction(USER_FORM.UPDATE_INNER_LOADING_FLAG, true));
    }

    if(payload.fromSandbox)
    {
        delete payload.fromSandbox
    }

    yield put(buildAction(UI.LOAD_START));
    try {
      const user_form_state = yield select(userFormState);
      const {
        data,
        fromSandbox,
        successCb,
        update,
        userId, // only populated for update
      } = payload;
      let newUserId;
      const userData = yield cps(API.users.getCurrent);
      if (update) {
        newUserId = yield cps(
        API.users.updateCoreApi,
        userId,
        utils.getUserChanges(data, user_form_state.user))
        if (userId === userData._id && data.email !== user_form_state.user.email) {
          location.reload();
        }
      }
      else {
        const { submit, ...rest } = payload.data;
        const data = { ...rest, primaryCompanyId: userData?.company };
        newUserId = yield cps(API.users.create, data);
      }
      
      if (!fromSandbox) {
        yield put(buildAction(USER.SET_CURRENT_USER, userData));
        yield put(buildAction(USER_FORM.UPDATE_INNER_LOADING_FLAG, false));
        yield put(buildAction(USERS.GET_USERS_LIST));
      }

      if (successCb) {
        successCb(newUserId)
      }
      else {
        yield put(buildAction(USER_FORM.FORM_SUBMISSION_STATE));
      }
    }
    catch (err) {
      yield put(buildAction(UI.SHOW_ALERT, {
        type: "errors",
        errors: getSubErrors(err),
        err
      }));
    }
    yield put(buildAction(UI.LOAD_END));
}

export function* submitInviteUser(action) {
    let payload = action.payload

    if(payload.data.inviteModal)
    {
        delete payload.data.inviteModal
        yield put(buildAction(USER_FORM.UPDATE_INNER_LOADING_FLAG, true));
    }

    try
    {
        let userId;
        if (payload.update)
        {
          userId = yield cps(API.users.update, payload.userId, payload.data)
        }
        else
        {
          const userData = yield cps(API.users.getCurrent);
          const { submit, ...rest } = payload.data;
          const data = { ...rest, primaryCompanyId: userData?.company };
          userId = yield cps(API.users.create, data)
        }


        if (action.payload.successCb)
        {
          action.payload.successCb(userId)
        }
        else
        {
          yield put(buildAction(USER_FORM.FORM_SUBMISSION_STATE));
        }
    }
    catch (err) {
      yield put(buildAction(UI.SHOW_ALERT, {
        type: "errors",
        errors: getSubErrors(err),
        err
      }));
    }
}

export function* updateInputOnChange(action) {

    const userForm = yield select(userFormState)
    let target = action.payload
    let value = target.value
    let name = target.name
    let input = utils.hash(name, userForm.inputs)
    switch (name) {
        case "firstName":
            validateField(input, validations.user.firstName, value)
          break
        case "lastName" :
            validateField(input, validations.user.lastName, value)
          break
        case "title" :
          validateField(input, validations.user.title, value)
          break

        case "role" :
          value = value.toUpperCase()
          validateField(input, validations.user.role, value)
          break

        case "groups" :
          // value = value.toUpperCase()
          input.value = value
          // validateField(input, validations.user.role, value)
          break

        case "email":
            validateField(input, validations.user.email, value)
            if (input.valid && userForm.user.hasOwnProperty("email") && userForm.user.email.toLowerCase() !== value.toLowerCase())
            {
                yield put(buildAction(USER_FORM.UPDATE_FORM_STATE, { values: input, name }));
                let payload = {email: input.value}
                let response = yield cps(API.users.validateEmail, payload)
                if (response.exist)
                {
                    let signinPath = window.location.href.replace("signup", "signin")
                    input.valid = false
                    input.message = "User already exists"
                    input.class = "invalid"
                    yield put(buildAction(USER_FORM.UPDATE_FORM_STATE, { values: input, name }));
                }
            }
            break
        default:
          {

          }
    }
    yield put(buildAction(USER_FORM.UPDATE_FORM_STATE, { values: input, name }));
}

export default function* (getState) {
    yield all([
        takeLatest(USER_FORM.UPDATE_INPUT_ON_CHANGE, updateInputOnChange),
        takeLatest(USER_FORM.SUBMIT_USER_FORM, submitUserForm),
        takeLatest(USER_FORM.SUBMIT_INVITE_USER, submitInviteUser)
    ]);
}
