import { Link } from "common/components/link";
import { Role } from "common/constants";
import { useMemo } from "react";
import { useUser } from "graphql/query/userQueries";

const aliasToPage: { [key: string]: string } = {
  cmp: "component",
  prd: "product",
};

export function CpnField({ cpn, id, alias }: { cpn: string, id: string, alias: string }) {
  const { role } = useUser().data ?? {};
  const url = useMemo(() => {
    switch (role) {
      case Role.VENDOR:
        return `/${aliasToPage[alias.toLocaleLowerCase()]}/revision/${id}`;
      default:
        return `/${aliasToPage[alias.toLocaleLowerCase()]}/view/${id}`;
    }
  }, [alias, id, role]);
  return (
    <Link to={url}>{cpn}</Link>
  );
}
