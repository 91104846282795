// Custom hook to listen for React Router location changes.
// Argument `callback` receives the location as its first and only parameter:
// callback(location)

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useLocationChange = (callback: Function) => {
  const location = useLocation();
  useEffect(() => callback(location), [callback, location]);
};
