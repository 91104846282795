import { useCallback } from "react";
import { GridColumnHeaderParams, GridValueGetterParams } from "@mui/x-data-grid-pro";
import { useDefaultCurrencySymbol } from "common/hooks";
import { PrimaryVariantType as GridRowType } from "design/components/fields";
import { ProcurementType } from "design/constants";
import { Component } from "design/models";
import Schemas from "v1/modules/schemas";
import Utils from "v1/modules/utils";
import { calculateChildTotalPrice } from "./component";

export type HeaderRendererProps = GridColumnHeaderParams<any, GridRowType>;
export type ValueGetterArgs = GridValueGetterParams<any, GridRowType>;

export function categoryValueGetter({ row }: ValueGetterArgs): string {
  const item = row.item as Component;
  if (!item || item.alias === "PRD") return "";
  return Schemas.component.category.getDisplayName(item.category);
}

export function cmpStateValueGetter({ row }: ValueGetterArgs) {
  if (!row.item) return "";
  return row.item?.workflowState;
}

export function cpnValueGetter({ row }: ValueGetterArgs) {
  if (!row.item) return "";
  return row.item.cpn?.displayValue;
}

export function descriptionValueGetter({ row }: ValueGetterArgs) {
  if (!row.item) return "";
  return row.item.description;
}

export function eidValueGetter({ row }: ValueGetterArgs) {
  if (!row.item) return "";
  return row.item.eid;
}

export function extendedQuantityValueGetter({ row }: ValueGetterArgs) {
  const { quantity, waste } = row;
  const extendedQuantity = quantity && waste != null ? (quantity * (1 + (waste / 100))) : quantity;
  return Utils.roundFieldValue(extendedQuantity, 2).roundedValue;
}

export function lastUpdatedValueGetter({ row }: ValueGetterArgs) {
  if (!row.item) return "";
  return row.item.lastModified;
}

export function leadTimeValueGetter({ row }: ValueGetterArgs) {
  const { leadTimeDays } = row.item?.primarySource ?? {};
  let lt = "";
  if (leadTimeDays !== null) {
    lt = `${leadTimeDays} Days`;
  }

  return lt;
}

export function manufacturerValueGetter({ row }: ValueGetterArgs) {
  return row.item?.primarySource?.manufacturer;
}

export function massValueGetter({ row }: ValueGetterArgs) {
  return row.item?.mass;
}

export function mpnValueGetter({ row }: ValueGetterArgs) {
  return row.item?.primarySource?.mpn;
}

export function nameValueGetter({ row }: ValueGetterArgs) {
  if (!row.item) return "";
  return row.item.name;
}

export function procurementValueGetter({ row }: ValueGetterArgs) {
  if (!row.item) return "";
  const { procurement } = row.item;
  if (procurement === ProcurementType.NONE) return "";
  return procurement;
}

export function revisionValueGetter({ row }: ValueGetterArgs) {
  if (!row.item) return "";
  return row.item.revisionValue;
}

export function statusValueGetter({ row }: ValueGetterArgs) {
  if (!row.item) return "";
  return row.item.status;
}

export function totalPriceValueGetter({ row }: ValueGetterArgs) {
  return calculateChildTotalPrice(row);
}

export function unitOfMeasureValueGetter({ row }: ValueGetterArgs) {
  return row.item?.unitOfMeasure;
}

export function unitPriceValueGetter({ row }: ValueGetterArgs) {
  return row.item?.primarySource?.unitPrice;
}

export function useExtendedCostValueGetter() {
  const currencySymbol = useDefaultCurrencySymbol();
  return useCallback(({ row }: ValueGetterArgs) => {
    const { unitPrice } = row.item?.primarySource ?? {};
    const { extendedCost } = Utils.calculateExtendedQuantityAndCost(row.waste, row.quantity, unitPrice);
    return Utils.concatCurrencySymbol(extendedCost, currencySymbol);
  }, [currencySymbol]);
}

export function wasteValueGetter({ row }: ValueGetterArgs) {
  if (row.waste == null) return null;
  return row.waste.toFixed(2);
}
