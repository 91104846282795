import React from "react"

function SvgComponent(props) {
  return (
    <svg
      width="19px"
      height="19px"
      viewBox="0 0 19 19"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g stroke="#FFF" strokeWidth={1} fill="none" fillRule="evenodd">
        <path d="M0.5 0.5H18.5V18.5H0.5z" />
        <path d="M9.5.5v18M9.5 9.5h9M14 10v8M14 14H9.5" />
      </g>
    </svg>
  )
}

export default SvgComponent
