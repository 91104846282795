import                          "../index.css"
import React, {Component}       from "react"
import validations              from "../../../../../modules/validations"
import InlineIcon               from "../../../../ui/icon/inline-icon.js"
import ItemScheduleIcon         from "../../../../../assets/icons/item-schedule-icon"
import ItemScheduleMassIcon     from "../../../../../assets/icons/item-schedule-mass-icon"
import TimeStampColumn          from "../../timestamp-column"
import NumberFormat             from "react-number-format"
import TileItem                 from "../../tile-item"

export class ItemSchedule extends Component
{
  constructor(props)
  {
    super(props)
    this.forecastScheme = window.__libraryType === "GENERAL" && window.__buildScheduleScheme === "EXPERT" ? "EXPERT" : "DEFAULT"
  }

  componentDidUpdate()
  {
    this.forecastScheme = window.__libraryType === "GENERAL" && window.__buildScheduleScheme === "EXPERT" ? "EXPERT" : "DEFAULT"
  }

  render()
  {
    let { item, isPrdOrCmpViewPage } = this.props
    let forecastKeys = []
    item.forecasts.forEach((input) => forecastKeys.push(input.key))
    let markup =
      <TileItem title="Build schedule" icon={<ItemScheduleIcon />}>
        {
          item.forecasts.map((spec, i) => (
            <div className="schedule" key={`build-schedule-${i}`}>
              {
                spec.key && <span className="schedule-title">
                  { !isPrdOrCmpViewPage && spec.key.split('').map(val => val === val.toUpperCase() ? ` ${val}` : val).join('') }
                  { isPrdOrCmpViewPage && validations.product.forecasts.getForecastDisplayName(spec.key, spec.counter, this.forecastScheme, forecastKeys) }
                </span>
              }
              <div className={`schedule-date-amount diff-${spec.key ? spec.key.replace(/[()]/g, '').replace(/\s\s+/g, ' ').split(' ').join('-').toLowerCase() : ''}-section`}>
                <div className="schedule-date">
                  <InlineIcon tooltip="Date" tooltipPlace="top">
                    <ItemScheduleIcon />
                  </InlineIcon>
                  {
                    spec.targetDate ?
                    <TimeStampColumn format="date-with-long-format" value={spec.targetDate} /> :
                    <span className="no-day">Enter day</span>
                  }
                </div>
                <div className="schedule-amount">
                  { spec.volume &&
                    <NumberFormat
                      thousandsGroupStyle="thousand"
                      value={spec.volume}
                      displayType="text"
                      type="text"
                      thousandSeparator={true}
                      allowNegative={true}
                    />
                  }
                  <InlineIcon tooltip="Volume" tooltipPlace="top">
                    <ItemScheduleMassIcon />
                  </InlineIcon>
                </div>
              </div>
            </div>
          ))
        }
      </TileItem>
    return markup
  }
}

export default ItemSchedule
