import { DuroZenOverlay } from "common/components/duroZenOverlay";
import { EmptyTable } from "common/components/grid";
import { Link } from "react-router-dom";
import { privatePaths } from "v1/app/routes";

export type OverlayComponentType = React.JSXElementConstructor<any> | undefined;
export type OverlayPropsType = {
  footer: JSX.Element,
  tiles: TileType[],
} |  {
  subtitle: string,
};
export interface IUseManageNoRowsOverlayReturn {
  OverlayProps?: OverlayPropsType,
  OverlayComponent: OverlayComponentType
}

export type TileType = {
  buttonLabel: string,
  Icon: OverlayComponentType,
  text: string,
  onClick: () => void,
};

const noResultOverlayProps = {
  subtitle: "We could not find the results you’re looking for.\nPlease refine your search.",
};

const footer = (
  <>
    Read our <Link to={privatePaths.docs.quickstart} target="_blank"> Quick Start Guide </Link>
    and < Link to={privatePaths.docs.documentation} target="_blank" > Documentation </Link >
    to get up and running.
  </>
);

export const useManageNoRowsOverlay = (
  isSearchResult: boolean,
  loading: boolean | undefined,
  tiles: TileType[],
  areItemsLoaded?: boolean,
): IUseManageNoRowsOverlayReturn => {
  if (loading || areItemsLoaded) {
    return { OverlayComponent: () => <></>, OverlayProps: undefined };
  }

  if (isSearchResult) {
    return { OverlayComponent: DuroZenOverlay, OverlayProps: noResultOverlayProps };
  }
  return { OverlayComponent: EmptyTable, OverlayProps: { footer, tiles } };
};
