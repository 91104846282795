import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="13"
      height="13"
      viewBox="0 0 13 13"
    >
      <defs>
        <path id="path-10-0" d="M0 0L13 0 13 13 0 13z"></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-680 -203)">
          <g transform="translate(680 198)">
            <g transform="translate(0 5)">
              <mask id="mask-2" fill="#fff">
                <use xlinkHref="#path-10-0"></use>
              </mask>
              <path
                fill="#DA9526"
                d="M5.44 10.562c-.452 0-.783-.273-.467-1.479l.518-2.137c.09-.342.105-.48 0-.48-.135 0-.721.237-1.067.47l-.226-.37C5.296 5.65 6.559 5.112 7.1 5.112c.451 0 .526.534.3 1.356l-.593 2.247c-.105.397-.06.534.045.534.136 0 .58-.165 1.016-.508l.256.343c-1.068 1.068-2.234 1.48-2.685 1.48m1.694-8.396c.66 0 .855.383.855.821 0 .548-.438 1.055-1.186 1.055-.626 0-.924-.315-.906-.836 0-.438.367-1.04 1.237-1.04M6.413 0a6.5 6.5 0 10.175 13 6.5 6.5 0 00-.175-13"
                mask="url(#mask-2)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
