import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="#fffff"
        d="M23.472 8.537H24v-4.56H0v4.56h.528a3.463 3.463 0 010 6.926H0v4.56h24v-4.56h-.528a3.463 3.463 0 010-6.926zm-4.519 3.429v.004a4.52 4.52 0 003.969 4.486l.022.002v2.482H1.056v-2.482c2.155-.37 3.775-2.224 3.775-4.457S3.212 7.914 1.083 7.548l-.027-.004v-2.51h10.416v13.714h1.056V5.034h10.416v2.51a4.524 4.524 0 00-3.991 4.455v.002z"
      ></path>
    </svg>
  );
}

export default Icon;
