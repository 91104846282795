import                              "./index.css"
import React, {Component}   from    "react"
import Icon                 from    "../../../ui/icon"
import Utils                from    "../../../../modules/utils"
import deleteSrc            from    "../../../../assets/icons/cross-icon.svg"
import FileDrop             from    "../../../ui/file-drop"
import API                  from    "../../../../modules/api"
import ProgressCircle       from    "../../../ui/progress-circle";
import Config               from    "../../../../modules/config";


class Thumbnails extends Component
{
    constructor(props, context)
    {
        super(props, context)
        this.state =
         {
            class : "",

            inputs :
            {
                files :
                [

                ],

                fileDrop :
                {
                    collapsed : true,
                }
            }
        }
        this.onFilesChange = this.onFilesChange.bind(this)
        this.onDeleteClick = this.onDeleteClick.bind(this)
        this.onChange = this.onChange.bind(this)
        this.uploadThumbnails = this.uploadThumbnails.bind(this)
        this.isProcessedAllThumbnails = this.isProcessedAllThumbnails.bind(this)
        this.getThumbnailsIds = this.getThumbnailsIds.bind(this)
        this.onDropRejected = this.onDropRejected.bind(this);
    }

    componentWillUnmount()
    {
        let state = this.state
        state.inputs.files = []
        this.setState(state)
    }

    componentWillReceiveProps(nextProps)
    {
        if (nextProps.forceResetChildState)
        {
            let state = this.state
            state.inputs.files = []
            state.inputs.fileDrop.collapsed = true
            state.class = ""
            this.setState(state)
        }
    }

    onDropRejected(files, maxFileSize)
    {
        let inputs    = this.state.inputs;
        let newInputs = files.map((file) =>
        {
            let err = {message: "Invalid file type", type: "invalid-file-type"};
            let fileSize = file.size/1000000;
            if( fileSize > maxFileSize)
            {
                err = {message: `File exceeds ${maxFileSize}mb limit`, type: "file-exceed-limit"};
            }
            let input =
            {
                src  : file.preview,
                name : file.name,
                type : Utils.getExtension(file.name),
                size : Utils.fileSize(file.size),
                file : file,
                info:
                {
                    id: null,
                    processFlag: false,
                    errors: [{message: err.message}],
                    hasExceededMaxFileSize: err.type === "file-exceed-limit" ? true : false,
                    hasInvalidFileType: err.type === "invalid-file-type" ? true : false
                }
            }
            return input;
        });
        inputs.files   = [...inputs.files, ...newInputs];
        this.setState({inputs: inputs});
    }

    onFilesChange(files)
    {
        this.props.onChange && this.props.onChange([], false)
        let inputs    = this.state.inputs
        let newInputs = files.map((file, index) =>
        {
            let input =
            {
                src  : file.preview,
                name : file.name,
                type : Utils.getExtension(file.name),
                size : Utils.fileSize(file.size),
                sizeInBytes: file.size,
                file : file,
                fileId: `file-${index}`,
                info:
                {
                    id: null,
                    processFlag: false,
                    errors: []
                }
            }

            return input
        })

        inputs.files              = [...inputs.files, ...newInputs]
        inputs.fileDrop.collapsed = inputs.files.length > 0

        this.setState({inputs: inputs})
        this.setState(this.state, this.uploadThumbnails)
    }

    isPresentInInputFiles(fileId)
    {
        let state = this.state
        let found = false
        for (let img of state.inputs.files){
            if (img.fileId === fileId)
            {
                found = true
                break;
            }
        }
        return found
    }


    uploadThumbnails()
    {
        //set isBeingUploaded process flag true so we can display consisten UI
        let images = this.state.inputs.files
        let notProcessedImgs = []
        for (let img of images){
            if (!img.info.id)
            {
                img.info.isBeingUploaded = true;
                notProcessedImgs.push(img)
            }
        }


        let imgBatches = Utils.createBatches(notProcessedImgs, 5)
        let index = 0
        let state = this.state

        let onComplete = (err, data) =>
        {
            if(++index === imgBatches.length)
            {
                if (this.isProcessedAllThumbnails())
                {
                    this.props.onChange && this.props.onChange(this.getThumbnailsIds(), true)
                }
                return
            }
            else
            {
                this.props.onChange && this.props.onChange([], false)
            }
            create(index)
        }

        let create = () =>
        {
            let counter = 0
            for (let img of imgBatches[index])
            {
                if (!img.info.id && img.info.errors.length === 0)
                {
                    img.info.processFlag = true
                    img.info.isBeingUploaded = true;
                    this.setState({files: images})
                    let startTime =  new Date()/1000;
                    if (this.isPresentInInputFiles(img.fileId))
                    {
                        Utils.uploadDocument("/images", img.file
                        ,(err, currentProgress) =>
                        {
                            Utils.updateDocProgress(currentProgress, startTime, img, (fillRule) =>
                            {
                                img.progressSoFar = fillRule;
                                this.setState(this.state);
                            }, true);
                        }
                        ,(err, res) =>
                        {
                            counter++
                            if(err)
                            {
                                img.info.errors = err.errors;
                            }
                            else
                            {
                                img.info.errors = "";
                                img.info.id = res.data;
                            }
                            img.info.isBeingUploaded = false;
                            img.info.processFlag = false
                            this.setState({files: images});

                            if (counter === imgBatches[index].length)
                            {
                                onComplete()
                            }
                        });
                    }
                    else
                    {
                        counter++
                        if (counter === imgBatches[index].length)
                        {
                            onComplete()
                        }
                    }
                }
                else
                {
                    counter++
                    if (counter === imgBatches[index].length)
                    {
                        onComplete()
                    }
                }

            }
        }
        create(0)
    }

    isProcessedAllThumbnails()
    {
        let files = this.state.inputs.files
        let valid = true
        if (files && Array.isArray(files))
        {
            files.forEach(function(file)
            {
                if(file.info.processFlag === true)
                {
                    valid = false
                    return valid
                }
                if(file.info.errors.length > 0)
                {
                    valid = false
                    return valid
                }
            })
        }
        return valid
    }

    getThumbnailsIds()
    {
        let imagesIdz = []
        let files = this.state.inputs.files
        files.forEach((file) => imagesIdz.push(file.info.id))
        return imagesIdz
    }

    onDeleteClick(index)
    {
        let inputs = this.state.inputs
        inputs.files.splice(index, 1)
        inputs.fileDrop.collapsed = inputs.files.length > 0
        this.setState(this.state, this.onChange)
    }

    onChange()
    {
        if (this.isProcessedAllThumbnails())
        {
            this.props.onChange && this.props.onChange(this.getThumbnailsIds(), true)
        }
        else
        {
            this.props.onChange && this.props.onChange([], false)
        }
    }

    render()
    {
        let inputs = this.state.inputs
        let markup =

            <div className="thumbnails-block ">
                <FileDrop
                    accept="image/png, image/jpeg"
                    collapsed={inputs.fileDrop.collapsed}
                    onChange={this.onFilesChange}
                    onDropRejected={this.onDropRejected}
                />

                <div className={"list " + (inputs.files.length < 1 ? "empty" : "")}>
                    {
                        inputs.files.map((input, i) =>
                        {
                            let isInProgress = input.info.isBeingUploaded;
                            let isInvalid    = input.info.hasInvalidFileType || input.info.hasExceededMaxFileSize || input.info.errors;
                            let block =
                                <div key={i}
                                    className={`upload-file-list ${isInProgress ? "file-uploading" : input.info.errors.length ? "file-upload-error" : ""}`}
                                >

                                    <div className="img-name-holder">
                                        <div className="image-holder" style={{backgroundImage: "url("+ input.src +")"}} />
                                        <div className={"file-name"}>
                                            {input.name}
                                        </div>
                                    </div>

                                    <div className="cross-size-holder">
                                        {
                                            <span className={isInProgress || isInvalid ? "current-progress" : ""}>
                                             {
                                                isInProgress || isInvalid ? input.info.errors[0] && input.info.errors[0].message ? input.info.errors[0].message :
                                                    <div>
                                                        <span className="cancel-file" onClick={() => this.onDeleteClick(i)}>cancel </span>
                                                        <i>{input.info.errors.length ? input.info.errors : "Waiting to upload..."}</i>
                                                    </div>
                                                : ""
                                            }
                                            </span>
                                        }
                                        {
                                            isInvalid || isInProgress ? null
                                            :
                                            <div className="file-size">
                                                {input.size}
                                            </div>
                                        }

                                        {
                                            isInProgress ?
                                            <ProgressCircle progressSoFar={input.progressSoFar} />
                                            :
                                            <div className="delete-icon">
                                                <Icon src={deleteSrc} onClick={() => this.onDeleteClick(i)}/>
                                            </div>
                                        }
                                    </div>
                                </div>
                            return block
                        })
                    }
                </div>
            </div>

        return markup
    }
}
export default Thumbnails
