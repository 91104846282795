import React from "react";

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='19'
      version='1.1'
      viewBox='0 0 16 19'
      id="import-icon"
    >
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g fill='#FFF' fillRule='nonzero' transform='translate(-2394 -634)'>
          <g transform='translate(1711 626)'>
            <g transform='translate(521)'>
              <g transform='translate(163 9)'>
                <g>
                  <path
                    id="path-1"
                    stroke='#FFF'
                    strokeWidth='0.25'
                    d='M14.051 4.363L9.723.113A.397.397 0 009.444 0H.394A.39.39 0 000 .386v16.228A.39.39 0 00.394 17h13.38a.39.39 0 00.393-.386V4.636c0-.102-.042-.2-.116-.273zM9.838 1.32l2.985 2.931H9.838V1.319zM.787 16.227V.773h8.264v3.863a.39.39 0 00.393.387h3.936v11.204H.787z'
                  ></path>
                  <path id="path-2" d='M10.402 7H3.39a.383.383 0 00-.39.375V13c0 .207.175.375.39.375h7.012c.215 0 .39-.168.39-.375V7.375a.383.383 0 00-.39-.375zm-.39 1.875H6.117V7.75h3.895v1.125zm-6.233.75h1.559v1.125H3.779V9.625zm2.338 0h3.895v1.125H6.117V9.625zm-.78-1.875v1.125H3.78V7.75h1.559zM3.78 11.5h1.559v1.125H3.779V11.5zm2.338 1.125V11.5h3.895v1.125H6.117z'></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
