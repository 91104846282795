import React, {Component} from "react";
import { connect }        from "react-redux";
import loadSrc            from "../../../../assets/icons/loading.svg";
import Icon               from "../../../ui/icon";
import                    "./index.css"
export class ValidationRunningModal extends Component
{
    componentDidUpdate()
    {
        if (this.props.setValidationModalPosition)
        {
            // this.props.setValidationModalPosition();
        }
    }
    render()
    {
        const {open, isValidating, hideModal, message} = this.props;
        let markup =
            <div ref="change-order-validation" className={`${(open ? "open " : "")} validation-running ${(isValidating || hideModal === "") ? "" : "hide-modal"}`}>
                {
                    <div className="popover-content-block">
                            <div className="validation-heading">
                                <div className="loading-block">
                                    <Icon src={loadSrc} />
                                </div>
                                <h4 className="">Please wait a moment...</h4>
                            </div>

                            <p className="">{message}</p>
                    </div>
                }
            </div>
        return markup
    }
}

const mapStateToProps = state => ({
    isValidating: state.ui.isValidating
})
export default connect(mapStateToProps)(ValidationRunningModal)
