export function getInitialState()
{
    return {
      side_bar_flag: false,
      file_import_bar_flag: false
    }
}

let initialState = getInitialState()
export default initialState
