import { Icon, styled, Tooltip } from "@mui/material";
import { PageItemType } from "design/constants";
import { useMemo } from "react";
import CoAlert from "v1/assets/icons/co-alert";

export interface RevisionFieldProps {
  pageItemtype: PageItemType;
  revision?: string;
  showIcon?: boolean;
}

export function RevisionField(props: RevisionFieldProps) {
  const { pageItemtype, revision, showIcon = true } = props;

  const title = useMemo(() => {
    switch (pageItemtype) {
      case PageItemType.COMPONENT: return "Component has been modified";
      default: return "Product has been modified";
    }
  }, [pageItemtype]);

  return (
    <>
      {revision || "—"}
      {showIcon && (
        <Tooltip placement="right" title={title}>
          <RevisionSmallIcon><CoAlert /></RevisionSmallIcon>
        </Tooltip>
      )}
    </>
  );
}

const RevisionSmallIcon = styled(Icon)(() => ({
  fontSize: "6px",
  height: "100%",
  paddingTop: "8px",
  "& > svg": {
    fontSize: "6px",
    height: "6px",
    width: "6px",
  },
}));
