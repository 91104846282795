import { Box, Icon, styled, Tooltip } from "@mui/material";
import { useMassPrecisionValue } from "common/hooks";
import { useMemo } from "react";
import NumberFormat from "react-number-format";
import AlertIcon from "v1/assets/icons/alert-icon";
import WarningTriangleIcon from "v1/assets/icons/warning-triangle";

const RightAlignedText = styled(Box)(() => ({
  flex: 1,
  textAlign: "right",
}));

function getRoundedMass(mass: number | string, massPrecision: number): string {
  if (!mass && mass !== 0) return "";
  return Number(mass).toFixed(massPrecision);
}

export interface MassFieldProps {
  isAssembly?: boolean;
  mass: number;
  massPrecision?: number;
  massStatus?: string;
}

export function MassField({ isAssembly, mass, massPrecision, massStatus }: MassFieldProps) {
  const defaultMassPrecisionValue = useMassPrecisionValue();

  const { showError, showWarning } = useMemo(() => ({
    showError: isAssembly && massStatus?.toUpperCase() === "ERROR",
    showWarning: isAssembly && massStatus?.toUpperCase() === "WARNING",
  }), [isAssembly, massStatus]);

  const roundedMass = useMemo(
    () => getRoundedMass(mass, massPrecision ?? defaultMassPrecisionValue),
    [defaultMassPrecisionValue, mass, massPrecision],
  );

  return (
    <>
      {showError && (
        <Tooltip title="Missing mass values for one or more children" placement="top">
          <StyledIcon><AlertIcon /></StyledIcon>
        </Tooltip>
      )}
      {showWarning && (
        <Tooltip title="Manually entered value" placement="top">
          <StyledIcon><WarningTriangleIcon /></StyledIcon>
        </Tooltip>
      )}
      <RightAlignedText>
        <NumberFormat
          value={roundedMass}
          displayType={"text"}
          thousandSeparator={true}
        />
      </RightAlignedText>
    </>
  );
}

const StyledIcon = styled(Icon)(() => ({
  fontSize: "15px",
  "& > svg": {
    fontSize: "10px",
    height: "15px",
    width: "15px",
  },
}));
