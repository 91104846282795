import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="15"
      viewBox="0 0 12 15"
    >
      <g fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fillRule="nonzero" transform="translate(-1183 -92)">
          <g transform="translate(1173 84)">
            <g transform="translate(10 8)">
              <path d="M10.142 2.842v-1.61h.771a.62.62 0 00.625-.616.62.62 0 00-.625-.616H.625A.62.62 0 000 .616c0 .34.28.617.625.617h.605v1.58c0 .793.343 1.551.942 2.08l2.831 2.499-2.71 2.498a2.782 2.782 0 00-.897 2.038v1.84h-.77a.62.62 0 00-.626.616c0 .34.28.616.625.616h10.288a.62.62 0 00.625-.616.62.62 0 00-.625-.617h-.604v-1.81c0-.793-.344-1.55-.942-2.08L6.535 7.379l2.71-2.498c.57-.526.897-1.268.897-2.038zm-1.084 9.115v1.81H2.647v-1.839c0-.429.182-.844.5-1.137l2.631-2.426 2.754 2.431c.335.295.526.718.526 1.16zm-.667-7.978L5.76 6.405l-2.754-2.43a1.55 1.55 0 01-.526-1.161V1.233h6.412v1.609c0 .43-.183.844-.5 1.137z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
