export function getInitialState()
{
    return {
        component: 		 null,
        loading: 		 false,
        documents_loaded:false,
        children_loaded: false,
        tabsUpdatedAt: null,
    }

}

let initialState = getInitialState()
export default initialState
