import { combineReducers } from 'redux';
import newPage from "./new"
import editPage from "./edit"
import viewPage from "./view"
import revisionPage from "./revision"

const productReducer = combineReducers({
    newPage,
    editPage,
    viewPage,
    revisionPage
});

export default productReducer;
