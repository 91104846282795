import COMPONENT from "../../../action-types/component"
import initialState, { getInitialState } from "./initial_state"
import { handleActions } from "redux-actions"

function setRevision(state=initialState, { payload })
{
    state.component = payload.component
    state.revision = payload.revision
    state.backgroundStyle.backgroundImage = payload.backgroundStyle.backgroundImage
    return {...state}
}

function resetPageStates(state=initialState, { payload })
{
    return {...getInitialState()}
}

function setNestedDocumentsOfRevision(state= initialState, { payload })
{
    state.revision.documents  = payload;
    return {...state};
}

function setNestedChildrenOfRevision(state= initialState, { payload })
{
    state.revision.children  = payload;
    return {...state};
}

function setDocumentsLoaded(state= initialState, { payload })
{
    state.documents_loaded  = payload;
    return {...state};
}

function setChildrenLoaded(state= initialState, { payload })
{
    state.children_loaded  = payload;
    return {...state};
}

export default handleActions({
  [COMPONENT.RESET_STATES_IN_REVISION_PAGE]: resetPageStates,
  [COMPONENT.SET_REVISION_IN_REVISION_PAGE]: setRevision,
  [COMPONENT.SET_REVISION_NESTED_DOCUMENTS]: setNestedDocumentsOfRevision,
  [COMPONENT.SET_REVISION_NESTED_CHILDREN] : setNestedChildrenOfRevision,
  [COMPONENT.SET_REVISION_DOCUMENTS_LOADED]: setDocumentsLoaded,
  [COMPONENT.SET_REVISION_CHILDREN_LOADED] : setChildrenLoaded,
}, initialState);
