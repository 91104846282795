import "v1/styles/index.css";
import { useReactiveVar } from "@apollo/client";
import { PaletteMode } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { ThemeProvider } from "@mui/material/styles";
import { useAuthInfo, useRedirectFunctions } from "@propelauth/react";
import { PlatformNotifierProvider } from "common/components/platformNotifier/provider";
import { ToastsProvider } from "common/components/toasts";
import { AppContext, AppSection } from "context";
import { client, setGraphqlHeaders } from "graphql/apolloClient";
import { getSubdomain, getUser, userById } from "graphql/query/userQueries";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useDuroTheme } from "theme";
import USER from "v1/action-types/user";
import Routes from "v1/components/routes";
import buildAction from "v1/helpers/buildAction";
import config from "v1/modules/config";
import Utils from "v1/modules/utils";
import { analyticsAndTracking } from "./common/analytics";
import { useLocationChange } from "./common/hooks/useLocationChange";

export default function App() {
  const { redirectToLoginPage } = useRedirectFunctions();
  const { isLoggedIn, accessToken } = useAuthInfo() as any;
  const [userId, setUserId] = useState("");
  const user = useReactiveVar(userById);
  const [loginError, setLoginError] = useState(false);
  const [mode, setMode] = React.useState<PaletteMode>("dark");
  const [section, setSection] = React.useState(AppSection.DESIGN);
  const theme = useDuroTheme(mode, section);
  const dispatch = useDispatch();
  useLocationChange(() => analyticsAndTracking(user));

  if (isLoggedIn === false) {
    redirectToLoginPage({ postLoginRedirectUrl: window.location.href });
  }

  useMemo(async () => {
    if (isLoggedIn && accessToken) {
      // Calling core-api first ensures it has created a new user (if necessary) before calling plm-api
      setGraphqlHeaders(accessToken);
      const { data: subdomain } = await getSubdomain(client);
      forwardToSubdomain(subdomain?.forwardTo);

      // For plm-api
      Utils.headers = {
        authorization: `Bearer ${accessToken}`,
      };

      const { data: _user, errors } = await getUser(client);
      if (errors?.find(e => e?.message.includes("Error"))) {
        setLoginError(true);
        return;
      }
      // Short-circuit changes to accessToken or dispatch from re-rendering the entire app.
      if (_user && (_user.id !== userId)) {
        setUserId(_user.id);
        const userKey = `firstLogin-${_user.id}`;
        if (_user.logins?.length <= 1 && (JSON.parse(localStorage.getItem(userKey) as any) === null)) {
          localStorage.setItem(userKey, "true");
        }
        dispatch(buildAction(USER.SETUP_USER, _user));
      }
    }
    else {
      // Logged out
      setGraphqlHeaders("");
      setUserId("");
      Utils.headers = {};
    }
  }, [accessToken, dispatch, isLoggedIn, userId]);

  const appContext = useMemo(() => ({
    toggleColorMode: () => {
      setMode(prevMode => (prevMode === "light" ? "dark" : "light"));
    },
    setUserId: (_userId: string) => {
      setUserId(_userId ?? "");
    },
    userId,
    setSection,
    section,
  }), [userId, section]);

  const navigateAccount = useCallback(() => {
    window.location.href = `${config.REACT_APP_AUTH_URL}/account`;
  }, []);

  const render = useMemo(() => (
    <AppContext.Provider value={appContext}>
      <ThemeProvider theme={theme}>
        <ToastsProvider>
          <PlatformNotifierProvider>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            {userId && <Routes />}
            <Dialog open={loginError}>
              <Box sx={{ margin: "1rem" }}>
                <DialogTitle>Login error. Check account settings.</DialogTitle>
                <DialogActions>
                  <Button onClick={navigateAccount}>
                    Account
                  </Button>
                </DialogActions>
              </Box>
            </Dialog>
          </PlatformNotifierProvider>
        </ToastsProvider>
      </ThemeProvider>
    </AppContext.Provider>
  ), [appContext, loginError, navigateAccount, theme, userId]);

  return render;
}

// Forward to appropriate subdomain if necessary
function forwardToSubdomain(companySubdomain: string) {
  if (!companySubdomain) return;
  const currentSubdomain = window.location.origin;
  if (currentSubdomain !== companySubdomain) {
    window.location.replace(`${companySubdomain}${window.location.pathname}`);
  }
}
