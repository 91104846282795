import                  "./index.css"
import React       from "react"
import ModalBox    from "../../../../ui/modal-box"

export class DeleteModal extends React.Component
{
    render()
    {
        let markup = <div className="delete-items-modal-wrapper">
            <ModalBox
                onClose={this.props.hideDeleteModal}
                className='delete-items-modal'
            >
            <h1>Delete the following items?</h1>
            <div className="items-list">
                {
                    this.props.components.map((component, i) =>
                    {
                        let block =
                            <div key={i + "-deleted-items-list"} className="item-row">
                                <span className="muted-text">{component.cpn + " "}</span>
                                <span>{component.name}</span>
                            </div>
                        return block
                    })
                }

                {
                    this.props.products.map((product, i) =>
                    {
                        let block =
                            <div key={i + "-deleted-items-list"} className="item-row">
                                <span className="muted-text">{product.cpn + " "}</span>
                                <span>{product.name}</span>
                            </div>
                        return block
                    })
                }
            </div>
            <div className="bottom-section">
                <div className="muted-text">This action cannot be undone.</div>
                <div className="button-sections">
                  <button
                      type="button"
                      value="cancel"
                      onClick={this.props.hideDeleteModal}
                      className="cancel-btn">
                    Cancel
                  </button>

                  <button
                      type="button"
                      value="delete"
                      onClick={this.props.deleteSelectedItems}
                      className="delete-btn danger">
                    Delete
                  </button>

                </div>
            </div>

            </ModalBox>
        </div>
        return markup
    }
}

export default DeleteModal
