export default {
    user : {
        _id: null,
        email: "",
        firstName: "",
        lastName: "",
        title: "",
        role: "",
        avatar:
        {
            src: ""
        },
        oauth2Google: {
            id: "",
            profile: {
                display: "",
                avatarUrl: "",
            },
        },
    },
    data : {
        _id: null,
        email: "",
        firstName: "",
        lastName: "",
        title: "",
        role: "",
        avatar:
        {
            src: ""
        }
    },
    inputs :
    {
        isUploadedImage: true,
        email :
        {
            message : "",
            class   : "",
            value   : "",
            valid   : true
        },

        firstName :
        {
            message : "",
            class   : "",
            value   : "",
            valid   : true
        },

        lastName :
        {
            message : "",
            class   : "",
            value   : "",
            valid   : true
        },

        title :
        {
            message : "",
            class   : "",
            value   : "",
            valid   : true
        },

        phoneNumber :
        {
            message : "",
            class   : "",
            value   : "",
            valid   : true
        },

        role :
        {
            message : "",
            class   : "",
            value   : "",
            valid   : true
        },

        twoFaEnabled :
        {
            message : "",
            class   : "",
            value   : false,
            valid   : true
        },

        twoFaSecret :
        {
            message : "",
            class   : "",
            value   : "",
            valid   : true
        },

        password :
        {
            old :
            {
                message : "",
                class   : "",
                value   : "",
                valid   : true
            },

            new :
            {
                message : "",
                class   : "",
                value   : "",
                valid   : true
            },

            confirm :
            {
                message : "",
                class   : "",
                value   : "",
                valid   : true
            }
        },

        avatar :
        {
            class : false ? "filled" : "",
            style :
            {
                backgroundImage : "url('')"
            }
        },

        subAccountsCount: 1,

        submit :
        {
            class : "disabled"
        }
    },

    passwordBlock :
    {
        class : ""
    }
}

