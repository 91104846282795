import validator from "validator"
import utils from "../utils"

const companySchema = {
    name:
    {
        normalize: (value) =>
        {
            value = typeof value === "string" ? value : ""
            value = utils.toAlphaNumericString(value, " ")
            value = utils.toParagraphString(value)
            return value
        },

        validate: (value) =>
        {
            if (typeof value !== "string")
            {
                return "Invalid company name"
            }

            if (!value)
            {
                return "Company name is required"
            }

            if (utils.hasSpecialChar(value))
            {
                return "Must only contain letters and numbers"
            }

            return ""
        }
    },

    website:
    {
        normalize: (value) =>
        {
            value = typeof value === "string" ? value : ""
            value = utils.toCondensedString(value)
            return value
        },

        validate: (value) =>
        {
            if (value === "")
            {
                return ""
            }

            if (!validator.isURL(value))
            {
                return "Invalid website"
            }

            return ""
        }
    },

    address:
    {
        street:
        {
            normalize: (value) =>
            {
                value = typeof value === "string" ? value : ""
                value = utils.toParagraphString(value)
                return value
            },

            validate: (value) =>
            {
                return ""
            }
        },

        suite:
        {
            normalize: (value) =>
            {
                value = typeof value === "string" ? value : ""
                value = utils.toParagraphString(value)
                return value
            },

            validate: (value) =>
            {
                return ""
            }
        },

        city:
        {
            normalize: (value) =>
            {
                value = typeof value === "string" ? value : ""
                value = utils.toAlphaString(value, " ")
                value = utils.toParagraphString(value)
                return value
            },

            validate: (value) =>
            {
                return ""
            }
        },

        state:
        {
            list()
            {
                let list =
                [
                {
                    displayName: "Alabama"
                },
                {
                    displayName: "Alaska"
                },
                {
                    displayName: "Arizona"
                },
                {
                    displayName: "Arkansas"
                },
                {
                    displayName: "California"
                },
                {
                    displayName: "Colorado"
                },
                {
                    displayName: "Connecticut"
                },
                {
                    displayName: "Delaware"
                },
                {
                    displayName: "Florida"
                },
                {
                    displayName: "Georgia"
                },
                {
                    displayName: "Hawaii"
                },
                {
                    displayName: "Idaho"
                },
                {
                    displayName: "Illinois"
                },
                {
                    displayName: "Indiana"
                },
                {
                    displayName: "Iowa"
                },
                {
                    displayName: "Kansas"
                },
                {
                    displayName: "Kentucky"
                },
                {
                    displayName: "Louisiana"
                },
                {
                    displayName: "Maine"
                },
                {
                    displayName: "Maryland"
                },
                {
                    displayName: "Massachusetts"
                },
                {
                    displayName: "Michigan"
                },
                {
                    displayName: "Minnesota"
                },
                {
                    displayName: "Mississippi"
                },
                {
                    displayName: "Missouri"
                },
                {
                    displayName: "Montana"
                },
                {
                    displayName: "Nebraska"
                },
                {
                    displayName: "Nevada"
                },
                {
                    displayName: "New Hampshire"
                },
                {
                    displayName: "New Jersey"
                },
                {
                    displayName: "New Mexico"
                },
                {
                    displayName: "New York"
                },
                {
                    displayName: "North Carolina"
                },
                {
                    displayName: "North Dakota"
                },
                {
                    displayName: "Ohio"
                },
                {
                    displayName: "Oklahoma"
                },
                {
                    displayName: "Oregon"
                },
                {
                    displayName: "Pennsylvania"
                },
                {
                    displayName: "Rhode Island"
                },
                {
                    displayName: "South Carolina"
                },
                {
                    displayName: "South Dakota"
                },
                {
                    displayName: "Tennessee"
                },
                {
                    displayName: "Texas"
                },
                {
                    displayName: "Utah"
                },
                {
                    displayName: "Vermont"
                },
                {
                    displayName: "Virginia"
                },
                {
                    displayName: "Washington"
                },
                {
                    displayName: "West Virginia"
                },
                {
                    displayName: "Wisconsin"
                },
                {
                    displayName: "Wyoming"
                }
                ]

                return list
            },

            normalize: (value) =>
            {
                return value
            },

            validate: (value) =>
            {
                return ""
            }
        },

        zip:
        {
            normalize: (value) =>
            {
                value = typeof value === "string" ? value : ""
                value = utils.toNumericString(value)
                value = utils.toCondensedString(value)
                value = utils.toTruncatedString(value, 5)
                return value
            },

            validate: (value) =>
            {
                return ""
            }
        }
    }
}

export default companySchema
