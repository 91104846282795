import { Component, createRef } from "react";
import 'react-perfect-scrollbar/dist/css/styles.css';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import ON_BOARDING from "../../action-types/on-boarding";
import USER from "../../action-types/user";
import { privatePaths, publicPaths } from "../../app/routes";
import buildAction from "../../helpers/buildAction";
import Notification from "../ui/notification";
import "./index.css";

// // -- common components --
import Alert from "../ui/alert";
import Header from "../ui/header";
import Loading from "../ui/loading";
import Sidebar from "../ui/sidebar";

// // -- public routes --
import Policy from "../page/legal/policy";
import Terms from "../page/legal/terms";

// // -- private routes --
import EditChangeOrder from "./../page/changeorder/edit";
import NewChangeOrder from "./../page/changeorder/new";
import ViewChangeOrder from "./../page/changeorder/view";
import DiffComponent from "./../page/component/diff";
import RevisionComponent from "./../page/component/revision";
import Dashboard from "./../page/dashboard";
import ExportRoute from "./../page/export";
import DiffProduct from "./../page/product/diff";
import EditProduct from "./../page/product/edit";
import NewProduct from "./../page/product/new";
import RevisionProduct from "./../page/product/revision";
import ViewProduct from "./../page/product/view/tile-view";
import EditProductionInstance from "./../page/production-instance/edit";
import ViewProductionInstance from "./../page/production-instance/view";
import ViewRelease from "./../page/release/view";
import Search from "./../page/search";
import ViewWebhook from "./../page/webhook/view";

import { Box, styled } from "@mui/material";
import { BuildExport } from "build/pages/export";
import { BuildInstance } from "build/pages/instance";
import { BuildInstances } from "build/pages/instances";
import { BuildLot } from "build/pages/lot";
import { BuildLots } from "build/pages/lots";
import { BuildProduction } from "build/pages/production";
import { BuildProductions } from "build/pages/productions";
import { AppSectionProvider } from "common/appSectionProvider";
import { LaunchDarklyProvider } from "common/components/launchDarkly";
import { AppSection } from "context";
import { ChangeOrderEditPage } from "design/pages/changeOrderEdit";
import { ComponentPage } from "design/pages/component";
import { Components } from "design/pages/components";
import { Products } from "design/pages/products";
import URLSearchParams from "url-search-params";
import LoadingIcon from "../../assets/icons/loading";
import Permissions from "../../modules/schemas/permissions";
import InlineIcon from "../ui/icon/inline-icon.js";
import ModalBox from "../ui/modal-box";
import { SettingsSideNav } from "../page/settings/setting-side-nav";

const BOTTOM_OFFSET = 200;

const tabsPermissionMapping = {
    "Company Profile": "company_profile",
    Configuration: "configurations",
    Groups: "groups",
    Integrations: "integrations",
    "Your Profile": "user_profile",
    Users: "user",
    Webhooks: "webhook",
};

class Routes extends Component
{

    constructor(props, context) {
        super(props, context);
        this.containerRef = createRef();
        this.setScrollHight = this.setScrollHight.bind(this)
        this.allowedRoute = this.allowedRoute.bind(this)
        this.closeAccountSetupProgressModal = this.closeAccountSetupProgressModal.bind(this);
    }

    closeAccountSetupProgressModal()
    {
        const {syncAccountSetupProgress} = this.props;
        syncAccountSetupProgress();
    }

    componentWillMount()
    {
        const {switchENV } = this.props
        const user = this.props.user.data
        let isSandboxMode = user && user.currentEnv === "SANDBOX"
        let settingsRoutes =
        [
            "/settings",
        ]
        if (isSandboxMode && settingsRoutes.includes(window.location.pathname))
        {
            let libraries = user.libraries
            let payload = {
                activeLibrary: libraries[0].type  === "PERSONAL" ?  libraries[1] : libraries[0],
                user: user,
                currentEnv: "LIVE"
            }
            switchENV(payload)
        }
    }

    componentDidMount()
    {
        this.setScrollHight()
    }

    allowedRoute(action, className, user, pathname, componentName)
    {
        let urlParams = new URLSearchParams(window.location.search)
        let tab  = urlParams.has('tab') ? urlParams.get('tab') : null

        // If the user does not have permission to view this route, then hide it.
        if (
            !Permissions.can(action, className, user) &&
            // Allow vendors to edit the sourcing tab.
            !(window.__userRole === 'VENDOR' && action === 'edit' && tab && tab.includes('sourcing'))
        ) {
            return null;
        }

        const PageComponent = componentName;

        return (
            <Route path={pathname} render={props =>
                <AppSectionProvider section={AppSection.DESIGN}>
                    <PageComponent {...props} />
                </AppSectionProvider>
            } />
        )
    }

    allowedBuildRoute = (action, className, path, componentName) => {
        const { user } = this.props;

        // TODO: BLD-47: Hide or disable the build feature if build is disabled.

        if (!Permissions.can(action, className, user.data)) {
            return null;
        }

        const PageComponent = componentName;
        const { pathname, state } = path

        return (
            <Route path={pathname}>
                <AppSectionProvider section={AppSection.BUILD}>
                    <PageComponent {...(state ?? {})} />
                </AppSectionProvider>
            </Route>
        )
    }

    setScrollHight()
    {
        window.changeLeftNavHeight()
    }

    componentDidUpdate(prevProps, prevState)
    {
        this.setScrollHight()
    }

    dispatchCustomScrollToEndEvent(container)
    {
        const pathname = window.location.pathname
        const havePagination = pathname === "/search" ||
                             pathname.includes('component/edit') ||
                             pathname.includes('component/view') ||
                             pathname.includes('component/revision') ||
                             pathname.includes('product/edit') ||
                             pathname.includes('product/view') ||
                             pathname.includes('changeorder/new') ||
                             pathname.includes('changeorder/edit') ||
                             pathname.includes('changeorder/view') ||
                             pathname.includes('product/revision') ||
                             pathname.includes('product/diff') ||
                             pathname.includes('component/diff') ||
                             pathname.includes('release/view') ||
                             pathname.includes('webhook/edit') ||
                             pathname.includes('webhook/view') ||
                             pathname.includes('production/instance/view') ||
                             pathname.includes('production/instance/edit')

        if (container.scrollTop !== 0 && havePagination)
        {
            let el = document.getElementById('routes')
            let event = new CustomEvent('scrollToEnd')
            el.dispatchEvent(event);
        }
    }

    handleScroll = () => {
        const container = this.containerRef.current;
        if (container.scrollTop + container.clientHeight + BOTTOM_OFFSET >= container.scrollHeight) {
            // User has reached the end of scrolling
            this.dispatchCustomScrollToEndEvent(container);
        }
    }

    render()
    {
        const { ui, company, on_boarding, history: { location } } = this.props;
        const user = this.props.user.data

        const loading                            = ui.loading

        const header  = ui.nolayout ? null : <Route path="/" component={Header} />
        const sidebar = ui.nolayout ? null : <Route path="/" component={Sidebar} />

        let contentClass = ""

        if (this.props.history.location.pathname) {
            contentClass = this.props.history.location.pathname
            contentClass = contentClass.split('/').join(' ')
        }
        let isSandboxMode = user && user.currentEnv === "SANDBOX"
        if (isSandboxMode)
        {
            contentClass += ' sandbox-env '
        }

        const publicRoutes =
            <Switch>
              <Route path={publicPaths.legal.policy.pathname} component={Policy} />
                <Route path={publicPaths.legal.terms.pathname} component={Terms} />
          </Switch>

        let privateDefaultRoute = privatePaths.dashboard.pathname
        const signInPath = decodeURIComponent(window.location.search)
        const allowedRoute = this.allowedRoute
        const allowedBuildRoute = this.allowedBuildRoute

        if (signInPath !== "")
        {
            privateDefaultRoute = signInPath.replace("?dest=","")
        }
        const isConfigurationsEnabled = window && window.__isConfigurationsEnabled;
        const newRoutesIdentifier = ["components", "products", "changeOrder/new", "build"];
        const newRoutes =
            <Switch>
                {allowedRoute("view", "search", user, privatePaths.components.pathname, Components)}
                {allowedRoute("view", "search", user, privatePaths.products.pathname, Products)}
                {allowedRoute("create", "change_order", user, privatePaths.changeOrderEditWithMui.pathname, ChangeOrderEditPage)},

                {/* TODO: BLD-53: Set proper action and className values for build page permissions */}
                {allowedBuildRoute("view", "dashboard", privatePaths.buildProductions, BuildProductions)}
                {allowedBuildRoute("view", "dashboard", privatePaths.buildProduction, BuildProduction)}
                {allowedBuildRoute("view", "dashboard", privatePaths.buildLots, BuildLots)}
                {allowedBuildRoute("view", "dashboard", privatePaths.buildLot, BuildLot)}
                {allowedBuildRoute("view", "dashboard", privatePaths.buildInstances, BuildInstances)}
                {allowedBuildRoute("view", "dashboard", privatePaths.buildInstance, BuildInstance)}
                {allowedBuildRoute("view", "dashboard", privatePaths.buildExport, BuildExport)}
            </Switch>

        const privateRoutes =
            <Switch>
                {/* Route to handle all the setting pages */}
                {allowedRoute("view", tabsPermissionMapping[location?.state?.currentTab] ?? "user", user, privatePaths.settings.pathname, SettingsSideNav)}

                {allowedRoute("view", "dashboard", user, privatePaths.dashboard.pathname, Dashboard)}
                {allowedRoute("view", "change_order", user, privatePaths.changeOrderShow.pathname, ViewChangeOrder)}
                {allowedRoute("view", "release", user, privatePaths.releaseShow.pathname, ViewRelease)}
                {allowedRoute("view", "product", user, privatePaths.productView.pathname, ViewProduct)}
                {allowedRoute("view", "product", user, privatePaths.productDiff.pathname, DiffProduct)}
                {allowedRoute("view", "component", user, privatePaths.componentDiff.pathname, DiffComponent)}
                {allowedRoute("view", "component", user, privatePaths.componentView.pathname, ComponentPage)}
                {allowedRoute("view", "export_cmp_prd", user, privatePaths.export.pathname, ExportRoute)}
                {allowedRoute("view", "search", user, privatePaths.search.pathname, Search)}

                {allowedRoute("create", "change_order", user, privatePaths.changeOrderNew.pathname, NewChangeOrder)}
                {allowedRoute("create", "product", user, privatePaths.productNew.pathname, NewProduct)}
                {allowedRoute("edit", "change_order", user, privatePaths.editChangeOrder.pathname, EditChangeOrder)}
                {allowedRoute("edit", "product", user, privatePaths.productEdit.pathname, EditProduct)}
                {allowedRoute("edit", "component", user, privatePaths.componentEdit.pathname, ComponentPage)}
                {allowedRoute("view", "revision", user, privatePaths.componentRevision.pathname, RevisionComponent)}
                {allowedRoute("view", "revision", user, privatePaths.productRevision.pathname, RevisionProduct)}
                {allowedRoute("view", "webhook", user, privatePaths.webhookView.pathname, ViewWebhook)}

                {allowedRoute("view", "production_instance", user, privatePaths.productionInstanceView.pathname, ViewProductionInstance)}
                {allowedRoute("edit", "production_instance", user, privatePaths.productionInstanceEdit.pathname, EditProductionInstance)}

                <Route path={publicPaths.legal.policy.pathname} component={Policy} />
                <Route path={publicPaths.legal.terms.pathname} component={Terms} />
                {
                    Object.keys(company.data).length &&
                    <Redirect to={privateDefaultRoute} />
                }
            </Switch>

        const legacyMarkup = (
            <OldPageScrollArea className="main-content routes" id="routes" onScroll={this.handleScroll} ref={this.containerRef}>
                {user ? privateRoutes : publicRoutes}
            </OldPageScrollArea>
        );
        const newMarkup = (
            <PageWrapper id="routes">
                {newRoutes}
            </PageWrapper>
        );

        const markups = {
            NEW: newMarkup,
            LEGACY: legacyMarkup,
        };
        const routeGroup = user && newRoutesIdentifier.some((item) => window.location.href.includes(item)) ? "NEW" : "LEGACY";

        const body = () => (
            <>
                {markups[routeGroup]}
                {
                    on_boarding.accountSetupCompleted === false &&
                    <div className={"new-custom-confirm-modal onboarding-in-progress"}>
                        <ModalBox
                            onClose={this.closeAccountSetupProgressModal}
                        >
                            <div>
                                <h1>
                                    Your account is being set up
                                </h1>
                                <p>Please wait while we set up a sample product in your sandbox.</p>
                                <div className="loading-block">
                                    <InlineIcon>
                                        <LoadingIcon />
                                    </InlineIcon>
                                </div>
                            </div>
                        </ModalBox>
                    </div>
                }
                {
                    user ?
                        <Notification
                            user={this.props.user.data}
                        /> : null
                }
            </>
        );

        let cpnScheme = company && company.cpnScheme ? (company.cpnScheme.activeScheme ? company.cpnScheme.activeScheme.toLowerCase() : "") : ""
        const app =
            <div className={(contentClass + (loading ? " hidden" : " app " + cpnScheme))}>
                {header}
                <div className="two-col">
                    {user && sidebar}
                    {body()}
                </div>
            </div>

        return (
            <LaunchDarklyProvider>
                {app}
                {loading ? "" : null}
                <Loading />
                <Alert />
            </LaunchDarklyProvider>
        );
    }
}

const mapDispatchToProps = dispatch => ({
  syncAccountSetupProgress : () => dispatch(buildAction(ON_BOARDING.SYNC_ACCOUNT_SETUP_PROGRESS, {closeProgressSynchingModal: true})),
  switchENV                : (payload) => dispatch(buildAction(USER.SWITCH_ENV, payload))
});

const mapStateToProps = state => ({
    ui: state.ui,
    user: state.user,
    signin: state.signin,
    signup: state.signup,
    postOAuth2: state.postOAuth2,
    company: state.company,
    product: state.product,
    components: state.components,
    changeorders: state.changeorders,
    authscreen: state.authscreen,
    user_profile: state.user_profile,
    userForm: state.userForm,
    users: state.users,
    search: state.search,
    help: state.help,
    invite: state.invite,
    on_boarding: state.on_boarding,
})

const _Routes = withRouter(Routes);
export default connect(mapStateToProps, mapDispatchToProps)(_Routes)

const PageWrapper = styled(Box)({
    display: "flex",
    flex: "1",
    overflow: "hidden",
});

const OldPageScrollArea = styled(Box)({
    overflowY: "auto",
});
