import React, { Component } from "react";
import Utils from "../../../../modules/utils";
import { connect } from "react-redux";

class Actions extends Component {
    constructor(props) {
        super(props)
        this.update = this.update.bind(this);
        this.getButtonsState = this.getButtonsState.bind(this);
        this.getActionButtons = this.getActionButtons.bind(this);
        const { proceed } = this.getButtonsState(this.props);

        this.state = {
            proceed:  proceed,
        };
    }

    getButtonsState(props)
    {
        let { inputs, validated, childValidated }   = props;
        return { proceed: validated && childValidated };
    }

    componentWillReceiveProps(nextProps)
    {
        this.update(nextProps);
    }

    update(props)
    {
        const { proceed } = this.getButtonsState(props);
        this.state.proceed = proceed;
        this.setState(this.state);
    }

    getActionButtons()
    {
        let { proceed } = this.state;
        let { isDataChanged } = this.props;
        let markup =
                <div>
                    <input className={(proceed && isDataChanged) ? "" : "disabled"} type="submit" value="SAVE" />
                </div>
        return markup;
    }
    render() {
        return this.getActionButtons();
    }
}

export default Actions
