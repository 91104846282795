import                                      "./index.css"
import                                      "./../index.css"
import React, { Component }                 from "react"
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import config from "v1/modules/config";
import DropZone                             from "../../../ui/drop-zone"
import {connect}                            from "react-redux"
import USER_PROFILE                         from "../../../../action-types/user_profile"
import buildAction                          from "../../../../helpers/buildAction"
import traverse                             from "traverse"
import Schemas,{permissions as Permissions} from "../../../../modules/schemas"
import PhoneInput                           from 'react-phone-number-input/input'
import {Helmet}                             from "react-helmet"
import Utils                                from "../../../../modules/utils"
import GoogleIcon                           from "../../../../assets/icons/google"
import InlineIcon from "../../../ui/icon/inline-icon.js";

export class UserProfile extends Component
{
    constructor(props, context)
    {
        super(props, context);

        this.componentWillMount     = this.update
        this.onRemoveClick          = this.onRemoveClick.bind(this);
        this.uploadImage            = this.uploadImage.bind(this);
        this.onSubmit               = this.onSubmit.bind(this);
        this.onCancel               = this.onCancel.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.openPluginPassword = this.openPluginPassword.bind(this);
        this.closePluginPassword = this.closePluginPassword.bind(this);
        this.onPluginPasswordChange = this.onPluginPasswordChange.bind(this);
        this.onPluginPasswordSubmit = this.onPluginPasswordSubmit.bind(this);
        this.componentWillMount = this.componentWillMount.bind(this);
        this.onPhoneInputChange     = this.onPhoneInputChange.bind(this);
        this.connectedAccountsView  = this.connectedAccountsView.bind(this);
        this.onEmailChange          = this.onEmailChange.bind(this);
        this.getEmailTooltip        = this.getEmailTooltip.bind(this);
        this.getEmailClass = this.getEmailClass.bind(this);
        this.state = {
            newPassword: {
                name: "",
                value: "",
            },
            passwordError: false,
            passwordMsg: "Password must be at least 8 characters, contain a number and special character",
            showEmailWarning: false,
            showPluginDialog: false,
        }
    }

    update(props)
    {
      const {dispatch} = this.props;
      dispatch(buildAction(USER_PROFILE.SET_USER_PROFILE_FORM));
    }

    navigateAccount() {
        window.location = `${config.REACT_APP_AUTH_URL}/account`;
    }

    openPluginPassword() {
        this.setState({ showPluginDialog: true });
    }

    closePluginPassword() {
        this.setState({ showPluginDialog: false });
    }

    onPluginPasswordChange(event) {
        this.setState({
            newPassword: {
                name: event.target.name,
                value: event.target.value,
            },
            passwordError: !this.validatePassword(event.target.value),
        });
    }

    onPluginPasswordSubmit() {
        const currentlyLoggedInUserEmail = this.props.user.data.email;
        const { dispatch } = this.props;
        dispatch(buildAction(USER_PROFILE.ON_INPUT_CHANGE,
            {
                currentlyLoggedInUserEmail: currentlyLoggedInUserEmail,
                ...this.state.newPassword,
            }
        ));
        dispatch(buildAction(USER_PROFILE.ON_SUBMIT));
        this.closePluginPassword();
    }

    validatePassword(password = "") {
        if (password.length < 8 ||
            password.match(/^[^a-zA-Z]+$/) || // has only letters
            password.match(/^[^0-9]+$/) || // has only numbers
            !password.match(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/)) // not special
        {
            return false;
        }
        return true;
    }

    onRemoveClick()
    {
      let event =
      {
          target :
          {
              name  : "removeBtn",
              value :
              {
                  key : ""
              }
          }
      }
      this.onInputChange(event)
    }

    uploadImage(file, errors, isUploaded)
    {
      const {dispatch} = this.props;
      dispatch(buildAction(USER_PROFILE.UPLOAD_IMAGE,
        {
          avatar: file,
          isUploadedImage: isUploaded,
          imageuploadErrors: errors
        }
      ));

    }

    onInputChange(event, callSubmit)
    {
        let currentlyLoggedInUserEmail = this.props.user.data.email
        const {dispatch} = this.props;
        dispatch(buildAction(USER_PROFILE.ON_INPUT_CHANGE,
            {
                name: event.target.name,
                value: event.target.value,
                currentlyLoggedInUserEmail: currentlyLoggedInUserEmail
            }
        ));
        callSubmit && this.onSubmit();
    }

    onSubmit(event)
    {
        event && event.preventDefault()
        const {dispatch} = this.props;
        let validated = true

        let inputs = this.props.user_profile.inputs
        traverse(inputs).forEach(function (value) {
            if (this.key === "valid" && !value && inputs?.isUploadedImage) validated = false
        })
        if (validated){
            dispatch(buildAction(USER_PROFILE.ON_SUBMIT));
        }
    }

    onCancel(event)
    {
      event.preventDefault()
      const {dispatch} = this.props;
      dispatch(buildAction(USER_PROFILE.SET_USER_PROFILE_FORM));
    }

    onPhoneInputChange(value){
        let e = {target: {name: "phoneNumber", value: value}}
        this.onInputChange(e)
    }

    connectedAccountsView(inputs)
    {
        let markup =
            <div className="oauth2-block">
                <h3 className="connected-account-heading">Connected Accounts</h3>
                <div className="oauth2-row">
                    <div className="oauth2-label">
                        <InlineIcon>
                            <GoogleIcon/>
                        </InlineIcon>
                        <span>Google</span>
                    </div>
                </div>
                <div className="oauth2-error">
                    <span className="oauth2-error-msg">{inputs?.oauth2Google.message}</span>
                </div>
            </div>

        return markup;

    }

    onEmailChange(event)
    {
        this.onInputChange(event);

        let state         = this.state;
        let inputs        = this.props.user_profile.inputs;
        let currentEmail  = this.props.user.data.email;

        if (!!inputs?.email.message || currentEmail.toLowerCase() === inputs?.email.value.toLowerCase())
        {
            state.showEmailWarning  = false;
        }
        else
        {
            if (inputs?.hasDuroAccount && !inputs?.oauth2Google.id)
            {
                return; // User has only duro account
            }
            else if (inputs?.hasDuroAccount && !!inputs?.oauth2Google.id)
            {
                state.showEmailWarning = true; // User has both Duro and Google oauth2 account
            }
        }
        this.setState(state)
    }

    getEmailTooltip(message)
    {
        if (!!message)
        {
            return message
        }
        else if (this.state.showEmailWarning)
        {
            return "Changing your email will disconnect your account from google.";
        }

    }

    getEmailClass()
    {
        let emailClassName = this.props.user_profile?.inputs?.email?.class;

        if (this.state.showEmailWarning)
        {
            emailClassName = "invalid"
        }

        return emailClassName;
    }

    render()
    {
        let inputs = this.props?.user_profile?.inputs
        let avatar = this.props?.user_profile?.user.avatar
        const companyData = this.props?.company?.data;
        const userData = this.props?.user?.data;
        if (companyData && Object.keys(companyData).length !== 0)
        if (!inputs){
            return null
        }
        let allowedUserRoles = companyData?.settings?.allowedUserRoles;

        let canEditRoleValue = Permissions.can("edit_role", "user_profile", userData, { company_creator_Id: companyData?.creator, current_user_Id: userData?._id })

        let markup = (

            <div className="user-settings-route app-row">
                <Helmet>
                    <title>{Utils.makeTitle("Settings - User")}</title>
                </Helmet>
                <div className="main-block">
                    <div className="content-block">
                        <h1>Your profile</h1>
                        <h3>Update your profile</h3>
                        <form className="form-block" onSubmit={this.onSubmit}>

                            <div className="info-block">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="text"
                                    name="email"
                                    value={inputs?.email?.value}
                                    placeholder="Enter email address"
                                    className={this.getEmailClass()}
                                    onChange={this.onEmailChange}
                                    data-tip={this.getEmailTooltip(inputs?.email.message)}
                                    data-place="right"
                                    data-type="error"
                                />

                                <div className="ui-message"></div>

                                {!!inputs?.oauth2Google && !!inputs?.oauth2Google.id && this.connectedAccountsView(inputs)}

                                <div className="names-block">
                                    <div>
                                        <label htmlFor="firstName">First name</label>
                                        <input
                                            type="text"
                                            name="firstName"
                                            placeholder="First name"
                                            className={inputs?.firstName.class}
                                            value={inputs?.firstName.value}
                                            onChange={this.onInputChange}
                                            data-tip={inputs?.firstName.message}
                                            data-place="top"
                                            data-type="error"
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="lastName">Last name</label>
                                        <input
                                            type="text"
                                            name="lastName"
                                            placeholder="Last name"
                                            className={inputs?.lastName.class}
                                            value={inputs?.lastName.value}
                                            onChange={this.onInputChange}
                                            data-tip={inputs?.lastName.message}
                                            data-place="top"
                                            data-type="error"
                                        />
                                        <div className="ui-message"></div>
                                    </div>
                                </div>

                                <div className="names-block">
                                    <div>
                                        <label htmlFor="title">Job title</label>
                                        <input
                                            type="text"
                                            name="title"
                                            placeholder="Job title"
                                            className={inputs?.title.class}
                                            value={inputs?.title.value}
                                            onChange={this.onInputChange}
                                            data-tip={inputs?.title.message}
                                            data-place="top"
                                            data-type="error"
                                        />
                                        <div className="ui-message"></div>
                                    </div>
                                    <div>
                                        <label htmlFor="phoneNumber">Phone number</label>

                                        <PhoneInput
                                            country="US"
                                            name="phoneNumber"
                                            placeholder="Phone Number"
                                            value={inputs?.phoneNumber.value}
                                            onChange={ value => this.onPhoneInputChange(value) }
                                            className={inputs?.phoneNumber.class}
                                            data-tip={inputs?.phoneNumber.message}
                                            data-place="top"
                                            data-type="error"
                                        />

                                        <div className="ui-message"></div>
                                    </div>
                                </div>

                                <Box sx={{ marginBottom: "2rem" }}>
                                    <label htmlFor="title">Role</label>
                                    <select
                                        name="role"
                                        className={inputs?.role.class + (!canEditRoleValue ? " disabled" : "")}
                                        value={inputs?.role.value}
                                        onChange={canEditRoleValue ? this.onInputChange : null}
                                        data-tip={inputs?.role.message}
                                        data-place="right"
                                        data-type="error"
                                        disabled={!canEditRoleValue}
                                    >
                                        {Utils.displayUserRoleOptions(allowedUserRoles)}
                                    </select>
                                </Box>

                                <button className={"cancel-btn " + inputs?.submit.class} onClick={this.onCancel}>
                                    cancel
                                </button>

                                <input
                                    type="submit"
                                    value="save"
                                    className={"submit-btn " + inputs?.submit.class}
                                />
                            </div>

                            <div className="right-block">
                                <Box>
                                    <Button
                                        data-testid="password"
                                        onClick={this.navigateAccount}
                                        sx={{ marginBottom: "2rem", width: "80%" }}
                                        variant="outlined"
                                    >
                                        Password
                                    </Button>

                                    <Button
                                        data-testid="password-plugin"
                                        onClick={this.openPluginPassword}
                                        sx={{ marginBottom: "2rem", width: "80%" }}
                                        variant="outlined"
                                    >
                                        Plugin Password
                                    </Button>
                                    <Dialog open={this.state.showPluginDialog} onClose={this.handleClose}>
                                        <DialogTitle>Change Plugin Password</DialogTitle>
                                        <DialogContent>
                                            <TextField
                                                autoFocus
                                                error={this.state.passwordError}
                                                fullWidth
                                                helperText={this.state.passwordMsg}
                                                id="newPassword"
                                                label="New password"
                                                margin="dense"
                                                name="password.new"
                                                onChange={this.onPluginPasswordChange}
                                                type="password"
                                                value={this.state.newPassword.value}
                                                variant="standard"
                                            />
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={this.closePluginPassword}>Cancel</Button>
                                            <Button
                                                onClick={this.onPluginPasswordSubmit}
                                                disabled={this.state.passwordError}
                                            >
                                                Save
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </Box>

                                <DropZone
                                    onChange={this.uploadImage}
                                    imageId={avatar}
                                    label="Profile picture"
                                    placeholder="Add a profile pic"
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        );
        return markup
    }
}

export default connect((store) => store)(UserProfile)
