import Utils              from "../../../../../../modules/utils"
import ExtendedTable      from "../../../../common/extended-table"
import React, {Component} from "react"
import Link               from "../../../../../ui/link"

class NotificationList extends Component
{
    constructor(props)
    {
        super(props)
        let viewStyles;

        try
        {
            viewStyles = window.__userStyles.styles.releaseNoficationTable || {}
        }
        catch(error) {
            viewStyles = {}
        }
        this.state =
        {
            headings :
            [
                {
                    key         : "users",
                    displayName : "USERS",
                    tooltip     : "USERS",
                    sortable    : true,
                    minWidth    : 215,
                    width       : Utils.getStyleValue(viewStyles, "USERS", "width", 690),
                    position    : Utils.getStyleValue(viewStyles, "USERS", "position", 0),
                    visibility  : Utils.getStyleValue(viewStyles, "USERS", "visibility", true),
                    disabled    : true
                }
            ]
        }
        this.getExternalUserTableRows = this.getExternalUserTableRows.bind(this);
    }

    getExternalUserTableRows()
    {
        let emailList = this.props.emailList;
        if(!emailList) return [];
        let _this = this
        let rows = emailList.map((email, i) =>
            {
                let cells =
                {
                    "users":
                    {
                        value        : email,
                        displayValue : email,
                        tooltip      : email
                    }
                }
                return cells
            })
        return rows
    }

    render()
    {
        let headings = this.state.headings;
        let rows = this.getExternalUserTableRows();

        let markup  =
            <div>
                <ExtendedTable
                    wrapperClassName="release-notification-table"
                    wrapperSelectorClass="release-notification-table"
                    stylesName="releaseNoficationTable"
                    headings={headings}
                    rows={rows || []}
                    endStaticColumns={1}
                    currentSortItem="users"
                    currentSortItemAscending={true}
                    includeToolBar={true}
                    afterSyncWithParentState={()=> {}}
                    resultText={`${rows.length} Users`}
                    footerRow={ rows.length === 0 && {dataCellEl: <p>No Users added</p> }}
                />

            </div>
        return markup
    }
}

export default NotificationList
