import                    "./index.css";
import React, {Component} from "react";
import Tooltip            from 'rc-tooltip';
import ModalBox           from "../../../ui/modal-box";
import API                from "../../../../modules/api";
import LazyInput          from "../../../ui/lazy-input/input.js";
import validations, { validateField, schemas }  from '../../../../modules/validations';
import CustomReactSelect  from "../../../ui/custom-react-select";
import SpinnerButton      from "../../../ui/spinner-button";
import InlineIcon         from "../../../ui/icon/inline-icon.js";
import InfoIcon          from "../../../../assets/icons/info.js";

class SaveTemplateModal extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            selectedTemplate: "",
            isPublic: false,
            isValid: true,
            errorMessage: "",
            templateName: "",
            loading: false,
            validateExisting: false
        }
        this.onClose              = this.onClose.bind(this);
        this.saveTemplate         = this.saveTemplate.bind(this);
        this.onInputChange        = this.onInputChange.bind(this);
        this.onChange             = this.onChange.bind(this);
        this.getTemplatesOptions  = this.getTemplatesOptions.bind(this);
        this.handleOnPublicChange = this.handleOnPublicChange.bind(this);
        this.handleOnInputChange  = this.handleOnInputChange.bind(this);
        this.checkForDupName      = this.checkForDupName.bind(this);
        this.handleOutsideClick   = this.handleOutsideClick.bind(this);

    }

    componentDidMount()
    {
        document.addEventListener("click", this.handleOutsideClick, false);
    }

    componentWillUnmount()
    {
        document.removeEventListener("click", this.handleOutsideClick, false);
    }

    onClose()
    {
        document.removeEventListener("click", this.handleOutsideClick, false);
        this.props.setCompanyTemplateFlag(this.state.isPublic);
        this.props.onClose("displaySaveTemplateModal", false);
    }

    checkForDupName(name, isPublic, cb){
        let _this = this;
        let data = {name, isPublic};
        const templateNameExists = this.props.isCoTemplate ? API.services.isCoTemplateNameExist : API.services.exportTemplateNameExists
        templateNameExists(data, (err, response) =>
        {
            if(err)
            {
                return _this.props.showAlert("errors", err.errors);
            }
            let isValid = !response.exist;
            cb(isValid, "Name already exists");
        });
    }

    handleOnPublicChange(event, options)
    {
        let _this = this;
        let {value, input, isValid, errorMessage} = options;

        let onSuccess = (isValid, err) => {
            _this.props.setCompanyTemplateFlag(value, isValid, _this.state.templateName);
            _this.setState({isValid: isValid, errorMessage: err});
        }
        !_this.state.validateExisting && isValid ? (this.checkForDupName(_this.state.templateName, value, (isUnique, err) =>{onSuccess(isUnique, err)})) : onSuccess(isValid, errorMessage);
        _this.setState({isPublic: value});
    }

    handleOnInputChange(event, options)
    {
        let _this = this;
        let {value, input, isValid, errorMessage} = options;

        let onSuccess = (isValid, err) => {
            _this.setState({templateName: value, isValid: isValid, errorMessage: err});
            _this.props.onInputChange(event, isValid);
        }
        isValid ? this.checkForDupName(value, _this.state.isPublic, (isUnique, err) =>{onSuccess(isUnique, err)}) : onSuccess(isValid, errorMessage);
        _this.setState({selectedTemplate: "", validateExisting: false, templateName: value});
    }

    onInputChange(event)
    {
        let _this   = this;
        let value   = event.target.value;
        let input   = {value: value, message: "", class: "", valid: true};
        let isValid = false;
        let errorMessage = "";
        let isCheckbox = event.target.name === "check-row";
        let name = value;

        if(isCheckbox){
            value = event.target.checked;
            name = input.value = _this.state.templateName;
        }

        if(!_this.state.validateExisting) validateField(input, validations.exportTemplate.templateName, name);
        isValid      = input.valid;
        errorMessage = input.message;

        let options = {value, input, isValid, errorMessage};

        event.target.name === "check-row" ? this.handleOnPublicChange(event, options) : this.handleOnInputChange(event, options);

    }

    saveTemplate()
    {
        this.setState({loading: true});
        this.props.saveTemplateData();
        this.onClose();
    }

    onChange(event)
    {
        let state = this.state;
        state.selectedTemplate = {label: event.label, value: event.value};

        this.props.templates.forEach((template) => {
            if(template.templateName === state.selectedTemplate.label)
            {
                state.isPublic = template.isPublic;
            }
        });
        state.validateExisting = true;
        state.isValid = true;
        state.errorMessage = "";
        state.templateName = event.label;
        this.props.onInputChange({target: {name: "update-template", value: event.label}});
        this.props.setCompanyTemplateFlag(state.isPublic, true, event.label);
        this.setState(state);
    }

    getTemplatesOptions()
    {
        let _this     = this;
        let templates = this.props.templates;
        let options   = [];
        let companyTemplates = [];
        let privateTemplates = [];
        let recentTemplates  = [];

        this.props.recentTemplates.forEach((template) => {
            if(this.props.user.data.role !== "ADMINISTRATOR" && template.isPublic) return; 
            recentTemplates.push({value: template._id, label: template.templateName});
        });

        templates.forEach((item, i) => {
            let value = {value: item._id, label:item.templateName};
            if(!item.isPublic)
            {
                privateTemplates.push(value);
            }
            else
            {
                companyTemplates.push(value);
            }
        });

        options.push({label: "RECENT", options: recentTemplates});
        options.push({label: "PRIVATE", options: privateTemplates});
        if(this.props.user.data.role === "ADMINISTRATOR")
        {
            options.push({label: "COMPANY", options: companyTemplates});
        }
        return options;
    }

    handleOutsideClick(e)
    {
        if((!this.node.contains(e.target) && !e.target.classList.contains('duro__option')) || e.target.classList.contains("background")) this.onClose();
    }

    render()
    {
        let className = this.props.disableTemplateModalSaveButton || this.state.loading ? "disabled" : "";
        let inputClassName = this.state.isValid ? "" : "error";
        let name = this.state.validateExisting ? "" : this.state.templateName;
        let markup = <div ref={node => { this.node = node;}}>
                        <ModalBox onClose={this.onClose} hideCrossIcon={true}>
                            <div className="modal-heading">
                                <h2> Save or Update Template</h2>
                            </div>
                            <div className="modal-body">
                                <div className="existing-templates">
                                    <h3> Save to an Existing Template </h3>
                                    <div className="select-template">
                                        <CustomReactSelect
                                            name="update-template"
                                            className={!this.state.selectedTemplate ? "placeholder" : ""}
                                            value={this.state.selectedTemplate}
                                            onChange={(e) => this.onChange(e)}
                                            isSearchable={false}
                                            options={this.getTemplatesOptions()}
                                            placeholder={"Select Template"}
                                        />
                                    </div>
                                </div>
                                <span className="template-divider">
                                    OR
                                </span>
                                <div className="new-templates">
                                    <h3> Save As New Template </h3>
                                    <Tooltip
                                      overlayClassName={`simple-rc-tip error custom-rev-tip ${!this.state.isValid ? "" : "hidden"}`}
                                      overlay={
                                          <div className={`description-tip`}>
                                              <span>{this.state.errorMessage}</span>
                                          </div>
                                      }
                                    >
                                    <LazyInput
                                    className={inputClassName}
                                    type="text"
                                    name="save-template"
                                    value={name}
                                    placeholder="Enter Name"
                                    onChange={(e) => this.onInputChange(e)}
                                    />
                                    </Tooltip>
                                </div>
                                <div className="checkbox-container">
                                    <div className="checkbox-holder">
                                        <input
                                        type="checkbox"
                                        name="check-row"
                                        className="large"
                                        checked={this.state.isPublic}
                                        onChange={(e) => this.onInputChange(e)}
                                        />
                                        <label htmlFor="check-row"
                                        />
                                    </div>
                                    <span className="checkbox-text">
                                        Accessible to all company account users
                                    </span>
                                    <InlineIcon
                                        className="info"
                                        tooltip = "New templates are private by default"
                                        tooltipPlace = "right"
                                    >
                                        <InfoIcon/>
                                    </InlineIcon>
                                </div>
                                <div className="btn-holder">
                                    <div className="btn-container">
                                        <button
                                            className="dark"
                                            onClick={this.onClose}>
                                            CANCEL
                                        </button>
                                        <SpinnerButton btnText='SAVE' onClick={!this.props.disableTemplateModalSaveButton ? this.saveTemplate: function(){}} className={`${className} save-template-modal-spinner`}/>
                                    </div>
                                </div>
                            </div>
                        </ModalBox>
                     </div>
        return markup;
    }
}

export default SaveTemplateModal;
