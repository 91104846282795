export function getInitialState()
{
    return {
        item: null,
        sourceRev: null,
        targetRev: null,
    }
}

let initialState = getInitialState()
export default initialState
