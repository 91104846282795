import { PageItemType, PageMode } from "design/constants";
import { usePrimaryCompany } from "graphql/query/companyQueries";
import { useEffect, useMemo, useState } from "react";
import API from "v1/modules/api";

const RESTRICTED_CATEGORIES = [
  "Cable Assembly", "EBOM", "Electrical Assembly", "Orbit Fab Assembly", "Orbit Fab Printed Wiring Assembly",
  "PCBA", "Printed Circuit Board Assembly", "Product", "Sub Assembly", "Sub-Assembly", "Top Level Assembly",
];

/**
 * Checks to see if the current item can use refDes and the company allows it.
 *
 * @param pageItemType What type of item is being displayed on this page.
 * @param category What is the current components/products category.
 * @returns True if the item has refDes available to it.
 */
export function useHasRefDes(pageItemType: PageItemType, category?: string) {
  const { displayRefDesAndItemNumber } = usePrimaryCompany()?.data?.settings ?? {};

  return useMemo(() => {
    // Only components that are loaded have a Ref Des field.
    if (!category || pageItemType !== PageItemType.COMPONENT) return false;

    // RefDes columns are only shown for specific assemblies, unless the company setting overrides
    // that and shows it for all assemblies.
    return displayRefDesAndItemNumber || RESTRICTED_CATEGORIES.includes(category);
  }, [category, displayRefDesAndItemNumber, pageItemType]);
}

interface UseIsAnyChildModifiedArgs {
  id: string;
  mode: PageMode;
  pageItemType: PageItemType;
}

/**
 * Checks to see if any children of a component is modified.
 *
 * @param args Information needed to load the correct information about the children.
 * @returns True if any of the children are modified.
 */
export function useIsAnyChildModified(args: UseIsAnyChildModifiedArgs) {
  const { id, mode, pageItemType } = args;
  const [isAnyChildModified, setIsAnyChildModified] = useState(false);

  // TODO: Figure out how we can get this information from the GraphQL API.
  useEffect(() => {
    const model = pageItemType === PageItemType.PRODUCT ? "products" : "components";
    API[model].getChildrenInfo(id, { mode }, (_err: any, res: any) => {
      if (res?.success && res.data) {
        setIsAnyChildModified(res.data.isAnyChildModified);
      }
    });
  }, [id, mode, pageItemType]);

  return isAnyChildModified;
}
