import React from "react";

function BrokenImageIcon(props) {
  return (
    <svg width="41px" height="41px" viewBox="0 0 41 41" {...props}>
      <path
        d="M36.444 4.556v31.888H4.556V4.556h31.888zm0-4.556H4.556A4.569 4.569 0 000 4.556v31.888A4.569 4.569 0 004.556 41h31.888A4.569 4.569 0 0041 36.444V4.556A4.569 4.569 0 0036.444 0zm-11.07 20.181l-6.833 8.815-4.874-5.9-6.834 8.793h27.334L25.374 20.18z"
        transform="translate(-107 -60) translate(107 60)"
        fill="#807F7F"
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1}
      />
    </svg>
  );
}

export default BrokenImageIcon;
