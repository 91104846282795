import userSchema           from "./user.js"
import companySchema        from "./company.js"
import productSchema        from "./product.js"
import companyUserSchema    from "./companyUser.js"
import componentSchema      from "./component.js"
import selectData           from "./selectData.js"
import categories           from "./categories.js"
import permissions          from "./permissions.js"
import helpers              from "./helpers.js"
import productionInstanceSchema from "./production-instance.js";

const Schemas =
{
    user    : userSchema,
    company : companySchema,
    product : productSchema,
    companyUser: companyUserSchema,
    component : componentSchema,
    selectData : selectData,
    categories : categories,
    permissions: permissions,
    helpers: helpers,
    productionInstance: productionInstanceSchema,
}

export default Schemas

export {permissions}
