import mapActions from '../helpers/mapActions';

export default mapActions(
  [
    'SET_USERS_LIST',
    'SET_USERS_LOADING',
    'GET_USERS_LIST',
    'RE_SEND_INVITE',
    'MARK_DISABLE_OR_DELETE',
    'REACTIVATE',
    'ADD_TO_GROUP'
  ],
  'USERS'
  );
