import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="15"
      version="1.1"
      viewBox="0 0 13 15"
      id="delete-svg"
    >
      <g fill="none" fillRule="evenodd" stroke="#FFF" strokeWidth="0.3">
        <g fill="#FFF" transform="translate(-1227 -92)">
          <path d="M1233.519 92c-.644 0-1.055.223-1.392.48-.337.257-.608.533-1.064.714a.203.203 0 00-.13.185H1227v.394h13v-.394h-3.933a.204.204 0 00-.13-.185c-.458-.182-.741-.458-1.077-.714-.335-.256-.733-.48-1.342-.48h0zm0 .394c.516 0 .797.164 1.107.4.23.175.47.395.794.585h-3.84c.323-.19.559-.41.787-.585.309-.235.594-.4 1.152-.4zm-5.534 1.773V105c0 .622.161 1.123.505 1.465.343.342.843.505 1.47.505h7.092c.62 0 1.124-.163 1.465-.505.34-.342.498-.843.498-1.465V94.167h-11.03zm.394.394h10.242V105c0 .556-.133.94-.381 1.188-.249.248-.634.388-1.188.388h-7.091c-.564 0-.944-.14-1.194-.388-.25-.249-.388-.632-.388-1.188V94.56zm2.166 2.56v7.091h.394v-7.09h-.394zm2.758 0v7.091h.394v-7.09h-.394zm2.758 0v7.091h.394v-7.09h-.394z"></path>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
