import mapActions from '../helpers/mapActions';

export default mapActions(
  [
    'GET_ITEM_ASSEMBLY_LIST',
    'SET_ITEM_ASSEMBLY_LIST',
    'GET_AND_SET_CHILD_ASSEMBLY',
    'SET_ITEM_ASSEMBLY_STATE',
    'RESET_STATE',
    'SET_SELECTED_TEXT_FOR_PRODUCT_LIST',
    'MARK_ASSEMBLY_UN_SELECTED',
    'HIGHLIGHT_SELECTED_ASSEMBLY_IN_NAV',
    'SET_PRODUCT_LIST',
    'GET_LATEST_CHILDREN',
    'REMOVE_COMPONENT_FROM_LEFT_NAV_TREE',
    'SET_OPEN_ITEM_LIST',
    'RESET_ITEM_LIST',
    'UPDATE_DISPLAY_ASSEBLY_SEARCH',
    'SEARCH_ASSEMBLIES_ON_NEW_PAGE',
    'CLEAR_ASSEMBLY_SEARCH_STATE',
    'CREATE_SEARCH_INPUTS',
    'SET_ASSEMBLY_STATE',
    'ADD_SEARCH_RESULTS',
    'UPDATE_SEARCH_INPUTS',
    'SEARCH_IN_PROGRESS'
  ],
  'ITEM_ASSEMBLY'
  );
