import UI                           from '../../action-types/ui';
import INVITE                       from '../../action-types/invite';
import USER                         from '../../action-types/user';
import SIGNIN                       from '../../action-types/signin';
import buildAction                  from '../../helpers/buildAction';
import API                          from "../../modules/api";
import Schemas                      from '../../modules/schemas';
import traverse                     from "traverse"
import { getEmail,
    getInputs,
    getPassword,
    getNewPassword,
    getToken
}                                   from "./selector"
import { takeEvery,
    all,
    cps,
    put,
    select,
    call
}                                   from 'redux-saga/effects';
import validations, {
        validateField,
        validateFieldWithAllErrorMessages
    }                               from '../../modules/validations';

export function* submitInviteForm(action) {
    yield put(buildAction(UI.LOAD_START))
    let token = yield select(getToken)
    let newPassword = yield select(getNewPassword)

    yield put(buildAction(UI.LOAD_START))
    try
    {
        let data = { newPassword, token }
        const response = yield cps(API.auth.accept, data)
        action.payload.history.push({pathname: "/signin", headingText: "Your email has been verified", helpText: "Please sign in.", showSignup: false})
    }
    catch(err)
    {
        yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}))
    }
    yield put(buildAction(UI.LOAD_END))
}

export function* getInvite(action) {
    yield put(buildAction(UI.LOAD_START))
    try{
        const inputs = yield select(getInputs)
        let token = yield select(getToken)
        const response = yield cps(API.users.verifyToken, {token: token})
        inputs.email.value = response.email
        if (response.invite.accepted){
            action.payload.history.push({pathname: "/signin", headingText: "Your email has already been verified", helpText: "Please sign in.", showSignup: false})
        }
        else{
            const payload = { loaded: true}
            yield put(buildAction(INVITE.UPDATE_LOADED_STATE, payload))
        }
    }
    catch(err)
    {
        let errMessage = err.errors[0].message
        if (errMessage.split('-')[0] === "Token is expired.")
            action.payload.history.push({pathname: "/success", success: true, inviteExpire: true, email: errMessage.split('-')[1]})
        else
            action.payload.history.push({pathname: "/success", success: true, linkVerificationError: true, errors: err.errors})
    }
    yield put(buildAction(UI.LOAD_END))
}

export function validated(inputs)
{
    let validated = true
    traverse(inputs).forEach(function(value)
    {
        if(this.key === "valid" && !value) validated = false
    })
    return validated
}

export function* onInputChange(action) {
    let {value, name, checked}  = action.payload
    const inputs = yield select(getInputs)
    let input  = inputs[name]

    switch(name)
    {
        case "newPassword":
            validateFieldWithAllErrorMessages(input, validations.user.password, value)
            break
        case "confirmPassword":
            input.value   = value
            if (input.value !== inputs.newPassword.value)
            {
                input.valid   = false
                input.message = "Passwords don’t match"
                input.class   = "invalid"
            }
            else
            {
                input.valid   = true
                input.message = ""
                input.class   = ""
            }
            break
        default :
        {
            // do nothing
        }
    }

    inputs.submit.class = validated(inputs) ? "" : "disabled"
    yield put(buildAction(INVITE.UPDATE_INPUTS_STATE, inputs))
}

export default function* (getState) {
    yield all([
        takeEvery(INVITE.SUBMIT_INVITE_FORM, submitInviteForm),
        takeEvery(INVITE.GET_INVITE, getInvite),
        takeEvery(INVITE.ON_INPUT_CHANGE, onInputChange),
    ]);
}
