export default {
    users : [],

    loading: false,

    column :
    {
        name      : "name",
        ascending : true
    },

    columns :
    [
        {
            name     : "name",
            class    : "sortable",
            value    : "Full name",
            sortable : true
        },
        {
            name     : "email",
            class    : "sortable",
            value    : "Email",
            sortable : true
        },
        {
            name     : "title",
            class    : "sortable",
            value    : "Job title",
            sortable : true
        },
        {
            name     : "role",
            class    : "sortable",
            value    : "Role",
            sortable : true
        },
        {
            name     : "groups",
            class    : "sortable",
            value    : "Groups",
            sortable : true
        },
        {
            name     : "session.created",
            class    : "sortable",
            value    : "Last session",
            sortable : true
        }
    ]
}
