import { SvgIcon } from "@mui/material";

export const ManualCreateIcon = () => (
  <SvgIcon height="24" width="24" className="toolbar-icon" viewBox="0 0 24 24">
    <path d="M16.737 20.126H4.105V7.263h7.369V5.158H4.105A2.111
      2.111 0 0 0 2 7.263v12.632C2 21.053 2.947 22 4.105 22h12.632a2.111
      2.111 0 0 0 2.105-2.105v-7.369h-2.105v7.6z
      M18.842 2h-2.105v3.158h-3.158c.01.01 0 2.105 0
      2.105h3.158v3.148c.01.01 2.105 0 2.105 0V7.263H22V5.158h-3.158V2zM6.211
      9.368h8.421v2.105H6.211zM6.21 12.526v2.106h8.422v-2.106h-3.158zM6.211
      15.684h8.421v2.105H6.211z"
    />
  </SvgIcon>
);
