import                                       "./index.css"
import React                                 from "react"
import ModalBox                              from "../../../../ui/modal-box"
import Utils                                 from "../../../../../modules/utils"
import {connect}                             from "react-redux";
import USERS                                 from "../../../../../action-types/users"
import USER                                  from "../../../../../action-types/user"
import USER_FORM                             from "../../../../../action-types/user_form"
import buildAction                           from "../../../../../helpers/buildAction"
import DeleteIcon                             from "../../../../../assets/icons/delete-cross"
import DisableIcon                            from "../../../../../assets/icons/disable"
import EditIcon                               from "../../../../../assets/icons/edit"
import { privatePaths }                      from "../../../../../app/routes"
import UserFormModalBox                      from "../user-modal-box"
import {permissions as Permissions}          from "../../../../../modules/schemas"
import ExtendedTable                         from "../../../common/extended-table"
import TableIcons from "../../../common/extended-table/table-icons";
import $                                     from "jquery";
import Loading                               from "../../../../ui/inner-loading"
import InlineIcon                            from "../../../../ui/icon/inline-icon.js"
import AvatarIcon                            from "../../../../../assets/icons/user-icon"

export class UsersView extends React.Component
{
    constructor(props, context)
    {
        super(props, context);
        let viewStyles;
        try
        {
            viewStyles = window.__userStyles.styles.settingsUsers || {}
        }
        catch(error) {
            viewStyles = {}
        }


        this.state =
        {
            selectedUserIndex : -1,
            displayUserForm   : false,
            disableFlag       : false,
            deleteFlag        : false,
            userId            : "",
            userEmail         : "",

            currentSortItemAscending : ("defaultSortAssending" in viewStyles ? viewStyles.defaultSortAssending : true),
            current : ("defaultSortColumnName" in viewStyles ? viewStyles.defaultSortColumnName : "name"),

            headings :
            [
                {
                    key         : "name",
                    displayName : "Full name",
                    tooltip     : "Full name",
                    sortable    : true,
                    minWidth    : 65,
                    width       : Utils.getStyleValue(viewStyles, "name", "width", 100),
                    position    : Utils.getStyleValue(viewStyles, "name", "position", 0),
                    visibility  : Utils.getStyleValue(viewStyles, "name", "visibility", true),
                    disabled    : true
                },
                {
                    key         : "email",
                    displayName : "Email",
                    tooltip     : "Email",
                    sortable    : true,
                    minWidth    : 60,
                    width       : Utils.getStyleValue(viewStyles, "email", "width", 100),
                    position    : Utils.getStyleValue(viewStyles, "email", "position", 1),
                    visibility  : Utils.getStyleValue(viewStyles, "email", "visibility", true),
                    disabled    : true
                },
                {
                    key         : "title",
                    displayName : "Job title",
                    tooltip     : "Job title",
                    sortable    : true,
                    minWidth    : 105,
                    width       : Utils.getStyleValue(viewStyles, "title", "width", 100),
                    position    : Utils.getStyleValue(viewStyles, "title", "position", 2),
                    visibility  : Utils.getStyleValue(viewStyles, "title", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "role",
                    displayName : "Role",
                    tooltip     : "Role",
                    sortable    : true,
                    minWidth    : 77,
                    width       : Utils.getStyleValue(viewStyles, "role", "width", 100),
                    position    : Utils.getStyleValue(viewStyles, "role", "position", 3),
                    visibility  : Utils.getStyleValue(viewStyles, "role", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "groups",
                    displayName : "Groups",
                    tooltip     : "Groups",
                    sortable    : true,
                    minWidth    : 124,
                    width       : Utils.getStyleValue(viewStyles, "groups", "width", 124),
                    position    : Utils.getStyleValue(viewStyles, "groups", "position", 4),
                    visibility  : Utils.getStyleValue(viewStyles, "groups", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "created",
                    displayName : "Last session",
                    tooltip     : "Last session",
                    sortable    : true,
                    minWidth    : 160,
                    width       : Utils.getStyleValue(viewStyles, "created", "width", 160),
                    position    : Utils.getStyleValue(viewStyles, "created", "position", 5),
                    visibility  : Utils.getStyleValue(viewStyles, "created", "visibility", true),
                    disabled    : false
                }
            ]
        }


        this.resendInvite                = this.resendInvite.bind(this);
        this.getAccountStatus            = this.getAccountStatus.bind(this);
        this.disableUser                 = this.disableUser.bind(this);
        this.deleteUser                  = this.deleteUser.bind(this);
        this.showDisableModal            = this.showDisableModal.bind(this);
        this.hideDisableModal            = this.hideDisableModal.bind(this);
        this.showDeleteModal             = this.showDeleteModal.bind(this);
        this.hideDeleteModal             = this.hideDeleteModal.bind(this);
        this.showUserFormModal           = this.showUserFormModal.bind(this)
        this.closeAddNewUser             = this.closeAddNewUser.bind(this)
        this.showUserFormModalFromTable  = this.showUserFormModalFromTable.bind(this)
        this.onUserActionsClick          = this.onUserActionsClick.bind(this)
        this.getUsersList                = this.getUsersList.bind(this)

    }

    getUsersList()
    {
        let {dispatch} = this.props
        dispatch(buildAction(USERS.GET_USERS_LIST, { handleLoading : false }));
    }

    onUserActionsClick(event, user)
    {
        event.stopPropagation()
        event.preventDefault();
        $("#action-item-" + user._id).removeClass('hidden');

        let element = event.target
        let parentTableEl = document.getElementsByClassName("extended-data-table-wrapper-scroll")[0]

        var elementPos = element.getBoundingClientRect();
        var parentPos = parentTableEl.getBoundingClientRect();

        let top  = elementPos.top - parentPos.top
        let left = elementPos.left - parentPos.left
        top = top + 100
        left = left - 80

        let $el =  $("#action-item-" + user._id)

        $el.css({
            top: top,
            left: left
        })
        $el.removeClass('hidden');

        document.body.addEventListener("click", this.outsideClickListener)

        $(".scrollbar-container").scroll(function() {
            $('.action-pop-item').addClass("hidden")
        })
    }

    outsideClickListener(event){
        let $target = $(event.target);

        if(!$target.closest('.action-pop-item').length)
        {
            $('.action-pop-item').addClass("hidden")
            document.body.removeEventListener("click", this.outsideClickListener)
        }
    }

    componentWillMount(){
        let {dispatch} = this.props
        dispatch(buildAction(USERS.GET_USERS_LIST, { handleLoading: false } ))
    }

    resendInvite(event, i)
    {
        if (Permissions.can("invite", "user", this.props.user.data))
        {
            const {dispatch} = this.props;
            const payload = {email: event.target.getAttribute("value")}
            dispatch(buildAction(USERS.RE_SEND_INVITE, payload))
        }
    }

    disableUser()
    {

        if (Permissions.can("disable", "user", this.props.user.data))
        {
            const {dispatch} = this.props;
            const payload = {_id: this.state.userId, type: "DISABLED"}
            dispatch(buildAction(USERS.MARK_DISABLE_OR_DELETE, payload))
            this.hideDisableModal()
        }
    }

    deleteUser()
    {
        if (Permissions.can("delete", "user", this.props.user.data))
        {
            const {dispatch} = this.props;
            const payload = {_id: this.state.userId, type: "DELETE"}
            dispatch(buildAction(USERS.MARK_DISABLE_OR_DELETE, payload))
            this.hideDeleteModal()
        }

    }

    reactivate(event, i)
    {
        event.stopPropagation();
        event.preventDefault();
        if (Permissions.can("invite", "user", this.props.user.data))
        {
            const {dispatch} = this.props;
            const payload = { id: event.target.getAttribute("value") }
            dispatch(buildAction(USERS.REACTIVATE, payload))
        }
    }

    showDisableModal(event, email, id)
    {
        let state = this.state
        state.disableFlag = true
        state.userId = id
        state.userEmail = email
        this.setState(state)
    }

    showDeleteModal(event, email, id)
    {
        let state = this.state
        state.deleteFlag = true
        state.userId = id
        state.userEmail = email
        this.setState(state)
    }

    hideDisableModal(event, i)
    {
        let state = this.state
        state.disableFlag = false
        this.setState(state)
    }

    hideDeleteModal(event, i)
    {
        let state = this.state
        state.deleteFlag = false
        this.setState(state)
    }

    getAccountStatus(user, getDisplayValue)
    {
        let markup = ""
        switch(user.status)
        {
            case "DISABLED":
                markup =
                Permissions.can("edit", "user", this.props.user.data) ?
                    <span className="link" onClick={(event) => this.reactivate(event)} value={user._id}>Reactivate</span>
                    :
                    <span className="disabled muted-text"> Reactivate</span>
                break
            default :
            {
                if(getDisplayValue || (user.session.value === 'Confirmed' || user.session.value === 'Invited'))
                {
                    markup = user.session.value;
                }
                else
                {
                    markup = user.lastLogin;
                }
            }
        }
        return markup
    }

    showUserFormModal(selectedUserIndex, userId)
    {
        if (Permissions.can("edit", "user", this.props.user.data, {userId, current_user_Id: this.props.user.data._id}))
        {
            let state = this.state
            state.displayUserForm = true
            state.selectedUserIndex = selectedUserIndex
            this.setState(state)

            let userData = Utils.clone(this.props.users.users[selectedUserIndex])
            let payload = { userData }
            const {dispatch} = this.props
            dispatch(buildAction(USER_FORM.SET_USER_FROM_INPUTS, payload))
        }
    }

    showUserFormModalFromTable(user)
    {
        if (Permissions.can("edit", "user", this.props.user.data, {userId: user._id, current_user_Id: this.props.user.data._id}))
        {
            let state = this.state
            state.displayUserForm = true
            this.setState(state)
            let userData = Utils.clone(user)
            let payload = { userData }
            const {dispatch} = this.props
            dispatch(buildAction(USER_FORM.SET_USER_FROM_INPUTS, payload))
        }
    }

    closeAddNewUser()
    {
        let state = this.state
        state.displayUserForm = false
        state.selectedUserIndex = -1
        this.setState(this.state)
        const {dispatch} = this.props
        dispatch(buildAction(USER_FORM.RESET_FORM))
    }

    render()
    {
        let canDelete = Permissions.can("delete", "user", this.props.user.data)

        let { users } = this.props.users

        let tableHeaders = this.state.headings
        let rows = users.map((user, i) =>
        {
            let role = user.superAdmin ? "SITE ADMIN" : user.role
            let groups = user.groups.join(', ')

            let options = []

            let disableClazz = (user.status === "DISABLED") ? "user-disable" : ""

            let actionsList = []
            let disableAction = {label: "Disable user", icon: <DisableIcon />,  to: "#", state: privatePaths.changeOrderNew.pathname.state, onClick: (event)=>this.showDisableModal(event, user.email, user._id) }
            let deleteAction = {label: "Delete user", icon: <DeleteIcon />,  to: "#", state: privatePaths.signOut.state, onClick: (event)=>this.showDeleteModal(event, user.email, user._id) }
            let accountStatus = this.getAccountStatus(user);
            if(typeof(accountStatus) === 'string' && (accountStatus.toLowerCase() === 'invited' || accountStatus.toLowerCase() === 'confirmed'))
            {
                accountStatus = 0;
            }
            if (!disableClazz)
                actionsList.push(disableAction)
            actionsList.push(deleteAction)

            actionsList.forEach((option, index) =>
            {
                let block =
                    <span className={option.disabled ? "disabled" : ""} key={index + 'u-' + i} onClick={(e) => option.onClick(e, option)}>
                        <div className="action-option">
                            <InlineIcon>{option.icon}</InlineIcon>
                            <div className="label">{
                                // option.label
                                user.name
                            }</div>
                        </div>
                    </span>

                options.push(block)
            })

            let cells =
            {
                "name":
                {
                    value        : user.name,
                    tooltip      : user.name,
                    cellClass    : "user-info-col",
                    displayValue : [
                        user.avatar
                        ?
                            <span className="user-profile-img" key={i + '-avatar'}>
                                <img src={user.avatar.src} alt={user.name}/>
                            </span>
                        :
                        <span className="face-avatar" key={i + '-avatar'}>
                            <InlineIcon>
                                <AvatarIcon/>
                            </InlineIcon>
                        </span>
                        ,

                        <span
                            className="user-name"
                            data-tip=""
                            key={i + '-name'}
                            onMouseOver={(e) => Utils.checkToolTipAppearance(e, user.name)}
                        >{user.name}</span>
                    ]

                },
                "email":
                {
                    value        : user.email,
                    tooltip      : user.email,
                    displayValue : user.email
                },
                "title":
                {
                    value        : user.title,
                    tooltip      : user.title,
                    displayValue : user.title
                },
                "role":
                {
                    value        : role,
                    tooltip      : role,
                    displayValue : role
                },
                "groups":
                {
                    value        : groups,
                    tooltip      : groups,
                    displayValue : groups
                },
                "created":
                {
                    value        : accountStatus,
                    tooltip      : "",
                    notLink      : true,
                    displayValue : <div
                                    className="user-session-cell"
                                    >
                                        <span>
                                            {this.getAccountStatus(user, true)}
                                        </span>

                                        {user.superAdmin ? null : !user.readOnly && canDelete ?
                                                <div onClick={(e) => this.onUserActionsClick(e, user)} className={"action-popover-trigger item-" + user._id}>
                                                    ...
                                                </div>
                                                :
                                                <div className="action-popover-trigger disabled">
                                                    ...
                                                </div>

                                          }
                                      </div>
                },
                rowSelected     : false,
                rowClassName    : "",
                onClick         : (e) => this.showUserFormModalFromTable(user),
                rowLink         : "#",
                displayIndexTableElOnHover: true,
                indexTableEl    : Permissions.can("edit", "user", this.props.user.data, {userId: user._id, current_user_Id: this.props.user.data._id}) &&
                                <div
                                    className="edit-svg"
                                    onClick={(e) => this.showUserFormModalFromTable(user)}
                                    >
                                    <InlineIcon><EditIcon /></InlineIcon>

                                </div>,
                object          : user,
                rowId           : user._id
            }

            return cells

        })
        if (!rows) {
            return <Loading />
        }

        let markup =
            <div className="user-list-form-block">
                <div className="users-header-block">
                    <div className="nav-block">
                        <div className="header-block">
                            <div className="heading-section">
                                <h1>Users</h1>
                                {
                                    Permissions.can("create", "user", this.props.user.data) &&
                                    <button
                                      className='green new-button'
                                      onClick={this.props.showAddNewUser}>
                                      NEW
                                    </button>
                                }

                            </div>

                            <div>
                          </div>

                        </div>

                    </div>

                </div>

                {
                this.state.disableFlag ?
                <div className="new-warning-modal">
                    <ModalBox onClose={this.hideDisableModal} className="disable-user-modal">
                        <h1>Disable user?</h1>
                        <h3>{this.state.userEmail}</h3>
                        <div className="warning-text">
                            <p>This user will no longer have login access. Disabled
                            users can be re-activated at any time and will be
                            required to set a new password.</p>
                        </div>
                        <div className="bottom-section">
                            <div className="button-sections">
                              <button
                                  type="button"
                                  onClick={this.hideDisableModal}
                                  >
                                NO
                              </button>

                              <button
                                  type="button"
                                  onClick={this.disableUser}
                                  className="filled">
                                YES
                              </button>
                            </div>
                        </div>
                    </ModalBox>
                </div> : null

            }
            {
                this.state.deleteFlag ?
                <div className="new-warning-modal">
                    <ModalBox onClose={this.hideDeleteModal} className="delete-user-modal">
                        <h1>Delete this user?</h1>
                        <h3>{this.state.userEmail}</h3>
                        <div className="warning-text">
                            <p>This action cannot be undone.</p>
                        </div>
                        <div className="bottom-section">
                            <div className="button-sections">
                              <button
                                  type="button"
                                  onClick={this.hideDeleteModal}
                                  >
                                NO
                              </button>

                              <button
                                  type="button"
                                  onClick={this.deleteUser}
                                  className="filled">
                                YES
                              </button>
                            </div>
                        </div>
                    </ModalBox>
                </div> : null
            }

            <div>
            {
                this.state.displayUserForm ?
                <UserFormModalBox
                  onClose={this.closeAddNewUser}
                  displayModal={this.state.displayUserForm}
                  mode={"edit"}
                  userData={users[this.state.selectedUserIndex]}
                  afterSubmitSuccessCb={this.getUsersList}
                />
                : null
            }
            </div>

            {
                this.props.users.loading ? <Loading />
                :
                <ExtendedTable
                    wrapperClassName="setting-users-block"
                    wrapperSelectorClass="setting-users-view"
                    headings={tableHeaders}
                    rows={rows}
                    stylesName="settingsUsers"
                    allowRowSelect={false}
                    includeToolBar={true}
                    currentSortItem={this.state.current}
                    currentSortItemAscending={this.state.currentSortItemAscending}
                    resultText={`${rows.length} users`}
                />
            }
            {
                users.map((user, i) =>
                {
                    let disableClazz = (user.status === "DISABLED") ? "user-disable" : ""

                    let actionsList = []
                    let disableAction = {label: "Disable user", icon: <DisableIcon />,  to: "#", state: privatePaths.changeOrderNew.pathname.state, onClick: (event)=>this.showDisableModal(event, user.email, user._id) }
                    let deleteAction = {label: "Delete user", icon: <DeleteIcon />,  to: "#", state: privatePaths.signOut.state, onClick: (event)=>this.showDeleteModal(event, user.email, user._id) }

                    if (!disableClazz)
                        actionsList.push(disableAction)
                    actionsList.push(deleteAction)
                    let options = []
                    actionsList.forEach((option, index) =>
                    {
                        let block =
                            <span className={option.disabled ? "disabled" : ""} key={index + 'u-' + i} onClick={(e) => option.onClick(e, option)}>
                                <div className="action-option">
                                    <InlineIcon>{option.icon}</InlineIcon>
                                    <div className="label">{
                                        option.label
                                        // user.name
                                    }</div>
                                </div>
                            </span>

                        options.push(block)
                    })

                    return <div key={"action-item-" + user._id} id={"action-item-" + user._id} className={"action-pop-item hidden action-popover"}> {options} </div>
                })
            }
        </div>

        return markup
    }
}

export default connect((store) => store)(UsersView)
