
import Utils from "../../utils"

class ProductRevisionAPI
{
    static findById(id, cb)
    {
        Utils.get("/products/revisions/" + id, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static getFullTree(data, cb)
    {
        Utils.post("/products/parent/revision", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }
}

export default ProductRevisionAPI
