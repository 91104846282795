import React, {Component} from "react";
import UploadScreen       from "./upload-screen";
import MapScreen          from "./map-screen";
import ReviewScreen       from "./review-screen";
import ModalBox           from "../../../../../ui/modal-box";
import buildAction        from "../../../../../../helpers/buildAction";
import DISPLAYSIDEBAR     from "../../../../../../action-types/display-sidebar";

class FileImportModule extends Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            screen : null,
            displayModal : this.props.displayModal ? true : false,
            prevHeadings : [],
            mpnImportDialog :
            {
                flag:  true
            }
        };
        this.next               = this.next.bind(this);
        this.setMpnFlag         = this.setMpnFlag.bind(this);
        this.onClose            = this.onClose.bind(this);
        this.onBackClick        = this.onBackClick.bind(this);
    }

    onClose()
    {
        this.props.onClose("displayFileImport", false);
    }

    componentWillMount()
    {
        if(this.props.displayImportFileFlag)
        {
            this.next();
        }
    }

    setMpnFlag(value)
    {
        let state = this.state;
        state.mpnImportDialog.flag = value;
        this.setState(state);
    }

    onBackClick(value)
    {
        let state = this.state;
        state.prevHeadings = value;
        this.setState({state})
    }

    next(event)
    {
        let name   = event && event.target.name;
        let screen = null;

        switch(name)
        {
            case undefined :
            {
                screen =
                    <UploadScreen
                        name="upload-screen"
                        showUiAlert={this.props.showUiAlert}
                        next={this.next}
                        displayModal={this.state.displayModal}
                        fileType={this.props.fileType}
                        onClose={this.onClose}
                        currentlyOpenedCategory={this.props.currentlyOpenedCategory}
                    />

                break;
            }

            case "upload-screen" :
            {
                screen =
                    <MapScreen
                        name="map-screen"
                        data={event.target.value}
                        next={this.next}
                        history={this.props.history}
                        fileType={event.fileType}
                        mpnImportText = {this.state.mpnImportText}
                        mpnImportDialog = {this.state.mpnImportDialog}
                        setMpnFlag = {this.setMpnFlag}
                        onClose={this.onClose}
                        currentlyOpenedCategory={this.props.currentlyOpenedCategory}
                        displayRefDesAndItemNumber={this.props.displayRefDesAndItemNumber}
                        prevHeadings={this.state.prevHeadings}
                    />

                break;
            }

            case "map-screen" :
            {
                screen =
                    <ReviewScreen
                        {...this.props}
                        name="review-screen"
                        data={event.target.value}
                        mappedData={event.target.mappingData}
                        fileType={event.fileType}
                        history={this.props.history}
                        next={this.next}
                        assemblyFlag={this.props.assemblyFlag}
                        currentlyOpenedCpn={this.props.currentlyOpenedCpn}
                        currentlyOpenedCategory={this.props.currentlyOpenedCategory}
                        mpnImportText={this.state.mpnImportText}
                        mpnImportDialog={this.state.mpnImportDialog}
                        onDoneFromCmpHeaderNav={this.props.onDoneFromCmpHeaderNav}
                        onClose={this.onClose}
                        onBackClick = {this.onBackClick}
                    />
                break;
            }

            default :
            {
                // noop
            }
        }

        this.state.screen = screen;
        this.setState(this.state);
    }

    render()
    {
        let markup =
            this.props.displayImportFileFlag ?
            <div className="new-component-file-route">
                {this.state.screen}
            </div>
        : <div></div>

        return markup;
    }
}

export default FileImportModule
