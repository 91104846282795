import COMPANY              from '../action-types/company'
import { handleActions }    from 'redux-actions'
import traverse             from 'traverse'
import initialState         from './initial-states/company'

function setCompanyState(state = initialState, {payload}) {
    const data = Object.assign(state.data, payload);
    state.inputs.name.value = data.name
    state.inputs.cpnScheme.activeScheme.value = data.cpnScheme ? data.cpnScheme.activeScheme : "DEFAULT"
    state.maxFileSize = data.settings.maxFileSize ? data.settings.maxFileSize : 25000000;
    state.inputs.cpnScheme.isActive.value = data.cpnScheme ? data.cpnScheme.isActive : false
    state.inputs.name.class = ""
    state.inputs.website.value = data.website
    state.inputs.website.class = ""
    state.inputs.address.street.value = data.address ? data.address.street : ""
    state.inputs.address.street.class = ""
    state.inputs.address.suite.value = data.address ? data.address.suite : ""
    state.inputs.address.suite.class = ""
    state.inputs.address.city.value = data.address ? data.address.city : ""
    state.inputs.address.city.class = ""
    state.inputs.address.state.class = data.address ? data.address.state ? "" : "placeholder" : "placeholder"
    state.inputs.address.state.value = data.address ? data.address.state : ""
    state.inputs.address.state.class = ""
    state.inputs.address.zip.value = data.address ? data.address.zip : ""
    state.inputs.address.zip.class = ""
    state.inputs.submit.class = "disabled"
    state.inputs.enforceTwoFa.value = data.settings.twoFaConfiguration.enforceTwoFa;
    state.logo_src = data.logo_src

    state.cpnScheme.activeScheme = data.cpnScheme ? data.cpnScheme.activeScheme : "DEFAULT"
    state.cpnScheme.isActive = data.cpnScheme ? data.cpnScheme.isActive : false
    state.name = data.name
    state.enforceTwoFa = data.settings.twoFaConfiguration.enforceTwoFa;

    return {...state, data};
}

const setFormFields = (state = initialState, {payload}) => {
    state.data   = payload.company
    state.inputs = payload.inputs
    state.inputs.submit.class = validated(state) ? "" : "disabled"

    return { ...state };
}


function validated(state) {
    let validated = true
    traverse(state.inputs).forEach(function (value) {
        if (this.key === "valid" && !value) validated = false
    })

    return validated
}

function setApiError (state = initialState, { payload }) {
    let fields = state.inputs
    let field = undefined
    payload.forEach((err) =>
    {
        switch ( err.path) {

            case "name" :
                field = fields.name
                break
            case "website" :
                field = fields.website
                break
            case "logo" :
                field = fields.logo
                break
            case "removeBtn" :
                field = fields.removeBtn
                break
            case "address.street" :
                field = fields.address.street
                break
            case "address.suite" :
                field = fields.address.suite
                break
            case "address.city" :
                field = fields.address.city
                break
            case "address.state" :
                field = fields.address.state
                break
            case "address.zip" :
                field = fields.address.zip
                break
            case "enforceTwoFa" :
                field = fields.enforceTwoFa;
                break;
            default:
        }
        if (field){
            field.message = err.message
            field.valid = false
            field.class = "invalid"
        }

    })

    return { ...state };
}

export default handleActions({
    [COMPANY.UPDATE_FORM_STATE]: setFormFields,
    [COMPANY.SET_COMPANY_STATE]: setCompanyState,
    [COMPANY.VALIDATE_COMPANY_FORM]: validated,
    [COMPANY.SET_API_ERRORS]: setApiError
}, initialState);
