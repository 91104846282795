import './index.css';
import React, {Fragment} from 'react';
import CustomReactSelect from "../../../ui/custom-react-select";
import InlineIcon from "../../../ui/icon/inline-icon.js"
import SettingsIconSrc from "../../../../assets/icons/settings";
import SaveIconSrc from "../../../../assets/icons/save";

const TemplateSelect = ({selectedTemplate, onChange, options, toggleManageTemplateModal, toggleSaveModal, enableSave, mode}) => {
    const saveIconClass = mode === 'co' ? enableSave ? 'show-save' : 'disable-save' : '';
    const template = Object.keys(selectedTemplate).length ? selectedTemplate : { label: "Default Template", value: "" };
    const onClick = (event) => {
        event.stopPropagation();
        toggleManageTemplateModal(event);
    }
    return (
        <Fragment>
            <div className="template-select">
                <CustomReactSelect value={template} onChange={onChange} options={options} placeholder={"Select Template"} className={`template`} />
                <div className="manage-templates" onClick={onClick}>
                    <InlineIcon tooltip="Template Settings" tooltipPlace="top">
                        <SettingsIconSrc />
                    </InlineIcon>
                </div>
                <div className={saveIconClass} onClick={toggleSaveModal}>
                    <InlineIcon tooltip="Save Template" tooltipPlace="top">
                        <SaveIconSrc />
                    </InlineIcon>
                </div>
            </div>
        </Fragment>)
}
export default TemplateSelect;
