import          "./index.css"
import React     from "react"

const DescriptionBlock = ({ heading, position, longDesc, showFullDesc, toggleDesc, description}) => {
    return (
        <div className="description-block">
            {heading && <div className="heading">{heading}</div>}
            <div className={`content ${position}`}>
                {description}
            </div>
            {longDesc && <span onClick={toggleDesc} className="show-fulltext green">{` ${showFullDesc ? "Less" : "Show more"}`}</span>}
        </div>
    )
}

export default DescriptionBlock
