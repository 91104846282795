import Utils from "../utils";

const modules = new window.PlmApiModules()

export const schemas = modules.schemas
export const queries = modules.queries

export function validateField(input, schema, value, state = {}, params = {}){
    if (window.__cpn_rules() && schema.path === 'cpnVariant')
       return Utils.validateCpnVariant({ input, value, state }) 
    let errors;
    let valid_class = "valid"
    let invalid_class = "invalid"
    if (params.valid_class !== undefined) valid_class     = params.valid_class
    if (params.invalid_class !== undefined) invalid_class = params.invalid_class
    input.value = value
    errors = schema.validateErrors(state, input.value)
    input.message = errors ? errors[0].message : ''
    input.class = input.message ? invalid_class : valid_class
    input.valid = !input.message
}

export function validateFieldWithAllErrorMessages(input, schema, value, state = {}, params = {}){
    let errors = undefined
    let valid_class = "valid"
    let invalid_class = "invalid"

    if (params.valid_class !== undefined) valid_class     = params.valid_class
    if (params.invalid_class !== undefined) invalid_class = params.invalid_class

    input.value = value
    errors = schema.validateErrors(state, input.value)
    let message = ""
    if (errors) errors.forEach((error) => message = message + error.message + ",")
    input.message = message
    input.class = input.message ? invalid_class : valid_class
    input.valid = !input.message
}

export function normalizeValue(schema, value, state = {}, params = {}){
    return schema.normalize(state, value)
}


export default schemas
