
import validator    from "validator"
import Utils        from "../utils"
import API          from "../api"
import Categories   from "./categories"
import Schemas      from "./index"
import Assembly     from "./assembly"
import { schemas }  from '../validations'

const componentSchema = {
    keys :
    {
        allList(listType, category="", displayRefDesAndItemNumber=false)
        {
            let refDesOption = {}
            let isItemNumberOnly = Utils.shouldDisplayOnlyItemNumber(category, displayRefDesAndItemNumber)
            if (!isItemNumberOnly)
            {
                refDesOption =
                {
                    "value": "ref des",
                    "displayName": "Ref Des",
                    "new_assembly": true,
                    "update_assembly": true
                }
            }

            let list =
            [

                {
                    "value"           : "",
                    "displayName"     : "",
                    "disabled"         : true
                },
                {
                    "value"           : "",
                    "displayName"     : "- - SUMMARY - -",
                    "disabled"         : true
                },
                {
                    "value"           : "",
                    "displayName"     : "",
                    "disabled"         : true
                },
                {
                    "value": "category",
                    "displayName": "Category",
                    "new": true,
                    "new_assembly": true
                },
                {
                    "value": "name",
                    "displayName": "Name",
                    "new": true,
                    "update": true,
                    "new_assembly": true
                },
                {
                    "value": "mass",
                    "displayName": "Mass",
                    "new": true,
                    "update": true,
                    "new_assembly": true
                },
                {
                    "value": "cpn",
                    "displayName": "CPN",
                    "update": true,
                    "update_assembly": true
                },
                {
                    "value": "eid",
                    "displayName": "EID",
                    "new": true,
                    "update": true,
                    "new_assembly": true
                },
                {
                    "value": "description",
                    "displayName": "Description",
                    "new": true,
                    "update": true,
                    "new_assembly": true
                },
                {
                    "value": "status",
                    "displayName": "Status",
                    "new": true,
                    "update": true,
                    "new_assembly": true
                },
                {
                    "value": "revision",
                    "displayName": "Revision",
                    "new": true,
                    "update": true,
                    "new_assembly": true
                },
                {
                    "value": "documents",
                    "displayName": "Documents",
                    "new": true,
                    "update": true,
                    "new_assembly": true
                },

                {
                    "value": "images",
                    "displayName": "Images",
                    "new": true,
                    "update": true,
                    "new_assembly": true
                },

                // {
                //     "value"        : "assembly",
                //     "displayName"  : "Assembly",
                //     "new"          : true,
                //     "update"       : true,
                //     "new_assembly" : true
                // },

                {
                    "value"           : "",
                    "displayName"     : "",
                    "disabled"         : true
                },
                {
                    "value"           : "",
                    "displayName"     : "- - SPECIFICATIONS - -",
                    "disabled"         : true
                },
                {
                    "value"           : "",
                    "displayName"     : "",
                    "disabled"         : true
                },
                {
                    "value": "specs",
                    "displayName": "Specs",
                    "new": true,
                    "update": true,
                    "new_assembly": true
                },

                {
                    "value": "procurement",
                    "displayName": "Procurement",
                    "new": true,
                    "update": true,
                    "new_assembly": true
                },

                {
                    "value": "unit of measure",
                    "displayName": "Unit Of Measure",
                    "new": true,
                    "update": true,
                    "new_assembly": true
                },

                {
                    "value"           : "",
                    "displayName"     : "",
                    "disabled"         : true
                },
                {
                    "value"           : "",
                    "displayName"     : "- - SOURCES - -",
                    "disabled"         : true
                },
                {
                    "value"           : "",
                    "displayName"     : "",
                    "disabled"         : true
                },
                {
                    "value": "mpn",
                    "displayName": "MPN",
                    "new": true,
                    "update": true,
                    "new_assembly": true
                },
                {
                    "value": "manufacturer",
                    "displayName": "Manufacturer",
                    "new": true,
                    "update": true,
                    "new_assembly": true
                },
                {
                    "value": "mfr description",
                    "displayName": "Mfr Description",
                    "new": true,
                    "update": true,
                    "new_assembly": true
                },
                {
                    "value": "datasheet",
                    "displayName": "Datasheet",
                    "new": true,
                    "update": true,
                    "new_assembly": true
                },
                {
                    "value": "dpn",
                    "displayName": "DPN",
                    "new": true,
                    "update": true,
                    "new_assembly": true
                },
                {
                    "value": "distributor",
                    "displayName": "Distributor",
                    "new": true,
                    "update": true,
                    "new_assembly": true
                },
                {
                    "value": "dist description",
                    "displayName": "Dist Description",
                    "new": true,
                    "update": true,
                    "new_assembly": true
                },
                {
                    "value": "package",
                    "displayName": "Package",
                    "new": true,
                    "update": true,
                    "new_assembly": true
                },
                {
                    "value": "package quantity",
                    "displayName": "Package Quantity",
                    "new": true,
                    "update": true,
                    "new_assembly": true
                },


                {
                    "value"           : "",
                    "displayName"     : "",
                    "disabled"         : true
                },
                {
                    "value"           : "",
                    "displayName"     : "- - QUOTES - -",
                    "disabled"         : true
                },
                {
                    "value"           : "",
                    "displayName"     : "",
                    "disabled"         : true
                },
                {
                    "value": "min quantity",
                    "displayName": "Min Quantity",
                    "new": true,
                    "update": true,
                    "new_assembly": true
                },
                {
                    "value": "price",
                    "displayName": "Price",
                    "new": true,
                    "update": true,
                    "new_assembly": true
                },
                {
                    "value": "quote lead time",
                    "displayName": "Quote Lead Time",
                    "new": true,
                    "update": true,
                    "new_assembly": true
                },
                {
                    "value": "quote lead time unit",
                    "displayName": "Quote Lead Time Unit",
                    "new": true,
                    "update": true,
                    "new_assembly": true
                },


                {
                    "value"           : "",
                    "displayName"     : "",
                    "disabled"         : true
                },
                {
                    "value"           : "",
                    "displayName"     : "- - ASSEMBLY - -",
                    "disabled"         : true
                },
                {
                    "value"           : "",
                    "displayName"     : "",
                    "disabled"         : true
                },

                {
                    "value": "quantity",
                    "displayName": "Quantity",
                    "new_assembly": true,
                    "update_assembly": true
                },

                {
                    "value": "item number",
                    "displayName": "Item Number",
                    "new_assembly": true,
                    "update_assembly": true
                },

                {
                    "value": "notes",
                    "displayName": "Notes",
                    "new_assembly": true,
                    "update_assembly": true
                }
            ]

            if (Object.getOwnPropertyNames(refDesOption).length > 0)
            {
                list.push(refDesOption);
            }

            list.forEach((item) =>
            {
                if(item.value === "cpn" && window.__currentCompanyCpnType === "FREE-FORM")
                {
                    item.new = true;
                    item.new_assembly = true;
                }
                if ( item[listType] !== true )
                    item.disabled = true
            })

            return list
        },

        getDisplayName(value, category="", displayRefDesAndItemNumber=false)
        {
            let list = Schemas.component.keys.allList(null, category, displayRefDesAndItemNumber)
            let name = ""

            list.forEach((item) =>
            {
                if(item.value === value)
                {
                    name = item.displayName
                }
            })

            return name
        }
    },

    name :
    {
        generate(category, inputs, catList)
        {
            if (!category)
                return ""
            let short = schemas.categories.findByName2(category, catList).shortName
            let parts = []

            inputs.forEach((input, i) =>
            {
                // TODO: Pass specs keys/current values along and get required from Categories class
                if(input.value && input.schema.required) parts.push(input.value)
            })

            return short + " " + parts.join(" ")
        },

        normalize(value)
        {
            return Utils.toParagraphString(value)
        },

        validate(value, cb, obj={})
        {
            if(!cb)
            {
                if(typeof value !== "string" || value.trim() === "")
                {
                    return "Invalid name"
                }

                return ""
            }

            if(typeof value !== "string" || value.trim() === "")
            {
                return cb("Invalid name")
            }

            API.components.nameExists({name: value}, (err, data) =>
            {
                if(data)
                {
                    return cb(data)
                }

                cb("")
            }, obj)
        }
    },

    cpn :
    {
        normalize(value)
        {
            return value
        },

        validate(value, cb, type)
        {
            if(!cb)
            {
                if(typeof value !== "string" || value.trim() === "")
                {
                    return "Invalid CPN"
                }

                return ""
            }

            if(typeof value !== "string" || value.trim() === "")
            {
                return cb("Invalid CPN")
            }
            if(["new", "new_assembly"].includes(type) && window.__currentCompanyCpnType === "FREE-FORM")
            {
                API.components.cpnExists({cpn: value}, (err, data) =>
                {
                    if(data)
                        return cb(data);
                    return cb("");
                });
            }
            else {
                API.components.findByCpn(value, (err, cmp) => {
                    if (cmp) {
                        if (cmp.co?.status === "OPEN") {
                            return cb("Component is in an open change order")
                        }
                        return cb("", cmp)
                    }
                    cb("Invalid CPN")
                })
            }
        },
    },

    mpn :
    {
        validate(data, cb) {
            API.components.validateMpn(data, (err, res) =>
            {
                cb(res)
            })
        },

        validateOctopart(data, cb) {
            API.components.validateOctopartMpn(data, (err, res) =>
            {
                cb(res)
            })
        }
    },

    parent :
    {
        normalize(value)
        {
            return value
        },
        validate(value, cb)
        {
            if(!cb)
            {
                if(typeof value !== "string" || value.trim() === "")
                {
                    return "Invalid Parent"
                }

                return ""
            }

            if(typeof value !== "string" || value.trim() === "")
            {
                return cb("Invalid Parent")
            }

            let query = "type:cmp id:" + value
            API.search(query, 1, null, (err, data) =>
            {
                if(err)
                {
                    // TODO: handle server error
                    return cb(console.error(err))
                }

                if (data.results.length === 0)
                {
                    return cb("No match found")
                }

                if (data.results[0].co !== null)
                {
                    return cb("This parent is in a change order")
                }

                cb("")
            })
        }
    },

    sku :
    {
        normalize(value)
        {
            return value
        },

        validate(value)
        {
            return ""
        }
    },

    category :
    {
        list           : Categories.list,
        getDisplayName : Categories.getDisplayName,
        getShortName   : Categories.getShortName,
        getType        : Categories.getType,

        normalize(value)
        {
            value = String(value)
            // value = value.toLowerCase()
            return value
        },

        validate(value)
        {
            let name = Schemas.component.category.getDisplayName(value)
            return name ? "" : "Invalid Category Type"
        }
    },

    specs :
    {
        list(category)
        {
            let list = []
            let categories = Categories.list()

            categories.forEach((item) =>
            {

                if(item.name === category)
                {

                    list = item.specs
                }
            })

            return list
        },

        getKey(key)
        {
            if (key === "Operating Temperature Min")
                return "op temp (min)"
            if (key === "Operating Temperature Max")
                return "op temp (max)"
            if (key === "Temperature Coefficient")
                return "temp coef."
            return key
        },


        getDisplayName(catValue, specValue)
        {
            let categories = Categories.list()

            for(let i=0; i<categories.length; i++)
            {
                let category = categories[i]

                if(category.name === catValue)
                {
                    for(let j=0; j<category.specs.length; j++)
                    {
                        let spec = category.specs[j]

                        if(spec.name === specValue)
                        {
                            return spec.displayName
                        }
                    }
                }
            }
        },

        normalize(category, key, value)
        {
            return value
        },

        validate(category, key, value)
        {
            return ""
        },

        isSpecAllowedForCompany(specName)
        {
            // ECAD General Specs are displayed at components view/edit page and manually create Modal of component,
            // only if the company flag "isEcadGeneralSpecsEnabled" is true.
            let ecadGeneralSpecs = ["Footprint Path", "Package Label", "Symbol Path", "Symbol Label"];
            return (!window.__isEcadGeneralSpecsEnabled && ecadGeneralSpecs.includes(specName)) ? false : true;
        }
    },

    status :
    {
        list(currentStatus='')
        {
            return Utils.getStatusesList(currentStatus)
        },

        normalize(value)
        {
            return value
        },

        validate(value, revision)
        {
            if(value === "" && revision === "") return ""

            let valid = false

            this.list().forEach((child) =>
            {
                if (child.value === value) { valid = true }
            })

            if (valid)
            {
                return "";
            }
            return "Status is not valid";
        }
    },

    unitOfMeasure :
    {
        list()
        {
            return Utils.getUnitOfMeasuresList()
        },

        normalize(value)
        {
            // TODO: Need to generalize the functionality of "unit of measure" to allow both
            // lower and upper case letters as per the requirements.
            // For now, only 2 custom "unit of measure" are in lower case i.e. "m²", "mm²"

            value = String(value)
            if (!["m²", "mm²", "mg/l"].includes(value.toLowerCase()))
            {
                value = value.toUpperCase()
            }

            return value
        },

        validate(value)
        {
            //Allow empty value or value from the valid list during import
            let unitOfMeasures = Schemas.component.unitOfMeasure.list()
            let foundObject = unitOfMeasures.find(obj => obj.displayName.toUpperCase() === value.toUpperCase());
            return foundObject || !value ? "" : "Invalid Unit Of Measure"
        }
    },

    procurement :
    {
        list()
        {
            return Utils.getProcurementList();
        },
    },

    revision :
    {
        normalize(value, status)
        {
            if(value === "") return ""

            if(typeof value !== "string") value = ""

            switch(status.toLowerCase())
            {
                case "design" :
                case "prototype" :
                {
                    let char1 = value[0] || ""
                    let char2 = value[1] || ""
                    let str   = String(char1 + char2)
                    if(validator.isNumeric(str) && Number(str) < 100 && Number(str) > 0) return str
                    return validator.isNumeric(char1) ? char1 : "1"
                }

                default :
                {
                    let char = (value[0] || "").toUpperCase()
                    return validator.isAlpha(char) ? char.toUpperCase() : "A"
                }
            }
        },

        validate(value, status)
        {
            if(value === "" && status === "") return ""

            if(value && !status) return "Status must be included with revision"

            switch(status.toLowerCase())
            {
                case "design" :
                {
                    if ( !value || Number(value) < 100 && Number(value) > 0 || validator.isAlpha(value.toUpperCase()) )
                    {
                        return ""
                    }
                    else
                    {
                        return "Should contain number from 1 to 99 or letter from A to Z"
                    }
                }


                case "prototype" :
                {
                    return Number(value) < 100 && Number(value) > 0 ? "" : "Invalid revision"
                }

                default :
                {
                    if (isNaN(value) )
                    {
                        return validator.isAlpha(value.toUpperCase()) ? "" : "Invalid Letter"
                    }
                    return "Invalid Letter"
                }
            }
        }
    },

    eid :
    {
        normalize(value)
        {
            return value
        },

        validate(value, cb, obj={})
        {
            if(!cb)
            {
                if(typeof value !== "string")
                {
                    return "Invalid type"
                }

                // NOTE: max value may come from another source in the future.
                if(value.length > 25)
                {
                    return "Must not exceed 25 characters"
                }
            }

            if(typeof value !== "string")
            {
                return cb("Invalid type")
            }

            // NOTE: max value may come from another source in the future.
            if(value.length > 25)
            {
                return cb("Must not exceed 25 characters")
            }

            if(cb)
            {
                if (value)
                {
                    API.components.eidExists({eid: String(value).trim()}, (err, data) =>
                    {
                        if(data)
                        {
                            return cb(data)
                            // return cb("Component EID already exists in library.")
                        }

                        cb("")
                    }, obj)
                }
                else
                {
                    cb("")
                }
            }

        }

    },

    description :
    {
        normalize(value)
        {
            return Utils.toParagraphString(value)
        },

        validate(value)
        {
            return ""
        }
    },

    documents :
    {
        specs :
        {
            type :
            {
                list()
                {
                    let list = []
                    const documentTypeList = Utils.getAllowedDocTypes(); 
                    documentTypeList.forEach((type) => list.push({value:type, displayName: type}))
                    return list
                },

                normalize(value)
                {
                    return value
                },

                validate(value)
                {
                    return ""
                }
            },

            revision :
            {
                normalize(value, status)
                {
                    if(value === "") return ""

                    if(typeof value !== "string") value = ""

                    switch(status.toLowerCase())
                    {
                        case "design" :
                        case "prototype" :
                        {
                            let char1 = value[0] || ""
                            let char2 = value[1] || ""
                            let str   = String(char1 + char2)
                            if(validator.isNumeric(str) && Number(str) < 100 && Number(str) > 0) return str
                            return validator.isNumeric(char1) ? char1 : "1"
                        }

                        default :
                        {
                            let char = (value[0] || "").toUpperCase()
                            return validator.isAlpha(char) ? char.toUpperCase() : "A"
                        }
                    }
                },

                validate(value, status)
                {
                    if(value === "" && status === "") return ""

                    if(value && !status) return "Status must be included with revision"

                    switch(status.toLowerCase())
                    {
                        case "design" :
                        case "prototype" :
                        {
                            return Number(value) < 100 && Number(value) > 0 ? "" : "Invalid revision"
                        }

                        default :
                        {
                            if (isNaN(value) )
                            {
                                return validator.isAlpha(value.toUpperCase()) ? "" : "Invalid Letter"
                            }
                            return "Invalid Letter"
                        }
                    }
                }
            },

            status :
            {
                list(currentStatus='')
                {
                    return Utils.getStatusesList(currentStatus)
                },

                normalize(value)
                {
                    return value
                },

                validate(value, revision)
                {
                    if(value === "" && revision === "") return ""

                    let valid = false

                    this.list().forEach((child) =>
                    {
                        if (child.value === value) { valid = true }
                    })

                    if (valid)
                    {
                        return "";
                    }
                    return "Status is not valid";
                }
            }
        },

        url:
        {
            validateUrls(urls)
            {
                let valid = true
                urls.forEach((url) => {
                    if(!validator.isURL(url.trim()))
                    {
                        valid = false
                        return valid
                    }
                })
                return valid
            },

            validate(value, cb)
            {
                let urls = value.split(",")

                if(!cb)
                {
                   if (!componentSchema.documents.url.validateUrls(urls))
                   {
                        return "Invalid URL found"
                   }
                }
                else
                {
                   if (!componentSchema.documents.url.validateUrls(urls))
                   {
                        return cb("Invalid URL found")
                   }
                }

                if(cb && componentSchema.documents.url.validateUrls(urls))
                {
                    if (value)
                    {
                        API.components.isValidDocumentUrl(value.trim(), (err, res) =>
                        {
                            if(!res)
                            {
                                return cb("Invalid URL found")
                            }
                            cb("")
                        })
                    }
                    else
                    {
                        cb("")
                    }
                }
            }
        }
    },

    images :
    {
        url:
        {
            validateUrls(urls)
            {
                let valid = true
                urls.forEach((url) => {
                    if(!validator.isURL(url.trim()))
                    {
                        valid = false
                        return valid
                    }
                })
                return valid
            },

            validate(value, cb)
            {
                let urls = value.split(",")

                if(!cb)
                {
                   if (!componentSchema.images.url.validateUrls(urls))
                   {
                        return "Invalid URL found"
                   }
                }
                else
                {
                   if (!componentSchema.images.url.validateUrls(urls))
                   {
                        return cb("Invalid URL found")
                   }
                }

                if(cb && componentSchema.images.url.validateUrls(urls))
                {
                    if (value)
                    {
                        API.components.isValidImageUrl(value.trim(), (err, res) =>
                        {
                            if(!res)
                            {
                                return cb("Invalid URL found")
                            }
                            cb("")
                        })
                    }
                    else
                    {
                        cb("")
                    }
                }
            }
        }
    },

    children :
    {
        quantity :
        {
            normalize(value)
            {
                if(value === "") return value
                value = Number(value)
                return value > 1 ? value : 1
            },

            validate(value)
            {
                if(typeof value !== "string" || value.trim() === "")
                {
                    return "Invalid quantity"
                }
            },

            validateWithRefDef(quantityValue, refDesValue, cb)
            {
                return Assembly.compareQuantityAndRefDes(refDesValue, quantityValue, cb)

            }
        },

        refDes :
        {
            normalize(value)
            {
                return value
            },

            validate(value)
            {
                return ""
            },

            validateWithRefDef(refDesValue, quantityValue, cb)
            {
                return Assembly.compareQuantityAndRefDes(refDesValue, quantityValue, cb)
            }
        }
    },

    manufacturers :
    {
        name :
        {
            normalize(value)
            {
                return Utils.toParagraphString(value)
            },

            validate(value)
            {
                return ""
            }
        },

        mpn :
        {
            value :
            {
                normalize(value)
                {
                    return Utils.toCondensedString(value).toUpperCase()
                },

                validate(value)
                {
                    return ""
                }
            },

            src :
            {
                normalize(value)
                {
                    //return Utils.toCondensedString(value)
                    return Utils.appendHTTP(value)
                },

                validate(value)
                {
                    return ""
                }
            }
        },

        description :
        {
            normalize(value)
            {
                return Utils.toParagraphString(value)
            },

            validate(value)
            {
                return ""
            }
        },

        leadTime :
        {
            normalize(value)
            {
                return Utils.toParagraphString(value)
            },

            validate(value)
            {
                return ""
            }
        },

        datasheet :
        {
            src :
            {
                normalize(value)
                {
                    //return Utils.toCondensedString(value)
                    value.replace(/\s/g, '')
                    return Utils.appendHTTP(value)
                },

                validate(value)
                {
                    return ""
                }
            }
        },

        distributors :
        {
            name :
            {
                normalize(value)
                {
                    return Utils.toParagraphString(value)
                },

                validate(value)
                {
                    return ""
                }
            },

            dpn :
            {
                value :
                {
                    normalize(value)
                    {
                        return Utils.toCondensedString(value).toUpperCase()
                    },

                    validate(value)
                    {
                        return ""
                    }
                },

                src :
                {
                    normalize(value)
                    {
                        return Utils.appendHTTP(value)
                    },

                    validate(value)
                    {
                        return ""
                    }
                }
            },

            description :
            {
                normalize(value)
                {
                    return Utils.toParagraphString(value)
                },

                validate(value)
                {
                    return ""
                }
            },

            package :
            {
                type :
                {
                    normalize(value)
                    {
                        return Utils.toParagraphString(value)
                    },

                    validate(value)
                    {
                        return ""
                    }
                },

                quantity :
                {
                    normalize(value)
                    {
                        if(value === "") return value
                        value = Number(value)
                        return value > 1 ? value : 1
                    },

                    validate(value)
                    {
                        return ""
                    }
                }
            },

            quotes :
            {

                minQuantity :
                {
                    normalize(value)
                    {
                        if(value === "") return value
                        value = Number(value)
                        return value > 1 ? value : 1
                    },

                    validate(value)
                    {
                        return ""
                    }
                },

                maxQuantity :
                {
                    normalize(value)
                    {
                        if(value === "") return value
                        value = Number(value)
                        return value > 1 ? value : 1
                    },

                    validate(value)
                    {
                        return ""
                    }
                },

                unitPrice :
                {
                    normalize(value)
                    {
                        return Utils.toCondensedString(value)
                    },

                    validate(value)
                    {
                        return ""
                    }
                },

                leadTime :
                {
                    normalize(value)
                    {
                        return Utils.toParagraphString(value)
                    },

                    validate(value)
                    {
                        return ""
                    }
                }
            }
        }
    }
}

export default componentSchema
