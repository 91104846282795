import React from "react";

function Icon({useCurrentColor = false}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="16"
      height="17"
      viewBox="0 0 16 17"
    >
      <defs>
        <path
          id="path-19098"
          d="M0 0.331571429L15.6730839 0.331571429 15.6730839 16.2380952 0 16.2380952z"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-1319 -651)">
          <g transform="translate(303 145)">
            <g transform="translate(1016 506)">
              <g>
                <g transform="translate(0 .192)">
                  <mask id="mask-2" fill="#fff">
                    <use xlinkHref="#path-19098"></use>
                  </mask>
                  <path
                    fill={useCurrentColor ? "currentColor" : "#3CD1B5"}
                    d="M15.673 8.285c0 4.393-3.509 7.954-7.836 7.954C3.508 16.239 0 12.678 0 8.285S3.508.331 7.837.331c4.327 0 7.836 3.561 7.836 7.954"
                    mask="url(#mask-2)"
                  ></path>
                </g>
                <path stroke="#17171F" d="M10.082 6.199l-4.49 4.556"></path>
                <path stroke="#17171F" d="M10.082 10.755L5.592 6.2"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
