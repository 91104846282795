import {
  JSXElementConstructor, MutableRefObject, PropsWithChildren, ReactNode, Ref, useMemo, useCallback, useState,
} from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Box, LinearProgress as MUILinearProgress, styled, Typography } from "@mui/material";
import {
  DataGridProProps, GridCallbackDetails, GridCellCheckboxRenderer, GridCellParams,
  GridColDef, GridColumnHeaderParams, GridColumnVisibilityModel, GridEventListener,
  GridFilterModel, GridHeaderCheckbox, GridInitialState, GridPinnedColumns,
  GridRenderCellParams, GridRowClassNameParams, GridRowIdGetter, GridSelectionModel,
  GridSortDirection, GridSortModel, GridValidRowModel,
  GRID_CHECKBOX_SELECTION_COL_DEF, LicenseInfo, useGridApiRef, GridRowId,
} from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { TreeCollapseIcon, TreeExpandIcon } from "assets/icons";
import { colorPalette } from "theme";
import { DuroCheckbox } from "../checkbox";
import { TableFilterPanel } from "./gridFilterPanel";
import { GridToolbar, ToolBarItem } from "./gridToolbar";
import { StyledBaseGrid } from "./styledBaseGrid";
import { useDataTestId } from "common/hooks";
import { ImageContextProvider } from "../imageContext/imageContextProvider";

LicenseInfo.setLicenseKey(
  "21c77a7e549bf40b7d5c7e307d97d640Tz01NTUwNixFPTE3MDE2MjM3OTUwOTAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
);

export const enum CellContentAlignment {
  CENTER = "center",
  LEFT = "left",
  RIGHT = "right",
}

export const enum SortMode {
  SERVER = "server",
  CLIENT = "client",
}

// This is a base height that can be used when dynamically sizing the parent of
// the grid in JavaScript.
export const GRID_BASE_HEIGHT = 75;

// This class name can be put onto cells in the grid to get the special edit
// properties onto the cell.
export const GRID_CELL_EDIT_CLASS_NAME = "grid-cell-edit";

// These are teh sort directions supported by the grid.
export const GRID_SORT_ORDER = ["asc", "desc"] as GridSortDirection[];

// The default height used for each row of the table.
export const ROW_HEIGHT = 34;

const GRID_TEST_ID = "grid";

type CheckboxColDef = Partial<
  Omit<GridColDef, "renderCell"> &
  { renderCell: (props: GridCheckboxParams) => ReactNode }
>

interface IGridProps<RowData extends GridValidRowModel, NoRowsOverlayProps> {
  apiRef?: MutableRefObject<GridApiPro>,
  autoHeight?: boolean;
  checkboxColumnDefinition?: CheckboxColDef;
  columnDefinition: Array<GridColDef>;
  columnVisibilityModel?: GridColumnVisibilityModel,
  customFooter?: () => JSX.Element;
  data: RowData[];
  defaultGroupingExpansionDepth?: number,
  deselectedRowClassName?: string,
  disableCheckbox?: boolean;
  enableCellFocus?: boolean;
  filters?: GridFilterModel;
  filtersEnabled?: boolean;
  getCellClassName?: (params: GridCellParams) => string,
  getRowId?: GridRowIdGetter<RowData>;
  getTreeDataPath?: (row: RowData) => string[];
  initialColumnOrder?: string[]
  initialColumnVisibilityModel?: GridColumnVisibilityModel;
  initialPinnedColumns?: GridPinnedColumns;
  isCellEditable?: (params: GridCellParams<number>) => boolean,
  loading?: boolean;
  loadingOverlay?: () => JSX.Element,
  name: string;
  noRowsOverlay?: JSXElementConstructor<NoRowsOverlayProps>,
  noRowsOverlayProps?: NoRowsOverlayProps,
  onCellEditStart?: GridEventListener<"cellEditStart">;
  onCellEditStop?: GridEventListener<"cellEditStop">;
  onColumnsReordered?: GridEventListener<"columnOrderChange">;
  onColumnsResized?: GridEventListener<"columnWidthChange">;
  onColumnVisibilityModelChange?: (model: GridColumnVisibilityModel, details: GridCallbackDetails) => void;
  onFiltersChange?: (model: GridFilterModel, details: GridCallbackDetails<"filter">) => void;
  onProcessRowUpdateError?: (error: any) => void;
  onRowScrollEnd?: GridEventListener<"rowsScrollEnd">;
  onSelectionChange?: (selectionModel: GridSelectionModel, details: GridCallbackDetails) => void;
  onSortChange?: (sortModel: GridSortModel, details: GridCallbackDetails) => void;
  pinnedColumns?: GridPinnedColumns;
  processRowUpdate?: (newRow: RowData, oldRow: RowData) => Promise<RowData> | RowData;
  scrollEndThreshold?: number;
  selectedRowClassName?: string,
  selectionModel?: GridRowId[];
  sortMode?: SortMode;
  sortModel?: GridSortModel;
  toolbarItems?: Array<ToolBarItem>;
  totalCount?: string;
  treeColumnDefinition?: DataGridProProps["groupingColDef"];
  treeData?: boolean;
}

function defaultGetTreeDataPath(row: { _path: string[] }) {
  return row._path;
}

export function getRowIdForApiData(row: { _id: string }) {
  return row._id;
}

const CheckboxContainer = styled(Box)<{
  checked: boolean,
  disableCheckbox: boolean,
}>(({ checked, disableCheckbox }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "100%",
  "& svg": {
    display: checked ? undefined : "none",
  },
  "&:hover svg": {
    display: disableCheckbox ? "none" : "inherit",
  },
  "& p": {
    display: checked ? "none" : undefined,
  },
  "&:hover p": {
    display: disableCheckbox ? "inherit" : "none",
  },
}));

export type GridCheckboxParams = GridRenderCellParams &
  PropsWithChildren<{ disableCheckbox: boolean, index: number }>;

export function GridCheckbox(props: GridCheckboxParams) {
  const { children, disableCheckbox, index, value: checked, ...checkboxProps }: any = props;

  if (children) return children;

  return (
    <CheckboxContainer checked={checked} disableCheckbox={disableCheckbox}>
      <GridCellCheckboxRenderer
        {...checkboxProps}
        value={checked}
      />
      <Typography variant="body2">
        {(index + 1)}
      </Typography>
    </CheckboxContainer>
  );
}

export type ButtonRefType = Ref<HTMLButtonElement> | undefined;
const experimentalFeatures = { newEditingApi: true };
const ColumnsPanelStyle = {
  backgroundColor: colorPalette.black,
  "& .MuiTypography-root": {
    fontSize: "0.875rem",
    marginLeft: "0.938rem",
  },
  "& .MuiDataGrid-columnsPanel": {
    marginLeft: "1rem",
  },
  "& .MuiDataGrid-columnsPanelRow > .Mui-disabled": {
    display: "none", // Hide settings checkboxes for columns that can't be removed.
  },
};

export function Grid<
  RowData extends GridValidRowModel,
  NoRowsOverlayProps
>(props: IGridProps<RowData, NoRowsOverlayProps>) {
  const {
    apiRef,
    checkboxColumnDefinition,
    columnDefinition,
    customFooter,
    data,
    deselectedRowClassName = "",
    disableCheckbox = false,
    filters,
    filtersEnabled,
    getCellClassName,
    getTreeDataPath = defaultGetTreeDataPath,
    initialColumnOrder,
    initialColumnVisibilityModel,
    initialPinnedColumns,
    loadingOverlay,
    name,
    noRowsOverlay,
    noRowsOverlayProps,
    onColumnsReordered,
    onColumnsResized,
    onFiltersChange,
    onRowScrollEnd,
    onSelectionChange,
    onSortChange,
    pinnedColumns,
    selectedRowClassName = "",
    sortMode = SortMode.SERVER,
    toolbarItems = [],
    totalCount,
    treeColumnDefinition,
    ...rest
  } = props;

  const backupApiRef = useGridApiRef();
  const internalApiRef = apiRef ?? backupApiRef;
  const columns: Array<GridColDef> = useMemo(() => (
    [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        ...checkboxColumnDefinition,
        type: "string",
        disableReorder: true,
        hideable: false,
        renderCell: (params: GridRenderCellParams) => {
          const index = internalApiRef?.current?.getRowIndexRelativeToVisibleRows(params.id) || 0;
          const newParams = { ...params, index, disableCheckbox };

          if (checkboxColumnDefinition?.renderCell) return checkboxColumnDefinition.renderCell(newParams);

          return (
            <GridCheckbox {...newParams} />
          );
        },
        renderHeader: (params: GridColumnHeaderParams) => {
          if (disableCheckbox) return "";

          return (
            <GridHeaderCheckbox {...params} />
          );
        },
        minWidth: 32,
        width: 32,
      },
      ...columnDefinition,
    ]
  ), [checkboxColumnDefinition, columnDefinition, disableCheckbox, internalApiRef]);

  const [settingsButtonRef, setSettingsButtonRef] = useState<ButtonRefType>();

  // Creating the initial state for the grid. Using a useState here it only needs to be done once.
  const [initialState] = useState<GridInitialState>(() => {
    // if we pass in a specific field order, then make sure the checkbox column is always first.
    const orderedFields = initialColumnOrder ? [...initialColumnOrder] : initialColumnOrder;
    if (orderedFields) {
      orderedFields.unshift(GRID_CHECKBOX_SELECTION_COL_DEF.field);
    }

    const initPinCols = {
      ...initialPinnedColumns,
      left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, ...(initialPinnedColumns?.left || [])],
    };

    return {
      columns: { columnVisibilityModel: initialColumnVisibilityModel, orderedFields },
      pinnedColumns: initPinCols,
      filter: { filterModel: filters },
    };
  });

  // If the pinned columns are set, name sure that the checkbox column is always
  // the left most pinned column.
  const updatedPinnedColumns = useMemo(() => {
    if (!pinnedColumns) return pinnedColumns;

    return {
      ...pinnedColumns,
      left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, ...(pinnedColumns?.left || [])],
    };
  }, [pinnedColumns]);

  const getRowClassName = useCallback((params: GridRowClassNameParams) => {
    const isRowSelected = internalApiRef?.current?.isRowSelected(params.id);
    const rowClass = params.indexRelativeToCurrentPage % 2 ? "odd" : "even";
    return isRowSelected ? `${rowClass} ${selectedRowClassName}` : `${rowClass} ${deselectedRowClassName}`;
  }, [deselectedRowClassName, internalApiRef, selectedRowClassName]);

  const components = useMemo(() => ({
    BaseCheckbox: DuroCheckbox,
    BaseSwitch: DuroCheckbox,
    ColumnSelectorIcon: SettingsOutlinedIcon,
    ColumnSortedAscendingIcon: ArrowDropUpIcon,
    ColumnSortedDescendingIcon: ArrowDropDownIcon,
    FilterPanel: TableFilterPanel,
    Footer: customFooter,
    LoadingOverlay: loadingOverlay ?? LinearProgress,
    NoRowsOverlay: noRowsOverlay,
    Toolbar: GridToolbar,
    TreeDataCollapseIcon: TreeCollapseIcon,
    TreeDataExpandIcon: TreeExpandIcon,
  }), [customFooter, loadingOverlay, noRowsOverlay]);
  const localeText = useMemo(() => ({
    toolbarColumns: "Settings",
    toolbarFilters: "Filter",
  }), []);

  const componentsProps = useMemo(() => ({
    columnsPanel: {
      sx: ColumnsPanelStyle,
    },
    noRowsOverlay: noRowsOverlayProps,
    panel: {
      anchorEl: settingsButtonRef,
      disablePortal: true,
      placement: "bottom-start",
    },
    toolbar: {
      filters,
      internalApiRef,
      items: toolbarItems,
      setSettingsButtonRef,
      totalCount,
    },
  }), [
    filters,
    internalApiRef,
    noRowsOverlayProps,
    settingsButtonRef,
    toolbarItems,
    totalCount,
  ]);

  const testId = useDataTestId(GRID_TEST_ID, name);

  return (
    <ImageContextProvider>
      <GridWrapper data-testid={testId}>
        <StyledBaseGrid
          {...rest}
          apiRef={internalApiRef}
          autoPageSize
          checkboxSelection
          columns={columns}
          pinnedColumns={updatedPinnedColumns}
          components={components}
          componentsProps={componentsProps}
          disableColumnFilter={!filtersEnabled}
          disableColumnMenu
          disableIgnoreModificationsIfProcessingProps
          disableSelectionOnClick
          experimentalFeatures={experimentalFeatures}
          getRowClassName={getRowClassName}
          getTreeDataPath={getTreeDataPath}
          groupingColDef={treeColumnDefinition}
          headerHeight={ROW_HEIGHT}
          hideFooter={!customFooter}
          initialState={initialState}
          localeText={localeText}
          onColumnOrderChange={onColumnsReordered}
          onColumnWidthChange={onColumnsResized}
          onFilterModelChange={onFiltersChange}
          onRowsScrollEnd={onRowScrollEnd}
          onSelectionModelChange={onSelectionChange}
          onSortModelChange={onSortChange}
          rowHeight={ROW_HEIGHT}
          rows={data}
          sortingMode={sortMode}
          sortingOrder={GRID_SORT_ORDER}
        />
      </GridWrapper>
    </ImageContextProvider>
  );
}

const GridWrapper = styled(Box)(() => ({
  display: "flex",
  flex: 1,
}));

const LinearProgress = styled(MUILinearProgress)({
  zIndex: "2",
});
