import React from 'react'

const DotsIcon = () => {
  return (
    <svg width="20" height="4" viewBox="0 0 20 4" xmlns="http://www.w3.org/2000/svg">
      <circle cx="2" cy="2" r="2" />
      <circle cx="10" cy="2" r="2" />
      <circle cx="18" cy="2" r="2" />
    </svg>
  )
}

export default DotsIcon
