import                           "./index.css"
import React, { Component } from 'react';
import SpinnerIcon          from "../../../assets/icons/spinner-green.js"
import InlineIcon           from "../icon/inline-icon.js"
import LoadingIcon              from "../../../assets/icons/loading"

class Spinner extends Component
{
    constructor(props)
    {
        super(props)
    }

    render()
    {

        let markup = null
        let iconClassName = this.props.iconClassName ? this.props.iconClassName : ''
        markup =
                <div className={`spinner-wrapper ${iconClassName}`}>
                    <div className="spinner">
                    <InlineIcon
                      className={iconClassName}
                    >
                       <LoadingIcon />
                     </InlineIcon>
                    </div>
                </div>
        return markup
    }
}

export default Spinner
