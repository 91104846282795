import React from "react";

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='17'
      viewBox='0 0 30 32'
    >
      <path
        fill='#fff'
        d='M30.222 8.493L23.614 0H6.608L0 8.493v23.408h30.222V8.493zm-2.612-.569H15.982V1.761h6.813l4.815 6.163zM7.428 1.761h6.813v6.163H2.613l4.815-6.163zM1.69 30.14V9.632h26.841V30.14H1.69zm14.292-10.823h4.354v1.709h-4.354v4.402h-1.69v-4.402H9.938v-1.709h4.354v-4.402h1.69v4.402z'
      ></path>
    </svg>
  );
}

export default Icon;
