import { Box, Icon, Tooltip, Typography, styled } from "@mui/material";
import { NotesIcon } from "assets/icons";
import { colorPalette } from "theme";

export interface NotesFieldProps {
  notes: string;
}

export function NotesField({ notes }: NotesFieldProps) {
  return (
    <NotesContainer>
      <NotesText>{notes}</NotesText>
      {notes && (
        <Tooltip title={notes}>
          <StyledIcon><NotesIcon /></StyledIcon>
        </Tooltip>
      )}
    </NotesContainer>
  );
}

const NotesContainer = styled(Box)({
  display: "flex",
  flex: 1,
  flexDirection: "row",
  justifyContent: "space-between",
  overflow: "hidden",
});

const NotesText = styled(Typography)({
  flex: 1,
  fontSize: "0.875rem",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});

const StyledIcon = styled(Icon)({
  color: colorPalette.lightGrey,
  fontSize: "1rem",
  "& > svg": {
    fontSize: "1rem",
  },
});
