import React from "react";

function Icon() {
  return (
    <svg width="12px" height="15px" viewBox="0 0 12 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="3-MANAGE-CONNECTIONS-FULL" transform="translate(-1131.000000, -93.000000)" fill="#FFFFFF" fillRule="nonzero">
              <g id="Manage-Connections" transform="translate(1121.000000, 85.000000)">
                  <g id="import_export-24px" transform="translate(10.000000, 8.000000)">
                      <path d="M3.42857143,0 L0,3.325 L2.57142857,3.325 L2.57142857,9.16666667 L4.28571429,9.16666667 L4.28571429,3.325 L6.85714286,3.325 L3.42857143,0 Z M9.42857143,11.675 L9.42857143,5.83333333 L7.71428571,5.83333333 L7.71428571,11.675 L5.14285714,11.675 L8.57142857,15 L12,11.675 L9.42857143,11.675 Z" id="Shape"></path>
                  </g>
              </g>
          </g>
      </g>
    </svg>
  );
}

export default Icon;
