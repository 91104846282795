import React from "react";
import            "./index.css"

const RevisionManaged = (props) =>
{
    let { tooltipText, revisionManaged, onChange, vendor } = props;
    let disableClass = vendor !== "duro" ? (revisionManaged ? "cross-checked gray-checkbox" : "gray-checkbox") : "";
    return (
        <div className="inner-info values-to-right diff-mass-section">
            <span className="inner-attribute specs" htmlFor="Revision Managed">
                Revision Managed
            </span>
            <div className="inner-value"
                data-tip={tooltipText} data-for="vendor-disable-input"
            >
                <div className="custom-checkbox revision-managed">
                    <input
                    type="checkbox"
                    value={revisionManaged}
                    checked={revisionManaged}
                    name="revisionManaged"
                    className="large"
                    onChange={onChange}
                    />
                    <label htmlFor="Check Box" className={disableClass} />
                </div>
            </div>
        </div>
    )
}

export default RevisionManaged;
