import { FormControlLabel, FormGroup, Switch as SwitchButton, Typography, styled } from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";

export interface ILabelStyle {
  alignItems?: string,
  color?: string,
  display?: string,
  fontSize?: string,
  width?: string,
}

export interface ISwitchProps {
  component?: any,
  defaultValue?: boolean,
  disabled?: boolean,
  label?: string,
  labelPosition?: "bottom" | "top" | "end" | "start",
  labelStyle?: ILabelStyle
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  sx?: {
    marginLeft?: string
  },
}

export const Switch: FC<ISwitchProps> = (({
  component,
  defaultValue,
  disabled,
  label,
  labelPosition,
  labelStyle,
  onChange,
  sx,
}) => {
  const [checked, setChecked] = useState(defaultValue ?? false);

  const toggleChecked = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(!checked);
    onChange(e);
  }, [checked, onChange, setChecked]);

  const switchLabel = useMemo(() => (
    <LabelStyling variant="subtitle1" sx={labelStyle}>
      {label ?? ""}
      {component ?? null}
    </LabelStyling>
  ), [component, label, labelStyle]);

  return (
    <CustomFormGroup sx={sx}>
      <CustomFormLabel
        control={<StyledButton disabled={disabled} checked={checked} onChange={toggleChecked} size="small" />}
        label={switchLabel}
        labelPlacement={labelPosition}
      />
    </CustomFormGroup>
  );
});

const CustomFormGroup = styled(FormGroup, {
  shouldForwardProp: prop => prop !== "sx",
})<Pick<ISwitchProps, "sx">>(({ sx }) => sx as any);

const CustomFormLabel = styled(FormControlLabel)({
  fontSize: "0.875rem",
  margin: "0",
  pointerEvents: "none",
});

export const LabelStyling = styled(Typography)<ILabelStyle>(({ sx }) => ({
  ...sx,
}));

const StyledButton = styled(SwitchButton)({
  pointerEvents: "auto",
});
