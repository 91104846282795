import Link                                 from "../../../../ui/link";
import Utils                                from "../../../../../modules/utils";
import deleteSrc                            from "../../../../../assets/icons/cross-icon.svg";
import ExtendedTable                        from "../../../common/extended-table";
import validations, { validateField }       from "../../../../../modules/validations";
import React, {Component}                   from "react";
import EditIcon                              from "../../../../../assets/icons/edit";
import Permissions                          from "../../../../../modules/schemas/permissions";
import InlineIcon                           from "../../../../ui/icon/inline-icon.js";
import AvatarIcon                           from "../../../../../assets/icons/user-icon.js";
import EmailResendIcon                      from "../../../../../assets/icons/resend-icon.js";
import EmailsentIcon                        from "../../../../../assets/icons/sent-icon.js";
import DeleteSrc                            from "../../../../../assets/icons/cross-icon.js";
import Avatar                               from "../../../../ui/avatar";
import COApi                                from "../../../../../modules/api/cos";

class CoUsersApproverList extends Component
{
    constructor(props)
    {
        super(props)
        let viewStyles;
        try
        {
            viewStyles = window.__userStyles.styles.changeOrderApproverListTable || {}
        }
        catch(error)
        {
            viewStyles = {}
        }
        this.state =
        {
            coApproverList : this.props.coApproverList || [],
            syncWithParentState: false,
            current : "users",
            currentSortItemAscending: true,
            headings :
            [
                {
                    key         : "users",
                    displayName : "USER",
                    tooltip     : "USER",
                    sortable    : true,
                    minWidth    : 60,
                    width       : Utils.getStyleValue(viewStyles, "users", "width", 198),
                    position    : Utils.getStyleValue(viewStyles, "users", "position", 0),
                    visibility  : Utils.getStyleValue(viewStyles, "users", "visibility", true),
                    headingClass: "user-col",
                    disabled    : true
                },
                {
                    key         : "emails",
                    displayName : "EMAIL",
                    tooltip     : "EMAIL",
                    sortable    : true,
                    minWidth    : 60,
                    width       : Utils.getStyleValue(viewStyles, "emails", "width", 198),
                    position    : Utils.getStyleValue(viewStyles, "emails", "position", 0),
                    visibility  : Utils.getStyleValue(viewStyles, "emails", "visibility", true),
                    headingClass: "user-col",
                    disabled    : true
                },
                {
                    key         : "groups",
                    displayName : "GROUPS",
                    tooltip     : "GROUPS",
                    sortable    : true,
                    minWidth    : 60,
                    width       : Utils.getStyleValue(viewStyles, "groups", "width", 265),
                    position    : Utils.getStyleValue(viewStyles, "groups", "position", 0),
                    visibility  : Utils.getStyleValue(viewStyles, "groups", "visibility", true),
                    disabled    : true
                }
            ]
        }

        if (this.props.mode === "view")
        {
            let decisionColHeading =
            {
                key         : "decision",
                displayName : "DECISION",
                tooltip     : "DECISION",
                sortable    : true,
                minWidth    : 60,
                width       : Utils.getStyleValue(viewStyles, "decision", "width", 140, {onErrorDefault: true}),
                position    : Utils.getStyleValue(viewStyles, "decision", "position", 0, {onErrorDefault: true}),
                visibility  : Utils.getStyleValue(viewStyles, "decision", "visibility", true, {onErrorDefault: true}),
                disabled    : true
            }
            this.state.headings.push(decisionColHeading)
        }

        if (this.props.mode !== "view")
        {
            let removeColHeading =
            {
                  key              : "remove",
                  displayName      : "",
                  sortable         : false,
                  minWidth         : 30,
                  width            : 30,
                  visibility       : true,
                  headingClass     : "remove-col",
                  disabled         : true,
                  dragable         : false,
                  expandAble       : false,
                  hideFromSettings : true
            }
            this.state.headings.push(removeColHeading)
        }
        this.getApproverTableRows       = this.getApproverTableRows.bind(this);
        this.removerApprover            = this.removerApprover.bind(this);
        this.onChange                   = this.onChange.bind(this);
        this.resetSyncWithParentState   = this.resetSyncWithParentState.bind(this);
        this.resendCoReviewNotification = this.resendCoReviewNotification.bind(this);
        this.areApproversChanged        = this.areApproversChanged.bind(this);
    }

    resendCoReviewNotification(id, approverEmail, approverId)
    {
        COApi.resendCoReviewNotification(id, {approverEmail, approverId}, (err, res) =>
        {
            if (res)
            {
                this.props.getChangeOrderFromApi(id, true);
            }
        });
    }

    areApproversChanged(nextProps)
    {
        let { coApproverList } = this.state;
        for(let approver of nextProps.coApproverList)
        {
            let exists = coApproverList.find((approv) => approv._id === approver._id && approv.irremovable === approver.irremovable)
            if(!exists) return true;
        }
    }

    componentWillReceiveProps(nextProps)
    {
        let state = this.state
        if(this.areApproversChanged(nextProps))
        {
            state.coApproverList = nextProps.coApproverList
            state.syncWithParentState = true;
            this.setState(state, this.onChange)
        }
    }

    resetSyncWithParentState()
    {
        let state = this.state
        state.syncWithParentState = false
        this.setState(state)
    }

    removerApprover(e, itemId)
    {
        e.stopPropagation();
        e.preventDefault();
        let coApproverList = this.state.coApproverList
        let deletedItemIndex;
        let deletedItem;
        coApproverList.forEach((item, i) => {
            if (itemId === item._id)
            {
                deletedItem     = item
                deletedItemIndex = i
            }
        })

        coApproverList.splice(deletedItemIndex, 1)
        this.props.enableSaveButton(true)
        this.setState(this.state, this.onChange)
    }

    onChange()
    {
        this.props.onRemoveApprover(this.state.coApproverList)
    }

    getDecisionColumnHtml(co, user)
    {
        let markup = null
        if (!user.decision && co.resolution === "UNRESOLVED" && co.status === "OPEN")
        {
            markup =
            Utils.isExpired(user.invitedAt, 24) ?
            <div
                className="resend-email"
                onClick={(e) => this.resendCoReviewNotification(co._id, user.email, user._id)}
                data-tip="Resend approval request"
                >
                <InlineIcon>
                    <EmailResendIcon/>
                </InlineIcon>
                <span className="resend-email-text">RESEND</span>
            </div>
            : !Utils.isExpired(user.invitedAt, 1) ?
            <div
                className="sent-email"
                data-tip="Approval request resent"
            >
                <InlineIcon>
                    <EmailsentIcon/>
                </InlineIcon>
                <span className="resend-email-text">SENT</span>
            </div>
            : null
        }
        else
        {
            markup =
            <span className={"resolution-label " + user.decision}>
                {user.decision}
            </span>
        }
        return markup;
    }

    getApproverTableRows()
    {
        let { coApproverList, co } = this.props
        for(let approver of coApproverList) {
            if(approver._id === co.creator) {
                approver.irremovable = true;
                break;
            }
        }
        let _this = this
        let rows = coApproverList.map((user, i) =>
            {
                let cells =
                {
                    "users":
                    {
                        value           : user.firstName + " " + user.lastName,
                        tooltip         : user.firstName + " " + user.lastName,
                        cellClass       : "user-info-col",
                        displayValue : [
                        <Avatar
                            imageId={user.avatar}
                            index={i}
                            imagesWithSrc={this.props.imagesWithSrc}
                            key={Utils.generateUniqueId()}
                            />
                            ,
                            <span
                                className="user-name"
                                data-tip=""
                                key={i + '-name'}
                                onMouseOver={(e) => Utils.checkToolTipAppearance(e, user.firstName + " " + user.lastName)}
                            >{user.firstName + " " + user.lastName}</span>
                        ]

                    },
                    "emails":
                    {
                        value           : user.email,
                        displayValue    : user.email,
                        tooltip         : user.email
                    },
                    "groups":
                    {
                        value           : user.groups.join(', '),
                        displayValue    : user.groups.join(', '),
                        tooltip         : user.groups.join(', ')
                    },
                }

                if (this.props.mode === "view")
                {
                    let decisionColValue =
                    {
                        value        : user.decision,
                        cellClass    : "co-decision-holder",
                        displayValue : this.getDecisionColumnHtml(co, user)
                    }
                    cells.decision = decisionColValue
                }

                if (this.props.mode !== "view")
                {
                    let removColValue =
                    {
                        value        : "",
                        cellClass    : "remove-col",
                        displayValue :
                                !user.irremovable ?
                                <div onClick={(e, i) => _this.removerApprover(e, user._id)}>
                                    <InlineIcon
                                        tooltip = "Remove from Change Order"
                                        tooltipPlace = "right"
                                        >
                                        <DeleteSrc/>
                                    </InlineIcon>
                                </div> : null
                    }
                    cells.remove = removColValue
                }
                return cells
            })
        return rows

    }

    render()
    {
        let {current, currentSortItemAscending, syncWithParentState} = this.state;
        let {co, mode, user} = this.props;
        let headings = this.state.headings
        let approverRows = this.getApproverTableRows()
        let markup  =
            <ExtendedTable
                wrapperClassName={"co-approver-list-table"}
                wrapperSelectorClass={"co-list"}
                themeDataTableId={"co-approver-list-table"}
                headings={headings}
                rows={approverRows}
                endStaticColumns={mode !== "view" ? 1 : 0}
                stylesName="changeOrderApproverListTable"
                currentSortItem={current}
                currentSortItemAscending={currentSortItemAscending}
                resultText={`${approverRows.length} Approvers`}
                syncWithParentState={syncWithParentState}
                afterSyncWithParentState={()=> {}}
                includeToolBar={true}
                footerRow={ mode === "view" ?
                    co.status === 'DRAFT' && Permissions.can("edit", "change_order", user.data) ? {
                            bordered: true,
                            dataCellEl: <p>Edit to add</p>,
                            indexCellEl:<div className="add-more-actions">
                                            <Link to={"/changeorder/edit/" + co._id}>
                                                <button className='add-trigger'
                                                        data-tip='Edit'
                                                    >
                                                    <InlineIcon>
                                                      <EditIcon />
                                                    </InlineIcon>
                                                </button>
                                            </Link>
                                         </div>
                        }
                        :
                        approverRows.length === 0 &&
                        {
                            dataCellEl: <p>No User added</p>
                        }
                    :
                    approverRows.length === 0 && {
                        dataCellEl: <p>No User added</p>
                    }
                }
            />
        return markup
    }
}

export default CoUsersApproverList
