import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-611 -209)">
          <g transform="translate(429 44)">
            <g transform="translate(50 163)">
              <g transform="translate(112.25)">
                <g transform="translate(17)">
                  <g>
                    <path d="M0 0L25 0 25 25 0 25z"></path>
                    <path
                      className='path'
                      fill="#CFCFCF"
                      fillRule="nonzero"
                      stroke="#2A2A36"
                      strokeWidth="0.5"
                      d="M15.667 9.333H9.333v6.334h6.334V9.333zm-2.111 4.223h-2.112v-2.112h2.112v2.112zM22 11.444v-2.11h-2.111V7.221c0-1.16-.95-2.11-2.111-2.11h-2.111V3h-2.111v2.111h-2.112V3h-2.11v2.111H7.221c-1.16 0-2.11.95-2.11 2.111v2.111H3v2.111h2.111v2.112H3v2.11h2.111v2.112c0 1.16.95 2.11 2.111 2.11h2.111V22h2.111v-2.111h2.112V22h2.11v-2.111h2.112c1.16 0 2.11-.95 2.11-2.111v-2.111H22v-2.111h-2.111v-2.112H22zm-4.222 6.334H7.222V7.222h10.556v10.556z"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
