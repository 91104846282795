import React, { Component }         from "react"
import { Popover, ArrowContainer } from 'react-tiny-popover'

export default class PopoverBox extends Component
{
    render()
    {
        let markup =
            <Popover
                padding={this.props.padding}
                containerClassName={this.props.className}
                isOpen={true}
                position={this.props.position}
                onClickOutside={this.props.hideFeaturePopOver ? this.props.hideFeaturePopOver : ''}
                content={({ position, childRect, popoverRect }) => (
                    <ArrowContainer
                        position={position}
                        childRect={childRect}
                        popoverRect={popoverRect}
                        arrowColor={this.props.arrowColor || "#363640"}
                        arrowSize={this.props.arrowSize || 10}
                        arrowStyle={this.arrowStyle || { opacity: 1 }}
                    >
                    <div className="popover-content-block">
                        {this.props.contentData}
                    </div>
                    </ArrowContainer>
                )}
            >
            <div></div>
            </Popover>

        return markup
    }
}
