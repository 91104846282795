export function getInitialState()
{
  return {
    loading : true,
    lean    : false,
    focused : false,
    value   : "",
    type    : "",
    results : [],
    count   : 0,
    page    : 1,
    query   :
    {
        string : "",
        object : ""
    },
    sort :
    {
        id : 1
    },
    selectedSearchResults: []
  }
}

let initialState = getInitialState()
export default initialState
