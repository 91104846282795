import mapActions from '../helpers/mapActions';

export default mapActions(
  [
   "UPDATE_NEW_FORM_INPUT_STATE",
   "UPDATE_NEW_PRODUCT_FORM_FIELDS",
   "DISABLE_FORM",
   "ADD_NEW_FORECAST",
   "REMOVE_FORECAST",
   "SUBMIT_PRODUCT_NEW_FORM",
   "RESET_PRODUCT_NEW_FORM",
   "GET_PRODUCT_AND_SET_IN_EDIT_PAGE",
   "SET_PRODUCT_IN_EDIT_FORM",
   "UPDATE_EDIT_FORM_INPUT_STATE",
   "UPDATE_EDIT_PRODUCT_FORM_FIELDS",
   "SEARCH_COMPONENTS_IN_EDIT_PAGE",
   "SET_EDIT_PAGE_COMPONENT_SEARCH_RESULTS",
   "SET_EDIT_PAGE_MODIFIED",
   "ADD_NEW_FORECAST_EDIT_PAGE",
   "REMOVE_FORECAST_EDIT_PAGE",
   "SUBMIT_PRODUCT_EDIT_FORM",
   "RESET_PRODUCT_EDIT_FORM",
   "RESET_STATES_IN_VIEW_PAGE",
   "GET_PRODUCT_IN_VIEW_PAGE",
   "SET_PRODUCT_IN_VIEW_PAGE",
   "GET_PRODUCT_NESTED_DATA",
   "SET_PRODUCT_NESTED_DOCUMENTS",
   "SET_PRODUCT_DOCUMENTS_LOADED",
   "SET_PRODUCT_FAV_IN_VIEW_PAGE",
   "DUPLICATE",
   "REVERT_CHANGES",
   "TOGGLE_FAVORITE",
   "RESET_STATES_IN_REVISION_PAGE",
   "RESET_PRODUCT_EDIT_FORM_INPUTS",
   "GET_REVISION_IN_REVISION_PAGE",
   "SET_REVISION_IN_REVISION_PAGE",
   "GET_REVISION_NESTED_DATA",
   "SET_REVISION_NESTED_CHILDREN",
   "SET_REVISION_NESTED_DOCUMENTS",
   "SET_REVISION_CHILDREN_LOADED",
   "SET_REVISION_DOCUMENTS_LOADED",
   "DELETE_PRODUCT",
   "SET_IS_NEW_OBJECT_FLAG",
   "SET_ORIGNAL_PRODUCT_ID",
   "UPLOAD_DOCUMENT",
   "UPDATE_DOCUMENTS_STATE",
   "UPDATE_DOCUMENTS_STATE_ON_EDIT_PAGE",
   "UPDATE_IMAGES_STATE_ON_EDIT_PAGE",
   "UPLOAD_IMAGES_ON_EDIT_PAGE",
   "UPDATE_ASSEMBLY_ON_EDIT_PAGE",
   "NEW_FORM_NAME_CHANGE",
   "NEW_FORM_EID_CHANGE",
   "EDIT_FORM_NAME_CHANGE",
   "EDIT_FORM_EID_CHANGE",
   "EDIT_FORM_CPN_CHANGE",
   "SET_PRIMARY_SOURCE",
   "CREATE_VARIANT",
   "SET_NON_DEFAULT_FORECAST",
  ],
  "PRODUCT"
)
