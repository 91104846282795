import { ApolloError, FetchPolicy, gql, useLazyQuery } from "@apollo/client";
import { GridSortDirection } from "@mui/x-data-grid-pro";
import { Lot } from "build/models";
import { LotSearchFragment } from "graphql/fragment/lotFragment";
import { useCallback, useMemo } from "react";

export const GET_LOTS_SEARCH = gql`
  query getLotsSearch($endCursor: String, $input: BuildQueryLotInput, $pageSize: Int) {
    build {
      lots(after: $endCursor, first: $pageSize, input: $input) {
        edges {
          node {
            ...lotSearchFragment
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
        totalCount
      }
    }
  }
  ${LotSearchFragment}
`;

export interface LotListPrimaryRevisionOrderBy {
  cpn?: GridSortDirection;
  name?: GridSortDirection;
  revisionValue?: GridSortDirection;
}

export interface LotListOrderBy {
  createdDate?: GridSortDirection;
  dueDate?: GridSortDirection;
  labels?: GridSortDirection;
  notes?: GridSortDirection;
  numberDisplayValue?: GridSortDirection;
  primaryRevision?: LotListPrimaryRevisionOrderBy;
  productionRun?: GridSortDirection;
}

export interface LotListInput {
  orderBy?: LotListOrderBy[];
}

export interface LotListArgs {
  fetchPolicy?: FetchPolicy;
  pageSize?: number;
  input?: LotListInput;
}

interface LotsQueryData {
  build?: {
    lots?: {
      edges: { node: Lot }[];
      pageInfo: {
        hasNextPage: boolean;
        endCursor?: string;
      };
      totalCount: number;
    }
  }
}

export interface LotListFetchRes {
  endCursor?: string,
  error?: ApolloError,
  hasNextPage?: boolean,
  lots: Lot[],
  totalCount: number,
}

export interface LotListRes extends LotListFetchRes {
  endCursor?: string,
  error?: ApolloError,
  fetchLots: (endCursor?: string, input?: LotListInput) => Promise<LotListFetchRes>,
  hasNextPage?: boolean,
  loading?: boolean,
  lots: Lot[],
  totalCount: number,
}

/**
 * Loads up all the lots.
 *
 * @param fetchPolicy How to fetch the data from the API.
 * @returns The lots when loaded, errors, and the loading state.
 */
export function useLotList(args: LotListArgs): LotListRes {
  const {
    fetchPolicy = "network-only",
    pageSize = 30,
  } = args;

  const [loadLots,
    {
      data,
      error,
      loading,
    },
  ] = useLazyQuery<LotsQueryData>(GET_LOTS_SEARCH, { fetchPolicy });
  const { edges, totalCount = 0, pageInfo } = data?.build?.lots ?? {};

  const fetchLots = useCallback(async (endCursor?: string, input?: LotListInput): Promise<LotListFetchRes> => {
    const res = await loadLots({
      variables: {
        endCursor,
        input,
        pageSize,
      },
    });

    return {
      endCursor: res.data?.build?.lots?.pageInfo.endCursor,
      error: res.error,
      hasNextPage: res.data?.build?.lots?.pageInfo.hasNextPage,
      lots: res.data?.build?.lots?.edges.map(({ node }) => node) ?? [],
      totalCount: res.data?.build?.lots?.totalCount ?? 0,
    };
  }, [pageSize, loadLots]);

  const lots: Lot[] = useMemo(() => {
    if (!edges) return [];
    return edges.map(({ node }) => node, [edges]);
  }, [edges]);

  return {
    lots,
    endCursor: pageInfo?.endCursor,
    error,
    fetchLots,
    hasNextPage: pageInfo?.hasNextPage,
    loading,
    totalCount,
  };
}
