import React, {Component}   from "react";
import { connect }          from "react-redux";
import ITEM_DIFF            from "../../../../action-types/item-diff";
import buildAction          from "../../../../helpers/buildAction";
import Utils                from "../../../../modules/utils";
import Loading              from "../../../ui/inner-loading";
import {Helmet}             from "react-helmet";
import DiffTool from "../../common/diff-tool";

export class DiffComponent extends Component
{
    constructor(props, context)
    {
        super(props, context);
        this.closeCompareRevisions = this.closeCompareRevisions.bind(this);
        this.compareRevisions = this.compareRevisions.bind(this);
        this.saveRevList = this.saveRevList.bind(this);
        this.state = {
            revList: []
        }
    }

    saveRevList(list)
    {
        let state = this.state
        state.revList = list
        this.setState(state)
    }

    componentWillMount()
    {
        const {dispatch} = this.props;
        let id = this.props.match.params.id;
        let urlParams = new URLSearchParams(window.location.search);
        let sourceRevId  = urlParams.has('sourceRevId') ? urlParams.get('sourceRevId') : null;
        let targetRevId  = urlParams.has('targetRevId') ? urlParams.get('targetRevId') : null;
        if (id && sourceRevId && targetRevId)
        {
            let payload = {id, sourceRevId, targetRevId, alias: "cmp"};
            dispatch(buildAction(ITEM_DIFF.GET_ITEM_AND_SOURCE_AND_TARGET_REVISIONS, payload));
        }
        else
        {
            this.props.history.push({pathname: `/component/view/${id}`});
        }
    }

    compareRevisions()
    {
        const {dispatch} = this.props;
        dispatch(buildAction(ITEM_DIFF.RESET_DIFF_STATE));
        let id = this.props.match.params.id;
        let urlParams = new URLSearchParams(window.location.search);
        let sourceRevId  = urlParams.has('sourceRevId') ? urlParams.get('sourceRevId') : null;
        let targetRevId  = urlParams.has('targetRevId') ? urlParams.get('targetRevId') : null;
        let payload = {id, sourceRevId, targetRevId, alias: "cmp"};
        dispatch(buildAction(ITEM_DIFF.GET_ITEM_AND_SOURCE_AND_TARGET_REVISIONS, payload));

        //TODO: Need to remove extra code from saga,reducer and action

        // dispatch(buildAction(ITEM_DIFF.CLEAR_TARGET_REV));
        // let urlParams = new URLSearchParams(window.location.search);
        // let targetRevId  = urlParams.has('targetRevId') ? urlParams.get('targetRevId') : null;
        // let payload = {targetRevId, alias: "cmp"};
        // dispatch(buildAction(ITEM_DIFF.GET_TARGET_REVISION, payload));
    }

    componentWillUnmount()
    {
        const {dispatch} = this.props;
        dispatch(buildAction(ITEM_DIFF.RESET_DIFF_STATE));
    }

    closeCompareRevisions()
    {
        let {item} = this.props.itemDiff
        this.props.history.push({pathname: `/component/view/${item._id}`});
    }

    render()
    {
        let {item, sourceRev, targetRev} = this.props.itemDiff;

        if (!item || !sourceRev || !targetRev){
            return (<Loading />);
        }

        let revision = sourceRev;
        let { company } = this.props;
        let user = this.props.user.data;
        let cpnVariant = "";
        if( revision && Utils.haveVariantScheme(company.data, user.currentEnv))
        {
            cpnVariant = `-${revision.cpnVariant}`;
        }

        let completeCPN = `${revision.cpn}${cpnVariant}`;
        return (
            <div>
                <Helmet>
                    <title>{Utils.makeTitle("["+item.cpn+"] "+item.name)}</title>
                </Helmet>

                <DiffTool revision={revision}
                    company={company}
                    alias="cmp"
                    component={item}
                    documents={revision.documents}
                    manufacturers={revision.manufacturers}
                    assemblyData={revision.children}
                    history={this.props.history}
                    getNestedData={this.getNestedData}
                    completeCPN={completeCPN}
                    closeCompareRevisions={this.closeCompareRevisions}
                    targetRev={targetRev}
                    compareRevisions={this.compareRevisions}
                    saveRevList={this.saveRevList}
                    revList={this.state.revList}
                    defaultCurrency={item.defaultCurrency}
                />
            </div>
            )
    }
}

export default connect((store) => ({
    user: store.user,
    company: store.company,
    itemDiff: store.itemDiff,
}))(DiffComponent);
