import React, {Component}   from "react"
import {connect}            from "react-redux"
import Export               from "../common/export"
import {Helmet}             from "react-helmet"
import Utils                from "../../../modules/utils"
import API                  from "../../../modules/api";

export class ExportRoute extends Component
{
    constructor(props, context)
    {
        super(props, context)

        this.state =
        {
            loading : false,

            collection :
            [

            ],

            search :
            {
                value                : "",
                selectAllButoonClazz : "",
                inputs               : [],
                selections           : [],
                selectAllFlag        : false,
                loading_flag         : true,
                count                : 0
            },

            page           : 1,

            column :
            {
                name      : "cpn",
                ascending : true
            },

            columns :
            [
                {
                    name     : "cpn",
                    class    : "sortable",
                    value    : "CPN",
                    sortable : true
                },
                {
                    name     : "name",
                    class    : "sortable",
                    value    : "Name",
                    sortable : true
                },
                {
                    name     : "revision",
                    class    : "sortable",
                    value    : "Revision",
                    sortable : true
                },
                {
                    name     : "status",
                    class    : "sortable",
                    value    : "Status",
                    sortable : true

                },
                {
                    value : ""
                }
            ],
            defaultTemplate: Utils.getDefaultExportTemplate(),
            areTemplatesLoaded: false
        }
    }

    componentDidUpdate()
    {
        if((window.__customFields && window.__customFields.wasteFieldEnabled) && !this.state.areTemplatesLoaded )
        {
            let defaultTemplate = Utils.getDefaultExportTemplate();
            this.setState({defaultTemplate, areTemplatesLoaded: true});
        }
    }

    render()
    {
         let currentUserEmail = this.props.user.data.email
         let markup =
         <div>
            <Helmet>
                <title>{Utils.makeTitle("Export")}</title>
            </Helmet>
             <Export dispatch={this.props.dispatch}
                            location={this.props.location}
                            alert={this.props.alert}
                            state={this.state}
                            currentUserEmail={currentUserEmail}
                            defaultTemplate={this.state.defaultTemplate}
                            user={this.props.user}
                            />
        </div>
         return markup
    }
}

export default connect((store) => store)(ExportRoute)
