import mapActions from '../helpers/mapActions';

export default mapActions(
  [
    'UPDATE_FORM_STATE',
    'SUBMIT_CHANGE_ORDER_FORM',
    'SET_CO_STATE',
    'UPDATE_INPUT_ON_CHANGE',
    'VALIDATE_CO_FORM',
    'DISABLE_FORM',
    'RESET_CO_FORM',
    'ADD_SEARCH_RESULTS',
    'ADD_SELECTED_INTO_LIST',
    'FIND_CO',
    'SET_CO_OBJECT',
    'SET_CO_STATE',
    'GET_PARENT_ASSEMBLIES',
    'GET_CHANGE_ORDER_OF_CHILDREN',
    'SET_CHANGE_ORDER_OF_CHILDREN',
    'GET_CHILDREN_ASSEMBLIES',
    'SET_PARENT_ASSEMBLIES',
    'GET_CHANGE_ORDER_LIST_DATA',
    'VALIDATE_CHANGE_ORDER_LIST',
    'RESET_RELOAD_EXTENDED_TABLE_VALUE',
    'SET_CO_FORM',
    'SET_LOADING',
    'UPDATE_CO_LIST',
    'REST_CO_VIEW_STATE',
    'CREATE_CO_WITH_DEFAULT_DATA',
    'DELETE_CO',
    'AUTO_SAVE_IN_DRAFT',
  ],
  'CHANGEORDER'
);
