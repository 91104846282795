import React, { Component }      from "react"

const SvgIcon = () =>
{
    return <svg width="15px" height="14px" viewBox="0 0 15 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <defs></defs>
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                    <g id="hover-area" transform="translate(-325.000000, -687.000000)" stroke="#FFFFFF" strokeWidth="1.25">
                        <g transform="translate(269.000000, 70.000000)">
                            <g transform="translate(57.000000, 618.000000)">
                                <path d="M6.3030303,0 L6.3030303,12.3528139"></path>
                                <path d="M12.4860029,6.5 L0.57027417,6.5"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
}

export default SvgIcon
