import {
  ComponentIcon, EditIcon, FileImportIcon, GridIcon, GridTreeIcon, ManualCreateIcon, RefreshIcon, VendorImportIcon,
} from "assets/icons";
import { ToolBarItem } from "common/components/grid";
import { COLLAPSE_TOOLBAR_ICON_PROPS, EXPAND_TOOLBAR_ICON_PROPS } from "common/components/grid/gridToolbar";
import { ToolbarItemType } from "common/constants";
import { MouseEventHandler, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import DisplaySidebarActions from "v1/action-types/display-sidebar";
import { AssemblyView } from "../constants";

export interface UseAssemblyToolbarArgs {
  assemblyView: AssemblyView;
  disableCollapse: boolean;
  disableExpand: boolean;
  id: string;
  isEditing: boolean;
  onCollapseClicked: MouseEventHandler;
  onExpandClicked: MouseEventHandler;
  reloadComponent: () => void;
  setAssemblyView: (view: AssemblyView) => void;
  setDisplayFileImport: (display: boolean) => void;
  setDisplayManualModal: (display: boolean) => void;
  setDisplayVendorModal: (display: boolean) => void;
}

export function useAssemblyToolbar(args: UseAssemblyToolbarArgs) {
  const {
    assemblyView,
    disableCollapse,
    disableExpand,
    id,
    isEditing,
    onCollapseClicked,
    onExpandClicked,
    reloadComponent,
    setAssemblyView,
    setDisplayFileImport,
    setDisplayManualModal,
    setDisplayVendorModal,
  } = args;

  const dispatch = useDispatch();
  const history = useHistory();

  const onEnterEdit = useCallback(() => {
    history.push(`/component/edit/${id}`);
  }, [history, id]);

  const showHideAssemblySearch = useCallback(
    () => dispatch({ type: DisplaySidebarActions.SET_LIBRARY_FLAG }),
    [dispatch],
  );

  return useMemo(() => {
    if (isEditing) {
      return [
        {
          label: "Add From:",
          type: ToolbarItemType.TEXT,
        },
        {
          Icon: ComponentIcon,
          label: "Components",
          onClick: showHideAssemblySearch,
          type: ToolbarItemType.ACTION,
        },
        {
          Icon: FileImportIcon,
          label: "File",
          onClick: () => setDisplayFileImport(true),
          type: ToolbarItemType.ACTION,
        },
        {
          Icon: VendorImportIcon,
          label: "Vendor",
          onClick: () => setDisplayVendorModal(true),
          type: ToolbarItemType.ACTION,
        },
        {
          Icon: ManualCreateIcon,
          label: "Manual",
          onClick: () => setDisplayManualModal(true),
          type: ToolbarItemType.ACTION,
        },
        {
          type: ToolbarItemType.DIVIDER,
        },
        {
          Icon: RefreshIcon,
          label: "Refresh",
          onClick: reloadComponent,
          type: ToolbarItemType.ACTION,
        },
      ];
    }

    const items: ToolBarItem[] = [
      {
        Icon: GridTreeIcon,
        disabled: assemblyView === AssemblyView.TREE,
        label: "Tree",
        onClick: () => setAssemblyView(AssemblyView.TREE),
        type: ToolbarItemType.ACTION,
      },
      {
        Icon: GridIcon,
        disabled: assemblyView === AssemblyView.GRID,
        label: "Grid",
        onClick: () => setAssemblyView(AssemblyView.GRID),
        type: ToolbarItemType.ACTION,
      },
      {
        type: ToolbarItemType.DIVIDER,
      },
    ];

    if (!isEditing && assemblyView === AssemblyView.TREE) {
      items.push(
        {
          ...EXPAND_TOOLBAR_ICON_PROPS,
          disabled: disableExpand,
          onClick: onExpandClicked,
        },
        {
          ...COLLAPSE_TOOLBAR_ICON_PROPS,
          disabled: disableCollapse,
          onClick: onCollapseClicked,
        },
      );
      items.push({ type: ToolbarItemType.DIVIDER });
    }

    items.push(
      {
        Icon: EditIcon,
        label: "Edit",
        onClick: onEnterEdit,
        type: ToolbarItemType.ACTION,
      },
      {
        type: ToolbarItemType.DIVIDER,
      },
      {
        Icon: RefreshIcon,
        label: "Refresh",
        onClick: reloadComponent,
        type: ToolbarItemType.ACTION,
      },
    );

    return items;
  }, [
    assemblyView,
    disableCollapse,
    disableExpand,
    isEditing,
    onCollapseClicked,
    onEnterEdit,
    onExpandClicked,
    reloadComponent,
    setAssemblyView,
    setDisplayFileImport,
    setDisplayManualModal,
    setDisplayVendorModal,
    showHideAssemblySearch,
  ]);
}
