import React, {Component} from "react";
import                    "./index.css";
import $                  from "jquery";
import PerfectScrollbar   from 'react-perfect-scrollbar';
import Spinner            from "../../../../../ui/spinner";
import API                from "../../../../../../modules/api";
import Utils              from "../../../../../../modules/utils";
import Config             from "../../../../../../modules/config";
import ExtendedTable      from "../../../../common/extended-table";
import TimeStampColumn    from "../../../../common/timestamp-column";
import InlineIcon         from "../../../../../ui/icon/inline-icon.js";
import ArrowIcon          from "../../../../../../assets/icons/arrow-icon";
import InlineImageViewer  from "../../../../common/inline-image-viewer/view";
import UpdateStatusIcon   from "../../../../../../assets/icons/update-status-icon";
import HeaderBanner       from "../../../../common/header-banner";
import WarningModal       from "../../../../../ui/warning-modal";
import Link               from "../../../../../ui/link";
import LinkIcon           from "../../../../../../assets/icons/open-link-icon.js";
import InfoIcon           from "../../../../../../assets/icons/bulk-update-info-icon.js";
import Tooltip            from 'rc-tooltip';



export default class ComponentView extends Component
{
    constructor(props)
    {
        super(props);
        let viewStyles;
        try
        {
            viewStyles = window.__userStyles.styles.valispaceComponentView || {}
        }
        catch(error) {
            viewStyles = {}
        }
        this.state =
        {
            headings :
            [
                {
                    key         : "name",
                    displayName : "Name",
                    tooltip     : "Name",
                    sortable    : false,
                    minWidth    : 250,
                    width       : Utils.getStyleValue(viewStyles, "name", "width", 302),
                    position    : Utils.getStyleValue(viewStyles, "name", "position", 0),
                    visibility  : Utils.getStyleValue(viewStyles, "name", "visibility", true),
                    disabled    : true,
                    dragable    : false
                },
                {
                    key         : "image",
                    displayName : "Image",
                    tooltip     : "Image",
                    sortable    : true,
                    minWidth    : 90,
                    width       : Utils.getStyleValue(viewStyles, "image", "width", 90),
                    position    : Utils.getStyleValue(viewStyles, "image", "position", 1),
                    visibility  : Utils.getStyleValue(viewStyles, "image", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "description",
                    displayName : "Description",
                    tooltip     : "Description",
                    sortable    : true,
                    minWidth    : 124,
                    width       : Utils.getStyleValue(viewStyles, "description", "width", 396),
                    position    : Utils.getStyleValue(viewStyles, "description", "position", 2),
                    visibility  : Utils.getStyleValue(viewStyles, "description", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "creator",
                    displayName : "Creator",
                    tooltip     : "Creator",
                    sortable    : true,
                    minWidth    : 130,
                    width       : Utils.getStyleValue(viewStyles, "creator", "width", 233),
                    position    : Utils.getStyleValue(viewStyles, "creator", "position", 3),
                    visibility  : Utils.getStyleValue(viewStyles, "creator", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "documents",
                    displayName : "Documents",
                    tooltip     : "Documents",
                    sortable    : true,
                    minWidth    : 130,
                    width       : Utils.getStyleValue(viewStyles, "documents", "width", 233),
                    position    : Utils.getStyleValue(viewStyles, "documents", "position", 4),
                    visibility  : Utils.getStyleValue(viewStyles, "documents", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "lastModified",
                    displayName : "Last Modified",
                    tooltip     : "Last Modified",
                    sortable    : true,
                    minWidth    : 200,
                    width       : Utils.getStyleValue(viewStyles, "lastModified", "width", 295),
                    position    : Utils.getStyleValue(viewStyles, "lastModified", "position", 5),
                    visibility  : Utils.getStyleValue(viewStyles, "lastModified", "visibility", true),
                    disabled    : false
                }
            ],
            components        : [],
            loading           : true,
            selectedResults   : this.props.selectedResults ? this.props.selectedResults : {},
            clearSelectedRows : false,
            reviewImport      : false,
            parentChildrenIds : {},
            alreadySelectedComponents : [],
            showWarningModal : false
        }
        this.Refresh     = this.Refresh.bind(this);
        this.setCells    = this.setCells.bind(this);
        this.getChilds   = this.getChilds.bind(this);
        this.getRowData  = this.getRowData.bind(this);
        this.backButton  = this.backButton.bind(this);
        this.onRowSelect = this.onRowSelect.bind(this);
        this.getSelectors = this.getSelectors.bind(this);
        this.reviewImport = this.reviewImport.bind(this);
        this.onSelectParent = this.onSelectParent.bind(this);
        this.onSelectAllRows  = this.onSelectAllRows.bind(this);
        this.addParentsToList = this.addParentsToList.bind(this);
        this.onUnselectParent = this.onUnselectParent.bind(this);
        this.getChildAssembly = this.getChildAssembly.bind(this);
        this.getValispaceData = this.getValispaceData.bind(this);
        this.getIconsActionsList = this.getIconsActionsList.bind(this);
        this.getSelectedComponents = this.getSelectedComponents.bind(this);
        this.removeParentsFromList = this.removeParentsFromList.bind(this);
        this.onChangeParentCheckbox = this.onChangeParentCheckbox.bind(this);
        this.setParentCheckboxState = this.setParentCheckboxState.bind(this);
        this.createParentChildrenHash = this.createParentChildrenHash.bind(this);
        this.onRejectMapping          = this.onRejectMapping.bind(this);
        this.onConfirmMapping         = this.onConfirmMapping.bind(this);
        this.toggleWarningModal       = this.toggleWarningModal.bind(this);
        this.getMappingComponents     = this.getMappingComponents.bind(this);
        this.mapComponent             = this.mapComponent.bind(this);
        this.onValispaceExportSuccess = this.onValispaceExportSuccess.bind(this);
    }

    componentDidMount()
    {
        this.getValispaceData();
    }

    getValispaceData()
    {
        let state = this.state;
        let { toggleModalOnComponentViewPage } = this.props;
        let data  = {projectId: this.props.selectedProject.id};
        if(toggleModalOnComponentViewPage) data.populateDuroCmps = true;
        API.integrations.getValispaceData(data, (err, response) =>
        {
            if(err)
            {
                return;
            }

            if(this.props.selectedResults !== {})
            {
                Object.keys(this.props.selectedResults).forEach((item) =>{
                    if(response[item])
                    {
                        response[item].isChecked=this.props.selectedResults[item].isChecked;
                        response[item].isPartiallySelected=this.props.selectedResults[item].isPartiallySelected;
                    }
                })
            }
            state.components = response;
            state.loading    = false;
            state.parentChildrenIds = this.createParentChildrenHash(state.components);
            this.setState(state);
        })
    }

    createParentChildrenHash(components)
    {
        let data  = {};
        Object.keys(components).forEach((item, i) => {
            if(components[item].children.length > 0)
            {
                data[item] = components[item].children;
            }
        });
        return data;
    }

    getChilds(cmp, level, rows, item, i)
    {
        let comp = this.state.components;
        for(let child of cmp.children)
        {
            if(comp[child])
            {
                rows.push(this.setCells(child, i, level));
                if(comp[child].children.length > 0 && comp[child].classList === "open")
                {
                    level++;
                    this.getChilds(comp[child], level, rows);
                    level--;
                }
            }
        }
    }

    getRowData(level = 1)
    {
        let rows = [];
        let components = this.state.components;
        Object.keys(components).forEach((item, i) => {
            let cmp = components[item];
            if(cmp && !cmp.parent)
            {
                rows.push(this.setCells(cmp.id, i, level));
                if(cmp.children.length > 0 && cmp.classList === "open")
                {
                    level++;
                    this.getChilds(cmp, level, rows, item, i);
                    level--;
                }
            }
        })

        return rows;
    }

    getChildAssembly(item)
    {
        let state = this.state;
        state.components[item].classList = state.components[item].classList === "close" ? "open" : "close";
        this.setState(state);
    }

    setCells(item, i, level)
    {
        let {components} = this.state;
        let padding = `${level*20}px`;
        padding = level === 1 ? '15px' : padding;
        let imagesWithSrc = [];
        let indexTableEl = <div>
                                <InlineIcon
                                    isValid={true}
                                    className="warningEl warning-icon"
                                    >
                                    <InfoIcon/>
                                 </InlineIcon>
                            </div>
        let errorOverlay = <div>
                            {
                                <span>
                                    Duro component already mapped. CPN:
                                    <Link
                                        to={`/component/view/${components[item].existingCmpId}`}
                                        target="_blank"
                                        className=""
                                        >
                                        <span className="link-text existing-cmp-cpn">
                                            {components[item].existingCmpCpn}
                                            <InlineIcon >
                                                <LinkIcon/>
                                            </InlineIcon>
                                        </span>
                                    </Link>
                                </span>
                            }
                        </div>


        let cell =
        {
            "name":
            {
                value        : components[item].name,
                tooltip      : "",
                displayValue : <span className="tree-child-assembly" style={{            paddingLeft: padding}}>
                                    <span className='position-relative'>
                                        {
                                            components[item].children.length > 0 ?
                                            <InlineIcon
                                              stopPropagation={true}
                                              onClick={
                                                  (e) => {
                                                      this.getChildAssembly(item)
                                                  }
                                              }
                                              className={components[item].classList}
                                              onMouseOver={(e) => this.addHOverState(e)}
                                            >
                                              <ArrowIcon/>
                                            </InlineIcon> : null
                                        }
                                        {components[item].name}
                                    </span>
                                </span>,
                notLink     :  true
            },
            "image":
            {
                value       : 0,
                displayValue: <InlineImageViewer imagesWithSrc={imagesWithSrc} key={Utils.generateUniqueId()} defaultResolution={Config.defaultResolutions.inlineImage} images={components[item].image ? [{src: components[item].image}] : []} imgIdentifier={components[item].image ? `img-${item}` : ''} isValispaceComponent={true}/>,
                notLink     : true,
                cellClass   : "inline-image-viewer"
            },
            "description":
            {
                value        : components[item].description,
                tooltip      : "",
                displayValue : components[item].description,
                notLink     :  true
            },
            "creator":
            {
                value        : components[item].creator,
                tooltip      : "",
                displayValue : components[item].creator,
                notLink     :  true
            },
            "documents":
            {
                value        : components[item].documents.length,
                tooltip      : "",
                displayValue : components[item].documents.length,
                notLink     :  true
            },
            "lastModified":
            {
                value        : components[item].lastModified ? components[item].lastModified : '',
                tooltip      : components[item].lastModified,
                displayValue : <TimeStampColumn
                                    key={Utils.generateUniqueId()}
                                    format='date-time-with-long-format'
                                    value={components[item].lastModified}/>,
                cellClass   : 'lastModified-col',
                notLink     :  true
            },
            object           : components[item],
            rowId            : item,
            notLink          : true,
            allowRowSelect   : components[item].isAlreadyMapped ? false : true ,
            rowClassName     : components[item].isAlreadyMapped ? 'disabeld-row' : '',
            rowSelected      : components[item].isChecked ? components[item].isChecked : false,
            className        : '',
            partialSelected  : components[item].isPartiallySelected ? components[item].isPartiallySelected : false,
            parentId         : components[item].parent,
            indexTableEl     : components[item].isAlreadyMapped ? 
                                <Tooltip
                                  placement={"top"}
                                  overlayClassName={"simple-rc-tip error default-styles no-primary-source-tooltip"}
                                  overlay={errorOverlay}>
                                    {indexTableEl}
                                </Tooltip> : null
        }
        return cell;
    }

    getIconsActionsList()
    {
        let actionsList = [];
        let actionEntry;

        actionEntry =
        {
            type          : 'action',
            iconSrc       : <UpdateStatusIcon />,
            toolTip       : "Refresh",
            name          : "Refresh",
            onClick       : this.Refresh,
            active        : true,
        };
        actionsList.push(actionEntry);

        return actionsList;
    }

    getSelectors(id)
    {
        let selector = `.valispace-components-view .index-table tbody tr[data-row-id='${id}']`;
        let selectParentCheckbox = $(`${selector} input:checkbox`);
        let selectParentCheckboxLabel = $(`${selector} .index-checkbox label`);

        return {selectParentCheckbox, selectParentCheckboxLabel};
    }

    onRowSelect(event, result, selectedRowsIds=[])
    {
        let state = this.state;
        let selectedRowIndex = selectedRowsIds.indexOf(result.id);
        let { toggleModalOnComponentViewPage } = this.props;
        let {selectParentCheckbox, selectParentCheckboxLabel} = this.getSelectors(result.id);

        if (event.target.checked) {
            state.selectedResults[result.id] = result;
            if (!(selectedRowIndex > -1)) {
                selectedRowsIds.push(result.id);
            }
            state.components[result.id].isChecked = event.target.checked;
            state.components[result.id].isPartiallySelected = false;

            if(!toggleModalOnComponentViewPage)
            {
                selectParentCheckbox.prop('checked', true);
                selectParentCheckboxLabel.removeClass("middle");
                if(result.parent) this.addParentsToList(state.selectedResults, state.components, result.parent, selectedRowsIds);
                this.onChangeParentCheckbox(result.id, selectedRowsIds, true);
            }

        }
        else if (!event.target.checked)
        {
            delete state.selectedResults[result.id];
            if(selectedRowIndex > -1)
            {
                selectedRowsIds.splice(selectedRowIndex, 1);
            }
            if(result.parent)
            {
                this.removeParentsFromList(state.selectedResults, state.components, result.parent, selectedRowsIds);
            }
            if(!toggleModalOnComponentViewPage)
            {
                state.components[result.id].isChecked = event.target.checked;
                selectParentCheckbox.prop('checked', false);
                selectParentCheckboxLabel.removeClass("middle");
                state.components[result.id].isPartiallySelected = false;
            }
            this.onChangeParentCheckbox(result.id, selectedRowsIds, false);
        }
        if(!toggleModalOnComponentViewPage) this.setParentCheckboxState(result.id, selectedRowsIds);
        this.setState(state);
    }

    addParentsToList(selectedResults, components, parent, selectedRowsIds)
    {
        let selectedRowIndex = selectedRowsIds.indexOf(parent);
        components[parent].isPartiallySelected  = true;
        selectedResults[parent] = components[parent];
        selectedRowIndex > -1 && selectedRowsIds.push(parent);
        if(components[parent].parent)
        {
            this.addParentsToList(selectedResults, components, components[parent].parent, selectedRowsIds);
        }
    }

    removeParentsFromList(selectedResults, components, parent, selectedRowsIds)
    {
        let selectedRowIndex = selectedRowsIds.indexOf(parent);
        selectedRowIndex > -1 && selectedRowsIds.splice(selectedRowIndex, 1);
        components[parent].isChecked = false;
        if(components[parent].parent)
        {
            this.removeParentsFromList(selectedResults, components, components[parent].parent, selectedRowsIds);
        }
    }

    onSelectAllRows(selectAll, selectedRowsIds=[])
    {
        let state = this.state;
        let { components } = this.state;
        let selectCheckboxLabel = $(`.valispace-components-view .index-table tbody tr .index-checkbox label`);
        selectCheckboxLabel.removeClass("middle");
        state.clearSelectedRows = false;
        if(selectAll)
        {
            Object.keys(components).forEach((item, i) =>
            {
                let index = selectedRowsIds.indexOf(components[item].id);
                state.selectedResults[item] = components[item];
                if(!(index > -1))
                {
                    selectedRowsIds.push(components[item].id);
                }
                state.components[item].isChecked = true;
            });
        }
        else
        {
            state.selectedResults = {};
            Object.keys(components).forEach((item, i) =>
            {
                state.components[item].isChecked = false;
            });
        }

        this.setState(state);
    }

    Refresh()
    {
        let state = this.state;
        state.loading = true;
        state.selectedResults = {};
        this.setState(state);
        this.getValispaceData();
    }

    reviewImport()
    {
        let state = this.state;
        this.props.renderReviewScreen(state.selectedResults);
    }

    backButton()
    {
        this.props.setStep("projects-list");
    }

    onChangeParentCheckbox(parent, selectedRowsIds, isSelected)
    {
        let parentChildrenIds = this.state.parentChildrenIds;
        let childrens = parentChildrenIds[parent];
        let {selectParentCheckboxLabel} = this.getSelectors(parent);

        if(childrens)
        {
            selectParentCheckboxLabel.removeClass("middle");
            childrens.forEach((child) => {
                if(this.state.components[child])
                {
                    let selectedRowIndex = selectedRowsIds.indexOf(child);
                    if(isSelected)
                    {
                        this.onSelectParent(selectedRowIndex, selectedRowsIds, this.state.selectedResults, child, this.state);
                        this.state.components[child].isChecked = true;
                    }
                    else
                    {
                        this.onUnselectParent(selectedRowIndex,selectedRowsIds, this.state.selectedResults, child);
                        this.state.components[child].isChecked = false;
                    }
                    this.onChangeParentCheckbox(child, selectedRowsIds, isSelected);
                }
            })
        }
        return
    }

    onSelectParent(selectedRowIndex, selectedRowsIds, selectedResults, id, state)
    {
        selectedResults[id] = state.components[id];
        if (!(selectedRowIndex > -1))
        {
            selectedRowsIds.push(id);
        }
    }

    onUnselectParent(selectedRowIndex, selectedRowsIds, selectedResults, id)
    {
        delete selectedResults[id];
        if(selectedRowIndex > -1)
        {
            selectedRowsIds.splice(selectedRowIndex, 1);
        }
    }

    setParentCheckboxState(id, selectedRowsIds)
    {
        let state = this.state;
        let parentId = this.state.components[id].parent;
        let parent = null;
        if(parentId)
        {
            let {selectParentCheckbox, selectParentCheckboxLabel} = this.getSelectors(parentId);

            parent = this.state.components[parentId];
            let availableChildsCount = 0;
            let selectedChild = 0;
            let childWithMiddle = 0;
            parent.children.forEach((child) => {
                let childCmp = this.state.components[child];
                if(childCmp)
                {
                    let element = document.querySelector(`.valispace-components-view .index-table tbody tr[data-row-id='${child}'] .index-checkbox label`);
                    let isPartiallySelectedState = element ? element.classList.contains("middle") : false;
                    availableChildsCount++;
                    isPartiallySelectedState && childWithMiddle++;
                    childCmp.isChecked && selectedChild++;
                }
            })

            let selectedRowIndex = selectedRowsIds.indexOf(parent.id);
            if(selectedChild > 0 && selectedChild < availableChildsCount || childWithMiddle > 0)
            {
                state.selectedResults[parentId].isChecked = false;
                state.selectedResults[parentId].isPartiallySelected = true;
                state.components[parentId].isChecked = false;
                state.components[parentId].isPartiallySelected = true;
                selectParentCheckbox.prop('checked', false);
                selectParentCheckboxLabel.addClass("middle");
            }
            else if(selectedChild === 0)
            {
                this.onUnselectParent(selectedRowIndex, selectedRowsIds, state.selectedResults, parentId);
                state.components[parentId].isPartiallySelected = false;
                state.components[parentId].isChecked = false;
                selectParentCheckbox.prop('checked', false);
                selectParentCheckboxLabel.removeClass("middle");
            }
            else if(selectedChild === availableChildsCount)
            {
                state.components[parentId].isPartiallySelected = false;
                state.components[parentId].isChecked = true;
                this.onSelectParent(selectedRowIndex, selectedRowsIds, state.selectedResults, parentId, state);
                selectParentCheckbox.prop('checked', true);
                selectParentCheckboxLabel.removeClass("middle");
            }
        }
        if(parentId)
        {
            this.setParentCheckboxState(parentId, selectedRowsIds);
        }
        this.setState(state);
    }

    getSelectedComponents()
    {
        let results = Object.values(this.props.selectedResults);
        let arr = [];
        results.forEach((result) => {
            if(result.isChecked)
            {
                arr.push(result.id);
            }
        })
        return arr;
    }

    onRejectMapping()
    {
        this.setState({showWarningModal: false});
    }

    onConfirmMapping()
    {
        this.setState({showWarningModal: false});
        this.mapComponent();        
    }

    toggleWarningModal()
    {
        this.setState({showWarningModal: true});
    }

    getMappingComponents()
    {
        let { components } = this.state;
        let { component }  = this.props;
        let to;
        let from;
        Object.keys(components).forEach((item) => {
            if(components[item].isChecked)
            {
                to = components[item];
            }
            if(components[item].id == component.vendorId)
            {
                from = components[item];
            }
        });
        return {to, from};
    }

    onValispaceExportSuccess()
    {
        window.location.reload();
    }
    
    mapComponent()
    {
        this.setState({loading: true});
        let { component }         = this.props;
        let {showAlert, onClose}  = this.props;
        let mappingComponents     = this.getMappingComponents();
        let selectedComponentId   = mappingComponents['to'] ? mappingComponents['to'].id : null;
        let selectedComponentName = mappingComponents['to'] ? mappingComponents['to'].name : null;
        let selectedProjectId     = this.props.selectedProject ? this.props.selectedProject.id : null; 
        let data = {
            baseComponent       : component._id,
            vendorId            : selectedComponentId,
            vendorName          : selectedComponentName,
            projectId           : selectedProjectId
        };

        API.integrations.mapComponent(data, (err, response) =>
        {
            if(err)
            {
                showAlert("modal","Component is Not Mapped",this.onValispaceExportSuccess);
                return;
            }
            this.setState({loading: false});
            showAlert("modal","Component is Successfully Mapped",this.onValispaceExportSuccess);
            onClose();
        });
    }

    render()
    {
        let state = this.state;
        let { component, headerText, heading, buttonText, toggleModalOnComponentViewPage} = this.props;
        let tableHeaders = state.headings;
        let projectName = this.props.selectedProject.name;
        let workspaceName = this.props.selectedWorkspace.name;
        let continueClassName = `btn-continue ${Object.keys(state.selectedResults).length > 0 ? "" : "disabled"}`;
        let rows = this.getRowData();
        let totalComponents = Object.keys(state.components).length;
        let selectedComponents = this.getSelectedComponents().length > rows.length ? rows.length : this.getSelectedComponents().length;
        let resultText = this.getSelectedComponents().length > 0 ? `Selected ${selectedComponents} of ${rows.length}`: `${rows.length}`;
        let isDuroComponent = component && Utils.getVendor(component) === 'duro' && component.vendorId ? true : false;
        let mappingModal = isDuroComponent ? this.toggleWarningModal : this.mapComponent;
        let nextScreen = !toggleModalOnComponentViewPage ? this.reviewImport : mappingModal;
        let mappingComponents = toggleModalOnComponentViewPage ? this.getMappingComponents() : undefined;
        let mapTo = mappingComponents && mappingComponents['to'] ? mappingComponents['to'].name : "";
        let alreadyMappedOn =  mappingComponents && mappingComponents['from'] ? mappingComponents['from'].name : "";
        
        let markup =
                    <div className="valispace-components-wrapper">
                        {
                                this.state.showWarningModal &&
                                <WarningModal
                                    onCancel={this.onRejectMapping}
                                    onConfirm={this.onConfirmMapping}
                                    isWarningIcon={true}
                                    title={"Map Again"}
                                    className={"warning-modal-custom"}
                                    description={`Are you sure you want to re-map this Component from Valispace ${alreadyMappedOn} to Valispace ${mapTo}`}
                                />
                        }
                        <div className="valispace-components-table">
                            <header className="valispace-modal-header">
                                <HeaderBanner text={headerText}/>
                                <div className="btn-container">
                                    <button
                                            className="btn-back"
                                            onClick={this.backButton}>
                                            Back
                                    </button>
                                    <button
                                        className={continueClassName}
                                        onClick={Object.keys(state.selectedResults).length > 0 ? nextScreen: function(){}}
                                    >
                                    {buttonText}
                                    </button>
                                </div>
                            </header>
                            <div className="content-wrapper">
                                <div className="modal-heading">
                                    <h1>{heading}</h1>
                                </div>
                                <div className="valispace-components">
                                    {
                                        state.loading ?
                                        <Spinner /> :
                                        rows.length > 0 ?
                                        <div className="details-wrapper">
                                            <div className="details-heading">
                                                <h3>VALISPACE <span>COMPONENTS</span></h3>
                                            </div>
                                            <div className="table-wrapper">
                                            <div className="heading-holder">
                                                <h3> <span>Workspace: </span> {workspaceName} </h3>
                                                <h3> <span>Project: </span> {projectName} </h3>
                                            </div>
                                            <PerfectScrollbar
                                                className="valispace-modal-scroll"
                                            >
                                                <ExtendedTable
                                                    wrapperClassName="valispace-components-block"
                                                    wrapperSelectorClass="valispace-components-view"
                                                    stylesName="valispaceComponentView"
                                                    themeDataTableId={"valispaceComponentView"}
                                                    rows={rows}
                                                    headings={tableHeaders}
                                                    includeToolBar={true}
                                                    allowRowSelect={true}
                                                    tableClass={"striped"}
                                                    borderedTable={true}
                                                    startStaticColumns={1}
                                                    disableDataSorting={true}
                                                    tableActionButtons={this.getIconsActionsList()}
                                                    onRowSelect={this.onRowSelect}
                                                    onSelectAllRows={this.onSelectAllRows}
                                                    concateWrapperSelectorClassMasterCheckbox={true}
                                                    resultText={`${resultText} Components`}
                                                    enableChildRowSelect={false}
                                                    totalComponents={totalComponents}
                                                    preselectedComponentIds={this.getSelectedComponents}
                                                    allowRowSelectAsRadio={toggleModalOnComponentViewPage ? true : false}

                                                />
                                            </PerfectScrollbar>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            No Components in this project
                                            <button
                                                className={"dark"}
                                                onClick={this.backButton}>
                                                Back
                                            </button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
        return markup;
    }
}
