import { SvgIcon } from "@mui/material";

export const DuroShortIcon = () => (
    <SvgIcon width="19" height="32" viewBox="0 0 19 32">
        <defs>
            <clipPath id="vqo8zpcdka">
                {/* eslint-disable-next-line max-len */}
                <path d="M6.54 7.958c1.454 0 2.437.417 3.121.917v5.583h-.556c-.812 0-1.838.376-1.838 1.959v7.124c0 1.584 1.026 1.96 1.838 1.96h.556v5.624c-.684.458-1.71.875-3.12.875C2.692 32 0 29.958 0 23.875v-7.792C0 10 2.693 7.958 6.54 7.958zM18.168 0v31.625h-7.224V0h7.224z" />
            </clipPath>
        </defs>
        <g clipPath="url(#vqo8zpcdka)">
            {/* intentional fill property on short logo */}
            <path fill="#3BD1B6" d="M0 0h18.168v32H0V0z" />
        </g>
    </SvgIcon>
);
