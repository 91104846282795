import USER_PROFILE from '../action-types/user_profile'
import { handleActions } from 'redux-actions'
import initialState from './initial-states/user_profile'

const updateFormState = (state = initialState, action) => {
  return {...state, ...action.payload};
}

const togglePasswordBlock = (state = initialState, action) => {
  state.passwordBlock.class = !state.passwordBlock.class ? "open" : ""
  return {...state};
}

export default handleActions({
    [USER_PROFILE.TOGGLE_PASSWORD_BLOCK]: togglePasswordBlock,
    [USER_PROFILE.UPDATE_FORM_STATE]: updateFormState,
}, initialState);
