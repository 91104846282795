import React        from "react";
import NumberFormat from 'react-number-format';
import Utils        from "../../../../modules/utils";

const MassField = (props) =>
{
    let { mass , massPrecision } = props;
    return(
        <NumberFormat
            value={Utils.getRoundedMass(mass, massPrecision)}
            displayType={"text"}
            thousandSeparator={true}
        />
    )
}

export default MassField;
