import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={12} height={10} viewBox="0 0 12 10" {...props}>
      <path
        d="M.885 0v10h10.917V0H.885zm4.852 8.889H2.098V5.556h3.64v3.333zm0-4.445H2.098V1.111h3.64v3.333zM10.59 8.89H6.95V5.556h3.639v3.333zm0-4.445H6.95V1.111h3.639v3.333z"
        fill="#CBCBCB"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default SvgComponent
