import USERS                        from '../action-types/users'
import initialState                 from './initial-states/users'
import { handleActions }            from 'redux-actions'
import Utils                        from '../modules/utils'

function setUsers(state, action) {
    let users = action.payload.users.map((user) =>
        {
            let readOnly = (user._id == action.payload.currentUserId)
            let _user =
            {
                _id        : user._id,
                readOnly   : readOnly,
                superAdmin : (user.role === "SUPER ADMINISTRATOR"),
                session    : getLastSession(user),
                status     : getStatus(user),
                name       : getFullName(user),
                email      : user.email,
                title      : user.title,
                role       : user.role,
                groups     : user.groups,
                avatar     : user.avatar,
                firstName  : user.firstName,
                lastName   : user.lastName,
                lastLogin  : user.lastLogin

            }

            return _user
        })

    state.users = Utils.clone(users)
    // sort(state)
    return { ...state };
}

function getLastSession(user)
{
    let session = user.lastLogin
    let obj     =
    {
        value   : session ? Utils.date(session) : user.verified ? "Confirmed" : "Invited",
        created : session ? session : 0
    }
    return obj
}

function getFullName(user)
{
    return user.firstName + " " + user.lastName
}

function getStatus(user)
{
    let status = ""
    if (user.token && user.token.isExpire)
    {
        status = "EXPIRE"
    }
    if (user.status && user.status === "DISABLED")
    {
        status = "DISABLED"
    }
    return status
}


function setUsersLoading(state, action)
{
    state.loading = action.payload
    return {...state}
}

export default handleActions({
    [USERS.SET_USERS_LIST]: setUsers,
    [USERS.SET_USERS_LOADING]: setUsersLoading

}, initialState);
