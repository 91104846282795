import { ApolloError } from "@apollo/client";

/**
 * Builds a list of sub errors on an error. Older systems put an erros property on the error to hold
 * these, and this is returned if it exists. Apollo splits the sub errors into three categories, and
 * these are all componed and returned for when recieving an Apollo Error. All other cases null is
 * returned.
 *
 * @param err Error to check for sub errors on.
 * @returns The list of sub errors or null if there are none.
 */
export function getSubErrors(err: any): Error[] | null {
  // Old style network errors
  if (err.errors) {
    return err.errors as Error[];
  }

  // Apollo errors
  if (err instanceof ApolloError) {
    return err.clientErrors.concat(err.graphQLErrors, err.networkError ?? []);
  }

  return null;
}
