import { GridRowId } from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { BuildRevision } from "build/models";
import { GridExpansionPostExpansionAction, getRowId, useGridExpansion } from "common/components/grid";
import { ModelType } from "design/constants";
import { Component } from "design/models";
import { client } from "graphql/apolloClient";
import { Assembly, getBuildRevision } from "graphql/query/buildRevisionQueries";
import { getComponentRevisionWithChildren } from "graphql/query/componentRevisionQueries";
import { getProductRevisionWithChildren } from "graphql/query/productRevisionQueries";
import { Dispatch, MutableRefObject, SetStateAction, useCallback, useEffect, useState } from "react";

export type SerializationNode = {
  _path: string[];
  _childrenLoaded?: boolean;
  _descendantCount?: number;
  item: Component | BuildRevision;
  notes: string;
  qtyToBuild: number;
  quantity: number;
  serialNumberType: SerialNumberType;
}

export enum SerialNumberType {
  GENERATED = "GENERATED",
  MANUAL = "MANUAL",
  COMMODITY = "COMMODITY",
  UNIQUE_COMMODITY = "UNIQUE_COMMODITY"
}

interface AssignPropsArgs {
  item: Component | BuildRevision;
  parentPath: string[];
  productionRun: number;
  quantity: number;
}

function assignProps(args: AssignPropsArgs): { node: SerializationNode, nodePath: string[] } {
  const {
    item,
    parentPath,
    productionRun,
    quantity,
  } = args;
  const _path = [...parentPath, item.id];

  return {
    node: {
      _descendantCount: item.children?.length ?? 0,
      _path,
      item,
      notes: "",
      qtyToBuild: quantity * productionRun,
      quantity,
      serialNumberType: SerialNumberType.GENERATED,
    },
    nodePath: _path,
  };
}

interface GenerateTreeArgs {
  item: Component | BuildRevision;
  parentPath?: string[];
  productionRun: number;
  quantity: number;
}

function generateTree({ item, productionRun, parentPath = [], quantity = 0 }: GenerateTreeArgs) {
  const { node, nodePath } = assignProps({ item, parentPath, productionRun, quantity });
  if (parentPath.length === 0) node._childrenLoaded = true;
  const nodes = [node];
  if (item.children?.length) {
    for (const child of item.children) {
      const childComponent = child.assemblyRevision ?? child.component;
      if (childComponent) {
        nodes.push(...generateTree({
          item: childComponent,
          productionRun: node.qtyToBuild,
          parentPath: nodePath,
          quantity: child.quantity ?? 0,
        }));
      }
    }
  }
  return nodes;
}

function updateRowsQuantity(
  parentRowId: string,
  rows: SerializationNode[],
  productionRun: number,
): SerializationNode[] {
  const rowMap: Record<string, SerializationNode> = {};
  const rowIds = rows.map(row => {
    const id = getRowId(row);
    rowMap[id] = row;
    return id;
  });

  function update(id: string, parentQuantity: number) {
    const row = rowMap[id];
    if (!row) return;
    const qtyToBuild = parentQuantity * row.quantity;
    rowMap[id] = { ...row, qtyToBuild };
    row.item.children?.forEach(
      child => update(
        getRowId({ _path: [...(row._path ?? []), child.assemblyRevision?.id ?? ""] }),
        qtyToBuild,
      ),
    );
  }

  update(parentRowId, productionRun);

  return rowIds.map(id => rowMap[id]);
}

export interface UseSerializationDataArgs {
  alias: ModelType,
  apiRef: MutableRefObject<GridApiPro>
  build?: boolean | undefined,
  id: string,
  productionRun: number,
}

export function useSerializationData({ alias, apiRef, build, id, productionRun }: UseSerializationDataArgs) {
  const isProduct = alias === ModelType.PRD;
  const [currentItem, setCurrentItem] = useState<Component | BuildRevision | undefined>();
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<SerializationNode[]>([]);

  useEffect(() => {
    let isMounted = true;
    async function loadItem() {
      let item: Component | BuildRevision;

      const fetchDesignRevision = async () => {
        if (isProduct) {
          const { product } = await getProductRevisionWithChildren(client, id);
          if (product) {
            return product;
          }
        }
        else {
          const { component } = await getComponentRevisionWithChildren(client, id);
          if (component) {
            return component;
          }
        }
        return undefined;
      };

      if (build) {
        item = (await fetchBuildRevision({ id, alias })).component as unknown as BuildRevision;
      }
      else {
        item = (await fetchDesignRevision()) as unknown as Component;
      }

      if (isMounted) {
        setCurrentItem(item);
        setLoading(false);
      }
    }
    loadItem();
    return () => {
      isMounted = false;
    };
  }, [alias, build, id, isProduct]);

  useEffect(() => {
    if (!currentItem || rows.length) return;
    setRows(generateTree({ item: currentItem, productionRun, quantity: 1 }));
  }, [currentItem, productionRun, rows.length]);

  useEffect(() => {
    // Surround the setting of the state in a promise so that we can have logic that always happens
    // after the state is updated. This is required, as otherwise react can cause the set state to
    // happen at an indeterminable time and causes issues with the logic.
    Promise.resolve().then(() => {
      let updatedRows: SerializationNode[] | undefined;
      setRows(old => {
        updatedRows = updateRowsQuantity(currentItem?.id ?? "", old, productionRun);
        return updatedRows;
      });
      return updatedRows;
    }).then(updatedRows => {
      if (!updatedRows || !apiRef.current.getAllRowIds) return;

      const gridRowIds = apiRef.current.getAllRowIds();
      const updatedGridRows = updatedRows.filter(r => gridRowIds.includes(getRowId(r)));
      apiRef.current.updateRows(updatedGridRows);
    });
  }, [apiRef, currentItem?.id, productionRun]);

  return {
    currentItem,
    loading,
    rows,
    setRows,
  };
}

export interface UseGridHelpersArgs {
  apiRef: MutableRefObject<GridApiPro>
  component?: Component | BuildRevision;
  componentLoading: boolean;
  setChildLoadingCount: Dispatch<SetStateAction<number>>;
  setRows: Dispatch<SetStateAction<SerializationNode[]>>;
  setSelectionModel: Dispatch<SetStateAction<GridRowId[]>>;
}

export function useGridHelpers(args: UseGridHelpersArgs) {
  const {
    apiRef,
    component,
    componentLoading,
    setChildLoadingCount,
    setRows,
    setSelectionModel,
  } = args;

  const getExpansionChildren = useCallback(async (row: SerializationNode) => {
    if (!row.item) return [];
    const res = await getRevisionWithChildren(row.item);
    const children = res.component?.children?.filter(c => c.assemblyRevision).map(child => assignProps({
      item: child.assemblyRevision!,
      parentPath: row._path,
      productionRun: row.qtyToBuild,
      quantity: child.quantity ?? 0,
    }).node) ?? [];

    if (children.length) {
      setRows(old => old.concat(children));
    }

    return children;
  }, [setRows]);

  const postExpansionAction: GridExpansionPostExpansionAction<SerializationNode> = useCallback(async (
    node,
    _row,
    children,
  ) => {
    if (apiRef.current.isRowSelected(node.id)) {
      setSelectionModel(old => old.concat(children.map(child => getRowId(child))));
    }
  }, [apiRef, setSelectionModel]);

  const {
    disableCollapse,
    disableExpand,
    setDisableCollapse,
    setDisableExpand,
    setVisibleRowCount,
    updatePostExpansionChange,
    visibleRowCount,
  } = useGridExpansion({ apiRef, getExpansionChildren, postExpansionAction, setChildLoadingCount });

  // When the component is done loading, set the initial expand state based off
  // of there being any children with their own children.
  useEffect(() => {
    if (componentLoading || !component?.children?.length) return;

    setDisableCollapse(true);
    setDisableExpand(!component.children.find(c => c.component?.children?.length));
  }, [component?.children, componentLoading, setDisableCollapse, setDisableExpand]);

  return { disableCollapse, disableExpand, setVisibleRowCount, updatePostExpansionChange, visibleRowCount };
}

export interface BldRevison {
  component: BuildRevision;
}

const getRevisionWithChildren = async (item: Component | BuildRevision) => {
  if ("isBuildRev" in item) {
    return fetchBuildRevision(item) as unknown as BldRevison;
  }
  return getComponentRevisionWithChildren(client, item.id);
};

const fetchBuildRevision = async (args: BuildRevision | { alias: string, id: string }) => {
  const { component: buildRevision } = await getBuildRevision(client, args.id);
  const childData = processChildren(buildRevision?.assembly);
  return ({
    component: {
      ...buildRevision,
      alias: args.alias,
      isBuildRev: true,
      children: childData,
      cpn: {
        displayValue: buildRevision?.cpn,
      },
    },
  });
};

const processChildren = (children?: Assembly[]) => (
  children?.map(item => ({
    assemblyRevision: {
      ...item.child,
      alias: ModelType.CMP,
      isBuildRev: true,
      cpn: {
        displayValue: item.child.cpn,
      },
      ...(item?.child?.assembly?.length && {
        children: [item?.child?.assembly?.map(e => ({ quantity: e.quantity }))],
      }),
    },
    quantity: item.quantity,
  }))
);
