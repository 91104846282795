
import Utils from "../../utils"

class ComponentRevisionAPI
{
    static findById(id, cb)
    {
        Utils.get("/components/revisions/" + id, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static getFullTree(data, cb)
    {
        Utils.post("/components/parent/revision", data,  (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }
}

export default ComponentRevisionAPI
