
import Utils from "../../utils"

class ChangeOrderAPI
{
    static create(data, cb)
    {
        Utils.post("/changeorders", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static findById(id, cb)
    {
        Utils.get("/changeorders/" + id, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static update(id, data, cb)
    {
        Utils.post("/changeorders/" + id, data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static approve(id, data, cb)
    {
        Utils.post("/changeorders/" + id + "/approve", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static reject(id, data, cb)
    {
        Utils.post("/changeorders/" + id + "/reject", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static autoSave(id, data, cb)
    {
        Utils.post("/changeorders/" + id + "/autoSave", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static delete(id, cb)
    {
        Utils.post("/changeorders/" + id + "/delete", {} , (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res)
        })
    }


    static addHistory(id, data, cb)
    {
        Utils.post("/changeorders/" + id + "/addHistory", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static list(cb)
    {
        Utils.get("/cos", (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static getParentAssemblies(data, cb)
    {
        Utils.post("/components/get/parents", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static getChildrenAssemblies(data, cb)
    {
        Utils.post("/components/get/children", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static getChildrenIdAndStatus(data, cb)
    {
        Utils.post("/components/get/childrenidandstatus", data, (err, res) =>
        {
            if(err)
            {
                return cb(err);
            }

            cb(null, res.data);
        })
    }

    static resendCoReviewNotification(id, data, cb)
    {
        Utils.post(`/changeorders/${id}/resendCoReviewNotification`, data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static resendResolutionEmails(coId, data, cb)
    {
        Utils.post(`/changeorders/${coId}/sendCoNotifications`, data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static getRevisionBump(data, cb)
    {
        Utils.post(`/changeorder/getRevisionBumpInCo`, data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }
            cb(null, res.data)
        })
    }

}

export default ChangeOrderAPI
