import { styled } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

interface CustomStyle {
  customStyle?: {
    color?: string;
  }
}

export const Link = styled(RouterLink, {
  shouldForwardProp: propName => propName !== "customStyle",
})<CustomStyle>(({ customStyle, theme }) => ({
  color: customStyle?.color ?? theme.palette.primary.main,
  textDecoration: "none",
}));
