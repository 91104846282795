import { handleActions }                 from 'redux-actions'
import CO                                from '../../../action-types/changeorder'
import initialState, { getInitialState } from "./initial_state"
import Utils                             from "../../../modules/utils"
import config                            from "../../../modules/config"


const setFormFields = (state = initialState, {payload}) => {
  state =  payload
  return {...state}
}

function disableFormSubmission(state, { payload })
{
    let { inputs } = state
    inputs.submit.class = payload ? "disabled" : ""
    return {...state}
}

function resetForm(state, { payload })
{
    // state.inputs = getInitialState().inputs
    // state.list = getInitialState().list
    state = getInitialState()
    return {...state}
}

function setParentAssemblies(state, { payload })
{
    state.list = payload.list
    state.search.inputs = payload.inputs
    if(payload.hasOwnProperty("parentsLoading") && !payload.parentsLoading){
        state.search.parentsLoading = payload.parentsLoading;
    }
    if(payload.hasOwnProperty("childrenLoading") && !payload.childrenLoading){
        state.search.childrenLoading = payload.childrenLoading;
    }
    return {...state}
}

function setChangeOrderOfChildren(state, { payload })
{
    state.list = payload.list;
    return {...state}
}

function validateList(state, row)
{
    let isDcoType = state.inputs.types.value && state.inputs.types.value.toUpperCase() === 'DCO'

    if(row.warning || row.error || (row.co && row.co.length > 0) || row.status === "DESIGN" || row.sourcingError ||
        (row.nextRevisionInput && !row.nextRevisionInput.valid) || (isDcoType && row.previousStatus === "DESIGN"))
    {
        state.listValid = false;
    }
    if (row.nextRevisionInput && row.previousStatus === "OBSOLETE" && row.status === "OBSOLETE") {
        row.nextRevisionInput.value = row.previousRevision; 
    }
    state.inputs.submit.class = Utils.isValidated(state.inputs) &&  state.listValid ? "" : "disabled";
}

function validateParentItem(parentsData, row, tableDataHash, statusHash, isDcoType) {
  if (!isDcoType) {
    const rowStatus = statusHash[row.status];

    parentsData[row._id].forEach(data => {
      const dataStatus = tableDataHash.hasOwnProperty(data.id)
        ? statusHash[tableDataHash[data.id].status]
        : statusHash[data.status];

      if (dataStatus < rowStatus) {
        row.warning = true;
      }
      if (data.status === "OBSOLETE")
        row.childObsoleteWarning = true;
    });
  }
}

function validateChildItem(childrenData, row, tableDataHash, statusHash, isDcoType)
{
    if (!isDcoType)
    {
        for(let childData of childrenData[row._id])
        {
            let rowStatus = statusHash[row.status];
            if((tableDataHash.hasOwnProperty(childData) && rowStatus < statusHash[tableDataHash[childData].status]))
            {
                row.error = true;
                row.disableRemove = true;
                row.parentIndex = tableDataHash[childData].index;
            }
        }
    }
}

function validateChangeOrderList(state, { payload })
{
    if(payload.newPageState) state = payload.newPageState;
    let childrenData = state.childrenData;
    let parentsData = state.parentsData;
    state.listValid = true;
    let isDcoType = state.inputs.types.value && state.inputs.types.value.toUpperCase() === 'DCO'

    if(payload.hasOwnProperty("childrenData"))
    {
        childrenData = payload.childrenData;
    }
    if(payload.hasOwnProperty("parentsData"))
    {
        parentsData = payload.parentsData;
    }
    let tableDataHash = {};

    let statusHash = {
        DESIGN : 1,
        PROTOTYPE : 2,
        PRODUCTION : 3,
    }

    for (let i = 0; i < state.list.length; i++)
    {
        let row = state.list[i];
        tableDataHash[row._id] = {status: row.status, index: i};
    }

    for (let i = 0; i < state.list.length; i++)
    {
        let row = state.list[i];
        row.validating = false;
        row.disableRemove = false;
        row.error = false;
        row.warning = false;
        row.childObsoleteWarning = false;
        row.parentIndex = -1;
        if(!row.hasOwnProperty("showRevision")) row.showRevision = true;

        if(childrenData.hasOwnProperty(row._id))
        {
            validateChildItem(childrenData, row, tableDataHash, statusHash, isDcoType);
        }

        if(parentsData.hasOwnProperty(row._id) && !row.error && !row.sourcingError)
        {
            validateParentItem(parentsData, row, tableDataHash, statusHash, isDcoType);
        }
        validateList(state, row);
    }

    state.parentsData = parentsData;
    state.childrenData = childrenData;
    state.validationRunning = false;
    state.list = [...state.list];

    for (let i = 0; i < state.list.length; i++)
    {
        if (!state.list[i].originalStatus)
        {
            state.list[i].originalStatus = state.list[i].status;
        }
    }
    return {...state};
}

function resetReloadExtendedTableValue(state)
{
    state.reloadExtendedTable = false;
    return {...state};
}

function setLoading(state, { payload })
{
    if(payload.parentsLoading){
        state.search.parentsLoading = payload.parentsLoading;
    }
    if(payload.childrenLoading){
        state.search.childrenLoading = payload.childrenLoading;
    }
    if(payload.validationRunning)
    {
        state.validationRunning = payload.validationRunning;
    }
    return {...state}
}

function setCoState(state)
{
    state.inputs.submit.class = Utils.isValidated(state.inputs) && state.list.length > 0 && state.listValid === true
    ? ""
    : "disabled"
    return {...state}
}


function setItem(item, unChangedChildren, unChangedChildrenHasComponents, unChangedChildrenHasProducts)
{
   item.nextRevisionInput = {
            value: item.nextCoRevision,
            message: '',
            valid: true
   };
    if (item.previousStatus === item.status && window.__libraryType === "GENERAL" && window.__revSchemeType === config.revisionTypes.alphaNumericXYZ)
    {
        item.revActionType = item.revActionType ? item.revActionType : "documentation"
    }
    else if (item.previousStatus === item.status && window.__libraryType === "GENERAL" && window.__revSchemeType === config.revisionTypes.alphaNumericXY)
    {
        item.revActionType = item.revActionType ? item.revActionType : "minor"
    }

   item.initialStatus = item.status;
   if(item.alias === "cmp" && unChangedChildrenHasComponents) item.showRevision = unChangedChildren.components.includes(item._id) ? false : true;
   if(item.alias === "prd" && unChangedChildrenHasProducts) item.showRevision = unChangedChildren.products.includes(item._id) ? false : true;
}

function setList(unChangedChildren, list)
{
    unChangedChildren = unChangedChildren ? unChangedChildren : {};
    let unChangedChildrenHasComponents = unChangedChildren.hasOwnProperty("components");
    let unChangedChildrenHasProducts = unChangedChildren.hasOwnProperty("products");
    list.forEach((item) => {
       setItem(item, unChangedChildren, unChangedChildrenHasComponents, unChangedChildrenHasProducts);
    })
    return list;
}

function setCoFrom(state= initialState, { payload })
{
    state.co = payload
    state.inputs.types.value = payload.type.toLowerCase()
    state.inputs.types.valid = true
    state.inputs.name.value = payload.name
    state.inputs.name.valid = state.inputs.name.value ? true : false
    state.inputs.description.value = payload.description
    state.unChangedChildren = payload.unChangedChildren;
    state.list = state.list.concat(payload.children.products)
    state.list = state.list.concat(payload.children.components)
    state.list = setList(state.unChangedChildren, state.list);
    state.inputs.submit.class = "disabled"
    state.inputs.enableDraft = Utils.isValidated(state.inputs);
    state.inputs.erpOptions.effectivity = setEffectivity(payload.erpOptions.effectivity);
    state.inputs.erpOptions.itemType = setItemType(payload.erpOptions.itemType);
    return {...state}
}

function setEffectivity(effectivity) {
    return {
        overrideExisting : {
            checked : effectivity.overrideExisting,
            valid : true
        },
        isEnabled : {
            checked : effectivity.isEnabled,
            valid : true
        },
        endDate : {
            value : effectivity.endDate,
            class : "",
            valid : true,
            message : ""
        },
        startDate : {
            value : effectivity.startDate,
            class : "",
            valid : true,
            message : ""
        }
    }
}

function setItemType(itemType) {
    return {
        overrideExisting : {
            checked : itemType.overrideExisting,
            valid : true
        },
        isEnabled : {
            checked : itemType.isEnabled,
            valid : true
        },
        value : {
            value : itemType.value,
            valid : true
        }
    }
}

export default handleActions({
    [CO.UPDATE_FORM_STATE]                      : setFormFields,
    [CO.DISABLE_FORM]                           : disableFormSubmission,
    // [CO.ADD_SEARCH_RESULTS]                     : addSearchResults,
    [CO.RESET_CO_FORM]                          : resetForm,
    [CO.SET_CO_STATE]                           : setCoState,
    [CO.SET_CHANGE_ORDER_OF_CHILDREN]           : setChangeOrderOfChildren,
    [CO.SET_PARENT_ASSEMBLIES]                  : setParentAssemblies,
    [CO.SET_CO_FORM]                            : setCoFrom,
    [CO.SET_LOADING]                            : setLoading,
    [CO.VALIDATE_CHANGE_ORDER_LIST]             : validateChangeOrderList,
    [CO.RESET_RELOAD_EXTENDED_TABLE_VALUE]      : resetReloadExtendedTableValue


}, initialState);
