import { takeLatest, all, cps, put, call } from "redux-saga/effects"
import UI                        from "../../action-types/ui"
import ON_BOARDING               from "../../action-types/on-boarding"
import buildAction               from "../../helpers/buildAction"
import API                       from "../../modules/api"
import Utils                     from "../../modules/utils"
import Config                    from "../../modules/config"
import { switchEnv,
saveOnBoardingSampleProductInfo} from "../user"

export function* getSampleProduct(action)
{
    let data =
    {
        dummyProduct    : action.payload.dummyProduct,
        dummyProductType: action.payload.dummyProductType,
    }
    const productId = yield cps(API.products.create, data)
    if (action.payload.history && productId)
    {
        let pathname = "/product/view/" + productId
        action.payload.history.push({pathname: pathname, onBoarding: true})
    }
    else if (action.payload.redirectionPath)
    {
        action.payload.history.push(action.payload.redirectionPath)
    }
}

export function* switchEnvAndCreateSampleProduct(action)
{
    try
    {
        yield put(buildAction(ON_BOARDING.SET_DISPLAY_SAMPLE_PRODUCT_MODAL_ON_DASHBOARD_FLAG, false));
        const response = yield cps(API.users.getCurrent)
        if (response.isSetupCompleted)
        {
            yield put(buildAction(ON_BOARDING.SET_INNER_LOADING_FLAG, true));
            yield put(buildAction(ON_BOARDING.SET_ACCOUNT_SETUP_FLAG, true));
            let history =  action.payload.history

            let payload =
            {
                user         : action.payload.user,
                currentEnv   : action.payload.currentEnv,
                activeLibrary: action.payload.activeLibrary
            }
            let switchAccountAction = { payload: payload}
            yield call(switchEnv, switchAccountAction);
            payload = {
                dummyProduct    :action.payload.dummyProduct,
                dummyProductType: action.payload.dummyProductType
            }
            let getSampleProductAction = { payload: payload}
            yield call(getSampleProduct, getSampleProductAction);
            payload = {
                user            : action.payload.user,
                dummyProductType: action.payload.dummyProductType
            }
            let saveOnBoardingInfoAction = { payload: payload}
            yield call(saveOnBoardingSampleProductInfo, saveOnBoardingInfoAction)
            yield put(buildAction(ON_BOARDING.SHOW_HIDE_ON_BOARDING_NAV, true));
            yield put(buildAction(ON_BOARDING.SHOW_ENV_INFO, true));

            const categories = yield cps(API.category.getCategories)
            window.__categories = categories

            if (action.payload.currentEnv === "LIVE")
            {
                window.__cpn_rules = () => window.__currentCompanyCpnType === 'CPN_RULES';
                window.__companyIs11DigitCpnType = () => window.__currentCompanyCpnType === 'CUSTOM-CODE-WITH-11-DIGIT';
                window.__companyIs10DigitCpnType = () => window.__currentCompanyCpnType === 'CUSTOM-CODE-WITH-10-DIGIT';
                window.__companyIs9DigitCpnType  = () => window.__currentCompanyCpnType === 'CUSTOM-CODE-WITH-9-DIGIT';
                window.__conditional01Variant  = () => window.__currentCompanyCpnType === 'CONDITIONAL-01-VARIANT';
                window.__with6DigitPrefixAndCounter  = () => window.__currentCompanyCpnType === 'WITH-6-DIGIT-PREFIX-AND-COUNTER';
                window.__nonIntelligentCPN  = () => window.__isIntelligentCpnScheme === false;
                window.__libraryType = "GENERAL";
                window.__companyWithHybridCpnType = () => window.__currentCompanyCpnType === 'HYBRID-WITH-6-DIGIT-CPN';
            }
            else
            {
                window.__companyIs11DigitCpnType = () => false;
                window.__companyIs10DigitCpnType = () => false;
                window.__companyIs9DigitCpnType  = () => false;
                window.__conditional01Variant  = () => false;
                window.__with6DigitPrefixAndCounter = () => false;
                window.__nonIntelligentCPN       = () => false;
                window.__companyWithHybridCpnType = () => false;
                window.__libraryType = "PERSONAL";
            }
            if(history) history.push("/refresh/dashboard")
        }
        else
        {
            yield put(buildAction(ON_BOARDING.SYNC_ACCOUNT_SETUP_PROGRESS, action.payload));
            return
        }

    }
    catch(err){
        yield put(buildAction(UI.SET_API_FAILED_RESPONSE_ERROR, err));
        yield put(buildAction(ON_BOARDING.SET_INNER_LOADING_FLAG, false));
    }
    yield put(buildAction(ON_BOARDING.SET_INNER_LOADING_FLAG, false));
}

export function* syncAccountSetupProgress(action)
{
    if (action.payload.closeProgressSynchingModal === true)
    {
        yield put(buildAction(ON_BOARDING.SET_ACCOUNT_SETUP_FLAG, null));
    }
    else{
        yield put(buildAction(ON_BOARDING.SET_ACCOUNT_SETUP_FLAG, false));
        yield call(Utils.delay, 2000)
        yield put(buildAction(ON_BOARDING.SWITCH_ENV_AND_CREATE_SAMPLE_PRODUCT, action.payload));
    }
}

export default function* (getState)
{
    yield all([
        takeLatest(ON_BOARDING.GET_SAMPLE_PRODUCT, getSampleProduct),
        takeLatest(ON_BOARDING.SWITCH_ENV_AND_CREATE_SAMPLE_PRODUCT, switchEnvAndCreateSampleProduct),
        takeLatest(ON_BOARDING.SYNC_ACCOUNT_SETUP_PROGRESS, syncAccountSetupProgress),
    ])
}
