
import React, { useCallback, useState, useEffect, Fragment } from 'react';
import ReactTooltip                       from 'react-tooltip';
import Utils 							  from '../../../../modules/utils';
import validations, {validateField}       from "../../../../modules/validations";
import LazyInput                          from "../../../ui/lazy-input/input.js";
import ModalBox                           from "../../../ui/modal-box";


const UpdateRevisionNonAssembly = ({revisionInput, onContinue, component, toogleSaveAsRevisionModal, newStatusValue}) => {

	const [revisionComment, setRevisionComment]     = useState("")

	const [revisionObject, setRevisionObject] = useState({
    value: revisionInput.value,
    valid: revisionInput.valid,
    class: "",
    message: "",
  	});

	const revisionInputChange = useCallback(event => {
        const value = event.target.value
        const {revisions} = component
        const libraryType = window.__libraryType
        const revSchemeType = window.__revSchemeType
        const isClient = true
        const isSaveAsRev = true
        const revValue = value.toUpperCase()
        const previousRevision = Utils.getPreviousRevision(component);
        const validationPayload = {
            status: newStatusValue ? newStatusValue : component.status,
            revisions,
            libraryType,
            revSchemeType,
            isClient,
            isSaveAsRev,
            previousRevision,
            defaultBlacklistedRevisions: window.__defaultBlacklistedRevisions
        }
        validateField(revisionInput, validations.component.revision, revValue, validationPayload)
        setRevisionObject(revisionInput);
		}, [component, newStatusValue, revisionInput]);

    const disableSaveAsRevisionContinueButton = () => {
       	setRevisionObject({valid: false})
    }

    const aliasingObject = () => {
        return (component && component.alias === "cmp") ? "Component" : "Product"
    }

    useEffect(() => {
    	revisionInputChange({target:{value: revisionInput.value}})
		}, [revisionInputChange, revisionInput.value])

	return (
		<Fragment>
			<div className={"confirm-revision-changed-modal"}>
	            <ModalBox onClose={(e) => {toogleSaveAsRevisionModal(false)}}>
	            <h1>Save New Revision</h1>
	            <div className="warning-text">
	                <p>You are saving a new revision on this {aliasingObject()}.</p>
	                <div className="inputs-section">
	                    <div className="revision-input-holder">
	                        <label className="muted-text">
	                            NEW REVISION
	                        </label>
	                        <ReactTooltip id={"input-revision-modal-input"}
	                            type="light"
	                            effect="solid"
	                            >
	                        </ReactTooltip>

	                        <LazyInput
	                            type="text"
	                            name="revision"
	                            autoFocus={true}
	                            value={revisionObject.value}
	                            onChange={revisionInputChange}
                           	    disableContinueButton={disableSaveAsRevisionContinueButton}
	                            className={revisionObject.class}
	                            data-tip={revisionObject.message}
	                            data-place="right"
	                            data-type="error"
	                            data-for='input-revision-modal-input'
	                        />

	                    </div>

	                    <div>
	                        <label className="muted-text">
	                            COMMENT
	                        </label>
	                        <input
	                            type="text"
	                            name="comment"
	                            value={revisionComment}
	                            onChange={(e)=> {setRevisionComment(e.target.value)}}
	                            className="revision-comment"
	                        />
	                    </div>
	                </div>
	            </div>
	            <div className="bottom-section">
	                <div className="button-sections">
	                  <button
	                      type="button"
	                      onClick={(e) => {toogleSaveAsRevisionModal(false)}}
	                      >
	                    Cancel
	                  </button>

	                  <button
	                      type="button"
	                      onClick={(e) => {onContinue(revisionObject, revisionComment)}}
	                      disabled={revisionObject.valid? false : true}
	                      className={revisionObject.valid ? "filled" : "disabled"}>
	                    continue
	                  </button>

	                </div>
	            </div>
	            </ModalBox>
	        </div>
		</Fragment>
	)
}

export default UpdateRevisionNonAssembly
