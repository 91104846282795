import { SvgIcon } from "@mui/material";

export const VendorImportIcon = () => (
  <SvgIcon className="toolbar-icon" width="24px" height="24px" viewBox="0 0 24 24">
    <path d="M16.737 20.126H4.105V7.263h7.369V5.158H4.105A2.111 2.111 0 0 0
      2 7.263v12.632C2 21.053 2.947 22 4.105 22h12.632a2.111 2.111 0 0 0 2.105-2.105v-7.369h-2.105v7.6z
      M18.842 2h-2.105v3.158h-3.158c.01.01 0 2.105 0
      2.105h3.158v3.148c.01.01 2.105 0 2.105 0V7.263H22V5.158h-3.158V2zM13.79
      11.141h-1.685v-.886c0-.492-.374-.887-.842-.887H9.58c-.467
      0-.842.395-.842.887v.886H7.053c-.468 0-.838.395-.838.887l-.004 4.875c0
      .492.374.886.842.886h6.736c.468 0
      .843-.394.843-.886v-4.875c0-.492-.375-.887-.843-.887zm-2.316.333H9.368V10.42h2.106v1.053z"
    />
  </SvgIcon>
);
