import React, { Component }             from "react"
import {connect}                        from 'react-redux';
import ChangeOrderForm                  from "../modules/co-form"
import buildAction                      from "../../../../helpers/buildAction"
import CO                               from '../../../../action-types/changeorder';
import Utils                            from "../../../../modules/utils";
import {Helmet}                         from "react-helmet";

export class NewChangeOrder extends Component
{
    constructor(props)
    {
        super(props);
        this.autoSaveOrDeleteChangeOrder = this.autoSaveOrDeleteChangeOrder.bind(this);
    }

    componentWillMount()
    {
        const {dispatch} = this.props;
        const id = this.props.match.params.id
        let payload ={id, currentUserId: this.props.user.data._id, history: this.props.history}
        payload.editMode = true

        dispatch(buildAction(CO.FIND_CO, payload))

        this.setState({ deleteOnUnmount: true })
        window.addEventListener('beforeunload', this.showWarning);
    }

    showWarning(e)
    {
        var message = 'Changes you have made might not be saved.';
        e.returnValue = message;
        return message;
    }

    autoSaveOrDeleteChangeOrder()
    {
        const {dispatch} = this.props;
        if (!this.state.deleteOnUnmount)
        {
            let newPageState = this.props.changeorders.changeForm
            let {co} = this.props.changeorders.changeForm
            dispatch(buildAction(CO.AUTO_SAVE_IN_DRAFT, {co, newPageState}))
        }

        if (this.state.deleteOnUnmount === true)
        {
            let co = this.props.changeorders.changeForm.co
            if (co)
            {
                let payload = {id: co._id, donotNavigateToSearch: true}
                dispatch(buildAction(CO.DELETE_CO, payload))
            }
        }
    }

    componentWillUnmount()
    {
        this.autoSaveOrDeleteChangeOrder();
        window.removeEventListener('beforeunload', this.showWarning);
    }

    componentWillReceiveProps(nextProps)
    {
        let { inputs } = nextProps.changeorders.changeForm

        if (inputs.enableDraft === true && this.state.deleteOnUnmount === true)
        {
            this.setState({ deleteOnUnmount: false })
        }
    }

    render()
    {
        let { inputs, list, searchResults } = this.props.changeorders.changeForm

        let { changeForm } = this.props.changeorders
        let { co }  = changeForm
        if(!co)
        {
            return null;
        }

        let markup =
            <div>
                <Helmet>
                    <title>{Utils.makeTitle("New Change Order")}</title>
                </Helmet>
                <ChangeOrderForm
                    mode="new"
                    changeForm={changeForm}
                    co={co}
                    inputs={inputs}
                    list={list}
                    searchResults={searchResults}
                    history={this.props.history}
                    dispatch={this.props.dispatch}
                    user={this.props.user}
                    users={this.props.users}
                    tabsType="cmp"
                />
            </div>
        return markup
    }
}

const mapDispatchToProps = dispatch => {
  return {dispatch}
};

const mapStateToProps = state => ({
    changeorders: state.changeorders,
    user: state.user,
    users: state.users
})

export default connect(mapStateToProps, mapDispatchToProps)(NewChangeOrder)
