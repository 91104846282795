// import                                "./index.css"
// import React, { Component }      from "react"
// import RouterLink                from "react-router-dom/Link"

// class Link extends Component
// {
//     render()
//     {

//         let class_name = "ui-link " + this.props.className
//         if (this.props.skipUILink && this.props.skipUILink == true) {
//           class_name = this.props.className
//         }
//         let markup =
//             <RouterLink
//                 to={this.props.to}
//                 className={class_name}
//                 target={this.props.target}
//                 onClick={this.props.onClick}
//                 >
//                 {this.props.children}
//             </RouterLink>

//         return markup
//     }
// }

// Link.defaultProps =
// {
//     className : "",
//     target    : ""
// }

// export default Link









import                   "./index.css"
import React        from "react"
import { Link as RouterLink } from "react-router-dom"


const Link = (props) =>
{
    let class_name = "ui-link " + props.className
    if (props.skipUILink && props.skipUILink == true) {
      class_name = props.className
    }
    let markup =
        <RouterLink
            to={props.to}
            className={class_name}
            target={props.target}
            onClick={props.onClick}
            >
            {props.children}
        </RouterLink>

    return markup
}

// Link.defaultProps =
// {
//     className : "",
//     target    : ""
// }

export default Link
