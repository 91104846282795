import { PropsWithChildren, useEffect } from "react";
import { useToasts } from "../toasts";
import { gql, useLazyQuery, useMutation } from "@apollo/client";

const FETCH_NOTIFICATION_QUERY = gql`
query FetchNotifications {
  notification {
    fetchUnreads {
      agentID
      category
      id
      message
      notificationType
      target
      timestamp
    }
  }
}
`;

const MARK_AS_READ_MUTATION = gql`
mutation MarkAsRead($ids: [String!]!) {
  notification {
    markAsRead(ids: $ids) {
      id      
    }
  }
}
`;

const isPlatformNotifierEnabled = window.DURO_ENV?.platformNotifier?.enabled
  ?? process.env.REACT_APP_PLATFORM_NOTIFIER_ENABLED === "true";

const NOTIFIER_POLL_INTERVAL = 10000;

const noop = () => { };

export function PlatformNotifierProvider({ children }: PropsWithChildren<{}>) {
  const { enqueueToast } = useToasts();

  const [fetchUnreads] = useLazyQuery(FETCH_NOTIFICATION_QUERY, { fetchPolicy: "network-only" });
  const [markAsRead] = useMutation(MARK_AS_READ_MUTATION);

  useEffect(() => {
    if (!isPlatformNotifierEnabled) {
      return noop;
    }
    const intervalID = setInterval(async () => {
      try {
        const {
          loading,
          error,
          data,
        } = await fetchUnreads();
        if (!loading && !error && data) {
          const unreads = data?.notification?.fetchUnreads;
          if (unreads && unreads.length > 0) {
            for (const unread of unreads) {
              enqueueToast({
                key: unread.id,
                message: unread.message, // TODO: PLA-644: add formatting for notifications by type
                autoHideDuration: 15000,
                persist: true,
              } as any);
            }
            markAsRead({
              variables: {
                ids: data?.notification?.fetchUnreads.map((n: any) => n.id),
              },
            });
          }
        }
      }
      catch (e: any) {
        // TODO: PLA-643: add proper logging here for errors in polling.
        enqueueToast({
          message: `Failed to fetch notifications: ${e.message}`,
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    }, NOTIFIER_POLL_INTERVAL);
    return () => clearInterval(intervalID);
  }, [enqueueToast, fetchUnreads, markAsRead]);

  return <>{children}</>;
}
