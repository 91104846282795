import mapActions from '../helpers/mapActions';

export default mapActions(
    [
        "SET_RELEASE_OBJECT",
        "RESET_RELEASE",
        "FIND_RELEASE"
    ],
    "RELEASE"
)
