import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
    >
      <g fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fillRule="nonzero" transform="translate(-883 -92)">
          <g transform="translate(875 84)">
            <g>
              <path d="M15.59 18.023a.47.47 0 01-.318-.122l-2.636-2.418a.474.474 0 01.634-.704l2.289 2.113 6.612-7.26a.473.473 0 11.704.63l-6.93 7.608a.47.47 0 01-.325.153h-.03zm5.99-4.96a6.59 6.59 0 11-1.644-2.387l.632-.704a7.5 7.5 0 101.715 2.33l-.704.761z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
