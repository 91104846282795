import { Button } from "@mui/material";
import { MouseEventHandler } from "react";
import { TileContentLabel, TileContentWrapper } from "./tileContent";

interface TileContentLinkProps {
  buttonLabel: string;
  label?: string;
  onClick: MouseEventHandler
}

export function TileContentAction(props: TileContentLinkProps) {
  const { buttonLabel, label, onClick } = props;

  return (
    <TileContentWrapper>
      <TileContentLabel label={label} />
      <Button onClick={onClick}>
        {buttonLabel}
      </Button>
    </TileContentWrapper>
  );
}
