import { FC } from "react";
import { Box, styled } from "@mui/material";
import { DuroZen } from "assets/icons";

export const DuroZenOverlay: FC<{ subtitle: string }> = ({ subtitle }) => (
  <OverlayWrapper>
    <ZenImage />
    <Subtitle>{subtitle}</Subtitle>
  </OverlayWrapper>
);

const Subtitle = styled(Box)({
  width: "19.063rem",
  textAlign: "center",
  marginTop: "1rem",
});

const OverlayWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  padding: "2rem 0",
  alignItems: "center",
  "& svg": {
    color: theme.palette.primary.main,
  },
}));

const ZenImage = styled(DuroZen)({
  width: "6.625rem",
  height: "7.313rem",
});
