import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
      <g data-name="Layer 2">
        <g>
          <path d="M17.5 26.74L0 8.26 35 8.26 17.5 26.74z"></path>
          <path d="M0 0H35V35H0z" className="cls-2"></path>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
