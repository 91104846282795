import                              "./index.css";
import ToastIcon from "../../../../../assets/icons/toast-modal-icon";
import InlineIcon from "../../../../ui/icon/inline-icon.js";
import React, { useEffect } from "react";
import { Popover } from 'react-tiny-popover';
import Utils   from "../../../../../modules/utils";

const CoTemplatePopOver = (props) => {

    const {hidePopOver, isOpen, template, action, setPreviousData, coRule} = props;
    const {coType, status} = Utils.splitCoRuleCombination(coRule)

    useEffect(() => {
        if(isOpen) {
            setPreviousData()
            setTimeout(() => {
                hidePopOver()
            }, 3000);
        }
    }, [isOpen, setPreviousData, hidePopOver])

    return(
        <Popover
            padding={60}
            containerClassName={`cmp-success-popover custom-popup-pos`}
            isOpen={isOpen}
            positions={["bottom", "left"]}
            align="end"
            onClickOutside={hidePopOver ? hidePopOver : ''}
            content={({ position, targetRect, popoverRect }) => (
                <div className="popover-content-block">
                    <div className="popover-content-header">
                        <InlineIcon className="rule-defined-icon"> <ToastIcon/> </InlineIcon>
                        <h4 className='popover-heading'>{`Mandatory Approvers ${Utils.capitalizeString(action)}`}</h4>
                    </div>
                    <div className="popover-content-body">
                     <div><span className="bold-text">Change Order Type:</span> <i>{coType.toUpperCase()}</i></div>
                     <div><span className="bold-text">Status:</span> <i>{status}</i></div>
                     <div><span className="bold-text">Approver Template:</span> <i>{template.templateName}</i></div>
                     <div>{action !== 'ADDED' ? <i>The approver list has been reset.</i>: ''}</div>
                    </div>
                </div>
            )}
        >
        <div></div>
        </Popover>
    )
}
export default CoTemplatePopOver;
