import                       "./index.css"
import React, {Component}   from "react"
import PerfectScrollbar     from 'react-perfect-scrollbar';
import ReactTooltip         from 'react-tooltip'
import Utils                from "../../../../../../../modules/utils"
import Schemas              from "../../../../../../../modules/schemas"
import API                  from "../../../../../../../modules/api"
import InlineIcon           from "../../../../../../ui/icon/inline-icon.js"
import ToggleBtn            from "../../../../../../ui/toggle-btn"
import InfoIcon             from "../../../../../../../assets/icons/info"
import ArrowIcon            from "../../../../../../../assets/icons/pagination"
import CheckIcon            from "../../../../../../../assets/icons/check-icon"
import FlagIcon             from "../../../../../../../assets/icons/flag"
import ModalBox from "../../../../../../ui/modal-box"
import SubmitBtn            from "../../../../../../ui/spinner-button"

class MapScreen extends Component
{
    constructor(props)
    {
        super(props)

        this.state =
        {
            is_processing: true,
            inputs :
            [

            ],
            checkboxState : "",
            checkboxSelected : "",
            submit :
            {
                class   : "disabled",
                message : ""
            },
            mpnImportText :
            {
                class: ""
            },
            mpnImportDialog:
            {
                class: "in-active",
                importBtn: this.props.mpnImportDialog.flag
            },
            prevHeadings: this.props.prevHeadings
        }
        this.componentWillMount        = this.createInputs
        this.createInputs              = this.createInputs.bind(this)
        this.checkCheckBoxState        = this.checkCheckBoxState.bind(this)
        this.onInputChange             = this.onInputChange.bind(this)
        this.used                      = this.used.bind(this)
        this.getOptions                = this.getOptions.bind(this)
        this.validate                  = this.validate.bind(this)
        this.onSubmit                  = this.onSubmit.bind(this)
        this.next                      = this.next.bind(this)
        this.getData                   = this.getData.bind(this)
        this.addAliases                = this.addAliases.bind(this)
        this.onModalClose              = this.onModalClose.bind(this)
        this.toggleButtonSelectedState = this.toggleButtonSelectedState.bind(this)
        this.haveFieldName             = this.haveFieldName.bind(this)
        this.getHeadingsList           = this.getHeadingsList.bind(this)
        this.getKey                    = this.getKey.bind(this)
        this.getHeadingFromOurHeadings = this.getHeadingFromOurHeadings.bind(this)
        this.setScrollHight            = this.setScrollHight.bind(this)
        this.setMpnFlag                = this.setMpnFlag.bind(this)
    }


    componentDidUpdate(prevProps, prevState)
    {
        this.setScrollHight()
    }

    setMpnFlag(event)
    {
        let mpnImportDialog = !event.target.props.value
        this.props.setMpnFlag(mpnImportDialog)
        let state = this.state
        state.mpnImportDialog.importBtn = mpnImportDialog
        this.setState(state)
        this.validate()
    }

    setScrollHight()
    {
        let height = (document.getElementsByClassName("modal")[0].clientHeight - 50) + 'px'
        document.getElementsByClassName("map-screen-scroll")[0].style.height = height
    }

    componentDidMount(){
        ReactTooltip.rebuild()
        this.setScrollHight()
        this._ismounted = true
    }

    componentWillUnmount(){
        this._ismounted = false
    }

    setMyState(state = this.state, cb){
        if (this._ismounted){
            this.setState(state, cb)

        }
    }

    createInputs()
    {
        API.headings.findByCompany((err, data) =>
        {
            if(err)
            {
                // TODO: handle upload errors
                this.state.is_processing = false
                this.state.message = "Sorry, something went wrong."
                this.setMyState(this.state)
                return console.error(err)
            }

            let aliases      = data
            let inputs       = []
            let keys         = {}
            let uniqueKeys   = []
            let hasAliases   = aliases.length > 0 ? true : false
            let fileHeadings = this.props.data.headings

            aliases.forEach((alias) =>
            {
                keys[alias.key.toLowerCase()] = alias.value.toLowerCase()
            })

            let prevHeadings = this.state.prevHeadings
            fileHeadings.forEach((fileHeading) =>
            {
                let key = this.getKey(keys, fileHeading)
                let isAlreadyMapped = prevHeadings.length && prevHeadings.find(heading => heading === key);
                let inputValue = key
                let input =
                {
                    valid   : Boolean(key),
                    value   : inputValue,
                    class   : isAlreadyMapped !== undefined && inputValue ? "valid" : "invalid disabled"
                }

                input.active = input.class === "valid"
                inputs.push(input)
                uniqueKeys.push(key)
            })

            this.state.inputs = inputs
            this.state.is_processing = false
            this.setMyState(this.state, () =>
                    {
                        this.validate()
                        this.checkCheckBoxState()
                    }
                )
        })

        let data   = this.props.data.headings
        let inputs = this.state.inputs

        data.forEach((heading) =>
        {
            let input =
            {
                valid   : Boolean(heading.key),
                class   : heading.key ? "valid" : "invalid disabled",
                value   : heading.key
            }
            input.active = input.class === "valid"

            inputs.push(input)
        })

        this.setMyState(this.state, this.validate)
        this.checkCheckBoxState()
    }

    getKey(aliases, headingKey)
    {
       let key1 = ""
       let aliasKeys = Object.keys(aliases)
       for(let i = 0; i < aliasKeys.length; i++)
       {
           let aliasValue = aliasKeys[i]
           let alias = aliases[aliasValue.toLowerCase()].toLowerCase()
           if(aliasValue === headingKey.toLowerCase() )
           {
               key1 = alias
               break
           }
       }

       if (key1 && this.getHeadingFromOurHeadings(key1) !== "")
           return key1.toLowerCase()
       else
       {
           return this.getHeadingFromOurHeadings(headingKey)
       }
   }

   getHeadingFromOurHeadings(headingKey="")
   {
        let selectKeys = this.getHeadingsList()
        let key = ""
        for(let i = 0; i < selectKeys.length; i++)
        {
            let selectKey = selectKeys[i]
            if(selectKey.value.toLowerCase() === headingKey.toLowerCase() && selectKey[this.props.fileType] === true)
            {
                key = selectKey.value
                break
            }
            else
                key = ""
        }
        return key.toLowerCase()
    }

    checkCheckBoxState()
    {
        let activeToggles = 0

        this.state.inputs.forEach((input) =>
        {
            if (input.active)
            {
                activeToggles++;
            }
        })

        if (activeToggles > 0 && activeToggles < this.state.inputs.length)
            this.state.checkboxState = "middle"
        else if (activeToggles === this.state.inputs.length)
            this.state.checkboxState = "all"
        else
            this.state.checkboxState = ""
    }

    toggleButtonSelectedState(){
        if (this.state.checkboxState === "all")
            return true
        else if (this.state.checkboxState === "" || this.state.checkboxState === "middle")
            return false
    }

    haveFieldName(name){
        let haveField = false
        for(let i = 0; i < this.state.inputs.length; i++)
        {
            let input = this.state.inputs[i]
            if(input.value === name && input.active)
            {
                haveField = true
                break;
            }
        }
        return haveField
    }

    onInputChange(event, i)
    {
        let name  = event.target.name
        let value = event.target.value
        let input = this.state.inputs[i]
        switch(name)
        {
            case "mapAll" :
            {
                // this.state.checkboxState = !this.state.checkboxState
                if (this.state.checkboxState === "middle")
                    this.state.checkboxState = "all"
                else
                    this.state.checkboxState = this.state.checkboxState === "all" ? "" : "all"

                if (this.state.checkboxState === "all")
                {
                    this.state.inputs.forEach((input) =>
                    {
                        input.active = true
                        input.class= ""
                    })
                }
                else
                {
                    this.state.inputs.forEach((input) =>
                    {
                        input.active = !input.active
                        input.class  = input.active ? "" : "disabled"
                        input.valid  = Boolean(input.active && input.value)
                        if (input.active && !input.value)
                        {
                            input.class = "invalid"
                        }
                    })
                }
                break
            }
            case "key-select" :
            {
                input.value = value
                input.class = input.value ? "valid" : "invalid"
                input.valid = Boolean(input.value)
                input.active = true
                this.checkCheckBoxState()
                break
            }

            case "toggle-btn" :
            {
                input.active = !input.active
                input.class  = input.active ? "" : "disabled"
                input.valid  = Boolean(input.active && input.value)
                if (input.active && !input.value)
                {
                    input.class = "invalid"
                }
                this.checkCheckBoxState()
                break
            }

            default :
            {
                // noop
            }
        }

        let state = this.state

        if(input && input.value === "mpn")
        {
            this.props.setMpnFlag(input.active)
            state.mpnImportDialog.importBtn = input.active
        }

        this.setState(state, this.validate)
    }

    used(value)
    {
        let inputs = this.state.inputs

        for(let i=0; i<inputs.length; i++)
        {
            let input = inputs[i]

            if(input.value === value)
            {
                return true
            }
        }

        return false
    }

    getHeadingsList()
    {
        return Schemas.component.keys.allList(this.props.fileType, this.props.currentlyOpenedCategory, this.props.displayRefDesAndItemNumber)
    }

    getOptions(value)
    {
        let keys = this.getHeadingsList()

        let list = [{name: "", displayName: "Select"}]

        keys.forEach((key) =>
        {
            if(key.value === value)
            {
                return list.push(key)
            }

            return list.push(key)
        })

        return Utils.toOptions(list)
    }

    validate()
    {
        let submit               = this.state.submit
        let inputs               = this.state.inputs
        let valid                = false
        let hasCat               = false
        let hasName              = false
        let hasParent            = false
        let hasQuantity          = false
        let hasItemNumber        = false
        let hasCPN               = false
        let hasMPN               = false
        let hasDPN               = false
        let hasManufacturer      = false
        let hasDistributor       = false
        let hasMinQuantity       = false
        let hasPrice             = false

        let hasMfrDescription    = false
        let hasDistDescription   = false
        let hasQuoteLeadtime     = false
        let hasQuoteLeadtimeUnit = false
        let hasPackage           = false
        let hasPackageQuantity   = false

        let hasRefDes            = false
        let hasSelections        = true
        let duplicateFree        = true
        let mappedLabels         = []

        inputs.forEach((input) =>
        {
            if (input.class !== "disabled")
            {
                mappedLabels.push(input.value)

                if(!input.valid && input.active)
                {
                    valid = false
                }

                if(input.value === "category" && input.class !== "disabled")
                {
                    hasCat = true
                }

                // if(input.value === "parent" && input.class !== "disabled")
                // {
                //     hasParent = true
                // }

                if(this.props.fileType === "new_assembly" || this.props.fileType === "update_assembly")
                {
                    hasParent = true
                }

                if(input.value === "cpn" && input.class !== "disabled")
                {
                    hasCPN = true
                }

                if(input.value === "name" && input.class !== "disabled")
                {
                    hasName = true
                }

                if(input.value === "quantity" && input.class !== "disabled")
                {
                    hasQuantity = true
                }

                if(input.value === "item number" && input.class !== "disabled")
                {
                    hasItemNumber = true
                }

                if(input.value === "ref des" && input.class !== "disabled")
                {
                    hasRefDes = true
                }

                if(input.value === "distributor" && input.class !== "disabled")
                {
                    hasDistributor = true
                }

                if(input.value === "manufacturer" && input.class !== "disabled")
                {
                    hasManufacturer = true
                }

                if(input.value === "mpn" && input.class !== "disabled")
                {
                    hasMPN = true
                }

                if(input.value === "dpn" && input.class !== "disabled")
                {
                    hasDPN = true
                }

                if(input.value === "min quantity" && input.class !== "disabled")
                {
                    hasMinQuantity = true
                }

                if(input.value === "price" && input.class !== "disabled")
                {
                    hasPrice = true
                }

                if(input.value === "mfr description" && input.class !== "disabled")
                {
                    hasMfrDescription = true
                }

                if(input.value === "dist description" && input.class !== "disabled")
                {
                    hasDistDescription = true
                }

                if(input.value === "quote lead time" && input.class !== "disabled")
                {
                    hasQuoteLeadtime = true
                }

                if(input.value === "quote lead time unit" && input.class !== "disabled")
                {
                    hasQuoteLeadtimeUnit = true
                }

                if(input.value === "package" && input.class !== "disabled")
                {
                    hasPackage = true
                }

                if(input.value === "package quantity" && input.class !== "disabled")
                {
                    hasPackageQuantity = true
                }

                if
                (
                    (hasName && !hasQuantity && !hasRefDes) ||
                    (hasName && !hasQuantity) ||
                    (hasName && !hasRefDes) ||
                    (hasCPN && !hasParent) ||
                    (hasCPN && hasParent && hasQuantity)
                )
                {
                    valid = true
                }
                else if (this.props.fileType === "new_assembly" && hasName && hasQuantity)
                {
                    valid = true
                }

                if (input.value === "" && input.active && input.class !== "disabled")
                {
                    hasSelections = false
                    input.class = "invalid"
                }

            }
        })

        mappedLabels.sort()
        for (var i = 0; i < mappedLabels.length; ++i)
        {
            if(mappedLabels[i] !== "" && mappedLabels[i] !== undefined)
            {
                if (mappedLabels[i + 1] === mappedLabels[i])
                {
                    duplicateFree = false
                    valid = false
                }
            }

        }

        submit.message = ""

        if (hasParent && !hasQuantity)
        {
            submit.message = "Quantity column is required"
        }
        else if (hasParent && Utils.isItemNumber(this.props.currentlyOpenedCategory) && !window.__isAllowedBlankItemNumber && !hasItemNumber)
        {
            submit.message = "Item Number column is required"
        }
        else if ((!hasCPN && hasParent && hasQuantity && this.props.fileType !== "new_assembly" && this.props.fileType !== "update_assembly") || (!hasCPN && hasParent && this.props.fileType !== "new_assembly" && this.props.fileType !== "update_assembly"))
        {
            submit.message = "CPN column is required"
        }
        else if ((hasQuantity && hasRefDes && !hasParent) || (hasQuantity && !hasParent) || (hasRefDes && !hasParent))
        {
            submit.message = "Parent column is required if including quantity or Ref Des"
        }
        else if(hasMPN && !hasManufacturer && !this.props.mpnImportDialog.flag)
        {
            submit.message = "Manufacturer is required if adding MPN"
        }

        else if (hasManufacturer && !hasMPN)
        {
            submit.message = "MPN is required if adding manufacturers "
        }

        else if (hasMfrDescription && !hasManufacturer)
        {
            submit.message = "Manufacturer is required if adding manufacturer's description"
        }

        else if (hasDistributor && !hasDPN)
        {
            submit.message = "DPN is required if adding distributor"
        }

        else if (hasDPN && !hasDistributor)
        {
            submit.message = "Distributor is required if adding DPN"
        }

        else if (hasDistributor && !hasManufacturer)
        {
            submit.message = "Manufacturer is required if adding distributors"
        }

        else if (hasDistDescription && !hasDistributor)
        {
            submit.message = "Distributor is required if adding distributor's description"
        }

        else if ((hasPackage || hasPackageQuantity) && !hasDistributor)
        {
            submit.message = "Distributor is required if adding package information"
        }

        else if (hasPackage && !hasPackageQuantity)
        {
            submit.message = "Package quantity is required if adding package"
        }

        else if (hasPackageQuantity && !hasPackage)
        {
            submit.message = "Package type is required if adding package quantity"
        }

        else if ((hasMinQuantity || hasPrice || hasQuoteLeadtime || hasQuoteLeadtimeUnit) && (!hasDistributor || !hasManufacturer))
        {
            submit.message = "Manufacturer and Distributor are required if adding quotes information"
        }

        else if ((hasMinQuantity || hasPrice || hasQuoteLeadtime || hasQuoteLeadtimeUnit) && (!hasDistributor || !hasManufacturer))
        {
            submit.message = "Manufacturer and Distributor are required if adding quotes information"
        }

        else if (hasPrice && (!hasMinQuantity))
        {
            submit.message = "Min quantity is required if adding price"
        }

        else if ((hasQuoteLeadtimeUnit || hasQuoteLeadtime) && !hasMinQuantity)
        {
            submit.message = "Min quantity of quote is required if adding quote's leadtime information"
        }

        else if ((hasQuoteLeadtimeUnit && !hasQuoteLeadtime ) || (!hasQuoteLeadtimeUnit && hasQuoteLeadtime ))
        {
            submit.message = "Both quote leadtime and leadtime unit are required if adding quote's leadtime information"
        }

        else if (!duplicateFree)
        {
            submit.message = "Cannot have duplicate columns mapped"
        }
        else if (!hasSelections)
        {
            submit.message = "Please map all selected columns"
        }
        else if (!valid)
        {
            submit.message = "Please resolve errors"
        }

        if (["new", "new_assembly"].includes(this.props.fileType) && !hasName){
            submit.message = "Name column is required"
        }

        if (["update", "update_assembly"].includes(this.props.fileType) && !hasCPN){
            submit.message = "CPN column is required"
        }

        if(hasMPN) {
            this.state.mpnImportDialog.class = ""
            this.state.mpnImportText.class  = "hidden"
        }

        if(!hasMPN) {
            this.state.mpnImportDialog.class = "in-active"
            this.state.mpnImportText.class  = ""
            this.state.mpnImportDialog.importBtn = false
        }

        submit.class = submit.message ? "disabled" : ""
        this.setState(this.state)
    }

    onSubmit(event)
    {

        event.preventDefault()
        let state = this.state

        let submit = state.submit

        if(submit.class === "disabled") return
        submit.class = "disabled"
        this.addAliases()
        this.next()
    }

    next()
    {
        let mappingData = this.props.data
        let data  = this.getData()
        let event = Utils.getEvent(this, data)
        event.target.mappingData = mappingData
        event.fileType = this.props.fileType
        this.props.next(event)
    }

    getData()
    {
        let passed = Utils.clone(this.props.data)
        let inputs = this.state.inputs
        let data   = {headings: [], rows:[]}

        passed.headings.forEach((heading, i) =>
        {
            let input = inputs[i]

            if(input.active)
            {
                data.headings.push(input.value)

                passed.rows.forEach((row, r) =>
                {
                    data.rows[r] = data.rows[r] || []
                    data.rows[r].push(row[i])
                })
            }
        })
        return data
    }

    addAliases()
    {
        let inputs   = this.state.inputs
        let headings = this.props.data.headings
        let data     = []

        inputs.forEach((input, i) =>
        {
            let heading = headings[i]
            if(!input.active) return
                data.push({value: input.value.toLowerCase(), key: heading.toLowerCase()})

        })

        API.headings.create(data, Utils.noop)
    }

    onModalClose(e)
    {
        e && e.preventDefault();
        this.props.next(undefined);
    }

    render()
    {
        let data   = this.props.data
        let inputs = this.state.inputs
        let submit = this.state.submit
        let lable_mapped_class = this.state.submit.class ? "warning" : "lable-mapped"
        let mpn_import_grey = this.state.mpnImportDialog.importBtn ? "" : " toggle_off "
        let progressIcon = this.state.submit.class ?
                <InlineIcon key="flag-icon" className="flag-icon"><FlagIcon /></InlineIcon> :
                <InlineIcon key="check-icon" className="check-icon"><CheckIcon /></InlineIcon>

        let markup =
            <div className={"large-modal map-screen-modal " + (this.state.is_processing ? "processing" : "processed")}>
            <ModalBox onClose={this.props.onClose}>
                <div className="map-screen">
                    <div className="form-holder">
                        <form onSubmit={this.onSubmit}>
                            <header className={lable_mapped_class + " modal-header"}>
                                <div className="header-heading">
                                {
                                    progressIcon
                                }


                                <h3>{this.state.submit.class ? "Map required fields." : ( this.props.fileType === "new" ? "Required labels have been mapped. Note: a Category label will be added." : "Required labels have been mapped.")}</h3>
                                </div>
                                <div className="btn-holder">
                                    <button
                                        className="map-screen-back"
                                        onClick={this.onModalClose}>
                                        Back
                                    </button>
                                    <SubmitBtn
                                        onClick={this.onSubmit}
                                        className={submit.class + " btn-header" + (lable_mapped_class !== "warning" ? " active" : "")}
                                      />
                                </div>
                            </header>
                            <PerfectScrollbar className="map-screen-scroll map-screen-content">

                            <div className="map-screen-content-holder">
                                 <div className={this.state.mpnImportDialog.importBtn ? "hidden" : ""}>
                                    <div className="notes-message">
                                        <InlineIcon>
                                          <InfoIcon />
                                        </InlineIcon>
                                        <div>
                                            <i>
                                                A richer data set is imported when Manufacuturer Part Numbers (MPN) are used
                                            </i>
                                        </div>
                                    </div>
                                </div>
                            <h1>Map your fields to Duro</h1>

                            <p> Import requires the following labels mapped: </p>


                            <div className="required">
                                {
                                    ["new", "new_assembly"].includes(this.props.fileType) ?
                                    <div className="fields">
                                        <span className={ this.haveFieldName("name") ? "activated" : ""}>
                                        <InlineIcon>
                                          <CheckIcon />
                                        </InlineIcon>
                                            Name
                                        </span>
                                        {
                                            this.props.fileType === "new_assembly" ?
                                             <span className={ this.haveFieldName("quantity") ? "activated" : ""}>
                                             <InlineIcon>
                                               <CheckIcon />
                                             </InlineIcon>
                                                Quantity
                                            </span> : ""
                                        }

                                        {
                                            this.props.fileType === "new_assembly" && Utils.isItemNumber(this.props.currentlyOpenedCategory) && !window.__isAllowedBlankItemNumber &&
                                             <span className={ this.haveFieldName("item number") ? "activated" : ""}>
                                             <InlineIcon>
                                               <CheckIcon />
                                             </InlineIcon>
                                                Item Number
                                            </span>
                                        }

                                        <span className={ this.haveFieldName("category") ? "activated" : ""}>
                                        <InlineIcon>
                                          <CheckIcon />
                                        </InlineIcon>
                                            Category <span className="optional custom-muted-text">(optional)</span>
                                        </span>
                                    </div>
                                         :
                                    <div className="fields">
                                        <span className={ this.haveFieldName("cpn") ? "activated" : ""}>
                                        <InlineIcon>
                                          <CheckIcon />
                                        </InlineIcon>
                                            CPN
                                        </span>
                                        {
                                            this.props.fileType === "update_assembly" ?
                                             <span className={ this.haveFieldName("quantity") ? "activated" : ""}>
                                             <InlineIcon>
                                               <CheckIcon />
                                             </InlineIcon>
                                                Quantity
                                            </span> : ""
                                        }

                                        {
                                            this.props.fileType === "update_assembly" && Utils.isItemNumber(this.props.currentlyOpenedCategory) && !window.__isAllowedBlankItemNumber &&
                                             <span className={ this.haveFieldName("item number") ? "activated" : ""}>
                                             <InlineIcon>
                                               <CheckIcon />
                                             </InlineIcon>
                                                Item Number
                                            </span>
                                        }


                                    </div>

                                }
                            </div>

                            <div className= {"mpn-import " + this.state.mpnImportDialog.class + mpn_import_grey }>
                                <span className="mpn-text">
                                    <p>
                                        We found Manufacturer Part Numbers (MPN) in your file. Import data using MPN?
                                    </p>
                                </span>

                                <ToggleBtn
                                    name="toggle-btn"
                                    selected={ this.state.mpnImportDialog.importBtn}
                                    value={ this.state.mpnImportDialog.importBtn}
                                    onChange={(e) => this.setMpnFlag(e)}
                                />

                            </div>

                            <div className="message ui-message invalid">
                                <p className={submit.message ? "invalid" : ""}>
                                    {submit.message}
                                </p>
                            </div>

                            <table>
                                <tbody>
                                    <tr>
                                        <th>
                                            <div data-tip="The column headers found in your file">Your labels</div>
                                            <div><span className="custom-muted-text">From your file</span></div>
                                        </th>
                                        <th></th>
                                        <th>
                                            <div data-tip="The application field names">Our labels</div>
                                            <div><span className="custom-muted-text">In Duro</span></div>
                                        </th>
                                        <th>
                                            <div className="importToggle">
                                                <ToggleBtn
                                                    name="mapAll"
                                                    class={this.state.checkboxState}
                                                    selected={this.toggleButtonSelectedState()}
                                                    onChange={this.onInputChange}
                                                />
                                                <label htmlFor="mapAll" />
                                            </div>
                                        </th>
                                    </tr>
                                    {
                                        data.headings.map((heading, i) =>
                                        {
                                            let input = inputs[i]
                                            let row =
                                                <tr key={i}>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            readOnly={true}
                                                            className={!input.active ? "disabled" : ""}
                                                            // value={heading.value}
                                                            value={data.headings[i]}
                                                        />
                                                    </td>
                                                    <td>
                                                      <InlineIcon>
                                                        <ArrowIcon />
                                                      </InlineIcon>
                                                    </td>
                                                    <td>
                                                        <select
                                                            name={"key-select"}
                                                            className={input.class + (!input.value ? " placeholder" : "")}
                                                            value={input.value}
                                                            onChange={(e) => this.onInputChange(e, i)}
                                                            >
                                                            {this.getOptions(data.headings[i])}
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <div data-tip={input.active ? "Import" : "Do not import"}>
                                                            <ToggleBtn
                                                                name="toggle-btn"
                                                                selected={input.active}
                                                                onChange={(e) => this.onInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            return row
                                        })
                                    }
                                </tbody>
                            </table>
                            </div>
                        </PerfectScrollbar>
                        </form>
                    </div>

                </div>
            </ModalBox>
            </div>

        return markup
    }
}



export default MapScreen
