import { SvgIcon } from "@mui/material";

export const ReleaseIcon = () => (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fillRule="evenodd">
            <g fillRule="nonzero">
                {/* eslint-disable-next-line max-len */}
                <path d="M8 7.556H6v7.777c0 1.223.9 2.223 2 2.223h9v-2.223H8V7.556z M20 2h-8c-1.1 0-2 1-2 2.222v6.667c0 1.222.9 2.222 2 2.222h8c1.1 0 2-1 2-2.222V4.222C22 3 21.1 2 20 2zM4 12H2v7.778C2 21 2.9 22 4 22h9v-2.222H4V12z" />
            </g>
        </g>
    </SvgIcon>
);
