import Utils    from "../utils"

class ComponentProductUtils
{
    static makeEditLink(object, type="")
    {
        if (type === 'productionInstance')
        {
            return `/production/instance/edit/${object._id}`
        }
        if (object.alias === 'prd')
        {
            return `/product/edit/${object._id}`
        }
        else if (object.alias === 'cmp')
        {
            return `/component/edit/${object._id}`
        }
        else if(object.alias === 'co')
        {
            return `/changeorder/edit/${object._id}`
        }
    }

    static isRevisionPage(location)
    {
        location.pathname.includes("revision")
    }

    static isInOpenCo(object)
    {
        return !!object.co
    }



}

export default ComponentProductUtils
