export function getInitialState()
{
    return {
        inputs :
        {
            firstName :
            {
                message : "",
                class   : "",
                value   : "",
                valid   : false
            },

            lastName :
            {
                message : "",
                class   : "",
                value   : "",
                valid   : false
            },

            email :
            {
                message : "",
                class   : "",
                value   : "",
                valid   : false
            },

            role :
            {
                message : "",
                class   : "",
                value   : "USER",
                valid   : true
            },

            groups :
            {
                message : "",
                class   : "",
                value   : [],
                valid   : true
            },

            title :
            {
                message : "",
                class   : "",
                value   : "",
                valid   : true
            },

            submit :
            {
                class : "disabled"
            }
        },
        showInnerLoading: false,
        user:
        {
            email: ""
        }
    }
}

let initialState = getInitialState()
export default initialState
