
import { takeLatest, all, call, put, cps, select, takeLeading }  from 'redux-saga/effects';
import ASSEMBLY_EDIT                                from '../action-types/assembly-edit.js';
import UI                                           from '../action-types/ui.js';
import buildAction                                  from '../helpers/buildAction';
import utils                                        from '../modules/utils'
import validations, {validateField}                 from '../modules/validations';

export const assemblyState = state => state.assembly_edit
export const getValidationState = state => state.ui.isValidating

export function* updateAssembly(action) {
    if(yield select(getValidationState)) yield put(buildAction(UI.VALIDATION_END))
    // call api fghblock 3
    const assemblyEditState = yield select(assemblyState)
    // if (action.storeSync)
    yield put(buildAction(ASSEMBLY_EDIT.SET_CHILDREN, { children: action.children, modified: true}));
}

// export function* updateAssembly(action) {
//     const assemblyEditState = yield select(assemblyState)
//     yield put(buildAction(ASSEMBLY_EDIT.UPDATE_CHILDREN, { children: action.children }));
// }

export default function* (getState) {
    yield all([
        takeLatest(ASSEMBLY_EDIT.ON_INPUT_CHANGE, updateAssembly),
    ]);
}
