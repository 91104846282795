import { Box, styled } from "@mui/material";
import { useMemo } from "react";
import Utils from "v1/modules/utils";

export const enum DateTimeFieldFormat {
  DATE_LONG = "DATE_LONG",
  DATE_TIME_LONG = "DATE_TIME_LONG",
}

export interface TimestampFieldProps {
  dateTime?: string;
  format: DateTimeFieldFormat;
}

export function TimestampField({ dateTime, format }: TimestampFieldProps) {
  const { dateValue, timeValue } = useMemo(() => {
    const value = dateTime ? (new Date(dateTime)).getTime() : 0;

    switch (format) {
      case DateTimeFieldFormat.DATE_LONG:
      case DateTimeFieldFormat.DATE_TIME_LONG:
        return Utils.dateTimeWithLongFormat(value);
      default:
        return { dateValue: "", timeValue: "" };
    }
  }, [dateTime, format]);

  return (
    <Box>
      <StyledDate>{dateValue}</StyledDate>
      {format !== DateTimeFieldFormat.DATE_LONG && (
        <StyledTime>{timeValue}</StyledTime>
      )}
    </Box>
  );
}

const StyledDate = styled("span")(({ theme }) => ({
  color: theme.duro.fields.timestamp.dateColor,
  marginRight: "0.25rem",
}));

const StyledTime = styled("span")(({ theme }) => ({
  color: theme.duro.fields.timestamp.timeColor,
}));
