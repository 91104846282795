import                   "./index.css"
import React        from "react"
import DropZone     from "react-dropzone"
import InlineIcon   from "../icon/inline-icon.js"
import Config       from "../../../modules/config"
import Utils        from "../../../modules/utils"
import ArrowIcon     from "../../../assets/icons/pagination"
import CloseIcon     from "../../../assets/icons/cross-icon"
import API          from "../../../modules/api"
import GearIcon  from "../../../assets/icons/gear";
import Image         from "../image";

import {
    componentDefaultImg as defaultSrc
}                   from "../../../assets"

export class ImageViewerDynamic extends React.Component {

    constructor(props, context)
    {
        super(props, context);
        this.state =
        {
            class  : "selected",
            index  : 0,
            images : []
        }

        this.images = this.images.bind(this);
        this.previous = this.previous.bind(this);
        this.next = this.next.bind(this);
        this.update = this.update.bind(this);
        this.onFileDrop = this.onFileDrop.bind(this);
        this.onRemoveClick = this.onRemoveClick.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onPrimaryClick = this.onPrimaryClick.bind(this);
        this.setImageViewerState = this.setImageViewerState.bind(this)
        this.removeCanceledImages = this.removeCanceledImages.bind(this)
        this.findIndexOfCanceledImage = this.findIndexOfCanceledImage.bind(this)
        this.getThumbnailsIds = this.getThumbnailsIds.bind(this)
        this.isProcessedAllThumbnails = this.isProcessedAllThumbnails.bind(this)
        this.uploadThumbnails = this.uploadThumbnails.bind(this)
        this.setImagesObject = this.setImagesObject.bind(this)
    }

    setImageViewerState(state = this.state, payload){
        let fields = state
        if ( payload.index === 0 )
            fields.index = 0
        else
            fields.index = payload.index ? payload.index : state.index
        fields.class = payload.className ? payload.className : state.class
        fields.images = payload.images ? payload.images : state.images
        const data = Object.assign(state, fields);

        if (payload.callback)
            this.setState({...state, data}, payload.callback)
        else
            this.setState({...state, data})
    }

    componentWillUnmount()
    {
        let state = this.state
        state.images = []
        this.setState(state)
    }

    componentWillMount(){
        if (this.props.images){
            this.setImagesObject(this.props.images)
        }

        if(!this.props.edit && this.state.images.length < 1)
        {
            this.setImageViewerState(this.state, {images: [{src: defaultSrc}]} )
        }
    }

    setImagesObject(imagesIdz){
        let images = []
        let imageFile, info = {}
        for (let image of imagesIdz)
        {
            info = { id: image._id, processFlag: false, errors: [] }
            imageFile = {...image, info}
            images.push(imageFile)
        }
        this.state.images = images
        this.setState(this.state)
    }

    images()
    {
        return this.props.this.state && this.props.this.state.images
    }

    previous()
    {
        let index = this.state.index === 0
            ? this.state.images.length - 1
            : this.state.index - 1
        this.update(index, "left")
    }

    next()
    {
        let index = this.state.index < this.state.images.length - 1
            ? this.state.index + 1
            : 0
        this.update(index, "right")
    }

    update(imgIndex = 0, slide = "left")
    {
        const index = imgIndex
        const className = "selected slide-from-" + slide

        this.setImageViewerState(this.state, {index, className} )
    }

    onFileDrop(files)
    {
        if(files.length < 1) return
        let index = this.state.images.length
        let images = this.state.images

        files.forEach((file, index) =>
        {
            //To make the image object identical as in case of document
            file.fileId = `file-${index}`
            file.info = {
                id: null,
                processFlag: false,
                errors: []
            }
            images.push(file)
        })

        this.setImageViewerState(this.state, {index, images, callback: this.uploadThumbnails} )
    }

    onRemoveClick()
    {
        if(this.state.images.length < 1) return

        this.state.images.splice(this.state.index, 1)
        let images = this.state.images
        let index = 0
        if (images.length !== 0)
            index  = this.state.index > images.length - 1 ? this.state.index - 1 : this.state.index
        this.setImageViewerState(this.state, {index, images, callback: this.onChange} )
    }

    findIndexOfCanceledImage()
    {
        let index = null
        let counter = -1

        this.state.images.forEach((img) => {
            counter ++
            if(img.info && img.info.errors.length > 0)
            {
                index = counter
                return index
            }
        })
        return index
    }

    removeCanceledImages()
    {
        if(this.state.images.length < 1) return

        let i = this.findIndexOfCanceledImage()
        this.state.images.splice(i, 1)
        let images = this.state.images
        let index = 0
        if (images.length !== 0)
            index  = i > images.length - 1 ? i - 1 : i
        this.setImageViewerState(this.state, {index, images} )
    }

    getThumbnailsIds()
    {
        let imagesIdz = []
        let files = this.state.images
        files.forEach((file) => {
            if(file.info.id)
            {
                imagesIdz.push(file.info.id)
            }
        })
        return imagesIdz
    }

    getErrors()
    {
        let errors = []
        let files = this.state.images
        files.forEach((file) => {
            if (!file.info.id && file.info.errors.length > 0)
            {
                errors.push({file: file.name, errors: file.info.errors})
            }
        })
        return errors
    }

    isProcessedAllThumbnails()
    {
        let files = this.state.images
        let valid = true
        if (files && Array.isArray(files))
        {
            files.forEach(function(file)
            {
                if(file.info.processFlag === true)
                {
                    valid = false
                    return valid
                }
            })
        }
        return valid
    }

    isPresentInInputFiles(fileId)
    {
        let state = this.state
        let found = false
        for (let img of state.images){
            if (img.fileId === fileId)
            {
                found = true
                break;
            }
        }
        return found
    }

    uploadThumbnails()
    {
        this.props.onChange([], [], false)
        let notProcessedImgs = []
        let images = this.state.images
        for (let img of images){
            if (!img.info.id)
            {
                notProcessedImgs.push(img)
            }
        }
        let canceledImagesCount = 0
        let imgBatches = Utils.createBatches(images, 5)
        let index = 0
        let state = this.state

        let onComplete = (err, data) =>
        {
            if(++index === imgBatches.length)
            {
                if (this.isProcessedAllThumbnails())
                {
                    this.props.onChange && this.props.onChange(this.getThumbnailsIds(), this.getErrors(), true)
                    for(let i = 0; i < canceledImagesCount; i++) this.removeCanceledImages()
                }
                return
            }
            create(index)
        }

        let create = () =>
        {
            let counter = 0
            for (let img of imgBatches[index])
            {
                if (!img.info.id && img.info.errors.length === 0)
                {
                    img.info.processFlag = true
                    this.setState({images: images})

                    if (this.isPresentInInputFiles(img.fileId))
                    {
                        API.images.create(img, (err, res) =>
                        {
                            counter++
                            if(err)
                            {
                                img.info.errors = err.errors
                                canceledImagesCount++
                            }
                            else
                            {
                                img.info.id = res
                            }
                            img.info.processFlag = false
                            this.setState({images: images})
                            if (counter === imgBatches[index].length)
                            {
                                onComplete()
                            }
                        })
                    }
                    else
                    {
                        counter++
                        if (counter === imgBatches[index].length)
                        {
                            onComplete()
                        }
                    }
                }
                else
                {
                    counter++
                    if (counter === imgBatches[index].length)
                    {
                        onComplete()
                    }
                }
            }
        }
        create(0)
    }

    onChange()
    {
        if(this.props.onChange && this.isProcessedAllThumbnails())
        {
            this.props.onChange(this.getThumbnailsIds(), this.getErrors(), true)
        }
        else
        {
            this.props.onChange([], [], false)
        }
    }

    onPrimaryClick(event)
    {
        let target = this.state.images[this.state.index]
        this.state.images.splice(this.state.index, 1)
        this.state.images.unshift(target)

        let images = this.state.images
        let index = 0

        this.setImageViewerState(this.state, {index, images, callback: this.onChange} )
    }

    render()
    {
        let imageNodes = []
        const maxFileSize = Config.MAX_FILE_SIZE;
        let tooltipText = this.props.vendorTooltipText;
        this.state.images.forEach((image, i) =>
        {
            let selected   = i === this.state.index
            let src        = image.src || image.preview
            if(this.props.defaultResolution && image.variants && image.src)
            {
                src = image.variants[this.props.defaultResolution];
            }
            let imageBlock =
                <Image
                    keyValue={i}
                    className={"image " + (selected ? this.state.class : "")}
                    src={src}
                >
                </Image>
            imageNodes.push(imageBlock)
        })

        let clazz = "ui-image-viewer"
            + (this.state.images.length === 0 ? " empty"  : "")
            + (this.state.images.length === 1 ? " single" : "")
            + (this.props.edit ? " edit" : "")

        let markup =

            <div className={clazz}>
                {
                    !this.props.isVendorCmp  &&
                    <div
                        className={"remove-btn"}
                        onClick={this.onRemoveClick}>
                        <InlineIcon>
                          <CloseIcon />
                        </InlineIcon>
                    </div>
                }
                {
                    !this.props.edit && this.props.images && this.props.images.length === 0 ?
                    <div className="default-img">
                        <InlineIcon>
                            <GearIcon />
                        </InlineIcon>
                    </div>
                    :
                    <div className="images">
                        {imageNodes}
                        <div className="image-copy">Add images?</div>
                    </div>
                }
                <div className="controls">
                    <div className="arrow previous" onClick={this.previous}>
                        <InlineIcon>
                          <ArrowIcon />
                        </InlineIcon>
                    </div>
                    <div className="count">
                        <span>{this.state.index + 1}</span>
                        <span className="of"> of </span>
                        <span>{this.state.images.length}</span>
                    </div>
                    <div className="arrow next" onClick={this.next}>
                        <InlineIcon>
                          <ArrowIcon />
                        </InlineIcon>
                    </div>
                </div>
                <div className="primary-block">
                    <input
                        type="checkbox"
                        name="primary"
                        checked={this.state.index === 0}
                        onChange={this.onPrimaryClick}
                    />
                    <label htmlFor="primary" />
                    <span>
                        <span>Primary image </span>
                    </span>
                </div>
                <div  data-tip={tooltipText} data-for="vendor-disable-input" data-place="right">
                    <DropZone
                        className="drop-zone"
                        activeClassName="accept-files"
                        rejectClassName="reject-files"
                        multiple={true}
                        accept={"image/*"}
                        onDrop={this.onFileDrop}
                        maxSize={maxFileSize}
                        >
                        <div>
                            <div>
                                <span>DRAG FILES</span>
                                <span>Or browse</span>
                            </div>
                        </div>
                        <div className="modal"></div>
                    </DropZone>
                </div>
            </div>

        return markup
    }
}

ImageViewerDynamic.defaultProps =
{
    images : []
}

export default ImageViewerDynamic
