import { SvgIcon } from "@mui/material";

export const HelpIcon = () => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg">
    <g fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path d="M7.333 12h1.334v-1.333H7.333V12zM8 1.333A6.67 6.67 0 0 0 1.333 8 6.67 6.67 0 0 0 8 14.667 6.67 6.67 0 0 0 14.667 8 6.67 6.67 0 0 0 8 1.333zm0 12A5.34 5.34 0 0 1 2.667 8 5.34 5.34 0 0 1 8 2.667 5.34 5.34 0 0 1 13.333 8 5.34 5.34 0 0 1 8 13.333zM8 4a2.666 2.666 0 0 0-2.667 2.667h1.334c0-.734.6-1.334 1.333-1.334s1.333.6 1.333 1.334c0 1.333-2 1.166-2 3.333h1.334c0-1.5 2-1.667 2-3.333A2.666 2.666 0 0 0 8 4z" />
    </g>
  </SvgIcon>
);
