import React from "react";
import InlineIcon from "../../../ui/icon/inline-icon";
import Icon from "../../../../assets/icons/valispace-icon";

const ValispaceIcon = (props) => {
  let {displayExportToVsIcon , toggleModal} = props;
  return(
    <InlineIcon
        tooltip={displayExportToVsIcon ? "Map Component to a Valispace component": "Mapping to Valispace is not permitted for this Component"}
        className= {!displayExportToVsIcon? "disabled" : ""}
        onClick={displayExportToVsIcon ? (e) => {toggleModal("displayValispaceImport", true)} : null}
    >
        <Icon />
    </InlineIcon>
  );
};
export default ValispaceIcon;
