import { styled } from "@mui/material";
import { colorPalette } from "theme";

const Divider = styled("hr")({
  border: "unset",
  borderBottom: `1px dashed ${colorPalette.taupeGray}`,
  margin: "0.25rem 0",
});

export function TileContentDivider() {
  return (
    <Divider />
  );
}
