import { GridEventListener, GridRowTreeNodeConfig } from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { Dispatch, MutableRefObject, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { GRID_BASE_HEIGHT, ROW_HEIGHT } from "./grid";

/**
 * Builds up the unique ID for a specific row. This deals with the fact that in
 * tree mode the same component show up twice under different parents, so it
 * needs to have it's ID built based off of its ancestry path.
 *
 * @param row The row to build the ID for.
 * @returns The final ID for the row.
 */
export function getRowId(row: { id?: string, _path?: string[] }): string {
  if (row._path?.length) return row._path.join("-");
  return row.id ?? "";
}

export function isCollapseDisabled(apiRef: MutableRefObject<GridApiPro>) {
  let disabled = true;
  apiRef.current.getVisibleRowModels().forEach(row => {
    const node = apiRef.current.getRowNode(getRowId(row));
    if (node?.childrenExpanded) {
      disabled = false;
    }
  });
  return disabled;
}

export function isExpandDisabled(apiRef: MutableRefObject<GridApiPro>) {
  let disabled = true;
  apiRef.current.getVisibleRowModels().forEach(row => {
    const node = apiRef.current.getRowNode(getRowId(row));
    if (!node?.childrenExpanded && row._descendantCount > 0) {
      disabled = false;
    }
  });
  return disabled;
}

/**
 * Updates all visible nodes with children to have their expanded state set to
 * the given value.
 *
 * @param apiRef The reference to the Grid API.
 * @param state The state to move them to (expanded or collapsed).
 */
export function setVisibleNodeExpansionState(apiRef: MutableRefObject<GridApiPro>, state: boolean) {
  apiRef.current.getVisibleRowModels().forEach(row => {
    const rowId = getRowId(row);
    const node = apiRef.current.getRowNode(rowId);
    // If the nodes need to be expanded, the expand all rows that are not
    // expanded and have children.
    if (state && !node?.childrenExpanded && row._descendantCount > 0) {
      apiRef.current.setRowChildrenExpansion(rowId, state);
    }
    // If the nodes need to be collapsed, then collapse all rows that are
    // currently expanded.
    else if (!state && node?.childrenExpanded) {
      apiRef.current.setRowChildrenExpansion(rowId, state);
    }
  });
}

export interface DefaultRowData {
  _childrenLoaded?: Boolean;
  _descendantCount?: number;
  _path: string[];
  item?: { id: string };
}
export type GridExpansionGetChildren<RowData extends DefaultRowData> = (row: RowData) => Promise<RowData[]>;
export type GridExpansionPostExpansionAction<RowData extends DefaultRowData> = (
  node: GridRowTreeNodeConfig,
  row: RowData,
  children: RowData[],
) => void;

export interface UseGridExpansionProps<RowData extends DefaultRowData> {
  apiRef: MutableRefObject<GridApiPro>;
  getExpansionChildren: GridExpansionGetChildren<RowData>;
  setChildLoadingCount: Dispatch<SetStateAction<number>>;
  postExpansionAction?: GridExpansionPostExpansionAction<RowData>
}

/**
 * Handles the logic around expanding and collapsing nodes withing a grid in tree view.
 */
export function useGridExpansion<RowData extends DefaultRowData>(props: UseGridExpansionProps<RowData>) {
  const { apiRef, getExpansionChildren, postExpansionAction, setChildLoadingCount } = props;

  const [disableCollapse, setDisableCollapse] = useState(true);
  const [disableExpand, setDisableExpand] = useState(true);
  const [visibleRowCount, setVisibleRowCount] = useState(0);

  const updatePostExpansionChange = useCallback(() => {
    // Check to see is there is anything that can be expanded or collapsed
    setDisableCollapse(isCollapseDisabled(apiRef));
    setDisableExpand(isExpandDisabled(apiRef));

    // Set the number of visible rows when the expansion changes.
    setVisibleRowCount(apiRef.current.getVisibleRowModels().size);
  }, [apiRef]);

  // Adds the controls for expanding the part rows of the table in tree mode and loading the children.
  useEffect(() => {
    const handleRowExpansionChange: GridEventListener<"rowExpansionChange"> = async node => {
      const row = apiRef.current.getRow(node.id) as RowData | null;

      if (!node.childrenExpanded || !row?.item || row._childrenLoaded) {
        updatePostExpansionChange();
        return;
      }

      setChildLoadingCount(count => (count + 1));
      const placeholderNode = {
        id: `placeholder-children-${node.id}`,
        _path: [...row._path, `placeholder-children-${node.id}`],
      };
      apiRef.current.updateRows([placeholderNode]);

      try {
        const children = await getExpansionChildren(row);
        apiRef.current.updateRows([
          ...children,
          { id: node.id, _path: row._path, _childrenLoaded: true },
          { ...placeholderNode, _action: "delete" },
        ]);

        apiRef.current.setRowChildrenExpansion(node.id, true);

        if (postExpansionAction) postExpansionAction(node, row, children);

        updatePostExpansionChange();
      }
      finally {
        setChildLoadingCount(count => (count - 1));
      }
    };

    /**
     * By default, the grid does not toggle the expansion of rows with 0 children
     * We need to override the `cellKeyDown` event listener to force the expansion if there are
     * children on the server
     */
    const handleCellKeyDown: GridEventListener<"cellKeyDown"> = (params, event) => {
      const cellParams = apiRef.current.getCellParams(params.id, params.field);
      if (cellParams.colDef.type === "treeDataGroup" && event.key === " ") {
        event.stopPropagation();
        event.preventDefault();
        // eslint-disable-next-line no-param-reassign
        event.defaultMuiPrevented = true;

        apiRef.current.setRowChildrenExpansion(
          params.id,
          !params.rowNode.childrenExpanded,
        );
      }
    };

    apiRef.current.subscribeEvent("rowExpansionChange", handleRowExpansionChange);
    apiRef.current.subscribeEvent("cellKeyDown", handleCellKeyDown, { isFirst: true });
  }, [apiRef, getExpansionChildren, postExpansionAction, setChildLoadingCount, updatePostExpansionChange]);

  return {
    disableCollapse,
    disableExpand,
    setDisableCollapse,
    setDisableExpand,
    setVisibleRowCount,
    updatePostExpansionChange,
    visibleRowCount,
  };
}

/**
 * Calculates the height of the table based on the number of visible rows.
 *
 * @param visibleRowCount The number of rows that are currently visible in the table.
 * @param emptyHeight The height of the body of the table when the rows are empty.
 * @returns The height of the table to display.
 */
export function useGridHeight(visibleRowCount: number, emptyHeight: number) {
  return useMemo(() => {
    // When the table is empty, then make sure the empty instructions show.
    if (visibleRowCount === 0) return GRID_BASE_HEIGHT + emptyHeight;

    // Set the table height based on the number of visible rows.
    return GRID_BASE_HEIGHT + (visibleRowCount * ROW_HEIGHT);
  }, [visibleRowCount, emptyHeight]);
}
