import { Box, styled } from "@mui/material";
import { useDefaultCurrencySymbol, useUnitPricePrecision } from "common/hooks";
import { useMemo } from "react";
import SourcingUtils from "v1/components/page/common/sourcing/sourcing-utils";

export interface CurrencyFieldProps {
  currencySymbol?: string;
  precision?: number;
  value?: string | number | null;
}

export function CurrencyField(props: CurrencyFieldProps) {
  const { currencySymbol, precision, value } = props;
  const defaultCurrencySymbol = useDefaultCurrencySymbol();
  const unitPricePrecision = useUnitPricePrecision();
  const displayValue = useMemo(() => {
    const price = isNaN(Number(value))
      ? Number(0).toFixed(precision ?? unitPricePrecision)
      : SourcingUtils.numberWithCommas(Number(value).toFixed(precision ?? unitPricePrecision));
    return `${currencySymbol ?? defaultCurrencySymbol}${price}`;
  }, [currencySymbol, defaultCurrencySymbol, precision, unitPricePrecision, value]);

  return (
    <RightAlignedText>
      {displayValue}
    </RightAlignedText>
  );
}

const RightAlignedText = styled(Box)(() => ({
  flex: 1,
  textAlign: "right",
}));
