import {
            takeEvery,
            takeLatest,
            all,
            cps,
            put,
            select,
            call
        }                           from "redux-saga/effects"
import UI                           from "../../action-types/ui"
import ITEM_ASSEMBLY                from "../../action-types/item-assembly"
import buildAction                  from "../../helpers/buildAction"
import API                          from "../../modules/api"
import Utils                        from "../../modules/utils"
import {privatePaths}               from "../../app/routes.js"
import { getItemAssembly }          from "./selector"

function findAndUnmarkSelectedComponent(root)
{
    root.selectedClass = ""
    if (root.children.length > 0)
        root.children.forEach((c) => findAndUnmarkSelectedComponent(c))
    else
        return root
}

function* findFirstChildrens(root, parent)
{
    if (root.haveChildren && root.children && root.children.length === 0)
    {
        let component = root
        let action = { payload: { component: component }}
        yield call(getChildAssembly,action)
    }
    if (root.children.length > 0 && parent)
        for(let c of root.children)
            yield call(findFirstChildrens,c, false)
    else
        return root
}


let selectedNode = null
function getSelectedNode(root)
{
    if (root.selectedClass === "selected")
    {
        selectedNode = root
        return selectedNode
    }
    if (root.children.length > 0)
        root.children.forEach((c) => getSelectedNode(c))
    return selectedNode
}

let exitFlag = false
function markNodeSelected(root, _id)
{
    if (root._id === _id)
    {
        root.selectedClass = "selected"
        exitFlag = true
    }
    if (exitFlag)
        return
    if (root.children.length > 0)
        root.children.forEach((c) => markNodeSelected(c,_id))
}

export function* findProductAndSetAssembly(action)
{
    try
    {
        let productAssembly = [];
        let response = null;
        let productId = getValuesToPopulate(action.payload.id);
        let path = getPath();
        let product = getQueryData('product');
        product = yield cps(API[product].findById, productId)

        if (product)
        {

            let children = []
            for(let cmp of product.children)
            {
               let data = {name: cmp[path].name, cpn: cmp[path].cpn, _id: cmp[path]._id, haveChildren: cmp[path].children.length > 0, children: [], cpnVariant: cmp[path].cpnVariant, alias: cmp[path].alias};
               if (data.haveChildren)
               {
                    data.iconClass = ""
                    data.listClass = "close"
               }
               children.push(data)
            }

            children = Utils.sortComponents("cpn", children)
            let payload = {
                children: children
            }
            yield put(buildAction(ITEM_ASSEMBLY.SET_ITEM_ASSEMBLY_LIST, payload))


            let data = yield select(getItemAssembly)
            if (data.children.length > 0)
            {
                yield findFirstChildrens(data,true)
                yield put(buildAction(ITEM_ASSEMBLY.SET_ITEM_ASSEMBLY_STATE))
            }

        }
    }
    catch(err)
    {
        let payload =
        {
            type: "errors",
            errors: err.errors,
            err: err,
            closeCb: action.payload.notFoundCb || function(){
                                Utils.redirectToParentRoute(privatePaths.productSearch)
                            }
        }
        yield put(buildAction(UI.SHOW_ALERT, payload))
    }
}

function getPath()
{
    return window.__userRole !=='VENDOR' ? 'component' : 'assemblyRevision';
}

function getQueryData(data)
{
    return window.__userRole !== 'VENDOR' ?  `${data}s` : `${data}Revision`
}

function getValuesToPopulate(data)
{
    return window.__userRole !== 'VENDOR' ?  `${data}?include=children&lean=true` : `${data}?include=children,documents,assemblyRevision`
}

export function* getAndSetChildAssembly(action)
{
    let path = getPath();
    if (action.payload.component.children.length === 0)
    {
        let componentId = getValuesToPopulate(action.payload.component._id);
        let component = getQueryData('component');
        component = yield cps(API[component].findById, componentId);

        let children = [];
        for(let cmp of component.children)
        {
           let data = {name: cmp[path].name, cpn: cmp[path].cpn, _id: cmp[path]._id, haveChildren: cmp[path].children.length > 0 ,children: [], cpnVariant: cmp[path].cpnVariant, alias: cmp[path].alias};
           if (data.haveChildren)
           {
                data.iconClass = "";
                data.listClass = "close";
           }
           children.push(data);
        }
        action.payload.component.children = Utils.sortComponents("cpn", children);
    }
    yield put(buildAction(ITEM_ASSEMBLY.SET_ITEM_ASSEMBLY_STATE));
    if (action.payload.component.children.length > 0)
    {
        yield findFirstChildrens(action.payload.component,true);
        yield put(buildAction(ITEM_ASSEMBLY.SET_ITEM_ASSEMBLY_STATE));
    }
}

export function* getAndSetChildAssemblyAfterComponentUpdate(action)
{
    let path = getPath();
    let componentId = getValuesToPopulate(action.payload.component._id);
    let component = getQueryData('component');
    component = yield cps(API[component].findById, componentId);

    let children = [];
    for(let cmp of component.children)
    {
       let data = {name: cmp[path].name, cpn: cmp[path].cpn, _id: cmp[path]._id, haveChildren: cmp[path].children.length > 0 ,children: [], cpnVariant: cmp[path].cpnVariant, alias: cmp[path].alias};
       if (data.haveChildren)
       {
            data.iconClass = "";
            data.listClass = "close";
       }
       children.push(data);
    }
    action.payload.component.children = children;
    action.payload.component.name = component.name;
    action.payload.component.cpn = component.cpn;

    if (children.length === 0)
    {
        action.payload.component.haveChildren = false;
        action.payload.component.iconClass = "";
        action.payload.component.listClass = "close";
    }
    else
    {
        action.payload.component.haveChildren = true;
        action.payload.component.iconClass = "open";
        action.payload.component.listClass = "open";
    }
}

export function* getChildAssembly(action)
{
    let path = getPath();
    let componentId = getValuesToPopulate(action.payload.component._id);
    let component = getQueryData('component');
    component = yield cps(API[component].findById, componentId);

    let children = []
    for(let cmp of component.children)
    {
       let data = {name: cmp[path].name, cpn: cmp[path].cpn, _id: cmp[path]._id, haveChildren: cmp[path].children.length > 0 ,children: [], cpnVariant: cmp[path].cpnVariant, alias: cmp[path].alias};
       if (data.haveChildren)
       {
            data.iconClass = ""
            data.listClass = "close"
       }
       children.push(data)
    }
    action.payload.component.images   = component.images;
    action.payload.component.children = Utils.sortComponents("cpn", children)
}

export function* markAssemblyUnSelected(action)
{
    let data = yield select(getItemAssembly)
    if (data.children.length > 0)
        findAndUnmarkSelectedComponent(data)
    yield put(buildAction(ITEM_ASSEMBLY.SET_ITEM_ASSEMBLY_STATE))
}

export function* highLightSelectedAssembly(action)
{
    let _id = action.payload._id
    let data = yield select(getItemAssembly)
    selectedNode = null //use global var to point the selected node in tree
    exitFlag = false //use global var to exit the recursive functions
    if (data.children.length > 0)
    {
        selectedNode = getSelectedNode(data)
        if (!selectedNode)
            markNodeSelected(data, _id)
        else
        {
            selectedNode.selectedClass = ""
            if (selectedNode.haveChildren && !selectedNode.iconClass)
            {
                selectedNode.iconClass = "open"
                selectedNode.listClass = "open"
                let action = { payload: { component: selectedNode }}
                yield call(getAndSetChildAssembly,action)
                markNodeSelected(selectedNode, _id)
            }
            else
                markNodeSelected(selectedNode, _id)
        }
    }
    yield put(buildAction(ITEM_ASSEMBLY.SET_ITEM_ASSEMBLY_STATE))
}

export function* getLatestChildforNode(root, _id)
{
    if (root._id === _id) yield call(getAndSetChildAssemblyAfterComponentUpdate, {payload: { component: root }})
    if (root.children.length > 0) for(let c of root.children) yield call(getLatestChildforNode,c, _id)
}

export function* getLatestChildren(action)
{
    let _id = action.payload._id
    let data = yield select(getItemAssembly)
    yield call(getLatestChildforNode, data, _id)
    yield put(buildAction(ITEM_ASSEMBLY.SET_ITEM_ASSEMBLY_STATE))
}

function removeComponentFromTree(root, _id)
{
    if (root._id === _id) root.isDeleted = true
    if (root.children.length > 0) root.children.forEach((c) => removeComponentFromTree(c,_id))
}

export function* removeComponentFromLeftNavTree(action)
{
    let _id = action.payload._id
    let data = yield select(getItemAssembly)
    removeComponentFromTree(data, _id)
    yield put(buildAction(ITEM_ASSEMBLY.SET_ITEM_ASSEMBLY_STATE))
}

export function* searchListForAssemblies(action)
{
    let {cmp_query} = action.payload

    try
    {
        yield put(buildAction(ITEM_ASSEMBLY.SEARCH_IN_PROGRESS, true))
        yield call(Utils.delay)
        const componentsData = yield cps(API.search, cmp_query)
        let results = componentsData.results
        yield put(buildAction(ITEM_ASSEMBLY.ADD_SEARCH_RESULTS, results));
        yield put(buildAction(ITEM_ASSEMBLY.SEARCH_IN_PROGRESS, false))

    }
    catch(err)
    {
        yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}))
    }
}

export default function* (getState)
{
    yield all([
        takeEvery(ITEM_ASSEMBLY.GET_ITEM_ASSEMBLY_LIST, findProductAndSetAssembly),
        takeEvery(ITEM_ASSEMBLY.GET_AND_SET_CHILD_ASSEMBLY, getAndSetChildAssembly),
        takeEvery(ITEM_ASSEMBLY.MARK_ASSEMBLY_UN_SELECTED, markAssemblyUnSelected),
        takeEvery(ITEM_ASSEMBLY.HIGHLIGHT_SELECTED_ASSEMBLY_IN_NAV, highLightSelectedAssembly),
        takeEvery(ITEM_ASSEMBLY.GET_LATEST_CHILDREN, getLatestChildren),
        takeEvery(ITEM_ASSEMBLY.REMOVE_COMPONENT_FROM_LEFT_NAV_TREE, removeComponentFromLeftNavTree),
        takeLatest(ITEM_ASSEMBLY.SEARCH_ASSEMBLIES_ON_NEW_PAGE, searchListForAssemblies),
    ])
}
