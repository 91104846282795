import React, { Component }      from "react"

const SvgIcon = () =>
{
    return <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="3_HoverOverBar" transform="translate(-1209.000000, -319.000000)">
            <g id="TOC" transform="translate(1156.000000, 75.000000)">
                <g id="STEPS" transform="translate(17.000000, 30.000000)">
                    <g id="STEP-3" transform="translate(0.000000, 214.000000)">
                        <g id="CHECK" transform="translate(36.000000, 0.000000)">
                            <g id="Group">
                                <circle id="Oval-3" fill="#04ADC3" opacity="0.5" cx="9" cy="9" r="9"></circle>
                                <polyline id="Stroke-1" stroke="#FFFFFF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" points="5 10.1818182 6.92857143 12.125 14 5"></polyline>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
}

export default SvgIcon
