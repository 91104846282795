import { Icon, styled, Tooltip } from "@mui/material";
import { GridPreProcessEditCellProps } from "@mui/x-data-grid-pro";
import { VendorLabelIcon } from "assets/icons";
import {
  GridCheckbox, GridCheckboxParams, GRID_CELL_EDIT_CLASS_NAME, GroupingColDef, renderEditCell,
} from "common/components/grid";
import {
  CellRendererProps, OpenVariantModalType, useComponentNameFieldRenderCell, useCpnNameRenderCell,
  useRemoveComponentRenderCell, useRevisionRenderCell, useRevisionRenderHeader, wasteRenderCell,
  wasteRenderEditCell,
} from "design/components/grid";
import { PageItemType, PageMode } from "design/constants";
import { useIsAnyChildModified } from "design/hooks/assemblies";
import { useVendorInformation, VendorInfo } from "design/hooks/components";
import { AssemblyTableStyle, Component } from "design/models";
import { useExtendedCostValueGetter } from "design/utils/componentGrid";
import { usePrimaryCompany } from "graphql/query/companyQueries";
import { MouseEvent, useCallback, useMemo } from "react";
import { AssemblyView } from "../constants";
import { useAssemblyValidators } from "./assemblyTabValidation";
import {
  ASSEMBLY_EDIT_COLUMNS, ASSEMBLY_GRID_COLUMNS, ASSEMBLY_TREE_COLUMNS, ASSEMBLY_WASTE_COLUMNS, ColumnTypes,
  COLUMN_DEFAULTS,
} from "../../../utils/columnDefaults";
import { OnInputValidationChangeArgs } from "./useGridUpdates";

export interface UseColumnCustomizationsArgs {
  id: string;
  isAllowedBlankItemNumber?: boolean;
  isEditing: boolean;
  mode: PageMode;
  onInputValidationChange: (args: OnInputValidationChangeArgs) => void;
  onRemoveRow: (compId: string, event: MouseEvent<HTMLElement>) => void,
  openVariantModal: OpenVariantModalType;
  pageItemType: PageItemType;
}

const VALID_FLOAT_REGEX = /^[0-9]*\.?[0-9]*$/;
const NOT_A_FLOAT_ERROR_MESSAGE = "Value should be of type Number";
const VALID_INT_REGEX = /^[0-9]+$/;
const NOT_AN_INT_ERROR_MESSAGE = "Value should be an Integer";
const VALID_WASTE_REGEX = /^[0-9]+(.[0-9]{0,2})?$/;
const NOT_A_WASTE_ERROR_MESSAGE = "Value should be a floating point with no more than 2 decimal places.";

/**
 * Builds up a list of column customizations around editing and functions that need additional
 * information to be able to do their job properly.
 *
 * @param args Information needed to build the column customizations.
 * @returns A map of column field name to objects with their customizations.
 */
function useColumnCustomizations(args: UseColumnCustomizationsArgs) {
  const {
    id,
    isAllowedBlankItemNumber,
    isEditing,
    mode,
    onInputValidationChange,
    onRemoveRow,
    openVariantModal,
    pageItemType,
  } = args;

  const isAnyChildModified = useIsAnyChildModified({ id, mode, pageItemType });

  const extendedCostValueGetter = useExtendedCostValueGetter();
  const nameFieldRenderer = useComponentNameFieldRenderCell(openVariantModal, isEditing);
  const removeRenderCell = useRemoveComponentRenderCell(onRemoveRow);
  const revisionRenderCell = useRevisionRenderCell(mode, pageItemType);
  const revisionRenderHeader = useRevisionRenderHeader(isAnyChildModified);

  const validators = useAssemblyValidators(pageItemType);

  // Validate that the Item Number is an integer between 1 and 999.
  const validateItemNumberCell = useCallback((params: GridPreProcessEditCellProps) => {
    const { value } = params.props;
    if (!value) {
      onInputValidationChange({ error: false, field: COLUMN_DEFAULTS.itemNumber.field, id: params.id as string });
      return { ...params.props, error: null };
    }

    let error: null | string = null;
    if (!VALID_INT_REGEX.test(value)) {
      error = NOT_AN_INT_ERROR_MESSAGE;
    }
    else {
      const valErrors = validators.itemNumber.validateErrors({ isAllowedBlankItemNumber }, parseFloat(value));
      error = valErrors?.[0].message || null;
    }

    onInputValidationChange({ error: !!error, field: COLUMN_DEFAULTS.itemNumber.field, id: params.id as string });
    return { ...params.props, error };
  }, [onInputValidationChange, isAllowedBlankItemNumber, validators]);

  // Validate that the notes are not too long.
  const validateNotesCell = useCallback((params: GridPreProcessEditCellProps) => {
    const { value } = params.props;
    const valError = validators.notes.validateErrors({}, value);
    const error = valError?.[0].message;
    onInputValidationChange({ error: !!error, field: COLUMN_DEFAULTS.notes.field, id: params.id as string });
    return { ...params.props, error };
  }, [onInputValidationChange, validators]);

  // Validate that the waste is a floating point number.
  const validateWasteCell = useCallback((params: GridPreProcessEditCellProps) => {
    const { value } = params.props;
    if (!value) {
      onInputValidationChange({ error: false, field: COLUMN_DEFAULTS.waste.field, id: params.id as string });
      return { ...params.props, error: null };
    }

    let error: null | string = null;
    if (!VALID_WASTE_REGEX.test(value)) {
      error = NOT_A_WASTE_ERROR_MESSAGE;
    }
    else {
      const roundedValue = Number(value).toFixed(2);
      const valErrors = validators.waste.validateErrors({ waste: roundedValue }, roundedValue);
      error = valErrors?.[0].message || null;
    }

    onInputValidationChange({ error: !!error, field: COLUMN_DEFAULTS.waste.field, id: params.id as string });
    return { ...params.props, error };
  }, [onInputValidationChange, validators]);

  // Validate the quantity is an integer.
  const validateQuantityCell = useCallback((params: GridPreProcessEditCellProps) => {
    const { value } = params.props;
    const valid = value.length > 0 && VALID_FLOAT_REGEX.test(value);
    onInputValidationChange({ error: !valid, field: COLUMN_DEFAULTS.quantity.field, id: params.id as string });
    return { ...params.props, error: !valid ? NOT_A_FLOAT_ERROR_MESSAGE : null };
  }, [onInputValidationChange]);

  return useMemo(() => {
    const inputCellClassName = isEditing ? GRID_CELL_EDIT_CLASS_NAME : "";

    return {
      [COLUMN_DEFAULTS.extendedCost.field]: {
        valueGetter: extendedCostValueGetter,
      },
      [COLUMN_DEFAULTS.itemNumber.field]: {
        cellClassName: inputCellClassName,
        preProcessEditCellProps: validateItemNumberCell,
        renderCell: isEditing ? renderEditCell : undefined,
      },
      [COLUMN_DEFAULTS.name.field]: {
        renderCell: nameFieldRenderer,
      },
      [COLUMN_DEFAULTS.notes.field]: {
        cellClassName: inputCellClassName,
        preProcessEditCellProps: validateNotesCell,
        renderCell: isEditing ? renderEditCell : undefined,
      },
      [COLUMN_DEFAULTS.quantity.field]: {
        cellClassName: inputCellClassName,
        preProcessEditCellProps: validateQuantityCell,
        renderCell: isEditing ? renderEditCell : undefined,
      },
      [COLUMN_DEFAULTS.refDes.field]: {
        cellClassName: inputCellClassName,
        renderCell: isEditing ? renderEditCell : undefined,
      },
      [COLUMN_DEFAULTS.remove.field]: {
        renderCell: removeRenderCell,
      },
      [COLUMN_DEFAULTS.revision.field]: {
        renderCell: revisionRenderCell,
        renderHeader: revisionRenderHeader,
      },
      [COLUMN_DEFAULTS.waste.field]: {
        cellClassName: inputCellClassName,
        preProcessEditCellProps: validateWasteCell,
        renderCell: isEditing ? wasteRenderEditCell : wasteRenderCell,
      },
    };
  }, [
    extendedCostValueGetter,
    isEditing,
    nameFieldRenderer,
    removeRenderCell,
    revisionRenderCell,
    revisionRenderHeader,
    validateItemNumberCell,
    validateNotesCell,
    validateQuantityCell,
    validateWasteCell,
  ]);
}

interface UseColumnNamesArgs {
  assemblyView: AssemblyView;
  hasRefDes?: boolean;
  isEditing: boolean;
}

export function useColumnNames(args: UseColumnNamesArgs) {
  const { assemblyView, hasRefDes, isEditing } = args;

  const { wasteFieldEnabled } = usePrimaryCompany()?.data?.settings?.customFields ?? {};

  return useMemo(() => {
    const extras: ColumnTypes[] = [];

    if (hasRefDes) extras.push(COLUMN_DEFAULTS.refDes.field);

    if (wasteFieldEnabled) extras.push(...ASSEMBLY_WASTE_COLUMNS);

    if (isEditing) return ASSEMBLY_EDIT_COLUMNS.concat(extras);
    if (assemblyView === AssemblyView.GRID) return ASSEMBLY_GRID_COLUMNS.concat(extras);
    return ASSEMBLY_TREE_COLUMNS.concat(extras);
  }, [assemblyView, hasRefDes, isEditing, wasteFieldEnabled]);
}

export interface UseAssemblyColumnsArgs {
  columnNames: ColumnTypes[];
  component?: Component;
  getCurrentStyles: () => AssemblyTableStyle;
  id: string;
  isEditing: boolean;
  mode: PageMode;
  onInputValidationChange: (args: OnInputValidationChangeArgs) => void;
  onRemoveRow: (compId: string, event: MouseEvent<HTMLElement>) => void,
  openVariantModal: OpenVariantModalType;
  pageItemType: PageItemType;
}

export function useAssemblyColumns(args: UseAssemblyColumnsArgs) {
  const {
    columnNames,
    component,
    getCurrentStyles,
    id,
    isEditing,
    mode,
    onInputValidationChange,
    onRemoveRow,
    openVariantModal,
    pageItemType,
  } = args;

  const { isAllowedBlankItemNumber } = usePrimaryCompany()?.data?.settings ?? {};

  const vendorInfo = useVendorInformation(component);

  const columnOverrides = useColumnCustomizations({
    id,
    isAllowedBlankItemNumber,
    isEditing,
    mode,
    onInputValidationChange,
    onRemoveRow,
    openVariantModal,
    pageItemType,
  });

  const editable = isEditing && !vendorInfo.isVendorCmp;
  const columns = useMemo(() => columnNames.map(field => {
    const userStyles = getCurrentStyles();
    const defaults = COLUMN_DEFAULTS[field];

    return {
      ...defaults,
      ...columnOverrides[field],
      editable: defaults.editable && editable,
      position: userStyles[field]?.position ?? defaults.position,
      width: userStyles[field]?.width ?? defaults.width,
    };
  }).sort((a, b) => (a.position - b.position)), [
    columnNames,
    columnOverrides,
    editable,
    getCurrentStyles,
  ]);

  const cpnNameRenderCell = useCpnNameRenderCell(openVariantModal, isEditing);
  const groupingRenderCell = useCallback((params: CellRendererProps) => (
    <GroupingColDef {...params} renderInnerCell={cpnNameRenderCell} />
  ), [cpnNameRenderCell]);

  const groupColumn = useMemo(() => {
    const userStyles = getCurrentStyles();
    const defaults = COLUMN_DEFAULTS.cpnName;
    return ({
      ...defaults,
      renderCell: groupingRenderCell,
      width: userStyles.cpnName?.width ?? defaults.width,
    });
  }, [getCurrentStyles, groupingRenderCell]);

  const vendorCheckboxRenderer = useVendorCheckboxRenderer(vendorInfo);
  const checkboxColumn = useMemo(() => ({
    renderCell: vendorCheckboxRenderer,
  }), [vendorCheckboxRenderer]);

  return { checkboxColumn, columns, groupColumn };
}

function useVendorCheckboxRenderer(vendorInfo: VendorInfo) {
  return useCallback((params: GridCheckboxParams) => (
    <GridCheckbox {...params}>
      {vendorInfo.isVendorCmp && (
        <Tooltip title={vendorInfo.tooltip}>
          <VendorIconContainer>
            <VendorLabelIcon />
          </VendorIconContainer>
        </Tooltip>
      )}
    </GridCheckbox>
  ), [vendorInfo]);
}

const VendorIconContainer = styled(Icon)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: "100%",
  color: theme.palette.common.white,
  fontSize: "1rem",
  "& svg": {
    fontSize: "1rem",
  },
}));
