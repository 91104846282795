import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#888" fillRule="nonzero" transform="translate(-1127 -255)">
          <g transform="translate(1102 80)">
            <g transform="translate(25 172)">
              <g transform="translate(0 3)">
                <path d="M7 0a7 7 0 00-7 7 7 7 0 007 7 7 7 0 007-7 7 7 0 00-7-7zm2.705 8.29a2.976 2.976 0 000-2.581l2.182-2.182A5.964 5.964 0 0113 7a5.966 5.966 0 01-1.113 3.473L9.705 8.29zm.768-6.177L8.291 4.295a2.977 2.977 0 00-2.582 0L3.527 2.113A5.967 5.967 0 017 1c1.296 0 2.492.415 3.473 1.113zM9 7a2 2 0 11-4 0 2 2 0 014 0zM2.112 3.527L4.295 5.71a2.984 2.984 0 000 2.582l-2.182 2.182A5.964 5.964 0 011 7c0-1.296.414-2.492 1.112-3.473zm1.415 8.36l2.182-2.182a2.984 2.984 0 002.582 0l2.182 2.182A5.967 5.967 0 017 13a5.966 5.966 0 01-3.473-1.113z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
