import React                from "react";
import TimeStampColumn      from "../timestamp-column";

const LastUpdatedField = (props) =>
{
    let {lastModified} = props.item;

    return( <span className='last-modified-value'>
                <TimeStampColumn format={props.format || 'date-time-with-long-format'} value={lastModified} />
            </span>
    )
}

export default LastUpdatedField;
