import                              "./index.css";
import React, {Component}           from "react";
import Utils                        from "../../../../../../modules/utils";
import ComponentProductUtils        from "../../../../../../modules/component-product-utils";
import Schemas                      from "../../../../../../modules/schemas";
import API                          from "../../../../../../modules/api";
import { connect }                  from "react-redux";
import Link                         from "../../../../../ui/link";
import SourcingUtils                from "../../../sourcing/sourcing-utils";
import ExtendedTable                from "../../../../common/extended-table";
import EditIcon                     from "../../../../../../assets/icons/edit";
import Permissions                  from "../../../../../../modules/schemas/permissions";
import AlertIcon                    from "../../../../../../assets/icons/alert-icon";
import WarningTriangleIcon          from "../../../../../../assets/icons/warning-triangle";
import InlineIcon                   from "../../../../../ui/icon/inline-icon.js";
import ArrowIcon                    from "../../../../../../assets/icons/arrow-icon";
import TimeStampColumn              from "../../../../common/timestamp-column";
import InlineImageViewer            from "../../../inline-image-viewer/view";
import Config                       from "../../../../../../modules/config";
import Spinner                      from "../../../../../ui/spinner";
import VariantIcon                  from "../../../../../../assets/icons/variant-icon";
import VariantModal                 from "../../../../common/view-action-items/variant-modal"
import RevisionField                from "../../../../common/revision-field";
import CpnField                     from "../../../../common/cpn-field";
import StatusField                  from "../../../../common/status-field";
import CurrencyField                from "../../../../common/currency-field";
import URLSearchParams              from "url-search-params";
import VariantIconHolder            from "../../../variants/variant-icon-holder";
import { HEADINGS, getHeadings }    from "../../../extended-table/helpers";
import xlsx                         from 'json-as-xlsx';
import {debounce, union}            from "lodash"
import ValidationRunningModal       from "../../../../common/validation-running-modal";
import MassField                    from "../../../../common/mass-field";

export class Assembly extends Component
{
    constructor(props, context)
    {
        super(props, context)
        let { children, isDiffTool } = this.props;
        let styles;
        try
        {
            styles = window.__userStyles.styles.assemblyTableTreeView || {}
        }
        catch(error) {
            styles = {}
        }

        //Note: Deleting refDesBlock html block because its belong to Grid View Component and we can't use in Assembly Tree View because of html context binding with Grid view file.
        for (let child of children) delete child.refDesBlock
        if (isDiffTool)
        {
            let parentCmp = this.props.objectData
            let comparisionResult = Utils.compareSouceAndTargetChilds(children, this.props.targetRevChildren, parentCmp)
            children = comparisionResult.children
            parentCmp = comparisionResult.parentCmp
            let urlParams  = new URLSearchParams(window.location.search);
            let sourceRevId = urlParams.has('sourceRevId') ? urlParams.get('sourceRevId') : '';
            let targetRevId = urlParams.has('targetRevId') ? urlParams.get('targetRevId') : '';
            parentCmp.sourceRevId = sourceRevId.split('?')[0]
            parentCmp.targetRevId = targetRevId.split('?')[0]
        }

        this.state =
        {
            showLoading: true,
            syncTable: false,
            generatedRows: [],
            openVariantModal: false,
            primaryVariant: null,
            permittedVariants: [],
            children : Utils.sortComponents(isDiffTool ? "assemblyRevision.cpn" : "component.cpn", this.getSavedAssembly() || children),
            currentSortItemAscending: ("defaultSortAssending" in styles ? styles.defaultSortAssending : true),
            current : ("defaultSortColumnName" in styles ? styles.defaultSortColumnName : "cpn"),
            VariantModal: {
                top: 0,
                left: 0
            },
            headings: this.createHeadings(styles),
            preparingDownload: false,
        }

        this.getChildLink     = this.getChildLink.bind(this)
        this.getMassIcon      = this.getMassIcon.bind(this)
        this.afterSyncTable   = this.afterSyncTable.bind(this)
        this.getChildAssembly = this.getChildAssembly.bind(this)
        this.setCells         = this.setCells.bind(this)
        this.setCellsForDiffTool = this.setCellsForDiffTool.bind(this)
        this.generateCells    = this.generateCells.bind(this)
        this.getSavedAssembly = this.getSavedAssembly.bind(this)
        this.generateRows     = this.generateRows.bind(this)
        this.closeVariantModal    = this.closeVariantModal.bind(this);
        this.updatePrimaryVariant = this.updatePrimaryVariant.bind(this);
        this.updatePrimaryVariantInNestedChild = this.updatePrimaryVariantInNestedChild.bind(this);
        this.setIdsOfSpecificLevelChildren = this.setIdsOfSpecificLevelChildren.bind(this);
        this.setVariantFlags  = this.setVariantFlags.bind(this);
        this.setChildOpen     = this.setChildOpen.bind(this);
        this.fetchComponent   = this.fetchComponent.bind(this);
        this.setCellsForProductionInstance = this.setCellsForProductionInstance.bind(this);
        this.createExcel          = this.createExcel.bind(this);
        this.prepareDownload      = this.prepareDownload.bind(this);
        this.triggerLoadingModal  = this.triggerLoadingModal.bind(this);
        this.createExcelDebounced = debounce(this.createExcel, 2000);
        this.populateDefaultKeys = this.populateDefaultKeys.bind(this);

        this.summaryCount = {
            childAddedCount: 0,
            childUpdatedCount: 0,
            childRemovedCount: 0,
        }
    }

    createHeadings(styles)
    {
        let { tabsType } = this.props;
        let headings;

        if (tabsType === "productionInstance")
        {
            headings = [
                {
                    ...HEADINGS.cpn,
                    displayName: "CPN/Name",
                    dragable: false,
                    minWidth: 215,
                    tooltip: "CPN/Name",
                },
                HEADINGS.serial,
                HEADINGS.images,
                HEADINGS.level,
                HEADINGS.category,
                HEADINGS.cmpState,
                HEADINGS.revision,
                HEADINGS.eid,
                HEADINGS.status,
                HEADINGS.productionDate,
                HEADINGS.label,
            ]
        }
        else
        {
            headings = [
                {
                    ...HEADINGS.cpn,
                    displayName: "CPN/Name",
                    dragable: false,
                    minWidth: 215,
                    tooltip: "CPN/Name",
                },
                HEADINGS.quantity,
                HEADINGS.level,
                HEADINGS.images,
                HEADINGS.category,
                HEADINGS.procurement,
                HEADINGS.cmpState,
                HEADINGS.revision,
                HEADINGS.refDes,
                {
                    ...HEADINGS.itemNumber,
                    minWidth: 71,
                    width: 120,
                },
                HEADINGS.eid,
                HEADINGS.status,
                HEADINGS.notes,
                HEADINGS.mass,
                HEADINGS.unitOfMeasure,
                HEADINGS.unitPrice,
                HEADINGS.totalPrice,
                HEADINGS.leadTime,
                HEADINGS.description,
                HEADINGS.mpn,
                HEADINGS.manufacturer,
                HEADINGS.lastUpdated
            ]

            if(window.__customFields && window.__customFields.wasteFieldEnabled && !this.props.isDiffTool)
            {
                headings.push(
                    {
                        ...HEADINGS.waste,
                        position: styles.waste ? styles.waste.position : 2
                    },
                    {
                        ...HEADINGS.extendedQuantity,
                        position: styles.extendedQuantity ? styles.extendedQuantity.position : 3
                    },
                    {
                        ...HEADINGS.extendedCost,
                        position: styles.extendedCost ? styles.extendedCost.position : 4
                    }
                );
            }

        }

        return getHeadings(headings, styles);
    }

    closeVariantModal(e, result)
    {
        let state = this.state;
        state.openVariantModal = false;
        state.primaryVariant = null;
        this.setState(state);
    }

    setVariantFlags(result)
    {
        let primaryVariant = this.props.mode === "revision" && result.assemblyRevision ? result.assemblyRevision : result.component;
        let disabledFromVendor = Utils.isVendorCmp(Utils.getVendor(result)) && Utils.isVendorCmp(Utils.getVendor(primaryVariant)) ? true : false;

        result.variants.forEach((item) =>
        {
            let existInAssembly = false;
            if(primaryVariant.chilIdsOfthisLevel && primaryVariant.chilIdsOfthisLevel.includes(item.variant))
            {
                existInAssembly = true;
            }

            if(item.variant !== primaryVariant._id && (disabledFromVendor || existInAssembly))
            {
                item.isPermitted = false;
            }
        });
    }

    setIdsOfSpecificLevelChildren(children)
    {
        if(children.length>0)
        {
            let chilIdsOfthisLevel = [];
            for(let child of children)
            {
                let childComponent = this.props.mode === "revision" ? child.assemblyRevision : child.component;
                chilIdsOfthisLevel.push(childComponent._id);
            }

            for(let child of children)
            {
                child = this.props.mode === "revision" ? child.assemblyRevision : child.component;
                child.chilIdsOfthisLevel = chilIdsOfthisLevel;
            }
        }
    }

    updatePrimaryVariant(previous, next, parent, variants)
    {
        let state = this.state;
        if(next.children.length>0)
        {
            next.listClass = "close";
        }
        state.children.forEach((child) =>
        {
            let result = null;
            if(child.component._id === previous && child.parent === parent)
            {
                Object.assign(child.component, next);
                child.variants = variants;
                result = child.component;
            }
            else
            {
                result = this.updatePrimaryVariantInNestedChild(previous, next, parent, child.component, child.parent, variants)
            }
            if(result)
            {
                state.showLoading = true;
                state.openVariantModal = false;
                this.setState(state);
                this.generateRows();
            }
        });
    }

    updatePrimaryVariantInNestedChild(previous, next, parent, component, componentParent, variants)
    {
        if(component._id === previous && componentParent === parent)
        {
            Object.assign(component, next);
            return component;
        }
        let result = null;
        for(let child of component.children)
        {
            if(typeof child.component === "object")
            {
                let tempResult = this.updatePrimaryVariantInNestedChild(previous, next, parent, child.component, child.parent, variants);
                if(tempResult && tempResult._id === child.component._id)
                {
                    child.variants = variants;
                    result = tempResult;
                }
                else if(tempResult)
                {
                    result = tempResult;
                }
            }
        }

        return result;
    }

    afterSyncTable()
    {
        this.setState({syncTable: false})
    }

    getSavedAssembly()
    {
        let { tabsType, mode, objectData } = this.props;
        let lastOpenedObjectId = Utils.getStore("lastAssemblyParent")
        let children = Utils.getStore("lastAssemblyTree")
        let isOnShapeChild = this.isOnshapeChildren(children)
        if (lastOpenedObjectId !== objectData._id || mode === 'revision' || isOnShapeChild)
        {
            return null
        }

        if (children)
        {
            children = Utils.sortComponents("component.cpn", children)
            let currentChildren = Utils.sortComponents("component.cpn", this.props.children)
            if (children.length === currentChildren.length)
            {
                for (const [i, child] of children.entries()) {
                    let _child = currentChildren[i]
                    if (tabsType !== "productionInstance" && child.component._id === _child.component._id)
                    {
                        child.refDes = _child.refDes
                        child.notes = _child.notes
                        child.quantity = _child.quantity
                        child.itemNumber = _child.itemNumber
                        child.waste = _child.waste
                    }
                    else{
                        children = null
                        break;
                    }
                }
            }
            else
            {
                children = null
            }
        }
        return children
    }

    isOnshapeChildren(children)
    {
        let onshapeChild = false;
        if (children && children.length)
        {
            for(let child of children)
            {
                if (child.component && Utils.getVendor(child.component) === "ONSHAPE")
                {
                    onshapeChild = true;
                    break;
                }
            }
        }
        return onshapeChild;
    }

    getChildAssembly(component, i)
    {
        let { tabsType } = this.props;
        if (!component.listClass)
        {
            component.listClass = "open";
        }
        else
        {
            component.listClass = component.listClass === "open" ? "close" : "open";
        }
        if (component.children.length > 0 && component.listClass === "open")
        {
            let children = [];
            let onCompleteCb = () => {
                this.generateRows(component.listClass);
            }
            for(let cmp of component.children)
            {
                cmp.haveChildren = this.props.mode === "revision" ? cmp.assemblyRevision.children.length > 0 : cmp.component.children.length > 0;

                cmp.listClass = "close";

                if (this.props.isDiffTool)
                {
                   onCompleteCb();
                }
                else if(cmp.haveChildren)
                {
                    if (this.props.mode === "revision")
                    {
                        cmp.assemblyRevision.children.forEach((childCmp) =>
                        {
                            if(typeof childCmp.assemblyRevision === "string")
                            {
                                let componentId = `${childCmp.assemblyRevision}?include=assemblyRevision&lean=true`;
                                API.componentRevision.findById(componentId, (err, componentData) =>
                                {
                                    childCmp.assemblyRevision = componentData;
                                });
                            }
                        });
                    }
                    else
                    {
                        cmp.component.children.forEach((childCmp) =>
                        {
                            if(typeof childCmp.component === "string")
                            {
                                let componentId = `${childCmp.component}?include=children&lean=true`;
                                let model = tabsType === "productionInstance" ? "productionInstances" : "components";
                                API[model].findById(componentId, (err, componentData) =>
                                {
                                    childCmp.component = componentData;
                                });
                            }
                        });
                    }
                }
                children.push(cmp);
            }
            component.children = Utils.sortComponents("component.cpn", children);
        }
        this.state.syncTable = true;
        if (!this.props.isDiffTool || component.listClass === "close") this.generateRows(component.listClass);
    }

    generateRows(openCloseClass="")
    {
        let state = this.state;
        state.showLoading = false;
        let children = state.children;

        let resultArr = []
        let parentCmp = this.props.objectData
        if (this.props.isDiffTool)
        {
            if (openCloseClass === "open")
            {
                Utils.setNestedChildModificationCounter({children: this.state.children}, parentCmp, this.props.mode)
                this.summaryCount.childAddedCount = 0
                this.summaryCount.childUpdatedCount = 0
                this.summaryCount.childRemovedCount = 0

                for(let child of this.state.children)
                {
                    this.summaryCount.childAddedCount = this.summaryCount.childAddedCount + Number(child.assemblyRevision.aggchildAdded)
                    this.summaryCount.childUpdatedCount = this.summaryCount.childUpdatedCount + Number(child.assemblyRevision.aggchildUpdated)
                    this.summaryCount.childRemovedCount = this.summaryCount.childRemovedCount + Number(child.assemblyRevision.aggchildRemoved)

                    switch(child.rowClassName)
                    {
                        case "add" :
                        {
                            this.summaryCount.childAddedCount++
                            break
                        }
                        case "remove" :
                        {
                            this.summaryCount.childRemovedCount++
                            break
                        }
                        case "update" :
                        {
                            this.summaryCount.childUpdatedCount++
                            break
                        }
                        default :
                        {
                            // noop
                        }
                    }
                }
            }
        }

        this.generateCells({children: this.state.children}, 0);
        this.setState(state, () => {
            //Note: For large assembly we are getting error "Setting the value of 'lastAssemblyTree' exceeded the quota." from localStorage

            //Note: Temoporary patch to just store the assembly tree if the child length < 500. We will handle is through state later.
            if (!this.props.isDiffTool && this.state.children && this.state.children.length < 500)
            {
                //Note: Adding try catch so that we will be fetching data from database if it's size is more than the allowed cache quota
                try
                {
                    Utils.setStore("lastAssemblyTree",   this.state.children);
                    Utils.setStore("lastAssemblyParent", this.props.objectData._id);
                }
                catch(err)
                {
                    Utils.setStore("lastAssemblyTree", null);
                    Utils.setStore("lastAssemblyParent", null);
                }
            }
            this.props.setAssemblyDiffCount && this.props.setAssemblyDiffCount(this.summaryCount)
        });
    }

    setCellsForDiffTool(child, level)
    {
        let { company } = this.props;
        let component = this.props.mode === "revision" && child.assemblyRevision ? child.assemblyRevision : child.component;
        component.listClass = component.listClass === "open" ? "open" : "close"

        let isRevisionPage = false
        if (this.props.location) isRevisionPage = ComponentProductUtils.isRevisionPage(this.props.location)

        let leadTime = component.primarySource.leadTime.value !== null && component.primarySource.leadTime.units && component.primarySource.leadTime.value + " " + component.primarySource.leadTime.units
        let unitPrice = component.primarySource.unitPrice !== null ? component.primarySource.unitPrice : undefined

        let totalPrice = component.primarySource.unitPrice !== null && child.quantity * component.primarySource.unitPrice
        let padding = (level*20)+'px'
        let cpn      = Utils.getCpn(component);

        let description = component.description ? component.description : '';
        let procurement = component.procurement ? component.procurement : '';
        let cmpState = component.cmpState ? component.cmpState : '';
        let dateTime    = Utils.dateTimeWithLongFormat(component.lastModified);
        let { mpn, manufacturer } = SourcingUtils.getPrimarySourceMpnAndMfr(component);
        let massPrecision = Utils.getCompanyMassPrecision(company);
        padding = level === 1 ? '15px' : padding

        let cells = {
            "cpn" : {
                value       : cpn,
                displayValue: <div className="diff-update-holder">
                                    <span className="tree-child-assembly" style={{paddingLeft: padding}}>
                                    <span className='link position-relative'>
                                        {
                                            component.children.length > 0 ?
                                            <InlineIcon
                                              stopPropagation={true}
                                              onClick={
                                                  (e) => {
                                                      this.props.isAssemblyLoaded ? this.getChildAssembly(component) : null
                                                  }
                                              }
                                              className={component.listClass}
                                              onMouseOver={(e) => this.addHOverState(e)}
                                            >
                                              <ArrowIcon/>
                                            </InlineIcon> : null
                                        }
                                        <CpnField item={component} cpn={cpn}/>
                                    </span>
                                        {component.name}
                                    </span>

                                    {
                                        !this.props.isAssemblyLoaded && component.children.length > 0 ?
                                        <Spinner iconClassName="where-used-modal-spinner"/> :
                                        (component.aggchildRemoved || component.aggchildAdded || component.aggchildUpdated) ?
                                        <div className="diff-update-section diff-tag-container">
                                            {
                                                (Number(component.aggchildRemoved)) ? <div className="update-tag remove">{`${Number(component.aggchildRemoved || 0)}`}</div> : null
                                            }
                                            {
                                                (Number(component.aggchildAdded)) ? <div className="update-tag add">{`${Number(component.aggchildAdded || 0)}`}</div> : null
                                            }
                                            {
                                                (Number(component.aggchildUpdated)) ? <div className="update-tag update">{`${Number(component.aggchildUpdated || 0)}`}</div> : null
                                            }
                                        </div> : null
                                    }
                                </div>
                                ,
                tooltip     : cpn + " " + component.name,
                cellCustomClass : "diff-tool",
            },

            "level" : {
                value       : level,
                displayValue: level,
                tooltip     : level
            },

            "category" : {
                value       : Schemas.component.category.getDisplayName(component.category),
                displayValue: Schemas.component.category.getDisplayName(component.category),
                tooltip     : Schemas.component.category.getDisplayName(component.category)
            },

            "procurement" : {
                value       : procurement,
                displayValue: procurement,
                tooltip     : procurement
            },

            "cmpState" : {
                value       : cmpState,
                displayValue: cmpState,
                tooltip     : cmpState
            },

            "description" : {
                value       : description,
                displayValue: description,
                tooltip     : description
            },

            "mpn":
            {
                value       : mpn,
                tooltip     : mpn,
                displayValue: mpn,

            },

            "manufacturer":
            {
                value       : manufacturer,
                tooltip     : manufacturer,
                displayValue: manufacturer,

            },

            "lastUpdated":
            {
                value       : component.lastModified,
                tooltip     : dateTime ? `${dateTime.dateValue} ${dateTime.timeValue}` : '',
                displayValue: <TimeStampColumn key={Utils.generateUniqueId()} format='date-time-with-long-format' value={component.lastModified} />
            },

            "mass" : {
                value       : component.mass,
                displayValue: [
                                <div className="price-value" key={'i-mass'}>
                                    <MassField
                                        mass={component.mass}
                                        massPrecision={massPrecision}
                                    />
                                </div>
                            ],
                tooltip     : Utils.getRoundedMass(component.mass, massPrecision)
            },

            "revision" : {
                value       : component.revision,
                displayValue: child.rowClassName === 'update' && (child.removedRevision || child.addedRevision) ?
                        <div className="diff-update-section">
                                {
                                    child.removedRevision &&
                                    <div className={`update-tag remove ${child.targetRevData.revisionType === "Modified" ? 'is-modified' : ''}`}>
                                        <RevisionField item={child.targetRevData} revision={child.removedRevision} showIcon={child.targetRevData.revisionType === "Modified"}/>
                                    </div>
                                }
                                {
                                    child.addedRevision &&
                                    <div className={`update-tag add ${component.revisionType === "Modified" ? 'is-modified' : ''}`}>
                                        <RevisionField item={component} revision={child.addedRevision} showIcon={ component.revisionType === "Modified"}/>
                                    </div>
                                }
                        </div>
                        : <RevisionField item={component} revision={component.revision} showIcon={ this.props.mode === "revision" ? component.revisionType === "Modified" : true }/>,
                tooltip     : "tooltip"
            },
            "eid" : {
                value       : component.eid,
                displayValue: component.eid,
                tooltip     : component.eid
            },
            "unitOfMeasure" : {
                value       : component.unitOfMeasure,
                displayValue: component.unitOfMeasure,
                tooltip     : component.unitOfMeasure
            },
            "quantity" : {
                value       : "child.quantity",
                displayValue: child.rowClassName === "update" && (child.removedQuantity || child.addedQuantity) ?
                    <div className="diff-update-section">
                        {child.removedQuantity && <div className="update-tag remove">{child.removedQuantity}</div>}
                        {child.addedQuantity && <div className="update-tag add">{child.addedQuantity}</div>}
                    </div> : child.quantity,
                tooltip     : "child.quantity",
                cellCustomClass : child.rowClassName === "update" ? "diff-tool" : "",
            },
            "refDes" : {
                value       : "child.refDes",
                displayValue: child.rowClassName === "update" && child.refDesBlock ?
                    child.refDesBlock : child.refDes,
                tooltip     : child.refDes,
                cellCustomClass : child.rowClassName === "update" ? "diff-tool" : "",
            },
            "itemNumber" : {
                value       : "child.itemNumber",
                displayValue: child.rowClassName === "update" && (child.removedItemNumber || child.addedItemNumber) ?
                    <div className="diff-update-section">
                        {child.removedItemNumber && <div className="update-tag remove">{child.removedItemNumber}</div>}
                        {child.addedItemNumber && <div className="update-tag add">{child.addedItemNumber}</div>}
                    </div> : child.temNumber,
                tooltip     : child.itemNumber,
                cellCustomClass : child.rowClassName === "update" ? "diff-tool" : "",
            },
            "status" : {
                value       : component["status"],
                displayValue:   <StatusField item={component} status={component["status"]}/>,
                tooltip     : component["status"]
            },
            "notes" : {
                value       : "child.notes",
                displayValue: child.rowClassName === "update" && (child.removedNotes || child.addedNotes) ?
                    <div className="diff-update-section">
                        {child.removedNotes && <div className="update-tag remove">{child.removedNotes}</div>}
                        {child.addedNotes && <div className="update-tag add">{child.addedNotes}</div>}
                    </div> : child.notes,
                tooltip     : "child.notes",
                cellCustomClass : child.rowClassName === "update" ? "diff-tool" : "",
            },
            "unitPrice" : {
                value       : unitPrice,
                displayValue: [
                                <div className="price-value" key={"i"+'-unit-price'}>
                                    {unitPrice && <CurrencyField symbol={this.props.defaultCurrency} value={unitPrice}/>}
                                </div>
                            ],
                tooltip     : unitPrice && (this.props.defaultCurrency + Number(unitPrice).toFixed(5) )
            },
            "totalPrice" : {
                value       : totalPrice,
                displayValue: <CurrencyField symbol={this.props.defaultCurrency} value={totalPrice}/>,
                tooltip     : (this.props.defaultCurrency + Number(totalPrice).toFixed(5) )
            },
            "leadTime" : {
                value       : leadTime ? Utils.stringValueToDays(leadTime.split(' ')[0], leadTime.split(' ')[1]) : -1,
                displayValue: leadTime,
                tooltip     : leadTime
            },
            "images" : {
                value       : 0,
                displayValue: <InlineImageViewer key={Utils.generateUniqueId()} defaultResolution={Config.defaultResolutions.inlineImage} images={component.images} imagesWithSrc={this.props.imagesWithSrc} />,
                notLink     : true,
                cellClass   : "inline-image-viewer"
            },
            rowLink         : !component.archived || isRevisionPage ? this.getChildLink(child) : '#',
            rowClassName    : child.rowClassName
        }
        return cells
    }

    setCells(child, level)
    {
        let { company } = this.props;
        let component = this.props.mode === "revision" && child.assemblyRevision ? child.assemblyRevision : child.component;
        component.listClass = component.listClass === "open" ? "open" : "close"

        let isRevisionPage = false
        if (this.props.location) isRevisionPage = ComponentProductUtils.isRevisionPage(this.props.location)

        let leadTime = component.primarySource.leadTime.value !== null && component.primarySource.leadTime.units && component.primarySource.leadTime.value + " " + component.primarySource.leadTime.units
        let unitPrice = component.primarySource.unitPrice !== null ? component.primarySource.unitPrice : undefined

        let totalPrice = component.primarySource.unitPrice !== null && child.quantity * component.primarySource.unitPrice
        let padding = (level*20)+'px'
        let cpn      = Utils.getCpn(component);

        let description = component.description ? component.description : '';
        let procurement = component.procurement ? component.procurement : '';
        let cmpState = component.cmpState ? component.cmpState : '';
        let dateTime    = Utils.dateTimeWithLongFormat(component.lastModified);
        let to = !component.archived || isRevisionPage ? this.getChildLink(child) : '#';
        let { mpn, manufacturer } = SourcingUtils.getPrimarySourceMpnAndMfr(component);
        let massPrecision = Utils.getCompanyMassPrecision(company);


        let { extendedQuantity, extendedCost } = Utils.calculateExtendedQuantityAndCost(child.waste, child.quantity, unitPrice);

        padding = level === 1 ? '15px' : padding
        let totalPermitted = 0;
        this.setVariantFlags(child);
        for(let item of child.variants)
        {
            if(item.isPermitted)
            {
                totalPermitted++;
            }
        }
        let cells = {
            "cpn" : {
                value       : cpn,
                displayValue: <span className="tree-child-assembly" style={{paddingLeft: padding}}>
                                <span className='link position-relative'>
                                    {
                                        component.children.length > 0 ?
                                        <InlineIcon
                                          stopPropagation={true}
                                          onClick={
                                              (e) => {
                                                  this.getChildAssembly(component)
                                              }
                                          }
                                          className={component.listClass}
                                          onMouseOver={(e) => this.addHOverState(e)}
                                        >
                                          <ArrowIcon/>
                                        </InlineIcon> : null
                                    }
                                    <CpnField item={component} cpn={cpn}/>
                                </span>
                                    <Link
                                    to={to}
                                    className={`link ${component.variantGroup ? '' : 'not-variant'}`}>
                                    {component.name}
                                    </Link>
                                {
                                    component.variantGroup && window.__userRole !== 'VENDOR' ?
                                    <VariantIconHolder
                                        openVariantModal={(primaryVariant, variantModal) => {
                                            let state = this.state
                                            state.openVariantModal = true;
                                            state.primaryVariant = primaryVariant;
                                            state.primaryVariant = this.props.mode === "revision" && primaryVariant.assemblyRevision ? primaryVariant.assemblyRevision : primaryVariant.component;
                                            state.primaryVariant.parent = {_id:primaryVariant.parent, alias: primaryVariant.parentAlias};
                                            if(Utils.isVendorCmp(Utils.getVendor(primaryVariant)) && Utils.isVendorCmp(Utils.getVendor(state.primaryVariant)))
                                            {
                                                state.primaryVariant.fixedPrimaryByVendor = true;
                                            }
                                            state.permittedVariants = [];
                                            state.permittedVariants = primaryVariant.variants;
                                            state.VariantModal.top = variantModal.top
                                            state.VariantModal.left = variantModal.left
                                            this.setState(state)
                                        }}
                                        result={child}
                                        leftFomular={(left) => left + 30}
                                        toolTip={`${totalPermitted} of ${child.variants.length} Variants`}
                                        tableSelector="assembly-list-tree extended-table"
                                    /> : null
                                }
                                </span>
                                ,
                tooltip     : cpn + " " + component.name,
                notLink     : false,
            },

            "level" : {
                value       : level,
                displayValue: level,
                tooltip     : level
            },

            "category" : {
                value       : Schemas.component.category.getDisplayName(component.category),
                displayValue: Schemas.component.category.getDisplayName(component.category),
                tooltip     : Schemas.component.category.getDisplayName(component.category)
            },

            "procurement" : {
                value       : procurement,
                displayValue: procurement,
                tooltip     : procurement
            },

            "cmpState" : {
                value       : cmpState,
                displayValue: cmpState,
                tooltip     : cmpState
            },

            "description" : {
                value       : description,
                displayValue: description,
                tooltip     : description
            },

            "mpn":
            {
                value       : mpn,
                tooltip     : mpn,
                displayValue: mpn,

            },

            "manufacturer":
            {
                value       : manufacturer,
                tooltip     : manufacturer,
                displayValue: manufacturer,

            },

            "lastUpdated":
            {
                value       : component.lastModified,
                tooltip     : dateTime ? `${dateTime.dateValue} ${dateTime.timeValue}` : '',
                displayValue: <TimeStampColumn key={Utils.generateUniqueId()} format='date-time-with-long-format' value={component.lastModified} />
            },

            "revision" : {
                value       : component.revision,
                displayValue: <RevisionField item={component} revision={component.revision} showIcon={ this.props.mode === "revision" ? component.revisionType === "Modified" : true }/>,
                tooltip     : "tooltip"
            },
            "eid" : {
                value       : component.eid,
                displayValue: component.eid,
                tooltip     : component.eid
            },
            "unitOfMeasure" : {
                value       : component.unitOfMeasure,
                displayValue: component.unitOfMeasure,
                tooltip     : component.unitOfMeasure
            },
            "quantity" : {
                value       : "child.quantity",
                displayValue: <span className="quantity">{child.quantity}</span>,
                tooltip     : "child.quantity"
            },
            "waste" : {
                value : child.waste ? child.waste : 0,
                cellClass : "wasteField",
                displayValue: (child.waste || child.waste >= 0) && Utils.roundFieldValue(child.waste, 2).roundedValue,
                tooltip: child.waste ? child.waste : 0
            },
            "extendedQuantity" : {
                value : extendedQuantity,
                cellClass : "extended-quantity",
                displayValue: Utils.roundFieldValue(extendedQuantity, 2).roundedValue,
                tooltip: extendedQuantity
            },
            "extendedCost" : {
                value : extendedCost,
                cellClass : "extended-cost",
                displayValue: Utils.concatCurrencySymbol(extendedCost, this.props.defaultCurrency),
                tooltip: Utils.concatCurrencySymbol(extendedCost, this.props.defaultCurrency)
            },
            "refDes" : {
                value       : "child.refDes",
                displayValue: <span className="refDes">{child.refDes}</span>,
                tooltip     : child.refDes
            },
            "itemNumber" : {
                value       : "child.itemNumber",
                displayValue: <span className="itemNumber">{child.itemNumber}</span>,
                tooltip     : child.itemNumber
            },
            "status" : {
                value       : component["status"],
                displayValue: <StatusField item={component} status={component["status"]}/>,
                tooltip     : component["status"]
            },
            "notes" : {
                value       : "child.notes",
                displayValue: <span className="notes">{child.notes}</span>,
                tooltip     : "child.notes"
            },
            "unitPrice" : {
                value       : unitPrice,
                displayValue: [
                                <div className="price-value" key={"i"+'-unit-price'}>
                                    {unitPrice && <CurrencyField symbol={this.props.defaultCurrency} value={unitPrice}/>}
                                </div>,
                                SourcingUtils.haveInValidCosting(component) === true &&
                                <InlineIcon
                                    tooltip="Incomplete Sources"
                                    tooltipPlace="top"
                                    className="warningEl"
                                    key={"i"+'-unit-price-icon'}
                                    >
                                    <AlertIcon/>
                                </InlineIcon>
                            ],
                tooltip     : unitPrice && (this.props.defaultCurrency + Number(unitPrice).toFixed(5) )
            },
            "totalPrice" : {
                value       : totalPrice,
                displayValue: <CurrencyField symbol={this.props.defaultCurrency} value={totalPrice}/>,
                tooltip     : (this.props.defaultCurrency + Number(totalPrice).toFixed(5) )
            },
            "leadTime" : {
                value       : leadTime ? Utils.stringValueToDays(leadTime.split(' ')[0], leadTime.split(' ')[1]) : -1,
                displayValue: leadTime,
                tooltip     : leadTime
            },
            "images" : {
                value       : 0,
                displayValue: <InlineImageViewer key={Utils.generateUniqueId()} defaultResolution={Config.defaultResolutions.inlineImage} images={component.images} imagesWithSrc={this.props.imagesWithSrc} />,
                notLink     : true,
                cellClass   : "inline-image-viewer"
            },
            "mass" : {
                value       : component.mass,
                displayValue: [
                                <div className="price-value" key={"i"+'-mass'}>
                                    <MassField
                                        mass={component.mass}
                                        massPrecision={massPrecision}
                                    />
                                </div>,
                                this.getMassIcon(component)
                            ],
                tooltip     : Utils.getRoundedMass(component.mass, massPrecision)
            },
            rowLink         : !component.archived || isRevisionPage ? this.getChildLink(child) : '#'
        }
        return cells
    }

    setCellsForProductionInstance(productionInstance, level)
    {
        productionInstance = productionInstance.component;
        productionInstance.listClass = productionInstance.listClass === "open" ? "open" : "close"
        let padding     = (level*20)+'px'
        let cmpState    = productionInstance.workflowState ? productionInstance.workflowState : '';
        let dateTime    = Utils.dateTimeWithLongFormat(productionInstance.productionDate);
        padding = level === 1 ? '15px' : padding
        let to  = `/${productionInstance.alias === "prd" ? "product" : "component"}/revision/${productionInstance.objectRevision}`;

        let cells = {
            "cpn" : {
                value       : productionInstance.cpn,
                displayValue:
                            <span className="tree-child-assembly" style={{paddingLeft: padding}}>
                                <span className='link position-relative'>
                                    {
                                        productionInstance.children.length > 0 ?
                                        <InlineIcon
                                          stopPropagation={true}
                                          onClick={
                                              (e) => {
                                                  this.getChildAssembly(productionInstance)
                                              }
                                          }
                                          className={productionInstance.listClass}
                                          onMouseOver={(e) => this.addHOverState(e)}
                                        >
                                          <ArrowIcon/>
                                        </InlineIcon> : null
                                    }
                                    {productionInstance.cpn}
                                </span>
                                    <Link
                                    to={to}
                                    className={`link`}>
                                    {productionInstance.name}
                                    </Link>
                            </span>,
                tooltip     : `${productionInstance.cpn} ${productionInstance.name}`,
            },
            "images" : {
                value       : 0,
                displayValue: <InlineImageViewer key={Utils.generateUniqueId()} defaultResolution={Config.defaultResolutions.inlineImage} images={productionInstance.images} imagesWithSrc={this.props.imagesWithSrc} />,
                notLink     : true,
                cellClass   : "inline-image-viewer"
            },
            "level" : {
                value       : level,
                displayValue: level,
                tooltip     : level
            },
            "serial" : {
                value       : productionInstance.serial,
                displayValue: productionInstance.serial,
                tooltip     : productionInstance.serial
            },
            "label" : {
                value       : productionInstance.label,
                displayValue: productionInstance.label,
                tooltip     : productionInstance.label
            },
            "category" : {
                value       : Schemas.component.category.getDisplayName(productionInstance.category),
                displayValue: Schemas.component.category.getDisplayName(productionInstance.category),
                tooltip     : Schemas.component.category.getDisplayName(productionInstance.category)
            },

            "cmpState" : {
                value       : cmpState,
                displayValue: cmpState,
                tooltip     : cmpState
            },
            "revision" : {
                value       : productionInstance.revision,
                displayValue: productionInstance.revision,
                tooltip     : "tooltip"
            },
            "eid" : {
                value       : productionInstance.eid,
                displayValue: productionInstance.eid,
                tooltip     : productionInstance.eid
            },
            "status" : {
                value       : productionInstance.status,
                displayValue:   <StatusField item={productionInstance} status={productionInstance.status}/>,
                tooltip     : productionInstance.status
            },
            "productionDate":
            {
                value       : productionInstance.productionDate,
                tooltip     : dateTime ? `${dateTime.dateValue} ${dateTime.timeValue}` : '',
                displayValue: <TimeStampColumn key={Utils.generateUniqueId()} format='date-time-with-long-format' value={productionInstance.productionDate} />
            },
            rowLink         : to
        }
        return cells
    }

    async componentWillReceiveProps(nextProps) {
        const { children } = this.state;
        const { collapseTreeView } = nextProps;

        const updateRows = () => {
            this.generateRows();
            this.props.afterToggleTreeView();
        }

        if (collapseTreeView === 'collapse') {
          const rootChilds = this.removeNestedChildren(children);

          this.setState({ children: rootChilds }, updateRows);
        }

        if (collapseTreeView === 'expandLevel') {
            const openedChilds = await this.setChildOpen(children);

            this.setState({ children: openedChilds }, updateRows);
        }

        if (collapseTreeView === 'expand') {
            const openedChilds = await this.setChildOpen(children, false);

            this.setState({ children: openedChilds }, updateRows);
          }
      }

    componentDidMount(){
        let { tabsType } = this.props;
        if(this.state.children && this.state.children.length > 0)
        {
            let state = this.state;
            let onCompleteCb = () => {
                this.generateRows("open");
            }

            if (this.props.isDiffTool && !this.props.isAssemblyLoaded)
            {
                let modelName = this.props.objectData.alias === 'cmp' ? 'componentRevision' : 'productRevision'
                let data = {
                    parentId: this.props.objectData._id,
                    sourceRevId: this.props.objectData.sourceRevId,
                    targetRevId: this.props.objectData.targetRevId
                }
                this.generateRows();
                if(this.props.setAssemblyLoading) this.props.setAssemblyLoading(true)
                API[modelName].getFullTree(data,  (err, data) =>
                {
                    if (data)
                    {
                        let {parent} = data
                        let comparisionResult = Utils.compareSouceAndTargetChilds(parent.sourceRevChildren, parent.targetRevChildren, parent);
                        parent          = comparisionResult.parentCmp;
                        parent.children = comparisionResult.children;
                        Utils.compareChildsRecursively(parent)
                        parent.children = Utils.sortComponents("assemblyRevision.cpn", parent.children)
                        state.children  = parent.children;
                        state.generatedRows = [];
                        this.props.setAssemblyTree(parent.children);
                        if(this.props.setAssemblyLoading) this.props.setAssemblyLoading(false)
                        this.setState(state);
                        onCompleteCb();
                    }
                });
            }
            else if(this.props.isDiffTool && this.props.isAssemblyLoaded)
            {
                state.children = this.props.assemblyTree;
                this.setState(state);
                onCompleteCb();
            }
            else
            {
                this.state.children.forEach((component, counter) =>
                {
                    let childComponent = this.props.mode === "revision" ? component.assemblyRevision : component.component
                    if(childComponent && childComponent.children && childComponent.children.length > 0)
                    {
                        childComponent.children.forEach((item) =>
                        {
                            if(typeof item.component === 'string')
                            {
                                if (this.props.mode === "revision")
                                {
                                    if (typeof(item.assemblyRevision) !== "object")
                                    {
                                        let componentId = `${item.assemblyRevision}?include=assemblyRevision&lean=true`;
                                        API.componentRevision.findById(componentId, (err, componentData) =>
                                        {
                                            item.assemblyRevision = componentData;
                                        });
                                    }
                                }
                                else
                                {
                                    let componentId = `${item.component}?include=children&lean=true`;
                                    let model = tabsType === "productionInstance" ? "productionInstances" : "components";
                                    API[model].findById(componentId, (err, componentData) =>
                                    {
                                        item.component = componentData;
                                    });
                                }
                            }
                        });
                    }
                });
            }
        }
        //Note: Render assembly tree immediately without waiting for loading child components
        if (!this.props.isDiffTool) this.generateRows("open");
    }

    fetchComponent (component){
        return new Promise((resolve, reject) => {
            let componentId = `${component}?include=children&lean=true`;
            let { tabsType } = this.props;
            let model = tabsType === "productionInstance" ? "productionInstances" : "components";
            API[model].findById(componentId, (err, componentData) =>
            {
                component = componentData;
                resolve(component);
            });
        })
    };

    /**
    * Recursive traversal of children tree.
    * Loop through each children in the tree to set
    * listClass property to 'open'.
    */
    async setChildOpen(root, byLevel = true) {
        const startLevel = 0;
        const levels = new Map();
        const queue = [];

        queue.push([root, startLevel]);

        while (queue.length > 0) {
            const [node, level] = queue.shift();

            const levelNodes = levels.get(level) || [];
            levelNodes.push(node);
            levels.set(level, levelNodes);

            for(const { component } of node){
                let { children, listClass } = component;
                for (const childCmp of children) {
                    if(typeof childCmp.component === "string")
                    {
                        childCmp.component = await this.fetchComponent(childCmp.component)
                    }
                }
                children = Utils.sortComponents("component.cpn", children);
                !!children.length &&
                (byLevel ? listClass === "open" : true) &&
                queue.push([children, level + 1]);
            }
        }

        let result = [];

        for (let i = levels.size - 1; i >= 0; i--) {
            for (const row of levels.get(i)){
                const level = []
                for (const {component, ...childProps} of row) {
                  let { children, listClass } = component;
                  level.push({
                      ...childProps,
                      component: {
                          ...component,
                          listClass: "open",
                          children:
                          !!children.length &&
                          (byLevel ? listClass === "open" : true)
                              ? result.shift()
                              : children,
                      },
                  })
                }
                result.push(level);
            }
        }
        this.state.syncTable = true;
        return result.flat();
    }

    removeNestedChildren(children)
    {
        return children.map((child) => {
            let comp = child.component
            if (comp && typeof(comp) == "object")
            {
                child.component.listClass = null
            }

            let compRev = child.assemblyRevision
            if (compRev && typeof(compRev) == "object")
            {
                child.assemblyRevision.listClass = null
            }
            return child
        })
    }

    generateCells(root, level)
    {
        let { tabsType } = this.props;

        if (level === 0)
        {
            this.state.generatedRows = []
        }
        if (root.children && root.listClass !== "close" && root.children.length > 0 )
        {
            level++
            root.children.forEach((c) =>
            {
                if(level === 1)
                {
                    c.parent = this.props.objectData._id;
                    c.parentAlias = this.props.objectData.alias;
                    c.vendor= Utils.getVendor(this.props.objectData);
                }
                else
                {
                    c.parent = root._id;
                    c.parentAlias = root.alias;
                    c.vendor= Utils.getVendor(root);
                }

                c.rowIndex = this.state.generatedRows.length

                if (this.props.mode === "revision")
                {
                    if (typeof(c.assemblyRevision) == "object")
                    {
                        if (this.props.isDiffTool)
                        {
                            this.state.generatedRows.push(this.setCellsForDiffTool(c, level))
                        }
                        else
                        {
                            this.setIdsOfSpecificLevelChildren(root.children);
                            this.state.generatedRows.push(this.setCells(c, level))
                        }
                        this.generateCells(c.assemblyRevision, level)
                    }
                }
                else
                {
                    if (typeof(c.component) == "object")
                    {
                        this.setIdsOfSpecificLevelChildren(root.children);
                        tabsType === "productionInstance"
                                ? this.state.generatedRows.push(this.setCellsForProductionInstance(c, level))
                                : this.state.generatedRows.push(this.setCells(c, level))
                        this.generateCells(c.component, level)
                    }
                }
            })
        }
        const expandAllIcon = document.querySelector(".action-item.tree-expand-icon")
        const childsCount = this.getChildsCount(this.state.children)
        if (expandAllIcon)
        {
            if (childsCount === this.state.generatedRows.length)
            {
                expandAllIcon.classList.remove("active");
                expandAllIcon.classList.add("disabled");
            }
            else{
                expandAllIcon.classList.add("active");
                expandAllIcon.classList.remove("disabled");
            }
        }
        const collapseAllIcon = document.querySelector(".action-item.tree-collapse-icon")
        if (collapseAllIcon)
        {
            if (this.state.children.length === this.state.generatedRows.length)
            {
                collapseAllIcon.classList.remove("active");
                collapseAllIcon.classList.add("disabled");
            }
            else{
                collapseAllIcon.classList.add("active");
                collapseAllIcon.classList.remove("disabled");
            }
        }
    }

    /**
     * Recursive traversal of children tree.
     * Loop through each children in the tree to count
     * the number of childrens.
     */
    getChildsCount(childrens) {
      return childrens.reduce(
        (count, { component }) =>
          (
            count +=
            component && Array.isArray(component.children) && component.children.length
              ? component && this.getChildsCount(component.children) + 1
              : 1),
        0
      );
    }

    getChildLink(child)
    {
      let pathname = this.props.mode === "revision" && child.assemblyRevision ? "/component/revision/" + child.assemblyRevision._id : "/component/view/" + child.component._id
      return pathname
    }

    getMassIcon(component)
    {
        let markup = null
        let isAssembly = component && component.category && Schemas.component.category.getType(component.category).toLowerCase() === "assembly"
        if (!isAssembly) return

        if (!!component.massStatus && component.massStatus.toUpperCase() === "ERROR")
        {
            markup =
                    <InlineIcon
                        tooltip="Missing mass values for one or more children"
                        tooltipPlace="top"
                        className="warningEl"
                        key={"i"+'-mass-error'}
                        >
                        <AlertIcon/>
                    </InlineIcon>
        }
        else if (!!component.massStatus && component.massStatus.toUpperCase() === "WARNING")
        {
            markup =
                    <InlineIcon
                        tooltip="Manually entered value"
                        tooltipPlace="top"
                        className="warningEl"
                        key={"i"+'-mass-warning'}
                        >
                        <WarningTriangleIcon/>
                    </InlineIcon>
        }

        return markup;
    }

    populateDefaultKeys()
    {
        let headings = this.state.headings;
        let keys = {};
        let i = 0;
        for(let heading of headings){
            keys[heading.key] = { visibility: true, position: i++};
        } 
        return keys;
    }

    getTableColumnNames()
    {   
        let keys   = [];
        let styles = window.__userStyles.styles.assemblyTableTreeView;
        styles = styles ? styles : this.populateDefaultKeys();

        Object.keys(styles).forEach((item, i) => {
            let value = styles[item];
            if(value.visibility)
            {
                if(item === 'cmpState')
                {
                    item = 'workflowState';
                }

                if(item && item !== 'images')
                {
                    keys[value.position] = item;
                }
            }
        });
        keys = keys.filter(function(el) { return el; });
        keys.splice(1,0,'name')
        return keys;
    }

    triggerLoadingModal(open=false)
    {
        if(open)
        {
            document.getElementsByClassName("validation-running")[0].classList.remove("hide-modal");
        }
        else
        {
            document.getElementsByClassName("validation-running")[0].classList.add("hide-modal");
        }
    }

    prepareDownload()
    {
        this.setState({preparingDownload: true})
        this.triggerLoadingModal(true);
        this.createExcelDebounced();
    }
    createExcel()
    {
        let {objectData, user} = this.props;
        let keys = this.getTableColumnNames();
        let data = {id: objectData._id, keys};
        API.productionInstances.downloadTree(data,  (err, data) =>
        {
            if (data)
            {
                let columns  = [];
                let contents = [];
                for(let key of keys)
                {
                    let label =  key === 'productionDate' ? 'production date' : key === 'workflowState'  ? 'workflow state'  : key;
                    columns.push({ label: label.toUpperCase(), value: key });
                }

                for(let item of data)
                {
                    let timeStamp       = Utils.dateTimeWithLongFormat(item.productionDate);
                    item.productionDate = `${timeStamp.dateValue} ${timeStamp.timeValue}`;
                    contents.push(item);
                }

                data = [
                  {
                    sheet: 'Instances',
                    columns: columns,
                    content: contents
                  }
                ]

                let preFix    = Utils.getLocalDate(user.data.timezoneOffset);
                let fileName  = `${objectData.cpn}`;
                if(objectData.revision)
                {
                    fileName  = `${fileName}.${objectData.revision}`;
                }
                fileName = `${fileName}.${objectData.serial}-${preFix}`;
                xlsx(data, { fileName, extraLength:1 });
                this.setState({preparingDownload: false})
                this.triggerLoadingModal(false);
            }
        });

    }

    footerInfo(tabsType,children)
    {
        let object = { 
            footerRowMsg: "Edit to add",
            displayFooter :true,
            }; 

        object.footerRowMsg = tabsType === 'productionInstance' ? 'Click to edit' : 'Edit to add';
        object.displayFooter = tabsType === 'productionInstance' && children && children.length === 0 ? false : true;
        
        return object;
    }

    render()
    {
        let { children, headings } = this.state
        let { tabsType } = this.props;
        let { footerRowMsg, displayFooter } = this.footerInfo(tabsType,children); 
        for (let heading of headings)
        {
            if (heading.key === "revision")
            {
                heading.displayName = Utils.getRevisionHeadingDisplayName(this.props.isAnyChildModified);
                break;
            }
        }

        if (children.length && this.state.showLoading)
        {
            return <Spinner iconClassName="where-used-modal-spinner"/>
        }

        let isRevisionPage = false
        if (this.props.location) isRevisionPage = ComponentProductUtils.isRevisionPage(this.props.location)
        let isInOpenCo = ComponentProductUtils.isInOpenCo(this.props.objectData)
        let rows = this.state.generatedRows
        Utils.updateHeadings(headings)

        let markup =
            <div className="assembly-tab-view-common">
                {
                    tabsType === 'productionInstance' &&
                    <ValidationRunningModal
                        open={true}
                        hideModal={"hide-modal"}
                        message="Preparing your Download."
                    />
                }
                {
                    this.state.openVariantModal &&
                    <div className="variant-table-holder">
                        <VariantModal
                            openVariantModal={this.state.openVariantModal}
                            closeVariantModal={this.closeVariantModal}
                            objectData={this.state.primaryVariant}
                            updatePrimaryVariant={this.updatePrimaryVariant}
                            permittedVariants={this.state.permittedVariants}
                            variantModalPosition={this.state.VariantModal}
                            history={this.props.history}
                            getDataFromApi={true}
                            compareVariants={null}
                            fromAssemblyTab={true}
                        />
                    </div>
                }
                <ExtendedTable
                    wrapperClassName="assembly-list-tree"
                    wrapperSelectorClass="assembly-list-tree"
                    headings={headings}
                    rows={rows}
                    stylesName="assemblyTableTreeView"
                    allowRowSelect={false}
                    startStaticColumns={1}
                    currentSortItem={this.state.current}
                    currentSortItemAscending={this.state.currentSortItemAscending}
                    scrollPagination={true}
                    disableDataSorting={true}
                    tableClass={"striped"}
                    paginationSize={30}
                    includeToolBar={true}
                    resultText={`${rows.length} ${tabsType === "productionInstance" ? "Instances" : "Components"}`}
                    tableActionButtons={this.props.getIconsActionsList()}
                    syncWithParentState={this.state.syncTable}
                    afterSyncWithParentState={this.afterSyncTable}
                    footerRow={displayFooter && !this.props.isDiffTool && !isInOpenCo && Permissions.can("edit", "component", this.props.user.data) && !isRevisionPage ? {
                                bordered: true,
                                dataCellEl: <p>{footerRowMsg}</p>,
                                indexCellEl: <div className="add-more-actions">
                                                <Link to={ComponentProductUtils.makeEditLink(this.props.objectData, tabsType)}>
                                                    <button
                                                        className='add-trigger'
                                                        data-tip='Edit'
                                                        >
                                                        <InlineIcon><EditIcon/></InlineIcon>
                                                    </button>
                                                </Link>
                                             </div>
                            }
                            :
                            rows.length === 0 &&
                            {
                                dataCellEl: <p>No Assembly added</p>
                            }
                        }
                    displayDownloadIcon={tabsType === "productionInstance" ? true : false}
                    downloadTable={tabsType === "productionInstance" ? this.prepareDownload : null}
                    downloadIconClass={this.state.preparingDownload  ? 'disabled' : 'active'}
                />

            </div>
        return markup
    }

}

export default connect((store) => store)(Assembly)
