import                          "./index.css";
import React, {Component}   from "react";
import Utils                from "../../../../../../modules/utils";
import Icon                 from "../../../../../ui/icon";
import DeleteSrc            from "../../../../../../assets/icons/cross-icon.js";
import AddSrc               from "../../../../../../assets/icons/add-row.js";
import LinkSrc              from "../../../../../../assets/icons/link.js";
import {validateField}      from "../../../../../../modules/validations";
import Schemas              from "../../../../../../modules/schemas";
import Quotes               from "./quotes";
import LazyInput            from "../../../../../ui/lazy-input/input.js";
import InlineIcon           from "../../../../../ui/icon/inline-icon.js";


class Distributors extends Component
{
    constructor(props, context)
    {
        super(props, context)
        this.onHeadingClick        = this.onHeadingClick.bind(this)
        this.sort                  = this.sort.bind(this)
        this.toggleRow             = this.toggleRow.bind(this)
        this.toggleSrcInput        = this.toggleSrcInput.bind(this)
        this.add                   = this.add.bind(this)
        this.remove                = this.remove.bind(this)
        this.onInputChange         = this.onInputChange.bind(this)
        this.onChange              = this.onChange.bind(this)
        this.setDistributorsValues = this.setDistributorsValues.bind(this)
        this.validateInputValues   = this.validateInputValues.bind(this)
        this.validateAllFields     = this.validateAllFields.bind(this)
        this.schema                = this.props.schema
        this.clientSchema          = this.props.clientSchema

        this.state =
        {
            column :
            {
                name      : "name",
                ascending : true
            },
            inputChanged : false,
            allCheckedBtn : false,
            columns :
            [
                {
                    name     : "checkBox",
                    value    : "",
                    width    : "30px",
                    // width    : 30
                },
                {
                    name     : "name",
                    class    : "sortable",
                    value    : "Distributor",
                    width    : "15%",
                    // width    : 60
                    sortable : true
                },
                {
                    name     : "dpn.key",
                    class    : "sortable",
                    value    : "DPN",
                    width    : "25%",
                    // width    : 265,
                    sortable : true
                },
                {
                    name     : "description",
                    class    : "sortable",
                    value    : "Description",
                    width    : "20%",
                    // width    : 125,
                    sortable : true
                },
                {
                    name     : "package.type",
                    class    : "sortable",
                    value    : "Package",
                    width    : "20%",
                    // width    : 115,
                    sortable : true
                },
                {
                    name     : "package.quantity",
                    class    : "sortable",
                    value    : "Package qty",
                    width    : "20%",
                    // width    : 150,
                    sortable : true
                },
                {
                    value : "",
                    width    : "30px"
                    // width    : 30,

                }
            ]
        }

        this.componentWillMount = this.sort
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.distributors !== this.props.distributors) {
            this.setDistributorsValues(nextProps.distributors)
            this.setState(this.state)
        }
    }

    onHeadingClick(event)
    {
        let column       = this.state.column
        let target       = event.target
        if ( target.tagName !== "TH" )
            target       = target.closest("th")
        column.name      = target.getAttribute("name")
        column.ascending = !target.className.match("ascending")
        this.setState(this.state, this.sort)
    }

    sort(cb)
    {
        let column       = this.state.column
        let columns      = this.state.columns
        let distributors = this.props.distributors

        columns.forEach((col) =>
        {
            col.class = col.sortable ? "sortable " : col.class
            if(col.name === column.name) col.class += column.ascending ?  "ascending" : "descending"
        })

        distributors.sort((a,b) =>
        {
            a = Utils.hash(column.name, a)
            b = Utils.hash(column.name, b)

            if(typeof a === "string") a = a.toLowerCase()
            if(typeof b === "string") b = b.toLowerCase()

            if(a < b) return column.ascending ? -1 :  1
            if(a > b) return column.ascending ?  1 : -1
            return 0
        })

        this.setDistributorsValues(distributors)

        this.setState(this.state, cb)
    }

    setDistributorsValues(distributors, options={}){
        distributors.forEach((dist) =>
        {
            dist.class = dist.expanded ? "expanded" : ""
            if (!dist.inputs){
                dist.inputs =
                {
                    name :
                    {
                        value : dist.name || "",
                        message : "",
                        class   : "",
                        valid   : true
                    },
                    dpn :
                    {
                        key :
                        {
                            value : dist.dpn.key || "",
                            message : "",
                            class   : "",
                            valid   : true
                        },
                        src :
                        {
                            value : dist.dpn.src || "",
                            message : "",
                            class   : "",
                            valid   : true,
                            active_class : dist.dpn.src ? "" : "empty"
                        }
                    },
                    package :
                    {
                        type :
                        {
                            value : (dist.package.type ? dist.package.type.toUpperCase() :  ""),
                            message : "",
                            class   : "",
                            valid   : true
                        },
                        quantity :
                        {
                            value : dist.package.quantity || "",
                            message : "",
                            class   : "",
                            valid   : true
                        }
                    },
                    description :
                    {
                        value : dist.description || "",
                        message : "",
                            class   : "",
                            valid   : true
                    },
                    quotes :
                    {
                        value : dist.quotes,
                        valid   : true
                    }
                }
                this.validateAllFields(dist, {trackInputChanged: options.trackInputChanged})
            }
        })
    }

    componentDidUpdate(prevProps) {
      if (prevProps.status !== this.props.status) {
        let distributors = this.props.distributors
        distributors.forEach((dist) =>
        {
            this.validateAllFields(dist)
        })
        this.setState(this.state)
      }
    }

    validateAllFields(distributor, options={}){
        this.validateInputValues(distributor, "name", distributor.inputs.name.value, options)
        this.validateInputValues(distributor, "dpn.key", distributor.inputs.dpn.key.value, options)
        this.validateInputValues(distributor, "dpn.src", distributor.inputs.dpn.src.value, options)
        this.validateInputValues(distributor, "description", distributor.inputs.description.value, options)
        this.validateInputValues(distributor, "package.type", distributor.inputs.package.type.value, options)
        this.validateInputValues(distributor, "package.quantity", distributor.inputs.package.quantity.value, options)
    }

    toggleRow(event, i)
    {
        let distributor   = this.props.distributors[i]
        distributor.expanded = event.target.className.indexOf("expanded") > -1 ? false : true
        distributor.class = distributor.expanded ? "expanded" : ""
        this.setState(this.state)
    }

    toggleSrcInput(event, i)
    {
        let dist      = this.props.distributors[i]
        let inputNode = event.target.nextSibling
        let input     = dist.inputs.dpn

        let onClick = (event) =>
        {
            // eslint-disable-next-line
            if(event.target !== inputNode) close()
        }

        let onKeyDown = (event) =>
        {
            // eslint-disable-next-line
            if(event.key === "Escape") close()
        }

        let open = () =>
        {
            if(input.src.active_class === "edit") return
            document.addEventListener("keydown", onKeyDown, { capture: true });
            document.addEventListener("click", onClick, { capture: true });
            input.src.active_class = "edit"
            this.setState(this.state, () => inputNode.focus())
        }

        let close = () =>
        {
            document.removeEventListener("keydown", onKeyDown, { capture: true });
            document.removeEventListener("click", onClick, { capture: true });
            input.src.active_class = input.src.value ? "" : "empty"
            this.setState(this.state)
        }

        open()
    }

    add()
    {
        let data =
        {
            // expandClass: "expanded",
            // expandClass: "expanded",
            expanded : true,
            name        : "",
            description : "",
            dpn         :
            {
                key : "",
                src   : ""
            },
            package   :
            {
                type     : "",
                quantity : 1
            },
            quotes :
            [
                {
                    minQuantity : "",
                    unitPrice   : "",
                    leadTime    : {},
                    isPrimary   : false,
                    isChecked   : false,
                    isNewlyCreated : true,
                    uniqueId    : Utils.generateUniqueId()
                }
            ]
        }

        this.props.distributors.push(data)
        this.setDistributorsValues(this.props.distributors, {trackInputChanged: true})
        this.setState(this.state, this.onChange)
    }

    remove(i)
    {
        this.props.toggleCountOnRemove(this.props.distributors[i], "dpn");
        let dist = this.props.distributors[i]

        if (dist.isPrimary)
        {
            this.props.onPrimarySourceDeselect()
            this.props.syncPrimaryQuoteInputWithData({})
        }
        dist = this.props.distributors[0];
        this.props.distributors.splice(i, 1);
        let checkedQuote = 0;
        let quote = null;
        let isValid = false;
        this.props.manufacturers.forEach((man, manIndex) => {
            man.distributors.forEach((dist, distIndex) => {
                dist.quotes.forEach((q, quoteIndex) => {
                    if(q.inputs.isChecked.value){
                        checkedQuote++;
                        if(q.inputs.minQuantity.value !== "" && q.inputs.minQuantity.valid)
                        {
                            quote = q;
                            isValid = true;
                        }
                    }
                });
            });
        });
        if(checkedQuote === 1 && isValid)
        {
            this.props.onPrimarySourceChange(true, quote);
        }
        else
        {
            this.props.onPrimarySourceChange(false, quote);
        }

        this.setState(this.state, () => this.onChange({trackInputChanged: true}))
    }

    onInputChange(event, i)
    {
        let name  = event.target.name
        let value = event.target.value
        let dist = this.props.distributors[i]

        this.validateInputValues(dist, name, value, {trackInputChanged: true})
        Utils.persistCursor(event, value)
        this.setState(this.state, this.onChange)
    }

    validateInputValues(dist, name, value, options={}){
        let input, validator  = null
        let trackInputChanged = options.trackInputChanged
        let state             = this.state

        switch(name)
        {
            case "name" :
            {
                state.inputChanged = trackInputChanged ? true : false
                validator   = this.clientSchema.name
                input       = dist.inputs.name
                this.props.validateSourcingFields(input, validator, value, {status: this.props.status}, {valid_class: ""}, input.valid);
                dist.name              = value
                break
            }

            case "dpn.key" :
            {
                state.inputChanged = trackInputChanged ? true : false
                validator   = this.clientSchema.dpn.key
                input       = dist.inputs.dpn.key
                this.props.validateSourcingFields(input, validator, value, {status: this.props.status}, {valid_class: ""}, input.valid);
                dist.dpn.key              = value
                break
            }

            case "dpn.src" :
            {
                state.inputChanged = trackInputChanged ? true : false
                validator   = this.clientSchema.dpn.src
                input       = dist.inputs.dpn.src
                this.props.validateSourcingFields(input, validator, value, {}, {valid_class: ""}, input.valid);
                dist.dpn.src              = value
                break
            }

            case "description" :
            {
                state.inputChanged = trackInputChanged ? true : false
                validator   = this.clientSchema.description
                input       = dist.inputs.description
                this.props.validateSourcingFields(input, validator, value, {}, {valid_class: ""}, input.valid);
                dist.description              = value
                break
            }

            case "package.type" :
            {
                state.inputChanged = trackInputChanged ? true : false
                validator   = this.clientSchema.package.type
                input       = dist.inputs.package.type
                this.props.validateSourcingFields(input, validator, value, {}, {valid_class: ""}, input.valid);
                dist.package.type              = value
                break
            }

            case "package.quantity" :
            {
                state.inputChanged = trackInputChanged ? true : false
                value       = parseInt(value) ? parseInt(value) : 1
                validator   = this.clientSchema.package.quantity
                input       = dist.inputs.package.quantity
                this.props.validateSourcingFields(input, validator, value, {}, {valid_class: ""}, input.valid);
                dist.package.quantity              = value
                break
            }

            case "quotes" :
            {
                state.inputChanged = value.inputChanged ? true : false
                dist.inputs.quotes.value        = value.data
                dist.inputs.quotes.valid        = value.valid
                dist.quotes              = value.data
                break
            }

            default :
            {
                // noop
            }
        }
    }

    onChange(options={})
    {
        if(this.props.onChange)
        {
            let data  = {data: this.props.distributors, valid: true, inputChanged: (this.state.inputChanged || options.trackInputChanged)}
            let event = Utils.getEvent(this, data)
            this.props.onChange(event)
        }
    }

    render()
    {
        let distributors = this.props.distributors
        let propsPrimarySource = this.props.primarySource
        let markup =
            <div className="distributors">
                <table>
                    <tbody>
                        <tr>
                        {
                            this.state.columns.map((col, i) =>
                            {
                                let headerCheckBox =
                                        <span
                                            className={"select-all-distributors primary-source-checkbox "+ this.props.manClass}
                                            >
                                                <input
                                                    type="checkbox"
                                                    name="selectAllDistributors"
                                                    onClick={
                                                        (e) => this.props.selectAllItems(event, this.props.distributors, this.props.manClass)
                                                    }
                                                />
                                                <label
                                                    htmlFor="group"
                                                />
                                            </span>
                                let textBlock =
                                <div
                                    className={"text-block"}
                                    >
                                    <div onClick={col.sortable ? this.onHeadingClick : null}>{col.value}</div>
                                    {distributors.length > 1 && <div onClick={col.sortable ? this.onHeadingClick : null}/>}
                                </div>

                                let expandEl =
                                        <span
                                            // className="col-move"
                                            // onMouseDown={(e) => this.onMouseDown(e, i)}
                                            // onMouseEnter={(e) => this.addExpandHoverClass(e)}
                                            // onMouseLeave={(e) => this.removeExpandHoverClass(e)}
                                            // onClick={this.performNoEvent}
                                            // onDoubleClick={this.onThDoubleClick}
                                        >
                                        </span>

                                let block =
                                    <th
                                        key={i}
                                        name={col.name}
                                        className={col.class + " resizing---"}
                                        style={{width: col.width}}
                                        // style={{width: col.width+"px"}}
                                        >
                                        <span className="handler">
                                            { (this.props.tabsType === 'cmp' && i == 0) ? headerCheckBox : textBlock}
                                            {expandEl}
                                        </span>
                                    </th>
                                    return block
                            })
                        }
                        </tr>
                        {
                            distributors.map((dist, i) =>
                            {
                                let distClass = this.props.manClass + 'dist-'+(i+1)+' '
                                let rowA =
                                    <tr className={distClass + ( (dist.isPrimary && propsPrimarySource.minQuantity !== '') ? 'primary-source' : '' )} key={i + "-standard"}>
                                        <td className="expand-btn">
                                            <div className={"expand-btn inlineBlock " + dist.class}
                                            onClick={(event) => this.toggleRow(event, i)}/>
                                        </td>
                                        <td
                                            className={dist.class}
                                            >
                                            <LazyInput
                                                type="text"
                                                autoComplete="off"
                                                name="name"
                                                value={dist.inputs.name.value}
                                                onChange={(event) => this.onInputChange(event, i)}
                                                className={dist.inputs.name.class}
                                                data-tip={dist.inputs.name.message}
                                                data-type="error"
                                            />
                                        </td>
                                        <td className="dist-dpn-key-cell">
                                            <div
                                                className={dist.inputs.dpn.key.class}
                                                data-tip={dist.inputs.dpn.key.message}
                                                data-type="error"
                                            >
                                                <LazyInput
                                                    type="text"
                                                    autoComplete="off"
                                                    name="dpn.key"
                                                    value={dist.inputs.dpn.key.value}
                                                    onChange={(event) => this.onInputChange(event, i)}
                                                    className={dist.inputs.dpn.key.class}
                                                    data-tip={dist.inputs.dpn.key.message}
                                                    data-type="error"
                                                />
                                                <InlineIcon
                                                    className={"link-icon " + dist.inputs.dpn.src.class + " " + dist.inputs.dpn.src.active_class}
                                                    onClick={(event) => this.toggleSrcInput(event, i)}
                                                    tooltip={dist.inputs.dpn.src.message}
                                                    tooltipType="error"
                                                >
                                                    <LinkSrc />
                                                </InlineIcon>
                                                <LazyInput
                                                    type="text"
                                                    autoComplete="off"
                                                    name="dpn.src"
                                                    className={dist.inputs.dpn.src.class + " " + dist.inputs.dpn.src.active_class}
                                                    value={dist.inputs.dpn.src.value}
                                                    placeholder="Paste URL"
                                                    data-tip={dist.inputs.dpn.src.message}
                                                    data-type="error"
                                                    onChange={(event) => this.onInputChange(event, i)}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <LazyInput
                                                type="text"
                                                autoComplete="off"
                                                name="description"
                                                value={dist.inputs.description.value}
                                                onChange={(event) => this.onInputChange(event, i)}
                                                className={dist.inputs.description.class}
                                                data-tip={dist.inputs.description.message}
                                                data-type="error"
                                            />
                                        </td>
                                        <td>
                                            <select
                                                name="package.type"
                                                value={dist.inputs.package.type.value}
                                                className={dist.inputs.package.type.class}
                                                data-tip={dist.inputs.package.type.message}
                                                data-type="error"
                                                onChange={(event) => this.onInputChange(event, i)}
                                                >
                                                {Utils.toOptions(Schemas.selectData.package.types())}
                                            </select>
                                        </td>
                                        <td>
                                            <LazyInput
                                                type="text"
                                                autoComplete="off"
                                                name="package.quantity"
                                                value={dist.inputs.package.quantity.value}
                                                onChange={(event) => this.onInputChange(event, i)}
                                                className={dist.inputs.package.quantity.class}
                                                data-tip={dist.inputs.package.quantity.message}
                                                data-type="error"
                                            />
                                        </td>
                                        <td className="remove-col">
                                            <InlineIcon onClick={(e) => this.remove(i)}>
                                                <DeleteSrc />
                                            </InlineIcon>
                                        </td>
                                    </tr>

                                let rowB =
                                    <tr
                                        key={i + "-expandable"}
                                        className={"expandable " + dist.class}
                                        >
                                        <td colSpan="7">
                                            <Quotes
                                                name="quotes"
                                                distributorIndex={i}
                                                tabsType={this.props.tabsType}
                                                quotes={dist.quotes}
                                                manufacturers={this.props.manufacturers}
                                                component={this.props.component}
                                                selectAllItems={this.props.selectAllItems}
                                                distClass={distClass}
                                                onChange={(event) => this.onInputChange(event, i)}
                                                schema={this.schema.quotes}
                                                clientSchema={this.clientSchema.quotes}
                                                status={this.props.status}
                                                toggleRefreshBtn={this.props.toggleRefreshBtn}
                                                primarySource={this.props.primarySource}
                                                onPrimarySourceChange={this.props.onPrimarySourceChange}
                                                onPrimarySourceDeselect={this.props.onPrimarySourceDeselect}
                                                syncPrimaryQuoteInputWithData={this.props.syncPrimaryQuoteInputWithData}
                                                removeSource={this.props.removeSource}
                                                clearSourceWarning={this.props.clearSourceWarning}
                                                toggleCountOnRemove={this.props.toggleCountOnRemove}
                                                validateSourcingFields={this.props.validateSourcingFields}
                                                defaultCurrency={this.props.defaultCurrency}
                                            />
                                        </td>
                                    </tr>

                                return [rowA, rowB]
                            })
                        }
                        <tr
                            className={"add-row " + (this.props.manClass + 'add-distributor ')}
                            onClick={() => this.add()}
                            >
                            <td>
                                <div
                                >
                                    <InlineIcon tooltip={"Add new distributor"} tooltipPlace="top">
                                        <AddSrc />
                                    </InlineIcon>
                                </div>
                            </td>
                            <td>
                                <span className="add-row-text">
                                    Add Row
                                </span>
                            </td>

                            <td/>
                            <td/>
                            <td/>
                            <td/>
                            <td/>
                        </tr>
                    </tbody>
                </table>
            </div>
        return markup
    }
}

export default Distributors


