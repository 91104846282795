import                              "./index.css"
import React, {Component} from "react"
import Query              from "../../../modules/query"
import MixedList          from "./modules/mixed-list"
import ProductList        from "./modules/product-list"
import ComponentList      from "./modules/component-list"
import ChangeOrderList    from "./modules/change-order-list"
import ReleaseList        from "./modules/release-list";
import {connect}          from "react-redux"
import buildAction        from "../../../helpers/buildAction"
import SEARCH             from "../../../action-types/search"
import UI                 from "../../../action-types/ui"
import Permissions        from "../../../modules/schemas/permissions"
import ExportMenu         from "../common/export/export-menu"
import Utils              from "../../../modules/utils"
import Actions            from "./modules/actions"
import DeleteModal        from "./modules/delete-modal"
import UpdateStatusModal  from "../common/update-status-modal"
import CO                 from '../../../action-types/changeorder';
import {Helmet}           from "react-helmet"
import UpdateStatusIcon   from "../../../assets/icons/update-status-icon"
import DuplicateIcon      from "../../../assets/icons/duplicate-new"
import DeleteIcon         from "../../../assets/icons/delete-new"
import ExportIcon         from "../../../assets/icons/export-new"
import CoRevertIcon       from "../../../assets/icons/co-revert"
import RevertBackModal    from "../../ui/revert-back-modal"

export class Search extends Component
{

    constructor(props, context)
    {
        super(props, context)
        this.setInitialState          = this.setInitialState.bind(this)
        this.nextPage                 = this.nextPage.bind(this)
        this.prevPage                 = this.prevPage.bind(this)
        this.fetch                    = this.fetch.bind(this)
        this.showExport               = this.showExport.bind(this)
        this.onExportCancel           = this.onExportCancel.bind(this)
        this.addToSelectedList        = this.addToSelectedList.bind(this)
        this.displayDeleteModal       = this.displayDeleteModal.bind(this)
        this.hideDeleteModal          = this.hideDeleteModal.bind(this)
        this.selectAllResults         = this.selectAllResults.bind(this)
        this.setActionIconsState      = this.setActionIconsState.bind(this)
        this.deleteSelectedItems      = this.deleteSelectedItems.bind(this)
        this.revertSelectedItems      = this.revertSelectedItems.bind(this)
        this.duplicateSelectedItems   = this.duplicateSelectedItems.bind(this)
        this.resetLocalState          = this.resetLocalState.bind(this)
        this.showAlert                = this.showAlert.bind(this)
        this.onExportComplete         = this.onExportComplete.bind(this)
        this.createCoWithDefaultData = this.createCoWithDefaultData.bind(this)
        this.displayUpdateStatusModal = this.displayUpdateStatusModal.bind(this)
        this.hideUpdateStatusModal    = this.hideUpdateStatusModal.bind(this)
        this.refreshAfterBulkUpdate= this.refreshAfterBulkUpdate.bind(this)
        this.checkInvalidItemForAction = this.checkInvalidItemForAction.bind(this);
        this.displayRevertModal       = this.displayRevertModal.bind(this);

        this.state =
        {
            showLoading           : false,
            displayDeleteModal    : false,
            showExportMenu        : false,
            displayColumnsSetting : false,
            clearSelectedRows     : false,
            displayStatusModal    : false,
            displayRevertModal    : false,
            selectedResults       : {
                cmp  : [],
                prd   : [],
                co : []
            },
            defaultTemplate: Utils.getDefaultExportTemplate()
        }

        this.initialLocalState = Utils.clone(this.state)
        this.clearSelectedRowsStore    = this.clearSelectedRowsStore.bind(this)
        this.getIconsActionsList       = this.getIconsActionsList.bind(this);
        this.handlePage                = this.handlePage.bind(this);
        this.imagesWithSrc = [];
        this.setInitialState()
    }

    onExportComplete(){
        let state = this.state
        this.clearSelectedRowsStore()
        state.showExportMenu = false
        this.setState(state)
    }

    clearSelectedRowsStore(){
        const {dispatch} = this.props
        dispatch(buildAction(SEARCH.CLEAR_SELECTED_RESULTS))
    }

    refreshAfterBulkUpdate(list=null)
    {
        let state                = this.state
        state.selectedResults = {
            cmp  : [],
            prd   : [],
            co : []
        }
        let {search} = this.props
        state.displayStatusModal = false
        let imported_ids         = []
        search.selectedSearchResults = []
        list.forEach((item) =>
        {
            search.selectedSearchResults.push({alias: item.alias, _id: item._id})
            imported_ids.push(item._id)
        });

        let records_length = this.props.search.results.length
        let success_message = ""
        this.selectAllResults(false, this.setState(state, ()=>{
            this.props.history.push("/search", {query: "type:cmp", success_message, total_records: records_length, imported_ids: imported_ids, sortBy: 'id', is_after_bulk_update: true})
            this.fetch()
        }))
    }

    showAlert(type, value)
    {
        const {dispatch} = this.props
        if(type === "modal")
        {
            dispatch(buildAction(UI.SHOW_ALERT, {type: "modal", text: value}))
        }

        if(type === "errors")
        {
            dispatch(buildAction(UI.SHOW_ALERT, {type: "errors", errors: value}))
        }
    }

    componentDidMount(){
        if (typeof window.loadIterateSurvey !== "undefined") {
            window.loadIterateSurvey();
        }
    }

    componentWillMount(){
        if (this.props.location.state && this.props.location.state.success_message && this.props.location.state.imported_ids && this.props.location.state.sortBy)
        {
            let sort      = {}
            if (this.props.location.state.sortBy === "lastModified")
                sort.lastModified = 1
            else
                sort.created = 1
            const page      = 1
            const payload = {
                sort: sort,
                page: page
            }
            const {dispatch} = this.props;
            dispatch(buildAction(SEARCH.UPDATE_SEARCH_STATE, payload));
        }
        this.fetch()
    }

    componentWillUnmount(){
        const {dispatch} = this.props;
        dispatch(buildAction(SEARCH.RESET_STATE));
    }

    setInitialState()
    {
        let value = this.props.location.state ? this.props.location.state.query : "type:all";
        let query  = Object.keys(Query.parse(value)).length === 0 ? {type: "all"} : Query.parse(value);
        let string = Query.stringify(query);
        const {dispatch} = this.props;
        const payload = {
            lean: true,
            value: value,
            query: {
                string: string,
                object: query
            }
        };

        dispatch(buildAction(SEARCH.SET_INITIAL_STATE, payload));
    }

    resetLocalState()
    {
        let state = this.state
        state     = this.initialLocalState
        state.selectedResults = {
                cmp  : [],
                prd   : [],
                co : []
            }
        this.setState(state)
    }

    deleteSelectedItems()
    {
        this.hideDeleteModal()
        const {dispatch} = this.props;
        let payload = {
            components: this.state.selectedResults.cmp,
            products: this.state.selectedResults.prd
        }
        this.resetLocalState()
        dispatch(buildAction(SEARCH.DELETE_ITEMS, payload));
    }

    revertSelectedItems()
    {
        this.displayRevertModal(false);
        const {dispatch} = this.props;
        let payload = {
            components: this.state.selectedResults.cmp,
            products: this.state.selectedResults.prd
        }
        this.resetLocalState()
        dispatch(buildAction(SEARCH.REVERT_ITEMS, payload));
    }

    duplicateSelectedItems()
    {
        const {dispatch} = this.props;
        this.clearSelectedRowsStore()
        let payload = {
            components: this.state.selectedResults.cmp,
            products: this.state.selectedResults.prd
        }

        let state = this.state
        state.showLoading = true
        this.setState(state)

        dispatch(buildAction(SEARCH.DUPLICATE_ITEMS, payload));
    }

    selectAllResults(selectAll, cb=null)
    {
        let { results } = this.props.search
        let state = this.state
        state.clearSelectedRows = false
        if ( selectAll === false )
        {
            state.selectedResults = {
                cmp  : [],
                prd   : [],
                co : []
            }
        }
        else if ( selectAll === true )
        {
            results.forEach((result, i) =>
            {
                state.selectedResults[result.alias].push(result)
            })
        }

        this.setState(state, cb)
    }

    addToSelectedList(event, result)
    {
        let state = this.state
        let index = state.selectedResults[result.alias].indexOf(result);

        if (event.target.checked === true)
        {
            if (!(index > -1)) {
                state.selectedResults[result.alias].push(result)
            }
        }
        else if (event.target.checked === false)
        {
            if (index > -1) {
               state.selectedResults[result.alias].splice(index, 1);
            }
        }
        this.setState(state)
    }

    componentWillReceiveProps(nextProps)
    {
        let { search } = nextProps
        let results = search && search.results
        let preSelectedSearchResults = search.selectedSearchResults

        if (this.props.search.value !== search.value)
        {
            this.resetLocalState()
        }

        if (preSelectedSearchResults.length > 0)
        {
            this.resetLocalState()
            results.forEach((result, i) =>
            {
                preSelectedSearchResults.forEach((selectedResult, i) =>
                {
                    if (selectedResult._id === result._id)
                    {
                        this.addToSelectedList({target: {checked: true}}, result)
                    }
                })
            })
        }
    }

    setActionIconsState()
    {
        let state = this.state
        let activeDeleteIcon = false
        let activeDuplicateIcon = false
        let activeExportIcon = false
        let activeUpdateStatusIcon = false
        let activeRevertIcon = false

        if (state.selectedResults.prd.length > 0 || state.selectedResults.cmp.length > 0)
        {
            activeDuplicateIcon = true
            activeExportIcon = true
            activeUpdateStatusIcon = true

            let isFoundInvalidItemForDeletion = this.checkInvalidItemForAction("prd", "deletion");
            if (!isFoundInvalidItemForDeletion)
            {
                isFoundInvalidItemForDeletion = this.checkInvalidItemForAction("cmp", "deletion");
            }

            if (!isFoundInvalidItemForDeletion) activeDeleteIcon = true

            let isFoundInvalidItemForRevert = this.checkInvalidItemForAction("prd", "revert");
            if (!isFoundInvalidItemForRevert)
            {
                isFoundInvalidItemForRevert = this.checkInvalidItemForAction("cmp", "revert");
            }

            if (!isFoundInvalidItemForRevert) activeRevertIcon = true
        }

        return {
            activeDeleteIcon,
            activeDuplicateIcon,
            activeExportIcon,
            activeRevertIcon,
            activeUpdateStatusIcon
        }
    }

    checkInvalidItemForAction(type, action)
    {
        let state = this.state;
        for (let item of state.selectedResults[type])
        {
            if ((action === "deletion" && item.status !== "DESIGN") || (action === "revert" && !item.modified)) return true;
        }
    }

    showExport()
    {
        this.setState({showExportMenu: true});
    }

    displayDeleteModal()
    {
        this.setState({displayDeleteModal: true});
    }

    hideDeleteModal()
    {
        this.setState({displayDeleteModal: false});
    }

    displayUpdateStatusModal()
    {
        this.setState({displayStatusModal: true});
    }

    hideUpdateStatusModal()
    {
        this.setState({displayStatusModal: false});
    }

    displayRevertModal(shouldShow = true)
    {
        this.setState({displayRevertModal: shouldShow})
    }

    onExportCancel()
    {
        this.setState({showExportMenu: false});
    }

    nextPage()
    {
        this.handlePage(1);
    }

    prevPage()
    {
        this.handlePage(-1);
    }

    handlePage(value)
    {
        let { search } = this.props;
        const payload = {
            page: search.page+(value)
        }
        const {dispatch} = this.props;
        dispatch(buildAction(SEARCH.UPDATE_SEARCH_STATE, payload));
        this.fetch();
        window.scroll(0, 0)
    }

    fetch(next)
    {
        const {dispatch} = this.props;
        dispatch(buildAction(SEARCH.GET_SEARCH_DATA));
    }

    createCoWithDefaultData()
    {
        const {dispatch} = this.props;
        let payload = {history: this.props.history, authorId: this.props.user.data._id}
        dispatch(buildAction(CO.CREATE_CO_WITH_DEFAULT_DATA, payload))
    }

    getIconsActionsList()
    {
        let type  =  this.props.search.query.object.type;
        const userData = this.props.user.data;
        const isNotCO = type !== "co";
        const isNotRel = type !== "rel";
        const {
            activeDeleteIcon,
            activeDuplicateIcon,
            activeExportIcon,
            activeRevertIcon,
            activeUpdateStatusIcon,
        } = this.setActionIconsState();
        let actionsList = [];
        let actionEntry;
        let restriction = isNotCO && window.__userRole !== "VENDOR";

        if ( restriction )
        {
            actionEntry =
            {
                type          : 'action',
                iconSrc       : <UpdateStatusIcon />,
                toolTip       : "Update Status",
                name          : "Update Status",
                onClick       : (activeUpdateStatusIcon ? this.displayUpdateStatusModal : null),
                active        : (activeUpdateStatusIcon),
            };
            actionsList.push(actionEntry);
        }
        if ( Permissions.can("create", "product", userData) && isNotCO)
        {
            actionEntry =
            {
                type          : 'action',
                iconSrc       : <DuplicateIcon />,
                toolTip       : "Duplicate products and components",
                name          : "Copy",
                onClick       : (activeDuplicateIcon ? this.duplicateSelectedItems : null),
                className     : (activeDuplicateIcon ? "" : "muted-text"),
                active        : (activeDuplicateIcon),
                iconClassName : (activeDuplicateIcon ? "" : "disabled")
            };
            actionsList.push(actionEntry);
        }
        if ( Permissions.can("delete", "product", userData) && isNotCO)
        {
            actionEntry =
            {
                type          : 'action',
                iconSrc       : <DeleteIcon />,
                toolTip       : "Delete products and components",
                name          : "Delete",
                onClick       : (activeDeleteIcon ? this.displayDeleteModal : null),
                active        : (activeDeleteIcon),
            };
            actionsList.push(actionEntry);
        }
        if (Permissions.can("view", "export_cmp_prd", userData) && isNotCO) {
          actionEntry = {
            type: "action",
            iconSrc: <ExportIcon />,
            toolTip: "Export products and components",
            name: "Export",
            onClick: activeExportIcon ? this.showExport : null,
            active: activeExportIcon,
          };
          actionsList.push(actionEntry);
        }
        
        if ( isNotCO && isNotRel && userData.role === "ADMINISTRATOR")
        {
            actionEntry =
            {
                type          : 'action',
                iconSrc       : <CoRevertIcon />,
                toolTip       : "Revert Products and Components back to their last Released revisions",
                name          : "Revert",
                onClick       : (activeRevertIcon ? () => {this.displayRevertModal()} : null),
                active        : (activeRevertIcon),
            };
            actionsList.push(actionEntry);
        }

        if ( restriction )
        {
            actionsList.push({ type: 'divider' });
        }
        return actionsList;
    }


    render()
    {
        let { search } = this.props
        let { query, results, count, page, loading, integrations } = search;

        let selectedDataForExport    = []
        let state = this.state
        this.state.selectedResults.cmp.forEach((cmp, i) =>
        {
            selectedDataForExport.push( { _id: cmp._id, alias: "cmp" } )
        })
        this.state.selectedResults.prd.forEach((prd, i) =>
        {
            selectedDataForExport.push( { _id: prd._id, alias: "prd" } )
        })

        let checkedResults = [...state.selectedResults.cmp, ...state.selectedResults.prd, ...state.selectedResults.co]
        let resultText = `${count} results`;

        let tableProps = {
            query                    : query,
            results                  : results,
            user                     : this.props.user,
            company                  : this.props.company,
            location                 : this.props.location,
            loading                  : (loading || this.props.ui.processing),
            preSelectedSearchResults : checkedResults,
            addToSelectedList        : this.addToSelectedList,
            selectAllResults         : this.selectAllResults,
            history                  : this.props.history,
            clearSelectedRows        : this.state.clearSelectedRows,
            clearSelectedRowsStore   : this.clearSelectedRowsStore,
            resultText               : resultText,
            getIconsActionsList      : this.getIconsActionsList,
            showShowHideSettings     : this.showShowHideSettings,
            imagesWithSrc            : this.imagesWithSrc,
            allowRowSelect           : Permissions.can("create", "product", this.props.user.data) || Permissions.can("delete", "product", this.props.user.data) || Permissions.can("view", "export_cmp_prd", this.props.user.data)
        }

        let markup =

            <div className="search-route search-page">

                <div className="app-row">

                <Actions
                    query={query}
                    results={results}
                    loading={loading}
                    location={this.props.location}
                    showExport={this.showExport}
                    history={this.props.history}
                    integrations={integrations}
                />

                {(() =>
                {
                    switch(query.object.type)
                    {
                       case "prd" :
                        {
                            return <div>
                                        <Helmet>
                                            <title>{Utils.makeTitle("Products")}</title>
                                        </Helmet>
                                        <ProductList
                                                {...tableProps}
                                            />
                                    </div>
                        }

                        case "cmp" :
                        {
                            return  <div>
                                        <Helmet>
                                            <title>{Utils.makeTitle("Components")}</title>
                                        </Helmet>
                                        <ComponentList
                                            {...tableProps}
                                        />
                                    </div>
                        }

                        case "co" :
                        {
                            return Permissions.can("view", "change_order", this.props.user.data) ?
                                <div>
                                    <Helmet>
                                        <title>{Utils.makeTitle("Change Orders")}</title>
                                    </Helmet>
                                    <ChangeOrderList
                                                {...tableProps}
                                                createCoWithDefaultData={this.createCoWithDefaultData}
                                            />
                                </div>
                                :
                                <div>
                                    <Helmet>
                                        <title>{Utils.makeTitle("All")}</title>
                                    </Helmet>
                                    <MixedList
                                                {...tableProps}
                                            />
                                </div>
                        }

                        case "rel" :
                        {
                            return  <div>
                                        <Helmet>
                                            <title>{Utils.makeTitle("Releases")}</title>
                                        </Helmet>
                                        <ReleaseList
                                            {...tableProps}
                                        />
                                    </div>
                        }

                        default :
                        {
                            return <div>
                                        <Helmet>
                                            <title>{Utils.makeTitle("All")}</title>
                                        </Helmet>
                                        <MixedList
                                            {...tableProps}
                                        />
                                    </div>
                        }
                    }

                })()}
                </div>
                {
                    this.state.showExportMenu &&
                    <ExportMenu
                        showAlert={this.showAlert}
                        onExportComplete={this.onExportComplete}
                        collection={selectedDataForExport}
                        onCancel={this.props.onCancel}
                        currentUserEmail={this.props.user.data.email}
                        onExportCancel={this.onExportCancel}
                        exportFirstChildWithShallow={false}
                        defaultTemplate={this.state.defaultTemplate}
                        user={this.props.user}
                        mode={window.__userRole === "VENDOR" ? "revision" : ""}
                    />
                }

                {
                    this.state.displayDeleteModal &&
                    <DeleteModal
                        components={this.state.selectedResults.cmp}
                        products={this.state.selectedResults.prd}
                        hideDeleteModal={this.hideDeleteModal}
                        deleteSelectedItems={this.deleteSelectedItems}
                    />
                }
                {
                    this.state.displayStatusModal &&
                    <UpdateStatusModal
                        hideUpdateStatusModal={this.hideUpdateStatusModal}
                        components={this.state.selectedResults.cmp}
                        products={this.state.selectedResults.prd}
                        refreshAfterBulkUpdate={this.refreshAfterBulkUpdate}
                    />
                }
                {
                    this.state.displayRevertModal &&
                    <RevertBackModal
                        onCancel={() => this.displayRevertModal(false)}
                        components={this.state.selectedResults.cmp}
                        products={this.state.selectedResults.prd}
                        onConfirm={this.revertSelectedItems}
                    />
                }
            </div>

        return markup
    }
}

export default connect((store) => ({
    user: store.user,
    company: store.company,
    search: store.search,
    ui: store.ui
}))(Search)
