import            "./index.css"
import React, { Component } from 'react';

class ModalAlert extends Component
{
    render()
    {
        let markup = null
        markup =
            <div className="ui-alert string">
                <input type="text" autoFocus={true}/>
                <div className="background" onClick={this.props.reject}></div>
                <div className="modal string">
                    <div className="messages string">
                        <h1>{this.props.text}</h1>
                    </div>
                    <div className="buttons">
                        <button onClick={this.props.confirm}>OK</button>
                    </div>
                </div>
            </div>


        return markup
    }
}

export default ModalAlert
