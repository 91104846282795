import                          "./index.css"
import Link                     from "../../../../../ui/link"
import React, {Component}       from "react"
import Tooltip                  from 'rc-tooltip'
import Spinner                  from "../../../../../ui/spinner"
import Utils                    from "../../../../../../modules/utils"
import API                      from "../../../../../../modules/api"
import { connect }              from "react-redux"
import PkgIcon                  from "../../../../../../assets/icons/pkg-box"
import PkgBulkIcon              from "../../../../../../assets/icons/pkg-bulk"
import PkgCutTapeIcon           from "../../../../../../assets/icons/pkg-cut-tape"
import PkgDigireelIcon          from "../../../../../../assets/icons/pkg-digireel"
import PkgTapeReelIcon          from "../../../../../../assets/icons/pkg-tape-reel"
import PkgTrayIcon              from "../../../../../../assets/icons/pkg-tray"
import DataSheetIcon            from "../../../../../../assets/icons/datasheet"
import AlertErrorIcon           from "../../../../../../assets/icons/alert-error"
import SourcingUtils            from "../../sourcing-utils.js"
import RolledUpTable            from "../../rolled-up-table"
import EditIcon                 from "../../../../../../assets/icons/edit"
import Permissions              from "../../../../../../modules/schemas/permissions"
import ExtendedTable            from "../../../../common/extended-table"
import ComponentProductUtils    from "../../../../../../modules/component-product-utils"
import InlineIcon               from "../../../../../ui/icon/inline-icon.js"
import DownSrc                  from "../../../../../../assets/icons/down.js"
import UpSrc                    from "../../../../../../assets/icons/up.js"
import WarningSrc               from "../../../../../../assets/icons/warning.js"
import SyncedSrc                from "../../../../../../assets/icons/synced.js"
import $                        from "jquery";
import PrimarySourceIcon        from "../../../../../../assets/icons/checkbox-with-center-dot";
import RefreshIcon              from "../../../../../../assets/icons/refresh-icon.js"
import RefreshQuoteModal        from "../../../validation-running-modal"
import buildAction              from "../../../../../../helpers/buildAction"
import COMPONENT                from "../../../../../../action-types/component"
import TimeStampColumn          from "../../../../common/timestamp-column";
import CurrencyField            from "../../../../common/currency-field";
import prependHttp              from 'prepend-http';
var jsdiff = require('diff');

export class SourcingRowView extends Component
{
    constructor(props)
    {
        super(props)

        let sourcingStyles;
        try
        {
            sourcingStyles = this.props.user.preferences.styles.sourcingViewRowMode || {}
        }
        catch(error) {
            sourcingStyles = {}
        }

        this.state =
        {
            data: [],
            rows: [],
            quoteRefreshbtn: false,
            processModal   : false,
            isManufacturerExist : false,
            currentSortItemAscending: ("defaultSortAssending" in sourcingStyles ? sourcingStyles.defaultSortAssending : true),
            current : ("defaultSortColumnName" in sourcingStyles ? sourcingStyles.defaultSortColumnName : "mfrMpn"),
            sourcingHeadings : this.createHeadings(sourcingStyles),
            selectedSourcingRowsIds : [],

        }

        this.onSourcingRowSelect             = this.onSourcingRowSelect.bind(this)
        this.onPrimaryRowDeselect            = this.onPrimaryRowDeselect.bind(this)
        this.getSourcingRows                 = this.getSourcingRows.bind(this)
        this.onMouseHoverOdWarningElOfSource = this.onMouseHoverOdWarningElOfSource.bind(this)
        this.getUnitPriceState               = this.getUnitPriceState.bind(this);
        this.getLeadTimeState                = this.getLeadTimeState.bind(this);
        this.onSelectAllRows                 = this.onSelectAllRows.bind(this);
        this.getActionListForSourcing        = this.getActionListForSourcing.bind(this);
        this.refreshQuote                    = this.refreshQuote.bind(this);
        this.isPrimaryRowExist               = this.isPrimaryRowExist.bind(this);

        this.markRowAsSelected               = this.markRowAsSelected.bind(this);
        this.updateSourcingState             = this.updateSourcingState.bind(this);
        this.onCheckBoxChange                = this.onCheckBoxChange.bind(this);
    }

    createHeadings(sourcingStyles)
    {
        let headings =
        [
            {
                key         : "mfrMpn",
                displayName : "MPN",
                tooltip     : "MPN",
                sortable    : true,
                ascending   : true,
                minWidth    : 71,
                width       : Utils.getStyleValue(sourcingStyles, "mfrMpn", "width", 71),
                position    : 0,
                visibility  : true,
                disabled    : true,
                dragable    : false
            },

            {
                key         : "mfrName",
                displayName : "MANUFACTURER",
                tooltip     : "MANUFACTURER",
                sortable    : true,
                ascending   : true,
                minWidth    : 71,
                width       : Utils.getStyleValue(sourcingStyles, "mfrName", "width", 140),
                position    : Utils.getStyleValue(sourcingStyles, "mfrName", "position", 1),
                visibility  : Utils.getStyleValue(sourcingStyles, "mfrName", "visibility", true),
                disabled    : true
            },

            {
                key         : "mfrDescription",
                displayName : "MFR DESCRIPTION",
                tooltip     : "MFR DESCRIPTION",
                sortable    : true,
                ascending   : true,
                minWidth    : 71,
                width       : Utils.getStyleValue(sourcingStyles, "mfrDescription", "width", 151),
                position    : Utils.getStyleValue(sourcingStyles, "mfrDescription", "position", 2),
                visibility  : Utils.getStyleValue(sourcingStyles, "mfrDescription", "visibility", true),
                disabled    : false
            },

            {
                key         : "mfrDatasheet",
                displayName : "DATASHEET",
                tooltip     : "DATASHEET",
                sortable    : true,
                ascending   : true,
                minWidth    : 71,
                width       : Utils.getStyleValue(sourcingStyles, "mfrDatasheet", "width", 111),
                position    : Utils.getStyleValue(sourcingStyles, "mfrDatasheet", "position", 3),
                visibility  : Utils.getStyleValue(sourcingStyles, "mfrDatasheet", "visibility", true),
                disabled    : false
            },

            {
                key         : "distName",
                displayName : "DISTRIBUTOR",
                tooltip     : "DISTRIBUTOR",
                sortable    : true,
                ascending   : true,
                minWidth    : 71,
                width       : Utils.getStyleValue(sourcingStyles, "distName", "width", 120),
                position    : Utils.getStyleValue(sourcingStyles, "distName", "position", 5),
                visibility  : Utils.getStyleValue(sourcingStyles, "distName", "visibility", true),
                disabled    : false
            },

            {
                key         : "distDpn",
                displayName : "DPN",
                tooltip     : "DPN",
                sortable    : true,
                ascending   : true,
                minWidth    : 71,
                width       : Utils.getStyleValue(sourcingStyles, "distDpn", "width", 71),
                position    : Utils.getStyleValue(sourcingStyles, "distDpn", "position", 6),
                visibility  : Utils.getStyleValue(sourcingStyles, "distDpn", "visibility", true),
                disabled    : false
            },

            {
                key         : "distDescription",
                displayName : "DIST DESCRIPTION",
                tooltip     : "DIST DESCRIPTION",
                sortable    : true,
                ascending   : true,
                minWidth    : 71,
                width       : Utils.getStyleValue(sourcingStyles, "distDescription", "width", 152),
                position    : Utils.getStyleValue(sourcingStyles, "distDescription", "position", 7),
                visibility  : Utils.getStyleValue(sourcingStyles, "distDescription", "visibility", true),
                disabled    : false
            },

            {
                key         : "distPackageType",
                displayName : "PACKAGE",
                tooltip     : "PACKAGE",
                sortable    : true,
                ascending   : true,
                minWidth    : 71,
                width       : Utils.getStyleValue(sourcingStyles, "distPackageType", "width", 97),
                position    : Utils.getStyleValue(sourcingStyles, "distPackageType", "position", 8),
                visibility  : Utils.getStyleValue(sourcingStyles, "distPackageType", "visibility", true),
                disabled    : false
            },

            {
                key         : "distPackageQuantity",
                displayName : "PACKAGE QTY",
                tooltip     : "PACKAGE QTY",
                sortable    : true,
                ascending   : true,
                minWidth    : 71,
                width       : Utils.getStyleValue(sourcingStyles, "distPackageQuantity", "width", 124),
                position    : Utils.getStyleValue(sourcingStyles, "distPackageQuantity", "position", 9),
                visibility  : Utils.getStyleValue(sourcingStyles, "distPackageQuantity", "visibility", true),
                disabled    : false
            },

            {
                key         : "quoteMinQuantity",
                displayName : "MIN QTY",
                tooltip     : "MIN QTY",
                sortable    : true,
                ascending   : true,
                minWidth    : 71,
                width       : Utils.getStyleValue(sourcingStyles, "quoteMinQuantity", "width", 91),
                position    : Utils.getStyleValue(sourcingStyles, "quoteMinQuantity", "position", 10),
                visibility  : Utils.getStyleValue(sourcingStyles, "quoteMinQuantity", "visibility", true),
                disabled    : false
            },

            {
                key         : "quoteUnitPrice",
                displayName : "UNIT PRICE",
                tooltip     : "UNIT PRICE",
                sortable    : true,
                ascending   : true,
                minWidth    : 71,
                width       : Utils.getStyleValue(sourcingStyles, "quoteUnitPrice", "width", 107),
                position    : Utils.getStyleValue(sourcingStyles, "quoteUnitPrice", "position", 11),
                visibility  : Utils.getStyleValue(sourcingStyles, "quoteUnitPrice", "visibility", true),
                disabled    : false
            },

            {
                key         : "quoteLeadTime",
                displayName : "QUOTE LEAD TIME",
                tooltip     : "QUOTE LEAD TIME",
                sortable    : true,
                ascending   : true,
                minWidth    : 71,
                width       : Utils.getStyleValue(sourcingStyles, "quoteLeadTime", "width", 149),
                position    : Utils.getStyleValue(sourcingStyles, "quoteLeadTime", "position", 12),
                visibility  : Utils.getStyleValue(sourcingStyles, "quoteLeadTime", "visibility", true),
                disabled    : false
            },
            {
                key         : "quoteLastUpdated",
                displayName : "LAST UPDATED",
                tooltip     : "LAST UPDATED",
                sortable    : true,
                ascending   : true,
                minWidth    : 71,
                width       : Utils.getStyleValue(sourcingStyles, "quoteLastUpdated", "width", 149),
                position    : Utils.getStyleValue(sourcingStyles, "quoteLastUpdated", "position", 13),
                visibility  : Utils.getStyleValue(sourcingStyles, "quoteLastUpdated", "visibility", true),
                disabled    : false
            }
        ];

        if(SourcingUtils.isWarrantyFieldEnabled(this.props.isDiffTool))
        {
            headings.push(
            {
                key         : "mfrWarranty",
                displayName : "WARRANTY",
                tooltip     : "WARRANTY",
                sortable    : true,
                ascending   : true,
                minWidth    : 71,
                width       : Utils.getStyleValue(sourcingStyles, "mfrWarranty", "width", 140),
                position    : Utils.getStyleValue(sourcingStyles, "mfrWarranty", "position", 4),
                visibility  : Utils.getStyleValue(sourcingStyles, "mfrWarranty", "visibility", true),
                disabled    : false
            });
        }

        return headings;
    }

    getActionListForSourcing()
    {
        let actionsList = [];
        let actionEntry;
        if(this.props.mode !== 'revision')
        {
            actionEntry =
            {
                type          : 'action',
                iconSrc       : <PrimarySourceIcon />,
                toolTip       : "Set selected as Primary Source",
                onClick       : (this.props.primarySource ? this.props.setPrimarySource : null),
                active        : this.props.primarySource ? true : false,
                name          : 'Set Primary',
                iconClassName : `primarySource ${this.props.primarySource ? 'white' : 'dark'}`
            };
            actionsList.push(actionEntry);
            if (this.props.tabsType === "cmp")
            {
                actionsList.push({ type: 'divider' });
                actionEntry =
                {
                    type          : 'action',
                    iconSrc       : <RefreshIcon/>,
                    toolTip       : "Refresh Quote",
                    name          : "Update",
                    iconClassName : "update",
                    className     : "update" + ((this.state.quoteRefreshbtn === true) ? "" : " disable-pointer-event"),
                    onClick       : (this.refreshQuote),
                    active        : ((this.state.quoteRefreshbtn === true) ? true : false)
                };
                actionsList.push(actionEntry);
            }
        }
        return actionsList;
    }

    removeSource(event, row)
    {
        let filteredData = null;
        let removeAllSource = false;
        if(this.state.selectedSourcingRowsIds.includes(row.quote_id)) removeAllSource = true;
        if (removeAllSource) {
            filteredData = SourcingUtils.getFilteredData(this.props.manufacturers, null);
        } else {
            filteredData = SourcingUtils.getFilteredData(this.props.manufacturers, row.quote_id);
        }
        this.state.quoteRefreshbtn = false;
        if (filteredData.length === 0) {
            if ($(".index-table thead input[type=checkbox]:checked"))
            {
                $(".index-table thead input[type=checkbox]").click();
            }
        } else {
            $('.index-table thead .index-checkbox label').removeClass("middle");
        }
        this.updateSourcingState(filteredData, row.quote_id, true);
    }

    clearSourceWarning(event, row)
    {
        let clearedData = null;
        let clearAllSourceWarning = false;
        if(this.state.selectedSourcingRowsIds.includes(row.quote_id)) clearAllSourceWarning = true;
        if (clearAllSourceWarning) {
            clearedData = SourcingUtils.getClearedData(this.props.manufacturers, null);
        } else {
            clearedData = SourcingUtils.getClearedData(this.props.manufacturers, row.quote_id);
        }
        this.updateSourcingState(clearedData, row.quote_id);
    }

    onCheckBoxChange(event, quoteId)
    {
        let value = event.target.checked;
        let selectedSourcingRowsIds = this.state.selectedSourcingRowsIds;
        if(value)
        {
            selectedSourcingRowsIds.push(quoteId);
        }
        else
        {
            const index = selectedSourcingRowsIds.indexOf(quoteId);
            if (index > -1) {
              selectedSourcingRowsIds.splice(index, 1);
            }
        }
        this.setState({selectedSourcingRowsIds});
    }

    updateSourcingState(updatedData, quoteId, updateModified=false)
    {
        let { component }      = this.props;
        if(updateModified && component.status !== "DESIGN") component.modified = true;
        component.manufacturers = updatedData;
        API.components.update(component._id, component, (err, source) =>
        {
            if(err)
            {
                // toDo.
            }
            if (source) {
                // toDo.
            }
        })
        let data         = SourcingUtils.manufacturersToRowData(updatedData, null, true);
        this.state.rows  = this.getSourcingRows(data);
        const index = this.state.selectedSourcingRowsIds.indexOf(quoteId);
        if (index > -1) {
            this.state.selectedSourcingRowsIds = [];
        }
        this.props.dispatch(buildAction(COMPONENT.SET_COMPONENT, component))
        this.setState(this.state);
    }

    refreshQuote()
    {
        let category = this.props.component.category;
        let _this = this;
        let selectedQuotes = [];
        _this.setState({processModal : true});
        _this.state.rows.forEach((row, index) => {
            if (row.rowSelected || row.isPrimary) {
                selectedQuotes.push({quote_id: row.quote_id, MPN: row.mfrMpn.value, DPN: row.distDpn.value});
            }
        });
        let viewData = SourcingUtils.addSelectedFieldToExistingQuotesProps(this.props.manufacturers, selectedQuotes);

        let callBackCount = 0;
        viewData.forEach((man, manIndex) =>
        {
            API.components.getSource(category, man.mpn.key, (err, source) =>
            {
                callBackCount++;
                if(err)
                {
                    if (err && err.errors.length) {
                        SourcingUtils.handleNotExistQuotes(man);
                    }
                    if (viewData.length === callBackCount) {
                        _this.setState({processModal : false});
                    }
                }

                if (source) {
                    let manuArray = [];
                    manuArray.push(source);
                    let apiData = SourcingUtils.manufacturersToRowData(manuArray, null, true);
                    if (man.distributors.length > 0)
                    {
                        man.distributors.forEach((dist, distIndex) =>
                        {
                            if (dist.quotes.length > 0)
                            {
                                dist.quotes.forEach((quote, quoteIndex) =>
                                {
                                    if (quote.selectedRow) {
                                        quote.unitPriceState = "worsened";
                                        quote.lastUpdated = Date.now();

                                        apiData.forEach((apiResp, index) => {
                                            if (apiResp.mfrMpnKey === man.mpn.key &&
                                                 apiResp.distDpnKey === dist.dpn.key &&
                                                  Number(apiResp.quoteMinQuantity) === quote.minQuantity) {
                                                    quote.modified = false;
                                                    SourcingUtils.checkViewQuoteState(apiResp, quote);
                                            }
                                        })
                                        let uniqueRowName = `.man${manIndex}.dist${distIndex}.quote${quoteIndex}`;
                                        let selected = document.querySelectorAll(`${uniqueRowName} input:checked`).length;
                                        if(quote.unitPriceState === "worsened" && selected > 0) $(`${uniqueRowName} input:checkbox`).click();
                                    }

                                }) // end dist.quotes.forEach((quote, i) =>
                            } // if (dist.quotes.length > 0)
                        }) // end man.distributors.forEach((dist, i) =>
                    } // end if (man.distributors.length > 0)
                }
                if (viewData.length === callBackCount) {
                    _this.updateManufacturers(viewData);
                }
            })
        }) // end manufacturers.forEach((man, i) =>


    }

    updateManufacturers(viewData)
    {
        let { component }      = this.props
        component.manufacturers = viewData;
        API.components.update(component._id, component, (err, source) =>
        {
            if(err)
            {
                // toDo.
            }
            if (source) {
                // toDo.
            }
        })
        let data         = SourcingUtils.manufacturersToRowData(viewData, null, true)
        this.state.rows  = this.getSourcingRows(data)
        this.state.processModal = false
        this.setState(this.state)
    }

    onSelectAllRows(checked)
    {
        let isPrimaryRowExist = false;
        this.state.rows.forEach((row) =>
        {
            row.rowSelected = checked;
            if (row.isPrimary) {
                isPrimaryRowExist = true;
            }
        });
        this.props.manufacturers.forEach((man, i) =>
        {
            man.distributors.forEach((dist, i) =>
            {
                dist.quotes.forEach((quote, i) =>{
                    quote.selectedRow = checked;
                })
            })
        })
        //enable update button ...
        if (checked || isPrimaryRowExist) {
            this.setState({quoteRefreshbtn: true});

        } else {
            this.setState({quoteRefreshbtn: false});
        }
    }

    onMouseHoverOdWarningElOfSource(event, addClass=true)
    {
        let element = event.target
        var rowIndex = element.closest("td").closest('tr').rowIndex + 1
        let selector = ".sourcing-row-mode #extended-data-table-wrapper tbody tr:nth-child(" + rowIndex + ") td.invalidSource"
        let list = document.querySelectorAll(selector);
        for (var i = 0; i < list.length; ++i) {
            if (addClass)
                list[i].classList.add('error');
            else
                list[i].classList.remove('error');
        }
    }

    onSourcingRowSelect(event, item)
    {
        // This method adding a property *checked* in the selected row to later retrive for update (refresh) quotes...
        this.markRowAsSelected(event.target.checked, item)

        let state = this.state
        let lastSelectedRow = {};
        let isPrimaryRowExist = false;
        let count = 0;
        state.rows.forEach((row) =>
        {
            if (row.rowSelected)
            {
                lastSelectedRow = row;
                count++;
            }
            if (row.isPrimary) {
                isPrimaryRowExist = true;
            }
        })

        this.props.manufacturers.forEach((man, i) =>
        {
            man.distributors.forEach((dist, i) =>
            {
                dist.quotes.forEach((quote, i) =>{
                    if(quote._id === item.quote_id) quote.selectedRow = event.target.checked;
                })
            })
        })

        // Updated set Primary row selection logic to activate only single row is selected...
        let isValidRow = !!(lastSelectedRow.quote_id && lastSelectedRow.quoteMinQuantity.value !== null && lastSelectedRow.quoteUnitPrice.value !== null );
        if (count == 1 && isValidRow) {
            state.quoteRefreshbtn = true;
            event.target.checked = true;
            this.props.onPrimaryRowSelection(event, lastSelectedRow);
        } else {
            state.quoteRefreshbtn = true;
            event.target.checked = false;
            this.props.onPrimaryRowSelection(event, item);
        }
        state.syncSourcingRowTable = true
        // DeActivate refresh button...
        if (count == 0 && isPrimaryRowExist == false) {
            state.quoteRefreshbtn = false;
        }
        this.setState(state)
    }

    markRowAsSelected(checked=false, row={})
    {
        let state = this.state
        let quote_id = row.quote_id
        state.rows.forEach((row) =>
        {
            // row.rowSelected = false
            if (row.quote_id === quote_id)
            {
               row.rowSelected = checked
            }
        })
    }

    onPrimaryRowDeselect(event)
    {
        this.props.onPrimaryRowDeselection(event)
        this.markRowAsSelected(false)
    }

    isPrimaryRowExist(data)
    {
        let isPrimaryRowExist = false;
        this.state.rows.forEach((row) =>
        {
            // row.rowSelected = false
            if (row.isPrimary)
            {
                isPrimaryRowExist = true;
                return;
            }
        })
        return isPrimaryRowExist;
    }

    componentWillMount()
    {
        let manufacturers = this.props.manufacturers
        let component     = this.props.component;
        let data          = SourcingUtils.manufacturersToRowData(manufacturers, component.lastModified, true)

        let state  = this.state
        state.data = data
        state.rows = this.getSourcingRows(data)
        // calculate and fetch primary row if exist...
        if (manufacturers.length) {
            state.isManufacturerExist = true;
        }
        state.quoteRefreshbtn = this.isPrimaryRowExist(data);
        this.setState(state)
    }

    importPkgIconWithType(type)
    {
        type = type || ""
        let pkgType = type.toLowerCase()
        switch(pkgType)
        {
            case "box" :
            {
                return <PkgIcon />
            }

            case "bulk" :
            {
                return <PkgBulkIcon />
            }

            case "cut tape" :
            {
                return <PkgCutTapeIcon />
            }

            case "digireel" :
            case "custom reel" :
            {
                return <PkgDigireelIcon />
            }

            case "tape & reel" :
            {
                return <PkgTapeReelIcon />
            }

            case "tray" :
            {
                return <PkgTrayIcon />
            }

            default :
            {
                return ""
            }
        }
    }

    getUnitPriceState(data, unitPrice) {

        let diffDays = Utils.timeDiff(data.quoteLastUpdated);
        let result = unitPrice;
        if (diffDays <= 1) {
            result = <span className="row-with-icon">
                                        {
                                            data.quoteUnitPriceState === "noChange" ?
                                                <React.Fragment>
                                                <span className="data-value white">
                                                    {unitPrice}
                                                </span>
                                                <span className="icon-value">
                                                    <InlineIcon>
                                                        <SyncedSrc />
                                                     </InlineIcon>
                                                </span>
                                                </React.Fragment>
                                                 :
                                            data.quoteUnitPriceState === "up" ?
                                            <React.Fragment>
                                                <span className="data-value red">
                                                    {unitPrice}
                                                </span>
                                                <span className="icon-value">
                                                    <InlineIcon>
                                                        <UpSrc />
                                                     </InlineIcon>
                                                </span>
                                            </React.Fragment>
                                                 :
                                            data.quoteUnitPriceState === "down" ?
                                            <React.Fragment>
                                                <span className="data-value green">
                                                    {unitPrice}
                                                </span>
                                                <span className="icon-value">
                                                    <InlineIcon>
                                                        <DownSrc />
                                                     </InlineIcon>
                                                </span>
                                            </React.Fragment>
                                                 :
                                                 <React.Fragment>
                                                     <span className="data-value">
                                                         {unitPrice}
                                                     </span>
                                                 </React.Fragment>
                                        }
                                    </span>
        }
        return result;

    }

    getLeadTimeState(result)
    {
        let diffDays = Utils.timeDiff(result.quoteLastUpdated);
        let data = <span className="lead-time">
                                        <span>
                                            {result.quoteLeadTimeValue}
                                        </span>
                                        <span>
                                            {result.quoteLeadTimeUnits}
                                        </span>
                                    </span>

        if (diffDays <= 1) {
           data = <span className="row-with-icon">
                {
                    result.quoteLeadTimeState === "noChange" ?
                        <React.Fragment>
                        <span className="data-value white">
                            <span className="lead-time">
                                <span>
                                    {result.quoteLeadTimeValue}
                                </span>
                                <span>
                                    {result.quoteLeadTimeUnits}
                                </span>
                            </span>
                        </span>
                        <span className="icon-value">
                            <InlineIcon>
                                <SyncedSrc />
                             </InlineIcon>
                        </span>
                        </React.Fragment>
                         :
                    result.quoteLeadTimeState === "up" ?
                    <React.Fragment>
                        <span className="data-value red">
                            <span className="lead-time">
                                <span>
                                    {result.quoteLeadTimeValue}
                                </span>
                                <span>
                                    {result.quoteLeadTimeUnits}
                                </span>
                            </span>
                        </span>
                        <span className="icon-value">
                            <InlineIcon>
                                <UpSrc />
                             </InlineIcon>
                        </span>
                    </React.Fragment>
                         :
                    result.quoteLeadTimeState === "down" ?
                    <React.Fragment>
                        <span className="data-value green">
                            <span className="lead-time">
                                <span>
                                    {result.quoteLeadTimeValue}
                                </span>
                                <span>
                                    {result.quoteLeadTimeUnits}
                                </span>
                            </span>
                        </span>
                        <span className="icon-value">
                            <InlineIcon>
                                <DownSrc />
                             </InlineIcon>
                        </span>
                    </React.Fragment>
                         :
                         <React.Fragment>
                             <span className="data-value">
                                 <span className="lead-time">
                                     <span>
                                         {result.quoteLeadTimeValue}
                                     </span>
                                     <span>
                                         {result.quoteLeadTimeUnits}
                                     </span>
                                 </span>
                             </span>
                         </React.Fragment>
                }
            </span>
        }

        return data;
    }

    getWarrantyState(value, unit)
    {
        let data = <span className="warranty-field">
                        <span>
                            {value}
                        </span>
                        <span>
                            {unit}
                        </span>
                    </span>
        return data;
    }

    getMatchedSource(sources, srcId)
    {
        let src = null;
        for(let i = 0; i < sources.length; i++) {

            if (srcId === sources[i].gId)
            {
                src = sources[i];
                break;
            }
        }
        return {src: src, found: !!src}
    }

    getRowsForDiffTool()
    {
        let sourceAndTargetSources = []
        let sourceAndTargetRevSourcesIds = []

        let sourceRevSourcesWithRowData = SourcingUtils.manufacturersToRowData(this.props.manufacturers, null, false, true)
        let targetRevSourcesWithRowData = SourcingUtils.manufacturersToRowData(this.props.targetRevManufacturers, null, false, true)

        for(let i = 0; i < sourceRevSourcesWithRowData.length; i++) {
            if (!sourceAndTargetRevSourcesIds.includes(sourceRevSourcesWithRowData[i].gId))
            {
                sourceAndTargetSources.push(sourceRevSourcesWithRowData[i])
                sourceAndTargetRevSourcesIds.push(sourceRevSourcesWithRowData[i].gId)
            }
        }

        for(let i = 0; i < targetRevSourcesWithRowData.length; i++) {
            if (!sourceAndTargetRevSourcesIds.includes(targetRevSourcesWithRowData[i].gId))
            {
                sourceAndTargetSources.push(targetRevSourcesWithRowData[i])
                sourceAndTargetRevSourcesIds.push(targetRevSourcesWithRowData[i].gId)
            }
        }

        for(let i = 0; i < sourceAndTargetSources.length; i++) {
            let isPresentInTargetRev = false
            let isPresentInSourceRev = false

            isPresentInTargetRev = this.getMatchedSource(targetRevSourcesWithRowData, sourceAndTargetSources[i].gId).found
            isPresentInSourceRev = this.getMatchedSource(sourceRevSourcesWithRowData, sourceAndTargetSources[i].gId).found

            if (!isPresentInSourceRev && isPresentInTargetRev)
            {
                sourceAndTargetSources[i].rowClassName = "remove"
            }
            else if (isPresentInSourceRev && !isPresentInTargetRev)
            {
                sourceAndTargetSources[i].rowClassName = "add"
            }

            else if (isPresentInSourceRev && isPresentInTargetRev)
            {
                let sourceRevSrc = this.getMatchedSource(sourceRevSourcesWithRowData, sourceAndTargetSources[i].gId).src
                let targetRevSrc = this.getMatchedSource(targetRevSourcesWithRowData, sourceAndTargetSources[i].gId).src

                let diff = jsdiff.diffJson(targetRevSrc, sourceRevSrc);
                if (diff.length > 1)
                {
                    for (const property in sourceRevSrc) {

                        let sourceRevAttribute = `${sourceRevSrc[property]}`
                        let targetRevAttribute = `${targetRevSrc[property]}`
                        let diff = jsdiff.diffLines(targetRevAttribute, sourceRevAttribute);

                        diff.forEach((part) => {
                            if (part.removed)
                            {
                                sourceAndTargetSources[i][`removed${property.charAt(0).toUpperCase()}${property.slice(1)}`] = part.value

                            }
                            else if (part.added)
                            {
                                sourceAndTargetSources[i][`added${property.charAt(0).toUpperCase()}${property.slice(1)}`] = part.value
                            }
                        })
                    }
                    sourceAndTargetSources[i].rowClassName = "update"
                }
            }
        }

        let rows = sourceAndTargetSources.map((result, i) =>
        {
            let unitPrice = result.quoteUnitPrice ? <CurrencyField symbol={this.props.defaultCurrency} value={result.quoteUnitPrice}/> : '';

            let leadTime = ![undefined, null, ''].includes(result.quoteLeadTimeValue) ? `${result.quoteLeadTimeValue}  ${result.quoteLeadTimeUnits}` : ''

           result.removedQuoteUnitPrice =  result.removedQuoteUnitPrice ? <CurrencyField symbol={this.props.defaultCurrency} value={result.removedQuoteUnitPrice}/> : '';

           result.addedQuoteUnitPrice =  result.addedQuoteUnitPrice ? <CurrencyField symbol={this.props.defaultCurrency} value={result.addedQuoteUnitPrice}/> : '';

            let dateTime = Utils.dateTimeWithLongFormat(result.quoteLastUpdated);
            let isSelected = result.quoteSelectedRow;
            let cells =
            {
                "mfrName" :
                {
                    value        : result.mfrName,
                    displayValue : result.rowClassName === "update" && (result.removedMfrName || result.addedMfrName) ?
                    <div className="diff-update-section">
                        {result.removedMfrName && <div className="update-tag remove">{result.removedMfrName}</div>}
                        {result.addedMfrName && <div className="update-tag add">{result.addedMfrName}</div>}
                    </div> : result.mfrName,
                    tooltip      : result.mfrName,
                    notLink      : true,
                    cellCustomClass : result.rowClassName === "update" ? "diff-tool" : "",
                },

                "mfrMpn" :
                {
                    value        : result.mfrMpnKey,
                    displayValue : result.rowClassName === "update" && (result.removedMfrMpnKey || result.addedMfrMpnKey) ?
                    <div className="diff-update-section">
                        {result.removedMfrMpnKey && <div className="update-tag remove">{result.removedMfrMpnKey}</div>}
                        {result.addedMfrMpnKey && <div className="update-tag add">{result.addedMfrMpnKey}</div>}
                    </div> : result.mfrMpnKey,
                    tooltip      : result.mfrMpnKey,
                    notLink      : true,
                    cellCustomClass : result.rowClassName === "update" ? "diff-tool" : "",

                },

                "mfrDescription" :
                {
                    value        : result.mfrDescription,
                    displayValue : result.rowClassName === "update" && (result.removedMfrDescription || result.addedMfrDescription) ?
                    <div className="diff-update-section">
                        {result.removedMfrDescription && <div className="update-tag remove">{result.removedMfrDescription}</div>}
                        {result.addedMfrDescription && <div className="update-tag add">{result.addedMfrDescription}</div>}
                    </div> : result.mfrDescription,
                    tooltip      : result.mfrDescription,
                    notLink      : true,
                    cellCustomClass : result.rowClassName === "update" ? "diff-tool" : "",

                },

                "mfrDatasheet" :
                {
                    value        : result.mfrDatasheetSrc,
                    cellClass    : 'text-center',
                    displayValue : '',
                    tooltip      : '',
                    notLink      : true
                },

                "distName" :
                {
                    value        : result.distName,
                    displayValue : result.rowClassName === "update" && (result.removedDistName || result.addedDistName) ?
                    <div className="diff-update-section">
                        {result.removedDistName && <div className="update-tag remove">{result.removedDistName}</div>}
                        {result.addedDistName && <div className="update-tag add">{result.addedDistName}</div>}
                    </div> : result.distName,
                    tooltip      : result.distName,
                    notLink      : true,
                    cellCustomClass : result.rowClassName === "update" ? "diff-tool" : "",

                },

                "distDpn" :
                {
                    value        : result.distDpnKey,
                    displayValue : result.rowClassName === "update" && (result.removedDistDpnKey || result.addedDistDpnKey) ?
                    <div className="diff-update-section">
                        {result.removedDistDpnKey && <div className="update-tag remove">{result.removedDistDpnKey}</div>}
                        {result.addedDistDpnKey && <div className="update-tag add">{result.addedDistDpnKey}</div>}
                    </div> : result.distDpnKey,
                    tooltip      : result.distDpnKey,
                    notLink      : true,
                    cellCustomClass : result.rowClassName === "update" ? "diff-tool" : "",

                },

                "distDescription" :
                {
                    value        : result.distDescription,
                    displayValue : result.rowClassName === "update" && (result.removedDistDescription || result.addedDistDescription) ?
                    <div className="diff-update-section">
                        {result.removedDistDescription && <div className="update-tag remove">{result.removedDistDescription}</div>}
                        {result.addedDistDescription && <div className="update-tag add">{result.addedDistDescription}</div>}
                    </div> : result.distDescription,
                    tooltip      : result.distDescription,
                    notLink      : true,
                    cellCustomClass : result.rowClassName === "update" ? "diff-tool" : "",

                },

                "distPackageType" :
                {
                    value        : result.distPackageType,
                    displayValue : result.rowClassName === "update" && (result.removedDistPackageType || result.addedDistPackageType) ?
                    <div className="diff-update-section">
                        {result.removedDistPackageType && <div className="update-tag remove">{result.removedDistPackageType}</div>}
                        {result.addedDistPackageType && <div className="update-tag add">{result.addedDistPackageType}</div>}
                    </div> : result.distPackageType,
                    tooltip      : result.distPackageType,
                    notLink      : true,
                    cellCustomClass : result.rowClassName === "update" ? "diff-tool" : "",

                },

                "distPackageQuantity" :
                {
                    value        : result.distPackageQuantity,
                    displayValue : result.rowClassName === "update" && (result.removedDistPackageQuantity || result.addedDistPackageQuantity) ?
                    <div className="diff-update-section">
                        {result.removedDistPackageQuantity && <div className="update-tag remove">{result.removedDistPackageQuantity}</div>}
                        {result.addedDistPackageQuantity && <div className="update-tag add">{result.addedDistPackageQuantity}</div>}
                    </div> : result.distPackageQuantity,
                    tooltip      : result.distPackageQuantity,
                    notLink      : true,
                    cellCustomClass : result.rowClassName === "update" ? "diff-tool" : "",

                },

                "quoteMinQuantity" :
                {
                    value        : result.quoteMinQuantity,
                    displayValue : result.rowClassName === "update" && (result.removedQuoteMinQuantity || result.addedQuoteMinQuantity) ?
                    <div className="diff-update-section">
                        {result.removedQuoteMinQuantity && <div className="update-tag remove">{result.removedQuoteMinQuantity}</div>}
                        {result.addedQuoteMinQuantity && <div className="update-tag add">{result.addedQuoteMinQuantity}</div>}
                    </div> : result.quoteMinQuantity,
                    cellClass    : '',
                    tooltip      : result.quoteMinQuantity,
                    notLink      : true,
                    cellCustomClass : result.rowClassName === "update" ? "diff-tool" : "",

                },

                "quoteUnitPrice" :
                {
                    value        : result.quoteUnitPrice,
                    displayValue : result.rowClassName === "update" && (result.removedQuoteUnitPrice || result.addedQuoteUnitPrice) ?
                    <div className="diff-update-section">
                        <div className="update-tag remove">
                        {
                            result.removedQuoteUnitPrice
                        }
                        </div>
                        <div className="update-tag add">
                        {
                            result.addedQuoteUnitPrice
                        }
                        </div>
                    </div> : unitPrice,
                    cellClass    : '',
                    tooltip      : '',
                    notLink      : true,
                    cellCustomClass : result.rowClassName === "update" ? "diff-tool" : "",

                },

                "quoteLeadTime" :
                {
                    value        : ![undefined, null, ''].includes(result.quoteLeadTimeValue) ? Utils.stringValueToDays(result.quoteLeadTimeValue, result.quoteLeadTimeUnits) : '',
                    displayValue : result.rowClassName === "update" && (result.removedQuoteLeadTimeValue || result.addedQuoteLeadTimeValue) ?
                    <div className="diff-update-section">
                        <div className="update-tag remove">
                        {
                            `${result.removedQuoteLeadTimeValue} ${result.removedQuoteLeadTimeUnits || result.quoteLeadTimeUnits}`
                        }
                        </div>
                        <div className="update-tag add">
                        {
                            `${result.addedQuoteLeadTimeValue} ${result.addedQuoteLeadTimeUnits || result.quoteLeadTimeUnits}`
                        }
                        </div>
                    </div> : leadTime,
                    cellClass    : '',
                    tooltip      : '',
                    notLink      : true,
                    cellCustomClass : result.rowClassName === "update" ? "diff-tool" : "",
                },
                 "quoteLastUpdated" :
                {
                    value        : result && result.quoteLastUpdated ? result.quoteLastUpdated : '',
                    displayValue : result.rowClassName === "update" && (result.removedQuoteLastUpdated || result.addedQuoteLastUpdated) ?
                    <div className="diff-update-section">
                        {result.removedQuoteLastUpdated && <div className="update-tag remove"><TimeStampColumn key={Utils.generateUniqueId()} format='date-time-with-long-format' value={result.removedQuoteLastUpdated} /></div>}
                        {result.addedQuoteLastUpdated && <div className="update-tag add"><TimeStampColumn key={Utils.generateUniqueId()} format='date-time-with-long-format' value={result.addedQuoteLastUpdated} /></div>}
                    </div> : result.quoteLastUpdated,
                    tooltip      : `${dateTime.dateValue} ${dateTime.timeValue}`,
                    notLink      : true
                },

                rowSelected     : isSelected,
                quote_id        : result.quote_id,
                uniqueRowName   : result.quoteUniqueIdentifier,
                rowId           : result.quote_id,
                object          : result,
                rowClassName    : result.rowClassName,
                indexTableEl    : null
            }
            return cells
        })
        return rows
    }

    getSourcingRows(data)
    {
        if (this.props.isDiffTool)
        {
            return this.getRowsForDiffTool()
        }


        let _this = this;
        let rows = data.map((result, i) =>
        {
            let allowRowSelect = !!(result.quote_id && result.quoteMinQuantity !== null && result.quoteUnitPrice !== null )

            let unitPrice = !result.quoteUnitPrice ? '' : <CurrencyField symbol={this.props.defaultCurrency} value={result.quoteUnitPrice}/>;

            let unitPriceState = this.getUnitPriceState(result, unitPrice);
            let dateTime = Utils.dateTimeWithLongFormat(result.quoteLastUpdated);
            let leadTimeState = this.getLeadTimeState(result);
            let warrantyUnit  = (result.mfrWarranty || result.mfrWarranty === 0) && result.mfrWarrantyUnits  ? Utils.warrantyFieldUnit(result.mfrWarranty, result.mfrWarrantyUnits) : '';
            let isSelected = result.quoteSelectedRow;
            let cells = {
              mfrName: {
                value: result.mfrName,
                displayValue: result.mfrName,
                tooltip: result.mfrName,
                notLink: true,
              },

              mfrMpn: {
                value: result.mfrMpnKey,
                displayValue: result.mfrMpnSrc ? (
                  <Link to={{pathname: prependHttp(result.mfrMpnSrc)}} target="_blank">
                    <span className="link">{result.mfrMpnKey}</span>
                  </Link>
                ) : (
                  result.mfrMpnKey
                ),
                tooltip: result.mfrMpnKey,
                notLink: true,
              },

              mfrDescription: {
                value: result.mfrDescription,
                displayValue: result.mfrDescription,
                tooltip: result.mfrDescription,
                notLink: true,
              },

              mfrDatasheet: {
                value: result.mfrDatasheetSrc,
                cellClass: "text-center",
                displayValue: result.mfrDatasheetSrc ? (
                  <Link to={{pathname: prependHttp(result.mfrDatasheetSrc)}} target="_blank">
                    <span className="link">
                      <InlineIcon className="data-icon">
                        <DataSheetIcon />
                      </InlineIcon>
                    </span>
                  </Link>
                ) : (
                  ""
                ),
                tooltip: result.mfrDatasheetSrc,
                notLink: true,
              },

              mfrWarranty: {
                value: [undefined, null, ""].includes(result.mfrWarranty) ? result.mfrWarranty : "",
                displayValue: this.getWarrantyState(result.mfrWarranty, warrantyUnit),
                tooltip: result.mfrWarranty,
                notLink: true,
              },

              distName: {
                value: result.distName,
                displayValue: result.distName,
                tooltip: result.distName,
                notLink: true,
              },

              distDpn: {
                value: result.distDpnKey,
                displayValue: result.distDpnSrc ? (
                  <Link to={{ pathname: prependHttp(result.distDpnSrc)}} target="_blank">
                    <span className="link">{result.distDpnKey}</span>
                  </Link>
                ) : (
                  result.distDpnKey
                ),
                tooltip: result.distDpnKey,
                notLink: true,
              },

              distDescription: {
                value: result.distDescription,
                displayValue: result.distDescription,
                tooltip: result.distDescription,
                notLink: true,
              },

              distPackageType: {
                value: result.distPackageType,
                displayValue: this.importPkgIconWithType(result.distPackageType) ? (
                  <InlineIcon tooltip={result.distPackageType}>{this.importPkgIconWithType(result.distPackageType)}</InlineIcon>
                ) : (
                  result.distPackageType
                ),
                tooltip: result.distPackageType,
                notLink: true,
              },

              distPackageQuantity: {
                value: result.distPackageQuantity,
                displayValue: result.distPackageQuantity,
                tooltip: result.distPackageQuantity,
                notLink: true,
              },

              quoteMinQuantity: {
                value: result.quoteMinQuantity,
                displayValue: result.quoteMinQuantity,
                cellClass: [undefined, null, ""].includes(result.quoteMinQuantity) ? "invalidSource" : "",
                tooltip: result.quoteMinQuantity,
                notLink: true,
              },

              quoteUnitPrice: {
                value: result.quoteUnitPrice,
                displayValue: unitPriceState,
                cellClass: [undefined, null, ""].includes(result.quoteUnitPrice) ? "invalidSource" : "",
                tooltip: [undefined, null, ""].includes(result.quoteUnitPrice)
                  ? ""
                  : this.props.defaultCurrency +
                    (isNaN(Number(result.quoteUnitPrice).toFixed(5)) ? Number(0).toFixed(5) : Number(result.quoteUnitPrice).toFixed(5)),
                notLink: true,
              },

              quoteLeadTime: {
                value: ![undefined, null, ""].includes(result.quoteLeadTimeValue)
                  ? Utils.stringValueToDays(result.quoteLeadTimeValue, result.quoteLeadTimeUnits)
                  : "",
                displayValue: leadTimeState,
                cellClass: [undefined, null, ""].includes(result.quoteLeadTimeValue) ? "invalidSource" : "",
                tooltip: result.quoteLeadTimeValue + " " + result.quoteLeadTimeUnits,
                notLink: true,
              },

              quoteLastUpdated: {
                value: result && result.quoteLastUpdated ? result.quoteLastUpdated : "",
                displayValue: result && result.quoteLastUpdated && (
                  <TimeStampColumn key={Utils.generateUniqueId()} format="date-time-with-long-format" value={result.quoteLastUpdated} />
                ),
                cellClass: [undefined, null, ""].includes(result.lastUpdated) ? "invalidSource" : "",
                tooltip: `${dateTime.dateValue} ${dateTime.timeValue}`,
                notLink: true,
              },

              rowSelected: isSelected,
              quote_id: result.quote_id,
              uniqueRowName: result.quoteUniqueIdentifier,
              allowRowSelect: allowRowSelect,
              rowId: result.quote_id,
              object: result,
              rowClassName:
                (result.isPrimary && "success") + (result.quoteUnitPriceState === "worsened" ? " warning no-bottom-border" : ""),
              displayIndexTableElOnHover: !allowRowSelect,
              isPrimary: result.isPrimary ? true : false,
              indexTableEl:
                result.quoteUnitPriceState === "worsened" ? (
                  <Tooltip
                    placement={"right"}
                    overlayClassName={"simple-rc-tip source-no-exist"}
                    overlay={
                      <div>
                        <p className="tooltip-text">Source is no longer available.</p>
                        <div className="tooltip-action-area">
                          <div className="tooltip-checkbox">
                            <input
                              type="checkbox"
                              name="doForAll"
                              id="doForAll"
                              onChange={(event) => this.onCheckBoxChange(event, result.quote_id)}
                            />
                            <label htmlFor="group"></label>
                            <span>Apply to all</span>
                          </div>
                          <button type="button1" className="sourcing-action-btn" onClick={(event) => this.removeSource(event, result)}>
                            Remove Source
                          </button>
                          <button
                            type="button2"
                            className="sourcing-action-btn"
                            onClick={(event) => this.clearSourceWarning(event, result)}>
                            Clear Warning
                          </button>
                        </div>
                      </div>
                    }>
                    <div>
                      <InlineIcon isValid={true} className="warningEl">
                        <WarningSrc />
                      </InlineIcon>
                    </div>
                  </Tooltip>
                ) : result.isPrimary ? (
                  <span className="center-state checkbox active" onClick={this.onPrimaryRowDeselect} />
                ) : !allowRowSelect ? (
                  <span
                    className="source-warning"
                    onMouseEnter={(e) => this.onMouseHoverOdWarningElOfSource(e, true)}
                    onMouseLeave={(e) => this.onMouseHoverOdWarningElOfSource(e, false)}>
                    <InlineIcon tooltip="Source missing information" tooltipType="error" tooltipPlace="top" className="warningEl">
                      <AlertErrorIcon />
                    </InlineIcon>
                  </span>
                ) : null,
            };
        return cells

        })
        return rows
    }


    render()
    {
        if(this.props.loading)
        {
            return <Spinner />
        }

        let current          = this.state.sourcingHeadings[this.state.current]
        let isAssembly       = this.props.isAssembly
        let sourcingHeadings = this.state.sourcingHeadings
        let sourcingRows     = this.state.rows
        let isInOpenCo       = ComponentProductUtils.isInOpenCo(this.props.objectData)
        let markup  =
                <div className="sourcing-row-mode">
                    { this.state.processModal && <RefreshQuoteModal open={true}
                            hideModal={this.state.processModal ? "" : "hide-modal"}
                            message="Checking for updates to your sourcing data."  />
                        }
                    {
                        isAssembly &&
                        <div>
                            <h3 className="muted-text mb10">
                                ROLLED UP COST
                            </h3>
                            <RolledUpTable
                                userStyles={this.props.user.preferences.styles}
                                onRolledUpSelect={this.props.onRolledUpSelect}
                                onRolledUpDeSelect={this.onPrimaryRowDeselect}
                                children={this.props.children}
                                objectData={this.props.objectData}
                                syncWithRolledUpCost={this.props.syncWithRolledUpCost}
                                afterSyncWithRolledUpCost={this.props.afterSyncWithRolledUpCost}
                                rows={this.state.rows}
                                mode={this.props.mode}
                                tabsType={this.props.tabsType}
                                isDiffTool={this.props.isDiffTool}
                                targetRevChildren={this.props.targetRevChildren}
                                defaultCurrency={this.props.defaultCurrency}
                            />
                        </div>
                    }

                    {
                        isAssembly &&
                        <h3 className="muted-text mt10 mb10">
                            CUSTOM
                        </h3>
                    }

                    <ExtendedTable
                        wrapperClassName={"sourcing-list-block" + (isAssembly ? " have-assembly" : "")}
                        wrapperSelectorClass="sourcing-list-view"
                        rows={sourcingRows}
                        syncWithParentState={true}
                        afterSyncWithParentState={this.props.afterSyncSourcingRowTable}
                        startStaticColumns={1}
                        headings={sourcingHeadings}
                        allowRowSelect={ this.props.tabsType === "cmp" && !this.props.isDiffTool ? this.state.isManufacturerExist : false}
                        allowRowSelectAsRadio={false}
                        onSelectAllRows={this.onSelectAllRows}
                        onRowSelect={this.onSourcingRowSelect}
                        stylesName="sourcingViewRowMode"
                        borderedTable={true}
                        resultText={`${sourcingRows.length} Manufacturers`}
                        includeToolBar={true}
                        tableActionButtons={this.getActionListForSourcing()}
                        currentSortItem={this.state.current}
                        currentSortItemAscending={this.state.currentSortItemAscending}
                        footerRow={ !this.props.isDiffTool && !isInOpenCo && Permissions.can("create", "component", this.props.user.data) ? {
                                bordered: true,
                                dataCellEl: <p>Edit to add</p>,
                                indexCellEl: Permissions.can("create", "component", this.props.user.data) &&
                                            <div className="add-more-actions">
                                                <Link to={ComponentProductUtils.makeEditLink(this.props.objectData)}>
                                                    <button className='add-trigger'
                                                            data-tip='Edit'
                                                        >
                                                        <InlineIcon><EditIcon/></InlineIcon>
                                                    </button>
                                                </Link>
                                             </div>
                            }
                            :
                            sourcingRows.length === 0 &&
                            {
                                dataCellEl: <p>No Sourcing added</p>
                            }
                        }

                    />
                </div>

        return markup
    }
}

export default connect((store) => store)(SourcingRowView)
