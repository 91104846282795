import React,{useState}   from "react";
import UnlinkWarningModal from '../unlink-warning-modal';
import InlineIcon         from "../../../../ui/icon/inline-icon";
import Utils              from "../../../../../modules/utils";
import API                from "../../../../../modules/api";
import Tooltip            from 'rc-tooltip';
import UnLinkedVendorIcon from "../../../../../assets/icons/unlinked-vendor-icon";
import LinkedVendorIcon   from "../../../../../assets/icons/linked-vendor-icon";

import  "./index.css"

const VendorPillbox = (props) => {
  const { isVendorCmp, label, className, componentId, isComponentLinked = false, fromView, isAddedInCO } = props
  const [modalVisible, setModalVisible]     =  useState(false);
  const [isCmpLinked, setIsCmpLinked]       =  useState(isComponentLinked);
  if (isVendorCmp === "duro" || !isVendorCmp) return null;
  const toolTipText = isCmpLinked ? ( isAddedInCO ? "Component can not be unlinked while in an unresolved change order" : "Unlink Component from 3rd party application" ) : "Component has been unlinked from its 3rd party original source";


  const displayIcon = () => {
    if(isCmpLinked) return(<LinkedVendorIcon/>)
    return(<UnLinkedVendorIcon/>)
  }

  const displayModal = () => {
    fromView && isCmpLinked && setModalVisible(true);
  }

  const hideModal = () => {
    setModalVisible(false);
  }

  const disablePillBox = (cmp) => {
    if(!Utils.isComponentLinked(cmp.vendorInfo))
      setIsCmpLinked(false);
  }

  const setOverlayDesignClasses = () => {
    return !fromView ? 'hidden' : 'simple-rc-tip error type-dark';
  }

  const unlinkComponent = () => {
    if(componentId) {
      API.components.unlinkComponentFromVendor(componentId, (err, res) => {
        if(res) {
          hideModal();
          disablePillBox(res);
        }
      })
    }
  }

  return(
    <div className="vendor-wrapper">
      <Tooltip
        placement={"right"}
        overlayClassName={setOverlayDesignClasses()}
        overlay={ !modalVisible  && fromView ?
            <div>
                <p>
                  <span>{toolTipText}</span>
                </p>
            </div>
            : ''
        }
      >
        <div className={`vendor-label DESIGN ${className} ${!isCmpLinked ? 'disabled' : ''}`} onClick={!isAddedInCO ? displayModal : function(){}}>
            <InlineIcon>
              {displayIcon()}
            </InlineIcon>
            <span>{label}</span>
        </div>
      </Tooltip>
      {modalVisible &&
        <UnlinkWarningModal
          label={label}
          hideModal={hideModal}
          unlinkComponent={unlinkComponent}
        />
      }
    </div>
  );
};

export default VendorPillbox;
