import { VariantType, useSnackbar } from "notistack";
import { ReactNode, useCallback } from "react";

export interface EnqueueToastArgs {
  actions?: ReactNode;
  autoHideDuration?: number;
  body?: string | ReactNode;
  customIcon?: ReactNode;
  hideIconVariant?: boolean;
  id?: string | number;
  message: string;
  persist?: boolean;
  showSpinner?: boolean;
  variant?: VariantType;
}

export interface UpdateToastArgs extends EnqueueToastArgs {
  id: string | number;
}

export function useToasts() {
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const enqueueToast = useCallback((data: EnqueueToastArgs) => enqueueSnackbar(data), [enqueueSnackbar]);

  return {
    closeToast: closeSnackbar,
    enqueueToast,
  };
}
