import { ItemType } from "design/components/grid/searchTable";
import { AssemblyChild } from "design/models";
import validations, { validateField } from "v1/modules/validations";

interface ISpec {
  key: string;
  value: string;
}

interface ISpecName {
  [key: string]: {
    allowedValues: string[];
    isDropDown: boolean;
  };
}

export function calculateChildTotalPrice(child: Partial<AssemblyChild & { item: ItemType }>) {
  const { unitPrice } = child.item?.primarySource ?? {};
  let totalPrice = 0;
  if (child.quantity && unitPrice) {
    totalPrice = child.quantity * unitPrice;
  }

  return totalPrice;
}

export function getSpecsValidated(specs: ISpec[], category: string, isSpecValidationEnabled: boolean) {
  const categoryObject = (window as any).__categories.find(
    ({ name }: { name: string }) => name === category,
  );
  const categorySpecs = categoryObject.specs;
  const inputs = specs?.map(spec => {
    const input = {
      value: spec.value,
      key: spec.key,
      schema: categorySpecs.specSettings.find((specName: ISpecName) =>
        spec.key in specName)[spec.key],
    };
    validateField(
      input,
      validations.component.specs.value,
      input.value,
      { categoryObject, key: spec.key, isSpecValidationEnabled },
    );
    return input;
  });
  return inputs;
}
