import Config     from "../modules/config"

const imagePath = `${Config.ASSETS_ORIGIN}images/`;
const videoPath = `${Config.ASSETS_ORIGIN}videos/`;

function makePath(key, directory = "") {
    return `${directory}${key}`;
}

// imagesPaths
const componentDefaultImg = makePath("component-default.png", imagePath);
const contactFbImg = makePath("contact-fb.png", imagePath);
const contactMapImg = makePath("contact-map.jpg", imagePath);
const contactTwImg = makePath("contact-tw.png", imagePath);
const dashboardAddProductImg = makePath("dashboard-add-product.png", imagePath);
const dashboardArrowImg = makePath("dashboard-arrow.png", imagePath);
const dashboardChangeOrderImg = makePath("dashboard-change-order.png", imagePath);
const dashboardComponentImg = makePath("dashboard-component.png", imagePath);
const dashboardSearchImg = makePath("dashboard-search.png", imagePath);
const loadingImg = makePath("loading.png", imagePath);
const statusFlowImg = makePath("status-workflow.png", imagePath);
const userRolesImg = makePath("user-roles.png", imagePath);
// videosPaths
const componentNewFileMov = makePath("component-new-file.mov", videoPath);
const componentNewManualMov = makePath("component-new-manual.mov", videoPath);
const componentNewWebResistorMov = makePath("component-new-web-resistor.mov", videoPath);
const componentNewWebScrewMov = makePath("component-new-web-screw.mov", videoPath);
const componentOpenMov = makePath("component-open.mov", videoPath);
const dashboardMov = makePath("dashboard.mov", videoPath);
const productEditMov = makePath("product-edit.mov", videoPath);
const productNewMov = makePath("product-new.mov", videoPath);
const settingsAddUsersMov = makePath("settings-add-users.mov", videoPath);
const settingsProfilesMov = makePath("settings-profiles.mov", videoPath);
const signupMov = makePath("signup.mov", videoPath);

export {
    componentDefaultImg,
    contactFbImg,
    contactMapImg,
    contactTwImg,
    userRolesImg,
    dashboardAddProductImg,
    dashboardArrowImg,
    dashboardChangeOrderImg,
    dashboardComponentImg,
    dashboardSearchImg,
    loadingImg,
    statusFlowImg,
    componentNewFileMov,
    componentNewManualMov,
    componentNewWebResistorMov,
    componentNewWebScrewMov,
    componentOpenMov,
    dashboardMov,
    productEditMov,
    productNewMov,
    settingsAddUsersMov,
    settingsProfilesMov,
    signupMov,
};
