import Utils                from "../../utils"
import { client } from "graphql/apolloClient";
import { createUser, deleteUser, updateUser } from "graphql/query/userMutations";

const UserAPI =
{
    getCurrent(cb)
    {
        Utils.get("/users/current", (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }
            if(!res.data.twoFaConfiguration.twoFaSecret && res.data.twoFaConfiguration.twoFaEnabled && !window.location.href.endsWith('/settings/user'))
                window.location.href = '/settings/user';

            cb(null, res.data)
        })
    },

    findUserById : (id, cb) =>
    {
        Utils.get("/users/" + id, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    },

    validateEmail : (data, cb) =>
    {
        Utils.post("/users/email/exists", data, (err, res) =>
        {
            if(err) return cb(err)
            return cb(null, res.data)
        })
    },



    authenticateUser : (params = {}, cb) =>
    {
      Utils.post("/auth/signin", params, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }
            return cb(null, res.data)
        })
    },

    create : (data, cb) =>
    {
        // Create user with core-api instead of plm-api so that PropelAuth is updated.
        const variables = { input: { ...data, invite: { accepted: true } } };
        createUser(client, variables)
            .then(user => {
                return cb(null, user.id)
            })
            .catch(err => {
                return cb(err)
            });
    },

    update : (id, data, cb) =>
    {
        if(data.avatar instanceof File === true)
        {
            return Utils.readFile(data.avatar, (err, avatar) =>
            {
                if(err)
                {
                    return cb(err)
                }

                data.avatar = avatar

                Utils.post("/users/" + id, data, (err, res) =>
                {
                    if(err)
                    {
                        return cb(err)
                    }

                    cb(null, res.data)
                })
            })
        }

        Utils.post("/users/" + id, data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    },

    updateCoreApi: (id, data, cb) => {
        // Create user with core-api instead of plm-api so that PropelAuth is updated.
        let mutation;
        if (data.archived) {
            mutation = deleteUser(client, { input: { id } });
        }
        else {
            const variables = { input: { id, ...data } };
            mutation = updateUser(client, variables);
        }
        mutation.then(user => cb(null, user.id))
            .catch(err => {
                return cb(err)
            });
    },

    delete_group : (id, data, cb) =>
    {
        Utils.post("/users/" + id + "/delete_group", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    },

    addToGroup : (data, cb) =>
    {
        Utils.post("/users/add_group/bulk_update", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    },

    getInvite : (email, cb) =>
    {
        Utils.get("/users/invite/" + email, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    },

    acceptInvite : (email, password, cb) =>
    {
        let data =
        {
            email    : email,
            password : password
        }

        Utils.post("/users/invite", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    },

    sendHelpEmail : (name, email, message, cb) =>
    {
        let data =
        {
            name    : name,
            email   : email,
            message : message
        }

        Utils.post("/services/mail/help", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    },

    bulkUpdate : (data, cb) =>
    {
        Utils.post("/users/bulk", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    },

    list : (cb) =>
    {
        Utils.get("/users", (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }
            cb(null, res.data)
        })
    },

    sendResetPasswordEmail : (email, cb) =>
    {
        let data =
        {
            email : email
        }

        Utils.post("/services/mail/reset", data, (err, res) =>
        {
            if(err)
            {
                return cb(err.errors[0])
            }

            cb(null, res.data)
        })
    },

    confirmPassword : (password, cb) =>
    {
        Utils.get("/users/password/" + password + "/confirm", (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    },

    authenticate : (email, password, cb) =>
    {
        var data =
        {
            email    : email,
            password : password
        }

        Utils.post("/auth/signin", data, (err, res) =>
        {
            if(err)
            {
                return cb(false)
            }

            cb(true)
        })
    },

    confirmAccountWithToken : (data, cb) =>
    {

        Utils.post("/users/verify/account", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    },

    verifyToken : (data, cb) =>
    {

        Utils.post("/users/verify/token", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    },

    resetPassword : (data, cb) =>
    {
        Utils.post("/users/password/reset", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    },

    resendInvite : (email, cb) =>
    {
        let data =
        {
            email : email
        }

        Utils.post("/services/mail/resend-invite", data, (err, res) =>
        {
            if(err)
            {
                return cb(err.errors[0])
            }

            cb(null, res.data)
        })
    },

    acceptAgreement : (data, cb) =>
    {
        Utils.post("/accounts/agreement", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    },

    unregisterOAuth2Google: (email, cb) =>
    {
        Utils.post('/auth/google/unregister', email, (err, res) =>
        {
            if (err)
            {
                return cb(err);
            }

            return cb(null, res.data);
        });
    },

    setInitiallyCoRuleApplied : (data) => {
        Utils.post("/users/initially/co-rule", data, (err, res) => {
            if(err) {
                console.log(err)
            }
        })
    }
}

export default UserAPI
