import { SvgIcon as MuiSvgIcon, styled } from "@mui/material";

export const DesignWorkspaceIcon = () => (
  // eslint-disable-next-line max-len
  <SvgIcon viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    {/* Intentional fill property is added on the design Workspace Icon */}
    <g fill="none" fillRule="evenodd">
      <rect width="40" height="40" rx="8" fill="#3CD1B6" />
      {/* eslint-disable-next-line max-len */}
      <path d="M21.173 32h5.108V8h-5.108v24zm-2.794-6.333c0 1.185.76 1.467 1.361 1.467h.411v4.21c-.506.344-1.265.656-2.31.656C14.995 32 13 30.471 13 25.917v-5.833C13 15.529 14.994 14 17.842 14c1.075 0 1.803.312 2.31.686v4.18h-.412c-.601 0-1.36.282-1.36 1.467v5.334z" fill="#231F20" />
    </g>
  </SvgIcon>
);

const SvgIcon = styled(MuiSvgIcon)(
  {
    width: "2.5rem",
    height: "2.5rem",
    marginRight: "0.7rem",
  },
);
