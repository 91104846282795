import React from "react"

function SvgComponent(props) {
  return (
    <svg
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.5 17.5h17v-4H11v-9h6.5v-4H.5v4H7v9H.5v4z"
        stroke="#FFF"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgComponent
