import { useEffect, useCallback } from "react";

/**
 * A React hook to trigger a callback upon clicking outside an element
 * @param {React.MutableRefObject} ref the ref to the element, obtained by useRef hook
 * @param {function} cb the callback to trigger on external click
 */
export const useExternalClick = (ref, cb) => {
  const handleExternalClick = useCallback(
    (e) => {
      if (ref.current && !ref.current.contains(e.target)) cb();
    },
    [ref, cb],
  );
  

  useEffect(() => {
    document.addEventListener("click", handleExternalClick, { capture: true });
    return () => document.removeEventListener("click", handleExternalClick, { capture: true });
  }, [handleExternalClick]);
};