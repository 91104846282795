import "./index.css";
import React, { Component } from 'react';
import ModalBox             from "../modal-box"
import InlineIcon           from "../icon/inline-icon.js"
import AlertIcon            from "../../../assets/icons/alert-icon.js"
import WarningIcon          from "../../../assets/icons/warning-triangle-black-bg.js";
import Link                 from "../link"

const WarningModal = (props) =>
{
    let okButtonMarkup =
        <button
            type="button"
            onClick={props.onConfirm ? props.onConfirm : null}
            className="filled allow">
            {props.okButtonText ? props.okButtonText : 'Ok'}
        </button>

    let titleMarkup = <h1>
                        { !props.hideTitleIcon && <InlineIcon> { props.isWarningIcon ? <WarningIcon/> : <AlertIcon/> } </InlineIcon> }
                        { props.title }
                    </h1>;

    return <div className={`new-warning-modal ${props.className ? props.className : "save-changes"}`} >
            <ModalBox onClose={props.onCancel}>
                {titleMarkup}
                <div className="warning-text">
                    <p className="save-warning-text">{props.description}</p>
                </div>
                <div className="bottom-section">
                    <div className="button-sections">
                    {!props.hideCancelBtn &&
                      <button
                          type="button"
                          className="dark allow"
                          onClick={props.onCancel}
                          >
                        Cancel
                      </button>
                    }
                      {
                        props.linkTo
                        ?
                            <Link to={props.linkTo}>
                                {okButtonMarkup}
                            </Link>
                        :
                            okButtonMarkup
                      }

                    </div>
                </div>
            </ModalBox>
        </div>
}

export default WarningModal
