import React, { Component }      from "react"

const SvgIcon = () =>
{
    return <svg width="29px" height="29px" viewBox="0 0 29 29" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
        <circle id="path-1" cx="10.5" cy="10.5" r="10.5"></circle>
        <filter x="-33.3%" y="-23.8%" width="166.7%" height="166.7%" filterUnits="objectBoundingBox" id="filter-2">
            <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="2-New-Color---HOVER" transform="translate(-1142.000000, -179.000000)">
            <g id="GREENARROW" transform="translate(1146.000000, 181.000000)">
                <g id="Side-Tab-Right">
                    <g id="Oval" fillRule="nonzero">
                        <use fill="black" fillOpacity="1" filter="url(#filter-2)" xlinkHref="#path-1"></use>
                        <use fill="#3CD1B5" xlinkHref="#path-1"></use>
                    </g>
                    <g id="GreenArrow" transform="translate(7.500000, 4.000000)" fill="#353540">
                        <g id="noun_Arrow_1813678">
                            <polygon id="Shape" fillRule="nonzero" points="7 6.50000509 6.40973827 5.84038151 1.62115468 0.5 0 1.81922681 4.19833516 6.50000509 0 11.1807732 1.62115468 12.5 6.40973827 7.15961849 7 6.50000509"></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>

}

export default SvgIcon




