import                                                  "./index.css"
import                                                  "./../index.css"
import React, { Component }                             from "react"
import DropZone                                         from "../../../ui/drop-zone"
import Config                                           from "../../../../modules/config"
import Schemas,{permissions as Permissions}             from "../../../../modules/schemas"
import Utils                                            from "../../../../modules/utils"
import {connect}                                        from 'react-redux';
import buildAction                                      from "../../../../helpers/buildAction"
import COMPANY                                          from '../../../../action-types/company';
import UI                                               from '../../../../action-types/ui';
import {Helmet}                                         from "react-helmet";
import ToggleBtn                                        from "../../../ui/toggle-btn";
import ModalBox                                         from "../../../ui/modal-box";
import Loading                                          from "../../../ui/loading"

export class CompanyProfile extends Component
{
    constructor(props)
    {
        super(props)
        this.onRemoveClick = this.onRemoveClick.bind(this)
        this.onFileChange = this.onFileChange.bind(this)
        this.uploadImage = this.uploadImage.bind(this)
        this.onInputChange = this.onInputChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.onContinue = this.onContinue.bind(this)
        this.enableTwoFA = this.enableTwoFA.bind(this)
        this.onModalClose = this.onModalClose.bind(this)
        this.disableTwoFA = this.disableTwoFA.bind(this)
        this.reloadFormState = this.reloadFormState.bind(this, true);

        this.state = {
            twoFaPermitted: false,
            adminTwoFaEnabled: true,
            displayEnforceModal: false,
            toggleButton: this.props.company && this.props.company.inputs.enforceTwoFa.value
        }
    }

    componentWillMount()
    {
        // if (!this.props.company){
            const {dispatch} = this.props;
        dispatch(buildAction(COMPANY.GET_CURRENT_COMPANY, { handleLoading: false }))
        // }
    }
    reloadFormState()
    {
        const {dispatch} = this.props;
        dispatch(buildAction(COMPANY.GET_CURRENT_COMPANY, { handleLoading: false }))
    }

    componentDidUpdate()
    {
        let { company, user_profile } = this.props;
        let { companySettings } = this.state;
        if(company.data.settings !== companySettings)
        {
            let dataToBeUpdated = {
                twoFaPermitted: company.data.settings.twoFaConfiguration.twoFaPermitted,
                companySettings: company.data.settings,
            };
            if(company.data.settings.twoFaConfiguration.enforceTwoFa)
                dataToBeUpdated.toggleButton = true;

            
            this.setState(dataToBeUpdated);
        }
    }



    uploadImage(file = null, images_errors = [], isUploaded)
    {
        const {dispatch} = this.props;
        if (images_errors.length > 0)
        {
            let errors = []
            let errorsArray = images_errors
            errors.push({ message: "Error in uploading following files:" })
            errorsArray.forEach(function(error, index)
            {
                errors.push({ message: "("+ (index+1) + ") " + error.file })
            })
            errors.push(errorsArray[0].errors[0])
            dispatch(buildAction(UI.SHOW_ALERT, {type: "errors", errors: errors, donotThrowError: true}))
        }
        else
        {
            let event = {target: {name: "logo", value: {valid: isUploaded, imageId: file} } }
            this.onInputChange(event)
        }

    }

    onRemoveClick()
    {
        let event =
        {
            target :
            {
                name  : "removeBtn",
                value :
                {
                    key : ""
                }
            }
        }

        this.onInputChange(event)
    }

    onFileChange(files)
    {
        let file = files[0]

        if(!files) return

        let event =
        {
            target :
            {
                name  : "logo",
                value : file
            }
        }

        this.onInputChange(event)
    }

    disableTwoFA()
    {
        let event = {target: {name: "enforceTwoFa", value: false}};
        this.setState({toggleButton: false}, this.onInputChange(event));
    }

    enableTwoFA()
    {
        let event = {target: {name: "enforceTwoFa", value: true}};
        this.setState({toggleButton: true, displayEnforceModal: true}, this.onInputChange(event));
    }

    onInputChange(event)
    {
        const {dispatch} = this.props;

        dispatch(buildAction(COMPANY.UPDATE_INPUT_ON_CHANGE, ({target: event.target, company: {...this.props.company.data}, inputs: {...this.props.company.inputs}}) ))
        Utils.persistCursor(event, event.target && event.target.value)

    }

    onModalClose()
    {
        let event = {target: {name: "enforceTwoFa", value:false}};
        this.setState({displayEnforceModal: false, toggleButton: false}, this.onInputChange(event));
    }

    onContinue()
    {
        this.setState({displayEnforceModal: false});
    }

    onSubmit(event)
    {

        const {dispatch} = this.props;
        const {company} = this.props;
        const { toggleButton } = this.state;
        company.history = this.props.history;
        event.preventDefault()

        if ( !Permissions.can("edit", "company_profile", this.props.user.data) )
            return

        if(company.inputs.submit.class === "disabled") return
        dispatch(buildAction(COMPANY.SUBMIT_UPDATE_FORM, company))
    }

    render()
    {
        let state = this.state;
        let { toggleButton, adminTwoFaEnabled, twoFaPermitted, displayEnforceModal } = state;
        let inputs = this.props.company && this.props.company.inputs
        if (!inputs || !this.props?.user){
            return <Loading/>
        }
        let canEdit = Permissions.can("edit", "company_profile", this.props.user.data)
        let markup =

            <div className="company-settings-route app-row">

                {
                    displayEnforceModal &&
                    <ModalBox onClose={this.onModalClose} >
                        <h1>All Users Have to Enable 2FA</h1>
                        <p> All users will be required to configure 2FA for their Duro accounts on their next user session </p>
                        <div className="buttons-holder">
                            <button onClick={this.onContinue}> OK </button>
                        </div>
                    </ModalBox>
                }

                <Helmet>
                    <title>{Utils.makeTitle("Settings - Company Profile")}</title>
                </Helmet>
                <div className="main-block">


                    <div className="content-block">

                        <h1>Company profile</h1>
                        <h3>Route and edit your company profile</h3>

                        <form className="form-block" onSubmit={this.onSubmit}>

                            <div className="info-block">
                                <label htmlFor="name">Company</label>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Company name"
                                    className={inputs.name.class}
                                    readOnly={!canEdit}
                                    value={inputs.name.value}
                                    onChange={this.onInputChange}
                                    data-tip={inputs.name.message}
                                    data-place="top"
                                    data-type="error"
                                />
                                <div className={"ui-message " + inputs.name.class}></div>

                                <label htmlFor="website">Company website</label>
                                <input
                                    type="text"
                                    name="website"
                                    placeholder="http://www.companyname.com"
                                    className={inputs.website.class}
                                    readOnly={!canEdit}
                                    value={inputs.website.value}
                                    onChange={this.onInputChange}
                                    data-tip={inputs.website.message}
                                    data-place="top"
                                    data-type="error"
                                />
                                <div className="ui-message"></div>


                                <label htmlFor="address.street">Street address</label>
                                <input
                                    id="street"
                                    type="text"
                                    name="address.street"
                                    placeholder="Street and number, P.O. box, c/o"
                                    className={inputs.address.street.class}
                                    readOnly={!canEdit}
                                    value={inputs.address.street.value}
                                    onChange={this.onInputChange}
                                    data-tip={inputs.address.street.message}
                                    data-place="top"
                                    data-type="error"
                                />
                                <input
                                    id="suite"
                                    type="text"
                                    name="address.suite"
                                    placeholder="Apartment, suite, unit"
                                    className={inputs.address.suite.class}
                                    readOnly={!canEdit}
                                    value={inputs.address.suite.value}
                                    onChange={this.onInputChange}
                                    data-tip={inputs.address.suite.message}
                                    data-place="top"
                                    data-type="error"
                                />
                                <div className="ui-message"></div>


                                <div className="city-block">
                                    <div>
                                        <label htmlFor="address.city">City</label>
                                        <input
                                            id="city"
                                            type="text"
                                            name="address.city"
                                            placeholder="City"
                                            value={inputs.address.city.value}
                                            onChange={this.onInputChange}
                                            className={inputs.address.city.class}
                                            readOnly={!canEdit}
                                            data-tip={inputs.address.city.message}
                                            data-place="top"
                                            data-type="error"
                                        />
                                        <div className="ui-message"></div>
                                    </div>
                                    <div>
                                        <label htmlFor="address.state">State</label>
                                        <select
                                            id="state"
                                            name="address.state"
                                            value={inputs.address.state.value}
                                            onChange={this.onInputChange}
                                            className={inputs.address.state.class + (canEdit ? "" : " disabled")}
                                            data-tip={inputs.address.state.message}
                                            data-place="top"
                                            data-type="error"
                                            disabled={!canEdit}
                                            >
                                            <option value="">Select</option>
                                            {Utils.toOptions(Schemas.company.address.state.list())}
                                        </select>
                                        <div className="ui-message"></div>
                                    </div>
                                    <div>
                                        <label htmlFor="address.zip">Zip code</label>
                                        <input
                                            id="zip"
                                            type="text"
                                            name="address.zip"
                                            placeholder="Zip code"
                                            value={inputs.address.zip.value}
                                            onChange={this.onInputChange}
                                            className={inputs.address.zip.class}
                                            readOnly={!canEdit}
                                            data-tip={inputs.address.zip.message}
                                            data-place="top"
                                            data-type="error"
                                        />
                                        <div className="ui-message"></div>
                                    </div>
                                </div>
                                <label>Country</label>
                                <input
                                    type="text"
                                    readOnly={true}
                                    value="United States of America"
                                />
                                <div className="ui-message"></div>

                                {      twoFaPermitted && canEdit &&
                                       <div className={`two-fa ${!adminTwoFaEnabled ? 'disable' : ''}`}>
                                        <label>Require 2FA for all users</label>
                                        <ToggleBtn
                                            name="enforceTwoFa"
                                            value={toggleButton}
                                            selected={toggleButton}
                                            onChange={toggleButton ? (e) => this.disableTwoFA(e) : !adminTwoFaEnabled ? false : this.enableTwoFA }
                                        />
                                    </div>
                                }

                                <button
                                    type="button"
                                    className={ "cancel-btn " + inputs.submit.class}
                                    onClick={this.reloadFormState}
                                >
                                    cancel
                                </button>

                                <input
                                    type="submit"
                                    value="save"
                                    className={"submit-btn " + inputs.submit.class}
                                />
                            </div>

                            <div className="right-block">

                                <DropZone
                                    onChange={this.uploadImage}
                                    imageId={this.props.company.data.logo}
                                    setCanceledFlag={this.setCanceledFlag}
                                    label="Logo"
                                    placeholder="Add a logo"
                                    disabled={!canEdit}
                                />

                            </div>

                        </form>
                    </div>
                </div>

            </div>
        return markup
    }
}

export default connect((store) => store)(CompanyProfile)
