import                                     "./index.css"
import React, {Component}             from "react"
import Icon                           from "../../../../ui/icon"
import Utils                          from "../../../../../modules/utils"
import Schemas                        from "../../../../../modules/schemas"
import API                            from "../../../../../modules/api"
import deleteSrc                      from "../../../../../assets/icons/cross-icon.svg"
import FileDrop                       from "../../../../ui/file-drop"
import validations, {validateField}   from '../../../../../modules/validations';
import LazyInput                      from "../../../../ui/lazy-input/input.js"
import { connect }                    from 'react-redux';
import ProgressCircle                 from  "../../../../ui/progress-circle";
import config                         from "../../../../../modules/config"

export class Documents extends Component
{
    constructor(props, context)
    {
        super(props, context)
        this.state =
        {
            inputs :
            {
                files :
                [

                ],

                fileDrop :
                {
                    collapsed : true,
                }
            }
        }
        this.onFilesChange          = this.onFilesChange.bind(this)
        this.onDeleteClick          = this.onDeleteClick.bind(this)
        this.onInputChange          = this.onInputChange.bind(this)
        this.onChange               = this.onChange.bind(this)
        this.uploadDocument         = this.uploadDocument.bind(this)
        this.getFilesProcessedState = this.getFilesProcessedState.bind(this)
        this.getUploadedFiles       = this.getUploadedFiles.bind(this)
        this.schema                 = this.props.clientSchema
        this.documentSchema         = this.props.documentSchema
        this.onDropRejected         = this.onDropRejected.bind(this);
        this.showUploadingStatus    = this.showUploadingStatus.bind(this);
        this.isPresentInInputFiles  = this.isPresentInInputFiles.bind(this);
    }

    componentWillUnmount()
    {
        let state = this.state
        state.inputs.files = []
        this.setState(state)
    }

    componentWillReceiveProps(nextProps)
    {
        if (nextProps.forceResetChildState)
        {
            let state = this.state
            state.inputs.files = []
            state.inputs.fileDrop.collapsed = true
            state.class = ""
            this.setState(state)
        }
    }

    onFilesChange(files)
    {
        let inputs    = this.state.inputs
        let newInputs = files.map((file, index) =>
        {
            let input =
            {
                src   : file.preview,
                name  : file.name,
                type  : Utils.getExtension(file.name),
                size  : file.size,
                file  : file,
                fileId: `file-${index}`,
                info: {
                    id    : null,
                    errors: [],
                    processFlag: false
                },

                specs :
                {
                    type     : "GENERIC",
                    revision : "1",
                    status   : "DESIGN",

                    inputs: {
                        type :
                        {
                            message : "",
                            class   : "",
                            value   : "GENERIC",
                            valid   : true
                        },

                        revision :
                        {
                            message : "",
                            class   : "",
                            value   : "1",
                            valid   : true
                        },

                        status :
                        {
                            message : "",
                            class   : "",
                            value   : "DESIGN",
                            valid   : true
                        }
                    }
                }
            }

            if (window.__libraryType === "GENERAL")
            {
                switch (window.__revSchemeType)
                {
                    case config.revisionTypes.alphaNumericXY:
                        input.specs.revision = "1"
                        input.specs.inputs.revision.value = "1"
                        break
                    default:
                        break
                }
            }
            return input
        })

        if(this.props.mcmasterCertificate && Utils.getExtension(files[0].name) !== 'pfx')
        {
            this.props.invalidFileFormatError();
            return
        }

        inputs.files              = [...inputs.files, ...newInputs]
        inputs.fileDrop.collapsed = inputs.files.length > 0
        this.setState(this.state, this.uploadDocument(newInputs))
    }

    onDeleteClick(index)
    {
        let inputs = this.state.inputs
        inputs.files.splice(index, 1)
        inputs.fileDrop.collapsed = inputs.files.length > 0
        this.setState(this.state, this.onChange)
    }

    onDropRejected(files, maxFileSize)
    {
        let inputs    = this.state.inputs;
        let newInputs = files.map((file) =>
        {
            let err = {message: "Invalid file type", type: "invalid-file-type"};
            let fileSize = file.size/1000000;
            if( fileSize > maxFileSize)
            {
                err = {message: `File exceeds ${maxFileSize}mb limit`, type: "file-exceed-limit"};
            }
            let input =
            {
                src   : file.preview,
                name  : file.name,
                type  : Utils.getExtension(file.name),
                size  : file.size,
                file  : file,

                info: {
                    id    : null,
                    errors: [err.message],
                    processFlag: false,
                    hasExceededMaxFileSize: err.type === "file-exceed-limit" ? true : false,
                    hasInvalidFileType: err.type === "invalid-file-type" ? true : false
                },

                specs :
                {
                    type     : "GENERIC",
                    revision : "1",
                    status   : "DESIGN",

                    inputs: {
                        type :
                        {
                            message : "",
                            class   : "",
                            value   : "GENERIC",
                            valid   : true
                        },

                        revision :
                        {
                            message : "",
                            class   : "",
                            value   : "1",
                            valid   : true
                        },

                        status :
                        {
                            message : "",
                            class   : "",
                            value   : "DESIGN",
                            valid   : true
                        }
                    }
                }
            };
            return input;
        });
        inputs.files              = [...inputs.files, ...newInputs];
        this.setState({inputs: inputs});
    }

    onInputChange(event, i)
    {
        let name  = event.target.name
        let value = event.target.value
        let specs = this.state.inputs.files[i].specs
        let inputs = specs.inputs
        let validator = null
        let specSchema = this.schema.specs
        let input = undefined
        switch(name)
        {
            case "type" :
            {
                validator = specSchema.type
                specs.type = value
                validateField(inputs.type, validator, value, {allowedDocTypes: window.__allowedDocTypes})
                break
            }

            case "revision" :
            {
                validator = specSchema.revision
                specs.revision = value
                validateField(inputs.revision, validator, value, {status: inputs.status.value, defaultBlacklistedRevisions: window.__defaultBlacklistedRevisions})
                break
            }

            case "status" :
            {
                let revisionInput = inputs.revision.value
                inputs.status.value = value
                let revSchemeType = [config.revisionTypes.alphaNumericXY, config.revisionTypes.numericXY].includes(window.__revSchemeType) ? window.__revSchemeType : "DEFAULT"
                let validationPayload = {status: value, revSchemeType, libraryType: window.__libraryType}
                revisionInput.value = validations.component.documents.specs.revision.normalize(validationPayload, null).revision
                validateField(revisionInput, validations.component.documents.specs.revision, revisionInput.value, validationPayload);
                break
            }

            default :
            {
                // noop
            }
        }
        this.setState(this.state, this.onChange())
    }

    onChange()
    {
        let files = this.state.inputs.files
        let event = Utils.getEvent(this, files)
        let payload = {documents: this.getUploadedFiles(), documentsProcessed: this.getFilesProcessedState()}
        this.props.onChange && this.props.onChange(event, payload)
    }

    getUploadedFiles(){
        let data = []
        let newFile = {}
        for (let file of this.state.inputs.files){
            if (file.info.id)
            {
                newFile = {}
                newFile.file = file.info.id
                newFile.specs = {}
                newFile.specs.type = file.specs.type
                newFile.specs.revision = file.specs.revision
                newFile.specs.status = file.specs.status
                file.file = file.info.id
                data.push(newFile)
            }
        }
        return data
    }

    getFilesProcessedState(){
        let documentsProcessed = true
        for (let file of this.state.inputs.files){
            if (file.info.processFlag == true || file.info.errors.length > 0 || !Utils.isValidated(this.state.inputs))
                documentsProcessed = false
        }
        return documentsProcessed

    }

    isPresentInInputFiles(fileId)
    {
        let state = this.state
        let found = false
        for (let doc of state.inputs.files){
            if (doc.fileId === fileId)
            {
                found = true
                break;
            }
        }
        return found
    }

    uploadDocument(documents)
    {
        //set isBeingUploaded process flag true so we can display consisten UI
        for (let document of documents){
            document.info.isBeingUploaded = true;
        }

        let docBatches = Utils.createBatches(documents, 5)
        let index = 0
        let state = this.state

        let onComplete = (err, data) =>
        {
            if(++index === docBatches.length)
            {
                return
            }
            create(index)
        }

        let onDocumentUpload = (document, counter) =>
        {
            document.info.processFlag = false;
            document.info.isBeingUploaded = false;
            this.setState({documents: this.state.documents}, this.onChange());
            if (counter === docBatches[index].length)
            {
                onComplete()
            }
        }

        let create = () =>
        {
            let counter = 0
            for (let document of docBatches[index]){
                document.file.specs = document.specs
                document.info.processFlag = true
                document.info.isBeingUploaded = true;
                this.setState(this.state, this.onChange())
                let startTime =  new Date()/1000;

                if (this.isPresentInInputFiles(document.fileId))
                {
                    Utils.uploadDocument("/documents", document.file, (err, currentProgress) =>
                    {
                        Utils.updateDocProgress(currentProgress, startTime, document, (fillRule) =>
                        {
                            document.progressSoFar = fillRule;
                            this.setState({documents: this.state.documents});
                        });
                    }, (err, res) =>
                    {
                        counter++
                        if(err)
                        {
                            document.info.errors = err.errors[0].message;
                            onDocumentUpload(document, counter);
                        }
                        else
                        {
                            document.info.id = res.data;
                            document.info.errors = "";
                            Utils.getDocument(res.data, (err, response) => {
                                if(!err)
                                {
                                    document.src = response.src;
                                }
                                onDocumentUpload(document, counter);
                            });
                        }
                    });
                }
                else
                {
                    counter++
                    if (counter === docBatches[index].length)
                    {
                        onComplete()
                    }
                }
            }//loop end
        }

        create(0)
        this.setState(this.state, this.onChange())
    }

    showUploadingStatus(isBeingUploaded, isInvalid, statusText, index)
    {
        return isBeingUploaded ?
            <div>
                <span className="cancel-file" onClick={() => this.onDeleteClick(index)}>cancel </span>
                <i>{statusText.length ? statusText : "Waiting to upload..."}</i>
            </div>
            :
            isInvalid ? statusText : "";
    }

    render()
    {
        let inputs = this.state.inputs
        let rows = []

        inputs.files.forEach((input, i) =>
        {
            let isInvalid = input.info.hasExceededMaxFileSize || input.info.hasInvalidFileType || !!input.info.errors.length;
            let infoRow =
                <div key={"info-" + i} className={`upload-file-list ${input.info.isBeingUploaded ? "file-uploading" : isInvalid ? "file-upload-error" : ""}`} >

                    <div className="img-name-holder">
                        <div className="file-name">
                            {input.name}
                        </div>
                    </div>

                    <div className="cross-size-holder">
                        {
                            <span className={input.info.isBeingUploaded || isInvalid ? "current-progress" : "upload-status"}>
                                {
                                    this.showUploadingStatus(input.info.isBeingUploaded, isInvalid, input.info.errors, i)
                                }
                            </span>
                        }

                        {
                            input.info.isBeingUploaded || isInvalid ?
                            null
                            :
                            <div className="file-size">
                                 {Utils.fileSize(input.size)}
                            </div>
                        }

                        {
                            input.info.isBeingUploaded ?
                            <ProgressCircle progressSoFar={input.progressSoFar} />
                            :
                            <div className="delete-icon">
                                <Icon src={deleteSrc} onClick={() => this.onDeleteClick(i)}/>
                            </div>

                        }
                    </div>
                </div>

            {

               let specsRow =
                <tr key={"specs-" + i} className={input.info.isBeingUploaded ? "file-uploading" : input.info.errors.length ? "file-upload-error" : ""}
                data-tip={input.info.isBeingUploaded ? "" : input.info.errors.length ? "please remove this document to submit the form as document  " + input.info.errors : ""}
                data-place={input.info.errors.length ? "right" : ""}
                data-type={input.info.errors.length ? "error" : ""}

                >
                    <td colSpan="4">
                        <div>
                            <div>
                                <label htmlFor="type">Type</label>
                                 <select
                                    name="type"
                                    value={input.specs.inputs.type.value}
                                    className={input.specs.inputs.type.class}
                                    data-tip={input.specs.inputs.type.message}
                                    data-place="right"
                                    data-type="error"
                                    onChange={(event) => this.onInputChange(event, i)}
                                    >
                                    {Utils.toOptions(Schemas.product.documents.specs.type.list())}
                                </select>
                            </div>
                            <div>
                                <label htmlFor="revision">Revision</label>
                                <LazyInput
                                    type="text"
                                    name="revision"
                                    value={input.specs.inputs.revision.value}
                                    className={input.specs.inputs.revision.class}
                                    data-tip={input.specs.inputs.revision.message}
                                    data-place="right"
                                    data-type="error"
                                    onChange={(event) => this.onInputChange(event, i)}
                                />
                            </div>
                            <div>
                                <label htmlFor="status">Status</label>
                                 <select
                                    name="status"
                                    value={input.specs.inputs.status.value}
                                    className={input.specs.inputs.status.class}
                                    data-tip={input.specs.inputs.status.message}
                                    data-place="right"
                                    data-type="error"
                                    onChange={(event) => this.onInputChange(event, i)}
                                    >
                                    {Utils.toOptions(Schemas.product.documents.specs.status.list())}
                                </select>
                            </div>
                        </div>
                    </td>
                </tr>

            }

            rows.push(infoRow)
        })


        let markup =

            <div className="documents-block">
                <FileDrop
                    collapsed={inputs.fileDrop.collapsed}
                    onChange={this.onFilesChange}
                    maxFileSize={this.props.maxFileSize}
                    onDropRejected={this.onDropRejected}
                    isDocument={true}
                />
                <div className={"list " + (inputs.files.length < 1 ? "empty" : "")}>
                    {rows}
                </div>
            </div>

        return markup
    }
}

const mapStateToProps = state => ({
  maxFileSize: state.company.maxFileSize,
})

export default connect(mapStateToProps)(Documents)

