import PRODUCT from "../../../action-types/product"
import initialState, { getInitialState, newforecastInputs, getForecastInputs } from "./initial_state"
import { handleActions } from "redux-actions"
import Schemas    from "../../../modules/schemas"

function setFormFields(state= initialState, { payload })
{
    initialState.inputs  = payload
    return {...initialState}
}

function addNewForecast(state=initialState, {payload})
{
    let forecasts = state.inputs.forecasts
    let existingForecastKeys = []
    for(let forecast of forecasts)
    {
        existingForecastKeys.push(forecast.key.value)
    }
    let keyWithCounter = {}
    if (payload.forecastType === "EXPERT")
    {

        keyWithCounter = Schemas.product.forecasts.getNextForecastLabel(existingForecastKeys, payload.forecastLabelType, payload.forecastType)

        let index = 0
        for(let i=0; i < forecasts.length; i++)
        {
            index = i
            if(forecasts[i].key.value === payload.forecastLabelType && forecasts[i+1].key.value !== payload.forecastLabelType)
            {
                index++
                break;
            }
        }
        forecasts.splice(index, 0, newforecastInputs(keyWithCounter.key, keyWithCounter.counter))
    }
    else
    {
        keyWithCounter = Schemas.product.forecasts.getNextForecastLabel(existingForecastKeys, "massProductionBuild")
        forecasts.push(newforecastInputs(keyWithCounter.key, keyWithCounter.counter))
    }

    return {...state}
}

function removeForecast(state=initialState, {payload})
{
    let { inputs } = state
    let deletedForecast = inputs.forecasts.splice(payload.index, 1)
    deletedForecast = deletedForecast[0]
    inputs = resetForecastCounter(inputs, payload.forecastType, deletedForecast)
    return {...state}
}

function resetForecastCounter(inputs, forecastType, deletedForecast)
{
    if (forecastType === "EXPERT")
    {
        inputs.forecasts.forEach((input) =>
        {
            if (input.counter.value > deletedForecast.counter.value)
            {
                input.counter.value = input.counter.value - 1
            }
        })
    }
    else
    {
        let count = 0
        inputs.forecasts.forEach((input) =>
        {
            count++
            if (count > 2)
            {
                input.counter.value = count - 2
            }
        })
    }
    return inputs
}

function disableFormSubmission(state, { payload })
{
    let { inputs } = state
    inputs.submit.class = payload ? "disabled" : ""
    return {...state}
}
function resetForm(state, { payload })
{
    state.inputs = getInitialState().inputs
    return {...state}
}

function updateDocumentState(state, { payload })
{
    state.inputs.documents = payload
    return {...state}
}

function setNonDefaultForecast(state, { payload })
{
    state.inputs.forecasts = getForecastInputs(payload.forecastType)
    return {...state}
}

export default handleActions({
  [PRODUCT.UPDATE_NEW_PRODUCT_FORM_FIELDS]: setFormFields,
  [PRODUCT.ADD_NEW_FORECAST]: addNewForecast,
  [PRODUCT.REMOVE_FORECAST]: removeForecast,
  [PRODUCT.DISABLE_FORM]: disableFormSubmission,
  [PRODUCT.RESET_PRODUCT_NEW_FORM]: resetForm,
  [PRODUCT.UPDATE_DOCUMENTS_STATE]: updateDocumentState,
  [PRODUCT.SET_NON_DEFAULT_FORECAST]: setNonDefaultForecast,
}, initialState);
