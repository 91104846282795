export default 
{
    "sandbox":
    {
        h1  : "Private Sandbox",
        p   : "Any data you add while in this library is for practice only. You can switch to your company account by clicking the exit sandbox link up top.",
        type: "sandbox",
        fill: "transparent",
        buttonText: "GOT IT",
        bottomText: null
    },

    "live":
    {
        h1  : "Company Account",
        p   : "Any data you add while in this library is part of your company account. You can switch back to sandbox by clicking on the help icon above.",
        type: "live",
        fill: "transparent",
        buttonText: "GOT IT",
        bottomText: null
    },

    "skipped":
    {
        h1  : "Sandbox and Quickstart Guide",
        p   : "Go to Sandbox to see tutorials and test features without impacting your company account library.",
        type: "skipped",
        fill: "white",
        // className: 'quick-start-popup',
        buttonText: "GO THERE",
        bottomText: "Don’t Show Again"

    },

    "completed":
    {
        h1  : "Congrats, you have completed your onboarding.",
        p   : "You can switch to the live application by clicking the leave Sandbox button in the tutorial link ribbon.",
        type: "completed",
        fill: "transparent",
        buttonText: "GOT IT",
        bottomText: null
    },

    "notify":
    {
        p   : "Click here to learn about new features.",
        fill: "#04adc3",
        bottomText: null
    
    },

}