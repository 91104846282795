import React from "react";

export const enum AppSection {
  DESIGN = "DESIGN",
  BUILD = "BUILD",
}

export const AppContext = React.createContext({
  toggleColorMode: () => { },
  setUserId: (userId: string) => { },
  userId: "",
  setSection: (section: AppSection | ((value: AppSection) => AppSection)) => { },
  section: AppSection.DESIGN,
});
