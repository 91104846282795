import React from "react";
import Tooltip from "rc-tooltip";
import WarningTriangleBlackBg from "../../../../assets/icons/warning-triangle-black-bg.js";
import AlertIcon from "../../../../assets/icons/alert-icon.js"

function ValidationIcon(props)
{
    let className = props.type === "warning" ? "ui-icon warningEl" : "ui-icon error-alert-icon";
    let icon = props.type === "warning" ? <WarningTriangleBlackBg/> : <AlertIcon/>
    let markup =
        <Tooltip
            placement="top"
            overlay={props.overlay}
            overlayClassName={"default-styles co-list-icon-tooltip"}
            arrowContent={<div className="rc-tooltip-arrow-inner"></div>}>
               <div
                    key={props.index}
                    className={className}
                    onClick={props.onClick}
                    data-tip={props.tooltipMessage}
                    data-type=""
                    data-place="top"
                    data-for={`${props.index}-icon`}
                    >
                    {icon}
                </div>
        </Tooltip>

    return markup;
}

export default ValidationIcon;
