import React, { useCallback, useState, useEffect, useRef } from 'react';
import ModalBox from "../../../../ui/modal-box";
import MandatoryIcon from "../../../../../assets/icons/mandatory-approver-icon";
import InlineIcon from "../../../../ui/icon/inline-icon.js";
import Utils   from "../../../../../modules/utils";

import "./index.css";

const MandatoryApproverNotifier = (props) => {

    const {template, onModalCancel, setPreviousData, coRule} = props;
	const {coType, status} = Utils.splitCoRuleCombination(coRule)
	const notifierModal = useRef(null);
	const [initialView, setInitialView] = useState(true);

	const onCancel = useCallback(() => {
		setPreviousData(template, coRule)
		setInitialView(false)
		onModalCancel()
	}, [coRule, onModalCancel, setPreviousData, template]);

	const handleOutsideClick = useCallback((e) => {
		setPreviousData(template, coRule)
		onCancel();
		onModalCancel();
	}, [coRule, onCancel, onModalCancel, setPreviousData, template]);

	useEffect(() => {
		setTimeout(() => document.addEventListener("click", handleOutsideClick, false));
		return () => {
			document.removeEventListener("click", handleOutsideClick, false);
		}
	}, [handleOutsideClick])

	return (
		<div className="initial-notifier">
		{
			initialView &&
			<ModalBox onClose={onCancel} ref={notifierModal}>
				<div className="header-block">
					<h2>
						<InlineIcon><MandatoryIcon/></InlineIcon>
						Mandatory Approver Template Added 
					</h2>
				</div>
				<div className="content-block">
					<p className="modal-content">
						Your administrator has set up a mandatory approver template, requiring select users be included as approvers on this change order. Additional users may still be added to the approver list. If the criteria listed below changes, the approvers list will be reset.
					</p>
					<ul className="rules-list">
						<li> <b>Rules applied:</b> </li>
						<li> <b>Change Order Type:</b> {coType.toUpperCase()}</li>
						<li> <b>Status:</b> {status} </li>
						<li> <b>Approver Template:</b> {template.templateName} </li>
					</ul>
					<div className="button-handler">
						<button className="save-button" onClick={onCancel}>Got it </button>
					</div>
				</div>
			</ModalBox>
		}
	    </div>
	)
}

export default MandatoryApproverNotifier;
