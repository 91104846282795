import                              "./index.css";
import React, { Component }     from "react";
import Link                     from "../../../../ui//link";
import Icon                     from "../../../../ui/icon";
import InlineIcon               from "../../../../ui/icon/inline-icon.js";
import CategoryTypes            from "../modules/category-types";
import CategorySelect           from "../modules/category-select";
import * as Constants           from "../../../../../modules/constants";
import Utils                    from "../../../../../modules/utils";
import LazyInput                from "../../../../ui/lazy-input/input.js";
import ModalBox                 from "../../../../ui/modal-box";
import ErrorIcon                from "../../../../../assets/icons/error-icon.svg";
import LinkIcon                 from "../../../../../assets/icons/open-link-icon.js";
import Tooltip                  from 'rc-tooltip';
import HelpIcon                 from "../../../../../assets/icons/help-icon.js";
import API                      from "../../../../../modules/api";
import Schemas                  from "../../../../../modules/schemas";
import SubmitBtn                from "../../../../ui/spinner-button";
import validations, {schemas}   from '../../../../../modules/validations';
import ComponentSuccessPopover  from "../../../common/component-success-popover";
import { userById } from "graphql/query/userQueries";
import { ModelType, extractCpnRules } from "utils/cpn";

export class NewComponentWeb extends Component
{
    constructor(props, context)
    {
        super(props, context);
        this.autoPopulate           = this.autoPopulate.bind(this);
        this.checkSkuValidity       = this.checkSkuValidity.bind(this);
        this.onInputChange          = this.onInputChange.bind(this);
        this.onSubmit               = this.onSubmit.bind(this);
        this.goteCmpEditRoute       = this.goteCmpEditRoute.bind(this);
        this.toggleAddMore          = this.toggleAddMore.bind(this);
        this.addAsAlternateSource   = this.addAsAlternateSource.bind(this);
        this.onCloseDuplicateModal  = this.onCloseDuplicateModal.bind(this);
        this.onClose                = this.onClose.bind(this);
        this.validations            = validations.component.children;
        this.getComponetFromAPIAndCreateChildAssemblyInput = this.getComponetFromAPIAndCreateChildAssemblyInput.bind(this);
        this.state =
        {
            displayPopover: false,
            addMore : false,
            haveDuplicateName: false,
            duplicateOf: {},
            mcMasterStatus: window.__integrations ? window.__integrations.McMaster.status : null,
            inputs  :
            {
                categoryTypes :
                {
                    value : ""
                },

                category :
                {
                    value : "",
                    valid : false
                },

                sku :
                {
                    message : "",
                    class   : "",
                    value   : "",
                    valid   : false
                },

                submit :
                {
                    class : "disabled",
                    stopLoading: false,
                }
            }
        };

        this.notFoundMpnText = "MPN not found"
        this.categoryNotSelectedText = "Please select a valid Category to import using an MPN"
        this.foundMpnText = "MPN found"
    }

    onClose()
    {
        this.props.onClose("displayVendorModal", false)
    }

    onCloseDuplicateModal()
    {
        let {haveDuplicateName} = this.state;
        this.setState({haveDuplicateName: false});
    }

    toggleAddMore()
    {
        let {addMore} = this.state;
        this.setState({addMore: !addMore});
    }

    autoPopulate()
    {
        let {inputs} = this.state;
        let state = this.state;
        let sku             = inputs.sku.value;

        switch(sku)
        {
            case "RC0603JR-0710KL" :
            {
                inputs.category.value = "Capacitor";
                inputs.sku.value = "GRM32ER60J107ME20L";
                break;
            }
            //commented this since mcmaster is unavailable should be uncommented once mcmaster issue is fixed.

/*            case "GRM32ER60J107ME20L" :
            {
                inputs.category.value = "Screw";
                inputs.sku.value = "91251A431";
                break;
            }

            case "91251A431" :
            {
                inputs.category.value = "Nut";
                inputs.sku.value = "95036A016";
                break;
            }*/

            default :
            {
                inputs.category.value = "Resistor";
                inputs.sku.value = "RC0603JR-0710KL";
                break;
            }
        }

        inputs.sku.message = "";
        inputs.sku.class = "";
        inputs.sku.valid = false;
        inputs.submit.class = Utils.isValidated(inputs) ? "" : "disabled";
        state.mpnIconClass = "";
        state.mpnIconToolTip = "";
        state.alreadyUsedMpn = false;
        state.alreadyUsedMpnData = null;
        this.setState({inputs: inputs, displayPopover: false}, this.checkSkuValidity);
    }

    checkSkuValidity()
    {
        let state = this.state;
        let inputs = state.inputs;
        inputs.category.valid = Boolean(inputs.category.value);
        let input = inputs.sku;
        state.alreadyUsedMpn = false;
        state.alreadyUsedMpnData = null;

        API.components.checkIfMpnUsed({mpn: inputs.sku.value, category: inputs.category.value}, (err, res) =>
        {
            if (err)
            {
                input.valid              = false;
                state.alreadyUsedMpn     = false;
                state.alreadyUsedMpnData = null;
                state.mpnIconClass = "duro-icon-warning-circle";
                state.mpnIconToolTip = this.notFoundMpnText;
            }
            else
            {
                let {data} = res;
                if (data && !data.isValidMpn)
                {
                    input.valid   = false;
                    state.alreadyUsedMpn     = true;
                    state.alreadyUsedMpnData = data;
                }
                else if (data && data.isValidMpn)
                {
                    input.valid              = true;
                    state.alreadyUsedMpn     = false;
                    state.alreadyUsedMpnData = null;
                    state.mpnIconClass = "duro-icon-confirm-circle";
                    state.mpnIconToolTip = this.foundMpnText;
                }
            }
            inputs.submit.class = Utils.isValidated(inputs) ? "" : "disabled";
            this.setState(state);
        });
    }

    onInputChange(event)
    {
        let state   = this.state;
        let target  = event.target;
        let name    = target.name;
        let value   = target.value;
        let inputs  = state.inputs;
        inputs.sku.message = "";
        inputs.sku.class = "";
        state.displayPopover = false;
        switch(name)
        {
            case "categoryTypes" :
            {
                inputs.categoryTypes.value = value;
                break;
            }
            case "category" :
            {
                inputs.category.value = value;
                inputs.category.valid = Boolean(inputs.category.value);
                let input = inputs.sku;
                input.valid = false;
                if (inputs.category.value && input.value)
                {
                    state.mpnIconClass   = "";
                    state.mpnIconToolTip = "";
                    this.checkSkuValidity();
                }
                else if(!inputs.category.value && input.value)
                {
                    state.mpnIconClass   = "duro-icon-warning-circle";
                    state.mpnIconToolTip = this.categoryNotSelectedText;
                    state.alreadyUsedMpn = false;
                    state.alreadyUsedMpnData = null;
                }
                else
                {
                    state.mpnIconClass   = "";
                    state.mpnIconToolTip = "";
                }
                break;
            }
            case "sku" :
            {
                let input = inputs.sku;
                input.value = Schemas.component.sku.normalize(value);
                input.value = input.value.trim();
                input.valid = Boolean(input.value);
                if (input.valid && inputs.category.value)
                {
                    input.valid = false;
                    if (input.value)
                    {
                        state.mpnIconClass = "";
                        state.mpnIconToolTip = "";
                        this.checkSkuValidity();
                    }
                    else
                    {
                        state.mpnIconClass = "duro-icon-warning-circle";
                        state.mpnIconToolTip = this.notFoundMpnText;
                    }
                }
                else if(input.valid && !inputs.category.value)
                {
                    state.mpnIconClass = "duro-icon-warning-circle";
                    state.mpnIconToolTip = this.categoryNotSelectedText;
                }
                else
                {
                    state.mpnIconClass = "";
                    state.mpnIconToolTip = "";
                }
                break;
            }
            default :
            {
                // noop
            }
        }
        inputs.submit.class = Utils.isValidated(inputs) ? "" : "disabled";
        this.setState(state);
    }

    onSubmit(event)
    {
        let state = this.state;
        let inputs = state.inputs;
        let {isEditRoute} = this.props;
        let archivedFlag = state.addMore || !!isEditRoute;

        inputs.submit.class = "disabled";
        inputs.submit.stopLoading = false;
        this.setState(state);

        const name = inputs.name ? inputs.name.value : '';
        API.components.import(inputs.category.value, inputs.sku.value, name, archivedFlag, (err, res) =>
        {
            if (res && res[0] && res[0].message)
            {
                let errors = res;
                let duplicate_name_error = errors.filter(function(error){ if(error.path && error.path==='name'){return error}});
                state.loading = false;
                if (duplicate_name_error[0] && duplicate_name_error[0].message && duplicate_name_error[0].message === 'Sorry, that name is taken.')
                {
                    duplicate_name_error[0].message = "Name already exists in library.";
                    let data = duplicate_name_error[0].data;
                    state.duplicateOf = duplicate_name_error[0];
                    state.haveDuplicateName = true;
                    state.duplicateOf.class = 'invalid';
                    inputs.submit.stopLoading = true;
                }
            }
            else if (res)
            {
                inputs.submit.stopLoading = true;
                if (archivedFlag)
                {
                    if (state.addMore)
                    {
                        state.displayPopover = true;
                        state.cmpId = res;
                        inputs.sku.value = "";
                    }
                    if (isEditRoute)
                    {
                        this.setState(state, this.getComponetFromAPIAndCreateChildAssemblyInput(res));
                    }
                    else
                    {
                        this.setState(state);
                    }
                }
                else
                {
                    this.setState(state, this.goteCmpEditRoute(res));
                }
            }

            if (err)
            {
                inputs.sku.message = "Component not found";
                inputs.sku.class = "invalid";
                inputs.submit.stopLoading = true;
            }
            this.setState(state);
        });
    }

    goteCmpEditRoute(cmpId)
    {
        this.props.history.push({pathname: `/component/edit/${cmpId}`, showAll: true, state: {isNewObject: true}});
    }

    getDuplicateOfData(duplicateOf)
    {
        let data = {};
        if(duplicateOf && duplicateOf.data && duplicateOf.data.alias && duplicateOf.data.id)
        {
            data.viewLink = duplicateOf.data.alias === 'cmp' ? `/component/view/${duplicateOf.data.id}`: `/product/view/${duplicateOf.data.id}`;
            data.editLink = duplicateOf.data.alias === 'cmp' ? `/component/edit/${duplicateOf.data.id}` : `/product/edit/${ duplicateOf.data.id}`;
            data.type = duplicateOf.data.alias;
            data.link_text = `${duplicateOf.data.cpn} ${duplicateOf.data.name} `;
        }
        else{
            data = null;
        }
        return data;
    }

    addAsAlternateSource()
    {
        let { duplicateOf } = this.state;
        let duplicate       = this.getDuplicateOfData(duplicateOf);
        let { inputs }      = this.state;
        let sku             = inputs.sku.value;
        this.props.history.push({pathname: duplicate.editLink, state: {refererSku: sku, isNewObject: true}});
    }

    getComponetFromAPIAndCreateChildAssemblyInput(cmpId)
    {
        if (cmpId)
        {
            let state = this.state;
            //get component from API and create child input for assembly table
            API.components.findById(cmpId, (err, component) =>
            {
                let selectedCmps = [];
                let child =
                {
                    quantity  : "",
                    refDes    : "",
                    component : component,
                    inputs    :
                    {
                        quantity :
                        {
                            value : "",
                            message: "",
                            valid: false,
                            class: ""
                        },
                        refDes :
                        {
                            value : ""
                        },
                        itemNumber :
                        {
                            value : ""
                        },
                        notes :
                        {
                            value : "",
                            message: "",
                            valid: true,
                            class: ""
                        },
                        waste :
                        {
                            value : "",
                            message: "",
                            valid: true,
                            class: ""
                        }
                    },
                    newlyAdded: true
                };
                let error = this.validations.quantity.validateErrors({refDes: ""}, "");
                child.inputs.quantity.message = error ? error[0].message : "";
                child.inputs.quantity.valid = !child.inputs.quantity.message;
                child.inputs.quantity.class = child.inputs.quantity.valid ? "" : "invalid";
                selectedCmps.push(child);

                let newlyCreatedCmpId = state.addMore ? null : cmpId;
                this.props.newlyAddedComponentsFromRightSearch(selectedCmps, newlyCreatedCmpId);

                //Close vendor modal if add another option false
                if (!state.addMore)
                {
                    this.onClose();
                }
            });
        }
    }

    render()
    {
        let { inputs, loading, haveDuplicateName, duplicateOf, alreadyUsedMpnData, alreadyUsedMpn } = this.state;
        let unsupported_categories = ["Fabricated Carbon Fiber","Fabricated Metal","Fabricated Other","Fabricated Plastic","PCB","Printed Circuit Board"];
        const user = userById();
        const cpnRules = extractCpnRules(user.activeLibrary, ModelType.COMPONENT);

        let duplicate   = this.getDuplicateOfData(duplicateOf);
        let mpnDuplicateTooltip = Utils.makeDuplicateInputTooltip(alreadyUsedMpnData, "MPN");
        let categoryObject  = schemas.categories.findByName2(inputs.category.value, window.__categories);

        let state = this.state
        let markup =

            <div className="new-component-web-route">
                <ModalBox onClose={this.onClose}>
                    <div className="main-block">
                        <div className="content-block">
                            <div className="custom-modal-header">
                                <div className="header-holder">
                                    <div className="heading-holder">
                                        <h2 onClick={this.autoPopulate}>Import Component from vendor</h2>
                                        <Link
                                          to={'https://duro.zendesk.com/hc/en-us/articles/360041699951-Import-from-Vendor'}
                                          target="_blank"
                                          >

                                        <InlineIcon>
                                            <HelpIcon/>
                                        </InlineIcon>
                                        </Link>
                                    </div>
                                    <div className="cpn-tag-holder">
                                        <h4>CPN</h4>
                                        <div className="cpn-tag">
                                            { Utils.getCpnTag(categoryObject, cpnRules) }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-panel">
                                <div className="card-panel-header">
                                    <h3>IMPORT DETAILS</h3>
                                </div>
                                <div className="card-panel-body">
                                    <form onSubmit={this.onSubmit}>
                                        <h5 className="categoryType-heading">CHOOSE TYPE</h5>
                                        <CategoryTypes
                                            type="web"
                                            name="categoryTypes"
                                            onChange={this.onInputChange}
                                            value={inputs.categoryTypes.value}
                                            mcMasterStatus={this.state.mcMasterStatus}
                                        />

                                        <div className="form-group">
                                            <label htmlFor="category">CHOOSE CATEGORY**</label>
                                            <CategorySelect
                                                className="form-control"
                                                name="category"
                                                onChange={this.onInputChange}
                                                type={inputs.categoryTypes.value}
                                                value={inputs.category.value}
                                                allowedTypes={[
                                                    Constants.mechanical,
                                                    Constants.electrical,
                                                    Constants.mechanicalmts,
                                                    Constants.mechanicalots,
                                                    "ELECTRICAL PART",
                                                ]}
                                                unsupported_categories={unsupported_categories}
                                                modelName={"web"}
                                                mcMasterStatus={this.state.mcMasterStatus}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="sku">ENTER MPN*</label>
                                            <Tooltip
                                                placement={"right"}
                                                visible={alreadyUsedMpn || !!inputs.sku.message}
                                                overlayClassName={`simple-rc-tip error ${!!inputs.sku.message ? "not-found" : ""}`}
                                                getTooltipContainer={() => document.querySelector(".modal-content")}
                                                overlay={ mpnDuplicateTooltip ?
                                                        <div>
                                                            <p>
                                                                <span className="link-text">{mpnDuplicateTooltip.errorMessage}</span>
                                                                <br/>
                                                                <Link
                                                                    to={mpnDuplicateTooltip.viewLink}
                                                                    target="_blank"
                                                                    className="open-link-holder white"
                                                                    >
                                                                    <span className="link-text">{mpnDuplicateTooltip.linkMessage}
                                                                        <InlineIcon >
                                                                            <LinkIcon/>
                                                                        </InlineIcon>
                                                                    </span>
                                                                </Link>
                                                            </p>
                                                        </div>
                                                        : inputs.sku.message ?
                                                        <span className="link-text">{inputs.sku.message}</span> : <div />
                                                    }
                                                >
                                                <div className="mpn-input-holder">
                                                    <LazyInput
                                                        type="text"
                                                        name="sku"
                                                        value={inputs.sku.value}
                                                        className={`form-control ${alreadyUsedMpn || inputs.sku.message ? "invalid" : ""}`}
                                                        onChange={this.onInputChange}
                                                        autoComplete="off"
                                                        placeholder="Enter the MPN of the Component">
                                                    </LazyInput>
                                                    <span
                                                        className={`ui-icon ${state.mpnIconClass}`}
                                                        data-tip={`${state.mpnIconToolTip}`}
                                                        data-place="right"
                                                        data-type={`${state.mpnIconToolTip === this.notFoundMpnText || state.mpnIconToolTip === this.categoryNotSelectedText ? "error" : ""}`}
                                                        />
                                                </div>
                                            </Tooltip>
                                        </div>
                                        {
                                            haveDuplicateName && duplicate &&
                                            <div className={"duplicate-name-modal"}>
                                                <ModalBox onClose={this.onCloseDuplicateModal}
                                                >
                                                    <h1>
                                                        <Icon src={ErrorIcon}/>
                                                        Name is taken
                                                    </h1>
                                                    <div className="warning-text">
                                                        Another {duplicate.type === 'cmp' ? "component" : "product"} with the same name exists. <br/>

                                                        <Link
                                                            to={duplicate.viewLink}
                                                            target="_blank"
                                                            className="open-link-holder green"
                                                            >
                                                            <span className="link-text">{duplicate.link_text}
                                                                <InlineIcon>
                                                                    <LinkIcon/>
                                                                </InlineIcon>
                                                            </span>
                                                        </Link>
                                                    </div>

                                                    <div className="name-field-label">
                                                        Do you want to add this MPN as an alternate source?
                                                    </div>

                                                    <div className="bottom-section">
                                                        <div className="button-sections">
                                                          <button
                                                              type="button"
                                                              onClick={this.onCloseDuplicateModal}
                                                              className="dark"
                                                              >
                                                            Cancel
                                                          </button>

                                                          <button
                                                              type="button"
                                                              onClick={this.addAsAlternateSource}
                                                              className="active"
                                                              >
                                                            ADD AS ALTERNATE
                                                          </button>

                                                        </div>
                                                    </div>
                                                </ModalBox>
                                            </div>
                                        }
                                    </form>
                                </div>
                                <div className="card-panel-footer">
                                    <div className="left-section">
                                        <button
                                            className="dark"
                                            onClick={this.onClose}
                                            >
                                            CANCEL
                                        </button>
                                    </div>
                                    <div className="right-section">
                                      <div className="checkbox-label">
                                        <input
                                            type="checkbox"
                                            name="add_more"
                                            onClick={this.toggleAddMore}
                                        />
                                        <label
                                            htmlFor="group"
                                        />
                                        <span className="label_value">
                                          Add Another
                                        </span>
                                      </div>

                                       <SubmitBtn
                                          onClick={this.onSubmit}
                                          className={inputs.submit.class}
                                          btnText={"CREATE"}
                                          isDisabled={inputs.submit.class === 'disabled'}
                                          stopLoading={inputs.submit.stopLoading}
                                        />
                                        {
                                            this.state.displayPopover ?
                                            <ComponentSuccessPopover cmpId={this.state.cmpId}/>
                                            : null
                                        }
                                    </div>
                                 </div>
                            </div>
                        </div>
                    </div>
                </ModalBox>
            </div>
        return markup
    }
}

export default NewComponentWeb
