import { SerializationIcon } from "assets/icons";
import { BuildPageWrapper } from "build/components/pageWrapper";
import { SerializationModal } from "build/components/serializationModal";
import { BreadCrumbIcon, useBreadCrumb } from "common/components/breadcrumb";
import { IToolbarActions } from "common/components/toolbar";
import { useCallback, useMemo, useState } from "react";
import { privatePaths } from "v1/app/routes";

const BREADCRUMB_OPTIONS = {
  icon: BreadCrumbIcon.PRODUCTS,
  label: "Instances",
  url: privatePaths.buildInstances.pathname,
};

export function BuildInstances() {
  const breadCrumbOptions = useBreadCrumb(BREADCRUMB_OPTIONS);
  const [displaySerializationModal, setDisplaySerializationModal] = useState(false);

  const toggleSerializationModal = useCallback(() => (
    setDisplaySerializationModal((displayModal: boolean) => !displayModal)
  ), []);

  const onSubmitSerializationModal = useCallback(() => {
    setDisplaySerializationModal(false);
  }, []);

  const toolbarActionItems: IToolbarActions[] = useMemo(() => ([{
    Icon: SerializationIcon,
    label: "Serialize",
    onClick: () => toggleSerializationModal(),
  }]), [toggleSerializationModal]);

  return (
    <BuildPageWrapper
      breadCrumbOptions={breadCrumbOptions}
      helmetTitle="Build Instance Library"
      toolbarProps={{
        toolbarItems: toolbarActionItems,
      }}
    >
      This is the Instance Library!
      {displaySerializationModal && (
        <SerializationModal
          onCancel={toggleSerializationModal}
          onClose={toggleSerializationModal}
          onSubmitCallback={onSubmitSerializationModal}
          open={displaySerializationModal}
          title="Serialize and Create New Lot"
        />
      )}
    </BuildPageWrapper>
  );
}
