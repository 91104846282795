import './index.css'
import React, { useState, useEffect, useRef } from 'react'
import ModalBox from '../../../../../ui/modal-box'
import Avatar from '../../../../../ui/avatar'
import InlineIcon from '../../../../../ui/icon/inline-icon'
import DeleteIcon from '../../../../../../assets/icons/cross-icon'
import Icon from '../../../../../ui/icon'
import emailSrc from "../../../../../../assets/icons/email-icon.svg"
import COApi from '../../../../../../modules/api/cos'
import Utils from '../../../../../../modules/utils'
import validator from 'validator'
import _ from 'lodash'

const ResendEmailModal = (
  {
    closeModal,
    coId,
    avatarUser,
    approvers,
    notifiers
  }
) => {
  const [emailList, setEmailList] = useState([])
  const [apprList, setApprList] = useState([])
  const [notiList, setNotiList] = useState([])
  const [ccList, setCcList] = useState([])
  const [isEmailsValid, setIsEmailsValid] = useState(false)
  const [emails, setEmails] = useState({
    value: "",
    valid: true,
    class: "",
    message: ""
  })
  const [isSended, setIsSended] = useState(false)
  const inputEl = useRef(null)
  const apprCheck = useRef(null)
  const notiCheck = useRef(null)

  const handleEmailsChange = (e) => {
    let value = e.target.value
    let valid = validateEmails(value)
    
    setIsEmailsValid(valid)
    setEmails({
      value,
      valid,
      class: valid ? "" : "invalid",
      message: valid ? "" : "Please enter a valid email address",
    })
  }

  const handleEmailsAdd = (e, emails) => {
    e.preventDefault()

    if (emails.length) {
      let entryList = emails.replace(/\s/g, '').split(",")
      let currentList = [...ccList]

      entryList.forEach(el => {
        currentList.push({
          userId: "",
          avatarId: "",
          src: "",
          email: el,
          username: "Anonymous",
          list: "cc"
        })
      })

      currentList = _.uniqBy(currentList, 'email')
      setCcList(currentList)
      inputEl.current.value = ""
      setEmails({
        value: "",
        valid: true,
        class: "",
        message: ""
      })
      inputEl.current.focus()
    }
  }

  const handleRemoveUser = (e, email, list) => {
    e.preventDefault()

    switch (list) {
      case "approvers":
        setApprList(apprList.filter(val => val.email !== email))
        break
      case "notifiers":
        setNotiList(notiList.filter(val => val.email !== email))
        break
      case "cc":
        setCcList(ccList.filter(val => val.email !== email))
        break
      default:
        break
    }
  }

  const handleCheck = (e, type) => {
    if (type === "appr") {
      setApprList(apprCheck.current.checked ? [...approvers] : [])
    } else {
      setNotiList(notiCheck.current.checked ? [...notifiers] : [])
    }
  }

  const handleResendEmails = (e) => {
    e.preventDefault()

    const userIds = []
    const emails = []

    emailList.forEach(val => {
      userIds.push(val.userId)
      emails.push(val.email)
    })

    COApi.resendResolutionEmails(coId, {userIds, emails}, (err, res) => {
      if (res) {
        setIsSended(true)
      }
    })
  }

  const validateEmails = (value) => {
    if (!value) return true
    let valid = false
    let emails = value.split(",")

    emails.forEach(email => {
      valid = (!validator.isEmail(email.trim())) ? false : true
    })

    return valid
  }

  useEffect(() => {
    setEmailList([...avatarUser])
  }, [avatarUser])

  useEffect(() => {
    let newList = _.uniqBy([...avatarUser, ...apprList, ...notiList, ...ccList], 'email')
    setEmailList(newList)
  }, [apprList, avatarUser, notiList, ccList])

  return (
    <div className="resend-email">
      {
        !isSended &&
        <ModalBox
          onClose={closeModal}
        >
          <div className="content-block">
            <h1>Resend Change Order?</h1>
            <p>
              Resend original Change Order final email and attachments to your specified email list
            </p>
            <div className='includers'>
              <span>Inlcude Original:</span>
              <div className="approver-checkbox">
                <input
                  ref={apprCheck}
                  onClick={e => handleCheck(e, "appr")}
                  type="checkbox"
                  name="approvers"
                />
                <label htmlFor="approvers" />
                <span>Approvers</span>
              </div>
              <div className="notifier-checkbox">
                <input
                  ref={notiCheck}
                  onClick={e => handleCheck(e, "noti")}
                  type="checkbox"
                  name="notifiers"
                />
                <label htmlFor="notifiers" />
                <span>Notifiers</span>
              </div>
            </div>
            <div className="includers-list">
              {
                emailList.length && emailList.map((item, ind) => (
                  <div className="item" key={`item-emails-${ind}`}>
                    <Avatar
                      imageId={item.avatarId}
                      index={ind}
                      imageWithSrc={[{
                        _id: item.avatarId,
                        src: item.src
                      }]}
                      key={`resend-email-${ind}`}
                    />
                    <span className="name">{item.username}{ind === 0 ? ' (Me)' : ''}</span>
                    <div className='email'>
                      <a
                        href={`mailto:${item.email}`}
                        data-tip=""
                        data-place="top"
                        onMouseOver={(e) => Utils.checkToolTipAppearance(e, item.email)}
                      >
                        {item.email}
                      </a>
                    </div>
                    <InlineIcon
                      className={ind === 0 ? 'disabled' : ''}
                      onClick={e => handleRemoveUser(e, item.email, item.list)}
                    >
                      <DeleteIcon />
                    </InlineIcon>
                  </div>
                ))
              }
            </div>
            <div className="includers-cc">
              <span className="cc-title">CC other people:</span>
              <input
                ref={inputEl}
                type="text"
                placeholder="Enter email addresses (separate by commas)"
                onChange={handleEmailsChange}
                data-tip={emails.message}
                data-place="left"
                data-type="error"
                className={emails.class}
                defaultValue=""
              />
              <button
                className={`action-button${isEmailsValid || emails.value === '' ? '' : ' disabled'}`}
                onClick={e => handleEmailsAdd(e, emails.value)}
              >Add
              </button>
            </div>
            <div className="bottom-section">
              <button
                onClick={e => handleResendEmails(e)}
              >
                Resend
              </button>
              <button className="dark" onClick={closeModal}>
                Cancel
              </button>
            </div>
          </div>
        </ModalBox>
      }
      {
        isSended &&
        <ModalBox
          className="emailed"
          onClose={closeModal}
        >
          <div className="email-sent-block">
            <Icon
              src={emailSrc}
            />
            <h1>Preparing Your Change Order</h1>
            <div className="email-info">
              <div className="modal-text">
                <p className="content">
                  We are preparing your change order. It will be<br/>
                  { `emailed to ${emailList.map(val => val.email).join(", ")}` }<br/>
                  and any additional listed recipients.
                </p>
              </div>
            </div>
          </div>
        </ModalBox>
      }
    </div>
  )
}

export default ResendEmailModal
