import { GridColDef } from "@mui/x-data-grid-pro";
import { CellContentAlignment, renderEditInputCell } from "common/components/grid";
import {
  cpnRenderCell, imageRenderCell, lastUpdatedRenderCell, massRenderCell, statusRenderCell, totalPriceRenderCell,
  unitPriceRenderCell,
} from "design/components/grid";
import { AssemblyTableStyle } from "design/models";
import {
  categoryValueGetter, cmpStateValueGetter, cpnValueGetter, descriptionValueGetter, eidValueGetter,
  extendedQuantityValueGetter, lastUpdatedValueGetter, leadTimeValueGetter, manufacturerValueGetter,
  massValueGetter, mpnValueGetter, nameValueGetter, procurementValueGetter, revisionValueGetter,
  statusValueGetter, totalPriceValueGetter, unitOfMeasureValueGetter, unitPriceValueGetter,
} from "design/utils/componentGrid";

export type ColumnTypes = keyof Omit<AssemblyTableStyle, "defaultSortAssending" | "defaultSortColumnName">;
export type AssemblyColumnDefs = GridColDef & { position: number, field: ColumnTypes };

export const COLUMN_DEFAULTS: Record<ColumnTypes, AssemblyColumnDefs> = Object.freeze({
  category: {
    editable: false,
    field: "category",
    headerName: "Category",
    hideable: true,
    minWidth: 71,
    position: 6,
    sortable: true,
    valueGetter: categoryValueGetter,
    width: 150,
  },
  cmpState: {
    editable: false,
    field: "cmpState",
    headerName: "Workflow State",
    hideable: true,
    minWidth: 117,
    position: 9,
    sortable: true,
    valueGetter: cmpStateValueGetter,
    width: 190,
  },
  cpn: {
    editable: false,
    field: "cpn",
    headerName: "CPN",
    hideable: false,
    minWidth: 71,
    position: 0,
    renderCell: cpnRenderCell,
    sortable: true,
    valueGetter: cpnValueGetter,
    width: 125,
  },
  cpnName: {
    disableReorder: true,
    editable: false,
    field: "cpnName",
    headerName: "CPN/Name",
    hideable: false,
    minWidth: 215,
    position: 0,
    sortable: true,
    valueGetter: cpnValueGetter,
    width: 200,
  },
  description: {
    editable: false,
    field: "description",
    headerName: "Description",
    hideable: true,
    minWidth: 117,
    position: 20,
    sortable: true,
    valueGetter: descriptionValueGetter,
    width: 160,
  },
  eid: {
    editable: false,
    field: "eid",
    headerName: "EID",
    hideable: true,
    minWidth: 71,
    position: 13,
    sortable: true,
    valueGetter: eidValueGetter,
    width: 125,
  },
  extendedCost: {
    align: CellContentAlignment.RIGHT,
    editable: false,
    field: "extendedCost",
    headerName: "Extended Cost",
    hideable: true,
    minWidth: 71,
    position: 7,
    sortable: true,
    width: 170,
  },
  extendedQuantity: {
    align: CellContentAlignment.RIGHT,
    editable: false,
    field: "extendedQuantity",
    headerName: "Extended Quantity",
    hideable: true,
    minWidth: 71,
    position: 5,
    sortable: true,
    valueGetter: extendedQuantityValueGetter,
    width: 210,
  },
  images: {
    editable: false,
    field: "images",
    headerName: "Images",
    hideable: true,
    minWidth: 90,
    position: 4,
    renderCell: imageRenderCell,
    sortable: false,
    width: 90,
  },
  itemNumber: {
    align: CellContentAlignment.RIGHT,
    editable: true,
    field: "itemNumber",
    headerName: "Item Number",
    hideable: true,
    minWidth: 71,
    position: 12,
    renderEditCell: renderEditInputCell,
    sortable: true,
    width: 160,
  },
  lastUpdated: {
    editable: false,
    field: "lastUpdated",
    headerName: "Last Updated",
    hideable: true,
    minWidth: 170,
    position: 23,
    renderCell: lastUpdatedRenderCell,
    sortable: true,
    valueGetter: lastUpdatedValueGetter,
    width: 170,
  },
  leadTime: {
    align: CellContentAlignment.RIGHT,
    editable: false,
    field: "leadTime",
    headerName: "Lead Time",
    hideable: true,
    minWidth: 71,
    position: 19,
    sortable: true,
    valueGetter: leadTimeValueGetter,
    width: 140,
  },
  manufacturer: {
    editable: false,
    field: "manufacturer",
    headerName: "Manufacturer",
    hideable: true,
    minWidth: 117,
    position: 22,
    sortable: true,
    valueGetter: manufacturerValueGetter,
    width: 170,
  },
  mass: {
    editable: false,
    field: "mass",
    headerName: "MASS (g)",
    hideable: true,
    minWidth: 117,
    position: 16,
    renderCell: massRenderCell,
    sortable: true,
    valueGetter: massValueGetter,
    width: 125,
  },
  mpn: {
    editable: false,
    field: "mpn",
    headerName: "MPN",
    hideable: true,
    minWidth: 117,
    position: 21,
    sortable: true,
    valueGetter: mpnValueGetter,
    width: 125,
  },
  name: {
    editable: false,
    field: "name",
    headerName: "Name",
    hideable: false,
    minWidth: 71,
    position: 1,
    sortable: true,
    valueGetter: nameValueGetter,
    width: 140,
  },
  notes: {
    editable: true,
    field: "notes",
    headerName: "Notes",
    hideable: true,
    minWidth: 71,
    position: 15,
    renderEditCell: renderEditInputCell,
    sortable: true,
    width: 140,
  },
  procurement: {
    editable: false,
    field: "procurement",
    headerName: "Procurement",
    hideable: true,
    minWidth: 117,
    position: 8,
    sortable: true,
    valueGetter: procurementValueGetter,
    width: 170,
  },
  quantity: {
    align: CellContentAlignment.RIGHT,
    editable: true,
    field: "quantity",
    headerName: "QTY",
    hideable: true,
    minWidth: 71,
    position: 2,
    renderEditCell: renderEditInputCell,
    sortable: true,
    width: 100,
  },
  refDes: {
    editable: true,
    field: "refDes",
    headerName: "Ref Des",
    hideable: true,
    minWidth: 71,
    position: 10,
    renderEditCell: renderEditInputCell,
    sortable: true,
    width: 140,
  },
  remove: {
    disableReorder: true,
    editable: false,
    field: "remove",
    headerName: "",
    hideable: false,
    hideSortIcons: true,
    minWidth: 32,
    position: 24,
    resizable: false,
    sortable: false,
    width: 32,
  },
  revision: {
    align: CellContentAlignment.RIGHT,
    editable: false,
    field: "revision",
    headerName: "Revision",
    hideable: true,
    minWidth: 120,
    position: 11,
    sortable: true,
    valueGetter: revisionValueGetter,
    width: 140,
  },
  status: {
    editable: false,
    field: "status",
    headerName: "Status",
    hideable: true,
    minWidth: 71,
    position: 14,
    renderCell: statusRenderCell,
    sortable: true,
    valueGetter: statusValueGetter,
    width: 140,
  },
  totalPrice: {
    editable: false,
    field: "totalPrice",
    headerName: "Total Price",
    hideable: true,
    minWidth: 71,
    position: 18,
    renderCell: totalPriceRenderCell,
    sortable: true,
    valueGetter: totalPriceValueGetter,
    width: 160,
  },
  unitOfMeasure: {
    editable: false,
    field: "unitOfMeasure",
    headerName: "Unit Of Measure",
    hideable: true,
    minWidth: 71,
    position: 17,
    sortable: true,
    valueGetter: unitOfMeasureValueGetter,
    width: 190,
  },
  unitPrice: {
    editable: false,
    field: "unitPrice",
    headerName: "Unit Price",
    hideable: true,
    minWidth: 117,
    position: 17,
    renderCell: unitPriceRenderCell,
    sortable: true,
    valueGetter: unitPriceValueGetter,
    width: 140,
  },
  waste: {
    align: CellContentAlignment.RIGHT,
    editable: true,
    field: "waste",
    headerName: "Waste %",
    hideable: true,
    minWidth: 71,
    position: 3,
    renderEditCell: renderEditInputCell,
    sortable: true,
    width: 130,
  },
});

const ASSEMBLY_BASE_COLUMNS = [
  COLUMN_DEFAULTS.category.field,
  COLUMN_DEFAULTS.cmpState.field,
  COLUMN_DEFAULTS.description.field,
  COLUMN_DEFAULTS.eid.field,
  COLUMN_DEFAULTS.itemNumber.field,
  COLUMN_DEFAULTS.lastUpdated.field,
  COLUMN_DEFAULTS.leadTime.field,
  COLUMN_DEFAULTS.manufacturer.field,
  COLUMN_DEFAULTS.mass.field,
  COLUMN_DEFAULTS.mpn.field,
  COLUMN_DEFAULTS.notes.field,
  COLUMN_DEFAULTS.procurement.field,
  COLUMN_DEFAULTS.quantity.field,
  COLUMN_DEFAULTS.revision.field,
  COLUMN_DEFAULTS.status.field,
  COLUMN_DEFAULTS.totalPrice.field,
  COLUMN_DEFAULTS.unitOfMeasure.field,
  COLUMN_DEFAULTS.unitPrice.field,
];

export const ASSEMBLY_GRID_COLUMNS = [
  ...ASSEMBLY_BASE_COLUMNS,
  COLUMN_DEFAULTS.cpn.field,
  COLUMN_DEFAULTS.images.field,
  COLUMN_DEFAULTS.name.field,
];

export const ASSEMBLY_TREE_COLUMNS = [
  ...ASSEMBLY_BASE_COLUMNS,
  COLUMN_DEFAULTS.images.field,
];

export const ASSEMBLY_EDIT_COLUMNS = [
  ...ASSEMBLY_BASE_COLUMNS,
  COLUMN_DEFAULTS.cpn.field,
  COLUMN_DEFAULTS.name.field,
  COLUMN_DEFAULTS.remove.field,
];

export const ASSEMBLY_WASTE_COLUMNS = [
  COLUMN_DEFAULTS.waste.field,
  COLUMN_DEFAULTS.extendedCost.field,
  COLUMN_DEFAULTS.extendedQuantity.field,
];

export const ASSEMBLY_PINNED_COLUMNS = {
  left: [COLUMN_DEFAULTS.cpn.field, COLUMN_DEFAULTS.cpnName.field],
  right: [COLUMN_DEFAULTS.remove.field],
};

export const ASSEMBLY_REQUIRED_COLUMNS = [
  COLUMN_DEFAULTS.cpn.field,
  COLUMN_DEFAULTS.cpnName.field,
  COLUMN_DEFAULTS.name.field,
  COLUMN_DEFAULTS.remove.field,
];
