import { Box, styled } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import {
  EmptyTable, getRowId, Grid, setVisibleNodeExpansionState, SortMode, useGridHeight,
} from "common/components/grid";
import { GridDataType } from "design/components/grid/cellRenderers";
import { PageItemType, PageMode } from "design/constants";
import { useCanUserViewVariants } from "design/hooks";
import { useHasRefDes } from "design/hooks/assemblies";
import { AssemblyChildVariant, Component } from "design/models";
import { client } from "graphql/apolloClient";
import { getComponent, refetchComponents, useComponentWithChildren } from "graphql/query/componentQueries";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useLocalStorageState from "use-local-storage-state";
import UiActions from "v1/action-types/ui";
import VariantModal from "v1/components/page/common/view-action-items/variant-modal";
import FileImportModule from "v1/components/page/component/new/file/file-import-module";
import ImportFromManual from "v1/components/page/component/new/manual";
import ImportFromVendor from "v1/components/page/component/new/web";
import Utils from "v1/modules/utils";
import { useAssemblyToolbar } from "./assemblyTab/assemblyToolbar";
import { ASSEMBLY_PINNED_COLUMNS, ASSEMBLY_REQUIRED_COLUMNS, COLUMN_DEFAULTS } from "../../utils/columnDefaults";
import { useAssemblyColumns, useColumnNames } from "./assemblyTab/useAssemblyColumns";
import { childToGridData, childrenToGridData, useGridHelpers } from "./assemblyTab/useGridHelpers";
import { useGridUpdates } from "./assemblyTab/useGridUpdates";
import { NO_ROWS_HEIGHT, useNoRowsOverlay } from "./assemblyTab/useNoRowsOverlayProps";
import { MUI_GROUPING_COLUMN_NAME, useTablePreferences } from "./assemblyTab/useTablePreferences";
import { useComponentAddAssemblyContext, useComponentModalContext } from "./componentModal";
import { AssemblyView, EditModal } from "./constants";
import { EditTabsContext } from "../common/editTabsContextProvider";
import { PrimaryVariantType } from "design/components/fields";

const STYLE_NAME = "assemblyTable";
const GRID_NAME = "assembly";

/**
 * Builds the top level data for the grid from a components assembly.
 *
 * @returns The top level grid data and the ability to add more.
 */
function useGridData(component?: Component, isReloading?: boolean) {
  const [gridData, setGridData] = useState<GridDataType[]>([]);
  const { getAssembly, setAssembly } = useContext(EditTabsContext);
  const { children, hasDataBeenLoaded, shouldReadFromContext } = getAssembly();
  // Update the data shown in the grid once the children are loaded, as well as after the user has
  // clicked the reload button and the data was reloaded. This is done as it seems that every time
  // more children are loaded during in tree view that the children array is rebuilt and causes this
  // to re-run.

  const contextNeedsToBeUpdated = useCallback((contextData: any, stateData: any) => {
    if (children?.length !== gridData.length) {
      return true;
    }
    if (children?.length === gridData.length) {
      const keys = ["itemNumber", "notes", "quantity", "refDes", "waste"];
      return contextData.some((data: any, index: number) => (
        keys.some(key => data[key] !== stateData[index][key])));
    }
    return false;
  }, [children, gridData]);

  useEffect(() => {
    if (shouldReadFromContext) {
      setGridData(children);
      setAssembly({ shouldReadFromContext: false });
      return;
    }
    if (hasDataBeenLoaded && !isReloading) {
      const needsToBeUpdated = contextNeedsToBeUpdated(children, gridData);
      if (needsToBeUpdated) {
        setAssembly({ children: gridData });
      }
      return;
    }

    if (component?.children) {
      const payload = childrenToGridData(component?.children);
      setAssembly({ hasDataBeenLoaded: true, children: payload });
      setGridData(payload);
    }
  }, [
    children,
    component?.children,
    contextNeedsToBeUpdated,
    gridData,
    hasDataBeenLoaded,
    isReloading,
    setAssembly,
    shouldReadFromContext,
  ]);

  return { gridData, setGridData };
}

function useInputCategoryValue(pageItemType: PageItemType) {
  // TODO: Update this to not use redux when we move the rest of the page to GraphQL.
  return useSelector((store: any) => {
    if (pageItemType !== PageItemType.COMPONENT) return null;

    return store.category?.editPage?.inputs?.category?.value;
  });
}

export interface AssemblyTabProps {
  id: string;
  isEditing: boolean;
  isVisible: boolean;
  mode: PageMode;
  pageItemType: PageItemType;
  updateErrorCount: (amount: number) => void;
}

export function AssemblyTab(props: AssemblyTabProps) {
  const { id, isEditing, isVisible, mode, pageItemType, updateErrorCount } = props;

  const apiRef = useGridApiRef();
  const dispatch = useDispatch();
  const history = useHistory();

  const [childLoadingCount, setChildLoadingCount] = useState(0);
  const [displayManualModal, setDisplayManualModal] = useState(false);
  const [displayVendorModal, setDisplayVendorModal] = useState(false);
  const [primaryVariant, setPrimaryVariant] = useState<PrimaryVariantType | undefined>();
  const [variantModalOpen, setVariantModalOpen] = useState(false);

  const { setAssembly } = useContext(EditTabsContext);
  const {
    showFileImportModal: displayFileImport,
    setShowFileImportModal: setDisplayFileImport,
  } = useComponentModalContext();

  const [assemblyView, setAssemblyView] = useLocalStorageState(
    "assemblyView",
    { defaultValue: AssemblyView.TREE },
  );
  const showTreeData = assemblyView === AssemblyView.TREE && !isEditing;

  const { component, componentLoading } = useComponentWithChildren(id);
  const hasRefDes = useHasRefDes(pageItemType, component?.category);

  const [isReloading, setReloading] = useState(false);
  const reloadComponent = useCallback(async () => {
    setReloading(true);
    await refetchComponents(client, [id]);
    setReloading(false);
  }, [id]);

  const { gridData, setGridData } = useGridData(component, isReloading);

  const openVariantModal = useCallback((pv: PrimaryVariantType) => {
    setVariantModalOpen(true);
    setPrimaryVariant(pv);
  }, []);

  const onCloseVariantModal = useCallback(() => {
    setVariantModalOpen(false);
    setPrimaryVariant(undefined);
  }, []);

  useEffect(() => () => setAssembly({ shouldReadFromContext: true }), [setAssembly]);
  const columnNames = useColumnNames({ assemblyView, isEditing, hasRefDes });

  // Make sure that sorting of the columns stays consistent when switching between tree, grid, and edit modes.
  const mutateSortColumn = useCallback((name: string) => {
    switch (name) {
      case COLUMN_DEFAULTS.cpn.field:
        // When sorting on the cpn field and then swapping to tree view, then the cpn/name field should
        // be sorted.
        if (assemblyView === AssemblyView.TREE && !isEditing) {
          return MUI_GROUPING_COLUMN_NAME;
        }
        return name;
      case COLUMN_DEFAULTS.cpnName.field:
        // When sorting on the cpn/name field and then swapping to grid or edit view, then the cpn field
        // should be sorted.
        if (isEditing || assemblyView === AssemblyView.GRID) {
          return COLUMN_DEFAULTS.cpn.field;
        }
        return MUI_GROUPING_COLUMN_NAME;
      default: return name;
    }
  }, [assemblyView, isEditing]);

  const {
    columnVisibilityModel,
    getCurrentStyles,
    onColumnVisibilityChange,
    onColumnsReordered,
    onColumnsResized,
    onSortModelChange,
    sortModel,
  } = useTablePreferences({
    columnNames,
    groupingColumnName: COLUMN_DEFAULTS.cpnName.field,
    mutateSortColumn,
    requiredFieldNames: ASSEMBLY_REQUIRED_COLUMNS,
    styleName: STYLE_NAME,
  });

  const { disableCollapse, disableExpand, visibleRowCount } = useGridHelpers({
    apiRef,
    assemblyView,
    component,
    componentLoading,
    gridData,
    isEditing,
    setChildLoadingCount,
  });

  const onExpandClicked = useCallback(() => {
    setVisibleNodeExpansionState(apiRef, true);
  }, [apiRef]);

  const onCollapseClicked = useCallback(() => {
    setVisibleNodeExpansionState(apiRef, false);
  }, [apiRef]);

  const updateAssemblyView = useCallback((newAssemblyView: AssemblyView) => {
    if (newAssemblyView === AssemblyView.GRID) {
      // When leaving tree view, name sure to clean out all the children from
      // the grid data.
      setGridData(cur => cur.filter(d => d._path.length === 1));

      // When leaving tree view, make sure to set all children state to collapsed
      // so that the arrows are correct the next time we enter it.
      setVisibleNodeExpansionState(apiRef, false);
    }
    setAssemblyView(newAssemblyView);
  }, [apiRef, setAssemblyView, setGridData]);

  const toolbarItems = useAssemblyToolbar({
    assemblyView,
    disableCollapse,
    disableExpand,
    id,
    isEditing,
    onCollapseClicked,
    onExpandClicked,
    reloadComponent,
    setAssemblyView: updateAssemblyView,
    setDisplayFileImport,
    setDisplayManualModal,
    setDisplayVendorModal,
  });

  const {
    addAssemblyFromFile,
    addAssemblyFromLibrary,
    setCmpId,
  } = useComponentAddAssemblyContext();
  const inputsCategoryValue = useInputCategoryValue(pageItemType);

  const toggleModal = useCallback((modalName: EditModal, modalValue: boolean) => {
    if (modalValue === true) {
      setCmpId(null);
    }
    switch (modalName) {
      case EditModal.FILE_IMPORT:
        setDisplayFileImport(modalValue);
        break;
      case EditModal.MANUAL:
        setDisplayManualModal(modalValue);
        break;
      case EditModal.VENDOR:
        setDisplayVendorModal(modalValue);
        break;
      default: // Do nothing, unsupported modal type
    }
  }, [setCmpId, setDisplayFileImport]);

  const showUiAlert = useCallback((payload: any) => {
    dispatch({ type: UiActions.SHOW_ALERT, payload });
  }, [dispatch]);

  const { processRowUpdate, onCellEditStop, onInputValidationChange, onRemoveRow } = useGridUpdates({
    gridData, hasRefDes: !!hasRefDes, pageItemType, setChildLoadingCount, setGridData, updateErrorCount,
  });

  const { checkboxColumn, columns, groupColumn } = useAssemblyColumns({
    columnNames,
    getCurrentStyles,
    id,
    isEditing,
    mode,
    onInputValidationChange,
    onRemoveRow,
    openVariantModal,
    pageItemType,
  });

  const onFileImportDone = useCallback((newlyCreatedComponents: any) => {
    setDisplayFileImport(false);
    addAssemblyFromFile(newlyCreatedComponents.map((
      { itemNumber, notes, quantity, refDes, waste, ...comp }: any,
    ) => ({ component: comp, itemNumber, notes, quantity, refDes, waste })));
  }, [addAssemblyFromFile, setDisplayFileImport]);

  const noRowsOverlayProps = useNoRowsOverlay({
    id, isEditing, setDisplayFileImport, setDisplayManualModal, setDisplayVendorModal,
  });

  const childCountLabel = useMemo(() => {
    if (visibleRowCount === 1) return `${visibleRowCount} Component`;
    return `${visibleRowCount} Components`;
  }, [visibleRowCount]);

  const gridHeight = useGridHeight(visibleRowCount, NO_ROWS_HEIGHT);

  const updatePrimaryVariant: UpdatePrimaryVariantFunc = useCallback((oldId, newComp, parentId, variants) => {
    refetchComponents(client, [id]);

    const oldRow = apiRef.current.getRow(oldId) as GridDataType;

    // Make sure to get the updated variant values with the old variant information, as
    // the variants argument is in the old format.
    const updatedVariants = variants.map(v => ({
      ...v,
      variant: oldRow.variants?.find(oldV => oldV.variant?.id === v.variant)?.variant,
    }));

    if (oldId !== newComp._id) {
      // Get the component information through GraphQL so that the correct information is loaded.
      getComponent(client, newComp._id).then(({ component: gqlComp }) => {
        if (gqlComp) {
          // Update the information displayed in the rows.
          apiRef.current.updateRows([
            childToGridData({
              component: gqlComp,
              itemNumber: oldRow.itemNumber,
              notes: oldRow.notes,
              quantity: oldRow.quantity,
              refDes: oldRow.refDes,
              variants: updatedVariants,
              waste: oldRow.waste,
            }),
            { ...oldRow, _action: "delete" },
          ]);
        }
      });
    }
    else {
      apiRef.current.updateRows([
        { ...oldRow, variants: updatedVariants },
      ]);
    }
  }, [apiRef, id]);

  if (!isVisible) return null;

  return (
    <>
      <WrappedVariantModal
        id={id}
        onClose={onCloseVariantModal}
        open={variantModalOpen}
        pageItemType={pageItemType}
        primaryVariant={primaryVariant}
        updatePrimaryVariant={updatePrimaryVariant}
      />
      <GridContainer height={gridHeight}>
        <Grid
          apiRef={apiRef}
          columnDefinition={columns}
          columnVisibilityModel={columnVisibilityModel}
          data={gridData}
          defaultGroupingExpansionDepth={1}
          disableCheckbox
          enableCellFocus={isEditing}
          getRowId={getRowId}
          loading={componentLoading || isReloading || childLoadingCount > 0}
          name={GRID_NAME}
          noRowsOverlay={EmptyTable}
          noRowsOverlayProps={noRowsOverlayProps}
          onCellEditStop={onCellEditStop}
          onColumnsReordered={onColumnsReordered}
          onColumnsResized={onColumnsResized}
          onColumnVisibilityModelChange={onColumnVisibilityChange}
          onSortChange={onSortModelChange}
          pinnedColumns={ASSEMBLY_PINNED_COLUMNS}
          processRowUpdate={processRowUpdate}
          sortMode={SortMode.CLIENT}
          sortModel={sortModel}
          toolbarItems={toolbarItems}
          totalCount={childCountLabel}
          treeColumnDefinition={groupColumn}
          treeData={showTreeData}
          checkboxColumnDefinition={checkboxColumn}
        />
      </GridContainer>

      {displayVendorModal && (
        <ImportFromVendor
          history={history}
          isEditRoute={true}
          newlyAddedComponentsFromRightSearch={addAssemblyFromLibrary}
          onClose={toggleModal}
        />
      )}

      {displayManualModal && (
        <ImportFromManual
          history={history}
          isEditRoute={true}
          newlyAddedComponentsFromRightSearch={addAssemblyFromLibrary}
          onClose={toggleModal}
        />
      )}
      {displayFileImport && (
        <FileImportModule
          assemblyFlag={true}
          currentlyOpenedCategory={inputsCategoryValue}
          currentlyOpenedCpn={component?.cpn.displayValue}
          displayImportFileFlag={true}
          displayModal={true}
          displayRefDesAndItemNumber={hasRefDes}
          fileType="new_assembly"
          history={history}
          onClose={toggleModal}
          onDone={onFileImportDone}
          showUiAlert={showUiAlert}
        />
      )}
    </>
  );
}

const GridContainer = styled(Box)<{ height: number }>(({ height }) => ({
  display: "flex",
  flex: 1,
  height: `${height}px`,
  // The grid should max out the height at being tall enough to mostly fill the
  // screen when the user scrolls down.
  maxHeight: "calc(100vh - 150px)",
}));

type UpdatePrimaryVariantFunc = (
  newId: string,
  newComp: any, // This is a component in the old format.
  parentId: string,
  variants: AssemblyChildVariant[],
) => void;

interface WrappedVariantModalProps {
  id: string;
  onClose: () => void;
  open: boolean;
  pageItemType: PageItemType;
  primaryVariant?: PrimaryVariantType;
  updatePrimaryVariant: UpdatePrimaryVariantFunc,
}

// TODO: This component should be replaces with a MUI based variant modal.
function WrappedVariantModal(props: WrappedVariantModalProps) {
  const { id, onClose, open, pageItemType, primaryVariant, updatePrimaryVariant } = props;
  const { item: component, variants } = primaryVariant ?? {};

  const history = useHistory();
  const canViewVariants = useCanUserViewVariants();

  const alias = useMemo(() => {
    switch (pageItemType) {
      case PageItemType.COMPONENT:
        return "cmp";
      default:
        return "prd";
    }
  }, [pageItemType]);

  const objectData = useMemo(() => {
    if (!component) return null;

    return {
      ...component,
      _id: component.id,
      fixedPrimaryByVendor: Utils.isVendorCmp(Utils.getVendor(component)),
      parent: { _id: id, alias },
      variantGroup: component.variantGroup?.id,
      variants,
    };
  }, [alias, component, id, variants]);

  const permittedVariants = useMemo(() => {
    if (!variants) return [];

    return variants.map(variant => ({
      ...variant,
      variant: variant.variant?.id,
    }));
  }, [variants]);

  return (
    <>
      {open && objectData && canViewVariants && (
        <VariantContainer className="variant-table-holder">
          <VariantModal
            closeVariantModal={onClose}
            compareVariants={null}
            fromAssemblyTab={true}
            getDataFromApi={true}
            history={history}
            objectData={objectData}
            openVariantModal={open}
            permittedVariants={permittedVariants}
            updatePrimaryVariant={updatePrimaryVariant}
          />
        </VariantContainer>
      )}
    </>
  );
}

const VariantContainer = styled(Box)({
  position: "fixed",
  bottom: "3rem",
  right: "3rem",
  zIndex: 5000,
  "& #variant-table": {
    position: "unset",
    "& .bottom-section": {
      float: "unset",
      marginBottom: 0,
      paddingBottom: "1rem",
      "& .button-sections": {
        float: "unset",
        justifyContent: "right",
      },
    },
  },
});
