import { SvgIcon } from "@mui/material";

export const VendorLabelIcon = () => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path d="M5.69 5.049h3.453l2.6 2.592h1.355v.741h-1.355l-2.608 2.593H5.691V5.049m-4.445 0V6.53H4.21v2.963H1.246v1.482H4.21v.007a1.472 1.472 0 0 0 1.467 1.474h4.089l2.592-2.592h2.223V6.16h-2.223l-2.6-2.593H5.691c-.815 0-1.482.667-1.482 1.482H1.246z" />
    </g>
  </SvgIcon>
);
