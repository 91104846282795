import React from 'react';
import InlineIcon   from "../../ui/icon/inline-icon.js";
import BrokenImageIcon from "../../../assets/icons/broken-image-icon";
import AvatarIcon   from "../../../assets/icons/user-avatar"
import Utils        from "../../../modules/utils"

class Image extends React.Component {

  constructor(props, context)
  {
    super(props);
    this.state = {isLoading: true}
  }

  render ()
  {

    const {isLoading}     = this.state;
    const {imgIdentifier, type, defaultImageClass} = this.props;
    const _this = this;
    const onLoadImgTag = "onload-img-" + Utils.generateUniqueId();

	  return(
	  	<React.Fragment>
        <img
          id={onLoadImgTag}
          className="hidden-imp"
          src={this.props.src}
          onLoad={() => {
            if(imgIdentifier)
              document.getElementById(imgIdentifier).classList.remove("hidden")
            _this.setState({isLoading: false});

            // to avoid memory leakage
            // document.getElementById(onLoadImgTag).remove();
          }}
          alt="loading"
        />

        {
          isLoading
            ? (
                type == 'avatar'
                  ? (
                      <InlineIcon><AvatarIcon /></InlineIcon>
                    )
                  :
                    (
                      <div className="table-image-viewer-wrapper">
                        <div className={`table-image-viewer-holder default-img ${defaultImageClass}`}>
                          <InlineIcon className='default-img-icon'>
                              <BrokenImageIcon />
                          </InlineIcon>
                        </div>
                      </div>
                    )
              )

            : ''
  			}

        {
          this.props.isRenderInTable
            ? (
                <div className={"table-image-viewer-holder " + (isLoading ? "hidden-imp" : "")}>
                  <div
                    key={this.props.keyValue}
                    className={this.props.className }
                    style={{backgroundImage: `url("${this.props.src}")`}}
                  >
                  </div>
                </div>
              )
            : (
                <div
                  key={this.props.keyValue}
                  className={this.props.className + (isLoading ? " hidden-imp" : "")}
                  style={{backgroundImage: `url("${this.props.src}")`}}
                  >
                </div>
              )
        }

	    </React.Fragment>
  	)
  }
}

// TODO: Add props validation

export default Image;
