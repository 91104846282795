import                              "./index.css"
import React                        from "react"
import ModalBox from "../../../ui/modal-box/"
import SandboxSrc                   from "../../../../assets/icons/sandbox-icon.js"
import CompanySrc                   from "../../../../assets/icons/company-icon.js"
import InlineIcon                   from "../../../ui/icon/inline-icon.js"
import Utils                        from "../../../../modules/utils"
import { Helmet } from "react-helmet"
import Link                         from "../../../ui/link"

export class LibraryModal extends React.Component
{
    constructor(props, context) {
        super(props, context);
    }

    getSampleProduct = () => {
        this.props.getSampleProduct("drone")
    }

    render()
    {
        const { skipTutorial, onBack } = this.props
        return (
            <div className="library-modal-holder">
                <Helmet>
                    <title>{Utils.makeTitle("Dashboard")}</title>
                </Helmet>
                <ModalBox hideCrossIcon={true} onClose={skipTutorial} className="library-modal">
                    <div className="library-contianer">
                        <div className="library-content-holder">
                            <div className="heading-holder">
                                <h1>Let’s Get Started</h1>
                                <p>Time to Become a Pro</p>
                                <h4>Your account has two library environments.</h4>
                            </div>

                            <div className="library-holder" >
                                <div className="personal-library" onClick={this.getSampleProduct}>
                                    <div className="inner-holder">
                                        <InlineIcon><SandboxSrc/></InlineIcon>
                                        <h3>Private Sandbox</h3>
                                        <p>A place to practice. Only you have access to this library, and it comes pre-populated with a demo product.</p>
                                        <div className="btn-holder">
                                            <button className="tutorial-btn">PRIVATE SANDBOX</button>
                                        </div>
                                    </div>
                                    <h4 className="recommended-text">(Recommended)</h4>
                                </div>

                                <div className="personal-library" onClick={skipTutorial}>
                                    <InlineIcon><CompanySrc/></InlineIcon>
                                    <h3>Company Account</h3>
                                    <p>The primary library of your company data. All company account users have access to this data.</p>
                                    <div className="btn-holder">
                                         <button className="tutorial-btn">COMPANY ACCOUNT</button>
                                    </div>
                                </div>
                            </div>
                            {onBack && (
                                <Link
                                    className="back-link"
                                    onClick={onBack}
                                    to="/dashboard"
                                >
                                    Back
                                </Link>
                            )}
                        </div>
                    </div>
                </ModalBox>
            </div>
        )
    }
}

export default LibraryModal
