import validator        from "validator"
import Utils            from "../utils"
import API              from "../api"
import Assembly         from "./assembly"
import { schemas }      from '../validations';


const productionInstanceSchema =
{
    documents :
    {
        specs :
        {
            type :
            {
                list()
                {
                    let list = []
                    schemas.product.documents.specs.type.ENUM.forEach((type) => list.push({value:type, displayName: type}))
                    return list
                },

                normalize(value)
                {
                    return value
                },

                validate(value)
                {
                    return ""
                }
            },

            status :
            {
                list(currentStatus='')
                {
                    return Utils.getStatusesList(currentStatus)
                },

                normalize(value)
                {
                    return value
                },

                validate(value, revision)
                {
                    if(value === "" && revision === "") return ""

                    let valid = false

                    this.list().forEach((child) =>
                    {
                        if (child.value === value) { valid = true }
                    })

                    if (valid)
                    {
                        return "";
                    }
                    return "Status is not valid";
                }
            },

            revision :
            {
                normalize(value, status)
                {
                    if(value === "") return ""

                    if(typeof value !== "string") value = ""

                    switch(status.toLowerCase())
                    {
                        case "design" :
                        case "prototype" :
                        {
                            let char1 = value[0] || ""
                            let char2 = value[1] || ""
                            let str   = String(char1 + char2)
                            if(validator.isNumeric(str) && Number(str) < 100 && Number(str) > 0) return str
                            return validator.isNumeric(char1) ? char1 : "1"
                        }

                        default :
                        {
                            let char = (value[0] || "").toUpperCase()
                            return validator.isAlpha(char) ? char.toUpperCase() : "A"
                        }
                    }
                },

                validate(value, status)
                {
                    if(value === "" && status === "") return ""

                    if(value && !status) return "Status must be included with revision"

                    switch(status.toLowerCase())
                    {
                        case "design" :
                        case "prototype" :
                        {
                            return Number(value) < 100 && Number(value) > 0 ? "" : "Invalid revision"
                        }

                        default :
                        {
                            if (isNaN(value) )
                            {
                                return validator.isAlpha(value.toUpperCase()) ? "" : "Invalid Letter"
                            }
                            return "Invalid Letter"
                        }
                    }
                }
            }
        }
    },
}

export default productionInstanceSchema

