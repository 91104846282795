import Utils   from "../../utils"

class ProductionBuildAPI
{
    static create(data, cb)
    {
        Utils.post("/production/build", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static isProductionBuildExist(data, cb)
    {
        let { parentId } = data
        Utils.get(`/production/build/exist?parentId=${parentId}`, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }
}

export default ProductionBuildAPI
