import { useMemo } from "react";

/**
 * Generates the test ID used for the `data-testid` attribute on the html elements so that the
 * automated tests can find elements in the DOM.
 *
 * @param args A list of strings or numbers to combine into the test ID.
 * @returns A string that can be used as the test ID.
 */
export function useDataTestId(...args: (string | number)[]) {
  return useMemo(() => `duro-${args.join("-")}`, [args]);
}
