import React, {Component} from "react";
import Utils              from "../../../../../modules/utils";
import Schemas            from "../../../../../modules/schemas";

class ProcurementEdit extends Component
{
    constructor(props)
    {
        super(props);
    }

    render()
    {
        let {onInputChange, inputs, isVendorCmp, vendorLabel} = this.props;
        let {procurement} = inputs;
        return (
            <div className="inner-info values-to-right diff-procurement-section">
                <span className="inner-attribute specs">procurement</span>
                {
                    (isVendorCmp && vendorLabel === "VALISPACE")
                    ?
                    <div className="inner-value" data-tip={this.props.tooltipText} data-for="procurement-input">
                        <input
                            type="text"
                            name="vendor-procurement"
                            value={procurement}
                            data-place="right"
                            data-type="error"
                        />
                    </div>
                    :
                    <select
                        className="inner-value have-input"
                        data-tip="procurement"
                        data-for="procurement-input"
                        name="procurement"
                        value={procurement}
                        onChange={(e) => onInputChange(e)}
                    >
                        {Utils.toOptions(Schemas.component.procurement.list())}
                    </select>
                }
            </div>
        )
    }
}

export default ProcurementEdit;
