import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="16"
      viewBox="0 0 20 16"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#888" stroke="#18171D" transform="translate(-1126 -342)">
          <g transform="translate(1102 80)">
            <g transform="translate(25 260)">
              <g transform="translate(0 3)">
                <path
                  fillRule="nonzero"
                  strokeWidth="0.75"
                  d="M1.011 8.625C.445 8.625 0 9.105 0 9.715c0 .609.445 1.088 1.011 1.088h2.346v1.786c0 .61.445 1.09 1.012 1.09h2.184c3.155 0 5.784-2.484 6.229-5.75h4.207c.566 0 1.011-.48 1.011-1.09 0-.61-.445-1.089-1.011-1.089h-4.167C12.337 2.483 9.748 0 6.593 0H4.37c-.567 0-1.012.48-1.012 1.089v1.786H1.011C.445 2.875 0 3.355 0 3.964c0 .61.445 1.09 1.011 1.09h2.346v3.571H1.011zM5.3 2.135h1.213c2.387 0 4.328 2.09 4.328 4.66 0 2.57-1.941 4.662-4.328 4.662H5.3V2.134z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
