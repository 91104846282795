export function getInitialState()
{
    return {
        component: null,
        revision: null,
        backgroundStyle :
        {
            backgroundImage : ""
        },
        documents_loaded:false,
        children_loaded: false,
    }
}

let initialState = getInitialState()
export default initialState
