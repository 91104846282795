import { takeLatest, all, put, select, cps, call }    from 'redux-saga/effects'
import SEARCH                                   from "../../action-types/search"
import ITEM_ASSEMBLY                            from "../../action-types/item-assembly"
import API                                      from "../../modules/api"
import Utils                                    from "../../modules/utils"
import Query                                    from "../../modules/query"
import buildAction                              from "../../helpers/buildAction"
import UI                                       from '../../action-types/ui'

import { client } from "graphql/apolloClient"
import { refetchComponents } from "graphql/query/componentQueries"
import { refetchProducts } from "graphql/query/productsQueries"

export const getSearch = state => state.search

export function* getSearchData (action){
    yield put(buildAction(SEARCH.SET_LOADING, { loading: true }));
    const search = yield select(getSearch)
    let query = search.query.object
    query.lean = search.lean
    query.limit = -99
    let response = {results: [], count: 0}
    try{

        yield put(buildAction(SEARCH.UPDATE_SEARCH_STATE, search));
        yield call(Utils.delay)

        response = yield cps(API.search, query, search.page, search.sort);
        search.loading = false
        search.results = response.results || []
        search.count   = response.count   || 0
        search.integrations = response.integrations || []
        if (action && action.payload && action.payload.recordSearch)
        {
            let {recordSearch} = action.payload
            if (recordSearch.searchString && recordSearch.searchString.trim())
            {
                let data = {
                    searchString: recordSearch.searchString,
                    searchSource: recordSearch.searchSource,
                    resultsCount: search.count
                }
                API.services.recordSearch(data)
            }
        }
        if (query.type === "prd")
        {
            yield put(buildAction(ITEM_ASSEMBLY.SET_PRODUCT_LIST, {productList: response.results}));
        }

        if (search.value.trim() !== "type:cmp" || search.results.length) {
            yield put(buildAction(SEARCH.UPDATE_SEARCH_STATE, search)); 
        }
    }
    catch(err){
        yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}));
    }
}

export function* changeSearchType (action){

    let state = action.payload.state
    let value  = state.query
    let query  = Query.parse(value)
    let string = Query.stringify(query)

    const payload = {
      value: value,
      lean: true,
      query: {
        string: string,
        object: query
      }
    }

    try{
        yield put(buildAction(SEARCH.RESET_STATE, payload));
        yield put(buildAction(SEARCH.GET_SEARCH_DATA));
    }
    catch(err){
        yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}));
    }
}

export function* deleteItems (action)
{
    yield put(buildAction(UI.START_PROCESSING))
    try
    {
        let errors = []
        for (let cmp of action.payload.components ?? [])
        {
            try
            {
               yield cps(API.components.deleteById, cmp._id, {})
            }
            catch(err)
            {
                errors.push({cpn: cmp.cpn, message: err.errors[0].message})
            }
        }

        for (let prd of action.payload.products ?? [])
        {
            try
            {
                yield cps(API.products.deleteById, prd._id, {})
            }
            catch(err)
            {
                errors.push({cpn: prd.cpn, message: err.errors[0].message})
            }
        }
        if (action.payload.components?.length) {
            yield call(refetchComponents, client, action.payload.components?.map(cmp => cmp._id));
        }
        else {
            yield call(refetchProducts, client, action.payload.products?.map(prd => prd._id));
        }
        if (errors.length !== 0) yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: errors, donotThrowError: true}))
        if (errors.length < (action.payload.components?.length ?? 0) + (action.payload.products?.length ?? 0)) {
            yield call(getSearchData);
        }
    }
    catch(err)
    {
        yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}))
    }

    yield put(buildAction(UI.END_PROCESSING))
}

export function* revertItems(action)
{
    yield put(buildAction(UI.START_PROCESSING))
    try
    {
        let errors = []
        for (let cmp of action.payload.components ?? [])
        {
            if(!cmp.revisions) cmp = yield cps(API.components.findById, cmp._id);
            const componentRevisionId = getRevisionId(cmp.revisions);
            try
            {
                const previousComponent = yield cps(API.componentRevision.findById, componentRevisionId);
                const data = getData(cmp, previousComponent, "cmp");
                const revised_component = yield cps(API.components.update, cmp._id, data);
            }
            catch(err)
            {

                errors.push({cpn: cmp.cpn, name: cmp.name, message: err.errors[0].message})
            }
        }

        for (let prd of action.payload.products ?? [])
        {
            if(!prd.revisions) prd = yield cps(API.products.findById, prd._id);
            let productRevisionId = getRevisionId(prd.revisions);
            try
            {
                const previousProduct = yield cps(API.productRevision.findById, productRevisionId);
                const data = getData(prd, previousProduct, "prd")
                const revised_product = yield cps(API.products.update, prd._id, data)
            }
            catch(err)
            {
                errors.push({cpn: prd.cpn, name: prd.name, message: err.errors[0].message})
            }
        }

        if (action.payload.components?.length) {
            yield call(refetchComponents, client, action.payload.components?.map(cmp => cmp._id));
        }
        else {
            yield call(refetchProducts, client, action.payload.products?.map(prd => prd._id));
        }

        if (errors.length !== 0) yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: errors, donotThrowError: true}))
        if (errors.length < action.payload.components?.length ?? 0 + action.payload.products?.length ?? 0) {
            yield call(getSearchData);
        }
    }
    catch(err)
    {
        yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}))
    }

    yield put(buildAction(UI.END_PROCESSING))
}

export function* duplicateItems (action)
{
    yield put(buildAction(UI.START_PROCESSING))
    const search = yield select(getSearch)
    try
    {
        let errors = []
        search.selectedSearchResults = []
        for (let cmp of action.payload.components ?? [])
        {
            try
            {
                const duplicate = yield cps(API.components.createDuplicate, {id: cmp._id, fromLibrary: true});
                search.selectedSearchResults.push({alias: 'cmp', _id: duplicate});

            }
            catch(err)
            {
                errors.push({cpn: cmp.cpn, message: err.errors[0].message + " " + "(" + cmp.name + ")"})
            }
        }

        for (let prd of action.payload.products ?? [])
        {
            try
            {
                const duplicate = yield cps(API.products.createDuplicate, {id: prd._id, fromLibrary: true});
                search.selectedSearchResults.push({alias: 'prd', _id: duplicate})
            }
            catch(err)
            {
                errors.push({cpn: prd.cpn, message: err.errors[0].message + " " + "(" + prd.name + ")"})
            }
        }
    
        if (action.payload.components?.length) {
            yield call(refetchComponents, client, action.payload.components?.map(cmp => cmp._id));
        }
        else {
            yield call(refetchProducts, client, action.payload.products?.map(prd => prd._id));
        }

        if (errors.length !== 0)
            yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: errors, donotThrowError: true}))
        if ( errors.length === 0 ||
            (errors.length > 0 && errors.length < (action.payload.components.length + action.payload.products.length  )))
        {
            yield put(buildAction(SEARCH.UPDATE_SEARCH_STATE, search));
            yield call(getSearchData)
        }
    }
    catch(err)
    {
        yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: err.errors, err: err}))
    }

    yield put(buildAction(UI.END_PROCESSING))

}

function getData(currentItem, previousData, itemType)
{
    let newItem = Utils.clone(currentItem)

    newItem.name               = previousData.name
    newItem.revision           = previousData.revision
    newItem.status             = previousData.status
    newItem.eid                = previousData.eid
    newItem.description        = previousData.description
    newItem.children           = previousData.children
    newItem.manufacturers      = previousData.manufacturers
    newItem.modified           = false
    newItem.images             = previousData.images
    newItem.documents          = previousData.documents
    newItem.cpn                = previousData.cpn
    newItem.cpnVariant         = previousData.cpnVariant
    newItem.previousStatus     = previousData.status
    newItem.revertFlag         = true
    newItem.previousRevision   = previousData.revision
    
    if(itemType === "cmp")
    {
        newItem.category           = previousData.category
        newItem.specs              = previousData.specs
        newItem.mass               = previousData.mass
        newItem.unitOfMeasure      = previousData.unitOfMeasure
    }

    if(itemType === "prd")
    {
        newItem.forecasts          = previousData.forecasts
        newItem.team               = previousData.team
    }
    
    newItem.revisions.pop();
    return newItem
}

function getRevisionId(revisionsArr)
{
    let revisionId;
    if(revisionsArr.length > 1)
    {
        revisionId = revisionsArr[revisionsArr.length - 2];
    }
    else
    {
        revisionId = revisionsArr[revisionsArr.length - 1];
    }

    if(typeof(revisionId) === 'object')
    {
        revisionId = revisionId._id;
    }
    return revisionId;
}

export default function*(getState)
{
    yield all([
        takeLatest(SEARCH.GET_SEARCH_DATA, getSearchData),
        takeLatest(SEARCH.CHANGE_SEARCH_TYPE, changeSearchType),
        takeLatest(SEARCH.DELETE_ITEMS, deleteItems),
        takeLatest(SEARCH.REVERT_ITEMS, revertItems),
        takeLatest(SEARCH.DUPLICATE_ITEMS, duplicateItems)
        ]);
}
