import { Box, SelectChangeEvent, styled } from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import { Select, Switch } from "common/components/inputs";
import { InputFieldVariants } from "common/constants";
import { User } from "common/models/user";
import { colorPalette } from "theme";
import InlineIcon from "v1/components/ui/icon/inline-icon";
import Permissions from "v1/modules/schemas/permissions";
import { Header, HeadingText, IconWrapper, Wrapper } from "../integrations";
import LockCloseIcon from "../../../../../assets/icons/lock-close.js";
import LockOpenIcon from "../../../../../assets/icons/lock-open.js";

const options = ["PDM One", "Solidworks PDM"];
const selectComponentStyles = { maxWidth: "22.625rem" };
export const titleStyle = {
  color: colorPalette.taupeGray,
  fontSize: "0.875rem",
};

export const AccountSettings: FC<{ user: User }> = ({ user }) => {
  const [pdmType, setPdmType] = useState("");
  const [isPdmSectionEnable, setIsPdmSectionEnable] = useState(false);

  const onPdmTypeChange = useCallback((event: SelectChangeEvent) => (
    setPdmType(event.target.value)
  ), []);

  const onTogglePdm = useCallback(() => (
    setIsPdmSectionEnable(!isPdmSectionEnable)
  ), [isPdmSectionEnable]);

  const { isDisabled, Icon, label, tooltipText } = useMemo(() => {
    const actionAllowed = Permissions.can("edit", "configurations", user);
    return {
      Icon: actionAllowed ? LockOpenIcon : LockCloseIcon,
      isDisabled: !actionAllowed,
      label: isPdmSectionEnable ? "On" : "Off",
      tooltipText: actionAllowed ? "" : "Administrator access only",
    };
  }, [isPdmSectionEnable, user]);

  return (
    <Wrapper display="grid">
      <GridWrapper>
        <GridParent>
          <FirstItem>
            <Header>
              <IconWrapper>
                <InlineIcon // Using this for consistency with older sections on the page
                  tooltip={tooltipText}
                >
                  <Icon />
                </InlineIcon>
              </IconWrapper>
              <HeadingText>
                PDM
              </HeadingText>
            </Header>
            <Select
              componentStyles={selectComponentStyles}
              disabled={isDisabled || !isPdmSectionEnable}
              displayEmpty={true}
              isRequired={false}
              label="Select PDM Type"
              onChange={onPdmTypeChange}
              options={options}
              placeholder="Select"
              titleStyle={titleStyle}
              value={pdmType}
              variant={InputFieldVariants.OUTLINED}
            />
          </FirstItem>
          <SecondItem>
            <Switch
              disabled={isDisabled}
              label={label}
              labelStyle={titleStyle}
              onChange={onTogglePdm}
            />
          </SecondItem>
        </GridParent>
      </GridWrapper>
    </Wrapper>
  );
};

const GridWrapper = styled(Box)({
  flex: "1",
});

const SecondItem = styled(Box)({
  flexBasis: "60%",
});

const FirstItem = styled(Box)({
  flexBasis: "40%",
});

const GridParent = styled(Box)({
  display: "flex",
});
