import                                   "./index.css";
import                                   "react-datepicker/dist/react-datepicker.css";
import React, {Component}               from "react";
import DatePicker                       from "react-datepicker";
import { getLegacyCpnConfig } from "utils/cpn"
import moment                           from "moment";
import { connect } from "react-redux";
import PRODUCT                          from "../../../../action-types/product";
import ITEM_ASSEMBLY                    from "../../../../action-types/item-assembly";
import UI                               from "../../../../action-types/ui";
import buildAction                      from "../../../../helpers/buildAction";
import Link                             from "../../../ui/link";
import ImageViewer                      from "../../../ui/image-viewer-dynamic";
import Loading                          from "../../../ui/inner-loading";
import AddRowIcon                       from "../../../../assets/icons/add-row";
import EditIcon                         from "../../../../assets/icons/edit";
import DeleteIcon                       from "../../../../assets/icons/cross-icon";
import Schemas                          from "../../../../modules/schemas";
import Utils                            from "../../../../modules/utils";
import AssemblySearchMenu               from "../../common/assembly/edit/modules/assembly-search-menu";
import Tabs                             from "../../common/tabs/edit";
import SourcingUtils                    from "../../common/sourcing/sourcing-utils";
import FileImportModule                 from "../../component/new/file/file-import-module";
import validations, {validateField}     from "../../../../modules/validations";
import ModalBox                         from "../../../ui/modal-box";
import ReactTooltip                     from 'react-tooltip';
import $, { type }                                from "jquery";
import AlertErrorIcon                   from "../../../../assets/icons/alert-error";
import WarningTriangleIcon              from "../../../../assets/icons/warning-triangle";
import ItemScheduleIcon                 from "../../../../assets/icons/item-schedule-icon";
import { Prompt }                       from 'react-router';
import WarningModal                     from "../../../ui/warning-modal";
import {Helmet}                         from "react-helmet";
import LazyInput                        from "../../../ui/lazy-input/input.js";
import TextArea                         from "../../../ui/lazy-input/textarea.js";
import Actions                          from "./actions.js";
import DISPLAYSIDEBAR                   from "../../../../action-types/display-sidebar";
import Tooltip                          from 'rc-tooltip';
import InlineIcon                       from "../../../ui/icon/inline-icon.js";
import LinkIcon                         from "../../../../assets/icons/open-link-icon.js";
import UpdateStatusModal                from "../../common/update-status-modal";
import URLSearchParams                  from "url-search-params";
import UpdateRevisionModal              from "../../common/update-revision-modal";
import ImportFromVendor                 from "../../component/new/web/index.js";
import ImportFromManual                 from "../../component/new/manual/index.js";
import Config                           from "../../../../modules/config";
import ExpertForecastPopover            from "../../common/expert-forecast-popover";
import StatusUpdateModalForNonAssembly  from "../../common/update-status-modal-for-non-assembly";
import NumberFormat                     from 'react-number-format';
import RevisionField                    from "../../common/revision-field";
import LastUpdatedField                 from "../../common/last-modified-field";
import ErrorNotification                from "../../common/error-notification";
import CurrencyField                    from "../../common/currency-field";
import TimeStampColumn                  from "../../common/timestamp-column";
import TileItem                         from "../../common/tile-item";
import ItemDetailsIcon                  from "../../../../assets/icons/item-details-icon";
import ItemTeamIcon                     from "../../../../assets/icons/item-team-icon";
import WebSrcIcon                       from "../../../../assets/icons/newcomp-web";
import ItemScheduleMassIcon             from "../../../../assets/icons/item-schedule-mass-icon";
import PerfectScrollbar                 from "react-perfect-scrollbar";
import ImageViewerTile                  from "../../../ui/image-viewer-tile";
import ExtendedRolledUpCost             from "../../common/extended-rolled-up-cost";
import UnitPrice                        from "../../common/unit-price";
import CpnEditField                     from "../../common/cpn-edit-field";
import UpdateRevisionNonAssembly        from "../../common/update-revision-non-assembly-modal";
import ItemERP                          from "../../common/specifications/item-erp-view";
import { EditTabsContextProvider } from "design/pages/common/editTabsContextProvider";
import { ItemCustomSpecs } from "../../common/specifications/item-custom-specs";

export class EditProduct extends Component
{
    constructor(props, context)
    {
        super(props, context)
        this.componentSearch                      = this.componentSearch.bind(this)
        this.clearComponentSearch                 = this.clearComponentSearch.bind(this)
        this.onDateChange                         = this.onDateChange.bind(this)
        this.onInputChange                        = this.onInputChange.bind(this)
        this.addForecast                          = this.addForecast.bind(this)
        this.remove                               = this.remove.bind(this)
        this.getData                              = this.getData.bind(this)
        this.onSubmit                             = this.onSubmit.bind(this)
        this.onKeyPress                           = this.onKeyPress.bind(this)
        this.handleCancelEvent                    = this.handleCancelEvent.bind(this)
        this.uploadImage                          = this.uploadImage.bind(this)
        this.onDone                               = this.onDone.bind(this)
        this.clearNewlyCreatedComponentsFromState = this.clearNewlyCreatedComponentsFromState.bind(this)
        this.hideAssemblyModals                   = this.hideAssemblyModals.bind(this)
        this.newlyAddedComponentsFromRightSearch  = this.newlyAddedComponentsFromRightSearch.bind(this)
        this.clearNewlyAddedComponentsFromState   = this.clearNewlyAddedComponentsFromState.bind(this)
        this.onAcceptStatusChange                 = this.onAcceptStatusChange.bind(this)
        this.onRejectStatusChange                 = this.onRejectStatusChange.bind(this)
        this.dispalyErrorModal                    = this.dispalyErrorModal.bind(this)
        this.onPrimarySourceChange                = this.onPrimarySourceChange.bind(this)
        this.onPrimarySourceDeselect              = this.onPrimarySourceDeselect.bind(this)
        this.setPrimarySourceInObject             = this.setPrimarySourceInObject.bind(this)
        this.syncPrimaryQuoteInputWithData        = this.syncPrimaryQuoteInputWithData.bind(this)
        this.onRolledUpSelect                     = this.onRolledUpSelect.bind(this)
        this.onRolledUpInputChanged               = this.onRolledUpInputChanged.bind(this)
        this.onModalExternalClick                 = this.onModalExternalClick.bind(this)
        this.showModal                            = this.showModal.bind(this)
        this.closeModal                           = this.closeModal.bind(this)
        this.handleBlockedNavigation              = this.handleBlockedNavigation.bind(this)
        this.handleConfirmNavigationClick         = this.handleConfirmNavigationClick.bind(this)
        this.lastClickedTarget                    = null
        this.syncRolledUpCostTable                = this.syncRolledUpCostTable.bind(this)
        this.afterSyncWithRolledUpCost            = this.afterSyncWithRolledUpCost.bind(this)
        this.blockNavigation                      = this.blockNavigation.bind(this)
        this.hideUpdateStatusModal                = this.hideUpdateStatusModal.bind(this)
        this.refreshAfterBulkUpdate               = this.refreshAfterBulkUpdate.bind(this)
        this.getProductFromAPI                    = this.getProductFromAPI.bind(this)
        this.showHideSaveAsRevisionModal          = this.showHideSaveAsRevisionModal.bind(this)
        this.toggleModal                          = this.toggleModal.bind(this);
        this.disablePrimarySourceBtn              = this.disablePrimarySourceBtn.bind(this);
        this.enablePrimarySourceBtn               = this.enablePrimarySourceBtn.bind(this);
        this.displayExpertForecastPopover         = this.displayExpertForecastPopover.bind(this)
        this.hideExpertForecastPopover            = this.hideExpertForecastPopover.bind(this)
        this.handleProcurement                    = this.handleProcurement.bind(this);
        this.toggleErrorCount                     = this.toggleErrorCount.bind(this);
        this.toggleAssemblyEditingState           = this.toggleAssemblyEditingState.bind(this);
        this.calculateAssemblyErrors              = this.calculateAssemblyErrors.bind(this);
        this.getErrorsWarningMsg                  = this.getErrorsWarningMsg.bind(this);
        this.getTotalErrorsCount                  = this.getTotalErrorsCount.bind(this);
        this.setRevisionInDesignStatus            = this.setRevisionInDesignStatus.bind(this);
        this.revisionInputChange                  = this.revisionInputChange.bind(this)

        this.allowedElementsForClick              = ["sign out", "private sandbox", "company account", "exit sandbox"]
        this.forecastScheme = window.__libraryType === "GENERAL" && window.__buildScheduleScheme === "EXPERT" ? "EXPERT" : "DEFAULT"

        this.state =
        {
            displayExpertForecastPopover        : false,
            isAddedClickListener                : false,
            showFileImportModal                 : false,
            newlyCreatedComponent               : null,
            newlyAddedComponentsFromRightSearch : null,
            actionType                          : "new",
            primarySource                       : {},
            productPrimarySource                : {},
            revisionInput:
            {
                valid: false
            },
            modalVisible: false,
            lastLocation: null,
            confirmedNavigation: false,
            showTrackStatusModal: true,
            showErrormodal: true,
            showErrorModalAfterBulk: true,
            showSaveAsRevisionModal: false,
            revisionComment: "",
            displayVendorModal: false,
            displayManualModal: false,
            displayFileImport: false,
            cmpId:null,
            showMassWarningIcon: false,
            showMassErrorIcon: true,
            procurement: "N/A",
            errorsCount: {
                assemblyTab: 0,
                sourcingTab: 0,
                documentTab: 0
            },
            isAssemblyEditing: false,
            cpnType: window.__currentCompanyCpnType, 
            isCpnVariantEditable: false,
            isCpnVariantScheme: false,
        }
    }

    displayExpertForecastPopover()
    {
        this.setState({displayExpertForecastPopover: true})
    }

    hideExpertForecastPopover()
    {
        this.setState({displayExpertForecastPopover: false})
    }

    toggleModal(modalName, modalValue)
    {
        if (modalValue === true)
        {
            this.setState({[modalName]: modalValue, cmpId: null});
        }
        else
        {
            this.setState({[modalName]: modalValue});
        }
    }

    showModal(location)
    {
        this.setState({modalVisible: true, lastLocation: location})
    }

    showHideSaveAsRevisionModal(value)
    {
        let revisionValue = this.props.product && this.props.product.product &&this.props.product.product.nextCoRevision || ""
        this.setState({showSaveAsRevisionModal: value, revisionComment: "", revisionInput:{value: revisionValue, valid: true}})
    }

    closeModal(callback, isSkip=false)
    {
        if (isSkip)
        {
           this.setState({modalVisible: false})
           this.lastClickedTarget = null
        }
        else
        {
            this.setState({modalVisible: false}, callback)
        }
    }

    handleConfirmNavigationClick()
    {
        this.closeModal(() =>
        {
            const {lastLocation} = this.state
            const lastClickedTarget = this.lastClickedTarget

            if (lastLocation)
            {
                this.setState({confirmedNavigation: true}, () => this.props.history.push({pathname: lastLocation.pathname, state: lastLocation.state}))
            }
            else if (lastClickedTarget)
            {
                let targetElementText = lastClickedTarget.innerText && lastClickedTarget.innerText.toLowerCase()
                if (this.allowedElementsForClick.includes(targetElementText))
                {
                    lastClickedTarget.click()
                }
                else if (!!lastClickedTarget.closest(".onboarding-setp-item"))
                {
                    lastClickedTarget.closest(".onboarding-setp-item").click()
                }
                else if (!!lastClickedTarget.closest(".exit-sandbox-btn"))
                {
                    lastClickedTarget.closest(".exit-sandbox-btn").click()
                }
            }

        })
    }

    handleBlockedNavigation(nextLocation)
    {
        const {confirmedNavigation} = this.state
        const {shouldBlockNavigation} = this.state
        const lastClickedTarget = this.lastClickedTarget

        if (!confirmedNavigation && shouldBlockNavigation && !lastClickedTarget)
        {
            this.showModal(nextLocation)
            return false
        }
        return true
    }

    onModalExternalClick(event)
    {
        let target     = event.target
        let parent     = this.refs.productForm
        let isExternal = target !== parent && !Utils.isDescendant(parent, target)
        let state = this.state
        let targetElementText = event.target.innerText && event.target.innerText.toLowerCase()

        if  (
                !isExternal && event.target.nodeName === "A" && event.target.innerText === "CANCEL" ||
                event.target.classList.contains("delete_btn") && event.target.value === "CANCEL"
            )
        {
            state.shouldBlockNavigation = false
            this.setState(state)
        }

        if  (
                isExternal && !this.lastClickedTarget &&
                (
                    (!!event.target.closest(".navigation") && this.allowedElementsForClick.includes(targetElementText)) ||
                    (!!event.target.closest(".onboarding-setp-item")) ||
                    (!!event.target.closest(".exit-sandbox-btn")) ||
                    (!!event.target.closest(".help-menu-actions") && (this.allowedElementsForClick.includes(targetElementText) || this.allowedElementsForClick.includes(targetElementText)))
                )
            )
        {
            event.preventDefault();
            event.stopPropagation();
            this.lastClickedTarget = event.target
            this.showModal()
        }
    }

    newlyAddedComponentsFromRightSearch(newlyAddedComponents, cmpId=null)
    {
        let state = this.state;
        state.newlyAddedComponentsFromRightSearch = newlyAddedComponents;
        state.cmpId = cmpId;
        this.setState(state);
    }

    clearNewlyAddedComponentsFromState()
    {
        let state = this.state
        state.newlyAddedComponentsFromRightSearch = null
        this.setState(state)
    }

    componentDidUpdate()
    {
        this.forecastScheme = window.__libraryType === "GENERAL" && window.__buildScheduleScheme === "EXPERT" ? "EXPERT" : "DEFAULT"
        if (this.state.shouldBlockNavigation)
        {
            window.onbeforeunload = () => true
        }
        else
        {
            window.onbeforeunload = undefined
        }
    }

    componentWillReceiveProps(nextProps)
    {
        let { isNewObject }  = nextProps.product
        let { modified, childrenModified } = nextProps
        let state = this.state

        let urlParams = new URLSearchParams(window.location.search)
        let status    = urlParams.has('status') ? urlParams.get('status') : null
        let revision  = urlParams.has('revision') ? urlParams.get('revision') : null
        let refer  = urlParams.has('refer') ? urlParams.get('refer') : null

        if(state.showTrackStatusModal)
        {
            if(status && this.props.product)
            {
                state.trackStatusChanged     = true
                state.newStatusValue         = status
                state.statusFieldPreviousValue = this.props.product.status
                state.revisionInput.valid     = true
            }
            if(revision && this.props.product)
            {
                state.revisionInput.value    = revision


                validateField(state.revisionInput, validations.product.revision, revision.toUpperCase(), {status: state.newStatusValue, revSchemeType: window.__revSchemeType, libraryType: window.__libraryType, isClient: true,})
            }
            if(refer === "co" && this.props.product && this.props.product.product)
            {
                state.trackStatusChanged = false;
                this.props.updateEditFormState({
                    value: state.newStatusValue,
                    name: "status"
                })

                this.props.updateEditFormState({
                    value: state.revisionInput.value,
                    name: "revision"
                })
                state.showTrackStatusModal   = false
                this.setState(state)
            }
            if((status || revision) && this.props.product && !refer)
            {
                state.showTrackStatusModal   = false
                this.setState(state)
            }
        }

        if (modified || childrenModified)
        {
            this.blockNavigation()
        }
    }

    componentWillUnmount()
    {
        window.onbeforeunload = undefined;
        document.body.removeEventListener("click", this.onModalExternalClick);
        this.state.shouldBlockNavigation = undefined;
        this.props.clearAssemblySearchState();
        const { resetProductEditForm } = this.props;
        resetProductEditForm();
        let app_row = document.getElementsByClassName('banner-block');
        if(app_row && app_row[0])
        {
            app_row[0].classList.remove("open-right-nav");
        }
        const {hideSidebars} = this.props;
        hideSidebars();
    }

    componentWillUpdate(nextProps, nextState) {
        let updateCpnType;
        if (nextState.currentCompanyCpnType !== window.__currentCompanyCpnType) {
            this.setState({ currentCompanyCpnType: window.__currentCompanyCpnType });
            updateCpnType = true;
        }
        const { company, isNewObject } = this.props;
        if (company !== nextProps.company || isNewObject !== nextProps.isNewObject || updateCpnType) {
            this.setVariantFlags(nextProps);
        }
    }

    componentWillMount()
    {
        let state = this.state;
        const {
          getProductAndSetEditPage,
          updateDisplayAssemblySearch,
          setEditPageModified,
          setNewObjectFlag
         } = this.props;

        this.setVariantFlags(this.props);
        let id = this.props.match.params.id;
        Utils.setLocalStorageForAssemblyTree(id);

        if (this.props.location && this.props.location.showAll){
            id = `${id}?showAll=true&include=children,documents,creator,images`;
        }
        else{
            id = `${id}?include=children,documents,creator,images,revisions`;
        }
        let payload = { id, history: this.props.history, editMode: true };

        getProductAndSetEditPage(payload);
        updateDisplayAssemblySearch(false);

        if (this.props.location?.state?.isNewObject)
        {
            document.body.addEventListener("click", this.onModalExternalClick);
            state.shouldBlockNavigation = true;
            this.setState(state);
            setEditPageModified();
            setNewObjectFlag();
        }
    }

    syncRolledUpCostTable()
    {
        let state = this.state
        state.syncWithRolledUpCost = true
        this.setState(state)
    }

    afterSyncWithRolledUpCost()
    {
        let state = this.state
        state.syncWithRolledUpCost = false
        this.setState(state)
    }

    syncPrimaryQuoteInputWithData(quote){
        let state = this.state
        state.productPrimarySource = quote
        this.setState(state)
    }

    onPrimarySourceChange(checked, quote){
        let state = this.state
        if (checked)
        {
            state.rolledUpCostAsPrimary = false
            state.allowPrimarySourceIcon = true
            state.primarySource = quote
            state.primarySource.allAreValid = true
        }
        else
        {
            state.allowPrimarySourceIcon = false
            state.primarySource = {
                minQuantity  : "",
                unitPrice    : "",
                leadTime     : "",
                leadTimeUnit : "",
                uniqueId     : "dummy_id"
            }
        }
        this.setState(state)
    }

    onRolledUpInputChanged(data)
    {
        let { product }      = this.props.product
        const {updateEditFormState}     = this.props;
        product.rolledUpCost = {mpn: data.mpn, manufacturer: data.manufacturer}
        updateEditFormState();
        this.syncRolledUpCostTable()
    }

    onRolledUpSelect(event, rolledUpCost){
        let state = this.state
        let { company } = this.props
        let { product } = this.props.product;

        if (Object.keys(rolledUpCost).length > 0 && event.target.checked)
        {
            state.allowPrimarySourceIcon = true
            state.rolledUpCostAsPrimary  = true
            state.primarySource =
            {
                minQuantity     : rolledUpCost.minQuantity,
                unitPrice       : rolledUpCost.totalPrice,
                leadTime        : rolledUpCost.leadTimeValue,
                leadTimeUnit    : rolledUpCost.leadTimeUnit,
                allAreValid     : rolledUpCost.allAreValid,

                leadTime     : {
                    value : rolledUpCost.leadTimeValue,
                    units : rolledUpCost.leadTimeUnit
                },
                primarySourceId : "dummy",
                rolledUpCost    : true,
                manufacturer    : (product.rolledUpCost && product.rolledUpCost.manufacturer) ? product.rolledUpCost.manufacturer : company.name,
                mpn             : (product.rolledUpCost && product.rolledUpCost.mpn) ? product.rolledUpCost.mpn : Utils.getCpn(product),
            }
        }

        else
        {
            state.allowPrimarySourceIcon = false
            state.rolledUpCostAsPrimary = false

            state.primarySource = {
                minQuantity  : "",
                unitPrice    : "",
                leadTime     : "",
                leadTimeUnit : "",
                uniqueId     : "dummy_id",
                rolledUpCost    : false
            }
        }

        this.setState(state)
    }

    onPrimarySourceDeselect()
    {
        let { product } = this.props.product
        let inputs      = this.props.product.inputs
        let state       = this.state

        $('.quotes .primary-source-checkbox input:checkbox').prop('checked', false);
        state.allowPrimarySourceIcon  = false
        state.rolledUpCostAsPrimary   = false
        product.rolledUpCostAsPrimary = false
        state.productPrimarySource = {}
        product.primarySource = {
                minQuantity  : "",
                unitPrice    : "",
                leadTime     : {
                    value : '',
                    units : ''
                },
                leadTimeUnit : "",
                uniqueId     : "dummy_id",
                manufacturer : "",
                mpn          : "",
            }

        let manufacturers = inputs.manufacturers.value

        manufacturers.forEach((man, i) =>
        {
            man.isPrimary = false
            man.distributors.forEach((dist, i) =>
            {
                dist.isPrimary = false
                dist.quotes.forEach((quote, i) =>
                {
                    quote.isPrimary = false
                    man.isPrimary = false
                    dist.isPrimary = false
                    if(quote.inputs) quote.inputs.isChecked.value = false;
                }) // end dist.quotes.forEach((quote, i) =>
            }) // end man.distributors.forEach((dist, i) =>
        }) // end manufacturers.forEach((man, i) =>
        state.rolledUpCostAsPrimary = false

        const {updateEditFormState} = this.props;
        updateEditFormState();
        this.setState(state, this.syncRolledUpCostTable)
    }

    setPrimarySourceInObject()
    {

        let { product } = this.props.product
        let inputs      = this.props.product.inputs
        let state       = this.state

        $('.quotes .primary-source-checkbox input:checkbox').prop('checked', false);
        state.allowPrimarySourceIcon  = false
        state.productPrimarySource    = Utils.clone(state.primarySource)
        product.primarySource         = state.productPrimarySource
        product.rolledUpCostAsPrimary = this.state.rolledUpCostAsPrimary

        let quoteUniqueId = state.primarySource.uniqueId

        let manufacturers = inputs.manufacturers.value
        manufacturers.forEach((man, i) =>
        {
            man.isPrimary = false
            if (man.distributors.length > 0)
            {
                man.distributors.forEach((dist, i) =>
                {
                    dist.isPrimary = false
                    if (dist.quotes.length > 0)
                    {
                        dist.quotes.forEach((quote, i) =>
                        {
                            quote.isPrimary = false
                            if (quoteUniqueId === quote.uniqueId && state.rolledUpCostAsPrimary !== true) {
                                man.isPrimary = true
                                dist.isPrimary = true
                                quote.isPrimary = true
                                product.primarySource.manufacturer = man.name;
                                product.primarySource.mpn = man.mpn.key;
                            }

                        }) // end dist.quotes.forEach((quote, i) =>
                    } // if (dist.quotes.length > 0)
                }) // end man.distributors.forEach((dist, i) =>
            } // end if (man.distributors.length > 0)
        }) // end manufacturers.forEach((man, i) =>

        const {updateEditFormState} = this.props;
        updateEditFormState();
        this.setState(state, this.syncRolledUpCostTable)
    }

    componentSearch(query)
    {
        const {searchComponentInEditPage} = this.props;
        searchComponentInEditPage(query)
    }

    handleCancelEvent(event)
    {
        this.props.history.goBack()
    }

    clearComponentSearch()
    {
        const {setEditPageComponentSearch} = this.props;
        setEditPageComponentSearch()
    }

    onDateChange(date, event, i)
    {
        // if (!date) return
        // mock an input event when date is changed
        let name  = "forecastsTarget"
        let value = date && new Date(date.toISOString()).getTime()
        this.onInputChange({target: {name: name, value: Number(value)}}, i)
    }

    blockNavigation()
    {
        let state = this.state

        if (!state.isAddedClickListener)
        {
            document.body.addEventListener("click", this.onModalExternalClick)
            state.shouldBlockNavigation = true
            state.isAddedClickListener = true
            this.setState(state)
        }
    }

    onInputChange(event, i)
    {
        let state = this.state
        let { modified, childrenModified } = this.props
        this.blockNavigation()
        const {updateEditFormState} = this.props;
        let { product }  = this.props.product
        let inputs       = this.props.product.inputs

        if (event.target.name === "status")
        {
            // let isProcBuy = inputs.procurement === "Buy";
            // state.trackStatusChanged = !isProcBuy;
            state.trackStatusChanged = true
            if (event.target.value === 'DESIGN')
            {
                state.trackStatusChanged = false
                updateEditFormState({
                  value: event.target.value,
                  name: event.target.name
                })

                updateEditFormState({
                  value: (product.status === 'DESIGN' ? product.revision : product.revision),
                  name: "revision"
                })
            }

            state.statusFieldPreviousValue = inputs.status.value
            state.newStatusValue = event.target.value
            // if(isProcBuy)
            // {
            //     state.newStatusValue = "PRODUCTION";
            // }
            let isClient = true
            let previousRevision = Utils.getPreviousRevision(product);
            let {revisions} = this.props.product.product
            let validationPayload = {status: state.newStatusValue, revSchemeType: window.__revSchemeType, libraryType: window.__libraryType, isClient: true, revisions, revActionType: null, previousRevision, isClient, defaultBlacklistedRevisions: window.__defaultBlacklistedRevisions}
            let revValue = validations.product.revision.normalize(validationPayload, inputs.revision.value).revision
            validateField(state.revisionInput, validations.product.revision, revValue.toUpperCase(), validationPayload)
            this.setState(state)
        }
        else if (event.target.name === "forecastVolume")
        {
            updateEditFormState({
                value: event.target.value.replaceAll(',', ''),
                name: event.target.name,
                index: i,
                cpnType: state.cpnType
            })
        }

        else
        {
            updateEditFormState({
              value: event.target.value,
              name: event.target.name,
              index: i,
              cpnType: state.cpnType
            })
        }

        if (event.target.name === "images")
        {
            this.uploadImage(event, i)
        }

        if (event.target.name === "mass")
        {
            inputs.mass.value           = validations.product.mass.normalize(inputs.mass.value);
            state.showMassWarningIcon   = true;
            state.showMassErrorIcon     = false;
            this.setState(state)
        }
    }

    uploadImage(images, errors, isUploadedThumbnails)
    {
        let state = this.state
        if (!state.isAddedClickListener)
        {
            document.body.addEventListener("click", this.onModalExternalClick)
            state.shouldBlockNavigation = true
            state.isAddedClickListener = true
            this.setState(state)
        }

        const {uploadImages} = this.props;
        uploadImages({
            images: images,
            thumbnailUploaderrors: errors,
            isUploadedThumbnails: isUploadedThumbnails
        })
    }

    addForecast(forecastLabelType="")
    {
        const {addNewForecast} = this.props
        addNewForecast({forecastType: this.forecastScheme, forecastLabelType})
    }

    remove(i)
    {
        const {removeForecast} = this.props
        removeForecast({index: i, forecastType: this.forecastScheme})
    }

    onAcceptStatusChange(showErrorModalAfterBulk=true){
        const {updateEditFormState} = this.props;
        let state = this.state

        updateEditFormState({
            value: state.newStatusValue,
            name: "status"
        })

        updateEditFormState({
          value: state.revisionInput.value,
          name: "revision"
        })

        state.trackStatusChanged = false
        state.showErrorModalAfterBulk = showErrorModalAfterBulk
        this.setState(state)
    }

    hideUpdateStatusModal()
    {
        this.onRejectStatusChange()
    }

    refreshAfterBulkUpdate(bulkRevisionValue='', bulkStatusValue='', showErrorModalAfterBulk=true)
    {
        window.localStorage.removeItem('lastAssemblyTree')
        window.localStorage.removeItem('lastAssemblyParent')
        this.onAcceptStatusChange(showErrorModalAfterBulk)
        this.getProductFromAPI(bulkRevisionValue, bulkStatusValue)
    }

    getProductFromAPI(bulkRevisionValue, bulkStatusValue)
    {
        const {
          getProductAndSetEditPage
         } = this.props;

        let id = this.props.match.params.id
        if (this.props.location && this.props.location.showAll){
            id = `${id}?showAll=true&include=children,documents,creator,images`;
        }
        else{
            id = `${id}?include=children,documents,creator,images`;
        }
        let payload = { id, history: this.props.history, editMode: true, bulkRevisionValue, bulkStatusValue }
        getProductAndSetEditPage(payload)
    }

    onRejectStatusChange(){
        let state                = this.state
        state.trackStatusChanged = false
        // this.props.product.inputs.procurement = state.procurement;
        this.setState(state)
    }
    
    revisionInputChange(event){
        let value    = event.target.value
        let { product } = this.props.product;
        let state    = this.state
        let status   = this.state.newStatusValue || "DESIGN"
        let revisions = this.props.product.product.revisions
        let revValue = value
        let isSaveAsRev = true
        let previousRevision = Utils.getPreviousRevision(product);
        let validationPayload = {
            status,
            revisions,
            isSaveAsRev,
            revSchemeType: window.__revSchemeType,
            libraryType: window.__libraryType,
            isClient: true,
            previousRevision,
            defaultBlacklistedRevisions: window.__defaultBlacklistedRevisions
        }



        validateField(state.revisionInput, validations.product.revision, revValue.toUpperCase(), validationPayload)
        this.setState(state)
    }

    getData()
    {
        let { product, inputs }       = this.props.product
        let newModifiedValue          = true
        let children                  = this.props.children

        Utils.updatePullRequestProperty(children);
        product.customSpecs = inputs.customSpecs;
        product.name                  = inputs.name.value
        product.revision              = inputs.revision.value
        product.status                = inputs.status.value
        product.eid                   = inputs.eid.value.trim()
        product.description           = inputs.description.value
        product.images                = inputs.images.value
        product.children              = children || []
        product.manufacturers         = inputs.manufacturers.value
        product.documents             = inputs.documents.value
        product.cpnVariant            = inputs.cpnVariant.value
        product.rolledUpCostAsPrimary = product.rolledUpCostAsPrimary
        product.mass                  = inputs.mass.value
        product.procurement           = inputs.procurement;

        const { cpnType, isCpnVariantEditable, isCpnVariantScheme } = this.state;
        if (cpnType === "FREE-FORM") {
            product.cpn = inputs.cpn.value;
        }
        if (isCpnVariantScheme && cpnType !== "CPN_RULES") {
            const cpnSplit = product.cpn.split("-");
            product.cpn = cpnSplit[0] + "-" + cpnSplit[1];
            if (cpnType === "CUSTOM-CODE-WITH-11-DIGIT") {
                product.cpn += "-" + cpnSplit[2];
            }
        }
        if (isCpnVariantEditable) {
            product.cpnVariant = inputs.cpnVariant.value;
        } 

        if (!inputs.inputChanged)
        {
            newModifiedValue = false
        }

        if (this.props.childrenModified)
        {
            newModifiedValue = true
        }

        let isNewObject = this.props.product.isNewObject || this.props.location?.state?.isNewObject

        if (product.status === 'DESIGN' || isNewObject)
        {
            newModifiedValue = false
            product.modified = false
        }

        if (isNewObject)
        {
            product.archived = false
        }

        let forecasts = []
        inputs.forecasts.forEach((input) => forecasts.push({ key: input.key.value, targetDate: input.targetDate.value, volume: input.volume.value, counter: input.counter.value}))
        inputs.team.forEach((input) => product.team[input.key] = { email: input.value })
        product.forecasts = forecasts


        if (!product.modified)
        {
            product.modified = newModifiedValue
        }

        let {revisionComment, showSaveAsRevisionModal, revisionInput} = this.state
        if (showSaveAsRevisionModal)
        {
            product.revisionComment = revisionComment
            product.saveAsRevision  = true
            product.revision        = revisionInput.value
        }
        return product
    }

    onKeyPress(event) {
        if (event.target.type !== 'textarea' && event.which === 13 /* Enter */) {
          event.preventDefault();
        }
    }

    onSubmit(event, options=null)
    {
        event && event.preventDefault()
        let { inputs }   = this.props.product
        const { children, history, location, user } = this.props
        let validated = Utils.isValidated(inputs) && Utils.isValidated(children)

        if(!validated)
        {
            return
        }

       let payload =
        {
            data: this.getData(),
           history,
           user: user?.data ?? user,
           showAll: location.showAll,
        }

        if (options)
        {
            payload.data.revisionComment = options.revisionComment
            payload.data.saveAsRevision  = true
            payload.data.revision        = options.revision
        }

        if(this.props.location && this.props.location.isVariant)
        {
            payload.data.originalObjectId = this.props.originalProductId;
            payload.data.isVariant        = this.props.location.isVariant;
        }

        this.props.submitProductForm(payload)
    }

    onDone(newlyCreatedComponents, actionType)
    {
        let state = this.state;
        state.showFileImportModal = false;
        state.newlyCreatedComponent = newlyCreatedComponents;
        state.actionType = actionType === "update_assembly" ? "update" : "new";
        state.displayFileImport = false;
        this.setState(state);
    }

    clearNewlyCreatedComponentsFromState()
    {
        let state = this.state
        state.newlyCreatedComponent = null
        this.setState(state)
    }


    hideAssemblyModals()
    {
        const {dispatch} = this.props;
        this.props.updateDisplayAssemblySearch(false)
        const {hideSidebars} = this.props
        hideSidebars()
    }


    dispalyErrorModal()
    {
        let _this = this
        setTimeout(function()
        {
            const {showUiAlert} = _this.props;
            let urlParams = new URLSearchParams(window.location.search)
            let showModal  = urlParams.has('showModal') ? urlParams.get('showModal') : null

            let payload =
            {
                closeCb: () => {},
                errorType: "custom",
                type: "errors",
                donotThrowError: true,
                errors: [{message: "By switching to revision control Duro validation rules are now active. Any errors found must be corrected before you can continue. If you do not wish to correct these errors now, switch back to design mode before saving."}]
            }
            if(_this.state.showErrormodal && (showModal && showModal === 'false'))
            {
                _this.state.showErrormodal = false
                _this.setState(_this.state)
            }
            else if(!_this.state.showErrorModalAfterBulk)
            {
                _this.state.showErrorModalAfterBulk = true
                _this.setState(_this.state)
            }
            else
            {
                showUiAlert(payload)
            }
        }, 500);
    }

    disablePrimarySourceBtn() {
        let state = this.state
        this.setState({allowPrimarySourceIcon: false});
    }

    enablePrimarySourceBtn() {
        let state = this.state
        this.setState({allowPrimarySourceIcon: true});
    }

    setRevisionInDesignStatus(revisionObject, revisionComment){
        let state = this.state;
        this.showHideSaveAsRevisionModal(false)
        state.revisionInput = revisionObject;
        state.revisionComment = revisionComment;
        this.setState(state);
        this.onSubmit()
    }

    handleProcurement(event)
    {
        let inputs  = this.props.product.inputs;
        // let product = this.props.product.product;
        let state   = this.state;
        // state.procurement = inputs.procurement;
        inputs.procurement = event.target.value;
        // if(inputs.procurement === "Buy" && inputs.status.value !== "PRODUCTION")
        // {
        //     state.trackStatusChanged = true;
        //     state.statusFieldPreviousValue = inputs.status.value;
        //     state.newStatusValue     = "PRODUCTION";
        //     let currentRevision = product.revision;
        //     let {revisions} = product;
        //     let validationPayload = {status: state.newStatusValue, revSchemeType: window.__revSchemeType, libraryType: window.__libraryType, isClient: true, revisions, revActionType: null, currentRevision};

        //     let revValue = validations.product.revision.normalize(validationPayload, inputs.revision.value).revision;
        //     validateField(state.revisionInput, validations.product.revision, revValue.toUpperCase(), validationPayload);
        //     this.setState(state);
        // }
        this.onInputChange(event);
    }

    calculateAssemblyErrors(childrens)
    {
        let assemblyErrors = Utils.calculateAssemblyErrors(childrens);
        let state = this.state;
        state.errorsCount['assemblyTab'] = assemblyErrors;
        this.setState(state);
    }

    toggleErrorCount(valid, tabName, amount = 1) {
        this.setState(({ errorsCount }) => {
            let count = errorsCount[tabName];

            if (!valid) {
                count += amount;
            }
            else {
                count -= amount;
            }

            if (count < 0) {
                count = 0;
            }

            return { errorsCount: { ...errorsCount, [tabName]: count } };
        });
    }

    toggleAssemblyEditingState(newState)
    {
        const state = this.state;
        const currentState = state.isAssemblyEditing;
        if(currentState === newState) return;
        state.isAssemblyEditing = newState;
        this.setState(state)
    }

    getErrorsWarningMsg(){
        let count = this.getTotalErrorsCount();
        return `${count} ${count > 1 ? 'errors' : 'error'} found.<br>All errors must be resolved before saving.`;

    }

    getTotalErrorsCount()
    {
        let count = 0;
        let _this = this;
        Object.keys(this.state.errorsCount).forEach(function(key){
            count += _this.state.errorsCount[key];
        })

        if(!this.props.product.inputs.name.valid) count++;
        if(!this.props.product.inputs.eid.valid) count++;
        if(!this.props.product.inputs.revision.valid) count++;

        return count;
    }

    setVariantFlags = async props => {
        const { cpnType, isCpnVariantEditable, isCpnVariantScheme } = getLegacyCpnConfig({
            currentCompanyCpnType: window.__currentCompanyCpnType,
            isCreating: props?.product?.isNewObject,
            modelType: "PRODUCT",
        });
        this.setState({ cpnType, isCpnVariantEditable, isCpnVariantScheme });
    }

    render()
    {
        const { product, inputs, modified } = this.props.product
        const company = this.props.company.data
        const user = this.props.user.data;
        let validated                = Utils.isValidated(inputs)
        let proceed                  = modified && validated
        let { isNewObject }          = this.props.product
        let state                    = this.state
        let urlParams                = new URLSearchParams(window.location.search)
        let showModal                = urlParams.has('showModal') ? urlParams.get('showModal') : null
        let parentNewStatus          = this.state.showErrormodal && (showModal && showModal === 'false')  &&  urlParams.has('status') ? urlParams.get('status') : this.state.newStatusValue

        if (!product || !Object.keys(company).length){
            return <Loading />
        }

        let isAssembly = this.props.children.length > 0 ? true : false
        product.primarySource = SourcingUtils.setPrimarySourceIfRolledUpSourceIsPrimary(product)

        let primarySource =
            Object.keys(this.state.productPrimarySource).length !== 0 ?
            this.state.productPrimarySource : product.primarySource

        let primarySourceLeadTimeValue = primarySource.leadTime.value === null ? '' : primarySource.leadTime.value
        let primarySourceLeadTimeUnits = primarySource.leadTime.units === null ? '' : primarySource.leadTime.units

        let categoryCPN = product.cpn
        const { cpnType, isCpnVariantEditable, isCpnVariantScheme } = this.state;
        if (isCpnVariantScheme) {
            let cpnWIthVariant = categoryCPN
            if (cpnType === "CPN_RULES") {
                categoryCPN = product.cpn;
            }
            else if (cpnType === "CUSTOM-CODE-WITH-11-DIGIT") {
                categoryCPN = categoryCPN.split("-")[0] + "-" + cpnWIthVariant.split("-")[1] + "-" + cpnWIthVariant.split("-")[2]
            } else if (cpnType === "WITH-6-DIGIT-PREFIX-AND-COUNTER" || window.__nonIntelligentCPN()) {
                categoryCPN = categoryCPN.split("-")[0]
            } else {
                categoryCPN = categoryCPN.split("-")[0] + "-" + cpnWIthVariant.split("-")[1]
            }
        }

        let productViewLink = "/product/view/" + product._id

        let { nameDuplicateOf, eidDuplicateOf, cpnDuplicateOf } = inputs
        let topSectionErrors = 0;
        if(nameDuplicateOf)
        {
            topSectionErrors++;
        }
        if(eidDuplicateOf)
        {
            topSectionErrors++;
        }
        if(cpnDuplicateOf)
            topSectionErrors++;

        let eidDuplicateTooltip  = Utils.makeDuplicateInputTooltip(eidDuplicateOf, "EID")
        let nameDuplicateTooltip = Utils.makeDuplicateInputTooltip(nameDuplicateOf, "Name")
        let cpnDuplicateTooltip  = window.__currentCompanyCpnType === "FREE-FORM" ?  Utils.makeDuplicateInputTooltip(cpnDuplicateOf, "CPN") : null ;
        let cpnFreeFormClass = window.__currentCompanyCpnType === "FREE-FORM" ? "free-form-input" : "";

        let forecastKeys = []
        inputs.forecasts.forEach((input) => forecastKeys.push(input.key.value))
        let currentStatus = Utils.getPreviousStatus(product);
        const lastModifiedBy = Utils.getLastModifiedBy(product);

        let markup =
            <div className="edit-product-route" ref="productForm">
                <Helmet>
                    <title>{Utils.makeTitle("["+ product.cpn +"] (EDIT) "+product.name)}</title>
                </Helmet>
                {
                    this.state.shouldBlockNavigation &&
                    <Prompt
                        when={state.shouldBlockNavigation}
                        message={this.handleBlockedNavigation}
                    />
                }

                {
                    this.state.modalVisible &&
                    <WarningModal
                        onCancel={(e) => this.closeModal(e, true)}
                        onConfirm={this.handleConfirmNavigationClick}
                        title={"Changes will be lost"}
                        description={"You have unsaved changes. If you navigate away without first saving, the changes will be lost."}
                    />
                }

                <AssemblySearchMenu
                    dispatch={this.props.dispatch}
                    parentType="Product"
                    component={product}
                    newlyAddedComponentsFromRightSearch={this.newlyAddedComponentsFromRightSearch}
                    addedChildList={this.props.children}
                    validations={validations.product.children}
                    changeorders={this.props.changeorders}
                    showHideAssemblySearch={this.props.showHideAssemblySearch}
                />

                {
                    this.state.displayFileImport &&
                    <FileImportModule
                        showUiAlert={this.props.showUiAlert}
                        displayModal={true}
                        history={this.props.history}
                        fileType={"new_assembly"}
                        onDone={this.onDone}
                        assemblyFlag={true}
                        displayImportFileFlag={true}
                        onClose={this.toggleModal}
                        currentlyOpenedCategory={"Product"}
                    />
                }

                {
                    state.trackStatusChanged &&
                    ( !isAssembly ?
                        <StatusUpdateModalForNonAssembly
                            statusFieldPreviousValue={state.statusFieldPreviousValue}
                            newStatusValue={state.newStatusValue}
                            revisionInput={state.revisionInput}
                            revisionInputChange={this.revisionInputChange}
                            onRejectStatusChange={this.onRejectStatusChange}
                            onAcceptStatusChange={this.onAcceptStatusChange}
                            item={"product"}

                        />
                        :
                        <UpdateStatusModal
                            editPage={true}
                            parent={product}
                            hideUpdateStatusModal={this.hideUpdateStatusModal}
                            parentNewStatus={parentNewStatus}
                            parentNewRevision={state.revisionInput.value}
                            refreshAfterBulkUpdate={this.refreshAfterBulkUpdate}
                        />
                    )
                }

                {
                    !isAssembly && state.showSaveAsRevisionModal ?
                    <UpdateRevisionNonAssembly 
                    revisionInput={state.revisionInput}
                    onContinue={this.setRevisionInDesignStatus}
                    component={product}
                    toogleSaveAsRevisionModal={this.showHideSaveAsRevisionModal}
                    newStatusValue={state.newStatusValue}
                    />
                    : isAssembly && state.showSaveAsRevisionModal ?
                    <UpdateRevisionModal
                        editPage={true}
                        parent={product}
                        children={this.props.children}
                        hideUpdateStatusModal={this.hideUpdateStatusModal}
                        parentNewStatus={parentNewStatus}
                        refreshAfterBulkUpdate={this.refreshAfterBulkUpdate}
                        onSubmit={this.onSubmit}
                        showHideSaveAsRevisionModal={this.showHideSaveAsRevisionModal}
                        nextCoRevision={this.nextCoRevision}
                    />
                    : null
                }

                {
                    this.state.displayVendorModal &&
                    <ImportFromVendor
                        onClose={this.toggleModal}
                        history={this.props.history}
                        newlyAddedComponentsFromRightSearch={this.newlyAddedComponentsFromRightSearch}
                        isEditRoute={true}
                    />
                }

                {
                    this.state.displayManualModal &&
                    <ImportFromManual
                        onClose={this.toggleModal}
                        history={this.props.history}
                        newlyAddedComponentsFromRightSearch={this.newlyAddedComponentsFromRightSearch}
                        isEditRoute={true}
                    />
                }

                <form onSubmit={this.onSubmit} onKeyPress={this.onKeyPress}>

                    <div className="banner-block">
                        <div className="app-row">
                            <div>
                                <InlineIcon><EditIcon /></InlineIcon>
                                <div>You are currently in edit mode.</div>
                            </div>
                            <div>
                                {
                                    isNewObject === false ? <Link to={productViewLink}>CANCEL</Link>
                                    :
                                    <input className="delete_btn" type="button" value="CANCEL" onClick={this.handleCancelEvent}/>
                                }
                                <Actions
                                    proceed={proceed}
                                    showHideSaveAsRevisionModal={this.showHideSaveAsRevisionModal}
                                    onSubmit={this.onSubmit}
                                    isNewObject={isNewObject}
                                    assemblyErrors={state.errorsCount.assemblyTab}
                                    isAssemblyEditing={state.isAssemblyEditing}
                                    calculateAssemblyErrors={this.calculateAssemblyErrors}/>
                            </div>
                            {
                                validated ? "" : <ErrorNotification getErrorsWarningMsg={this.getErrorsWarningMsg}/>
                            }
                        </div>
                    </div>

                    <div className="top-block app-row">
                        {/* <div className="nav-block">
                            <div>
                                products / {product.cpn}
                            </div>
                        </div> */}

                        <div className={`name-block${window.__companyIs11DigitCpnType() ? ' afero' : ''}`}>
                            <Tooltip
                                placement={"right"}
                                visible={nameDuplicateTooltip}
                                overlayClassName={"simple-rc-tip error"}
                                getTooltipContainer={() => document.querySelector("#routes")}
                                overlay={ nameDuplicateTooltip ?
                                        <div>
                                            <p>
                                                <span className="link-text">{nameDuplicateTooltip.errorMessage}</span>
                                                <br/>
                                                <Link
                                                    to={nameDuplicateTooltip.viewLink}
                                                    target="_blank"
                                                    className="open-link-holder white"
                                                    >
                                                    <span className="link-text">{nameDuplicateTooltip.linkMessage}
                                                        <InlineIcon >
                                                            <LinkIcon/>
                                                        </InlineIcon>
                                                    </span>
                                                </Link>
                                            </p>
                                        </div>
                                        : ""
                                    }
                            >
                                <LazyInput
                                    type="text"
                                    name="name"
                                    autoFocus={true}
                                    value={inputs.name.value}
                                    onChange={this.onInputChange}
                                    className={inputs.name.class}
                                    data-place="right"
                                    data-type="error"
                                    data-tip={inputs.name.message}
                                />
                            </Tooltip>
                        </div>

                        <div className="tiles-wrapper edit">
                            <PerfectScrollbar className="tiles-section-scroll">
                                <TileItem title="Product overview">
                                    <ImageViewerTile
                                        name="images"
                                        images={product.images}
                                        defaultResolution={Config.defaultResolutions.featureImage}
                                        onChange={this.uploadImage}
                                        edit={true}
                                    />
                                    <CpnEditField
                                        cpnDuplicateTooltip={cpnDuplicateTooltip}
                                        inputs={inputs}
                                        categoryCPN={categoryCPN}
                                        cpnVariantScheme={isCpnVariantScheme}
                                        onInputChange={this.onInputChange}
                                        cpnVariant_disabled={!isCpnVariantEditable}
                                        user={this.props.user}
                                    />
                                    <div className="inner-info diff-eid-section">
                                        <span className="inner-attribute">eid</span>
                                        <div className="inner-value">
                                            <Tooltip
                                                placement={"right"}
                                                visible={eidDuplicateTooltip}
                                                overlayClassName={"simple-rc-tip error"}
                                                getTooltipContainer={() => document.querySelector("#routes")}
                                                overlay={ eidDuplicateTooltip ?
                                                        <div>
                                                            <p>
                                                                <span className="link-text">{eidDuplicateTooltip.errorMessage}</span>
                                                                <br/>
                                                                <Link
                                                                    to={eidDuplicateTooltip.viewLink}
                                                                    target="_blank"
                                                                    className="open-link-holder white"
                                                                    >
                                                                    <span className="link-text">{eidDuplicateTooltip.linkMessage}
                                                                        <InlineIcon >
                                                                            <LinkIcon/>
                                                                        </InlineIcon>
                                                                    </span>
                                                                </Link>
                                                            </p>
                                                        </div>
                                                        : ""
                                                    }
                                                >
                                                <LazyInput
                                                    type="text"
                                                    name="eid"
                                                    className={inputs.eid.class}
                                                    value={inputs.eid.value}
                                                    onChange={this.onInputChange}
                                                    data-place="right"
                                                    data-type="error"
                                                    data-tip={inputs.eid.message}
                                                />
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className="inner-info diff-revision-section">
                                        <span className="inner-attribute">revision</span>
                                            {
                                                isNewObject ?
                                                <LazyInput
                                                    type="text"
                                                    name="revision"
                                                    className={inputs.revision.class}
                                                    value={inputs.revision.value}
                                                    data-place="right"
                                                    data-type="error"
                                                    data-tip={inputs.revision.message}
                                                    onChange={this.onInputChange}
                                                />
                                                :
                                                <RevisionField
                                                    item={product}
                                                    revision={inputs.revision.value}
                                                />
                                            }
                                    </div>
                                    <div className="inner-info diff-status-section">
                                        <span className="inner-attribute">status</span>
                                        <select
                                            name="status"
                                            value={inputs.status.value}
                                            onChange={this.onInputChange}
                                            >
                                            {Utils.toOptions(Schemas.product.status.list(!isNewObject ? currentStatus : ''))}
                                        </select>
                                    </div>
                                </TileItem>
                                <TileItem title="Details" icon={ <ItemDetailsIcon /> }>
                                    <div className="description">
                                        <TextArea
                                            name="description"
                                            onChange={this.onInputChange}
                                            placeholder="Enter a product description"
                                            value={inputs.description.value}
                                            className={`description diff-description-section ${inputs.description.class}`}
                                            data-tip={inputs.description.message}
                                            data-place="top"
                                            data-type="error"
                                        />
                                    </div>
                                    <InlineIcon className="primary-source">
                                        <WebSrcIcon />
                                        <span>primary source</span>
                                    </InlineIcon>
                                    <div className="inner-info values-to-right diff-mpn-section">
                                        <span className="inner-attribute">mpn</span>
                                        <span className="inner-value">
                                            {primarySource.mpn}
                                        </span>
                                    </div>
                                    <div className="inner-info values-to-right diff-mfr-section">
                                        <span className="inner-attribute">mfr</span>
                                        <span className="inner-value">
                                            {primarySource.manufacturer}
                                        </span>
                                    </div>
                                    <div className="inner-info values-to-right diff-min-qty-section">
                                        <span className="inner-attribute">min qty</span>
                                        <span className="inner-value">
                                            {primarySource.minQuantity}
                                        </span>
                                    </div>
                                    <UnitPrice
                                        primarySource={primarySource}
                                        item={product}
                                        mode="EDIT"
                                    />
                                    <ExtendedRolledUpCost
                                        extendedCost={primarySource.extendedCost}
                                        object={product}
                                        mode="EDIT"
                                    />
                                    <div className="inner-info values-to-right diff-lead-time-section">
                                        <span className="inner-attribute">lead time</span>
                                        <span className="inner-value">
                                            { primarySourceLeadTimeValue +' '+ primarySourceLeadTimeUnits }
                                        </span>
                                    </div>
                                    <div className="inner-info values-to-right diff-mass-cell-section">
                                        <span className="inner-attribute no-cl">{`MASS (${Utils.getCompanyMassUnit()})`}</span>
                                        <div className="inner-value rolled-up-mass-cell">
                                            {
                                                (this.state.showMassErrorIcon === true && product.massStatus && product.massStatus.toUpperCase() === "ERROR") &&
                                                <InlineIcon
                                                    tooltip="Missing mass values for one or more children"
                                                    tooltipPlace="top"
                                                    className="inside-icon"
                                                >
                                                    <AlertErrorIcon />
                                                </InlineIcon>
                                            }
                                            {
                                                ((this.state.showMassWarningIcon === true) || (product.massStatus && product.massStatus.toUpperCase() === "WARNING")) &&
                                                <InlineIcon
                                                    tooltip={`${inputs.mass.value ? "Manually entered value" : "Auto calculate value from children"}`}
                                                    tooltipPlace="top"
                                                    className="inside-icon"
                                                >
                                                    <WarningTriangleIcon />
                                                </InlineIcon>
                                            }
                                            <NumberFormat
                                                type="text"
                                                name="mass"
                                                className={`${inputs.mass.class} icon-in-input`}
                                                value={inputs.mass.value}
                                                onChange={this.onInputChange}
                                                data-place="right"
                                                data-type="error"
                                                data-tip={inputs.mass.message}
                                                thousandSeparator={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="inner-info last-info values-to-right diff-procurement-cell-section">
                                        <span className="inner-attribute">procurement</span>
                                        <select
                                            className="inner-value"
                                            name="procurement"
                                            value={inputs.procurement}
                                            onChange={(e) => this.handleProcurement(e)}
                                        >
                                            {Utils.toOptions(Schemas.component.procurement.list())}
                                        </select>
                                    </div>
                                    <div className="inner-info">
                                        <span className="inner-attribute">created</span>
                                        <TimeStampColumn format='date-time-with-long-format' value={product.created} />
                                    </div>
                                    <div className="inner-info">
                                        <span className="inner-attribute">modified</span>
                                        <LastUpdatedField item={product}/>
                                    </div>
                                    <div className="inner-info">
                                        <span className="inner-attribute">modified by</span>
                                        {lastModifiedBy}
                                    </div>
                                </TileItem>
                                <ItemCustomSpecs
                                    isEditing={true}
                                    item={inputs}
                                    onInputChange={this.onInputChange}
                                    title={user?.activeLibrary?.customTileTitle ?? "Custom Specs"}
                                />
                                <TileItem title="Team" icon={<ItemTeamIcon />}>
                                    {
                                        inputs.team.map((item, i) => (
                                            <div key={`team-${i}`} className="player">
                                                <span className="player-title">
                                                    { item.displayName === 'QA' ? 'quality assurance (qa)' : item.displayName }
                                                </span>
                                                <LazyInput
                                                    type="text"
                                                    name="team"
                                                    value={item.value}
                                                    onChange={e => this.onInputChange(e, i)}
                                                    className={`player-edit-email ${item.class || ''}`.trim()}
                                                    data-tip={item.message}
                                                    data-place="top"
                                                    data-type="error"
                                                />
                                            </div>
                                        ))
                                    }
                                </TileItem>
                                <TileItem title="Build schedule" icon={<ItemScheduleIcon />}>
                                    {
                                        inputs.forecasts.map((item, i) => (
                                            <div className="schedule-edit" key={`build-schedule-${i}`}>
                                                {
                                                    item.key && <span className="schedule-title">
                                                        {validations.product.forecasts.getForecastDisplayName(item.key.value, item.counter.value, this.forecastScheme, forecastKeys)}
                                                    </span>
                                                }
                                                <div className="schedule-date-amount">
                                                    <div className="schedule-date">
                                                        <InlineIcon tooltip="Date" tooltipPlace="top">
                                                            <ItemScheduleIcon />
                                                        </InlineIcon>
                                                        <DatePicker
                                                            popperProps={{
                                                                positionFixed: true
                                                            }}
                                                            selected={item.targetDate.value ? new Date(item.targetDate.value) : null}
                                                            onChange={(date) => this.onDateChange(date, null, i)}
                                                            dateFormat={"MMM d, yyyy"}
                                                            className={`${item.targetDate.class} custom-date-trigger-${i}`}
                                                            customInput={
                                                                <input
                                                                    type="text"
                                                                    value={item.targetDate.value ? Utils.dateFormatWithSlash(item.targetDate.value) : null}
                                                                    data-tip={item.targetDate.message}
                                                                    data-place="right"
                                                                    data-type="error"
                                                                    className={`${item.targetDate.class}`}
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                    <div className="schedule-amount">
                                                        <div className="remove-icon">
                                                            <InlineIcon
                                                                onClick={item.isDisabled ? null : () => this.remove(i)}
                                                                className={`${item.isDisabled ? "disabled" : ""}`}
                                                            >
                                                                <DeleteIcon />
                                                            </InlineIcon>
                                                        </div>
                                                        <LazyInput
                                                            type="text"
                                                            name="forecastVolume"
                                                            value={item.volume.value}
                                                            onChange={(event) => this.onInputChange(event, i)}
                                                            className={item.volume.class}
                                                            data-tip={item.volume.message}
                                                            data-place="top"
                                                            data-type="error"
                                                            autoComplete="off"
                                                        />
                                                        <InlineIcon tooltip="Volume" tooltipPlace="top">
                                                            <ItemScheduleMassIcon />
                                                        </InlineIcon>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    <div
                                        className="add-schedule"
                                        onClick={this.forecastScheme === "DEFAULT" ? this.addForecast : this.displayExpertForecastPopover}>
                                        <InlineIcon><AddRowIcon /></InlineIcon>
                                        <span>ADD NEW FORECAST</span>
                                    </div>
                                    {
                                        //
                                        this.state.displayExpertForecastPopover &&
                                        <ExpertForecastPopover
                                            addForecast={this.addForecast}
                                            hideExpertForecastPopover={this.hideExpertForecastPopover}
                                        />
                                    }
                                </TileItem>
                                <ItemERP item={product} />
                            </PerfectScrollbar>
                        </div>
                    </div>
                    <EditTabsContextProvider currentItem={this.props?.product?.product}>
                        <Tabs
                            tabsType="prd"
                            product={product}
                            calculateAssemblyErrors={this.calculateAssemblyErrors}
                            errorsCount={this.state.errorsCount}
                            toggleErrorCount={this.toggleErrorCount}
                            toggleAssemblyEditingState={this.toggleAssemblyEditingState}
                            onChange={this.onInputChange}
                            componentSearch={this.componentSearch}
                            hideAssemblyModals={this.hideAssemblyModals}
                            clearComponentSearch={this.clearComponentSearch}
                            inputs={inputs}
                            showFileImportModal={state.showFileImportModal}
                            newlyCreatedComponent={state.newlyCreatedComponent}
                            clearNewlyCreatedComponentsFromState={this.clearNewlyCreatedComponentsFromState}
                            actionType={state.actionType}
                            newlyAddedComponentsFromRightSearch={state.newlyAddedComponentsFromRightSearch}
                            clearNewlyAddedComponentsFromState={this.clearNewlyAddedComponentsFromState}
                            dispalyErrorModal={this.dispalyErrorModal}
                            history={this.props.history}
                            onPrimarySourceChange={this.onPrimarySourceChange}
                            onPrimarySourceDeselect={this.onPrimarySourceDeselect}
                            disablePrimarySourceBtn={this.disablePrimarySourceBtn}
                            enablePrimarySourceBtn={this.enablePrimarySourceBtn}
                            onRolledUpSelect={this.onRolledUpSelect}
                            onRolledUpInputChanged={this.onRolledUpInputChanged}
                            allowPrimarySourceIcon={this.state.allowPrimarySourceIcon}
                            setPrimarySourceInObject={this.setPrimarySourceInObject}
                            syncPrimaryQuoteInputWithData={this.syncPrimaryQuoteInputWithData}
                            primarySource={primarySource}
                            userStyles={this.props.user.preferences.styles}
                            item_assembly={this.props.item_assembly}
                            syncWithRolledUpCost={this.state.syncWithRolledUpCost}
                            afterSyncWithRolledUpCost={this.afterSyncWithRolledUpCost}
                            showHideAssemblySearch={this.props.showHideAssemblySearch}
                            toggleModal={this.toggleModal}
                            cmpId={this.state.cmpId}
                            defaultCurrency={product.defaultCurrency}
                        />
                    </EditTabsContextProvider>
                </form>
            </div>

        return markup
    }
}

const mapDispatchToProps = dispatch => ({
  showUiAlert                 : payload => dispatch(buildAction(UI.SHOW_ALERT, payload)),
  getProductAndSetEditPage    : payload => dispatch(buildAction(PRODUCT.GET_PRODUCT_AND_SET_IN_EDIT_PAGE, payload)),
  updateDisplayAssemblySearch : (payload) =>  dispatch(buildAction(ITEM_ASSEMBLY.UPDATE_DISPLAY_ASSEBLY_SEARCH, payload)),
  setEditPageModified         : () => dispatch(buildAction(PRODUCT.SET_EDIT_PAGE_MODIFIED, true )),
  setNewObjectFlag            : () => dispatch(buildAction(PRODUCT.SET_IS_NEW_OBJECT_FLAG, true )),
  resetProductEditForm        : () => buildAction(PRODUCT.RESET_PRODUCT_EDIT_FORM),
  addNewForecast              : (payload) => dispatch(buildAction(PRODUCT.ADD_NEW_FORECAST_EDIT_PAGE, payload)),
  removeForecast              : (payload={}) => dispatch(buildAction(PRODUCT.REMOVE_FORECAST_EDIT_PAGE, payload)),
  updateEditFormState         : (payload={}) => dispatch(buildAction(PRODUCT.UPDATE_EDIT_FORM_INPUT_STATE, payload)),
  submitProductForm           : (payload={}) => dispatch(buildAction(PRODUCT.SUBMIT_PRODUCT_EDIT_FORM, payload)),
  uploadImages                : (payload={}) => dispatch(buildAction(PRODUCT.UPLOAD_IMAGES_ON_EDIT_PAGE, payload)),
  searchComponentInEditPage   : query => dispatch(buildAction(PRODUCT.SEARCH_COMPONENTS_IN_EDIT_PAGE, {query: query})),
  setEditPageComponentSearch  : () =>  dispatch(buildAction(PRODUCT.SET_EDIT_PAGE_COMPONENT_SEARCH_RESULTS, [])),
  clearAssemblySearchState    : () =>  dispatch(buildAction(ITEM_ASSEMBLY.CLEAR_ASSEMBLY_SEARCH_STATE, {children: []})),
  showHideAssemblySearch      : () =>  dispatch(buildAction(DISPLAYSIDEBAR.SET_LIBRARY_FLAG)),
  hideSidebars                :  () =>  dispatch(buildAction(DISPLAYSIDEBAR.HIDE_SIDE_BARS))
});


const mapStateToProps = state => ({
    product: state.product.editPage,
    item_assembly: state.item_assembly,
    company: state.company,
    user: state.user,
    children: state.assembly_edit.children,
    childrenModified: state.assembly_edit.modified,
    originalProductId: state.product.editPage.originalProductId,
})

export default connect(mapStateToProps, mapDispatchToProps)(EditProduct);
