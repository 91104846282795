import Tooltip from "rc-tooltip";
import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import InnerLoading from "v1/components/ui/inner-loading";
import ArrowIcon from "../../../../../assets/icons/arrow-icon";
import BlackArrow from "../../../../../assets/icons/black-arrow.js";
import CompareRevIcon from "../../../../../assets/icons/compare-rev.js";
import GreenArrow from "../../../../../assets/icons/green-arrow.js";
import LinkIcon from "../../../../../assets/icons/open-link-icon.js";
import Utils from "../../../../../modules/utils";
import Avatar from "../../../../ui/avatar";
import InlineIcon from "../../../../ui/icon/inline-icon";
import Link from "../../../../ui/link";
import RevisionField from "../../revision-field";
import StatusField from "../../status-field";
import TimeStampColumn from "../../timestamp-column";

export const SkeletonCell = () => {
  return (
    <React.Fragment>
      <SkeletonTheme className="custom-loader" color="#2A2A36" highlightColor="#3CD1BF">
        <Skeleton delay={0.9} height={10} />
      </SkeletonTheme>
    </React.Fragment>
  );
}

export const CheckboxCellHeader = ({ sourceRevId, targetRevId }) => {
  return (
    <Tooltip
      overlay="Select a revision to compare"
      placement="top"
      overlayClassName={`help-container default-styles ${sourceRevId && targetRevId ? "" : "hidden"}`}
    >
      <div className="icon-div">
        <InlineIcon>
          <CompareRevIcon className="abc" />
        </InlineIcon>
      </div>
    </Tooltip>
  )
};

export const CheckboxCell = ({ row, sourceRevId, targetRevId, selectTargetRevision }) => {
  const isSourceRev = sourceRevId === row.original._id;
  const isTargetRev = targetRevId === row.original._id;

  const onClickRev = (e, revision) => {
    if (revision._id == sourceRevId) return;
    selectTargetRevision(revision._id);
  };

  return (
    <div className="rounded-checkbox">
      {isSourceRev ? (
        <InlineIcon className="arrow-button right">
          <BlackArrow />
        </InlineIcon>
      ) : isTargetRev ? (
        <InlineIcon
          className="arrow-button"
        >
          <GreenArrow />
        </InlineIcon>
      ) : null}
      <input
        className="checked-input"
        type="checkbox"
        checked={isSourceRev || isTargetRev ? true : false}
        onClick={(e) => onClickRev(e, row.original)}
      />
      <label
        className={`checked-label ${sourceRevId === row.original._id ? "disabled" : ""}`}
      />
    </div>
  );
};

export const ExpanderCell = ({ row, expandRow }) => {
  if (row.getIsExpanded() && !row.original.subRevisions.length)
    return <InnerLoading />
  if (row.getCanExpand()) {
    return (
      <span className="custom-arrow-icon">
        <InlineIcon
          className={`sub-rev-icon ${row.getIsExpanded() ? "open" : ""}`}
          onClick={() => expandRow(row)}
        >
          <ArrowIcon />
        </InlineIcon>
      </span>
    );
  } else return <div></div>;
};

export const AuthorCell = ({ row, makeRevisionLink, imagesWithSrc }) => {
  return <Link to={{ pathname: makeRevisionLink(row.original), state: { parentRevision: row.original.parentRev ? row.original.parentRev : null } }} href={makeRevisionLink(row.original)}>
    <div className={`history-table-title ${row.original.isSubRev && 'text-block'}`}>
      <Avatar
        imageId={row.original.creator.avatar}
        index={row.original._id}
        imagesWithSrc={imagesWithSrc}
      />
      <span className="user-name">
        {`${row.original.creator.firstName} ${row.original.creator.lastName}`}
      </span>
    </div>
  </Link>
};

export const TimestampCell = ({ row, makeRevisionLink }) => {
  return (
    <Link to={{ pathname: makeRevisionLink(row.original), state: { parentRevision: row.original.parentRev ? row.original.parentRev : null } }} href={makeRevisionLink(row.original)}>
      <TimeStampColumn
        key={Utils.generateUniqueId()}
        format="date-time-with-long-format"
        value={row.original.created}
      />
    </Link>
  )
};

export const TypeCell = ({ row }) => {
  let type = row.original.revisionType ? row.original.revisionType : "Modified";
  if (row.original.co && row.original.co._id) type = "coRevision";
  let typeLink = "";
  let typeClass = "";
  let typeResult;
  let refCoResolution = "";
  if (row.original.co && row.original.co._id) {
    typeLink = `/changeorder/view/${row.original.co._id}`;
    typeResult = row.original.co.con;
    refCoResolution = row.original.co.resolution;
    if (["REJECTED", "NONE"].includes(refCoResolution)) {
      typeClass =
        refCoResolution === "REJECTED" ? "red-font" : "grey-font";
    }
  } else if (row.original.release && row.original.release._id) {
    typeLink = `/release/view/${row.original.release._id}`;
    typeResult = row.original.release.releaseNo;
  }
  return (
    <React.Fragment>
      {type === "coRevision" || type === "releaseRevision" ? (
        <div
          className={`con-holder ${row.original.revisionType === "releaseRevision" ? "rel-holder" : ""
            }`}
        >
          <Link
            to={typeLink}
            target="_blank"
            className={`link ${typeClass}`}
          >
            {typeResult}
            <InlineIcon className="co-link-icon">
              <LinkIcon />
            </InlineIcon>
          </Link>
        </div>
      ) : (
        <span className={`${type.toLowerCase()}`}>{type}</span>
      )}
    </React.Fragment>
  );
};

export const RevCell = ({ row, makeRevisionLink }) => !row.original.isSubRev ? (row.original.revision ? <RevisionField
  item={row.original}
  revision={row.original.revision}
  showIcon={row.original.revisionType === "Modified" ? true : false}
/> : "—") : ((row.original.parentRevisionNumber === row.original.revision) && (row.original.parentRevType !== 'Modified' || row.original.parentStatus === "DESIGN")) ? "*" : row.original.revision ? (
  <RevisionField
    item={row.original}
    revision={row.original.revision}
      showIcon={row.original.revisionType === "Modified" ? true : false}
  />
) : '';

export const StatusCell = ({ row, makeRevisionLink }) => {
  return (
    <Link to={{ pathname: makeRevisionLink(row.original), state: { parentRevision: row.original.parentRev ? row.original.parentRev : null } }} href={makeRevisionLink(row.original)}>
      <StatusField item={row.original} status={row.original.status} />
    </Link>
  )
};

export const Details = ({ comment }) => {
  return (
    <p
      className="comment"
      data-tip=""
      data-place="bottom"
      onMouseOver={e => Utils.checkToolTipAppearance(e, comment)}
    >
      {comment}
    </p>
  )
};
