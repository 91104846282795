
import ASSEMBLY_EDIT        from '../action-types/assembly-edit.js'
import initialState         from './initial-states/assembly-edit.js'
import { handleActions }    from 'redux-actions'


function setChildren(state, { payload })
{
    state.children = payload.children
    state.modified = payload.modified
    return {...state}
}

function updateChildren(state, { updates, append, remove }) {
    let children = state.children;

    if (remove?.length) {
        children = children.filter(c => !remove.includes(c.component._id));
    }

    if (updates && Object.keys(updates).length) {
        children = children.map(child => {
            if (updates?.[child.component._id]) {
                return {
                    ...child,
                    ...updates[child.component._id]
                };
            }

            return child;
        });
    }

    if (append?.length) {
        children = children.concat(append.map(c => ({
            ...c,
            newlyAdded: true,
            component: {
                ...c.component,
                // Make sure that the alias is set properly, as PLM-API doesn't seem to return this
                // in the bulk create used by the file import dialog.
                alias: c.component?.alias ?? "cmp",
            },
        })))
    }

    return {
        ...state,
        children,
        modified: true,
    }
}

export default handleActions({
    [ASSEMBLY_EDIT.SET_CHILDREN]: setChildren,
    [ASSEMBLY_EDIT.UPDATE_CHILDREN]: updateChildren,
}, initialState);
