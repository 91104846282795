import React from "react";

function DragDropIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="23"
      viewBox="0 0 18 23"
    >
      <defs>
        <filter
          id="filter-1"
          width="105.4%"
          height="112%"
          x="-2.7%"
          y="-2%"
          filterUnits="objectBoundingBox"
        >
          <feOffset
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          ></feOffset>
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="2"
          ></feGaussianBlur>
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
      </defs>
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        opacity="0.5"
      >
        <g fill="#888" fillRule="nonzero" transform="translate(-518 -195)">
          <g transform="translate(458 89)">
            <g transform="translate(44 79)">
              <g filter="url(#filter-1)">
                <g transform="translate(20 29)">
                  <path d="M4 13.125C4 14.156 3.1 15 2 15s-2-.844-2-1.875c0-1.031.9-1.875 2-1.875s2 .844 2 1.875zm-2-7.5c-1.1 0-2 .844-2 1.875 0 1.031.9 1.875 2 1.875S4 8.531 4 7.5c0-1.031-.9-1.875-2-1.875zM2 0C.9 0 0 .844 0 1.875 0 2.906.9 3.75 2 3.75s2-.844 2-1.875C4 .844 3.1 0 2 0zm6 3.75c1.1 0 2-.844 2-1.875C10 .844 9.1 0 8 0S6 .844 6 1.875c0 1.031.9 1.875 2 1.875zm0 1.875c-1.1 0-2 .844-2 1.875 0 1.031.9 1.875 2 1.875s2-.844 2-1.875c0-1.031-.9-1.875-2-1.875zm0 5.625c-1.1 0-2 .844-2 1.875C6 14.156 6.9 15 8 15s2-.844 2-1.875c0-1.031-.9-1.875-2-1.875z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default DragDropIcon;
