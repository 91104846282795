import React from "react";

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='23'
      height='23'
      viewBox='0 0 23 23'
    >
      <g fill='none' fillRule='evenodd' strokeWidth='1'>
        <g transform='translate(-777 -108)'>
          <g transform='translate(778 109)'>
            <g>
              <g>
                <circle cx='10.5' cy='10.5' r='10.5'></circle>
                <ellipse cx='10.957' cy='10.5' rx='5.478' ry='10.5'></ellipse>
                <path d='M10.957 0v21M21 10.957H0'></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
