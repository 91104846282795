import { SvgIcon } from "@mui/material";

export const SubmitIcon = () => (
  <SvgIcon width="24px" height="24px" viewBox="0 0 24 24">
      <g fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-1324 -92)">
          <g transform="translate(979 84)">
            <g transform="translate(345 8)">
              {/* eslint-disable-next-line max-len */}
              <path d="M4 22v-6.5c0-.4.15-.75.45-1.05.3-.3.65-.45 1.05-.45h13c.4 0 .75.15 1.05.45.3.3.45.65.45 1.05V22H4zm1.5-4h13v-2.5h-13V18zm6.5-4L7.025 7c0-1.383.483-2.563 1.45-3.538C9.442 2.487 10.617 2 12 2s2.558.487 3.525 1.462c.967.975 1.45 2.155 1.45 3.538L12 14zm0-1.95l3.475-4.9V7c0-.967-.338-1.792-1.012-2.475C13.787 3.842 12.966 3.5 12 3.5c-.967 0-1.787.342-2.463 1.025A3.393 3.393 0 008.525 7v.15L12 12.05z"></path>
            </g>
          </g>
        </g>
      </g>
  </SvgIcon>
);
