import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
    >
      <g fill="none" fillRule="evenodd" stroke="#000" strokeWidth="1">
        <g transform="translate(-340 -297)">
          <path d="M344 302.363356L345.500202 304 351 298"></path>
          <path d="M351 303v3.889c0 .613-.498 1.111-1.111 1.111h-7.779c-.613 0-1.11-.498-1.11-1.111v-7.778a1.11 1.11 0 011.11-1.111h6.112"></path>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
