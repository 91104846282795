import "./index.css";
import React, { useCallback, useState, useEffect, useRef, useMemo } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import Avatar from '@mui/material/Avatar';
import AvatarIcon from "v1/assets/icons/user-icon.js"

const VisbilityModal = (props) => {

    const visibilityModal = useRef(null);
    const [selectedTemplate, setSelectedTemplate] = useState({});
    const [usersList, setUsersList] = useState([]);
    const isDcoEnabled = window.__isDcoEnabled;
    const {
        onClose,
        templateId,
        templates,
    } = props;

    const handleOutsideClick = useCallback((e) => {
        onClose(false, '', '');
    }, [onClose]);

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick, false);
        if (templateId)
        {
            let template = templates.find((temp) => { return templateId === temp._id });
            setSelectedTemplate(template);
            let users = [...template.approversList, ...template.notifiersList];
            setUsersList(users);
        }
        return() => {
            document.removeEventListener('click', handleOutsideClick, false);
        }
    }, [handleOutsideClick, templateId, templates])

    const userRows = useMemo(() => usersList?.map((item, ind) => {
        return (
            <div className='template-user' key={`template-user-${ind}`}>
                <Avatar alt="User" src={item.src} style={avatarContainerStyle}>
                    <AvatarIcon /> { /* fallback if no src */}
                </Avatar>
                <span className='name'>{`${item.firstName} ${item.lastName}`}</span>
            </div>
        );
    }), [usersList]);

    return (
        <div className='show-users' ref={visibilityModal}>
            <PerfectScrollbar className={`custom-mandatory-scroll ${isDcoEnabled ? 'dco-':''}popover-${props.rowIndex+1}`}>
                <div className='content-block'>
                    <h4>{selectedTemplate.templateName && selectedTemplate.templateName.toUpperCase()}</h4>
                    <div className='includers-list'>
                        {userRows}
                    </div>
                </div>
            </PerfectScrollbar>
        </div>
    )
}

export default VisbilityModal

const avatarContainerStyle = {
    marginRight: "8px",
    backgroundColor: "transparent",
};
