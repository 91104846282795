import { FC, useMemo } from "react";
import { Box, Grid, styled, Typography } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import SaveAsOutlinedIcon from "@mui/icons-material/SaveAsOutlined";
import {
  EditIconChangeOrder,
  SubmitIcon,
} from "assets/icons";
import { BreadCrumbIcon } from "common/components/breadcrumb";
import { PageWrapper } from "common/components/pageLayout";
import { IToolbarActions } from "common/components/toolbar";
import { colorPalette } from "theme";
import { CoEditPageProvider } from "./changeOrderContextProvider";
import { ChangeOrderEditFrom } from "./coEditFormSection";
import { ChangeOrderEditTabs } from "./coEditTabSection";

const breadcrumbIcons = [
  {
    icon: BreadCrumbIcon.CHANGEORDER,
    label: "Change Order",
    url: "/changeOrder/new",
  },
  {
    label: "New Change Order",
  },
];

export const ChangeOrderEditPage: FC = () => {
  const onSubmit = (event: any) => {
    event.preventDefault();
  };

  const toolbarActionItems: IToolbarActions[] = useMemo(() => (
    [
      {
        Icon: () => <CancelOutlinedIcon fontSize={"medium"} />,
        label: "Cancel",
        onClick: () => { }, // Need to handle
        submitType: "reset",
      },
      {
        Icon: () => <SaveAsOutlinedIcon fontSize={"medium"} />,
        label: "Save Draft",
        onClick: () => { }, // Need to handle
        submitType: "submit",
      },
      {
        Icon: SubmitIcon,
        label: "Submit for Approval",
        onClick: () => { }, // Need to handle
        submitType: "submit",
      },
    ]
  ), []);

  const toolbarMode: JSX.Element = useMemo(() => (
    <BoxWrapper>
      <EditIconChangeOrder />
      <StyledToolbarMode variant="h6">Edit Mode</StyledToolbarMode>
    </BoxWrapper>
  ), []);

  return (
    <CoEditPageProvider>
      <StyledFormWrapper>
        <form onSubmit={onSubmit}>
          <PageWrapper
            breadCrumbOptions={breadcrumbIcons}
            helmetTitle={"Change Order | New"}
            pageHeading={"Change Order"}
            rightSidePanelProps={{
              RightSidePanelChildren: <></>,
              visible: false,
            }}
            toolbarProps={{
              toolbarItems: toolbarActionItems,
              toolbarMode,
            }}
          >
            <Grid container spacing={3}>
              <ChangeOrderEditFrom />
            </Grid>
            <Grid>
              <ChangeOrderEditTabs />
            </Grid>
          </PageWrapper>
        </form>
      </StyledFormWrapper>
    </CoEditPageProvider>

  );
};

const StyledToolbarMode = styled(Typography)({
  fontSize: "1rem",
  paddingLeft: "0.6rem",
});

export const CustomTitleSection = styled(Typography)({
  fontSize: "1.188rem",
  textAlign: "center",
  width: "100%",
});

const BoxWrapper = styled(Box)({
  color: colorPalette.black,
  alignItems: "center",
  backgroundColor: colorPalette.yellow,
  borderRadius: "0.3rem 0 0 0.3rem",
  display: "flex",
  height: "2.5rem",
  padding: "0.6rem",
  width: "8rem",
});

export const StyledFormWrapper = styled(Box)({
  flex: "1",
});
