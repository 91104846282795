import                              "./index.css"
import React                        from "react"
import { connect }                  from 'react-redux';
import View                         from "../../../ui/view"
import Actions                      from "./modules/actions"
import UserNotificationTable        from "../modules/user-notification-table"
import ApproverListTable            from "../modules/approver-list-table"
import Children                     from "./modules/children"
import Details                      from "./modules/details"
import HistoryTable                 from "../modules/history-table"
import CO                           from '../../../../action-types/changeorder';
import buildAction                  from "../../../../helpers/buildAction"
import coStatusNavArrow             from "../../../../assets/icons/co-status-nav-arrow.svg"
import prdCmpSrc                    from "../../../../assets/icons/prd-cmp-icon.svg"
import revsSrc                      from "../../../../assets/icons/revs.svg"
import Icon                         from "../../../ui/icon"
import notificationSrc              from "../../../../assets/icons/send-notification-icon.svg"
import ApproverIcon                 from "../../../../assets/icons/approver-icon"
import InlineIcon                   from "../../../ui/icon/inline-icon.js"
import Utils                        from "../../../../modules/utils"
import {Helmet}                     from "react-helmet"
import API                          from "../../../../modules/api";
import documentSrc                  from "../../../../assets/icons/cat-document.svg";
import Documents                    from "../../common/documents/view";
import DiffToolRightSideBar         from "../../common/diff-tool/modules/diff-tool-right-side-bar";
import Loading                      from "../../../ui/inner-loading"


export class ViewChangeOrder extends View
{
    constructor(props)
    {
        super(props)
        this.checkStatusInterval = null
        this.state =
        {
            view                      : "childrenData",
            showRevisionProgressModal : false,
            showLoading : false,
            diffToolRightSideBar: {
                open : false,
                sourceRev : null,
                targetRev : null,
                alias : null,
                parent: null,
                assemblyDiffCount: null
            }
        }
        this.showDiffToolRightSideBar       = this.showDiffToolRightSideBar.bind(this)
        this.closeDiffToolRightSideBar      = this.closeDiffToolRightSideBar.bind(this)
        this.syncWidthOfRightSideBar        = this.syncWidthOfRightSideBar.bind(this)
        this.onDiffToolRightSideBarExternalClick = this.onDiffToolRightSideBarExternalClick.bind(this)
        this.diffCountSummary               = this.diffCountSummary.bind(this)
        this.imagesWithSrc = []
    }

    componentWillMount()
    {
        this.getChangeOrderFromApi(this.props.match.params.id)
        if (/[?&]history=true/.test(window.location.href))
        {
            this.show("history")
        }
        else
        {
            let coActiveTab = Utils.getStore("co-tab") || "childrenData"
            this.show(coActiveTab)
        }
    }

    componentWillUnmount()
    {
        const {dispatch} = this.props;
        dispatch(buildAction(CO.RESET_CO_FORM))
        dispatch(buildAction(CO.REST_CO_VIEW_STATE))
        dispatch(buildAction(CO.SET_CO_OBJECT, null))
        if (this.checkStatusInterval)
        {
          clearTimeout(this.checkStatusInterval);
        }
    }

    componentDidMount()
    {
        this.checkStatus()
    }

    componentDidUpdate(prevProps) {
        if(this.state.diffToolRightSideBar.open)
        {
            this.syncWidthOfRightSideBar()
        }
    }

    syncWidthOfRightSideBar()
    {
        let changeOrderViewHeight;
        let scrollbarContainerHeight;

        let rightSideBarDiffEl = document.getElementsByClassName("diff-tool-sidebar")[0]
        if (rightSideBarDiffEl)
        {
            rightSideBarDiffEl.style.height = '0px'
        }

        let scrollbarContainer = document.getElementsByClassName("scrollbar-container")[0]
        if (scrollbarContainer)
        {
            scrollbarContainerHeight = scrollbarContainer.clientHeight
        }

        if (document.getElementsByClassName("view-changeorder-route")[0])
        {
            changeOrderViewHeight = document.getElementsByClassName("view-changeorder-route")[0].clientHeight
        }

        let scrollElHeight = scrollbarContainerHeight
        let viewPageHeight = changeOrderViewHeight

        let selectedHeight;
        if (scrollElHeight > viewPageHeight)
        {
            rightSideBarDiffEl.style.height = (scrollElHeight) + "px"
            selectedHeight = (scrollElHeight) + "px"
        }
        else
        {
            selectedHeight = (changeOrderViewHeight) + "px"
        }

        if (rightSideBarDiffEl)
        {
            rightSideBarDiffEl.style.height = selectedHeight
            rightSideBarDiffEl.style.minHeight = selectedHeight
        }
    }

    onRevisionsInProgressModalClose()
    {
        this.setState({showRevisionProgressModal: false});
    }

    checkStatus()
    {
        let timeOut = 5000;
        this.checkStatusInterval = setInterval(() =>
        {
            let { co } = this.props.changeorders.changeOrder;
            if(co && co.status === "CLOSED" && co.resolution === "FINALIZING")
            {
                API.cos.findById( co._id, (err, response) =>
                {
                    if(err)
                    {
                        console.log(err)
                    }
                    if(response && response._id !== co._id)
                    {
                        clearTimeout(this.checkStatusInterval);
                    }
                    else if(response && response._id === co._id && response.resolution !== "FINALIZING")
                    {
                        clearTimeout(this.checkStatusInterval);
                        const {dispatch} = this.props;
                        dispatch(buildAction(CO.RESET_CO_FORM));
                        dispatch(buildAction(CO.REST_CO_VIEW_STATE));
                        dispatch(buildAction(CO.SET_CO_OBJECT, response));
                    }
                });
            }
            else if(co && co.resolution !== "FINALIZING")
            {
                clearTimeout(this.checkStatusInterval);
            }
        }, timeOut);
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.match.params.id !== this.props.match.params.id)
        {
            this.getChangeOrderFromApi(nextProps.match.params.id)
        }
    }

    getChangeOrderFromApi(id, shouldResetState=false)
    {
        const {dispatch} = this.props;
        if (shouldResetState) dispatch(buildAction(CO.SET_CO_OBJECT, null))
        let payload = { id, history: this.props.history, history_action: 'VIEWED' }
        dispatch(buildAction(CO.FIND_CO, payload ))
    }

    onChange(co, isCloseAction=false)
    {
        const {dispatch} = this.props
        let payload =
        {
          data: co,
          history: this.props.history,
          current_page: "view",
          isCloseAction: isCloseAction
        }
        payload.editMode = true
        this.setState({showRevisionProgressModal: true});
        this.checkStatus()
        dispatch(buildAction(CO.SUBMIT_CHANGE_ORDER_FORM, payload))
    }

    onDelete(id)
    {
        const {dispatch} = this.props
        let payload = {id, history: this.props.history}
        dispatch(buildAction(CO.DELETE_CO, payload))
    }

    show(viewName)
    {
        this.state.view = viewName
        Utils.setStore("co-tab", this.state.view)
        this.setState(this.state)
    }

    getActionAndActionStatus()
    {
        let user = this.props.user.data;
        let { co } = this.props.changeorders.changeOrder;
        let isActionPerformed = true;
        let isInApproverList = false;
        let isAdministrator = user.role === "ADMINISTRATOR" ? true : false;
        let decision = "";

        co.approverList.forEach((approver) => {
            if (approver.user._id === user._id)
            {
                if (!approver.action)
                {
                    isActionPerformed = false;
                }
                decision = approver.action;
                isInApproverList = true;
                return
            }
        });
        return {isActionPerformed, decision, isInApproverList, isAdministrator};
    }

    showDiffToolRightSideBar(event, item)
    {
        let { diffToolRightSideBar }   = this.state
        let parentId = item.parent ? item.parent : item._id
        diffToolRightSideBar.parent = parentId
        if(diffToolRightSideBar.open)
        {
            this.closeDiffToolRightSideBar();
        }

        let model = item.alias === "cmp" ? "componentRevision" : "productRevision"
        if(item.revisions.length > 0)
        {
            let showLoading = true;
            this.setState({showLoading})
            let data = {
                parentId: parentId
            }
            API[model].getFullTree({parentId}, (err, data) =>
            {
                if(data)
                {
                    let {parent} = data
                    let result   = Utils.compareSouceAndTargetChilds(parent.sourceRevChildren, parent.targetRevChildren, parent);
                    parent          = result.parentCmp;
                    parent.children = result.children;
                    Utils.compareChildsRecursively(parent)

                    diffToolRightSideBar.assemblyDiffCount = this.diffCountSummary(parent)
                    diffToolRightSideBar.sourceRev = parent.sourceRev
                    diffToolRightSideBar.targetRev = parent.targetRev
                    diffToolRightSideBar.alias     = item.alias
                    diffToolRightSideBar.open      = true
                    showLoading                    = false
                    this.setState({ diffToolRightSideBar, showLoading })
                }
            });
        }
    }

    closeDiffToolRightSideBar(event)
    {
        //document.body.removeEventListener("click", this.onDiffToolRightSideBarExternalClick)
        let { diffToolRightSideBar }       = this.state
        diffToolRightSideBar.open          = false
        this.setState({ diffToolRightSideBar })
    }

    onDiffToolRightSideBarExternalClick(event)
    {
        let target     = event.target
        let parent     = this.refs.diffToolRightSideBarNode
        let isExternal = target !== parent && !Utils.isDescendant(parent, target)
        if(isExternal) this.closeDiffToolRightSideBar()
    }

    diffCountSummary(parentCmp)
    {
        let summaryCount = {
            childAddedCount: 0,
            childUpdatedCount: 0,
            childRemovedCount: 0,
        }
        Utils.setNestedChildModificationCounter({children: parentCmp.children}, parentCmp, 'revision')
        summaryCount.childAddedCount = 0
        summaryCount.childUpdatedCount = 0
        summaryCount.childRemovedCount = 0

        for(let child of parentCmp.children)
        {
            summaryCount.childAddedCount = summaryCount.childAddedCount + Number(child.assemblyRevision.aggchildAdded)
            summaryCount.childUpdatedCount = summaryCount.childUpdatedCount + Number(child.assemblyRevision.aggchildUpdated)
            summaryCount.childRemovedCount = summaryCount.childRemovedCount + Number(child.assemblyRevision.aggchildRemoved)

            switch(child.rowClassName)
            {
                case "add" :
                {
                    summaryCount.childAddedCount++
                    break
                }
                case "remove" :
                {
                    summaryCount.childRemovedCount++
                    break
                }
                case "update" :
                {
                    summaryCount.childUpdatedCount++
                    break
                }
                default :
                {
                    // noop
                }
            }
        }
        return summaryCount;
    }

    render()
    {
        let { co }       = this.props.changeorders.changeOrder
        if(!co){
            return null;
        }
        let approverList = []
        co.approverList.forEach((approver) =>
        {
            approver.user.decision = approver.action
            approver.user.invitedAt = approver.invitedAt
            approverList.push(approver.user)
        })
        let view = this.state.view
        let closeClazz = ""
        if (co.status === "CLOSED" && co.resolution === "REJECTED")
        {
            closeClazz = "close-with-reject"
        }
        else if (co.status === "CLOSED" && co.resolution === "APPROVED")
        {
            closeClazz = "close-with-approve"
        }
        let documents = co.documents ? co.documents : [];

        let markup =
            <div className="view-changeorder-route app-row view-changeorder-route-block">
                <div className="content-inner-block">
                    <Helmet>
                        <title>{Utils.makeTitle("["+ co.con + "] "+co.name)}</title>
                    </Helmet>
                    <div className="header-section">
                        <ul className="status-nav">
                            <li className={"status-item " + (co.status === "DRAFT" ? co.status : "")}>DRAFT</li>
                            <li><Icon src={coStatusNavArrow} /></li>
                            <li className={"status-item " + (co.status === "OPEN" ? co.status : "")}>OPEN</li>
                            <li><Icon src={coStatusNavArrow} /></li>
                            <li className={"status-item " + closeClazz}>CLOSED</li>
                        </ul>
                        <Actions {...this.props} co={co} onChange={this.onChange} onDelete={this.onDelete} actionAndActionStatus={this.getActionAndActionStatus()} showRevisionProgressModal={this.state.showRevisionProgressModal} onRevisionsInProgressModalClose={this.onRevisionsInProgressModalClose} imagesWithSrc={this.imagesWithSrc} />
                    </div>
                    <div className="align-block">
                        <Details co={co} actionAndActionStatus={this.getActionAndActionStatus()}/>
                    </div>
                </div>
                <div className="tabs-block">
                    <div className="nav-block display-flex-sb">
                        <div
                            className={view === "childrenData" ? "selected" : ""}
                            onClick={() => this.show("childrenData")}
                            >
                            <div className="prd-cmp-holder">
                                <Icon src={prdCmpSrc} />
                                <h2>Products and Components</h2>
                            </div>
                        </div>
                        <div
                            className={view === "approverList" ? "selected" : ""}
                            onClick={() => this.show("approverList")}
                            >
                            <div>
                                <InlineIcon className={"active"}>
                                  <ApproverIcon />
                                </InlineIcon>
                                <h2>Approver List</h2>
                            </div>
                        </div>
                        <div
                            className={view === "notificationList" ? "selected" : ""}
                            onClick={() => this.show("notificationList")}
                            >
                            <div>
                                <Icon
                                    src={notificationSrc}
                                    className={"active"}
                                />
                                <h2>Notification List</h2>
                            </div>
                        </div>
                        <div
                            className={view === "documents" ? "selected" : ""}
                            name="documents"
                            onClick={() => this.show("documents")}
                        >
                            <div className="documents-holder">
                                <Icon
                                    src={documentSrc}
                                    className={view === "documents" ? "" :"deactive"}
                                />
                                <h2>Documents</h2>
                            </div>
                        </div>
                        <div
                            className={view === "history" ? "selected" : ""}
                            onClick={() => this.show("history")}
                            >
                            <div>
                                <Icon
                                    src={revsSrc}
                                    className={"active"}
                                />
                                <h2>History</h2>
                            </div>
                        </div>
                    </div>
                    <div className="view-block">
                        {
                            view === "childrenData" &&
                            <Children
                                co={co}
                                history={this.props.history}
                                user={this.props.user}
                                imagesWithSrc={this.imagesWithSrc}
                                mode={"view"}
                                showDiffToolRightSideBar={this.showDiffToolRightSideBar}
                            />
                        }
                        {
                            view === "history" &&
                            <HistoryTable
                                co={co}
                                user={this.props.user}
                                imagesWithSrc={this.imagesWithSrc}
                            />
                        }
                        {
                            view === "approverList" &&
                            <div>
                                <ApproverListTable {...this.props}
                                    coApproverList={approverList}
                                    mode="view"
                                    user={this.props.user}
                                    co={co}
                                    imagesWithSrc={this.imagesWithSrc}
                                    getChangeOrderFromApi={this.getChangeOrderFromApi}
                                    />
                            </div>
                        }
                        {
                            view === "notificationList" &&
                            <div>
                                <UserNotificationTable {...this.props}
                                    coInternalNotifyUsers={co.coInternalNotifyUsers}
                                    coExternalNotifyUsers={co.coExternalNotifyUsers}
                                    user={this.props.user}
                                    co={co}
                                    mode="view"
                                    imagesWithSrc={this.imagesWithSrc}
                                    />
                            </div>
                        }
                        {
                            view === "documents" &&
                            <Documents
                                documents={documents}
                                objectData={co}
                                isDiffTool={false}
                                actionAndActionStatus={this.getActionAndActionStatus()}
                                user={this.props.user}
                                cmptype="co"
                            />
                        }
                    </div>
                </div>
                {
                    this.state.showLoading &&
                    <Loading/>
                }
                {
                    this.state.diffToolRightSideBar.open &&
                    <span ref="diffToolRightSideBarNode" className="diff-side-bar-holder">
                    <DiffToolRightSideBar
                        mode={"co"}
                        alias={this.state.diffToolRightSideBar.alias}
                        sourceRev={this.state.diffToolRightSideBar.sourceRev}
                        targetRev={this.state.diffToolRightSideBar.targetRev}
                        history={this.props.history}
                        parent={this.state.diffToolRightSideBar.parent}
                        onExternalClick={this.onDiffToolRightSideBarExternalClick}
                        closeDiffToolRightSideBar={this.closeDiffToolRightSideBar}
                        assemblyDiffCount={this.state.diffToolRightSideBar.assemblyDiffCount}
                    />
                    </span>
                }
            </div>
        return markup
    }
}

export default connect((store) => store)(ViewChangeOrder)
