import { Box, styled } from "@mui/material";
import { BreadCrumbComponent, IBreadCrumbProps } from "common/components/breadcrumb";
import { PageContent, PageHeader, PageTitle, RightSidePanel } from "common/components/pageLayout";
import { IToolbarActions, Toolbar } from "common/components/toolbar";
import { ComponentType, FC, MouseEventHandler, ReactElement, ReactNode, useMemo } from "react";
import { Helmet } from "react-helmet";
import { makeTitle } from "utils/helper";

export interface IPageWrapperProps {
  breadCrumbOptions: IBreadCrumbProps[];
  children: ReactNode | JSX.Element | ReactElement;
  helmetTitle: string;
  pageHeading?: string;
  rightSidePanelProps?: {
    contentClassName?: string;
    onClose?: MouseEventHandler<HTMLButtonElement>;
    panelClassName?: string;
    RightSidePanelChildren: ReactNode | JSX.Element | ReactElement
    title?: string;
    TitleIcon?: ComponentType;
    visible: boolean;
    width?: string;
  }
  toolbarProps: {
    actionLabel?: string;
    leftAction?: IToolbarActions[];
    toolbarMode?: JSX.Element;
    toolbarItems: IToolbarActions[];
  };
}

export const PageWrapper: FC<IPageWrapperProps> = ({
  breadCrumbOptions,
  children,
  helmetTitle,
  pageHeading,
  rightSidePanelProps,
  toolbarProps: { toolbarItems, ...toolbarRest },
}: IPageWrapperProps) => {
  const { RightSidePanelChildren, visible = false, ...rightSidePanelRest } = rightSidePanelProps ?? {};
  const tabTitle = useMemo(() => (makeTitle(helmetTitle)), [helmetTitle]);
  return (
    <>
      <MainSection>
        <Helmet>
          <title>{tabTitle}</title>
        </Helmet>
        <SubSection>
          <PageHeader>
            <BreadCrumbComponent breadCrumbOptions={breadCrumbOptions} />
            {
              toolbarItems.length ? <Toolbar
                centerActions={toolbarItems}
                {...toolbarRest}
              /> : null
            }
          </PageHeader>
          <PageTitle>{pageHeading}</PageTitle>
          <PageContent >
            {children}
          </PageContent>
        </SubSection>
        <RightSidePanel
          visible={visible}
          {...rightSidePanelRest}
        >
          {RightSidePanelChildren}
        </RightSidePanel>
      </MainSection>
    </>
  );
};

export const MainSection = styled(Box)(() => ({
  display: "flex",
  flexGrow: 1,
  overflow: "hidden",
}));

export const SubSection = styled(Box)(() => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  overflow: "hidden",
  padding: "1.5rem 1.5rem 1rem",
}));
