import { fork, all }            from 'redux-saga/effects';
import changeOrderForm          from "./form"
import changeOrderCommonSaga    from "./common"

export default function* changeOrderSaga(context = {}) {
    yield all([
        fork(changeOrderForm, context),
        fork(changeOrderCommonSaga, context)
    ]);
}
