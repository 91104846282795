import                      "./index.css"
import { Component } from "react"
import { connect } from "react-redux"
import buildAction          from "../../../helpers/buildAction"
import Query                from "../../../modules/query"
import SEARCH               from "../../../action-types/search"
import ITEM_ASSEMBLY        from "../../../action-types/item-assembly"
import Utils                from "../../../modules/utils"
import { NavBar }           from "common/components/navBar";

class Sidebar extends Component
{
    constructor(props, context) {
        super(props, context);
        this.onOptionClick     = this.onOptionClick.bind(this)
        this.setSearchState    = this.setSearchState.bind(this)
        this.handleRezise      = this.handleRezise.bind(this)
        this.ActiveTab         = this.activeTab.bind(this)

        this.openCloseItemListForOnBoarding      = this.openCloseItemListForOnBoarding.bind(this)
        this.allowedRoutes     = ["/search", "/components", "/products"]

        this.state             = {
                openItemList: false,
                widthClass: "",
                style: Utils.getStore("side-bar-styles") || {
                    width: "270",
                    minWidth: "270"
                }
            }

        this.initialState       = Utils.clone(this.state)
    }

    onOptionClick(event, option)
    {
        event.preventDefault();
        const { pathname, state: optionState } = option;
        this.allowedRoutes.includes(pathname) && this.setSearchState(option);
        window.changeLeftNavHeight();

        switch (optionState.query) {
            case "type:prd":
                this.props.history.push({ pathname, state: optionState });
                const { dispatch } = this.props;
                dispatch(buildAction(ITEM_ASSEMBLY.MARK_ASSEMBLY_UN_SELECTED));
                let state = this.state;
                state.openItemList = true;
                this.setState(state);
                break;
            default: 
                this.props.history.push({ pathname, state: optionState });
        }
    }

    componentDidMount()
    {
        this.setScrollHight()
        this.handleRezise()
        window.addEventListener('resize', this.handleRezise)
    }

    componentWillUnmount()
    {
        window.removeEventListener('resize', this.handleRezise)
    }

    setScrollHight()
    {
        window.changeLeftNavHeight()
    }

    componentWillReceiveProps(nextProps)
    {
        let state = this.state
        const {dispatch} = this.props;

        if(nextProps.item_assembly.openItemList)
        {
            state.openItemList = true
            dispatch(buildAction(ITEM_ASSEMBLY.SET_OPEN_ITEM_LIST, false));
        }

        if (this.props.item_assembly.resetAssembly || (!this.isValidRoute() && JSON.stringify(this.initialState) !== JSON.stringify(this.state)))
        {
            state = this.initialState
            state.style.class = this.state.style.class
            dispatch(buildAction(ITEM_ASSEMBLY.RESET_STATE));
            dispatch(buildAction(ITEM_ASSEMBLY.RESET_ITEM_LIST, false));
        }

        if (this.props.on_boarding.currentEnv === "SANDBOX" && this.props.location.onBoarding && window.location.pathname.match("/product/view") && this.props.item_assembly.productList.length)
        {
            this.openCloseItemListForOnBoarding(true)
        }
        else if (this.props.on_boarding.currentEnv === "SANDBOX" && this.props.on_boarding.displayOnBoardingNav && this.props.location.onBoarding && !window.location.pathname.match("/product/view"))
        {
            this.openCloseItemListForOnBoarding(false)
        }

        this.setState(state)
    }

    componentDidUpdate(prevProps, prevState)
    {
        this.setScrollHight()

        window.changeRoutesWidth()

        if (!this.isValidRoute())
        {
            let element = document.getElementById("componentNavLink")
            if ( element ) element.classList.remove("list-bottom");
            element = document.getElementById("changeOrderNavLink")
            if ( element ) element.classList.remove("list-bottom");
        }

    }

    isValidRoute()
    {
        let route = window.location.pathname
        let isValidRoute = false
        if (
                (
                    route.match("/component")   ||
                    route.match("/product")     ||
                    route.match("/changeorder") ||
                    route.match("/dashboard")   ||
                    route.match("/components")  ||
                    route.match("/search")      ||
                    route.match("/export")

                )
            )
            isValidRoute = true
        return isValidRoute
    }

    setSearchState(option) {
        const {dispatch} = this.props;
        let value  = option.state ? option.state.query : ""
        let query  = Query.parse(value)
        let string = Query.stringify(query)

        const payload = {
          value: value,
          lean: true,
          query: {
            string: string,
            object: query
          }
        }
        dispatch(buildAction(SEARCH.RESET_STATE, payload));
        dispatch(buildAction(SEARCH.GET_SEARCH_DATA));
    }

    handleRezise()
    {
        let style = this.state.style
        let currentWidth = style.class === 'narrow' ? 55 : style.width

        if (window.innerWidth <= 768) {
            style.class = 'narrow'
            style.width = 55
            style.minWidth = 55
        } else {
            style.class = currentWidth === 55 ? 'narrow' : ''
            style.width = currentWidth
            style.minWidth = currentWidth
        }

        this.setState({ style })
    }

    openCloseItemListForOnBoarding(openCloseFlag)
    {
        let state = this.state
        state.openItemList = openCloseFlag
        if (state.openItemList && this.props.item_assembly.productList.length)
        {
            let element = document.getElementById("componentNavLink")
            if ( element ) element.classList.add("list-bottom");
            element = document.getElementById("changeOrderNavLink")
            if ( element ) element.classList.add("list-bottom");
        }
        else
        {
            let element = document.getElementById("componentNavLink")
            if ( element ) element.classList.remove("list-bottom");
            element = document.getElementById("changeOrderNavLink")
            if ( element ) element.classList.remove("list-bottom");
        }
        this.setState(state)
    }

    activeTab() {
        const path = this.props.location.pathname;
        const query = this.props.search.value;
        const SearchPage = {
            "type:co": "Change Orders",
            "type:rel": "Releases",
            "type:all": "",
        };
        const navigateTo = {
            "/changeorder": "Change Orders",
            "/component": "Components",
            "/dashboard": "Dashboard",
            "/instance": "Instances",
            "/lot": "Lots",
            "/production": "Productions",
            "/product": "Products",
            "/release": "Releases",
            "/search": SearchPage[query],
        };

        const key = Object.keys(navigateTo).find(key => path.match(key));
        return navigateTo[key] || "";
    }

    render()
    {
        return <NavBar navigateTo={this.onOptionClick} activeTab={this.activeTab()} currentLibraryType={window.__libraryType} />;
    }
}

export default connect((store) => store)(Sidebar)
