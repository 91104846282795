import { Chip } from "@mui/material";
import { CSSProperties, useMemo } from "react";
import { TileContentLabel, TileContentWrapper } from "./tileContent";

interface TileContentStatusProps {
  color?: "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
  labelStyle?: CSSProperties;
  status: string;
}

export function TileContentStatus(props: TileContentStatusProps) {
  const { status, color } = props;
  const cStyle = useMemo(() => color ?? "success", [color]);
  return (
    <TileContentWrapper>
      <TileContentLabel label="Status" />
      <Chip label={status} color={cStyle} />
    </TileContentWrapper>
  );
}
