import { FetchPolicy, gql, useLazyQuery, useQuery } from "@apollo/client";
import { Image } from "common/models";
import { ImageFragment } from "graphql/fragment/imageFragment";
import { useCallback, useMemo } from "react";

export const GET_IMAGE = gql`
  query imageById($id: ID!) {
    imagesByIds(ids: [$id]) {
      ...ImageFragment
    }
  }
  ${ImageFragment}
`;

export const GET_IMAGES = gql`
  query($ids: [ID]!) {
    imagesByIds(ids: $ids) {
      ...ImageFragment
    }
  }
  ${ImageFragment}
`;

interface ImageQueryData {
  imagesByIds?: Image[] | null;
}

export function useImage(id: string, fetchPolicy: FetchPolicy = "cache-first") {
  const { data, error, loading } = useQuery<ImageQueryData>(GET_IMAGE, {
    fetchPolicy,
    skip: !id,
    variables: { id },
  });

  const image = useMemo(() => data?.imagesByIds?.[0], [data?.imagesByIds]);

  return { image, imageError: error, imageLoading: loading };
}

export function useImages(ids: string[], fetchPolicy: FetchPolicy = "cache-first") {
  const { data, error, loading } = useQuery<ImageQueryData>(GET_IMAGES, {
    fetchPolicy,
    skip: !ids.length,
    variables: { ids },
  });

  const images = useMemo(() => data?.imagesByIds, [data?.imagesByIds]);

  return { images, imageError: error, imageLoading: loading };
}

export function useImageList(fetchPolicy: FetchPolicy = "cache-first") {
  const [fetchImages, { data }] = useLazyQuery(GET_IMAGES, { fetchPolicy });

  const fetchImageByIds = useCallback((ids: string[]) => {
    ids?.length && fetchImages({
      variables: { ids },
    });
  }, [fetchImages]);

  return {
    fetchImageByIds,
    imgData: data,
  };
}
