import { connect } from "react-redux";
import React, { Component } from "react";
import Permissions from "../../../../modules/schemas/permissions";
import API from "../../../../modules/api";
import Loading from "../../../ui/inner-loading";
import Actions from "./actions";
import { Helmet } from "react-helmet";
import Utils from "../../../../modules/utils";
import PerfectScrollbar from 'react-perfect-scrollbar';
import ItemOverView from "../../common/specifications/item-overview";
import ItemDetails from "../../common/specifications/item-details";
import Tabs from "../../common/tabs/view";

export class ViewProductionInstance extends Component
{

    constructor(props, context)
    {
        super(props, context);

        this.initialState =
        {
            productionInstance: null,
        }


        this.state = {
            ...this.initialState
        }

        this.resetPageStates = this.resetPageStates.bind(this);
        this.getProductionInstanceFromApi = this.getProductionInstanceFromApi.bind(this);
        this.loadProductAndSetLocalStorage = this.loadProductAndSetLocalStorage.bind(this);
        this.getNestedData = this.getNestedData.bind(this);
    }

    componentDidMount()
    {
        let id = Utils.getIdFromParams(this.props.match.params);
        this.getProductionInstanceFromApi(id);
    }

    componentWillMount()
    {
        this.loadProductAndSetLocalStorage();
    }

    componentWillUnmount()
    {
        this.resetPageStates();
    }

    loadProductAndSetLocalStorage()
    {
        let id = Utils.getIdFromParams(this.props.match.params);

        this.getProductionInstanceFromApi(id);
        Utils.setLocalStorageForAssemblyTree(id);
    }

    componentWillReceiveProps(nextProps){
        let nextId = Utils.getIdFromParams(nextProps.match.params);
        let previousId = Utils.getIdFromParams(this.props.match.params);

        if (nextId !== previousId)
        {
            this.resetPageStates();
            this.getProductionInstanceFromApi(nextId);
        }
    }

    getProductionInstanceFromApi(id)
    {
        id = `${id}?include=children,creator,images`;
        API.productionInstances.findById(id, (err, data) =>
        {
            if (data)
            {
                this.setState({ productionInstance: data });
            }
        })
    }

    getNestedData(name)
    {
        let id = `${Utils.getIdFromParams(this.props.match.params)}?include=${name}`;
        API.productionInstances.findById(id, (err, data) =>
        {
            if (data)
            {
                let { productionInstance } = this.state;
                productionInstance.documents = data.documents;
                this.setState({ productionInstance: productionInstance, documents_loaded: true });
            }
        })
    }

    resetPageStates()
    {
        this.setState(this.initialState);
    }

    render()
    {
        let { productionInstance } = this.state;
        let { user } = this.props;
        let isSerializationEnabled = window.__isSerializationEnabled && window.__libraryType === "GENERAL";

        if (!productionInstance || !Permissions.can("view", "production_instance", user.data) || !isSerializationEnabled)
        {
            return (<Loading />)
        }

        let markup =
            <div className="view-product-route">
                <Helmet>
                    <title>{Utils.makeTitle(`[${productionInstance.cpn}] ${productionInstance.name}`)}</title>
                </Helmet>

                <div className="top-block">
                    <Actions
                        productionInstance={productionInstance}
                        user={user}
                    />

                    <div className="tiles-wrapper">
                        <PerfectScrollbar className="tiles-section-scroll">
                            <ItemOverView
                                item={productionInstance}
                                alias={productionInstance.alias}
                                isProductionInstance={true}
                            />
                            <ItemDetails
                                item={productionInstance}
                                isProductionInstance={true}
                            />
                        </PerfectScrollbar>
                    </div>
                </div>

                <Tabs
                    tabsType="productionInstance"
                    productionInstance={productionInstance}
                    componentCategory={productionInstance.category}
                    documents={productionInstance.documents}
                    assemblyData={productionInstance.children}
                    paramId = {this.props.match.params.id}
                    getNestedData={this.getNestedData}
                    documents_loaded={this.state.documents_loaded}
                    children_loaded={true}
                    user={this.props.user}
                />
            </div>

        return markup
    }
}

export default connect((store) => ({
    user: store.user,
}))(ViewProductionInstance)
