import React, { Component }      from "react"

const SvgIcon = () =>
{
    return <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <defs></defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="3_MODAL" transform="translate(-435.000000, -152.000000)">
                    <g id="Modal" transform="translate(393.000000, 91.000000)">
                        <g id="ERROR-ICON" transform="translate(42.000000, 61.000000)">
                            <circle id="Oval" fill="#F54A4F" fillRule="nonzero" cx="10" cy="10" r="10"></circle>
                            <text id="!" fontFamily="Roboto-Bold, Roboto" fontSize="14" fontWeight="bold" fill="#000000">
                                <tspan x="8" y="15">!</tspan>
                            </text>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
}

export default SvgIcon
