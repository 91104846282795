import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      viewBox="0 0 20 19"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-706 -210)">
          <g transform="translate(429 44)">
            <g transform="translate(50 163)">
              <g transform="translate(205.5) translate(19)">
                <path d="M0 0L25 0 25 25 0 25z"></path>
                <g
                  fillRule="nonzero"
                  stroke="#CFCFCF"
                  strokeWidth="1.5"
                  transform="translate(3 3)"
                >
                  <path strokeLinecap="round" d="M4.56 4.778v11.198"></path>
                  <path strokeLinecap="square" d="M10.26 8.556H5.067"></path>
                  <rect width="7.62" height="4" x="0.75" y="0.75" rx="2"></rect>
                  <rect
                    width="7.62"
                    height="4"
                    x="10.63"
                    y="6.147"
                    rx="2"
                  ></rect>
                  <rect
                    width="7.62"
                    height="4"
                    x="10.63"
                    y="13.433"
                    rx="2"
                  ></rect>
                  <path strokeLinecap="round" d="M10.26 16.111h-5.7"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
