import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { InstanceState, Source } from "build/models";
import { BuildCpnField, InstanceStateField, SerialNumberField } from "../fields";

export function instanceStateRenderCell({ row }: GridRenderCellParams<any, { item: { state?: InstanceState } }>) {
  return <InstanceStateField state={row.item.state} />;
}

export type SerialNumberRenderCellArgs = GridRenderCellParams<any, {
  item: {
    id: string;
    serialNumber?: {
      displayValue: string;
    };
    topLevel?: boolean;
  };
}>;

export function serialNumberRenderCell({ row }: SerialNumberRenderCellArgs) {
  if (!row.item.serialNumber) return "";

  return (
    <SerialNumberField
      displayValue={row.item.serialNumber.displayValue}
      instanceId={row.item.id}
      displayBackground={row.item.topLevel}
    />
  );
}

export type BuildCpnRenderCellArgs = GridRenderCellParams<any, {
  item: {
    cpn: string;
    id: string;
    revision?: {
      cpn: string;
      source: Source;
    };
    source?: Source;
  };
}>;

export function buildCpnRenderCell({ row }: BuildCpnRenderCellArgs) {
  const source = row.item.source ?? row.item.revision?.source;
  const cpn = row.item.cpn ?? row.item.revision?.cpn;

  if (!cpn || !source || !(source.url || source.extId)) return "";

  return <BuildCpnField cpn={cpn} {...source} />;
}
