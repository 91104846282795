import { useMemo } from "react";
import { Box, Tooltip, Typography, styled } from "@mui/material";
import { InputField, Select } from "common/components/inputs";
import { InputFieldVariants } from "common/constants";
import { Component, Product } from "design/models";
import { colorPalette } from "theme";
import ItemSpecsIcon from "v1/assets/icons/item-specs-icon";
import TileItem from "../../tile-item";

type CustomTypographyProps = {
  type: string
}

interface CustomSpecInputFieldProps {
  allowedValues: string[],
  error: ErrorObj,
  index: number,
  isDropDown: boolean,
  onChange: Function,
  value: string,
}

const componentStyle = {
  padding: "0",
  width: "7.875rem",
};

const enum CustomSpecsEnum {
  KEY = "KEY",
  VALUE = "VALUE",
}
const CUSTOM_SPECS = "customSpecs";

type ErrorObj = {
  valid: boolean,
  message: string,
}

const inputProps = {
  sx: {
    padding: "0.25rem 0 0 0.25rem",
  },
};

interface ICustomSpecs {
  key: string,
  value: string,
  message: string,
  valid: boolean,
  allowedValues: string[],
  isDropDown: boolean,
}

interface ItemCustomSpecsProps {
  item: (Component | Product) & { customSpecs?: ICustomSpecs[] };
  isEditing: boolean;
  onInputChange: Function;
  title: string,
}

const fieldWrapper = (spec: string, type: string) =>
  <Tooltip title={spec} placement="top-end">
    <TypographyTextStyle type={type} >
      {spec.length > 13 ? `${spec.substring(0, 13)}...` : spec}
    </TypographyTextStyle>
  </Tooltip>;

const CustomSpecInputField = ({
  allowedValues,
  error,
  index,
  isDropDown,
  onChange,
  value,
}: CustomSpecInputFieldProps) => {
  const isRequired = useMemo(() => !!allowedValues.length && !allowedValues.includes(""), [allowedValues]);
  return (
    <Tooltip title={error.message}>
      {isDropDown
        ? <Select
          componentStyles={componentStyle}
          name={CUSTOM_SPECS}
          inputProps={inputProps}
          onChange={event => onChange(event, index)}
          options={allowedValues}
          isRequired={isRequired}
          value={value}
        />
        : <InputFieldStyle
          error={!error.valid}
          name={CUSTOM_SPECS}
          value={value}
          isRequired={isRequired}
          onChange={event => onChange(event, index)}
          variant={InputFieldVariants.FILLED}
        />
      }
    </Tooltip>
  );
};

export const ItemCustomSpecs = ({ item, isEditing, onInputChange, title }: ItemCustomSpecsProps) => {
  const { customSpecs } = item;

  if (!customSpecs?.length) return null;
  return (
    <>
      <TileItem title={title} icon={<ItemSpecsIcon />}>
        {customSpecs.map((spec: ICustomSpecs, index: number) => {
          const { key, value, isDropDown, allowedValues, valid, message } = spec;
          return (
            <CustomSpecsWrapper key={index}>
              {fieldWrapper(key, CustomSpecsEnum.KEY)}
              {
                isEditing
                  ? <CustomSpecInputField
                    allowedValues={allowedValues}
                    value={value}
                    onChange={onInputChange}
                    index={index}
                    error={{ valid, message }}
                    isDropDown={isDropDown}
                  />
                  : fieldWrapper(value, CustomSpecsEnum.VALUE)
              }
            </CustomSpecsWrapper>
          );
        })}
      </TileItem>
    </>
  );
};

const CustomSpecsWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  paddingBottom: "0.1rem",
}));

const TypographyTextStyle = styled(Typography, {
  shouldForwardProp: propName => propName !== "type",
})<CustomTypographyProps>(({ type }) => ({
  alignItems: "center",
  color: type === CustomSpecsEnum.KEY ? colorPalette.taupeGray : colorPalette.white,
  fontSize: "0.9rem",
  maxWidth: "96pt",
  overflow: "hidden",
  textTransform: type === CustomSpecsEnum.KEY ? "uppercase" : "inherit",
  whiteSpace: "nowrap",
}));

const InputFieldStyle = styled(InputField)(() => ({
  alignItems: "center",
  display: "flex",
  overflow: "hidden",
  width: "48%",
}));
