import Utils        from "../../utils"
import Schemas      from "../../schemas"

const UidAPI =
{
    products : (data, cb) =>
    {
        Utils.post("/uids/products", {}, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })

    },

    components : (category = '', cb) =>
    {
        Utils.post("/uids/components", {category: category}, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })

    },

    changeOrders : (type = 'ECO', cb) =>
    {
        Utils.post("/uids/changeorders", {type: type}, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })

    }

}

export default UidAPI
