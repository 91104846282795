import                 "./index.css";
import React           from "react";
import ModalBox        from "../../../../ui/modal-box";
import FileDrop        from "../../../../ui/file-drop";
import API             from "../../../../../modules/api";
import Loading         from "../../../../ui/inner-loading";
import Utils           from "../../../../../modules/utils";
import config          from '../../../../../modules/config';
import UI              from '../../../../../action-types/ui';
import CopyToClipboard from "../../../common/copy-to-clipboard";
import LazyInput       from "../../../../ui/lazy-input/input.js";
import buildAction     from "../../../../../helpers/buildAction";
import InlineIcon      from "../../../../ui/icon/inline-icon.js";
import CrossIcon       from "../../../../../assets/icons/cross-icon.js";

export class ConfigurationModal extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            duroEntityId: "",
            idpEntityId: "",
            certificate: "",
            entryPoint: "",
            idpName: "JumpCloud",
            fileUploaded: false,
            fileName : "",
            copyEntityId: false,
            copyAcsUrl: false,
            loading: true
        }

        this.onCopy = this.onCopy.bind(this);
        this.remove = this.remove.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onClose = this.onClose.bind(this);
        this.showAlert = this.showAlert.bind(this);
        this.onFileDrop = this.onFileDrop.bind(this);
        this.onIdpChange = this.onIdpChange.bind(this);
        this.createIdpOptions = this.createIdpOptions.bind(this);
        this.renderUploadedFile = this.renderUploadedFile.bind(this);
    }

    componentDidMount()
    {
        let state = this.state;
        API.ssoIntegration.generateIdAndUrl((err, res) =>
        {
            if(err)
            {
                return;
            }
            state.duroEntityId = res.duroEntityId;
            state.loading = false;
            this.setState(state);
        })
    }

    onFileDrop(files)
    {
        let file = files[0];
        let state = this.state;
        if (!file || Utils.haveXMLFileExtension(file.name) === false)
        {
            this.showAlert([{"message": "Only .xml file is allowed."}]);
            return;
        }
        let data = {file: file, specs: state.idpName};
        API.components.parseFile(data, (err, data) =>
        {
            if(err)
            {
                this.showAlert(err.errors);
                return console.error(err);
            }
            let updatedData = {
                entryPoint: data.entryPoint,
                idpEntityId: data.entityId,
                certificate: data.certificate,
                fileUploaded: true,
                fileName: file.name,
            }
            this.setState(updatedData);
        })
    }

    showAlert(errors)
    {
        let { dispatch } = this.props;
        dispatch(buildAction(UI.SHOW_ALERT, {type: "errors", errors: errors, donotThrowError: true}))
    }

    createIdpOptions()
    {
        let availableIdps = ['JumpCloud', 'OneLogin'];
        let options = [];
        availableIdps.forEach((idp, i) => {
            options[i] = <option value={idp}>{idp}</option>
        })
        return options;
    }

    onIdpChange(event, i)
    {
        this.setState({idpName: event.target.value});
    }

    onSave()
    {
        let _this = this;
        let payload = {};
        let state = this.state;
        payload['idpName'] = state.idpName;
        payload['entryPoint'] = state.entryPoint;
        payload['certificate'] = state.certificate;
        if(this.props.integrations && this.props.integrations['SSO'])
        {
            payload['toBeUpdated'] = true;
            payload['id'] = this.props.integrations['SSO'].id;
        }

        API.ssoIntegration.uploadMetaData(payload, (err, res) => {
            if(err)
            {
                return;
            }
            _this.onClose();
            _this.props.getIntegrations();
        })
    }

    remove()
    {
        let updatedData =
        {
            entry: "",
            idpEntityId: "",
            certificate: "",
            fileUploaded: false,
            fileName: "",
        }
        this.setState(updatedData);
    }

    renderUploadedFile()
    {
        let state = this.state;
        let { fileName } = state;
        return (
            <div className="inner-block">
                <div className="file-name">
                    {fileName}
                </div>
                <div className="file-remove" onClick={this.remove}>
                    <InlineIcon>
                        <CrossIcon/>
                    </InlineIcon>
                </div>
            </div>
        )
    }

    onCopy(event)
    {
        let name = event.target.name;
        this.setState({[name]: true});
        setTimeout(()=>{
            this.setState({[name]: false});
        },1200);
    }

    onClose()
    {
        this.props.onClose("displaySSOModal");
    }

    renderIdpEntity(idpName, idpEntityId)
    {
        return idpName === "JumpCloud" ?
        <div className="form-group">
            <label htmlFor="category">IDP Entity ID</label>
            <LazyInput
            type="text"
            readOnly={true}
            value={idpEntityId}
            />
        </div> : "";
    }

    render()
    {
        let state = this.state;
        let duroAcsUrl = `${config.API_ORIGIN}/saml/consume`;
        let { duroEntityId, idpName, idpEntityId, fileUploaded, copyAcsUrl, copyEntityId, loading } = state;
        let saveBtnClass = `save-btn ${fileUploaded ? '' : 'disabled'}`;
        let labelText = idpName === "OneLogin" ? "" : "Duro (SP) Entity ID And "
        if(loading)
        {
            return <Loading/>
        }
        let markup =
            <div className="configuration-sso-modal-route">
                <ModalBox onClose={this.onClose} className="configuration-sso-modal">
                    <div className="main-block">
                        <div className="content-block">
                            <div className="custom-modal-header">
                                <h2>SSO Configuration</h2>
                            </div>
                            <div className="card-panel">
                                <div className="card-panel-body">
                                    <div className="form-group">
                                        <label htmlFor="category">IDENTITY PROVIDER (IDP)</label>
                                        <select
                                            name='idp'
                                            value={idpName}
                                            onChange={(event) => this.onIdpChange(event)}
                                        >
                                            {this.createIdpOptions()}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="category">{`${labelText}RelayState`}</label>
                                        <div className="item-desc">
                                            <span>{duroEntityId}</span>
                                            <CopyToClipboard textToBeCopied={duroEntityId} tooltipText="Copy to Clipboard" tooltipPosition="top" onCopy={this.onCopy} name="copyEntityId" className={copyEntityId ? 'clicked' : ""}/>
                                            <div className={`text-copied ${copyEntityId ? "" : "empty"}`}>
                                                Copied
                                            </div>
                                        </div>
                                    </div>
                                    {this.renderIdpEntity(idpName, idpEntityId)}
                                    <div className="form-group">
                                        <label htmlFor="category">DURO ACS URL</label>
                                        <div className="item-desc">
                                            <span>{duroAcsUrl}</span>
                                            <CopyToClipboard textToBeCopied={duroAcsUrl} tooltipText="Copy to Clipboard" tooltipPosition="top" onCopy={this.onCopy} name="copyAcsUrl" className={copyAcsUrl ? 'clicked' : ""}/>
                                            <div className={`text-copied ${copyAcsUrl ? "" : "empty"}`}>
                                                Copied
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="category">UPLOAD IDP METADATA FILE</label>
                                        <div className="upload-metadata-block">
                                            <FileDrop
                                                onChange={this.onFileDrop}
                                                isDocument={true}
                                            />
                                            <div className={`uploaded-file ${fileUploaded ? "" : "empty"}`}>
                                                {
                                                    fileUploaded && this.renderUploadedFile()
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-panel-footer">
                                <div className="left-section">
                                    <button
                                        className="dark"
                                        value="cancel"
                                        onClick={this.onClose}
                                        >
                                        CANCEL
                                    </button>
                                </div>
                                <div className="right-section">
                                    <button
                                        type="submit"
                                        value="Save"
                                        className={saveBtnClass}
                                        onClick={fileUploaded ? this.onSave : function(){}}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBox>
            </div>
        return markup
    }
}

export default ConfigurationModal
