import React from "react";
import SourcingUtils from "../sourcing/sourcing-utils";
import CurrencyField from "../currency-field";
import AlertErrorIcon from "../../../../assets/icons/alert-error";
import InlineIcon from "../../../../components/ui/icon/inline-icon.js";

const ExtendedRolledUpCost = (props) =>
{
    let { object , extendedCost, mode, alias} = props;
    let markup = null

    if(window.__customFields && window.__customFields.wasteFieldEnabled){
        let haveInValidCosting = SourcingUtils.haveInValidCosting(object);
        let currencyFieldMarkUp = extendedCost || extendedCost === 0  ? <CurrencyField symbol={object.defaultCurrency} value={extendedCost.toFixed(2)}/> : null;
        let inValidCostingMarkUp = haveInValidCosting === true
            ?
                <span className="not-all-valid">
                    <InlineIcon
                        tooltip="Incomplete Sources"
                        tooltipPlace="top"
                        className={`warningEl ${mode === 'VIEW' ? 'not-all-valid-s' : ''}`}
                    >
                      <AlertErrorIcon />
                    </InlineIcon>
                </span>
            :
                null

        markup =
        <div className="inner-info values-to-right diff-extended-cost-section">
            <span className="inner-attribute">extended cost</span>
            <div
                className={`inner-value${mode === 'VIEW' ? haveInValidCosting === true ? '' : ' valid' : ''}`}
            >
                {
                    mode === 'VIEW' &&
                    <span className="extended-cost">
                        { inValidCostingMarkUp }
                        { currencyFieldMarkUp }
                    </span>
                }
                {
                    mode === 'EDIT' &&
                    <div className={alias === 'cmp' ? 'rolled-up-extended-cost cmp-edit' : ''}>
                        { inValidCostingMarkUp }
                        <span className="extended-cost">
                            { currencyFieldMarkUp }
                        </span>
                    </div>
                }
            </div>
        </div>
    }

    return markup;

}
export default ExtendedRolledUpCost;
