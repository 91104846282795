import React from "react";

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
    >
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g fill='#fff' stroke='#fff' transform='translate(-1126 -163)'>
          <g transform='translate(1102 80)'>
            <g transform='translate(25 81)'>
              <g transform='translate(0 3)'>
                <g>
                  <path
                    fillRule='nonzero'
                    strokeWidth='0.25'
                    d='M8.41 14H5.749a.764.764 0 01-.772-.652l-.222-1.5a5.517 5.517 0 01-.807-.453l-1.464.565a.804.804 0 01-.968-.334L.122 9.364a.745.745 0 01.186-1.01l1.243-.943v-.454-.454L.308 5.569a.736.736 0 01-.186-.976L1.48 2.357c.19-.325.6-.464.958-.326l1.473.574c.256-.173.526-.325.808-.454l.23-1.508c.055-.38.401-.66.8-.642H8.41c.39 0 .719.278.772.65l.222 1.5a5.5 5.5 0 01.808.454l1.464-.565a.804.804 0 011.012.317l1.357 2.261a.745.745 0 01-.195.968l-1.242.943v.908l1.242.916a.745.745 0 01.195.969L12.68 11.6a.786.786 0 01-.959.326l-1.473-.574a5.642 5.642 0 01-.808.454l-.221 1.508a.763.763 0 01-.808.685h0zm-2.617-.849H8.33l.284-1.893.23-.095a4.783 4.783 0 001.057-.59l.204-.146 1.837.71 1.27-2.124L11.65 7.84V7.6a3.97 3.97 0 000-1.182v-.24l1.562-1.173-1.251-2.142-1.837.711-.204-.146a4.477 4.477 0 00-1.048-.59l-.24-.095L8.35.85H5.81l-.284 1.893-.231.094a4.783 4.783 0 00-1.056.591l-.204.146-1.837-.71-1.26 2.124L2.5 6.16v.24a3.97 3.97 0 000 1.182v.24L.938 9.005l1.26 2.124 1.838-.71.204.145c.32.24.672.44 1.047.59l.23.095.276 1.902zM7.08 9.698c-1.55 0-2.805-1.212-2.805-2.707 0-1.495 1.256-2.707 2.805-2.707 1.549 0 2.804 1.212 2.804 2.707a2.66 2.66 0 01-.821 1.915 2.856 2.856 0 01-1.983.792h0zm0-4.549c-1.059 0-1.917.829-1.917 1.85 0 1.023.858 1.851 1.917 1.851 1.058 0 1.917-.828 1.917-1.85 0-1.022-.859-1.85-1.917-1.85z'
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
