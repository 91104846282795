import                    "./index.css";
import React, {Component} from "react";
import ModalBox           from "../../../../ui/modal-box";
import SingleRow          from "./single-row";
import PerfectScrollbar   from 'react-perfect-scrollbar';
import tableDragger       from 'table-dragger';
import Utils              from "../../../../../modules/utils";
import $                  from "jquery";
import ReactDragListView  from 'react-drag-listview';

class CustomizedSpecificationsModal extends Component
{
    constructor(props)
    {
        super(props);
        this.dragDropTable;
        this.columnAvatar = '.dragItemClass';
        this.tableWrap    = '.table-wrapper-scroll';
        this.MinLength    = 1;
        this.MaxLength    = 30;
        this.state =
        {
            checkAll: false,
            mappedHeaders: Utils.clone(this.props.mappedHeaders),
            duroLabels: Utils.clone(this.props.duroLabels),
            disableSaveButton: false,
            middleClass: ""
        }

        this.onClose              = this.onClose.bind(this);
        this.checkAll             = this.checkAll.bind(this);
        this.renderColumnSettings = this.renderColumnSettings.bind(this);
        this.updateMapping        = this.updateMapping.bind(this);
        this.saveCustomizeFields  = this.saveCustomizeFields.bind(this);
        this.toggleCheckboxes     = this.toggleCheckboxes.bind(this);
        this.onMouseLeave         = this.onMouseLeave.bind(this);
        this.onDragStart          = this.onDragStart.bind(this);
        this.toggleSaveButton     = this.toggleSaveButton.bind(this);
        // this.validateHeaders      = this.validateHeaders.bind(this);
        this.getDuplicateHeaders    = this.getDuplicateHeaders.bind(this);
        this.handleOutsideClick   = this.handleOutsideClick.bind(this);
        this.validateCharsLimit   = this.validateCharsLimit.bind(this);


        this.validateHeader = this.validateHeader.bind(this);
        this.validateUniqueness = this.validateUniqueness.bind(this);
        this.validateAll = this.validateAll.bind(this);

        this.dragProps = {
            onDragEnd: this.onMouseLeave,
            handleSelector: ".drag-drop-holder",
            ignoreSelector: "tr.ant-table-expanded-row",
            nodeSelector: "tr",
            lineClassName: "dragItemClass"
        };
    }

    componentDidMount()
    {
        let headers = this.state.mappedHeaders;
        let middleClass = "";
        let {isCheckAll, addMiddleClass} = this.toggleCheckboxes(headers);
        if(addMiddleClass)
        {
            middleClass = "middle";
        }

        document.addEventListener("click", this.handleOutsideClick, false);
        this.setState({checkAll: isCheckAll, middleClass: middleClass});
    }

    componentWillUnmount()
    {
        document.removeEventListener("click", this.handleOutsideClick, false);
    }


    onDragStart()
    {
        $('.drag-drop-holder').find('.ui-icon').addClass('grabbing');
    }

    onMouseLeave(fromIndex, toIndex)
    {
        $('.drag-drop-holder').find('.ui-icon').removeClass('grabbing')
        let state = this.state;
        state.duroLabels.splice(toIndex, 0, state.duroLabels.splice(fromIndex, 1)[0])
        this.setState(state);
    }

    onClose()
    {
        document.removeEventListener("click", this.handleOutsideClick, false);
        this.setState({mappedHeaders: this.props.mappedHeaders});
        this.props.onClose("displaySpecificationsModal", false);
    }

    checkAll(e)
    {
        let headers = this.state.mappedHeaders;
        Object.keys(headers).forEach(function(headerItem){
            headers[headerItem].isChecked = e.target.checked;
        });
        let disableSaveButton = !e.target.checked;
        this.validateAll(headers);
        this.setState({mappedHeaders: headers, checkAll: e.target.checked, middleClass: "", disableSaveButton: disableSaveButton});
    }

    renderColumnSettings(){
        let markup = []
        let _this = this;
        let headers = this.state.mappedHeaders;
        let duroLabels = this.state.duroLabels;
        duroLabels.forEach((Item) => {
          markup.push(<SingleRow onMouseLeave={this.onMouseLeave} onDragStart={this.onDragStart} key={Item} duroLabel={Item} headerSettings={headers[Item]} checkAll={_this.state.checkAll} updateMapping={_this.updateMapping}/>);
        })
        return markup
    }

    updateMapping(duroLabel, userLabel, isChecked, type)
    {
        let headers = this.state.mappedHeaders;
        let state = this.state;
        let oldValue = headers[duroLabel].userLabel;
        headers[duroLabel].isChecked = isChecked;
        headers[duroLabel].userLabel = userLabel;
        let disableSaveButton = false;
        this.validateHeader(duroLabel, oldValue);
        let middleClass = "";
        let {isCheckAll, addMiddleClass} = this.toggleCheckboxes(headers);
        disableSaveButton = this.toggleSaveButton(headers, isCheckAll, addMiddleClass);
        if(addMiddleClass)
        {
            middleClass = "middle";
        }
        this.setState({mappedHeaders: headers, checkAll: isCheckAll, middleClass: middleClass, disableSaveButton: disableSaveButton});
    }

    validateHeader(duroLabel, userLabel){
        let headers = this.state.mappedHeaders;
        headers[duroLabel].invalid = false;
        let dupHeaders = [];
        this.validateCharsLimit(headers, duroLabel);
        if(!headers[duroLabel].invalid){
            dupHeaders = this.validateUniqueness(duroLabel, userLabel);
        }

    }

    validateUniqueness(durolabel, userLabel="")
    {
        let headers = this.state.mappedHeaders;
        let duplicateHeaders = []
        if(headers[durolabel].userLabel){
            let {count, dupHeaders, oldDuplicates} = this.getDuplicateHeaders(durolabel, userLabel);
            if(count > 1){
                dupHeaders.forEach(function(header){
                    headers[header].invalid = true;
                    headers[header].message = "Value must be unique";
                })
            }else{
                headers[durolabel].invalid = false;
                headers[durolabel].message = "";
            }
            if(oldDuplicates.length < 2){
                oldDuplicates.forEach(function(header){
                    headers[header].invalid = false;
                    headers[header].message = "";
                })
            }
            duplicateHeaders = dupHeaders;
        }

        return duplicateHeaders;
    }

    validateCharsLimit(headers, duroLabel){
        if(headers[duroLabel].isChecked){
            if(headers[duroLabel].userLabel.length < this.MinLength)
            {
                headers[duroLabel].invalid = true;
                headers[duroLabel].message = `Value must contain at least ${this.MinLength} character`;
            }
            else if(headers[duroLabel].userLabel.length > this.MaxLength)
            {
                headers[duroLabel].invalid = true;
                headers[duroLabel].message = `Value must be less than ${this.MaxLength} characters`;
            }
        }
        else
        {
            headers[duroLabel].invalid = false;
            headers[duroLabel].message = "";
        }
    }

    getDuplicateHeaders(duroLabel, prevValue)
    {
        let headers = this.state.mappedHeaders;
        let count = 0;
        let dupHeaders = [];
        let oldDuplicates = [];
        let isDisabled = !headers[duroLabel].isChecked;
        let userLabel = headers[duroLabel].userLabel;
        this.state.duroLabels.forEach((item) => {
            if(headers[item].userLabel === userLabel)
            {
                if(headers[item].isChecked)
                {
                    dupHeaders.push(item);
                    count++;
                    if(isDisabled && item != duroLabel){
                        oldDuplicates.push(item);
                    }
                 }
            }
            else if(prevValue && !isDisabled && headers[item].userLabel == prevValue && prevValue != userLabel){
                oldDuplicates.push(item);
            }
        })

        return {count: count, dupHeaders: dupHeaders, oldDuplicates: oldDuplicates};
    }

    validateAll(headers){
        let validationError = false;
        let validatedHeaders = [];
        let _this = this;
        this.state.duroLabels.forEach((headerItem) =>{
            if(headers[headerItem].isChecked)
            {
                // let userLabel = headers[headerItem].userLabel;
                this.validateCharsLimit(headers, headerItem);
                if(!headers[headerItem].invalid && !validatedHeaders.includes(headerItem))
                {
                    let dupHeaders = _this.validateUniqueness(headerItem);
                    validatedHeaders = [...validatedHeaders, ...dupHeaders];
                }
            }
            else
            {
                headers[headerItem].invalid = false;
                headers[headerItem].message = "";
            }
        });
    }



    toggleSaveButton(headers, isCheckAll, addMiddleClass)
    {
        let isInvalid = false;
        this.state.duroLabels.forEach((headerItem) => {
            if(headers[headerItem].invalid)
            {
                isInvalid = true;
            }
            else if(!isCheckAll && !addMiddleClass)
            {
                isInvalid = true;
            }
        });

        return isInvalid;
    }

    saveCustomizeFields()
    {
        this.props.updateCustomizedSettings(this.state.mappedHeaders,true,this.state.duroLabels);
        this.props.onClose("displaySpecificationsModal", false);
    }

    toggleCheckboxes(headers)
    {
        let isCheckAll = true;
        let addMiddleClass = false;
        Object.keys(headers).forEach(function(headerItem){
            if(!headers[headerItem].isChecked)
            {
                isCheckAll = false;
            }
            else
            {
                addMiddleClass = true;
            }
        });
        if(isCheckAll)
        {
            addMiddleClass = false;
            return {isCheckAll, addMiddleClass};
        }
        else
        {
            return {isCheckAll, addMiddleClass};
        }
    }

    handleOutsideClick(e)
    {
        if(!this.node.contains(e.target) || e.target.classList.contains("background")) this.onClose();
    }

    render()
    {
        let markup = <div ref={node => { this.node = node;}}>
                     <ModalBox onClose={this.onClose} hideCrossIcon={true}>
                        <div className="modal-heading">
                            <h2> CUSTOMIZE YOUR FIELDS</h2>
                        </div>
                        <table className="customise-specifications-table-header">
                            <thead>
                                <tr>
                                    <th>
                                       <div className="checkbox-holder">
                                            <input
                                                type="checkbox"
                                                name="check-all"
                                                className="large"
                                                checked={this.state.checkAll}
                                                onChange={(e)=>this.checkAll(e)}
                                            />
                                            <label htmlFor="check-all"
                                                className={this.state.middleClass}
                                            />
                                       </div>
                                    </th>
                                    <th>
                                        DURO
                                    </th>
                                    <th></th>
                                    <th>
                                        YOUR LABELS
                                    </th>
                                </tr>
                            </thead>
                        </table>
                        <ReactDragListView {...this.dragProps}>
                            <PerfectScrollbar className="table-wrapper-scroll">
                                <table className="customise-specifications-table-content" id="customize-specifications-data">
                                    <tbody>
                                        {this.renderColumnSettings()}
                                    </tbody>
                                </table>
                            </PerfectScrollbar>
                        </ReactDragListView>
                        <div className="btn-holder">
                            <div className="btn-container">
                                <button
                                    className="dark"
                                    onClick={this.onClose}>
                                    CANCEL
                                </button>
                                <button
                                    className={this.state.disableSaveButton ? "disable": ""}
                                    onClick={!this.state.disableSaveButton ? this.saveCustomizeFields: function(){}}>
                                    SAVE
                                </button>
                            </div>
                        </div>
                    </ModalBox>
                    </div>
        return markup;
    }
}

export default CustomizedSpecificationsModal;
