import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="17"
      viewBox="0 0 20 17"
    >
      <g
        fill="#888"
        fillRule="nonzero"
        transform="translate(-744 -497) translate(313 494) translate(1)"
        className="white-fill"
      >
        <path d="M433.586 12.985v4.602a.653.653 0 001.093.482l2.004-1.838 2.775 2.909a.652.652 0 001.024-.102l9.277-14.696a.652.652 0 00-.72-.978l-18.556 4.972a.652.652 0 00-.303 1.08l3.406 3.57zm1.304 3.12v-1.753l.892.935-.892.817zm4.934 1.53l-4.625-4.849 12.054-6.918-7.429 11.766zm4.719-11.716l-10.271 5.896-2.389-2.504 12.66-3.392z"></path>
      </g>
    </svg>
  );
}

export default Icon;
