import                               "./index.css"
import Link                     from "../../../../ui/link"
import React, {Component}       from "react"
import Icon                     from "../../../../ui/icon"
import Utils                    from "../../../../../modules/utils"
import ExtendedTable            from "../../../common/extended-table"
import { connect }              from "react-redux"
import alertErrorSrc            from "../../../../../assets/icons/alert-error.svg"
import SourcingUtils            from "../sourcing-utils.js"
import TimeStampColumn          from "../../../common/timestamp-column";
import CurrencyField            from "../../../common/currency-field";
var jsdiff = require('diff');

export class RolledUpTable extends Component
{
    constructor(props)
    {
        super(props)
        let rolledUpStyles;
        try
        {
            rolledUpStyles = this.props.userStyles.rolledUpView || {}
        }
        catch(error) {
            rolledUpStyles = {}
        }

        this.objectData = this.props.objectData

        this.state =
        {
            data                     : [],
            currentSortItemAscending : true,
            current                  : "mfrMpn",
            inputs                   : {
                mpn : {
                    value : this.objectData.rolledUpCost.mpn || this.objectData.cpn,
                    name : "mpn"
                },
                manufacturer : {
                    value : this.objectData.rolledUpCost.manufacturer || this.props.company.data.name,
                    name : "manufacturer"
                }
            },
            rolledUpHeadings :
            [
                {
                    key         : "mfrMpn",
                    displayName : "MPN",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 71,
                    width       : Utils.getStyleValue(rolledUpStyles, "mfrMpn", "width", 90),
                    position    : 0,
                    visibility  : true,
                    disabled    : true,
                    dragable    : false
                },

                {
                    key         : "mfrName",
                    displayName : "MANUFACTURER",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 71,
                    width       : Utils.getStyleValue(rolledUpStyles, "mfrName", "width", 200),
                    position    : Utils.getStyleValue(rolledUpStyles, "mfrName", "position", 1),
                    visibility  : Utils.getStyleValue(rolledUpStyles, "mfrName", "visibility", true),
                    disabled    : true
                },

                {
                    key         : "minQuantity",
                    displayName : "MIN QTY",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 71,
                    width       : Utils.getStyleValue(rolledUpStyles, "minQuantity", "width", 91),
                    position    : Utils.getStyleValue(rolledUpStyles, "minQuantity", "position", 2),
                    visibility  : Utils.getStyleValue(rolledUpStyles, "minQuantity", "visibility", true),
                    disabled    : false
                },

                {
                    key         : "unitPrice",
                    displayName : "UNIT PRICE",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 71,
                    width       : Utils.getStyleValue(rolledUpStyles, "unitPrice", "width", 107),
                    position    : Utils.getStyleValue(rolledUpStyles, "unitPrice", "position", 3),
                    visibility  : Utils.getStyleValue(rolledUpStyles, "unitPrice", "visibility", true),
                    disabled    : false
                },

                {
                    key         : "leadTime",
                    displayName : "LEAD TIME",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 71,
                    width       : Utils.getStyleValue(rolledUpStyles, "leadTime", "width", 149),
                    position    : Utils.getStyleValue(rolledUpStyles, "leadTime", "position", 5),
                    visibility  : Utils.getStyleValue(rolledUpStyles, "leadTime", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "lastUpdated",
                    displayName : "LAST UPDATED",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 170,
                    width       : Utils.getStyleValue(rolledUpStyles, "lastUpdated", "width", 170),
                    position    : Utils.getStyleValue(rolledUpStyles, "lastUpdated", "position", 6),
                    visibility  : Utils.getStyleValue(rolledUpStyles, "lastUpdated", "visibility", true),
                    disabled    : false
                }
            ]   
        }
        if(window.__customFields && window.__customFields.wasteFieldEnabled){
            this.state.rolledUpHeadings.push(   
            {
                    key         : "extendedCost",
                    displayName : "Extended Cost",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 71,
                    width       : Utils.getStyleValue(rolledUpStyles, "extendedCost", "width", 107),
                    position    : Utils.getStyleValue(rolledUpStyles, "extendedCost", "position", 4),
                    visibility  : Utils.getStyleValue(rolledUpStyles, "extendedCost", "visibility", true),
                    disabled    : false
            })
        }
        this.onRolledUpSelect                = this.onRolledUpSelect.bind(this)
        this.onRolledUpDeSelect              = this.onRolledUpDeSelect.bind(this)
        this.rolledUpRows                    = this.rolledUpRows.bind(this)
        this.onMouseHoverOdWarningElOfSource = this.onMouseHoverOdWarningElOfSource.bind(this)
        this.onInputChange                   = this.onInputChange.bind(this)
        this.onRolledUpInputChanged          = this.onRolledUpInputChanged.bind(this)
    }

    onMouseHoverOdWarningElOfSource(event, addClass=true)
    {
        let element = event.target
        var rowIndex = element.closest("td").closest('tr').rowIndex + 1
        let selector = ".sourcing-row-mode #theme-data-table tbody tr:nth-child(" + rowIndex + ") td.invalidSource"
        let list = document.querySelectorAll(selector);
        for (var i = 0; i < list.length; ++i) {
            if (addClass)
                list[i].classList.add('error');
            else
                list[i].classList.remove('error');
        }
    }

    onRolledUpSelect(event, rolledUpCost)
    {
        let state = this.state
        state.selectedRolledUpCost = event.target.checked
        this.setState(state)
        this.props.onRolledUpSelect(event, rolledUpCost)
    }

    onRolledUpDeSelect(event)
    {
        let state = this.state
        state.selectedRolledUpCost = false
        this.setState(state)
        this.props.onRolledUpDeSelect(event)
    }

    onInputChange(event)
    {
        let state = this.state
        let name  = event.target.name
        let value = event.target.value

        let input = state.inputs[name]
        input.value = value

        this.setState(state, this.onRolledUpInputChanged)
    }

    onRolledUpInputChanged()
    {
        let state  = this.state
        let inputs = state.inputs

        let rolledUpCost = {
            mpn : inputs.mpn.value,
            manufacturer : inputs.manufacturer.value
        }

        this.props.onRolledUpInputChanged(rolledUpCost)
    }

    getLastUpdatedValue()
    {
        let {objectData, children} = this.props;
        let mostRecentValue = 0;
        if(children)
        {
            if(children.length === 0)
            {
                if(this.props.tabsType === 'prd' && this.props.product.revisionPage && this.props.product.revisionPage.revision && this.props.mode === 'revision')
                {
                    mostRecentValue = this.props.product.revisionPage.revision.lastModified;
                }
                else
                {
                    mostRecentValue = objectData.lastModified
                }
            }
            else
            {
                children.forEach((child) => {
                    if(child.component && child.component.lastModified > mostRecentValue && this.props.mode !== 'revision')
                    {
                        mostRecentValue = child.component.lastModified;
                    }
                    else if(child.assemblyRevision && child.assemblyRevision.lastModified > mostRecentValue && this.props.mode === 'revision')
                    {
                        mostRecentValue = child.assemblyRevision.lastModified;
                    }
                });
            }
        }
        return mostRecentValue;
    }

    rolledUpRowsForDiffTool()
    {
        let allowRowSelect = true
        let state = this.state
        let inputs = state.inputs
        let isSelected     = this.objectData.rolledUpCostAsPrimary
        let sourceRolledUpCost = SourcingUtils.getRolledUpCostFromChildren(this.props.children, true)
        let targetRolledUpCost = SourcingUtils.getRolledUpCostFromChildren(this.props.targetRevChildren, true)

        sourceRolledUpCost.mpn = inputs.mpn.value
        sourceRolledUpCost.manufacturer = inputs.manufacturer.value

        targetRolledUpCost.mpn = sourceRolledUpCost.mpn
        targetRolledUpCost.manufacturer = sourceRolledUpCost.manufacturer


        let diff = jsdiff.diffJson(targetRolledUpCost, sourceRolledUpCost)
        if (diff.length > 1)
        {
            sourceRolledUpCost.rowClassName = "update"

            for (const property in sourceRolledUpCost) {

                let sourceRevAttribute = `${sourceRolledUpCost[property]}`
                let targetRevAttribute = `${targetRolledUpCost[property]}`
                let diff = jsdiff.diffLines(targetRevAttribute, sourceRevAttribute);

                diff.forEach((part) => {
                    if (part.removed)
                    {
                        sourceRolledUpCost[`removed${property.charAt(0).toUpperCase()}${property.slice(1)}`] = part.value
                    }
                    else if (part.added)
                    {
                        sourceRolledUpCost[`added${property.charAt(0).toUpperCase()}${property.slice(1)}`] = part.value
                    }
                })
            }
        }

        let lastUpdatedValue  = this.getLastUpdatedValue();
        lastUpdatedValue      = lastUpdatedValue ? lastUpdatedValue : undefined;

        let data = sourceRolledUpCost
        let rows =
        {
            "mfrMpn" :
            {
                value        : this.objectData.cpn,
                displayValue : data.rowClassName === "update" && (data.removedMpn || data.addedMpn) ?
                    <div className="diff-update-section">
                        <div className="update-tag remove">{data.removedMpn}</div>
                        <div className="update-tag add">{data.addedMpn}</div>
                    </div> : data.mpn,
                cellClass    : '',
            },

            "mfrName" :
            {
                value        : this.props.company.data.name,
                displayValue : data.rowClassName === "update" && (data.removedManufacturer || data.addedManufacturer) ?
                    <div className="diff-update-section">
                        <div className="update-tag remove">{data.removedManufacturer}</div>
                        <div className="update-tag add">{data.addedManufacturer}</div>
                    </div> : data.manufacturer,
                cellClass    : '',
            },

            "minQuantity" :
            {
                value        : data.minQuantity,
                displayValue : data.rowClassName === "update" && (data.removedMinQuantity || data.addedMinQuantity) ?
                    <div className="diff-update-section">
                        <div className="update-tag remove">{data.removedMinQuantity}</div>
                        <div className="update-tag add">{data.addedMinQuantity}</div>
                    </div> : data.minQuantity,
            },

            "unitPrice" :
            {
                value        :  data.totalPrice,
                displayValue :  data.rowClassName === "update" && (data.removedTotalPrice || data.addedTotalPrice) ?
                    <div className="diff-update-section">
                        <div className="update-tag remove">{<CurrencyField symbol={this.props.defaultCurrency} value={data.removedTotalPrice}/>}</div>
                        <div className="update-tag add">{<CurrencyField symbol={this.props.defaultCurrency} value={data.addedTotalPrice}/>}</div>
                        {
                            data.inCompleteCost &&
                            <span className="not-all-valid">
                                <Icon
                                    tooltip="Incomplete Sources"
                                    tooltipPlace="top"
                                    src={alertErrorSrc}
                                    className="warningEl not-all-valid-s"
                                    />
                            </span>
                        }
                    </div>
                : <span className="unit-price-rolledup">
                                    {<CurrencyField symbol={this.props.defaultCurrency} value={data.totalPrice}/>}
                                    {
                                        data.inCompleteCost &&
                                        <span className="not-all-valid">
                                            <Icon
                                                tooltip="Incomplete Sources"
                                                tooltipPlace="top"
                                                src={alertErrorSrc}
                                                className="warningEl not-all-valid-s"
                                                />
                                        </span>
                                    }
                                </span>,
                cellClass    : '',
            },

            "leadTime" :
            {
                value        : data.leadTimeValueInDays,
                displayValue :  data.rowClassName === "update" && (data.removedLeadTimeValue || data.addedLeadTimeValue) ?
                    <div className="diff-update-section">
                        <div className="update-tag remove">{`${data.removedLeadTimeValue} ${data.removedLeadTimeUnit}`}</div>
                        <div className="update-tag add">{`${data.addedLeadTimeValue} ${data.addedLeadTimeUnit}`}</div>
                    </div> : `${data.leadTimeValue} ${data.leadTimeUnit}`,
            },

            "lastUpdated" :
            {
                value        : lastUpdatedValue,
                displayValue : <TimeStampColumn format='date-time-with-long-format' value={lastUpdatedValue} />
            },
            object          : {},
            rowClassName    : data.rowClassName,
            indexTableEl    : null
                }


        return {rows: [rows], rolledUpCost: data}
    }

    rolledUpRows()
    {
        let allowRowSelect = true
        let isSelected     = this.objectData.rolledUpCostAsPrimary
        let data           = SourcingUtils.getRolledUpCostFromChildren(this.props.children)
        let editMode       = this.props.editMode

        let state = this.state
        let inputs = state.inputs
        let lastUpdatedValue  = this.getLastUpdatedValue();
        lastUpdatedValue      = lastUpdatedValue ? lastUpdatedValue : undefined;

        let mpnDisplayValue     = inputs.mpn.value
        let mfrNameDisplayValue = inputs.manufacturer.value
        let extendedCost = this.objectData.primarySource && this.objectData.primarySource.extendedCost ? this.objectData.primarySource.extendedCost : 0;

        if (editMode)
        {
            mpnDisplayValue = <input
                                    type="text"
                                    name={inputs.mpn.name}
                                    value={inputs.mpn.value}
                                    onChange={this.onInputChange}
                                />

            mfrNameDisplayValue = <input
                                    type="text"
                                    name={inputs.manufacturer.name}
                                    value={inputs.manufacturer.value}
                                    onChange={this.onInputChange}
                                />
        }

        let rows =
        {
            "mfrMpn" :
            {
                value        : this.objectData.cpn,
                displayValue : mpnDisplayValue,
                cellClass    : editMode ? "edit-mode" : '',
                haveInput    : editMode,
                notLink      : true
            },

            "mfrName" :
            {
                value        : this.props.company.data.name,
                displayValue : mfrNameDisplayValue,
                cellClass    : editMode ? "edit-mode" : '',
                haveInput    : editMode,
                notLink      : true
            },

            "minQuantity" :
            {
                value        : data.minQuantity,
                displayValue : data.minQuantity,
            },

            "unitPrice" :
            {
                value        :  data.totalPrice,
                displayValue :  <span className="unit-price-rolledup">
                                    {
                                        data.inCompleteCost &&
                                        <span className="not-all-valid">
                                            <Icon
                                                tooltip="Incomplete Sources"
                                                tooltipPlace="top"
                                                src={alertErrorSrc}
                                                className="warningEl not-all-valid-s"
                                                />
                                        </span>
                                    }
                                    {<CurrencyField symbol={this.props.defaultCurrency} value={data.totalPrice}/>}
                                </span>,
                cellClass    : '',
            },
            "extendedCost" :
             {
                    value        :  extendedCost ,
                    displayValue :  <span className="extended-cost-rolledup">
                                        {
                                            data.inCompleteCost &&
                                            <span className="not-all-valid">
                                                <Icon
                                                    tooltip="Incomplete Sources"
                                                    tooltipPlace="top"
                                                    src={alertErrorSrc}
                                                    className="warningEl not-all-valid-s"
                                                    />
                                            </span>
                                        }
                                        {<CurrencyField symbol={this.props.defaultCurrency} value={extendedCost.toFixed(2)}/>}
                                    </span>,
                    cellClass    : '',
            },    

            "leadTime" :
            {
                value        : data.leadTimeValueInDays,
                displayValue :  <span>
                                    <span className="mr15">
                                        {data.leadTimeValue}
                                    </span>
                                    <span>
                                        {data.leadTimeUnit}
                                    </span>
                                </span>

            },

            "lastUpdated" :
            {
                value        : lastUpdatedValue,
                displayValue : <TimeStampColumn format='date-time-with-long-format' value={lastUpdatedValue} />
            },
            allowRowSelect  : allowRowSelect ,
            object          : {},
            // rowSelected     : this.objectData.rolledUpCostAsPrimary,
            rowSelected     : this.state.selectedRolledUpCost,
            rowClassName    : isSelected && 'success',
            displayIndexTableElOnHover : !allowRowSelect,
            indexTableEl    : isSelected ?
                               <span className="center-state checkbox active" onClick={this.onRolledUpDeSelect}/> : !allowRowSelect ?
                                    <span
                                        className="source-warning"
                                        onMouseEnter={(e) => this.onMouseHoverOdWarningElOfSource(e, true)}
                                        onMouseLeave={(e) => this.onMouseHoverOdWarningElOfSource(e, false)}
                                    >
                                        <Icon
                                            tooltip="Source missing information"
                                            tooltipType="error"
                                            tooltipPlace="top"
                                            src={alertErrorSrc}
                                            className="warningEl"
                                        />
                                    </span>

                                    : null
                }


        return {rows: [rows], rolledUpCost: data}
    }

    render()
    {
        let rolledUpHeadings = this.state.rolledUpHeadings
        let rolledUpData     = []
        if (this.props.isDiffTool)
        {
            rolledUpData = this.rolledUpRowsForDiffTool()
        }
        else
        {
            rolledUpData = this.rolledUpRows()
        }

        let rolledUpRows = rolledUpData.rows
        let rolledUpCost = rolledUpData.rolledUpCost
        let editMode     = this.props.editMode

        let markup =
                <div className="rolled-up-table">
                    <ExtendedTable
                        wrapperClassName={"rolled-up-table " + (editMode ? "edit-mode" : '')}
                        themeDataTableId="rolled-up-table"
                        wrapperSelectorClass="rolled-up-cost-table"
                        startStaticColumns={1}
                        headings={rolledUpHeadings}
                        allowRowSelect={this.props.isDiffTool ? false : true}
                        allowRowSelectAsRadio={true}
                        onRowSelect={(e) => this.onRolledUpSelect(e, rolledUpCost)}
                        rows={rolledUpRows}
                        stylesName="rolledUpView"
                        borderedTable={true}
                        displayColumnsSetting={false}
                        syncWithParentState={this.props.syncWithRolledUpCost}
                        afterSyncWithParentState={this.props.afterSyncWithRolledUpCost}
                    />
                </div>

        return markup
    }
}

export default connect((store) => store)(RolledUpTable)
