import { PageItemType, PageMode } from "design/constants";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ModelType, getLegacyCpnConfig } from "utils/cpn";
import ComponentActions from "v1/action-types/component";
import ItemAssemblyActions from "v1/action-types/item-assembly";
import buildAction from "v1/helpers/buildAction";
import Utils from "v1/modules/utils";

export function useViewObject(pageItemType: PageItemType) {
  return useSelector((store: any) => {
    switch (pageItemType) {
      case PageItemType.COMPONENT: return store.components?.viewPage?.component;
      default: return store.products?.viewPage?.product;
    }
  });
}

export function useEditObject(pageItemType: PageItemType) {
  return useSelector((store: any) => {
    switch (pageItemType) {
      case PageItemType.COMPONENT: return store.components?.editPage?.component;
      default: return store.products?.editPage?.product;
    }
  });
}

export function useEditInputs(pageItemType: PageItemType) {
  return useSelector((store: any) => {
    switch (pageItemType) {
      case PageItemType.COMPONENT: return store.components?.editPage?.inputs;
      default: return store.products?.editPage?.inputs;
    }
  });
}

export function useEditChildren() {
  return useSelector((store: any) => store.assembly_edit.children);
}

export function useUser() {
  return useSelector((store: any) => store.user.data);
}

export function useCompany() {
  return useSelector((store: any) => store.company);
}

export function useAreChildrenLoaded(pageItemType: PageItemType) {
  return useSelector((store: any) => {
    switch (pageItemType) {
      case PageItemType.COMPONENT: return store.components?.viewPage?.children_loaded;
      default: return store.products?.viewPage?.children_loaded;
    }
  });
}

export function useGetViewComponentFromApi(id: string) {
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useUser();

  return useCallback(() => {
    let queryParams = `${id}?include=creator,images${Utils.checkForRestrictedRole(user.role)
      ? ",revisions&history=true" : ""}&populateIntegration=true`;
    if ((history.location as any).showAll) {
      queryParams += "&showAll=true";
    }
    const payload = { id: queryParams, history, user };
    dispatch(buildAction(ComponentActions.SET_LOADING, true));
    dispatch(buildAction(ComponentActions.GET_COMPONENT, payload));
  }, [dispatch, history, id, user]);
}

export function useGetEditComponentFromApi(id: string) {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    Utils.setLocalStorageForAssemblyTree(id);
  }, [id]);

  return useCallback((bulkRevisionValue?: any, bulkStatusValue?: any) => {
    let queryParams = `${id}?include=children,documents,creator,images,revisions`;
    if ((history.location as any).showAll) {
      queryParams += "&showAll=true";
    }

    const payload: any = { id: queryParams, history, editMode: true };
    if (bulkRevisionValue) payload.bulkRevisionValue = bulkRevisionValue;
    if (bulkStatusValue) payload.bulkStatusValue = bulkStatusValue;
    dispatch(buildAction(ComponentActions.GET_COMPONENT_AND_SET_IN_EDIT_PAGE, payload));
    dispatch(buildAction(ItemAssemblyActions.UPDATE_DISPLAY_ASSEBLY_SEARCH, false));
  }, [dispatch, history, id]);
}

export function useGetNestedData(id: string) {
  const dispatch = useDispatch();

  return useCallback((name: string) => {
    dispatch(buildAction(ComponentActions.GET_COMPONENT_NESTED_DATA, { id: `${id}?include=${name}`, name }));
  }, [dispatch, id]);
}

export interface LoadNestedDataType {
  id: string;
  mode: PageMode;
  pageItemType: PageItemType;
  skip: boolean;
}

function getNestedDataParams(mode: PageMode, param: string) {
  if (mode === PageMode.REVISION) {
    return `${param},assemblyRevision`;
  }
  return param;
}

export function useLoadDocuments(props: LoadNestedDataType) {
  const { id, mode, pageItemType, skip } = props;

  const areDocsLoaded = useSelector((store: any) => {
    if (mode !== PageMode.REVISION && mode !== PageMode.VIEW) return true;
    switch (pageItemType) {
      case PageItemType.COMPONENT: return store.components?.viewPage?.documents_loaded;
      default: return store.products?.viewPage?.documents_loaded;
    }
  });

  const getNestedData = useGetNestedData(id);

  useEffect(() => {
    if (!areDocsLoaded && !skip) {
      getNestedData(getNestedDataParams(mode, "documents"));
    }
  }, [areDocsLoaded, getNestedData, mode, skip]);

  return areDocsLoaded;
}

export function useLoadChildren(props: LoadNestedDataType) {
  const { id, mode, pageItemType, skip } = props;

  const areChildrenLoaded = useSelector((store: any) => {
    if (mode !== PageMode.REVISION && mode !== PageMode.VIEW) return true;
    switch (pageItemType) {
      case PageItemType.COMPONENT: return store.components?.viewPage?.children_loaded;
      default: return store.products?.viewPage?.children_loaded;
    }
  });

  const getNestedData = useGetNestedData(id);

  useEffect(() => {
    if (!areChildrenLoaded && !skip) {
      getNestedData(getNestedDataParams(mode, "children"));
    }
  }, [areChildrenLoaded, getNestedData, mode, skip]);

  return areChildrenLoaded;
}

export function useCompanyIs10DigitCpnType() {
  const { currentEnv } = useUser();
  const { cpnType } = useCompany().data;

  return useMemo(() => {
    if (currentEnv === "SANDBOX") return false;
    return cpnType === "CUSTOM-CODE-WITH-10-DIGIT";
  }, [cpnType, currentEnv]);
}

export function useCompanyIs11DigitCpnType() {
  const { currentEnv } = useUser();
  const { cpnType } = useCompany().data;

  return useMemo(() => {
    if (currentEnv === "SANDBOX") return false;
    return cpnType === "CUSTOM-CODE-WITH-11-DIGIT";
  }, [cpnType, currentEnv]);
}

interface parseCpnArguments {
  baseCpn: string;
  cpnType: string;
  hasVariantScheme: boolean;
  isIntelligentCpnScheme: boolean;
  currentEnv: string;
}

function parseCpn(args: parseCpnArguments) {
  const { baseCpn, cpnType, currentEnv, hasVariantScheme, isIntelligentCpnScheme } = args;
  if (!baseCpn) return "";
  const splitCpn = (baseCpn as string).split("-");

  if (!hasVariantScheme || currentEnv === "SANDBOX") return baseCpn;
  if (cpnType === "CUSTOM-CODE-WITH-11-DIGIT") return splitCpn.slice(0, 3).join("-");
  if (cpnType === "WITH-6-DIGIT-PREFIX-AND-COUNTER" || isIntelligentCpnScheme === false) return splitCpn[0];

  return splitCpn.slice(0, 2).join("-");
}

export function useCategoryCpn(pageItemType: PageItemType, isCpnVariantScheme: boolean) {
  const { currentEnv } = useUser();
  const { cpnType, isIntelligentCpnScheme } = useCompany().data;
  const { categoryCPN: baseCpn } = useEditObject(pageItemType) ?? {};

  const categoryCPN = useMemo(() => (
    parseCpn({ baseCpn, cpnType, currentEnv, hasVariantScheme: isCpnVariantScheme, isIntelligentCpnScheme })
  ), [baseCpn, cpnType, currentEnv, isCpnVariantScheme, isIntelligentCpnScheme]);

  return { categoryCPN };
}

export interface VariantConfigArgs{
  isCreating: boolean
}

export function useVariantConfig(args: VariantConfigArgs) {
  const { isCreating } = args;
  const { cpnType: currentCompanyCpnType } = useCompany().data;
  const [updatedCpnType, setUpdatedCpnType] = useState(currentCompanyCpnType);
  const [isCpnVariantEditable, setIsCpnVariantEditable] = useState(false);
  const [isCpnVariantScheme, setIsCpnVariantScheme] = useState(false);

  useEffect(() => {
    const {
      cpnType,
      isCpnVariantEditable: isVariantEditable,
      isCpnVariantScheme: isVariantScheme,
    } = getLegacyCpnConfig({
      currentCompanyCpnType,
      isCreating,
      modelType: ModelType.COMPONENT,
    });
    setUpdatedCpnType(cpnType);
    setIsCpnVariantEditable(isVariantEditable);
    setIsCpnVariantScheme(isVariantScheme);
  }, [currentCompanyCpnType, isCreating]);
  return { cpnType: updatedCpnType, isCpnVariantEditable, isCpnVariantScheme };
}

export function useViewCpn(pageItemType: PageItemType) {
  const { currentEnv } = useUser();
  const { cpnScheme, cpnType, isIntelligentCpnScheme } = useCompany().data;
  const { cpn: baseCpn } = useViewObject(pageItemType) ?? {};

  const cpnVariantScheme = useMemo(() => (
    cpnScheme?.activeScheme === "EXTRA-TWO-DIGIT-VARIANT"
  ), [cpnScheme?.activeScheme]);

  return useMemo(() => (
    parseCpn({ baseCpn, cpnType, currentEnv, hasVariantScheme: cpnVariantScheme, isIntelligentCpnScheme })
  ), [baseCpn, cpnType, cpnVariantScheme, currentEnv, isIntelligentCpnScheme]);
}

export function useLibraryType() {
  return useSelector((store: any) => {
    const { currentEnv } = store.user.data;
    return currentEnv === "LIVE" ? "GENERAL" : "PERSONAL";
  });
}
