import { GridValueGetterParams } from "@mui/x-data-grid-pro";
import { Lot } from "build/models";

export type DesignLotsValueGetterArgs = GridValueGetterParams<any, {
  item: {
    lot: Lot,
  },
}>;

export function instancesCountValueGetter({ row }: DesignLotsValueGetterArgs) {
  return row.item?.lot.instanceCount;
}

export function lotCreatedDateValueGetter({ row }: DesignLotsValueGetterArgs) {
  if (!row.item) return "";
  return row.item.lot.createdDate;
}

export function lotDueDateValueGetter({ row }: DesignLotsValueGetterArgs) {
  if (!row.item) return "";
  return row.item.lot.dueDate;
}

export function lotLabelValueGetter({ row }: DesignLotsValueGetterArgs) {
  return row.item?.lot.labels.join(", ");
}
