import                      "./index.css";
import Tooltip              from 'rc-tooltip';
import Link                 from "../../../../ui/link";
import Utils                from "../../../../../modules/utils";
import ExtendedTable        from "../../../common/extended-table";
import React, {Component}   from "react";
import API                  from "../../../../../modules/api";
import Spinner              from "../../../../ui/spinner";
import SourcingUtils        from "../../../common/sourcing/sourcing-utils.js";
import InlineIcon           from "../../../../ui/icon/inline-icon.js";
import WarningSrc           from "../../../../../assets/icons/warning.js";
import SyncedSrc            from "../../../../../assets/icons/synced.js";
import DownSrc              from "../../../../../assets/icons/down.js";
import UpSrc                from "../../../../../assets/icons/up.js";
import AlertIcon            from "../../../../../assets/icons/alert-icon.js";
import RefreshIcon          from "../../../../../assets/icons/refresh-icon.js"
import $                    from 'jquery';
import * as XLSX            from 'xlsx';
import {saveAs}             from 'file-saver';
import RefreshQuoteModal    from '../../validation-running-modal';
import InfoIcon             from "../../../../../assets/icons/bulk-update-info-icon.js";
import TimeStampColumn      from "../../../common/timestamp-column";
import CpnField             from "../../../common/cpn-field";
import CurrencyField         from "../../../common/currency-field";
import { getHeadings, HEADINGS } from "../../extended-table/helpers";

let callBackCount = 0;
class Reports extends Component
{
    constructor(props)
    {
        super(props)

        let reportsStyles;
        try
        {
            reportsStyles = this.props.user.preferences.styles.reportsViewRowMode || {}
        }
        catch(error) {
            reportsStyles = {}
        }

        this.state =
        {
            showLoading: false,
            checkedRows: [],
            headings: [],
            reportsStyles: reportsStyles,
            rows: [],
            reports: [],
            quoteRefreshbtn: false,
            processModal: false,
            syncTable: false,
            doForAll: false,
            currentSortItemAscending: ("defaultSortAssending" in reportsStyles ? reportsStyles.defaultSortAssending : true),
            current : ("defaultSortColumnName" in reportsStyles ? reportsStyles.defaultSortColumnName : "cpn"),
        }

        this.setReports                 = this.setReports.bind(this);
        this.getRows                    = this.getRows.bind(this);
        this.getActionListForReports    = this.getActionListForReports.bind(this);
        this.onRowSelect                = this.onRowSelect.bind(this);
        this.onSelectAllRows            = this.onSelectAllRows.bind(this);
        this.updateSource               = this.updateSource.bind(this);
        this.createExcel                = this.createExcel.bind(this);
        this.updateManufacturers        = this.updateManufacturers.bind(this);
        this.afterSyncTable             = this.afterSyncTable.bind(this);
        this.clearSourceWarning         = this.clearSourceWarning.bind(this);
    }

    getActionListForReports()
    {
        let actionsList = [];
        let actionEntry;
        actionEntry =
        {
            type          : 'action',
            iconSrc       : <RefreshIcon/>,
            toolTip       : "Refresh Quote",
            name          : "Update",
            iconClassName : "update",
            className     : "update" + ((this.state.quoteRefreshbtn === true) ? "" : " disable-pointer-event"),
            onClick       : (this.updateSource),
            active        : ((this.state.quoteRefreshbtn === true) ? true : false)
        };
        actionsList.push(actionEntry);
        return actionsList;
    }

    refreshIndividualQuote(report)
    {
        let category = report.category;
        let _this = this;
        let selectedQuotes = SourcingUtils.getPrimarySourceQuote(report.manufacturers);
        let totalQuotes = _this.state.reports.filter((rep) => { return rep.checked && rep.manufacturers && rep.manufacturers.length > 0 });
        if(!selectedQuotes.quote)
        {
            callBackCount++;
            if (totalQuotes.length === callBackCount)
            {
                callBackCount = 0;
                this.setState({reports: this.state.reports, processModal: false, syncTable: true});
            }
            return;
        }
        let viewData = report.manufacturers;
        let {man} = selectedQuotes;
        API.components.getSource(category, man.mpn.key, (err, source) =>
        {
            callBackCount++;
            if(err)
            {
                if (err && err.errors.length) {
                    this.handleNotExistQuotes(report, true);
                    if (totalQuotes.length === callBackCount)
                    {
                        callBackCount = 0;
                        this.setState({reports: this.state.reports, processModal: false, syncTable: true});
                    }
                    return;
                }
            }
            let responsePrimaryQuotes = SourcingUtils.getPrimarySourceQuoteDetails(man, source);
            if (source) {
                if (man.distributors.length > 0)
                {
                    man.distributors.forEach((dist, distIndex) =>
                    {
                        if (dist.quotes.length > 0)
                        {
                            dist.quotes.forEach((quote, quoteIndex) =>
                            {
                                if (quote.isPrimary) {
                                    if(responsePrimaryQuotes)
                                    {
                                        responsePrimaryQuotes.quoteUnitPrice = responsePrimaryQuotes.unitPrice;
                                        responsePrimaryQuotes.quoteLeadTimeValue = responsePrimaryQuotes.leadTime.value;
                                        responsePrimaryQuotes.quoteLeadTimeUnits = responsePrimaryQuotes.leadTime.units;
                                        SourcingUtils.checkViewQuoteState(responsePrimaryQuotes, quote);
                                        quote.leadTime.value = responsePrimaryQuotes.leadTime.value;
                                        quote.leadTime.units = responsePrimaryQuotes.leadTime.units;
                                        quote.maxQuantity = responsePrimaryQuotes.maxQuantity;
                                        quote.minQuantity = responsePrimaryQuotes.minQuantity;
                                        quote.unitPrice   = responsePrimaryQuotes.unitPrice;
                                    }
                                    else
                                    {
                                        quote.unitPriceState = 'worsened';
                                    }
                                    quote.lastUpdated = Date.now();
                                }
                            });
                        }
                    });
                }
            }
            _this.updateManufacturers(viewData, report);
            if (totalQuotes.length === callBackCount)
            {
                callBackCount = 0;
                this.setState({reports: this.state.reports, processModal: false, syncTable: true});
            }
        });
    }

    updateManufacturers(viewData, report)
    {
        let component           = report;
        component.manufacturers = viewData;
        API.components.update(component._id, component, (err, response) =>
        {
            //noop
        });
    }

    updateSource()
    {
        let totalQuotesToBeUpdated = this.state.reports.filter((rep) => { return rep.checked && rep.manufacturers && rep.manufacturers.length > 0 });
        if(totalQuotesToBeUpdated.length === 0)
        {
            return;
        }
        this.setState({processModal: true}, () => {
            this.state.reports.forEach((report) => {
                if(report.checked && report.manufacturers && report.manufacturers.length > 0)
                {
                    this.refreshIndividualQuote(report);
                }
            });
        });
    }

    componentDidMount()
    {
        let state = this.state;
        let product = this.props.product;
        let reports = this.props.reports;
        if (this.props.getDataFromApi && product && reports.length === 0)
        {
            state.showLoading = true;
            this.setState(state);
            let _this = this;

            API.products.getProductReports(product._id, {}, (err, data) =>
            {
                if(err)
                {
                    this.setState({showLoading: false});
                    //TODO: handle err
                }
                if(data && data.length) this.setReports(data);
            })
        }
        else
        {
            this.setState({reports: this.props.reports})
            this.props.saveReportsInParent(this.props.reports);
        }
    }

    removeDuplicate(list)
    {
        let uniqueList = [];
        let childrenIds = [];
        let quantityReport = {}
        list.forEach((item, i) => {
            if(!childrenIds.includes(item._id)) uniqueList.push(item);
            childrenIds.push(item._id);
        });
        list.forEach((item, i) => {
            if(quantityReport[item._id])
            {
                quantityReport[item._id] = quantityReport[item._id] + item.flattendQty;
            }
            else
            {
                quantityReport[item._id] = item.flattendQty;
            }
        });
        uniqueList.forEach((item, i) => {
            item.quantity = quantityReport[item._id];
        });
        return uniqueList;
    }

    setReports(data)
    {
        let list = [];
        let headings = [];
        let rows = [];
        data.forEach((item, i) => {
            if(item.obj && item.obj.archived)
            {
                return;
            }

            if (item.obj && item.obj.alias === "cmp")
            {
                item.obj.flattendQty = item.qty;
                list.push(item.obj);
                item.obj.checked = false;
            }
        });
        list = this.removeDuplicate(list);
        this.setState({reports: list, showLoading: false});
        this.props.saveReportsInParent(list);
    }

    createReportTabHeadings(reports)
    {
        let product = this.props.product;
        let reportsStyles = this.state.reportsStyles;
        let headings = getHeadings(
            [
              {
                ...HEADINGS.itemNumber,
                displayName: "Item",
                tooltip: "",
                sortable: false,
                ascending: false,
                visibility: false,
                disabled: true,
                hideFromSettings: true,
              },
              { ...HEADINGS.cpn, width: 254 },
              HEADINGS.name,
              HEADINGS.quantity,
              HEADINGS.mfrMpn,
              HEADINGS.mfrName,
              HEADINGS.mfrDescription,
              HEADINGS.distName,
              HEADINGS.distDpn,
              HEADINGS.distDescription,
              HEADINGS.quoteMinQuantity,
              HEADINGS.quoteUnitPrice,
              HEADINGS.quoteLeadTime,
              HEADINGS.quoteLastUpdated,
              {
                ...HEADINGS.totalPrice,
                minWidth: 170,
                width: 170,
                displayName: "TOTAL PRICE",
                tooltip: "TOTAL PRICE",
              },
            ],
            reportsStyles
        );
        return headings;
    }

    getLeadTimeState(quote)
    {
        let diffDays = Utils.timeDiff(quote.lastUpdated);
        let data     = null;
        if (diffDays <= 1) {
           data = <span className="row-with-icon">
                {
                    quote.leadTimeState === "noChange" ?
                        <React.Fragment>
                            <span className="icon-value">
                                <InlineIcon>
                                    <SyncedSrc />
                                 </InlineIcon>
                            </span>
                        </React.Fragment>
                         :
                    quote.leadTimeState === "up" ?
                    <React.Fragment>
                        <span className="icon-value">
                            <InlineIcon>
                                <UpSrc />
                             </InlineIcon>
                        </span>
                    </React.Fragment>
                         :
                    quote.leadTimeState === "down" ?
                    <React.Fragment>
                        <span className="icon-value">
                            <InlineIcon>
                                <DownSrc />
                             </InlineIcon>
                        </span>
                    </React.Fragment>
                        :
                    null
                }
            </span>
        }

        return data;
    }

    onSelectAllRows(checked)
    {
        let state = this.state
        let { reports } = state;
        let quoteRefreshbtn = false;
        state.clearSelectedRows = false
        reports.forEach((report, i) =>
        {
            report.checked = checked;
            if(report.checked)
            {
                quoteRefreshbtn = true;
            }
        });
        this.setState({reports, quoteRefreshbtn});
    }

    onRowSelect(event, item)
    {
        let state = this.state
        let reports = state.reports;
        let checkedRows = state.checkedRows;
        let checked = event.target.checked;
        let quoteRefreshbtn = false;
        reports.forEach((report) =>
        {
            if(report.cpn === item.cpn)
            {
                report.checked = checked;
            }
            if(report.checked)
            {
                quoteRefreshbtn = true;
            }
        });
        reports.forEach((report) =>
        {
            if(report.checked)
            {
                quoteRefreshbtn = true;
            }
        });
        this.setState({reports, quoteRefreshbtn});
    }

    clearSourceWarning(report)
    {
        let {doForAll} = this.state;
        if(doForAll)
        {
            let {reports} = this.state;
            for(let report of reports)
            {
                if(report.manufacturers && report.manufacturers.length > 0)
                {
                    for(let manufacturer of report.manufacturers)
                    {
                        if(manufacturer.distributors && manufacturer.distributors.length > 0)
                        {
                            for(let distributor of manufacturer.distributors)
                            {
                                if(distributor.quotes && distributor.quotes.length > 0)
                                {
                                    for(let quote of distributor.quotes)
                                    {
                                        if(quote.isPrimary)
                                        {
                                            if(quote.unitPriceState === 'worsened')
                                            {
                                                quote.unitPriceState = '';
                                                quote.lastUpdated = Date.now();
                                                this.updateManufacturers(report.manufacturers, report);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        else
        {
            let primaryQuote = SourcingUtils.getPrimarySourceQuote(report.manufacturers);
            primaryQuote.quote.unitPriceState = '';
            SourcingUtils.setPrimarySourceQuote(report.manufacturers, primaryQuote.quote);
            this.updateManufacturers(report.manufacturers, report);
        }
        this.setState({ reports: this.state.reports, syncTable: true });
    }


    handleNotExistQuotes(report)
    {
        if(report.manufacturers && report.manufacturers.length > 0)
        {
            for(let manufacturer of report.manufacturers)
            {
                if(manufacturer.distributors && manufacturer.distributors.length > 0)
                {
                    for(let distributor of manufacturer.distributors)
                    {
                        if(distributor.quotes && distributor.quotes.length > 0)
                        {
                            for(let quote of distributor.quotes)
                            {
                                if(quote.isPrimary)
                                {
                                    quote.unitPriceState = 'worsened';
                                    quote.lastUpdated = Date.now();
                                    this.updateManufacturers(report.manufacturers, report);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    onCheckBoxChange()
    {
        this.setState({doForAll: !this.state.doForAll, syncTable: true});
    }

    getUnitPriceState(data)
    {
        let diffDays = Utils.timeDiff(data.lastUpdated);
        let result   = null;
        if (diffDays <= 1) {
            result = <span className="row-with-icon">
                    {
                        data.unitPriceState === "noChange" ?
                            <React.Fragment>
                                <span className="icon-value"><InlineIcon><SyncedSrc /></InlineIcon></span>
                            </React.Fragment>
                        :
                        data.unitPriceState === "up" ?
                        <React.Fragment>
                            <span className="icon-value">
                                <InlineIcon>
                                    <UpSrc />
                                 </InlineIcon>
                            </span>
                        </React.Fragment>
                        :
                        data.unitPriceState === "down" ?
                        <React.Fragment>
                            <span className="icon-value">
                                <InlineIcon>
                                    <DownSrc />
                                </InlineIcon>
                            </span>
                        </React.Fragment>
                        :
                        null
                    }
                    </span>
        }
        return result;
    }

    getChildLink(report)
    {
        let pathname =  `/component/view/${report._id}`;
        return pathname;
    }

    getLastUpdatedValueForRolledUpCost(report)
    {
        let mostRecentValue = 0;
        if(report.children && report.children.length > 0)
        {
            for(let children of report.children)
            {
                if(children.component && children.component.lastModified > mostRecentValue)
                {
                    mostRecentValue = children.component.lastModified;
                }
            }
        }
        else if(report.children && report.children.length === 0)
        {
            mostRecentValue = report.lastModified;
        }
        return mostRecentValue;
    }

    getRows(reports, headings)
    {
        let _this = this;
        let product = this.props.product;
        let rows = reports.map((result, i) =>
        {
            let isSelected = result.checked;
            let noPrimarySourceText = 'A Primary Source needs to be selected for this Component to update its pricing and lead time.';
            let indexTableEl = <div>
                                    <InlineIcon
                                        isValid={true}
                                        className="warningEl warning-icon"
                                        >
                                        <AlertIcon/>
                                     </InlineIcon>
                                </div>
            let manufacturers = result.manufacturers ? result.manufacturers : [];
            let primarySourceData = SourcingUtils.getPrimarySourceQuote(manufacturers);
            let allowRowSelect = !!(result.quote_id && result.quoteMinQuantity !== null && result.quoteUnitPrice !== null )
            let leadTimeState = "";


            let man = primarySourceData && primarySourceData.man;
            let dist = primarySourceData && primarySourceData.dist;
            let quote = primarySourceData && primarySourceData.quote;
            if(!quote) quote = result.primarySource;

            let mfrName = result.primarySource && result.primarySource.manufacturer ? result.primarySource.manufacturer : "";
            let mfrMpnKey = result.primarySource && result.primarySource.mpn ? result.primarySource.mpn : "";
            let mfrMpnSrc = man && man.mpn ? man.mpn.src : "";
            let mfrDescription = man && man.description ? man.description : "";
            let distName = dist && dist.name ? dist.name : "";
            let distDpnKey = dist && dist.dpn && dist.dpn.key ? dist.dpn.key : "";
            let distDpnSrc = dist && dist.dpn && dist.dpn.src ? dist.dpn.src : "";
            let distDescription = dist && dist.description ? dist.description : "";

            let quoteMinQuantity = quote && quote.minQuantity ? quote.minQuantity : '';
            let quoteUnitPrice = quote && (quote.unitPrice ? <CurrencyField symbol={this.props.defaultCurrency} value={quote.unitPrice}/> : '');
            let quoteLeadTimeValue = quote && quote.leadTime && quote.leadTime.hasOwnProperty("value") ? quote.leadTime.value : '';
            let quoteLeadTimeUnits = quote && quote.leadTime && quote.leadTime.units ? quote.leadTime.units : '';
            let unitPriceState = null;
            let unitPriceClass = null;
            let leadTimeClass = '';
            let dateTime;
            let hasNoPrimarySourceToBeUpdated = !primarySourceData.quote || result.rolledUpCostAsPrimary;
            let showLastUpdatedColumn = primarySourceData.quote || result.rolledUpCostAsPrimary;

            let totalPrice = 0
            if (quote && quote.unitPrice && result.quantity)
            {
                totalPrice = result.quantity * quote.unitPrice
            }

            if(quote)
            {
                if(!quote.lastUpdated) quote.lastUpdated = result.lastModified;
                dateTime = Utils.dateTimeWithLongFormat(quote.lastUpdated);
                unitPriceState = this.getUnitPriceState(quote);
                unitPriceClass = quote.unitPriceState === 'up' ? 'red' : quote.unitPriceState === 'down' ? 'green' : '';
                leadTimeClass  = quote.leadTimeState === 'up' ? 'red' : quote.leadTimeState === 'down' ? 'green' : '';
            }
            if(quote && quote.leadTime && quoteLeadTimeValue !== "") leadTimeState = this.getLeadTimeState(quote);
            let lastUpdated = quote && quote.lastUpdated ? quote.lastUpdated : '';
            let cpn      = Utils.getCpn(result);
            if(result.rolledUpCostAsPrimary)
            {
                noPrimarySourceText = 'This Component uses the rolled up cost of its child components. Update its child Components to view latest sourcing details.'
                indexTableEl = <div>
                                    <InlineIcon
                                        isValid={true}
                                        className="warningEl warning-icon"
                                        >
                                        <InfoIcon/>
                                     </InlineIcon>
                                </div>
                lastUpdated = this.getLastUpdatedValueForRolledUpCost(result);
                dateTime    = Utils.dateTimeWithLongFormat(lastUpdated);
            }
            let errorOverlay = <div>
                                    <span>
                                        {noPrimarySourceText}
                                    </span>
                                </div>

            let cells =
            {
                "itemNumber" : {
                    value       : i+1,
                    displayValue: i+1,
                    tooltip     : '',
                },
                "cpn" : {
                    value       : cpn,
                    displayValue: <span className='link'><CpnField item={result} cpn={cpn} /></span>,
                    tooltip     : cpn,
                },
                "name" : {
                    value       : result.name,
                    displayValue: result.name,
                    tooltip     : result.name
                },
                "quantity" : {
                    value       : result.quantity,
                    displayValue: <span className="quantity">{result.quantity}</span>,
                    tooltip     : result.quantity
                },
                "mfrName" :
                {
                    value        : mfrName,
                    displayValue : mfrName,
                    tooltip      : mfrName
                },
                "mfrMpn" :
                {
                    value        : mfrMpnKey,
                    displayValue : mfrMpnSrc ?
                                    <Link
                                        to={mfrMpnSrc}
                                        target="_blank"
                                        >
                                        <span className='link'>{mfrMpnKey}</span>
                                    </Link>
                                    :
                                    mfrMpnKey,
                    tooltip      : mfrMpnKey,
                    notLink      : mfrMpnSrc ? true : false
                },
                "mfrDescription" :
                {
                    value        : mfrDescription,
                    displayValue : mfrDescription,
                    tooltip      : mfrDescription
                },
                "distName" :
                {
                    value        : distName,
                    displayValue : distName,
                    tooltip      : distName
                },
                "distDpn" :
                {
                    value        : distDpnKey,
                    displayValue : distDpnSrc ?
                                    <Link
                                        to={distDpnSrc}
                                        target="_blank"
                                        >
                                        <span className='link'>{distDpnKey}</span>
                                    </Link>
                                    :
                                    distDpnKey,
                    tooltip      : distDpnKey,
                    notLink      : distDpnSrc ? true : false
                },
                "distDescription" :
                {
                    value        : distDescription,
                    displayValue : distDescription,
                    tooltip      : distDescription
                },

                "quoteMinQuantity" :
                {
                    value        : quoteMinQuantity,
                    displayValue : quoteMinQuantity,
                    cellClass    : [undefined, null, ''].includes(quoteMinQuantity) ? 'invalidSource' : '',
                    tooltip      : quoteMinQuantity
                },

                "quoteUnitPrice" :
                {
                    value        : quoteUnitPrice,
                    displayValue : <div className='column-with-icon'>
                                        {
                                            SourcingUtils.haveInValidCosting(result) === true ?
                                            <InlineIcon
                                                tooltip="Incomplete Sources"
                                                tooltipPlace="top"
                                                className="warningEl"
                                                key={i+'-unit-price-icon'}
                                                >
                                                <AlertIcon/>
                                            </InlineIcon>
                                            :
                                            unitPriceState
                                        }
                                        <div className={`price-value ${unitPriceClass}`} key={i+'-unit-price'}>
                                            {quoteUnitPrice}
                                        </div>
                                        </div>
                                ,
                    cellClass    : [undefined, null, ''].includes(quoteUnitPrice) ? 'invalidSource' : '',
                    tooltip      : [undefined, null, ''].includes(quoteUnitPrice) ? '' : ( this.props.defaultCurrency + ( isNaN(Number(quoteUnitPrice).toFixed(5)) ? Number(0).toFixed(5) : Number(quoteUnitPrice).toFixed(5) ) )
                },

                "quoteLeadTime" :
                {
                    value        : ![undefined, null, ''].includes(quoteLeadTimeValue) ? Utils.stringValueToDays(quoteLeadTimeValue, quoteLeadTimeUnits) : '',
                    displayValue : <div className='column-with-icon'>
                                        <div className={`lead-time ${leadTimeClass}`} key={`${i}-lead-time`}>
                                            <span className='quote-lead-time'>
                                                {quoteLeadTimeValue}
                                            </span>
                                            &nbsp;
                                            <span>
                                                {quoteLeadTimeUnits}
                                            </span>
                                        </div>
                                        {leadTimeState}
                                    </div>
                                   ,
                    cellClass    : [undefined, null, ''].includes(quoteLeadTimeValue) ? 'invalidSource' : '',
                    tooltip      : quoteLeadTimeValue + ' ' + quoteLeadTimeUnits,
                },

                "quoteLastUpdated" :
                {
                    value        : showLastUpdatedColumn && lastUpdated ? lastUpdated : "",
                    displayValue : showLastUpdatedColumn && <TimeStampColumn key={Utils.generateUniqueId()} format='date-time-with-long-format' value={lastUpdated} />,
                    cellClass    : [undefined, null, ''].includes(quote && quote.lastUpdated) ? 'invalidSource' : '',
                    tooltip      : showLastUpdatedColumn && dateTime ? `${dateTime.dateValue} ${dateTime.timeValue}` : ""
                },
                "totalPrice" :
                {
                    value        : totalPrice,
                    displayValue : totalPrice ? <CurrencyField symbol={this.props.defaultCurrency} value={totalPrice}/> : '',
                    tooltip      : totalPrice ? (this.props.defaultCurrency + Number(totalPrice).toFixed(5) ) : ''
                },
                allowRowSelect  : hasNoPrimarySourceToBeUpdated ? false : true,
                rowId           : result._id,
                getSelectedInBulk: hasNoPrimarySourceToBeUpdated ? false : true,
                rowSelected     : hasNoPrimarySourceToBeUpdated ? false : isSelected,
                object          : result,
                rowClassName    :(quote && quote.unitPriceState === 'worsened' ? ' warning' : '' ),
                indexTableEl    : quote && quote.unitPriceState === 'worsened'?
                                        <Tooltip
                                          placement={"right"}
                                          overlayClassName={"simple-rc-tip source-no-exist"}
                                          overlay={
                                                <div>
                                                    <p className="tooltip-text">Source is no longer available.</p>
                                                    <div className="tooltip-action-area">
                                                        <div className="tooltip-checkbox">
                                                            <input
                                                                type="checkbox"
                                                                name="doForAll"
                                                                id="doForAll"
                                                                checked={this.state.doForAll}
                                                                onChange={() => this.onCheckBoxChange()}
                                                            />
                                                            <label htmlFor="group"></label>
                                                            <span>Apply to all</span>
                                                        </div>
                                                        <button type="button2"
                                                            className="sourcing-action-btn"
                                                            onClick={() => this.clearSourceWarning(result)}>
                                                            Clear Warning
                                                        </button>
                                                    </div>
                                                    </div>}
                                            >
                                            <div>
                                                <InlineIcon
                                                    isValid={true}
                                                    className="warningEl warning-icon"
                                                    >
                                                    <WarningSrc/>
                                                 </InlineIcon>
                                            </div>
                                            </Tooltip> : hasNoPrimarySourceToBeUpdated ?
                                                <Tooltip
                                                  placement={"top"}
                                                  overlayClassName={"simple-rc-tip error default-styles no-primary-source-tooltip"}
                                                  overlay={
                                                    <div>
                                                        <p className="tooltip-text">{noPrimarySourceText}</p>
                                                    </div>}
                                            >
                                                {indexTableEl}
                                            </Tooltip>
                                                : null,
                rowLink         : !result.archived ? this.getChildLink(result) : '#'
            }
            return cells
        });
        return rows;
    }

    createExcel(headings)
    {
        const s2ab = (s) =>
        {
            let buf = new ArrayBuffer(s.length);
            let view = new Uint8Array(buf);
            for (let i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }
        let table = $('#extended-table-data')[0];
        if(table)
        {
            let {product, user} = this.props;
            let preFix    = Utils.getLocalDate(user.data.timezoneOffset);
            let fileName  = `${product.cpn}`;
            if(product.revision)
            {
                fileName = `${fileName}.${product.revision}-${preFix}.xlsx`;
            }
            else
            {
                fileName = `${fileName}-${preFix}.xlsx`;
            }
            console
            let wb        = XLSX.utils.table_to_book(table, {sheet:'Report', raw: false});
            wb            = this.filterUnitPriceBeforeDownload(wb, headings);
            let wbout = XLSX.write(wb, {bookType:'xlsx', bookSST:true, type: 'binary'});
            saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), fileName);
        }
    }

    filterUnitPriceBeforeDownload(wb, headings)
    {
        let {reports} = this.state;
        let index    = headings.findIndex(heading => heading.displayName === 'UNIT PRICE');
        if(index >= 0)
        {
            let excelFileColumn = String.fromCharCode(97 + index).toUpperCase();
            for(let i=1; i<=reports.length; i++)
            {
                let unitPrice = wb.Sheets['Report'][`${excelFileColumn}${i+1}`]['v'];
                if(typeof(unitPrice) === 'string')
                {
                    unitPrice     = unitPrice.replace('!', '');
                    unitPrice     = unitPrice.replace('$', '');
                    wb.Sheets['Report'][`${excelFileColumn}${i+1}`]['t'] = 'n';
                }
                wb.Sheets['Report'][`${excelFileColumn}${i+1}`]['v'] = unitPrice;
            }
        }
        index    = headings.findIndex(heading => heading.displayName === 'Item');
        if(index >= 0)
        {
            let excelFileColumn = String.fromCharCode(97 + index).toUpperCase();
            for(let i=1; i<=reports.length; i++)
            {
                wb.Sheets['Report'][`${excelFileColumn}${i+1}`]['v'] = i;
            }
        }
        return wb;
    }

    afterSyncTable()
    {
        this.setState({syncTable: false});
    }

    render()
    {
        if (this.state.reports.length === 0 && this.state.showLoading)
        {
            return <Spinner />
        }
        let headings = this.createReportTabHeadings(this.state.reports);
        let rows = this.getRows(this.state.reports, headings);

        let markup  =
            <div className="reports-table-wrapper">
                { this.state.processModal && <RefreshQuoteModal open={true}
                            hideModal={this.state.processModal ? "" : "hide-modal"}
                            message="Checking for updates to your sourcing data."  />
                }
                <ExtendedTable
                    wrapperClassName="report-list"
                    wrapperSelectorClass="report-list"
                    headings={headings}
                    rows={rows}
                    borderedTable={true}
                    stylesName="reportsViewRowMode"
                    allowRowSelect={true}
                    onSelectAllRows={this.onSelectAllRows}
                    onRowSelect={this.onRowSelect}
                    includeToolBar={true}
                    resultText={`${rows.length} Results`}
                    allowRowSelectAsRadio={false}
                    tableActionButtons={this.getActionListForReports()}
                    afterSyncWithParentState={this.afterSyncTable}
                    syncWithParentState={this.state.syncTable}
                    displayDownloadIcon={true}
                    downloadTable={this.createExcel}
                    currentSortItem={this.state.current}
                    currentSortItemAscending={this.state.currentSortItemAscending}
                    downloadIconClass={this.state.reports.length === 0 ? 'disabled' : 'active'}
                    footerRow={this.state.reports.length === 0 && {
                            dataCellEl: <p>No Components added</p>
                        }
                    }
                />
            </div>
        return markup
    }
}

export default Reports
