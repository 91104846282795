import { Backdrop, Box, CircularProgress, styled } from "@mui/material";
import { useDuroFlag } from "common/components/launchDarkly";
import { Tab, TabContext, TabList, TabPanel } from "common/components/tabs";
import { Role } from "common/constants";
import { useDataTestId } from "common/hooks";
import { PageItemType, PageMode, StatusValue } from "design/constants";
import {
  MouseEvent, useCallback,
  useEffect, useMemo, useRef, useState,
} from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import ComponentActions from "v1/action-types/component";
import DisplaySidebarActions from "v1/action-types/display-sidebar";
import ItemAssemblyActions from "v1/action-types/item-assembly";
import { BuildWorkspaceIcon } from "v1/assets/icons/buildWorkspaceIcon";
import buildAction from "v1/helpers/buildAction";
import Schemas from "v1/modules/schemas";
import Utils from "v1/modules/utils";
import { BuildTab } from "../../components/buildTab";
import { EditTabsContextProvider } from "../common/editTabsContextProvider";
import { AssemblyTab } from "./assemblyTab";
import "./compatibilityStyles.css";
import { ComponentHeader } from "./componentHeader";
import { ComponentModal, SetShouldBlockNavigation } from "./componentModal";
import { ComponentRightSideBar } from "./componentRightSidebar";
import { ComponentTiles } from "./componentTiles";
import { TabError, TabValue } from "./constants";
import { DocumentsTab } from "./documentsTab";
import {
  useEditObject,
  useGetEditComponentFromApi,
  useGetViewComponentFromApi,
  useUser,
  useViewObject,
} from "./hooks";
import { SourcingTab } from "./sourcingTab";
import { VariantsTab } from "./variantsTab";

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  color: theme.palette.primary.main,
  zIndex: theme.zIndex.drawer + 1,
  background: "#00000000", // Make it transparent so only the loader is visible
}));

const TopBlock = styled(Box)(({ theme }) => ({
  backgroundColor: theme.duro.component.topBlock.backgroundColor,
  paddingBottom: "1rem",
}));

const ComponentWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
}));

const LeftContent = styled(Box)(() => ({
  flex: 1,
  overflow: "hidden",
}));

function useViewComponentLoading() {
  return useSelector((store: any) => store.components.viewPage.loading);
}

interface GetInitialTabArgs {
  hasVariants: boolean;
  isAssembly: boolean;
  isEditing: boolean;
  urlQuery: string;
}

function getInitialTab(args: GetInitialTabArgs) {
  const { hasVariants, isAssembly, isEditing, urlQuery } = args;
  let view = Utils.getStore("component-tab") || TabValue.DOCUMENTS;

  if ((!isAssembly && view === TabValue.ASSEMBLY)) {
    view = TabValue.DOCUMENTS;
  }

  if (!hasVariants && view === TabValue.VARIANTS) {
    view = TabValue.DOCUMENTS;
  }

  if (isEditing && view === TabValue.BUILD) {
    view = TabValue.DOCUMENTS;
  }

  const urlParams = new URLSearchParams(urlQuery);
  const tab = urlParams.has("tab") ? urlParams.get("tab") : null;
  if (tab) {
    view = tab as TabValue;
  }

  return view;
}

function getInitialErrorCounts(): Record<TabError, number> {
  return { [TabError.ASSEMBLY]: 0, [TabError.DOCUMENT]: 0, [TabError.SOURCING]: 0 };
}

interface ComponentUrlParams {
  id: string;
  mode: PageMode;
  pageItemType: PageItemType;
}

export interface ComponentPageLocationState {
  isNewObject?: boolean;
}

export function ComponentPage() {
  const history = useHistory<ComponentPageLocationState>();
  const dispatch = useDispatch();

  const routeMatch = useRouteMatch<ComponentUrlParams>({ path: "/:pageItemType/:mode/:id" });
  const {
    id = "",
    mode = PageMode.VIEW,
    pageItemType = PageItemType.COMPONENT,
  } = routeMatch?.params ?? {};

  const isEditing = useMemo(() => mode === PageMode.EDIT, [mode]);
  const isDiffTool = useMemo(() => mode === PageMode.DIFF, [mode]);

  const user = useUser();
  const viewObject = useViewObject(pageItemType);
  const editObject = useEditObject(pageItemType);
  const isViewComponentLoading = useViewComponentLoading();
  const getViewComponentFromApi = useGetViewComponentFromApi(id);
  const getEditComponentFromApi = useGetEditComponentFromApi(id);
  const dataTestId = useDataTestId(pageItemType, mode);

  const component = useMemo(() => (
    isEditing ? editObject : viewObject
  ), [editObject, isEditing, viewObject]);
  const isAssembly = useMemo(() => (
    Schemas.component.category.getType(component?.category)?.toLowerCase() === "assembly"
  ), [component]);

  // Make sure the users of role VENDOR are forwarded to the last released revision.
  useEffect(() => {
    if (component && user?.role === Role.VENDOR) {
      history.replace(`/component/revision/${component.lastReleaseCmpRev}`);
    }
  }, [history, component, user?.role]);

  const [currentTab, setCurrentTab] = useState(() => (Utils.getStore("component-tab") || TabValue.DOCUMENTS));
  const [errorCounts, setErrorCounts] = useState(getInitialErrorCounts);
  const [showLoading, setShowLoading] = useState(false);
  const [openVariantModal, setOpenVariantModal] = useState(false);
  const [categoryChanged, setCategoryChanged] = useState(false);
  const shouldBlockNavigation = useRef(false);
  const isSaving = useRef(false);
  const setShouldBlockNavigation = useCallback(({ block, saving }: SetShouldBlockNavigation) => {
    if (saving) {
      isSaving.current = saving;
    }

    if (block && !isSaving.current) {
      shouldBlockNavigation.current = true;
      window.onbeforeunload = () => true;
    }
    else {
      shouldBlockNavigation.current = false;
      window.onbeforeunload = null;
    }
  }, []);

  const cpn = useMemo(() => component && Utils.getCpn(component), [component]);
  const { isVendorCmp, vendorLabel } = useMemo(() => Utils.getVendorLabel(component), [component]);
  const hasSourcing = user.role !== "VENDOR";
  const hasVariants = user.role !== "VENDOR" && component?.variantGroup && !isDiffTool;
  const { build: isBuildEnabled } = useDuroFlag();
  const showLotsTab = useMemo(() => (
    isBuildEnabled && component?.previousStatus !== StatusValue.DESIGN
  ), [component?.previousStatus, isBuildEnabled]);
  const isNewObjectHistory = useMemo(() => (
    !!history.location.state?.isNewObject
  ), [history.location.state?.isNewObject]);
  const isNewObjectStore = useSelector((store: any) => store.components.editPage.isNewObject);

  useEffect(() => {
    if (isNewObjectHistory) {
      dispatch(buildAction(ComponentActions.SET_EDIT_PAGE_MODIFIED, true));
      dispatch(buildAction(ComponentActions.SET_IS_NEW_OBJECT_FLAG, true));
    }
  }, [dispatch, isNewObjectHistory, setShouldBlockNavigation]);

  useEffect(() => {
    if (isNewObjectStore) {
      setShouldBlockNavigation({ block: true });
    }
  }, [isNewObjectStore, setShouldBlockNavigation]);

  // Make sure we have a valid tab selected, this needs to wait until the component is loaded.
  const isComponentLoaded = !!component;
  useEffect(() => {
    if (isComponentLoaded) {
      setCurrentTab(getInitialTab({ hasVariants, isAssembly, isEditing, urlQuery: history.location.search }));
    }
  }, [hasVariants, isAssembly, history.location.search, isComponentLoaded, isEditing]);

  // Load the view version of the component from the API.
  useEffect(() => {
    if (isEditing) return;

    setOpenVariantModal(false);
    getViewComponentFromApi();

    // eslint-disable-next-line consistent-return
    return () => {
      dispatch(buildAction(ComponentActions.RESET_STATES_IN_VIEW_PAGE, {}));
    };
  }, [dispatch, getViewComponentFromApi, isEditing]);

  // Load the edit version of the component from the API
  useEffect(() => {
    if (!isEditing) return;

    getEditComponentFromApi();

    // eslint-disable-next-line consistent-return
    return () => {
      setCategoryChanged(false);
      setShouldBlockNavigation({ block: false });
      dispatch(buildAction(ComponentActions.RESET_COMPONENT_EDIT_FORM));
      dispatch(buildAction(ItemAssemblyActions.CLEAR_ASSEMBLY_SEARCH_STATE, { children: [] }));
      dispatch(buildAction(ComponentActions.SET_EDIT_PAGE_COMPONENT_SEARCH_RESULTS, []));
      dispatch(buildAction(DisplaySidebarActions.HIDE_SIDE_BARS));
    };
  }, [dispatch, getEditComponentFromApi, isEditing, setShouldBlockNavigation]);

  const onTabClicked = useCallback((_event, tab) => {
    Utils.setStore("component-tab", tab);
    setCurrentTab(tab);
  }, []);
  const showSourcingTab = useCallback(() => onTabClicked(null, TabValue.SOURCING), [onTabClicked]);

  const updateErrorCount = useCallback((valid: boolean, tab: TabError, amount: number = 1) => {
    setErrorCounts(ec => {
      let count = ec[tab];

      if (!valid) {
        count += amount;
      }
      else {
        count -= amount;
      }

      if (count < 0) {
        count = 0;
      }

      return { ...ec, [tab]: count };
    });
  }, []);
  const setAssemblyErrorCount = useCallback((count: number) => (
    setErrorCounts(ec => ({ ...ec, [TabError.ASSEMBLY]: count }))
  ), []);
  const resetErrorCounts = useCallback(() => setErrorCounts(getInitialErrorCounts()), []);

  const containerRef = useRef();
  const handleClickClearBlockingNavigation = useCallback((event: MouseEvent) => {
    const target = event.target as any;
    const parent = containerRef.current;
    const isExternal = target !== parent && !Utils.isDescendant(parent, target);

    if (
      !isExternal && target.nodeName === "A"
      && (target.innerText === "CANCEL" || target.classList.contains("delete_btn"))
      && target.value === "CANCEL"
    ) {
      setShouldBlockNavigation({ block: false });
    }
  }, [setShouldBlockNavigation]);

  const pageTitle = useMemo(() => (
    component?.name && Utils.makeTitle(`[${cpn}] ${isEditing ? "(EDIT) " : ""}${component.name}`)
  ), [cpn, component?.name, isEditing]);

  const renderLoading = !component || showLoading || isViewComponentLoading;

  const className = useMemo(() => {
    if (!isEditing) return "view-component-route";

    return `edit-component-route${isVendorCmp ? Utils.vendorComponentEditClass(vendorLabel) : ""}`;
  }, [isEditing, isVendorCmp, vendorLabel]);

  return (
    <ComponentModal
      categoryChanged={categoryChanged}
      id={id}
      isAssembly={isAssembly}
      isNewObject={isNewObjectStore}
      pageItemType={pageItemType}
      setShouldBlockNavigation={setShouldBlockNavigation}
      shouldBlockNavigation={shouldBlockNavigation}
      setCategoryChanged={setCategoryChanged}
    >
      <EditTabsContextProvider currentItem={component}>
        <ComponentWrapper
          className={className}
          onClick={handleClickClearBlockingNavigation}
          ref={containerRef}
          data-testid={dataTestId}
        >
          {pageTitle && (
            <Helmet>
              <title>{pageTitle}</title>
            </Helmet>
          )}

          <LeftContent>
            {!renderLoading && (
              <>
                <TopBlock>
                  <ComponentHeader
                    errorCounts={errorCounts}
                    id={id}
                    isEditing={isEditing}
                    isNewObject={isNewObjectStore}
                    openVariantModal={openVariantModal}
                    pageItemType={pageItemType}
                    resetErrorCounts={resetErrorCounts}
                    setOpenVariantModal={setOpenVariantModal}
                    setShowLoading={setShowLoading}
                  />

                  <ComponentTiles
                    isAssembly={isAssembly}
                    isEditing={isEditing}
                    pageItemType={pageItemType}
                  />
                </TopBlock>
                <TabContext className="tabs-block">
                  <TabList value={currentTab} onChange={onTabClicked}>
                    <Tab value={TabValue.DOCUMENTS} errorCount={errorCounts[TabError.DOCUMENT]}>Documents</Tab>
                    {hasSourcing && (
                      <Tab value={TabValue.SOURCING} errorCount={errorCounts[TabError.SOURCING]}>
                        Sourcing
                      </Tab>
                    )}
                    {isAssembly && (
                      <Tab value={TabValue.ASSEMBLY} errorCount={errorCounts[TabError.ASSEMBLY]}>
                        Assembly
                      </Tab>
                    )}
                    {showLotsTab && !isEditing && (
                      <Tab value={TabValue.BUILD} >
                        <BuildTabWapperIcon>
                          <BuildWorkspaceIcon/>
                          Build
                        </BuildTabWapperIcon>
                      </Tab>
                    )}
                    {hasVariants && !isEditing && <Tab value={TabValue.VARIANTS}>Variants</Tab>}
                  </TabList>
                  <TabPanel value={TabValue.DOCUMENTS} currentValue={currentTab}>
                    <DocumentsTab
                      id={id}
                      isDiffTool={isDiffTool}
                      isEditing={isEditing}
                      isVisible={currentTab === TabValue.DOCUMENTS}
                      mode={mode}
                      pageItemType={pageItemType}
                      updateErrorCount={updateErrorCount}
                    />
                  </TabPanel>
                  {hasSourcing && (
                    <TabPanel value={TabValue.SOURCING} currentValue={currentTab}>
                      <SourcingTab
                        activeTab={currentTab}
                        id={id}
                        isAssembly={isAssembly}
                        isDiffTool={isDiffTool}
                        isEditing={isEditing}
                        isVisible={currentTab === TabValue.SOURCING}
                        mode={mode}
                        pageItemType={pageItemType}
                        showSourcingTab={showSourcingTab}
                        updateErrorCount={updateErrorCount}
                      />
                    </TabPanel>
                  )}
                  {isAssembly && (
                    <TabPanel value={TabValue.ASSEMBLY} currentValue={currentTab}>
                      <AssemblyTab
                        id={id}
                        isEditing={isEditing}
                        isVisible={currentTab === TabValue.ASSEMBLY}
                        mode={mode}
                        pageItemType={pageItemType}
                        updateErrorCount={setAssemblyErrorCount}
                      />
                    </TabPanel>
                  )}
                  {showLotsTab && !isEditing && (
                    <TabPanel value={TabValue.BUILD} currentValue={currentTab}>
                      <BuildTab
                        id={id}
                        cpn={component.cpn}
                        gridName={pageItemType}
                        mode={mode}
                        lastReleasedRev={component.lastReleaseCmpRev}
                        modified={component.modified}
                      />
                    </TabPanel>
                  )}
                  {hasVariants && !isEditing && (
                    <TabPanel value={TabValue.VARIANTS} currentValue={currentTab}>
                      <VariantsTab isEditing={isEditing} pageItemType={pageItemType} />
                    </TabPanel>
                  )}
                </TabContext>
              </>
            )}

            {renderLoading && (
              <StyledBackdrop open={renderLoading}>
                <CircularProgress color="inherit" />
              </StyledBackdrop>
            )}
          </LeftContent>

          {isAssembly && isEditing && editObject && (
            <ComponentRightSideBar pageItemType={pageItemType} />
          )}
        </ComponentWrapper>
      </EditTabsContextProvider>
    </ComponentModal>
  );
}

const BuildTabWapperIcon = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "5rem",
});
