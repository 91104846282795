import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={17} height={17} viewBox="0 0 17 17" {...props}>
      <g fillRule="nonzero" fill="#888">
        <path d="M5.773.373H1.424C.826.373.342.955.342 1.665L.337 12.001c0 .71.483 1.292 1.082 1.292h6.529c.598 0 1.087-.581 1.087-1.292V4.25L5.773.373zM1.424 12.001V1.665H5.23v3.23h2.718v7.106H1.424z" />
        <path d="M4.162 5.167V6.76H2.525v1.17h1.637v1.635l2.593-2.2zM12.86 9.667v1.594h1.638v1.17H12.86v1.635l-2.594-2.2z" />
        <path d="M13.516 4.04h-4.35c-.597 0-1.081.581-1.081 1.292l-.006 10.336c0 .71.484 1.292 1.082 1.292h6.53c.598 0 1.087-.581 1.087-1.292V7.916L13.516 4.04zm-4.35 11.628V5.332h3.806v3.23h2.718v7.106H9.167z" />
      </g>
    </svg>
  )
}

export default SvgComponent
