import {
  GridColumnOrderChangeParams, GridColumnResizeParams, GridColumnVisibilityModel, GridSortDirection,
  GridSortItem, GridSortModel,
} from "@mui/x-data-grid-pro";
import { AssemblyTableStyle, FieldStyle } from "design/models";
import { useUser } from "graphql/query/userQueries";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import UserActions from "v1/action-types/user";
import { ColumnTypes, COLUMN_DEFAULTS } from "../../../utils/columnDefaults";

export const MUI_GROUPING_COLUMN_NAME = "__tree_data_group__";

function generateColumnVisibility(
  columns: ColumnTypes[],
  userStyles: AssemblyTableStyle,
): Record<string, boolean> {
  return columns.reduce((coll, field) => {
    // eslint-disable-next-line no-param-reassign
    coll[field] = userStyles[field]?.visibility ?? true;
    return coll;
  }, {} as Record<ColumnTypes, boolean>);
}

function getStyleForName(name: string) {
  return ((window as any).__userStyles.styles[name] || {});
}

export interface UseGetTableStylesArgs {
  defaultSort?: GridSortItem;
  mutateSortColumn?: (name: string) => string;
  styleName: string;
}

/**
 * Retries and handles updating the users styles/preferences for tables.
 *
 * @param defaultSort The default sorting information if there is non in the styles.
 * @param mutateSortColumn A function that will be called that can modify the column being sorted on.
 * @param styleName The name of the styles to get from the user styles.
 * @returns The styles and ways to update them.
 */
export function useGetTableStyles({ defaultSort, mutateSortColumn, styleName }: UseGetTableStylesArgs) {
  // TODO: This needs to move to getting the user settings from GraphQL when they become available.
  // This is using the data put onto the window object, as it is the only place that changes are
  // stored during a users session.

  const userId = useUser().data?.id;
  const dispatch = useDispatch();

  const [sortModel, setSortModel] = useState<GridSortItem[]>([]);

  useEffect(() => {
    const styles = getStyleForName(styleName) as AssemblyTableStyle;
    let field = styles.defaultSortColumnName ?? defaultSort?.field ?? COLUMN_DEFAULTS.cpn.field;

    // Give the caller the option to mutate the actual name used for a field. This is important when
    // dealing with things like grouping columns or tables with changeable set of columns like the
    // assembly table.
    if (mutateSortColumn) {
      field = mutateSortColumn(field);
    }

    let sort = defaultSort?.sort ?? "asc";
    if (styles.defaultSortAssending != null) {
      sort = styles.defaultSortAssending === false ? "desc" : "asc";
    }

    setSortModel([{ field, sort }]);
  }, [defaultSort, mutateSortColumn, styleName]);

  const saveUserStyles = useCallback(newStyles => {
    const styles = (window as any).__userStyles?.styles;

    const updatedStyles = {
      ...styles,
      [styleName]: newStyles,
    };

    dispatch({
      type: UserActions.SAVE_USER_PREFERENCES_IN_LOCAL,
      payload: { data: { styles: updatedStyles }, user_id: userId },
    });
  }, [dispatch, styleName, userId]);

  const updateStyles = useCallback((changes: Record<string, Partial<FieldStyle>>) => {
    const currStyles = { ...getStyleForName(styleName) };

    for (const field in changes) {
      if (Object.prototype.hasOwnProperty.call(changes, field)) {
        currStyles[field] = {
          ...currStyles[field],
          ...changes[field],
        };
      }
    }

    saveUserStyles(currStyles);
  }, [saveUserStyles, styleName]);

  const updatePosition = useCallback((field: string, position: number) => {
    const currStyles = { [field]: {}, ...getStyleForName(styleName) };
    const old = currStyles[field].position;

    for (const name in currStyles) {
      if (Object.prototype.hasOwnProperty.call(currStyles, name)) {
        const style = currStyles[name] as FieldStyle;
        if (name === field) {
          currStyles[name] = { ...style, position };
        }
        else if (style.position > old && style.position <= position) {
          currStyles[name] = { ...style, position: Math.max(style.position - 1, 0) };
        }
        else if (style.position < old && style.position >= position) {
          currStyles[name] = { ...style, position: style.position + 1 };
        }
      }
    }

    if (currStyles) {
      saveUserStyles(currStyles);
    }
  }, [saveUserStyles, styleName]);

  const updateSort = useCallback((field: string, sort: GridSortDirection) => {
    const currStyles = {
      ...getStyleForName(styleName),
      defaultSortAssending: sort === "asc",
      defaultSortColumnName: field,
    };
    saveUserStyles(currStyles);
    setSortModel([{ field, sort }]);
  }, [saveUserStyles, styleName]);

  const getCurrentStyles = useCallback(() => getStyleForName(styleName) as AssemblyTableStyle, [styleName]);

  return { getCurrentStyles, sortModel, updatePosition, updateSort, updateStyles };
}

export interface UseTablePreferencesArgs {
  columnNames: ColumnTypes[];
  defaultSort?: GridSortItem;
  groupingColumnName?: string;
  mutateSortColumn?: (name: string) => string;
  requiredFieldNames?: string[];
  styleName: string;
}

/**
 * Loads and saves information around table preferences and the users styles.
 *
 * @param columnName The list of columns that are currently available to the table.
 * @param styleName The name of the user style to use.
 * @returns Table preferences and ways to update them.
 */
export function useTablePreferences(args: UseTablePreferencesArgs) {
  const { columnNames, defaultSort, groupingColumnName, mutateSortColumn, requiredFieldNames, styleName } = args;

  const {
    getCurrentStyles,
    sortModel,
    updatePosition,
    updateSort,
    updateStyles,
  } = useGetTableStyles({ defaultSort, mutateSortColumn, styleName });

  const hideableFields = useMemo(() => (
    columnNames.filter(name => !requiredFieldNames?.includes(name))
  ), [columnNames, requiredFieldNames]);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    () => generateColumnVisibility(hideableFields, getCurrentStyles()),
  );

  const onColumnsReordered = useCallback((changes: GridColumnOrderChangeParams) => {
    updatePosition(changes.field, Math.max(changes.targetIndex - 1, 0));
  }, [updatePosition]);

  const onColumnsResized = useCallback((changes: GridColumnResizeParams) => {
    let { field } = changes.colDef;
    if (groupingColumnName && field === MUI_GROUPING_COLUMN_NAME) {
      field = groupingColumnName;
    }
    updateStyles({ [field]: { width: changes.width } });
  }, [groupingColumnName, updateStyles]);

  const onColumnVisibilityChange = useCallback((model: GridColumnVisibilityModel) => {
    const newModel = { ...model };
    if (Object.keys(model).length) {
      setColumnVisibilityModel(old => ({ ...old, ...model }));
    }
    else {
      setColumnVisibilityModel(old => {
        for (const key in old) {
          if (Object.prototype.hasOwnProperty.call(old, key)) {
            newModel[key] = true;
          }
        }
        return newModel;
      });
    }
    updateStyles(Object.entries(newModel).reduce((changes, [field, visibility]) => ({
      ...changes,
      [field]: { visibility },
    }), {} as Record<string, Partial<FieldStyle>>));
  }, [updateStyles]);

  const onSortModelChange = useCallback((model: GridSortModel) => {
    if (model.length) {
      updateSort(model[0].field, model[0].sort);
    }
  }, [updateSort]);

  return {
    columnVisibilityModel,
    getCurrentStyles,
    onColumnsReordered,
    onColumnsResized,
    onColumnVisibilityChange,
    onSortModelChange,
    sortModel,
  };
}
