import React, { Component }      from "react"

const SvgIcon = () =>
{
    return <svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg">

    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="REFRESH-6" transform="translate(-532.000000, -312.000000)">
            <path d="M-69.4986288,-663 L1931,-663 L1931,638 L-69.4986288,638 C-73.1662096,528.137778 -75,378.522778 -75,189.155 C-75,-0.212777778 -73.1662096,-284.264444 -69.4986288,-663 Z" id="BKGD" fill="#2A2A36"></path>
            <polygon id="Frame" fill="#353540" points="2 649 1931 649 1931 2 2 2"></polygon>
            <g id="Group-3" transform="translate(49.000000, 99.000000)">
                <g id="DISTRIBUTER" transform="translate(33.000000, 101.000000)">
                    <g id="COST" transform="translate(10.000000, 9.000000)">
                        <rect id="Table-Body-rectangle" stroke="#979797" fill="#FFFFFF" fillRule="nonzero" x="53.5" y="58.5" width="859" height="98"></rect>
                        <g id="1st-row" transform="translate(63.000000, 75.000000)"></g>
                    </g>
                </g>
                <g id="MANU-ROW-2" transform="translate(0.000000, 35.000000)"></g>
                <g id="Verified" transform="translate(483.000000, 213.000000)" stroke="#3CD1B5">
                    <path d="M7.5,14.5 C11.3569883,14.5 14.5,11.3518242 14.5,7.5 C14.5,3.64817578 11.3569883,0.5 7.5,0.5 C3.63501225,0.5 0.5,3.63366181 0.5,7.5 C0.5,11.3663382 3.63501225,14.5 7.5,14.5 Z" id="Shape" fillRule="nonzero"></path>
                    <g id="Group" fillRule="evenodd" transform="translate(4.615385, 4.615385)" stroke-linecap="square">
                        <line x1="0.576923077" y1="4.03846154" x2="1.73076923" y2="5.19230769" id="Line" fillRule="nonzero"></line>
                        <line x1="6.34615385" y1="0.576923077" x2="1.74092579" y2="5.20228852" id="Line" fillRule="nonzero"></line>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>



}

export default SvgIcon




