export function getInitialState()
{
    return {
        loaded : false,

        inputs :
        {
            token :
            {
                value : ""
            },

            email :
            {
                value : ""
            },

            newPassword :
            {
                valid   : false,
                message : "",
                class   : "",
                value   : ""
            },

            confirmPassword :
            {
                valid   : false,
                message : "",
                class   : "",
                value   : ""
            },

            submit :
            {
                class : "disabled"
            }
        }

    }
}

let initialState = getInitialState()
export default initialState
