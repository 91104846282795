import { SvgIcon } from "@mui/material";

export const ProductionsIcon = () => (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g id="Symbols" stroke="none" strokeWidth="1" fillRule="evenodd">
            <g id="A-/-Icon-/-Dashboard-/-Filled">
                {/* eslint-disable-next-line max-len */}
                <path d="M10.8888889,22 L4.22222222,22 C3,22 2,21 2,19.7777778 L2,4.22222222 C2,3 3,2 4.22222222,2 L10.8888889,2 L10.8888889,22 Z M13.1111111,22 L19.7777778,22 C21,22 22,21 22,19.7777778 L22,12 L13.1111111,12 L13.1111111,22 Z M22,9.77777778 L22,4.22222222 C22,3 21,2 19.7777778,2 L13.1111111,2 L13.1111111,9.77777778 L22,9.77777778 Z" id="Shape"></path>
            </g>
        </g>
    </SvgIcon>
);
