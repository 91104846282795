import React      from "react"

const SvgIcon = () =>
{
    return <svg width="21px" height="24px" viewBox="0 0 21 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="RESENT" transform="translate(-1162.000000, -527.000000)">
                <g id="List" transform="translate(310.000000, 517.000000)">
                    <g id="Change-Order-Items"></g>
                </g>
                <g id="LeftSideNumbers" transform="translate(310.000000, 443.000000)"></g>
                <g id="Approved" transform="translate(1162.000000, 490.000000)" fill="#FFFFFF">
                    <g id="RESEND" transform="translate(0.000000, 37.000000)">
                        <g id="RESEND-EMAiL">
                            <g id="noun_Email_3092108">
                                <path d="M21,12.1516588 C21,8.98378648 19.3898477,5.99052133 16.671066,4.11973061 C16.3807107,3.9201796 15.9583756,3.97006735 15.7208122,4.26939386 C15.5096447,4.5437765 15.5624365,4.94287852 15.8791878,5.16737341 C18.2548223,6.78872537 19.6538071,9.40783238 19.6538071,12.1766026 C19.6538071,16.9658269 15.5360406,20.8570716 10.4680203,20.8570716 L10.2832487,20.8570716 L11.1543147,20.0339237 C11.4182741,19.7844849 11.4182741,19.3853829 11.1543147,19.1359441 C10.8903553,18.8865054 10.4680203,18.8865054 10.2040609,19.1359441 L8.19796954,21.0316787 C7.93401015,21.2811175 7.93401015,21.6802195 8.19796954,21.9296583 L10.2040609,23.8253929 C10.3360406,23.9501122 10.4944162,24 10.6791878,24 C10.8639594,24 11.022335,23.9501122 11.1543147,23.8253929 C11.4182741,23.5759541 11.4182741,23.1768521 11.1543147,22.9274133 L10.2832487,22.1042654 L10.4680203,22.1042654 C16.2751269,22.1042654 21,17.6393115 21,12.1516588 Z" id="Shape" fillRule="nonzero"></path>
                                <path d="M1.34619289,11.8504673 C1.34619289,7.06542056 5.46395939,3.17757009 10.5319797,3.17757009 L10.7167513,3.17757009 L9.84568528,4 C9.58172589,4.24922118 9.58172589,4.64797508 9.84568528,4.89719626 C9.97766497,5.02180685 10.1360406,5.07165109 10.3208122,5.07165109 C10.5055838,5.07165109 10.6639594,5.02180685 10.7959391,4.89719626 L12.8020305,3.00311526 C13.0659898,2.75389408 13.0659898,2.35514019 12.8020305,2.105919 L10.7959391,0.186915888 C10.5319797,-0.062305296 10.1096447,-0.062305296 9.84568528,0.186915888 C9.58172589,0.436137072 9.58172589,0.834890966 9.84568528,1.08411215 L10.7167513,1.90654206 L10.5319797,1.90654206 C4.7248731,1.90654206 0,6.36760125 0,11.8504673 C0,15.0155763 1.61015228,18.0062305 4.32893401,19.8753894 C4.46091371,19.9501558 4.5928934,20 4.7248731,20 C4.93604061,20 5.14720812,19.9003115 5.27918782,19.7507788 C5.49035533,19.4766355 5.43756345,19.0778816 5.12081218,18.8535826 C2.7715736,17.2336449 1.34619289,14.6168224 1.34619289,11.8504673 Z" id="Shape" fillRule="nonzero"></path>
                                <path d="M5,8.58598726 L5,15.4140127 C5,15.7452229 5.248307,16 5.57110609,16 L15.4288939,16 C15.751693,16 16,15.7452229 16,15.4140127 L16,8.58598726 C16,8.25477707 15.751693,8 15.4288939,8 L5.5462754,8 C5.248307,8 5,8.25477707 5,8.58598726 Z M6.11738149,14.8280255 L6.11738149,9.91082803 L10.1151242,13.1719745 C10.2392777,13.2484076 10.3634312,13.2738854 10.4875847,13.2738854 C10.6117381,13.2738854 10.7358916,13.2229299 10.8352144,13.1464968 L14.8329571,9.88535032 L14.8329571,14.8025478 L6.11738149,14.8025478 L6.11738149,14.8280255 Z M7.06094808,9.17197452 L13.9390519,9.17197452 L10.4875847,11.9490446 L7.06094808,9.17197452 Z" id="Shape" fillRule="nonzero"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
}

export default SvgIcon
