import { Box, styled } from "@mui/material";

export const PageContent = styled(Box)(() => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  overflowX: "hidden",
  overflowY: "auto",
  paddingBottom: "1rem",
}));
