import { SvgIcon } from "@mui/material";

export const DocumentsIcon = () => (
  <SvgIcon width="24px" height="24px" viewBox="0 0 24 24">
    <g id="Symbols" stroke="none" strokeWidth="1" fillRule="evenodd">
      <g id="A-/-Icon-/-Document-/-Line">
        {/* eslint-disable-next-line max-len */}
        <path d="M8,16 L16,16 L16,18 L8,18 L8,16 Z M8,12 L16,12 L16,14 L8,14 L8,12 Z M14,2 L6,2 C4.9,2 4,2.9 4,4 L4,20 C4,21.1 4.89,22 5.99,22 L18,22 C19.1,22 20,21.1 20,20 L20,8 L14,2 Z M18,20 L6,20 L6,4 L13,4 L13,9 L18,9 L18,20 Z" id="Shape"></path>
      </g>
    </g>
  </SvgIcon>
);
