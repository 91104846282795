
import Utils   from "../../utils"
import Schemas from "../../schemas"

export default class WebhooksAPI
{
    static registerWebhook(data, cb)
    {
        Utils.post("/webhooks/registerWebhook", data , (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static getWebhooks(data, cb)
    {
        Utils.get("/webhooks/getWebhooks", (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static findById(id, cb)
    {
        Utils.get(`/webhooks/${id}`, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static delete(id, cb)
    {
        Utils.get(`/webhooks/delete/${id}`, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static getWebhookEvents(id, cb)
    {
        Utils.get(`/webhooks/getWebhookEvents/${id}`, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static updateWebhook(id, data, cb)
    {
        Utils.post(`/webhooks/updateWebhook/${id}`, data , (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }
}
