import                  "./index.css"
import React           from "react"
import ModalBox        from "../../../../ui/modal-box"
import WebhookFormView from "./webhook-form-view"

export class WebhookModalBox extends React.Component
{
    constructor(props, context)
    {
        super(props, context)
        this.openModal  = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.state          = {
            openModal: true
        }
    }

    openModal()
    {
        this.state.openModal = true
        this.setState(this.state)
    }

    closeModal()
    {
        this.state.openModal = false
        this.setState(this.state, this.props.onClose)
    }

    render()
    {
        let displayModal = this.props.displayModal
        let markup = <div className="webhook-add-modal">
          {
            this.state.openModal ?
            <ModalBox
                onClose={this.closeModal}
                className='new-webhook-modal'
            >
              <WebhookFormView
                  onCancel={this.closeModal}
                  data={this.props.data}
                  mode={this.props.mode}
                  updateWebhook={this.props.updateWebhook}
                  addNewToWebhooks={this.props.addNewToWebhooks}
              />
            </ModalBox>
            : null
          }
        </div>
        return markup
    }
}

export default WebhookModalBox
