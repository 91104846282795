import React from "react";

const SvgComponent = props => (
  <svg
    id="checkbox-svg-with-dot"
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    {...props}
  >
    <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g id="1_VIEW" transform="translate(-377 -518)" stroke="#888">
        <g id="ICONS" transform="translate(377 518)">
          <g id="Primary">
            <path id="Rolled-Over-Check-Box" d="M0.5 0.5H15.5V15.5H0.5z" />
            <circle
              id="Oval"
              fill="#888"
              fillRule="nonzero"
              cx={8}
              cy={8}
              r={2.5}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgComponent;
