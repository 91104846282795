import                              "./index.css";
import React, { Component }         from "react";
import { Popover } from 'react-tiny-popover';
import API                          from "../../../../modules/api";
import LinkIcon                     from "../../../../assets/icons/open-link-icon.js";
import InlineIcon                   from "../../../../components/ui/icon/inline-icon.js";
import Link                         from "../../../../components/ui//link";

export default class ForecastPopover extends Component
{
    constructor(props, context)
    {
        super(props, context);
    }

    addForecast(forecastType)
    {
        this.props.addForecast(forecastType)
        this.props.hideExpertForecastPopover()
    }

    render()
    {
        let markup =
            <Popover
                padding={-90}
                containerClassName={`forecast-type-popover`}
                isOpen={true}
                position={"right"}
                onClickOutside={this.props.hideExpertForecastPopover}
                content={({ position, targetRect, popoverRect }) => (
                    <div className="popover-content-block">
                        <div className="forecast-type" onClick={(e) => this.addForecast("evt")}>EVT</div>
                        <div className="forecast-type" onClick={(e) => this.addForecast("dvt")}>DVT</div>
                        <div className="forecast-type" onClick={(e) => this.addForecast("pvt")}>PVT</div>
                    </div>
                )}
            >
            <div className="forecast-bottom-pointer"></div>
            </Popover>
        return markup;
    }
}
