/**
 * TODO (PLM-6279): The component/file currently is just wrapping the old functionality used here.
 * It needs to be updated to using MUI components and make it so that the data isn't modified in way
 * that are hard to follow and can have unintended side effects.
 */

import { PageItemType } from "design/constants";
import { Location } from "history";
import {
  createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useMemo, useState,
} from "react";
import { useDispatch } from "react-redux";
import { Prompt, useHistory } from "react-router-dom";
import { refetchComponents } from "graphql/query/componentQueries";
import { client } from "graphql/apolloClient";
import ComponentActions from "v1/action-types/component";
import UpdateRevisionModal from "v1/components/page/common/update-revision-modal";
import UpdateRevisionNonAssembly from "v1/components/page/common/update-revision-non-assembly-modal";
import UpdateStatusModal from "v1/components/page/common/update-status-modal";
import StatusUpdateModalForNonAssembly from "v1/components/page/common/update-status-modal-for-non-assembly";
import ConfirmBox from "v1/components/ui/alert/confirm.js";
import WarningModal from "v1/components/ui/warning-modal";
import buildAction from "v1/helpers/buildAction";
import Utils from "v1/modules/utils";
import validations, { validateField } from "v1/modules/validations";
import {
  useCompany, useEditChildren, useEditObject, useGetEditComponentFromApi, useLibraryType, useVariantConfig,
} from "./hooks";
import { useOnSubmit } from "./useOnSubmit";

export interface ComponentAddAssemblyContextType {
  addAssemblyFromFile: (newAssembly: any[]) => void;
  addAssemblyFromLibrary: (newAssembly: any[], newCmpId: string | null) => void;
  addedAssembly: any[] | null;
  addedAssemblyFromFile: boolean;
  cmpId: string | null;
  setAddedAssembly: Dispatch<SetStateAction<any[] | null>>;
  setAddedAssemblyFromFile: Dispatch<SetStateAction<boolean>>;
  setCmpId: Dispatch<SetStateAction<string | null>>;
}

export const ComponentAddAssemblyContext = createContext<ComponentAddAssemblyContextType>({
  addAssemblyFromFile: _newAssembly => { },
  addAssemblyFromLibrary: (_newAssembly, newCmpId) => { },
  addedAssembly: null,
  addedAssemblyFromFile: false,
  cmpId: null,
  setAddedAssembly: _value => { },
  setAddedAssemblyFromFile: _value => { },
  setCmpId: _value => { },
});

export interface ComponentCategoryContextType {
  categoryChanged: boolean;
  setChangedFieldName: Dispatch<SetStateAction<string | null>>;
  setChangedFieldValue: Dispatch<SetStateAction<string | null>>;
}
export const ComponentCategoryContext = createContext<ComponentCategoryContextType>({
  categoryChanged: false,
  setChangedFieldName: _value => { },
  setChangedFieldValue: _value => { },
});

export interface SetShouldBlockNavigation {
  block: boolean;
  saving?: boolean;
}

export interface ComponentModalContextType {
  setShouldBlockNavigation: (args: SetShouldBlockNavigation) => void;
  setShowCategoryChangeAlert: Dispatch<SetStateAction<boolean>>;
  setShowFileImportModal: Dispatch<SetStateAction<boolean>>;
  setShowSaveAsRevisionModal: Dispatch<SetStateAction<boolean>>;
  showFileImportModal: boolean;
  showSaveAsRevisionModal: boolean;
}

export const ComponentModalContext = createContext<ComponentModalContextType>({
  setShouldBlockNavigation: _value => { },
  setShowCategoryChangeAlert: _value => { },
  setShowFileImportModal: _value => { },
  setShowSaveAsRevisionModal: _value => { },
  showFileImportModal: false,
  showSaveAsRevisionModal: false,
});

export interface ComponentRevisionContextType {
  revisionComment: string | null;
  revisionInput: any;
  setRevisionComment: Dispatch<SetStateAction<string | null>>;
  setRevisionInput: Dispatch<SetStateAction<any>>;
}

export const ComponentRevisionContext = createContext<ComponentRevisionContextType>({
  revisionComment: null,
  revisionInput: { valid: false },
  setRevisionComment: _value => { },
  setRevisionInput: _value => { },
});

export interface ComponentStatusContextType {
  setNewStatusValue: Dispatch<SetStateAction<string | null>>;
  setStatusFieldPreviousValue: Dispatch<SetStateAction<string | null>>;
  setTrackStatusChanged: Dispatch<SetStateAction<boolean>>;
}

export const ComponentStatusContext = createContext<ComponentStatusContextType>({
  setNewStatusValue: _value => { },
  setStatusFieldPreviousValue: _value => { },
  setTrackStatusChanged: _value => { },
});

export interface ComponentVariantContextType {
  getVariantDataFromApi: boolean;
  reloadVariantData: Dispatch<SetStateAction<boolean>>;
}

export const ComponentVariantContext = createContext<ComponentVariantContextType>({
  getVariantDataFromApi: false,
  reloadVariantData: (value: SetStateAction<boolean>) => { },
});

export interface ComponentModalContextProviderProps {
  categoryChanged: boolean;
  children?: ReactNode;
  id: string;
  isAssembly: boolean;
  isNewObject: boolean;
  pageItemType: PageItemType;
  setShouldBlockNavigation: (args: SetShouldBlockNavigation) => void;
  shouldBlockNavigation: React.MutableRefObject<boolean>;
  setCategoryChanged: (value: boolean) => void;
}

export function ComponentModal(props: ComponentModalContextProviderProps) {
  const {
    categoryChanged,
    children,
    id,
    isAssembly,
    isNewObject,
    pageItemType,
    setShouldBlockNavigation,
    shouldBlockNavigation,
    setCategoryChanged,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const company = useCompany();
  const editObject = useEditObject(pageItemType);
  const editChildren = useEditChildren();
  const getEditComponentFromApi = useGetEditComponentFromApi(id);
  const { isCpnVariantEditable, isCpnVariantScheme } = useVariantConfig({ isCreating: isNewObject });
  const onSubmit = useOnSubmit({ isNewObject, pageItemType, isCpnVariantScheme, isCpnVariantEditable });
  const libraryType = useLibraryType();

  const [addedAssembly, setAddedAssembly] = useState<any>(null);
  const [addedAssemblyFromFile, setAddedAssemblyFromFile] = useState(false);
  const [cmpId, setCmpId] = useState<null | string>(null);
  const addAssemblyFromLibrary = useCallback((newlyAddedComponents, newCmpId = null) => {
    setAddedAssembly(newlyAddedComponents);
    setCmpId(newCmpId);
  }, []);
  const addAssemblyFromFile = useCallback(newlyAddedComponents => {
    setAddedAssemblyFromFile(true);
    setAddedAssembly(newlyAddedComponents);
  }, []);

  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [showFileImportModal, setShowFileImportModal] = useState(false);

  const [changedFieldName, setChangedFieldName] = useState<string | null>(null);
  const [changedFieldValue, setChangedFieldValue] = useState<string | null>(null);
  const [showCategoryChangeAlert, setShowCategoryChangeAlert] = useState<boolean>(false);

  const [revisionInput, setRevisionInput] = useState<any>({ valid: false });
  const [revisionComment, setRevisionComment] = useState<string | null>(null);
  const [showSaveAsRevisionModal, setShowSaveAsRevisionModal] = useState(false);

  const [newStatusValue, setNewStatusValue] = useState<string | null>(null);
  const [statusFieldPreviousValue, setStatusFieldPreviousValue] = useState<string | null>(null);
  const [trackStatusChanged, setTrackStatusChanged] = useState(false);

  const [getVariantDataFromApi, reloadVariantData] = useState(false);

  const addFromLibraryValue: ComponentAddAssemblyContextType = useMemo(() => ({
    addAssemblyFromFile,
    addAssemblyFromLibrary,
    addedAssembly,
    addedAssemblyFromFile,
    cmpId,
    setAddedAssembly,
    setAddedAssemblyFromFile,
    setCmpId,
  }), [addAssemblyFromFile, addAssemblyFromLibrary, addedAssembly, addedAssemblyFromFile, cmpId]);

  const categoryValue = useMemo(() => ({
    categoryChanged,
    setChangedFieldName,
    setChangedFieldValue,
  }), [categoryChanged]);

  const modalValue = useMemo(() => ({
    setShouldBlockNavigation,
    setShowCategoryChangeAlert,
    setShowFileImportModal,
    setShowSaveAsRevisionModal,
    showFileImportModal,
    showSaveAsRevisionModal,
  }), [setShouldBlockNavigation, showFileImportModal, showSaveAsRevisionModal]);

  const revisionValue = useMemo(() => ({
    revisionComment,
    revisionInput,
    setRevisionComment,
    setRevisionInput,
  }), [revisionComment, revisionInput]);

  const statusValue = useMemo(() => ({
    setNewStatusValue,
    setStatusFieldPreviousValue,
    setTrackStatusChanged,
  }), []);

  const variantValue = useMemo(() => ({ getVariantDataFromApi, reloadVariantData }), [getVariantDataFromApi]);

  const handleBlockedNavigation = useCallback((nextLocation: Location) => {
    if (shouldBlockNavigation.current) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  }, [shouldBlockNavigation]);

  const handleConfirmNavigationClick = useCallback(() => {
    setModalVisible(false);
    setShouldBlockNavigation({ block: false });
    setLastLocation(null);

    if (lastLocation) {
      history.push(lastLocation);
    }
  }, [history, lastLocation, setShouldBlockNavigation]);

  const closeModalDialog = useCallback(() => {
    setModalVisible(false);
  }, []);

  const setStateFromNonAssemblyComponent = useCallback((revisionObject: any, comment: string) => {
    setRevisionInput(revisionObject);
    setRevisionComment(comment);
    setShowSaveAsRevisionModal(false);
    setShouldBlockNavigation({ block: false });
    onSubmit(null, { revisionComment: comment, revision: revisionObject?.value });
  }, [onSubmit, setShouldBlockNavigation]);

  const saveAssemblyAsRevision = useCallback((event: any, options: any = null) => {
    setShowSaveAsRevisionModal(false);
    setShouldBlockNavigation({ block: false });
    onSubmit(event, options);
  }, [onSubmit, setShouldBlockNavigation]);

  const onAcceptStatusChange = useCallback(() => {
    dispatch(buildAction(ComponentActions.UPDATE_EDIT_FORM_INPUT_STATE, {
      name: "status",
      value: newStatusValue,
    }));

    dispatch(buildAction(ComponentActions.UPDATE_EDIT_FORM_INPUT_STATE, {
      name: "revision",
      value: revisionInput.value,
    }));

    setTrackStatusChanged(false);
  }, [dispatch, newStatusValue, revisionInput]);

  const refreshAfterBulkUpdate = useCallback(async (
    bulkRevisionValue = "",
    bulkStatusValue = "",
    componentIds = [],
  ) => {
    window.localStorage.removeItem("lastAssemblyTree");
    window.localStorage.removeItem("lastAssemblyParent");
    onAcceptStatusChange();
    getEditComponentFromApi(bulkRevisionValue, bulkStatusValue);
    await refetchComponents(client, componentIds);
  }, [getEditComponentFromApi, onAcceptStatusChange]);

  const hideUpdateStatusModal = useCallback(() => setTrackStatusChanged(false), []);

  const { revSchemeType = "DEFAULT" } = company.data;
  const { defaultBlacklistedRevisions } = company.data.settings ?? {};
  const revisionInputChange = useCallback((event: any) => {
    const { value } = event.target;
    const { revisions } = editObject;
    const status = newStatusValue || "DESIGN";

    const revValue = value.toUpperCase();
    const previousRevision = Utils.getPreviousRevision(editObject);
    const validationPayload = {
      defaultBlacklistedRevisions,
      isClient: true,
      isSaveAsRev: true,
      libraryType,
      previousRevision,
      revisions,
      revSchemeType,
      status,
    };
    const revisionInputCopy = { ...revisionInput };
    validateField(revisionInputCopy, validations.component.revision, revValue, validationPayload);
    setRevisionInput(revisionInputCopy);
  }, [defaultBlacklistedRevisions, editObject, libraryType, newStatusValue, revSchemeType, revisionInput]);

  const onAcceptCategoryChange = useCallback(() => {
    dispatch(buildAction(ComponentActions.UPDATE_EDIT_FORM_INPUT_STATE, {
      name: changedFieldName,
      value: changedFieldValue,
    }));

    setShowCategoryChangeAlert(false);
    setCategoryChanged(true);
    setChangedFieldName(null);
    setChangedFieldValue(null);
  }, [changedFieldName, changedFieldValue, dispatch, setCategoryChanged]);

  const onRejectFieldChanged = useCallback(() => {
    setShowCategoryChangeAlert(false);
    setTrackStatusChanged(false);
    setChangedFieldName(null);
    setChangedFieldValue(null);
  }, []);

  return (
    <ComponentAddAssemblyContext.Provider value={addFromLibraryValue}>
      <ComponentCategoryContext.Provider value={categoryValue}>
        <ComponentModalContext.Provider value={modalValue}>
          <ComponentRevisionContext.Provider value={revisionValue}>
            <ComponentStatusContext.Provider value={statusValue}>
              <ComponentVariantContext.Provider value={variantValue}>
                {/*
                  As the file import modal has it's own prompt, this is needed so that this prompt
                  will take over once it is closed. Because of how onbeforeunload works, only the
                  last created prompt will be valid.
                */}
                {!showFileImportModal && <Prompt message={handleBlockedNavigation} />}
                {children}
                {modalVisible && (
                  <WarningModal
                    description="You have unsaved changes. If you navigate away without first saving,
                      the changes will be lost."
                    onCancel={closeModalDialog}
                    onConfirm={handleConfirmNavigationClick}
                    title="Changes will be lost"
                  />
                )}

                {!isAssembly && showSaveAsRevisionModal && editObject && (
                  <UpdateRevisionNonAssembly
                    revisionInput={revisionInput}
                    component={editObject}
                    newStatusValue={newStatusValue}
                    onContinue={setStateFromNonAssemblyComponent}
                    toogleSaveAsRevisionModal={setShowSaveAsRevisionModal}
                  />
                )}

                {isAssembly && showSaveAsRevisionModal && editObject && (
                  <UpdateRevisionModal
                    editPage={true}
                    parent={editObject}
                    children={editChildren}
                    hideUpdateStatusModal={hideUpdateStatusModal}
                    parentNewStatus={newStatusValue}
                    refreshAfterBulkUpdate={refreshAfterBulkUpdate}
                    onSubmit={saveAssemblyAsRevision}
                    showHideSaveAsRevisionModal={setShowSaveAsRevisionModal}
                  />
                )}

                {!isAssembly && trackStatusChanged && editObject && (
                  <StatusUpdateModalForNonAssembly
                    statusFieldPreviousValue={statusFieldPreviousValue}
                    newStatusValue={newStatusValue}
                    revisionInput={revisionInput}
                    revisionInputChange={revisionInputChange}
                    onRejectStatusChange={hideUpdateStatusModal}
                    onAcceptStatusChange={onAcceptStatusChange}
                    item={pageItemType}
                  />
                )}

                {isAssembly && trackStatusChanged && editObject && (
                  <UpdateStatusModal
                    editPage={true}
                    parent={editObject}
                    hideUpdateStatusModal={hideUpdateStatusModal}
                    parentNewStatus={newStatusValue}
                    parentNewRevision={revisionInput.value}
                    refreshAfterBulkUpdate={refreshAfterBulkUpdate}
                  />
                )}

                {showCategoryChangeAlert && !categoryChanged && (
                  <ConfirmBox
                    confirm={onAcceptCategoryChange}
                    reject={onRejectFieldChanged}
                    heading="Category change may result in loss of data."
                    confirmButtonText="Continue"
                    text="You will lose category specific data by changing the
                            category. Are you sure you want to continue?"
                  >
                    <div>
                      <h1>Category Change may result in loss of data. </h1>
                      <p>
                        You will lose Category Specific data by changing the
                        category. Are you sure you want to continue?
                      </p>
                    </div>
                  </ConfirmBox>
                )}
              </ComponentVariantContext.Provider>
            </ComponentStatusContext.Provider>
          </ComponentRevisionContext.Provider>
        </ComponentModalContext.Provider>
      </ComponentCategoryContext.Provider>
    </ComponentAddAssemblyContext.Provider>
  );
}

export function useComponentAddAssemblyContext() {
  return useContext(ComponentAddAssemblyContext);
}

export function useComponentCategoryContext() {
  return useContext(ComponentCategoryContext);
}

export function useComponentModalContext() {
  return useContext(ComponentModalContext);
}

export function useComponentRevisionContext() {
  return useContext(ComponentRevisionContext);
}

export function useComponentStatusContext() {
  return useContext(ComponentStatusContext);
}

export function useComponentVariantContext() {
  return useContext(ComponentVariantContext);
}
