import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={20} height={16} viewBox="0 0 20 16" {...props}>
      <g strokeWidth={0.25} fillRule="nonzero" fill="#888" stroke="#888">
        <path d="M12.667 7.533a3.276 3.276 0 003.266-3.266A3.276 3.276 0 0012.667 1 3.276 3.276 0 009.4 4.267a3.276 3.276 0 003.267 3.266zm0-5.444c1.197 0 2.177.98 2.177 2.178 0 1.197-.98 2.177-2.177 2.177a2.184 2.184 0 01-2.178-2.177c0-1.198.98-2.178 2.178-2.178zM19.667 12.332c0-2.014-1.648-3.702-3.721-3.838l-.256-.027-.17.163a3.529 3.529 0 01-2.387.926c-.88 0-1.732-.327-2.386-.926l-.17-.163h-.256C8.248 8.63 6.6 10.317 6.6 12.332V15h13.067v-2.668zm-1.137 1.58H7.736v-1.58c0-1.36 1.051-2.531 2.443-2.722a4.729 4.729 0 002.954 1.034c1.108 0 2.13-.38 2.955-1.061 1.391.218 2.442 1.36 2.442 2.722v1.606zM5.667 8.467c1.54 0 2.8-1.26 2.8-2.8 0-1.54-1.26-2.8-2.8-2.8-1.54 0-2.8 1.26-2.8 2.8 0 1.54 1.26 2.8 2.8 2.8zm0-4.48c.924 0 1.68.756 1.68 1.68 0 .924-.756 1.68-1.68 1.68-.924 0-1.68-.756-1.68-1.68 0-.924.756-1.68 1.68-1.68zM5.667 13.03h-3.63v-1.037c0-.856.7-1.556 1.556-1.556h.051c.57.519 1.245.778 2.023.778v-1.037c-.57 0-1.063-.208-1.452-.622L4.059 9.4h-.466A2.6 2.6 0 001 11.993v2.074h4.667V13.03z" />
      </g>
    </svg>
  )
}

export default SvgComponent
