import mapActions from '../helpers/mapActions';

export default mapActions(
  [
    'UPDATE_FORM_STATE',
    'SET_USER_PROFILE_FORM',
    'TOGGLE_PASSWORD_BLOCK',
    'ON_INPUT_CHANGE',
    'ON_SUBMIT',
    'UPLOAD_IMAGE'
  ],
  'USER_PROFILE'
  );
