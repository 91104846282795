import { useCallback, FC } from "react";
import { Box, SelectChangeEvent, styled } from "@mui/material";
import { CustomDuroCheckbox } from "common/components/checkbox";
import { Select, Switch } from "common/components/inputs";
import { InputFieldVariants } from "common/constants";
import { colorPalette } from "theme";
import { useChangeOrderFormContext } from "../../changeOrderContextProvider";

const checkboxLabelStyles = { fontSize: "0.75rem", marginLeft: "0.80rem" };
const itemTypeOptions = ["1", "2", "3"];
const selectComponentStyles = { marginBottom: "1rem" };
const selectInputStyleProps = {
  sx: {
    fontSize: "1rem",
    padding: "0.625rem",
  },
};
const switchLabelStyles = { color: colorPalette.silver, fontSize: "0.8rem", width: "6rem" };

export const ItemType: FC = () => {
  const {
    setItemTypeData,
    getItemTypeData,
  } = useChangeOrderFormContext();
  const { isItemTypeEnabled, itemType, overrideItemType } = getItemTypeData();

  const handleSetItemTypeChange = useCallback(() => setItemTypeData(
    { isItemTypeEnabled: !isItemTypeEnabled },
  ), [isItemTypeEnabled, setItemTypeData]);

  const handleItemTypeChange = useCallback((event: SelectChangeEvent) => {
    setItemTypeData({ itemType: event.target.value });
  }, [setItemTypeData]);

  const handleOverrideItemTypeChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setItemTypeData({ overrideItemType: event.target.checked });
  }, [setItemTypeData]);

  return (
    <CustomItemTypeComponent>
      <CustomSwitch>
        <Switch
          defaultValue={isItemTypeEnabled}
          label={"Set Item Type: "}
          labelPosition={"start"}
          labelStyle={switchLabelStyles}
          onChange={handleSetItemTypeChange}
        />
      </CustomSwitch>
      <StyledComp isEnabled={isItemTypeEnabled}>
        <Select
          componentStyles={selectComponentStyles}
          defaultValue={itemType}
          disabled={!isItemTypeEnabled}
          displayEmpty={true}
          inputProps={selectInputStyleProps}
          isRequired={false}
          label={"Select Item Type"}
          onChange={handleItemTypeChange}
          options={itemTypeOptions}
          placeholder={"Select"}
          variant={InputFieldVariants.STANDARD}
        />
      </StyledComp>
      <CustomCheckbox>
        <CustomDuroCheckbox
          checkBoxLabel={"Override"}
          checked={overrideItemType}
          checkboxFontSize={"1rem"}
          disabled={!isItemTypeEnabled}
          onChange={handleOverrideItemTypeChange}
          labelStyles={checkboxLabelStyles}
        />
      </CustomCheckbox>
    </CustomItemTypeComponent>
  );
};

const StyledComp = styled(Box, {
  shouldForwardProp: prop => prop !== "isEnabled",
})<{ isEnabled: boolean }>(({ isEnabled }) => ({
  color: isEnabled ? colorPalette.white : colorPalette.disabled,
  paddingRight: "2rem",
  width: "60%",
}));

const CustomCheckbox = styled(Box)({
  marginTop: "0.6rem",
});

const CustomSwitch = styled(Box)({
  marginTop: "1rem",
  paddingRight: "1rem",
});

const CustomItemTypeComponent = styled(Box)({
  alignItems: "center",
  display: "flex",
});
