import React, { Component }     from 'react';
import InlineIcon               from "../icon/inline-icon.js"
import AvatarIcon               from "../../../assets/icons/user-icon.js"
import API                      from "../../../modules/api"
import Utils                    from "../../../modules/utils"

class Avatar extends Component
{
    constructor(props)
    {
        super(props)
        this.state =
        {
            imageId: this.props.imageId,
            imagesWithSrc: this.props.imagesWithSrc ? this.props.imagesWithSrc : null,
            avatar: null,
            isLoading: true
        }
        this.isAlreadyLoaded = this.isAlreadyLoaded.bind(this)
    }

    componentDidMount ()
    {
        let imageId = this.state.imageId
        let {loaded, avatar} = this.isAlreadyLoaded(imageId)

        if(imageId && !loaded)
        {
            API.images.findById(imageId, (err, response) =>
            {
                if(err)
                {
                    console.log(err)
                }
                else
                {
                    if (!this.isAlreadyLoaded(imageId).loaded && this.props.imagesWithSrc)
                    {
                        this.props.imagesWithSrc.push({_id: response._id, src: response.src})
                    }
                    this.setState({avatar: response})
                }
            });
        }
        else
        {
            this.setState({avatar: avatar})
        }
    }

    isAlreadyLoaded(imageId)
    {
        let imagesWithSrc = this.props.imagesWithSrc
        let result = {avatar: null, loaded: false}
        if (imagesWithSrc && typeof imageId === "string")
        {
            for(let imgWithSrc of imagesWithSrc)
            {
                if (imageId === imgWithSrc._id)
                {
                    result = {avatar: imgWithSrc, loaded: true}
                    break
                }
            }
        }
        else if (imageId && typeof imageId === "object" && imageId.hasOwnProperty("src"))
        {
            result = {avatar: imageId, loaded: true}
        }
        return result
    }

    render()
    {
        const i = this.props.index
        const {avatar, isLoading} = this.state
        return (
            <div className="user-avatar-holder" onClick={this.props.onClick ? this.props.onClick : null}>
                {
                  isLoading
                    ? (
                        <span className={"face-avatar"} key={i + '-face-avatar'}>
                            <InlineIcon>
                                <AvatarIcon/>
                            </InlineIcon>
                        </span>
                      )
                    : ''
                }
                <span className={"user-profile-img " + (isLoading ? "hidden" : "")} key={i + '-avatar'}>
                    <img
                        src={avatar && avatar.src ? avatar.src : ""}
                        onLoad={() => this.setState({ isLoading: false })}
                        alt="avatar"
                    />
                </span>
            </div>
            )
    }
}
//TODO: Added validations for params
export default Avatar
