import ITEM_ASSEMBLY from "../../action-types/item-assembly"
import initialState, { getInitialState } from "./initial_state"
import { handleActions } from "redux-actions"
import Utils from "../../modules/utils"

function setItemAssembly(state=initialState, { payload })
{
    state.children = payload.children
    return {...state}
}

function setState(state=initialState, { payload })
{
    return {...state}
}

function resetState(state=initialState, { payload })
{
    state.selectedTextForProductList = "Select Product"
    state.widthClass = "small"
    state.children = []
    state.productList = []
    state.class = "close"
    state.buttonHighlight = false
    return {...state}
}

function setSelectedTextForProductList(state=initialState, { payload })
{
    state.selectedTextForProductList = payload.selectedTextForProductList
    state._id = payload._id
    state.class = payload.class
    state.buttonHighlight = payload.buttonHighlight
    return {...state}
}

function setProductList(state=initialState, { payload })
{
    state.productList = payload.productList
    return {...state}
}

function setOpenItemList(state=initialState, { payload })
{
    state.openItemList = payload
    return {...state}
}

function resetItemAssembly(state=initialState, { payload })
{
    state.resetAssembly = payload
    return {...state}
}

function updateDisplayAsseblySearch(state=initialState, { payload })
{
    state.displayAsseblySearch = payload
    return {...state}
}

function addSearchResults(state=initialState, { payload })
{
    state.searchResults = payload
    return {...state}
}

function clearAssemblySearchState(state=initialState, { payload })
{
    state.search.value            = ""
    state.search.selections       = []
    state.searchResults           = []
    state.list                    = []
    state.search.inputs           = []
    state.search.results          = []
    state.search.makeCustomSearch = false
    state.search.selectAllFlag    = false
    state.search.selectAllButoonClazz = ""
    return {...state}
}

function setAssemblyState(state=initialState, { payload })
{
    state.inputs.submit.class = Utils.isValidated(state.inputs) && state.list.length > 0
    ? ""
    : "disabled"
    return {...state}
}

function createSearchInputs(state=initialState, { payload })
{
    let searchInputs = []
    let results = state.searchResults
    results.forEach((result, i) =>
    {
        let input =
        {
            cpn   : result.cpn,
            name   : result.name,
            checked: false,
            index : i,
            isAddedInList : false
        }
        searchInputs.push(input)
    })
    state.search.inputs = searchInputs
    state.search.results = state.searchResults
    return {...state}
}

function updateSearchInputs(state=initialState, { payload })
{
    state.search.inputs = payload
    return {...state}
}

function updateSearchInProgress(state=initialState, { payload })
{
    state.search.searchInProgress = payload
    return {...state}
}

export default handleActions({
  [ITEM_ASSEMBLY.SET_ITEM_ASSEMBLY_LIST]: setItemAssembly,
  [ITEM_ASSEMBLY.SET_ITEM_ASSEMBLY_STATE]: setState,
  [ITEM_ASSEMBLY.RESET_STATE]: resetState,
  [ITEM_ASSEMBLY.SET_SELECTED_TEXT_FOR_PRODUCT_LIST]: setSelectedTextForProductList,
  [ITEM_ASSEMBLY.SET_PRODUCT_LIST]: setProductList,
  [ITEM_ASSEMBLY.SET_OPEN_ITEM_LIST]: setOpenItemList,
  [ITEM_ASSEMBLY.RESET_ITEM_LIST]: resetItemAssembly,
  [ITEM_ASSEMBLY.UPDATE_DISPLAY_ASSEBLY_SEARCH]: updateDisplayAsseblySearch,
  [ITEM_ASSEMBLY.ADD_SEARCH_RESULTS]: addSearchResults,
  [ITEM_ASSEMBLY.CLEAR_ASSEMBLY_SEARCH_STATE]: clearAssemblySearchState,
  [ITEM_ASSEMBLY.SET_ASSEMBLY_STATE]: setAssemblyState,
  [ITEM_ASSEMBLY.CREATE_SEARCH_INPUTS]: createSearchInputs,
  [ITEM_ASSEMBLY.UPDATE_SEARCH_INPUTS]: updateSearchInputs,
  [ITEM_ASSEMBLY.SEARCH_IN_PROGRESS]: updateSearchInProgress,
  

}, initialState);
