import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="19"
      viewBox="0 0 16 19"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-806 -210)">
          <g transform="translate(429 44)">
            <g transform="translate(50 163)">
              <g transform="translate(302.75)">
                <g transform="translate(20)">
                  <path d="M0 0L25 0 25 25 0 25z"></path>
                  <path
                    className="path"
                    fill="#CFCFCF"
                    fillRule="nonzero"
                    d="M14.5 3H6.9c-1.045 0-1.89.855-1.89 1.9L5 20.1c0 1.045.846 1.9 1.89 1.9H18.3c1.045 0 1.9-.855 1.9-1.9V8.7L14.5 3zM6.9 20.1V4.9h6.65v4.75h4.75V20.1H6.9z"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
