import { GridCellEditStopParams, GridCellEditStopReasons } from "@mui/x-data-grid-pro";
import { GridDataType } from "design/components/grid";
import { PageItemType } from "design/constants";
import { AssemblyChild } from "design/models";
import { client } from "graphql/apolloClient";
import { usePrimaryCompany } from "graphql/query/companyQueries";
import { getComponents } from "graphql/query/componentQueries";
import { pick } from "lodash";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AssemblyEditActions from "v1/action-types/assembly-edit.js";
import { useComponentAddAssemblyContext, useComponentModalContext } from "../componentModal";
import { REF_DES_DELIMITER_REGEX, validateChanges } from "./assemblyTabValidation";
import { childToGridData } from "./useGridHelpers";

/**
 * Takes a value that can be a string, number, null, or undefined, and then returns a valid number
 * or null. NaN in converted to null. Uses parseInt to parse string.
 *
 * @param num The value to make sure is a valid number or null
 * @returns A valid number or null.
 */
function handleUpdatedInt(num?: number | string | null): number | null {
  if (typeof num === "string") {
    const newNum = parseInt(num, 10);
    if (Number.isInteger(newNum)) {
      return newNum;
    }

    return null;
  }

  if (num == null || isNaN(num)) {
    return null;
  }

  return num;
}

/**
 * Takes a value that can be a string, number, null, or undefined, and then returns a valid number
 * or null. NaN in converted to null. Uses parseFloat to parse string.
 *
 * @param num The value to make sure is a valid number or null
 * @returns A valid number or null.
 */
function handleUpdatedFloat(num?: number | string | null): number | null {
  if (typeof num === "string") {
    const newNum = parseFloat(num);
    if (Number.isFinite(newNum)) {
      return newNum;
    }

    return null;
  }

  if (num == null || isNaN(num)) {
    return null;
  }

  return num;
}

interface UpdateChildrenArgs {
  adds?: GridDataType[]
  changes?: Record<string, AssemblyChild>;
  remove?: string[];
}

export interface OnInputValidationChangeArgs {
  error: boolean;
  field: string;
  id: string;
}

export interface UseGridUpdatesArgs {
  gridData: GridDataType[];
  hasRefDes: boolean;
  pageItemType: PageItemType;
  setChildLoadingCount: Dispatch<SetStateAction<number>>;
  setGridData: Dispatch<SetStateAction<GridDataType[]>>;
  updateErrorCount: (amount: number) => void;
}

export function useGridUpdates(args: UseGridUpdatesArgs) {
  const {
    gridData,
    hasRefDes,
    pageItemType,
    setChildLoadingCount,
    setGridData,
    updateErrorCount,
  } = args;

  const dispatch = useDispatch();
  const { data: company } = usePrimaryCompany();
  const { isAllowedBlankItemNumber } = company?.settings ?? {};
  const {
    addedAssembly,
    addedAssemblyFromFile,
    setAddedAssembly,
    setAddedAssemblyFromFile,
  } = useComponentAddAssemblyContext();
  const { setShouldBlockNavigation } = useComponentModalContext();

  // Handle error counts from active inputs and the validation logic.

  const [inputErrors, setInputErrors] = useState<Set<string>>(new Set());
  //  Adding flag so that validations should run initially
  const [shouldValidateInitially, setShouldValidateInitially] = useState(true);
  const [validationErrors, setValidationErrors] = useState(0);

  useEffect(() => {
    const totalErrors = inputErrors.size + validationErrors;
    updateErrorCount(totalErrors);
  }, [inputErrors, updateErrorCount, validationErrors]);

  //  Need to validate assembly input fields initially
  useEffect(() => {
    if (shouldValidateInitially && gridData.length) {
      const res = validateChanges({
        adds: [],
        changes: {},
        children: gridData,
        hasRefDes,
        initialItemNumberValues: [],
        initialRefDesValues: [],
        isAllowedBlankItemNumber,
        pageItemType,
      });
      setValidationErrors(res.errorCount);
      setShouldValidateInitially(false);
    }
  }, [gridData, hasRefDes, isAllowedBlankItemNumber, pageItemType, shouldValidateInitially]);

  const onInputValidationChange = useCallback(({ error, field, id }: OnInputValidationChangeArgs) => {
    setInputErrors(old => {
      const updated = new Set(old);
      const identifier = `${id}-${field}`;

      if (error) {
        updated.add(identifier);
      }
      else {
        updated.delete(identifier);
      }

      return updated;
    });
  }, []);

  const onCellEditStop = useCallback((cellEditStopArgs: GridCellEditStopParams) => {
    const { id, field, reason } = cellEditStopArgs;
    if (reason === GridCellEditStopReasons.escapeKeyDown) {
      onInputValidationChange({ error: false, field, id: id as string });
    }
  }, [onInputValidationChange]);

  // Core logic for updating the children locally and in redux, as well as validation of the data.
  const updateChildren = useCallback((updateChangesArgs: UpdateChildrenArgs) => {
    const { adds, changes, remove } = updateChangesArgs;
    let updates: Record<string, AssemblyChild> | null = null;
    let errorCount = 0;
    setShouldBlockNavigation({ block: true });

    // Surround the setting of the state in a promise so that we can have logic that always happens
    // after the state is updated. This is required, as otherwise react can cause the set state to
    // happen at an indeterminable time and causes issues with the logic.
    Promise.resolve().then(() => setGridData(curr => {
      let children = curr;
      const initialItemNumberValues = [] as number[];
      const initialRefDesValues = [] as string[];

      // Clear out removed children before running validation, and collect information for column
      // based validation related to them.
      if (remove?.length) {
        children = children.filter(c => {
          if (!remove.includes(c.item?.id ?? "")) return true;

          if (c.itemNumber != null) {
            initialItemNumberValues.push(c.itemNumber);
          }

          if (c.refDes) {
            initialRefDesValues.push(...c.refDes.split(REF_DES_DELIMITER_REGEX));
          }

          return false;
        });
      }

      const res = validateChanges({
        adds,
        changes: changes ?? {},
        children,
        hasRefDes,
        initialItemNumberValues,
        initialRefDesValues,
        isAllowedBlankItemNumber,
        pageItemType,
      });

      errorCount = res.errorCount;

      const updatedMap = {} as Record<string, AssemblyChild>;
      res.affectedChildIndexes.forEach(index => {
        const uid = res.updatedChildren[index].item?.id ?? "";
        updatedMap[uid] = pick(
          res.updatedChildren[index],
          ["_validations", "itemNumber", "notes", "refDes", "quantity", "waste"],
        );
      });
      updates = updatedMap;

      return res.updatedChildren;
    })).then(() => {
      setValidationErrors(errorCount);
      if (updates || remove) dispatch({ type: AssemblyEditActions.UPDATE_CHILDREN, updates, remove });
    });
  }, [dispatch, hasRefDes, isAllowedBlankItemNumber, pageItemType, setGridData, setShouldBlockNavigation]);

  // Handles loading in added children from the different locations (library, file, vendor, manual).
  useEffect(() => {
    if (!addedAssembly?.length) return;
    setAddedAssembly(null);
    setAddedAssemblyFromFile(false);
    setChildLoadingCount(count => count + 1);

    const addedMap = addedAssembly.reduce((map, child) => ({
      ...map,
      [child.component._id]: child,
    }), {});
    const addedIds = Object.keys(addedMap);

    getComponents(client, addedIds).then(res => {
      const { components } = res;
      if (components?.length) {
        const added = [] as GridDataType[];
        const reduxAppend = [] as AssemblyChild[];
        const remove = [] as string[];
        const updated = {} as Record<string, AssemblyChild>;

        const childMap = {} as Record<string, GridDataType>;
        gridData.forEach(child => {
          const childId = child.item?.id || "";
          childMap[childId] = child;

          // When adding a new assembly from file, all children that do not show up in the file need
          // to be removed.
          if (addedAssemblyFromFile && !addedIds.includes(childId)) {
            remove.push(childId);
          }
        });

        components.forEach(comp => {
          const current = childMap[comp.id];
          const { inputs, ...assembly } = addedMap[comp.id];

          const updates = {
            itemNumber: handleUpdatedInt(assembly.itemNumber),
            notes: assembly.notes ?? "",
            quantity: handleUpdatedFloat(assembly.quantity),
            refDes: assembly.refDes ?? "",
            waste: handleUpdatedFloat(assembly.waste),
          };

          if (current) {
            updated[comp.id] = updates;
          }
          else {
            reduxAppend.push(assembly);
            added.push(childToGridData({
              ...updates,
              component: comp,
              variants: [],
            }));
          }
        });

        dispatch({
          type: AssemblyEditActions.UPDATE_CHILDREN,
          append: reduxAppend,
        });
        updateChildren({ adds: added, changes: updated, remove });
      }
    }).finally(() => setChildLoadingCount(count => count - 1));
  }, [
    addedAssembly,
    addedAssemblyFromFile,
    dispatch,
    gridData,
    setAddedAssembly,
    setAddedAssemblyFromFile,
    setChildLoadingCount,
    setGridData,
    updateChildren,
  ]);

  // Processes changes made while editing a cell within the grid table.
  const processRowUpdate = useCallback((newRow: GridDataType, oldRow: GridDataType) => {
    const changes: AssemblyChild = {};

    const newItemNumber = handleUpdatedInt(newRow.itemNumber);
    if (newItemNumber !== oldRow.itemNumber) {
      changes.itemNumber = newItemNumber;
    }

    if (newRow.notes !== oldRow.notes) {
      changes.notes = newRow.notes;
    }

    const newQuantity = handleUpdatedFloat(newRow.quantity);
    if (newQuantity !== oldRow.quantity) {
      changes.quantity = newQuantity;
    }

    if (newRow.refDes !== oldRow.refDes) {
      changes.refDes = newRow.refDes;
    }

    const newWaste = handleUpdatedFloat(newRow.waste);
    if (newWaste !== oldRow.waste) {
      changes.waste = newWaste;
    }

    updateChildren({ changes: { [newRow.item?.id ?? ""]: changes } });

    return newRow;
  }, [updateChildren]);

  const onRemoveRow = useCallback((compId: string) => {
    updateChildren({ remove: [compId] });
  }, [updateChildren]);

  return { processRowUpdate, onCellEditStop, onInputValidationChange, onRemoveRow, setInputErrors };
}
