import { SvgIcon } from "@mui/material";

export const DashboardIcon = () => (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g>
            {/* eslint-disable-next-line max-len */}
            <path d="M11 21H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h6v18zm2 0h6c1.1 0 2-.9 2-2v-7h-8v9zm8-11V5c0-1.1-.9-2-2-2h-6v7h8z" />,
        </g>
    </SvgIcon>
);
