import { SvgIcon } from "@mui/material";

export const CopyIcon = () => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g>
      {/* eslint-disable-next-line max-len */}
      <path d="M1.65853659,20 C1.20325203,20 0.81300813,19.8373984 0.487804878,19.5121951 C0.162601626,19.1869919 0,18.796748 0,18.3414634 L0,3.68292683 L1.65853659,3.68292683 L1.65853659,18.3414634 L13.1707317,18.3414634 L13.1707317,20 L1.65853659,20 Z M4.7804878,16.8780488 C4.34146341,16.8780488 3.95528455,16.7113821 3.62195122,16.3780488 C3.28861789,16.0447154 3.12195122,15.6585366 3.12195122,15.2195122 L3.12195122,1.65853659 C3.12195122,1.20325203 3.28861789,0.81300813 3.62195122,0.487804878 C3.95528455,0.162601626 4.34146341,0 4.7804878,0 L15.4146341,0 C15.8699187,0 16.2601626,0.162601626 16.5853659,0.487804878 C16.9105691,0.81300813 17.0731707,1.20325203 17.0731707,1.65853659 L17.0731707,15.2195122 C17.0731707,15.6585366 16.9105691,16.0447154 16.5853659,16.3780488 C16.2601626,16.7113821 15.8699187,16.8780488 15.4146341,16.8780488 L4.7804878,16.8780488 Z M4.7804878,15.2195122 L15.4146341,15.2195122 L15.4146341,1.65853659 L4.7804878,1.65853659 L4.7804878,15.2195122 L4.7804878,15.2195122 Z M4.7804878,15.2195122 L4.7804878,1.65853659 L4.7804878,15.2195122 L4.7804878,15.2195122 Z"></path>

    </g>
  </SvgIcon>
);
