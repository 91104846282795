import mapActions from '../helpers/mapActions';

export default mapActions(
  [
    'SET_INITIAL_STATE',
    'SUBMIT_INVITE_FORM',
    'GET_INVITE',
    'UPDATE_LOADED_STATE',
    'ON_INPUT_CHANGE',
    'UPDATE_INPUTS_STATE',
  ],
  'INVITE'
  );
