import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      x="0"
      y="0"
      fill="#fff"
      viewBox="0 0 100 100"
    >
      <path className='path' d="M81.22 38.174l-16.57-9.566c-.279-7.586-11.827-20.73-13.159-22.221-.758-.851-2.225-.85-2.983 0-1.331 1.491-12.877 14.634-13.158 22.22l-16.57 9.567a2 2 0 00-1 1.732v34.897c0 .715.381 1.375 1 1.732L49 93.982a2.008 2.008 0 002 0l30.22-17.447a2 2 0 001-1.732V39.906c0-.715-.381-1.375-1-1.732zM50 55.043L23.78 39.906l12.129-7.002c1.751 6.138 7.398 10.65 14.091 10.65s12.34-4.511 14.092-10.649l12.128 7.002L50 55.043zm0-44.268c4.632 5.502 10.664 14.032 10.664 18.114 0 5.88-4.784 10.664-10.664 10.664S39.337 34.77 39.337 28.889c0-4.077 6.031-12.609 10.663-18.114zM21.78 43.37L48 58.508v30.277L21.78 73.648V43.37zM52 88.785V58.508L78.22 43.37v30.279L52 88.785z"></path>
    </svg>
  );
}

export default Icon;
