import React, {Component}       from "react";
import Loading                  from "../../../../../ui/inner-loading";
import Utils                    from "../../../../../../modules/utils";
import ComponentProductUtils    from "../../../../../../modules/component-product-utils";
import ExtendedTable            from "../../../../common/extended-table";
import { connect }              from "react-redux";
import Link                     from "../../../../../ui/link";
import TimeStampColumn          from "../../../../common/timestamp-column";
import { DocumentRegenerateButton } from "../../../../common/document-regenerate-button";
import Config from "../../../../../../modules/config";
export class Documents extends Component
{
    constructor(props)
    {
        super(props)
        this.pusher = null;
        let styles;
        try
        {
            styles = window.__userStyles.styles.releaseDocumentsTable || {};
        }
        catch(error) {
            styles = {};
        }

        this.state =
        {
            data: this.props.documents || [],
            syncWithParentState: false,
            currentSortItemAscending: ("defaultSortAssending" in styles ? styles.defaultSortAssending : true),
            current : ("defaultSortColumnName" in styles ? styles.defaultSortColumnName : "name"),
            headings :
            [
                {
                    key         : "name",
                    displayName : "Name",
                    tooltip     : "Name",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 71,
                    width       : Utils.getStyleValue(styles, "name", "width", 254),
                    position    : Utils.getStyleValue(styles, "name", "position", 0),
                    visibility  : Utils.getStyleValue(styles, "name", "visibility", true),
                    disabled    : false
                },

                {
                    key         : "type",
                    displayName : "Doc type",
                    tooltip     : "Doc type",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 91,
                    width       : Utils.getStyleValue(styles, "type", "width", 91),
                    position    : Utils.getStyleValue(styles, "type", "position", 1),
                    visibility  : Utils.getStyleValue(styles, "type", "visibility", true),
                    disabled    : true
                },

                {
                    key         : "ext",
                    displayName : "File type",
                    tooltip     : "File type",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 71,
                    width       : Utils.getStyleValue(styles, "ext", "width", 151),
                    position    : Utils.getStyleValue(styles, "ext", "position", 2),
                    visibility  : Utils.getStyleValue(styles, "ext", "visibility", true),
                    disabled    : false
                },

                {
                    key         : "size",
                    displayName : "File size",
                    tooltip     : "File size",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 71,
                    width       : Utils.getStyleValue(styles, "size", "width", 111),
                    position    : Utils.getStyleValue(styles, "size", "position", 3),
                    visibility  : Utils.getStyleValue(styles, "size", "visibility", true),
                    disabled    : false
                },

                {
                    key         : "revision",
                    displayName : "Revision",
                    tooltip     : "Revision",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 71,
                    width       : Utils.getStyleValue(styles, "revision", "width", 120),
                    position    : Utils.getStyleValue(styles, "revision", "position", 4),
                    visibility  : Utils.getStyleValue(styles, "revision", "visibility", true),
                    disabled    : false
                },

                {
                    key         : "status",
                    displayName : "Status",
                    tooltip     : "Status",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 71,
                    width       : Utils.getStyleValue(styles, "status", "width", 145),
                    position    : Utils.getStyleValue(styles, "status", "position", 5),
                    visibility  : Utils.getStyleValue(styles, "status", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "lastUpdated",
                    displayName : "Last Updated",
                    tooltip     : "Last Updated",
                    sortable    : true,
                    ascending   : true,
                    minWidth    : 170,
                    width       : Utils.getStyleValue(styles, "lastUpdated", "width", 170),
                    position    : Utils.getStyleValue(styles, "lastUpdated", "position", 6),
                    visibility  : Utils.getStyleValue(styles, "lastUpdated", "visibility", true),
                    disabled    : false
                },
            ]

        }
        this.updateDocState           = this.updateDocState.bind(this);
        this.getRows                  = this.getRows.bind(this);
        this.afterSyncWithParentState = this.afterSyncWithParentState.bind(this)
        this.getCompanyId             = this.getCompanyId.bind(this);
    }

    afterSyncWithParentState()
    {
        this.setState({syncWithParentState: false});
    }

    getCompanyId()
    {
        let {releaseObject} = this.props;
        if(releaseObject && releaseObject.company)
        {
            return Utils.extractId(releaseObject.company);
        }
        return null;
    }

    componentDidMount()
    {
        if(this.props.initializePusher && !this.pusher) this.pusher = Utils.initPusher();
        let companyId                     = this.getCompanyId();
        let { data, syncWithParentState } = this.state;
        if(companyId && this.pusher) 
        {
            let channel = this.pusher.subscribe(`company-${companyId}`);
            channel.bind('document-regeneration', res => 
            {
                if(!res) return;
                let { doc, found } = Utils.getMatchedDocument(data, res.doc._id);
                if (!found) return;
                doc.specs.lastModified = res.lastModified;
                doc.file.status        = res.doc.status;
                syncWithParentState    = true;
                this.setState({ syncWithParentState, data });
            });
        }
    }

    componentWillUnmount()
    {
        let companyId = this.getCompanyId();
        if(companyId && this.pusher)
        {
            this.pusher.unsubscribe(`company-${companyId}`);
        }
    }

    updateDocState(docId)
    {
        let {syncWithParentState, data} = this.state;
        let {doc, found} = Utils.getMatchedDocument(data, docId);
        if(!found) return;
        doc.file.status = "PROCESSING";
        syncWithParentState = true;
        this.setState({data, syncWithParentState});
    }

    getRows()
    {
        let data = Utils.filterDocuments(this.state.data, this.props.releaseObject && this.props.releaseObject.vendor);
        let rows = data.map((result, i) =>
        {
            let allowRowSelect = !!(result.quote_id && result.quoteMinQuantity !== null && result.quoteUnitPrice !== null )
            let lastModified        = result.specs && result.specs.lastModified ? result.specs.lastModified : undefined;
            let lastModifiedTooltip = lastModified ? Utils.dateTimeWithLongFormat(lastModified) : null;

            let parentVendor        =  this.props.releaseObject && this.props.releaseObject.vendor ?  this.props.releaseObject.vendor.toUpperCase() : "";
            let needsToBeRegenerated= Utils.fileCanBeRegenerated(parentVendor, result.file);

            let fileData =
            {
                vendor: parentVendor,
                fileType: Utils.extractDocumentTypeFromFileName(result.file.name)
            };

            let cells =

            {
                "name" :
                {
                    value        : result.file.name,
                    displayValue : <span className={needsToBeRegenerated ? "" : "link"}>{result.file.name}</span>,
                    tooltip      : result.file.name,
                    notLink      : needsToBeRegenerated ? true : false
                },

                "type" :
                {
                    value        : result.specs.type,
                    displayValue : result.specs.type,
                    tooltip      : result.specs.type,
                    notLink      : true
                },

                "ext" :
                {
                    value        : Utils.getExtension(result.file.name),
                    displayValue : Utils.getExtension(result.file.name).toUpperCase(),
                    tooltip      : Utils.getExtension(result.file.name).toUpperCase(),
                    notLink      : true
                },

                "size" :
                {
                    value        : Utils.fileSize(result.file.size),
                    displayValue : Utils.fileSize(result.file.size),
                    tooltip      : Utils.fileSize(result.file.size),
                    notLink      : true
                },

                "revision" :
                {
                    value        : result.specs.revision,
                    displayValue : result.specs.revision,
                    tooltip      : result.specs.revision,
                    notLink      : true
                },

                "status" :
                {
                    value        : result.specs.status,
                    displayValue : result.specs.status,
                    tooltip      : result.specs.status,
                    notLink      : true
                },
                "lastUpdated" :
                {
                    value        : lastModified ? lastModified : '',
                    displayValue : lastModified && <TimeStampColumn key={Utils.generateUniqueId()} format='date-time-with-long-format' value={lastModified} />,
                    tooltip      : lastModified ? `${lastModifiedTooltip.dateValue} ${lastModifiedTooltip.timeValue}` : '',
                    notLink      : true
                },
                "indexTableEl"  : needsToBeRegenerated ? <DocumentRegenerateButton docId={result.file._id} fileData={fileData} docState={result.file.status} updateDocState={this.updateDocState}/> : null,
                onClick         : () => {},
                rowLink         : needsToBeRegenerated ? "" : result.file.src || "",
                simpleLink      : true

            }
        return cells

        })

        return rows
    }

    render()
    {
        if(this.props.loading)
        {
            return <Loading />
        }

        let tableHeaders = this.state.headings;
        let rows         = this.getRows();

        let markup  =
                <div className="documents-view-block">
                    <ExtendedTable
                        syncWithParentState={this.state.syncWithParentState}
                        afterSyncWithParentState={this.afterSyncWithParentState}
                        wrapperClassName="documents-list"
                        wrapperSelectorClass="documents-list"
                        headings={tableHeaders}
                        rows={rows}
                        resultText={`${rows.length} Documents`}
                        stylesName="releaseDocumentsTable"
                        borderedTable={true}
                        allowRowSelect={false}
                        includeToolBar={true}
                        currentSortItem={this.state.current}
                        currentSortItemAscending={this.state.currentSortItemAscending}
                        footerRow={ rows.length === 0 && {dataCellEl: <p>No Documents added</p> }}
                    />
                </div>

        return markup;
    }
}

export default Documents;
