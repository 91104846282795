import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-435 -152)">
          <g transform="translate(393 91)">
            <g transform="translate(42 61)">
              <circle
                cx="10"
                cy="10"
                r="10"
                fill="#F54A4F"
                fillRule="nonzero"
              ></circle>
              <text
                fill="#000"
                fontFamily="Roboto-Bold, Roboto"
                fontSize="14"
                fontWeight="bold"
              >
                <tspan x="8" y="15">
                  !
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
