import ITEM_DIFF            from "../../action-types/item-diff"
import { handleActions }    from "redux-actions"
import initialState, { getInitialState } from "./initial_state"

function setRelease(state=initialState, { payload })
{
    state.item  = payload.item;
    state.sourceRev  = payload.sourceRev;
    state.targetRev  = payload.targetRev;
    return {...state};
}

function resetState(state=initialState)
{
    state.item = null;
    state.sourceRev = null;
    state.targetRev = null;
    return {...state};
}

function clearTargetRev(state=initialState)
{
    state.targetRev = null;
    return {...state};
}

function setTargetRev(state=initialState, { payload })
{
    state.targetRev  = payload.targetRev;
    return {...state};
}

export default handleActions({
  [ITEM_DIFF.SET_DIFF_STATE]: setRelease,
  [ITEM_DIFF.RESET_DIFF_STATE]: resetState,
  [ITEM_DIFF.CLEAR_TARGET_REV]: clearTargetRev,
  [ITEM_DIFF.SET_TARGET_REV]: setTargetRev,
}, initialState);
