import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="#fffff"
        d="M0 0v24h24V0zm22.629 22.629H12.7V12.7h9.929zm0-11.301H12.7V1.371h9.929zM11.301 1.371V11.3H1.372V1.371zm-9.93 11.328H11.3v9.929H1.371z"
      ></path>
    </svg>
  );
}

export default Icon;
