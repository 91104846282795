import                              "./index.css"
import React, { Component } from    "react"
import closeSrc             from "../../../assets/icons/close.svg"
import Icon                 from "../../ui/icon"
import ReactTooltip         from 'react-tooltip'

class ModalBox extends Component
{
    constructor(props, context) {
        super(props, context);
        this.onClose = this.onClose.bind(this);
        this.escKeyPress = this.escKeyPress.bind(this);
        this.state          = {
            closeClass : ''
        }
    }

    escKeyPress(event)
    {
        if(event.keyCode === 27 && this.props.onClose) {
            this.props.onClose();
        }
    }

    componentDidMount()
    {
        ReactTooltip.rebuild()
        let bodyClass = this.props.bodyClass ? (this.props.bodyClass + ' open') : 'open'
        document.querySelector("body").dataset.modal = bodyClass
        document.addEventListener("keydown", this.escKeyPress, false);
    }

    componentWillUnmount()
    {
        document.removeEventListener("keydown", this.escKeyPress, false);
    }

    componentDidUpdate()
    {
        ReactTooltip.rebuild()
    }

    onClose(e)
    {
        if(document.getElementsByClassName('popover-tool-tip-container')[0])
        {
            document.getElementsByClassName('popover-tool-tip-container')[0].style.zIndex = "99"
        }
        let state = this.state
        state.closeClass = 'zoomOutAnimation'
        this.setState(state)
        let _this = this
        setTimeout( () => {
            _this.props.onClose(e)
        }, 300)
    }

    componentWillUnmount()
    {
        delete document.querySelector("body").dataset.modal
    }

    render()
    {
        let markup = null
        markup =
            <div className={"ui-modal-box zoomInAnimation " + this.props.className + ' ' + this.state.closeClass}>
                <div className="background"
                    // onClick={this.props.reject}
                    >

                </div>
                <div className="modal">
                    <div className={"modal-content"}>
                        {this.props.children}
                    </div>

                    <div className="buttons modal-close">
                        {
                            this.props.hideCrossIcon ? ""
                            :
                            <Icon src={closeSrc}
                            onClick={(e) => this.onClose(e)}
                            />
                        }
                    </div>
                </div>
            </div>


        return markup
    }
}

export default ModalBox
