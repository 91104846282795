import * as React from "react"

const Icon = (props) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.19 18h1.8v-7.2h-1.8V18ZM12 8.58c.28 0 .515-.09.705-.27.19-.18.285-.41.285-.69 0-.28-.095-.52-.285-.72A.933.933 0 0 0 12 6.6c-.28 0-.515.1-.705.3-.19.2-.285.44-.285.72 0 .28.095.51.285.69.19.18.425.27.705.27ZM12 24c-1.64 0-3.19-.315-4.65-.945a12.172 12.172 0 0 1-3.825-2.58 12.172 12.172 0 0 1-2.58-3.825C.315 15.19 0 13.63 0 11.97c0-1.64.315-3.19.945-4.65.63-1.46 1.49-2.73 2.58-3.81A12.301 12.301 0 0 1 7.35.945C8.81.315 10.37 0 12.03 0c1.64 0 3.19.315 4.65.945 1.46.63 2.73 1.485 3.81 2.565a12.118 12.118 0 0 1 2.565 3.81c.63 1.46.945 3.02.945 4.68 0 1.64-.315 3.19-.945 4.65a12.301 12.301 0 0 1-2.565 3.825 11.993 11.993 0 0 1-3.81 2.58c-1.46.63-3.02.945-4.68.945Z"
      fill="#29B6F6"
      fillRule="nonzero"
    />
  </svg>
)

export default Icon
