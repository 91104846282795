export const enum TabValue {
  ASSEMBLY = "assembly",
  BUILD = "build",
  DOCUMENTS = "documents",
  SOURCING = "sourcing",
  VARIANTS = "variants",
}

export const enum TabError {
  ASSEMBLY = "assemblyTab",
  DOCUMENT = "documentTab",
  SOURCING = "sourcingTab",
}

export const enum AssemblyView {
  GRID = "grid",
  TREE = "tree",
}

export const enum TreeViewCollapse {
  COLLAPSE = "collapse",
  EXPAND = "expand",
  EXPAND_LEVEL = "expandLevel",
}

export const enum ActionType {
  NEW = "new",
  UPDATE = "update",
}

export const enum EditModal {
  FILE_IMPORT = "displayFileImport",
  MANUAL = "displayManualModal",
  VENDOR = "displayVendorModal",
}
