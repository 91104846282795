import { useContext, useEffect } from "react";
import { useImages as useImagesQuery } from "graphql/query/imagesQueries";
import { imageContext } from "common/components/imageContext/imageContextProvider";

export const useImages = (imageIds: string[] | undefined) => {
  const { setImageIds } = useContext(imageContext);

  const { images, imageLoading } = useImagesQuery(imageIds ?? [], "cache-only");

  useEffect(() => {
    if (!imageLoading && !images?.length && imageIds?.length) {
      setImageIds(imageIds);
    }
  }, [images?.length, imageIds, imageLoading, setImageIds]);

  return { imageList: images ?? [] };
};
