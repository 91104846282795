export function getInitialState()
{
    return {
        release: 		 null,
        loading: 		 false,
    }

}

let initialState = getInitialState()
export default initialState
