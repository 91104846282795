
import { compose, createStore, combineReducers, applyMiddleware } from "redux"
import createSagaMiddleware from "redux-saga"

import userReducer         from '../reducers/user';
import companyReducer      from '../reducers/company';
import UserProfileReducer  from '../reducers/user_profile';
import uiReducer           from '../reducers/ui';
import productReducer      from "../reducers/product";
import componentReducer    from '../reducers/component';
import changeOrdersReducer from '../reducers/changeorders';
import usersReducer        from '../reducers/users';
import userFormReducer     from '../reducers/user_form';
import searchReducer       from "../reducers/search"
import InviteReducer       from '../reducers/invite';
import ItemAssemblyReducer from '../reducers/item-assembly';
import OnBoardingReducer   from '../reducers/on-boarding';
import AssemblyEditReducer from '../reducers/assembly-edit.js';
import SidebarReducer      from "../reducers/displaysidebar";
import ReleaseReducer      from '../reducers/release';
import ItemDiffReducer     from '../reducers/item-diff';


import userSaga from '../sagas/user';
import companySaga         from '../sagas/company';
import userprofileSaga     from '../sagas/user_profile';
import productSaga         from "../sagas/product"
import componentsSaga      from '../sagas/component';
import changeordersSaga    from '../sagas/changeorder';
import usersSaga           from '../sagas/users';
import userFormSaga        from '../sagas/user_form';
import searchSaga          from "../sagas/search"
import inviteSaga          from '../sagas/invite';
import itemAssemblySaga    from '../sagas/item-assembly';
import onBoardingSaga      from '../sagas/on-boarding';
import assemblyEditSaga    from '../sagas/assembly-edit.js';
import releaseSaga         from '../sagas/release';
import itemDiffSaga        from '../sagas/item-diff';


import {createLogger}      from "redux-logger";
import config              from "../modules/config";

const sagaMiddleware = createSagaMiddleware({
    onError(error) {
        setImmediate(() => {
          throw error;
        });
    }
})

const loggerMiddleware = createLogger()

const middleware =
[
    sagaMiddleware
]

if(config.LOG_REDUX)
{
    middleware.push(loggerMiddleware)
}
// Add the reducer to your store on the `routing` key
const store = createStore(
  combineReducers({
    ui: uiReducer,
    user: userReducer,
    company: companyReducer,
    product: productReducer,
    components: componentReducer,
    changeorders: changeOrdersReducer,
    user_profile: UserProfileReducer,
    userForm: userFormReducer,
    users: usersReducer,
    search: searchReducer,
    invite: InviteReducer,
    item_assembly: ItemAssemblyReducer,
    on_boarding: OnBoardingReducer,
    assembly_edit: AssemblyEditReducer,
    displaysidebar: SidebarReducer,
    release : ReleaseReducer,
    itemDiff : ItemDiffReducer,

  }),
  {},
  compose(
    applyMiddleware(...middleware),
    config.LOG_REDUX && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__() : f => f
  )
)

sagaMiddleware.run(userSaga);
sagaMiddleware.run(companySaga);
sagaMiddleware.run(userprofileSaga);
sagaMiddleware.run(productSaga)
sagaMiddleware.run(componentsSaga);
sagaMiddleware.run(changeordersSaga);
sagaMiddleware.run(userFormSaga);
sagaMiddleware.run(usersSaga);
sagaMiddleware.run(searchSaga);
sagaMiddleware.run(inviteSaga);
sagaMiddleware.run(itemAssemblySaga);
sagaMiddleware.run(onBoardingSaga);
sagaMiddleware.run(assemblyEditSaga);
sagaMiddleware.run(releaseSaga);
sagaMiddleware.run(itemDiffSaga);

export default store
