import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={17} height={14} viewBox="0 0 17 14" {...props}>
      <g fill="none" fillRule="evenodd">
        <path stroke="#979797" strokeWidth={1.5} d="M.75.75h15.5v12.5H.75z" />
        <g fill="#888" fillRule="nonzero">
          <path d="M3 3h4.547v1.5H3zM3 6h4.547v1.5H3zM3 9h4.547v1.5H3z" />
          <g>
            <path d="M9.45 3h4.547v1.5H9.45zM9.45 6h4.547v1.5H9.45zM9.45 9h4.547v1.5H9.45z" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
