import {
  createContext, useContext, useState, useMemo, ReactNode, useCallback, FC,
} from "react";
import { Date } from "common/components/inputs/dateTimePicker";
import { ChangeOrderTabValue, ChangeOrderType } from "./constants";

export interface ICoEditPageProvider {
  children: ReactNode;
}
export interface IEffectivityData {
  endDate: Date,
  isEffectivityEnabled: boolean,
  overrideEffectivity: boolean,
  startDate: Date,
}
export interface IItemTypeData {
  isItemTypeEnabled: boolean,
  itemType: string,
  overrideItemType: boolean,
}

export interface IChangeOrderFormContextProps {
  coType: ChangeOrderType,
  description: string,
  erpToggle: boolean;
  getEffectivityData: () => IEffectivityData,
  getItemTypeData: () => IItemTypeData,
  isSaveAsDraftEnabled: boolean, // Need to handle the Draft button state
  isSubmitForApprovalEnabled: boolean, // Need to handle the Approval button state
  name: string;
  setCoType: (value: ChangeOrderType) => void,
  setDescription: (value: string) => void,
  setEffectivityData: (value: Partial<IEffectivityData>) => void,
  setErpToggle: (value: boolean) => void;
  setIsSaveAsDraftEnabled: (value: boolean) => void,
  setIsSubmitForApprovalEnabled: (value: boolean) => void,
  setItemTypeData: (value: Partial<IItemTypeData>) => void,
  setName: (value: string) => void,
}

export interface ITabsContextProps {
  currentTab: ChangeOrderTabValue,
  setCurrentTab: (value: ChangeOrderTabValue) => void,
}
const ChangeOrderFormContext = createContext<IChangeOrderFormContextProps>({
  coType: ChangeOrderType.ECO,
  description: "",
  erpToggle: false,
  getEffectivityData: () => ({
    endDate: null,
    isEffectivityEnabled: false,
    overrideEffectivity: false,
    startDate: null,
  }),
  getItemTypeData: () => ({
    itemType: "",
    isItemTypeEnabled: false,
    overrideItemType: false,
  }),
  isSaveAsDraftEnabled: false,
  isSubmitForApprovalEnabled: false,
  name: "",
  setCoType: () => { },
  setDescription: () => { },
  setEffectivityData: _value => { },
  setErpToggle: () => { },
  setIsSaveAsDraftEnabled: () => { },
  setIsSubmitForApprovalEnabled: () => { },
  setItemTypeData: _value => { },
  setName: () => { },
});

const ChangeOrderTabsContext = createContext<ITabsContextProps>({
  currentTab: ChangeOrderTabValue.CMP_PRD,
  setCurrentTab: () => { },
});

export const CoEditPageProvider: FC<ICoEditPageProvider> = ({ children }) => {
  const [coType, setCoType] = useState<ChangeOrderType>(ChangeOrderType.ECO);
  const [currentTab, setCurrentTab] = useState<ChangeOrderTabValue>(ChangeOrderTabValue.CMP_PRD);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [isSaveAsDraftEnabled, setIsSaveAsDraftEnabled] = useState<boolean>(false);
  const [isSubmitForApprovalEnabled, setIsSubmitForApprovalEnabled] = useState<boolean>(false);
  const [erpToggle, setErpToggle] = useState<boolean>(false);
  const [effectivityValues, setEffectivityValues] = useState<IEffectivityData>({
    endDate: null,
    isEffectivityEnabled: false,
    overrideEffectivity: false,
    startDate: null,
  });
  const [itemTypeValues, setItemTypeValues] = useState<IItemTypeData>({
    itemType: "",
    isItemTypeEnabled: false,
    overrideItemType: false,
  });

  const setEffectivityData = useCallback(data => {
    setEffectivityValues(prevData => ({ ...prevData, ...data }));
  }, []);

  const setItemTypeData = useCallback(data => {
    setItemTypeValues(prevData => ({ ...prevData, ...data }));
  }, []);

  const getEffectivityData = useCallback(() => effectivityValues, [effectivityValues]);
  const getItemTypeData = useCallback(() => itemTypeValues, [itemTypeValues]);

  const ChangeOrderTabsContextValues = useMemo(() => ({
    currentTab,
    setCurrentTab,
  }), [currentTab]);

  const ChangeOrderFormContextValues = useMemo(() => ({
    coType,
    description,
    erpToggle,
    getEffectivityData,
    getItemTypeData,
    isSaveAsDraftEnabled,
    isSubmitForApprovalEnabled,
    name,
    setCoType,
    setDescription,
    setEffectivityData,
    setErpToggle,
    setIsSaveAsDraftEnabled,
    setIsSubmitForApprovalEnabled,
    setItemTypeData,
    setName,
  }), [coType, description, erpToggle, getEffectivityData, getItemTypeData,
    isSaveAsDraftEnabled, isSubmitForApprovalEnabled, name, setEffectivityData, setItemTypeData,
  ]);

  return (
    <ChangeOrderFormContext.Provider value={ChangeOrderFormContextValues}>
      <ChangeOrderTabsContext.Provider value={ChangeOrderTabsContextValues}>
        {children}
      </ChangeOrderTabsContext.Provider>
    </ChangeOrderFormContext.Provider >
  );
};

export function useChangeOrderFormContext() {
  return useContext(ChangeOrderFormContext);
}

export function useChangeOrderTabsContext() {
  return useContext(ChangeOrderTabsContext);
}
