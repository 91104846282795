import React, { Component } from 'react';
import ModalBox             from "../modal-box";
import InlineIcon           from "../../ui/icon/inline-icon.js";
import WarningIcon          from "../../../assets/icons/warning-triangle-black-bg.js";


class ConfirmAlert extends Component
{
    render()
    {
        let { errorType, text, heading, confirmButtonText } = this.props;
        let isWarning = errorType === "warning";
        let warningClass = isWarning ? "global-modal-box-for-errors warning-err" : "save-changes ";
        let markup =
            <div className={`new-warning-modal ${warningClass}`}>
                <ModalBox onClose={this.props.reject} className="field-change-confirm">
                    {
                      isWarning &&
                      <InlineIcon>
                        <WarningIcon/>
                      </InlineIcon>
                    }
                    <h1>
                      { heading }
                    </h1>
                    <div className="warning-text">
                        <p className="save-warning-text" dangerouslySetInnerHTML={{ __html: text }} >
                        </p>
                    </div>
                    <div className="bottom-section">
                        <div className="button-sections">
                          <button
                              type="button"
                              className="dark allow"
                              onClick={this.props.reject}
                              >
                            Cancel
                          </button>

                          <button
                              type="button"
                              onClick={this.props.confirm}
                              className="filled allow">
                            { confirmButtonText }
                          </button>

                        </div>
                    </div>
                </ModalBox>
            </div>
        return markup
    }
}

export default ConfirmAlert
