import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#888" transform="translate(-1127 -384)">
          <g transform="translate(1102 80)">
            <g transform="translate(25 301)">
              <g transform="matrix(-1 0 0 1 14 3)">
                <g fillRule="nonzero">
                  <path d="M9.29 9.492a.608.608 0 00-.594.593v2.729h-7.51V1.186h7.51v2.73c0 .316.277.592.593.592a.608.608 0 00.593-.593V.593A.608.608 0 009.29 0H.593A.608.608 0 000 .593v12.814c0 .316.277.593.593.593h8.696a.608.608 0 00.593-.593v-3.322a.582.582 0 00-.593-.593z"></path>
                  <path d="M14 7.455v-.103-.069-.068c0-.034-.033-.034-.033-.068l-.032-.035v-.034l-2.65-2.806a.46.46 0 00-.686 0 .512.512 0 000 .718l1.766 1.917H5.432c-.262 0-.49.24-.49.513 0 .274.228.514.49.514h6.9L10.566 9.85a.512.512 0 000 .719.457.457 0 00.327.137c.131 0 .262-.069.36-.171l2.583-2.772s0-.035.033-.035v-.034c.033-.034.033-.068.066-.103v-.034c0-.034.032-.068.032-.102H14z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
