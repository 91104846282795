import Utils   from "../../utils"
import Schemas from "../../schemas"

class HeadingAPI
{
    static create(data, cb)
    {
        Utils.post("/headings", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static findByCompany(cb)
    {
        Utils.get("/headings", (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

             return cb(null, res.data)
        })
    }

}

export default HeadingAPI
