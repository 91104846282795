import React, { Component }      from "react"

const Icon = () =>
{
    return <svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" >

        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="REFRESH-5" transform="translate(-532.000000, -343.000000)">
            <path d="M-69.4986288,-663 L1931,-663 L1931,638 L-69.4986288,638 C-73.1662096,528.137778 -75,378.522778 -75,189.155 C-75,-0.212777778 -73.1662096,-284.264444 -69.4986288,-663 Z" id="BKGD" fill="#2A2A36"></path>
            <polygon id="Frame" fill="#353540" points="2 649 1931 649 1931 2 2 2"></polygon>
            <g id="Group-3" transform="translate(49.000000, 99.000000)">
                <g id="DISTRIBUTER" transform="translate(33.000000, 101.000000)">
                    <g id="COST" transform="translate(10.000000, 9.000000)">
                        <rect id="Table-Body-rectangle" stroke="#979797" fill="#FFFFFF" fillRule="nonzero" x="53.5" y="58.5" width="859" height="98"></rect>
                        <g id="1st-row" transform="translate(63.000000, 75.000000)"></g>
                        <g id="2nd-row" transform="translate(63.000000, 129.000000)"></g>
                    </g>
                </g>
                <g id="MANU-ROW-2" transform="translate(0.000000, 35.000000)"></g>
                <g id="Down" transform="translate(483.000000, 244.000000)" stroke="#60BC59">
                    <g>
                        <circle id="Oval-2" className="down-icon-cirlce" fillRule="nonzero" cx="7.5" cy="7.5" r="7"></circle>
                        <path d="M9.89559343,10.5220684 C9.92728984,10.5220684 9.95768807,10.509477 9.98010082,10.4870643 C10.0267729,10.4403921 10.0267729,10.3647216 9.98010082,10.3180495 L4.06999145,4.40794017 C3.97666952,4.31461819 3.97666952,4.16331344 4.06999145,4.06999145 C4.16331344,3.97666952 4.31461819,3.97666952 4.40794017,4.06999145 L10.3180495,9.98010082 C10.3404622,10.0025136 10.3708605,10.0151049 10.4025569,10.0151049 C10.4685613,10.0151049 10.5220684,9.96159781 10.5220684,9.89559343 L10.5220684,5.50378632 C10.5220684,5.37180916 10.629057,5.26482051 10.7610342,5.26482051 C10.8930114,5.26482051 11,5.37180916 11,5.50378632 L11,10.760977 C11,10.8929858 10.8929858,11 10.760977,11 L5.50378632,11 C5.37180916,11 5.26482051,10.8930114 5.26482051,10.7610342 C5.26482051,10.629057 5.37180916,10.5220684 5.50378632,10.5220684 L9.89559343,10.5220684 Z" id="Shape" strokeWidth="0.5" stroke="#3CD1B5" fillRule="nonzero" transform="translate(7.500000, 7.500000) rotate(-315.000000) translate(-7.500000, -7.500000) "></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>

}

export default Icon
