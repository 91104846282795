import                           "./index.css";
import React, { Component } from "react";
import Link                 from "../link";
import InlineIcon           from "../icon/inline-icon.js";
import Utils                from "../../../modules/utils";

class Integrations extends Component
{
    constructor(props, context)
    {
        super(props, context)

        this.state            = {open: false};
        this.onTriggerClicked = this.onTriggerClicked.bind(this);
        this.onOptionClick    = this.onOptionClick.bind(this);
        this.close            = this.close.bind(this);
    }

    onTriggerClicked()
    {
        if(this.state.open) return this.close();
        document.body.addEventListener("click", this.close);
        this.setState({open: true});
    }

    close(event)
    {
        if(event)
        {
            let ref  = this.refs.triggerNode;
            let targ = event.target;
            if(ref === targ || Utils.isDescendant(ref, targ)) return;
        }

        document.body.removeEventListener("click", this.close);
        this.setState({open: false});
    }

    onOptionClick(event, option)
    {
        event.preventDefault();

        if(option.onClick)
        {
            return option.onClick(event, option);
        }
        if (option.target_blank)
            window.open(option.to);
    }

    render()
    {
        let options = []

        this.props.options.forEach((option, index) =>
        {
            let block =
                option.isClickAble ?
                    <Link className={`${option.last ? "open-link-holder last" : ""} ${option.disabled ? "disabled" : ""}`} key={index} to={option.to ? option.to : ""} onClick={(e) => this.onOptionClick(e, option)}>
                        {
                            option.last ?
                                <div className={'option ' + (option.labelWapperClass || '')}>
                                    <div className={option.disabled ? "muted-text" : "label"}>{option.label}</div>
                                    {
                                        option.icon &&
                                        <InlineIcon
                                          className={`${option.iconClass ? option.iconClass : ""} ${option.disabled ? "disabled" : ""}`}
                                        >
                                          {option.icon}
                                        </InlineIcon>
                                    }
                                </div>
                            :
                                <div className={'option ' + (option.labelWapperClass || '')}>
                                {
                                    option.icon &&
                                    <InlineIcon
                                        className={`${option.iconClass ? option.iconClass : ""} ${option.disabled ? "disabled" : ""}`}
                                    >
                                      {option.icon}
                                    </InlineIcon>
                                }
                                    <div className={option.disabled ? "muted-text" : "label"}>{option.label}</div>
                                </div>
                        }
                    </Link>
                :
                    option.last ?
                        <div className={'option ' + (option.labelWapperClass || '')}>
                            {option.label}
                            <Link className={`${option.last ? "open-link-holder last" : ""}`} key={index} to={option.to ? option.to : ""} onClick={(e) => this.onOptionClick(e, option)}>
                                <span className="label">{option.linkText}</span>
                                {
                                    option.icon &&
                                    <InlineIcon
                                      className={`${option.iconClass ? option.iconClass : ""} ${option.disabled ? "disabled" : ""}`}
                                    >
                                      {option.icon}
                                    </InlineIcon>
                                }
                            </Link>
                        </div>
                    :
                        <div className={'option ' + (option.labelWapperClass || '')}>
                                {
                                    option.icon &&
                                    <InlineIcon className={`${option.iconClass ? option.iconClass : ""} ${option.disabled ? "disabled" : ""}`}>
                                      {option.icon}
                                    </InlineIcon>
                                }
                                <div className={option.disabled ? "muted-text" : "label"}>{option.label}</div>
                        </div>
            options.push(block)
        })

        let trigger = this.props.children;
        let clazz = "integration "
            + this.props.className
            + (this.state.open ? " open" : "")

        let markup =
            <div className={clazz}>
                <div className="custom_trigger" ref="triggerNode" onClick={this.onTriggerClicked}>
                    <a>
                        {trigger}
                    </a>
                </div>
                <div className="sub-integration">
                    {options}
                </div>
            </div>

        return markup;
    }
}

Integrations.defaultProps =
{
    options   : [],
    className : ""
}
export default Integrations
