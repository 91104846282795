import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="19"
      viewBox="0 0 14 19"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      >
        <g stroke="#000" transform="translate(-90 -86)">
          <g transform="translate(90 87)">
            <path d="M.5 10.945s.812-.812 3.25-.812c2.437 0 4.062 1.624 6.5 1.624 2.438 0 3.25-.812 3.25-.812v-9.75s-.812.812-3.25.812c-2.438 0-4.063-1.625-6.5-1.625-2.438 0-3.25.813-3.25.813v9.75z"></path>
            <path d="M.5 16.633v-5.687"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
