import React, { Component }      from "react"

const SvgIcon = () =>
{
    return <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
        <path d="M15,0 C6.728,0 0,6.728 0,15 C0,23.272 6.728,30 15,30 C23.272,30 30,23.272 30,15 C30,6.728 23.272,0 15,0 Z M25.0314335,26.1617991 C24.1149161,24.5610402 21.2810078,20.7692308 15,20.7692308 C8.71899221,20.7692308 5.88508387,24.5610402 4.9685665,26.1617991 C1.91647889,23.4179688 0,19.437079 0,15 C0,6.70230769 6.70230769,0 15,0 C23.2976923,0 30,6.70230769 30,15 C30,19.437079 28.0835211,23.4179688 25.0314335,26.1617991 Z M10.3846154,11.5384615 C10.3846154,14.8076923 12.6346154,17.3076923 15.5769231,17.3076923 C18.5192308,17.3076923 20.7692308,14.8076923 20.7692308,11.5384615 C20.7692308,8.26923077 18.5192308,5.76923077 15.5769231,5.76923077 C12.6346154,5.76923077 10.3846154,8.26923077 10.3846154,11.5384615 Z" id="path-1-0009"></path>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="NEW-USER-MENU-WITH-DEFAULT-AVATAR" transform="translate(-1325.000000, -17.000000)" stroke="#FFFFFF">
            <g id="ACCOUNT" transform="translate(1326.000000, 18.000000)">
                <g id="Combined-Shape">
                    <g strokeWidth="1.5">
                        <use xlinkHref="#path-1-0009"></use>
                        <use xlinkHref="#path-1-0009"></use>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
}

export default SvgIcon
