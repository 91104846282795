export function getInitialState()
{
    return {
        product: null,
        isFavorite: false,
        documents_loaded:false,
    }
}

let initialState = getInitialState()
export default initialState
