import * as React from "react"

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 35 35" width="1em" height="1em" {...props}>
      <title>{"datasheet"}</title>
      <g data-name="Layer 2">
        <path fill="none" d="M0 0h35v35H0z" />
        <path
          d="M31 7.93a.8.8 0 00-.17-.26L23.38.24a.81.81 0 00-.56-.24H4.75A.8.8 0 004 .8v33.4a.8.8 0 00.8.8h25.45a.8.8 0 00.8-.8v-26a.8.8 0 00-.05-.27zm-7.38-5.19l4.69 4.69h-4.69zm5.83 30.66H5.55V1.6H22v6.63a.8.8 0 00.8.8h6.63z"
          fill="#fffff"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
