import { gql, useMutation } from "@apollo/client";
import { Lot } from "build/models";
import { BaseLotFragment } from "graphql/fragment/lotFragment";
import { useCallback } from "react";

const DELETE_LOTS_MUTATION = gql`
mutation deleteLots($ids: [ID!]!) {
  build {
    deleteLotsByIds(ids: $ids) {
      ...baseLotFragment
    }
  }
}
${BaseLotFragment}
`;

export interface DeleteLotResp {
  build?: {
    deleteLotsByIds?: Lot[]
  }
}

export function useDeleteLots() {
  const [deleteLots] = useMutation<DeleteLotResp>(DELETE_LOTS_MUTATION);

  return useCallback(async (ids: string[]) => {
    const res = await deleteLots({ variables: { ids } });
    return res.data?.build?.deleteLotsByIds;
  }, [deleteLots]);
}
