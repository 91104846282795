import React        from "react";
import Icon         from "../../../ui/icon";

const StatusField = (props) =>
{
    let {status} = props;

    status = status ? status.toUpperCase() : status;
    return( <span className='status-value'>
                <span className={`status-label ${status}`}>
                    {status}
                </span>
            </span>
    )
}

export default StatusField;
