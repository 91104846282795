import Utils                from "../../utils"
import fetchIntercept       from 'fetch-intercept';

const AuthAPI =
{
    // This remains to clear cookies from previously logged in users.
    signOut(cb)
    {
        Utils.post("/auth/signout", null, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null)
        })
    },
}

export default AuthAPI
