import React from 'react'

const UploadIcon = () => {
  return (
    <svg width="25px" height="19px">
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Upload" transform="translate(1.000000, 1.000000)" stroke="#888888" strokeWidth="1.25">
          <g>
            <polyline id="Stroke-20" points="14.5 10 11.5000956 7 8.5 10"></polyline>
            <line x1="11.5" y1="7.12766166" x2="11.5" y2="17.2171727" id="Stroke-21"></line>
            <path d="M18.0526995,4.0659641 C17.7871944,4.0659641 17.5268198,4.08748785 17.2726751,4.12795984 C16.5076759,1.73367323 14.2729619,0 11.6340352,0 C8.69772074,0 6.26199979,2.14630365 5.79493798,4.96131508 C5.40868207,4.85535203 5.00245373,4.79758745 4.58284936,4.79758745 C2.05184707,4.79758745 0,6.85761215 0,9.39870174 C0,11.8167197 1.85798619,13.79764 4.21858146,13.9841791 L4.21858146,14 L18.0526995,14 C20.7850752,14 23,11.7762477 23,9.03298205 C23,6.28971643 20.7850752,4.0659641 18.0526995,4.0659641 Z" id="Stroke-22"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default UploadIcon
