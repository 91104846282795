import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 35 35">
      <g data-name="Layer 2">
        <g>
          <path
            d="M30 11.85L18.45.32a1.08 1.08 0 00-1.53 0L5.39 11.85a1.08 1.08 0 001.53 1.53l9.68-9.69V23.2a1.08 1.08 0 102.16 0V3.69l9.68 9.68A1.08 1.08 0 1030 11.85z"
            className="cls-10000"
          ></path>
          <path
            d="M32 35H3a1.08 1.08 0 01-1.08-1.08V22.35a1.08 1.08 0 012.16 0v10.49H31V22.35a1.08 1.08 0 012.16 0v11.57A1.08 1.08 0 0132 35z"
            className="cls-10000"
          ></path>
          <path d="M0 0H35V35H0z" className="cls-2"></path>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
