import                                   "./index.css"
import React, { Component }       from "react"
import {connect}                  from 'react-redux';
import Query                      from "../../../modules/query"
import Utils                      from "../../../modules/utils"
import Link                       from "../../ui/link"
import Loading                    from "../../ui/inner-loading"
import { dashboardAddProductImg } from "../../../assets/index.js"
import { privatePaths }           from "../../../app/routes"
import buildAction                from "../../../helpers/buildAction"
import SEARCH                     from "../../../action-types/search"
import ITEM_ASSEMBLY from "../../../action-types/item-assembly"
import PRODUCT                    from "../../../action-types/product"
import ON_BOARDING                from "../../../action-types/on-boarding"
import CO                         from "../../../action-types/changeorder"
import PlusIcon                   from "../../../assets/icons/plus"
import InlineIcon                 from "../../ui/icon/inline-icon.js"
import GearIcon from "../../../assets/icons/gear"
import AddComponentIcon           from "../../../assets/icons/add-components"
import AddCoIcon                  from "../../../assets/icons/copy-icon"
import ExportIcon                 from "../../../assets/icons/export-with-colors"
import LibraryModal               from "../signup/library-modal"
import CustomBlueToolTip          from "../../ui/custom-blue-tooltips-for-on-boarding"
import Permissions                from "../../../modules/schemas/permissions"
import VariantModal               from "../common/view-action-items/variant-modal"
import {Helmet}                   from "react-helmet"
import Config                     from "../../../modules/config";
import Image from "../../ui/image"
import RevisionField              from "../common/revision-field";
import CpnField                   from "../common/cpn-field";
import StatusField                from "../common/status-field";
import LastUpdatedField           from "../common/last-modified-field";
import VariantIconHolder          from "../common/variants/variant-icon-holder";
import AddUserModal from "../signup/add-user-modal";

export class Dashboard extends Component
{
    constructor(props, context)
    {
        super(props, context)
        this.setInitialState                    = this.setInitialState.bind(this)
        this.updateSearchResults                = this.updateSearchResults.bind(this)
        this.getSampleProduct                   = this.getSampleProduct.bind(this)
        this.setSampleProductFlagForDashBoard   = this.setSampleProductFlagForDashBoard.bind(this)
        this.openProductAssemblyTree            = this.openProductAssemblyTree.bind(this)
        this.onModalExternalClick               = this.onModalExternalClick.bind(this)
        this.createCoWithDefaultData            = this.createCoWithDefaultData.bind(this);
        this.closeVariantModal                  = this.closeVariantModal.bind(this);
        this.createVariant                      = this.createVariant.bind(this);
        this.filterVariantsAndGetCount = this.filterVariantsAndGetCount.bind(this);
        this.updateProductsData                 = this.updateProductsData.bind(this);
        this.setProductInVariantModal           = this.setProductInVariantModal.bind(this);

        this.state =
        {
            openVariantModal: false,
            product: null,
            OpenProductForcefully: null
        }
        this.setInitialState()
    }

    createVariant(productId)
    {
        const {dispatch} = this.props;
        let payload = {productId, history: this.props.history}
        dispatch(buildAction(PRODUCT.CREATE_VARIANT, payload))
    }

    closeVariantModal(e, product)
    {
        let state = this.state
        state.openVariantModal = false
        state.product = null
        this.setState(state)
    }

    createCoWithDefaultData()
    {
        const {dispatch} = this.props;
        let payload = {history: this.props.history, authorId: this.props.user.data._id}
        dispatch(buildAction(CO.CREATE_CO_WITH_DEFAULT_DATA, payload))
    }

    setSampleProductFlagForDashBoard()
    {
        const {dispatch} = this.props;
        document.body.addEventListener("click", this.onModalExternalClick)
        dispatch(buildAction(ON_BOARDING.SET_DISPLAY_SAMPLE_PRODUCT_MODAL_ON_DASHBOARD_FLAG, false));
        dispatch(buildAction(ON_BOARDING.SET_TUTORIAL_SKIPED_FLAG, true));
    }

    setInitialState()
    {
        let { search } = this.props
        let query  = {type: "prd", populate: 'images'}
        let string = Query.stringify(query)
        const {dispatch} = this.props;
        const payload = {
            value: "type:prd",
            query: {
                string: string,
                object: query
            }
        }

        dispatch(buildAction(SEARCH.SET_INITIAL_STATE, payload));
    }

    componentWillMount()
    {
        this.updateSearchResults()
    }

    componentWillUnmount()
    {
        const {dispatch} = this.props;
        dispatch(buildAction(SEARCH.RESET_STATE));
    }

    updateSearchResults()
    {
        const {dispatch} = this.props;
        dispatch(buildAction(SEARCH.RESET_STATE));
        this.setInitialState()
        let sort      = {}
        sort.lastModified = 1
        const page      = 1
        const payload = {
            sort: sort,
            page: page
        }
        dispatch(buildAction(SEARCH.UPDATE_SEARCH_STATE, payload));
        dispatch(buildAction(SEARCH.GET_SEARCH_DATA));
    }

    openProductAssemblyTree(event, item)
    {
        const {dispatch} = this.props;
        dispatch(buildAction(ITEM_ASSEMBLY.SET_OPEN_ITEM_LIST, true));
    }

    getSampleProduct(name)
    {
        const {dispatch} = this.props;
        let payload = {
            activeLibrary: this.props.user.data.personalLibrary,
            user: this.props.user,
            currentEnv: "SANDBOX",
            history: this.props.history,
            dummyProduct: true,
            dummyProductType: name
        }
        dispatch(buildAction(ON_BOARDING.SWITCH_ENV_AND_CREATE_SAMPLE_PRODUCT, payload));
        document.body.addEventListener("click", this.onModalExternalClick)
    }


    onModalExternalClick(event)
    {
        let loadingUI = document.getElementsByClassName("ui-loading").length
        if(!loadingUI)
        {
            const {dispatch} = this.props;
            dispatch(buildAction(ON_BOARDING.SHOW_ENV_INFO, false));
            dispatch(buildAction(ON_BOARDING.SET_TUTORIAL_SKIPED_FLAG, false));
            document.body.removeEventListener("click", this.onModalExternalClick)
        }
    }

    filterVariantsAndGetCount(results)
    {
        let variantIdz = []
        let filteredResutlsWithVariantCount = []
        results.forEach((result) => {
            if (!variantIdz.includes(result.variantGroup) || !result.variantGroup)
            {
                if (result.variantGroup)
                {
                    variantIdz.push(result.variantGroup)
                }
                filteredResutlsWithVariantCount.push(result)
            }
        })


        filteredResutlsWithVariantCount.forEach((filteredResult) =>
        {
            filteredResult.variantCount = 0
            results.forEach((result) =>
            {
                if (result.variantGroup && filteredResult.variantGroup === result.variantGroup)
                {
                    filteredResult.variantCount = filteredResult.variantCount + 1
                }
            })
        })

        return filteredResutlsWithVariantCount
    }

    setProductInVariantModal(results)
    {
        let state = this.state;
        results.forEach((result) =>
        {
            if(state.OpenProductForcefully === result._id)
            {
                state.openVariantModal = true;
                state.product = result;
                state.OpenProductForcefully = null;
                this.setState(state);
            }
        });
    }

    updateProductsData(productId)
    {
        this.updateSearchResults();
        let state = this.state;
        state.OpenProductForcefully = productId;
        this.setState(state);
    }

    render()
    {

        const user      = this.props.user.data
        let { search, companySettings, companyCreator } = this.props
        let { results } = search
        let { loading } = search
        let path = window.__userRole === "VENDOR" ? 'revision' : 'view'

        if(loading || this.props.on_boarding.innerLoading || search.value !== "type:prd")
        {
            return <Loading/>
        }

        if ((this.props.on_boarding.displaySampleProductModalOnDashBoard) && Permissions.can("view", "sandbox", user) && Permissions.can("edit", "add_users", user))
        {
            if (user._id === companyCreator) {
                return (
                    <AddUserModal
                        skipTutorial={this.setSampleProductFlagForDashBoard}
                        getSampleProduct={this.getSampleProduct}
                        companySettings={companySettings}
                    />
                );
            } else {
                return (
                    <LibraryModal
                        skipTutorial={this.setSampleProductFlagForDashBoard}
                        getSampleProduct={this.getSampleProduct}
                        showBackButton={false}
                    />
                );
            }
        }

        if (results.length > 0)
        {
            if(this.state.OpenProductForcefully)
            {
                this.setProductInVariantModal(results);
            }
            results = this.filterVariantsAndGetCount(results)
        }

        let markup =

            <div className="dashboard-route">
                <Helmet>
                    <title>{Utils.makeTitle("Dashboard")}</title>
                </Helmet>
                <div className="dashboard-heading">
                    <h1>{user.firstName}'s Dashboard</h1>
                    <h3>Workflow Management For Your Next Hardware Product</h3>
                </div>

                {
                    this.state.openVariantModal && window.__userRole!=='VENDOR' &&
                    <VariantModal
                        openVariantModal={this.state.openVariantModal}
                        closeVariantModal={this.closeVariantModal}
                        objectData={this.state.product}
                        createVariant={this.createVariant}
                        updateObjectData={this.updateProductsData}
                        getDataFromApi={true}
                        location={this.props.location}
                        search={this.props.search}
                        history={this.props.history}
                        compareVariants={null}
                        fromDashboard={true}
                    />
                }

                <div className="tasks-block">
                    <ul className="create-product">

                        {
                            results.length > 0 ?
                                results.map((result, i) =>
                                {
                                    let productLink = {pathname: `/product/${path}/${result._id}`}
                                    let imgSrc = null;
                                    if(result.images && result.images.length > 0)
                                    {
                                        imgSrc = result.images[0].variants ? result.images[0].variants[Config.defaultResolutions.dashboardImage] : result.images[0].src;
                                    }
                                    let block =
                                        <li className="customer-data" key={i + "list"}>
                                            <Link
                                                key={i + 'prd'}
                                                className="task customer-data component"
                                                to={productLink}
                                                onClick={(e) => this.openProductAssemblyTree(e, result) } >

                                                <div className={(imgSrc) ? "task-image" : "default-img"}>
                                                {
                                                    imgSrc ?
                                                    <Image className="icon" src={imgSrc}></Image>
                                                    :
                                                    <InlineIcon><GearIcon /></InlineIcon>
                                                }
                                                </div>

                                                <div className="task-detail">
                                                    <h3
                                                    data-tip={result.name}
                                                    >{result.name}</h3>
                                                    <div className="content">
                                                        <span className="title">CPN:</span>
                                                        <span className="value"><CpnField item={result} cpn={result.cpn}/></span>
                                                    </div>
                                                    <div className="content">
                                                        <span className="title">Rev:</span>
                                                        <span className="value"><RevisionField vendor={window.__userRole === 'VENDOR'} item={result} revision={result.revision}/></span>
                                                    </div>
                                                    <div className="content">
                                                        <span className="title">Status:</span>
                                                        <StatusField item={result} status={result.status}/>
                                                    </div>
                                                    <div className="content">
                                                        <span className="title">Last Updated:</span>
                                                        <span className="value">
                                                            <LastUpdatedField
                                                                format="date-with-long-format" item={result}
                                                            />
                                                        </span>
                                                    </div>
                                                </div>
                                            </Link>
                                        { window.__userRole !== 'VENDOR' &&
                                            <VariantIconHolder
                                                openVariantModal={(product) => {
                                                    let state = this.state
                                                    state.openVariantModal = true;
                                                    state.product = product;
                                                    this.setState(state)
                                                }}
                                                result={result}
                                                toolTip="Product Variants"
                                                tableSelector=""
                                            >
                                                {
                                                    result.variantCount > 1 &&
                                                    <span className="variant-count">{result.variantCount}</span>
                                                }
                                            </VariantIconHolder>
                                        }
                                    </li>

                                    return block
                                }) : ""
                            }

                        {
                            Permissions.can("create", "product", this.props.user.data) ?
                            results.length > 0 ?
                            <li className="new-product">
                                <Link className="task new-product component" to={privatePaths.productNew.pathname} >
                                    <div className="new-product-holder">
                                        <InlineIcon><PlusIcon /></InlineIcon>
                                        <h3>Create New Product</h3>
                                    </div>
                                </Link>
                            </li>

                            :
                            <li className="first-product">
                                <Link className="task component" to={privatePaths.productNew.pathname} >
                                    <img className="icon" src={dashboardAddProductImg} alt="" />
                                    <h3>Create Your First Product</h3>
                                    <p className="copy">
                                        Homebase to collaborate and build your product.
                                    </p>

                                    <CustomBlueToolTip>
                                        <button>Create Product</button>
                                    </CustomBlueToolTip>

                                </Link>
                            </li>
                            :
                            results.length == 0 &&
                            <li className="first-product disabled">
                                <Link className="task component disabled" to="#" >
                                    <img className="icon" src={dashboardAddProductImg} alt="" />
                                    <h3>Your products will be here</h3>
                                    <p className="copy">
                                        Homebase to collaborate and build your product.
                                    </p>

                                </Link>
                            </li>
                        }
                    </ul>

                    {
                        (
                            Permissions.can("create", "component", this.props.user.data) ||
                            Permissions.can("create", "change_order", this.props.user.data) ||
                            Permissions.can("view", "export_cmp_prd", this.props.user.data)
                        ) &&
                        <div>
                            <h2 className="primary-box-heading">
                                Quick Access
                            </h2>
                        </div>

                    }
                    <ul className="primary-box">
                        {
                            Permissions.can("create", "component", this.props.user.data) ?
                        <li>
                            <Link
                              className="task component"
                              to={{ 
                                pathname: '/components',
                                state: { query: "type:cmp" }
                              }}
                            >
                                <InlineIcon><AddComponentIcon /></InlineIcon>
                                <h3>Add or Import Components</h3>
                            </Link>
                        </li>
                        : null
                        }
                        {
                            Permissions.can("create", "change_order", this.props.user.data) ?
                            <li onClick={this.createCoWithDefaultData}>
                                <div className="task co">
                                    <InlineIcon><AddCoIcon /></InlineIcon>
                                    <h3><div>Create</div> Change Orders</h3>
                                </div>
                            </li>
                            : null
                        }
                        {
                            Permissions.can("view", "export_cmp_prd", this.props.user.data) ?
                            <li>
                                <Link className="task co" to={privatePaths.export.pathname}>
                                    <InlineIcon><ExportIcon /></InlineIcon>
                                    <h3>Export BOM's and Design Files</h3>
                                </Link>
                            </li>
                            : null
                        }
                    </ul>
                </div>

                <div className="bottom-block">
                    <p className="copy">Read our <Link to={privatePaths.docs.quickstart} target="_blank">Quick Start Guide</Link> and <Link to={privatePaths.docs.documentation} target="_blank">Documentation</Link> to get up and running efficiently
                    </p>
                </div>
            </div>

        return markup
    }
}
// export default connect((store) => store)(Dashboard)

export default connect((store) => ({
    user: store.user,
    search: store.search,
    companySettings: store.company.data.settings,
    companyCreator: store.company.data.creator,
    on_boarding: store.on_boarding
}))(Dashboard)
