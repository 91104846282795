import "./index.css";
import React, { useState, useMemo } from "react";
import { Box, Tooltip, List, ListItem } from "@mui/material";
import ArrowRightIcon from "../../../../assets/icons/arrow-right";
import ERPDates from "./erp-dates";
import ErpCheckbox from "./erp-checkbox";
import Utils from "../../../../modules/utils";

const ErpOptions = ({ onInputChange, erpOptions }) => {

    const [erpToggle, setErpToggle] = useState(false);
    const toggleArrow = () => setErpToggle(!erpToggle);
    const erpOptionsStringified = JSON.stringify(erpOptions)
    
    const details = useMemo(() => {
        const erpOptions = JSON.parse(erpOptionsStringified)
        const isEffectivityChecked = erpOptions.effectivity.isEnabled.checked;
        const isItemTypeChecked = erpOptions.itemType.isEnabled.checked;
        const overrideEffectivity = erpOptions.effectivity.overrideExisting.checked;
        const overrideItemType = erpOptions.itemType.overrideExisting.checked;
        const itemTypeValue = erpOptions.itemType.value.value;
        const effectivityClass = isEffectivityChecked ? "enabled" : "disabled";
        const itemTypeClass = isItemTypeChecked ? "enabled" : "disabled";

        const isItemTypeEnabled = !!window.__erpItemTypeOptions.length;
        const tooltipMessage = isItemTypeEnabled ? '' : 'Contact sales@durolabs.co'; 


        const itemTypeOptions = Utils.toOptions(Utils.getItemTypeList());
        
        return (
            <List className="erp-options">
                <ListItem className="erp-effectivity">
                    <ErpCheckbox
                        value={isEffectivityChecked}
                        onInputChange={onInputChange}
                        label={{ value: "Set Effectivity" }}
                        name="isEffectivityEnabled"
                    />
                    <span className="custom-datepicker">
                        <ERPDates effectivity={erpOptions.effectivity} onInputChange={onInputChange} />
                    </span>
                    <ErpCheckbox
                        value={overrideEffectivity}
                        onInputChange={onInputChange}
                        itemStateClass={effectivityClass}
                        label={{ value: "Override existing", textClass: effectivityClass }}
                        name="isEffectivityOverrideEnabled"
                    />
                </ListItem>
                <Tooltip title={tooltipMessage} placement="right" arrow>
                    <ListItem className="erp-item-type">
                        <ErpCheckbox
                            value={isItemTypeChecked}
                            onInputChange={isItemTypeEnabled ? onInputChange : () => { }}
                            label={{ value: "Set Item Type" }}
                            name="isItemTypeEnabled"
                            customClass={`item-type ${isItemTypeEnabled ? '' : 'disabled-item-type'}`}
                        />
                        <span className={`select-class custom-datepicker`}>
                            <select
                                name="selectItemType"
                                className={itemTypeClass}
                                value={itemTypeValue}
                                onChange={onInputChange}
                            >
                                {itemTypeOptions}
                            </select>
                        </span>
                        <ErpCheckbox
                            value={overrideItemType}
                            onInputChange={onInputChange}
                            itemStateClass={itemTypeClass}
                            label={{ value: "Override existing", textClass: itemTypeClass }}
                            name="isItemTypeOverrideEnabled"
                        />
                    </ListItem>
                </Tooltip>
            </List>
        )}, [erpOptionsStringified, onInputChange])

    return (
        <Box className="erp-wrapper">
            <List className="container">
                <span
                    className={`erp-options-arrow ${erpToggle ? "open" : "close"}`}
                    onClick={toggleArrow}
                >
                    <ArrowRightIcon />
                </span>
                <span className="erp-title">ERP Options</span>
            </List>
            {erpToggle && (
                details
            )}
        </Box>
    );
}

export default ErpOptions;