import React, {Component} from "react";
import InlineIcon from "../../../../ui/icon/inline-icon.js";
import Link from "../../../../ui/link";
import Permissions from "../../../../../modules/schemas/permissions";
import EditIcon from "../../../../../assets/icons/edit";
import EllipsisTooltip from "../../../common/ellipsis-tooltip/index.js";

class Actions extends Component
{
    constructor(props, context)
    {
        super(props, context);
    }

    render()
    {
        let { productionInstance, user } = this.props;

        let markup =
            <div className="actions-block">
                <div className="nav-block app-row">
                    <EllipsisTooltip
                        classes={"ellipsis-tooltip tooltip-no-width"}
                        title={productionInstance.name}
                        innerClasses={"truncate-name"}
                    >
                        {productionInstance.name}
                    </EllipsisTooltip>
                    <div className="cta-icon-toolbar">
                        {
                            Permissions.can("edit", "production_instance", user.data) &&
                            <nav className="navigate">
                                <Link to={`/production/instance/edit/${productionInstance._id}`}>
                                    <InlineIcon tooltip="Edit production instance information">
                                        <EditIcon />
                                    </InlineIcon>
                                </Link>
                            </nav>
                        }
                    </div>
                </div>
            </div>

        return markup;
    }
}

export default Actions
