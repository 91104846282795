import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="13"
      viewBox="0 0 22 13"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#888" className="white-fill" fillRule="nonzero" transform="translate(-635 -500)">
          <g transform="translate(369 497)">
            <g transform="translate(266 3)">
              <path d="M10.685 3.62c1.27 0 2.304 1.04 2.304 2.316a2.313 2.313 0 01-2.304 2.317 2.312 2.312 0 01-2.303-2.317 2.312 2.312 0 012.303-2.316zm0-1.245a3.566 3.566 0 00-3.561 3.561 3.566 3.566 0 003.561 3.562 3.566 3.566 0 003.563-3.562 3.566 3.566 0 00-3.563-3.561z"></path>
              <path d="M10.693 1.232c.604 0 1.212.064 1.804.188 1.762.37 3.588 1.295 5.426 2.744.9.708 1.566 1.329 2.097 1.954l.04.043c.011.01.02.028.024.047l-.006.072a.085.085 0 01-.018.028l-.04.042c-.198.234-.424.459-.635.668-1.306 1.282-2.69 2.298-4.116 3.02-1.071.54-2.055.885-3.009 1.055a9.166 9.166 0 01-1.592.141c-.61 0-1.214-.063-1.81-.188-1.762-.37-3.588-1.294-5.425-2.743-.898-.706-1.564-1.326-2.098-1.953-.077-.09-.077-.142.001-.233a9.76 9.76 0 01.636-.668c1.302-1.28 2.687-2.296 4.116-3.02 1.072-.54 2.055-.886 3.008-1.053a9.173 9.173 0 011.597-.144zm0-1.232c-.6 0-1.215.055-1.827.163-1.077.19-2.18.574-3.37 1.174C3.95 2.12 2.46 3.213 1.063 4.585c-.273.267-.5.498-.715.752-.463.542-.464 1.25.003 1.797.59.692 1.313 1.367 2.277 2.125 1.994 1.572 4 2.58 5.96 2.991.68.144 1.38.217 2.08.217.604 0 1.217-.054 1.822-.161 1.082-.193 2.184-.577 3.37-1.175 1.545-.783 3.036-1.875 4.435-3.249.241-.237.473-.47.69-.724l.013-.013c.116-.12.207-.26.27-.416l.068-.169.015-.18.006-.072.015-.184-.04-.18a1.317 1.317 0 00-.333-.619l-.014-.015c-.582-.68-1.299-1.349-2.255-2.102-1.995-1.573-4-2.58-5.96-2.992A10.127 10.127 0 0010.693 0z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
