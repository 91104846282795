import                              "./index.css"
import React                        from "react"
import WebhookEventList             from "../modules/webhookEvents-table"
import Details                      from "./modules/details"
import statusNavArrow               from "../../../../assets/icons/co-status-nav-arrow.svg"
import Icon                         from "../../../ui/icon"
import logSrc                       from "../../../../assets/icons/cat-document.svg";
import InlineIcon                   from "../../../ui/icon/inline-icon.js"
import Utils                        from "../../../../modules/utils"
import {Helmet}                     from "react-helmet"
import API                          from "../../../../modules/api";
import Loading                      from "../../../ui/inner-loading"
import EditIcon                     from "../../../../assets/icons/edit"
import DeleteIcon                   from "../../../../assets/icons/delete"
import ImportIcon                   from "../../../../assets/icons/import-icon"
import Link                         from "../../../ui/link";
import WebhookModalBox              from "../../settings/webhooks/webhook-modal-box"


export class ViewWebhook extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            showLoading: true,
            webhook: null,
            editWebhook: false,
        }
        this.getWebhookFromApi = this.getWebhookFromApi.bind(this);
        this.onDelete          = this.onDelete.bind(this);
        this.showWebhookModal  = this.showWebhookModal.bind(this)
        this.closeWebhookModal = this.closeWebhookModal.bind(this)
        this.updateWebhook     = this.updateWebhook.bind(this);
    }

    getWebhookFromApi()
    {
        let state = this.state;
        const id  = this.props.match.params.id;
        API.webhooks.findById(id , (err, data) =>
        {
            if(data)
            {
                state.webhook    = data;
                state.showLoading = false;
                this.setState(state);
            }
            else
            {
                this.props.history.push({pathname: '/settings/webhooks'})
            }
        })
    }

    componentWillMount()
    {
        let state = this.state;
        if(!state.webhook)
        {
            state.showLoading = true;
            this.setState(state);
            this.getWebhookFromApi();
        }
    }

    showWebhookModal()
    {
        this.state.editWebhook = true
        this.setState(this.state)
    }

    closeWebhookModal()
    {
        this.state.editWebhook = false
        this.setState(this.state)
    }

    onDelete()
    {
        let state = this.state;
        let webhook = this.state.webhook
        API.webhooks.delete(webhook._id , (err, data) =>
        {
            if(data)
            {
               this.props.history.push({pathname: '/settings/webhooks'})
            }
        })
    }

    updateWebhook(webhook)
    {
        this.state
        this.setState({webhook});
        this.state
    }

    render()
    {
        if (this.state.showLoading)
        {
            return <Loading />
        }
        let webhook = this.state.webhook
        let status = webhook.status;
        let view = "webhookEvents"

        let markup =
            <div className="view-webhook-route view-webhook-route-block">
                <div className="content-inner-block">
                    <Helmet>
                        <title>{Utils.makeTitle("Webhook")}</title>
                    </Helmet>
                    <div className="header-section">
                        <div className="status-nav">
                            {
                                status === 'Enabled' ?
                                <span className={"status-label " + (status === "Enabled" ? "ENABLED": "")}>Enabled</span> :
                                <span className={"status-label " + (status === "Disabled" ? "DISABLED" : "")}>Disabled</span>
                            }
                        </div>
                        <div className="action-btn-holder">
                            <button
                                onClick={this.showWebhookModal}
                                className="action-btn edit-btn">
                                <InlineIcon><EditIcon/></InlineIcon>EDIT</button>
                            <button
                                onClick={this.onDelete}
                                className="action-btn delete-btn">
                                <InlineIcon><DeleteIcon/></InlineIcon>DELETE</button>
                            <Link to={`/settings/webhooks`}>
                                <button className="action-btn library-btn"> <InlineIcon><ImportIcon/></InlineIcon> WEBHOOK LIBRARY</button>
                            </Link>
                        </div>
                    </div>
                    <div className="align-block">
                        {<Details webhook={webhook}/>}
                    </div>
                </div>
                <div className="tabs-block">
                    <div className="nav-block display-flex-sb">
                        <div
                            className={view === "webhookEvents" ? "selected" : ""}>
                            <div className="log-holder">
                                <Icon
                                    src={logSrc}
                                    className={view === "webhookEvents" ? "" :"deactive"}
                                />
                                <h2>Webhook Event Logs</h2>
                            </div>
                        </div>
                    </div>
                    <div className="view-block">
                        {
                            view === "webhookEvents" &&
                            <div>
                                <WebhookEventList
                                    webhookId={webhook._id}
                                    />
                            </div>
                        }
                    </div>
                </div>
                {this.state.editWebhook ?
                    <WebhookModalBox
                        data={webhook}
                        mode={"edit"}
                        updateWebhook={this.updateWebhook}
                        onClose={this.closeWebhookModal}
                    /> : null
                }
            </div>
        return markup
    }
}

export default ViewWebhook
