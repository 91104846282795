import { SvgIcon } from "@mui/material";

export const RefreshIcon = () => (
  <SvgIcon width="24px" height="24px" viewBox="0 0 24 24">
    <path d="M10.543 22a9.463 9.463 0 0 1-3.38-1.08 9.674 9.674 0 0 1-2.713-2.174
      10.031 10.031 0 0 1-1.797-3.04C2.218 14.559 2 13.323 2 12c0-1.474.348-2.881
      1.043-4.221A12.316 12.316 0 0 1 5.844 4.21H2.78V2.704h5.754v5.753H7.025v-3.24a9.844
      9.844 0 0 0-2.563 3.052A7.754 7.754 0 0 0 3.508 12c0 2.211.67 4.1 2.01 5.666 1.34 1.566
      3.015 2.5 5.025 2.801V22zm4.522-.704v-5.753h1.508v3.24a10.186 10.186 0 0 0 2.563-3.064A7.682
      7.682 0 0 0 20.09 12c0-2.211-.67-4.1-2.01-5.666-1.34-1.566-3.015-2.5-5.025-2.801V2c2.446.302
      4.481 1.382 6.106 3.241 1.625 1.86 2.437 4.112 2.437 6.759a9.046 9.046 0 0 1-1.043 4.221 12.316
      12.316 0 0 1-2.801 3.568h3.065v1.507h-5.754z"
    />
  </SvgIcon>
);
