import React, { Component }      from "react"

const SvgIcon = () =>
{
    return <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-2-Status-Revision-Rules" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="3-Design" transform="translate(-488.000000, -157.000000)">
            <rect fill="#2A2A36" x="0" y="0" width="1440" height="1000"></rect>
            <g id="Modal" transform="translate(0.000000, -7.000000)" fill="#FFFFFF">
                <g id="Help" transform="translate(488.000000, 164.000000)">
                    <path d="M7,0 C3.13973333,0 0,3.13973333 0,7 C0,10.8602667 3.13973333,14 7,14 C10.8602667,14 14,10.8602667 14,7 C14,3.13973333 10.8602667,0 7,0 Z M7,0.933333333 C10.3559556,0.933333333 13.0666667,3.64404444 13.0666667,7 C13.0666667,10.3559556 10.3559556,13.0666667 7,13.0666667 C3.64404444,13.0666667 0.933333333,10.3559556 0.933333333,7 C0.933333333,3.64404444 3.64404444,0.933333333 7,0.933333333 Z M7,3.11111111 C5.71666667,3.11111111 4.66666667,4.16111111 4.66666667,5.44444444 C4.66484356,5.56961844 4.71345467,5.68992667 4.80095467,5.77864 C4.88845467,5.86796311 5.00815467,5.91778911 5.13333022,5.91778911 C5.25850578,5.91778911 5.37820578,5.86796311 5.46570578,5.77864 C5.55320578,5.68992511 5.60181689,5.56960444 5.59999378,5.44444444 C5.59999378,4.66545333 6.22100267,4.04444444 6.99999378,4.04444444 C7.77898489,4.04444444 8.39999378,4.66545333 8.39999378,5.44444444 C8.40546311,6.08368444 8.07612711,6.41058444 7.61736267,6.84444444 C7.38766933,7.06136667 7.13307156,7.28742 6.91736267,7.58333333 C6.70165378,7.87924667 6.53332711,8.26449556 6.53332711,8.71111111 C6.531504,8.83628511 6.58011511,8.95659333 6.66761511,9.04530667 C6.75511511,9.13462978 6.87481511,9.18445578 6.99999067,9.18445578 C7.12516622,9.18445578 7.24486622,9.13462978 7.33236622,9.04530667 C7.41986622,8.95659178 7.46847733,8.83627111 7.46665422,8.71111111 C7.46665422,8.48020444 7.53410156,8.32040222 7.67082089,8.13263111 C7.80754022,7.94486 8.02021422,7.74616889 8.25900756,7.52013111 C8.73600311,7.06926889 9.33999422,6.43853778 9.33330533,5.44439778 L9.33330533,5.43953667 C9.330874,4.15863 8.28148533,3.11102556 6.999972,3.11102556 L7,3.11111111 Z M7,9.64444444 C6.65608222,9.64444444 6.37777778,9.92274889 6.37777778,10.2666667 C6.37777778,10.6105844 6.65608222,10.8888889 7,10.8888889 C7.34391778,10.8888889 7.62222222,10.6105844 7.62222222,10.2666667 C7.62222222,9.92274889 7.34391778,9.64444444 7,9.64444444 Z" id="Fill-1"></path>
                </g>
            </g>
        </g>
    </g>
</svg>

}

export default SvgIcon
