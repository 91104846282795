import React, { Component }      from "react"

const SvgIcon = () =>
{
    return <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="REFRESH-11" transform="translate(-208.000000, -108.000000)">
                <path d="M-69.4986288,-663 L1931,-663 L1931,638 L-69.4986288,638 C-73.1662096,528.137778 -75,378.522778 -75,189.155 C-75,-0.212777778 -73.1662096,-284.264444 -69.4986288,-663 Z" id="BKGD" fill="#2A2A36"></path>
                <polygon id="Frame" fill="#353540" points="2 648 1931 648 1931 1 2 1"></polygon>
                <g id="TABLE-ICONS" transform="translate(50.000000, 99.000000)" fillRule="nonzero">
                    <path d="M0.5,34.5 L431.5,34.5 L431.5,8 C431.5,3.85786438 428.142136,0.5 424,0.5 L8,0.5 C3.85786438,0.5 0.5,3.85786438 0.5,8 L0.5,34.5 Z" id="Rectangle-4" stroke="#888888" fillOpacity="0.15" fill="#888888" opacity="0.9953125"></path>
                    <g id="refresh-24px" transform="translate(159.000000, 10.000000)" fill="#FFFFFF" stroke="#41414A" strokeWidth="0.75">
                        <path d="M13.6585303,2.35 C12.2076235,0.9 10.216379,0 8.00499687,0 C3.58223265,0 0.01,3.58 0.01,8 C0.01,12.42 3.58223265,16 8.00499687,16 C11.7373296,16 14.8492745,13.45 15.7398311,10 L13.6585303,10 C12.8380175,12.33 10.6166291,14 8.00499687,14 C4.69292683,14 2.00124453,11.31 2.00124453,8 C2.00124453,4.69 4.69292683,2 8.00499687,2 C9.66603502,2 11.1469606,2.69 12.227636,3.78 L9.00562226,7 L16.01,7 L16.01,0 L13.6585303,2.35 Z" id="Shape"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>


}

export default SvgIcon




