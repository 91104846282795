import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width="29px" height="29px" viewBox="0 0 29 29" {...props}>
      <defs>
        <style />
      </defs>
      <g
        id="REVISED-CHANGE-ORDERS-NEW-WORKFLOW"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      >
        <g
          id="1_CHANGE-ORDER-EDIT"
          transform="translate(-313 -216)"
          fillRule="nonzero"
          stroke="#FFF"
          strokeWidth={0.25}
        >
          <g id="TOP-CONTENT" transform="translate(313 142)">
            <g id="ECO" transform="translate(1 75)">
              <path
                d="M22.037 16.16l4.908 8.592a.41.41 0 01-.355.612h-9.818a.41.41 0 01-.355-.612l4.91-8.591a.41.41 0 01.71 0zm-4.56 8.385h8.408l-4.204-7.357-4.204 7.357zM8.591 0h8.181a.41.41 0 01.41.41v8.18a.41.41 0 01-.41.41H8.591a.41.41 0 01-.41-.41V.41a.41.41 0 01.41-.41zM9 .818v7.364h7.363V.818H9zm15.136 11.285l1.347-1.347a.41.41 0 11.578.579l-2.045 2.045a.41.41 0 01-.579 0l-2.045-2.045a.41.41 0 01.579-.579l1.347 1.347V4.91h-3.273a.41.41 0 010-.818h3.682a.41.41 0 01.409.409v7.603zm-12.512 6.715l1.347 1.347a.41.41 0 01-.579.579l-2.045-2.046a.41.41 0 010-.578l2.045-2.046a.41.41 0 11.579.579L11.624 18h5.148a.41.41 0 010 .818h-5.148zM4.704 27a4.704 4.704 0 110-9.409 4.704 4.704 0 010 9.409zm0-.818a3.886 3.886 0 100-7.773 3.886 3.886 0 000 7.773zM4.33 4.909L2.983 3.562a.41.41 0 01.579-.579L5.607 5.03a.41.41 0 010 .578L3.562 7.653a.41.41 0 11-.579-.579L4.33 5.727H.818v8.591a.41.41 0 11-.818 0v-9a.41.41 0 01.41-.409h3.92z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
