import React, {Component}           from "react"
import { connect }                  from "react-redux"
import { DURO_QUERY_PARAM_KEYS }    from "design/constants";
import Query                        from "../../../../modules/query"
import buildAction                  from "../../../../helpers/buildAction"
import SEARCH                       from "../../../../action-types/search"
import InlineIcon                   from "../../../ui/icon/inline-icon.js"
import SearchIcon                   from "../../../../assets/icons/search.js"
import Utils                        from "../../../../modules/utils"

export class Search extends Component
{
    constructor(props, context)
    {
        super(props, context)
        this.setInitialState        = this.setInitialState.bind(this)
        this.sort                   = this.sort.bind(this)
        this.fetch                  = this.fetch.bind(this)
        this.onTypeChange           = this.onTypeChange.bind(this)
        this.onFieldChange          = this.onFieldChange.bind(this)
        this.setQuery               = this.setQuery.bind(this)
        this.onSearchInputFocus     = this.onSearchInputFocus.bind(this)
        this.onSearchInputChange    = this.onSearchInputChange.bind(this)
        this.onSearchInputBlur      = this.onSearchInputBlur.bind(this)
        this.gotoSearch             = this.gotoSearch.bind(this)
        this.allowedRoutes          = ["/search", "/components", "/products"];

        this.setInitialState()
        // this.componentWillMount = this.fetch
    }

    setInitialState(queryVal = null)
    {
        let { search, location } = this.props
        let value = undefined

        if (queryVal === null) {
            if (search.value) value = search.value;
            else if (location.state) value = location.state.query;
            else if (location.pathname === "/components") value = "type:cmp";
            else if (location.pathname === "/products") value = "type:prd";
            else "type:all";
        }
        else
            value = queryVal

        let query  = Query.parse(value)
        let string = Query.stringify(query)

        const {dispatch} = this.props;
        const payload = {
            page: 1,
            value: value,
            query: {
                string: string,
                object: query
            }
        }

        if(queryVal && location.pathname === "/search"){
            search.value = value
            this.setQuery(string, true);
        }
        dispatch(buildAction(SEARCH.SET_INITIAL_STATE, payload));
        dispatch(buildAction(SEARCH.SET_INPUT_FOCUSED, {focused: false}));
        if (queryVal !== null) this.fetch();
    }

    componentDidMount() {
        const { location } = this.props;
        let duroSearch = this.getQueryStringFromURL(DURO_QUERY_PARAM_KEYS.DURO_SEARCH, location.search);
        if(duroSearch) {
            this.setInitialState(duroSearch);
        }
    }

    componentDidUpdate()
    {
        const { location, search } = this.props;
        const queryVal = this.getQueryStringFromURL(DURO_QUERY_PARAM_KEYS.DURO_SEARCH, location.search);
        if (
          queryVal &&
          this.allowedRoutes.includes(location.pathname) &&
          queryVal !== search.value
        )
          this.setInitialState(queryVal);
        
    }

    gotoSearch(){
        this.setInitialState("")
    }

    sort(key, ascending)
    {
        let sort      = {}
        sort[key] = ascending ? 1 : -1
        const page      = 1
        const payload = {
            sort: sort,
            page: page
        }

        const {dispatch} = this.props;
        dispatch(buildAction(SEARCH.UPDATE_SEARCH_STATE, payload));
        this.fetch();
    }

    fetch(recordSearch=null)
    {
        let payload = {recordSearch}
        const {dispatch} = this.props;
        if (!["/components", "/products"].includes(this.props.location.pathname))
            dispatch(buildAction(SEARCH.GET_SEARCH_DATA, payload));
    }

    onSearchInputChange(event)
    {
        const { location } = this.props;
        let { search } = this.props
        let value        = event.target.value
        let query        = Query.parse(value, search.query.object.type)
        if(!query.type) query.type = search.query.object.type || "all"
        let string       = Query.stringify(query)
        search.value = value;
        const params = (new URLSearchParams(location.search));
        if (value) {
            params.set(DURO_QUERY_PARAM_KEYS.DURO_SEARCH, value);
        }
        else {
            params.delete(DURO_QUERY_PARAM_KEYS.DURO_SEARCH);
        }
        this.props.history.push(Utils.selectSearchRoute(search.value) + "?" + params.toString());
        this.setQuery(string, true)
    }

    onTypeChange(value)
    {
        let { search } = this.props
        search.query.object.type = value
        search.sort              = { id : 1 }
        search.page              = 1
        this.setQuery()
    }

    onFieldChange(value)
    {
        let { search } = this.props
        search.page = 1
        search.value = ""
        this.setQuery(value)
    }

    getQueryStringFromURL(key, url) {
        const decodedUrl = decodeURI(url);
        return (new URLSearchParams(decodedUrl)).get(key) ?? "";
    }

    setQuery(value, input_changed = false)
    {
        let { search } = this.props
        let originalValue = search.value
        let query  = typeof value === "string" ? Query.parse(value) : search.query.object
        search.query.object = query
        search.query.string = Query.stringify(search.query.object)
        const payload = {
            page: input_changed ? 1 : search.page ,
            query: {
                object: query,
                string: Query.stringify(search.query.object)
            }
        }
        const {dispatch} = this.props;
        dispatch(buildAction(SEARCH.UPDATE_SEARCH_STATE, payload));
        let recordSearch = {
            searchSource: "Search route",
            searchString: originalValue
        }
        if (input_changed)
        {
            this.fetch(recordSearch);
        }
        else{
            this.fetch();
        }

    }

    onSearchInputFocus(event)
    {
        event.target.focus();
        const {dispatch} = this.props;
        dispatch(buildAction(SEARCH.SET_INPUT_FOCUSED, {focused: true}));
    }

    onSearchInputBlur()
    {
        setTimeout(() =>
        {
            const {dispatch } = this.props;
            dispatch(buildAction(SEARCH.SET_INPUT_FOCUSED, {focused: false}));
        }, 100)
    }

    render()
    {
        let { search } = this.props
        if (!this.allowedRoutes.includes(this.props.location.pathname)) {
            return null
        }

        let markup =
            <div className={"search-row" + (search.focused ? " focused" : "")}>
                <div className="search-input">
                <div className="search-bar">
                    <div onClick={this.gotoSearch}>
                        <InlineIcon>
                            <SearchIcon/>
                        </InlineIcon>
                    </div>
                    <input
                        ref="input"
                        type="text"
                        className="search-input"
                        placeholder="Enter a search term or query"
                        onClick={this.onSearchInputFocus}
                        onBlur={this.onSearchInputBlur}
                        onChange={this.onSearchInputChange}
                        value={search.value}
                    />
                </div>
                </div>

            </div>


        return markup
    }
}

// export default connect((store) => store)(Search)


export default connect((store) => ({
    search: store.search
}))(Search)
