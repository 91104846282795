import * as React from "react"

function MaterialNonMetalIcon() {
  return (
    <svg
      width="20px"
      height="18px"
      viewBox="0 0 20 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="translate(.515 -4.787)"
        stroke="#FFF"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <path
          transform="rotate(45 9.604 16.396)"
          d="M13.4155334 10.5971994L16.3956504 9.60434963 13.4237417 20.2150425 2.81304888 23.1869511 3.99431585 20.018417"
        />
        <path
          transform="rotate(45 9.604 13)"
          d="M12.2023602 7.58918002L16.3956504 6.20869925 13.4237417 16.8193921 2.81304888 19.7913007 4.10476252 15.6867777"
        />
        <path
          d="M15.66 3.587l-2.642 9.431-9.431 2.642 3.028-9.045 9.045-3.028z"
          transform="rotate(45 9.604 9.604)"
        />
      </g>
    </svg>
  )
}

export default MaterialNonMetalIcon;