import            "./index.css"
import React from "react"
import Utils     from "../../../modules/utils"


class View extends React.Component
{
    constructor(props)
    {
        super(props)

        // auto-bind all class methods to the View instance
        // using es6 syntax like this will not be bound:
        //      method = () => {}

        // get current instance keys
        let keysA = Utils.getObjectKeys(this)

        // get basic react instance keys
        let keysB = Utils.getObjectKeys(new React.Component());

        for (let i = 0; i < keysA.length; i++)
        {
            let key = keysA[i]

            // do not bind if react already has the key
            if(keysB.indexOf(key) > -1) continue

            // TODO: can we determine if property is getter?
            // getters can throw exceptions when accessed
            try
            {
                // bind if value is a function
                if(this[key] instanceof Function) this[key] = this[key].bind(this)
            }
            catch(err)
            {
                continue
            }
        }

        // access initialState as an immutable
        this._initialState = null
    }

    get initialState()
    {
        return Utils.clone(this._initialState)
    }

    set initialState(value)
    {
        if(this._initialState) return
        this._initialState = Utils.clone(value)
    }

    redirect(path, state)
    {
        // redirect using the browser if no state is passed
        if(!state)
        {
            return window.location = path
        }

        // TODO: why is history.listen not always called when using this method
        this.props.history.push({pathname: path, state: state})
    }
}

export default View
