import                         "./index.css";
import React, {Component} from "react";
import Utils              from "../../../../modules/utils";
import Link               from "../../../ui/link"
import EllipsisTooltip    from "../ellipsis-tooltip";

export class SimpleTable extends Component
{
    constructor(props)
    {
        super(props)

        this.state =
        {
            column :
            {
                name      : this.props.currentSortItem,
                ascending : (this.props.currentSortItemAscending || false),
            },
            headings : this.props.headings
        }

        this.onHeadingClick = this.onHeadingClick.bind(this)
        this.sort           = this.sort.bind(this)
        this.setMyState     = this.setMyState.bind(this)
    }

    componentWillMount(){
       this.sort()
    }

    componentDidMount(){
        this._ismounted = true
    }

    componentWillUnmount(){
        this._ismounted = false
    }

    setMyState(state = this.state, cb){
        if (this._ismounted){
            this.setState(state, cb)
        }
    }

    onHeadingClick(event)
    {
        let column       = this.state.column
        let target       = event.target
        if ( target.tagName !== "TH" )
            target       = event.target.closest("th")

        column.name      = target.getAttribute("name")
        column.ascending = !target.className.match("ascending")

        this.setMyState(this.state, this.sort)
    }


    sort()
    {
        let column   = this.state.column
        let columns  = this.props.headings
        let rows     = this.props.rows

        columns.forEach((col) =>
        {
            col.className = ""
            if(col.key === column.name)
                col.className = column.ascending ?  "ascending" : "descending"
        })

        rows.sort((a,b) =>
        {
            a = Utils.hash(column.name, a).value
            b = Utils.hash(column.name, b).value

            if(typeof a === "string") a = a.toLowerCase()
            if(typeof b === "string") b = b.toLowerCase()

            if(a < b) return column.ascending ? -1 :  1
            if(a > b) return column.ascending ?  1 : -1
            return 0
        })

        let state = this.state
        state.rows = rows
        this.setMyState(state, this.saveStyles)
    }

    render()
    {
        let markup   = null
        let headings = this.props.headings
        let rows     = this.props.rows

        markup =
            <div className="simple-table">
                <table>
                    <thead>
                        <tr>
                        {
                            headings.map((col, i) =>
                            {
                                let textBlock =
                                <div className="th-holder">
                                    <EllipsisTooltip
                                        index={i}
                                        classes={"heading ellipsis-tooltip"}
                                        title={col.tooltip}
                                        innerClasses={"text-block"}
                                    >
                                        <div
                                            className={"th-text"}
                                            onClick={col.sortable ? this.onHeadingClick : null}
                                            >
                                            {col.displayName}
                                        </div>
                                    </EllipsisTooltip>
                                    {
                                        rows.length > 1 &&
                                        <div
                                            className="sorting-icon-holder"
                                            onClick={col.sortable ? this.onHeadingClick : null}
                                            >
                                            <div
                                                className="sorting-icon"
                                            />
                                        </div>
                                    }
                                </div>


                                let sortableClass = rows.length > 1 && col.sortable ? ' sortable ' : 'default-cursor'
                                let thClasses = (col.headingClass || '') + " " + col.className + sortableClass

                                let block =
                                    <th
                                        key={i}
                                        name={col.key}
                                        className={ thClasses }
                                        >
                                        {textBlock}
                                    </th>
                                    return block;
                            })
                        }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            rows.length > 0 ?
                            rows.map((row, i) =>
                            {
                                let block =
                                    <tr key={i}
                                        className={row.rowClassName}
                                        >
                                    {
                                        headings.map((heading, index) =>
                                        {
                                            let cell = row[heading.key]
                                            let tdClasses = (!!!cell.cellClass ? '' : cell.cellClass)

                                            let TagName = row.onClick ? `${'a'}` : Link
                                            TagName = cell.notLink === true ? `${'div'}` : TagName;

                                            let childBlock =
                                            <td
                                                key={i + ":" + index}
                                                className={tdClasses}
                                                >
                                                <TagName
                                                    to={row.rowLink || '#'}
                                                    href={row.rowLink || '#'}
                                                    onClick={row.onClick || null}
                                                    className={cell.firstDivClass && cell.firstDivClass}
                                                    >
                                                    <EllipsisTooltip
                                                        classes={"ellipsis-tooltip"}
                                                        index={index}
                                                        innerClasses={"text-block"}
                                                        title={cell.tooltip}>
                                                            {cell.displayValue}
                                                    </EllipsisTooltip>

                                                </TagName>
                                            </td>

                                            return childBlock
                                        })

                                    }
                                    </tr>
                                return block
                            })
                            :
                            <tr className='no-data'>
                                <td colSpan={headings.length} className="muted-text">
                                    {this.props.footerElForNoEmptyData || "No data present!"}
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>

        return markup
    }
}

export default SimpleTable
