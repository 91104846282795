import React from "react";

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='15'
      viewBox='0 0 16 15'
      id='revert-icon'
    >
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g transform='translate(-1059 -184)'>
          <g transform='translate(1041 111)'>
            <g transform='rotate(90 -20.5 52.833)'>
              <path
                stroke='#FFF'
                strokeWidth='0.5'
                d='M6.515 14C2.917 14 0 10.866 0 7s2.917-7 6.515-7c3.599 0 6.516 3.134 6.516 7'
                className='path1'
              ></path>
              <path
                fill='#FFF'
                d='M9.47027287 5.64675817L16 5.64675817 12.7351364 9.88152941z'
                className='path2'
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
