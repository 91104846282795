export interface Source {
  extId?: string;
  id?: string;
  type: SourceType;
  url?: string;
}

export enum SourceType {
  DURO_BUILD_PRODUCTION = "DURO_BUILD_PRODUCTION",
  DURO_BUILD_REVISION = "DURO_BUILD_REVISION",
  DURO_COMPONENT = "DURO_COMPONENT",
  DURO_COMPONENT_REVISION = "DURO_COMPONENT_REVISION",
  DURO_PRODUCT = "DURO_PRODUCT",
  DURO_PRODUCT_REVISION = "DURO_PRODUCT_REVISION",
  GENERIC = "GENERIC",
}
