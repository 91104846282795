import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
      <g data-name="Layer 2">
        <g>
          <path
            d="M25.41 6.56a1.13 1.13 0 00-.25-.37L19.3.33a1.14 1.14 0 00-.8-.33H4.25a1.13 1.13 0 00-1.13 1.13v26.35a1.13 1.13 0 001.13 1.13h20.11a1.13 1.13 0 001.13-1.13V7a1.13 1.13 0 00-.08-.44zm-5.78-2.69l2 2h-2zm3.6 22.48H5.38V2.27h12V7a1.13 1.13 0 001.12 1.13h4.73z"
            className="cls-10000"
          ></path>
          <path
            d="M30.75 12.25a1.13 1.13 0 00-1.13 1.13v19.35h-19a1.13 1.13 0 100 2.27h20.13a1.13 1.13 0 001.13-1.13V13.38a1.13 1.13 0 00-1.13-1.13z"
            className="cls-10000"
          ></path>
          <path d="M0 0H35V35H0z" className="cls-2"></path>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
