import { Box, Paper, Typography, styled } from "@mui/material";
import { BreadCrumbComponent } from "common/components/breadcrumb";
import { useDuroFlag } from "common/components/launchDarkly";
import {
  IPageWrapperProps, MainSection, PageContent, PageHeader, RightSidePanel,
} from "common/components/pageLayout";
import { Toolbar } from "common/components/toolbar";
import { useMemo } from "react";
import { Helmet } from "react-helmet";
import { makeTitle } from "utils/helper";

export interface BuildPageWrapperProps extends IPageWrapperProps {
  isLoading?: boolean;
}

export function BuildPageWrapper(props: BuildPageWrapperProps) {
  const { build: isBuildEnabled } = useDuroFlag();
  const {
    breadCrumbOptions, children, isLoading, helmetTitle, rightSidePanelProps, toolbarProps,
  } = props;

  const tabTitle = useMemo(() => (makeTitle(helmetTitle)), [helmetTitle]);

  const { RightSidePanelChildren, visible = false, ...rightSidePanelRest } = rightSidePanelProps ?? {};
  const { toolbarItems, ...toolbarRest } = toolbarProps;

  return (
    <MainSection>
      <Helmet>
        <title>{tabTitle}</title>
      </Helmet>
      <SubSection>
        <StyledPageHeader>
          <BreadCrumbComponent breadCrumbOptions={breadCrumbOptions} />
          {
            toolbarItems.length ? <Toolbar
              centerActions={toolbarItems}
              {...toolbarRest}
            /> : null
          }
        </StyledPageHeader>
        <StyledPageContent >
          {!isBuildEnabled && (
            <Paper sx={{ padding: "1rem", margin: "1rem", textAlign: "center" }}>
              <Typography variant="h3">Build functionality is not enabled for this account.</Typography>
            </Paper>
          )}
          {isBuildEnabled && !isLoading && children}
        </StyledPageContent>
      </SubSection>
      <RightSidePanel
        visible={visible}
        {...rightSidePanelRest}
      >
        {RightSidePanelChildren}
      </RightSidePanel>
    </MainSection>
  );
}

const SubSection = styled(Box)(() => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  overflow: "hidden",
}));

const StyledPageHeader = styled(PageHeader)({
  padding: "1.5rem",
});

const StyledPageContent = styled(PageContent)({
  paddingBottom: 0,
});
