import React from "react";

const SvgComponent = props => (
  <svg
    width="15px"
    height="17px"
    viewBox="0 0 15 17"
    id="duplicate-icon-new"
    {...props}
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="icon-g"
        transform="translate(-20 -9)"
        fill="#FFF"
        fillRule="nonzero"
      >
        <g transform="translate(20 9)" id="COPY">
          <g id="COPY_ICON">
            <path
              d="M14.173 4.24v-.041a.822.822 0 00-.061-.307c0-.02 0-.02-.02-.041-.041-.103-.082-.205-.164-.287l-.02-.02c-.042-.042-.062-.083-.103-.124L10.773.37A1.265 1.265 0 009.913 0H4.424c-.676 0-1.229.553-1.229 1.229v1.946H1.23C.553 3.175 0 3.728 0 4.404V15.77C0 16.447.553 17 1.229 17h8.52c.676 0 1.23-.553 1.23-1.229v-1.946h1.966c.675 0 1.228-.553 1.228-1.229V4.281 4.24zM9.75 15.77H1.23V4.404h1.966v8.192c0 .676.553 1.23 1.23 1.23h5.324v1.945zm3.196-3.175H4.424V1.23h4.26V4.3c0 .512.328.963.779 1.147.143.062.286.082.45.082h3.052v7.066h-.02zM9.913 4.281V1.249l3.052 3.052H9.913v-.02z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgComponent;
