import { SvgIcon } from "@mui/material";

export const ProductIcon = () => (
  <SvgIcon height="24" width="24" viewBox="0 0 24 24" version="1.1">
    <path d="M19.7777778,2 L4.22222222,2 C3,2 2,3 2,4.22222222
      L2,19.7777778 C2,21 3,22 4.22222222,22 L19.7777778,22 C21,
      22 22,21 22,19.7777778 L22,4.22222222 C22,3 21,2 19.7777778,
      2 Z M14.7777778,12 C16.3148148,12 17.5555556,13.2407407 17.5555556,
      14.7777778 C17.5555556,16.3148148 16.3148148,17.5555556 14.7777778,
      17.5555556 C13.2407407,17.5555556 12,16.3148148 12,14.7777778 C12,
      13.2407407 13.2407407,12 14.7777778,12 Z M10.8888889,13.1111111
      L10.8888889,17.5555556 L6.44444444,17.5555556 L6.44444444,
      13.1111111 L10.8888889,13.1111111 Z M11.8771215,5.44982691 L15.0198183,
      8.59252371 L11.8771215,11.7352205 L8.73442473,8.59252371 L11.8771215,
      5.44982691 Z"
    />
  </SvgIcon>
);
