import                  "./index.css"
import React       from "react"
import ModalBox    from "../../../../ui/modal-box"
import UserFormView from "./user-form-view"

export class UserModalBox extends React.Component
{
    constructor(props, context)
    {
        super(props, context)
        this.openModal  = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.state          = {
            openModal: true
        }
    }

    openModal()
    {
        this.state.openModal = true
        this.setState(this.state)
    }

    closeModal()
    {
        this.state.openModal = false
        this.setState(this.state, this.props.onClose)
    }

    render()
    {
        let displayModal = this.props.displayModal
        let markup = <div className="user-add-edit-modal">
          {
            this.state.openModal ?
            <ModalBox
                onClose={this.closeModal}
                history={this.props.history}
                className='new-user-modal'
            >
              <UserFormView
                  displayModal={displayModal}
                  props={this.props.props}
                  history={this.props.history}
                  onCancel={this.closeModal}
                  mode={this.props.mode}
                  groupName={this.props.groupName}
                  afterSubmitSuccessCb={this.props.afterSubmitSuccessCb}
              />
            </ModalBox>
            : ""
          }
        </div>
        return markup
    }
}

export default UserModalBox
