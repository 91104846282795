import                           "./index.css"
import React                 from "react"
import {connect}             from "react-redux";
import USER                  from "../../../../../action-types/user"
import USERS                 from "../../../../../action-types/users"
import Utils                 from "../../../../../modules/utils"
import NewUserModalBox       from "../../users/user-modal-box"
import AddExistingUsersModal from "../add-existing-users-modal"
import ModalBox              from "../../../../ui/modal-box"
import Icon                  from "../../../../ui/icon"
import deleteSrc             from "../../../../../assets/icons/cross-icon.svg"
import buildAction           from "../../../../../helpers/buildAction"
import Schemas               from "../../../../../modules/schemas"
import Permissions           from "../../../../../modules/schemas/permissions"

export class GroupsList extends React.Component
{
    constructor(props, context)
    {
      super(props, context)
      this.displayRemoveFromModal = this.displayRemoveFromModal.bind(this)
      this.showAddUserModal  = this.showAddUserModal.bind(this)
      this.closeAddUserModal  = this.closeAddUserModal.bind(this)
      this.hideRemoveFromModal  = this.hideRemoveFromModal.bind(this)
      this.removeUserFromGroup  = this.removeUserFromGroup.bind(this)

      this.state = {
          addUser: false,
          groupName: this.props.groupName,
          removeFromGroup: false,
          selectedUser: {}
      }
    }

    showAddUserModal(groupName)
    {
        this.state.addUser = true
        this.state.groupName = groupName
        this.setState(this.state)
    }

    closeAddUserModal()
    {
        this.state.addUser = false
        this.state.groupName = ''
        this.setState(this.state)
    }

    displayRemoveFromModal(user){
      this.state.removeFromGroup = true
      this.state.selectedUser = user
      this.setState(this.state)
    }

    hideRemoveFromModal(){
      this.state.removeFromGroup = false
      this.state.selectedUser = {}
      this.setState(this.state)
    }

    removeUserFromGroup(){
        let payload = {userId: this.state.selectedUser._id, groupName: this.state.groupName.toUpperCase()}
        let {dispatch} = this.props
        dispatch(buildAction(USER.REMOVE_USER_FROM_GROUP, payload))
        this.hideRemoveFromModal()
    }

    render()
    {
        let { users }     = this.props.users
        let { groupName } = this.props
        let current_user  = this.props.user.data
        groupName = groupName.toUpperCase()

        var userRows = users.map((user, i) =>
        {
            if (user.groups.includes(groupName) && user.groups.length !== 0 && !this.props.disableActions)
            {
                let row  =
                    <tr key={i} >
                        <td>
                          {Utils.capitalizeString(user.name)}
                        </td>

                        <td>
                          {user.email}
                        </td>

                        {
                          this.props.disableActions !== true ?
                          <td>
                            {user.groups.length > 1 ? "Member of " + user.groups.length + " Groups" :  "Member of " + user.groups.length + " Group"}
                          </td>
                          :
                          null
                        }

                        {
                            Permissions.can("edit", "groups", current_user) &&
                            <td className="remove-col">
                              {
                                this.props.disableActions == true ?
                                null :
                                <Icon
                                  src={deleteSrc}
                                  onClick={() => this.displayRemoveFromModal(user)}
                                />
                              }
                          </td>

                        }
                    </tr>
                return row
            }
            else if (this.props.disableActions && user.groups.length === 0)
            {
                let row  =
                    <tr key={i} >
                        <td>
                          {Utils.capitalizeString(user.name)}
                        </td>

                        <td>
                          {user.email}
                        </td>

                        <td>{null}</td>

                        <td className="remove-col">
                            { null }
                        </td>
                    </tr>
                return row
            }
        })

        if ( Utils.removeEmptyValues(userRows).length == 0 )
        {
          userRows = [<tr key={groupName+1}><td className="no-users">No users in this group</td></tr>]
        }

        let markup =
            <div className="group-list">
              {
                this.state.removeFromGroup &&
                <ModalBox onClose={this.hideRemoveFromModal} className="remove-user">
                    <div>
                       <h1>Remove From Group</h1>
                       <h3>{this.state.selectedUser.email}</h3>
                        <div className="info">
                        <p>
                          {"This user will be removed from " + this.props.groupName + "."}
                        </p>
                        </div>
                    </div>
                    <div className="buttons modal-footer">
                        <button className="empty" onClick={this.hideRemoveFromModal}>NO</button>
                        <button className="filled" onClick={this.removeUserFromGroup}>YES</button>
                    </div>
                </ModalBox>
              }

              <div>
              {
                  this.state.addUser &&
                  <AddExistingUsersModal
                    onClose={this.closeAddUserModal}
                    displayModal={this.state.addUser}
                    groupName={groupName}
                  />

              }
              </div>

              <div className="group-header">
                <div className="title">
                  {
                    groupName!= 'QA' ?
                      Utils.capitalizeString(groupName)
                      :
                      groupName
                  }
                </div>
                {
                  this.props.disableActions == true ?
                  null
                  :
                  <div
                    className="add-btn-holder">
                    {
                      Permissions.can("edit", "groups", current_user) &&
                      <span
                          onClick={(e) => this.showAddUserModal(groupName)}
                          onClick={this.showAddUserModal}
                          >
                          + ADD USER
                      </span>
                    }
                  </div>
                }
              </div>
              <table>
                  <tbody>
                    {userRows}
                  </tbody>
              </table>
            </div>

        return markup
    }
}

export default connect((store) => store)(GroupsList)
