import { Box, Icon, styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { DefaultResolution } from "common/constants";
import { useImages } from "common/hooks";
import { useMemo } from "react";
import GearIcon from "v1/assets/icons/gear";
import ImageEnlargeIcon from "v1/assets/icons/image-enlarge-icon.js";
import { ImageWithLoader, PlaceholderBackground, PlaceholderIcon } from "../ImageWithLoader";

export interface ImageFieldProps {
  alt?: string;
  defaultResolution?: DefaultResolution;
  imageIds?: string[];
}

export function ImageField(props: ImageFieldProps) {
  const { alt = "", defaultResolution, imageIds } = props;
  let images;

  const { imageList } = useImages(imageIds);
  if (imageList.length) images = imageList;
  const [image] = images ?? [];
  const hasDefaultImage = !image?.src;

  const imageSrc = useMemo(() => {
    if (hasDefaultImage) return "#";
    const { src, variants } = image;
    if (defaultResolution && variants) {
      return variants[defaultResolution] ?? src;
    }
    return src;
  }, [defaultResolution, hasDefaultImage, image]);

  const imageViewer = useMemo(() => {
    if (hasDefaultImage) return "";
    let viewerSrc = image.src;
    if (image?.variants) {
      viewerSrc = image.variants[DefaultResolution.ENLARGED_VIEW] ?? "#";
    }

    return (
      <ImageViewerWrapper>
        <img alt={alt} src={viewerSrc} />
      </ImageViewerWrapper>
    );
  }, [alt, hasDefaultImage, image]);

  return (
    <ImageContainer>
      {hasDefaultImage && (
        <PlaceholderBackground>
          <PlaceholderIcon><GearIcon /></PlaceholderIcon>
        </PlaceholderBackground>
      )}
      {!hasDefaultImage && (
        <>
          <ImageWrapper><ImageWithLoader alt={alt} src={imageSrc} /></ImageWrapper>
          <HtmlTooltip placement="right-start" title={imageViewer}>
            <StyledIcon>
              <ImageEnlargeIcon />
            </StyledIcon>
          </HtmlTooltip>
        </>
      )}
    </ImageContainer>
  );
}

const ImageContainer = styled(Box)(() => ({
  display: "flex",
  flex: 1,
  flexDirection: "row",
  justifyContent: "center",
}));

const ImageWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: "2px",
  display: "flex",
  height: "28px",
  justifyContent: "center",
  padding: "4px 5px",
  width: "38px",
}));

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(12),
    maxWidth: 220,
  },
}));

const ImageViewerWrapper = styled(Box)(() => ({
  alignItems: "center",
  display: "flex",
  height: "100%",
  justifyContent: "center",
  width: "100%",
}));

const StyledIcon = styled(Icon)(({ theme }) => ({
  bottom: 0,
  cursor: "pointer",
  fontSize: "18px",
  position: "absolute",
  right: "-4px",
  "& > svg": {
    fontSize: "18px",
    height: "18px",
    width: "10px",
  },
  "&:hover g": {
    stroke: theme.palette.primary.main,
  },
}));
