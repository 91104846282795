export default class ImportUtils
{
    static mergeData(inputs, cmpFromWeb, cmpFromSheet, oldComponent=null, updateData=false)
    {
        let selectedKeys = []
        ImportUtils.mergeFieldsFromSheet(inputs, cmpFromWeb, cmpFromSheet, oldComponent, selectedKeys)
        ImportUtils.mergeComponentFields(cmpFromWeb, cmpFromSheet, oldComponent, selectedKeys, updateData)
        return cmpFromSheet
    }

    static filterUseExisting(components)
    {
        let list = []
        components.forEach((cmp) =>
        {
            if (cmp.selectedFromLibrary)
            {
                let component = cmp.selectedCmpFromLibrary
                component.quantity = cmp.quantity
                component.refDes = cmp.refDes
                component.notes = cmp.notes
                list.push(component)
            }
        })

        return list

    }

    static filterUseWithoutExisting(components)
    {
        let list = []
        components.forEach((cmp) =>
        {
            if (cmp.selectedFromLibrary)
            {
                // noop
            }
            else{
                list.push(cmp)
            }
        })

        return list

    }

    static filterUniqueComponents(components)
    {
        let list = []
        let ids = []

        components.forEach((cmp) =>
        {
            if (!ids.includes(cmp._id))
            {
                ids.push(cmp._id)
                list.push(cmp)
            }
        })
        return list
    }

    static mergeFieldsFromSheet(inputs, cmpFromWeb, cmpFromSheet, oldComponent, selectedKeys=[])
    {
        inputs.forEach((input) =>
        {
            selectedKeys.push(input.name)
            if(input.name === "specs")
            {
                ImportUtils.mergeSpecs(cmpFromWeb, cmpFromSheet, oldComponent, true)
            }
            if(input.name === "documents")
            {
                ImportUtils.mergeDocuments(cmpFromWeb, cmpFromSheet, oldComponent, true)
            }

            if(input.name === "images")
            {
                ImportUtils.mergeImages(cmpFromWeb, cmpFromSheet, oldComponent, true)
            }
            else if( (!cmpFromSheet.hasOwnProperty(input.name) && cmpFromWeb.hasOwnProperty(input.name)) || (cmpFromSheet.hasOwnProperty(input.name) && !cmpFromSheet[input.name]))
            {
                if(input.name !== "name" && (!oldComponent || (oldComponent && !oldComponent[input.name])))
                {
                    cmpFromSheet[input.name] = cmpFromWeb[input.name]
                }
            }
        })

        return {cmpFromSheet: cmpFromSheet, selectedKeys: selectedKeys}
    }

    static mergeComponentFields(cmpFromWeb, cmpFromSheet, oldComponent, selectedKeys=[], updateData)
    {
        ImportUtils.mergeManufacturers(cmpFromWeb, cmpFromSheet, oldComponent, updateData)

        if(!selectedKeys.includes("specs"))
        {
            ImportUtils.mergeSpecs(cmpFromWeb, cmpFromSheet, oldComponent)
        }

        if(!selectedKeys.includes("description"))
        {
            ImportUtils.mergeDescription(cmpFromWeb, cmpFromSheet, oldComponent)
        }
        if(!selectedKeys.includes("documents"))
        {
            ImportUtils.mergeDocuments(cmpFromWeb, cmpFromSheet, oldComponent)
        }
        if(!selectedKeys.includes("images"))
        {
            ImportUtils.mergeImages(cmpFromWeb, cmpFromSheet, oldComponent)
        }

        return cmpFromSheet
    }

    static mergeManufacturers(cmpFromWeb, cmpFromSheet, oldComponent, updateData)
    {
        if(oldComponent)
        {
            if(!cmpFromSheet.hasOwnProperty("manufacturers") || cmpFromSheet.manufacturers.length === 0)
            {
                cmpFromSheet.manufacturers = oldComponent.manufacturers
            }
        }
        if(!updateData && cmpFromWeb.hasOwnProperty("manufacturers") && cmpFromWeb.manufacturers.length > 0)
        {
            if (cmpFromWeb.manufacturers.length > 0 && cmpFromSheet.manufacturers.length > 0)
            {
                let manufacturerFromWeb = cmpFromWeb.manufacturers[0]
                let manufacturerFromSheet = cmpFromSheet.manufacturers[0]
                // cmpFromSheet.manufacturers.push(manufacturerFromWeb)
                if (manufacturerFromSheet.distributors.length > 0 && manufacturerFromWeb.distributors.length > 0)
                {
                    manufacturerFromWeb.distributors.push(manufacturerFromSheet.distributors[0])
                }
            }
            cmpFromSheet.manufacturers = cmpFromWeb.manufacturers
        }

        return cmpFromSheet
    }

    static mergeSpecs(cmpFromWeb, cmpFromSheet, oldComponent, fromSheet=false)
    {
        if(fromSheet)
        {
            if(oldComponent)
            {
                let specs = oldComponent.specs
                oldComponent.specs.forEach( (spec, index) =>
                {
                    cmpFromSheet.specs.forEach( (item) =>
                    {
                        if(spec.key === item.key)
                        {
                            if(item.value)
                            {
                                spec.value = item.value
                            }
                            else if(!spec.value && cmpFromWeb.hasOwnProperty("specs"))
                            {
                                cmpFromWeb.specs.forEach( (specObj) =>
                                {
                                    if(spec.key === specObj.key && specObj.value)
                                    {
                                        spec.value = specObj.value
                                    }
                                })
                            }
                            return
                        }
                    })
                    specs[index] = spec
                })

                cmpFromSheet.specs = specs
            }
            else
            {
                cmpFromSheet.specs.forEach( (spec, index) =>
                {
                    if(!spec.value && cmpFromWeb.hasOwnProperty("specs"))
                    {
                        cmpFromWeb.specs.forEach( (item) =>
                        {
                            if(item.key === spec.key && item.value)
                            {
                                spec.value = item.value
                                return
                            }
                        })
                    }

                    cmpFromSheet.specs[index] = spec
                })
            }
        }
        else
        {
            if(oldComponent)
            {
                cmpFromSheet.specs = oldComponent.specs
                oldComponent.specs.forEach( (spec, index) =>
                {
                    if(!spec.value && cmpFromWeb.hasOwnProperty("specs"))
                    {
                        cmpFromWeb.specs.forEach( (item) =>
                        {
                            if(spec.key === item.key && item.value)
                            {
                                spec.value = item.value
                            }
                        })
                    }
                    cmpFromSheet.specs[index] = spec
                })
            }
            else
            {
                if (cmpFromWeb.specs && cmpFromWeb.specs.length > 0)
                {
                    cmpFromSheet.specs = cmpFromWeb.specs
                }
            }
        }


        return cmpFromSheet
    }

    static mergeDescription(cmpFromWeb, cmpFromSheet, oldComponent, fromSheet=false)
    {
        if(oldComponent)
        {
            if(!cmpFromSheet.description)
            {
                cmpFromSheet.description = oldComponent.description
            }
        }

        if(!cmpFromSheet.description && cmpFromWeb.description)
        {
            cmpFromSheet.description = cmpFromWeb.description
        }

        return cmpFromSheet
    }

    static mergeDocuments(cmpFromWeb, cmpFromSheet, oldComponent, fromSheet=false)
    {
        if(fromSheet)
        {
            let documentNameArr = []
            if(oldComponent)
            {
                if(cmpFromSheet.hasOwnProperty("documents") && cmpFromSheet.documents.length > 0)
                {
                    if(oldComponent.documents.length > 0)
                    {

                        cmpFromSheet.documents.forEach( (document) =>
                        {
                            documentNameArr.push(document.name.split("/").pop())
                        })

                        oldComponent.documents.forEach( (document) =>
                        {
                            if(!documentNameArr.includes(document.file.name))
                            {
                                documentNameArr.push(document.file.name)
                                cmpFromSheet.documents.push(document)
                            }
                        })

                    }
                }
                else
                {
                    cmpFromSheet.documents = oldComponent.documents
                }
            }

            if(cmpFromWeb.hasOwnProperty("documents") &&  cmpFromWeb.documents.length > 0)
            {
                cmpFromWeb.documents.forEach( (document) =>
                {
                    if(!documentNameArr.includes(document.name.split("/").pop()))
                    {
                        cmpFromSheet.documents.push(document)
                    }
                })
            }
        }
        else
        {
            if(oldComponent)
            {
                cmpFromSheet.documents = oldComponent.documents
                if(cmpFromWeb.hasOwnProperty("documents") &&  cmpFromWeb.documents.length > 0)
                {
                    let documentNameArr = []
                    cmpFromSheet.documents.forEach( (document) =>
                    {
                        documentNameArr.push(document.file.name)
                    })

                    cmpFromWeb.documents.forEach( (document) =>
                    {
                        if(!documentNameArr.includes(document.name.split("/").pop()))
                        {
                            cmpFromSheet.documents.push(document)
                        }
                    })
                }
            }
            else
            {
                cmpFromSheet.documents = cmpFromWeb.documents
            }
        }
        return cmpFromSheet
    }

    static mergeImages(cmpFromWeb, cmpFromSheet, oldComponent, fromSheet=false)
    {
        if(fromSheet)
        {
            let imageNameArr = []
            let mergedImages = []
            if(oldComponent)
            {
                if(cmpFromSheet.hasOwnProperty("images") && cmpFromSheet.images.length > 0)
                {
                    if(oldComponent.images.length > 0)
                    {
                        oldComponent.images.forEach( (image) =>
                        {
                            imageNameArr.push(image.name)
                            mergedImages.push(image)
                        })

                        cmpFromSheet.images.forEach( (image) =>
                        {
                            if(!imageNameArr.includes(image.src.split("/").pop()))
                            {
                                imageNameArr.push(image.src.split("/").pop())
                                mergedImages.push(image)
                            }
                        })
                    }
                }
                else
                {
                    mergedImages = oldComponent.images
                }
            }
            else
            {
                mergedImages = cmpFromSheet.images
            }

            if(cmpFromWeb.hasOwnProperty("images") && cmpFromWeb.images.length > 0)
            {
                cmpFromWeb.images.forEach( (image) =>
                {
                    let imgName = image.name ? image.name : image.src.split("/").pop()
                    if(!imageNameArr.includes(imgName))
                    {
                        imageNameArr.push(imgName)
                        mergedImages.push(image)
                    }
                })
            }
            cmpFromSheet.images = mergedImages
        }
        else
        {
            if(oldComponent)
            {
                cmpFromSheet.images = oldComponent.images

                if(cmpFromWeb.hasOwnProperty("images") && cmpFromWeb.images.length > 0)
                {
                    let imageNameArr = []
                    cmpFromSheet.images.forEach( (image) =>
                    {
                        imageNameArr.push(image.name)
                    })

                    cmpFromWeb.images.forEach( (image) =>
                    {
                        let imgName = image.name ? image.name : image.src.split("/").pop()

                        if(!imageNameArr.includes(imgName))
                        {
                            imageNameArr.push(imgName)
                            cmpFromSheet.images.push(image)
                        }
                    })
                }
            }
            else
            {
                cmpFromSheet.images = cmpFromWeb.images
            }
        }
        return cmpFromSheet
    }

    static createComponentBatches(components)
    {
        let i, j, temp, batch_size, batchesArray=[], elementsCount = 0
        const randomBatchSize = [4,5,6,7];

        for (i=0;  elementsCount < components.length; i+=batch_size)
        {
            batch_size = randomBatchSize[Math.floor(Math.random()*randomBatchSize.length)];
            temp = components.slice(i,i+batch_size)
            batchesArray.push(temp)
            elementsCount = elementsCount + temp.length
        }
        return batchesArray
    }

    static splitComponentsBasedOnCategoryType(components)
    {
        let categoryWithCmps = {}
        let categories = []


        components.forEach((cmp) =>
        {
            if (!categories.includes(cmp.category))
            {
                categories.push(cmp.category)
                categoryWithCmps[cmp.category] = []
            }
        })

        categories.forEach((category) =>
        {
            components.forEach((cmp) => {
                if (category === cmp.category)
                {
                    categoryWithCmps[cmp.category].push(cmp)
                }
            })
        })
        return categoryWithCmps
    }

    static getDefaultMinWidthOfColumn(column)
    {
        let defaultMinWidthOfColumns =
        {
            "category": 100,
            "name": 71,
            "eid": 43,
            "description": 109,
            "revision": 82,
            "status": 73,
            "datasheet": 99,
            "cpn": 43,
            "parent": 130,
            "specs": 64,
            "images": 72,
            "documents": 105,
            "assembly": 130,
            "manufacturer": 129,
            "distributor": 109,
            "mpn": 55,
            "dpn": 49,
            "price": 60,
            "min quantity": 118,
            "quote lead time": 140,
            "quote lead time unit": 174,
            "package": 83,
            "package quantity": 153,
            "quantity": 85,
            "ref des": 83,
            "item number": 120,
            "mfr description": 141,
            "dist description": 141,
            "notes": 72,
            "mass": 118,
            "procurement": 120,
            "unit of measure": 176
        }
        return defaultMinWidthOfColumns[column]
    }

    static updatedSourcingData(oldSourcing = [], sheetSourcing = []) {
        let updatedSourcing = [...sheetSourcing];
        const mpnKey = updatedSourcing[0]?.mpn?.key;
        const dpnKey = updatedSourcing[0]?.distributors[0]?.dpn?.key;
        const minQuantity = updatedSourcing[0]?.distributors[0]?.quotes[0]?.minQuantity;
        oldSourcing.forEach((oldDataMpn) => {
            if(mpnKey !== oldDataMpn.mpn.key) {
                updatedSourcing.push(oldDataMpn);
                return;
            }
            else {
                oldDataMpn.distributors.forEach((oldDataDpn) => {
                if(dpnKey !== oldDataDpn.dpn.key) {
                    updatedSourcing[0].distributors.push(oldDataDpn);
                    return;
                }
                else {
                    oldDataDpn.quotes.forEach((oldDataMin) => {
                            if(minQuantity !== oldDataMin.minQuantity) {
                                updatedSourcing[0].distributors[0].quotes.push(oldDataMin)
                                return;
                            }
                            else
                              return;
                    })
                }
                })
            }
        })
        return updatedSourcing;
    }

    static mergeSourcing(list) {
        let manufacturerHash = list.reduce((result, current) => {
           if(!result[current.cpn]) result[current.cpn] = current.currentComponentClone.manufacturers;
           return result;
        }, {})

        const refineUpdateList = () => {
            let processedCpns = [];
            for(let i = 0; i < list.length; i++) {
                if(processedCpns.includes(list[i].cpn)) {
                    list.splice(i,1);
                    i -= 1;
                    continue;
                }
                processedCpns.push(list[i].cpn);
                list[i].manufacturers = manufacturerHash[list[i].cpn];
            }
        };

        for(let component of list)
        {
            manufacturerHash[component.cpn] = this.updatedSourcingData(manufacturerHash[component.cpn], component.manufacturers)
        }
        refineUpdateList();
    }
}
