import { useMemo } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import {
  AutofitIcon, CopyIcon, DeleteIcon, ExportIcon, FilterIcon, RefreshIcon, UpdateStatusIcon,
} from "assets/icons";
import { RevertIcon } from "assets/icons/revertIcon";
import { CellContentAlignment, ToolBarItem } from "common/components/grid";
import { ToolbarItemType } from "common/constants";
import {
  CellRendererProps, cpnRenderCell, imageRenderCell, lastUpdatedRenderCell,
  statusRenderCell, useRevisionRenderCell, useRevisionRenderHeader,
} from "design/components/grid/cellRenderers";
import { PageItemType, PageMode } from "design/constants";
import { AssemblyTableStyle } from "design/models";
import {
  categoryValueGetter, cpnValueGetter, descriptionValueGetter, eidValueGetter,
  HeaderRendererProps, lastUpdatedValueGetter, nameValueGetter, revisionValueGetter,
  statusValueGetter, ValueGetterArgs,
} from "design/utils/componentGrid";
import { HEADINGS } from "v1/components/page/common/extended-table/helpers";

export interface IHeadingProps {
  ascending: boolean,
  disabled: boolean,
  displayName: string,
  hideable: boolean,
  key: string,
  maxWidth?: number,
  minWidth: number,
  sortable: boolean,
  tooltip: string,
  visibility: boolean,
  width: number,
}

export interface IHeadings {
  [x: string]: IHeadingProps,
}

const headings: IHeadings = (HEADINGS as unknown) as IHeadings;

export type ColumnRenderCell = (props: CellRendererProps) => JSX.Element | "";
export type ColumnRenderHeader = (props: HeaderRendererProps) => JSX.Element;
export type ColumnValueGetter = (props: ValueGetterArgs) => any;
export type TableColumnStyles = Omit<AssemblyTableStyle, "defaultSortAssending" | "defaultSortColumnName">;

export interface IColumnRule {
  field: string,
  renderCell?: ColumnRenderCell,
  renderHeader?: ColumnRenderHeader,
  valueGetter?: ColumnValueGetter,
}

export interface IColumnRule2 {
  align?: CellContentAlignment,
  renderCell?: ColumnRenderCell,
  renderHeader?: ColumnRenderHeader,
  valueGetter?: ColumnValueGetter,
}

type ColumnRulesType = { [key: string]: IColumnRule2 };

export interface IGenerateColumns {
  columnNames: string[],
  customRules?: ColumnRulesType,
  getCurrentStyles: () => AssemblyTableStyle,
  editable?: Boolean,
}

/**
 * Generate DataGrid columns using pre-defined templates.
 *
 * @param editable default editable flag for columns
 * @param customRules custom rules to extend the default columns
 * @param columnNames selected column names
 * @returns generated columns
 */
export const useGenerateColumns = (
  { editable = false, customRules, columnNames, getCurrentStyles }: IGenerateColumns,
): GridColDef[] => {
  const revisionRenderCell = useRevisionRenderCell(PageMode.VIEW, PageItemType.COMPONENT);
  const revisionRenderHeader = useRevisionRenderHeader(false);

  return useMemo(() => {
    const userStyles = getCurrentStyles();

    // default column rules
    const defaultRules: ColumnRulesType = {
      category: {
        valueGetter: categoryValueGetter,
      },
      cpn: {
        renderCell: cpnRenderCell,
        valueGetter: cpnValueGetter,
      },
      description: {
        valueGetter: descriptionValueGetter,
      },
      eid: {
        valueGetter: eidValueGetter,
      },
      images: {
        renderCell: imageRenderCell,
      },
      lastUpdated: {
        renderCell: lastUpdatedRenderCell,
        valueGetter: lastUpdatedValueGetter,
      },
      name: {
        valueGetter: nameValueGetter,
      },
      revision: {
        align: CellContentAlignment.RIGHT,
        renderCell: revisionRenderCell,
        renderHeader: revisionRenderHeader,
        valueGetter: revisionValueGetter,
      },
      status: {
        renderCell: statusRenderCell,
        valueGetter: statusValueGetter,
      },
    };

    // construct and return columns
    return columnNames.map((column, index) => ({
      // defaults
      editable,
      field: column,
      headerName: headings[column].displayName,
      minWidth: headings[column].minWidth,
      sortable: headings[column].sortable,
      width: userStyles?.[column as keyof TableColumnStyles]?.width ?? headings[column].width,
      position: userStyles?.[column as keyof TableColumnStyles]?.position ?? index,
      ...(headings[column].maxWidth && { maxWidth: headings[column].maxWidth }),
      ...("hideable" in headings[column] && { hideable: headings[column].hideable }),

      // apply rules on top of defaults
      ...defaultRules[column],
      ...customRules?.[column],
    })).sort((a, b) => (a.position - b.position)) as unknown as GridColDef[] & { position: number };
  }, [
    columnNames, customRules, editable, getCurrentStyles, revisionRenderCell, revisionRenderHeader,
  ]);
};

export interface IGenerateToolbar {
  customItems?: { [key: string]: Partial<ToolBarItem> },
  dividerIndices?: number[],
  itemNames: string[],
}
type ToolbarRulesType = { [key: string]: ToolBarItem };

/**
 * Generate toolbar items for DataGrid using pre-defined templates.
 *
 * @param columnNames selected column names
 * @param customItems custom rules to extend the default toolbar items
 * @param dividerIndices indices to insert dividers at
 * @returns toolbar items
 */
export const useGenerateToolbar = (
  { itemNames, customItems, dividerIndices }: IGenerateToolbar,
): ToolBarItem[] => useMemo(() => {
  // default items
  const defaultItems: ToolbarRulesType = {
    AutoFit: { Icon: AutofitIcon, type: ToolbarItemType.ACTION, disabled: false },
    Copy: { Icon: CopyIcon, type: ToolbarItemType.ACTION, disabled: false },
    Delete: { Icon: DeleteIcon, type: ToolbarItemType.ACTION, disabled: false },
    Export: { Icon: ExportIcon, type: ToolbarItemType.ACTION, disabled: false },
    Filter: { Icon: FilterIcon, type: ToolbarItemType.ACTION, disabled: false },
    Refresh: { Icon: RefreshIcon, type: ToolbarItemType.ACTION, disabled: false },
    Revert: { Icon: RevertIcon, type: ToolbarItemType.ACTION, disabled: false },
    "Update Status": { Icon: UpdateStatusIcon, type: ToolbarItemType.ACTION, disabled: false },
  };

  // construct toolbar
  const toolbar = itemNames.map(item => ({
    label: item,
    ...defaultItems[item],
    ...customItems?.[item],
  })) as ToolBarItem[];

  // add dividers
  dividerIndices?.sort((a, b) => a - b)?.forEach((dividerIndex, dividersInserted) => {
    toolbar.splice(dividerIndex + dividersInserted, 0, { type: ToolbarItemType.DIVIDER });
  });

  return toolbar;
}, [itemNames, customItems, dividerIndices]);
