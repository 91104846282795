import                      "./index.css"
import React, {Component}   from "react"
import InlineIcon           from "../icon/inline-icon.js"
import LoadingIcon          from "../../../assets/icons/loading"

export class InnerLoading extends Component
{
    render()
    {
        let markup =

            <div className="ui-loading">
                <div className="background"></div>
                <div className="loading-block">
                  <InlineIcon>
                     <LoadingIcon />
                   </InlineIcon>
                </div>
                <div className="text-block">
                    {this.props.loadingText && <p>{this.props.loadingText}</p>}
                </div>
            </div>

        return markup
    }
}

export default InnerLoading
