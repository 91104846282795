import { DuroZenOverlay } from "common/components/duroZenOverlay";
import { EmptyTable, EmptyTableProps, EmptyTableTileProps } from "common/components/grid";
import { useMemo } from "react";

export type OverlayComponentType = React.JSXElementConstructor<any> | undefined;
export type OverlayPropsType = EmptyTableProps | {
  subtitle: string,
};
export interface IUseManageNoRowsOverlayReturn {
  OverlayProps?: OverlayPropsType,
  OverlayComponent: OverlayComponentType
}

const noResultOverlayProps = {
  subtitle: "We could not find the results you’re looking for.\nPlease refine your search.",
};

const tileStyles = {
  height: "17.5rem",
  justifyContent: "center",
  padding: "2.5rem",
  width: "17.5rem",
};

export const useManageNoRowsOverlay = (
  isSearchResult: boolean,
  tiles: EmptyTableTileProps[],
): IUseManageNoRowsOverlayReturn => useMemo(() => {
  if (isSearchResult) {
    return { OverlayComponent: DuroZenOverlay, OverlayProps: noResultOverlayProps };
  }
  return { OverlayComponent: EmptyTable, OverlayProps: { tiles, tileStyles } };
}, [isSearchResult, tiles]);
