import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
      <g data-name="Layer 2">
        <g>
          <path
            d="M34.82 7v-.22A3.44 3.44 0 0034 3.52L31.51 1a3.49 3.49 0 00-4.93 0l-4.65 4.67L2.63 25a1.16 1.16 0 00-.3.51L.07 33.53A1.16 1.16 0 001.18 35a1.18 1.18 0 00.31 0l8.06-2.27a1.17 1.17 0 00.51-.3l18.83-18.87a1.13 1.13 0 00.4-.26l4.62-4.62a3.43 3.43 0 00.81-1.31 3.17 3.17 0 00.1-.37zM8.64 30.54l-5.78 1.63 1.63-5.78L23 7.83a1.12 1.12 0 00.5-.28l4.62-4.62a1.19 1.19 0 011.64 0l2.48 2.48a1.16 1.16 0 01.34.82 1.14 1.14 0 01-.05.27 1.13 1.13 0 01-.2.3l-4.65 4.65z"
            className="cls-10000"
          ></path>
          <path d="M0 0H35V35H0z" className="cls-2"></path>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
