import                                 "./index.css"
import React, { Component }       from "react"
import {connect}                  from 'react-redux';
import PrivateView                from "./private"
import ReactTooltip               from 'react-tooltip'
import PublicView                 from "./public"

class Header extends Component
{
    render()
    {
        const user = this.props.user.data
        let markup =
            <div className="header">
                { user ? <PrivateView {...this.props} /> : <PublicView {...this.props}/> }
                <ReactTooltip delayShow={500} effect="solid" html={true} globalEventOff="click"/>
            </div>

        return markup
    }
}

export default connect((store) => store)(Header)
