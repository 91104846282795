import * as React from "react"

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 35 35" width="1em" height="1em" {...props}>
      <defs>
        <style />
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__SVGs">
          <path
            className="prefix__cls-10000"
            d="M25.55 21.24l-7.9-.1a1.09 1.09 0 01-1.08-1.09V7.74a1.09 1.09 0 012.19 0V19l6.82.08a1.09 1.09 0 010 2.19z"
          />
          <path
            className="prefix__cls-10000"
            d="M17.5 35A17.5 17.5 0 1135 17.5 17.52 17.52 0 0117.5 35zm0-32.81A15.31 15.31 0 1032.81 17.5 15.33 15.33 0 0017.5 2.19z"
          />
          <path fill="none" d="M0 0h35v35H0z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
