import { fork, all }            from 'redux-saga/effects';
import componetCommonSaga       from "./common"
import componentRevisionSaga    from "./revision"
import componentEditSaga        from "./edit"

export default function* componentSaga(context = {}) {
    yield all([
        fork(componetCommonSaga, context),
        fork(componentRevisionSaga, context),
        fork(componentEditSaga, context)
    ]);
}
