export default
{
    data : {
    },
    logo_src : null,
    name: null,
    cpnScheme:
    {
        isActive: false,
        activeScheme: "DEFAULT"
    },
    inputs :
    {
        name :
        {
            message : "",
            class   : "",
            value   : "",
            valid   : true
        },

        website :
        {
            message : "",
            class   : "",
            value   : "",
            valid   : true
        },

        address :
        {
            street :
            {
                message : "",
                class   : "",
                value   : "",
                valid   : true
            },

            suite :
            {
                message : "",
                class   : "",
                value   : "",
                valid   : true
            },

            city :
            {
                message : "",
                class   : "",
                value   : "",
                valid   : true
            },

            state :
            {
                message : "",
                class   : "",
                value   : "",
                valid   : true
            },

            zip :
            {
                message : "",
                class   : "",
                value   : "",
            }
        },

        logo :
        {
            class : "",
            style :
            {
                backgroundImage : "url('')"
            }
        },

        cpnScheme:
        {
            isActive:
            {
                message : "",
                class   : "",
                value   : false,
                valid   : true

            },
            activeScheme: {
                message : "",
                class   : "",
                value   : "DEFAULT",
                valid   : true
            }
        },

        enforceTwoFa :
        {
            message : "",
            class   : "",
            value   : false,
            valid   : true
        },

        submit :
        {
            class : "disabled"
        }
    }
}
