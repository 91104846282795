import React, {Component} from "react";

export default class SingleProject extends Component
{
    constructor(props)
    {
        super(props)

        this.onInputChange = this.onInputChange.bind(this);
    }

    onInputChange(event)
    {
        let value = event.target.value;
        this.props.changeProject(parseInt(value));
    }

    render()
    {
        let project = this.props.project;
        let projectName = project.name;
        let projectId   = project.id;
        let markup =
                    <tr name={projectName}>
                        <td>
                            <div className="radio large">
                               <input
                                   type="radio"
                                   value={projectId}
                                   name={projectName}
                                   checked={this.props.selectedProject.id === projectId}
                                   onChange={(e) => this.onInputChange(e)}
                                   />
                             <label htmlFor="radio" />
                             <span htmlFor="radio">
                                 {projectName}
                             </span>
                           </div>
                        </td>
                    </tr>
        return markup
    }
}
