import React, {Component} from "react"
import Tooltip from 'rc-tooltip';

export default class MpnStatusIcon extends Component
{
    constructor(props)
    {
        super(props)
    }

    render()
    {
        let markup = ""
        let input  = this.props.input
        let r      = this.props.rowIndex
        let c      = this.props.inputIndex
        let isRowDisabled = this.props.isRowDisabled
        let isSelectedFromLibrary = this.props.isSelectedFromLibrary

        let mpnOverlay = this.props.mpnOverlay ? this.props.mpnOverlay : input.resMsg

        if(input.resStatus)
        {
          let iconClass = ""
          if(input.resStatus.toLowerCase() === "error")
          {
              iconClass =  "duro-icon-error-triangle"
          }
          else if(input.resStatus.toLowerCase() === "warning")
          {
              iconClass =  "duro-icon-warning-circle"
          }
          else if(input.resStatus.toLowerCase() === "validating")
          {
              iconClass =  ''
          }
          else
          {
              iconClass =  "duro-icon-confirm-circle"
          }

          if(iconClass == '' || (!this.props.mpnImportDialog && !['error','warning'].includes(input.resStatus.toLowerCase()))) {
            markup = null
          }
          else
          {

            let disabledClazz = ""
            let displayToolTipClazz = "hidden"
            
            if (input.resMsg)
            {
              displayToolTipClazz = ""
            }            

            if (input.disabled || isRowDisabled)
            {
                disabledClazz = " disabled"
                displayToolTipClazz = "hidden"

            }
            else if (isSelectedFromLibrary)
            {
                disabledClazz = " disabled selected-from-library"
                displayToolTipClazz = "hidden"
            }

            markup =
            <Tooltip
                placement="right"
                overlay={disabledClazz ? "" : mpnOverlay}
                overlayClassName={`error default-styles ${this.props.fileType === "new_assembly" ? 'new_assembly-rc-tooltip' : 'new-file-rc-tooltip'} ${displayToolTipClazz} ${this.props.includeUseExistingBtn ?  'with-existing-btn' : ''}`}
                arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
              >
                <icon
                    className={"ui-icon " + iconClass + disabledClazz}
                    data-tip={disabledClazz ? "" : mpnOverlay}
                    data-type={input.resStatus.toLowerCase()}
                    data-place="right"
                    data-for={r+"-icon-"+c}
                    custom-data-tip={this.props.customTipMsg}
                  />
              </Tooltip>
          }
          return markup
        }
        else
        {
            return null
        }
    }
}
