import "./index.css";
import { Tab, TabContext, TabList, TabPanel } from "common/components/tabs";
import { BuildTab } from "design/components/buildTab";
import { RevisionType } from "design/constants";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import URLSearchParams from "url-search-params";
import { BuildWorkspaceIcon } from "v1/assets/icons/buildWorkspaceIcon";
import ExpandIcon from "../../../../../assets/icons/expand-icon";
import GridIcon from "../../../../../assets/icons/grid-icon";
import TreeIcon from "../../../../../assets/icons/tree-icon";
import API from "../../../../../modules/api";
import Schemas from "../../../../../modules/schemas";
import Utils from "../../../../../modules/utils";
import InlineIcon from "../../../../ui/icon/inline-icon.js";
import Spinner from "../../../../ui/spinner";
import View from "../../../../ui/view";
import AssemblyGrid from "../../../common/assembly/view/grid";
import AssemblyTree from "../../../common/assembly/view/tree";
import Documents from "../../../common/documents/view";
import Reports from "../../../common/reports/view";
import SerializationTab from "../../../common/serialization-tab/view";
import SourcingRowView from "../../../common/sourcing/view/row-mode";
import Variants from "../../../common/variants/view";

export class Tabs extends View {
    constructor(props) {
        super(props)

        let { tabsType, isProductionBuildExist } = this.props;
        let view;
        let assemblyView = Utils.getStore("assemblyView") || "tree";

        if (tabsType === 'productionInstance') {
            this.objectData = this.props.productionInstance;
            view = Utils.getStore("production-instance-tab") || "documents";
            assemblyView = "tree"; // As Assembly Tab in Production Instance Landing Page has only Tree View.
        }
        else if (tabsType === "prd") {
            view = Utils.getStore("product-tab") || "assembly";
            if (view === "serialization" && !isProductionBuildExist) // If no production instance exist then do not show Serialization Tab
            {
                view = "assembly"
            }
            this.objectData = this.props.product;
        }
        else {
            this.objectData = this.props.component;
            view = Utils.getStore("component-tab") || "documents";
        }

        let isAssembly = true;
        if (tabsType === "cmp") {
            isAssembly = Schemas.component.category.getType(this.props.componentCategory).toLowerCase() === "assembly"

            if ((!isAssembly && view === "assembly")) {
                view = "documents"
            }
        }

        if (view === "variants" && !this.objectData.variantGroup) {
            view = "documents"
        }

        if (this.props.isDiffTool && (view === "reports" || view === "variants")) {
            view = "documents"
        }

        let urlParams = new URLSearchParams(window.location.search);
        let tab = urlParams.has('tab') ? urlParams.get('tab') : null;
        if (tab) {
            view = tab;
        }

        this.state =
        {
            variants: [],
            reports: [],
            view,
            isAssembly,
            assemblyView,
            syncSourcingRowTable: false,
            isAnyChildModified: false,
        }
        this.imagesWithSrc = [];
    }

    saveReportsInParent(reports) {
        let state = this.state
        state.reports = reports;
        this.setState(state)
    }

    saveVariantsInParent(variants) {
        let state = this.state
        state.variants = variants
        this.setState(state)
    }

    componentDidMount() {
        let { mode, tabsType, paramId, documents_loaded, children_loaded } = this.props;

        let current_view = this.state.view;
        let params = "documents";
        if (current_view == "sourcing" || current_view == "assembly") {
            params = "children";
        }
        if ((params == "documents" && !documents_loaded) ||
            (params == "children" && !children_loaded)) {
            if (mode === "revision" && params == "children") {
                params = `${params},assemblyRevision`
            }
            this.props.getNestedData(params);
        }

        if (tabsType !== 'productionInstance') {
            let model = tabsType === 'prd' ? 'products' : 'components';
            API[model].getChildrenInfo(paramId, { mode }, (err, res) => {
                if (res && res.success && res.data) {
                    this.setState({ isAnyChildModified: res.data.isAnyChildModified })
                }
            });
        }
    }

    show(_event, viewTab) {

        let { tabsType, documents_loaded, children_loaded, mode } = this.props;

        let params = "documents";
        if (viewTab == "sourcing" || viewTab == "assembly" || viewTab == "reports") {
            params = "children";
        }
        if (
            (params == "documents" && !documents_loaded) ||
            (params == "children" && !children_loaded)
        ) {
            if (mode === "revision" && params == "children") {
                params = `${params},assemblyRevision`
            }
            this.props.getNestedData(params);
        }

        let tab;
        if (tabsType === "prd") {
            tab = "product-tab";
        }
        else if (tabsType === "productionInstance") {
            tab = "production-instance-tab";
        }
        else {
            tab = "component-tab";
        }

        Utils.setStore(tab, viewTab);
        this.setState({ view: viewTab })
    }

    onPrimaryRowSelection(event, item) {
        if (event.target.checked) {
            this.state.primarySource =
            {
                minQuantity: item.quoteMinQuantity,
                unitPrice: item.quoteUnitPrice,
                leadTime: item.quoteLeadTimeValue,
                leadTimeUnit: item.quoteLeadTimeUnits,
                primarySourceId: item.quote_id,
                rolledUpCost: false
            }
        }
        else
            this.state.primarySource = undefined

        this.setState(this.state)
    }

    onRolledUpSelect(event, rolledUpCost) {
        if (Object.keys(rolledUpCost).length > 0 && event.target.checked) {
            this.state.primarySource =
            {
                minQuantity: rolledUpCost.rolledUpCost,
                unitPrice: rolledUpCost.totalPrice,
                leadTime: rolledUpCost.leadTimeValue,
                leadTimeUnit: rolledUpCost.leadTimeUnit,
                primarySourceId: "dummy",
                rolledUpCost: true
            };
        }
        else {
            this.state.primarySource = undefined;
        }

        this.state.syncSourcingRowTable = true;
        this.setState(this.state);
    }

    onPrimaryRowDeselection(event) {
        this.state.primarySource =
        {
            minQuantity: "",
            unitPrice: "",
            leadTime: "",
            leadTimeUnit: "",
            primarySourceId: "dummy"
        }
        this.props.setPrimarySourceInApi(this.state.primarySource)
    }

    toggleAssemblyTableView() {
        let state = this.state
        state.assemblyView = state.assemblyView == 'grid' ? 'tree' : 'grid'
        Utils.setStore("assemblyView", state.assemblyView)
        this.setState(state, window.changeRoutesWidth)
    }

    getAssemblyActionList() {
        let { tabsType } = this.props;
        let state = this.state;
        let actionsList = [];
        let actionEntry;
        let isTreeView = state.assemblyView === 'tree';

        if (tabsType !== "productionInstance") {
            actionsList.push({
                type: 'customEl',
                element: <div className="table-view-toggler flex-v">
                    <span
                        className={'tree-view action-item flex-v' + (!isTreeView ? ' active' : ' disabled')}
                        data-tip={'Tree View'}
                        onClick={!isTreeView ? this.toggleAssemblyTableView : null}
                    >
                        <InlineIcon
                            className={(!isTreeView ? '' : 'disabled ')}
                        >
                            <TreeIcon />
                        </InlineIcon>
                        <span
                            className="item-title"
                        >
                            {'Tree'}
                        </span>
                    </span>
                    <span
                        className={'grid-view action-item flex-v' + (isTreeView ? ' active' : ' disabled')}
                        data-tip={'Grid View'}
                        onClick={isTreeView ? this.toggleAssemblyTableView : null}
                    >
                        <InlineIcon
                            className={(isTreeView ? 'flex-v ' : 'disabled flex-v')}
                        >
                            <GridIcon />
                        </InlineIcon>
                        <span
                            className="item-title"
                        >
                            {'Grid'}
                        </span>
                    </span>
                </div>
            });
            actionsList.push({ type: 'divider' });
        }
        actionsList.push({ type: 'autofit' });

        if (isTreeView || tabsType === "productionInstance") {
            actionEntry =
            {
                type: 'action',
                iconSrc: <ExpandIcon />,
                toolTip: 'Expand all',
                name: 'Expand',
                onClick: this.expandTreeViewLevel,
                onDoubleClick: this.expandTreeView,
                active: true,
                iconClassName: "expand-icon",
                className: "tree-expand-icon"
            };
            actionsList.push(actionEntry);
            actionEntry =
            {
                type: 'action',
                iconSrc: <ExpandIcon />,
                toolTip: 'Collapse all',
                name: 'Collapse',
                onClick: this.collapseTreeView,
                active: true,
                iconClassName: "collapse-icon",
                className: "tree-collapse-icon"
            };
            actionsList.push(actionEntry);
        }

        return actionsList;
    }

    expandTreeView() {
        this.setState({
            collapseTreeView: 'expand'
        })
    }

    expandTreeViewLevel() {
        this.setState({
            collapseTreeView: 'expandLevel'
        })
    }

    collapseTreeView() {
        this.setState({
            collapseTreeView: 'collapse'
        })
    }

    afterToggleTreeView() {
        this.setState({
            collapseTreeView: undefined
        })
    }

    setPrimarySource() {
        if (this.state.primarySource) {
            this.props.setPrimarySourceInApi(this.state.primarySource)
            let id = this.props.paramId;
            Utils.setLocalStorageForAssemblyTree(id);
        }
    }

    afterSyncSourcingRowTable() {
        let state = this.state
        state.syncSourcingRowTable = false
        this.setState(state)
    }

    render() {
        let { tabsType, documents_loaded, children_loaded, isProductionBuildExist, paramId, massPrecision, user, mode } = this.props;
        const isBuildEnabled = (this.props?.ldClient?.allFlags())?.build ?? false;
        let view = this.state.view;
        let isAssembly = this.state.isAssembly;
        let assemblyView = this.state.assemblyView;
        let isLoading = ((!documents_loaded && view == "documents") || (!children_loaded && view == "assembly"));
        let isSerializationEnabled = window.__isSerializationEnabled && window.__libraryType === "GENERAL";
        let isRestrictedRole = window.__userRole !== "VENDOR";
        let showVariantTab = window.__userRole !== 'VENDOR' && this.objectData && this.objectData.variantGroup && !this.props.isDiffTool;
        const hasSourcing = tabsType !== "productionInstance" && isRestrictedRole;
        const hasVariants = showVariantTab && tabsType !== "productionInstance";
        const hasReports = tabsType === "prd" && this.props.mode !== "revision" && !this.props.isDiffTool;
        const hasSerialization = isProductionBuildExist && tabsType === "prd"
            && this.props.mode !== "revision" && !this.props.isDiffTool && isSerializationEnabled;
        const previousStatus = this.objectData?.previousStatus;
        let lastReleasedRev = tabsType === "cmp"
            ? this.props?.component?.lastReleaseCmpRev
            : this.props?.product?.lastReleasePrdRev;
        if (this.props.mode === "revision") {
            lastReleasedRev = paramId;
        }
        const gridName = tabsType === "cmp" ? "component" : "product";

        let showLotsTab = isBuildEnabled && previousStatus !== "DESIGN";
        const isReleased = this.objectData?.revisionType === RevisionType.CO_REVISION
            || this.objectData?.revisionType === RevisionType.RELEASE_REVISION;
        if (mode === "revision") {
            showLotsTab = isReleased;
        }

        if (view ===  "build" && !showLotsTab) {
            view = "documents";
        }
        return (
            <TabContext className="tabs-block">
                <TabList value={view} onChange={this.show}>
                    <Tab value="documents">Documents</Tab>
                    {hasSourcing && <Tab value="sourcing">Sourcing</Tab>}
                    {isAssembly && <Tab value="assembly">Assembly</Tab>}
                    {showLotsTab && <Tab value="build"><BuildWorkspaceIcon/><div className="nav-text ml-10">Build</div></Tab>}
                    {hasVariants && <Tab value="variants">Variants</Tab>}
                    {hasReports && <Tab value="reports">Reports</Tab>}
                    {hasSerialization && <Tab value="serialization">Serialization</Tab>}
                </TabList>
                {isLoading ? <Spinner /> : (<>
                    <TabPanel value="documents" currentValue={view}>
                        <Documents
                            documents={this.props.documents}
                            objectData={this.objectData}
                            location={this.props.location}
                            isDiffTool={this.props.isDiffTool}
                            targetRevDocuments={this.props.targetRevDocuments}
                            component={this.props.component}
                            initializePusher={true}
                            isProductionInstance={tabsType === "productionInstance"}
                        />
                    </TabPanel>
                    {hasSourcing && (
                        <TabPanel value="sourcing" currentValue={view}>
                            <SourcingRowView
                                tabsType={tabsType}
                                setPrimarySource={this.setPrimarySource}
                                primarySource={this.state.primarySource}
                                component={tabsType === "cmp" ? this.props.component : this.props.product}
                                dispatch={this.props.dispatch}
                                objectData={this.objectData}
                                isAssembly={isAssembly}
                                children={this.props.assemblyData}
                                allowPrimarySourceSelection={true}
                                onPrimaryRowSelection={this.onPrimaryRowSelection}
                                onPrimaryRowDeselection={this.onPrimaryRowDeselection}
                                onRolledUpSelect={this.onRolledUpSelect}
                                manufacturers={this.props.manufacturers}
                                syncSourcingRowTable={this.state.syncSourcingRowTable}
                                afterSyncSourcingRowTable={this.afterSyncSourcingRowTable}
                                mode={this.props.mode}
                                isDiffTool={this.props.isDiffTool}
                                targetRevManufacturers={this.props.targetRevManufacturers}
                                targetRevChildren={this.props.targetRevChildren}
                                defaultCurrency={this.props.defaultCurrency}
                            />
                        </TabPanel>
                    )}
                    {isAssembly && (
                        <TabPanel value="assembly" currentValue={view}>
                            {assemblyView === "grid" && (
                                <AssemblyGrid
                                    children={this.props.assemblyData}
                                    objectData={this.objectData}
                                    mode={this.props.mode}
                                    getIconsActionsList={this.getAssemblyActionList}
                                    location={this.props.location}
                                    tabsType={tabsType}
                                    imagesWithSrc={this.imagesWithSrc}
                                    isDiffTool={this.props.isDiffTool}
                                    targetRevChildren={this.props.targetRevChildren}
                                    defaultCurrency={this.props.defaultCurrency}
                                    isAnyChildModified={this.state.isAnyChildModified}
                                />
                            )}
                            {assemblyView === "tree" && (
                                <AssemblyTree
                                    children={this.props.assemblyData}
                                    objectData={this.objectData}
                                    mode={this.props.mode}
                                    getIconsActionsList={this.getAssemblyActionList}
                                    collapseTreeView={this.state.collapseTreeView}
                                    afterToggleTreeView={this.afterToggleTreeView}
                                    location={this.props.location}
                                    tabsType={tabsType}
                                    imagesWithSrc={this.imagesWithSrc}
                                    isDiffTool={this.props.isDiffTool}
                                    targetRevChildren={this.props.targetRevChildren}
                                    setAssemblyDiffCount={this.props.setAssemblyDiffCount}
                                    history={this.props.history}
                                    defaultCurrency={this.props.defaultCurrency}
                                    setAssemblyTree={this.props.setAssemblyTree}
                                    assemblyTree={this.props.assemblyTree}
                                    setAssemblyLoading={this.props.setAssemblyLoading}
                                    isAssemblyLoaded={this.props.isAssemblyLoaded}
                                    isAnyChildModified={this.state.isAnyChildModified}
                                />
                            )}
                        </TabPanel>
                    )}
                    {showLotsTab && (
                        <TabPanel value="build" currentValue={view}>
                            <BuildTab
                                id={paramId}
                                cpn={this.objectData.cpn}
                                gridName={gridName}
                                mode={this.props.mode}
                                lastReleasedRev={lastReleasedRev}
                                modified={mode === "revision" ? !isReleased : this.objectData.modified}
                            />
                        </TabPanel>
                    )}
                    {hasVariants && (
                        <TabPanel value="variants" currentValue={view}>
                            <Variants
                                massPrecision={massPrecision}
                                objectData={this.objectData}
                                getDataFromApi={this.props.getDataFromApi}
                                variants={this.state.variants}
                                saveVariantsInParent={this.saveVariantsInParent}
                                history={this.props.history}
                                updateVariantsTab={this.props.updateVariantsTab}
                                defaultCurrency={this.props.defaultCurrency}
                            />
                        </TabPanel>
                    )}
                    {hasReports && (
                        <TabPanel value="reports" currentValue={view}>
                            <Reports
                                children={this.props.assemblyData}
                                product={this.objectData}
                                getDataFromApi={this.state.reports.length === 0}
                                reports={this.state.reports}
                                saveReportsInParent={this.saveReportsInParent}
                                history={this.props.history}
                                user={user}
                                defaultCurrency={this.props.defaultCurrency}
                            />
                        </TabPanel>
                    )}
                    {hasSerialization && (
                        <TabPanel value="serialization" currentValue={view}>
                            <SerializationTab
                                objectData={this.objectData}
                                alias={"prd"}
                                user={user}
                                refreshSerializationTab={this.props.refreshSerializationTab}
                            />
                        </TabPanel>
                    )}
                </>)}
            </TabContext>
        );
    }
}

export default withLDConsumer({ clientOnly: true })(Tabs);
