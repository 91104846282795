import mapActions from '../helpers/mapActions';

export default mapActions([
    'SET_CURRENT_USER',
    'SETUP_USER',
    'CHECKED',
    'LOGOUT',
    'SET_IMAGE_OBJECT',
    'AUTHENTICATING',
    'SET_USER_PREFERENCES',
    'SAVE_USER_PREFERENCES',
    'SET_SHOW_HIDE_STATE_FOR_ASSEMBLY',
    'SET_SHOW_HIDE_STATE_FOR_DOCUMENT',
    'SAVE_ON_BOARDING_SAMPLE_PRODUCT_INFO',
    'SET_USER_ONBOARDING',
    'SAVE_ON_BOARDING_INFO_ON_API',
    "SWITCH_ENV",
    "REMOVE_USER_FROM_GROUP",
    "SAVE_USER_PREFERENCES_IN_LOCAL",
    "SET_QUICK_START_GUIDE_FLAG"
  ], 'USER');
