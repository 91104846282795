import React from "react"

function SvgComponent(props) {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        transform="translate(-9 -6) translate(5 4)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <path d="M0 0L16 0 16 16 0 16z" />
        <path
          d="M10.667 6v3.107l-2.334 2.34v1.22h-.666v-1.22L5.333 9.1V6h5.334m0-4H9.333v2.667H6.667V2H5.333v2.667h-.006A1.324 1.324 0 004 5.987v3.68L6.333 12v2h3.334v-2L12 9.66V6c0-.733-.6-1.333-1.333-1.333V2z"
          fill="#2A2A36"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
