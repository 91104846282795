import React from "react"

const SaveIcon = (props) => (
  <svg width={13} height={13} {...props}>
    <path
      d="M10.042.125h-8.5C.755.125.125.763.125 1.542v9.916c0 .78.63 1.417 1.417 1.417h9.916a1.42 1.42 0 0 0 1.417-1.417v-8.5L10.042.125Zm1.416 11.333H1.542V1.542h7.912l2.004 2.004v7.912ZM6.5 6.5c-1.176 0-2.125.95-2.125 2.125 0 1.176.95 2.125 2.125 2.125 1.176 0 2.125-.95 2.125-2.125 0-1.176-.95-2.125-2.125-2.125ZM2.25 2.25h6.375v2.833H2.25V2.25Z"
      fillRule="nonzero"
    />
  </svg>
)

export default SaveIcon
