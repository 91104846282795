import { gql } from "@apollo/client";

export const BaseProductionFragment = gql`
  fragment baseProductionFragment on BuildProduction {
    cpn
    id
    primaryRevision {
      id
      cpn
      revisionValue
      status
      lotCount
      instanceCount
      lastModifiedDate
      name
      source {
        extId
        type
        url
      }
    }
    isBookmarked
    revisionCount
    source {
      extId
      type
      url
    }
  }
`;

export const ProductionWithRevisions = gql`
  fragment productionWithRevisionsFragment on BuildProduction {
    ...baseProductionFragment
    revisions {
      id
      cpn
      revisionValue
      status
      lotCount
      images {
        id
      }
      instanceCount
      lastModifiedDate
      name
    }
  }
  ${BaseProductionFragment}
`;
