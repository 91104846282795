import                          "./index.css";
import React, { Component }     from 'react';
import Link                     from "../link";
import Icon                     from "../icon";
import plusIcon                 from "../../../assets/icons/header-nav-plus.svg";
import importIcon               from "../../../assets/icons/import-icon.svg";
import manualSrc                from "../../../assets/icons/newcomp-manual.svg";
import webSrc                   from "../../../assets/icons/newcomp-web.svg";
import CustomBlueToolTip        from "./../custom-blue-tooltips-for-on-boarding";
import { privatePaths }         from "../../../app/routes";
import NavTab                   from "./nav-tab.js";
import Permissions              from "../../../modules/schemas/permissions";
import ImportFromVendor         from "../../../components/page/component/new/web";
import ImportFromManual         from "../../../components/page/component/new/manual";
import ImportFromFile           from "../../../components/page/component/new/file/file-import-module";
import ImportFromValispace      from "../../../components/page/component/new/valispace";
import ValiSrc                  from "../../../assets/icons/valispace-icon.svg";

class ComponentsHeaderNav extends Component
{
    constructor(props, context) {
        super(props, context);
        this.toggleModal              = this.toggleModal.bind(this);
        this.onDoneFromCmpHeaderNav   = this.onDoneFromCmpHeaderNav.bind(this);
        this.state =
        {
            displayVendorModal: false,
            displayManualModal: false,
            displayFileImport: false,
            displayValispaceImport: false
        }
    }

    onDoneFromCmpHeaderNav(modalName)
    {
        this.setState({[modalName]: false}, this.props.getComponentListFromAPI());
    }

    toggleModal(modalName, modalValue)
    {
        this.setState({[modalName]: modalValue});
    }

    render()
    {

        let props = this.props;
        let allSearch    = "";
        let newCpmFile   = "";
        let newCpmWeb    = "";
        let newCpmManual = "";
        let navClass     = " top-padding-require";
        let selectedTab  = props.selectedTab;
        let showValispaceTab = (this.props.integrations ? this.props.integrations.length > 0 : false);

        switch(selectedTab)
        {
            case "allSearch" :
            {
                allSearch = "selected-tab";
                navClass = "";
                break;
            }

            case "newCpmFile" :
            {
                newCpmFile = "selected-tab";
                break;
            }

            case "newCpmWeb" :
            {
                newCpmWeb = "selected-tab";
                break;
            }

           case "newCpmManual" :
            {
                newCpmManual = "selected-tab";
                break;
            }

            default :
            {
                allSearch = "selected-tab";
            }
        }

        let cmpLabelTab = <NavTab tip=""
                             to={privatePaths.componentSearch}
                             linkClass={""}
                             className="cmp-nav-label"
                             iconSrc={plusIcon}
                             linkText="Components:"
                     />
        let fileTab = <NavTab tip="Import components from file"
                             to="#"
                             linkClass={newCpmFile}
                             className={'import-icon'}
                             iconSrc={importIcon}
                             linkText="Import from File"
                             onClick={(e) => {this.toggleModal("displayFileImport", true)}}
                     />

        let webTab = <NavTab tip="Create a new Component using its MPN to pull specs directly from vendor websites"
                             to="#"
                             linkClass={newCpmWeb + ' web-icon'}
                             iconSrc={webSrc}
                             linkText="Import from Vendor"
                             onClick={(e) => {this.toggleModal("displayVendorModal", true)}}
                     />

        let mnlTab = <NavTab tip="Manually Create a new Component Part or Assembly"
                             to="#"
                             linkClass={newCpmManual + ' hand-icon'}
                             iconSrc={manualSrc}
                             linkText="Create Manually"
                             onClick={(e) => {this.toggleModal("displayManualModal", true)}}
                     />

        let valiTab = showValispaceTab &&
                      <NavTab tip="Import components from valispace"

                             to="#"
                             linkClass={'hand-icon'}
                             iconSrc={ValiSrc}
                             className={'valispace-import-icon'}
                             linkText="Import from Valispace"
                             onClick={(e) => {this.toggleModal("displayValispaceImport", true)}}
                      />

        let fileTipTab = newCpmFile === "" ? fileTab
            :
            <CustomBlueToolTip placement="left" customClazz="right-arrow-tooltip">{fileTab}</CustomBlueToolTip>

        let webTipTab = newCpmWeb === "" ? webTab
            :
            <CustomBlueToolTip placement="left" customClazz="right-arrow-tooltip">{webTab}</CustomBlueToolTip>

        let markup =
            <div className={"components-header-nav" + navClass} >
                {Permissions.can("create", "component", props.user) && cmpLabelTab}
                {Permissions.can("create", "component", props.user) && fileTipTab }
                {Permissions.can("create", "component", props.user) && webTipTab }
                {Permissions.can("create", "component", props.user) && mnlTab}

                {Permissions.can("create", "component", props.user) && valiTab}

                {
                    this.state.displayVendorModal &&
                    <ImportFromVendor
                    onClose={this.toggleModal}
                    history={this.props.history}
                    />
                }
                {
                    this.state.displayManualModal &&
                    <ImportFromManual
                    onClose={this.toggleModal}
                    history={this.props.history}
                    />
                }
                {
                    this.state.displayFileImport &&
                    <ImportFromFile
                        showUiAlert={this.props.showUiAlert}
                        onClose={this.toggleModal}
                        displayImportFileFlag={true}
                        history={this.props.history}
                        onDoneFromCmpHeaderNav={this.onDoneFromCmpHeaderNav}
                        displayRefDesAndItemNumber={this.props.displayRefDesAndItemNumber}
                        />
                }

                {
                    this.state.displayValispaceImport &&
                    <ImportFromValispace
                        onClose={this.toggleModal}
                        onDoneFromCmpHeaderNav={this.onDoneFromCmpHeaderNav}
                    />
                }
            </div>
        return markup
    }
}

export default ComponentsHeaderNav
