import Utils from "../../../modules/utils"

export function getInitialState()
{
    let stateObj = {
        product             : null,
        modified            : false,
        isNewObject         : false,
        originalProductId   : null,
        inputs :
        {
            customSpecs: [],
            isUploadedThumbnails: true,
            inputChanged        : false,
            name :
            {
                value : "",
                valid : true
            },
            revision :
            {
                value : "",
                valid : true
            },
            status :
            {
                value : "",
                valid : true
            },
            eid :
            {
                value : "",
                valid : true
            },
            description :
            {
                value : "",
                valid : true
            },
            images :
            {
                value : [],
                valid : true
            },
            documents :
            {
                value : [],
                valid : true
            },
            children :
            {
                value : [],
                valid : true

            },
            manufacturers :
            {
                value : [],
                valid : true
            },
            team :
            [

            ],
            forecasts :
            [

            ],
            searchComponent :
            {
                results : []
            },
            cpnVariant :
            {
                class : "cpn-variant-field",
                value : "",
                valid : true
            },
            mass :
            {
                value : "",
                valid : true
            },
            cpn :
            {
                value : "",
                valid : true
            },
        }


    }
    return Utils.clone(stateObj)

}
export function newforecastInputs(forecastKey, counter)
{
    return {
        key :
        {
            value   : forecastKey,
            class   : "",
            valid   : true,
            message : ""
        },
        targetDate :
        {
            value   : "",
            class   : "",
            valid   : true,
            message : ""
        },
        volume :
        {
            value   : "",
            class   : "",
            valid   : true,
            message : ""
        },
        counter:
        {
            value: counter
        }
    }
}

let initialState = getInitialState()
export default initialState
