/**
 * TODO (PLM-6278): The component/file currently is just wrapping the old functionality used here.
 * It needs to be updated to using MUI components and make it so that the data isn't modified in way
 * that are hard to follow and can have unintended side effects.
 */

import { Box, Typography } from "@mui/material";
import { PageItemType } from "design/constants";
import Tooltip from "rc-tooltip";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ChangeOrderActions from "v1/action-types/changeorder";
import ComponentActions from "v1/action-types/component";
import CoIcon from "v1/assets/icons/co";
import EditIcon from "v1/assets/icons/edit";
import LinkIcon from "v1/assets/icons/open-link-icon";
import CTAIconToolbar from "v1/components/page/common/cta-icon-toolbar";
import EllipsisTooltip from "v1/components/page/common/ellipsis-tooltip";
import ErrorNotification from "v1/components/page/common/error-notification";
import VendorPillbox from "v1/components/page/component/components/vendor";
import InlineIcon from "v1/components/ui/icon/inline-icon";
import LazyInput from "v1/components/ui/lazy-input/input";
import Link from "v1/components/ui/link";
import buildAction from "v1/helpers/buildAction";
import Utils from "v1/modules/utils";
import {
  useComponentModalContext,
  useComponentRevisionContext,
  useComponentVariantContext,
} from "./componentModal";
import { TabError } from "./constants";
import {
  useEditChildren, useEditInputs, useEditObject,
  useGetViewComponentFromApi, useUser, useVariantConfig, useViewObject,
} from "./hooks";
import { useOnSubmit } from "./useOnSubmit";

function useSearch() {
  return useSelector((store: any) => store.search);
}

function useCanSave(assemblyErrors: number) {
  // proceed: (modified || childrenModified) && validated && childrenValidated && assemblyErrors === 0,
  //   proceedSaveAsRevision: validated && childrenValidated && assemblyErrors === 0,
  const isDataGood = useSelector((store: any) => {
    // Make sure there has been an edit.
    if (!(store.components.editPage.modified || store.assembly_edit.modified)) return false;

    // Make sure that the data is valid
    if (!Utils.isValidated(store.components.editPage.inputs)) return false;
    if (!Utils.isValidated(store.assembly_edit.children)) return false;

    return true;
  });

  return useMemo(() => isDataGood && assemblyErrors === 0, [isDataGood, assemblyErrors]);
}

function useCanSaveRevision(assemblyErrors: number, isNewObject: boolean) {
  const isDataGood = useSelector((store: any) => {
    // Make sure that the data is valid
    if (!Utils.isValidated(store.components.editPage.inputs)) return false;
    if (!Utils.isValidated(store.assembly_edit.children)) return false;

    return true;
  });

  const isRenderStatus = useSelector((store: any) => (
    store.components.editPage?.inputs?.status?.value === "DESIGN"
    && !Utils.getVendorLabel(store.components.editPage?.component)?.isVendorCmp
  ));

  return useMemo(() => ({
    canRenderRevision: isRenderStatus && !isNewObject,
    canSaveRevision: isDataGood && assemblyErrors === 0,
  }), [isDataGood, isNewObject, isRenderStatus, assemblyErrors]);
}

export interface ComponentHeaderProps {
  errorCounts: Record<TabError, number>;
  id: string;
  isEditing: boolean;
  isNewObject: boolean;
  openVariantModal: boolean;
  pageItemType: PageItemType;
  resetErrorCounts: () => void;
  setOpenVariantModal: (value: boolean) => void;
  setShowLoading: (value: boolean) => void;
}

export function ComponentHeader(props: ComponentHeaderProps) {
  const {
    errorCounts,
    id,
    isEditing,
    isNewObject,
    openVariantModal,
    pageItemType,
    resetErrorCounts,
    setOpenVariantModal,
    setShowLoading,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useUser();
  const viewObject = useViewObject(pageItemType);
  const editObject = useEditObject(pageItemType);
  const editInputs = useEditInputs(pageItemType);
  const getViewComponentFromApi = useGetViewComponentFromApi(id);
  const search = useSearch();
  const { reloadVariantData } = useComponentVariantContext();
  const canSave = useCanSave(errorCounts.assemblyTab);
  const { canRenderRevision, canSaveRevision } = useCanSaveRevision(errorCounts.assemblyTab, isNewObject);
  const { setRevisionComment, setRevisionInput } = useComponentRevisionContext();
  const { setShowSaveAsRevisionModal, setShouldBlockNavigation } = useComponentModalContext();
  const children = useEditChildren();

  const { cpnType, isCpnVariantEditable, isCpnVariantScheme } = useVariantConfig({ isCreating: isNewObject });
  const onSubmit = useOnSubmit({ isCpnVariantEditable, isCpnVariantScheme, isNewObject, pageItemType });

  const component = useMemo(() => (
    isEditing ? editObject : viewObject
  ), [editObject, isEditing, viewObject]);
  const { vendorLabel } = useMemo(() => Utils.getVendorLabel(component), [component]);
  const componentEditLink = useMemo(() => `/component/edit/${id}`, [id]);
  const disabledRevisionId = useMemo(() => {
    const revision = component.revisions.slice(-1)[0];
    if (revision) return revision;
    return null;
  }, [component]);
  const { isVendorCmp } = useMemo(() => Utils.getVendorLabel(component), [component]);
  const componentViewLink = useMemo(() => `/component/view/${id}`, [id]);
  const validated = useMemo(() => Utils.isValidated(editInputs), [editInputs]);
  const childrenValidated = useMemo(() => Utils.isValidated(children), [children]);
  const vendorTooltipText = useMemo(() => Utils.vendorTooltip(Utils.getVendor(component)), [component]);
  const nameDuplicateTooltip = useMemo(() =>
    Utils.makeDuplicateInputTooltip(editInputs.nameDuplicateOf, "Name"), [editInputs]);

  const gotoRevision = useCallback((compId, isMostRecent = false) => {
    if (isMostRecent) {
      const queryString = Utils.checkForRestrictedRole(user.role) && user.role !== "VENDOR"
        ? "?viewRecent=true" : "";
      history.push(`/component/view/${compId}${queryString}`);
    }
    else history.push(`/component/revision/${compId}`);
  }, [history, user]);

  const onOpenVariantModal = useCallback(() => setOpenVariantModal(true), [setOpenVariantModal]);
  const onCloseVariantModal = useCallback(() => setOpenVariantModal(false), [setOpenVariantModal]);

  const makeDuplicate = useCallback(() => {
    const payload = {
      componentId: component._id,
      history,
    };
    dispatch(buildAction(ComponentActions.MAKE_DUPLICATE, payload));
  }, [component, history, dispatch]);

  const toggleFavorite = useCallback(() => {
    const payload = {
      component,
      user,
    };
    dispatch(buildAction(ComponentActions.MARK_FAVOURITE, payload));
  }, [component, dispatch, user]);

  const updateComponent = useCallback(() => {
    dispatch(buildAction(ComponentActions.RESET_STATES_IN_VIEW_PAGE, {}));
    getViewComponentFromApi();
    setOpenVariantModal(true);
  }, [dispatch, getViewComponentFromApi, setOpenVariantModal]);

  const refreshItemViewRoute = useCallback(() => {
    dispatch(buildAction(ComponentActions.RESET_STATES_IN_VIEW_PAGE));
    getViewComponentFromApi();
    Utils.setLocalStorageForAssemblyTree(id);
  }, [dispatch, getViewComponentFromApi, id]);

  const addToChangeOrder = useCallback((data: any) => {
    dispatch(buildAction(ChangeOrderActions.CREATE_CO_WITH_DEFAULT_DATA, {
      addChildren: true,
      authorId: user._id,
      data: {
        ...data,
        children: {
          components: [data.children[0]._id],
        },
      },
      history,
    }));
  }, [dispatch, history, user]);

  const revertChanges = useCallback(() => {
    let componentId = viewObject.revisions[viewObject.revisions.length - 1];
    if (viewObject.revisions.length > 1) {
      componentId = viewObject.revisions[viewObject.revisions.length - 2];
    }

    dispatch(buildAction(ComponentActions.REVERT_CHANGES, {
      component: viewObject,
      componentId,
      history,
    }));
  }, [dispatch, history, viewObject]);

  const deleteComponent = useCallback(() => {
    setShowLoading(true);
    dispatch(buildAction(ComponentActions.DELETE_COMPONENT, {
      component: viewObject,
      history,
      viewRoute: true,
    }));
  }, [dispatch, history, setShowLoading, viewObject]);

  const createVariant = useCallback(componentId => {
    dispatch(buildAction(ComponentActions.CREATE_VARIANT, { componentId, history }));
  }, [dispatch, history]);

  const handleCancelEvent = useCallback(() => {
    resetErrorCounts();
    if (isNewObject) {
      history.goBack();
    }
    else {
      history.push({ pathname: componentViewLink });
    }
  }, [componentViewLink, history, isNewObject, resetErrorCounts]);

  const showHideSaveAsRevisionModal = useCallback(() => {
    const revisionValue = component?.nextCoRevision ?? "";
    setRevisionInput({ value: revisionValue, valid: true });
    setRevisionComment("");
    setShowSaveAsRevisionModal(true);
  }, [component, setRevisionComment, setRevisionInput, setShowSaveAsRevisionModal]);

  const totalErrorCount = useMemo(() => {
    let count = Object.values(errorCounts).reduce((l, r) => l + r);
    if (!editInputs.name.valid) count++;
    if (!editInputs.eid.valid) count++;
    if (!editInputs.revision.valid) count++;

    return count;
  }, [editInputs, errorCounts]);

  const getErrorsWarningMsg = useCallback(() => `${totalErrorCount} ${totalErrorCount > 1
    ? "errors" : "error"} found.<br>All errors must be resolved before saving.`, [totalErrorCount]);

  const onInputChange = useCallback((event: any, i: number) => {
    setShouldBlockNavigation({ block: true });
    const { name, value } = event.target;

    dispatch(buildAction(ComponentActions.UPDATE_EDIT_FORM_INPUT_STATE, {
      cpnType,
      index: i,
      name,
      value,
    }));
  }, [cpnType, dispatch, setShouldBlockNavigation]);

  const tooltipElement = useCallback(() => document.querySelector("#routes .tiles-wrapper"), []);

  return (
    <Box className="actions-block">
      {!isEditing && (
        <>
          {(component.archived || component.co) && (
            <Box className={`banner-block${component.archived ? " archived" : ""}`}>
              <Box className="app-row">
                {component.archived && (
                  <Box>
                    <Typography>This component has been deleted.</Typography>
                  </Box>
                )}
                {component.co && (
                  <Box>
                    <InlineIcon>
                      <CoIcon />
                    </InlineIcon>
                    <Typography>This component is in an unresolved change order</Typography>
                    <Link to={`/changeorder/view/${component.co._id}`}>{component.co.con}</Link>
                    <Typography>{`with Status: ${component.co.status}`}</Typography>
                  </Box>
                )}
              </Box>
            </Box>
          )}
          <Box className={`nav-block${component.archived ? " vendor " : " "}app-row`}>
            <Box className="header-left">
              <VendorPillbox
                className={component.co ? "not-editable" : ""}
                componentId={component._id}
                fromView={true}
                isAddedInCO={!!component.co}
                isComponentLinked={Utils.isComponentLinked(component.vendorInfo)}
                isVendorCmp={Utils.getOriginalVendor(component)}
                label={vendorLabel}
              />
              <EllipsisTooltip
                classes={"ellipsis-tooltip tooltip-no-width"}
                innerClasses={"truncate-name"}
                title={component.name}
              >
                {component.name}
              </EllipsisTooltip>
            </Box>
            <CTAIconToolbar
              addToChangeOrder={addToChangeOrder}
              closeVariantModal={onCloseVariantModal}
              createVariant={createVariant}
              deleteComponent={deleteComponent}
              disabledRevisionId={disabledRevisionId}
              dispatch={dispatch}
              getDataFromApi={true}
              gotoRevision={gotoRevision}
              history={history}
              item={component}
              itemEditLink={componentEditLink}
              itemName="component"
              makeDuplicate={makeDuplicate}
              openVariantModal={onOpenVariantModal}
              openVariantModalFlag={openVariantModal}
              refreshItemViewRoute={refreshItemViewRoute}
              revertChanges={revertChanges}
              search={search}
              toggleFavorite={toggleFavorite}
              updateComponent={updateComponent}
              updateVariantsTab={reloadVariantData}
              user={user}
              // deleteProduct={deleteProduct} // TODO (PLM-6230): This is part of product
              // duplicate={duplicate} // TODO (PLM-6230): This is part of product
              // updateProduct={updateProduct} // TODO (PLM-6230): This is part of product
            />
          </Box>
        </>
      )}
      {isEditing && (
        <>
          <div className="banner-block">
            <div className="app-row">
              <div>
                <InlineIcon><EditIcon /></InlineIcon>

                <div>You are currently in edit mode.</div>
              </div>
              <div>
                <input className="delete_btn" type="button" value="CANCEL" onClick={handleCancelEvent} />
                <input
                  disabled={!canSave}
                  className={canSave ? "" : "disabled"}
                  type="submit"
                  onClick={onSubmit}
                  value="SAVE"
                />
                {canRenderRevision && (
                  <button
                    disabled={!canSaveRevision}
                    className={`btn-black-theme ${canSaveRevision ? "" : "disabled"}`}
                    onClick={showHideSaveAsRevisionModal}
                  >
                    SAVE AS REVISION
                  </button>
                )}
              </div>
              {
                validated && childrenValidated ? ""
                  : <ErrorNotification getErrorsWarningMsg={getErrorsWarningMsg} />
              }
            </div>
          </div>
          <div
            className={`top-block app-row ${isVendorCmp ? "managed-by-vendor" : ""}`}
            style={{ paddingBottom: 0 }}
          >
            <VendorPillbox
              isComponentLinked={Utils.isComponentLinked(component.vendorInfo)}
              isVendorCmp={Utils.getOriginalVendor(component)}
              label={vendorLabel}
            />
            <div
              className="name-block"
              data-for="vendor-disable-input"
              data-place="left"
              data-tip={vendorTooltipText}
            >
              <Tooltip
                getTooltipContainer={tooltipElement}
                placement={"right"}
                overlay={nameDuplicateTooltip
                  ? <div>
                    <p>
                      <span className="link-text">{nameDuplicateTooltip.errorMessage}</span>
                      <br />
                      <Link
                        to={nameDuplicateTooltip.viewLink}
                        target="_blank"
                        className="open-link-holder white"
                      >
                        <span className="link-text">{nameDuplicateTooltip.linkMessage}
                          <InlineIcon >
                            <LinkIcon />
                          </InlineIcon>
                        </span>
                      </Link>
                    </p>
                  </div>
                  : ""
                }
                overlayClassName={"simple-rc-tip error"}
                visible={!!nameDuplicateTooltip}
              >
                <LazyInput
                  autoFocus={!isVendorCmp}
                  className={editInputs.name.class}
                  data-place="right"
                  data-tip={editInputs.name.message}
                  data-type="error"
                  name="name"
                  onChange={onInputChange}
                  timeout={600}
                  value={editInputs.name.value}
                />
              </Tooltip>
            </div>
          </div>
        </>
      )}
    </Box>
  );
}
