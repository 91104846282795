import React, { Component }     from 'react';
import { Link as RouterLink } from "react-router-dom"
import InlineIcon  from "../icon/inline-icon.js";
import LinkIcon from "../../../assets/icons/open-link-icon.js";

class ChangeOrderItem extends Component
{
    constructor(props)
    {
        super(props);
    }

    render(){
        let href = `/changeorder/view/${this.props.id}`;
        return (
            <div className='change-order-block'>
                <div className="change-order-holder">
                    <RouterLink className={''} href={href} to={href} target={this.props.target}>
                        <span className = "link">
                            {this.props.con}
                            {
                                this.props.target === '_blank' &&
                                <InlineIcon className="co-link-icon">
                                    <LinkIcon/>
                                </InlineIcon>
                            }
                        </span>
                    </RouterLink>

                    <span className={`status-label ${this.props.status} ${this.props.resolution ? this.props.resolution : ""}`}>
                        {this.props.status}
                    </span>
                </div>
            </div>
        )
    }
}

export default ChangeOrderItem;
