import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      height="31px"
      width="35px"
      fill="#fff"
      x="0px"
      y="0px"
      viewBox="7 0 48 48"
      {...props}
    >
      <style />
      <path d="M15 19c0-.6.4-1 1-1h12c.6 0 1 .4 1 1s-.4 1-1 1H16c-.6 0-1-.4-1-1zm1 7h12c.6 0 1-.4 1-1s-.4-1-1-1H16c-.6 0-1 .4-1 1s.4 1 1 1zm28 9.5c0 5.2-4.3 9.5-9.5 9.5-3.6 0-6.8-2-8.4-5H10.5C9.1 40 8 38.9 8 37.5v-31C8 5.1 9.1 4 10.5 4h17.2c.7 0 1.3.6 1.3 1.3v4.9c0 .4.3.8.8.8h4.9c.7 0 1.3.6 1.3 1.3V26.1c4.5.7 8 4.7 8 9.4zM25.3 38c-.2-.8-.3-1.6-.3-2.5 0-1.2.2-2.4.7-3.5H16c-.6 0-1-.4-1-1s.4-1 1-1h10.8c1.6-2.3 4.3-3.8 7.2-4V13h-4.2c-1.5 0-2.8-1.2-2.8-2.8V6H10.5c-.3 0-.5.2-.5.5v31.1c0 .3.2.5.5.5h14.8zM42 35.5c0-4.1-3.4-7.5-7.5-7.5S27 31.4 27 35.5s3.4 7.5 7.5 7.5 7.5-3.4 7.5-7.5zm-5-.5h-2v-2c0-.6-.4-1-1-1s-1 .4-1 1v3c0 .6.4 1 1 1h3c.6 0 1-.4 1-1s-.4-1-1-1z" />
    </svg>
  )
}

export default SvgComponent
