import { SvgIcon } from "@mui/material";

export const ComponentIconFilled = () => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="evenodd">
      {/* eslint-disable-next-line max-len */}
      <path d="M19.778 2a2.23 2.23 0 0 1 2.216 2.057l.006.165v15.556a2.23 2.23 0 0 1-2.057 2.216l-.165.006H4.222a2.23 2.23 0 0 1-2.216-2.057L2 19.778V4.222a2.23 2.23 0 0 1 2.057-2.216L4.222 2h15.556zm-2.222 13.333H6.444v2.223h11.112v-2.223zm-3.704-4.444H6.444v2.222h7.408V10.89zm0-4.445H6.444v2.223h7.408V6.444z"/>
    </g>
  </SvgIcon>
);
