import * as React from "react"

const SvgComponent = (props) => (
  <svg width={16} height={16} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.46 12h1.2V7.2h-1.2V12ZM8 5.72c.187 0 .343-.06.47-.18a.606.606 0 0 0 .19-.46.672.672 0 0 0-.19-.48.622.622 0 0 0-.47-.2.622.622 0 0 0-.47.2.672.672 0 0 0-.19.48c0 .187.063.34.19.46s.283.18.47.18ZM8 16a7.74 7.74 0 0 1-3.1-.63 8.114 8.114 0 0 1-2.55-1.72A8.114 8.114 0 0 1 .63 11.1 7.79 7.79 0 0 1 0 7.98c0-1.093.21-2.127.63-3.1.42-.973.993-1.82 1.72-2.54A8.2 8.2 0 0 1 4.9.63 7.79 7.79 0 0 1 8.02 0a7.74 7.74 0 0 1 3.1.63c.973.42 1.82.99 2.54 1.71s1.29 1.567 1.71 2.54c.42.973.63 2.013.63 3.12a7.74 7.74 0 0 1-.63 3.1 8.2 8.2 0 0 1-1.71 2.55 7.995 7.995 0 0 1-2.54 1.72A7.79 7.79 0 0 1 8 16Z"
      fill="#29B6F6"
      fillRule="nonzero"
    />
  </svg>
)

export default SvgComponent
