import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
    >
      <g fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fillRule="nonzero" transform="translate(-993 -92)">
          <g transform="translate(875 84)">
            <g transform="translate(110)">
              <path d="M15.5 8c4.142 0 7.5 3.198 7.5 7.143s-3.358 7.143-7.5 7.143c-4.142 0-7.5-3.198-7.5-7.143S11.358 8 15.5 8zm4.84 3.376l-8.796 8.376c2.455 1.916 6.084 1.782 8.375-.4 2.292-2.183 2.432-5.638.42-7.976zm-9.68 7.534l8.796-8.376c-2.455-1.916-6.084-1.783-8.375.4-2.292 2.182-2.432 5.638-.42 7.976z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
