import {
    takeEvery,
    takeLatest,
    all,
    call,
    put,
    delay,
    select,
    cps
}                                       from 'redux-saga/effects';
import UI                               from '../action-types/ui';
import USER_PROFILE                     from '../action-types/user_profile';
import USER                             from '../action-types/user';
import buildAction                      from '../helpers/buildAction';
import Schemas                          from '../modules/schemas';
import Utils                            from '../modules/utils';
import API                              from "../modules/api";
import validations, {validateField}     from '../modules/validations';
import config                           from '../modules/config';

// Private helper functions

function _getSubAccountsCount(inputs) {
    let count = Number(!!inputs.hasDuroAccount);
    count += Number(!!inputs.oauth2Google);

    return count;
}

function* _removeOAuth2Message(input, duration) {
    yield new Promise((resolve) => {
        setTimeout(() => input = '', 2000);
    })
}

export const userProfile = state => state.user_profile
export const userData = state => state.user
export function* onInputChange(action)
{
    try
    {
        let name   = action.payload.name
        let value  = action.payload.value
        let user_profile = yield select(userProfile)
        let inputs = user_profile.inputs
        let input  = Utils.hash(name, inputs)
        let user   = user_profile.user

        switch(name)
        {
            case "email" :
                user.email = value
                validateField(input, validations.user.email, value)
                if (input.valid && action.payload.currentlyLoggedInUserEmail.toLowerCase() !== value.toLowerCase())
                {
                    yield put(buildAction(USER_PROFILE.UPDATE_FORM_STATE, user_profile));
                    let payload = {email: input.value}
                    let response = yield cps(API.users.validateEmail, payload)
                    if (response.exist)
                    {
                        input.valid = false
                        input.message = "User already exists"
                        input.class = "invalid"
                        yield put(buildAction(USER_PROFILE.UPDATE_FORM_STATE, user_profile));
                    }
                    else if (!inputs.hasDuroAccount && !!inputs.oauth2Google.id)
                    {
                        // User can not change email if user is connected to only Google oauth2 account.
                        input.valid = false
                        input.message = "Changing your email will disconnect your account from google. Add a unique password before updating your email."
                        input.class = "invalid"
                        yield put(buildAction(USER_PROFILE.UPDATE_FORM_STATE, user_profile));
                    }
                }
                break

            case "role" :
                value = value.toUpperCase()
                user.role = value
                validateField(input, validations.user.role, value)
                break

            case "firstName" :
                user.firstName    = value
                validateField(input, validations.user.firstName, value)
                break

            case "lastName" :
                user.lastName    = value
                validateField(input, validations.user.lastName, value)
                break

            case "title" :
                user.title    = value
                validateField(input, validations.user.title, value)
                break

            case "twoFaEnabled" :
                user.twoFaConfiguration.twoFaEnabled = value
                break;

            case "twoFaSecret" :
                user.twoFaConfiguration.twoFaSecret = value
                break;

            case "phoneNumber" :
                user.phoneNumber    = value
                input.value         = value
                if (input.value.length > 15)
                {
                    input.message = "Should be a maximum of 15 characters"
                    input.class = "invalid"
                    input.valid = false
                }
                else
                {
                    input.message = ""
                    input.class = "valid"
                    input.valid = true
                }
                break

            case "password.old" :
                input.value = value
                delete user.password
                yield put(buildAction(USER_PROFILE.UPDATE_FORM_STATE, user_profile));
                try {
                    inputs.password.old.message = ""
                    inputs.password.old.class   = "valid"
                    inputs.password.old.valid   = true
                    yield put(buildAction(USER_PROFILE.UPDATE_FORM_STATE, user_profile));
                }
                catch(err){
                    inputs.password.old.message = "Incorrect password"
                    inputs.password.old.class   = "invalid"
                    inputs.password.old.valid   = false
                }
                break
            case "password.new":
                validateField(input, validations.user.password, value)
                break

            case "password.confirm" :
                console.log('password.old', inputs.password.old);
                console.log('password.new', inputs.password.new);

                // hasDuroAccount means that the user can sign in with password. If a user doesn't sign in with
                // a password, the old password field will always be valid.
                const oldPwdOk = (inputs.hasDuroAccount) ? inputs.password.old.valid && inputs.password.old.value : true;
                const newPwdOk = inputs.password.new.valid && inputs.password.new.value;
                if (!(oldPwdOk && newPwdOk)) {
                    break;
                }

                validateField(input, validations.user.password, value)
                if (input.valid)
                {
                    validateField(input, validations.user.password, value)
                    if (input.value !== inputs.password.new.value){
                        input.message = "Password mismatch"
                        input.class   = input.message ? "invalid" : ""
                        input.valid   = !input.message
                    }
                    if(input.valid)
                        user.password = input.value
                }
                break

            case "oauth2Google":
                inputs.oauth2Google.switchEnabled = value;
                inputs.oauth2Google.message = '';

                if (value) {
                    if (value !== !!user_profile.user.oauth2Google) {
                        // Linking an OAuth2 profile immediately connect the user to the consent page and
                        // start a new OAuth2 process.

                        window.location.href = `${config.API_ORIGIN}/auth/google/link`;
                    } else {
                        inputs.oauth2Google.message = 'You have already linked your Google OAuth2 profile';
                        yield put(buildAction(USER_PROFILE.UPDATE_FORM_STATE, user_profile));
                        yield delay(1500);
                        inputs.oauth2Google.message = '';
                        yield put(buildAction(USER_PROFILE.UPDATE_FORM_STATE, user_profile));
                    }
                }
                break;
            default :
            {
            }
        }
        let changed         = true
        let validated       = Utils.isValidated(inputs) && inputs.isUploadedImage
        inputs.submit.class = !changed || !validated ? "disabled" : ""
        yield put(buildAction(USER_PROFILE.UPDATE_FORM_STATE, user_profile));
    }
    catch(err)
    {
        yield put(buildAction(UI.SET_API_FAILED_RESPONSE_ERROR, err));
    }
}

export function* setUserProfileForm (action){
    try{

        const user                            = yield select(userData)
        const user_profile                    = yield select(userProfile)
        user_profile.user                     = {...user_profile.user, ...user.data}
        user_profile.inputs.password.old      = {message: "", class: "", value: "", valid: true}
        user_profile.inputs.password.new      = {message: "", class: "", value: "", valid: true}
        user_profile.inputs.password.confirm  = {message: "", class: "", value: "", valid: true}
        user_profile.inputs.email.value       = user_profile.user.email
        user_profile.inputs.firstName.value   = user_profile.user.firstName
        user_profile.inputs.lastName.value    = user_profile.user.lastName
        user_profile.inputs.title.value       = user_profile.user.title
        user_profile.inputs.role.value        = user_profile.user.role
        user_profile.inputs.twoFaSecret.value = user_profile.user.twoFaConfiguration.twoFaSecret
        user_profile.inputs.twoFaEnabled.value = user_profile.user.twoFaConfiguration.twoFaEnabled
        user_profile.inputs.phoneNumber.value = user_profile.user.phoneNumber
        user_profile.inputs.submit.class      = "disabled"
        user_profile.passwordBlock.class      = ""
        user_profile.inputs.oauth2Google      = {
            ...user_profile.user.oauth2Google,
            message: '',
            switchEnabled: !!user_profile.user.oauth2Google,
        };
        user_profile.inputs.hasDuroAccount    = user_profile.user.hasDuroAccount;
        user_profile.inputs.subAccountsCount = _getSubAccountsCount(user_profile.inputs);

        yield put(buildAction(USER_PROFILE.UPDATE_FORM_STATE, user_profile));
    }
    catch(err){
        yield put(buildAction(UI.SET_API_FAILED_RESPONSE_ERROR, err));
    }
}

export function* onSubmit (action){
    yield put(buildAction(UI.LOAD_START));
    const user_profile = yield select(userProfile);
    const user = yield select(userData);
    let currentlyLoggedInUserEmail = user.data.email.toLowerCase()

    try
    {
        // Calling unregister OAuth2 Google separately as update doesn't handle OAuth2.
        if (currentlyLoggedInUserEmail !== user_profile.user.email.toLowerCase() && !!user_profile.user.oauth2Google)
        {
                const params = { email: currentlyLoggedInUserEmail};
                yield cps(API.users.unregisterOAuth2Google, params);
        }

        let pro_user = user_profile.user

        if (user_profile?.inputs?.password?.new?.value) {
            // Plugin password is set independently
            pro_user.password = user_profile?.inputs?.password?.new?.value;
            yield cps(API.users.update, pro_user._id, pro_user);
        }
        else 
            yield cps(API.users.updateCoreApi, pro_user._id, Utils.getUserChanges(pro_user, user.data));
        if(pro_user.email !== user.data.email) location.reload()
        yield put(buildAction(USER.SETUP_USER));
        yield put(buildAction(USER_PROFILE.SET_USER_PROFILE_FORM));
    }
    catch(err){
        yield put(buildAction(UI.SET_API_FAILED_RESPONSE_ERROR, err));
        yield put(buildAction(UI.LOAD_END));
    }
}

export function* uploadImage (action){
    const user_profile = yield select(userProfile)
    let inputs = user_profile.inputs
    user_profile.user.avatar = action.payload.avatar
    user_profile.inputs.isUploadedImage = action.payload.isUploadedImage

    if (action.payload.imageuploadErrors.length > 0)
    {
        let errors = []
        let errorsArray = action.payload.imageuploadErrors
        errors.push({ message: "Error in uploading following files:" })
        errorsArray.forEach(function(error, index)
        {
            errors.push({ message: "("+ (index+1) + ") " + error.file })
        })
        errors.push(errorsArray[0].errors[0])
        yield put(buildAction(UI.SHOW_ALERT, {type: "errors", errors: errors, donotThrowError: true}))
    }

    if (Utils.isValidated(inputs) && user_profile.inputs.isUploadedImage)
    {
        user_profile.inputs.submit.class = ""
    }
    else
    {
        user_profile.inputs.submit.class = "disabled"
    }

    yield put(buildAction(USER_PROFILE.UPDATE_FORM_STATE, user_profile));
}

export default function*(getState)
{
    yield all([
        takeLatest(USER_PROFILE.ON_INPUT_CHANGE, onInputChange),
        takeEvery(USER_PROFILE.SET_USER_PROFILE_FORM, setUserProfileForm),
        takeEvery(USER_PROFILE.ON_SUBMIT, onSubmit),
        takeEvery(USER_PROFILE.UPLOAD_IMAGE, uploadImage),
        ]);
}
