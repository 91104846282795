import React, {Component} from "react";

export default class SingleWorkspace extends Component
{
    constructor(props)
    {
        super(props)

        this.onInputChange = this.onInputChange.bind(this);
    }

    onInputChange(event)
    {
        let {value, name} = event.target;
        this.props.changeWorkspace(name, parseInt(value));
    }

    render()
    {
        let workspace = this.props.workspace;
        let workspaceName = workspace.name;
        let workspaceId   = workspace.id;
        let markup =
                    <tr name={workspaceName}>
                        <td>
                            <div className="radio large">
                               <input
                                   type="radio"
                                   value={workspaceId}
                                   name={workspaceName}
                                   checked={this.props.selectedWorkspace.id === workspaceId}
                                   onChange={(e) => this.onInputChange(e)}
                                   />
                             <label htmlFor="radio" />
                             <span htmlFor="radio">
                                 {workspaceName}
                             </span>
                           </div>
                        </td>
                    </tr>
        return markup
    }
}
