import React                from 'react';
import ModalBox             from "../modal-box"

const RevertBackModal = (props) =>
{
    const {
        components,
        products,
        onCancel,
        onConfirm,
    } = props;
    return  (
        <div className="new-warning-modal revert-back-modal">
            <ModalBox onClose={onCancel}>
                <h1>Are you sure you want to revert back?</h1>
                <div className="warning-text">
                    <p>All modified changes will be lost and will revert back to its last Revision. This can't be undone.</p>
                </div>
                {(!!components || !!products) && <div className="items-list">
                    {
                        components.map(({name, cpn, _id}) =>
                        {
                            let block =
                                <div key={_id + "-deleted-items-list"} className="item-row">
                                    <span className="muted-text">{cpn + " "}</span>
                                    <span>{name}</span>
                                </div>
                            return block
                        })
                    }

                    {
                        products.map(({name, cpn, _id}) =>
                        {
                            let block =
                                <div key={_id + "-deleted-items-list"} className="item-row">
                                    <span className="muted-text">{cpn + " "}</span>
                                    <span>{name}</span>
                                </div>
                            return block
                        })
                    }
                </div>}
                <div className="bottom-section">
                    <div className="button-sections">
                        <button
                            type="button"
                            onClick={onCancel}
                            >
                        No
                        </button>

                        <button
                            type="button"
                            onClick={onConfirm}
                            className="filled">
                        Yes
                        </button>
                    </div>
                </div>
            </ModalBox>
        </div>
    )
}

export default RevertBackModal
