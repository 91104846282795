import { SvgIcon } from "@mui/material";

export const EditIconChangeOrder = () => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="evenodd">
      <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM5.92 19H5v-.92l9.06-9.06.92.92L5.92
      19zM20.71 5.63l-2.34-2.34c-.2-.2-.45-.29-.71-.29-.26 0-.51.1-.7.29l-1.83 1.83 3.75 3.75
      1.83-1.83a.996.996 0 0 0 0-1.41z" fillRule="nonzero" />
    </g>
  </SvgIcon>
);
