import { useMemo } from "react";
import { ApolloClient, FetchPolicy, gql, NormalizedCacheObject, useQuery } from "@apollo/client";
import { Component } from "design/models";
import { ComponentFragment, ComponentWithChildrenFragment } from "graphql/fragment/componentFragment";
import { getSubErrors } from "v1/modules/utils/errors";
import { QuerySingleArgs } from "./common";

export const GET_COMPONENT = gql`
  query($id: ID) {
    componentsByIds(ids: [$id]) {
      ...componentFragment
    }
  }
  ${ComponentFragment}
`;

export const GET_COMPONENTS = gql`
  query($ids: [ID!]!) {
    componentsByIds(ids: $ids) {
      ...componentFragment
    }
  }
  ${ComponentFragment}
`;

export const GET_COMPONENT_WITH_CHILDREN = gql`
  query componentsWithChildrenById($id: ID) {
    componentsByIds(ids: [$id]) {
      ...componentWithChildrenFragment
    }
  }
  ${ComponentWithChildrenFragment}
`;

interface ComponentQueryData {
  componentsByIds?: Component[] | null;
}

/**
 * Loads up the component without the information about it's children.
 *
 * @param fetchPolicy How to fetch the data from the API.
 * @param id Id of the component to load.
 * @param skip When true the query isn't run.
 * @returns The component, when loaded, errors, and the loading state.
 */
export function useComponent({ fetchPolicy = "cache-first", id, skip }: QuerySingleArgs) {
  const { data, error, loading } = useQuery<ComponentQueryData>(GET_COMPONENT, {
    fetchPolicy,
    skip,
    variables: { id },
  });

  const component = useMemo(() => data?.componentsByIds?.[0], [data?.componentsByIds]);

  return { component, componentError: error, componentLoading: loading };
}

/**
 * Loads up the component
 *
 * @param client The apollo client used to load information from.
 * @param id Id of the component to load.
 * @param fetchPolicy How to fetch the data from the API.
 * @returns The component and errors.
 */
export async function getComponent(
  client: ApolloClient<NormalizedCacheObject>,
  id: string,
  fetchPolicy: FetchPolicy = "cache-first",
) {
  try {
    const { data, errors } = await client.query<ComponentQueryData>({
      query: GET_COMPONENTS,
      variables: { ids: [id] },
      fetchPolicy,
    });
    const component = data?.componentsByIds?.[0];
    return { component, componentError: errors };
  }
  catch (e: any) {
    return {
      component: null,
      errors: getSubErrors(e),
      loading: false,
    };
  }
}

/**
 * Loads up the components
 *
 * @param client The apollo client used to load information from.
 * @param ids Ids of the components to load.
 * @param fetchPolicy How to fetch the data from the API.
 * @returns The components and errors.
 */
export async function getComponents(
  client: ApolloClient<NormalizedCacheObject>,
  ids: string[],
  fetchPolicy: FetchPolicy = "cache-first",
) {
  try {
    const { data, errors } = await client.query<ComponentQueryData>({
      query: GET_COMPONENTS,
      variables: { ids },
      fetchPolicy,
    });
    const components = data?.componentsByIds;
    return { components, componentError: errors };
  }
  catch (e: any) {
    return {
      components: null,
      errors: getSubErrors(e),
      loading: false,
    };
  }
}

/**
 * Loads up the component and it's children.
 *
 * @param client The apollo client used to load information from.
 * @param id Id of the component to load.
 * @param fetchPolicy How to fetch the data from the API.
 * @returns The component and errors.
 */
export async function getComponentWithChildren(
  client: ApolloClient<NormalizedCacheObject>,
  id: string,
  fetchPolicy: FetchPolicy = "cache-first",
) {
  try {
    const { data, errors } = await client.query<ComponentQueryData>({
      query: GET_COMPONENT_WITH_CHILDREN,
      variables: { id },
      fetchPolicy,
    });
    const component = data?.componentsByIds?.[0];
    return { component, componentError: errors };
  }
  catch (e: any) {
    return {
      component: null,
      errors: getSubErrors(e),
      loading: false,
    };
  }
}

/**
 * Loads up the component and it's children.
 *
 * @param id Id of the component to load.
 * @param fetchPolicy How to fetch the data from the API.
 * @returns The component when loaded, errors, and the loading state.
 */
export function useComponentWithChildren(id: string, fetchPolicy: FetchPolicy = "cache-first") {
  const { data, error, loading, refetch } = useQuery<ComponentQueryData>(GET_COMPONENT_WITH_CHILDREN, {
    variables: { id },
    fetchPolicy,
  });

  const component = useMemo(() => data?.componentsByIds?.[0], [data?.componentsByIds]);

  return { component, componentError: error, componentLoading: loading, componentRefetch: refetch };
}

/**
 * Marks components based on the ID's as dirty in the cache and causing them to be re-fetched.
 *
 * @param client The apollo client used to reload the components.
 * @param ids Ids of the components to reload.
 * @returns Promise that will resolve when the refetching is done.
 */
export async function refetchComponents(
  client: ApolloClient<NormalizedCacheObject>,
  ids: string[],
) {
  await client.refetchQueries({
    updateCache(cache) {
      ids.forEach(id => {
        cache.evict({ id: "ROOT_QUERY", fieldName: `componentsByIds({"ids":["${id}"]})` });
        cache.evict({ id: `Component:${id}` });
      });
    },
  });
}
