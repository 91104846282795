import { ComponentType, useMemo } from "react";
import { BaseTile, BaseTilesGrid, BaseTilesRow } from "./baseTile";
import { OverviewIcon } from "assets/icons";
import { TileContentText } from "./tileContentText";
import { TileContentDivider } from "./tileContentDivider";
import { TileContentDirection } from "./consts";
import { TileContentLink } from "./tileContentLink";
import { TileContentStatus } from "./tileContentStatus";
import { TileContentAction } from "./tileContentAction";
import { TileContentDial } from "./tileContentDial";

export enum TileIcon {
  OVERVIEW = "OVERVIEW"
}

export enum TileContentType {
  ACTION = "ACTION",
  CUSTOM = "CUSTOM",
  DIAL = "DIAL",
  DIVIDER = "DIVIDER",
  LINK = "LINK",
  LINK_IF_VALUE = "LINK_IF_VALUE",
  PARAGRAPH = "PARAGRAPH",
  STATUS = "STATUS",
  TEXT = "TEXT",
}

export enum TileValueFormat {
  DATE = "DATE",
  DEFAULT = "DEFAULT",
  COMBINE = "COMBINE"
}

const icons = {
  [TileIcon.OVERVIEW]: OverviewIcon,
};

export interface TileContentConfigProps {
  config: TileContentConfiguration;
  data: any;
}

export interface TileContentConfiguration {
  component?: ComponentType<TileContentConfigProps>;
  label?: string;
  labelPath?: string;
  label2?: string;
  label2Path?: string;
  label3?: string;
  label3Path?: string;
  onClickPath?: string;
  type: TileContentType;
  value?: string | number;
  valueFormat?: TileValueFormat;
  valueFormatSep?: string;
  valuePath?: string | string[];
}

export interface TileConfiguration {
  content?: TileContentConfiguration[];
  icon?: TileIcon;
  imageAlt?: string;
  imageId?: string;
  imageIdPath?: string;
  pinned?: boolean;
  title?: string;
}

/**
 * Uses the supplied path to transverse the data object and returns the requested property.
 *
 * @param path Period delimited path of the object properties and array elements to transverse.
 * @param data The data to pull the property from.
 *
 * @returns The property, or undefined if it does not exist.
 */
function propFromPath(path: string, data: any): any {
  const parts = path.split(".");
  let resp = data;
  parts.forEach(p => {
    if (!p) return;
    resp = resp?.[p];
  });
  return resp;
}

/**
 * This pulls the value information out of the path. This can be a single path or an array of paths.
 * Under the hoods this uses `propFromPath` but handles the possibility of an array of paths.
 *
 * @param data The data to pull the path(s) from.
 * @param path A single period delimited path or an array of them.
 *
 * @returns The property or properties pointed at from the path.
 */
function valueFromPath(data: any, path?: string | string[]): any | any[] {
  if (!path || !path.length) return null;

  if (typeof path === "string") return propFromPath(path, data);

  if (Array.isArray(path)) return path.map(p => propFromPath(p, data));

  return null;
}

function TileConfigContent({ data, config }: TileContentConfigProps) {
  const label = useMemo(() => (
    (config.labelPath && propFromPath(config.labelPath, data)) ?? config.label
  ), [config.label, config.labelPath, data]);

  const label2 = useMemo(() => (
    (config.label2Path && propFromPath(config.label2Path, data)) ?? config.label2
  ), [config.label2, config.label2Path, data]);

  const label3 = useMemo(() => (
    (config.label3Path && propFromPath(config.label3Path, data)) ?? config.label3
  ), [config.label3, config.label3Path, data]);

  const onClick = useMemo(() => (
    (config.onClickPath && propFromPath(config.onClickPath, data))
  ), [config.onClickPath, data]);

  const value = useMemo(() => {
    const v = (config.valuePath && valueFromPath(data, config.valuePath)) ?? config.value;
    switch (config.valueFormat) {
      case TileValueFormat.COMBINE:
        return (v && Array.isArray(v) && v.join(config.valueFormatSep)) || v;
      case TileValueFormat.DATE:
        return v && new Date(v).toDateString();
      case TileValueFormat.DEFAULT:
      default:
        return v;
    }
  }, [config.value, config.valueFormat, config.valueFormatSep, config.valuePath, data]);

  switch (config.type) {
    case TileContentType.ACTION: {
      return <TileContentAction buttonLabel={label2} label={label} onClick={onClick} />;
    }
    case TileContentType.CUSTOM: {
      const Content = config.component;
      return Content ? <Content config={config} data={data} /> : null;
    }
    case TileContentType.DIAL: {
      return <TileContentDial label={label} legendOther={label2} legendPercent={label3} percent={value} />;
    }
    case TileContentType.DIVIDER:
      return <TileContentDivider />;
    case TileContentType.LINK: {
      return <TileContentLink linkLabel={label2} label={label} onClick={onClick} url={value} />;
    }
    case TileContentType.LINK_IF_VALUE: {
      if (value) return <TileContentLink linkLabel={label2} label={label} onClick={onClick} url={value} />;
      return <TileContentText label={label} text={label2} />;
    }
    case TileContentType.PARAGRAPH:
      return <TileContentText direction={TileContentDirection.COLUMN} label={label} text={value} />;
    case TileContentType.STATUS:
      return <TileContentStatus status={value} />;
    case TileContentType.TEXT:
      return <TileContentText label={label} text={value} />;
    default:
      // Unknown tile content type, don't render anything.
      return null;
  }
}

export interface TileProps {
  config: TileConfiguration;
  data: any;
}

export function Tile({ config, data }: TileProps) {
  const { content, icon, imageAlt, pinned, title } = config;

  const TitleIcon = useMemo(() => icon && icons[icon], [icon]);

  const imageId = useMemo(() => (
    (config.imageIdPath && propFromPath(config.imageIdPath, data)) ?? config.imageId
  ), [config.imageId, config.imageIdPath, data]);

  return (
    <BaseTile
      icon={TitleIcon && <TitleIcon />}
      imageAlt={imageAlt}
      imageId={imageId}
      pinned={pinned}
      title={title}
    >
      {content?.map((c, index) => <TileConfigContent data={data} config={c} key={index} />)}
    </BaseTile>
  );
}

export interface TilesRowProps {
  className?: string;
  configs: TileConfiguration[];
  data: any;
}

export function TilesRow({ className, configs, data }: TilesRowProps) {
  return (
    <BaseTilesRow className={className}>
      {configs.map((tc, index) => <Tile config={tc} data={data} key={index} />)}
    </BaseTilesRow>
  );
}

export interface TilesGridProps {
  config: TileConfiguration;
  tilesData: any[];
}

export function TilesGrid({ config, tilesData }: TilesGridProps) {
  return (
    <BaseTilesGrid>
      {tilesData.map((data, index) => <Tile config={config} data={data} key={index} />)}
    </BaseTilesGrid>
  );
}
