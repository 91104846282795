import React      from "react";
import SourcingUtils from "../../common/sourcing/sourcing-utils"

const CurrencyField = (props) =>
{
    let {symbol, value} = props;
    let unitPricePrecision = window.__unitPricePrecision
    const price = isNaN(Number(value))
        ? Number(0).toFixed(unitPricePrecision)
       : SourcingUtils.numberWithCommas(SourcingUtils.autoCorrectPriceDecimalPlaces(value));

    return (
        `${symbol}${price}`
    )
}

export default CurrencyField;
