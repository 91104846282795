import { TextField, styled } from "@mui/material";
import { InputFieldVariants } from "common/constants";
import { HTMLInputTypeAttribute, forwardRef, useMemo } from "react";
import { FieldTitle } from "./fieldTitle";

export interface InputFieldStyleProps {
  marginBottom?: string,
  width?: string,
}

export interface InputFieldProps {
  componentStyles?: InputFieldStyleProps,
  defaultValue?: string | number | null,
  disabled?: boolean,
  error?: boolean,
  InputProps?: InputProps,
  inputStyles?: InputStyleProps,
  isRequired?: boolean,
  label?: string,
  labelStyling?: InputLabelStyleProps,
  name?: string,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  placeholder?: string,
  type?: HTMLInputTypeAttribute,
  value?: string | number | null,
  variant?: InputFieldVariants,
}

export interface InputProps {
  endAdornment?: React.ReactNode,
}

export interface InputStyleProps {
  style?: {
    backgroundColor?: string,
    borderRadius?: string,
    padding?: string,
    paddingTop?: string,
  }
}

export interface InputLabelStyleProps {
  color?: string,
}

export const InputField = forwardRef<HTMLDivElement, InputFieldProps>((props, ref) => {
  const {
    isRequired = false,
    label,
    labelStyling,
    placeholder = "",
    ...passthrough
  } = props;

  const _fieldTitle = useMemo(() => (
    label && (
      <FieldTitle
        isRequired={isRequired}
        label={label}
        sx={{
          fontSize: "0.75rem",
          marginBottom: "0.5rem",
          ...labelStyling,
        }}
      />
    )
  ), [isRequired, label, labelStyling]);

  return (
    <>
      {_fieldTitle}
      <CustomInput
        placeholder={placeholder}
        ref={ref}
        required={isRequired}
        {...passthrough}
      />
    </>
  );
});

const propsToBeSkipped: string[] = ["componentStyles", "inputStyles"];
const CustomInput = styled(TextField, {
  shouldForwardProp: prop => !propsToBeSkipped.includes(prop as string),
})<Pick<InputFieldProps, "componentStyles" | "inputStyles">>(({
  componentStyles,
  inputStyles,
  theme,
}) => ({
  "& .Mui-disabled": {
    backgroundColor: theme.duro.filledInput.backgroundColor,
  },
  "& .MuiFilledInput-input": {
    paddingTop: inputStyles?.style?.paddingTop ?? "0.313rem",
  },
  "& .MuiInputBase-root": {
    backgroundColor: inputStyles?.style?.backgroundColor ?? theme.duro.filledInput.backgroundColor,
  },
  marginBottom: componentStyles?.marginBottom ?? "0",
  width: componentStyles?.width ?? "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: inputStyles?.style?.borderRadius,
  },
  "& .MuiOutlinedInput-input": {
    padding: inputStyles?.style?.padding,
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
    margin: 0,
  },
}));
