import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 42 42"
      xmlSpace="preserve"
    >
      <path d="M42 19L23 19 23 0 19 0 19 19 0 19 0 23 19 23 19 42 23 42 23 23 42 23z"></path>
    </svg>
  );
}

export default Icon;
