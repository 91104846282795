import Utils                    from "../../../../modules/utils"

class SourcingUtils
{
    static getRolledUpCostFromChildren(assemblies, isRevision=false)
    {
        let data = {}
        data.totalPrice          = 0
        data.leadTimeValue       = 0
        data.leadTimeUnit        = 'DAYS'
        data.leadTimeValueInDays = 0
        data.minQuantity         = -999
        data.allAreValid         = true
        data.inCompleteCost      = false


        assemblies.forEach((child, i) =>
        {
            // Unit Price = Sum of all children "Total Price" cells
            // Lead Time = Longest Lead Time in children
            // Min Quantity = Minimum of all children "Min Quantity" cells

            // child.component.primarySource.leadTime.value !== null && child.component.primarySource.leadTime.units && child.component.primarySource.leadTime.value + " " + child.component.primarySource.leadTime.units


            // child.component.primarySource.leadTime.value !== null

            let item = isRevision ? child.assemblyRevision : child.component


            if (item && item.inCompleteCost === true)
            {
                data.inCompleteCost = true
            }

            //TODO zaeem: Need to look into this condition for child.component.primarySource 31-august-2020
            if  (item && item.primarySource && item.primarySource.leadTime.value !== null &&
                  item.primarySource.unitPrice !== null )
            {

                let childPrimarySource = item.primarySource
                let leadTimeInDays = Utils.stringValueToDays(childPrimarySource.leadTime.value, childPrimarySource.leadTime.units)

                let totalPrice = child.quantity * childPrimarySource.unitPrice

                data.totalPrice += totalPrice

                if (leadTimeInDays > data.leadTimeValueInDays)
                {
                    data.leadTimeValueInDays = leadTimeInDays
                    data.leadTimeValue       = childPrimarySource.leadTime.value
                    data.leadTimeUnit        = childPrimarySource.leadTime.units
                }

                if (data.minQuantity === -999)
                {
                    data.minQuantity = child.quantity
                }
                if (child.quantity < data.minQuantity)
                {
                    data.minQuantity = child.quantity
                }
            }
            else{
                data.allAreValid = false
            }

        })

        if (data.minQuantity === -999)
        {
            data.minQuantity = '0'
        }
        data.minQuantity = 1

        //Note: No need to compare this flag in diff-tool
        if (isRevision)
        {
            delete data.inCompleteCost
            delete data.allAreValid
        }
        return data
    }

    static setPrimarySourceIfRolledUpSourceIsPrimary(data)
    {
        let primarySource = data.primarySource;
        return primarySource;
    }

    static primarySource(component)
    {
        let source = null;
        if (component.manufacturers && component.manufacturers.length) {
            let data = SourcingUtils.manufacturersToRowData(component.manufacturers);
            data.forEach((mnf, i) => {
                if (mnf.isPrimary) {
                    source = mnf;
                }
            });
        }
        return source;
    }

    static getPrimarySourceMpnAndMfr(component)
    {
        let data = {};
        data.mpn = component && component.primarySource && component.primarySource.mpn ? component.primarySource.mpn : '';
        data.manufacturer = component && component.primarySource && component.primarySource.manufacturer ? component.primarySource.manufacturer : '';
        return data;

    }

    static addSelectedFieldToExistingQuotesProps(manufacturers, selectedQuotes)
    {
        manufacturers.forEach((man, i) =>
        {
            if (man.distributors.length > 0)
            {
                man.distributors.forEach((dist, i) =>
                {
                    if (dist.quotes.length > 0)
                    {
                        dist.quotes.forEach((quote, i) =>
                        {
                            quote.selectedRow = false;
                            selectedQuotes.forEach((row, i) => {
                                if (man.mpn.key === row.MPN && dist.dpn.key === row.DPN && quote._id === row.quote_id) {
                                    quote.selectedRow = true;
                                }
                            })
                        })
                    }

                })
            }
        })

        return manufacturers;
    }

    static handleNotExistQuotes(manufacturer) {

        if (manufacturer.distributors.length > 0)
        {
            manufacturer.distributors.forEach((dist, i) =>
            {
                if (dist.quotes.length > 0)
                {
                    dist.quotes.forEach((quote, i) =>
                    {
                        if ((quote.inputs && quote.inputs.isChecked.value) || quote.selectedRow) {
                            quote.modified = false;
                            quote.unitPriceState = "worsened";
                            quote.lastUpdated = Date.now();
                            let dateTime = Utils.dateTimeWithLongFormat(quote.lastUpdated);
                            if (quote.inputs && quote.inputs.lastUpdated) {
                                quote.inputs.lastUpdated.value = `${dateTime.dateValue} ${dateTime.timeValue}`;
                            }
                        }
                    })
                }

            })
        }
    }

    static getFilteredData(manufacturers, quote_id)
    {
        let emptyManufacturerQuotes = [];
        manufacturers.forEach((man, i) =>
        {
           if (man.distributors.length > 0)
           {
               man.distributors.forEach((dist, i, distObject) =>
               {
                    if (dist.quotes.length > 0)
                    {
                        dist.quotes.forEach((quote, i, object) =>
                        {
                            quote.isValid = true;
                            if (quote_id && quote._id === quote_id) {
                               quote.isValid = false;
                            } else if (quote.unitPriceState === "worsened" && quote_id === null) {
                                quote.isValid = false;
                            }
                        })
                        // filter valid quotes...
                        dist.quotes = dist.quotes.filter((item) => item.isValid === true );
                    }
                    if (dist.quotes.length === 0) dist.shouldBeRemoved = true;
               })
                man.distributors = man.distributors.filter((item) => !item.shouldBeRemoved);
           }
            if (man.distributors.length === 0) man.shouldBeRemoved = true;
        })
        manufacturers = manufacturers.filter((item) => !item.shouldBeRemoved);
        return manufacturers;
    }

    static getClearedData(manufacturers, quote_id)
    {
        manufacturers.forEach((man, i) =>
           {
               if (man.distributors.length > 0)
               {
                   man.distributors.forEach((dist, i) =>
                   {
                       if (dist.quotes.length > 0)
                       {
                           dist.quotes.forEach((quote, i, object) =>
                           {
                               if ((quote_id && quote._id === quote_id) ||
                                    (quote.unitPriceState === "worsened" && quote_id === null))
                               {
                                   if(quote.inputs) quote.inputs.isChecked.value = false;
                                   // quote.selectedRow = false;
                                   quote.unitPriceState = "";
                               }
                           })
                       }
                   })
               }
           })

        return manufacturers;
    }

    static checkViewQuoteState(apiData, quote) {
        let APIUnitPrice = Number(apiData.quoteUnitPrice);
        let quoteUnitPrice = Number(quote.unitPrice);
        if (APIUnitPrice === quoteUnitPrice) {
            quote.unitPriceState = "noChange";
        }
        else if ( APIUnitPrice > quoteUnitPrice) {
            // add icon of price went up ...
            quote.unitPrice = APIUnitPrice;
            // quote.inputs.unitPrice.value = APIUnitPrice;
            quote.unitPriceState = "up";
        } else if (APIUnitPrice < quoteUnitPrice) {
            // add icon of price went down ...
            quote.unitPrice = APIUnitPrice;
            // quote.inputs.unitPrice.value = APIUnitPrice;
            quote.unitPriceState = "down";
        }

        if (apiData.quoteLeadTimeValue === quote.leadTime.value) {
            quote.leadTimeState = "noChange";
        }
        else if ( apiData.quoteLeadTimeValue > quote.leadTime.value) {
            // add icon of leat Time went up ...
            quote.leadTime.value = apiData.quoteLeadTimeValue;
            // quote.inputs.leadTime.value = apiData.quoteLeadTimeValue;
            quote.leadTimeState = "up";
        } else if (apiData.quoteLeadTimeValue < quote.leadTime.value) {
            // add icon of lead time went down ...
            quote.leadTime.value = apiData.quoteLeadTimeValue;
            // quote.inputs.leadTime.value = apiData.quoteLeadTimeValue;
            quote.leadTimeState = "down";
        }

        let apiLeadTimeUnit = apiData.quoteLeadTimeUnits;
        let quoteLeadTimeUnit = quote.leadTime.units;
        if (quote.leadTimeState === "noChange") {
            if (apiLeadTimeUnit === quoteLeadTimeUnit) {
                quote.leadTimeState = "noChange";
            } else {
                if (["DAYS", "DAY"].includes(apiLeadTimeUnit)) {
                    quote.leadTimeState = "down";
                } else if (["WEEKS", "WEEK"].includes(apiLeadTimeUnit)) {
                    quote.leadTimeState = "up";
                }
                quote.leadTime.units = apiLeadTimeUnit;
            }
        } else {
            quote.leadTime.units = apiLeadTimeUnit;
        }
    }

    static checkQuoteState(apiData, quote)
    {
        let APIUnitPrice = Number(SourcingUtils.precisePriceValue(apiData.quoteUnitPrice));
        let quoteUnitPrice = Number(quote.unitPrice);
        if (APIUnitPrice === quoteUnitPrice) {
            quote.unitPriceState = "noChange";
        }
        else if ( APIUnitPrice > quoteUnitPrice) {
            // add icon of price went up ...
            quote.unitPrice = APIUnitPrice;
            quote.inputs.unitPrice.value = SourcingUtils.precisePriceValue(APIUnitPrice);
            quote.unitPriceState = "up";
        } else if (APIUnitPrice < quoteUnitPrice) {
            // add icon of price went down ...
            quote.unitPrice = APIUnitPrice;
            quote.inputs.unitPrice.value = SourcingUtils.precisePriceValue(APIUnitPrice);
            quote.unitPriceState = "down";
        }

        if (apiData.quoteLeadTimeValue === quote.leadTime.value) {
            quote.leadTimeState = "noChange";
        }
        else if ( apiData.quoteLeadTimeValue > quote.leadTime.value) {
            // add icon of leat Time went up ...
            quote.leadTime.value = apiData.quoteLeadTimeValue;
            quote.inputs.leadTime.value = apiData.quoteLeadTimeValue;
            quote.leadTimeState = "up";
        } else if (apiData.quoteLeadTimeValue < quote.leadTime.value) {
            // add icon of lead time went down ...
            quote.leadTime.value = apiData.quoteLeadTimeValue;
            quote.inputs.leadTime.value = apiData.quoteLeadTimeValue;
            quote.leadTimeState = "down";
        }

        let apiLeadTimeUnit = apiData.quoteLeadTimeUnits;
        let quoteLeadTimeUnit = quote.leadTime.units;
        if (quote.leadTimeState === "noChange") {
            if (apiLeadTimeUnit === quoteLeadTimeUnit) {
                quote.leadTimeState = "noChange";
            } else {
                if (["DAYS", "DAY"].includes(apiLeadTimeUnit)) {
                    quote.leadTimeState = "down";
                } else if (["WEEKS", "WEEK"].includes(apiLeadTimeUnit)) {
                    quote.leadTimeState = "up";
                }
                quote.leadTime.units = apiLeadTimeUnit;
            }
        } else {
            quote.leadTime.units = apiLeadTimeUnit;
        }
    }

    static getPrimarySourceQuote(manufacturers)
    {
        let data = {};
        manufacturers.forEach((man, i) =>
        {
            man.distributors.forEach((dist, i) =>
            {
                dist.quotes.forEach((quote, i) => {
                    if(quote.isPrimary) data = {quote, dist, man};
                })
            });
        });
        return data;
    }

    static setPrimarySourceQuote(manufacturers, updatedPrimaryQuote)
    {
        for (let man of manufacturers)
        {
            for(let dist of man.distributors)
            {
                for(let quote of dist.quotes)
                {
                    if(quote.isPrimary)
                    {
                        // SourcingUtils.checkQuoteState(updatedPrimaryQuote, quote);
                        quote.leadTime.value = updatedPrimaryQuote.leadTime.value;
                        quote.leadTime.units = updatedPrimaryQuote.leadTime.units;
                        quote.maxQuantity = updatedPrimaryQuote.maxQuantity;
                        quote.minQuantity = updatedPrimaryQuote.minQuantity;
                        quote.unitPrice   = updatedPrimaryQuote.unitPrice;
                        if(updatedPrimaryQuote.unitPriceState)
                        {
                            quote.unitPriceState = updatedPrimaryQuote.unitPriceState
                        }
                    }
                }
            }
        }
        return manufacturers;
    }

    static getPrimarySourceQuoteDetails(man, source)
    {
        let primaryQuote = null;
        // for (let man of manufacturers)
        // {
            for(let dist of man.distributors)
            {
                for(let quote of dist.quotes)
                {
                    if(quote.isPrimary)
                    {
                        primaryQuote = {mpn: man.mpn.key, dpn: dist.dpn.key, minQuantity: quote.minQuantity};
                    }
                }
            }
        // }
        for(let distributor of source.distributors)
        {
            for(let quote of distributor.quotes)
            {
                if(primaryQuote && source.mpn.key === primaryQuote.mpn && (Number(quote.minQuantity) === primaryQuote.minQuantity) && distributor.dpn.key === primaryQuote.dpn)
                {
                    return quote;
                }
            }
        }
        return null;
    }


    static manufacturersToRowData(manufacturers, cmpLastModified=null, ignoreEmptyDistributors=false, isDiffTool=false)
    {
        let data = []
        manufacturers.forEach((man, i) =>
        {
            let manIndex = i;
            let datasheetSrc = Utils.getDataSheetLinkToDisplay(man.datasheet);
            if (man.distributors.length > 0)
            {
                man.distributors.forEach((dist, i) =>
                {
                    let distIndex = i;
                    if (dist.quotes.length > 0)
                    {
                        dist.quotes.forEach((quote, i) =>
                        {
                            let quoteIndex = i;
                            if (quote.lastUpdated == null) {
                                quote.lastUpdated = cmpLastModified;
                            }


                            data.push({
                                mfrName             : man.name,
                                mfrMpnSrc           : man.mpn.src,
                                mfrMpnKey           : man.mpn.key,
                                mfrDescription      : man.description,
                                mfrDatasheetSrc     : datasheetSrc,

                                distName            : dist.name,
                                distDpnSrc          : dist.dpn.src,
                                distDpnKey          : dist.dpn.key,
                                distDescription     : dist.description,
                                distPackageType     : dist.package.type,
                                distPackageQuantity : dist.package.quantity,

                                quoteMinQuantity    : quote.minQuantity,
                                quoteUnitPrice      : quote.unitPrice,
                                quoteUnitPriceState : quote.unitPriceState,
                                quoteLeadTimeState  : quote.leadTimeState,
                                quoteLastUpdated    : quote.lastUpdated,
                                quoteLeadTimeValue  : quote.leadTime.value,
                                quoteLeadTimeUnits  : quote.leadTime.units,
                                isPrimary           : isDiffTool ? "" : quote.isPrimary,
                                quote_id            : quote._id,
                                quoteSelectedRow    : quote.selectedRow,
                                quoteUniqueIdentifier : isDiffTool ? "" : `man${manIndex} dist${distIndex} quote${quoteIndex}`,
                                gId : `${man._id}${dist._id}${quote._id}`
                            })
                        }) // end dist.quotes.forEach((quote, i) =>
                    } // if (dist.quotes.length > 0)
                    else
                    {
                        data.push({
                            mfrName             : man.name,
                            mfrMpnSrc           : man.mpn.src,
                            mfrMpnKey           : man.mpn.key,
                            mfrDescription      : man.description,
                            mfrDatasheetSrc     : datasheetSrc,

                            distName            : dist.name,
                            distDpnSrc          : dist.dpn.src,
                            distDpnKey          : dist.dpn.key,
                            distDescription     : dist.description,
                            distPackageType     : dist.package.type,
                            distPackageQuantity : dist.package.quantity,
                            gId: `${man._id}${dist._id}`

                        })
                    } // else (dist.quotes.length > 0)
                }) // end man.distributors.forEach((dist, i) =>
                SourcingUtils.createWarrantyFields(data, man, isDiffTool, i);
            } // end if (man.distributors.length > 0)
            else{

                data.push({
                    mfrName          : man.name,
                    mfrMpnSrc        : man.mpn.src,
                    mfrMpnKey        : man.mpn.key,
                    mfrDescription   : man.description,
                    mfrDatasheetSrc  : datasheetSrc,
                    gId: `${man._id}`
                })
                SourcingUtils.createWarrantyFields(data, man, isDiffTool, i);
            } // end else (man.distributors.length > 0)

        }) // end manufacturers.forEach((man, i) =>

        return data
    }

    static createWarrantyFields(data, man, isDiffTool, i)
    {
        if(SourcingUtils.isWarrantyFieldEnabled(isDiffTool))
        {
            data[i]['mfrWarranty'] = man.warranty.value;
            data[i]['mfrWarrantyUnits'] = man.warranty.units;
        }
    }

    static haveInValidCosting(object)
    {
        return object.inCompleteCost
    }

    static autoCorrectPriceDecimalPlaces (value) {

        let unitPricePrecision = window.__unitPricePrecision;
        const fraction = value.toString().split('.')[1]
        const decimalPlaces = fraction ? fraction.length : 0
        return (decimalPlaces !== unitPricePrecision)
            ? Number(value).toFixed(unitPricePrecision) : value;

    }

    static numberWithCommas(value)
    {
        var parts=value.toString().split(".");
        return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
    }

    static setPrimarySrc(primarySourceId, rolledUpCost, obj)
    {
        if(!obj) return;
        obj.rolledUpCostAsPrimary = rolledUpCost;
        for(let manufacturer of obj.manufacturers)
        {
            for(let distributor of manufacturer.distributors)
            {
                for(let quote of distributor.quotes)
                {
                    if(quote._id === primarySourceId)
                    {
                        quote.isPrimary = true;
                    }
                    else
                    {
                        quote.isPrimary = false;
                    }
                }
            }
        }
        return obj;
    }

    static precisePriceValue(value) {
        const unitPricePrecision = window.__unitPricePrecision;
        return Number(value ?? 0).toFixed(unitPricePrecision);
    }

    static isWarrantyFieldEnabled(isDiffTool)
    {
        if(window.__customFields && window.__customFields.warrantyFieldEnabled && !isDiffTool) return true;
        return false;
    }

    static unitPricePlaceHolder()
    {
        let unitPricePrecision = window.__unitPricePrecision;
        return unitPricePrecision ? Number(0).toFixed(unitPricePrecision) : Number(0).toFixed(4);
    }
}

export default SourcingUtils
