import React, { Component }      from "react"

const SvgIcon = () =>
{
    return <svg width="15px" height="12px" viewBox="0 0 15 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" fillRule="evenodd">
        <g id="1-hide-toolbar" transform="translate(-323.000000, -175.000000)"  fillRule="nonzero">
            <g id="MinMax" transform="translate(323.500000, 175.000000)">
                <rect id="Rectangle-3" x="0" y="10" width="14" height="1.5" rx="0.75"></rect>
                <rect id="Rectangle-3" x="0" y="5" width="14" height="1.5" rx="0.75"></rect>
                <rect id="Rectangle-3" x="0" y="0" width="14" height="1.5" rx="0.75"></rect>
            </g>
        </g>
    </g>
</svg>
}

export default SvgIcon
