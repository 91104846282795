import Utils   from "../../utils"
import Schemas from "../../schemas"

export default class ServiceAPI
{
    static createXlsx(data, cb)
    {
        Utils.post("/services/files/create/xlsx", data , (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static createCsv(data, cb)
    {
        Utils.post("/services/files/create/csv", data , (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static mailXlsx(data, cb)
    {
        Utils.post("/services/mail/export/xlsx", data , (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static mailCsv(data, cb)
    {
        Utils.post("/services/mail/export/csv", data , (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static exportFile(data, cb)
    {
        Utils.post("/services/files/export", data , (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static recordSearch(data)
    {
        Utils.post("/search/record_stats", data)
    }

    static resendConfirmation(data, cb)
    {
        Utils.post("/services/mail/resend-confirmation", data , (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static saveTemplate(data, cb)
    {
        Utils.post("/services/files/saveTemplate", data , (err, res) =>
        {
            if(err)
            {
                return cb(err);
            }

            cb(null, res.data);
        })
    }

    static updateTemplate(data, cb)
    {
        let id = data.id;
        Utils.post("/services/files/updateTemplate/" + id, data , (err, res) =>
        {
            if(err)
            {
                return cb(err);
            }

            cb(null, res.data);
        })
    }

    static removeTemplate(data, cb)
    {
        Utils.post("/services/files/removeTemplate", data , (err, res) =>
        {
            if(err)
            {
                return cb(err);
            }

            cb(null, res.data);
        })
    }

    static getUserTemplates(id, cb)
    {
        Utils.get("/services/files/getExportTemplates/" + id, (err, res) =>
        {
            if(err)
            {
                return cb(err);
            }

            cb(null, res.data);
        })
    }

    static exportTemplateNameExists(data, cb)
    {
        Utils.post("/services/files/isTemplateNameExists", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            if(!res.success)
            {
                return cb(res)
            }

            return cb(null, res.data)
        })
    }

    static getTwoFaRegisteration(data, cb)
    {
        Utils.post("/services/two-fa/register-two-fa", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }
            cb(null, res.data)
        });
    }

    static setTwoFaSecretKey(data, cb)
    {
        Utils.post("/services/two-fa/set-secret-key", data, (err, res) =>
        {
            if(err)
            {
                return cb(err);
            }
            cb(null, res.data);
        })
    }
   
    static saveNewCoTemplate(data, cb){
        Utils.post("/services/files/saveCoApprovalTemplate", data , (err, res) => {
            if(err) return cb(err);
            cb(null, res.data);
        })
    }

    static updateExistingCoTemplate(data, cb){
        let id = data.id;
        Utils.post("/services/files/updateCoApprovalTemplate/" + id, data , (err, res) => {
            if(err) return cb(err);
            cb(null, res.data);
        })
    }

    static getCoTemplate(cb, includeApprovers=false) {
        Utils.get(`/services/files/getCoApprovalTemplate?includeApprovers=${includeApprovers}`, (err, res) => {
            if(err) return cb(err);
            cb(null, res.data);
        })
    }

    static removeCoApprovalTemplates(data, cb){
        Utils.post("/services/files/removeCoApprovalTemplates", data, (err, res) => {
            if(err) return cb(err);
            cb(null, true);
        })
    }

    static isCoTemplateNameExist(data, cb) {
        Utils.post("/services/files/isCoTemplateNameExist", data, (err, res) => {
            if(err) return cb(err)
            if(!res.success) return cb(res)
            return cb(null, res.data)
        })
    }
    static updateLastUsedCoTemplate(data, cb) {
        Utils.post("/services/files/updateLastUsedCoTemplate/" + data, null, (err, res) => {
            if(err) return cb(err)
            if(!res.success) return cb(res)
            return cb(null, res.data)
        })
    }

    static createCoTemplateMappings(data, cb) {
        Utils.post("/services/files/createCoTemplateMappings", data, (err, res) => {
            if(err)
                return cb(err, null)
            cb(null, res);
        })
    }

    static getCompanyCoTemplateMappings(cb) {
        Utils.get("/services/files/getCompanyCoTemplateMappings", (err, res) =>
        {
            if(err)
                return cb(err)
            cb(null, res.data)
        })
    }

    static updateCoTemplateMappings(data,cb) {
        Utils.post("/services/files/updateCoTemplateMappings", data, (err, res) => {
            if(err) return cb(err)
            if(!res.success) return cb(res)
            return cb(null, res.data)
        })
    }

}
