import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="68"
      viewBox="0 0 90 68"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#3CD1B5" transform="translate(-676 -236)">
          <g transform="translate(-2 -20)">
            <path d="M760.5 265.402l-5.801 10.602c-.2.301-.5.5-.801.5h-.102c-.3 0-.601-.199-.8-.398l-5-7c-.301-.5-.2-1.102.199-1.398.5-.301 1.101-.2 1.398.199l4 5.699 5-9.2c.3-.5.899-.698 1.399-.398.609.293.808.895.507 1.395zm7.5 4.297c0 7.102-5.399 12.898-12.2 13.7v37.5c0 1.5-1.199 2.698-2.699 2.698l-72.402.004c-1.5 0-2.699-1.199-2.699-2.699v-49.5c0-.5.2-1 .398-1.398 0-.2.102-.301.2-.5.101-.102.199-.2.398-.2.5-.398 1-.601 1.602-.601h59.8C741 261.601 747 256 754.2 256c7.601 0 13.8 6.2 13.8 13.699zm-86.7 1l35.602 35.602 27.301-27.3c-2.101-2.2-3.398-5.102-3.601-8.302H681.3zm72.5 12.801c-3.101-.101-5.898-1.199-8.199-3l-28 28c-.199.2-.5.301-.699.301-.2 0-.5-.102-.7-.3L680 272.3v48.602c0 .399.3.7.699.7h72.398c.398 0 .7-.301.7-.7l.003-37.403zm12.2-13.8c0-6.5-5.301-11.802-11.802-11.802-6.5 0-11.8 5.301-11.8 11.801s5.3 11.801 11.8 11.801c6.5 0 11.801-5.3 11.801-11.8z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
