import Utils from "../../utils"

class CompanyAPI
{
    static getCurrent(cb)
    {
        Utils.get("/companies/current", (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static update(id, data, cb)
    {
        if(data.logo instanceof File === true)
        {
            return Utils.readFile(data.logo, (err, logo) =>
            {
                if(err)
                {
                    return cb(err)
                }

                data.logo = logo

                Utils.post("/companies/" + id, data, (err, res) =>
                {
                    if(err)
                    {
                        return cb(err)
                    }

                    cb(null, res.data)
                })
            })
        }

        Utils.post("/companies/" + id, data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }
}

export default CompanyAPI
