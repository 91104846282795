export function getInitialState()
{
    return {
      co: null,
      inputs :
      {
          types :
          {
              value : "",
              valid : false
          },
          name :
          {
              value : "",
              valid : false
          },
          description :
          {
              value : "",
              valid : true
          },
          submit :
          {
              class : "disabled"
          },
          erpOptions : {
            effectivity : {
                overrideExisting : {
                    checked : false,
                    valid : true
                },
                isEnabled : {
                    checked : false,
                    valid : true
                },
                endDate : {
                    value : "",
                    class : "",
                    valid : true,
                    message : ""
                },
                startDate : {
                    value : "",
                    class : "",
                    valid : true,
                    message : ""
                }
            },
            itemType : {
                overrideExisting : {
                    checked : false,
                    valid : true
                },
                isEnabled : {
                    checked : false,
                    valid : true
                },
                value : {
                    value : "",
                    valid : true
                }
            }
        }  
      },

      list :
      [

      ],
      childrenIdAndStatus : [
        
      ],
      validationRunning: false,
      reloadExtendedTable : false,
      parentsData : [],
      childrenData : [],
      unChangedChildren: [],
      searchResults: [],
      search :
      {
          value               : "",
          selections          : [],
          inputs              : [],
          results             : [],
          makeCustomSearch    : false,
          selectAllFlag       : false,
          parentAssemblyFlag  : false,
          childrenAssemblyFlag: false,
          parentsLoading      : false,
          childrenLoading     : false,
          selectAllButoonClazz: ""
      }
    }
}

let initialState = getInitialState()
export default initialState
