import validator        from "validator"
import Utils            from "../utils"
import API              from "../api"
import Assembly         from "./assembly"
import { schemas }      from '../validations';


const productSchema =
{
    name :
    {
        //TODO: Need to clean this code after PLM-2791 completion

        // normalize(value)
        // {
        //     return Utils.toParagraphString(value)
        // },

        // validate(value, cb)
        // {
        //     if(!cb)
        //     {
        //         if(typeof value !== "string" || value.trim() === "")
        //         {
        //             return "Invalid name"
        //         }

        //         return ""
        //     }

        //     if(typeof value !== "string" || value.trim() === "")
        //     {
        //         return cb("Invalid name")
        //     }

        //     API.products.nameExists(value, (err, exists) =>
        //     {
        //         if(exists)
        //         {
        //             return cb("This product name already exists in your library.")
        //         }

        //         cb("")
        //     })
        // }
    },

    cpn :
    {
        normalize(value)
        {
            return value
        },

        validate(value, cb)
        {
            if(!cb)
            {
                if(typeof value !== "string" || value.trim() === "")
                {
                    return "Invalid CPN"
                }

                return ""
            }

            if(typeof value !== "string" || value.trim() === "")
            {
                return cb("Invalid CPN")
            }

            API.products.findByCpn(value, (err, cmp) =>
            {
                if(cmp)
                {
                    if(cmp.co)
                    {
                        return cb("Component is in an open change order")
                    }

                    return cb("")
                }

                cb("Invalid CPN")
            })
        }
    },

    sku :
    {
        normalize(value)
        {
            return value
        },

        validate(value)
        {
            return ""
        }
    },

    status :
    {
        list(currentStatus='')
        {
            return Utils.getStatusesList(currentStatus)
        }
    },

    revision :
    {
        normalize(value, status)
        {
            if(value === "") return ""

            if(typeof value !== "string") value = ""

            switch(status.toLowerCase())
            {
                case "design" :
                case "prototype" :
                {
                    let char1 = value[0] || ""
                    let char2 = value[1] || ""
                    let str   = String(char1 + char2)
                    if(validator.isNumeric(str) && Number(str) < 100 && Number(str) > 0) return str
                    return validator.isNumeric(char1) ? char1 : "1"
                }

                default :
                {
                    let char = (value[0] || "").toUpperCase()
                    return validator.isAlpha(char) ? char.toUpperCase() : "A"
                }
            }
        },

        validate(value, status)
        {
            if(value === "") return "Invalid revision"

            if(value && !status) return "Status must be included with revision"

            switch(status.toLowerCase())
            {
                case "design" :
                if ( !value || Number(value) < 100 && Number(value) > 0 || validator.isAlpha(value.toUpperCase()) )
                {
                    return ""
                }
                else
                {
                    return "Should contain number from 1 to 99 or letter from A to Z"
                }

                case "prototype" :
                {
                    return Number(value) < 100 && Number(value) > 0 ? "" : "Invalid revision"
                }

                default :
                {
                    if (isNaN(value) )
                    {
                        return validator.isAlpha(value.toUpperCase()) ? "" : "Invalid Letter"
                    }
                    return "Invalid Letter"
                }
            }
        }
    },

    eid :
    {
        //TODO: Need to clean this code after PLM-2791 completion

        // normalize(value)
        // {
        //     return value
        // },

        // validate(value)
        // {
        //     if(typeof value !== "string")
        //     {
        //         return "Invalid type"
        //     }

        //     // NOTE: max value may come from another source in the future.
        //     if(value.length > 25)
        //     {
        //         return "Must not exceed 25 characters"
        //     }

        //     return ""
        // }
    },

    description :
    {
        //TODO: Need to clean this code after PLM-2791 completion

        // normalize(value)
        // {
        //     return Utils.toParagraphString(value)
        // },

        // validate(value)
        // {
        //     return ""
        // }
    },

    documents :
    {
        specs :
        {
            type :
            {
                list()
                {
                    let list = []
                    const documentTypeList = Utils.getAllowedDocTypes(); 
                    documentTypeList.forEach((type) => list.push({value:type, displayName: type}))
                    return list
                },

                normalize(value)
                {
                    return value
                },

                validate(value)
                {
                    return ""
                }
            },

            status :
            {
                list(currentStatus='')
                {
                    return Utils.getStatusesList(currentStatus)
                },

                normalize(value)
                {
                    return value
                },

                validate(value, revision)
                {
                    if(value === "" && revision === "") return ""

                    let valid = false

                    this.list().forEach((child) =>
                    {
                        if (child.value === value) { valid = true }
                    })

                    if (valid)
                    {
                        return "";
                    }
                    return "Status is not valid";
                }
            },

            revision :
            {
                normalize(value, status)
                {
                    if(value === "") return ""

                    if(typeof value !== "string") value = ""

                    switch(status.toLowerCase())
                    {
                        case "design" :
                        case "prototype" :
                        {
                            let char1 = value[0] || ""
                            let char2 = value[1] || ""
                            let str   = String(char1 + char2)
                            if(validator.isNumeric(str) && Number(str) < 100 && Number(str) > 0) return str
                            return validator.isNumeric(char1) ? char1 : "1"
                        }

                        default :
                        {
                            let char = (value[0] || "").toUpperCase()
                            return validator.isAlpha(char) ? char.toUpperCase() : "A"
                        }
                    }
                },

                validate(value, status)
                {
                    if(value === "" && status === "") return ""

                    if(value && !status) return "Status must be included with revision"

                    switch(status.toLowerCase())
                    {
                        case "design" :
                        case "prototype" :
                        {
                            return Number(value) < 100 && Number(value) > 0 ? "" : "Invalid revision"
                        }

                        default :
                        {
                            if (isNaN(value) )
                            {
                                return validator.isAlpha(value.toUpperCase()) ? "" : "Invalid Letter"
                            }
                            return "Invalid Letter"
                        }
                    }
                }
            }
        }
    },

    children :
    {
        quantity :
        {
            normalize(value)
            {
                if(value === "") return value
                value = Number(value)
                return value > 1 ? value : 1
            },

            validate(value)
            {
                if(typeof value !== "string" || value.trim() === "")
                {
                    return "Invalid quantity"
                }
            },

            validateWithRefDef(quantityValue, refDesValue, cb)
            {
                return Assembly.compareQuantityAndRefDes(refDesValue, quantityValue, cb)

            },

            validateWithRefDef(refDesValue, quantityValue, cb)
            {
                return Assembly.compareQuantityAndRefDes(refDesValue, quantityValue, cb)
            }
        },

        refDes :
        {
            normalize(value)
            {
                return value
            },

            validate(value)
            {
                return ""
            }
        }
    },

    manufacturers :
    {
        name :
        {
            normalize(value)
            {
                return Utils.toParagraphString(value)
            },

            validate(value)
            {
                return ""
            }
        },

        mpn :
        {
            value :
            {
                normalize(value)
                {
                    return Utils.toCondensedString(value).toUpperCase()
                },

                validate(value)
                {
                    return ""
                }
            },

            src :
            {
                normalize(value)
                {
                    return Utils.appendHTTP(value)
                },

                validate(value)
                {
                    return ""
                }
            }
        },

        description :
        {
            normalize(value)
            {
                return Utils.toParagraphString(value)
            },

            validate(value)
            {
                return ""
            }
        },

        leadTime :
        {
            normalize(value)
            {
                return Utils.toParagraphString(value)
            },

            validate(value)
            {
                return ""
            }
        },

        datasheet :
        {
            src :
            {
                normalize(value)
                {
                    return Utils.appendHTTP(value)
                },

                validate(value)
                {
                    return ""
                }
            }
        },

        distributors :
        {
            name :
            {
                normalize(value)
                {
                    return Utils.toParagraphString(value)
                },

                validate(value)
                {
                    return ""
                }
            },

            dpn :
            {
                value :
                {
                    normalize(value)
                    {
                        return Utils.toCondensedString(value).toUpperCase()
                    },

                    validate(value)
                    {
                        return ""
                    }
                },

                src :
                {
                    normalize(value)
                    {
                        return Utils.appendHTTP(value)
                    },

                    validate(value)
                    {
                        return ""
                    }
                }
            },

            description :
            {
                normalize(value)
                {
                    return Utils.toParagraphString(value)
                },

                validate(value)
                {
                    return ""
                }
            },

            package :
            {
                type :
                {
                    normalize(value)
                    {
                        return Utils.toParagraphString(value)
                    },

                    validate(value)
                    {
                        return ""
                    }
                },

                quantity :
                {
                    normalize(value)
                    {
                        if(value === "") return value
                        value = Number(value)
                        return value > 1 ? value : 1
                    },

                    validate(value)
                    {
                        return ""
                    }
                }
            },

            quotes :
            {

                minQuantity :
                {
                    normalize(value)
                    {
                        if(value === "") return value
                        value = Number(value)
                        return value > 1 ? value : 1
                    },

                    validate(value)
                    {
                        return ""
                    }
                },

                maxQuantity :
                {
                    normalize(value)
                    {
                        if(value === "") return value
                        value = Number(value)
                        return value > 1 ? value : 1
                    },

                    validate(value)
                    {
                        return ""
                    }
                },

                unitPrice :
                {
                    normalize(value)
                    {
                        return Utils.toCondensedString(value)
                    },

                    validate(value)
                    {
                        return ""
                    }
                },

                leadTime :
                {
                    normalize(value)
                    {
                        return Utils.toParagraphString(value)
                    },

                    validate(value)
                    {
                        return ""
                    }
                }
            }
        }
    },

    categories :
    {
        list()
        {
            let list =
            [
                {
                    value : "ELECTRICAL",
                    displayName : "Electrical"
                },
                {
                    value : "MECHANICAL",
                    displayName : "Mechanical"
                },
                {
                    value : "PACKAGING",
                    displayName : "Packaging"
                },
                {
                    value : "FIRMWARE",
                    displayName : "Firmware"
                }
            ]

            return list
        },

        normalize(value)
        {
            return value
        },

        validate(value)
        {
            return ""
        }
    },

    team :
    {
        list()
        {
            let list =
            [
                {
                    value : "productManager",
                    displayName : "Product Manager"
                },
                {
                    value : "engineering",
                    displayName : "Engineering"
                },
                {
                    value : "qa",
                    displayName : "QA"
                },
                {
                    value : "procurement",
                    displayName : "Procurement"
                },
                {
                    value : "manufacturing",
                    displayName : "Manufacturing"
                }
            ]

            return list
        },

        normalize(value)
        {
            return value
        },

        validate(value)
        {
            return validator.isEmail(value) || value === "" ? "" : "Invalid email"
        }
    },

    forecasts: schemas.product.forecasts
    //TODO: Need to clean this code after PLM-2791 completion
    // forecasts :
    // {
    //     // key :
    //     // {
    //     //     normalize(value)
    //     //     {
    //     //         return value
    //     //     },

    //     //     validate(value)
    //     //     {
    //     //         return ""
    //     //     }
    //     // },
    //     // targetDate :
    //     // {
    //     //     normalize(value)
    //     //     {
    //     //         return value
    //     //     },

    //     //     validate(value)
    //     //     {
    //     //         return ""
    //     //     }
    //     // },
    //     volume :
    //     {
    //         normalize(value)
    //         {
    //             value = typeof value === "string" ? value : ""
    //             value = Utils.toNumericString(value)
    //             value = Utils.toCondensedString(value)
    //             return value
    //         },

    //         validate(value)
    //         {
    //             return validator.isNumeric(value) ? "" : "Invalid volume"
    //         }
    //     }
    // }
}

export default productSchema

