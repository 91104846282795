import mapActions from '../helpers/mapActions';

export default mapActions(
  [
    'SUBMIT_SIGNIN_FORM',
    'UPDATE_EMAIL_AFTER_VALIDATION',
    'UPDATE_PASSWORD_AFTER_VALIDATION',
    'SET_EMAIL',
    'SET_PASSWORD',
    'SET_API_ERRORS',
    'DISABLE_FORM',
    'SIGN_ME_IN',
    'RESET_SIGN_IN_FORM_STATE'
  ],
  'SIGNIN'
  );
