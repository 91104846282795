import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width="15px" height="15px" viewBox="0 0 15 15" {...props}>
      <g fillRule="nonzero" stroke="none" strokeWidth={1} fill="none">
        <rect
          stroke="#888"
          fill="#888"
          x={0.5}
          y={0.5}
          width={14}
          height={14}
          rx={7}
        />
        <path
          fill="#18171D"
          d="M6.86938101 2.5L6.86938101 6.12362211 3 6.12362211 3 8.78395015 6.86938101 8.78395015 6.86938101 12.5 13 7.4998106z"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
