import {
  ChangeEvent,
  FC,
  useCallback,
  useMemo,
} from "react";
import { styled, Typography, Grid } from "@mui/material";
import { EcoIcon, McoIcon, DcoIcon } from "assets/icons";
import { IconsList } from "common/components/iconsList";
import { InputField, TextArea } from "common/components/inputs";
import { InputFieldVariants } from "common/constants";
import { colorPalette } from "theme";
import { ERPEdit } from "./erp-section";
import { useChangeOrderFormContext } from "../changeOrderContextProvider";
import { ChangeOrderType } from "../constants";

const iconListStyles = { flex: "0" };
const inputFieldComponentStyles = { marginBottom: "0.625rem" };
const inputFieldStyles = {
  style: {
    backgroundColor: colorPalette.gray,
  },
};
const labelStyles = { color: colorPalette.silver };
const textAreaStyles = { fontSize: "0.875rem", height: "5rem !important" };

export const ChangeOrderEditFrom: FC = () => {
  const {
    coType,
    description,
    name,
    setCoType,
    setDescription,
    setName,
  } = useChangeOrderFormContext();

  const Icons = useMemo(() => (
    [
      {
        defaultSelect: coType === ChangeOrderType.ECO,
        Icon: EcoIcon,
        label: "ECO",
      },
      {
        defaultSelect: coType === ChangeOrderType.MCO,
        Icon: McoIcon,
        label: "MCO",
      },
      {
        defaultSelect: coType === ChangeOrderType.DCO,
        Icon: DcoIcon,
        label: "DCO",
      },
    ]
  ), [coType]);

  const onChangeCoType = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    event.target.value && setCoType(event.target.value as ChangeOrderType);
  }, [setCoType]);

  const onChangeName = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }, [setName]);

  const onChangeDescription = useCallback((value: string, event: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
  }, [setDescription]);

  return (
    <>
      <Grid item xs={5.5}>
        <IconsList Icons={Icons} onChange={onChangeCoType} isRequired={true}
          label={"Choose A Change Order Category"} styleProps={iconListStyles}
        />
        <InputField
          componentStyles={inputFieldComponentStyles}
          inputStyles={inputFieldStyles}
          isRequired={true}
          label="Change Order Name"
          labelStyling={labelStyles}
          onChange={onChangeName}
          value={name}
          variant={InputFieldVariants.STANDARD}
        />
        <TextArea
          isRequired={false}
          label="Description"
          onChange={onChangeDescription}
          placeholder=""
          labelStyling={labelStyles}
          styleProps={textAreaStyles}
          initialValue={description}
        />
        <PlainText>
          *Required fields
        </PlainText>
      </Grid>
      <ERPEdit />
    </>
  );
};

const PlainText = styled(Typography)({
  color: colorPalette.silver,
  fontSize: "1rem",
  marginTop: "1rem",
});
