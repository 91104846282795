import "./index.css"
import React, { Component } from "react"
import Utils from "../../../../../modules/utils"
import Sourcing from "../../../common/sourcing/edit"
import Assembly from "../../../common/assembly/edit"
import InstanceAssembly from "../../../common/assembly/edit/tree"
import Documents from "../../../common/documents/edit"
import validations from "../../../../../modules/validations"
import Schemas from "../../../../../modules/schemas"
import AddAssemblyIcon from "../../../../../assets/icons/assembly-add-icon"
import InlineIcon from "../../../../ui/icon/inline-icon.js"
import importIcon from "../../../../../assets/icons/import-icon.svg"
import webSrc from "../../../../../assets/icons/newcomp-web.svg"
import manualSrc from "../../../../../assets/icons/newcomp-manual.svg"
import Icon from "../../../../ui/icon"
import URLSearchParams from "url-search-params";
import ExpandIcon from "../../../../../assets/icons/expand-icon";
import { Tab, TabContext, TabList, TabPanel } from "common/components/tabs"

export class Tabs extends Component {
    constructor(props) {
        super(props)

        let { tabsType, inputs } = this.props;
        let view;

        if (tabsType === "prd") {
            this.objectData = this.props.product
            view = Utils.getStore("product-tab") || "assembly";
        }
        else if (tabsType === "productionInstance") {
            this.objectData = this.props.productionInstance
            // Will uncomment this once assembly tree code is added for
            // production instance Edit Mode, but for now, show only document tab
            view = Utils.getStore("production-instance-tab") || "documents";
            // view = "documents";
        }
        else {
            this.objectData = this.props.component
            view = Utils.getStore("component-tab") || "assembly";
        }

        if (view === "serialization") view = "documents";

        if (tabsType === "cmp") {
            let isAssembly = Schemas.component.category.getType(inputs.category.value).toLowerCase() === "assembly"

            if (!isAssembly && view === "assembly") {
                view = "documents"
            }
        }

        if (view === "variants" || view === 'reports' || view === "build") {
            view = "assembly"
            let tab = tabsType === "prd" ? "product-tab" : "component-tab"
            Utils.setStore(tab, view)
        }

        let urlParams = new URLSearchParams(window.location.search);
        let tab = urlParams.has('tab') ? urlParams.get('tab') : null;
        if (tab) {
            view = tab;
        }

        this.state =
        {
            view: view,
            categoryValue: tabsType === "cmp" ? inputs.category.value : null,
            collapseTreeView: undefined,
        }

        this.show = this.show.bind(this)
        this.showSourcingTab = this.showSourcingTab.bind(this)
        this.getAssemblyActionsList = this.getAssemblyActionsList.bind(this)
        this.haveRefererSkuValue = this.haveRefererSkuValue.bind(this)
        this.expandTreeViewLevel = this.expandTreeViewLevel.bind(this)
        this.expandTreeView = this.expandTreeView.bind(this)
        this.collapseTreeView = this.collapseTreeView.bind(this)
        this.afterToggleTreeView = this.afterToggleTreeView.bind(this)
        this.shouldForceRerenderAssemblyTab = false
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.tabsType === "cmp" && nextProps.inputs.category.value !== this.state.categoryValue) {
            if (Utils.isItemNumber(this.state.categoryValue) !== Utils.isItemNumber(nextProps.inputs.category.value)) {
                this.shouldForceRerenderAssemblyTab = true
            }
            this.state.categoryValue = nextProps.inputs.category.value
        }
    }

    componentDidMount() {
        this.haveRefererSkuValue();
    }

    haveRefererSkuValue() {
        let history = this.props.history
        let locationState = history && history.location && history.location.state
        if (locationState && locationState.refererSku) {
            this.showSourcingTab()
        }
    }

    expandTreeView() {
        this.setState({
            collapseTreeView: 'expand'
        })
    }

    expandTreeViewLevel() {
        this.setState({
            collapseTreeView: 'expandLevel'
        })
    }

    collapseTreeView() {
        this.setState({
            collapseTreeView: 'collapse'
        })
    }

    afterToggleTreeView() {
        this.setState({
            collapseTreeView: undefined
        })
    }

    getAssemblyActionsList() {
        let actionsList = []
        let actionEntry;
        let { tabsType } = this.props;

        if (tabsType !== 'productionInstance') {
            actionsList.push({
                type: 'customEl',
                element: <span className="assembly-icons flex-v" key={Math.random()}>
                    <span className='muted-text default-cursor'>+ Add From:</span>
                    <span className={" icon-section flex-v"} >
                        <span
                            className={"action-item active flex-v"}
                            data-tip={"Add components from library"}
                            onClick={this.props.showHideAssemblySearch}
                        >
                            <InlineIcon
                                className={" flex-v"}
                            >
                                <AddAssemblyIcon />
                            </InlineIcon>
                            <span
                                className={" item-title"}
                            >
                                {"Library"}
                            </span>
                        </span>

                        <span
                            className={"action-item active flex-v"}
                            data-tip={"Import components from file"}
                            onClick=
                            {
                                (e) => { this.props.toggleModal("displayFileImport", true) }
                            }
                        >
                            <Icon
                                src={importIcon}
                                className={" flex-v"}
                            />
                            <span
                                className={" item-title flex-v"}
                            >
                                {"File"}
                            </span>
                        </span>

                        <span
                            className={"action-item active flex-v vendor-icon"}
                            data-tip={"Add from vendor"}
                            onClick=
                            {
                                (e) => { this.props.toggleModal("displayVendorModal", true) }
                            }
                        >
                            <Icon
                                src={webSrc}
                                className={" flex-v"}
                            />
                            <span
                                className={" item-title flex-v"}
                            >
                                {"Vendor"}
                            </span>
                        </span>

                        <span
                            className={"action-item active flex-v vendor-icon"}
                            data-tip={"Create manually"}
                            onClick=
                            {
                                (e) => { this.props.toggleModal("displayManualModal", true) }
                            }
                        >
                            <Icon
                                src={manualSrc}
                                className={" flex-v"}
                            />
                            <span
                                className={" item-title flex-v"}
                            >
                                {"Manual"}
                            </span>
                        </span>
                    </span>
                </span>
            })

            actionsList.push({ type: 'divider' });
        }

        if (tabsType === "productionInstance") {
            actionEntry =
            {
                type: 'action',
                iconSrc: <ExpandIcon />,
                toolTip: 'Expand all',
                name: 'Expand',
                onClick: this.expandTreeViewLevel,
                onDoubleClick: this.expandTreeView,
                active: true,
                iconClassName: "expand-icon",
                className: "tree-expand-icon"
            };
            actionsList.push(actionEntry);
            actionEntry =
            {
                type: 'action',
                iconSrc: <ExpandIcon />,
                toolTip: 'Collapse all',
                name: 'Collapse',
                onClick: this.collapseTreeView,
                active: true,
                iconClassName: "collapse-icon",
                className: "tree-collapse-icon"
            };
            actionsList.push(actionEntry);
        }

        return actionsList
    }

    show(_event, viewTab) {
        let { tabsType } = this.props;
        if (viewTab !== "assembly" && tabsType !== "productionInstance") {
            this.props.hideAssemblyModals()
        }

        let tab;
        if (tabsType === "prd") {
            tab = "product-tab";
        }
        else if (tabsType === "productionInstance") {
            tab = "production-instance-tab";
        }
        else {
            tab = "component-tab";
        }

        Utils.setStore(tab, viewTab)
        this.setState({ view: viewTab }, window.changeRoutesWidth)
    }

    showSourcingTab() {
        this.setState({ view: "sourcing" })
    }

    render() {
        let view = this.state.view
        let isAssembly = false
        let tabsType = this.props.tabsType

        if (tabsType === "cmp") {
            isAssembly = Schemas.component.category.getType(this.props.inputs.category.value).toLowerCase() === "assembly"
            if (!isAssembly && view === "assembly") {
                view = "documents"
            }
        }
        let isVendorCmp = this.props.tabsType === "cmp" && Utils.isVendorCmp(Utils.getVendor(this.props.component));
        let vendorTooltipText = "";
        if (isVendorCmp) vendorTooltipText = Utils.vendorTooltip(Utils.getVendor(this.props.component));
        let sourcingProps = {};
        let assemblyProps = {};
        let documentsProps = {};
        if (tabsType !== "productionInstance") {
            sourcingProps =
            {
                tabsType: tabsType,
                name: "manufacturers",
                onChange: this.props.onChange,
                component: this.props.component,
                // manufacturers: this.objectData.manufacturers,
                manufacturers: tabsType === "prd" ? this.props.product.manufacturers : this.props.component.manufacturers,
                schema: tabsType === "prd" ? Schemas.product : Schemas.component,
                clientSchema: tabsType === "prd" ? validations.product.manufacturers : validations.component.manufacturers,
                history: this.props.history,
                status: this.props.inputs.status.value,
                dispalyErrorModal: this.props.dispalyErrorModal,
                showSourcingTab: this.showSourcingTab,
                primarySource: this.props.primarySource,
                onPrimarySourceChange: this.props.onPrimarySourceChange,
                disablePrimarySourceBtn: this.props.disablePrimarySourceBtn,
                enablePrimarySourceBtn: this.props.enablePrimarySourceBtn,
                allowPrimarySourceIcon: this.props.allowPrimarySourceIcon,
                setPrimarySourceInObject: this.props.setPrimarySourceInObject,
                onPrimarySourceDeselect: this.props.onPrimarySourceDeselect,
                syncPrimaryQuoteInputWithData: this.props.syncPrimaryQuoteInputWithData,
                isAssembly: (isAssembly || tabsType === "prd"),
                // children: tabsType === "prd" ? this.props.product.children : this.props.inputs.children.value,
                userStyles: this.props.userStyles,
                objectData: this.objectData,
                onRolledUpSelect: this.props.onRolledUpSelect,
                onRolledUpInputChanged: this.props.onRolledUpInputChanged,
                tabsType: tabsType,
                itemId: tabsType === "prd" ? this.props.product._id : this.props.component._id,
            }

            if (tabsType === "cmp") {
                sourcingProps.editSku = true
                sourcingProps.category = this.props.component.category
            }

            assemblyProps =
            {
                name: "children",
                // children: tabsType === "prd" ? this.props.product.children : this.props.inputs.children.value,
                // exclude: tabsType === "prd" ? this.props.product._id : this.props.component._id,
                // inputs: this.props.inputs,
                // onChange: this.props.onChange,
                componentSearch: this.props.componentSearch,
                clearComponentSearch: this.props.clearComponentSearch,
                // validations: tabsType === "prd" ? validations.product.children : validations.component.children,
                // validations: tabsType === "prd" ? validations.product.children : validations.component.children,
                showHideAssemblySearch: this.props.showHideAssemblySearch,
                newlyCreatedComponent: this.props.newlyCreatedComponent,
                clearNewlyCreatedComponentsFromState: this.props.clearNewlyCreatedComponentsFromState,
                actionType: this.props.actionType,
                newlyAddedComponentsFromRightSearch: this.props.newlyAddedComponentsFromRightSearch,
                clearNewlyAddedComponentsFromState: this.props.clearNewlyAddedComponentsFromState,
                // history: this.props.history,
                // location: this.props.location,
                objectType: tabsType,
                getIconsActionsList: this.getAssemblyActionsList,
                vendorTooltipText
            }

            documentsProps =
            {
                name: "documents",
                onChange: this.props.onChange,
                toggleErrorCount: this.props.toggleErrorCount,
                documents: tabsType === "prd" ? this.props.product.documents : this.props.component.documents,
                clientSchema: tabsType === "prd" ? validations.product.documents : validations.component.documents,
                documentSchema: tabsType === "prd" ? Schemas.product.documents : Schemas.component.documents,
                vendorData: tabsType === "cmp" ? { isVendorCmp: Utils.isVendorCmp(Utils.getVendor(this.props.component)) } : { isVendorCmp: false },
                vendorTooltipText,
                component: this.props.component ? this.props.component : null,
                initializePusher: true
            }
        }
        else {
            documentsProps =
            {
                name: "documents",
                onChange: this.props.onChange,
                toggleErrorCount: this.props.toggleErrorCount,
                documents: this.props.productionInstance.documents,

                clientSchema: validations.productionInstance.documents,
                documentSchema: Schemas.productionInstance.documents,
                vendorData: { isVendorCmp: false },
                vendorTooltipText,

                component: null,
                initializePusher: true
            }

            assemblyProps =
            {
                name: "children",
                children: tabsType === 'productionInstance' ? this.props.productionInstance.children : this.props.inputs.children.value,
                objectData: this.props.productionInstance,
                inputs: this.props.inputs,
                objectType: tabsType,
                getIconsActionsList: this.getAssemblyActionsList,
                vendorTooltipText,
                newSerialValue: this.props.newSerialValue,
            }
        }

        const hasSourcingTab = tabsType !== "productionInstance";
        const hasAssemblyTab = isAssembly || tabsType === "prd" || tabsType === 'productionInstance';

        return (
            <TabContext className="tabs-block">
                <TabList value={view} onChange={this.show}>
                    <Tab value="documents" errorCount={this.props.errorsCount?.documentTab}>Documents</Tab>
                    {hasSourcingTab && <Tab value="sourcing" errorCount={this.props.errorsCount?.sourcingTab}>Sourcing</Tab>}
                    {hasAssemblyTab && <Tab value="assembly" errorCount={this.props.errorsCount?.assemblyTab}>Assembly</Tab>}
                </TabList>
                <TabPanel value={"documents"} currentValue={view}>
                    <Documents
                        {...documentsProps}
                    />
                </TabPanel>
                {hasSourcingTab && (
                    <TabPanel value={"sourcing"} currentValue={view}>
                        <Sourcing
                            {...sourcingProps}
                            syncWithRolledUpCost={this.props.syncWithRolledUpCost}
                            afterSyncWithRolledUpCost={this.props.afterSyncWithRolledUpCost}
                            activeTab={view}
                            toggleErrorCount={this.props.toggleErrorCount}
                            defaultCurrency={this.props.defaultCurrency}
                        />
                    </TabPanel>)
                }
                {hasAssemblyTab && <TabPanel value="assembly" currentValue={view}>
                    {tabsType !== "productionInstance" && (
                        <Assembly
                            {...assemblyProps}
                            showHideAssemblySearch={this.props.showHideAssemblySearch}
                            toggleModal={this.props.toggleModal}
                            isVendorCmp={isVendorCmp}
                            cmpId={this.props.cmpId}
                            category={this.props.tabsType === "cmp" ? this.props.inputs.category.value : null}
                            tabsType={this.props.tabsType}
                            key={this.shouldForceRerenderAssemblyTab ? Utils.generateUniqueId() : "assembly-initial-rendering"}
                            defaultCurrency={this.props.defaultCurrency}
                            toggleErrorCount={this.props.toggleErrorCount}
                            toggleAssemblyEditingState={this.props.toggleAssemblyEditingState}
                        />
                    )}
                    {tabsType === "productionInstance" && (
                        <InstanceAssembly
                            {...assemblyProps}
                            showHideAssemblySearch={this.props.showHideAssemblySearch}
                            toggleModal={this.props.toggleModal}
                            isVendorCmp={isVendorCmp}
                            cmpId={this.props.cmpId}
                            category={this.props.tabsType === "cmp" ? this.props.inputs.category.value : null}
                            tabsType={this.props.tabsType}
                            key={this.shouldForceRerenderAssemblyTab ? Utils.generateUniqueId() : "assembly-initial-rendering"}
                            defaultCurrency={this.props.defaultCurrency}
                            updateChildrenInstances={this.props.updateChildrenInstances}
                            updateProductionInstances={this.props.updateProductionInstances}
                            collapseTreeView={this.state.collapseTreeView}
                            afterToggleTreeView={this.afterToggleTreeView}
                            calculateAssemblyErrors={this.props.calculateAssemblyErrors}
                            setEditBarState={this.props.setEditBarState}
                        />
                    )}
                </TabPanel>}
            </TabContext>
        );
    }
}
export default Tabs
