import { Box, styled } from "@mui/material";
import { GridSortItem, useGridApiRef } from "@mui/x-data-grid-pro";
import { FileImportIcon, ManualCreateIcon, VendorImportIcon } from "assets/icons";
import { BreadCrumbIcon, IBreadCrumbProps, useBreadCrumb } from "common/components/breadcrumb";
import { ErrorModal } from "common/components/modals";
import { PageWrapper } from "common/components/pageLayout";
import { IToolbarActions } from "common/components/toolbar";
import { User } from "common/models/user";
import { PageItemType } from "design/constants";
import { Component } from "design/models/component";
import { usePrimaryCompany } from "graphql/query/companyQueries";
import { IComponentList, OrderByType, useComponentList } from "graphql/query/componentsQueries";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { DefaultRootState, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useDebounce } from "use-debounce";
import SEARCH from "v1/action-types/search.js";
import UiActions from "v1/action-types/ui";
import ValispaceIcon from "v1/assets/icons/valispace-icon";
import ExportMenu from "v1/components/page/common/export/export-menu";
import UpdateStatusModal from "v1/components/page/common/update-status-modal";
import FileImportModule from "v1/components/page/component/new/file/file-import-module";
import ImportFromManual from "v1/components/page/component/new/manual";
import ValispaceModal from "v1/components/page/component/new/valispace/index.js";
import ImportFromVendor from "v1/components/page/component/new/web";
import DeleteModal from "v1/components/page/search/modules/delete-modal";
import RevertBackModal from "v1/components/ui/revert-back-modal";
import buildAction from "v1/helpers/buildAction";
import Utils from "v1/modules/utils";
import { useManageNoRowsOverlay } from "../../components/grid/manageNoRowsOverlay";
import { Table } from "../../components/grid/searchTable";
import { ColumnTypes } from "../../utils/columnDefaults";
import { useSearchStringHandler } from "../../utils/searchStringHandler";
import { useTablePreferences } from "../component/assemblyTab/useTablePreferences";

type ModalDataType = Partial<IComponentOld>[];
type ModalToggles = { [key: string]: (isVisible: boolean, modalPayload?: any) => void };
type IState = DefaultRootState & {
  user: { data: User },
  search: {
    value: string,
  }
}

export interface IComponentOld {
  _id: string,
  alias: string,
  category: string,
  cpn: string,
  description: string,
  eid: string,
  images: {
    archived: boolean,
    created: string,
    id: string,
    key: string,
    mime: string,
    mode: string,
    name: string,
    size: number,
    src: string,
    variants: any,
  },
  lastModified: number,
  modified: boolean,
  name: string,
  status: string,
  variantGroup: {
    id: string,
    type: string,
  },
  previousRevision: string,
  revision: string,
}

export function sortModelToOrderBy(sortModel: GridSortItem[]): OrderByType {
  if (!sortModel?.length) return undefined;

  const { field, sort } = sortModel[0];
  switch (field) {
    case "revision": return [{ revisionValue: sort }];
    case "lastUpdated": return [{ lastModified: sort }];
    case "cpn": return [{ legacyCpn: sort }];
    default: return [{ [field]: sort }];
  }
}

const BREAD_CRUMB_VALUES = {
  icon: BreadCrumbIcon.COMPONENTS,
  label: "Components",
  url: "/components",
};
const defaultSort: GridSortItem = { field: "cpn", sort: "desc" };
const notEmptySearch = /type:cmp\s*\S+/;
export const columnNames: ColumnTypes[] = [
  "cpn", "category", "description", "eid", "images", "lastUpdated", "name", "revision", "status",
];
const GRID_NAME = "components";
const requiredFieldNames = ["cpn", "name"];
const styleName = "searchComponents";

const ErrorMessage = [
  {
    message: (
      <>
        Our apologies a network issue occurred,
        please try again in a few minutes
        and if it happens again contact support at
        <a href="mailto:Support@durolabs.co"> Support@durolabs.co </a>
      </>
    ),
  },
];

export const Components: FC = () => {
  const [data, setData] = useState<Component[]>([]);
  const [importFromFile, setImportFromFile] = useState<boolean>(false);
  const [importFromVendor, setImportFromVendor] = useState<boolean>(false);
  const [itemCount, setItemCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [manualImport, setManualImport] = useState<boolean>(false);
  const [valispaceImport, setValispaceImport] = useState<boolean>(false);
  const [modalData, setModalData] = useState<ModalDataType>([]);
  const [orderBy, setOrderBy] = useState<OrderByType>();
  const [retainPreviousData, setRetainPreviousData] = useState<boolean>(true);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [showExportMenu, setShowExportMenu] = useState<boolean>(false);
  const [showRevertModal, setShowRevertModal] = useState<boolean>(false);
  const [showUpdateStatusModal, setShowUpdateStatusModal] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const { user, _searchString } = useSelector((state: IState) => ({
    user: state.user,
    _searchString: state.search.value,
  }));

  const {
    columnVisibilityModel,
    getCurrentStyles,
    onColumnsReordered,
    onColumnsResized,
    onColumnVisibilityChange,
    onSortModelChange,
    sortModel,
  } = useTablePreferences({ columnNames, defaultSort, styleName, requiredFieldNames });

  // set order by from user prefs
  useEffect(() => {
    setOrderBy(sortModelToOrderBy(sortModel));
  }, [setOrderBy, sortModel]);

  const [searchString] = useDebounce(_searchString, 500);

  const apiRef = useGridApiRef();
  const dispatch = useDispatch();
  const history = useHistory();

  // Workaround for handling search string from location state
  useSearchStringHandler(searchString, "type:cmp");

  useEffect(() => {
    const message = (history?.location?.state as any)?.success_message;
    if (message) {
      setSuccessMessage(message);
    }
  }, [history?.location?.state]);

  const {
    components,
    endCursor,
    error,
    fetchComponents,
    hasNextPage,
    loading: componentsLoading,
    totalCount,
  }: IComponentList = useComponentList({ libraryType: user?.data?.activeLibrary?.type, pageSize: 100 });

  const { data: company } = usePrimaryCompany();
  const breadCrumbOptions: IBreadCrumbProps[] = useBreadCrumb(BREAD_CRUMB_VALUES);

  useEffect(() => {
    if (!componentsLoading) {
      setData(previousData => ([...(retainPreviousData ? previousData : []), ...components]));
      if (!retainPreviousData) {
        apiRef.current?.scrollToIndexes({ rowIndex: 0, colIndex: 0 });
        apiRef.current?.setSelectionModel([]);
      }
      setLoading(false);
      if (totalCount) {
        setItemCount(totalCount);
      }
    }
  }, [apiRef, components, componentsLoading, retainPreviousData, totalCount]);

  // To handle the core-api errors
  useEffect(() => {
    if (error) {
      setShowErrorModal(true);
      setRetainPreviousData(true);
    }
    else {
      setShowErrorModal(false);
    }
  }, [error]);

  useEffect(() => {
    if (orderBy && Object.keys(orderBy as any).length && searchString) {
      setLoading(true);
      setRetainPreviousData(false);
      fetchComponents?.(undefined, orderBy, searchString);
    }
  }, [fetchComponents, orderBy, searchString]);

  const loadMoreData = useCallback(() => {
    if (!hasNextPage || loading) return;
    setRetainPreviousData(true);
    setLoading(true);
    fetchComponents?.(endCursor, orderBy, searchString);
  }, [endCursor, fetchComponents, hasNextPage, loading, setLoading, searchString, orderBy]);

  const refetchComponents = useCallback(() => {
    if (loading) return;
    setRetainPreviousData(false);
    setLoading(true);
    fetchComponents?.(undefined, orderBy, searchString);
  }, [fetchComponents, loading, searchString, orderBy]);

  const showUiAlert = useCallback((payload: any) => (
    dispatch(buildAction(UiActions.SHOW_ALERT, payload))
  ), [dispatch]);

  const deleteSelectedItems = useCallback(() => {
    setShowDeleteModal(false);
    setLoading(true);
    setRetainPreviousData(false);
    dispatch(buildAction(SEARCH.DELETE_ITEMS, { components: modalData }));
  }, [modalData, dispatch]);

  const duplicateSelectedItems = useCallback((items: ModalDataType) => {
    setLoading(true);
    setRetainPreviousData(false);
    dispatch(buildAction(SEARCH.CLEAR_SELECTED_RESULTS));
    dispatch(buildAction(SEARCH.DUPLICATE_ITEMS, { components: items }));
  }, [dispatch]);

  const onCloseBulkStatusModal = useCallback(() => setShowUpdateStatusModal(false), []);
  const onCloseDeleteModal = useCallback(() => setShowDeleteModal(false), []);
  const onCloseErrorModal = useCallback(() => setShowErrorModal(false), []);
  const onCloseExportMenu = useCallback(() => setShowExportMenu(false), []);
  const onCloseRevertModal = useCallback(() => setShowRevertModal(false), []);

  const onContinueUpdateStatusModal = useCallback(() => {
    setShowUpdateStatusModal(false);
    refetchComponents();
  }, [refetchComponents]);

  const openBulkStatusModal = useCallback((isVisible: boolean, requiredData: ModalDataType) => {
    setShowUpdateStatusModal(isVisible);
    setModalData(requiredData);
  }, []);

  const openDeleteModal = useCallback((isVisible: boolean, requiredData: ModalDataType) => {
    setShowDeleteModal(isVisible);
    setModalData(requiredData);
  }, []);

  const openRevertModal = useCallback((isVisible: boolean, requiredData: ModalDataType) => {
    setShowRevertModal(isVisible);
    setModalData(requiredData);
  }, []);

  const openExportMenu = useCallback((isVisible: boolean, requiredData: ModalDataType) => {
    setModalData(requiredData);
    setShowExportMenu(isVisible);
  }, []);

  const revertSelectedItems = useCallback(() => {
    setShowRevertModal(false);
    setLoading(true);
    setRetainPreviousData(false);
    dispatch(buildAction(SEARCH.REVERT_ITEMS, { components: modalData }));
  }, [modalData, dispatch]);

  const toggleModal = useCallback((modalName: string, modalValue: boolean, payload?: ModalDataType) => {
    if (modalName === "displayFileImport") {
      setTimeout(() => {
        setSuccessMessage("");
      }, 5000); // display time of import success message is 5 seconds.
    }

    const modals: ModalToggles = {
      displayBulkStatusModal: openBulkStatusModal,
      displayDeleteModal: openDeleteModal,
      displayExportMenu: openExportMenu,
      displayFileImport: setImportFromFile,
      displayManualModal: setManualImport,
      displayRevertModal: openRevertModal,
      displayVendorModal: setImportFromVendor,
      displayValispaceImport: setValispaceImport,
    };
    payload ? modals[modalName](modalValue, payload) : modals[modalName](modalValue);
  }, [openBulkStatusModal, openDeleteModal, openExportMenu, openRevertModal]);

  const onDoneFromCmpHeaderNav = useCallback((modalName: string) => {
    toggleModal(modalName, false);
    refetchComponents();
  }, [refetchComponents, toggleModal]);

  const toolbarItems: IToolbarActions[] = useMemo(() => {
    const items = [{
      Icon: FileImportIcon,
      label: "File",
      onClick: () => setImportFromFile(true),
    }, {
      Icon: VendorImportIcon,
      label: "Vendor",
      onClick: () => setImportFromVendor(true),
    }, {
      Icon: ManualCreateIcon,
      label: "Manual",
      onClick: () => setManualImport(true),
    }];
    const isValispaceEnabled = company?.settings?.isValispaceEnabled ?? false;
    if (isValispaceEnabled) {
      items.push({
        Icon: ValispaceIcon,
        label: "Valispace",
        onClick: () => setValispaceImport(true),
      });
    }
    return items;
  }, [company?.settings?.isValispaceEnabled]);

  const isSearchResult = useMemo(() => (notEmptySearch.test(searchString)), [searchString]);

  const tiles = useMemo(() => ([
    {
      buttonLabel: "Import Spreadsheet",
      Icon: FileImportIcon,
      onClick: () => setImportFromFile(true),
      text: "Import components from a spreadsheet.",
    },
    {
      buttonLabel: "Import from Vendor",
      Icon: VendorImportIcon,
      onClick: () => setImportFromVendor(true),
      text: "Import components from Vendor.",
    },
    {
      buttonLabel: "Create Component",
      Icon: ManualCreateIcon,
      onClick: () => setManualImport(true),
      text: "Create and add components manually.",
    },
  ]), []);

  const { OverlayComponent, OverlayProps } = useManageNoRowsOverlay(
    isSearchResult,
    componentsLoading,
    tiles,
    components.length > 0,
  );
  // Temporary Box to display legacy import message until toast is not implemented
  const ImportMessage = useMemo(() => (
    <MessageWrapper>
      {successMessage}
    </MessageWrapper>
  ), [successMessage]);

  const mainContent = useMemo(() => (
    <>
      {ImportMessage}
      <Table
        apiRef={apiRef}
        columnNames={columnNames}
        columnVisibilityModel={columnVisibilityModel}
        duplicateSelectedItems={duplicateSelectedItems}
        getCurrentStyles={getCurrentStyles}
        gridName={GRID_NAME}
        isSearchResult={isSearchResult}
        items={data}
        loading={loading}
        loadMoreData={loadMoreData}
        onColumnsReordered={onColumnsReordered}
        onColumnsResized={onColumnsResized}
        onColumnVisibilityChange={onColumnVisibilityChange}
        onSortModelChange={onSortModelChange}
        OverlayComponent={OverlayComponent}
        OverlayProps={OverlayProps}
        pageItemType={PageItemType.COMPONENT}
        refetch={refetchComponents}
        sortModel={sortModel}
        toggleModal={toggleModal}
        totalCount={itemCount}
      />
    </>
  ), [
    ImportMessage, apiRef, columnVisibilityModel, duplicateSelectedItems,
    getCurrentStyles, data, isSearchResult, loading, loadMoreData,
    onColumnsReordered, onColumnsResized, onColumnVisibilityChange,
    onSortModelChange, OverlayComponent, OverlayProps, refetchComponents,
    sortModel, toggleModal, itemCount,
  ]);
  const RightSidePanelChildren = useMemo(() => (
    <ExportMenu
      collection={modalData}
      currentUserEmail={user.data.email}
      defaultTemplate={Utils.getDefaultExportTemplate()}
      exportFirstChildWithShallow={false}
      mode={user.data.role === "VENDOR" ? "revision" : ""}
      onExportCancel={onCloseExportMenu}
      onExportComplete={onCloseExportMenu}
      showAlert={showUiAlert}
      user={user}
    />
  ), [modalData, onCloseExportMenu, showUiAlert, user]);

  return (
    <>
      <PageWrapper
        breadCrumbOptions={breadCrumbOptions}
        helmetTitle="Components"
        pageHeading="Components"
        rightSidePanelProps={{
          RightSidePanelChildren,
          visible: showExportMenu,
          width: "16.875rem",
        }}
        toolbarProps={{
          actionLabel: "Import",
          toolbarItems,
        }}
      >
        <>
          {manualImport && <ImportFromManual onClose={toggleModal} history={history} />}
          {importFromVendor && <ImportFromVendor onClose={toggleModal} history={history} />}
          {
            valispaceImport && (
              <ValispaceModal
                onClose={toggleModal}
                onDoneFromCmpHeaderNav={onDoneFromCmpHeaderNav}
                showUiAlert={showUiAlert}
              />
            )
          }
          {
            importFromFile && (
              <FileImportModule
                displayImportFileFlag={true}
                displayRefDesAndItemNumber={company?.settings?.displayRefDesAndItemNumber ?? false}
                history={history}
                onClose={toggleModal}
                onDoneFromCmpHeaderNav={onDoneFromCmpHeaderNav}
                showUiAlert={showUiAlert}
              />
            )
          }{
            showUpdateStatusModal && (
              <UpdateStatusModal
                components={modalData}
                hideUpdateStatusModal={onCloseBulkStatusModal}
                products={[]}
                refreshAfterBulkUpdate={onContinueUpdateStatusModal}
              />
            )
          }
          {
            showDeleteModal && (
              <DeleteModal
                components={modalData}
                deleteSelectedItems={deleteSelectedItems}
                hideDeleteModal={onCloseDeleteModal}
                products={[]}
              />
            )
          }
          {
            // Show error modal on empty search criteria
            showErrorModal && !isSearchResult && (
              <ErrorModal
                onClose={onCloseErrorModal}
                open={showErrorModal}
                title={"Component Search Page Error"}
                confirmLabel={"OK"}
                onConfirm={onCloseErrorModal}
                errors={showErrorModal ? ErrorMessage : []}
              />
            )
          }
          {
            showRevertModal && (
              <RevertBackModal
                components={modalData}
                onCancel={onCloseRevertModal}
                onConfirm={revertSelectedItems}
                products={[]}
              />
            )
          }
          {mainContent}
        </>
      </PageWrapper>
    </>
  );
};

const MessageWrapper = styled(Box)({
  marginBottom: "1rem",
  maxHeight: "0.625rem",
  minHeight: "0.625rem",
});
