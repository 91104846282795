import * as React from "react"

const SvgComponent = (props) => (
  <svg width={8} height={12} {...props}>
    <path
      d="M0 10.59 1.41 12l6-6-6-6L0 1.41 4.58 6z"
      fill="#CBCBCB"
      fillRule="evenodd"
    />
  </svg>
)

export default SvgComponent
