import React, { Component }      from "react"

const SvgIcon = () =>
{
    return <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Onboarding-Annotated" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" >
        <g id="04_BOM_Visual" transform="translate(-1209.000000, -281.000000)" fill="#04ADC3" fillRule="nonzero">
            <g id="TOC" transform="translate(1156.000000, 70.000000)">
                <g id="Step-2" transform="translate(17.000000, 204.000000)">
                    <g id="Off" transform="translate(0.000000, 7.000000)">
                        <circle id="Oval" cx="45" cy="9" r="9"></circle>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
}

export default SvgIcon
