import { Link } from "common/components/link";
import { useMemo } from "react";
import { SourceType } from "build/models";

export interface BuildCpnFieldProps {
  cpn: string;
  extId?: string;
  type: SourceType;
  url?: string;
}

export function BuildCpnField({ cpn, extId, type, url }: BuildCpnFieldProps) {
  const finalUrl = useMemo(() => {
    if (url?.length) return url;

    switch (type) {
      case SourceType.DURO_BUILD_PRODUCTION: return `/build/production/${extId}`;
      case SourceType.DURO_BUILD_REVISION: return `/build/revision/${extId}`;
      case SourceType.DURO_COMPONENT: return `/component/view/${extId}`;
      case SourceType.DURO_COMPONENT_REVISION: return `/component/revision/${extId}`;
      case SourceType.DURO_PRODUCT: return `/product/view/${extId}`;
      case SourceType.DURO_PRODUCT_REVISION: return `/product/revision/${extId}`;
      default: return "";
    }
  }, [extId, type, url]);

  return (
    <Link to={finalUrl}>{cpn}</Link>
  );
}
