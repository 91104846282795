import React, { Component }      from "react"

const SvgIcon = () =>
{
    return <svg width="33px" height="38px" viewBox="0 0 33 38" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="LETS-GET-STARTED-3B" transform="translate(-770.000000, -365.000000)" fill="#18171D" fillRule="nonzero">
            <g id="noun_group_61731" transform="translate(500.000000, 365.000000)">
                <path d="M278.25,8.76923077 C278.25,3.8 281.825,0 286.5,0 C291.175,0 294.75,3.8 294.75,8.76923077 C294.75,13.7384615 291.175,17.5384615 286.5,17.5384615 C281.825,17.5384615 278.25,13.7384615 278.25,8.76923077 Z M286.5,23.3846154 C273.85,23.3846154 270,33.0307692 270,33.0307692 L270,38 L303,38 L303,33.0307692 C303,33.0307692 299.15,23.3846154 286.5,23.3846154 Z" id="Shape"></path>
            </g>
        </g>
    </g>
</svg>

}

export default SvgIcon

