import            "./index.css"
import React, { Component } from 'react';

class DefaultAlert extends Component
{
    render()
    {
        let markup = null
        markup =
            <div className="ui-alert default">
                <input type="text" autoFocus={true}/>
                <div className="background" onClick={this.props.reject}></div>
                <div className="modal">
                    <div className="messages">
                        <h1>Something went wrong!</h1>
                    </div>
                    <div className="buttons">
                        <button onClick={this.props.confirm}>OK</button>
                    </div>
                </div>
            </div>

        return markup
    }
}

export default DefaultAlert
