import React from "react"
import Link  from "../link"
import Icon  from "../icon"

const NavTab = (props) => {
    return <span data-tip={props.tip} data-place="bottom" className={props.className}>
                <Link
                    onClick={props.onClick}
                    to={props.to}
                    skipUILink={true}
                    className={props.linkClass}>
                    <Icon src={props.iconSrc} /><span>{props.linkText}</span>
                </Link>
            </span>
}

export default NavTab
