import React, { Component }      from "react"

const SvgIcon = () =>
{
    return <svg width="13px" height="13px" viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="error-icon" transform="translate(-1509.000000, -99.000000)">
                <g id="Group-2" transform="translate(1381.000000, -2.000000)">
                    <g id="update-status-modal-error-icon" transform="translate(56.000000, 90.000000)">
                        <g id="WARNING" transform="translate(72.000000, 11.000000)">
                            <g id="baseline-warning-24px" transform="translate(-0.866667, -2.000000)">
                                <ellipse id="Oval-2" fill="#18171D" fillRule="nonzero" cx="7.36666667" cy="9" rx="1.3" ry="5"></ellipse>
                                <polygon id="Shape" points="0 0 14.7333333 0 14.7333333 17 0 17"></polygon>
                                <path d="M0.866666667,15 L13.8666667,15 L7.36666667,2 L0.866666667,15 Z M7.95757576,12.9473684 L6.77575758,12.9473684 L6.77575758,11.5789474 L7.95757576,11.5789474 L7.95757576,12.9473684 Z M7.95757576,10.2105263 L6.77575758,10.2105263 L6.77575758,7.47368421 L7.95757576,7.47368421 L7.95757576,10.2105263 Z" id="Shape" fill="#F5A64F" fillRule="nonzero"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
}

export default SvgIcon
