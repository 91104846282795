import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export interface IBreadCrumbProps {
  icon?: BreadCrumbIcon,
  label: string,
  url?: string,
}

export interface IBreadCrumb {
  breadCrumbOptions: IBreadCrumbProps[],
}
export interface ILocation {
  state: IBreadCrumb,
}

export const enum BreadCrumbIcon {
  CHANGEORDER = "CHANGEORDER",
  COMPONENTS = "COMPONENTS",
  DASHBOARD = "DASHBOARD",
  LOT = "LOT",
  PRODUCTS = "PRODUCTS",
}

export const useBreadCrumb = ({ icon, label, url }: IBreadCrumbProps) => {
  const { state = { breadCrumbOptions: [] } }: ILocation = useLocation();
  return useMemo(
    () => ([...state?.breadCrumbOptions ?? [], { icon, label, url }]),
    [icon, label, state, url],
  );
};
