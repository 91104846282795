
import Utils from "../../utils"

class ReleaseAPI
{
    static findById(id, cb)
    {
        Utils.get("/releases/" + id, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static syncRelease(cb)
    {
        Utils.post("/releases/sync", {}, (err, res) =>
        {
            if(err)
            {
                return cb(err);
            }

            cb(null, res.data);
        });
    }
}

export default ReleaseAPI
