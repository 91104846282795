import { IconButton, styled } from "@mui/material";
import { RemoveIcon } from "assets/icons";
import { MouseEvent, useCallback } from "react";

export type RemoveClickEventHandler = (id: string, event: MouseEvent<HTMLElement>) => void;

interface RemoveFieldProps {
  id: string;
  onRemove: RemoveClickEventHandler;
}

const RemoveButton = styled(IconButton)(() => ({
  padding: 0,
  margin: "auto",
  "& svg": {
    fontSize: "1rem",
  },
}));

export function RemoveField({ id, onRemove }: RemoveFieldProps) {
  const onClick = useCallback((e: MouseEvent<HTMLElement>) => onRemove(id, e), [id, onRemove]);

  return (
    <RemoveButton onClick={onClick}><RemoveIcon /></RemoveButton>
  );
}
