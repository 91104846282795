import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="18"
      viewBox="0 0 25 18"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-898 -210)">
          <g transform="translate(429 44)">
            <g transform="translate(50 163)">
              <g transform="translate(403)">
                <g transform="translate(16)">
                  <g>
                    <path
                      className='path'
                      fill="#CFCFCF"
                      fillRule="nonzero"
                      stroke="#2A2A36"
                      strokeWidth="0.5"
                      d="M20.167 18.583c1.054 0 1.916-.937 1.916-2.083V6.083c0-1.146-.862-2.083-1.916-2.083H4.833c-1.054 0-1.916.938-1.916 2.083V16.5c0 1.146.862 2.083 1.916 2.083H1v2.084h23v-2.084h-3.833zM4.833 6.083h15.334V16.5H4.833V6.083z"
                    ></path>
                    <path d="M0 0L25 0 25 25 0 25z"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
