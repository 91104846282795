import React, { Component }      from "react";

const SvgIcon = () =>
{
    return <svg width="17px" height="15px" viewBox="0 0 17 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="REFRESH-6" transform="translate(-122.000000, -341.000000)">
                <g id="WARNING" transform="translate(122.000000, 341.000000)">
                    <g transform="translate(-0.772727, -1.973684)" id="baseline-warning-24px">
                        <g>
                            <polygon id="Shape" points="0 0 19 0 19 19 0 19"></polygon>
                            <path d="M1.11764706,16.7647059 L17.8823529,16.7647059 L9.5,2.23529412 L1.11764706,16.7647059 Z M10.2620321,14.4705882 L8.73796791,14.4705882 L8.73796791,12.9411765 L10.2620321,12.9411765 L10.2620321,14.4705882 Z M10.2620321,11.4117647 L8.73796791,11.4117647 L8.73796791,8.35294118 L10.2620321,8.35294118 L10.2620321,11.4117647 Z" id="Shape" fill="#F5A64F" fillRule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>

}

export default SvgIcon;
