import { SvgIcon as MuiSvgIcon, styled } from "@mui/material";

export const BuildWorkspaceIcon = () => (
  <SvgIcon
    width="40"
    height="40"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <path id="b8s3f5yt7a" d="M.62 0h13.281v24H.621z" />
    </defs>
    {/* Intentional fill property is added on the build Workspace Icon */}
    <g fill="none" fillRule="evenodd">
      <rect width="40" height="40" rx="8" fill="#BF8AFF" />
      <g transform="translate(13.393 8)">
        <mask id="6sndz6aleb" fill="#fff">
          <use xlinkHref="#b8s3f5yt7a" />
        </mask>
        {/* eslint-disable-next-line max-len */}
        <path d="M8.59 12.312c0-1.187-.75-1.469-1.345-1.469H6.84V6.656c.5-.375 1.219-.687 2.281-.687 2.813 0 4.781 1.531 4.781 6.093v5.844c0 4.563-1.968 6.094-4.78 6.094-1.032 0-1.782-.313-2.282-.657v-4.218h.406c.594 0 1.344-.282 1.344-1.47v-5.343zM.62 23.718h5.281V0H.621v23.718z" fill="#231F20" mask="url(#6sndz6aleb)" />
      </g>
    </g>
  </SvgIcon>
);

const SvgIcon = styled(MuiSvgIcon)(
  {
    width: "2.5rem",
    height: "2.5rem",
    marginRight: "0.7rem",
  },
);
