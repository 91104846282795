import React from "react";

function Icon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 35 35'>
      <g data-name='Layer 2'>
        <g>
          <path
            d='M35 1.44L33.57 0 17.5 16.07 1.43 0 0 1.44 16.07 17.5 0 33.57 1.43 35 17.5 18.93 33.57 35 35 33.57 18.93 17.5 35 1.44z'
            className='cls-10000'
          ></path>
          <path d='M0 0H35V35H0z' className='cls-2'></path>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
