export const initialState = {
    currentTemplate: {},
    templates: [],
    privateTemplates: [],
    companyTemplates: [],
    recentTemplates: [],
    options: [],
    isPublic: false,
    saveTemplateName: "",
    updateTemplateName: "",
    displaySaveTemplateModal: false,
    displayManageTemplatesModal: false,
}

export const reducer = (state, action) => {
  const { type, payload } = action
  return { ...state, [type]: payload }
}

