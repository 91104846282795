export enum ChangeOrderTabValue {
    APPROVERS = "approverList",
    CMP_PRD = "chidrenData",
    DOCUMENTS = "documents",
    HISTORY = "history",
    NOTIFIERS = "notificationList",
}

export const enum ChangeOrderType {
    DCO = "dco",
    ECO = "eco",
    MCO = "mco",
}
