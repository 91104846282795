import { useCallback, useMemo } from "react";
import { ApolloClient, ApolloError, FetchPolicy, NormalizedCacheObject, gql, useLazyQuery } from "@apollo/client";
import { Component } from "design/models/component";
import { ComponentsFragment, ComponentsLastReleasedRevisionFragment } from "graphql/fragment/componentsFragment";
import { GridSortDirection } from "@mui/x-data-grid-pro";
import { parseSearchString } from "design/utils/search";
import { Role } from "common/constants";
import { useUser } from "./userQueries";
import { Lot } from "build/models";
import { ComponentsLotFragment } from "graphql/fragment/componentFragment";

export const GET_COMPONENTS = gql`
  query(
    $libraryType: LibraryType, $orderBy: [ComponentsOrderByInput],
    $pageSize: Int, $endCursor: String, $search: SearchFields
  ) {
    components(libraryType: $libraryType, orderBy: $orderBy, search: $search) {
      ...componentsFragment
    }
  }
  ${ComponentsFragment}
`;

export const GET_COMPONENTS_LAST_RELEASE_REVISION = gql`
  query(
    $libraryType: LibraryType, $orderBy: [ComponentsOrderByInput],
    $pageSize: Int, $endCursor: String, $search: SearchFields
  ) {
    components(libraryType: $libraryType, orderBy: $orderBy, search: $search) {
      ...componentsLastReleasedRevisionFragment
    }
  }
  ${ComponentsLastReleasedRevisionFragment}
`;

const GET_COMPONENT_WITH_LOTS = gql`
  query componentsWithLots($ids: [ID!]!) {
    componentsByIds(ids: $ids) {
      ...componentsLotFragment
    }
  }
  ${ComponentsLotFragment}
`;

export type OrderByType = { [field: string]: GridSortDirection }[] | undefined;

interface IComponentListArgs {
  endCursor?: string,
  fetchPolicy?: FetchPolicy,
  libraryType?: string,
  orderBy?: [],
  pageSize?: number,
}

interface ComponentsQueryData {
  components?: {
    connection: {
      edges: { node: Component }[],
      pageInfo: {
        hasNextPage: boolean,
        endCursor?: string,
      },
      totalCount: number,
    }
  }
}

export interface IComponentList {
  components: Component[],
  endCursor?: string,
  error?: ApolloError,
  fetchComponents?: (endCursor: string | undefined, orderBy: any, searchString?: string) => void,
  hasNextPage?: boolean,
  loading?: boolean,
  totalCount: number,
}

interface CmpWithLots {
  build: {
    lots: Lot[]
  }
}

interface ComponentsWithLotsQueryData {
  componentsByIds?: CmpWithLots[]
}

/**
 * Loads up all the components.
 *
 * @param LibraryType Library of which components to be load.
 * @param orderBy order in which components to be sorted.
 * @param fetchPolicy How to fetch the data from the API.
 * @returns The components when loaded, errors, and the loading state.
 */
export function useComponentList(args: IComponentListArgs): IComponentList {
  const {
    fetchPolicy = "network-only",
    libraryType,
    pageSize = 30,
  } = args;

  const { role } = useUser().data ?? {};
  let QUERY;
  switch (role) {
    case Role.VENDOR:
      QUERY = GET_COMPONENTS_LAST_RELEASE_REVISION;
      break;
    default:
      QUERY = GET_COMPONENTS;
      break;
  }

  const [loadComponents,
    {
      data,
      error,
      loading,
    },
  ] = useLazyQuery<ComponentsQueryData>(QUERY, { fetchPolicy });
  const { edges, totalCount = 0, pageInfo } = data?.components?.connection ?? {};

  const fetchComponents = useCallback((endCursor, orderBy, searchString) => {
    loadComponents({
      variables: {
        endCursor,
        libraryType,
        orderBy,
        pageSize,
        search: parseSearchString(searchString),
      },
    });
  }, [libraryType, pageSize, loadComponents]);

  const components: Component[] = useMemo(() => {
    if (!edges) return [];
    return edges.map(({ node }) =>
      (role === Role.VENDOR ? node.lastReleaseRevision : node)).filter(n => !!n) as Component[];
  }, [edges, role]);

  return {
    components,
    endCursor: pageInfo?.endCursor,
    error,
    fetchComponents,
    hasNextPage: pageInfo?.hasNextPage,
    loading,
    totalCount,
  };
}

export function useComponentWithLots(fetchPolicy: FetchPolicy = "cache-first") {
  const [getComponentsWithLots,
    {
      data,
      error,
      loading,
    },
  ]  = useLazyQuery<ComponentsWithLotsQueryData>(GET_COMPONENT_WITH_LOTS, { fetchPolicy });
  const fetchCmpWithLots = useCallback((ids: string[]) => {
    ids?.length && getComponentsWithLots({
      variables: { ids },
    });
  }, [getComponentsWithLots]);

  const updateCmpLotsCache = useCallback((
    client: ApolloClient<NormalizedCacheObject>,
    newLot: Lot,
    ids: string[],
  ) => {
    const prevData = client.readQuery({
      query: GET_COMPONENT_WITH_LOTS,
      variables: { ids },
    });
    const newData = {
      ...(prevData && {
        ...prevData,
        componentsByIds: (prevData.componentsByIds || [])
          .map((cmp: CmpWithLots) => ({
            ...cmp,
            build: {
              ...cmp.build,
              lots: [...(cmp.build.lots || []), newLot],
            },
          })),
      }),
    };
    client.writeQuery({
      query: GET_COMPONENT_WITH_LOTS,
      variables: { ids },
      data: newData,
    });
  }, []);
  const cmpLotsData = useMemo(() => data?.componentsByIds?.[0], [data?.componentsByIds]);
  return {
    fetchCmpWithLots,
    cmpLotsData,
    cmpLotError: error,
    cmpLotLoading: loading,
    updateCmpLotsCache,
  };
}
