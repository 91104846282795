import               "./index.css";
import React    from "react";
import ModalBox from "../../../../ui/modal-box";
import API      from "../../../../../modules/api";
import Utils    from "../../../../../modules/utils";

export default function UnlinkWarningModal(props)
{
  let {hideModal, unlinkComponent, label} = props;
  return(
    <div className='unlink-modal'>
      <ModalBox onClose={hideModal}>
        <div>
          <div className='top-section'>
            <h2>{`Component created from ${Utils.capitalizeString(label)}`} </h2>
            <p> This component can be unlinked from its original source. </p>
            <p> WARNING: This permanently unlinks the component from the 3rd party
              application source. This action can not be undone. </p>
          </div>
          <div className='bottom-section'>
            <button className='dark allow' onClick={hideModal}>Cancel </button>
            <button className='filled allow' onClick={unlinkComponent}>Unlink </button>
          </div>
        </div>
      </ModalBox>
    </div>
  )
}
