import                              "./index.css";
import Link                         from "../../../../ui/link";
import Utils                        from "../../../../../modules/utils";
import Icon                         from "../../../../ui/icon";
import ExtendedTable                from "../../../common/extended-table";
import React, {Component}           from "react";
import API                          from "../../../../../modules/api";
import SourcingUtils                from "../../../common/sourcing/sourcing-utils.js";
import AlertErrorSrc                from "../../../../../assets/icons/alert-error.svg";
import AlertErrorIcon               from "../../../../../assets/icons/alert-error"
import WarningTriangleIcon          from "../../../../../assets/icons/warning-triangle"
import InlineIcon                   from "../../../../ui/icon/inline-icon.js"
import Schemas                      from "../../../../../modules/schemas";
import Spinner                      from "../../../../ui/spinner";
import RevisionField                from "../../revision-field";
import StatusField                  from "../../status-field";
import CpnField                     from "../../cpn-field";
import CurrencyField                from "../../currency-field";
import MassField                    from "../../mass-field";

class Variants extends Component
{
    constructor(props)
    {
        super(props)
        this.state =
        {
            showLoading: false,
            assemblyHeadings : [],
            documentHeadings : [],
            specsHeadings : [],
            isLadingFromApi: false
        }
        this.getTableRowsForAssembly    = this.getTableRowsForAssembly.bind(this);
        this.getTableRowsForDocumenst   = this.getTableRowsForDocumenst.bind(this);
        this.getTableRowsForSpecification = this.getTableRowsForSpecification.bind(this);
        this.getDocuments               = this.getDocuments.bind(this);
        this.sortByCpn                  = this.sortByCpn.bind(this);
        this.getFlattenedBOM            = this.getFlattenedBOM.bind(this);
        this.getRolledUpCostRow         = this.getRolledUpCostRow.bind(this);
        this.saveMyStylesForAssemblyTable  = this.saveMyStylesForAssemblyTable.bind(this);
        this.saveMyStylesForDocumentsTable = this.saveMyStylesForDocumentsTable.bind(this);
        this.saveMyStylesForSpecsTable  = this.saveMyStylesForSpecsTable.bind(this);
        this.createVarintTabHeadings    = this.createVarintTabHeadings.bind(this);
        this.getDataFromApi             = this.getDataFromApi.bind(this);
        this.generateVariantsTable      = this.generateVariantsTable.bind(this);
        this.generateSpecsTable         = this.generateSpecsTable.bind(this);
        this.generateDocumentsTable     = this.generateDocumentsTable.bind(this);
    }

    componentWillMount()
    {
        let objectData = this.props.objectData
        let variants = this.props.variants
        if (this.props.history.location && this.props.history.location.variants && this.props.history.location.variants.length !== 0)
        {
            variants = this.props.history.location.variants
        }

        if (objectData && objectData.variantGroup && variants.length === 0)
        {
            this.getDataFromApi(objectData);
            if (objectData.variants && objectData.variants.items && objectData.variants.items.length)
            {
                this.createVarintTabHeadings(objectData.variants.items, true)
            }
        }
        else
        {
            this.createVarintTabHeadings(variants)
        }
    }

    getDataFromApi(objectData)
    {
        let state = this.state;
        state.list = [];
        state.showLoading = true
        this.setState(state)
        let _this = this
        if(objectData.alias === "prd")
        {
            API.products.getProductVariants(objectData.variantGroup, {BOMTree: true} , (err, data) =>
            {
                if(data)
                {
                    _this.createVarintTabHeadings(data.items)
                    _this.props.saveVariantsInParent(data.items)
                }
            })
        }
        else if(objectData.alias === "cmp")
        {
            API.components.getComponentVariants(objectData.variantGroup, {BOMTree: true} , (err, data) =>
            {
                if(data)
                {
                    _this.createVarintTabHeadings(data.items)
                    _this.props.saveVariantsInParent(data.items)
                }
            })
        }
    }

    componentWillReceiveProps(nextProps)
    {
        if(nextProps.getDataFromApi)
        {
            let state = this.state;
            state.assemblyHeadings = [];
            state.documentHeadings = [];
            state.specsHeadings = [];
            this.setState(state);
            this.getDataFromApi(nextProps.objectData);
            this.props.updateVariantsTab(false);
        }
    }

    createVarintTabHeadings(variants, resetList=false)
    {
        let objectData = this.props.objectData
        let state = this.state
        state.list = variants
        if(!resetList)
        {
            state.showLoading = false
            state.assemblyHeadings = [];
            state.specsHeadings  = [];
            state.documentHeadings = [];
            this.setState(state)
        }

        let cmpsHeading =
             {
                key                 : "cmpsHeading",
                columnSettingText   : "Components",
                tooltip             : "Components",
                displayName         : "Components",
                sortable            : false,
                minWidth            : 200,
                width               : Utils.getStore("assembly-tab-cmp-column") || 242,
                position            : 0,
                visibility          : true,
                disabled            : true,
                dragable            : false,
                hideFromSettings    : true
            }

        let isExist = false;
        state.assemblyHeadings.forEach((item,i) =>
        {
            if(item.key === cmpsHeading.key)
            {
                isExist = true;
            }
        })

        if(!isExist)
        {
            state.assemblyHeadings.push(cmpsHeading)
        }

        let docsHeading =
             {
                key                 : "docsHeading",
                columnSettingText   : "Documents",
                tooltip             : "Documents",
                displayName         : "Documents",
                sortable            : false,
                minWidth            : 200,
                width               : Utils.getStore("document-tab-cmp-column") || 242,
                position            : 0,
                visibility          : true,
                disabled            : true,
                dragable            : false,
                hideFromSettings    : true
            }
        isExist = false;
        state.documentHeadings.forEach((item,i) =>
        {
            if(item.key === docsHeading.key)
            {
                isExist = true;
            }
        })

        if(!isExist)
        {
            state.documentHeadings.push(docsHeading);
        }
        let specsHeadings =
             {
                key                 : "specsHeading",
                columnSettingText   : "Specs",
                tooltip             : "Specs",
                displayName         : "Specs",
                sortable            : false,
                minWidth            : 200,
                width               : Utils.getStore("specs-tab-cmp-column") || 242,
                position            : 0,
                visibility          : true,
                disabled            : true,
                dragable            : false,
                hideFromSettings    : true
            }

        isExist = false;
        state.specsHeadings.forEach((item,i) =>
        {
            if(item.key === specsHeadings.key)
            {
                isExist = true;
            }
        })

        if(!isExist)
        {
            state.specsHeadings.push(specsHeadings);
        }

        state.list.forEach((variant, i) => {
            let state = this.state
            let pathname = null;

            if(variant.alias === 'prd')
            {
                pathname = `/product/view/${variant._id}`
            }
            else
            {
                pathname = `/component/view/${variant._id}`
            }

            let to = {pathname: pathname, variants: state.list}
            let heading =
             {
                key                         : ("cpn-" + variant.cpn),
                columnSettingText           : variant.cpn,
                tooltip                     : variant.cpn,
                displayName                 :
                                            <Link className="link cpn-heading-link" to={to}>
                                                <div className="handler sindu_handle" data-row={"handler"}>
                                                    <CpnField item={variant} cpn={variant.cpn}/>
                                                </div>
                                            </Link>,
                sortable                    : false,
                minWidth                    : 130,
                width                       : 130,
                position                    : i + 1,
                visibility                  : true,
                disabled                    : false,
                disabledDefaultDragble      : true,
                headingClass                : (objectData._id === variant._id ? "active-variant" : "")
            }

            let specHeading =
            {
                key                         : ("cpn-" + variant.cpn),
                columnSettingText           : variant.cpn,
                tooltip                     : variant.cpn,
                displayName                 :
                                            <Link className="link cpn-heading-link" to={to}>
                                                <div className="handler sindu_handle" data-row={"handler"}>
                                                    <CpnField item={variant} cpn={variant.cpn}/>

                                                </div>
                                            </Link>,
                sortable                    : false,
                minWidth                    : 130,
                width                       : 130,
                position                    : i + 1,
                visibility                  : true,
                disabled                    : false,
                disabledDefaultDragble      : true,
                headingClass                : (objectData._id === variant._id ? "active-variant" : "")
            }



            let docHeading =
            {
                key                         : ("cpn-" + variant.cpn),
                columnSettingText           : variant.cpn,
                tooltip                     : variant.cpn,
                displayName                 :
                                            <Link className="link cpn-heading-link" to={to}>
                                                <div className="handler sindu_handle" data-row={"handler"}>
                                                    <CpnField item={variant} cpn={variant.cpn}/>
                                                </div>
                                            </Link>,
                sortable                    : false,
                minWidth                    : 130,
                width                       : 130,
                position                    : i + 1,
                visibility                  : true,
                disabled                    : false,
                disabledDefaultDragble      : true,
                headingClass                : (objectData._id === variant._id ? "active-variant" : "")
            }

            isExist = false;
            state.assemblyHeadings.forEach((item,i) =>
            {
                if(item.key === heading.key)
                {
                    isExist = true;
                }
            })

            if(!isExist)
            {
                state.assemblyHeadings.push(heading);
                state.specsHeadings.push(specHeading);
                state.documentHeadings.push(docHeading);
            }
        })

        if (resetList)
        {
            state.list = []
        }
        else
        {
            state.isLadingFromApi = true
        }

        this.setState(state)
    }

    sortByCpn(parsedFlatBOM)
    {
        let ascending = true
        parsedFlatBOM.sort((a,b) =>
        {
            a = Utils.hash("cpn", a)
            b = Utils.hash("cpn", b)

            if(typeof a === "string") a = a.toLowerCase()
            if(typeof b === "string") b = b.toLowerCase()

            if(a < b) return ascending ? -1 :  1
            if(a > b) return ascending ?  1 : -1
            return 0
        })
        return parsedFlatBOM
    }

    getFlattenedBOM(variants)
    {
        let flatBOM = []
        let uniqBOMIdz = []
        
        variants.forEach((variant) => {
            if(variant.flattenedBom && variant.flattenedBom.length) {
                variant.flattenedBom.forEach((bom) => {
                    if(bom.childRev) {
                        const { _id, cpn, name } = bom.childRev;
                        if (!uniqBOMIdz.includes(_id)) {
                            uniqBOMIdz.push(_id);
                            flatBOM.push({
                                _id,
                                cpn,
                                name,
                                qty: bom.quantity
                            });
                        }
                    }
                })
            }
        });
        flatBOM = this.sortByCpn(flatBOM)
        return flatBOM
    }

    getDocuments(variants)
    {
        let uniqDocs = []
        let uniqDocIdz = []
        variants.forEach((variant) => {
            variant.documents.forEach((doc) =>
            {
                if (!uniqDocIdz.includes(doc.file._id))
                {
                    uniqDocIdz.push(doc.file._id)
                    uniqDocs.push(doc)
                }
            })
        })
        return uniqDocs;
    }

    performNoEvent(e){
        e.stopPropagation()
        e.preventDefault()
    }

    getRolledUpCostRow(variants)
    {
        let rolledUpCostRow = {}
        for(let variant of variants)
        {
            let data = SourcingUtils.getRolledUpCostFromChildren(variant.children)
            let cell =
            {
                value           : this.props.defaultCurrency + parseFloat(data.totalPrice).toFixed(5),
                displayValue    :  <span className="unit-price-rolledup">
                                    {<CurrencyField symbol={this.props.defaultCurrency} value={data.totalPrice}/>}
                                    {
                                        data.inCompleteCost &&
                                        <span className="not-all-valid">
                                            <Icon
                                                tooltip="Incomplete Sources"
                                                tooltipPlace="top"
                                                src={AlertErrorSrc}
                                                className="warningEl not-all-valid-s"
                                                />
                                        </span>
                                    }
                                </span>,
                cellClass       : "grey-highlighted text-center",
                tooltip         : this.props.defaultCurrency + parseFloat(data.totalPrice).toFixed(5),

            }
            rolledUpCostRow["cpn-" + variant.cpn] = cell
        }

        rolledUpCostRow.cmpsHeading = {
            value               : "Rolled Up Cost",
            displayValue        : "Rolled Up Cost",
            tooltip             : "Rolled Up Cost",
            cellClass           : "grey-highlighted"
        }
        rolledUpCostRow.indexTableEl = <span></span>

        return rolledUpCostRow
    }

    getTableRowsForAssembly()
    {
        let state = this.state
        let variants = state.list
        let flatBOM = this.getFlattenedBOM(variants)
        let rolledUpCostRow = this.getRolledUpCostRow(variants)
        let rows = [rolledUpCostRow]
        flatBOM.forEach((item, i) =>
        {
            let isSameQty = true
            let lastVariantQty = null
            let row =
            {
                "cmpsHeading":
                {
                    value           : Utils.getCpn(item)+ " " + item.name,
                    displayValue    :
                        <span>
                            <span className="link cpn-link">
                                {item.cpn}
                            </span>
                            <span>{item.name}</span>
                        </span>,
                    tooltip         : item.cpn+ " " + item.name
                }
            }

            for(let variant of variants)
            {
                let qty = null
                if(variant.flattenedBom && variant.flattenedBom.length) {
                    variant.flattenedBom.forEach((bom) => {
                        if (bom.childRev && item._id.toString() === bom.childRev._id.toString()) {
                            qty += bom.quantity;
                        }
                    })
                }

                if (lastVariantQty !== null && lastVariantQty !== qty && isSameQty || qty === null)
                {
                    isSameQty = false
                }

                lastVariantQty = qty
                let cell =
                {
                    value           : qty || "—",
                    displayValue    : qty || "—",
                    cellClass       : "text-center"

                }
                row["cpn-" + variant.cpn] = cell
            }

            //set not-same class if qty is not same
            if (!isSameQty)
            {
                for(let variant of variants)
                {
                    row["cpn-" + variant.cpn].cellClass = 'not-same text-center'
                }
            }
            row.indexTableEl = i+1
            row.rowLink = (`/component/revision/${item._id}`);
            rows.push(row)
        })

        return rows

    }

    getTableRowsForDocumenst()
    {
        let state = this.state
        let variants = state.list
        let documents = this.getDocuments(variants)
        let rows = [];
        if(documents.length === 0)
        {
            let row =
            {
                "docsHeading":
                {
                    value           : '—',
                    displayValue    : '—',
                    cellClass       : "text-center"
                }
            }
            let cell =
            {
                value           : "—",
                displayValue    : "—",
                cellClass       : "text-center"
            }
            variants.forEach((variant) =>
            {
                row["cpn-" + variant.cpn] = cell
            });
            row.indexTableEl = <span></span>;
            rows.push(row)
            return rows;
        }

        documents.forEach((item, i) =>
        {
            let isSameRev = true
            let lastVariantRev = null
            let row =
            {
                "docsHeading":
                {
                    value           : item.file.name,
                    displayValue    :
                        <span>
                            <span>
                                {item.file.name}
                            </span>
                        </span>,
                    tooltip         : item.file.name
                }
            }

            for(let variant of variants)
            {
                let revision = null
                variant.documents.forEach((doc) => {
                    if (item.file._id === doc.file._id)
                    {
                        revision = doc.specs.revision;
                    }
                })

                if (lastVariantRev !== null && lastVariantRev !== revision && isSameRev || revision === null)
                {
                    isSameRev = false
                }

                lastVariantRev = revision
                let cell =
                {
                    value           : revision ? `${revision}` : "—",
                    displayValue    : revision ? `${revision}` : "—",
                    cellClass       : "text-center"

                }
                row["cpn-" + variant.cpn] = cell
            }

            //set not-same class if rev is not same
            if (!isSameRev)
            {
                for(let variant of variants)
                {
                    row["cpn-" + variant.cpn].cellClass = 'not-same text-center'
                }
            }
            row.indexTableEl = i+1
            rows.push(row)
        })
        return rows
    }

    getTableRowsForSpecification()
    {
        let state = this.state
        let { massPrecision } = this.props;
        let variants = state.list
        let specs = ["eid", "revision", "status", "created", "creator","lastModified","minQuantity", "unitPrice", "leadTime", "mass"];
        let specsLabels = ["EID","REVISION", "STATUS", "CREATED", "CREATOR", "LAST MODIFIED", "MIN QTY", "UNIT PRICE", "LEAD TIME", "MASS"];

        variants.forEach((variant) =>
        {
            if(variant.specs)
            {
                variant.specs.forEach((item) =>
                {
                    if(!specs.includes(item.key))
                    {
                        specs.push(item.key);
                    }
                });
            }
        });

        let rows = [];
        specs.forEach((item, i) =>
        {
            let isSameSpec = true
            let lastVariantspec = null
            let specList = []
            let row =
            {
                "specsHeading":
                {
                    value           : item,
                    displayValue    : <span> {specsLabels[i] ? specsLabels[i] : item.toUpperCase()} </span>,
                    tooltip         : item
                }
            }

            for(let variant of variants)
            {
                let specValue = null
                if(variant[item] || variant[item] === 0)
                {
                    if(item === "created" || item === "lastModified")
                    {
                        specValue = Utils.dateInUSFormat(variant[item]);
                    }
                    else if(item === "creator")
                    {
                        specValue = variant.creator.firstName + " " + variant.creator.lastName;
                    }
                    else
                    {
                        specValue = variant[item];
                    }
                }
                else if(variant.primarySource.hasOwnProperty(item))
                {
                    if(item === "leadTime")
                    {
                        if(variant.primarySource[item] && variant.primarySource[item].value)
                        {
                            specValue = `${variant.primarySource[item].value} ${variant.primarySource[item].units}`;
                        }
                        else
                        {
                            specValue = null;
                        }

                    }
                    else if (item === "unitPrice" || item === "minQuantity")
                    {
                       specValue = variant.primarySource[item];
                    }
                }
                else if(variant.hasOwnProperty("specs"))
                {
                    variant.specs.forEach((spec) =>
                    {
                        if(spec.key === item)
                        specValue = spec.value;
                    });
                }

                if(specValue)
                {
                    specValue = specValue.toString().trim();
                }
                specList.push(specValue);

                let cell =
                {

                    value           : specValue ? `${specValue}` : "—",
                    displayValue    : specValue ? `${specValue}` : "—",
                    cellClass       : "text-center"
                }

                if(item === 'status')
                {
                    cell.displayValue = <StatusField item={variant} status={variant.status}/>
                }
                else if(item === 'revision')
                {
                    cell.displayValue = <RevisionField item={variant} revision={variant.revision}/>
                }
                else if(item==="mass" && (variant.massStatus && variant.massStatus.toUpperCase() !== "SUCCESS" || specValue === 0))

                {
                    cell.displayValue = <div className="rolled-up-mass-cell">
                                            <span className="icon-cell">
                                                <MassField
                                                    mass={specValue}
                                                    massPrecision={massPrecision}
                                                />
                                                {
                                                    (variant.massStatus && variant.massStatus.toUpperCase() === "ERROR") &&
                                                    <span className="not-all-valid">
                                                        <InlineIcon
                                                            tooltip="Missing mass values for one or more children"
                                                            tooltipPlace="top"
                                                            className="warning-el not-all-valid-s"
                                                        >
                                                          <AlertErrorIcon />
                                                        </InlineIcon>
                                                    </span>
                                                }
                                                {
                                                    (variant.massStatus && variant.massStatus.toUpperCase() === "WARNING") &&
                                                    <span className="not-all-valid">
                                                        <InlineIcon
                                                            tooltip="Manually entered value"
                                                            tooltipPlace="top"
                                                            className="warning-el not-all-valid-s"
                                                        >
                                                          <WarningTriangleIcon />
                                                        </InlineIcon>
                                                    </span>
                                                }
                                            </span>
                                        </div>
                }
                else if(item === "unitPrice")
                {
                    cell.displayValue = <div className="rolled-up-cost-cell">
                                            <span className="icon-cell">
                                                {
                                                    specValue !== null &&
                                                    <CurrencyField symbol={this.props.defaultCurrency} value={specValue}/>
                                                }
                                                {
                                                    SourcingUtils.haveInValidCosting(variant) === true &&
                                                    <span className="not-all-valid">
                                                        <InlineIcon
                                                            tooltip="Incomplete Sources"
                                                            tooltipPlace="top"
                                                            className="warning-el not-all-valid-s"
                                                        >
                                                          <AlertErrorIcon />
                                                        </InlineIcon>
                                                    </span>
                                                }
                                            </span>
                                        </div>
                }
                row["cpn-" + variant.cpn] = cell
            }

            lastVariantspec = specList[0];
            for(let i=1; i<specList.length; i++)
            {
                if(lastVariantspec !== specList[i])
                {
                    isSameSpec = false;
                    break;
                }
            }

            if (!isSameSpec)
            {
                for(let variant of variants)
                {
                    row["cpn-" + variant.cpn].cellClass = 'not-same text-center'
                }
            }
            row.indexTableEl = i+1
            rows.push(row)
        })

        return rows

    }

    saveMyStylesForAssemblyTable(data)
    {
        Utils.setStore("assembly-tab-cmp-column", data[0].width);
        this.setState(this.state)
    }
    saveMyStylesForDocumentsTable(data)
    {
        Utils.setStore("document-tab-cmp-column", data[0].width);
        this.setState(this.state)
    }
    saveMyStylesForSpecsTable(data)
    {
        Utils.setStore("specs-tab-cmp-column", data[0].width);
        this.setState(this.state)
    }

    generateVariantsTable(assemblyHeadings,rowsForAssemblyTable)
    {
        let table = <div className="assembly-table">
                        <h2 className="variants-tab-label">Assembly</h2>
                        <ExtendedTable
                            wrapperClassName="variant-list"
                            wrapperSelectorClass="assembly-list"
                            themeDataTableId={"assemblyTable"}
                            headings={assemblyHeadings}
                            rows={rowsForAssemblyTable}
                            borderedTable={true}
                            disableDataSorting={true}
                            stylesName="assemblyTableView"
                            allowRowSelect={false}
                            includeToolBar={true}
                            startStaticColumns={1}
                            resultText={`${rowsForAssemblyTable.length ? rowsForAssemblyTable.length - 1 : 0} Components`}
                            saveMyStyles={this.saveMyStylesForAssemblyTable}
                        />
                        {
                            this.state.showLoading ? <Spinner/> : null
                        }
                    </div>
        return table;
    }

    generateSpecsTable(specHeadings, rowsForSpecsTable)
    {
        let table = <div className="comparison-table">
                        <h2 className="variants-tab-label">Specifications</h2>
                        <ExtendedTable
                            wrapperClassName="specs-list"
                            wrapperSelectorClass="specs-list"
                            themeDataTableId={"specsTable"}
                            headings={specHeadings}
                            rows={rowsForSpecsTable}
                            borderedTable={true}
                            disableDataSorting={true}
                            stylesName="specsTableView"
                            allowRowSelect={false}
                            includeToolBar={true}
                            startStaticColumns={1}
                            resultText={`${rowsForSpecsTable.length} Specifications`}
                            saveMyStyles={this.saveMyStylesForSpecsTable}
                        />
                        {
                            this.state.showLoading ? <Spinner /> : null
                        }
                    </div>
        return table;
    }

    generateDocumentsTable(documentHeadings, rowsForDocumentsTable)
    {
        let totalDocuments = this.getDocuments(this.state.list).length;
        let table = <div className="comparison-table">
                        <h2 className="variants-tab-label" >Documents</h2>
                        <ExtendedTable
                            wrapperClassName="documents-list"
                            wrapperSelectorClass="documents-list"
                            themeDataTableId={"docsTable"}
                            headings={documentHeadings}
                            rows={rowsForDocumentsTable}
                            borderedTable={true}
                            disableDataSorting={true}
                            stylesName="documentTableView"
                            allowRowSelect={false}
                            includeToolBar={true}
                            startStaticColumns={1}
                            resultText={`${totalDocuments} Documents`}
                            saveMyStyles={this.saveMyStylesForDocumentsTable}
                        />
                        {
                            this.state.showLoading ? <Spinner /> : null
                        }
                    </div>
        return table;
    }

    render()
    {
        let assemblyHeadings = this.state.assemblyHeadings;
        let documentHeadings = this.state.documentHeadings;
        let specHeadings = this.state.specsHeadings;
        let objectData = this.props.objectData
        let shouldDisplayAssembly = true
        if (objectData && objectData.alias === "cmp")
        {
            let getCategoryType = Schemas.component.category.getType(objectData.category)
            let isAssembly = getCategoryType && getCategoryType.toLowerCase() === "assembly";
            if (!isAssembly) shouldDisplayAssembly = false
        }

        if(assemblyHeadings.length === 0 && documentHeadings.length === 0 && specHeadings.length === 0)
        {
            return <Spinner/>;
        }

        if (!this.state.isLadingFromApi)
        {
            let markup  =
                <div className="variants-table-wrapper">
                    {
                        shouldDisplayAssembly ? this.generateVariantsTable(assemblyHeadings,[]) : null
                    }
                    { this.generateSpecsTable(specHeadings, []) }
                    { this.generateDocumentsTable(documentHeadings, []) }
                </div>
            return markup
        }
        else
        {
            let rowsForAssemblyTable = this.getTableRowsForAssembly()
            let rowsForDocumentsTable = this.getTableRowsForDocumenst()
            if(!rowsForDocumentsTable)
            {
                rowsForDocumentsTable = [];
            }
            let rowsForSpecsTable = this.getTableRowsForSpecification()

            let markup  =
                <div className="variants-table-wrapper">
                    {
                        shouldDisplayAssembly ? this.generateVariantsTable(assemblyHeadings,rowsForAssemblyTable) : null
                    }
                    { this.generateSpecsTable(specHeadings, rowsForSpecsTable) }
                    { this.generateDocumentsTable(documentHeadings, rowsForDocumentsTable) }

                </div>

            return markup
        }
    }
}

export default Variants
