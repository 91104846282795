import Utils from '../../utils';

const oauth2Api = {
    googleCompleteSignUp(data, cb) {
        Utils.post('/auth/google', data, (err, res) => {
            if (err) {
                return cb(err.errors);
            }

            return cb(null, res.data);
        });
    },

    findOauth2User : (id, cb) =>
    {
        Utils.get("/oauth2/user/" + id, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }
};

export default oauth2Api;
