import { ChangeEvent, forwardRef, useMemo } from "react";
import MuiIndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { Checkbox, FormControlLabel, styled, Typography } from "@mui/material";
import { CheckboxFilledIcon, CheckboxUnfilledIcon } from "assets/icons";

interface CustomDuroCheckboxProps {
  checkBoxLabel?: string,
  checked: boolean,
  disabled?: boolean,
  checkboxFontSize?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void,
  labelStyles: {
    fontSize?: string,
    marginLeft?: string,
  }
}

export const DuroCheckbox = forwardRef<any, CustomDuroCheckboxProps>(({
  checkBoxLabel,
  labelStyles,
  ...rest
}, ref) => {
  const _label = useMemo(() => (
    checkBoxLabel && (
      <CustomLabel labelStyles={labelStyles}>
        {checkBoxLabel}
      </CustomLabel>
    )
  ), [checkBoxLabel, labelStyles]);

  return (
    <StyledFormControlLabel
      control={
        <Checkbox
          checkedIcon={<CheckboxFilledIcon />}
          icon={<CheckboxUnfilledIcon />}
          ref={ref}
          indeterminateIcon={<MuiIndeterminateCheckBoxIcon />}
          {...rest}
        />
      }
      label={_label}
    />
  );
});

export const CustomDuroCheckbox = styled(DuroCheckbox, {
  shouldForwardProp: prop => prop !== "checkboxFontSize",
})<Pick<CustomDuroCheckboxProps, "checkboxFontSize">>(
  ({ checkboxFontSize }) => ({
    "& .MuiSvgIcon-root": { fontSize: checkboxFontSize ?? "1.5rem" },
  }),
);

export const CustomLabel = styled(Typography, {
  shouldForwardProp: prop => prop !== "labelStyles",
})<Pick<CustomDuroCheckboxProps, "labelStyles">>(({ labelStyles }) => labelStyles);

export const StyledFormControlLabel = styled(FormControlLabel)({
  margin: "0",
});
