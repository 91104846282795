import React from "react";

const addLabel = (label, isChecked) => {
    const effectOverBorderClass = isChecked ? "" : "disabled-override-existing";
    return (
        <React.Fragment>
            <label className={effectOverBorderClass} />
            <span className={`checkbox-label ${label.textClass}`}>
                {label.value}
            </span>
        </React.Fragment>)
}
const ErpCheckboxes = ({
    name,
    itemStateClass,
    value,
    onInputChange,
    label,
    customClass
}) => {
    return (
        <span className={`custom-checkbox ${customClass}`}>
            <input
                type="checkbox"
                checked={value}
                name={name}
                className={`large ${itemStateClass}`}
                onClick={onInputChange}
            />
            {!!label && addLabel(label, value)}
        </span>
    )
}

export default ErpCheckboxes;