import { SvgIcon } from "@mui/material";

export const SerializationIcon = () => (
  <SvgIcon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="evenodd">
      <path d="M3 21V10.178L9.3 7.5v1.8l4.5-1.823V10.2H21V21H3zm1.35-1.35h15.3v-8.1h-7.2V9.48l-4.5
      1.8V9.503L4.35 11.1v8.55zm6.975-2.25h1.35v-3.6h-1.35v3.6zm-3.6 0h1.35v-3.6h-1.35v3.6zm7.2
      0h1.35v-3.6h-1.35v3.6zM21 10.2h-4.05l.9-7.2h2.25l.9 7.2zM4.35 19.65h15.3-15.3z"/>
    </g>
  </SvgIcon>
);
