import { gql } from "@apollo/client";

// TODO: Fill this fragment out once we have a better idea of what we need to load.
export const CompanyFragment = gql`
  fragment companyFragment on Company {
    id
    archived
    name
    settings {
      customFields {
        warrantyFieldEnabled
        wasteFieldEnabled
      }
      defaultCurrency
      displayRefDesAndItemNumber
      isAllowedBlankItemNumber
      isBuildEnabled
      massPrecisionValue
      unitPricePrecision
      isValispaceEnabled
    }
  }
`;
