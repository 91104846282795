import mapActions from '../helpers/mapActions';

export default mapActions([
  'LOAD_START',
  'LOAD_END',
  'SET_API_FAILED_RESPONSE_ERROR',
  'ADD_NOTIFICATION',
  'REMOVE_NOTIFICATION',
  'BLANK_MESSAGE',
  'CLEAR_NOTIFICATIONS',
  'SET_NOLAYOUT',
  'SET_API_ERROR',
  'SHOW_ALERT',
  'CLOSE_ALERT',
  'START_PROCESSING',
  'END_PROCESSING',
  'VALIDATION_START',
  'VALIDATION_END',
], 'UI');
