import React, {Component}           from "react";
import InlineIcon                   from "../../../../components/ui/icon/inline-icon.js";
import VendorIcon                   from "../../../../assets/icons/vendor-label";
import                                   "./index.css";

export class VendorIndexElement extends Component
{
    constructor(props, context)
    {
        super(props, context);
    }

    render()
    {
        let markup  = <div className={`vendor-index-element ${this.props.wrapperClassName}`}>
                        <InlineIcon className='vendor-index-element-icon' tooltip={this.props.vendorTooltipText} tooltipPlace="top">
                            <VendorIcon/>
                        </InlineIcon>
                    </div>
        return markup;
    }
}

export default VendorIndexElement;
