import React from "react";

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 17 17'
    >
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g fillRule='nonzero' stroke='#FFF' className='variant-stroke' transform='translate(-538 -467)'>
          <g transform='translate(313 179)'>
            <g transform='translate(225 288)'>
              <path d='M1 5.5a.5.5 0 00-.5.5v10a.5.5 0 00.5.5h10a.5.5 0 00.5-.5V6a.5.5 0 00-.5-.5H1z'></path>
              <path d='M6 .5a.5.5 0 00-.5.5v10a.5.5 0 00.5.5h10a.5.5 0 00.5-.5V1a.5.5 0 00-.5-.5H6z'></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
