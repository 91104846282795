import React from "react";

const SvgComponent = props => (
  <svg
    id="collapse-icon"
    width="18px"
    height="16px"
    viewBox="0 0 18 18"
    {...props}
  >
    <g
      transform="translate(-482 -694) translate(377 694) translate(104.975) rotate(-90 9.16 8.34)"
      fillRule="nonzero"
      fill="#FFF"
    >
      <path
        id="Shape"
        stroke="#353540"
        strokeWidth={0.75}
        d="M2 0L1.98951966e-13 0 1.98951966e-13 16 2 16 2 2.30958146e-15z"
      />
      <path
        id="auto-icon"
        d="M3.59 8.27l2.863 3.58a.41.41 0 00.579.056.449.449 0 00.054-.596L4.78 8.421h8.312c.226 0 .409-.188.409-.42a.415.415 0 00-.41-.422H4.78l2.307-2.888a.45.45 0 00-.054-.596.409.409 0 00-.579.056L3.59 7.731c-.128.204-.111.358 0 .54z"
        stroke="#FFF"
        strokeWidth={0.5}
      />
      <path
        id="Shape"
        stroke="#353540"
        strokeWidth={0.75}
        d="M15 16L17 16 17 0 15 0z"
      />
    </g>
  </svg>
);

export default SvgComponent;
