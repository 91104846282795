import { FetchPolicy, gql, useQuery } from "@apollo/client";
import { LotWithInstances } from "build/models/lot";
import { LotWithInstancesFragment } from "graphql/fragment/lotFragment";
import { useMemo } from "react";

export const GET_LOT_WITH_INSTANCES = gql`
  query buildLotById($id: ID!) {
    build {
      lotsByIds(ids: [$id]) {
        ...lotWithInstancesFragment
      }
    }
  }
  ${LotWithInstancesFragment}
`;

export interface BuildQueryData {
  build?: {
    lotsByIds?: LotWithInstances[];
  }
}

/**
 * Loads up the lot and it's children.
 *
 * @param id Id of the lot to load.
 * @param fetchPolicy How to fetch the data from the API.
 * @returns The lot when loaded, errors, and the loading state.
 */
export function useLotWithInstances(id: string, fetchPolicy: FetchPolicy = "cache-first") {
  const { data, error, loading, refetch } = useQuery<BuildQueryData>(GET_LOT_WITH_INSTANCES, {
    variables: { id },
    fetchPolicy,
  });

  const lot = useMemo(() => data?.build?.lotsByIds?.[0], [data?.build?.lotsByIds]);

  return { lot, lotError: error, lotLoading: loading, lotRefetch: refetch };
}
