import { ChangeEvent, ElementType, FC, MouseEvent, useEffect, useMemo, useState } from "react";
import { Box, ToggleButton, ToggleButtonGroup, Typography, styled } from "@mui/material";
import { FieldTitle } from "../inputs";

export interface IIconListProps {
  Icons: {
    defaultSelect?: boolean,
    Icon: ElementType,
    label: string,
  }[],
  isRequired: boolean,
  label: string,
  onChange: (e: ChangeEvent<HTMLInputElement>) => void,
  styleProps?: {
    flex?: string,
  },
}

export const IconsList: FC<IIconListProps> = (({ Icons, label, isRequired, styleProps }) => {
  const [value, setValue] = useState<string>("");

  const handleChange = (event: MouseEvent | React.MouseEvent<HTMLElement, MouseEvent>, newValue: string) => {
    newValue && setValue(newValue);
  };

  const list = useMemo(() => (
    Icons.map(({ Icon, label: _label }, index) => (
      <CustomToggleButton key={index} value={_label} styleProps={styleProps}>
        <Icon />
        <IconLabel> {_label} </IconLabel>
      </CustomToggleButton>
    ))
  ), [Icons, styleProps]);

  useEffect(() => {
    const defaultIcon = Icons.find(icon => icon.defaultSelect);
    if (defaultIcon) {
      setValue(defaultIcon.label);
    }
  }, [Icons]);

  return (
    <IconListWrapper>
      <FieldTitle
        isRequired={isRequired}
        label={label}
        sx={{
          fontSize: "0.75rem",
          marginBottom: "0.5rem",
        }}
      />
      <CustomToggleButtonGroup
        exclusive
        onChange={handleChange}
        value={value}
      >
        {list}
      </CustomToggleButtonGroup>
    </IconListWrapper>
  );
});

const CustomToggleButton = styled(ToggleButton)<Pick<IIconListProps, "styleProps">>(({ styleProps }) => ({
  border: "none",
  display: "block",
  flex: "1",
  ...styleProps,
}));

const CustomToggleButtonGroup = styled(ToggleButtonGroup)({
  display: "flex",
  marginBottom: "0.938rem",
});

const IconLabel = styled(Typography)({
  fontSize: "0.875rem",
});

const IconListWrapper = styled(Box)({
  fontSize: "0.75rem",
});
