import React, { Component }      from "react";

const SvgIcon = () =>
{
    return <svg width="14px" height="9px" viewBox="0 0 14 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="3-ABOVE-FOLD-EDIT-ALT" transform="translate(-825.000000, -781.000000)" fill="#888888" fillRule="nonzero" stroke="#888888" strokeWidth="0.5">
            <g id="IMAGE-POP-UP" transform="translate(716.000000, 674.000000)">
                <g id="category-drop-menu" transform="translate(8.000000, 0.000000)">
                    <g id="Group" transform="translate(29.000000, 106.000000)">
                        <g id="ArrowRight" transform="translate(72.000000, 1.000000)">
                            <path d="M9.41101695,7.86769759 C9.24788136,7.69129439 9.24788136,7.40263459 9.41101695,7.22623139 L11.4872881,4.98109966 L1.41525424,4.98109966 C1.1779661,4.98109966 1,4.77262314 1,4.53207331 L1,4.48396334 C1,4.22737686 1.19279661,4.034937 1.41525424,4.034937 L11.4872881,4.034937 L9.41101695,1.77376861 C9.24788136,1.59736541 9.24788136,1.30870561 9.41101695,1.13230241 C9.57415254,0.955899198 9.84110169,0.955899198 10.0042373,1.13230241 L12.9110169,4.27548683 C13.029661,4.40378007 13.029661,4.59621993 12.9110169,4.72451317 L10.0042373,7.86769759 C9.84110169,8.0441008 9.57415254,8.0441008 9.41101695,7.86769759 Z" id="Arrow"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
}

export default SvgIcon;
