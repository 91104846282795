import { datadogRum } from "@datadog/browser-rum";
import { userById } from "graphql/query/userQueries";
import { User } from "./models";

/**
 * Sets the user information in the DataDog RUM session, or clears it if no user passed in.
 *
 * @param user The user to assign to the current session.
 */
function setUserForDataDogSession(user?: User) {
  if (typeof user?.id === "string") {
    datadogRum.setUser({
      company: user.primaryCompany?.id,
      email: user.email,
      id: user.id,
      role: user.role,
    });
  }
  else {
    datadogRum.clearUser();
  }
  userById.onNextChange(setUserForDataDogSession);
}

if ((window as any)?.DURO_ENV?.datadog) {
  datadogRum.init((window as any).DURO_ENV.datadog);
  datadogRum.startSessionReplayRecording();
  userById.onNextChange(setUserForDataDogSession);
}

export function analyticsAndTracking(user: any) {
  const _window = window as any;
  let intervalTimer: NodeJS.Timer;
  let retries = 0;

  if (_window?.DURO_ENV?.appcues) {
    if (!_window?.AppcuesSettings) {
      _window.AppcuesSettings = { enableURLDetection: true };
      const appcuesScript = document.createElement("script");
      appcuesScript.src = "//fast.appcues.com/116594.js";
      document.head.appendChild(appcuesScript);
    }

    // Retry in case of race condition
    intervalTimer = setInterval(() => {
      try {
        // user.id will throw if user is undefined
        _window?.Appcues.identify(user?.id);
        clearInterval(intervalTimer);
      }
      catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        retries++;
        if (retries > 5) {
          clearInterval(intervalTimer);
        }
      }
    }, 2000);
  }
}
