import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g
          fill="#888"
          fillRule="nonzero"
          stroke="#888"
          strokeWidth="0.25"
          transform="translate(-1126 -293)"
        >
          <g transform="translate(1102 80)">
            <g transform="translate(25 211)">
              <g transform="translate(0 3)">
                <path
                  d="M3.696 14a.807.807 0 01-.363-.088c-.33-.166-.536-.525-.536-.937v-1.256h-.894C.853 11.719 0 10.743 0 9.544V2.175C0 .975.854 0 1.903 0h10.194C13.147 0 14 .976 14 2.175v7.369c0 1.199-.854 2.175-1.903 2.175H6.203L4.3 13.727a.83.83 0 01-.604.273zM2.007 1C1.452 1 1 1.528 1 2.178v7.384c0 .65.452 1.177 1.007 1.177h1.305c.236 0 .428.225.428.501V13l2.021-2.128a.397.397 0 01.29-.133h5.942c.555 0 1.007-.528 1.007-1.177V2.178C13 1.528 12.548 1 11.993 1H2.007z"
                  transform="matrix(-1 0 0 1 14 0)"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
