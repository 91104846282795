import { UnfoldLessOutlined, UnfoldMoreOutlined, FilterList } from "@mui/icons-material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box, Button, SxProps, Theme, Typography, styled } from "@mui/material";
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { AutofitIcon, DeleteIcon, ExportIcon, RefreshIcon } from "assets/icons";
import { InputFieldVariants, ToolbarItemType } from "common/constants";
import { useDataTestId } from "common/hooks";
import {
  ComponentType, FunctionComponent, Key, MouseEvent, MouseEventHandler, MutableRefObject, useCallback, useMemo,
} from "react";
import { colorPalette } from "theme";
import { ButtonRefType } from "./grid";
import { ToolbarOptionsButton, ToolbarOptionsItem } from "./toolbarOptionsButton";

export interface ToolBarItem {
  disabled?: boolean;
  Icon?: ComponentType;
  items?: ToolbarOptionsItem[];
  label?: string;
  onClick?: MouseEventHandler;
  onOptionClick?: (event: MouseEvent<HTMLElement>, value: any) => void,
  type: ToolbarItemType;
}

export const FILTER_TOOLBAR_ICON_PROPS: ToolBarItem = {
  disabled: true, // disabled till the implementation of the relevant functionality
  Icon: FilterList,
  label: "Filter",
  type: ToolbarItemType.ACTION,
};

export const AUTOFIT_TOOLBAR_ICON_PROPS: ToolBarItem = {
  disabled: true, // disabled till the implementation of the relevant functionality
  Icon: AutofitIcon,
  label: "Autofit",
  type: ToolbarItemType.ACTION,
};

export const REFRESH_TOOLBAR_ICON_PROPS: ToolBarItem = {
  Icon: RefreshIcon,
  label: "Refresh",
  type: ToolbarItemType.ACTION,
};

export const COLLAPSE_TOOLBAR_ICON_PROPS: ToolBarItem = {
  Icon: UnfoldLessOutlined,
  label: "Collapse",
  type: ToolbarItemType.ACTION,
};

export const EXPAND_TOOLBAR_ICON_PROPS: ToolBarItem = {
  Icon: UnfoldMoreOutlined,
  label: "Expand",
  type: ToolbarItemType.ACTION,
};

export const DELETE_TOOLBAR_ICON_PROPS: ToolBarItem = {
  Icon: DeleteIcon,
  label: "Delete",
  type: ToolbarItemType.ACTION,
};

export const EXPORT_TOOLBAR_ICON_PROPS: ToolBarItem = {
  Icon: ExportIcon,
  label: "Export",
  type: ToolbarItemType.ACTION,
};

const commonButtonStyle = (theme: Theme, customButtonStyle: SxProps<Theme> = {}): any => (
  {
    "& .MuiButton-root ": {
      border: "0 !important",
      padding: 0,
      marginRight: "1rem",
      textTransform: "none",
      minWidth: "unset", // Remove the default min width on buttons.
      "& .MuiButton-startIcon": {
        marginRight: "0.25rem",
        "& svg": {
          fontSize: "20px",
        },
      },
      ...customButtonStyle,
      "&:not(.Mui-disabled)": {
        color: theme.palette.common.white,
      },
      "&.Mui-disabled": {
        color: theme.duro.complexTable.toolbar.disabledButtonColor,
      },
      "&:hover": {
        backgroundColor: "transparent",
        color: `${theme.palette.primary.main} !important`,
        border: 0,
      },
      "& .MuiTypography-root": {
        fontSize: "13px",
      },
      "& .MuiBadge-badge": { display: "none" },
    },
  });

const ToolbarWrapper = styled(Box)({
  alignItems: "flex-end",
  display: "flex",
  flexDirection: "row",
  width: "100%",
});

const ToolbarContainer = styled(Box)(({ theme }) => (
  {
    backgroundColor: theme.duro.complexTable.backgroundColor,
    border: `0.063rem solid ${theme.duro.complexTable.borderColor}`,
    display: "inline-block",
    borderRadius: "0.5rem 0.5rem 0 0",
    borderBottom: 0,
    "& .MuiDataGrid-toolbarContainer": {
      backgroundColor: theme.duro.complexTable.backgroundColor,
      padding: 0,
    },
    ...commonButtonStyle(theme),
  }
));

const IconDivider = styled(Box)(({ theme }) => ({
  borderRight: `0.063rem solid ${theme.duro.complexTable.borderColor}`,
  display: "inline-flex",
  height: "1rem",
  marginRight: "1rem",
}));

export const InitialDivider = styled(Box)(({ theme }) => ({
  borderRight: `0.063rem solid ${theme.duro.complexTable.borderColor}`,
  height: "2rem",
  width: "2rem",
}));

const ToolbarItemsContainer = styled(Box)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  padding: "0.125rem 1rem 0.125rem",
  "& .MuiButton-root ": {
    "&:last-child": {
      marginRight: 0,
    },
  },
}));

const FiltersCounter = styled(Box)(({ theme }) => ({
  display: "inline-block",
  top: "-5px",
  padding: "0 10px",
  color: theme.palette.primary.main,
  "& .MuiTypography-root": {
    fontSize: "13px",
    display: "inline-block",
  },
  ...commonButtonStyle(theme, {
    height: 0,
    top: "-2px",
    left: "-10px",
    color: "#d8d8d8 !important",
    "& svg": {
      fontSize: "17px !important",
    },
  }),
}));

const ResultCounterTypography = styled(Typography)({
  color: colorPalette.taupeGray,
  fontSize: "0.875rem",
  flex: 1,
  textAlign: "right",
});

const ToolbarTextTypography = styled(Typography)(() => ({
  color: colorPalette.taupeGray,
  marginRight: "1rem",
}));

export interface IGridToolBarProps {
  apiRef?: MutableRefObject<GridApiPro>;
  filters: any;
  items: Array<ToolBarItem>;
  totalCount?: number;
  setSettingsButtonRef: ButtonRefType;
}

const ACTIONS_TEST_ID = ["grid", "toolbar", "actions"];
const CLEAR_FILTERS_TEST_ID = ["grid", "toolbar", "clear"];
const TOTAL_COUNT_TEST_ID = ["grid", "toolbar", "count"];

export const GridToolbar: FunctionComponent<IGridToolBarProps> = (props: IGridToolBarProps) => {
  const { apiRef, filters, items, setSettingsButtonRef, totalCount } = props;

  const actionsTestId = useDataTestId(...ACTIONS_TEST_ID);
  const clearFiltersTestId = useDataTestId(...CLEAR_FILTERS_TEST_ID);
  const totalCountTestId = useDataTestId(...TOTAL_COUNT_TEST_ID);

  const clearFilters = useCallback(() => (apiRef?.current?.setFilterModel({ items: [] })), [apiRef]);

  const showFiltersCount = useMemo(() => {
    if (!filters?.items?.length) return null;
    return (
      <FiltersCounter data-testid={clearFiltersTestId}>
        <Typography>{`${filters?.items?.length} Filters Active`}</Typography>
        <Button
          onClick={clearFilters}
          startIcon={<HighlightOffIcon />}
          variant={InputFieldVariants.OUTLINED}>
        </Button>
      </FiltersCounter>
    );
  }, [clearFilters, clearFiltersTestId, filters]);
  const showResultsCount = useMemo(() => {
    if (!totalCount) return null;
    return (
      <ResultCounterTypography data-testid={totalCountTestId}>
        {totalCount}
      </ResultCounterTypography>
    );
  }, [totalCount, totalCountTestId]);

  const renderItems = useMemo(() => (
    items.map((item: ToolBarItem, index: number) => {
      const { disabled, Icon, label, onClick, type } = item;
      const key: Key = `${type}-${index}`;
      switch (type) {
        case ToolbarItemType.ACTION:
          return (
            <Button
              disabled={disabled}
              key={key}
              onClick={onClick}
              startIcon={Icon ? <Icon /> : null}
              variant={InputFieldVariants.OUTLINED}
            >
              {label}
            </Button>
          );
        case ToolbarItemType.DIVIDER:
          return <IconDivider key={key} />;
        case ToolbarItemType.OPTIONS:
          return <ToolbarOptionsButton {...item} />;
        case ToolbarItemType.TEXT:
          return (
            <ToolbarTextTypography key={key} variant="body2">{label}</ToolbarTextTypography>
          );
        default:
          return null;
      }
    })
  ), [items]);
  return (
    <ToolbarWrapper>
      <ToolbarContainer>
        <GridToolbarContainer data-testid={actionsTestId}>
          <InitialDivider />
          <ToolbarItemsContainer>
            {renderItems}
          </ToolbarItemsContainer>
          <GridToolbarFilterButton {...MUI_TYPES_HACK} />
          <GridToolbarColumnsButton ref={setSettingsButtonRef} {...MUI_TYPES_HACK} />
        </GridToolbarContainer>
      </ToolbarContainer>
      {showFiltersCount}
      {showResultsCount}
    </ToolbarWrapper>
  );
};

// This is required to deal with the fact that the MUI type definitions are defined
// with updated types from Popper and React 18 that cause typescript to freak out
// some since we are still using React 17. This is needed to use the latest v5 of
// MUI's DataGid, as they have only partially fixed the issue on there side.
// https://github.com/mui/mui-x/issues/8011
const MUI_TYPES_HACK = { onResize: undefined, onResizeCapture: undefined, nonce: undefined };
