import { FC, createContext, useCallback, useEffect, useMemo, useState } from "react";

export interface IContextData {
  getAssembly: () => any,
  getDocuments: () => any,
  getManufacturers: () => any,
  setAssembly: (assembly: any) => void,
  setDocuments: (documents: any) => void,
  setManufacturers: (manufacturers: any) => void,
}

export interface IEditTabsContextProviderProps {
  children: React.ReactNode,
  currentItem: any,
}

export const EditTabsContext = createContext<IContextData>({
  getAssembly: () => [],
  getDocuments: () => [],
  getManufacturers: () => [],
  setAssembly: _value => { },
  setDocuments: _value => { },
  setManufacturers: _value => { },
});

export interface IItemType {
  assembly: {
    children: [],
    hasDataBeenLoaded: boolean,
    shouldReadFromContext: boolean,
  },
  documents: [],
  sourcing: {
    manufacturers: [],
  }
}

export const EditTabsContextProvider: FC<IEditTabsContextProviderProps> = ({ children, currentItem }) => {
  const defaultAssemblyState = useMemo(() => ({
    children: currentItem?.children ?? [],
    hasDataBeenLoaded: false,
    shouldReadFromContext: false,
  }), [currentItem?.children]);

  const [item, setItem] = useState<IItemType>({
    assembly: defaultAssemblyState,
    documents: currentItem?.documents ?? [],
    sourcing: { manufacturers: currentItem?.manufacturers ?? [] },
  });

  useEffect(() => {
    setItem(prevItem => ({
      ...prevItem,
      assembly: defaultAssemblyState,
      documents: currentItem?.documents ?? [],
      sourcing: { manufacturers: currentItem?.manufacturers ?? [] },
    }));
  }, [
    currentItem?.documents,
    currentItem?.manufacturers,
    defaultAssemblyState,
  ]);

  const setAssembly = useCallback(updatedAssembly => {
    setItem(prevItem => ({ ...prevItem, ...{ assembly: { ...prevItem.assembly, ...updatedAssembly } } }));
  }, []);
  const setDocuments = useCallback(documents => setItem(prevItem => ({ ...prevItem, documents })), []);
  const setManufacturers = useCallback(manufacturers => (
    setItem(prevItem => ({ ...prevItem, sourcing: { manufacturers } }))), []);

  const getAssembly = useCallback(() => item.assembly, [item.assembly]);
  const getManufacturers = useCallback(() => item.sourcing.manufacturers, [item.sourcing]);
  const getDocuments = useCallback(() => item.documents, [item.documents]);

  const contextData: IContextData = {
    getAssembly,
    getDocuments,
    getManufacturers,
    setAssembly,
    setDocuments,
    setManufacturers,
  };

  return <EditTabsContext.Provider value={contextData}>
    {children}
  </EditTabsContext.Provider>;
};
