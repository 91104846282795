import React, { FunctionComponent } from "react";
import { Box, styled } from "@mui/material";
import { GridFilterPanel } from "@mui/x-data-grid-pro";

const FilterPanelContainer = styled(Box)`
 & {
   & .MuiDataGrid-panelContent {
    & .MuiDataGrid-filterFormDeleteIcon {
      margin-right: 10px;
      margin-bottom: 5px;
      & .MuiButtonBase-root {
        border: 0;
        padding: 0;
        min-width: auto;
      }
    }
    & .MuiInput-input.MuiInputBase-input:not(.MuiNativeSelect-select) {
      padding: .15rem 10px;
     }
   }
 } 
`;

export const TableFilterPanel: FunctionComponent<{}> = () => (
  <FilterPanelContainer>
    <GridFilterPanel/>
  </FilterPanelContainer>
);
