import                "./index.css";
import React          from "react";
import ReactTooltip   from 'react-tooltip';
import InlineIcon     from "../../../ui/icon/inline-icon.js";
import AlertErrorIcon from "../../../../assets/icons/alert-error";

const ErrorNotification = (props) =>
{
    return(
        <div className="error-alert">
            <ReactTooltip id={"errors-warning-icon"}
                effect="solid"
                >
            </ReactTooltip>

            <div
                data-tip={props.getErrorsWarningMsg()}
                data-type="dark"
                data-place="left"
                data-for='errors-warning-icon'
                data-offset="{'top': -50, 'left': -35}"
                data-multiline={true}
            >
                <InlineIcon>
                    <AlertErrorIcon/>
                </InlineIcon>
            </div>
        </div>
    )
}

export default ErrorNotification;
