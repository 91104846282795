import Utils   from "../../utils";

class SSOIntegrationAPI
{
    static uploadMetaData(data, cb)
    {
        Utils.post("/saml/upload-meta-data", data, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }

            cb(null, res.data)
        })
    }

    static generateIdAndUrl(cb)
    {
        Utils.get("/saml/generate-id-and-url", (err, res) => {
            if(err)
            {
                return cb(err);
            }

            cb(null, res.data);
        })
    }

    static checkDomainExist(data, cb)
    {
        Utils.post("/is-domain-exist", data, (err, res) => {
            if(err)
            {
                return cb(err);
            }

            cb(null, res.data);
        })
    }
}

export default SSOIntegrationAPI
