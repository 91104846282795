import { SvgIcon } from "@mui/material";

export const FileImportIcon = () => (
  <SvgIcon height="24" width="24" className="toolbar-icon" viewBox="0 0 24 24">
    <path d="M16.737 20.126H4.105V7.263h7.369V5.158H4.105A2.111 2.111 0 0 0 2 7.263v12.632C2
      21.053 2.947 22 4.105 22h12.632a2.111 2.111 0 0 0 2.105-2.105v-7.369h-2.105v7.6z
      M18.842 2h-2.105v3.158h-3.158c.01.01 0 2.105 0 2.105h3.158v3.148c.01.01 2.105 0
      2.105 0V7.263H22V5.158h-3.158V2zM11.333 17.333c-.916
      0-1.666-.75-1.666-1.666v-4.584c0-.233.183-.416.416-.416.234
      0 .417.183.417.416v4.584h1.667v-4.584a2.084 2.084 0 0 0-4.167
      0v4.584a3.332 3.332 0 1 0 6.667 0v-3.334H13v3.334c0 .916-.75 1.666-1.667 1.666z"
    />
  </SvgIcon>
);
