import { fork, all }        from 'redux-saga/effects';
import productNewSaga       from "./new"
import productEditSaga      from "./edit"
import productViewSaga      from "./view"
import productRevisionSaga  from "./revision"
export default function* productSaga(context = {}) {
    yield all([
        fork(productNewSaga, context),
        fork(productEditSaga, context),
        fork(productViewSaga, context),
        fork(productRevisionSaga, context)
    ]);
}
