export function getInitialState()
{
    return {
        selectedTextForProductList: "Select Product",
        searchResults:[],
        search :
        {
            value      : "",
            inputs     : [],
            results    : [],
            selectAllFlag : false,
            selectAllButoonClazz: ""
        },
        children: [],
        productList: [],
        class: "close",
        buttonHighlight: false,
        openItemList: false,
        resetAssembly:false,
        displayAsseblySearch: false,
        searchInProgress: false,
        inputs :
        {
            types :
            {
               value : "",
               valid : false
            },
            name :
            {
               value : "",
               valid : false
            },
            description :
            {
               value : "",
               valid : true
            },
            submit :
            {
               class : "disabled"
            }
        }
    }
}

let initialState = getInitialState()
export default initialState
