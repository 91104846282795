import { gql } from "@apollo/client";
import { CreatedLotFragment } from "./lotFragment";

export const BaseComponentFragment = gql`
  fragment baseComponentFragment on Component {
    alias
    category
    cpn {
      displayValue
      id
    }
    description
    eid
    id
    imageIds
    lastModified
    modified
    name
    revisionValue
    status
    variantGroup {
      id
      type
    }
  }
`;

export const BaseComponentRevisionFragment = gql`
  fragment baseComponentRevisionFragment on ComponentRevision {
    alias
    category
    cpn {
      displayValue
      id
    }
    description
    eid
    id
    imageIds
    lastModified
    modified
    name
    revisionValue
    status
    variantGroup {
      id
      type
    }
  }
`;

export const ComponentFragment = gql`
  fragment componentFragment on Component {
    ...baseComponentFragment
    children {
      quantity
    }
    incompleteCost
    mass
    massStatus
    previousRevisionValue
    previousStatus
    primarySource {
      leadTimeDays
      manufacturer
      mpn
      unitPrice
    }
    procurement
    unitOfMeasure
    vendorInfo {
      currentVendors
    }
    workflowState
  }
  ${BaseComponentFragment}
`;

export const ComponentRevisionFragment = gql`
  fragment componentRevisionFragment on ComponentRevision {
    ...baseComponentRevisionFragment
    children {
      quantity
    }
    incompleteCost
    mass
    massStatus
    parent {
      id
    }
    previousRevisionValue
    previousStatus
    primarySource {
      leadTimeDays
      manufacturer
      mpn
      unitPrice
    }
    procurement
    unitOfMeasure
    vendorInfo {
      currentVendors
    }
    workflowState
  }
  ${BaseComponentRevisionFragment}
`;

export const ComponentWithChildrenFragment = gql`
  fragment componentWithChildrenFragment on Component {
    ...componentFragment
    children {
      component {
        ...componentFragment
      }
      isAddedAfterPullRequest
      itemNumber
      notes
      quantity
      refDes
      variants {
        isPermitted
        isPrimary
        variant {
          id
        }
      }
      waste
    }
  }
  ${ComponentFragment}
`;

export const ComponentRevisionWithChildrenFragment = gql`
  fragment componentRevisionWithChildrenFragment on ComponentRevision {
    ...componentRevisionFragment
    children {
      assemblyRevision {
        ...componentRevisionFragment
      }
      isAddedAfterPullRequest
      itemNumber
      notes
      quantity
      refDes
      variants {
        isPermitted
        isPrimary
        variant {
          id
        }
      }
      waste
    }
  }
  ${ComponentRevisionFragment}
`;

export const ComponentLastReleasedRevisionFragment = gql`
  fragment componentLastReleasedRevisionFragment on Component {
    lastReleaseRevision {
      ...baseComponentRevisionFragment
    }
  }
  ${BaseComponentRevisionFragment}
`;

export const ComponentRevisionsLotFragment = gql`
  fragment componentRevisionsLotFragment on ComponentRevision {
    build {
      lots {
        ...createdLotFragment
      }
    }
    cpn {
      displayValue
    }
    id
  }
  ${CreatedLotFragment}  
`;

export const ComponentsLotFragment = gql`
  fragment componentsLotFragment on Component {
    build {
      lots {
        ...createdLotFragment
      }
    }
    cpn {
      displayValue
    }
    id
  }
  ${CreatedLotFragment}  
`;
