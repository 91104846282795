/**
 * TODO (PLM-6233): The component/file currently is just wrapping the old functionality used here.
 * It needs to be updated to using MUI components and make it so that the data isn't modified in way
 * that are hard to follow and can have unintended side effects.
 */

import { PageItemType, PageMode } from "design/constants";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ComponentActions from "v1/action-types/component";
import ProductActions from "v1/action-types/product";
import DocumentsEdit from "v1/components/page/common/documents/edit";
import DocumentsView from "v1/components/page/common/documents/view";
import Spinner from "v1/components/ui/spinner";
import buildAction from "v1/helpers/buildAction";
import Schemas from "v1/modules/schemas";
import Utils from "v1/modules/utils";
import validations from "v1/modules/validations";
import { TabError } from "./constants";
import { useEditObject, useLoadDocuments, useViewObject } from "./hooks";

function useTargetRevDocuments(isDiffTool: boolean) {
  return useSelector((store: any) => (isDiffTool ? store.itemDiff?.targetRev?.documents : undefined));
}

export interface DocumentsTabProps {
  id: string,
  isDiffTool: boolean;
  isEditing: boolean;
  isVisible: boolean;
  mode: PageMode;
  pageItemType: PageItemType;
  updateErrorCount: (valid: boolean, tab: TabError, amount?: number) => void,
}

export function DocumentsTab(props: DocumentsTabProps) {
  const { id, isDiffTool, isEditing, isVisible, mode, pageItemType, updateErrorCount } = props;
  const location = useLocation();
  const dispatch = useDispatch();

  const viewObject = useViewObject(pageItemType);
  const editObject = useEditObject(pageItemType);
  const targetRevDocuments = useTargetRevDocuments(isDiffTool);
  const areDocsLoaded = useLoadDocuments({ id, mode, pageItemType, skip: !isVisible });

  const { clientSchema, documentSchema } = useMemo(() => {
    switch (pageItemType) {
      case PageItemType.COMPONENT:
        return {
          clientSchema: validations.component.documents,
          documentSchema: Schemas.component.documents,
        };
      default:
        return {
          clientSchema: validations.product.documents,
          documentSchema: Schemas.product.documents,
        };
    }
  }, [pageItemType]);

  const component = useMemo(() => {
    switch (pageItemType) {
      case PageItemType.COMPONENT:
        return isEditing ? editObject : viewObject;
      default:
        return null;
    }
  }, [editObject, isEditing, pageItemType, viewObject]);

  const vendorData = useMemo(() => {
    switch (pageItemType) {
      case PageItemType.COMPONENT:
        return { isVendorCmp: Utils.isVendorCmp(Utils.getVendor(editObject)) };
      default:
        return { isVendorCmp: false };
    }
  }, [editObject, pageItemType]);

  const vendorTooltipText = useMemo(() => {
    if (!vendorData.isVendorCmp) return "";

    return Utils.vendorTooltip(Utils.getVendor(editObject));
  }, [vendorData, editObject]);

  const onChange = useCallback((event: CustomEvent) => {
    const { value, name } = event.target as any;
    const payload = { value, name };
    switch (pageItemType) {
      case PageItemType.COMPONENT:
        dispatch(buildAction(ComponentActions.UPDATE_EDIT_FORM_INPUT_STATE, payload));
        break;
      case PageItemType.PRODUCT:
        dispatch(buildAction(ProductActions.UPDATE_EDIT_FORM_INPUT_STATE, payload));
        break;
      default: // No other page items types need to edit.
    }
  }, [dispatch, pageItemType]);

  if (!areDocsLoaded) {
    return <Spinner />;
  }

  return (
    <>
      {!isEditing && (
        <DocumentsView
          component={component}
          documents={viewObject.documents}
          initializePusher={true}
          isDiffTool={isDiffTool}
          isProductionInstance={false}
          location={location}
          objectData={viewObject}
          targetRevDocuments={targetRevDocuments}
        />
      )}
      {isEditing && (
        <DocumentsEdit
          clientSchema={clientSchema}
          component={component}
          documents={editObject.documents}
          documentSchema={documentSchema}
          initializePusher={true}
          name={"documents"}
          onChange={onChange}
          toggleErrorCount={updateErrorCount}
          vendorData={vendorData}
          vendorTooltipText={vendorTooltipText}
        />
      )}
    </>
  );
}
