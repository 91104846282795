import { gql } from "@apollo/client";

export const ImageFragment = gql`
  fragment ImageFragment on Image {
      archived
      created
      id
      key
      mime
      mode
      name
      size
      src
      variants {
        r38_24
        r134_84
        r185_116
        r255_160
      }
  }
`;
