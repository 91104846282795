import { Typography, styled } from "@mui/material";
import { TileContentDirection } from "./consts";
import { TileContentLabel, TileContentWrapper } from "./tileContent";

interface TileContentTextProps {
  direction?: TileContentDirection;
  label?: string;
  text?: string;
}

const Text = styled(Typography)<{ direction?: TileContentDirection }>(({ direction }) => {
  const isColumn = direction === TileContentDirection.COLUMN;
  return {
    fontSize: "0.875rem",
    overflow: "hidden",
    textOverflow: isColumn ? "inherit" : "ellipsis",
    whiteSpace: isColumn ? "inherit" : "nowrap",
    wordWrap: isColumn ? "break-word" : "inherit",
  };
});

export function TileContentText(props: TileContentTextProps) {
  const { direction, label, text } = props;

  return (
    <TileContentWrapper direction={direction}>
      <TileContentLabel label={label} />
      <Text direction={direction} title={text}>{text}</Text>
    </TileContentWrapper>
  );
}
