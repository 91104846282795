import { Box, TextareaAutosize, Typography, styled } from "@mui/material";
import { ChangeEvent, FC, useCallback, useState } from "react";
import { FieldTitle } from "./fieldTitle";

export interface ITextArea {
  characterLimit?: number,
  initialValue?: string,
  isRequired?: boolean,
  label: string,
  labelStyling?: ILabelStyleProps,
  maxRows?: number,
  minRows?: number,
  onChange: (value: string, e: ChangeEvent<HTMLTextAreaElement>) => void,
  placeholder: string | null,
  styleProps?: {
    borderBottom?: string,
    fontSize?: string,
    height?: string,
  },
}

export interface ILabelStyleProps {
  color?: string,
}

export interface ITargetValue {
  target: {
    value: string,
  },
}

export const TextArea: FC<ITextArea> = (({
  characterLimit,
  initialValue,
  isRequired,
  label,
  labelStyling,
  maxRows,
  minRows,
  onChange,
  placeholder,
  styleProps,
}) => {
  const CHARACTER_LIMIT = characterLimit ?? 100;
  const [textValue, setTextValue] = useState(initialValue ?? "");
  const handleChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    const { target: { value } }: ITargetValue = event;
    if (value.length <= CHARACTER_LIMIT) {
      setTextValue(value);
      onChange(value, event);
    }
  }, [CHARACTER_LIMIT, onChange]);

  return (
    <>
      <HeadingBox>
        <FieldTitle
          isRequired={isRequired}
          label={label}
          sx={{
            fontSize: "0.75rem",
            ...labelStyling,
          }}
        />
        <CounterTag>{textValue.length}/{CHARACTER_LIMIT}</CounterTag>
      </HeadingBox>
      <CustomTextArea
        className={"custom-text-area"}  // TODO: clean this up once we remove the old styling
        maxRows={maxRows ?? 7}
        minRows={minRows ?? 7}
        onChange={handleChange}
        placeholder={placeholder ?? "Enter Comments Here"}
        styleProps={styleProps}
        value={textValue}
      />
    </>);
});

const CounterTag = styled(Typography)({
  fontSize: "0.75rem",
});

export const CustomTextArea = styled(TextareaAutosize, {
  shouldForwardProp: prop => prop !== "styleProps",
})<Pick<ITextArea, "styleProps">>(({ styleProps, theme }) => (
  {
    ...styleProps,
    backgroundColor: theme.duro.eventModal.textArea.backgroundColor,
    color: theme.duro.eventModal.textArea.color,
    padding: "0.688rem 1rem",
  }
));

const HeadingBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "0.313rem",
});
