import                                      "./index.css"
import React, { Fragment, useCallback, useState, useEffect, useReducer } from "react";
import { reducer, initialState}        from "./reducer";
import SaveTemplateModal               from "../../../../common/save-template-modal/index.js";
import ManageTemplatesModal            from "../../../../common/manage-templates-modal/index.js";
import API from "../../../../../../modules/api";
import Utils                           from "../../../../../../modules/utils"
import TemplateSelect                  from "../../../../common/template-select"

const CoTemplatesSelect = (props) => {
  
    const [disableTemplateModalSaveButton, setDisableTemplateModalSaveButton] = useState(true)
    const [state, dispatch] = useReducer(reducer, initialState)

    const optionsForTemplates = useCallback((templates) => {
        const options = [];
        const templateData = populateOptions(templates)
        dispatch({ type: "privateTemplates", payload: templateData.privateTemplates })
        dispatch({ type: "companyTemplates", payload: templateData.companyTemplates })
        dispatch({ type: "recentTemplates", payload: templateData.recentTemp })
        options.push({ label: "", options: templateData.defaultTemplate });
        options.push({ label: "RECENT", options: templateData.recentTemplates });
        options.push({ label: "PRIVATE", options: templateData.privateTemplates });
        options.push({ label: "COMPANY", options: templateData.companyTemplates });
        dispatch({ type: "options", payload: options })
    }, [dispatch]);

    useEffect(() => {
        dispatch({ type: "templates", payload: props.templates})
        optionsForTemplates(props.templates)
    }, [optionsForTemplates, props.templates]);

    const getTemplate = (label) => {
        if(label === 'Default Template') return Utils.getCoDefaultTemplate();

        const { templates } = props
        const template = templates.find(({templateName}) => templateName === label)
        
        return template;
    }

    const toggleSaveModal = (event) => {
        if (event?.stopPropagation) event.stopPropagation()
        dispatch({ type: "displaySaveTemplateModal", payload: !state.displaySaveTemplateModal})
    }

    const toggleManageTemplateModal = (event) => {
        if (event?.stopPropagation) event.stopPropagation()
        dispatch({ type: "displayManageTemplatesModal", payload: !state.displayManageTemplatesModal})
    }

    const extractIds = (values) => { return values.map(({_id}) => {return _id})}

    const saveTemplateData = () => {
        const {templates, updateTemplateName} = state
        const template = templates.find(({templateName}) => templateName === updateTemplateName)
        const updateTemplate = !!template;
        const templateId = template ? template._id : null

        const approversList = extractIds(props.selectedUsers.selectedApprovers)
        const notifiersList = extractIds(props.selectedUsers.selectedNotifiers)
        const data = {
            externalUsers: props.selectedUsers.selectedExternals,   
            approvalType : props.approvalType,
            templateName : state.saveTemplateName,
            isPublic     : state.isPublic,
            approversList,
            notifiersList
        }
        saveCoApprovalTemplate(data, updateTemplate, false, templateId)
    }

    const saveCoApprovalTemplate = (data, updateTemplate=false, removeTemplate=false, id="") => {
        const apiRequest = updateTemplate ? API.services.updateExistingCoTemplate : removeTemplate ? API.services.removeCoApprovalTemplates : API.services.saveNewCoTemplate;
        if(updateTemplate) data.id = id;
        apiRequest(data, (err, response) => {
            if(response) {
                props.enableSaveButton(false)
                if(removeTemplate) removeFromTemplateOptions(data)
                else {
                    data.creator = props.user.data._id;
                    data.company = props.user.data.company;
                    if(updateTemplate){
                        data._id = id;
                        data.templateName = state.updateTemplateName;
                        updateTemplateToOptions(data, updateTemplate)
                    }
                    else {
                        data._id = response;
                        newTemplateToOptions(data)
                    }
                }
            }
        });
    }

    const setCompanyTemplateFlag = (value, isValid) => {
        dispatch({ type: "isPublic", payload: value})
        setDisableTemplateModalSaveButton(!isValid)
    }

    const newTemplateToOptions = (data) => {
        const newTemplate = [...state.templates, data]
        dispatch({ type: "templates", payload: newTemplate})
        optionsForTemplates(newTemplate)
        props.setCoTemplates(newTemplate)
    }
    
    const updateTemplateToOptions = (data) => {
        const updateTemp = state.templates.filter(template => template._id !== data._id)
        updateTemp.push(data)
        dispatch({ type: "templates", payload: updateTemp})
        optionsForTemplates(updateTemp)
        props.setCoTemplates(updateTemp)
    }

    const removeFromTemplateOptions = (data) => {
        const updateTemp = state.templates.filter(template => !data.includes(template._id))
        dispatch({ type: "templates", payload: updateTemp})
        optionsForTemplates(updateTemp)
        props.setCoTemplates(updateTemp)   
    }

    const onInputChange = (event, inValid) => {
        if(event.target.name === "save-template"){
            dispatch({ type: "saveTemplateName", payload: event.target.value})
            dispatch({ type: "updateTemplateName", payload: ""})
            setDisableTemplateModalSaveButton(false)
            if(event.target.value === "" || !inValid) setDisableTemplateModalSaveButton(true)
        }
        else if(event.target.name === "update-template"){
            dispatch({ type: "updateTemplateName", payload: event.target.value})
            dispatch({ type: "saveTemplateName", payload: ""})
            setDisableTemplateModalSaveButton(false)
        }
    }

    const updateUserLists = (coTemplate, templateName) => {

        let cloneUsers = props.allUsers.map((user) => ({ ...user,irremovable : false }))
        const alreadyLoadedTemplate = props.loadTemplate && !!(Object.keys(props.loadTemplate).length);
        const listOfApprovers = alreadyLoadedTemplate ? Utils.getUpdatedLists(cloneUsers, props.loadTemplate.approversList) : [];
        const listOfNotifiers = alreadyLoadedTemplate ? Utils.getUpdatedLists(cloneUsers, props.loadTemplate.notifiersList) : [];
        const listOfExternals   = alreadyLoadedTemplate  ? props.loadTemplate.externalUsers : [];

        for(let externalUser of coTemplate.externalUsers) {
            !listOfExternals.includes(externalUser) && listOfExternals.push(externalUser)
        }

        dispatch({ type: "currentTemplate", payload: templateName})
        cloneUsers.forEach(user => {
            if(!listOfApprovers.includes(user) && props.creator === user._id) {
                user.irremovable = true;
                listOfApprovers.push(user)
            }
            if(!listOfApprovers.includes(user) && coTemplate.approversList.includes(user._id)) listOfApprovers.push(user)
            if(!listOfNotifiers.includes(user) && coTemplate.notifiersList.includes(user._id)) listOfNotifiers.push(user)
        })
        props.updateListsTemplates(listOfApprovers, listOfNotifiers, listOfExternals)
    }

    const onTemplateSelect = (template) => {
        const coTemplate = getTemplate(template.label)
        updateUserLists(coTemplate, template)
        props.approvalTypeFromTemplate(coTemplate.approvalType)
        props.setSelectedTemplateName(template)
        if(coTemplate.templateName === 'Default Template') return
        API.services.updateLastUsedCoTemplate(template.value, (err, res)=>{
            if(res) return 
        })
    }

    const manageCoTemplates = (template, removeTemplate) => {
        const data = {};
        if(removeTemplate.length === 0) return
        removeTemplate.forEach(removeTemp => {
            if(removeTemp === props.selectedTemplateName.value){
                data.label = "Default Template";
                data.value = ""
            onTemplateSelect(data)
            }
        })
    }

    function populateOptions(templates) {
        const defaultTemplate = [];
        const privateTemplates = [];
        const companyTemplates = [];
        const recentTemplates = [];
        const temp = Utils.getCoDefaultTemplate();
        const previouslyUsedTemplates = templates.filter(template => { return template.hasOwnProperty('lastUsed') })
        const recentTemp = previouslyUsedTemplates.sort((a, b) => { return b.lastUsed - a.lastUsed }).slice(0, 3);
        templates.forEach(template => {
            const option = { value: template._id, label: template.templateName }
            template.isPublic ? companyTemplates.push(option) : privateTemplates.push(option)
        })
        recentTemp.forEach(temp => {
            const option = { value: temp._id, label: temp.templateName }
            recentTemplates.push(option)
        })
        defaultTemplate.push({ value: "", label: temp.templateName });
        return { defaultTemplate, privateTemplates, companyTemplates, recentTemplates, recentTemp }
    }

    return( 
        <Fragment>
            {state.displaySaveTemplateModal && 
            <div className="save-template-modal">
                <SaveTemplateModal
                    onClose={toggleSaveModal}
                    saveTemplateData={saveTemplateData}
                    onInputChange={onInputChange}
                    disableTemplateModalSaveButton={disableTemplateModalSaveButton}
                    selectedTemplate={state.currentTemplate}
                    templates={state.templates}
                    setCompanyTemplateFlag={setCompanyTemplateFlag}
                    recentTemplates={state.recentTemplates}
                    user={props.user}
                    isCoTemplate={true}
                />
            </div>}
            {state.displayManageTemplatesModal &&
            <div className="manage-templates-modal">
                <ManageTemplatesModal
                    onClose={toggleManageTemplateModal}
                    templates={state.templates}
                    privateTemplates={state.privateTemplates}
                    companyTemplates={state.companyTemplates}
                    saveTemplate={saveCoApprovalTemplate}
                    manageTemplates={manageCoTemplates}
                    user={props.user}
                    isCoTemplate={true}
                    coTemplatesMappings={props.coTemplatesMappings}
                />
            </div>}
            <TemplateSelect
                selectedTemplate={props.selectedTemplateName}
                onChange={onTemplateSelect}
                options={state.options}
                toggleManageTemplateModal={toggleManageTemplateModal}
                enableSave={props.enableSave}
                toggleSaveModal={toggleSaveModal}
                mode='co'
            />
        </Fragment>
    )
}
export default CoTemplatesSelect;
