import React, { Component } from "react";
import API from "../../../../../modules/api";
import StatusField from "../../../common/status-field";
import ExtendedTable from "../../../common/extended-table";
import TimeStampColumn from "../../../common/timestamp-column";
import Utils from "../../../../../modules/utils";
import { getHeadings, HEADINGS } from "../../extended-table/helpers";
import Spinner from "../../../../ui/spinner";

export class SerializationTab extends Component
{
    constructor(props, context)
    {
        super(props, context);
        let serializationViewTabStyles;
        try
        {
            serializationViewTabStyles = this.props.user.preferences.styles.serializationViewTabStyles || {};
        }
        catch(error)
        {
            serializationViewTabStyles = {};
        }

        this.state =
        {
            productionInstances : null,
            currentSortItemAscending: ("defaultSortAssending" in serializationViewTabStyles ? serializationViewTabStyles.defaultSortAssending : true),
            current : ("defaultSortColumnName" in serializationViewTabStyles ? serializationViewTabStyles.defaultSortColumnName : "cpn"),
            headings: getHeadings(
                [
                  {
                    ...HEADINGS.cpn,
                    width: 120,
                  },
                  HEADINGS.name,
                  HEADINGS.status,
                  HEADINGS.revision,
                  HEADINGS.serial,
                  HEADINGS.label,
                  HEADINGS.productionDate,
                ],
                serializationViewTabStyles
            ),
        }

        this.getRows = this.getRows.bind(this);
        this.getProductionInstances = this.getProductionInstances.bind(this);
    }

    componentDidMount()
    {
        this.getProductionInstances();
    }

    componentWillReceiveProps(nextProps)
    {
        let { refreshSerializationTab } = nextProps;
        if(refreshSerializationTab && refreshSerializationTab !== this.props.refreshSerializationTab)
        {
            this.getProductionInstances();
        }
    }

    getProductionInstances()
    {
        let { objectData, alias } = this.props;
        API.productionInstances.getProductionInstances(objectData._id, {alias}, (err, data) =>
        {
            if (data)
            {
                this.setState({ productionInstances: data });
            }
        })
    }

    getRows()
    {
        let { productionInstances } = this.state;
        let rows = [];
        if (productionInstances)
        {
            rows = productionInstances.map((item, i) =>
            {
                let dateTime = Utils.dateTimeWithLongFormat(item.productionDate);
                let link = `/production/instance/view/${item._id}`;

                let cells = {
                    "cpn" : {
                        value       : item.cpn,
                        displayValue: item.cpn,
                        tooltip     : item.cpn
                    },
                    "name" : {
                        value       : item.name,
                        displayValue: item.name,
                        tooltip     : item.name
                    },
                    "status" : {
                        value       : item.status,
                        displayValue: <StatusField item={item} status={item.status}/>,
                        tooltip     : item.status
                    },
                    "revision" : {
                        value       : item.revision,
                        displayValue: item.revision,
                        tooltip     : item.revision
                    },
                    "serial" : {
                        value       : item.serial,
                        displayValue: <span className='link'>{item.serial}</span>,
                        tooltip     : item.serial
                    },
                    "label" : {
                        value       : item.label,
                        displayValue: item.label,
                        tooltip     : item.label
                    },
                    "productionDate" : {
                        value       : item.productionDate,
                        tooltip     : dateTime ? `${dateTime.dateValue} ${dateTime.timeValue}` : '',
                        displayValue: <TimeStampColumn key={Utils.generateUniqueId()} format='date-time-with-long-format' value={item.productionDate} />,
                    },
                    rowLink      : link,
                }

                return cells
            });
        }

        return rows;
    }

    render()
    {
        let { headings, current, currentSortItemAscending, productionInstances } = this.state;
        if (!productionInstances) return <Spinner/>;

        let rows = this.getRows();

        let markup =
            <div className="serialization-tab-view-common">
                <ExtendedTable
                    wrapperClassName="serialization-list"
                    wrapperSelectorClass="serialization-list"
                    headings={headings}
                    rows={rows}
                    stylesName="serializationViewTabStyles"
                    borderedTable={true}
                    allowRowSelect={false}
                    includeToolBar={true}
                    currentSortItem={current}
                    resultText={`${rows.length} Instances`}
                    currentSortItemAscending={currentSortItemAscending}
                    scrollPagination={true}
                    paginationSize={30}
                    footerRow={
                        rows.length === 0 &&
                        {
                            dataCellEl: <p>No Production Instance added</p>
                        }
                    }

                />
            </div>
        return markup
    }

}

export default SerializationTab
