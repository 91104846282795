import validator from "validator"
import config from "../config"
import utils from "../utils"

const userSchema =

{
    firstName:
    {
        normalize: (value) =>
        {
            value = typeof value === "string" ? value : ""
            value = utils.toAlphaString(value)
            value = utils.toCondensedString(value)
            return value
        },

        validate: (value, excludes = []) =>
        {
            if (typeof value !== "string")
            {
                return "Invalid first name"
            }

            if (!value)
            {
                return excludes.includes("required") ? "" : "First name is required"
            }

            if (!validator.isAlpha(value))
            {
                return "Must only contain letters"
            }

            return ""
        }
    },

    lastName:
    {
        normalize: (value) =>
        {
            value = typeof value === "string" ? value : ""
            value = utils.toAlphaString(value)
            value = utils.toCondensedString(value)
            return value
        },

        validate: (value, excludes = []) =>
        {
            if (typeof value !== "string")
            {
                return "Invalid last name"
            }

            if (!value)
            {
                return excludes.includes("required") ? "" : "Last name is required"
            }

            if (!validator.isAlpha(value))
            {
                return "Must only contain letters"
            }

            return ""
        }
    },

    email:
    {
        normalize: (value) =>
        {
            value = typeof value === "string" ? value : ""
            value = value.toLowerCase()
            value = utils.toCondensedString(value)
            return value
        },

        validate: (value, excludes = []) =>
        {
            if (typeof value !== "string")
            {
                return "Invalid email"
            }

            if (!value)
            {
                return excludes.includes("required") ? "" : "Email is required"
            }

            if (!validator.isEmail(value))
            {
                return "Invalid email"
            }

            return ""
        }
    },

    password:
    {
        normalize: (value) =>
        {
            value = typeof value === "string" ? value : ""
            value = utils.toCondensedString(value)
            return value
        },

        validate: (value) =>
        {
            if (typeof value !== "string")
            {
                return "Invalid password"
            }

            if (!value)
            {
                return "Password is required"
            }

            if (value.length < config.PASSWORD_MIN)
            {
                return "Must have " + config.PASSWORD_MIN + " characters"
            }

            if (!utils.hasNumber(value))
            {
                return "Must contain a number"
            }

            if (!utils.hasLetter(value))
            {
                return "Must contain a letter"
            }

            if (!utils.hasSpecialChar(value))
            {
                return "Must contain a special character"
            }

            return ""
        }
    },

    confirmPassword:
    {
        normalize: (value) =>
        {
            value = typeof value === "string" ? value : ""
            value = utils.toCondensedString(value)
            return value
        },

        validate: (value, match, cb) =>
        {
            if (typeof value !== "string")
            {
                return "Invalid type"
            }

            if (!value || !match || value !== match)
            {
                return "Passwords do not match"
            }

            return ""
        }
    },

    title:
    {
        normalize: (value) =>
        {
            value = typeof value === "string" ? value : ""
            value = utils.toAlphaNumericString(value, " ")
            value = utils.toParagraphString(value)
            return value
        },

        validate: (value, match, cb) =>
        {
            return ""
        }
    },
}

export default userSchema
