import Utils   from "../../utils";

export default class IntegrationsAPI
{
    static valispaceAuthentication(data, cb)
    {
        Utils.post("/valispace/authentication", data , (err, res) =>
        {
            if(err)
            {
                return cb(err);
            }

            cb(null, res.data);
        })
    }

    static nexusAuthentication(data, cb)
    {
        Utils.post("/nexus/authentication", data , (err, res) =>
        {
            if(err)
            {
                return cb(err);
            }

            cb(null, res.data);
        })
    }

    static getIntegrations(cb)
    {
        Utils.get("/integration/getIntegrations", (err, res) =>
        {
            if(err)
            {
                return cb(err);
            }

            cb(null, res.data);
        })
    }

    static disconnectIntegration(data, cb)
    {
        let id = data.id;

        Utils.post("/integration/disconnectIntegration/" + id, data , (err, res) =>
        {
            if(err)
            {
                return cb(err);
            }

            cb(null, res.data);
        })
    }

    static updateIntegrations(data, cb)
    {
        let id = data.id;

        Utils.post("/integration/updateIntegrations/" + id, data , (err, res) =>
        {
            if(err)
            {
                return cb(err);
            }

            cb(null, res.data);
        })
    }

    static getValispaceData(data, cb)
    {
        Utils.post("/valispace/getData", data, (err, res) =>
        {
            if(err)
            {
                return cb(err);
            }

            cb(null, res.data);
        })
    }

    static getExistingComponents(vendorIDs, cb)
    {
        Utils.post("/valispace/getExistingComponents", {vendorIDs}, (err, res) =>
        {
            if(err)
            {
                return cb(err);
            }

            cb(null, res.data);
        })
    }

    static bulkNameExists(data, cb)
    {
        Utils.post("/valispace/isComponentsNameExists", data, (err, res) =>
        {
            if(err)
            {
                return cb(err);
            }

            cb(null, res.data);
        })
    }

    static getValispaceWorkspaces(cb)
    {
        Utils.get("/valispace/getWorkspaces", (err, res) =>
        {
            if(err)
            {
                return cb(err);
            }

            cb(null, res.data);
        })
    }

    static getValispaceProjects(data, cb)
    {
        Utils.post("/valispace/getProjects", data, (err, res) =>
        {
            if(err)
            {
                return cb(err);
            }

            cb(null, res.data);
        })
    }

    static createWithData(payload, cb)
    {
        Utils.post("/valispace/bulk/create", payload, (err, res) =>
        {
            if(err)
            {
                return cb(err)
            }
            res.data.forEach((cmp, i) => {
                payload.components[i]._id = cmp._id
                payload.components[i].cpn = cmp.cpn
                payload.components[i].cpnVariant = cmp.cpnVariant
            })
            return cb(null, payload.components)
        })
    }

    static mapComponent(data,cb)
    {
        Utils.post("/valispace/mapComponent", data, (err, res) =>
        {
            if(err)
            {
                return cb(err);
            }

            cb(null, res.data);
        })
    }

    static mcMasterLogin(data,cb)
    {
        Utils.post("/mcmaster/login", data, (err, res) =>
        {
            if(err)
            {
                return cb(err);
            }

            cb(null, res.data);
        })
    }

    static getCompanyIntegrations(cb)
    {
        Utils.get("/integration/getCompanyIntegrationsStatus", (err, res) =>
        {
            if(err)
            {
                return cb(err);
            }

            cb(null, res.data);
        })
    }
}
