import { SvgIcon } from "@mui/material";

export const TextSnippetIcon = () => (
  <SvgIcon viewBox="0 -2 20 20">
    <path
      fillRule="nonzero"
      d={`
        M1.2386,12.0947 L12.0947,12.0947 L12.0947,5.2641 L8.0692,1.2386 L1.2386,1.2386 L1.2386,12.0947 Z
        M1.2386,13.3333 C0.8986,13.3333 0.6072,13.2119 0.3643,12.9690
        C0.1214,12.7262 0,12.4347 0,12.0947 L0,1.2386 C0,0.8986
        0.1214,0.6072 0.3643,0.3643 C0.6072,0.1214 0.8986,0 1.2386,0
        L8.5974,0 L13.3333,4.7359 L13.3333,12.0947 C13.3333,12.4347
        13.2119,12.7262 12.9690,12.9690 C12.7262,13.2119 12.4347,13.3333 12.0947,13.3333 L1.2386,13.3333 Z
        M2.9690,10.0911 L10.3643,10.0911 L10.3643,8.9617 L2.9690,8.9617 L2.9690,10.0911 Z
        M2.9690,7.2313 L10.3643,7.2313 L10.3643,6.1202 L2.9690,6.1202 L2.9690,7.2313 Z
        M2.9690,4.3898 L8.0146,4.3898 L8.0146,3.2787 L2.9690,3.2787 L2.9690,4.3898 Z
        M1.2386,12.0947 L1.2386,1.2386 L1.2386,12.0947 Z
      `}
    />
  </SvgIcon>
);
