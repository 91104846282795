import { useCallback, useMemo } from "react";
import { ApolloClient, FetchPolicy, NormalizedCacheObject, gql, useLazyQuery, useQuery } from "@apollo/client";
import { Lot } from "build/models";
import { Component } from "design/models";
import { getSubErrors } from "v1/modules/utils/errors";
import { QuerySingleArgs } from "./common";
import {
  ComponentRevisionFragment,
  ComponentRevisionWithChildrenFragment,
  ComponentRevisionsLotFragment,
} from "../fragment/componentFragment";

const GET_COMPONENT = gql`
  query componentRevisionById($id: ID) {
    componentRevisionsByIds(ids: [$id]) {
      ...componentRevisionFragment
    }
  }
  ${ComponentRevisionFragment}
`;

const GET_COMPONENT_WITH_CHILDREN = gql`
  query componentRevisionWithChildrenById($id: ID) {
    componentRevisionsByIds(ids: [$id]) {
      ...componentRevisionWithChildrenFragment
    }
  }
  ${ComponentRevisionWithChildrenFragment}
`;

const GET_COMPONENT_REVISIONS_WITH_LOTS = gql`
  query componentRevisionsWithLots($ids: [ID!]!) {
    componentRevisionsByIds(ids: $ids) {
      ...componentRevisionsLotFragment
    }
  }
  ${ComponentRevisionsLotFragment}
`;

interface ComponentQueryData {
  componentRevisionsByIds?: Component[] | null;
}

interface CmpRevWithLots {
  build: {
    lots: Lot[]
  }
}

interface ComponentsWithLotsQueryData {
  componentRevisionsByIds?: CmpRevWithLots[]
}

/**
 * Loads up the component revision without the information about it's children.
 *
 * @param fetchPolicy How to fetch the data from the API.
 * @param id Id of the component to load.
 * @param skip When true the query isn't run.
 * @returns The component revision, when loaded, errors, and the loading state.
 */
export function useComponentRevision({ fetchPolicy = "cache-first", id, skip }: QuerySingleArgs) {
  const { data, error, loading } = useQuery<ComponentQueryData>(GET_COMPONENT, {
    fetchPolicy,
    skip,
    variables: { id },
  });

  const component = useMemo(() => data?.componentRevisionsByIds?.[0], [data?.componentRevisionsByIds]);

  return { component, componentError: error, componentLoading: loading };
}

/**
 * Loads up the component revision and it's children.
 *
 * @param client The apollo client used to load information from.
 * @param id Id of the component to load.
 * @param fetchPolicy How to fetch the data from the API.
 * @returns The component revision and errors.
 */
export async function getComponentRevisionWithChildren(
  client: ApolloClient<NormalizedCacheObject>,
  id: string,
  fetchPolicy: FetchPolicy = "cache-first",
) {
  try {
    const { data, errors } = await client.query<ComponentQueryData>({
      query: GET_COMPONENT_WITH_CHILDREN,
      variables: { id },
      fetchPolicy,
    });
    const component = data?.componentRevisionsByIds?.[0];

    return { component, componentError: errors };
  }
  catch (e: any) {
    return {
      component: null,
      errors: getSubErrors(e),
    };
  }
}

export function useComponentRevisionWithLots(fetchPolicy: FetchPolicy = "cache-first") {
  const [getComponentRevisionsWithLots,
    {
      data,
      error,
      loading,
    },
  ]  = useLazyQuery<ComponentsWithLotsQueryData>(GET_COMPONENT_REVISIONS_WITH_LOTS, { fetchPolicy });
  const fetchCmpRevWithLots = useCallback((ids: string[]) => {
    ids?.length && getComponentRevisionsWithLots({
      variables: { ids },
    });
  }, [getComponentRevisionsWithLots]);

  const updateCmpRevLotsCache = useCallback((
    client: ApolloClient<NormalizedCacheObject>,
    newLot: Lot,
    ids: string[],
  ) => {
    const prevData = client.readQuery({
      query: GET_COMPONENT_REVISIONS_WITH_LOTS,
      variables: { ids },
    });
    const newData = {
      ...(prevData && {
        ...prevData,
        componentRevisionsByIds: (prevData.componentRevisionsByIds || [])
          .map((cmpRev: CmpRevWithLots) => ({
            ...cmpRev,
            build: {
              ...cmpRev.build,
              lots: [...(cmpRev.build.lots || []), newLot],
            },
          })),
      }),
    };
    client.writeQuery({
      query: GET_COMPONENT_REVISIONS_WITH_LOTS,
      variables: { ids },
      data: newData,
    });
  }, []);
  const cmpRevLotsData = useMemo(() => data?.componentRevisionsByIds?.[0], [data?.componentRevisionsByIds]);
  return {
    fetchCmpRevWithLots,
    cmpRevLotsData,
    cmpRevLotError: error,
    cmpRevLotLoading: loading,
    updateCmpRevLotsCache,
  };
}
