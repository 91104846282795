import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="16"
      version="1.1"
      viewBox="0 0 20 16"
      id="tree-svg-icon"
    >
      <g id="tree-icon" fill="none" fillRule="evenodd" strokeWidth="1">
        <g stroke="#FFF" transform="translate(-1148 -493)">
          <g transform="translate(314 82)">
            <g transform="translate(834 411)">
              <g fillRule="nonzero">
                <path strokeLinecap="round" d="M4.8 3.556v10.54"></path>
                <path strokeLinecap="square" d="M10.8 7.111H5.333"></path>
                <rect
                  width="8.6"
                  height="2.81"
                  x="0.5"
                  y="0.5"
                  rx="1.405"
                ></rect>
                <rect
                  width="8.6"
                  height="2.81"
                  x="10.9"
                  y="5.579"
                  rx="1.405"
                ></rect>
                <rect
                  width="8.6"
                  height="2.81"
                  x="10.9"
                  y="12.437"
                  rx="1.405"
                ></rect>
                <path strokeLinecap="round" d="M10.8 14.222h-6"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
