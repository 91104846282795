import { ApolloClient, FetchPolicy, NormalizedCacheObject, gql, useLazyQuery, useQuery } from "@apollo/client";
import { Lot } from "build/models";
import { Component } from "design/models";
import { QuerySingleArgs } from "./common";
import { useCallback, useMemo } from "react";
import {
  ProductRevisionFragment, ProductRevisionWithChildrenFragment, ProductRevisionsLotFragment,
} from "graphql/fragment/productFragment";
import { getSubErrors } from "v1/modules/utils/errors";

export const GET_PRODUCT = gql`
  query productRevisionById($id: ID) {
    productRevisionsByIds(ids: [$id]) {
      ...productRevisionFragment
    }
  }
  ${ProductRevisionFragment}
`;

export const GET_PRODUCT_WITH_CHILDREN = gql`
  query productRevisionById($id: ID) {
    productRevisionsByIds(ids: [$id]) {
      ...productRevisionWithChildrenFragment
    }
  }
  ${ProductRevisionWithChildrenFragment}
`;

export const GET_PRODUCT_REVISIONS_WITH_LOT = gql`
  query productRevisionsWithLots($ids: [ID!]!) {
    productRevisionsByIds(ids: $ids) {
      ...productRevisionsLotFragment
    }
  }
  ${ProductRevisionsLotFragment}
`;

interface ProductQueryData {
  productRevisionsByIds?: Component[] | null;
}

interface PrdRevsWithLots {
  build: {
    lots: Lot[]
  }
}

interface PrdRevsWithLotsQueryData {
  productRevisionsByIds?: PrdRevsWithLots[]
}

/**
 * Loads up the product without the information about it's children.
 *
 * @param fetchPolicy How to fetch the data from the API.
 * @param id Id of the product to load.
 * @param skip When true the query isn't run.
 *
 * @returns The product revision, when loaded, errors, and the loading state.
 */
export function useProductRevision({ fetchPolicy = "cache-first", id, skip }: QuerySingleArgs) {
  const { data, error, loading } = useQuery<ProductQueryData>(GET_PRODUCT, {
    fetchPolicy,
    skip,
    variables: { id },
  });

  const product = useMemo(() => data?.productRevisionsByIds?.[0], [data?.productRevisionsByIds]);

  return { product, productError: error, productLoading: loading };
}

/**
 * Loads up the product revision and it's children.
 *
 * @param client The apollo client used to load information from.
 * @param id Id of the product revision to load.
 * @param fetchPolicy How to fetch the data from the API.
 * @returns The product revision and errors.
 */
export async function getProductRevisionWithChildren(
  client: ApolloClient<NormalizedCacheObject>,
  id: string,
  fetchPolicy: FetchPolicy = "cache-first",
) {
  try {
    const { data, errors } = await client.query<ProductQueryData>({
      query: GET_PRODUCT_WITH_CHILDREN,
      variables: { id },
      fetchPolicy,
    });
    const product = data?.productRevisionsByIds?.[0];
    return { product, productError: errors };
  }
  catch (e: any) {
    return {
      product: null,
      productError: getSubErrors(e),
    };
  }
}

export function useProductRevisionsWithLots(fetchPolicy: FetchPolicy = "cache-first") {
  const [getProductRevisionsWithLots,
    {
      data,
      error,
      loading,
    },
  ] = useLazyQuery<PrdRevsWithLotsQueryData>(GET_PRODUCT_REVISIONS_WITH_LOT, { fetchPolicy });

  const fetchPrdRevWithLots = useCallback((ids: string[]) => {
    ids?.length && getProductRevisionsWithLots({
      variables: { ids },
    });
  }, [getProductRevisionsWithLots]);

  const updatePrdRevLotsCache = useCallback((
    client: ApolloClient<NormalizedCacheObject>,
    newLot: Lot,
    ids: string[],
  ) => {
    const prevData = client.readQuery({
      query: GET_PRODUCT_REVISIONS_WITH_LOT,
      variables: { ids },
    });
    if (prevData?.productRevisionsByIds) {
      const newData = {
        ...prevData,
        productRevisionsByIds: prevData.productRevisionsByIds.map((prdRev: PrdRevsWithLots) => ({
          ...prdRev,
          build: {
            ...prdRev.build,
            lots: [...prdRev.build.lots, newLot],
          },
        })),
      };
      client.writeQuery({
        query: GET_PRODUCT_REVISIONS_WITH_LOT,
        variables: { ids },
        data: newData,
      });
    }
  }, []);

  const prdRevLotsData = useMemo(() => data?.productRevisionsByIds?.[0], [data?.productRevisionsByIds]);
  return {
    fetchPrdRevWithLots,
    prdRevLotsData,
    prdRevLotError: error,
    prdRevLotLoading: loading,
    updatePrdRevLotsCache,
  };
}
