import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="17"
      version="1.1"
      viewBox="0 0 21 17"
      id="grid-svg-icon"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#FFF" transform="translate(-1053 -493)">
          <g transform="translate(1033 484)">
            <g transform="translate(20 8)">
              <g transform="translate(0 1)">
                <g>
                  <g>
                    <path
                      fillRule="nonzero"
                      d="M19.485 0H1.146C.514 0 0 .56 0 1.25v14.5C0 16.44.514 17 1.146 17h18.34c.631 0 1.146-.56 1.146-1.25V1.25c0-.69-.515-1.25-1.147-1.25zM1.09.944h18.451c.128 0 .23.142.23.315v3.463H.86V1.26c0-.173.103-.315.23-.315zm5.787 11.334V9.444h6.018v2.834H6.877zm6.018.944v2.834H6.877v-2.834h6.018zm0-7.555V8.5H6.877V5.667h6.018zM6.018 8.5H.86V5.667h5.158V8.5zm0 .944v2.834H.86V9.444h5.158zm7.736 0h6.018v2.834h-6.018V9.444zm0-.944V5.667h6.018V8.5h-6.018zM.86 15.82v-2.598h5.158v2.834H1.084a.23.23 0 01-.224-.237zm18.65.236h-5.756v-2.834h6.018v2.597c0 .13-.117.237-.262.237z"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
