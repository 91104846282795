import React, { Fragment } from "react";
import DatePicker from "react-datepicker";
import InlineIcon from "../../../ui/icon/inline-icon";
import ItemScheduleIcon from "../../../../assets/icons/item-schedule-icon";
import Utils from "../../../../modules/utils";

const ERPDates = ({ effectivity, onInputChange }) => {

    const onDateChange = (key, date, event, i, onInputChange) => {
        const name = key === "Start Date" ? "startDate" : "endDate";
        const value = date && new Date(date.toISOString()).getTime();
        onInputChange({ target: { name, value: Number(value) } }, i);
    }
    const effectivityStateClass = effectivity.isEnabled.checked ? "enabled" : "disabled";
    const dates = { "Start Date": effectivity.startDate, "End Date": effectivity.endDate };
    
    return (
        Object.keys(dates).map((date, i) =>
            <Fragment>
                <InlineIcon tooltip="Date" tooltipPlace="top">
                    <ItemScheduleIcon />
                </InlineIcon>
                <DatePicker
                    selected={dates[date].value ? new Date(dates[date].value) : null}
                    onChange={(value) => onDateChange(date, value, null, i, onInputChange)}
                    dateFormat={"MMM d, yyyy"}
                    className={`custom-date-trigger ${dates[date].class} ${dates[date].class !== 'invalid' ? effectivityStateClass : ''}`}
                    placeholderText={date}
                    customInput={
                        <input
                            type="text"
                            value={dates[date].value ? Utils.dateFormatWithSlash(dates[date].value) : null}
                            data-tip={dates[date].message}
                            data-place="right"
                            data-type="error"
                            className={`${dates[date].class}`}
                        />
                    }
                />
            </Fragment>
        )
    )
}


export default ERPDates