import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
      <g data-name="Layer 2">
        <g>
          <path
            d="M18.57 1.07L17.32 2.31 31.63 16.62 0 16.62 0 18.38 31.63 18.38 17.32 32.69 18.57 33.93 35 17.5 18.57 1.07z"
            className="cls-10000"
          ></path>
          <path d="M0 0H35V35H0z" className="cls-2"></path>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
