import CO                                     from "../../../action-types/changeorder"
import initialState, { getInitialState }      from "./initial_state"
import { handleActions }                      from "redux-actions"

function setChangeOrder(state, { payload })
{
    state.co = payload
    return {...state}
}

function resetCoViewState(state, { payload })
{
    return {...getInitialState()}
}




export default handleActions({
  [CO.SET_CO_OBJECT]              : setChangeOrder,
  [CO.REST_CO_VIEW_STATE]         : resetCoViewState
}, initialState);
