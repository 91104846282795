
import React, {Component} from "react"
import Tooltip            from 'rc-tooltip';
import Utils              from "../../../../../../../../modules/utils/index.js";
import { schemas }        from '../../../../../../../../modules/validations';

export default class Categories extends Component
{

    constructor(props)
    {
        super(props)
        this.onSelectClick   = this.onSelectClick.bind(this)
        this.onInputChange   = this.onInputChange.bind(this)
        this.state =
        {
            input: null,
            inputIndex: 0,
            row: this.props.row,
            options: null
        }
    }

    componentWillMount()
    {
        let state      = this.state
        let row        = state.row
        let inputIndex = row.columns.indexOf("category")
        let input      = row.data[inputIndex]
        let categoryObj= schemas.categories.findByName2(input.value, window.__categories);
        if(Utils.isNonIntelligentCPNScheme())
        {
            input.displayName = input.value
        }
        else if(categoryObj)
        {
            input.displayName = categoryObj.displayNameWithPrefix
        }
        state.input = input
        state.inputIndex = inputIndex
        this.setState(state)
    }

    onInputChange(e, rowIndex, inputIndex)
    {
        let state = this.state
        state.input.value = e.target.value
        this.setState(state)

        this.props.parentComponent.onInputChange(state.input.value, rowIndex, inputIndex)
    }

    onSelectClick(e)
    {
        let state = this.state
        if(!state.options)
        {
            state.options = this.props.parentComponent.state.categories.options;
            this.setState(state);
        }
    }

    createToolTipClass(fileType, message){
        return `error default-styles ${fileType === "new_assembly" ? 'new_assembly-rc-tooltip' : 'new-file-rc-tooltip'} ${message ? '' : 'hidden'}`
    }

    render()
    {
        let markup     = ""
        let row        = this.state.row
        let input      = this.state.input
        let inputIndex = this.state.inputIndex
        let tooltipProps = input.class ? {} : {visible: false}
        let value       = input.value
        let disabledClazz = ""
        let tableSettings = this.props.tableSettings
        if (row.disabled)
        {
            disabledClazz = " disabled"
        }
        else if (row.selectedFromLibrary)
        {
            disabledClazz = " disabled selected-from-library"
            value = row.selectedCmpFromLibrary.category || ""
        }

        markup = <td  name={"category"} style={ {width: ( Utils.getStyleValue(tableSettings, "category", "width", 176, {onErrorDefault: true}) + 'px') }}>
                    <Tooltip
                        {...tooltipProps}
                        placement="right"
                        overlay={input.class ? input.message : ""}
                        destroyTooltipOnHide={true}
                        mouseLeaveDelay={0}
                        overlayClassName={this.createToolTipClass(this.props.fileType, input.message)}
                        arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                      >
                        <select
                            data-for={this.props.rowIndex + "" + inputIndex}
                            name={input.name}
                            className={input.class + disabledClazz}
                            value={value}
                            data-default-value={input.defaultOption}
                            onChange={(e) => this.onInputChange(e, this.props.rowIndex, inputIndex)}
                            onMouseDown={(e) => this.onSelectClick(e)}
                            data-tip={input.class ? input.message : ""}
                            data-place="right"
                            data-type="error"
                            >
                            <option value=""></option>
                            {
                                !row.selectedFromLibrary && this.state.options ?
                                    this.state.options
                                :
                                    <option value={value}>{input.displayName}</option>
                            }
                        </select>
                    </Tooltip>
                </td>

        return markup
    }
}
