import SEARCH                               from "../../action-types/search"
import initialState, { getInitialState }    from "./initial_state"
import { handleActions }                    from "redux-actions"

function setInitialState(state=initialState, { payload })
{
    return {...state, ...payload}
}

function updateSearchState(state, { payload })
{
    return {...state, ...payload}
}

function setLoading(state, { payload })
{
    return updateSearchState(state, { payload })
}

function setInputFocused(state, { payload })
{
    return updateSearchState(state, { payload })
}

function clearSelectedResults(state)
{
    state.selectedSearchResults = []//getInitialState().selectedSearchResults
    return {...state}
}

function resetState(state, { payload })
{
    return {...getInitialState(), ...payload}
}

export default handleActions({
    [SEARCH.SET_INITIAL_STATE]: setInitialState,
    [SEARCH.UPDATE_SEARCH_STATE]: updateSearchState,
    [SEARCH.SET_LOADING]: setLoading,
    [SEARCH.CLEAR_SELECTED_RESULTS]: clearSelectedResults,
    [SEARCH.SET_INPUT_FOCUSED]: setInputFocused,
    [SEARCH.RESET_STATE]: resetState
}, initialState);
