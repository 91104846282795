import React, { Component }         from "react";
import InlineIcon                   from "../../../../components/ui/icon/inline-icon.js";
import Utils                        from "../../../../modules/utils"
import ModalBox                     from "../../../ui/modal-box"
import CoAlertIcon                  from "../../../../assets/icons/co-alert"
import ReactTooltip                 from 'react-tooltip'
import LazyInput                    from "../../../ui/lazy-input/input.js"
import validations                  from "../../../../modules/validations"
import BulkUpdateInfoIcon           from "../../../../assets/icons/bulk-update-info-icon.js";

export default class StatusUpdateModalForNonAssembly extends Component
{
    render()
    {
        let markup = <div className={"confirm-status-changed-modal"}>
            <ModalBox onClose={this.props.onRejectStatusChange}>
            <h1>
                <InlineIcon><CoAlertIcon /></InlineIcon>
                {
                    this.props.statusFieldPreviousValue === 'DESIGN' ?
                    "Switch to Revision Control" :
                    `Moving into ${this.props.newStatusValue.toLowerCase()}`
                }
            </h1>
            <div className="warning-text">
                {
                    this.props.statusFieldPreviousValue === 'DESIGN' ?
                    <p>
                        This {this.props.item} will now be under revision control. All your changes will be tracked going forward. Use Change Orders to control new revisions.
                    </p>
                    :
                    <section>
                        <span>
                            This {this.props.item} will now be marked as In {Utils.capitalizeString(this.props.newStatusValue)}.
                        </span>
                        <p>
                            This can not be undone.
                        </p>
                    </section>
                }
                <div className="inputs-section">
                    <div>
                        <label className="muted-text">
                            NEW REVISION
                        </label>
                        <ReactTooltip id={"input-revision-modal-input"}
                            type="light"
                            effect="solid"
                            >
                        </ReactTooltip>

                        <LazyInput
                            type="text"
                            name="revision"
                            autoFocus={true}
                            value={this.props.revisionInput.value}
                            onChange={this.props.revisionInputChange}
                            className={this.props.revisionInput.class}
                            data-tip={this.props.revisionInput.message}
                            data-place="right"
                            data-type="error"
                            data-for='input-revision-modal-input'
                        />
                        <InlineIcon
                            className="revision-alert-icon"
                            tooltip = {Utils.getToolTipText(this.props.newStatusValue, validations, "prd")}
                            tooltipPlace = "right"
                        >
                            <BulkUpdateInfoIcon/>
                        </InlineIcon>

                    </div>

                    <div>
                        <label className="muted-text">
                            NEW STATUS
                        </label>

                        <span>
                            {this.props.newStatusValue}
                        </span>
                    </div>
                </div>
            </div>
            <div className="bottom-section">
                <div className="button-sections">
                  <button
                      type="button"
                      onClick={this.props.onRejectStatusChange}
                      >
                    Cancel
                  </button>

                  <button
                      type="button"
                      onClick={this.props.onAcceptStatusChange}
                      disabled={this.props.revisionInput.valid ? false : true}
                      className={this.props.revisionInput.valid ? "filled" : "disabled"}>
                    continue
                  </button>

                </div>
            </div>
            </ModalBox>
        </div>
        return markup
    }
}
