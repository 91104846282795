import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      viewBox="0 0 19 18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g stroke="#FFF" strokeWidth={1} fill="none" fillRule="evenodd">
        <path
          d="M5.785 0l1.48 7.352 4.93-3.386v3.386L17 4.585V16H0V7.352L2.08 0h3.705zM1.173 3.064h5.125"
          transform="translate(-1256 -85) translate(1257 86)"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
