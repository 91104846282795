import                  "./index.css"
import React       from "react"
import {connect}   from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import ModalBox    from "../modal-box"
import Utils       from "../..//../modules/utils"
import USER_FORM   from "../../../action-types/user_form"
import buildAction from "../../../helpers/buildAction"
import Loading     from "../../ui/inner-loading"

export class NewUserModalBox extends React.Component
{
    constructor(props, context)
    {
        super(props, context)
        this.onSubmit       = this.onSubmit.bind(this)
        this.onInputChange  = this.onInputChange.bind(this)
        this.addInvitedUser = this.addInvitedUser.bind(this)
        this.state          = {
            invitedUsers : []
        }
    }

    onInputChange(event)
    {
        const {dispatch} = this.props
        Utils.persistCursor(event, event.target.value)
        dispatch(buildAction(USER_FORM.UPDATE_INPUT_ON_CHANGE, event.target))
    }

    onSubmit(event)
    {
        const {dispatch} = this.props
        event.preventDefault()
        Utils.blur()
        let data = Utils.fromInputsToValues({...this.props.userForm.inputs})
        data.inviteModal = true
        let payload = { data }
        dispatch(buildAction(USER_FORM.SUBMIT_USER_FORM, payload))
        this.addInvitedUser(event, data.email)
    }

    addInvitedUser(event, email)
    {
        let state = this.state
        state.invitedUsers.unshift(email)
        this.setState(state)
    }

    render()
    {
        let inputs = this.props.userForm && this.props.userForm.inputs
        if (!inputs){
            return null
        }

        let userRows = this.state.invitedUsers.map((user, i) =>
        {
            let row  =
                <tr key={"user-" + i}>
                    <td>{user}</td>
                    <td>Invited</td>
                </tr>
            return row
        })

        let markup =

        <div className="invite-user-modal">
            <ModalBox onClose={this.props.onModalClose} className="">
                <h2>Invite team members to Duro</h2>
                <form onSubmit={this.onSubmit}>

                    <div className="names-block">
                        <div className="input-holder">
                            <label>
                                <input
                                    id="firstName"
                                    type="text"
                                    required
                                    name="firstName"
                                    className={inputs.firstName.class}
                                    value={inputs.firstName.value}
                                    onChange={this.onInputChange}
                                />
                                <div className="label-text">FIRST NAME*</div>
                                <div className="ui-message">{inputs.firstName.message}</div>
                            </label>
                        </div>

                        <div className="input-holder">
                            <label>
                                <input
                                    id="lastName"
                                    type="text"
                                    name="lastName"
                                    required
                                    className={inputs.lastName.class}
                                    value={inputs.lastName.value}
                                    onChange={this.onInputChange}
                                    placeholder=""

                                />
                                <div className="label-text">LAST NAME*</div>
                            <div className="ui-message">{inputs.lastName.message}</div>
                            </label>
                       </div>

                        <div className="input-holder">
                            <label>
                                <input
                                    id="email"
                                    type="text"
                                    name="email"
                                    required
                                    className={inputs.email.class}
                                    value={inputs.email.value}
                                    onChange={this.onInputChange}
                                    placeholder=""
                                />
                                <div className="label-text">EMAIL*</div>
                                <div className="ui-message">{inputs.email.message}</div>
                            </label>
                        </div>
                    </div>

                    <div className="submit-block flex-h flex-v">
                        <input
                            type="submit"
                            value="ADD MEMBER"
                            className={this.props.userForm.showInnerLoading ? "disabled" : inputs.submit.class}
                        />
                    </div>
                </form>
                {
                    this.props.userForm.showInnerLoading ?
                        <Loading/>
                    :
                        null
                }
                <PerfectScrollbar className="area table-block">
                    <div className="table-block">
                        <table>
                         <tbody>
                            {userRows}
                         </tbody>
                        </table>
                    </div>
                </PerfectScrollbar>
                <a onClick={this.props.onModalClose} className="close-btn">CLOSE</a>
            </ModalBox>
        </div>

        return markup
    }
}

export default connect((store) => store)(NewUserModalBox)
