import React, {Fragment} from 'react'
import LazyInput from '../../../ui/lazy-input/input'
import Utils from './../../../../modules/utils'

const SpecInputField = ({input, onInputChange, allowedValues, isDropDown, index}) => {
    const dataTipPosition = index % 2 ? "right" : "left"
    return (
     isDropDown ? 
        <select 
            name="spec" 
            value={input.value} 
            onChange={(event) => onInputChange(event, index)} 
            className={`spec-select ${input.class}`}
            data-tip={input.message ? 'Must select a value' : ''}
            data-place={dataTipPosition}
            data-type="error"
        >{Utils.toOptions(allowedValues, true, null, 'Select Value')}</select>
        :
        <LazyInput
            type="text"
            name="spec"
            value={input.value}
            onChange={(event) => onInputChange(event, index)}
            className={`form-control ${input.class}`}
            data-tip={input.message}
            data-place={dataTipPosition}
            data-key={input.key ? input.key : ''}
            data-type="error"
        />
    )
}
export default SpecInputField