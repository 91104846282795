import DashboardIcon from "@mui/icons-material/Dashboard";
import { Box, Breadcrumbs, Link, SvgIconTypeMap, Typography, styled } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { ChangeOrderIcon, ComponentIconFilled, LotIcon, ProductIconFilled } from "assets/icons";
import { FC, useMemo } from "react";
import { colorPalette } from "theme";
import { BreadCrumbIcon, IBreadCrumb } from "./useBreadCrumb";

type SvgType = OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
  muiName: string;
}

export interface IEnumType {
  [key: string]: SvgType | React.ElementType;
}

const PossibleIcons: IEnumType = Object.freeze({
  [BreadCrumbIcon.CHANGEORDER]: ChangeOrderIcon,
  [BreadCrumbIcon.COMPONENTS]: ComponentIconFilled,
  [BreadCrumbIcon.DASHBOARD]: DashboardIcon,
  [BreadCrumbIcon.LOT]: LotIcon,
  [BreadCrumbIcon.PRODUCTS]: ProductIconFilled,
});

export * from "./useBreadCrumb";

export const BreadCrumbComponent: FC<IBreadCrumb> = ({ breadCrumbOptions }) => {
  const body = useMemo((): React.ReactNode => {
    const lastIndex = breadCrumbOptions.length - 1;
    return (
      breadCrumbOptions.map(({ icon, label, url }, index) => {
        const IconToRender = icon && PossibleIcons[icon];
        return (
          <Box
            key={`${index}-icon`}
            sx={{
              alignItems: "center",
              display: "flex",
            }}
          >{index === 0 && IconToRender && <IconToRender sx={{ height: "1.5rem", width: "1.5rem" }} />}
            {
              index === lastIndex
                ? <BreadcrumbLabel>{label}</BreadcrumbLabel>
                : <Link
                  color="primary"
                  href={url}
                  sx={{ textDecoration: "none" }}
                >
                  {label}
                </Link>}
          </Box>
        );
      }));
  }, [breadCrumbOptions]);

  return (
    <CustomBreadcrumb separator=">" color="primary" >
      {body}
    </CustomBreadcrumb>);
};

const BreadcrumbLabel = styled(Typography)({
  color: colorPalette.taupeGray,
  fontSize: "0.875rem",
  marginLeft: "0.188rem",
});

const CustomBreadcrumb = styled(Breadcrumbs)({
  "& .MuiBreadcrumbs-separator": {
    color: colorPalette.taupeGray,
  },
});
