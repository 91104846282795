import React            from "react"

class LazyInput extends React.PureComponent
{
    constructor(props)
    {
        super(props)
        this.state = {
            typingTimeout: 0,
            inputValue: [undefined, null].includes(this.props.value) ? '' : this.props.value
        }
        this.onInputChange = this.onInputChange.bind(this)
    }

    componentWillReceiveProps(nextProps)
    {
        if (nextProps.value !== this.props.value || nextProps.shouldRerender) {
            this.setState({inputValue: nextProps.value});
        }
    }

    onInputChange(event){

        this.props.disableContinueButton && this.props.disableContinueButton()
        if (this.state.typingTimeout) {
           clearTimeout(this.state.typingTimeout);
        }
        let _this = this
        let _event = {...event}

        this.setState({
           inputValue: event.target.value,
           typingTimeout: setTimeout(function () {
               _this.props.onChange(_event)
             }, this.props.timeout || 800)
        });
    }

    render()
    {
        const inputProps = Object.assign({}, this.props);
        delete inputProps.value;
        delete inputProps.onChange;
        delete inputProps.timeout;

        let markup =
            <input
                type="text"
                onChange={this.onInputChange}
                value={this.state.inputValue}
                className={this.props.className ? this.props.className : ""}
                data-input-value={this.props.value}
                {...inputProps}
            />
        return markup
    }
}

export default LazyInput
