import React from "react";
import ToggleBtn                          from "../../../../../../ui/toggle-btn"
import InputField                         from "./modules/input-field"
import RevertIcon                         from "../../../../../../../assets/icons/revert-icon"
import InlineIcon                         from "../../../../../../ui/icon/inline-icon.js"
import Tooltip                            from "rc-tooltip"

export default (props) => {
    let tableSettings = props.tableSettings
    let markup =
        <tbody>
        {
            !props.state.showProgressBar &&
            props.inputs.map((row, r) =>
            {
                let disabledClazz = props.isRowDisabled(row) ? "" : "disabled"
                let isSelectedFromLibrary = props.isRowSelectedFromLibrary(row)
                let isRowChecked = props.state.checkedRows.includes(r)

                if (isSelectedFromLibrary)
                {
                    disabledClazz = "disabled"
                }

                let block =
                 <tr key={r}>
                     <td className="first-child">
                      {!isSelectedFromLibrary && props.isValidRow(row.data) === "invalid-row" ? <span className="error-circle"></span> : null}
                     <ToggleBtn
                        name="disbaleMe"
                        selected={props.isRowDisabled(row)}
                        onChange={(e) => props.toggleRowState(e, r)}
                        />
                    </td>
                    {
                        ["new", "new_assembly"].includes(props.fileType) && !isSelectedFromLibrary ?
                        <td className={"second-child indexes " + (isRowChecked ? "selected" : "")}>
                        <span className="index-number block">{r + 1}</span>
                        <span className="index-checkbox hidden">
                            <input
                                type="checkbox"
                                name="category-check-all"
                                className={disabledClazz}
                                checked={isRowChecked}
                                onClick={(e) => props.checkThisRow(e, r)}
                                onChange={null}
                            />
                            <label
                                htmlFor="category-check-all"
                                className={disabledClazz}
                            />
                        </span>
                       </td> : ["update", "update_assembly"].includes(props.fileType) ? null :
                                <td className="second-child">
                                    <Tooltip
                                        placement="top"
                                        overlay="Revert"
                                        overlayClassName={"simple-rc-tip default-styles dark-tooltip"}
                                        >
                                        <div>
                                        <InlineIcon onClick={(e) => props.selectFromLibrary(e,r)}>
                                            <RevertIcon/>
                                        </InlineIcon>
                                        </div>
                                    </Tooltip>
                                </td>
                    }
                    {
                        ["new", "new_assembly"].includes(props.fileType) ?
                        props.createCategoryTdMarkup(row, r)
                        : null
                    }
                    {

                        row.data.map((input, c) =>
                        {
                            if (input.name === "category")
                            {}
                            else if (input.name === "unit of measure")
                            {
                                return props.createUnitOfMeasureTdMarkup(row, r)
                            }
                            else
                                return <InputField
                                            key={c}
                                            parentComponent={props.parentComponent}
                                            input={input}
                                            row={row}
                                            col={c}
                                            rowIndex={r}
                                            onInputChange={props.onInputChange}
                                            mpnImportDialog={props.mpnImportDialog}
                                            selectFromLibrary={props.selectFromLibrary}
                                            fileType={props.fileType}
                                            tableSettings={tableSettings}
                                        />
                        })
                    }
                    </tr>
                return block
            })
        }
    </tbody>

    return markup

};
