import                     "./index.css";
import React, {Component}  from "react";
import NexusModal          from "../nexus-modal";
import ConfigurationModal  from "../configuration-modal";
import ValispaceLoginModal from "../valispace-login-modal";
import McMasterLogin       from "../mcmaster-modal";
import API                 from "../../../../../modules/api";
import Utils               from "../../../../../modules/utils";
import Loading             from "../../../../ui/inner-loading";
import ExtendedTable       from "../../../common/extended-table";
import UI                  from '../../../../../action-types/ui';
import TimeStampColumn     from "../../../common/timestamp-column";
import buildAction         from "../../../../../helpers/buildAction";
import InlineIcon          from "../../../../ui/icon/inline-icon.js";
import HelpIcon            from "../../../../../assets/icons/help-icon.js";

export default class IntegrationsTable extends Component
{
    constructor(props, context)
    {
        super(props, context);
        let viewStyles;
        try
        {
            viewStyles = window.__userStyles.styles.settingsIntegrations || {}
        }
        catch(error) {
            viewStyles = {}
        }

        this.displayNexusModal   = this.displayNexusModal.bind(this);
        this.displayMcMasterModal = this.displayMcMasterModal.bind(this);
        this.displayValispaceLoginModal = this.displayValispaceLoginModal.bind(this);
        this.displayConfigurationSSOModal = this.displayConfigurationSSOModal.bind(this);

        this.state =
        {
            activeValispaceLoginIcon   : true,
            displayValispaceLoginModal : false,
            displayNexusModal     : false,
            displaySSOModal : false,
            displayMcMasterModal: false,
            integrations: {},
            companyData: false,
            headings :
            [
                {
                    key         : "help",
                    displayName : "",
                    tooltip     : "",
                    sortable    : true,
                    minWidth    : 35,
                    width       : Utils.getStyleValue(viewStyles, "name", "width", 45),
                    position    : Utils.getStyleValue(viewStyles, "name", "position", 0),
                    visibility  : Utils.getStyleValue(viewStyles, "name", "visibility", true),
                    disabled    : true
                },
                {
                    key         : "name",
                    displayName : "Name",
                    tooltip     : "Name",
                    sortable    : true,
                    minWidth    : 65,
                    width       : Utils.getStyleValue(viewStyles, "name", "width", 100),
                    position    : Utils.getStyleValue(viewStyles, "name", "position", 1),
                    visibility  : Utils.getStyleValue(viewStyles, "name", "visibility", true),
                    disabled    : true
                },
                {
                    key         : "type",
                    displayName : "Type",
                    tooltip     : "Type",
                    sortable    : true,
                    minWidth    : 60,
                    width       : Utils.getStyleValue(viewStyles, "type", "width", 100),
                    position    : Utils.getStyleValue(viewStyles, "type", "position", 2),
                    visibility  : Utils.getStyleValue(viewStyles, "type", "visibility", true),
                    disabled    : true
                },
                {
                    key         : "status",
                    displayName : "Status",
                    tooltip     : "Status",
                    sortable    : true,
                    minWidth    : 105,
                    width       : Utils.getStyleValue(viewStyles, "status", "width", 120),
                    position    : Utils.getStyleValue(viewStyles, "status", "position", 3),
                    visibility  : Utils.getStyleValue(viewStyles, "status", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "action",
                    displayName : "Action",
                    tooltip     : "Action",
                    sortable    : true,
                    minWidth    : 160,
                    width       : Utils.getStyleValue(viewStyles, "action", "width", 160),
                    position    : Utils.getStyleValue(viewStyles, "action", "position", 4),
                    visibility  : Utils.getStyleValue(viewStyles, "action", "visibility", true),
                    disabled    : false
                },
                {
                    key         : "transaction",
                    displayName : "Last Transaction",
                    tooltip     : "Last Transaction",
                    sortable    : true,
                    minWidth    : 160,
                    width       : Utils.getStyleValue(viewStyles, "transaction", "width", 160),
                    position    : Utils.getStyleValue(viewStyles, "transaction", "position", 5),
                    visibility  : Utils.getStyleValue(viewStyles, "transaction", "visibility", true),
                    disabled    : false
                }
            ],
            availableIntegrations: [{
                    actionName: "Plugin",
                    actionUrl: "https://duro.zendesk.com/hc/en-us/articles/360029171772-Onshape",
                    lastModified: "",
                    modal: "",
                    name: "ONSHAPE",
                    status: "Available",
                    type: "CAD",
                }, {
                    actionName: "Plugin",
                    actionUrl: "https://duro.zendesk.com/hc/en-us/sections/8451260677524-Solidworks",
                    lastModified: "",
                    modal: "",
                    name: "SOLIDWORKS",
                    status: "Available",
                    type: "CAD",
                }, {
                    actionName: "Connection",
                    actionUrl: "https://duro.zendesk.com/hc/en-us/articles/8409257115028-Valispace",
                    lastModified: "",
                    modal:this.displayValispaceLoginModal,
                    name: "Valispace",
                    status: "Not Available",
                    type: "Validation",
                }, {
                    actionName: "Connection",
                    actionUrl: "https://duro.zendesk.com/hc/en-us/articles/10886161401108-NetSuite",
                    lastModified: "",
                    modal: this.displayNexusModal,
                    name: "Netsuite",
                    status: "Not Available",
                    type: "ERP",
                }, {
                    actionName: "Connection",
                    actionUrl: "https://duro.zendesk.com/hc/en-us/articles/10521347374484-First-Resonance",
                    lastModified: "",
                    modal: "",
                    name: "First Resonance",
                    status: "Not Available",
                    type: "MES",
                },
            ]
        }

        this.onClose             = this.onClose.bind(this);
        this.showAlert           = this.showAlert.bind(this);
        this.disconnect          = this.disconnect.bind(this);
        this.getRowData          = this.getRowData.bind(this);
        this.getHelpIcon         = this.getHelpIcon.bind(this);
        this.getIntegrations     = this.getIntegrations.bind(this);
        this.getButtonAction     = this.getButtonAction.bind(this);
        this.setIntegrationStatus = this.setIntegrationStatus.bind(this);
        this.formatIntegrationName = this.formatIntegrationName.bind(this);
    }

    componentDidMount()
    {
        this.setIntegrationStatus();
        this.getIntegrations();
    }

    componentDidUpdate()
    {
        this.setIntegrationStatus();
    }

    setIntegrationStatus()
    {
        let state = this.state;
        let company = this.props.company.data;
        let integrationFlags = ["isValispaceEnabled", "isNexusEnabled", "isFirstResonanceEnabled"];
        if(!state.companyData && Object.keys(company).length !== 0)
        {
            integrationFlags.forEach((flag, i) => {
                if(company.settings[flag])
                {
                    state.availableIntegrations[i+2].status = "Available";
                }
            })
            company.vendors.forEach((item, i) => {
                if(item === "ONSHAPE")
                    state.availableIntegrations[i].status = "Available";
                else if(item === "SOLIDWORKS")
                    state.availableIntegrations[i+1].status = "Available";
            })
            state.companyData = true;
            this.setState({availableIntegrations: state.availableIntegrations, companyData: true});
        }
    }

    getIntegrations()
    {
        let state = this.state;
        API.integrations.getIntegrations((err, response) =>
        {
            if(err)
            {
                return;
            }
            state.integrations    = response.integrations;
            window.__integrations = response.integrationsStatus;
            this.setState(state);
        })
    }

    onClose(modalName)
    {
        this.setState({[modalName]: false});
    }

    displayValispaceLoginModal(event)
    {
        event.stopPropagation();
        this.setState({displayValispaceLoginModal: true});
    }

    displayNexusModal(event) {
        event.stopPropagation();
        this.setState({displayNexusModal: true})
    }

    displayConfigurationSSOModal(event) {
        event.stopPropagation();
        this.setState({displaySSOModal: true});
    }

    displayMcMasterModal(event) {
        event.stopPropagation();
        this.setState({displayMcMasterModal: true});
    }

    formatIntegrationName(vendor)
    {
        if(vendor.toLowerCase() === "onshape") vendor = "Onshape";
        else if(vendor.toLowerCase() === "solidworks") vendor = "Solidworks";
        return vendor;
    }

    getHelpIcon(actionUrl)
    {
        return (
        <div>
            <a className="link" href={actionUrl} target="_blank" data-tip={"Click to learn more."}>
                <InlineIcon>
                    <HelpIcon/>
                </InlineIcon>
            </a>
        </div>
        )
    }

    getButtonAction(integration, status)
    {
        let isConnected = (status === "Connected");
        let action  =  !isConnected ? "Connect" : "Disconnect";
        let dataTip = `Click to ${action} integration`;
        if(integration.status === "Not Available")
        {
            return (
            <div>
                <a className='link disable' data-tip={"Contact sales@durolabs.co"}>
                    {action}
                </a>
            </div>)
        }
        else
        {
            return(
            <div>
                <a className='link'
                    data-tip={dataTip}
                    onClick={!isConnected ? integration.modal : (e) => this.disconnect(e, integration.name)}
                >
                    {action}
                </a>
            </div>)
        }
    }

    getRowData()
    {
        let rows = [];

        let res  = this.state.integrations;
        this.state.availableIntegrations.forEach((integration, i) => {
            let status = integration.status;
            let lastModified = integration.lastModified;
            if(res[integration.name])
            {
                status = res[integration.name].status ? res[integration.name].status : integration.status;
                lastModified = res[integration.name].lastModified ? res[integration.name].lastModified : integration.lastModified;
            }
            let action  =  status !== "Connected" ? "Connect" : "Disconnect";
            let actionUrl = integration.actionUrl;
            let actionName = integration.actionName;
            let row = {
                "help":
                {
                    value : "Help",
                    tooltip: "",
                    cellClass: "help-icon-class",
                    displayValue : this.getHelpIcon(actionUrl),
                    notLink      : true
                },
                "name":
                {
                    value        : integration.name,
                    tooltip      : "",
                    displayValue : this.formatIntegrationName(integration.name)

                },
                "type":
                {
                    value        : integration.type,
                    tooltip      : "",
                    displayValue : integration.type
                },
                "status":
                {
                    value        : status,
                    tooltip      : status,
                    displayValue : status
                },
                "action":
                {
                    value        : actionName === "Plugin" ? actionUrl : action,
                    tooltip      : "",
                    displayValue : <div className="action">
                                    {
                                        actionName === "Plugin" ?
                                        <a className="link" href={actionUrl} target="_blank">
                                            Get Plugin
                                        </a>
                                        :
                                        this.getButtonAction(integration, status)
                                    }
                                    </div>,
                    notLink      : true
                },
                "transaction":
                {
                    value        : lastModified,
                    tooltip      : "",
                    displayValue : lastModified ? <TimeStampColumn key={Utils.generateUniqueId()} format='date-time-with-long-format' value={lastModified}/> : ""
                },
            }
            rows.push(row);
        })
        return rows;
    }

    showAlert(type, value)
    {
        const {dispatch} = this.props
        if(type === "modal")
        {
            dispatch(buildAction(UI.SHOW_ALERT, {type: "modal", text: value}))
        }

        if(type === "errors")
        {
            dispatch(buildAction(UI.SHOW_ALERT, {type: "errors", errors: value, err: new Error(value[0])}))
        }
    }

    disconnect(event, name)
    {
        let data = {"id":this.state.integrations[name].id, "status": "Disconnected"};
        API.integrations.disconnectIntegration(data, (err, response) =>
        {
            if(err)
            {
                return;
            }
            this.getIntegrations();
        });
    }

    render()
    {
        let tableHeaders = this.state.headings

        let rows = this.getRowData();
        let markup =
            <div className="integrations-table">
            {
                this.state.displayValispaceLoginModal ?
                <ValispaceLoginModal onClose={this.onClose} showAlert={this.showAlert} getIntegrations={this.getIntegrations} integrations={this.state.integrations}/>
                : null
            }
            {
                this.state.displayNexusModal ?
                <NexusModal onClose={this.onClose} showAlert={this.showAlert} getIntegrations={this.getIntegrations} integrations={this.state.integrations}/>
                : null
            }
            {
                this.state.displaySSOModal ?
                <ConfigurationModal
                    onClose={this.onClose}
                    dispatch={this.props.dispatch}
                    integrations={this.state.integrations}
                    getIntegrations={this.getIntegrations}
                />
                : ""
            }
            {
                this.state.displayMcMasterModal ?
                <McMasterLogin onClose={this.onClose} showAlert={this.showAlert} getIntegrations={this.getIntegrations} integrations={this.state.integrations}/>
                : null
            }
                <div className="integrations-list">
                {
                    this.props.loading ? <Loading />
                    :
                    <ExtendedTable
                        wrapperClassName="integrations-block"
                        wrapperSelectorClass="integrations-view"
                        headings={tableHeaders}
                        rows={rows}
                        stylesName="integrationsSetting"
                        allowRowSelect={false}
                        includeToolBar={true}
                        resultText={`${rows.length} integrations`}
                        disableDataSorting={true}
                        syncWithParentState={true}
                    />
                }
                </div>
            </div>
        return markup
    }
}

