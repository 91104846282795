import { SvgIcon } from "@mui/material";

export const TodoIcon = () => (
  <SvgIcon width="16px" height="16px" viewBox="0 0 16 16">
    <g fill-rule="evenodd">
      <g>
        {/* eslint-disable-next-line max-len */}
        <path d="M14.667 4.667h-6V6h6V4.667zm0 5.333h-6v1.333h6V10zM3.693 7.333l-2.36-2.36.94-.94 1.414 1.414L6.513 2.62l.94.94-3.76 3.773zm0 5.334-2.36-2.36.94-.94 1.414 1.413 2.826-2.827.94.94-3.76 3.774z" />
      </g>
    </g>
  </SvgIcon>
);
