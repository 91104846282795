import React      from "react";
import Utils      from "../../../../modules/utils";

const CpnField = ({ item }) => {
    return( <span className='cpn-value'>
               {Utils.getCpn(item)}
            </span>
    )
}
export default CpnField;
