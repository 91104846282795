// TODO: make all image source files uppercase
// TODO: create component navigation block
import                                          "./index.css"
import React, { Component } from "react"
import { cloneDeep } from "lodash";
import Link                                     from "../../../../ui/link"
import Icon                                     from "../../../../ui/icon"
import Utils                                    from "../../../../../modules/utils"
import Schemas                                  from "../../../../../modules/schemas"
import CategoryTypes                            from "../modules/category-types"
import CategorySelect                           from "../modules/category-select"
import Thumbnails                               from "../../../common/thumbnails"
import Documents                                from "../../../common/documents/new"
import LazyInput                                from "../../../../ui/lazy-input/input.js"
import TextArea                                 from "../../../../ui/lazy-input/textarea.js"
import Tooltip                                  from 'rc-tooltip'
import InlineIcon                               from "../../../../ui/icon/inline-icon.js"
import HelpIcon                                 from "../../../../../assets/icons/help-icon.js"
import LinkIcon                                 from "../../../../../assets/icons/open-link-icon.js"
import specLabelSrc                             from "../../../../../assets/icons/specs-label.svg"
import ModalBox                                 from "../../../../ui/modal-box"
import PerfectScrollbar                         from 'react-perfect-scrollbar';
import SubmitBtn                                from "../../../../ui/spinner-button";
import validations, { validateField, schemas }  from '../../../../../modules/validations';
import API                                      from "../../../../../modules/api";
import ComponentSuccessPopover                  from "../../../common/component-success-popover";
import SpecInputField                           from "../../../common/components/spec-input";
import 'react-perfect-scrollbar/dist/css/styles.css'
import { publicPaths } from "v1/app/routes";
import { userById } from "graphql/query/userQueries";
import { ModelType, extractCpnRules } from "utils/cpn";

export class NewComponentManual extends Component
{
    constructor(props, context)
    {
        super(props, context);
        this.onSubmit          = this.onSubmit.bind(this);
        this.onInputChange     = this.onInputChange.bind(this);
        this.getSpecs          = this.getSpecs.bind(this);
        this.getData           = this.getData.bind(this);
        this.onThumbnailChange = this.onThumbnailChange.bind(this);
        this.onDocumentChange  = this.onDocumentChange.bind(this);
        this.getSpecInputs     = this.getSpecInputs.bind(this);
        this.onNameChange      = this.onNameChange.bind(this);
        this.onEidChange       = this.onEidChange.bind(this);
        this.goteCmpEditRoute  = this.goteCmpEditRoute.bind(this);
        this.toggleAddMore     = this.toggleAddMore.bind(this);
        this.getInitialState   = this.getInitialState.bind(this);
        this.resetStateFields = this.resetInputs.bind(this);
        this.setScrollToTop    = this.setScrollToTop.bind(this);
        this.onClose           = this.onClose.bind(this);
        this.validations       = validations.component.children;
        this.getComponetFromAPIAndCreateChildAssemblyInput = this.getComponetFromAPIAndCreateChildAssemblyInput.bind(this);
        this.state             = this.getInitialState();
    }

    onClose()
    {
        this.props.onClose("displayManualModal", false);
    }

    setScrollToTop()
    {
        const scrollableSection = document.querySelector( ".component-manual-scroll");
        if (scrollableSection) scrollableSection.scrollTop = 0;
    }

    resetInputs(inputs)
    {
        // Warning: This mutates inputs :/
        inputs.category.value = "";
        inputs.specs = [];
        inputs.name.value = "";
        inputs.eid.value = "";
        inputs.description.value = "";
        inputs.images = [];
        inputs.documents = [];
        inputs.name.interacted = false;
        this.setScrollToTop();
    }

    getInitialState()
    {
        let revision = ""
        if (window.__libraryType === "GENERAL")
        {
           revision = Utils.getInitialRevision()
        }

        let intialState =  {
            inputs :
            {
                displayPopover: false,
                addMore : false,
                isUploadedDocuments: true,
                isUploadedThumbnails: true,
                categoryTypes :
                {
                    value    : "",
                    disabled : false
                },

                category :
                {
                    value    : "",
                    valid    : false,
                    message  : "",
                    class    : "",
                    disabled : false
                },

                specs :
                [

                ],

                name :
                {
                    value      : "",
                    valid      : false,
                    interacted : false,
                    class      : "",
                    message    : ""
                },

                revision :
                {
                    value : revision,
                    valid : true
                },

                status :
                {
                    value : "DESIGN",
                    valid : true
                },

                eid :
                {
                    value   : "",
                    valid   : true,
                    class   : "",
                    message : ""
                },

                description :
                {
                    value : "",
                    valid : true
                },

                submit :
                {
                    class : "disabled",
                    stopLoading: false,
                },

                images :
                [

                ],

                documents :
                [

                ]
            }
        };
        return intialState;
    }

    toggleAddMore()
    {
        let {addMore} = this.state;
        this.setState({addMore: !addMore});
    }

    getSpecInputs(category, isSpecValidationEnabled)
    {
        let categoryObject  = category ? schemas.categories.findByName2(category, window.__categories) : [];
        let specs           = categoryObject && categoryObject.specs ? categoryObject.specs : [];
        let inputs = [];
        if (specs && specs.names)
        {
            inputs = specs.names && specs.names.map((spec, i) =>
            {
                let required = specs.options.required.includes(spec) ? true : false;
                const { allowedValues, isDropDown} = specs.specSettings.find(specName => Object.keys(specName) == spec)[spec];
                let input =
                {
                    value   : "",
                    class   : "",
                    valid   : true,
                    message : "",
                    schema  : {name: spec, displayName: spec, required, allowedValues, isDropDown}
                };
                validateField(input, validations.component.specs.value, input.value, {categoryObject: categoryObject, key: input.schema.name, isSpecValidationEnabled});
                return input;
            });
        }
        return inputs;
    }

    onEidChange(payload)
    {
        let state   = this.state;
        let inputs  = state.inputs;
        let input   = inputs.eid;
        let target  = payload.target;
        let value   = target.value;

        validateField(input, validations.component.eid, value);
        state["eidDuplicateOf"] = null;
        if (input.valid)
        {
            input.valid = false;
            inputs.submit.class = "disabled";
            this.setState(state);
            API.components.eidExists({eid: String(value.trim())}, (err, data) =>
            {
                let exists = data.exist;
                input.message = exists ? "EID already exists in library." : "";
                input.valid   = !input.message;
                input.class   = input.valid ? "" : "invalid";
                state["eidDuplicateOf"] = data.duplicate_of;

                inputs.submit.class = Utils.isValidated(inputs) && inputs.isUploadedThumbnails && inputs.isUploadedDocuments ? "" : "disabled";
                this.setState(state);
            });
        }
        input.value = value;
        inputs.submit.class = Utils.isValidated(inputs) && inputs.isUploadedThumbnails && inputs.isUploadedDocuments ? "" : "disabled";
        this.setState(state);
    }

    onNameChange(payload)
    {
        let state   = this.state;
        let inputs  = state.inputs;
        let input   = inputs.name;
        let value   = payload.value;

        validateField(input, validations.component.name, value.trim());
        input.value = value;
        state["nameDuplicateOf"] = null;
        if (input.valid)
        {
            input.valid = false;
            inputs.submit.class = "disabled";
            this.setState(state);

            API.components.nameExists({name: value}, (err, data) =>
            {
                let exists = data.exist;
                input.message = exists ? "Name already exists in library." : "";
                input.valid   = !input.message;
                state["nameDuplicateOf"] = data.duplicate_of;
                input.class   = input.valid ? "" : "invalid";

                inputs.submit.class = Utils.isValidated(inputs) && inputs.isUploadedThumbnails && inputs.isUploadedDocuments ? "" : "disabled";
                this.setState(state);
            });
        }
        input.value = value;
        inputs.submit.class = Utils.isValidated(inputs) && inputs.isUploadedThumbnails && inputs.isUploadedDocuments ? "" : "disabled";
        this.setState(state);
    }

    onInputChange(event, i)
    {
        let payload =
        {
            target: event.target,
            index: i,
            type: 'changeInput'
        };
        this.handleChangeAction(payload);
    }

    handleChangeAction(payload)
    {
        let state = this.state;
        let inputs = state.inputs;
        const isSpecValidationEnabled = window.__isSpecValidationEnabled

        if(payload.type === 'submit')
        {
            inputs.submit.class = "disabled";
        }
        else
        {
            state.displayPopover = false;
            state.forceResetChildState = false;
            let target  = payload.target;
            let name    = target.name;
            let value   = target.value;
            let i       = payload.index;
            switch(name)
            {
                case "categoryTypes" :
                {
                    inputs.categoryTypes.value = value;
                    break;
                }

                case "category" :
                {
                    inputs.category.value         = value;
                    inputs.category.valid         = Boolean(value);
                    inputs.specs                  = this.getSpecInputs(value, isSpecValidationEnabled);
                    if(!inputs.name.interacted)
                    {
                        inputs.name.value   = Schemas.component.name.generate(inputs.category.value, inputs.specs, window.__categories);
                        this.onNameChange({value: inputs.name.value});
                    }
                    break;
                }

                case "name" :
                {
                    inputs.name.interacted = true;
                    this.onNameChange({value});
                    break;
                }

                case "spec" :
                {
                    let categoryObject  = inputs.category && inputs.category.value ? schemas.categories.findByName2(inputs.category.value, window.__categories) : [];
                    let input = inputs.specs[i];
                    validateField(input, validations.component.specs.value, value, {categoryObject: categoryObject, key: input.schema.name, isSpecValidationEnabled});

                    if(!inputs.name.interacted)
                    {
                        inputs.name.value = Schemas.component.name.generate(inputs.category.value, inputs.specs, window.__categories);
                        this.onNameChange({value: inputs.name.value});
                    }
                    break;
                }

                case "revision" :
                {
                    validateField(inputs.revision, validations.component.revision, value.toUpperCase(), {status: inputs.status.value, revSchemeType: window.__revSchemeType, libraryType: window.__libraryType, defaultBlacklistedRevisions: window.__defaultBlacklistedRevisions});
                    break;
                }

                case "status" :
                {
                    let validationPayload = {status: value, revSchemeType: window.__revSchemeType, libraryType: window.__libraryType}
                    inputs.status.value   = value;
                    inputs.revision.value = validations.component.revision.normalize(validationPayload, null).revision//Schemas.component.revision.normalize(null, value);

                    let input = inputs.revision;
                    input.value = input.value.toUpperCase()
                    // let schema    = Schemas.component.revision;
                    // input.message = schema.validate(input.value, inputs.status.value);
                    // input.valid   = !input.message;
                    // input.class   = input.valid ? "" : "invalid";
                    validateField(input, validations.component.revision, input.value, {status: inputs.status.value, revSchemeType: window.__revSchemeType, libraryType: window.__libraryType});
                    break;
                }

                case "eid" :
                {
                    this.onEidChange(payload);
                    break;
                }

                case "description" :
                {
                    validateField(inputs.description, validations.component.description, value);
                    break;
                }

                case "images" :
                {
                    inputs.images = value.images;
                    inputs.isUploadedThumbnails = value.isUploadedThumbnails;
                    break;
                }

                case "documents" :
                {
                    inputs.documents = value.documents;
                    inputs.isUploadedDocuments = value.documentsProcessed;
                    break;
                }

                default :
                {
                    // noop
                }
            }
            inputs.submit.class = Utils.isValidated(inputs) && inputs.isUploadedThumbnails && inputs.isUploadedDocuments ? "" : "disabled";
        }
        this.setState(state);
    }

    getSpecs()
    {
        let { inputs } = this.state;
        return inputs.specs.map((input) =>
        {
            let spec =
            {
                key   : input.schema.name,
                value : input.value
            };

            return spec;
        });
    }

    getData()
    {
        let { inputs } = this.state;
        let data =
        {
            category    : inputs.category.value,
            name        : inputs.name.value,
            revision    : inputs.revision.value,
            status      : inputs.status.value,
            eid         : inputs.eid.value.trim(),
            description : inputs.description.value,
            images      : inputs.images,
            documents   : inputs.documents,
            specs       : this.getSpecs(),
            children    : [],
            archived    : true,
        };
        return data;
    }

    onSubmit(event)
    {
        event?.preventDefault();
        const { addMore, inputs: _inputs } = this.state;
        if (_inputs.submit.class === "disabled") return;

        let {isEditRoute} = this.props;
        let archivedFlag = addMore || !!isEditRoute;

        const inputs = cloneDeep(_inputs);
        inputs.submit.class = "disabled";
        inputs.submit.stopLoading = false;
        this.setState({ inputs });

        let data = this.getData();
        if (addMore || isEditRoute) data.archived = false;

        const postCreate = componentId => {
            inputs.submit.stopLoading = true;
            const newState = { inputs, forceResetChildState: true };
            if (archivedFlag) {
                if (addMore) {
                    newState.displayPopover = true;
                    newState.cmpId = componentId;
                }
                if (isEditRoute) {
                    this.getComponetFromAPIAndCreateChildAssemblyInput(componentId);
                }
                this.resetInputs(inputs);
                this.setState(newState);
            }
            else {
                this.goteCmpEditRoute(componentId);
                return;
            }
        }

        API.components.create(data, (err, componentId) => {
            if (archivedFlag && addMore) {
                // Set archived false since create component always sets it
                API.components.update(componentId, { archived: false }, () => postCreate(componentId));
            }
            else {
                postCreate(componentId);   
            }
        });
    }

    goteCmpEditRoute(cmpId)
    {
        this.props.history.push({pathname: ("/component/edit/" + cmpId), showAll: true, state: {isNewObject: true}});
    }

    onThumbnailChange(images, error)
    {
        let payload = {
            target: {
                value: {images: images, isUploadedThumbnails: error },
                name: "images"
            }
        };
        this.handleChangeAction(payload);
    }

    onDocumentChange(event, payload)
    {
        let { inputs } = this.state;
        payload = {target: { value: payload, name: event.target.name}};
        this.handleChangeAction(payload);
    }

    getComponetFromAPIAndCreateChildAssemblyInput(cmpId)
    {
        if (cmpId)
        {
            let state = this.state;
            //get component from API and create child input for assembly table
            API.components.findById(cmpId, (err, component) =>
            {
                let selectedCmps = []
                let child =
                {
                    quantity  : "",
                    refDes    : "",
                    component : component,
                    inputs    :
                    {
                        quantity :
                        {
                            value : "",
                            message: "",
                            valid: false,
                            class: ""
                        },
                        refDes :
                        {
                            value : ""
                        },
                        itemNumber :
                        {
                            value : ""
                        },
                        notes :
                        {
                            value : "",
                            message: "",
                            valid: true,
                            class: ""
                        },
                        waste :
                        {
                            value : "",
                            message: "",
                            valid: true,
                            class: ""
                        }
                    },
                    newlyAdded: true
                };
                let error = this.validations.quantity.validateErrors({refDes: ""}, "");
                child.inputs.quantity.message = error ? error[0].message : "";
                child.inputs.quantity.valid = !child.inputs.quantity.message;
                child.inputs.quantity.class = child.inputs.quantity.valid ? "" : "invalid";
                selectedCmps.push(child);

                let newlyCreatedCmpId = state.addMore ? null : cmpId;
                this.props.newlyAddedComponentsFromRightSearch(selectedCmps, newlyCreatedCmpId);

                //Close Manual modal if add another option false
                if (!state.addMore)
                {
                    this.onClose();
                }
            });
        }
    }

    componentDidMount()
    {
        // NOTE: Added more-then-five-cat class for accounts haveing category group > 5
        let catTypeElm = document.querySelector(".new-component-category-types")
        let categoryTypeCount = catTypeElm && catTypeElm.children && catTypeElm.children.length || 0
        if (categoryTypeCount > 5)
        {
            let manualModalElm = document.querySelector(".new-component-manual-route .modal");
            if (manualModalElm)
            {
                manualModalElm.classList.add("more-then-five-cat")
            }
        }
    }

    render()
    {
        let { inputs, submit, nameDuplicateOf, eidDuplicateOf}  = this.state;
        let eidDuplicateTooltip  = Utils.makeDuplicateInputTooltip(eidDuplicateOf, "EID");
        let nameDuplicateTooltip = Utils.makeDuplicateInputTooltip(nameDuplicateOf, "Name");
        const user = userById();
        const cpnRules = extractCpnRules(user.activeLibrary, ModelType.COMPONENT);
        let categoryObject  = schemas.categories.findByName2(inputs.category.value, window.__categories);
        let markup =

            <div className="new-component-manual-route">
                <ModalBox onClose={this.onClose}>
                        <div className="main-block">
                            <div className="content-block">
                                <div className="custom-modal-header">
                                    <div className="header-holder">
                                        <div className="heading-holder">
                                            <h2 onClick={this.autoPopulate}>Create Component</h2>
                                            <Link
                                              to={publicPaths.docs.createManualHelp}
                                              target="_blank"
                                              >
                                            <InlineIcon>
                                                <HelpIcon/>
                                            </InlineIcon>
                                            </Link>
                                        </div>
                                        <div className="cpn-tag-holder">
                                            <h4>CPN</h4>
                                            <div className="cpn-tag">
                                                { Utils.getCpnTag(categoryObject, cpnRules) }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <PerfectScrollbar className="component-manual-scroll">
                                    <div className="card-panel">
                                        <div className="card-panel-header">
                                            <h3>CATEGORY & SPECS</h3>
                                        </div>
                                        <div className="card-panel-body">
                                            <h5 className="categoryType-heading">CHOOSE TYPE</h5>
                                            <CategoryTypes
                                                type="manual"
                                                name="categoryTypes"
                                                onChange={this.onInputChange}
                                                value={inputs.categoryTypes.value}
                                                disabled={inputs.categoryTypes.disabled}
                                            />
                                            <div className="form-group">
                                                <label htmlFor="category">CHOOSE CATEGORY*</label>
                                                <CategorySelect
                                                    className="form-control"
                                                    name="category"
                                                    onChange={this.onInputChange}
                                                    type={inputs.categoryTypes.value}
                                                    value={inputs.category.value}
                                                    disabled={inputs.category.disabled}
                                                />
                                            </div>

                                            {
                                                !inputs.category.value &&
                                                <div className="spec-label-holder">
                                                    <Icon src={specLabelSrc} />
                                                    <p>Select a category to view specs</p>
                                                </div>
                                            }

                                            <div className={"spec-block"}>
                                                <div className="specs">
                                                {
                                                    inputs.specs.map((input, i) =>
                                                    {
                                                        const allowedValues = []
                                                        input.schema.allowedValues.map(allowedValue => {
                                                            allowedValues.push({ value: allowedValue, displayName: allowedValue })
                                                        });
                                                        const isDropDown = input.schema.isDropDown;
                                                        return (Schemas.component.specs.isSpecAllowedForCompany(input.schema.name) && <div key={i} className="spec-holder">
                                                            <div className="form-group">
                                                                <label>{input.schema.displayName}{input.schema.required ? "*" : ""}</label>
                                                                <SpecInputField
                                                                    input={input}
                                                                    onInputChange={this.onInputChange}
                                                                    allowedValues={allowedValues}
                                                                    isDropDown={isDropDown}
                                                                    index={i}
                                                                />
                                                            </div>
                                                        </div> )
                                                    })
                                                }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-panel">
                                        <div className="card-panel-header">
                                            <h3>COMPONENT DETAILS</h3>
                                        </div>
                                        <div className="card-panel-body">
                                            <div className="form-group">
                                                <label htmlFor="name">Name*</label>
                                                <Tooltip
                                                    placement={"right"}
                                                    // visible={nameDuplicateTooltip}
                                                    overlayClassName={`simple-rc-tip error ${nameDuplicateTooltip || inputs.name.message ? "" : "hidden"}`}
                                                    getTooltipContainer={() => document.querySelector("#routes")}
                                                    overlay={ nameDuplicateTooltip ?
                                                            <div>
                                                                <p>
                                                                    <span className="link-text">{nameDuplicateTooltip.errorMessage}</span>
                                                                    <br/>
                                                                    <Link
                                                                        to={nameDuplicateTooltip.viewLink}
                                                                        target="_blank"
                                                                        className="open-link-holder white"
                                                                        >
                                                                        <span className="link-text">{nameDuplicateTooltip.linkMessage}
                                                                            <InlineIcon >
                                                                                <LinkIcon/>
                                                                            </InlineIcon>
                                                                        </span>
                                                                    </Link>
                                                                </p>
                                                            </div>
                                                            :
                                                            <div className={`name-tip ${inputs.name.class}`}>
                                                                <span>{inputs.name.message}</span>
                                                            </div>
                                                        }
                                                    >
                                                    <LazyInput
                                                        type="text"
                                                        name="name"
                                                        placeholder="Please enter a name"
                                                        value={inputs.name.value}
                                                        className={`form-control ${inputs.name.class}`}
                                                        onChange={this.onInputChange}
                                                    />
                                                </Tooltip>
                                            </div>

                                            <div className="form-group-holder">
                                                <div className="form-group">
                                                    <label htmlFor="status">Status</label>
                                                    <select
                                                        name="status"
                                                        value={inputs.status.value}
                                                        onChange={this.onInputChange}
                                                        className={`form-control ${inputs.revision.class}`}
                                                        >
                                                        {Utils.toOptions(Schemas.component.status.list())}
                                                    </select>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="revision">Revision</label>
                                                    <Tooltip
                                                        overlayClassName={`simple-rc-tip error ${inputs.revision.message ? "" : "hidden"}`}

                                                        overlay={
                                                            <div className={`revision-tip ${inputs.revision.class}`}>
                                                                <span>{inputs.revision.message}</span>
                                                            </div>
                                                        }
                                                    >
                                                    <LazyInput
                                                        type="text"
                                                        name="revision"
                                                        value={inputs.revision.value}
                                                        onChange={this.onInputChange}
                                                        className={`form-control ${inputs.revision.class}`}
                                                    />
                                                    </Tooltip>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="eid">EID</label>
                                                    <Tooltip
                                                        placement={"right"}
                                                        // visible={eidDuplicateTooltip}
                                                        overlayClassName={`simple-rc-tip error ${eidDuplicateTooltip || inputs.eid.message ? "" : "hidden"}`}
                                                        getTooltipContainer={() => document.querySelector("#routes")}
                                                        overlay={ eidDuplicateTooltip ?
                                                                <div>
                                                                    <p>
                                                                        <span className={`link-text eid-tip ${inputs.eid.class}`}>{eidDuplicateTooltip.errorMessage}</span>
                                                                        <br/>
                                                                        <Link
                                                                            to={eidDuplicateTooltip.viewLink}
                                                                            target="_blank"
                                                                            className="open-link-holder white"
                                                                            >
                                                                            <span className="link-text">{eidDuplicateTooltip.linkMessage}
                                                                                <InlineIcon >
                                                                                    <LinkIcon/>
                                                                                </InlineIcon>
                                                                            </span>
                                                                        </Link>
                                                                    </p>
                                                                </div>
                                                                :
                                                                <div className={`eid-tip ${inputs.eid.class}`}>
                                                                    <span>{inputs.eid.message}</span>
                                                                </div>
                                                            }
                                                        >
                                                        <LazyInput
                                                            type="text"
                                                            name="eid"
                                                            className={`form-control ${inputs.eid.class}`}
                                                            value={inputs.eid.value}
                                                            onChange={this.onInputChange}
                                                        />
                                                    </Tooltip>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="description">Description</label>
                                            {
                                                <Tooltip
                                                    overlayClassName={`simple-rc-tip error ${inputs.description.message ? "" : "hidden"}`}
                                                    overlay={
                                                        <div className={`description-tip ${inputs.description.class}`}>
                                                            <span>{inputs.description.message}</span>
                                                        </div>
                                                    }
                                                >
                                                <TextArea
                                                    name="description"
                                                    value={inputs.description.value}
                                                    onChange={this.onInputChange}
                                                    className={inputs.description.class}
                                                    forceResetChildState={this.state.forceResetChildState}
                                                />
                                                </Tooltip>
                                            }
                                        </div>
                                        </div>
                                    </div>

                                    <div className="card-panel">
                                        <div className="card-panel-header">
                                            <h3>ATTACHMENTS</h3>
                                        </div>
                                        <div className="card-panel-body last-panel">
                                            <div className="form-group">
                                                <label htmlFor="thumbnails">Thumbnails</label>
                                                <Thumbnails
                                                    name="images"
                                                    onChange={this.onThumbnailChange}
                                                    forceResetChildState={this.state.forceResetChildState}
                                                />
                                            </div>

                                            <div className="form-group">
                                               <label htmlFor="documents">Documents</label>
                                                <Documents
                                                    name="documents"
                                                    onChange={this.onDocumentChange}
                                                    clientSchema={validations.component.documents}
                                                    documentSchema={Schemas.component.documents}
                                                    forceResetChildState={this.state.forceResetChildState}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </PerfectScrollbar>
                                <div className="card-panel-footer">
                                            <div className="left-section">
                                                <button
                                                    className="dark"
                                                    onClick={this.onClose}
                                                    >
                                                    CANCEL
                                                </button>
                                            </div>
                                            <div className="right-section">
                                              <div className="checkbox-label">
                                                <input
                                                    type="checkbox"
                                                    name="add_more"
                                                    onClick={this.toggleAddMore}
                                                />
                                                <label
                                                    htmlFor="group"
                                                />
                                                <span className="label_value">
                                                  Add Another
                                                </span>
                                              </div>

                                               <SubmitBtn
                                                  onClick={this.onSubmit}
                                                  className={inputs.submit.class}
                                                  btnText={"CREATE"}
                                                  isDisabled={inputs.submit.class === 'disabled'}
                                                  stopLoading={inputs.submit.stopLoading}
                                                />
                                                {
                                                    this.state.displayPopover ?
                                                    <ComponentSuccessPopover cmpId={this.state.cmpId}/>
                                                    : null
                                                }
                                            </div>
                                         </div>
                            </div>
                        </div>
                </ModalBox>
            </div>
        return markup
    }
}

export default NewComponentManual
