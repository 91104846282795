import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-742 -477)">
          <g transform="translate(694 127)">
            <g>
              <g transform="translate(48 350)">
                <g fillRule="nonzero">
                  <circle
                    cx="12.5"
                    cy="12.5"
                    r="10.577"
                    fill="#18171D"
                    stroke="#979797"
                    strokeWidth="1"
                  ></circle>
                  <path
                    fill="#3CD1BF"
                    d="M12.5 25C19.4 25 25 19.37 25 12.5S19.4 0 12.5 0 0 5.593 0 12.5 5.6 25 12.5 25zM8.815 12.82l2.168 2.204 7.164-7.332 1.084 1.122-8.286 8.494-3.253-3.326 1.123-1.161z"
                  ></path>
                  <g
                    stroke="#18171D"
                    strokeLinecap="square"
                    strokeWidth="1"
                    transform="translate(7.692 7.692)"
                  >
                    <path d="M.962 6.73l1.923 1.924M10.577.962L2.902 8.67"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
