import USER from '../action-types/user';
import { handleActions } from 'redux-actions';

const defaultStyle =
{
  document: {
    'no' : {
      width: 30,
      position: 0,
      visibility: true,
    },
    'name' : {
      width: 0,
      position: 1,
      visibility: true,
    },
    'specs.type' : {
      width: 120,
      position: 2,
      visibility: true,
    },
    'ext' : {
      width: 100,
      position: 3,
      visibility: true,
    },
    'size' : {
      width: 100,
      position: 4,
      visibility: true,
    },
    'specs.revision' : {
      width: 100,
      position: 5,
      visibility: true,
    },
    'specs.status' : {
      width: 100,
      position: 6,
      visibility: true,
    }
  },
  assembly: {
    'no' : {
      width: 30,
      position: 0,
      visibility: true,
    },
    'component.cpn' : {
      width: 85,
      position: 1,
      visibility: true,
    },
    'component.name' : {
      width: 350,
      position: 2,
      visibility: true,
    },
    'component.category' : {
      width: 150,
      position: 3,
      visibility: true,
    },
    'component.revision' : {
      width: 140,
      position: 4,
      visibility: true,
    },
    'component.status' : {
      width: 145,
      position: 5,
      visibility: true,
    },
    'quantity' : {
      width: 85,
      position: 6,
      visibility: true,
    },
    'refDes' : {
      width: 95,
      position: 7,
      visibility: true,
    },
    'component.eid' : {
      width: 85,
      position: 8,
      visibility: true,
    },
    'notes' : {
      width: 85,
      position: 9,
      visibility: true,
    },
    'unitPrice' : {
      width: 85,
      position: 10,
      visibility: true,
    },
    'totalPrice' : {
      width: 85,
      position: 11,
      visibility: true,
    },
    'leadTime' : {
      width: 85,
      position: 12,
      visibility: true,
    }
  }
}

const initialState =
{
    data : null,
    checked: false,
    imageObject: "",
    authenticating: true,
    preferences: {
      styles: defaultStyle,
    }
}

const setUserPreferences = (state = initialState, action) => {
    const data = action.payload
    state.preferences = data
    return {...state}
}

const setUserOnBoarding = (state = initialState, action) => {
    const data = action.payload
    state.data = data
    // state.onBoarding = setCurrentUserDataa
    return {...state}
}

const setCurrentUserData = (state = initialState, action) => {
    let payloadStyles = action.payload && action.payload.hasOwnProperty("styles") ? action.payload.styles : defaultStyle
    return {...state, data: action.payload, preferences: { styles: payloadStyles }}
}

const setShowHideStateForAssembly = (state = initialState, action) => {
    const data = action.payload
    state.preferences.styles.assembly["component.cpn"].visibility = data["component.cpn"].visibility
    state.preferences.styles.assembly["component.name"].visibility = data["component.name"].visibility
    state.preferences.styles.assembly["component.category"].visibility = data["component.category"].visibility
    state.preferences.styles.assembly["component.revision"].visibility = data["component.revision"].visibility
    state.preferences.styles.assembly["component.status"].visibility = data["component.status"].visibility
    state.preferences.styles.assembly["quantity"].visibility = data["quantity"].visibility
    state.preferences.styles.assembly["refDes"].visibility = data["refDes"].visibility
    state.preferences.styles.assembly["component.eid"].visibility = data["component.eid"].visibility
    state.preferences.styles.assembly["notes"].visibility = data["notes"].visibility
    state.preferences.styles.assembly["unitPrice"].visibility = data["unitPrice"].visibility
    state.preferences.styles.assembly["totalPrice"].visibility = data["totalPrice"].visibility
    state.preferences.styles.assembly["leadTime"].visibility = data["leadTime"].visibility
    return {...state}
}

const setShowHideStateForDocument = (state = initialState, action) => {
    const data = action.payload
    state.preferences.styles.document["name"].visibility = data["name"].visibility
    state.preferences.styles.document["specs.type"].visibility = data["specs.type"].visibility
    state.preferences.styles.document["ext"].visibility = data["ext"].visibility
    state.preferences.styles.document["size"].visibility = data["size"].visibility
    state.preferences.styles.document["specs.revision"].visibility = data["specs.revision"].visibility
    state.preferences.styles.document["specs.status"].visibility = data["specs.status"].visibility
    return {...state}
}

export default handleActions({
  [USER.SET_CURRENT_USER]: setCurrentUserData,
  [USER.AUTHENTICATING]: (state, action) => ({...state, authenticating: action.payload}),
  [USER.SET_IMAGE_OBJECT]: (state, action) => ({...state, imageObject: action.payload}),
  [USER.CHECKED]: state => ({...state, checked: true}),
  // [USER.LOGOUT_CURRENT_USER]: state => ({...state, data: null}),
  [USER.SET_USER_PREFERENCES]: setUserPreferences,
  [USER.SET_USER_ONBOARDING]: setUserOnBoarding,
  [USER.SET_SHOW_HIDE_STATE_FOR_ASSEMBLY]: setShowHideStateForAssembly,
  [USER.SET_SHOW_HIDE_STATE_FOR_DOCUMENT]: setShowHideStateForDocument,

}, initialState);
