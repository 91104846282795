/**
 * TODO (PLM-6282): The component/file currently is just wrapping the old functionality used here.
 * It needs to be updated to using MUI components and make it so that the data isn't modified in way
 * that are hard to follow and can have unintended side effects.
 */

import { Box, styled } from "@mui/material";
import { PageItemType } from "design/constants";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import DisplaySidebarActions from "v1/action-types/display-sidebar";
import AssemblySearchMenu from "v1/components/page/common/assembly/edit/modules/assembly-search-menu";
import buildAction from "v1/helpers/buildAction";
import validations from "v1/modules/validations";
import { useComponentAddAssemblyContext } from "./componentModal";
import { useEditChildren, useEditObject } from "./hooks";

export interface ComponentRightSideBarProps {
  pageItemType: PageItemType;
}

const RightContent = styled(Box)(() => ({
  flexShrink: 0,
  "& .right-search-menu": {
    position: "relative",
  },
}));

export function ComponentRightSideBar({ pageItemType }: ComponentRightSideBarProps) {
  const dispatch = useDispatch();

  const editObject = useEditObject(pageItemType);
  const children = useEditChildren();
  const { addAssemblyFromLibrary } = useComponentAddAssemblyContext();

  const validationLogic = useMemo(() => {
    switch (pageItemType) {
      case PageItemType.COMPONENT: return validations.component.children;
      default: return validations.product.children;
    }
  }, [pageItemType]);

  const showHideAssemblySearch = useCallback(
    () => dispatch(buildAction(DisplaySidebarActions.SET_LIBRARY_FLAG)),
    [dispatch],
  );

  return (
    <RightContent>
      <AssemblySearchMenu
        addedChildList={children}
        component={editObject}
        dispatch={dispatch}
        newlyAddedComponentsFromRightSearch={addAssemblyFromLibrary}
        parentType={pageItemType}
        showHideAssemblySearch={showHideAssemblySearch}
        validations={validationLogic}
      />
    </RightContent>
  );
}
