import { combineReducers }  from 'redux';
import changeForm              from "./form"
import changeOrder             from "./changeorder"

const changeOrderReducer = combineReducers({
    changeForm,
    changeOrder
});

export default changeOrderReducer;
