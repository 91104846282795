import React, {Component}             from "react";


export class FileUploadRow extends Component
{
    constructor(props, context)
    {
        super(props, context);
    }

    getTotalColumnsToSpan()
    {
        let spanValue = 0;
        this.props.headings.forEach((heading) =>
        {
            if(heading.visibility && heading.key !== "remove")
            {
                spanValue++;
            }
        });
        return spanValue;
    }

    render()
    {
        let spanValue = this.getTotalColumnsToSpan();
        let loadingCol= this.props.row.remove;
        let nameCol   = this.props.row.name;
        let remainingTimeCol   = this.props.row.revision;
        return <React.Fragment>
            <td colSpan={spanValue}>{nameCol.displayValue} <span className="current-status">{remainingTimeCol.displayValue}</span></td>
            <td className={loadingCol.cellClass}>
                <div
                    className={""}
                    data-tip=""
                    onMouseOver={null}
                    >
                    {
                        loadingCol.displayValue
                    }
                </div>
            </td>
        </React.Fragment>
    }
}

export default FileUploadRow;
