import React, { Component }      from "react"

const SvgIcon = () =>
{
    return <svg width="15px" height="14px" viewBox="0 0 15 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="2-ERRORS-MPN" transform="translate(-179.000000, -376.000000)" fill="#FFFFFF" fillRule="nonzero" stroke="#FFFFFF" strokeWidth="0.25">
                <g id="Undo" transform="translate(180.000000, 377.000000)">
                    <path d="M12.7076458,0.965279675 C12.5018982,0.915052162 12.2947378,1.04131336 12.2444261,1.24660242 L11.7751525,3.22276698 C10.680318,1.25845753 8.59455676,0 6.28540099,0 C2.81962753,0 0,2.81962753 0,6.28540099 C0,9.75109959 2.81962753,12.5707271 6.28540099,12.5707271 C8.81713727,12.5707271 11.0904751,11.0639016 12.0767608,8.73202745 C12.1592507,8.53710576 12.067928,8.3122422 11.8730063,8.22975229 C11.6786835,8.14801094 11.453221,8.23843543 11.3707311,8.43350684 C10.504662,10.4810828 8.50858612,11.8042148 6.28540099,11.8042148 C3.24225728,11.8042148 0.766512316,9.32846984 0.766512316,6.28540099 C0.766512316,3.24225728 3.24225728,0.766512316 6.28540099,0.766512316 C8.38737762,0.766512316 10.2784885,1.95381107 11.2080063,3.78814466 L9.05420217,3.07768917 C8.85318918,3.01064741 8.63616664,3.11918675 8.56928395,3.31986289 C8.50226091,3.52061389 8.6106786,3.73771129 8.81145767,3.80479047 L11.761819,4.77996967 C11.9032569,4.82077484 12.0148466,4.78151354 12.0685736,4.75185237 C12.1285322,4.71874787 12.2144093,4.64965696 12.2554578,4.50741429 L12.9889498,1.42860229 C13.0392147,1.2229109 12.9132436,1.01550719 12.7076458,0.965279675 Z" id="Shape" transform="translate(6.500000, 6.285364) scale(-1, 1) translate(-6.500000, -6.285364) "></path>
                </g>
            </g>
        </g>
        </svg>
}

export default SvgIcon