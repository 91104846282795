import { FetchPolicy, gql, useQuery } from "@apollo/client";
import { Production } from "build/models/production";
import { ProductionWithRevisions } from "graphql/fragment/productionFragment";
import { useMemo } from "react";

export const GET_PRODUCTION_WITH_REVISIONS = gql`
  query buildProductionWithRevisionById($id: ID!) {
    build {
      productionsByIds(ids: [$id]) {
        ...productionWithRevisionsFragment
      }
    }
  }
  ${ProductionWithRevisions}
`;

export interface BuildQueryData {
  build?: {
    productionsByIds?: Production[];
  }
}

/**
 * Loads up the production and it's revisions.
 *
 * @param id Id of the production to load.
 * @param fetchPolicy How to fetch the data from the API.
 * @returns The production when loaded, errors, and the loading state.
 */
export function useProductionWithRevisions(id: string, fetchPolicy: FetchPolicy = "cache-first") {
  const { data, error, loading, refetch } = useQuery<BuildQueryData>(GET_PRODUCTION_WITH_REVISIONS, {
    variables: { id },
    fetchPolicy,
  });

  const production = useMemo(() => data?.build?.productionsByIds?.[0], [data?.build?.productionsByIds]);

  return { production, productionError: error, productionLoading: loading, productionRefetch: refetch };
}
