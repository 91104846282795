import USER_FORM                         from '../action-types/user_form'
import initialState, { getInitialState } from './initial-states/user_form'
import { handleActions }                 from 'redux-actions'

const setFields = (state = initialState, action) => {
    const field_name = action.payload.name
    const userFields = state.inputs
    userFields[field_name].valid = action.payload.values.valid
    userFields[field_name].message = action.payload.values.message
    userFields[field_name].class = action.payload.values.class
    userFields[field_name].value = action.payload.values.value
    userFields["submit"].class = validated(userFields) ? "" : "disabled"
    return { ...state, inputs: userFields };
}

const resetFieldsValue = (state = initialState, action) => {
    const reset_list = action.payload.reset_list
    const userFields = state.inputs
    reset_list.forEach((field_name) =>
    {
      let input = userFields[field_name]
      input.message = ""
      input.class = ""
      input.value = ""
      input.valid = false
    })
    userFields["submit"].class = validated(userFields) ? "" : "disabled"
    return { ...state, inputs: userFields };
}

const resetInitialState = (state, action) => {
    return {...getInitialState()}
}

export function validated(inputs) {
    for(let key in inputs)
    {
        let input = inputs[key]
        if
        (
            // (input.hasOwnProperty("value") && !input.value) ||
            (input.hasOwnProperty("valid") && !input.valid)
        )
        {
            return false
        }
    }

    return true
}

function updateInnerLoadingFlag(state=initialState, { payload })
{
    state.showInnerLoading = payload
    return {...state}
}

function setUserFormInputs(state=initialState, { payload })
{
    for (var key in payload.userData) {
        if (state.inputs[key])
        {
            state.inputs[key].value = payload.userData[key]
            state.inputs[key].valid = true
        }
    }
    state.inputs.firstName.value = payload.userData.name.split(' ')[0]
    state.inputs.firstName.valid = true
    state.inputs.lastName.value = payload.userData.name.split(' ')[1]
    state.inputs.lastName.valid = true
    state.user = payload.userData
    return {...state}
}

export default handleActions({
    [USER_FORM.UPDATE_FORM_STATE]: setFields,
    [USER_FORM.FORM_SUBMISSION_STATE]: resetInitialState,
    [USER_FORM.RESET_FORM]: resetInitialState,
    [USER_FORM.RESET_THESE_FIELDS]: resetFieldsValue,
    [USER_FORM.UPDATE_INNER_LOADING_FLAG]: updateInnerLoadingFlag,
    [USER_FORM.SET_USER_FROM_INPUTS]: setUserFormInputs,

}, initialState);
