import React from "react"

function Icon(props) {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        transform="translate(-6 -6) translate(5 4)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <path d="M0 0L16 0 16 16 1.04199057e-15 16z" />
        <path d="M0 0L16 0 16 16 0 16z" />
        <path opacity={0.87} d="M0 0L16 0 16 16 0 16z" />
        <path d="M0 0L16 0 16 16 0 16z" />
        <path
          d="M11.6 9.6l-2-1.2.733-.733V4c0-.733-.6-1.333-1.333-1.333V0H7.667v2.667H5V0H3.667v2.667c-.734 0-1.334.6-1.334 1.333v.067l-1.6-.934L0 4.267 2.333 5.6v2.067L4.667 10v2H8v-2l.6-.667 2.2 1.334.8-1.067zM3.667 4H9v3.133l-.6.6-4.733-2.866V4zm3 5.467v1.2H6v-1.2L3.667 7.133v-.666l3.8 2.266-.8.734z"
          transform="translate(1.667 2)"
          fill="#2A2A36"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default Icon
