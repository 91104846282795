export default {
    steps: [
        {
            key             : "full_bom_assembly_tree",
            h1              : "Product Page Overview",
            p1              : "The Product page provides for a centralized, single source of truth",
            p2              : "All your parts and assemblies are easy to find and view, making it easy to communicate with your colleagues and suppliers.",
            p3              : "Notice the level of detail Duro can capture for each Component in the Bill of Materials. Now lets show how this Bill of Materials was created.",
            videoLink       : "https://www.youtube.com/embed/ydUggSRfvP8"
        },
        {
            key             : "first_product",
            h1              : "Creating a New Product",
            p1              : "Start with creating a new Product",
            p2              : "Here you can fill in a few simple fields that describe your product, team members and production forecasting. Think of this page as your central point of reference for all Product related content.",
            videoLink       : "https://www.youtube.com/embed/ZCRpVSRUpsw"
        },
        {
            key             : "import_an_existing_spreadsheet",
            h1              : "Importing a Spreadsheet",
            p1              : "Import a BOM spreadsheet with your parts and assemblies into Duro's library",
            p2              : "One way to add components, parts and assembly data is through Duro's spreadsheet import tool. Duro automatically validates your data against industry standards to help you minimize mistakes and delays when communicating with your colleagues, suppliers, and manufacturers.",
            videoLink       : "https://www.youtube.com/embed/gpIB_D59O7k"
        },
        {
            key             : "component_from_vendor",
            h1              : "Adding Components from a Vendor's Catalog",
            p1              : "Create a new Component directly from a Vendor's available online catalog",
            p2              : "Duro's automated Vendor data import engine removes the need for manual data entry. Simply select a Category and a Manufacturer Part Number.",
            p3              : "Duro will create a new Component with complete and accurate data including datasheets, specs, thumbnails, price breaks and lead times.",
            videoLink       : "https://www.youtube.com/embed/VZ5YYsRId5U"
        },
        {
            key             : "change_order",
            h1              : "Creating a Change Order",
            p1              : "Submit your Changes to your peers and suppliers for review and approval",
            p2              : "Use Duro's simple Change Order workflow to document changes, manage revisions, and notify others for change review and approval. Duro’s streamlined Change Order workflow system enables teams to move quickly and helps them avoid lengthy and bureaucratic approval processes.",
            videoLink       : "https://www.youtube.com/embed/W_wFZ3Pp1z4"
        },
        {
            key             : "export",
            h1              : "Exporting Data and Files",
            p1              : "Export clean and complete design files to your project stakeholders",
            p2              : "Is your design ready to be produced or do you simply want to share it with someone outside your team?",
            p3              : "Duro's easy Export feature allows you to control what product information that people outside your company can see, including Bills of Materials and any associated design and production files.",
            videoLink       : "https://www.youtube.com/embed/3IpKghRtkS0"
        }
    ]
}
