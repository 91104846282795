import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="#fffff"
        d="M12 23.129l.439-.439L1.749 12 12 1.749 22.251 12l-10.69 10.69.439.439.439.432L24 12 12 0 0 12l12 12 .439-.439-.439-.432z"
      ></path>
    </svg>
  );
}

export default Icon;
