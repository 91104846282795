import { Component } from "design/models";
import { useMemo } from "react";
import Utils from "v1/modules/utils";

export interface VendorInfo {
  isVendorCmp: boolean;
  tooltip: string;
}

/**
 * Given a component this returns the vendor specific information about it.
 *
 * @param component The component to get the information from.
 * @returns The vendor information from the component.
 */
export function useVendorInformation(component?: Component): VendorInfo {
  return useMemo(() => {
    if (!component?.vendorInfo?.currentVendors.length) {
      return { isVendorCmp: false, tooltip: "" };
    }
    const vendor = Utils.getVendor(component);

    return {
      isVendorCmp: Utils.isVendorCmp(vendor),
      tooltip: Utils.vendorTooltip(vendor),
    };
  }, [component]);
}
