import UI                   from "../../../action-types/ui"
import CO                   from "../../../action-types/changeorder"
import buildAction          from "../../../helpers/buildAction"
import API                  from "../../../modules/api"
import {privatePaths}       from "../../../app/routes.js"
import Utils                from "../../../modules/utils"

import
{
    takeEvery,
    all,
    cps,
    put,
    call
}                           from "redux-saga/effects"

export const getCO = state => state.changeorders.changeOrder

export function* findChangeOrderById(action)
{
    yield put(buildAction(CO.DISABLE_FORM, true));
    yield put(buildAction(UI.LOAD_START));
    try
    {
        const changeOrder = yield cps(API.cos.findById, action.payload.id);
        const isAdministrator = action.payload.currentUserRole && action.payload.currentUserRole === "ADMINISTRATOR" ? true : false;

        if (changeOrder && action.payload.history_action)
        {
            yield call(API.cos.addHistory, changeOrder._id, {action : "VIEWED"}, () => {});
        }

        if (!changeOrder)
        {
            action.payload.history.push({pathname: "/search", state: {query: "type:co"}});
        }
        else
        {
            yield put(buildAction(CO.SET_CO_OBJECT, changeOrder));
        }

        if (action.payload.editMode && changeOrder)
        {
            if (!isAdministrator)
            {
                let isInApproverList = false;
                if (action.payload.currentUserId)
                {
                    changeOrder.approverList.forEach((approver) =>
                    {
                        if (approver.user._id === action.payload.currentUserId)
                        {
                            isInApproverList = true;
                            return;
                        }
                    });
                }
                if (!isInApproverList || changeOrder.status === 'CLOSED' || changeOrder.resolution === 'UNRESOLVED')
                {
                    action.payload.history.push({pathname: `/changeorder/view/${changeOrder._id}`});
                    return;
                }
            }
        }

        if (action.payload.editMode === true && changeOrder)
        {
            yield put(buildAction(CO.SET_CO_FORM, changeOrder));
        }
    }
    catch(err)
    {
        let payload =
        {
            type: "errors",
            errors: err.errors,
            err: err,
            closeCb: action.payload.notFoundCb || function(){
                                Utils.redirectToParentRoute(privatePaths.changeOrderSearch)
                            }
        };
        yield put(buildAction(UI.SHOW_ALERT, payload));
    }
    yield put(buildAction(UI.LOAD_END));
}

export default function* (getState)
{
    yield all([
        takeEvery(CO.FIND_CO, findChangeOrderById)
    ])
}
